import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import TextInput from "../../../FormInput/TextInput";
import Select from "../../../FormInput/Select";
import PricePicker from "../../../FormComponents/PricePicker/PricePicker";
import ShippingPicker from "../../../FormComponents/ShippingPicker/ShippingPicker";
import { isNumber, validateEmail } from "../../../../utils/Validators";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const SHIPPING_OPTIONS = [
	{ value: "placeholder", name: "Leveransmetod" },
	{ value: "Pickup", name: "Upphämtning hos konstnären" },
	{ value: "Contact", name: "Kunden önskar att få verket levererat" },
	{ value: "Artist", name: "Kontakta konstnären angående transport" },
	{ value: "Sweden", name: "Leverans inom Sverige valt" },
	{ value: "EU", name: "Leverans inom EU valt" },
	{ value: "Global", name: "Leverans globalt valt" },
	{ value: "Delivered", name: "Redan levererad" },
];

const PRO_OPTIONS = [
	{ value: "placeholder", name: "Ja/Nej" },
	{ value: true, name: "Ja" },
	{ value: false, name: "Nej" },
];

let PurchaseArtworkForm = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		currency,
		price,
		vatCode,
		framePrice,
		transport,
		artistIsProMember,
		manualOrder,
		t,
	} = props;
	const isResponsive = appGlobals.useResponsive;
	const formClass = isResponsive ? "responsive-form" : "Form";
	const buttonClass = isResponsive
		? "purple-button--wide"
		: "purple-button--wide button-somemargin";

	return (
		<form onSubmit={handleSubmit} className={formClass}>
			<div className="fieldset-block fieldset-block--nopadding">
				<legend className="legend-header">
					<b>{t("forms.orderConfirm")}</b>
				</legend>
				<div className="fieldset">
					<Fields
						names={["priceCurrency", "price", "status", "VATcode"]}
						component={PricePicker}
						selectedCurrency={currency}
						selectedPrice={price}
						selectedVATCode={vatCode}
						isSwedish={i18next.language === "sv"}
						artistIsProMember={artistIsProMember}
						hideStatus={!manualOrder}
						isResponsive={isResponsive}
						order
					/>
				</div>
				<div className="fieldset" style={{ marginTop: "20px" }}>
					<Fields
						names={[
							"shipping.price",
							"shipping.location",
							"shipping.transport",
						]}
						component={ShippingPicker}
						currency={currency}
						transport={transport}
						isSwedish={i18next.language === "sv"}
						order={!manualOrder}
						manualOrder={manualOrder}
						isResponsive={isResponsive}
						disabled={!manualOrder}
					/>
					{manualOrder && (
						<div className="input-holder">
							<legend className="legend-nomargin">
								{t("artworks:single.framePrice")}
							</legend>
							<Field
								name="framePrice"
								component={TextInput}
								placeholder={framePrice}
							/>
						</div>
					)}
				</div>
				{manualOrder && (
					<div className="fieldset" style={{ marginTop: 20 }}>
						<div className="input-holder">
							<legend className="legend-nomargin">
								{t("artworks:single.proAtSale")}
							</legend>
							<Field
								name="wasProAtSale"
								component={Select}
								options={PRO_OPTIONS}
							/>
						</div>
						<legend>{"Kontaktuppgifter och leverans"}</legend>
						<div className="input-holder">
							<Field
								name="buyerName"
								component={TextInput}
								placeholder="Namn på köpare"
							/>
						</div>
						<div className="input-holder">
							<Field
								name="buyerEmail"
								component={TextInput}
								placeholder="Köparens email"
								validate={validateEmail}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="buyerAddress"
								component={TextInput}
								placeholder="Köparens adress"
							/>
						</div>
						<div className="input-holder">
							<Field
								name="buyerPost"
								component={TextInput}
								placeholder="Postkod"
							/>
						</div>
						<div className="input-holder">
							<Field
								name="buyerCity"
								component={TextInput}
								placeholder="Stad"
							/>
						</div>
						<div className="input-holder">
							<Field
								name="buyerCountry"
								component={TextInput}
								placeholder="Land"
							/>
						</div>
						<legend>{"Kundens val av leverans"}</legend>
						<div className="input-holder">
							<Field
								name="description"
								component={Select}
								options={SHIPPING_OPTIONS}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="fieldset">
				<button className={buttonClass} type="submit" disabled={submitting}>
					{manualOrder ? t("forms.manualOrder") : t("forms.reserve")}
				</button>
			</div>
		</form>
	);
};

PurchaseArtworkForm = reduxForm({
	form: "purchaseArtwork",
})(PurchaseArtworkForm);

const selector = formValueSelector("purchaseArtwork");
PurchaseArtworkForm = connect((state) => {
	const currency = selector(state, "priceCurrency");
	const price = selector(state, "price");
	const vatCode = selector(state, "VATcode");
	const framePrice = selector(state, "framePrice");
	const transport = selector(state, "shipping.transport");

	const artist = selector(state, "artistMod");
	const artistIsProMember = isPremium(artist);

	return { currency, price, vatCode, framePrice, transport, artistIsProMember };
})(PurchaseArtworkForm);

export default withNamespaces("chat")(PurchaseArtworkForm);
