import {
	METRICS_REQUEST, METRICS_FAILURE, METRICS_SUCCESS,
	ARTWORKS_METRICS_REQUEST, ARTWORKS_METRICS_FAILURE, ARTWORKS_METRICS_SUCCESS,
	ARTWORKS_ADMIN_METRICS_REQUEST, ARTWORKS_ADMIN_METRICS_FAILURE, ARTWORKS_ADMIN_METRICS_SUCCESS,
	VENUES_ADMIN_METRICS_REQUEST, VENUES_ADMIN_METRICS_FAILURE, VENUES_ADMIN_METRICS_SUCCESS,
	SHOWS_METRICS_REQUEST, SHOWS_METRICS_FAILURE, SHOWS_METRICS_SUCCESS,
	GALLERY_METRICS_REQUEST, GALLERY_METRICS_FAILURE, GALLERY_METRICS_SUCCESS
} from '../actions/DashboardActions'

export function dashboardReducer(state = {
		isFetching: false,
		metrics: {},
		artworksMetrics: [],
		adminArtworksMetrics: [],
		adminVenuesMetrics: [],
		showsMetrics: [],
		galleryMetrics: [],
		authenticated: false
	}, action) {
	switch (action.type) {
		case METRICS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				metrics: {}
			})
		}
		case METRICS_SUCCESS: {
			let metrics = action.response
			return Object.assign({}, state, {
				isFetching: false,
				metrics: metrics,
				error: null
			})
		}
		case METRICS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}

		case ARTWORKS_METRICS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				artworksMetrics: []
			})
		}
		case ARTWORKS_METRICS_SUCCESS: {
			let metrics = action.response
			return Object.assign({}, state, {
				isFetching: false,
				artworksMetrics: metrics,
				error: null
			})
		}
		case ARTWORKS_METRICS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case ARTWORKS_ADMIN_METRICS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				adminArtworksMetrics: []
			})
		}
		case ARTWORKS_ADMIN_METRICS_SUCCESS: {
			let metrics = action.response
			return Object.assign({}, state, {
				isFetching: false,
				adminArtworksMetrics: metrics,
				error: null
			})
		}
		case ARTWORKS_ADMIN_METRICS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case VENUES_ADMIN_METRICS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				adminVenuesMetrics: []
			})
		}
		case VENUES_ADMIN_METRICS_SUCCESS: {
			let metrics = action.response.count
			return Object.assign({}, state, {
				isFetching: false,
				adminVenuesMetrics: metrics,
				error: null
			})
		}
		case VENUES_ADMIN_METRICS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case SHOWS_METRICS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				showsMetrics: []
			})
		}
		case SHOWS_METRICS_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				showsMetrics: action.response,
				error: null
			})
		}
		case SHOWS_METRICS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case GALLERY_METRICS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				galleryMetrics: []
			})
		}
		case GALLERY_METRICS_SUCCESS: {
			let metrics = action.response
			return Object.assign({}, state, {
				isFetching: false,
				galleryMetrics: metrics,
				error: null
			})
		}
		case GALLERY_METRICS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		default:
			return state
	}
}
