import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { fetchInvoiceOrders } from "../../actions/OrderActions";
import Main from "../../components/Main/Main";
import InvoiceOrder from "../../components/OrderComponents/InvoiceOrder";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";

class InvoiceOrders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pendingExpanded: false,
			convertedExpanded: false,
			deliveredExpanded: false,
		};
		this.scrollRefs = {};
	}

	fetchOrders = (status) => {
		this.setState({
			pendingExpanded: false,
			convertedExpanded: false,
			deliveredExpanded: false,
		});
		this.props.dispatch(fetchInvoiceOrders(status));
	};

	toggleSection = (section) => {
		this.setState({
			[section]: !this.state[section],
		});
	};

	render() {
		const { invoiceOrders, user } = this.props;
		const { pendingExpanded, convertedExpanded, deliveredExpanded } =
			this.state;

		const admin = user.role === "admin";
		if (!admin) return false;

		const ordersSorted = {
			pending: [],
			converted: [],
			delivered: [],
		};
		Object.keys(invoiceOrders).forEach((key) => {
			const order = invoiceOrders[key];
			ordersSorted[order.status].push(order);
		});

		return (
			<div className="orders">
				<Main>
					<div className="orders__buttons">
						<button
							className="button--small-button"
							onClick={() => this.fetchOrders("all")}
						>
							All
						</button>
						<button
							className="button--small-button"
							onClick={() => this.fetchOrders("pending")}
						>
							Pending
						</button>
						<button
							className="button--small-button"
							onClick={() => this.fetchOrders("converted")}
						>
							Converted
						</button>
						<button
							className="button--small-button"
							onClick={() => this.fetchOrders("delivered")}
						>
							Delivered
						</button>
					</div>
					<div className="MainTop" style={{ marginBottom: "1.77rem" }}>
						<h2>Pending orders ({ordersSorted.pending.length})</h2>
						<div className="orders__section-clarification">
							(Not checked out)
						</div>
						{ordersSorted.pending.length > 0 && (
							<ExpandButton
								expanded={pendingExpanded}
								click={() => this.toggleSection("pendingExpanded")}
								style={{ top: 0 }}
							/>
						)}
					</div>
					{pendingExpanded &&
						Object.keys(ordersSorted.pending).map((key) => {
							const order = ordersSorted.pending[key];
							return (
								<InvoiceOrder key={key} order={order} activeUserId={user.id} />
							);
						})}
					<div className="MainTop" style={{ marginBottom: "1.77rem" }}>
						<h2>Converted orders ({ordersSorted.converted.length})</h2>
						<div className="orders__section-clarification">
							(Have been checked out)
						</div>
						{ordersSorted.converted.length > 0 && (
							<ExpandButton
								expanded={convertedExpanded}
								click={() => this.toggleSection("convertedExpanded")}
								style={{ top: 0 }}
							/>
						)}
					</div>
					{convertedExpanded &&
						Object.keys(ordersSorted.converted).map((key) => {
							const order = ordersSorted.converted[key];
							return (
								<InvoiceOrder key={key} order={order} activeUserId={user.id} />
							);
						})}
					<div className="MainTop" style={{ marginBottom: "1.77rem" }}>
						<h2>Delivered orders ({ordersSorted.delivered.length})</h2>
						<div className="orders__section-clarification">
							(Have been handled by us)
						</div>
						{ordersSorted.delivered.length > 0 && (
							<ExpandButton
								expanded={deliveredExpanded}
								click={() => this.toggleSection("deliveredExpanded")}
								style={{ top: 0 }}
							/>
						)}
					</div>
					{deliveredExpanded &&
						Object.keys(ordersSorted.delivered).map((key) => {
							const order = ordersSorted.delivered[key];
							return (
								<InvoiceOrder key={key} order={order} activeUserId={user.id} />
							);
						})}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		order: { invoiceOrders },
	} = state;

	return { user, invoiceOrders };
}

export default connect(mapStateToProps)(InvoiceOrders);
