import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
	change,
} from "redux-form";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import TextInput from "../../../components/FormInput/TextInput";
import PricePicker from "../../../components/FormComponents/PricePicker/PricePicker";
import {
	validateVAT,
	validateClearNr,
	validateAccNr,
} from "../../../utils/Validators";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class InvoiceForm extends Component {
	constructor(props) {
		super(props);
	}

	//Initialize all values from the artist
	componentWillReceiveProps(nextProps) {
		if (
			this.props.locationName !== nextProps.locationName &&
			nextProps.locationName
		) {
			this.props.dispatch(
				change("invoice", "locationName", nextProps.locationName)
			);
		}
		if (this.props.orgNumber !== nextProps.orgNumber && nextProps.orgNumber) {
			this.props.dispatch(change("invoice", "orgNumber", nextProps.orgNumber));
		}
		if (this.props.bankName !== nextProps.bankName && nextProps.bankName) {
			this.props.dispatch(change("invoice", "bankName", nextProps.bankName));
		}
		if (
			this.props.clearingNr !== nextProps.clearingNr &&
			nextProps.clearingNr
		) {
			this.props.dispatch(
				change("invoice", "clearingNr", nextProps.clearingNr)
			);
		}
		if (this.props.accountNr !== nextProps.accountNr && nextProps.accountNr) {
			this.props.dispatch(change("invoice", "accountNr", nextProps.accountNr));
		}
		if (this.props.bankgiro !== nextProps.bankgiro && nextProps.bankgiro) {
			this.props.dispatch(change("invoice", "bankgiro", nextProps.bankgiro));
		}
	}

	//Returns the price of the selected shipping
	getShippingPrice = (shipping, selectedTransport) => {
		if (!shipping) {
			return 0;
		}
		let price = 0;
		switch (selectedTransport) {
			case "Artist":
				price = Number(shipping.customPrice);
				break;
			case "Sweden":
				price = Number(shipping.swePrice);
				break;
			case "EU":
				price = Number(shipping.euPrice);
				break;
			case "Global":
				price = Number(shipping.globalPrice);
				break;
			default:
				break;
		}
		return price;
	};

	render() {
		const {
			handleSubmit,
			orgNumber,
			clearingNr,
			accountNr,
			bankgiro,
			pristine,
			submitting,
			currency,
			price,
			vatCode,
			framePrice,
			shipping,
			selectedTransport,
			locationName,
			artistIsProMember,
			fetchingInvoice,
			t,
		} = this.props;

		const isSwedish = i18next.language === "sv";
		const shippingPrice = this.getShippingPrice(shipping, selectedTransport);

		//Responsive data
		const isResponsive = appGlobals.useResponsive;
		const formClass = isResponsive ? "responsive-form" : "form";

		//Add VAT to shipping- and frameprice
		const shippingVAT = shippingPrice * 1.25;
		const frameVAT = framePrice * 1.25;

		return (
			<form onSubmit={handleSubmit} className={formClass}>
				<div className="fieldset-block fieldset-block--nopadding">
					<legend className="legend-header">{t("invoice.details")}</legend>
					<div className="fieldset">
						<Fields
							names={["priceCurrency", "price", "status", "VATcode"]}
							component={PricePicker}
							selectedCurrency={currency}
							selectedPrice={price}
							selectedVATCode={vatCode}
							isSwedish={isSwedish}
							artistIsProMember={artistIsProMember}
							isResponsive={isResponsive}
							hideStatus
							disabled
							order
							invoice
						/>
					</div>
					<div
						className={isResponsive ? "fieldset-r" : "fieldset"}
						style={{ marginTop: "20px" }}
					>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<legend className="legend-nomargin">
								{t("invoice.shippingDesc")}
							</legend>
							<Field
								name="shippingDesc"
								component={TextInput}
								placeholder={selectedTransport}
								isResponsive={isResponsive}
								disabled
							/>
						</div>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<legend className="legend-nomargin">
								{t("invoice.shippingPrice")}
							</legend>
							<Field
								name="shippingPrice"
								component={TextInput}
								placeholder={shippingVAT}
								isResponsive={isResponsive}
								disabled
							/>
						</div>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<legend className="legend-nomargin">
								{t("single.framePrice")}
							</legend>
							<Field
								name="frameVAT"
								component={TextInput}
								placeholder={frameVAT || "0"}
								disabled
							/>
						</div>
					</div>
					<div
						className={isResponsive ? "fieldset-r" : "fieldset"}
						style={{ marginTop: "20px" }}
					>
						<legend>{t("artist:single.payment")}</legend>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<Field
								name="orgNumber"
								component={TextInput}
								placeholder={t("artist:single.organisationNumber")}
								validate={validateVAT}
							/>
						</div>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<Field
								name="locationName"
								component={TextInput}
								placeholder={t("invoice.billingAdress")}
							/>
						</div>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<Field
								name="bankName"
								component={TextInput}
								placeholder={t("artist:single.bankName")}
							/>
						</div>
					</div>
					<div className={isResponsive ? "fieldset-r" : "fieldset"}>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<Field
								name="clearingNr"
								component={TextInput}
								placeholder={t("artist:single.clearingNr")}
								validate={validateClearNr}
							/>
						</div>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<Field
								name="accountNr"
								component={TextInput}
								placeholder={t("artist:single.accountNr")}
							/>
						</div>
						<div className={isResponsive ? "input-holder-r" : "input-holder"}>
							<Field
								name="bankgiro"
								component={TextInput}
								placeholder={t("artist:single.bankgiro")}
							/>
						</div>
					</div>
				</div>
				<div className="fixed-save-button mobile-only">
					<button
						type="submit"
						className="purple-button--wide"
						disabled={pristine || submitting || fetchingInvoice}
						style={isResponsive ? {} : { marginLeft: "40px" }}
					>
						{isResponsive ? (
							<span style={{ lineHeight: "1" }}>
								{t("invoice.sendInvoice1")}
								<br />
								{t("invoice.sendInvoice2")}
							</span>
						) : (
							<span style={{ lineHeight: "1" }}>
								{`${t("invoice.sendInvoice1")} ${t("invoice.sendInvoice2")}`}
							</span>
						)}
					</button>
				</div>
			</form>
		);
	}
}

InvoiceForm = reduxForm({
	form: "invoice",
})(InvoiceForm);

const selector = formValueSelector("invoice");
InvoiceForm = connect((state) => {
	const currency = selector(state, "priceCurrency");
	const price = selector(state, "price");
	const vatCode = selector(state, "VATcode");
	const framePrice = selector(state, "framePrice");
	const orgNumber = selector(state, "artistMod.orgNumber");
	const bankName = selector(state, "artistMod.bankName");
	const clearingNr = selector(state, "artistMod.clearingNr");
	const accountNr = selector(state, "artistMod.accountNr");
	const bankgiro = selector(state, "artistMod.bankgiro");
	const locationName = selector(state, "artistMod.locationName");
	const shipping = selector(state, "shipping");

	const artist = selector(state, "artistMod");
	const artistIsProMember = isPremium(artist);

	return {
		currency,
		price,
		vatCode,
		framePrice,
		shipping,
		orgNumber,
		bankName,
		clearingNr,
		accountNr,
		bankgiro,
		locationName,
		artistIsProMember,
	};
})(InvoiceForm);

export default withNamespaces("artworks")(InvoiceForm);
