import React, { Component } from "react";

const TEXTS = {
	gallery: "Edit your venue profile.",
	artworks: "Manage your collection of artworks.",
	settings: "Edit your personal account settings.",
	events: "Add an upcoming event, such as an art talk or a vernissage",
	shows: "Add and edit your current, past and upcoming exhibitions.",
};

const FormMeta = (props) => {
	const text = props.text || TEXTS[props.type] || "";

	return (
		<div style={{ marginBottom: "0.77em" }}>
			<h4 className="muted">{text}</h4>
		</div>
	);
};

export default FormMeta;
