import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormsy } from "formsy-react";

// Select is a Formsy component. The parent component tells this component what
// validations that should run onChange.
// The built-in valdations can be found here: https://github.com/christianalfoni/formsy-react/blob/master/API.md#validators

class Select extends Component {
	static propTypes = {
		options: PropTypes.array.isRequired,
		selected: PropTypes.string,
	};
	changeValue = (event) => {
		this.props.setValue(event.currentTarget.value);
		if (this.props.onChange) {
			this.props.onChange(event.currentTarget.value);
		}
	};
	renderOptions = () => {
		return this.props.options.map((option) => {
			return (
				<option key={option.value} value={option.value}>
					{option.name}
				</option>
			);
		});
	};
	render() {
		const { props } = this;
		const className = props.showRequired()
			? "required"
			: props.showError()
			? "error"
			: null;
		const errorMessage = props.getErrorMessage();
		const value = props.getValue() || "";

		return (
			<div className={"SimpleSelect " + className}>
				<select type={props.type} onChange={this.changeValue} value={value}>
					{this.renderOptions()}
				</select>
				{errorMessage ? <span>{errorMessage}</span> : null}
			</div>
		);
	}
}

export default withFormsy(Select);
