import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import i18n from "i18next";
import { withNamespaces } from "react-i18next";
import TextArea from "../../components/FormInput/TextArea";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import MemberApplication from "../../components/FormComponents/Registry/MemberApplication";
import FormEditor from "../../components/FormComponents/FormEditor/FormEditor";

// Same default mail used in artSocietyEmail.js in API.
// Explicit space ${' '} because Safari don't read linebreak and spaces in placeholder as we wan't it to.
const memberAppliedPlaceholder = (name) => {
	if (!name) return "";
	// if (i18n.language !== 'sv') return 'Text for email to person who applied for membership'
	return `Skriv eget mejl här, eller lämna den här rutan tom för att det här mejlet ska skickas:${" "}

Hej!${" "}

Vad kul att du vill bli medlem i ${name}!${" "}

Du kommer att bli meddelad inom kort när vi har godkänt ditt medlemskap.${" "}

Vänliga hälsningar,${" "}
${name}`;
};

// Same default mail used in artSocietyEmail.js in API.
const memberApprovedPlaceholder = (name) => {
	if (!name) return "";
	// if (i18n.language !== 'sv') return 'Text for email to person whose membership has been approved'
	return `Skriv eget mejl här, eller lämna den här rutan tom för att det här mejlet ska skickas:${" "}

Hej!${" "}

Din ansökan om medlemskap i ${name} har godkänts.${" "}

Välkommen som medlem!${" "}

Vänliga hälsningar,${" "}
${name}`;
};

class InfoForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorChanged: false,
		};
	}

	updateChild = () => {
		this.editor.updateContentFromParent();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			isAdmin,
			galleryName,
			email,
			t,
		} = this.props;
		const { editorChanged } = this.state;

		const appliedEmailPlaceholder = memberAppliedPlaceholder(galleryName);
		const approvedEmailPlaceholder = memberApprovedPlaceholder(galleryName);

		const defaultEmail = email || "";

		return (
			<form onSubmit={handleSubmit} className="Gallery__form' Form">
				{/*Membership information*/}
				<div className="input-holder input-holder--full">
					<legend className="legend-nomargin">
						{t("single.membershipInformation")}
					</legend>
					{/*
            <Field
            name="artSocietyData.membershipInformation"
            component={ TextArea }
            placeholder={t('single.membershipInformationPlaceholder')} />
          */}
					<Fields
						names={[
							"artSocietyData.draftContentStateMember",
							"artSocietyData.htmlContentMember",
						]}
						component={FormEditor}
						onRef={(ref) => (this.editor = ref)}
						onEditorChange={this.editorChange}
					/>
				</div>

				{/*Member checkboxes*/}
				<div className="input-holder input-holder--full">
					<legend className="legend-nomargin">
						{t("single.registryColumns")}
					</legend>
					<Fields
						names={["registry.applicationColumns", "registry.customColumns"]}
						component={MemberApplication}
					/>
				</div>

				{/*Application recipient*/}
				<div className="fieldset-block">
					<div className="input-holder input-holder--full input-holder--nopadding">
						<legend className="legend-nomargin">
							{t("common:account.applicationEmail")}:
						</legend>
						<Field
							name="artSocietyData.applicationEmailAddress"
							component={TextInput}
							placeholder={defaultEmail}
						/>
						<legend className="legend-nomargin">
							{t("common:account.applicationEmailCopy")}:
						</legend>
						<Field
							name="artSocietyData.applicationEmailAddressCopy"
							component={TextInput}
							placeholder={t("common:email")}
						/>
					</div>
				</div>

				{/*Application emails*/}
				<div className="input-holder input-holder--full">
					<legend className="legend-nomargin">
						{t("single.memberApplied")}
					</legend>
					<Field
						name="artSocietyData.memberAppliedMail"
						component={TextArea}
						placeholder={appliedEmailPlaceholder}
						extraClass="linebreaks info-form"
						rows={10}
					/>
					<legend className="legend-nomargin">
						{t("single.memberApproved")}
					</legend>
					<Field
						name="artSocietyData.memberApprovedMail"
						component={TextArea}
						placeholder={approvedEmailPlaceholder}
						extraClass="linebreaks info-form"
						rows={10}
					/>
				</div>

				{/*Save button*/}
				<div className="fieldset">
					{/* View-online link now in NavBar, except for admin. */}
					{isAdmin && (
						<div className="input-holder">
							<Field
								name="slug"
								itemType="gallery"
								component={TextDisplay}
								isAdmin={isAdmin}
							/>
						</div>
					)}
					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							onClick={this.updateChild}
							disabled={(pristine || submitting) && !editorChanged}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

InfoForm = reduxForm({
	form: "gallery",
})(InfoForm);
const selector = formValueSelector("gallery");

const translated = withNamespaces("gallery")(InfoForm);
export default connect(null)(translated);
