import {
	TOGGLE_EXPANDED, SHOW_MORE, DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE
} from '../../actions/DashboardActions/PastShowsActions'

const DISPLAY_COUNT = 3;

export function pastShowsReducer(state = {
		isFetching: false,
		authenticated: false,
		displayCount: DISPLAY_COUNT,
		shows: [],
    expanded: false
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		case SHOW_MORE:
			return Object.assign({}, state, {
				displayCount: state.displayCount + DISPLAY_COUNT
			});
		case DATA_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			});
		case DATA_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				shows: action.response.shows
			});
		case DATA_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			});
		default:
			return state
	}
}
