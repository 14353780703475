import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import Select from "react-select";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import Main from "../../../components/Main/Main";
import StripeSubForm from "./StripeSubForm";

import {
	fetchStripeSubscription,
	addOrRemoveFromArtist,
} from "../../../actions/Stripe/StripeSubActions";
import { fetchAllArtistsFast } from "../../../actions/ArtistActions";

class StripeSubscription extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			selectedArtist: null,
			inputArtistId: "",
		};
	}

	componentWillMount() {
		// console.log('this.props', this.props);
		const id = this.props.params.subId;
		// console.log('FETCH SUB', id);
		this.props.dispatch(fetchStripeSubscription(id));
	}

	componentWillReceiveProps(nextProps) {
		const { allArtists } = nextProps;

		const id = this.props.params.subId;
		if (!this.props.subscriptions[id] && nextProps.subscriptions[id]) {
			// console.log('initialize with', nextProps.subscriptions[id]);
			this.props.dispatch(initialize("stripeSub", nextProps.subscriptions[id]));
		}

		const artistKeys = Object.keys(allArtists);
		let artistOptions = this.state.artistOptions;
		let updateOptions = false;
		if (artistOptions.length !== artistKeys.length) {
			// console.log('CREATE NEW ARTIST OPTIONS');
			artistOptions = [];
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				// Value is artist instead of artist._id to allow checking tagged artworks
				artistOptions.push({
					value: artist,
					label: artist.name,
					id: artist._id,
				});
			});

			updateOptions = true;
		}

		if (updateOptions) {
			artistOptions.sort(this.sortOptions);
			// console.log('artistOptions', artistOptions);

			this.setState({
				artistOptions,
			});
		}
	}

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	handleSubmit = () => {
		console.log("HANDLE SUBMIT");
		const {
			form: { show, initial },
		} = this.props;
		// this.props.dispatch( submitStripeSub(show, initial) )
	};

	inputChanged = (input) => {
		// Only fetch when exact numbers of letters entered.
		if (input.length === 2) {
			this.props.dispatch(fetchAllArtistsFast(input));
		}
	};

	selectOption = (option) => {
		const { allGalleries, allArtists } = this.props;

		this.setState({
			selectedArtist: option,
		});
	};

	addOrRemove = (artistId, subscriptionId, type) => {
		console.log("addToArtist", artistId, subscriptionId, type);
		const sure = window.confirm(`Are you sure you want to ${type}?`);
		if (sure) {
			this.props.dispatch(
				addOrRemoveFromArtist(artistId, subscriptionId, type)
			);
		}
	};

	artistIdChanged = (e) => {
		this.setState({
			inputArtistId: e.target.value,
		});
	};

	// Function for testing fetch function in the API.
	fetchAgain = () => {
		const id = this.props.params.subId;
		this.props.dispatch(fetchStripeSubscription(id));
	};

	render() {
		const { isAdmin, subscriptions, isFetching } = this.props;
		const { artistOptions, selectedArtist, inputArtistId } = this.state;
		if (!isAdmin) return null;
		const id = this.props.params.subId;
		const subscription = subscriptions[id];
		// console.log('artistOptions', artistOptions);
		// console.log('selectedArtist', selectedArtist);
		// console.log('inputArtistId', inputArtistId);

		return (
			<Main>
				<StripeSubForm
					handleSubmit={this.handleSubmit}
					isAdmin={isAdmin}
					fetchAgain={this.fetchAgain}
				/>
				{/*<button type="button" onClick={ this.fetchAgain }>Fetch subscription</button>*/}
				<div>
					<div
						className="input-holder input-holder--full"
						style={{ marginTop: "50px" }}
					>
						<legend>Subscription set on artist:</legend>
						{subscription &&
							subscription.artists &&
							subscription.artists.map((artist) => {
								return (
									<div key={artist._id}>
										<div style={{ margin: "20px 0", whiteSpace: "nowrap" }}>
											<Link
												to={`/artists/${artist.slug}`}
											>{`${artist.name} ${artist.email}`}</Link>
										</div>
										<button
											onClick={() =>
												this.addOrRemove(artist._id, subscription._id, "remove")
											}
										>{`Remove sub from ${artist.name}`}</button>
									</div>
								);
							})}
					</div>
					<div className="input-holder" style={{ marginTop: "50px" }}>
						<legend>Add subscription to artist</legend>
						<Select
							value={selectedArtist}
							options={artistOptions}
							onChange={this.selectOption}
							onInputChange={this.inputChanged}
							valueKey="value"
							labelKey="label"
							isLoading={isFetching}
							placeholder="Select artist"
							multi={false}
						/>
						<button
							onClick={() =>
								this.addOrRemove(selectedArtist.id, subscription._id, "add")
							}
							disabled={!selectedArtist}
						>
							{`Add sub to ${selectedArtist ? selectedArtist.label : "N/A"}`}
						</button>
					</div>
					<div className="input-holder" style={{ marginTop: "50px" }}>
						<legend>Or use artist id</legend>
						<input
							value={inputArtistId}
							onChange={(e) => this.artistIdChanged(e)}
							style={{ marginBottom: "20px" }}
						/>
						<button
							onClick={() =>
								this.addOrRemove(inputArtistId, subscription._id, "add")
							}
							disabled={!inputArtistId}
						>
							{`Add sub to ${inputArtistId}`}
						</button>
					</div>
				</div>
				<div
					className="input-holder input-holder--full"
					style={{ marginTop: "50px" }}
				>
					{subscription &&
						subscription.users &&
						subscription.users.map((user) => {
							return (
								<div key={user._id}>
									User:{" "}
									<Link
										to={`/accounts/${user._id}`}
									>{`${user.name} ${user.email}`}</Link>
								</div>
							);
						})}
				</div>
			</Main>
		);
	}
}

const mapStateToProps = (state) => {
	// console.log('state.stripeSubscriptions', state.stripeSubscriptions);
	// console.log('state.artist', state.artist);
	const {
		stripeSubscriptions: { subscriptions },
		auth: { user },
		artist: { allArtists, isFetching },
	} = state;
	const isAdmin = user.role === "admin";
	const form = {
		submitting: isSubmitting("stripeSub")(state),
		submitSucceeded: hasSubmitSucceeded("stripeSub")(state),
		submitFailed: hasSubmitFailed("stripeSub")(state),
		stripeSub: getFormValues("stripeSub")(state),
		initial: getFormInitialValues("stripeSub")(state),
	};

	return { isAdmin, subscriptions, allArtists, form, isFetching };
};

export default connect(mapStateToProps)(StripeSubscription);
