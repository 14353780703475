import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

class GalleryStats extends React.Component {
	/**
	 * defaultProps
	 */
	static defaultProps = {
		isAdmin: false,
	};

	static propTypes = {
		isAdmin: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			metrics: {},
			isAdmin: false,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.metrics) {
			this.setState({
				metrics: newProps.metrics,
			});
		}
	}
	renderAdmin = () => {
		return <h1>Admin lacks dashboard...</h1>;
	};
	renderGallerist = (metrics) => {
		return null;
	};
	render() {
		const { metrics } = this.state;
		if (this.props.isAdmin) {
			return this.renderAdmin();
		} else {
			return this.renderGallerist(metrics);
		}
	}
}

export default GalleryStats;
