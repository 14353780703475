import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
	togglePopularArtwork,
	fetchData,
} from "../../../actions/DashboardActions/PopularArtworkActions";
import LeastMostPopular from "./LeastMostPopular";

class PopularArtworks extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.dispatch(fetchData());
	}

	render() {
		const { expanded, artworks, t } = this.props;

		return (
			<div className="LeastMostPopular">
				<h4 className="artist">{t("artist.popularEver")}</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded && artworks.length > 0 ? (
							<LeastMostPopular artworks={artworks} t={t} />
						) : (
							<div className="LeastMostPopular__Empty">
								<h4 className="artist">
									{t("artist.noPopular1")}
									<br />
									{t("artist.noPopular2")}
								</h4>
							</div>
						)}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		popularArtwork: { expanded, artworks },
	} = state;
	return { expanded, artworks };
}

export default connect(mapStateToProps)(PopularArtworks);
