import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { withNamespaces }   from 'react-i18next'
import RelatedPicker        from '../../components/FormComponents/RelatedPicker/RelatedPicker'
import CreateRelated        from '../../components/FormComponents/RelatedPicker/CreateRelated'
import {
  Field,
  Fields,
  FormSection,
  reduxForm,
} from 'redux-form'
import {
  submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
}	from 'redux-form'
import {
	loadArtist,
  submitMentionForArtist,
}	from '../../actions/FormActions/ArtistFormActions'

let MentionsForm = (props) => {
  const { handleSubmit, submitting, t } = props

  return (
    <form onSubmit={ handleSubmit } className="Form">
      <div className="fieldset-block">
        <legend>{t('common:mentions.addNewMention')}</legend>
        <CreateRelated handleSubmit={ handleSubmit } submitting={ submitting } />
      </div>
    </form>
  )
}

MentionsForm = reduxForm({
  form: 'artist'
})(MentionsForm)

class EditMentions extends Component {

  componentWillMount() {
    this.props.dispatch( loadArtist(this.props.artist.artist) )
  }

  handleSubmit = () => {
		const { form: { artist, initial } } = this.props
		this.props.dispatch( submitMentionForArtist(artist, initial) )
    this.props.toggleModal()
	}

  render() {
    const { handleSubmit, submitting, t } = this.props

    return (
      <MentionsForm
        handleSubmit={ this.handleSubmit }
        submitting={ submitting }
        t={ t } />
    )
  }
}

function mapStateToProps(state) {
	const { artist } = state
	const form = {
		submitting: isSubmitting('artist')(state),
		submitSucceeded: hasSubmitSucceeded('artist')(state),
		submitFailed: hasSubmitFailed('artist')(state),
		artist: getFormValues('artist')(state),
		initial: getFormInitialValues('artist')(state)
	}

	return { artist, form }
}

const translated = withNamespaces('artist')(EditMentions)
export default connect(mapStateToProps)(translated)
