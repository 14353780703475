import React from "react";
import { Link } from "react-router";
import moment from "moment";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const ShowsList = ({
	shows,
	gallery,
	isAdmin,
	isArtist,
	myShowsOnly,
	relatedIds = [],
	toggleRelated,
	maxItems,
	t,
}) => {
	const hasProp = (object, key) => {
		if (object) {
			return object.hasOwnProperty(key);
		} else {
			return false;
		}
	};

	const getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	const thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	const rows = [];
	Object.keys(shows).map((key) => {
		const show = shows[key];

		// const image = show.featuredImage
		// ? ((show.featuredImage.thumbnails && show.featuredImage.thumbnails['100x100']) ? show.featuredImage.thumbnails['100x100'].src : show.featuredImage.src)
		// : null
		const image = show.featuredImage || "";
		const thumbnailSrc = image ? getImageSrc(image) : "";
		const thumbnailImg = (
			<img
				src={thumbnailSrc}
				className="table-image"
				onError={(e) => thumbnailError(e, e.target.src, image.src)}
				alt="Show image"
			/>
		);
		const divOrLinkImage =
			!isArtist || myShowsOnly ? (
				<Link to={`/shows/${show.slug}`}>{thumbnailImg}</Link>
			) : (
				<div>{thumbnailImg}</div>
			);
		const createdBy = hasProp(show.created_by, "name")
			? show.created_by.role === "admin"
				? "Artworks"
				: show.created_by.name
			: "N/A";
		// Only ised by admin.
		const galleryName =
			show.gallery && show.gallery.name ? show.gallery.name : "";
		const published = show.status === "published" ? true : false;
		const checked = relatedIds.includes(show._id);

		rows.push(
			<Tr key={show._id}>
				<Td column="checkbox" style={{ padding: "0px", width: "70px" }}>
					<input
						type="checkbox"
						className={"checkbox"}
						onChange={() => toggleRelated(show)}
						checked={checked}
						disabled={false}
					/>
				</Td>
				<Td column="image" style={{ padding: "0px", width: "70px" }}>
					{image ? <div>{divOrLinkImage}</div> : null}
				</Td>
				<Td column="title" value={show.title}>
					{!isArtist || myShowsOnly ? (
						<Link to={`/shows/${show.slug}`}>{show.title}</Link>
					) : (
						<div>{show.title}</div>
					)}
				</Td>
				<Td column="name" value={show.name}>
					{!isArtist || myShowsOnly ? (
						<Link to={`/shows/${show.slug}`}>{show.name}</Link>
					) : (
						<div>{show.name}</div>
					)}
				</Td>
				<Td column="owner" value={galleryName}>
					<Link to={`/shows/${show.slug}`}>{galleryName}</Link>
				</Td>
				<Td column="visits" value={show.visits}>
					{!isArtist || myShowsOnly ? (
						<Link to={`/shows/${show.slug}`}>{show.visits}</Link>
					) : (
						<div>{show.visits}</div>
					)}
				</Td>
				<Td column="startDate" value={show.startDate}>
					{!isArtist || myShowsOnly ? (
						<Link to={`/shows/${show.slug}`}>
							{DateUtil.dateMonthShort(show.startDate, i18next.language, true)}
						</Link>
					) : (
						<div>
							{DateUtil.dateMonthShort(show.startDate, i18next.language, true)}
						</div>
					)}
				</Td>
				<Td column="endDate" value={show.endDate}>
					{!isArtist || myShowsOnly ? (
						<Link to={`/shows/${show.slug}`}>
							{DateUtil.dateMonthShort(show.endDate, i18next.language, true)}
						</Link>
					) : (
						<div>
							{DateUtil.dateMonthShort(show.endDate, i18next.language, true)}
						</div>
					)}
				</Td>
				<Td column="createdBy" value={createdBy}>
					<Link to={`/shows/${show.slug}`}>{createdBy}</Link>
				</Td>
				<Td column="createdAt" value={show.created_at}>
					<Link to={`/shows/${show.slug}`}>
						{DateUtil.dateMonthShort(show.created_at, i18next.language, true)}
					</Link>
				</Td>
				<Td column="status" value={show.status}>
					{!isArtist || myShowsOnly ? (
						<Link
							to={`/shows/${show.slug}`}
							className={
								"reactable__state" + (published ? " is-published" : "")
							}
						>
							{t(`common:status.${show.status}`)}
						</Link>
					) : (
						<div
							className={
								"reactable__state" + (published ? " is-published" : "")
							}
						>
							{t(`common:status.${show.status}`)}
						</div>
					)}
				</Td>
			</Tr>
		);
	});

	return (
		<Table
			className="reactable"
			sortable={[
				"title",
				"name",
				"startDate",
				"endDate",
				"createdBy",
				"createdAt",
				"status",
				"owner",
			]}
			filterable={
				isAdmin ? ["title", "name", "createdBy", "status", "owner"] : false
			}
			filterPlaceholder={t("list.searchShow")}
			defaultSort={"startDate"}
			defaultSortDescending={true}
			itemsPerPage={maxItems}
			pageButtonLimit={10}
		>
			<Thead>
				{isArtist && !myShowsOnly && (
					<Th column="checkbox">{t("list.checkbox")}</Th>
				)}
				<Th column="image"> </Th>
				<Th column="title">{t("list.title")}</Th>
				<Th column="name">{t("list.artist")}</Th>
				{isAdmin && <Th column="owner">{t("list.organizer")}</Th>}
				<Th column="visits">{t("list.visits")}</Th>
				<Th column="startDate">{t("common:startDate")}</Th>
				<Th column="endDate">{t("common:endDate")}</Th>
				{isAdmin && <Th column="createdBy">{t("common:createdBy")}</Th>}
				{isAdmin && <Th column="createdAt">{t("common:createdAt")}</Th>}
				<Th column="status">Status</Th>
			</Thead>
			{rows}
		</Table>
	);
};

export default ShowsList;
