import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	Field,
	Fields,
	FormSection,
	reduxForm,
	formValueSelector,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import ArtworkPicker from "../../components/FormComponents/ArtworkPicker/ArtworkPicker";
// import ArtworkPicker        from '../../components/ArtworkPicker/ArtworkPicker'
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import SingleDateTimePicker from "../../components/FormComponents/DateRange/SingleDateTimePicker";
import PublishStatus from "../../components/FormComponents/PublishStatus/PublishStatus";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextInput from "../../components/FormInput/TextInput";
import TextArea from "../../components/FormInput/TextArea";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import Select from "../../components/FormInput/Select";
import CheckBox from "../../components/FormInput/CheckBox";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import Tooltip from "../../components/Tooltip/Tooltip";
import { imageRotationActivated } from "../../utils/Helpers";
import { validateEmbed } from "../../utils/Validators";
import { videoTooltip } from "../../utils/values";
import { showPremiumUpgradeModal } from "../../utils/Dispatcher";
import { noEmailCheckbox } from "../../utils/languageUtil";
import { showCustomModal } from "../../actions/AppActions";
import {
	hasAccessToFeature,
	AUTOMATIC_REMINDERS_FEATURE,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class RaffleForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	showProModal = () => {
		if (this.props.isSociety) {
			return showPremiumUpgradeModal(this.props.dispatch, "Premium");
		}

		const content = (
			<div>
				<p>
					Här kan du som <i>Premium</i> ladda upp en videofil.
				</p>
				<p>
					Läs mer och testa <i>Premium</i> gratis{" "}
					<a href="https://artworks.se/konstforening" target="_blank">
						här
					</a>
					! 🎨
				</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	premiumOnly = (evt) => {
		const { t } = this.props;
		evt.preventDefault();

		showPremiumUpgradeModal(this.props.dispatch, "Premium");
	};

	render() {
		const {
			isNewForm,
			handleSubmit,
			pristine,
			submitting,
			isAdmin,
			isSociety,
			drawDateHidden,
			isPremium,
			raffleVideo,
			gallery,
			t,
		} = this.props;
		const { expanded } = this.state;
		const expandButtonStyle = {
			position: "absolute",
			right: "40px",
			top: 0,
		};

		const useUploadWithRotate = imageRotationActivated();
		const hasAutomaticReminderAccess = hasAccessToFeature(
			gallery,
			AUTOMATIC_REMINDERS_FEATURE
		);

		return (
			<form onSubmit={handleSubmit} className="Form">
				<div className="fieldset">
					{isAdmin && (
						<div className="input-holder input-holder--full">
							<legend className="legend-nomargin">{t("common:venue")}</legend>
							<Field
								name="gallery"
								component={GalleryPicker}
								raffle={true}
								required
							/>
						</div>
					)}
					<legend>{t("common:title")}</legend>
					<div className="input-holder">
						<Field
							name="title"
							component={TextInput}
							placeholder={t("single.name")}
							required
						/>
					</div>
					<div className="fieldset-block">
						<legend className="legend-nomargin">{t("single.drawDate")}</legend>
						<Field
							name="drawDate"
							component={SingleDateTimePicker}
							disabled={drawDateHidden}
						/>
						<div className="fieldset">
							<Field
								name="drawDateHidden"
								component={CheckBox}
								checkboxLabel={t("single.hideDrawDate")}
							/>
						</div>
					</div>
					<legend>{t("single.about")}</legend>
					<div className="input-holder input-holder--full">
						<Field
							name="about"
							component={TextArea}
							placeholder={t("single.aboutPlaceholder")}
						/>
					</div>
					<div className="input-holder input-holder--full input-holder--nopadding">
						<legend>{t("single.featuredImage")}</legend>
						<Fields
							names={[
								"featuredImage.src",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
							profileImage={true}
							canRemoveImage={true}
						/>
						{isAdmin && (
							<Field name="featuredImage.color" component={ColorPicker} />
						)}
					</div>

					<div className="fieldset">
						<div className="input-holder input-holder--flex">
							<legend className="legend-nomargin">
								{t("common:uploadVideo")}
							</legend>
							<Tooltip
								data={videoTooltip()}
								style={{ left: "auto", right: "30px" }}
							/>
							<Field
								name="videoLink"
								component={TextInput}
								placeholder={t("common:youtubeExamle")}
								validate={validateEmbed}
								disabled={!isPremium && !isAdmin}
								green
								video
							/>
							{!isPremium && !isAdmin && (
								<div
									className="block-div"
									onClick={() => this.showProModal()}
								></div>
							)}
						</div>
						{raffleVideo && (isPremium || isAdmin) && (
							<div className="videoContainer">
								<iframe
									width="440px"
									height="247px"
									src={raffleVideo}
									frameBorder="0"
								/>
							</div>
						)}
					</div>

					{/*
            ArtworkPicker replaced with ArtworksListAndUpload in Raffle.jsx.
            Keeping ArtworkPicker here for now, just in case.
          */}
					{/*<div className="fieldset-block">
            <Field
              name="artworks"
              component={ ArtworkPicker }
              defaultValue={[]}
              header={t('single.selectArtworks')}
              upload={ true }
              onlyForSale={ false }
              addOnUpload={ true }
            />
          </div>
          */}
					{(isSociety || isAdmin) && (
						<div className="input-holder input-holder--full">
							<Field name="publish_status" component={PublishStatus} />
						</div>
					)}
					{isPremium || hasAutomaticReminderAccess ? (
						<div className="fieldset-block">
							<Field
								name="noAutomaticEmails"
								component={CheckBox}
								checkboxLabel={noEmailCheckbox(
									i18next.language === "sv" ? "utlottningen" : "raffle"
								)}
							/>
						</div>
					) : (
						<div
							className="fieldset-block"
							style={{ opacity: 0.5 }}
							onClick={this.premiumOnly}
						>
							{/* pointerEvents: none, or onClick on parent element won't be triggered. */}
							<input
								id="placeholder-checkbox"
								type="checkbox"
								checked
								disabled
								style={{ pointerEvents: "none" }}
							/>
							<label htmlFor="placeholder-checkbox">
								{noEmailCheckbox(
									i18next.language === "sv" ? "utlottningen" : "raffle"
								)}
							</label>
						</div>
					)}
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="slug"
								itemType="raffle"
								component={TextDisplay}
								isAdmin={isAdmin}
							/>
						</div>
						<div className="fixed-save-button">
							<button
								className="purple-button--wide"
								type="submit"
								disabled={pristine || submitting}
							>
								{t("common:save")}
							</button>
						</div>
					</div>
					{isAdmin && (
						<div className="fieldset" style={{ marginTop: "30px" }}>
							<legend>Last email sent (admin only)</legend>
							<div className="input-holder">
								<Field name="emailsSentDate" component={TextInput} />
							</div>
						</div>
					)}
				</div>
			</form>
		);
	}
}

RaffleForm = reduxForm({
	form: "raffle",
})(RaffleForm);

const selector = formValueSelector("raffle");
const translated = withNamespaces("raffle")(RaffleForm);
export default connect((state) => {
	return {
		drawDateHidden: selector(state, "drawDateHidden"),
	};
})(translated);
