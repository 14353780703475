// Graph:
// https://www.react-google-charts.com/examples
// Props and options: https://www.react-google-charts.com/components/chart
// https://developers.google.com/chart/interactive/docs/gallery/linechart
// Other props for options:
// fontSize: '28',
// For horizontal axis, only display dates we have a value for (dateTicks).
// const dateTicks = data.map(d => new Date(d.date))
// legend: 'none',
// legend: {
//   textStyle: { color: 'purple' }
// },
// crosshair: { trigger: "both" },
// fontName: 'Helvetica Neue, Arial', // Works but get error: Not enough columns given to draw the requested chart
// curveType: 'function',
// If multiple curves:
// series: {
//   1: { curveType: 'function' },
// },
// Other props for axis:
// axisTitlesPosition: 'in', out/none
// hAxis: {
// title: isSwedish ? 'Vecka' : 'Week',
// format: 'MMM dd', // Date
// format: 'WW yyyy', // Month number
// viewWindow: { min: 0, max: 15 },
// ticks: dateTicks,
// gridlines: {
//   count: 0,
// }
// },

import React from "react";
import { Chart } from "react-google-charts";
import i18next from "i18next";
import moment from "moment";

class Graph extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	sortByDate = (a, b) => {
		if (a.time < b.time) return -1;
		if (a.time > b.time) return 1;
		return 0;
	};

	sortByWeek = (a, b) => {
		if (a.week < b.week) return -1;
		if (a.week > b.week) return 1;
		return 0;
	};

	findComparison = (date, stats) => {
		const compare = stats.find(
			(stat) => stat.time.slice(0, 10) === date.slice(0, 10)
		);
		return compare ? compare.visits : 0;
	};

	// If several stats has been created for the same week, merge them into one item.
	mergeStats = (stats) => {
		const weeks = {};
		stats.forEach((stat) => {
			// const dateFormat = 'WW/GGGG' // WW = Week number, G = ISO year
			const dateFormat = "WW";
			// Subtract three days, date is for last weeks visits. Should be run on sunday night.
			const week = moment(stat.time).subtract(3, "days").format(dateFormat);
			if (weeks[week]) {
				weeks[week].visits = weeks[week].visits + stat.visits;
			} else {
				weeks[week] = {
					week,
					visits: stat.visits,
				};
			}
		});

		return weeks;
	};

	render() {
		const { galleryStats, galleryStatsCompare, t } = this.props;

		// If emtpy, error will be drawn. We don't want that.
		if (galleryStats.length < 2) return null;

		const hasComparison = galleryStatsCompare.length > 1;
		const isSwedish = i18next.language === "sv";
		const headers = isSwedish
			? ["Vecka", "Din förening"]
			: ["Week", "Your art society"];
		if (hasComparison) {
			headers.push(
				isSwedish
					? "Den mest besökta föreningen"
					: "Our most visited art society"
			);
		}

		const mergedStats = this.mergeStats(galleryStats);
		const mergedCompareStats = this.mergeStats(galleryStatsCompare);

		let values = Object.keys(mergedStats).map((key) => {
			const stat = mergedStats[key];
			return hasComparison
				? [
						stat.week,
						stat.visits,
						mergedCompareStats[stat.week]
							? mergedCompareStats[stat.week].visits
							: 0,
				  ]
				: [stat.week, stat.visits];
		});

		values.sort(this.sortByWeek);
		values.unshift(headers);

		const options = {
			// title: t('society.graph.visitsPerWeek'),
			backgroundColor: "white",
			titleTextStyle: {
				fontSize: "18",
			},

			legend: {
				position: "top",
			},
			chartArea: {
				// Not too wide or left axis numbers get cut off.
				// Also leave some space for axis title.
				width: "80%",
				// height: '70%',
			},
			hAxis: {
				title: isSwedish ? "Vecka" : "Week",
			},
			vAxis: {
				title: isSwedish ? "Besökare" : "Visits",
				format: "0", // If only two items, axis could show with decimals, ex. 10.0
				viewWindow: { min: 0 },
			},
			series: {
				0: { lineWidth: 2, color: "blue" }, // Us, pointSize: 5
				1: { lineWidth: 2, color: "red", lineDashStyle: [5, 5] }, // Them
			},
		};

		return (
			<div className="graph-google-charts">
				<h4>{t("society.graph.visitsPerWeek")}</h4>
				<Chart
					chartType="LineChart"
					data={values}
					options={options}
					width="100%"
					height="400px"
					legendToggle
				/>
			</div>
		);
	}
}

export default Graph;
