import React, { useMemo, useState } from "react";
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	flexRender,
} from "@tanstack/react-table";

export function Thead({ children }) {
	return <thead className="reactable-header">{children}</thead>;
}

export function Th({ column, children }) {
	return (
		<th className="reactable-header-sortable" data-column={column}>
			{children}
		</th>
	);
}

export function Tr({ children }) {
	return <tr className="reactable-row">{children}</tr>;
}

export function Td({ column, children }) {
	return (
		<td className="reactable-cell" data-column={column}>
			{children}
		</td>
	);
}

export function Table({
	className,
	children,
	itemsPerPage = 500,
	sortable = [],
	filterable = [],
	filterPlaceholder = "",
	filterBy = "",
	onFilter,
	onSort,
	defaultSort,
	defaultSortDescending = false,
	previousPageLabel = "<",
	nextPageLabel = ">",
}) {
	const tableClass = className ? `reactable ${className}` : "reactable";

	const childrenArray = React.Children.toArray(children);
	const thead = childrenArray.find((c) => c.type === Thead);
	const rows = childrenArray.filter((c) => c.type === Tr);

	if (!thead || rows.length === 0) {
		return null;
	}

	const [sorting, setSorting] = useState(
		defaultSort ? [{ id: defaultSort, desc: defaultSortDescending }] : []
	);
	const [pageIndex, setPageIndex] = useState(0);
	const [globalFilter, setGlobalFilter] = useState(filterBy || "");

	/** Extract sorting function from `sortable[]` */
	const getSortingFunction = (colId) => {
		const foundSort = sortable.find((s) => s.column === colId);
		if (!foundSort) return undefined;

		return (rowA, rowB) => {
			const a = rowA.getValue(colId);
			const b = rowB.getValue(colId);

			const extractSortableValue = (val) => {
				if (val === null || val === undefined) return "";

				if (typeof val === "symbol") return ""; // ✅ Avoid crashing on Symbols

				if (typeof val === "object") {
					// ✅ Filter out Symbols before joining
					return (
						val.props?.value ||
						Object.values(val)
							.filter((v) => typeof v !== "symbol") // Remove Symbols
							.join(" ")
					);
				}

				return String(val);
			};

			const aVal = extractSortableValue(a);
			const bVal = extractSortableValue(b);

			return foundSort.sortFunction(aVal, bVal);
		};
	};

	/** Extract columns from <Thead><Th column="..."/> */
	const columns = useMemo(() => {
		return React.Children.toArray(thead.props.children)
			.filter((th) => th && React.isValidElement(th) && th.props?.column)
			.map((th) => {
				const sortFn = getSortingFunction(th.props.column);
				return {
					accessorKey: th.props.column,
					header: th.props.children,
					enableSorting: Boolean(sortFn),
					sortingFn: sortFn,
				};
			});
	}, [thead.props.children, sortable]);

	/** Convert <Tr><Td column="..."/> to row data */
	const data = useMemo(() => {
		return rows.map((tr) => {
			const rowData = {};
			React.Children.forEach(tr.props.children, (td) => {
				if (!td || !td.props?.column) return;
				const colName = td.props.column;
				rowData[colName] =
					typeof td.props.children === "string"
						? td.props.children.trim()
						: td.props.children || "";
			});
			return rowData;
		});
	}, [rows]);

	const enablePagination = data.length > itemsPerPage;

	// Initialize table
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			globalFilter,
			pagination: {
				pageIndex,
				pageSize: itemsPerPage || 500,
			},
		},
		onSortingChange: setSorting,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		...(enablePagination && {
			getPaginationRowModel: getPaginationRowModel(),
		}),
	});

	// 🔥 Log individual rows
	// if (table.getRowModel().rows.length === 0) {
	// 	console.error("🚨 NO ROWS FOUND IN TABLE! CHECK IF DATA IS REJECTED!");
	// 	console.log("🔎 Data Sent to Table:", data);
	// 	console.log("🔎 Columns Sent to Table:", columns);
	// 	console.log(
	// 		"🔎 Data Keys:",
	// 		data.length > 0 ? Object.keys(data[0]) : "NO DATA"
	// 	);
	// }

	// Log each row to see if they are formatted incorrectly
	// table.getRowModel().rows.forEach((row, index) => {
	// 	console.log(`🔎 Row ${index}:`, row);
	// });

	// Log how rows are processed
	// const coreRows = getCoreRowModel()(table);
	// console.log("🔎 Core Row Model:", coreRows);

	/** Handle sorting */
	const handleSortClick = (header) => {
		if (header.column.getCanSort()) {
			header.column.toggleSorting();
			if (onSort) {
				onSort({
					column: header.column.id,
					direction: header.column.getIsSorted(),
				});
			}
		}
	};

	/** Pagination controls */
	const goPrev = () => setPageIndex((old) => Math.max(old - 1, 0));
	const goNext = () =>
		setPageIndex((old) => Math.min(old + 1, table.getPageCount() - 1));

	return (
		<table className={tableClass}>
			<thead className="reactable-header">
				<tr>
					<th
						colSpan={table.getAllColumns().length}
						style={{ textAlign: "left" }}
					>
						<input
							placeholder={filterPlaceholder || "Search..."}
							value={globalFilter || ""}
							onChange={(e) => {
								const val = e.target.value;
								table.setGlobalFilter(val);
								if (onFilter) {
									onFilter(val);
								}
							}}
							disabled
						/>
					</th>
				</tr>

				{table.getHeaderGroups().map((hg) => (
					<tr key={hg.id}>
						{hg.headers.map((header) => {
							const sorted = header.column.getIsSorted();
							return (
								<th
									key={header.id}
									className={
										"reactable-header-sortable " +
										(sorted === "asc"
											? "reactable-header-sort-asc"
											: sorted === "desc"
											? "reactable-header-sort-desc"
											: "")
									}
									onClick={() => handleSortClick(header)}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</th>
							);
						})}
					</tr>
				))}
			</thead>

			<tbody className="reactable-data">
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id} className="reactable-row">
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id} className="reactable-cell">
								{cell.getValue() instanceof Date
									? cell.getValue().toLocaleDateString()
									: cell.getValue() || ""}
							</td>
						))}
					</tr>
				))}
			</tbody>

			{enablePagination && (
				<tfoot className="reactable-pagination">
					<tr>
						<td colSpan={table.getAllColumns().length}>
							<div className="pagination-controls">
								<button onClick={goPrev} disabled={!table.getCanPreviousPage()}>
									{previousPageLabel}
								</button>

								<span>
									Sida {table.getState().pagination.pageIndex + 1} av{" "}
									{table.getPageCount()}
								</span>

								<button onClick={goNext} disabled={!table.getCanNextPage()}>
									{nextPageLabel}
								</button>
							</div>
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	);
}
