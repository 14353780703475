import {
  ANNOUNCEMENTS_REQUEST, ANNOUNCEMENTS_SUCCESS, ANNOUNCEMENTS_FAILURE,
  ANNOUNCEMENT_REQUEST, ANNOUNCEMENT_SUCCESS, ANNOUNCEMENT_FAILURE,
  SAVE_ANNOUNCEMENT_REQUEST, SAVE_ANNOUNCEMENT_SUCCESS, SAVE_ANNOUNCEMENT_FAILURE,
  DELETE_ANNOUNCEMENT_REQUEST, DELETE_ANNOUNCEMENT_SUCCESS, DELETE_ANNOUNCEMENT_FAILURE,
  RESET_ANNOUNCEMENT,
} from '../actions/AnnouncementActions'

export function announcementReducer(state = {
    isFetching: false,
    announcements: [],
    announcement: {},
  }, action) {
  switch(action.type) {
    case DELETE_ANNOUNCEMENT_REQUEST:
    case ANNOUNCEMENTS_REQUEST:
    case ANNOUNCEMENT_REQUEST:
    case SAVE_ANNOUNCEMENT_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case ANNOUNCEMENTS_SUCCESS: {
      const announcements = action.response
      return Object.assign({}, state, {
        isFetching: false,
        announcements: announcements,
        error: null
      })
    }
    case ANNOUNCEMENT_SUCCESS:
    case SAVE_ANNOUNCEMENT_SUCCESS: {
      const announcement = action.response
      let announcements = Object.assign({}, state.announcements)
      announcements[announcement._id] = announcement
      return Object.assign({}, state, {
        isFetching: false,
        announcements: announcements,
        announcement: announcement
      })
    }
    case DELETE_ANNOUNCEMENT_SUCCESS: {
      const id = action.response
      let announcements = state.announcements.slice()
      const index = announcements.findIndex(announcement => announcement._id === id)
      announcements.splice(index, 1)
      return Object.assign({}, state, {
        isFetching: false,
        announcements: announcements
      })
    }
    case ANNOUNCEMENT_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        announcement: {},
        error: action.error
      })
    }
    case DELETE_ANNOUNCEMENT_FAILURE:
    case ANNOUNCEMENTS_FAILURE:
    case SAVE_ANNOUNCEMENT_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    }
    case RESET_ANNOUNCEMENT:
      return Object.assign({}, state, {
        announcement: {}
      })
    default:
      return state
  }
}
