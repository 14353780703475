import {
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR,
}                         from '../../actions/FormActions/SelectionFormActions'

export function selectionFormReducer(state = {
  showSuccess: false,
  showError: false,
  error: null
}, action) {
  switch (action.type) {
    case SHOW_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case SHOW_ERROR:
      return Object.assign({}, state, {
        showError: true,
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null,
      })
    default:
      return state
  }
}
