import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { Table, Tr, Td, Thead, Th } from "../../../Table/Table";
import i18next from "i18next";
import moment from "moment";
import Main from "../../../Main/Main";
import MainResp from "../../../Main/MainResp";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import { capitalizeString } from "../../../../utils/FormatHelper";

class InspectDetails extends Component {
	//Render the pop-up header with name and current sub-level
	renderHeader = () => {
		const { item, t } = this.props;
		const { membershipLevel, cancelledSubscriptions } = item.billing;
		const level = membershipLevel === "premium" ? "Pro" : "Bas";

		return (
			<div className="InspectDetails__inspect__details">
				<p>
					<u>{t("inspectDetails.name")}</u>
					{" " + item.name}
				</p>
				<p>
					<u>{t("inspectDetails.membershipLevel")}</u>
					{" " + level}
				</p>
			</div>
		);
	};

	//Render table of previous subs
	renderSubTable = () => {
		const { item, t } = this.props;
		const rows = this.renderSubRows(item, t);

		return (
			<Table
				className="reactable"
				sortable={["start", "end", "plan", "status"]}
				defaultSort={{ column: "end", direction: "desc" }}
				filterable={false}
				itemsPerPage={5}
			>
				<Thead>
					<Th column="start">{t("inspectDetails.startDate")}</Th>
					<Th column="end">{t("inspectDetails.endDate")}</Th>
					<Th column="plan">{t("inspectDetails.plan")}</Th>
					<Th column="status">{t("inspectDetails.status")}</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	renderSubRows = (item, t) => {
		let rows = [];
		const cancelledKlarna =
			item.billing && item.billing.cancelledSubscriptions
				? item.billing.cancelledSubscriptions
				: [];
		const subs = cancelledKlarna.concat(item.stripeSubscriptions);

		subs.forEach((sub, i) => {
			//Klarna subsctiptions only store the cancelled date, therefore startDate
			//is unclear
			const startDate = sub.currentPeriodStartDate
				? DateUtil.dateMonthShort(sub.currentPeriodStartDate, "en", true)
				: "Undefined";
			const endDate = sub.currentPeriodEndDate
				? DateUtil.dateMonthShort(sub.currentPeriodEndDate, "en", true)
				: DateUtil.dateMonthShort(sub.date, "en", true);
			const planNic = sub.planNickname ? sub.planNickname : "Undefined";
			const status = sub.status ? capitalizeString(sub.status) : "Canceled";

			rows.push(
				<Tr key={i}>
					<Td column="start" value={startDate}>
						<p>{startDate}</p>
					</Td>
					<Td column="end" value={endDate}>
						<p>{endDate}</p>
					</Td>
					<Td column="plan" value={planNic}>
						<p>{planNic}</p>
					</Td>
					<Td column="status" value={status}>
						<p>{status}</p>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const header = this.renderHeader();
		const table = this.renderSubTable();

		return (
			<div className="InspectDetails">
				<div className="InspectDetails__container">
					<div
						className="InspectDetails__close"
						onClick={() => this.props.close()}
					/>
					<div className="InspectDetails__content inspect">
						{header}
						<div className="InspectDetails__inspect__subs">{table}</div>
					</div>
				</div>
			</div>
		);
	}
}

const translated = withNamespaces("components")(InspectDetails);
export default connect(null)(withRouter(translated));
