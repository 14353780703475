import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ExpandButton from "../ExpandButton/ExpandButton";
import {
	toggleSummary,
	fetchSummaryData,
} from "../../../actions/DashboardActions/SummaryActions";
import { formatNumber } from "../../../utils/Helpers";

const ARROW_UP = require("../../../../assets/images/svg/icon_arrow_up.svg");
const ARROW_DOWN = require("../../../../assets/images/svg/icon_arrow_down.svg");
const ARROW_RIGHT = require("../../../../assets/images/svg/arrow_right.svg");

const Arrow = ({ direction }) => {
	if (direction === "up")
		return (
			<ARROW_UP style={{ color: "red" }} height="30" width="30" alt="arrow" />
		);
	if (direction === "down")
		return (
			<ARROW_DOWN style={{ color: "red" }} height="30" width="30" alt="arrow" />
		);
	if (direction === "right")
		return (
			<ARROW_RIGHT
				style={{ color: "red" }}
				height="30"
				width="30"
				alt="arrow"
			/>
		);
	return null;
};

const DataPoint = ({ title, value, trend, arrow, t }) => {
	return (
		<div className="Summary__Data__DataEntry">
			<h4 className="artist">{title}</h4>
			<div className="Summary__Data__DataEntry__Sum">
				<p>{value}</p>
				<Arrow direction={arrow} />
			</div>
			{/*<p>{ trend }</p>*/}
		</div>
	);
};

const SummaryData = (props) => {
	return (
		<div className={props.class_name}>
			{props.data.map((datapoint, key) => {
				return <DataPoint {...datapoint} key={`Summary_${key}`} />;
			})}
		</div>
	);
};

class Summary extends Component {
	componentWillMount() {
		this.props.dispatch(fetchSummaryData());
	}

	updateData = (data) => {
		const { t } = this.props;
		const updatedData = data.map((datapoint, key) => {
			datapoint.title = t(datapoint.title);
			datapoint.value = formatNumber(datapoint.value);
			return datapoint;
		});
		return updatedData;
	};

	render() {
		const { expanded, data, role, t } = this.props;
		const updatedData = this.updateData(data);
		console.log("updatedData", updatedData);

		return (
			<div className="Summary">
				{role !== "artist" && (
					<div>
						<ExpandButton
							expanded={expanded}
							click={() => this.props.dispatch(toggleSummary())}
						/>
					</div>
				)}
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded ? (
							<SummaryData class_name="Summary__Data" data={updatedData} />
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		summary: { expanded, data },
	} = state;
	return { expanded, data };
}

export default connect(mapStateToProps)(Summary);
