import React from "react";

const SwitchButton = ({
	isOn,
	disabled,
	switched,
	isWaiting = false,
	markerOnly = false,
	styling = {},
}) => {
	let stateClass = isOn ? "is-on" : "is-off";
	if (isOn && isWaiting) stateClass += " is-waiting";
	if (disabled) stateClass += " is-disabled";
	// markerOnly, if button is not switchable, but ex. only opens a popup.

	return !markerOnly ? (
		<div
			className={`switch-button ${stateClass}`}
			style={styling}
			onClick={!disabled ? switched : null}
		>
			<div className={`switch-circle ${stateClass}`}></div>
		</div>
	) : (
		<div
			className={`switch-marker-container ${stateClass}`}
			style={styling}
			onClick={!disabled ? switched : null}
		>
			<div className={`switch-marker ${stateClass}`}></div>
		</div>
	);
};

const FormSwitchButton = (props) => {
	const {
		offLabel,
		waitingLabel,
		onLabel,
		disabled,
		isWaiting = false,
		checkedValue = true,
		uncheckedValue = false,
		styling = {},
	} = props;
	const checked = props.input.value === checkedValue ? true : false;
	let label = offLabel;
	let stateClass = checked ? "is-on" : "is-off";

	if (checked && isWaiting) {
		if (props.doLog) console.log("IS waiting");
		stateClass += " is-waiting";
		if (waitingLabel) label = waitingLabel;
	} else if (checked && onLabel) {
		label = onLabel;
	}

	return (
		<div className="switch-button-container" style={styling}>
			<div
				id="switch-button"
				className={`switch-button ${stateClass}`}
				onClick={(e) => {
					if (disabled) return;
					// Can use for true false values, but also ex. draft/published strings.
					props.input.onChange(checked ? uncheckedValue : checkedValue);
					props.input.onBlur(checked ? uncheckedValue : checkedValue);
				}}
			>
				<div className={`switch-circle ${stateClass}`}></div>
			</div>
			{label && <label htmlFor="switch-button">{label}</label>}
		</div>
	);
};

export default SwitchButton;

export { FormSwitchButton };
