import {
	ARTWORKS_REQUEST, ARTWORKS_FAILURE, ARTWORKS_SUCCESS,
	ARTWORKS_ALL_REQUEST, ARTWORKS_ALL_FAILURE, ARTWORKS_ALL_SUCCESS,
	ARTWORKS_ALL_POPULATE_REQUEST, ARTWORKS_ALL_POPULATE_SUCCESS, ARTWORKS_ALL_POPULATE_FAILURE,
	ARTWORK_REQUEST, ARTWORK_FAILURE, ARTWORK_SUCCESS,
	ARTWORK_SAVE_REQUEST, ARTWORK_SAVE_FAILURE, ARTWORK_SAVE_SUCCESS,
	ARTWORK_FROM_IMAGE_REQUEST, ARTWORK_FROM_IMAGE_FAILURE, ARTWORK_FROM_IMAGE_SUCCESS,
	ARTWORK_DELETE_REQUEST, ARTWORK_DELETE_FAILURE, ARTWORK_DELETE_SUCCESS,
	ARTWORK_MAIL_PREVIEW_REQUEST, ARTWORK_MAIL_PREVIEW_SUCCESS, ARTWORK_MAIL_PREVIEW_FAILURE, ARTWORK_MAIL_PREVIEW_RESET,
	CLEAR_ARTWORKS, ARTWORK_ORDER_REQUEST, ARTWORK_ORDER_SUCCESS, ARTWORK_ORDER_FAILURE, INDEX_ANIMATION_RESET,
} from '../actions/ArtworkActions'

export function artworkReducer(state = {
		isFetching: false,
		artworks: {},
		artwork: {},
		allArtworks: {},
		authenticated: false,
		saved: false,
		preview: '',
		wasSorted: false,
	}, action) {
	switch (action.type) {
		case ARTWORKS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				artworks: {},
				deletedArtwork: false
			})
		}
		case ARTWORKS_SUCCESS: {
			let artworks = {}
			action.response.forEach((artwork) => {
				artworks[artwork._id] = artwork
			})
			return Object.assign({}, state, {
				isFetching: false,
				artworks,
				error: null
			})
		}
		case ARTWORKS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case ARTWORKS_ALL_POPULATE_REQUEST:
		case ARTWORKS_ALL_REQUEST: {
			// Do not set as empty object. Should already be fetched when jumping between tabs. allArtworks is only for admin.
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case ARTWORKS_ALL_SUCCESS: {
			let artworks = {}
			action.response.forEach((artwork) => {
				artworks[artwork._id] = artwork
			})
			return Object.assign({}, state, {
				isFetching: false,
				allArtworks: artworks,
			})
		}
		case ARTWORKS_ALL_POPULATE_SUCCESS: {
			let artworks = Object.assign({}, state.allArtworks)
			action.response.map((artwork) => {
				artworks[artwork._id] = artwork
			})
			return Object.assign({}, state, {
				isFetching: false,
				allArtworks: artworks,
			})
		}
		case ARTWORKS_ALL_POPULATE_FAILURE:
		case ARTWORKS_ALL_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error,
			})
		}
		case ARTWORK_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				artwork: {}
			})
		}
		case ARTWORK_SUCCESS: {
			const artwork = action.response
			let artworks = Object.assign({}, state.artworks)
			artworks[artwork._id] = artwork
			return Object.assign({}, state, {
				isFetching: false,
				artworks: artworks,
				artwork: action.response
			})
		}
		case ARTWORK_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
				artwork: {}
			})
		}
		case ARTWORK_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				saved: false
			})
		}
		case ARTWORK_SAVE_SUCCESS: {
			const artwork = action.response
			let artworks = Object.assign({}, state.artworks)
			artworks[artwork._id] = artwork
			let allArtworks = Object.assign({}, state.allArtworks)
			allArtworks[artwork._id] = artwork
			return Object.assign({}, state, {
				isFetching: false,
				artwork,
				artworks,
				allArtworks,
				saved: true
			})
		}
		case ARTWORK_SAVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
				saved: false
			})
		}
		case ARTWORK_FROM_IMAGE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case ARTWORK_FROM_IMAGE_SUCCESS: {
			const artwork = action.response
			let artworks = Object.assign({}, state.artworks)
			let allArtworks = Object.assign({}, state.allArtworks)
			artworks[artwork._id] = artwork
			allArtworks[artwork._id] = artwork
			return Object.assign({}, state, {
				isFetching: false,
				artworks,
				allArtworks,
			})
		}
		case ARTWORK_FROM_IMAGE_FAILURE: {
			// Error message not displayed in error modal.
			// Shown as failCount in ArtworksListAndUpload.
			return Object.assign({}, state, {
				isFetching: false,
			})
		}
		case ARTWORK_DELETE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				error: null,
				deletedArtwork: true
			})
		}
		case ARTWORK_DELETE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case ARTWORK_DELETE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case CLEAR_ARTWORKS: {
			return Object.assign({}, state, {
				artworks: {},
				artwork: {},
				allArtworks: {},
			})
		}
		case ARTWORK_MAIL_PREVIEW_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
				preview: '',
				error: null,
      })
    }
    case ARTWORK_MAIL_PREVIEW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				preview: action.response,
			})
		}
		case ARTWORK_MAIL_PREVIEW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'artworkMailPreviewError',
			})
		}
    case ARTWORK_MAIL_PREVIEW_RESET: {
			return Object.assign({}, state, {
				preview: '',
			})
		}
		case ARTWORK_ORDER_REQUEST: {
			return Object.assign({}, state, {
				wasSorted: false,
				isFetching: true,
				error: null,
			})
		}
		case ARTWORK_ORDER_SUCCESS: {
			let artworks = Object.assign({}, state.artworks)
			action.response.map((artwork) => {
				if (artworks[artwork._id] && artworks[artwork._id].index !== artwork.index) {
					artwork['animated'] = true
				} else {
					artwork['animated'] = false
				}
				artworks[artwork._id] = artwork
			})
			return Object.assign({}, state, {
				isFetching: false,
				artworks: artworks,
				wasSorted: true,
			})
		}
		case ARTWORK_ORDER_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error
			})
		}
		case INDEX_ANIMATION_RESET: {
			let artworks = Object.assign({}, state.artworks)
			Object.keys(artworks).forEach(key => {
				artworks[key]['animated'] = false
			})
			return Object.assign({}, state, {
				artworks: artworks,
				wasSorted: false,
			})
		}
		default:
			return state
	}
}
