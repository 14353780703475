import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitArtist,
	loadArtist,
	showSuccess,
	hideSuccess,
	hideError,
} from "../../../actions/FormActions/ArtistFormActions";
import {
	fetchArtist,
	fetchAllArtistsFast,
} from "../../../actions/ArtistActions";
import Main from "../../../components/Main/Main";
import MainResp from "../../../components/Main/MainResp";
import SelectionPicker from "../../../components/FormComponents/SelectionPicker/SelectionPicker";
import ArtistSettingsForm from "./ArtistSettingsForm";
import ArtistSettingsFormResp from "./ArtistSettingsFormResp";

class ArtistSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			selectedOption: null,
		};
	}

	componentDidMount() {
		const {
			user: { artist },
		} = this.props;
		//Redirect all artists as page is no longer in use
		this.props.router.push("/artists/me");

		const artistId = artist ? artist : false;
		if (artistId) this.props.dispatch(fetchArtist(artistId));
	}

	componentWillReceiveProps(nextProps) {
		const {
			artist: { artist, allArtists },
		} = this.props;

		//Fetch / load artist if changed
		if (artist !== nextProps.artist.artist) {
			this.props.dispatch(loadArtist(nextProps.artist.artist));
		}

		//Handle options for admin dropdown
		const artistKeys = Object.keys(allArtists);
		let artistOptions = this.state.artistOptions;
		let updateOptions = false;

		//Update options if more are retrieved
		if (artistOptions.length !== artistKeys.length) {
			artistOptions = [];
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				artistOptions.push({
					value: artist._id,
					label: artist.name,
					type: "artist",
				});
			});
			updateOptions = true;
		}

		//Update state if changed
		if (updateOptions) {
			this.setState({
				artistOptions,
			});
		}
	}

	//Updates state selected option and fetches
	selectOption = (option) => {
		this.setState({
			selectedOption: option,
		});
		this.props.dispatch(fetchArtist(option.value));
	};

	//Fetch artists depending on updated input
	inputChanged = (input) => {
		// Only fetch when exact numbers of letters entered.
		if (input.length === 2) {
			this.props.dispatch(fetchAllArtistsFast(input));
		}
	};

	//Submits form
	handleSubmit = () => {
		const {
			form: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial, {}));
	};

	drawAdminSelector = () => {
		const { isAdmin, artist, t } = this.props;
		const { artistOptions, selectedOption } = this.state;
		if (!isAdmin) return null;

		const style = appGlobals.useResponsive ? { padding: "0" } : {};

		return (
			<div className="input-holder input-holder--full" style={style}>
				<Select
					value={selectedOption}
					options={artistOptions}
					onChange={this.selectOption}
					onInputChange={this.inputChanged}
					valueKey={"artist"}
					labelKey="label"
					backspaceToRemoveMessage=""
					backspaceRemoves={false}
					isLoading={artist.isFetching}
					placeholder={t("artworks:list.artist")}
					multi={false}
					autosize={false}
					className={"artist-picker-select"}
					isSearchable={true}
				/>
			</div>
		);
	};

	render() {
		const { user, isAdmin, isArtist, artist, form, t } = this.props;
		const { selectedOption } = this.state;

		const adminSelector = this.drawAdminSelector();
		const displayForm = isAdmin ? artist.artist._id && selectedOption : true;

		return (
			<div>
				{appGlobals.useResponsive ? (
					<MainResp contentClassName="slim-content">
						{adminSelector}
						{displayForm && (
							<ArtistSettingsFormResp
								onSubmit={this.handleSubmit}
								isAdmin={isAdmin}
								isArtist={isArtist}
							/>
						)}
					</MainResp>
				) : (
					<Main contentClassName="slim-content">
						{adminSelector}
						{displayForm && (
							<ArtistSettingsForm
								onSubmit={this.handleSubmit}
								isAdmin={isAdmin}
							/>
						)}
					</Main>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
		artist,
	} = state;

	const isArtist = user.role === "artist";
	const form = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return { user, isAdmin, isArtist, artist, form };
}

const translated = withNamespaces("artist")(ArtistSettings);
export default connect(mapStateToProps)(withRouter(translated));
