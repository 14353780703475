import React from "react";
import moment from "moment";
class Metrics extends React.Component {
	static propTypes = {};
	constructor(props) {
		super(props);
		this.state = {
			metrics: {},
		};
	}
	componentWillReceiveProps(newProps) {
		if (newProps.metrics) {
			this.setState({
				metrics: newProps.metrics,
			});
		}
	}
	render() {
		const { metrics } = this.state;
		let galleries = [];
		let artworks = [];
		let artworksList = [];
		Object.keys(metrics).forEach((key) => {
			let gallery = metrics[key];
			Object.keys(gallery.shows).forEach((key) => {
				let show = gallery.shows[key];
				Object.keys(show.artworks).forEach((key) => {
					let artwork = show.artworks[key];
					artworks.push(artwork);
				});
			});
			galleries.push(
				<div className="gallery" key={key}>
					<img src={gallery.img} style={{ height: "200px", width: "auto" }} />
					<div className="info">
						<p>{gallery.name}</p>
						<p>Likes in app: {gallery.likes}</p>
					</div>
				</div>
			);
		});
		artworks.sort((a, b) => {
			return b.likes - a.likes;
		});
		if (artworks.length > 3) {
			artworks = artworks.slice(0, 3);
		}

		Object.keys(artworks).forEach((key) => {
			let artwork = artworks[key];
			artworksList.push(
				<div className="Metrics artwork" key={key}>
					<div className="aw-image">
						<p>{artwork.title}</p>
						<img src={artwork.img} style={{ height: "200px", width: "auto" }} />
						<p>Likes in app: {artwork.likes}</p>
					</div>
				</div>
			);
		});
		console.log(artworks);
		return (
			<div>
				<div className="Metrics content">
					<h6>Your gallery</h6>
					{galleries}
				</div>
				<div className="Metrics content">
					<h6>Your top performing artworks</h6>
					<div className="Metrics artwork-container">{artworksList}</div>
				</div>
			</div>
		);
	}
}

export default Metrics;
