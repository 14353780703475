import React, { Component } from "react";
import PropTypes from "prop-types";

const defaultProps = {
	rows: 4,
	disabled: false,
};

const TextArea = (props) => {
	const green = props.green ? true : false;
	const marked = props.marked ? true : false;
	const required = props.required ? true : false;
	const empty = props.input.value === "";
	const valid = props.meta.valid && props.meta.touched && !empty ? true : false;
	const invalid =
		props.meta.invalid && props.meta.touched && !empty ? true : false;
	const prevValid = props.meta.valid && !props.meta.touched ? true : false;
	const extraClass = props.extraClass || "";
	const isResponsive = props.isResponsive || false;
	return (
		<div
			className={
				"Textarea " +
				(invalid ? " is-error" : "") +
				(valid ? " is-valid" : "") +
				(prevValid ? " is-prevValid" : "") +
				(required ? " is-required" : "") +
				(marked ? " is-marked" : "") +
				(empty ? " is-empty" : "") +
				(green ? " is-green" : "") +
				(isResponsive ? " is-responsive" : "") +
				extraClass
			}
		>
			<textarea
				{...props.input}
				rows={props.rows}
				placeholder={props.placeholder}
				disabled={props.disabled}
			/>
			{props.meta.invalid ? (
				<span className="Textarea__error">{props.meta.error}</span>
			) : null}
		</div>
	);
};

TextArea.propTypes = {
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	rows: PropTypes.number,
};
TextArea.defaultProps = defaultProps;

export default TextArea;
