import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import moment from "moment";
import { fetchArtSocieties } from "../../actions/GalleryActions";
import Main from "../../components/Main/Main";
import { thumbnailError } from "../../utils/Helpers";

class ArtSocieties extends React.Component {
	componentWillMount() {
		this.props.dispatch(fetchArtSocieties());
	}

	levelToTier = (level) => {
		// Same as in BillingUtil in sharedmodules.
		if (level === "premium") return 3;
		if (level === "standard") return 2;
		if (level === "basic") return 1;
		return 0;
	};

	defaultSort = (a, b) => {
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	};

	membershipLevelSort = (a, b) => {
		const aTier = this.levelToTier(a),
			bTier = this.levelToTier(b);
		if (aTier < bTier) return -1;
		if (aTier > bTier) return 1;
		return 0;
	};

	getSort = (value) => {
		return {
			column: value,
			sortFunction:
				value === "membershipLevel"
					? this.membershipLevelSort
					: this.defaultSort,
		};
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	// getUnlockedFeatures = (gallery) => {
	//   if (gallery && gallery.billing) {
	//
	//   }
	// }

	createRows = (galleries) => {
		let rows = [];

		Object.keys(galleries).forEach((key) => {
			let gallery = galleries[key];

			const image = gallery.featuredImage || "";
			const thumbnailSrc = image ? this.getImageSrc(image) : "";

			const level = gallery.billing ? gallery.billing.membershipLevel : "free";
			const renewal = gallery.billing ? gallery.billing.membershipEnds : "";

			rows.push(
				<Tr key={gallery._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{gallery.featuredImage ? (
							<Link to={"/galleries/" + gallery.slug}>
								<img
									src={thumbnailSrc}
									className="table-image"
									onError={(e) => thumbnailError(e, e.target.src, image.src)}
									alt="Gallery image"
								/>
							</Link>
						) : null}
					</Td>
					<Td column="name" value={gallery.name || "--"}>
						<Link to={"/galleries/" + gallery.slug}>
							{gallery.name || "--"}
						</Link>
					</Td>
					<Td column="membershipLevel" value={level}>
						<Link to={"/galleries/" + gallery.slug}>{level}</Link>
					</Td>
					<Td column="renewalDate" value={renewal}>
						<Link to={"/galleries/" + gallery.slug}>
							{renewal ? moment(renewal).format("MMM Do, YYYY") : "-"}
						</Link>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const { galleries, isAdmin } = this.props;
		if (!isAdmin) return null;

		let galleriesCount = Object.keys(galleries).length;
		const rows = this.createRows(galleries);
		// What we want to show:
		// Name of the society
		// Membershiplevel
		// When subscription is to be renewed
		const sorts = ["name", "membershipLevel", "renewalDate"].map((sortName) =>
			this.getSort(sortName)
		);

		return (
			<div className="Galleries">
				<Main>
					<div className="MainTop">
						<h1>Art societies ({galleriesCount})</h1>
						<Link
							to="/galleries/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							Create new venue
						</Link>
					</div>
					<Table
						className="reactable"
						sortable={sorts}
						defaultSort={{ column: "membershipLevel", direction: "desc" }}
						filterable={["name", "membershipLevel"]}
						filterPlaceholder={"Search art society"}
					>
						<Thead>
							<Th column="image">{""}</Th>
							<Th column="name">Name</Th>
							<Th column="membershipLevel">Membership</Th>
							<Th column="renewalDate">Renew</Th>
						</Thead>
						{rows}
					</Table>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		gallery: { galleries },
		auth: { user },
	} = state;
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";

	return { galleries, isAdmin };
}

export default connect(mapStateToProps)(ArtSocieties);
