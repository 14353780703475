import {
  PRINT_GET_REQUEST, PRINT_GET_SUCCESS, PRINTS_GET_SUCCESS, PRINT_GET_FAILURE,
  PRINT_SAVE_REQUEST, PRINT_SAVE_SUCCESS, PRINT_SAVE_FAILURE,
  PRINT_REMOVE_REQUEST, PRINT_REMOVE_SUCCESS, PRINT_REMOVE_FAILURE,
  PRINT_ARTIST_GET_REQUEST, PRINT_ARTIST_GET_SUCCESS, PRINT_ARTIST_GET_FAILURE,
} from '../actions/PrintActions'

export function printReducer(state = {
  print: {},
  allPrints: {},
  printArtists: {},
  isFetching: false,
  error: null,
}, action) {
  switch(action.type) {
    case PRINT_GET_REQUEST: {
      return Object.assign({}, state, {
        print: {},
        deletedPrint: false,
        isFetching: true,
				error: null,
      })
    }
    case PRINT_ARTIST_GET_REQUEST:
    case PRINT_SAVE_REQUEST:
    case PRINT_REMOVE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
				error: null,
      })
    }
    case PRINT_SAVE_SUCCESS:
    case PRINT_GET_SUCCESS: {
      let print = action.response
      let allPrints = Object.assign({}, state.allPrints)
      allPrints[print._id] = print
      return Object.assign({}, state, {
        print,
        allPrints,
        isFetching: false,
      });
		}
    case PRINTS_GET_SUCCESS: {
      let allPrints = {}
      action.response.map(print => {
        allPrints[print._id] = print
      })
      return Object.assign({}, state, {
        allPrints,
        isFetching: false,
      });
		}
    case PRINT_REMOVE_SUCCESS: {
      const { removedId } = action.response
      let allPrints = Object.assign({}, state.allPrints)
      delete allPrints[removedId]
      return Object.assign({}, state, {
        allPrints,
        print: {},
        deletedPrint: true,
				isFetching: false,
			})
    }
    case PRINT_ARTIST_GET_REQUEST:
    case PRINT_SAVE_FAILURE:
    case PRINT_REMOVE_FAILURE:
		case PRINT_GET_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			})
		}
    case PRINT_ARTIST_GET_SUCCESS: {
      let artists = {}
      action.response.map(artist => {
        artists[artist._id] = artist
      })
      return Object.assign({}, state, {
        printArtists: artists,
				isFetching: false,
			})
    }
    default:
      return state
  }
}
