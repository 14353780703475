import React from "react";

const Progressbar = ({ gallery, shows, artworks, pathname }) => {
	const progress =
		25 + Number(gallery) * 25 + Number(shows) * 25 + Number(artworks) * 25;

	let bgColor = "#8BD59B";
	let progressText = "";

	if (progress === 25) {
		progressText = "Nice, 25% done!";
	} else if (progress === 50) {
		progressText = "Good job, 50% filled out!";
	} else if (progress === 75) {
		progressText = "75% filled out, almost there!";
	} else if (progress === 100) {
		bgColor = "#8BD59B";
		progressText = "Congrats, all done! 😊";
	}

	const meterWidth = `${progress}%`;

	return (
		<div className="Progressbar">
			<div className="Progressbar__container">
				<div className="Progressbar__container__progress">
					<div
						className="Progressbar__container__progress__bar"
						style={{ backgroundColor: bgColor }}
					></div>
					<div
						className="Progressbar__container__progress__meter"
						style={{ backgroundColor: bgColor, width: meterWidth }}
					></div>
					<p
						className="Progressbar__container__progress__text"
						style={{ width: meterWidth }}
					>
						{progressText}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Progressbar;
