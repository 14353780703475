import {
	TOGGLE_EXPANDED, DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE
} from '../../actions/DashboardActions/VisitsActions'

export function visitsReducer(state = {
		isFetching: false,
		authenticated: false,
    totalVisits: 0,
		yearVisits: 0,
		avgVisits: 0,
		totalLikes: 0,
		shows: 0,
		enquiries: 0,
    influencers: [],
    expanded: true
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		case DATA_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			});
		case DATA_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				totalVisits: action.response.totalVisits,
				yearVisits: action.response.yearVisits,
				avgVisits: action.response.avgVisits,
				totalLikes: action.response.totalLikes,
				shows: action.response.shows,
				enquiries: action.response.enquiries,
				influencers: action.response.influencers
			});
		case DATA_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			});
		default:
			return state
	}
}
