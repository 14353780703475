import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	change,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitShow,
	loadShow,
	initShow,
} from "../../actions/FormActions/ShowFormActions";
import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
} from "../../actions/FormActions/ArtworkFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import {
	fetchScrapedShow,
	acceptScrapedShow,
} from "../../actions/ScrapedActions";
import ShowForm from "../Shows/ShowForm";
import ArtistForm from "../Artist/ArtistForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import Main from "../../components/Main/Main";
import { withRouter } from "react-router";
import { saveArtwork } from "../../actions/ArtworkActions";
import {
	getGalleryOptions,
	getArtistOptions,
} from "../../actions/PickersActions";
import { getObjectProp } from "../../utils/Helpers";
import Modal from "../../components/Modal/Modal";

class AddShow extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			artistOptions: [],
		};
	}
	componentDidMount() {
		const showId = this.props.params.showId;
		this.props.dispatch(fetchScrapedShow(showId));
		this.props.dispatch(initShow());
	}
	componentWillReceiveProps(newProps) {
		console.log(
			"this.props.scrapedShows !== newProps.scrapedShows",
			this.props.scrapedShows !== newProps.scrapedShows
		);
		const showId = this.props.params.showId;
		const show = newProps.scrapedShows[showId];
		console.log("!!show", !!show);
		if (this.props.scrapedShows !== newProps.scrapedShows && show) {
			console.log("loading");
			this.props.dispatch(loadShow(show));
			this.populateData(show);
		}
	}

	populateData = (show) => {
		this.props
			.dispatch(getGalleryOptions(show.galleryData.name))
			.then(({ response }) => {
				console.log("GALLERY", response);
				this.setState({
					galleryOptions: response,
				});
			});
		if (show.artist && show.artist.length > 0) {
			this.props
				.dispatch(getArtistOptions(show.artist))
				.then(({ response }) => {
					console.log("ARTIST", response);
					this.setState({
						artistOptions: response,
					});
				});
		}
	};

	setGallery = (gallery) => {
		this.props.dispatch(change("show", "gallery", gallery));
	};

	setArtist = (artist) => {
		this.props.dispatch(change("show", "artists", [artist]));
	};

	handleSubmit = () => {
		const showId = this.props.params.showId;
		let {
			form: { show },
		} = this.props;
		delete show._id;
		this.props.dispatch(acceptScrapedShow(showId));
		this.props.dispatch(submitShow(show, {}));
	};
	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};

	render() {
		const { showArtworkModal } = this.props;
		const { showArtistModal } = this.props;
		const {
			scrapedShows,
			params: { showId },
		} = this.props;
		const { galleryOptions, artistOptions } = this.state;
		const show = scrapedShows[showId] ? scrapedShows[showId] : {};

		const { isAdmin } = this.props;

		return (
			<div>
				<Main>
					<p>{`Gallery: ${
						show.galleryData && show.galleryData.name
							? show.galleryData.name
							: "N/A"
					}`}</p>
					{galleryOptions.map((gallery) => {
						return (
							<p key={gallery._id} onClick={() => this.setGallery(gallery)}>
								{gallery.name}
							</p>
						);
					})}
					<p>{`Artist: ${show.artist ? show.artist : "N/A"}`}</p>
					{artistOptions.map((artist) => {
						return (
							<p key={artist._id} onClick={() => this.setArtist(artist)}>
								{artist.name}
							</p>
						);
					})}
					<ShowForm onSubmit={this.handleSubmit} isAdmin={isAdmin} />
				</Main>
				{showArtworkModal ? (
					<Modal onCloseClick={this.toggleArtworksModal}>
						<ArtworkForm
							onSubmit={this.handleArtworkSubmit}
							isAdmin={isAdmin}
						/>
					</Modal>
				) : null}
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
						/>
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		scraped: { scrapedShows },
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		auth: { user, isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("show")(state),
		submitSucceeded: hasSubmitSucceeded("show")(state),
		submitFailed: hasSubmitFailed("show")(state),
		show: getFormValues("show")(state),
		initial: getFormInitialValues("show")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	return {
		scrapedShows,
		user,
		isAdmin,
		form,
		artworkForm,
		artistForm,
		showArtworkModal,
		showArtistModal,
	};
}

export default connect(mapStateToProps)(withRouter(AddShow));
