import { initialize, stopSubmit, startSubmit, change } from "redux-form";
import diff from "object-diff";
import { saveXmasCalendar } from "@artworkslab/sharedmodules/src/actions/XmasActions";

export function submitCalendarForm(data, initial, galleryId) {
	return (dispatch, getState) => {
		const { user } = getState().auth;
		const changedValues = initial ? diff(initial, data) : data;
		changedValues._id = data._id ? data._id : undefined;

		const body = { gallery: galleryId, data: changedValues };

		dispatch(startSubmit("xmascalendar"));
		return dispatch(saveXmasCalendar(body));
	};
}
