import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getObjectProp } from "../../utils/Helpers";

import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormInitialValues,
	getFormValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";

import {
	submitEvent,
	initEvent,
	showSuccess,
	hideSuccess,
	hideError,
} from "../../actions/FormActions/EventFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
	fetchArtistArtworks,
} from "../../actions/FormActions/ArtworkFormActions";

import EventForm from "./EventForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import ArtistForm from "../Artist/ArtistForm";

import Main from "../../components/Main/Main";
import Modal from "../../components/Modal/Modal";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";

class CreateEvent extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentWillReceiveProps(newProps) {
		if (this.props.user.gallery !== newProps.user.gallery) {
			const galleryId = getObjectProp(newProps, "user.gallery._id");
			if (galleryId) this.props.dispatch(fetchArtworks(galleryId));
			this.props.dispatch(initEvent());
		} else if (this.props.user.artist !== newProps.user.artist) {
			const artistId = getObjectProp(newProps, "user.artist._id");
			if (artistId) this.props.dispatch(fetchArtistArtworks(artistId));
			this.props.dispatch(initEvent());
		}

		// Redirect to events/slug after saving.
		if (this.props.form.submitting && !newProps.form.submitting) {
			if (newProps.form.event && newProps.form.event.slug) {
				this.props.router.push(`/events/${newProps.form.event.slug}`);
			}
		}
	}
	componentWillMount() {
		const galleryId =
			this.props.user.gallery && this.props.user.gallery._id
				? this.props.user.gallery._id
				: this.props.user.gallery;
		const artistId =
			this.props.user.artist && this.props.user.artist._id
				? this.props.user.artist._id
				: this.props.user.artist;
		if (galleryId) {
			this.props.dispatch(fetchArtworks(galleryId));
		} else if (artistId) {
			this.props.dispatch(fetchArtistArtworks(artistId));
		}
	}
	componentDidMount() {
		this.props.dispatch(initEvent());
	}

	handleSubmit = () => {
		const {
			form: { event, initial },
		} = this.props;

		if (event.rsvpMaxUsers) {
			const toNumber = parseInt(event.rsvpMaxUsers);
			if (typeof toNumber === "number") event.rsvpMaxUsers = toNumber;
			else event.rsvpMaxUsers = null;
		}
		if (event.vote && event.vote.voteLimit) {
			const toNumber = parseInt(event.vote.voteLimit);
			if (typeof toNumber === "number" && !isNaN(toNumber))
				event.vote.voteLimit = toNumber;
			else event.vote.voteLimit = 1;
		}

		this.props.dispatch(submitEvent(event, initial));
	};
	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};
	render() {
		const { showArtworkModal } = this.props;
		const { showArtistModal } = this.props;

		const {
			isAdmin,
			user: { role },
			isSociety,
			gallery,
			artist,
			form,
		} = this.props;
		const isArtist = role === "artist";
		const isArtSociety = isVenueArtSociety(gallery);

		const eventVideo =
			form.event && form.event.videoLink ? form.event.videoLink : null;

		return (
			<div>
				<Main>
					<EventForm
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isSociety={isSociety}
						gallery={gallery}
						artist={artist}
						isArtSociety={isArtSociety}
						eventVideo={eventVideo}
						isNew={true}
					/>
				</Main>
				{showArtworkModal ? (
					<Modal onCloseClick={this.toggleArtworksModal}>
						<ArtworkForm
							onSubmit={this.handleArtworkSubmit}
							isAdmin={isAdmin}
							isArtist={isArtist}
						/>
					</Modal>
				) : null}
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
							forArtSociety={gallery && gallery.venueType === "ArtSociety"}
						/>
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		event,
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		auth: { user },
		gallery: { gallery },
		artist: { artist },
	} = state;
	const isSociety = user.role === "society";
	const isAdmin = user.role === "admin";
	const form = {
		submitting: isSubmitting("event")(state),
		submitSucceeded: hasSubmitSucceeded("event")(state),
		submitFailed: hasSubmitFailed("event")(state),
		event: getFormValues("event")(state),
		initial: getFormInitialValues("event")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	return {
		event,
		user,
		isAdmin,
		isSociety,
		gallery,
		artist,
		form,
		artworkForm,
		artistForm,
		showArtworkModal,
		showArtistModal,
	};
}

export default connect(mapStateToProps)(withRouter(CreateEvent));
