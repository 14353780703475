import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import { Field } from "redux-form";
import TextInput from "../../FormInput/TextInput";
import CheckBox from "../../FormInput/CheckBox";

class LocationPicker extends Component {
	static propTypes = {
		placeholder: PropTypes.string,
	};
	static defaultProps = {
		selectedLocation: {
			locationName: "",
			location: {
				type: "Point",
				coordinates: [59.335909, 18.075466],
			},
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			autocompleteListener: null,
		};
	}

	componentDidMount() {
		// So page works locally when offline. MUST BE REMOVED AFTER OR LOCATIONPICKER WON'T WORK!!!
		// const google = google || false
		// if (!google) return

		const autocomplete = new google.maps.places.Autocomplete(
			this.refs.location
		);
		const autocompleteListener = google.maps.event.addListener(
			autocomplete,
			"place_changed",
			() => {
				let place = autocomplete.getPlace();
				if (!place.geometry) {
					alert("No location found.");
					return;
				} else {
					this.handleLocationSearch(place);
				}
			}
		);
		this.setState({
			autocompleteListener,
		});
	}
	componentWillUnmount() {
		google.maps.event.removeListener(this.state.autocompleteListener);
	}

	getName = (name, address_components) => {
		var city = false;
		for (var i = 0; i < address_components.length; i++) {
			for (var j = 0; j < address_components[i].types.length; j++) {
				if (address_components[i].types[j] === "postal_town") {
					city = address_components[i].long_name;
				}
			}
		}
		const placeName = city ? name + ", " + city : name;
		return placeName;
	};

	handleLocationSearch = (place) => {
		const lat = place.geometry.location.lat();
		const long = place.geometry.location.lng();
		//Set name
		if (place.name) {
			const locName = this.getName(place.name, place.address_components);
			this.props.locationName.input.onChange(locName);
		}
		//Set lat + long
		if (lat && long) {
			this.props.location.coordinates.input.onChange([lat, long]);
			this.props.location.type.input.onChange("Point");
		}
	};

	render() {
		const {
			location: { coordinates },
			locationName,
			mapSize = false,
			isArtist = false,
			isGallery = false,
			responsive = false,
			isShow = false,
			t,
		} = this.props;
		const errorMessage = locationName.meta.error || coordinates.meta.error;
		const green = this.props.green ? true : false;
		const marked = this.props.marked ? true : false;
		const required = this.props.required ? true : false;
		const empty = locationName.input.value === "" ? true : false;
		const coords = coordinates.input.value.length
			? `${coordinates.input.value[0]},${coordinates.input.value[1]}`
			: false;
		const mapStyle = mapSize ? mapSize : { width: 300, height: 300 };
		const inputStyle = isShow ? {} : { width: "100%", maxWidth: "100%" };

		let searchPlaceholder = "";
		if (isArtist) {
			searchPlaceholder = t("locationPicker.searchStudio");
		} else if (isGallery) {
			searchPlaceholder = t("locationPicker.searchGallery");
		} else {
			searchPlaceholder = t("locationPicker.search");
		}

		// Don't show second field on responsive, instead show location as placeholder in first field.
		if (responsive && locationName.meta.initial) {
			searchPlaceholder = locationName.meta.initial;
		}
		// Smaller space to items below needed when no map is shown.
		const style = coords ? { marginBottom: 0 } : {};

		return (
			<div className={responsive ? "fieldset-r" : "fieldset"} style={style}>
				<div
					className={
						"LocationPicker" +
						(errorMessage ? " is-error" : "") +
						(coords ? " is-valid" : "") +
						(required ? " is-required" : "")
					}
				>
					<div
						className={responsive ? "input-holder-r" : "input-holder"}
						style={inputStyle}
					>
						<div
							className={
								"LocationPicker Input Input--text" +
								(errorMessage ? " is-error" : "") +
								(coords ? " is-valid" : "") +
								(required ? " is-required" : "") +
								(marked ? " is-marked" : "") +
								(empty ? " is-empty" : "") +
								(green ? " is-green" : "")
							}
						>
							<input
								type="text"
								ref="location"
								placeholder={searchPlaceholder}
							/>
						</div>
						{errorMessage ? (
							<span className="Input__error">{errorMessage}</span>
						) : null}
					</div>
					{isShow && (
						<div
							className={responsive ? "input-holder-r" : "input-holder"}
							style={{ alignItems: "center" }}
						>
							<Field
								name="onlineShow"
								component={CheckBox}
								checkboxLabel={t("show:single.online")}
							/>
						</div>
					)}
				</div>
				{coords && !responsive ? (
					<div
						className={responsive ? "input-holder-r" : "input-holder"}
						style={{ width: "100%", maxWidth: "100%" }}
					>
						<div
							className={
								"LocationPicker Input Input--text" +
								(errorMessage ? " is-error" : "") +
								(coords ? " is-valid" : "") +
								(required ? " is-required" : "") +
								(marked ? " is-marked" : "") +
								(empty ? " is-empty" : "") +
								(green ? " is-green" : "")
							}
						>
							<TextInput {...locationName} placeholder="Namn på adress" />
						</div>
					</div>
				) : null}
				{/* coords ?
					// Displays a google map image of the chosen location
					<img src={"https://maps.google.com/maps/api/staticmap?center=" + coords + "&markers=color:red%7Clabel:%7C" + coords + "&zoom=13&size=400x400&sensor=false&key="+process.env.GOOGLE_MAPS_KEY} style={ mapStyle } />
				: null */}
			</div>
		);
	}
}

export default withNamespaces("components")(LocationPicker);
