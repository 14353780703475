import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import RaffleForm from "./RaffleForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import ArtistForm from "../Artist/ArtistForm";
import Main from "../../components/Main/Main";
import Modal from "../../components/Modal/Modal";
import InformMemberButton from "../../components/Buttons/InformMemberButton/InformMemberButton";
import ArtworksListAndUpload from "../../components/Artworks/ArtworksListAndUpload";
import {
	submitRaffle,
	deleteRaffle,
} from "../../actions/FormActions/RaffleFormActions";
import {
	fetchArtworksForGallery,
	fetchArtworksWithQuery,
} from "../../actions/ArtworkActions";
import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
} from "../../actions/FormActions/ArtworkFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import { saveWishListEvent } from "../../actions/EventActions";
import { showPremiumUpgradeModal } from "../../utils/Dispatcher";
import {
	fetchRaffle,
	resetRaffle,
} from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class Raffle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	componentDidMount() {
		const raffleId = this.props.params.raffleId;
		const { user } = this.props;
		if (raffleId !== "new") {
			this.props.dispatch(fetchRaffle(raffleId));
		} else {
			this.props.dispatch(resetRaffle());
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			params: { raffleId },
		} = nextProps;
		const newForm = raffleId === "new";

		if (nextProps.deletedRaffle) {
			return this.props.router.push("/raffles");
		}

		const changedRaffle =
			this.props.raffle._id !== nextProps.raffle._id && nextProps.raffle._id
				? true
				: false;
		const artworkDeleted =
			!this.props.deletedArtwork && nextProps.deletedArtwork;
		const notInitialized = !this.state.initialized && nextProps.raffle._id;

		if (changedRaffle || artworkDeleted || notInitialized) {
			this.setState(
				{
					initialized: true,
				},
				() => {
					this.props.dispatch(initialize("raffle", nextProps.raffle));
				}
			);
			// this.props.dispatch( fetchArtworksForGallery(nextProps.raffle.gallery._id) )

			// Before artwork had forRaffle property, any artwork could be added. Using artworkIds to show those here too.
			const artworkIds = nextProps.raffle.artworks.map(
				(artwork) => artwork._id
			);
			// Using $and might fetch all or time out.
			// const dbQuery = {
			// 	$and: [
			// 		{ gallery: nextProps.raffle.gallery._id },
			// 		{ $or: [
			// 				{ forRaffle: true },
			// 				{ _id: { $in: artworkIds } }
			// 			]
			// 		}
			// 	]
			// }
			const dbQuery = {
				gallery: nextProps.raffle.gallery._id,
				$or: [{ forRaffle: true }, { _id: { $in: artworkIds } }],
			};

			this.props.dispatch(fetchArtworksWithQuery(dbQuery));
		}

		// // Redirect to raffles/slug after saving new raffle.
		if (newForm && this.props.form.submitting && !nextProps.form.submitting) {
			if (nextProps.form.raffle && nextProps.form.raffle.slug) {
				this.props.router.push(`/raffles/${nextProps.form.raffle.slug}`);
			}
		}
	}

	handleSubmit = () => {
		const {
			form: { raffle, initial },
		} = this.props;
		this.props.dispatch(submitRaffle(raffle, initial));
	};

	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};

	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};

	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};

	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};

	handleRemove = () => {
		const raffle = this.props.form.raffle;
		this.props.dispatch(deleteRaffle(raffle));
	};

	// Create an event that is a wish list for this raffle. Members can select what they would like to win.
	createWishList = () => {
		const { raffle } = this.props;

		const params = {
			title: raffle.title + " Önskelista",
			wishList: true,
			gallery: raffle.gallery,
			location: raffle.gallery.location,
			locationName: raffle.gallery.locationName,
			artworks: raffle.artworks,
			featuredImage: raffle.featuredImage,
			voteEnabled: true,
			publish_status: "published",
		};

		this.props
			.dispatch(saveWishListEvent(params))
			.then((result) => {
				const wishListEvent = result.response;
				this.props.router.push(`/events/${wishListEvent.slug}`);
			})
			.catch((err) => {
				console.log("createWishList Error", err);
			});
	};

	render() {
		const {
			params: { raffleId },
			raffle,
			user,
			isAdmin,
			isSociety,
			showArtworkModal,
			showArtistModal,
			isFetching,
			previewFetching,
			gallery,
			form,
			standardOrAbove,
			t,
		} = this.props;

		const newForm = raffleId === "new";
		const userGallery =
			user.gallery && user.gallery._id ? user.gallery._id : user.gallery;

		const raffleBelongsToUser = !!(
			raffle.gallery && userGallery === raffle.gallery._id
		);

		const isOver = moment(raffle.drawDate).isBefore(moment());

		const raffleVideo =
			form.raffle && form.raffle.videoLink ? form.raffle.videoLink : null;

		if (isAdmin || newForm || raffleBelongsToUser) {
			return (
				<Main>
					<RaffleForm
						isNewForm={newForm}
						isAdmin={isAdmin}
						isSociety={isSociety}
						onSubmit={this.handleSubmit}
						raffle={raffle}
						gallery={gallery}
						isPremium={standardOrAbove}
						raffleVideo={raffleVideo}
					/>
					{raffle._id && (
						<InformMemberButton
							item={raffle}
							type={"raffle"}
							isFetching={isFetching || previewFetching}
						/>
					)}
					<legend style={{ marginTop: "50px" }}>
						{raffle.gallery
							? t("single.selectArtworks")
							: t("single.saveToSelectArtworks")}
					</legend>
					{raffle.gallery && (
						<ArtworksListAndUpload
							isAdmin={isAdmin}
							isSociety={isSociety}
							galleryId={raffle.gallery._id}
							artistId={false}
							showDropZone={raffle.gallery._id}
							raffle={raffle}
						/>
					)}
					{standardOrAbove && (
						<button
							className="purple-button--wide button-somemargin"
							type="button"
							onClick={this.createWishList}
							disabled={this.props.eventIsFetching}
						>
							{t("single.createWishList")}
						</button>
					)}
					{!standardOrAbove && (
						<button
							className="purple-button--wide button-somemargin"
							type="button"
							onClick={() =>
								showPremiumUpgradeModal(this.props.dispatch, "Premium")
							}
							disabled={this.props.eventIsFetching}
							style={{ opacity: 0.2 }}
						>
							{t("single.createWishList")}
						</button>
					)}
					{!standardOrAbove && isAdmin && (
						<div>
							<legend style={{ paddingTop: 40 }}>Extra (admin only)</legend>
							<button
								className="purple-button--wide button-somemargin"
								type="button"
								onClick={this.createWishList}
								disabled={this.props.eventIsFetching}
							>
								{t("single.createWishList")}
							</button>
						</div>
					)}
					{showArtworkModal && (
						<Modal onCloseClick={this.toggleArtworksModal}>
							<ArtworkForm
								onSubmit={this.handleArtworkSubmit}
								isAdmin={isAdmin}
							/>
						</Modal>
					)}
					{(isAdmin || raffleBelongsToUser) && !newForm && (
						<div className="fieldset">
							<legend style={{ paddingTop: 40 }}>
								{t("common:dangerZone")}
							</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={() => {
									let confirm = window.confirm(t("common:areYouSure"));
									if (confirm) this.handleRemove();
								}}
							>
								{t("single.deleteRaffle")}
							</button>
						</div>
					)}
					{showArtistModal && (
						<Modal onCloseClick={this.toggleArtistModal}>
							<ArtistForm
								onSubmit={this.handleArtistSubmit}
								isAdmin={isAdmin}
								isModal={true}
							/>
						</Modal>
					)}
				</Main>
			);
		} else {
			return null;
		}
	}
}

function mapStateToProps(state) {
	const {
		artSociety: { raffle, deletedRaffle, isFetching },
		artwork: { deletedArtwork },
		auth: { user, isAdmin },
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		raffle: { isFetching: previewFetching },
		event: { isFetching: eventIsFetching },
		gallery: { gallery },
	} = state;
	const form = {
		submitting: isSubmitting("raffle")(state),
		submitSucceeded: hasSubmitSucceeded("raffle")(state),
		submitFailed: hasSubmitFailed("raffle")(state),
		raffle: getFormValues("raffle")(state),
		initial: getFormInitialValues("raffle")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	const isSociety = user.role === "society";
	const standardOrAbove = isStandardOrAbove(gallery);

	return {
		raffle,
		deletedRaffle,
		deletedArtwork,
		user,
		isAdmin,
		isSociety,
		gallery,
		form,
		artistForm,
		artworkForm,
		standardOrAbove,
		showArtworkModal,
		showArtistModal,
		isFetching,
		previewFetching,
		eventIsFetching,
	};
}

const translated = withNamespaces("raffle")(Raffle);
export default connect(mapStateToProps)(withRouter(translated));
