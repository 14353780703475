import React from "react";
import PropTypes from "prop-types";

const PLUS_SYMBOL = require("../../../../assets/images/svg/plus_32px.svg");
const MINUS_SYMBOL = require("../../../../assets/images/svg/minus_32px.svg");

const defaultProps = {
	expanded: true,
};

const ExpandButton = (props) => {
	const { expanded, click, style = {} } = props;

	if (expanded) {
		return (
			<MINUS_SYMBOL className="ExpandButton" onClick={click} style={style} />
		);
	} else {
		return (
			<PLUS_SYMBOL className="ExpandButton" onClick={click} style={style} />
		);
	}
};

ExpandButton.propTypes = {
	expanded: PropTypes.bool,
};
ExpandButton.defaultProps = defaultProps;

export default ExpandButton;
