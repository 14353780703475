import {
  LOAD,
  SHOW_ARTWORK_MODAL,
  HIDE_ARTWORK_MODAL,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR
}                         from '../../actions/FormActions/ArtworkFormActions'
import {
  ARTWORKS_REQUEST,
  ARTWORK_REQUEST,
  ARTWORK_SAVE_SUCCESS,
  ARTWORK_SAVE_FAILED,
}                         from '../../actions/ArtworkActions'

export function artworkFormReducer(state = {
  showSuccess: false,
  showError: false,
  showArtworkModal: false,
  error: null
}, action) {
  switch (action.type) {
    case LOAD:
      return Object.assign({}, state, {
        data: action.data
      })
    case ARTWORKS_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false
      })
    case ARTWORK_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false
      })
    case ARTWORK_SAVE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true,
        showArtworkModal: false
      })
    case ARTWORK_SAVE_FAILED:
      return Object.assign({}, state, {
        showError: true,
        error: action.message
      })
    case SHOW_ARTWORK_MODAL:
      return Object.assign({}, state, {
        showArtworkModal: true
      })
    case HIDE_ARTWORK_MODAL:
      return Object.assign({}, state, {
        showArtworkModal: false
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    default:
      return state
  }
}
