import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import readXlsxFile from "read-excel-file";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import { saveAs } from "file-saver";
import Select from "react-select";
import Main from "../../components/Main/Main";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import {
	fetchNewsletter,
	fetchNewsletterByGallery,
	addNewsletter,
	updateNewsletter,
	updateSubscribers,
	resetNewsletter,
	updateSubscriber,
} from "@artworkslab/sharedmodules/src/actions/NewsletterActions";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { fetchAllGalleriesFast } from "../../actions/GalleryActions";
import { exportData } from "../../actions/RegistryActions";

const BIN = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");
const PLUS_WHITE = require("../../../assets/images/svg/plus_32px_white.svg");
const CLOSE = require("../../../assets/images/icons/close@2x.png");
const DEFAULT_MESSAGE =
	"Hej och tack för att du har anmält dig till vårt nyhetsbrev!\nDu kommer från och med nu få information om våra kommande utställningar.\nVi ses snart!\n\nVänliga hälsningar,\nKonstföreningen.";

class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
	}

	componentDidMount() {
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setValue(nextProps.value);
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	_update = (evt) => {
		const { subscrId, keyForValue, update } = this.props;
		update(subscrId, keyForValue, evt.target.value);
	};

	render() {
		const { value } = this.state;
		const { approved } = this.props;

		return (
			<input
				type="text"
				value={value}
				onChange={(evt) => this.setValue(evt.target.value)}
				onBlur={(evt) => this._update(evt)}
				className="input-field"
			/>
		);
	}
}

class Newsletter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			newsletterActive: false,
			addSubscriberOpen: false,
			newSubscriberName: "",
			newSubscriberEmail: "",
			newSubscriberEmailValid: false,
			feedbackMessage: "",
			importExportOpen: false,
			emailBody: "",
			galleryOptions: [],
			selectedGallery: null,
			optionsOpen: true,
		};
	}

	componentWillMount() {
		const { isAdmin, gallery, newsletter, isFetching } = this.props;
		if (!isAdmin && gallery.newsletter) {
			// && !newsletter._id (don't check for this or newsletter won't be fetched when swapping between users)
			this.fetchData(this.props);
		} else if (isAdmin) {
			this.props.dispatch(fetchAllGalleriesFast());
		}

		if (newsletter._id) {
			this.setState({
				emailBody: newsletter.confirmationText,
				newsletterActive: newsletter.active,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, gallery, newsletter, isFetching } = nextProps;
		if (
			!isAdmin &&
			!newsletter._id &&
			!this.props.gallery.newsletter &&
			nextProps.gallery.newsletter
		) {
			this.fetchData(nextProps);
		}

		if (this.props.newsletter.active !== nextProps.newsletter.active) {
			this.setState({
				newsletterActive: nextProps.newsletter.active,
			});
		}

		if (!this.props.newsletter._id && newsletter._id) {
			this.setState({
				emailBody: newsletter.confirmationText,
				newsletterActive: newsletter.active,
			});
		}

		if (isAdmin && this.props.newsletter._id !== newsletter._id) {
			this.setState({
				emailBody: newsletter.confirmationText,
				newsletterActive: newsletter.active,
			});
		}

		if (
			isAdmin &&
			this.state.galleryOptions.length !==
				Object.keys(nextProps.allGalleries).length > 0
		) {
			const galleryOptions = Object.keys(nextProps.allGalleries).map((key) => {
				const venue = nextProps.allGalleries[key];
				return {
					value: venue._id,
					label: venue.name,
				};
			});

			this.setState({
				galleryOptions: galleryOptions.sort(this.sortOptions),
			});
		}

		if (!this.props.exported && nextProps.exported) {
			const type =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
			const blob = new Blob([this.s2ab(nextProps.exported)], { type: type });
			saveAs(blob, "medlemsregister.xlsx");
		}
	}

	s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	// Admin doesn't use this.
	fetchData = (props) => {
		this.props.dispatch(fetchNewsletterByGallery(props.gallery._id));
	};

	importFile = (evt) => {
		evt.preventDefault();
		const columns = [
			{ value: "name", label: "Namn" },
			{ value: "email", label: "Email" },
		];
		let newSubscribers = [];
		const subscribingEmails = this.props.newsletter.subscribers.map(
			(subscriber) => subscriber.email
		);
		let feedbackMessage = "";

		const file = this.fileUpload.files[0];
		readXlsxFile(file)
			.then((rows) => {
				// Get column index of name and email headers.
				let nameIndex = -1,
					emailIndex = -1;
				const nameRegex = /^(namn|name)$/i;
				const emailRegex = /^(mejl|email)$/i;
				rows[0].forEach((header, index) => {
					if (nameRegex.test(header)) {
						nameIndex = index;
					} else if (emailRegex.test(header)) {
						emailIndex = index;
					}
				});

				if (emailIndex < 0) {
					if (feedbackMessage) {
						this.setState({
							feedbackMessage: "Ingen kolumn för Email",
						});
					}
					throw new Error("No email column");
				}

				// Loop through imported subscribers.
				rows.forEach((row, rowIndex) => {
					// Don't include header row.
					if (rowIndex > 0) {
						let newSubscriber = {
							name: "",
							email: "",
						};
						// Can create subscriber without name, but not without email address.
						newSubscriber.email = row[emailIndex];
						if (nameIndex >= 0) newSubscriber.name = row[nameIndex];

						const valid = validateEmail(newSubscriber.email);
						const alreadySubscribing =
							subscribingEmails.indexOf(newSubscriber.email) > -1;

						if (valid && !alreadySubscribing) {
							newSubscribers.push(newSubscriber);
						} else if (!valid) {
							feedbackMessage =
								feedbackMessage +
								`Ogiltig email adress: ${newSubscriber.email}\n`;
						} else if (alreadySubscribing) {
							feedbackMessage =
								feedbackMessage +
								`${newSubscriber.email} finns redan i listan.\n`;
						}
					}
				});

				const params = {
					addSubscribers: newSubscribers,
				};
				this.props.dispatch(
					updateSubscribers(this.props.newsletter._id, params)
				);

				if (feedbackMessage) {
					this.setState({
						feedbackMessage,
					});
				}
			})
			.catch((error) => {
				console.log("upload error", error);
			});
		// Allows trying to import the same file twice in a row.
		evt.target.value = null;
	};

	exportSubscribers = () => {
		const {
			newsletter: { subscribers },
		} = this.props;

		const headers = ["Namn", "Email"];
		const sheet = [];

		subscribers.forEach((subscriber) => {
			let subscriberDate = {
				name: subscriber.name,
				email: subscriber.email,
			};
			sheet.push(subscriberDate);
		});

		this.props.dispatch(exportData(headers, sheet));
	};

	toggleActive = () => {
		const { newsletter } = this.props;
		if (newsletter._id) {
			// console.log('TOGGLE set to', !newsletter.active);
			this.props.dispatch(
				updateNewsletter(newsletter._id, { active: !newsletter.active })
			);
		}
	};

	// Create a newsletter
	createSendout = () => {
		const { gallery, isAdmin } = this.props;
		const galleryId = isAdmin ? this.state.selectedGallery.value : gallery._id;
		this.props.dispatch(
			addNewsletter({
				gallery: galleryId,
				confirmationText: DEFAULT_MESSAGE,
			})
		);
	};

	emailValid = (value) => {
		const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		if (!value || re.test(value)) {
			return true;
		} else {
			return false;
		}
	};

	toggleAddSubscriberOpen = (e) => {
		e.preventDefault();
		this.setState({
			addSubscriberOpen: !this.state.addSubscriberOpen,
		});
	};

	updateNewName = (e) => {
		e.preventDefault();
		this.setState({
			newSubscriberName: e.target.value,
		});
	};

	updateNewEmail = (e) => {
		e.preventDefault();
		this.setState({
			newSubscriberEmail: e.target.value,
			newSubscriberEmailValid: validateEmail(e.target.value),
		});
	};

	removeNewSubscriber = (e) => {
		e.preventDefault();
		this.setState({
			newSubscriberName: "",
			newSubscriberEmail: "",
			newSubscriberEmailValid: true,
			addSubscriberOpen: false,
		});
	};

	addSubscriber = (e) => {
		e.preventDefault();
		const { newsletter } = this.props;
		const { newSubscriberName, newSubscriberEmail } = this.state;

		const params = {
			addSubscribers: [
				{
					name: newSubscriberName,
					email: newSubscriberEmail,
				},
			],
		};

		this.setState(
			{
				newSubscriberName: "",
				newSubscriberEmail: "",
				newSubscriberEmailValid: true,
				addSubscriberOpen: false,
			},
			() => {
				this.props.dispatch(updateSubscribers(newsletter._id, params));
			}
		);
	};

	updateSubscriberValue = (subscrId, key, value) => {
		const { newsletter, t } = this.props;
		const newsletterId = newsletter._id;
		const body = { key, value, newsletterId };
		if (key === "email") {
			if (!this.emailValid(value)) {
				window.alert(t("badEmail"));
				return;
			}
		}
		this.props.dispatch(updateSubscriber(subscrId, body));
	};

	removeSubscriber = (id) => {
		const { newsletter, t } = this.props;
		let confirm = window.confirm(t("confirmRemoveSubscriber"));
		if (!confirm) return;

		const params = {
			removeSubscribers: [id],
		};

		this.props.dispatch(updateSubscribers(newsletter._id, params));
	};

	closeMessage = (e) => {
		e.preventDefault();
		this.setState({
			feedbackMessage: "",
		});
	};

	toggleImportOpen = () => {
		this.setState({
			importExportOpen: !this.state.importExportOpen,
		});
	};

	changeEmailBody = (e) => {
		const text = e.target.value;
		this.setState({
			emailBody: text,
		});
	};

	saveEmailText = (e) => {
		const { newsletter } = this.props;
		const { emailBody } = this.state;

		this.props.dispatch(
			updateNewsletter(newsletter._id, { confirmationText: emailBody })
		);
	};

	selectGallery = (gallery) => {
		const { allGalleries } = this.props;
		this.setState(
			{
				selectedGallery: gallery,
			},
			() => {
				this.props.dispatch(fetchNewsletterByGallery(gallery.value));
			}
		);
	};

	toggleOptionsOpen = () => {
		this.setState({
			optionsOpen: !this.state.optionsOpen,
		});
	};

	createRows = (subscribers = []) => {
		return subscribers.map((subscr) => {
			return (
				<Tr key={subscr._id}>
					<Td column={"delete"} value="delete" key="delete">
						<BIN
							className={"remove-subscriber"}
							onClick={() => this.removeSubscriber(subscr._id)}
						/>
					</Td>
					<Td column="name" value={subscr.name}>
						<InputField
							value={subscr.name}
							update={this.updateSubscriberValue}
							subscrId={subscr._id}
							keyForValue={"name"}
						/>
					</Td>
					<Td column="email" value={subscr.email}>
						<InputField
							value={subscr.email}
							update={this.updateSubscriberValue}
							subscrId={subscr._id}
							keyForValue={"email"}
						/>
					</Td>
				</Tr>
			);
		});
	};

	render() {
		const { isAdmin } = this.props;
		// Show this page for everyone.
		// if (!isAdmin && !premium) return null

		const { newsletter, isFetching, exportIsFetching, t } = this.props;
		const {
			newsletterActive,
			feedbackMessage,
			importExportOpen,
			emailBody,
			addSubscriberOpen,
			newSubscriberName,
			newSubscriberEmail,
			newSubscriberEmailValid,
			galleryOptions,
			selectedGallery,
			optionsOpen,
		} = this.state;

		// const addButtonDisabled = (!newSubscriberName && !newSubscriberEmail) || !newSubscriberEmailValid
		const addButtonDisabled = !newSubscriberEmailValid;
		const mailTextChanged = emailBody !== newsletter.confirmationText;
		const rows = this.createRows(newsletter.subscribers);

		if (isAdmin && !selectedGallery) {
			return (
				<div className="newsletter">
					<Main>
						<Select
							value={selectedGallery}
							options={galleryOptions}
							onChange={this.selectGallery}
							onInputChange={this.onInputChange}
							valueKey={"gallery"}
							labelKey="label"
							backspaceToRemoveMessage=""
							backspaceRemoves={false}
							isLoading={isFetching}
							placeholder={"Gallery"}
							multi={false}
							autosize={false}
							className={"gallery-picker-select"}
							isSearchable={true}
						/>
					</Main>
				</div>
			);
		}

		return (
			<div className="newsletter">
				<Main>
					{isAdmin && (
						<Select
							value={selectedGallery}
							options={galleryOptions}
							onChange={this.selectGallery}
							onInputChange={this.onInputChange}
							valueKey={"gallery"}
							labelKey="label"
							backspaceToRemoveMessage=""
							backspaceRemoves={false}
							isLoading={isFetching}
							placeholder={"Gallery"}
							multi={false}
							autosize={false}
							className={"gallery-picker-select"}
							isSearchable={true}
						/>
					)}
					{/*<p className={ b('description') }>Mottagare av nyhetsbrev får alla påminnelser som går ut om dina utställningar. Påminnelserna ser ut på samma sätt som de mejl som skickas till dina medlemmar. Mottagarna har möjlighet att avregistrera sig från nyhetsbreven.</p>*/}
					{/*<p className={ b('description') }>Spara och lägg till kontakter som kan tänkas vilja ha information om vad som händer i föreningen. Mejl som ni skickar till medlemmarna kan du även skickas till tillagda kontakter. Mottagarna har möjlighet att avregistrera sig från utskicken.</p>*/}
					<p className="newsletter__description">
						Lägg till och spara kontakter som kan tänkas vilja ha information om
						vad som händer i föreningen. Mejl som ni skickar till medlemmar kan
						också aviseras tillagda kontakter. Mottagaren kan avregistrera sig
						från utskicken.
					</p>
					{!newsletter._id && !isFetching && (
						<button
							className="purple-button--wide button-nomargin"
							onClick={() => this.createSendout()}
						>
							{t("create")}
						</button>
					)}
					{newsletter._id && (
						<div className="newsletter__activate-area">
							<input
								type="checkbox"
								id="newsletter-checkbox"
								className="newsletter__activate-area__activate"
								onChange={() => this.toggleActive()}
								checked={newsletterActive}
								disabled={isFetching}
							/>
							<label htmlFor="newsletter-checkbox">
								Kryssa i här om du vill att man ska kunna anmäla sig till
								nyhetsbrev på sidan
							</label>
						</div>
					)}
					{newsletter.active && (
						<legend
							className={
								"newsletter__options-opener" + (optionsOpen ? " is-open" : "")
							}
						>
							{"Alternativ"}
							<ExpandButton
								expanded={optionsOpen}
								click={this.toggleOptionsOpen}
								style={{ top: 0 }}
							/>
						</legend>
					)}
					{newsletter._id && (
						<div className="newsletter__content">
							{newsletter.active && optionsOpen && (
								<div>
									<div className="newsletter__content__confirmation-email">
										<p style={{ color: "#a8a8a8" }}>
											Hälsning till de som anmäler sig till era nyhetsbrev
										</p>
										<textarea
											rows={10}
											onChange={this.changeEmailBody}
											value={emailBody}
											placeholder={`Exempel: ${DEFAULT_MESSAGE}`}
											disabled={isFetching}
										/>
										<button
											className="purple-button--wide"
											style={{ marginTop: "10px" }}
											onClick={this.saveEmailText}
											disabled={!mailTextChanged}
										>
											{t("save")}
										</button>
									</div>
								</div>
							)}

							{/*Import/export section*/}
							<div className="newsletter__content__import-export">
								<div className="content__import-export__buttons">
									<input
										type="file"
										id="file"
										ref={(ref) => (this.fileUpload = ref)}
										onChange={this.importFile}
										className={"hidden-file-upload-button"}
										disabled={isFetching}
									/>
									<label
										htmlFor="file"
										className="purple-button purple-button--wide purple-button--wide--mod_import"
									>
										Importera
									</label>
									<button
										onClick={this.exportSubscribers}
										disabled={isFetching || exportIsFetching}
										className="purple-button purple-button--wide purple-button--wide--mod_export"
									>
										Exportera
									</button>
								</div>
							</div>

							{feedbackMessage ? (
								<div className="newsletter__content__message">
									<img src={CLOSE} onClick={this.closeMessage} />
									<p>{feedbackMessage}</p>
								</div>
							) : (
								<div className="newsletter__content__message-spacer" />
							)}
							<Table className="reactable" sortable={["name", "email"]}>
								<Thead>
									<Th
										column={"delete"}
										key={"delete"}
										style={{ position: "relative" }}
									>
										<PLUS_WHITE
											className="newsletter__content__add-subscriber"
											onClick={this.toggleAddSubscriberOpen}
										/>
									</Th>
									<Th column="name">{t("common:name")}</Th>
									<Th column="email">{t("common:email")}</Th>
									<Th column="add">{``}</Th>
								</Thead>
								{addSubscriberOpen && (
									<Tr
										key={"index"}
										className={`new-subscriber ${
											addSubscriberOpen ? "" : "is-closed"
										}`}
									>
										<Td column={"delete"} value="delete" key="delete">
											<BIN
												className={"remove-subscriber"}
												onClick={this.removeNewSubscriber}
											/>
										</Td>
										<Td column="name" value={"sort-first"}>
											<input
												value={newSubscriberName}
												onChange={this.updateNewName}
											/>
										</Td>
										<Td column="email" value={"sort-first"}>
											<input
												value={newSubscriberEmail}
												onChange={this.updateNewEmail}
												style={{
													color: newSubscriberEmailValid ? "black" : "red",
												}}
											/>
										</Td>
										<td column="add">
											<div
												className={`add-subscriber ${
													addButtonDisabled ? "disabled" : ""
												}`}
												onClick={addButtonDisabled ? null : this.addSubscriber}
											>
												{t("addSubscriber")}
											</div>
										</td>
									</Tr>
								)}
								{rows}
							</Table>
						</div>
					)}
				</Main>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		newsletter: { newsletter, isFetching },
		gallery: { gallery, allGalleries },
		auth: { user },
		registry: { exported, isFetching: exportIsFetching },
	} = state;
	const isAdmin = user.role === "admin";

	return {
		newsletter,
		gallery,
		allGalleries,
		isAdmin,
		isFetching,
		exported,
		exportIsFetching,
	};
};

const translated = withNamespaces("newsletter")(Newsletter);
export default connect(mapStateToProps)(translated);
