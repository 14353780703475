import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import GalleryStats from "../../components/GalleryStats/GalleryStats";
import ShowStats from "../../components/ShowStats/ShowStats";
import Summary from "../../components/DashboardComponents/Summary/Summary";
import CurrentShow from "../../components/DashboardComponents/CurrentShow/CurrentShow";
import PastShows from "../../components/DashboardComponents/PastShows/PastShows";
import UpcomingShows from "../../components/DashboardComponents/UpcomingShows/UpcomingShows";
import PopularArtwork from "../../components/DashboardComponents/PopularArtwork/PopularArtwork";
import PopularOnArtworks from "../../components/DashboardComponents/PopularOnAw/PopularOnAw";
import Header from "../../components/DashboardComponents/Header/Header";

const CHEVRON = require("../../../assets/images/icons/chevron_right_white_22px.png");
const CHECK = require("../../../assets/images/svg/checkmark_white_18px.svg");

class AuthorDashboard extends React.Component {
	/**
	 * defaultProps
	 */
	static defaultProps = {
		isAdmin: false,
	};

	static propTypes = {
		isAdmin: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillReceiveProps(newProps) {}

	renderValidPage() {
		const { t } = this.props;
		return (
			<div>
				<Header role={"author"} t={t} />
				<Summary role={"author"} t={t} />
				<CurrentShow />
				<UpcomingShows />
				<PastShows />
				<PopularArtwork />
				{/*<PopularOnArtworks />
				<GalleryStats metrics={galleryStats} />
				<ShowStats metrics={showStats} />*/}
			</div>
		);
	}

	renderProgressPageOld() {
		return (
			<div>
				<h6>Welcome to Artworks Portal!</h6>
				<ul>
					<li>
						<Link to="/gallery">
							Start by filling out information about your venue.
						</Link>
					</li>
					<li>
						<Link to="/shows">Then add an upcoming, current or past show</Link>
					</li>
				</ul>
			</div>
		);
	}

	renderProgressPage({ gallery, shows, artworks }) {
		return (
			<div className="AuthorDashboard">
				<h6>Welcome to Artworks!</h6>
				<br />
				<p>
					We give you the tools to drive “real-life” traffic, stronger customer
					relations, brand visibility and ultimately more sales.
				</p>
				<br />
				<p>
					Get to know your customers better by filling out some information
					about yourself and your exhibition program. It will assist us to
					provide you the data to perform even better.
				</p>
				<div className="AuthorDashboard__todoList">
					<div className="AuthorDashboard__todoList__todo">
						<h4>Your venue</h4>
						<p>Fill out general information about yourself.</p>
						<Link
							to="/gallery"
							className={
								"AuthorDashboard__todoList__todo__button" +
								(gallery ? " is-done" : "")
							}
						>
							{gallery ? "Done" : "Not done yet"}
							{gallery ? (
								<img src={CHEVRON} className="todo-image" />
							) : (
								<CHECK className="todo-image" />
							)}
						</Link>
					</div>
					<div className="AuthorDashboard__todoList__todo">
						<h4>Your shows</h4>
						<p>Share your current, upcoming and past shows.</p>
						<Link
							to="/shows"
							className={
								"AuthorDashboard__todoList__todo__button" +
								(shows ? " is-done" : "")
							}
						>
							{shows ? "Done" : "Not done yet"}
							{shows ? (
								<img src={CHEVRON} className="todo-image" />
							) : (
								<CHECK className="todo-image" />
							)}
						</Link>
					</div>
					<div className="AuthorDashboard__todoList__todo">
						<h4>Your artworks</h4>
						<p>
							Upload the artworks that belong to each show, whether for sale or
							not.
						</p>
						<Link
							to="/artworks"
							className={
								"AuthorDashboard__todoList__todo__button" +
								(artworks ? " is-done" : "")
							}
						>
							{artworks ? "Done" : "Not done yet"}
							{artworks ? (
								<img src={CHEVRON} className="todo-image" />
							) : (
								<CHECK className="todo-image" />
							)}
						</Link>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { valid, onboarding } = this.props;

		if (valid) {
			return this.renderValidPage();
		} else if (onboarding && valid !== null) {
			/* valid !== null, prevent renderProgressPage showing before renderValidPage on browser reload,
			because onboarding is set before valid. */
			return this.renderProgressPage(onboarding);
		} else {
			return null;
		}
	}
}

export default withNamespaces("dashboard")(AuthorDashboard);
