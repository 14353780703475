import React, {Component} 	from 'react'
import Select								from 'react-select'
import { connect }					from 'react-redux'
import { withNamespaces }		from 'react-i18next'
import Tooltip              from '../../Tooltip/Tooltip'
import { fetchMentionsForArtist } from '../../../actions/ArtistActions'

class RelatedPicker extends Component {
	constructor(props){
		super(props);
    this.state = {
      selected: []
    }
	}

	componentDidMount(){
    if (this.props.artistId) {
      this.props.dispatch( fetchMentionsForArtist(this.props.artistId) )
    }
	}

	componentWillReceiveProps(newProps) {
    if (this.props.artistId !== newProps.artistId && newProps.artistId) {
      this.props.dispatch( fetchMentionsForArtist(newProps.artistId) )
    }
		// Save after removing mention. Can't save after onChange/onBlur in handleChange(), will save before values are updated.
		if (this.props.input.value.length > newProps.input.value.length && this.props.handleSubmit) {
			this.props.handleSubmit()
		}
	}


	selectRelated = (selections) => {
    this.setState({
			selected: [].concat(selections),
		})
	}

  formatRelated = (related) => {

    const formattedRelated = related.map(rel => {

			const category = rel.category ? `(${rel.category})` : ''
			// value and label required by react-select component
			return Object.assign({}, rel,
        {
          value: rel.internalLink ? rel.internalLink : rel.externalLink,
          label: `${rel.title} ${category}`,
        }
      )
    })

    return formattedRelated
  }

	handleChange = (selectedItems) => {
		// selectedItems = currently selected
		const { onChange, onBlur, value } = this.props.input
		// value = previously selected items

		let values = []
		values = values.concat(selectedItems)

		onChange(values)
		onBlur(values)
	}

	render() {
		const { related, defaultMentions, t } = this.props
    const isFetching = false
		const { type, placeholder, input: { value } } = this.props
    const { selected } = this.state

    const formattedRelated = this.formatRelated(related || [])
    const formattedSelected = this.formatRelated(selected || [])
		const formattedDefaultMentions = this.formatRelated(defaultMentions || [])
		const formattedValues = this.formatRelated(value || [])

    const toolTip = {
      text: t('relatedPicker.toolTip'),
      popupPos: 'left',
    }

		// Default mentions (related shows and events) always available in the dropdown
		return (
			<div className="input-holder input-holder--nopadding">
				<Select
					isLoading={isFetching}
					onChange={this.handleChange}
          options={formattedDefaultMentions}
					value={formattedValues}
					multi={true}
          removeSelected={true}
					/>
				{/* formattedDefaultMentions.length > 0 &&
					<Tooltip data={ toolTip } extraClass="more-left" />
				*/}
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { _id: artistId, related } = state.artist.artist
  const defaultMentions = state.artist.mentions[artistId] || []

	return { artistId, related, defaultMentions }
}

const translated = withNamespaces('components')(RelatedPicker)
export default connect(mapStateToProps)(translated)
