import React, { PropTypes } from "react";
import { connect } from "react-redux";
import { Field, Fields } from "redux-form";
import TextInput from "../../FormInput/TextInput";
import Select from "../../FormInput/Select";
import Tooltip from "../../Tooltip/Tooltip";
import CurrencyUtil, {
	VAT_ARR,
	VAT_MAP,
} from "@artworkslab/sharedmodules/src/CurrencyUtil";
import { withNamespaces } from "react-i18next";

const getPriceStatus = (t) => {
	return [
		{ value: "for-sale", name: t("pricePicker.forSale") },
		{ value: "not-for-sale", name: t("pricePicker.notForSale") },
		{ value: "sold", name: t("pricePicker.sold") },
	];
};

const PRICE_CURRENCIES = [
	{ value: "SEK", name: "SEK" },
	{ value: "USD", name: "USD" },
	{ value: "EUR", name: "EUR" },
	{ value: "NOK", name: "NOK" },
	{ value: "ISK", name: "ISK" },
	{ value: "DKK", name: "DKK" },
];

const VAT_OPTIONS = VAT_ARR.map((vat) => {
	return {
		value: Math.round(vat.value * 100),
		name: vat.symbol,
	};
});

const getContactToolTip = (t) => {
	return {
		text: t("pricePicker.toolTips.contact"),
		popupPos: "left",
	};
};

const noVatAlert = (t) => {
	window.alert(t("pricePicker.noVatAlert"));
};

const PricePicker = (props) => {
	const {
		selectedCurrency,
		selectedPrice,
		selectedVATCode,
		isSwedish,
		role,
		artistIsProMember,
		artistOrgNr = null,
		hideStatus = false,
		disabled,
		invoice = false,
		order = false,
		isResponsive = false,
		t,
	} = props;
	// const priceVisible = (props.status.input.value === 'for-sale' || invoice || order) ? null : {display: 'none'}
	const priceVisible =
		props.status.input.value === "for-sale" || invoice || order
			? {}
			: { display: "none" };
	const contactToolTip = getContactToolTip(t);

	const selectedPriceNumber = parseInt(selectedPrice);
	const showCompensation = !!selectedPrice;

	// 10% for galleries, 30% for artists, 15% for artists PRO.
	let commissionPercent = 0.1;
	if (role === "artist") {
		if (artistIsProMember) {
			commissionPercent = 0.15;
		} else {
			commissionPercent = 0.3;
		}
	}
	const textCommissionPercent = `${100 * commissionPercent}%`;

	let compensation = 0;
	if (showCompensation) {
		const VATmultiplier = VAT_MAP[selectedVATCode].value;

		const VATvalue = selectedPriceNumber * VATmultiplier;

		const totalPrice = selectedPriceNumber + VATvalue;

		const commission = selectedPriceNumber * commissionPercent;

		const commissionWithVAT = commission * 1.25;

		const compensationRaw = totalPrice - commissionWithVAT;

		compensation = Math.ceil(compensationRaw);
	}

	const fieldsetClass = isResponsive ? "fieldset-r" : "fieldset";
	const inputHolderClass = isResponsive ? "input-holder-r" : "input-holder";
	const inputHolderFullClass = isResponsive
		? "input-holder-r input-holder-r--full"
		: "input-holder input-holder--full";

	let vatOptions = VAT_OPTIONS.slice();
	vatOptions.unshift({
		name: t("pricePicker.selectVAT"),
		value: "placeholder",
	});
	//Disable VAT-field if VAT is 0 and artists don't have an org. number
	const hasOrgNr = artistOrgNr && artistOrgNr.length > 0 ? true : false;
	const disabledVAT = !hasOrgNr && selectedVATCode === 0 && role != "admin";

	let priceWithVAT = CurrencyUtil.format(
		props.price.input.value,
		props.priceCurrency.input.value,
		props.VATcode.input.value
	);
	if (isResponsive)
		priceWithVAT = (isSwedish ? "Summa: " : "Sum: ") + priceWithVAT;

	return (
		<div className={fieldsetClass}>
			{!hideStatus && !isResponsive && (
				<legend>{t("pricePicker.title")}</legend>
			)}

			{!hideStatus && (
				<div className={inputHolderFullClass}>
					<Select
						{...props.status}
						options={getPriceStatus(t)}
						disabled={disabled}
					/>
				</div>
			)}

			{/* Put currency selector above the right part of the price input field. */}
			{isResponsive && (
				<div className={inputHolderFullClass} style={priceVisible}>
					<TextInput
						{...props.price}
						placeholder={t("pricePicker.price")}
						disabled={disabled}
					/>
					<div
						style={{ position: "absolute", top: 0, right: 0, width: "100px" }}
					>
						<Select
							{...props.priceCurrency}
							options={PRICE_CURRENCIES}
							disabled={disabled}
							style={{
								padding: "0 40px 0 20px",
								borderLeft: "1px solid #a8a8a8",
							}}
						/>
					</div>
				</div>
			)}

			{!isResponsive && (
				<div className={inputHolderClass} style={priceVisible}>
					<TextInput
						{...props.price}
						placeholder={t("pricePicker.price")}
						disabled={disabled}
					/>
					{/*<Tooltip data={ contactToolTip } extraClass="more-left" />*/}
				</div>
			)}
			{!isResponsive && (
				<div className={inputHolderClass} style={priceVisible}>
					<Select
						{...props.priceCurrency}
						options={PRICE_CURRENCIES}
						disabled={disabled}
					/>
				</div>
			)}

			<div
				className={isResponsive ? inputHolderFullClass : inputHolderClass}
				style={priceVisible}
				onClick={disabledVAT ? () => noVatAlert(t) : () => {}}
			>
				{!isResponsive && (
					<legend className="legend-nomargin">
						{t("pricePicker.selectVAT")}
					</legend>
				)}
				<Select
					{...props.VATcode}
					options={vatOptions}
					disabled={disabled || disabledVAT}
					showPlaceholderInitially={false}
				/>
			</div>
			{showCompensation && (
				<div
					className={isResponsive ? inputHolderFullClass : inputHolderClass}
					style={priceVisible}
				>
					{!isResponsive && (
						<legend className="legend-nomargin">
							{t("pricePicker.total")}
						</legend>
					)}
					<div className="Input">
						<input value={priceWithVAT} type="text" disabled />
					</div>
				</div>
			)}
			{showCompensation && !order && !isResponsive && (
				<div className={inputHolderFullClass} style={priceVisible}>
					<p style={{ color: "#a8a8a8", marginBottom: "20px" }}>
						{t("pricePicker.ourCommission", { percent: textCommissionPercent })}{" "}
						{t("pricePicker.yourCompensation")}{" "}
						<b>
							{CurrencyUtil.formatNoVatAdded(compensation, selectedCurrency)}
						</b>{" "}
						{t("pricePicker.vatIncluded")}
					</p>
					{role === "artist" && !artistIsProMember && (
						<p
							style={{
								color: "#a8a8a8",
								marginBottom: "20px",
								textAlign: "center",
							}}
						>
							<i>{t("pricePicker.proCommission")}</i>
						</p>
					)}
				</div>
			)}
			{/*<div className={ inputHolderFullClass } style={ priceVisible }>
        { (showCompensation && !order) &&
          <div>
            <p style={{ color: '#a8a8a8', marginBottom: '0px' }}>
              {t('pricePicker.ourCommission', { percent: textCommissionPercent })} {t('pricePicker.yourCompensation')} <b>{CurrencyUtil.formatNoVatAdded(compensation, selectedCurrency)}</b> {t('pricePicker.vatIncluded')}</p>
            { role === 'artist' && !artistIsProMember &&
              <p style={{ color: '#a8a8a8', marginBottom: '30px', textAlign: 'center' }}><i>{t('pricePicker.proCommission')}</i></p>
            }
          </div>
        }
      </div>*/}
		</div>
	);
};

function mapStateToProps(state) {
	const { role } = state.auth.user;

	return { role };
}

const translated = withNamespaces("components")(PricePicker);
export default connect(mapStateToProps)(translated);
