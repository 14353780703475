import React, { Component } from "react";
import { Fields } from "redux-form";
import Select from "react-select";
import OpeningHourDay from "./OpeningHourDay";

const DAYS = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

// const propTypes = {
//   // openingHours: PropTypes.object.isRequired,
//   // openingHoursChanged: PropTypes.func.isRequired,
//   // nameOfClass: PropTypes.string
// }
const defaultProps = {
	openingHours: {
		monday: {},
		tuesday: {},
		wednesday: {},
		thursday: {},
		friday: {},
		saturday: {},
		sunday: {},
	},
	nameOfClass: "Gallery__openingHours OpeningHours",
};

const OpeningHours = (props) => {
	const { nameOfClass, openingHours } = props;
	return (
		<table className={nameOfClass}>
			<tbody>
				{DAYS.map((day) => (
					<Fields
						key={day}
						day={day}
						names={[`${day}.opens`, `${day}.closes`, `${day}.appointment`]}
						component={OpeningHourDay}
					/>
				))}
			</tbody>
		</table>
	);
};

// OpeningHours.propTypes = propTypes
OpeningHours.defaultProps = defaultProps;

export default OpeningHours;
