import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import Spinner from "react-spinkit";
import moment from "moment";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import ChatWindow from "./ChatWindow/ChatWindow";
import ConfirmPurchaseModal from "../Modal/Custom/ConfirmPurchaseModal";
import { formatName, createWebbLink } from "../../utils/Helpers";
import {
	toggleEnquiries,
	fetchAdminEnquiries,
	fetchArtistEnquiries,
} from "@artworkslab/sharedmodules/src/actions/EnquiryActions";
import {
	fetchAdminMessages,
	fetchUserMessages,
	fetchChat,
	clearChat,
	sendMessage,
	closeChat,
	unresolveChat,
	resetChat,
} from "@artworkslab/sharedmodules/src/actions/ChatActions";
import { getInitials } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { fetchArtistNotifications } from "@artworkslab/sharedmodules/src/actions/NotificationActions";

const EMPTY_ENQUIRY_LOGO = require("../../../assets/images/enquiries.png");
const EMPTY_MESSAGES_LOGO = require("../../../assets/images/messages.png");
const PLACEHOLDER_IMG =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==";

class ChatList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openedChat: null,
			openedStatus: null,
			openedWebbLink: null,
			updateCountdown: 5,
			chatArtwork: null,
			confirmModalOpen: false,
		};
		this.updateTimer = false;
	}

	componentDidMount() {
		this.fetchData(this.props);
	}

	componentWillMount() {
		this.chatCountdown();
	}

	componentWillUnmount() {
		clearInterval(this.updateTimer);
	}

	componentWillReceiveProps(nextProps) {
		if (
			(nextProps.isAdmin && this.props.artistId !== nextProps.artistId) ||
			this.props.selectedTab !== nextProps.selectedTab
		) {
			this.fetchData(nextProps);
		}
		if (this.props.selectedTab !== nextProps.selectedTab) {
			this.resetSelected();
		}
		if (
			this.props.fetchedChat !== nextProps.fetchedChat &&
			nextProps.fetchedChat &&
			!nextProps.isAdmin
		) {
			this.props.dispatch(fetchArtistNotifications(nextProps.artistId));
		}
	}

	//Fetch the enquiries related to the current artist
	fetchData(props) {
		const { artistId, isAdmin, selectedTab } = props;
		if (isAdmin && artistId === "All") {
			if (selectedTab === "messages") {
				this.props.dispatch(fetchAdminMessages());
			} else {
				this.props.dispatch(fetchAdminEnquiries());
			}
		} else if (artistId !== "All") {
			if (selectedTab === "messages") {
				this.props.dispatch(fetchUserMessages(artistId, "artist"));
			} else {
				this.props.dispatch(fetchArtistEnquiries(artistId));
			}
		}
		this.props.dispatch(clearChat());
	}

	//Initiate an interval that ticks every second
	chatCountdown = () => {
		this.updateTimer = setInterval(this.tick, 1000);
	};

	//Reduces the value of the updateCountdown by 1
	tick = () => {
		if (this.state.openedChat) {
			const newCount = this.state.updateCountdown - 1;
			if (newCount === 0) {
				this.updateChat();
			} else {
				this.setState({
					updateCountdown: newCount,
				});
			}
		}
	};

	//Re-fetch the chat and reset the update countdown
	updateChat = () => {
		const { openedChat } = this.state;
		const { selectedTab, user } = this.props;
		const awsURL = this.getCurrentAwsURL();
		if (awsURL) {
			this.props.dispatch(fetchChat(awsURL, user.id, openedChat, selectedTab));
		}
		this.setState({
			updateCountdown: 5,
		});
	};

	//Sends a user message to the awsURL
	sendChatMessage = (newFileData, type) => {
		const { openedChat } = this.state;
		const { selectedTab, user } = this.props;
		const awsURL = this.getCurrentAwsURL();
		if (awsURL) {
			this.props.dispatch(
				sendMessage(
					newFileData,
					awsURL,
					type,
					user.id,
					openedChat._id,
					selectedTab
				)
			);
		}
	};

	//Opens the modal used to confirm purchase details
	openConfirmModal = () => {
		this.setState({
			confirmModalOpen: true,
		});
	};

	//Closes confirm modal
	closeConfirmModal = () => {
		this.setState({
			confirmModalOpen: false,
		});
	};

	//ONLY ADMINS
	//Used to reset a chat back to the correct bucket
	resetChat = () => {
		const { openedChat } = this.state;
		const { user, t } = this.props;

		if (window.confirm(t("resetWarning"))) {
			this.props.dispatch(resetChat(openedChat, user.id, "Återställd"));
		} else {
			return;
		}
	};

	//Returns the awsURL of the currently open chat
	getCurrentAwsURL = () => {
		const { openedChat } = this.state;
		const { selectedTab, enquiries, messages } = this.props;
		const chatList = selectedTab === "messages" ? messages : enquiries;
		let awsURL = "";
		chatList.forEach((chatObj) => {
			if (chatObj._id === openedChat._id) {
				awsURL = chatObj.chat_location;
			}
		});
		return awsURL;
	};

	//Marks the chatObject as resolved
	closeChat = (chatObj) => {
		const { selectedTab, t } = this.props;
		if (window.confirm(t("chatObj.closeWarning"))) {
			this.props.dispatch(closeChat(chatObj._id, selectedTab));
			this.setState({
				openedStatus: false,
			});
		} else {
			return;
		}
	};

	//Marks the chatObject as unresolved
	unresolveChat = (chatObj) => {
		const { selectedTab, t } = this.props;
		if (window.confirm(t("chatObj.openWarning"))) {
			this.props.dispatch(unresolveChat(chatObj._id, selectedTab));
			this.setState({
				openedStatus: true,
			});
		} else {
			return;
		}
	};

	//Open and fetch the chat of a chatObject
	openChat = (chatObj) => {
		const { selectedTab, user, t } = this.props;
		if (chatObj.chat_location) {
			this.props.dispatch(
				fetchChat(chatObj.chat_location, user.id, chatObj._id, selectedTab)
			);
			this.setState({
				openedChat: chatObj,
				openedStatus: chatObj.unresolved,
				openedWebbLink:
					chatObj.artwork && chatObj.artwork.slug
						? createWebbLink(chatObj.artwork.slug, "artworks")
						: "",
				chatArtwork: chatObj.artwork ? chatObj.artwork._id : null,
			});
		} else {
			window.alert(t("enquiryWarning"));
			return;
		}
	};

	//Sorts the current list of chats based on activity and update
	sortChatList = (chatList) => {
		const open = [];
		const resolved = [];
		chatList.forEach((chatObj) => {
			const active = chatObj.unresolved && chatObj.chat_location;
			if (active) {
				open.push(chatObj);
			} else {
				resolved.push(chatObj);
			}
		});
		open.sort(this.sortUpdate);
		resolved.sort(this.sortUpdate);
		return open.concat(resolved);
	};

	//Sorts objects based on which was updated last
	sortUpdate = (a, b) => {
		const a_updated = a.updated_chat ? a.updated_chat : a.updated_at;
		const b_updated = b.updated_chat ? b.updated_chat : b.updated_at;
		if (moment(a_updated).isAfter(moment(b_updated))) return -1;
		if (moment(a_updated).isBefore(moment(b_updated))) return 1;
		return 0;
	};

	//Draw all user chats
	drawChatList = (chatList) => {
		const { openedChat } = this.state;
		const { selectedTab, user, isAdmin, t } = this.props;
		const rows = [];
		chatList.forEach((chatObj, i) => {
			if (!chatObj.unresolved) chatObj.unresolved = false;
			const name = chatObj.userName
				? formatName(chatObj.userName)
				: t("chatObj.userName");
			let image = "";
			let initials = "";
			let webbLink = "";
			if (selectedTab === "enquiries") {
				image =
					chatObj.artwork && chatObj.artwork.images
						? chatObj.artwork.images[0].src
						: PLACEHOLDER_IMG;
				initials = getInitials(chatObj.userId);
				webbLink =
					chatObj.artwork && chatObj.artwork.slug
						? createWebbLink(chatObj.artwork.slug, "artworks")
						: "";
			} else {
				image =
					chatObj.user && chatObj.user.profilePic
						? chatObj.user.profilePic
						: PLACEHOLDER_IMG;
				initials = getInitials(chatObj.user);
			}
			const selected = openedChat && chatObj._id === openedChat._id;
			const active = chatObj.unresolved && chatObj.chat_location;
			const read =
				chatObj.viewed_by && chatObj.viewed_by.includes(user.id) ? true : false;
			rows.push(
				<div
					key={i}
					className={
						"ChatList__side-container__enquiries__enquiry" +
						(active ? " is-active" : "") +
						(selected ? " is-selected" : "")
					}
				>
					{isAdmin && selectedTab === "enquiries" ? (
						<a
							className="ChatList__side-container__enquiries__enquiry__image"
							style={{ backgroundImage: `url(${image})` }}
							href={webbLink}
							target="_blank"
							onClick={() => this.openChat(chatObj)}
						>
							{image === "false" && <p>{initials}</p>}
						</a>
					) : (
						<div
							className="ChatList__side-container__enquiries__enquiry__image"
							style={{ backgroundImage: `url(${image})` }}
							onClick={() => this.openChat(chatObj)}
						>
							{image === "false" && <p>{initials}</p>}
						</div>
					)}
					<div
						className="ChatList__side-container__enquiries__enquiry__details"
						onClick={() => this.openChat(chatObj)}
					>
						<div
							className={
								"ChatList__side-container__enquiries__enquiry__details__detail" +
								!read
									? " unread"
									: ""
							}
						>
							{name}
						</div>
						<div className="ChatList__side-container__enquiries__enquiry__details__detail">
							{chatObj.updated_chat
								? DateUtil.format(chatObj.updated_chat)
								: DateUtil.format(chatObj.updated_at)}
						</div>
					</div>
					<div className="ChatList__side-container__enquiries__enquiry__close">
						{chatObj.unresolved && (
							<p onClick={() => this.closeChat(chatObj)}>
								{t("chatObj.close")}
							</p>
						)}
						{!chatObj.unresolved && isAdmin && (
							<p onClick={() => this.unresolveChat(chatObj)}>
								{t("chatObj.open")}
							</p>
						)}
					</div>
				</div>
			);
		});
		return rows;
	};

	resetSelected = () => {
		this.setState({
			openedChat: null,
		});
	};

	render() {
		const {
			chat,
			enquiries,
			messages,
			selectedTab,
			enqConfirmed,
			user,
			isAdmin,
			isFetching,
			t,
		} = this.props;
		const {
			openedChat,
			openedStatus,
			openedWebbLink,
			chatArtwork,
			confirmModalOpen,
		} = this.state;

		const displayEnqs = selectedTab === "enquiries";
		const chatList = !displayEnqs ? messages : enquiries;
		const rows = this.drawChatList(this.sortChatList(chatList));

		const useResponsive = appGlobals.useResponsive;
		const emptyURL = displayEnqs ? EMPTY_ENQUIRY_LOGO : EMPTY_MESSAGES_LOGO;
		const emptyHeader = displayEnqs
			? t("chatObj.noEnqHeader")
			: t("chatObj.noMessHeader");
		const emptyText = displayEnqs
			? t("chatObj.noEnqText")
			: t("chatObj.noMessText");

		if (rows.length === 0) {
			return (
				<div className="ChatList">
					{isFetching ? (
						<div className="ChatList__empty">
							<Spinner spinnerName="cube-grid" noFadeIn />
						</div>
					) : (
						<div className="ChatList__empty">
							<p
								style={
									useResponsive ? { marginTop: "20px" } : { marginTop: "60px" }
								}
							>
								{emptyHeader}
							</p>
							<p>{emptyText}</p>
							{displayEnqs && (
								<Link to="/artworks" className="button button--large-button">
									{t("chatObj.noButton")}
								</Link>
							)}
						</div>
					)}
				</div>
			);
		}

		return (
			<div className="ChatList">
				{confirmModalOpen && openedChat && displayEnqs && (
					<ConfirmPurchaseModal
						close={this.closeConfirmModal}
						openedEnq={openedChat._id}
						artworkId={chatArtwork}
					/>
				)}
				{isAdmin && openedChat && (
					<button
						className="ChatList__purple-button--wide ChatList__purple-button--mod_danger ChatList__admin-reset ChatList__admin-reset"
						onClick={() => this.resetChat()}
					>
						RESET CHAT
					</button>
				)}
				<div className="ChatList__side-container">
					<div className="ChatList__side-container__enquiries">{rows}</div>
				</div>
				<div
					className={
						"ChatList__chat-window" + (!openedChat ? " is-hidden" : "")
					}
				>
					<div
						className={
							"ChatList__chat-window__container" +
							(openedChat ? " is-open" : "")
						}
					>
						<ChatWindow
							chatObj={openedChat}
							data={chat[0]}
							sendMessage={this.sendChatMessage}
							confirmEnq={this.openConfirmModal}
							status={openedStatus}
							confirmed={enqConfirmed}
							webbLink={openedWebbLink}
							user={user}
							tab={selectedTab}
							back={this.resetSelected}
							isFetching={isFetching}
							t={t}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		enquiries: {
			expanded,
			enquiries,
			messages,
			chat,
			enqConfirmed,
			isFetching,
			fetchedChat,
		},
	} = state;
	const isAdmin = user.role === "admin";
	return {
		expanded,
		enquiries,
		messages,
		chat,
		enqConfirmed,
		user,
		isAdmin,
		isFetching,
		fetchedChat,
	};
}

const translated = withNamespaces("chat")(ChatList);
export default connect(mapStateToProps)(translated);
