import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Main from "../../components/Main/Main";
import { fetchPrints } from "../../actions/PrintActions";
import { BLACK_IMAGE, thumbnailError } from "../../utils/Helpers";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const ARROW = require("../../../assets/images/icons/chevron_right_white_22px.png");

class Prints extends Component {
	componentWillMount() {
		this.fetchData(this.props);
	}

	fetchData = (props) => {
		this.props.dispatch(fetchPrints("all"));
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	createRows = (prints, t) => {
		return Object.keys(prints).map((key) => {
			const print = prints[key];

			let image = print.images && print.images[0] ? print.images[0] : false;
			const thumbnailSrc = image ? this.getImageSrc(image) : "";
			if (!image) image = BLACK_IMAGE;

			const published = print.publish_status === "published" ? true : false;

			return (
				<Tr key={key}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						<Link to={`/prints/${print.slug || print._id}`}>
							<img
								src={thumbnailSrc}
								className="table-image"
								onError={(e) => thumbnailError(e, e.target.src, image.src)}
								alt="Print image"
							/>
						</Link>
					</Td>
					<Td column="title" value={print.title || "Untitled"}>
						<Link to={`/prints/${print.slug || print._id}`}>
							{print.title || "Untitled"}
						</Link>
					</Td>
					{print.printArtistMod && (
						<Td column="artist" value={print.printArtistMod.name}>
							<Link to={`/influencers/${print.printArtistMod.slug}`}>
								{print.printArtistMod.name}
							</Link>
						</Td>
					)}
					{print.influencerMod && (
						<Td column="influencer" value={print.influencerMod.name}>
							<Link to={`/influencers/${print.influencerMod.slug}`}>
								{print.influencerMod.name}
							</Link>
						</Td>
					)}
					<Td column="edition" value={print.edition || 0}>
						<Link to={`/prints/${print.slug || print._id}`}>
							{print.edition || "Undefined"}
						</Link>
					</Td>
					<Td column="editionSold" value={print.editionSold}>
						<Link to={`/prints/${print.slug || print._id}`}>
							{print.editionSold}
						</Link>
					</Td>
					<Td column="createdAt" value={print.created_at}>
						<Link to={`/prints/${print.slug || print._id}`}>
							{DateUtil.dateMonthShort(
								print.created_at,
								i18next.language,
								true,
								false
							)}
						</Link>
					</Td>
					<Td column="status" value={print.publish_status}>
						<Link
							to={`/prints/${print.slug || print._id}`}
							className={
								"reactable__state" + (published ? " is-published" : "")
							}
						>
							{t(`common:status.${print.publish_status}`)}
						</Link>
					</Td>
				</Tr>
			);
		});
	};

	render() {
		const { prints, isAdmin, t } = this.props;

		if (!isAdmin) return null;

		const rows = this.createRows(prints, t);

		return (
			<Main>
				<div className="prints">
					<h1>Prints</h1>
					<div style={{ marginBottom: "40px" }}>
						<Link
							to="/prints/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							{"Create print"}
						</Link>
					</div>
					<Table
						className="reactable"
						sortable={["title", "edition", "editionSold", "createdAt"]}
						filterable={["title"]}
						itemsPerPage={20}
						pageButtonLimit={10}
					>
						<Thead>
							<Th column="image"> </Th>
							<Th column="title">{"Title"}</Th>
							<Th column="artist">{"Artist"}</Th>
							<Th column="influencer">{"Influencer"}</Th>
							<Th column="edition">{"Edition"}</Th>
							<Th column="editionSold">{"Sold"}</Th>
							<Th column="status">{"Status"}</Th>
							<Th column="createdAt">{"Created at"}</Th>
						</Thead>
						{rows}
					</Table>
				</div>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		print: { allPrints: prints },
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	return { prints, isAdmin };
}

const translated = withNamespaces("common")(Prints);
export default connect(mapStateToProps)(translated);
