import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import TextInput from "../../components/FormInput/TextInput";
import RadioButtons from "../../components/FormInput/RadioButtons";
import {
	fetchSocietyAdmins,
	inviteUserSocietyAdmin,
	removeSocietyAdmins,
} from "../../actions/UserActions";
import {
	showPremiumFunctionalityModal,
	showPremiumUpgradeModal,
} from "../../utils/Dispatcher";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";

const BIN_SVG = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");

// Only used in GallerySettingsForm to invite new admins
class AdditionalSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true,
			newSocietyAdminName: "",
			newSocietyAdminEmail: "",
			newSocietyAdminEmailValid: false,
			societyAdminsFetched: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const prevGallery = this.props.gallery ? this.props.gallery._id : false;
		const nextGallery = nextProps.gallery ? nextProps.gallery._id : false;
		if (
			nextGallery &&
			(prevGallery !== prevGallery || !this.state.societyAdminsFetched)
		) {
			this.props.dispatch(fetchSocietyAdmins(nextGallery));
			this.setState({
				societyAdminsFetched: true,
			});
		}
	}

	toggleExpanded = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	//Update name of new admin
	nameChange = (evt) => {
		const name = evt.currentTarget.value;
		this.setState({
			newSocietyAdminName: name,
		});
	};

	//Update email of new admin
	emailChange = (event) => {
		const email = event.currentTarget.value;
		const emailIsValid = validateEmail(email);
		this.setState({
			newSocietyAdminEmail: email,
			newSocietyAdminEmailValid: emailIsValid,
		});
	};

	//Upgrade user to admin of society
	upgradeUser = (e) => {
		e.preventDefault();
		const { newSocietyAdminName, newSocietyAdminEmail } = this.state;
		const { user, gallery } = this.props;
		const galleryId = gallery._id || gallery;
		this.props
			.dispatch(
				inviteUserSocietyAdmin(
					galleryId,
					newSocietyAdminName,
					newSocietyAdminEmail,
					user.name
				)
			)
			.then((result) => {
				// Result here is undefined. Don't need it anyway.
				this.props.dispatch(fetchSocietyAdmins(galleryId));
			});
	};

	//Remove admin from society
	removeAdminFromSociety = (userToRemove) => {
		const { user, gallery, t } = this.props;
		if (this.props.isFetching) return;
		let confirm = window.confirm(t("account.demoteUserConfirm"));
		const galleryId = gallery._id || gallery;
		if (confirm)
			this.props.dispatch(
				removeSocietyAdmins(userToRemove, galleryId, user.name)
			);
	};

	render() {
		const {
			expanded,
			newSocietyAdminName,
			newSocietyAdminEmail,
			newSocietyAdminEmailValid,
		} = this.state;
		const { user, gallery, societyAdmins, userIsGallery, isFetching, t } =
			this.props;
		const defaultEmail = gallery.email || "";
		const premium = __USE_TIERS__
			? isStandardOrAbove(gallery)
			: isPremium(gallery);
		/*
      applicationEmailAddress and other names do not belong to the user model, but to the gallery model.
      Setting these here makes save button work when these fields change.
      When saving, these values are taken from the user data and saved on the gallery model.
    */
		return (
			<div className="additional-settings">
				{/* !userIsGallery &&
          <legend className="legend-nomargin">{t('account.additionalSettings')}</legend>
        */}
				{/* !userIsGallery &&
          <ExpandButton expanded={ expanded } click={ this.toggleExpanded } />
        */}
				{expanded && (
					<div className="additional-settings__inputs">
						{/*Application address handled under /members for galleries*/}
						{/* false && !userIsGallery &&
              <div className="input-holder input-holder--full input-holder--nopadding">
                <p>{t('account.applicationEmail')}:</p>
                <TextInput
                  {...this.props.applicationEmailAddress}
                  placeholder={ defaultEmail }
                  />
                <p>{t('account.applicationEmailCopy')}:</p>
                <TextInput
                  {...this.props.applicationEmailAddressCopy}
                  placeholder={t('email')}
                  />
              </div>
            */}

						{/*Gallery themes set under /gallery*/}
						{/* false && !userIsGallery &&
              <div className={ b('inputs')('layout') }
                onClick={() => premium ? null : (__USE_TIERS__ ? showPremiumUpgradeModal(this.props.dispatch, 'Standard och Premium') : showPremiumFunctionalityModal(this.props.dispatch, false))}>
                <legend className="legend-nomargin">{t('account.selectLayout')}</legend>
                <RadioButtons
                  {...this.props.layout}
                  options={[
                    { value: 0, label: t('account.themeA')  },
                    { value: 1, label: t('account.themeB') },
                  ]}
                  disabled={ !premium }
                  />
              </div>
            */}

						<legend className="legend-nomargin">
							{t("account.addAdministratorForSociety")}:
						</legend>
						<div className="additional-settings__inputs__new-admins">
							<div className="additional-settings__inputs__new-admins__input">
								<div className="Input Input--text">
									<input
										type="text"
										placeholder={t("name")}
										value={newSocietyAdminName}
										onChange={this.nameChange}
										style={{ marginRight: "40px" }}
									/>
									<button
										type="button"
										className="purple-button--wide button-nomargin"
										disabled={
											!newSocietyAdminName ||
											!newSocietyAdminEmailValid ||
											isFetching
										}
										onClick={this.upgradeUser}
									>
										{t("account.invite")}
									</button>
								</div>
							</div>
							<div className="additional-settings__inputs__new-admins__input">
								<div className="Input Input--text">
									<input
										type="text"
										placeholder={t("email")}
										value={newSocietyAdminEmail}
										onChange={this.emailChange}
									/>
								</div>
							</div>
						</div>
						{Object.keys(societyAdmins).length > 0 && (
							<div className="additional-settings__society-admins">
								<legend className="legend-nomargin">
									{t("account.admins")}
								</legend>
								{Object.keys(societyAdmins).map((key) => {
									const socAdm = societyAdmins[key];
									// Logged in user will appear in list, but can not remove oneself as admin from the art society.
									const isLoggedInUser = socAdm._id === user.id;
									return (
										<div
											className="additional-settings__society-admins__user"
											key={key}
										>
											<BIN_SVG
												className={
													"additional-settings__society-admins__user__remove" +
													(isFetching || isLoggedInUser ? " is-disabled" : "")
												}
												onClick={() =>
													isFetching || isLoggedInUser
														? {}
														: this.removeAdminFromSociety(socAdm)
												}
												data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
											/>
											<p>{`${socAdm.name ? socAdm.name : "Namn saknas"}, ${
												socAdm.email
											}`}</p>
										</div>
									);
								})}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		user: { societyAdmins, isFetching, error },
	} = state;

	const userIsGallery = user.role === "society" || user.role === "author";
	return { user, societyAdmins, isFetching, error, userIsGallery };
};

const translated = withNamespaces("common")(AdditionalSettings);
export default connect(mapStateToProps)(translated);
