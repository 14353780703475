import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { requestTierUpdate } from "../../../actions/GalleryActions";
import { showErrorModal, showSuccessModal } from "../../../actions/AppActions";
import { getTier } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const CHECKMARK = require("../../../../assets/images/svg/fontAwesome/circle-check-solid-green.svg");

class GalleryMembershipModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tier: 0,
			selectedTier: null,
		};
		this.upgrades = [
			{
				tier: "basic",
				price: "1995 kr / år",
				// oldPrice: '1 995 kr /år',
				features: [
					"Snygg hemsida",
					"Medlemshantering",
					"Mejla medlemmar",
					"Utlottningsfunktion",
				],
			},
			// {
			//   tier: 'standard',
			//   price: '6 995 kr / år',
			//   oldPrice: '',
			//   features: [
			//     'Filarkiv',
			//     'Rösta på konst',
			//     'Önskelistor',
			//     'Aktiviteter',
			//     'Bjud in vän',
			//   ]
			// },
			{
				tier: "premium",
				price: "5 995 kr / år",
				// oldPrice: '9 995 kr /år',
				features: [
					"Allt i Bas",
					"Automatiska påminnelser",
					"OSA-funktion",
					"Filarkiv",
					"Önskelista inför utlottning",
				],
			},
		];
	}

	componentDidMount() {
		const { gallery } = this.props;

		if (gallery) {
			this.setState({
				tier: getTier(gallery),
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.gallery !== nextProps.gallery && nextProps.gallery) {
			this.setState({
				tier: getTier(nextProps.gallery),
			});
		}
	}

	// Selects tier that gallery is updated to
	selectTier = (tier) => {
		this.setState({
			selectedTier: tier,
		});
	};

	// Sends gallery tier updates
	sendUpdate = () => {
		const { selectedTier } = this.state;
		const { gallery, user, t } = this.props;
		const curTier = getTier(gallery);
		const newTier = getTier({ billing: { membershipLevel: selectedTier } });
		const requestedHigherTier = curTier < newTier;

		// Display success/error modal after sending update request
		this.props
			.dispatch(requestTierUpdate(selectedTier, gallery._id, user.id, "portal"))
			.then((result) => {
				if (result.error) {
					this.props.dispatch(
						showErrorModal(t("galleryMembership.sendFailure"))
					);
				} else {
					// Check that membershipLevel was updated just in case.
					if (
						requestedHigherTier &&
						result.billing &&
						result.billing.membershipLevel === selectedTier
					) {
						this.props.dispatch(
							showSuccessModal(t("galleryMembership.sendSuccessAndTierUpdated"))
						);
					} else {
						this.props.dispatch(
							showSuccessModal(t("galleryMembership.sendSuccess"))
						);
					}
				}
				this.props.close();
			})
			.catch((err) => {
				console.log("sendUpdate error", err);
				this.props.close();
				this.props.dispatch(showErrorModal("Generic error"));
			});
	};

	getButtonClass = (isSelected, isDisabled) => {
		let buttonClass = "purple-button--wide";
		if (isDisabled) {
			buttonClass += " purple-button--wide--mod_inverse-nohover is-disabled";
		} else if (isSelected) {
			buttonClass += " purple-button--wide--mod_nohover";
		} else if (!isSelected) {
			buttonClass += " purple-button--wide--mod_inverse";
		}

		return buttonClass;
	};

	drawUpgradeOption = () => {
		const { tier, selectedTier } = this.state;
		const { gallery, canTerminate, t } = this.props;
		const levels = ["free", "basic", "standard", "premium", "terminate"];
		const currentTier = levels[tier];
		let columns = [];

		this.upgrades.forEach((upgrade) => {
			const upgradeIsCurrentTier = upgrade.tier === currentTier;
			const upgradeSelected = upgrade.tier === selectedTier;
			// Only disable if it is the current gallery tier
			const disabled = upgradeIsCurrentTier;
			const buttonClass = this.getButtonClass(upgradeSelected, disabled);

			columns.push(
				<div className="GalleryMembershipModal__column" key={upgrade.tier}>
					<button
						className={buttonClass}
						onClick={() =>
							this.selectTier(!upgradeSelected ? upgrade.tier : null)
						}
						disabled={disabled}
					>
						<span>
							{t("galleryMembership.choose") +
								" " +
								t(`galleryMembership.tiers.${upgrade.tier}`)}
						</span>
					</button>
					<div
						className={
							"GalleryMembershipModal__column__prices" +
							(disabled ? " is-disabled" : "")
						}
					>
						{upgrade.oldPrice && (
							<p>
								<s>{upgrade.oldPrice}</s>
							</p>
						)}
						{upgrade.price && (
							<p>
								<b>{upgrade.price}</b>
							</p>
						)}
					</div>
					<ul
						className={
							"GalleryMembershipModal__column__list" +
							(disabled ? " is-disabled" : "")
						}
					>
						{upgrade.features.map((feature, index) => {
							return (
								<li key={index}>
									<CHECKMARK />
									<p>{feature}</p>
								</li>
							);
						})}
					</ul>
					{!disabled ? (
						<a
							href={process.env.BASE_URL + "konstforening"}
							target="_blank"
							className="see-all-functions"
						>
							Se alla funktioner
						</a>
					) : (
						<div className="see-all-functions is-disabled">
							Se alla funktioner
						</div>
					)}
				</div>
			);
		});

		return <div className="GalleryMembershipModal__columns">{columns}</div>;
	};

	render() {
		const { tier, selectedTier } = this.state;
		const {
			header,
			footer = null,
			gallery,
			canTerminate,
			forceChoice,
			t,
		} = this.props;
		const subTiers = this.drawUpgradeOption();

		return (
			<div className="GalleryMembershipModal">
				<div
					className="GalleryMembershipModal__wrapper"
					onClick={() => (!forceChoice ? this.props.close() : null)}
				></div>
				<div className="GalleryMembershipModal__container">
					{!forceChoice && (
						<div
							className="GalleryMembershipModal__close"
							onClick={() => this.props.close()}
						></div>
					)}
					{header || (
						<h3>
							Det ska bli spännande att se vad ni <span>uppgraderar till!</span>
						</h3>
					)}
					{subTiers}
					<div
						className="GalleryMembershipModal__columns"
						style={{ marginTop: "30px" }}
					>
						<div className="GalleryMembershipModal__column"></div>
						<div className="GalleryMembershipModal__column">
							<button
								className="purple-button--wide"
								onClick={() => this.sendUpdate()}
								disabled={!selectedTier}
							>
								<span>{t("common:smooch.send")}</span>
							</button>
						</div>
						<div className="GalleryMembershipModal__column">
							{canTerminate && tier !== 0 && (
								<button
									className={
										this.getButtonClass(
											selectedTier === "terminate",
											tier === 0
										) + " terminate-button"
									}
									onClick={() =>
										this.selectTier(
											selectedTier !== "terminate" ? "terminate" : null
										)
									}
									disabled={tier === 0}
								>
									<span>{t(`galleryMembership.tiers.terminate`)}</span>
								</button>
							)}
						</div>
					</div>
					{footer}
					{/* __DEVELOPMENT__ && forceChoice &&
            <div onClick={() => this.props.close() }>Test close</div>
          */}
				</div>
			</div>
		);
	}
}

const translated = withNamespaces("components")(GalleryMembershipModal);
export default connect(null)(withRouter(translated));
