import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import queryString from "query-string";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormValues,
	getFormInitialValues,
	hasSubmitFailed,
	isSubmitting,
	initialize,
} from "redux-form";
import InvoiceForm from "./InvoiceForm";
import Main from "../../../components/Main/Main";
import MainResp from "../../../components/Main/MainResp";
import { fetchArtwork } from "../../../actions/ArtworkActions";
import { submitInvoice } from "../../../actions/FormActions/ArtworkInvoiceFormActions";
import { showSuccessModal, showErrorModal } from "../../../actions/AppActions";
import { fetchTransaction } from "../../../actions/ArtworkTransactionActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class ArtworkInvoice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTransport: "",
			artworkId: "",
			transId: "",
			proAtSale: false,
		};
	}

	componentDidMount(props) {
		const params = this.getParams(this.props);
		if (params.artworkId) {
			this.props.dispatch(fetchArtwork(params.artworkId));
		}
		if (params.transId) {
			this.props.dispatch(fetchTransaction(params.transId));
		}
		this.setState({
			artworkId: params.artworkId,
			transId: params.transId,
		});
	}

	componentWillReceiveProps(nextProps) {
		//Handle change of artwork
		const thisArtId = this.getParams(this.props).artworkId;
		const nextArtId = this.getParams(nextProps).artworkId;
		if (thisArtId !== nextArtId && nextArtId) {
			this.props.dispatch(fetchArtwork(nextArtId));
		}

		//Handle change of transaction
		const thisTransId = this.getParams(this.props).transId;
		const nextTransId = this.getParams(nextProps).transId;
		if (thisTransId !== nextTransId && nextTransId) {
			this.props.dispatch(fetchArtwork(nextTransId));
		}

		if (this.props.artwork.artwork !== nextProps.artwork.artwork) {
			this.props.dispatch(initialize("invoice", nextProps.artwork.artwork));
		}

		if (
			this.props.transaction !== nextProps.transaction &&
			nextProps.transaction
		) {
			const curTransaction = nextProps.transaction;
			const description = curTransaction.description || "Contact";
			const proAtSale =
				curTransaction === undefined
					? isPremium(curTransaction.artist)
					: curTransaction.proAtSale;
			this.setState({
				selectedTransport: description,
				proAtSale: curTransaction.proAtSale,
			});
		}
	}

	getParams = (props) => {
		const params = queryString.parse(props.location.search);
		return params;
	};

	invoiceSubmit = () => {
		let {
			invoiceForm: { data },
			artwork: { artwork },
			fetchingInvoice,
			t,
		} = this.props;
		const { selectedTransport, artworkId, transId, proAtSale } = this.state;

		const artist = artwork.artistMod ? artwork.artistMod : null;
		const noCommission = artist.noCommission || false;

		//Assure that a previous submission is not being handled before allowing
		// further submission
		if (window.confirm(t("invoice.warning")) && !fetchingInvoice) {
			this.props
				.dispatch(
					submitInvoice(
						artworkId,
						artist._id,
						proAtSale,
						noCommission,
						data,
						selectedTransport,
						transId
					)
				)
				.then((res) => {
					if (res === "SUCCESS") {
						this.props.dispatch(showSuccessModal(t("invoice.submitSuccess")));
						this.props.router.push("/artist-invoices");
					} else {
						if (!res.error) {
							this.props.dispatch(showErrorModal(t("invoice.submitFailure")));
						}
						console.log("res.error", res.error);
					}
				});
		} else {
			return null;
		}
	};

	render() {
		const {
			artist: { artist },
			fetchingInvoice,
			t,
		} = this.props;
		const { selectedTransport } = this.state;
		//Responsive data
		const isResponsive = appGlobals.useResponsive;
		const MainComponent = isResponsive ? MainResp : Main;

		return (
			<div className="ArtworkInvoice">
				<MainComponent contentClassName="slim-content">
					<div className="ArtworkInvoice__container">
						<InvoiceForm
							onSubmit={this.invoiceSubmit}
							selectedTransport={selectedTransport}
							fetchingInvoice={fetchingInvoice}
						/>
					</div>
				</MainComponent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artwork,
		artist,
		artworkInvoice: { isFetching: fetchingInvoice },
		artworkTransaction: { transaction },
	} = state;
	const invoiceForm = {
		submitting: isSubmitting("invoice")(state),
		submitSucceeded: hasSubmitSucceeded("invoice")(state),
		submitFailed: hasSubmitFailed("invoice")(state),
		data: getFormValues("invoice")(state),
		initial: getFormInitialValues("invoice")(state),
	};

	return { artwork, artist, invoiceForm, transaction, fetchingInvoice };
}

const translated = withNamespaces("artworks")(ArtworkInvoice);
export default connect(mapStateToProps)(withRouter(translated));
