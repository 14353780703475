import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import { Table, Thead, Th, Tr, Td } from "reactable";
import Main from "../../components/Main/Main";
import {
	fetchLatestRatedShowsList,
	fetchLatestRatedArtworksAll,
	fetchLatestRatedVenuesAll,
	fetchLatestRatedArtistsAll,
	fetchLatestRatedArticlesAll,
} from "@artworkslab/sharedmodules/src/actions/RatedActions";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

class Rated extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.dispatch(fetchLatestRatedShowsList());
		this.props.dispatch(fetchLatestRatedVenuesAll(10));
		this.props.dispatch(fetchLatestRatedArtistsAll(10));
		this.props.dispatch(fetchLatestRatedArticlesAll(10));
		this.props.dispatch(fetchLatestRatedArtworksAll(20));
	}

	createShowsTable = (showsRatedLists) => {
		let rows = [];
		let order = 1;
		showsRatedLists.forEach((ratedList) => {
			let orderSet = false;
			Object.keys(ratedList.entries).map((key) => {
				const show = ratedList.entries[key];
				const image = show.featuredImage ? show.featuredImage.src : null;
				const available = DateUtil.available(show.startDate, show.endDate);
				let orderValue = "";
				if (available !== "past" && !orderSet && show.status === "published") {
					orderValue = order++;
					orderSet = true;
				}
				rows.push(
					<Tr key={show._id}>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{image ? (
								<Link to={"/shows/" + show._id}>
									<div
										className="table-image"
										style={{ backgroundImage: "url(" + image + ")" }}
									/>
								</Link>
							) : null}
						</Td>
						<Td column="title" value={show.title}>
							<Link to={"/shows/" + show._id}>{show.title}</Link>
						</Td>
						<Td column="order" value={`${orderValue}`}>
							<Link to={"/shows/" + show._id}>{`${orderValue}`}</Link>
						</Td>
						<Td column="rating" value={show.editorRating}>
							<Link to={"/shows/" + show._id}>{show.editorRating}</Link>
						</Td>
						<Td column="rating date" value={show.editorRatingDate}>
							<Link to={"/shows/" + show._id}>
								{moment(show.editorRatingDate).format("MMM Do, YYYY")}
							</Link>
						</Td>
						<Td column="open" value={available}>
							<Link to={"/shows/" + show._id}>{available}</Link>
						</Td>
						<Td column="status" value={show.status}>
							<Link to={"/shows/" + show._id}>{show.status}</Link>
						</Td>
					</Tr>
				);
			});
		});

		return (
			<Table className="reactable">
				<Thead>
					<Th column="image">Image</Th>
					<Th column="title">Title</Th>
					<Th column="order">Order</Th>
					<Th column="rating">Rating</Th>
					<Th column="rating date" style={{ width: 150 }}>
						Rating date
					</Th>
					<Th column="open" style={{ width: 100 }}>
						Open
					</Th>
					<Th column="status" style={{ width: 100 }}>
						Status
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	createArtworksTable = (artworks) => {
		let rows = [];
		let order = 1;
		artworks.forEach((artwork) => {
			const image = artwork.images.length > 0 ? artwork.images[0].src : null;
			let orderValue = "";
			if (
				artwork.status === "for-sale" &&
				artwork.price !== null &&
				artwork.publish_status === "published"
			) {
				orderValue = order++;
			}
			rows.push(
				<Tr key={artwork._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{image ? (
							<Link to={"/artworks/" + artwork._id}>
								<div
									className="table-image"
									style={{ backgroundImage: "url(" + image + ")" }}
								/>
							</Link>
						) : null}
					</Td>
					<Td column="title" value={artwork.title}>
						<Link to={"/artworks/" + artwork._id}>{artwork.title}</Link>
					</Td>
					<Td column="order" value={`${orderValue}`}>
						<Link to={"/artworks/" + artwork._id}>{`${orderValue}`}</Link>
					</Td>
					<Td column="rating" value={artwork.editorRating}>
						<Link to={"/artworks/" + artwork._id}>{artwork.editorRating}</Link>
					</Td>
					<Td column="rating date" value={artwork.editorRatingDate}>
						<Link to={"/artworks/" + artwork._id}>
							{moment(artwork.editorRatingDate).format("MMM Do, YYYY")}
						</Link>
					</Td>
					<Td column="status" value={artwork.status}>
						<Link to={"/artworks/" + artwork._id}>{artwork.status}</Link>
					</Td>
					<Td column="published" value={artwork.publish_status}>
						<Link to={"/artworks/" + artwork._id}>
							{artwork.publish_status}
						</Link>
					</Td>
				</Tr>
			);
		});

		return (
			<Table className="reactable">
				<Thead>
					<Th column="image">Image</Th>
					<Th column="title">Title</Th>
					<Th column="order">Order</Th>
					<Th column="rating">Rating</Th>
					<Th column="rating date" style={{ width: 150 }}>
						Rating date
					</Th>
					<Th column="status" style={{ width: 100 }}>
						Status
					</Th>
					<Th column="published" style={{ width: 100 }}>
						Published
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	createVenuesTable = (venues) => {
		let rows = [];
		let order = 1;
		venues.forEach((venue) => {
			const image = venue.featuredImage ? venue.featuredImage.src : null;
			let orderValue = "";
			if (venue.publish_status === "published") {
				orderValue = order++;
			}
			rows.push(
				<Tr key={venue._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{image ? (
							<Link to={"/venues/" + venue._id}>
								<div
									className="table-image"
									style={{ backgroundImage: "url(" + image + ")" }}
								/>
							</Link>
						) : null}
					</Td>
					<Td column="name" value={venue.name}>
						<Link to={"/venues/" + venue._id}>{venue.name}</Link>
					</Td>
					<Td column="order" value={`${orderValue}`}>
						<Link to={"/venues/" + venue._id}>{`${orderValue}`}</Link>
					</Td>
					<Td column="rating" value={venue.editorRating}>
						<Link to={"/venues/" + venue._id}>{venue.editorRating}</Link>
					</Td>
					<Td column="rating date" value={venue.editorRatingDate}>
						<Link to={"/venues/" + venue._id}>
							{moment(venue.editorRatingDate).format("MMM Do, YYYY")}
						</Link>
					</Td>
					<Td column="status" value={venue.publish_status}>
						<Link to={"/venues/" + venue._id}>{venue.publish_status}</Link>
					</Td>
				</Tr>
			);
		});

		return (
			<Table className="reactable">
				<Thead>
					<Th column="image">Image</Th>
					<Th column="name">Name</Th>
					<Th column="order">Order</Th>
					<Th column="rating">Rating</Th>
					<Th column="rating date" style={{ width: 150 }}>
						Rating date
					</Th>
					<Th column="status" style={{ width: 100 }}>
						Status
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	createArtistsTable = (artists) => {
		let rows = [];
		let order = 1;
		let currentRating = -1;
		artists.forEach((artist) => {
			const image = artist.featuredImage ? artist.featuredImage.src : null;
			let orderValue = "";
			if (
				artist.status === "published" &&
				artist.editorRating !== currentRating
			) {
				orderValue = order++;
				currentRating = artist.editorRating;
			}
			rows.push(
				<Tr key={artist._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{image ? (
							<Link to={"/artists/" + artist._id}>
								<div
									className="table-image"
									style={{ backgroundImage: "url(" + image + ")" }}
								/>
							</Link>
						) : null}
					</Td>
					<Td column="name" value={artist.name}>
						<Link to={"/artists/" + artist._id}>{artist.name}</Link>
					</Td>
					<Td column="order" value={`${orderValue}`}>
						<Link to={"/artists/" + artist._id}>{`${orderValue}`}</Link>
					</Td>
					<Td column="rating" value={artist.editorRating}>
						<Link to={"/artists/" + artist._id}>{artist.editorRating}</Link>
					</Td>
					<Td column="rating date" value={artist.editorRatingDate}>
						<Link to={"/artists/" + artist._id}>
							{moment(artist.editorRatingDate).format("MMM Do, YYYY")}
						</Link>
					</Td>
					<Td column="status" value={artist.status}>
						<Link to={"/artists/" + artist._id}>{artist.status}</Link>
					</Td>
				</Tr>
			);
		});

		return (
			<Table className="reactable">
				<Thead>
					<Th column="image">Image</Th>
					<Th column="name">Name</Th>
					<Th column="order">Order</Th>
					<Th column="rating">Rating</Th>
					<Th column="rating date" style={{ width: 150 }}>
						Rating date
					</Th>
					<Th column="status" style={{ width: 100 }}>
						Status
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	createArticlesTable = (articles) => {
		let rows = [];
		let order = 1;
		articles.forEach((article) => {
			const image = article.featuredImage ? article.featuredImage.src : null;

			let articleStatus = "Ok";
			const today = moment();
			const oldest = moment("2015-01-01");
			if (moment(article.updated_at).isBefore(oldest)) {
				// Some articles from 2014 have no content
				articleStatus = "Too old";
			} else if (moment(article.publish.availiable_from).isAfter(today)) {
				articleStatus = `Available from ${moment(
					article.publish.availiable_from
				).format("MMM Do, YYYY")}`;
			}

			let orderValue = "";
			if (article.publish.status === "published" && articleStatus === "Ok") {
				orderValue = order++;
			}
			rows.push(
				<Tr key={article._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{image ? (
							<Link to={"/posts/" + article._id}>
								<div
									className="table-image"
									style={{ backgroundImage: "url(" + image + ")" }}
								/>
							</Link>
						) : null}
					</Td>
					<Td column="title" value={article.title}>
						<Link to={"/posts/" + article._id}>{article.name}</Link>
					</Td>
					<Td column="order" value={`${orderValue}`}>
						<Link to={"/posts/" + article._id}>{`${orderValue}`}</Link>
					</Td>
					<Td column="rating" value={article.editorRating}>
						<Link to={"/posts/" + article._id}>{article.editorRating}</Link>
					</Td>
					<Td column="rating date" value={article.editorRatingDate}>
						<Link to={"/posts/" + article._id}>
							{moment(article.editorRatingDate).format("MMM Do, YYYY")}
						</Link>
					</Td>
					<Td column="status" value={articleStatus}>
						<Link to={"/posts/" + article._id}>{articleStatus}</Link>
					</Td>
					<Td column="published" value={article.publish.status}>
						<Link to={"/posts/" + article._id}>{article.publish.status}</Link>
					</Td>
				</Tr>
			);
		});

		return (
			<Table className="reactable">
				<Thead>
					<Th column="image">Image</Th>
					<Th column="name">Name</Th>
					<Th column="order">Order</Th>
					<Th column="rating">Rating</Th>
					<Th column="rating date" style={{ width: 150 }}>
						Rating date
					</Th>
					<Th column="status" style={{ width: 100 }}>
						Status
					</Th>
					<Th column="published" style={{ width: 100 }}>
						Published
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	render() {
		const { isAdmin, shows, artworks, venues, artists, articles } = this.props;
		if (!isAdmin) return null;
		const artworksTable = this.createArtworksTable(artworks);
		const showsTable = this.createShowsTable(shows);
		const venuesTable = this.createVenuesTable(venues);
		const artistsTable = this.createArtistsTable(artists);
		const articlesTable = this.createArticlesTable(articles);
		return (
			<Main>
				<legend className="legend legend-nomargin">Artworks</legend>
				{artworksTable}
				<legend className="legend legend-nomargin">Venues</legend>
				{venuesTable}
				<legend className="legend legend-nomargin">Artists</legend>
				{artistsTable}
				<legend className="legend legend-nomargin">Articles</legend>
				{articlesTable}
				<legend className="legend legend-nomargin">Shows</legend>
				{showsTable}
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin },
		rated: {
			latestRatedShowsList: shows,
			latestRatedArtworksAll: artworks,
			latestRatedVenuesAll: venues,
			latestRatedArtistsAll: artists,
			latestRatedArticlesAll: articles,
		},
	} = state;
	return {
		isAdmin,
		shows,
		artworks,
		venues,
		artists,
		articles,
	};
}

export default connect(mapStateToProps)(Rated);
