import {
  setAccess,
  getAccess
}   from '../AccessActions'

import {
  initialize,
  stopSubmit,
  startSubmit,
}   from 'redux-form'

import Validator              from 'validatorjs'

import {  getObjectProp,
          hasObjectProp }     from '../../utils/Helpers'

export const SET_TYPE = 'artworksapp/access/SET_TYPE'

export const SHOW_ACCESS_MODAL = 'artworksapp/access/SHOW_ACCESS_MODAL'
export const HIDE_ACCESS_MODAL = 'artworksapp/access/HIDE_ACCESS_MODAL'

export function submitAccess(id, data, type = 'shows') {
  return (dispatch, getState) => {
    const { user } = getState()
    let customErrors = {}
    let rules = {}
    const emails = []
    const users = []
    data.allowed.map((entry) => {
      if (entry.email) {
        emails.push(entry.email)
      }
      if (entry.user) {
        users.push(entry.user)
      }
    })
    const accessData = {
      emails,
      users
    }
    const validation = new Validator(data, rules)
    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)
    if (Object.keys(errors) < 1) {
      dispatch( startSubmit('access') )
      return dispatch( setAccess(id, accessData, type) ).then((data) => {
          dispatch( initialize('access', data.response, null, {keepSubmitSucceeded:true}) )
        })
        .catch((error) => {
          console.log(error);
        })
    }else{
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('access', errors) )
    }
  }
}

export function fetchAccess(id, type = 'shows'){
  return (dispatch, getState) => {
    return dispatch( getAccess(id, type) ).then(() => {
      dispatch( showAccessModal() )
      dispatch( setType(type) )
      const { access } = getState().access
      if (access && access._id) {
        return dispatch( loadAccess(access) )
      }else{
        return dispatch( initAccess(id, type) )
      }
    })
  }
}

//Loads data to form
export function loadAccess(access) {
  return (dispatch, getState) => {
    dispatch( initialize('access', access) )
  }
}

//Sets default data for form
export function initAccess(id, type) {
  return (dispatch, getState) => {
    const access = { _id: id, allowed: [] }
    dispatch( initialize('access', access) )
  }
}

export function setType(type) {
  return {
    type: SET_TYPE,
    accessType: type
  }
}

export function showAccessModal(){
  return {
    type: SHOW_ACCESS_MODAL
  }
}

export function hideAccessModal(){
  return {
    type: HIDE_ACCESS_MODAL
  }
}
