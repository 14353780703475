import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormValues,
	getFormInitialValues,
	hasSubmitFailed,
	isSubmitting,
	initialize,
} from "redux-form";
import PurchaseArtworkForm from "./Forms/PurchaseArtworkForm";
import { fetchArtwork } from "../../../actions/ArtworkActions";
import { submitConfirmPurchase } from "../../../actions/FormActions/EnquiryFormActions";
import { submitManualOrder } from "../../../actions/FormActions/ArtworkInvoiceFormActions";

class ConfirmPurchaseModal extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount(props) {
		const { artworkId } = this.props;
		this.props.dispatch(fetchArtwork(artworkId));
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.artwork.artwork !== nextProps.artwork.artwork) {
			this.props.dispatch(
				initialize("purchaseArtwork", nextProps.artwork.artwork)
			);
		}
	}

	//Updates artwork and reserves it for the buyer
	purchaseSubmit = () => {
		const {
			purchaseForm: { artwork, initial },
			openedEnq,
			manualOrder = false,
			t,
		} = this.props;
		if (
			window.confirm(
				manualOrder ? t("forms.manualWarning") : t("forms.purchaseWarning")
			)
		) {
			//Switch dispatch relative to manualInvoice or confirmPurchase usage
			const res = manualOrder
				? this.props.dispatch(submitManualOrder(artwork, initial))
				: this.props.dispatch(
						submitConfirmPurchase(openedEnq, artwork, initial)
				  );
			//Close form modal if submission was successfull
			if (res === "SUCCESS") {
				this.props.close();
			}
		} else {
			return null;
		}
	};

	render() {
		const { manualOrder = false, artist, artwork, t } = this.props;

		return (
			<div className="ConfirmPurchaseModal">
				<div className="ConfirmPurchaseModal__container">
					<div
						className="ConfirmPurchaseModal__close"
						onClick={() => this.props.close()}
					/>
					<div className="ConfirmPurchaseModal__content">
						<PurchaseArtworkForm
							onSubmit={this.purchaseSubmit}
							manualOrder={manualOrder}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { artwork, artist } = state;
	const purchaseForm = {
		submitting: isSubmitting("purchaseArtwork")(state),
		submitSucceeded: hasSubmitSucceeded("purchaseArtwork")(state),
		submitFailed: hasSubmitFailed("purchaseArtwork")(state),
		artwork: getFormValues("purchaseArtwork")(state),
		initial: getFormInitialValues("purchaseArtwork")(state),
	};

	return { artwork, artist, purchaseForm };
}

const translated = withNamespaces("chat")(ConfirmPurchaseModal);
export default connect(mapStateToProps)(withRouter(translated));
