import { CALL_API } from '../utils/api'

// fetch shows

export const SCRAPED_SHOWS_REQUEST = 'SCRAPED_SHOWS_REQUEST'
export const SCRAPED_SHOWS_SUCCESS = 'SCRAPED_SHOWS_SUCCESS'
export const SCRAPED_SHOWS_FAILURE = 'SCRAPED_SHOWS_FAILURE'

// Uses the API to get a show
export function fetchScrapedShows() {
	return {
		[CALL_API]: {
			endpoint: 'scraped/shows',
			authenticated: true,
			types: [SCRAPED_SHOWS_REQUEST, SCRAPED_SHOWS_SUCCESS, SCRAPED_SHOWS_FAILURE]
		}
	}
}

// fetch shows

export const SCRAPED_SHOW_REQUEST = 'SCRAPED_SHOW_REQUEST'
export const SCRAPED_SHOW_SUCCESS = 'SCRAPED_SHOW_SUCCESS'
export const SCRAPED_SHOW_FAILURE = 'SCRAPED_SHOW_FAILURE'

// Uses the API to get a show
export function fetchScrapedShow(showId) {
	const endpoint = `scraped/shows/${showId}`
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [SCRAPED_SHOW_REQUEST, SCRAPED_SHOW_SUCCESS, SCRAPED_SHOW_FAILURE]
		}
	}
}

export const ACCEPT_SCRAPED_SHOW_REQUEST = 'ACCEPT_SCRAPED_SHOW_REQUEST'
export const ACCEPT_SCRAPED_SHOW_SUCCESS = 'ACCEPT_SCRAPED_SHOW_SUCCESS'
export const ACCEPT_SCRAPED_SHOW_FAILURE = 'ACCEPT_SCRAPED_SHOW_FAILURE'

// Uses the API to get a show
export function acceptScrapedShow(showId) {
	const endpoint = `scraped/shows/${showId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: { status: 'accepted' },
			authenticated: true,
			types: [ACCEPT_SCRAPED_SHOW_REQUEST, ACCEPT_SCRAPED_SHOW_SUCCESS, ACCEPT_SCRAPED_SHOW_FAILURE]
		}
	}
}

export const REJECT_SCRAPED_SHOW_REQUEST = 'REJECT_SCRAPED_SHOW_REQUEST'
export const REJECT_SCRAPED_SHOW_SUCCESS = 'REJECT_SCRAPED_SHOW_SUCCESS'
export const REJECT_SCRAPED_SHOW_FAILURE = 'REJECT_SCRAPED_SHOW_FAILURE'

// Uses the API to get a show
export function rejectScrapedShow(showId) {
	const endpoint = `scraped/shows/${showId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: { status: 'rejected' },
			authenticated: true,
			types: [REJECT_SCRAPED_SHOW_REQUEST, REJECT_SCRAPED_SHOW_SUCCESS, REJECT_SCRAPED_SHOW_FAILURE]
		}
	}
}


/*
*
*	Artists actions
*
*/

export const SCRAPED_ARTISTS_REQUEST = 'SCRAPED_ARTISTS_REQUEST'
export const SCRAPED_ARTISTS_SUCCESS = 'SCRAPED_ARTISTS_SUCCESS'
export const SCRAPED_ARTISTS_FAILURE = 'SCRAPED_ARTISTS_FAILURE'

// Uses the API to get a show
export function fetchScrapedArtists() {
	return {
		[CALL_API]: {
			endpoint: 'scraped/artists',
			authenticated: true,
			types: [SCRAPED_ARTISTS_REQUEST, SCRAPED_ARTISTS_SUCCESS, SCRAPED_ARTISTS_FAILURE]
		}
	}
}

export const SCRAPED_ARTIST_REQUEST = 'SCRAPED_ARTIST_REQUEST'
export const SCRAPED_ARTIST_SUCCESS = 'SCRAPED_ARTIST_SUCCESS'
export const SCRAPED_ARTIST_FAILURE = 'SCRAPED_ARTIST_FAILURE'

// Uses the API to get a show
export function fetchScrapedArtist(artistId) {
	const endpoint = `scraped/artists/${artistId}`
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [SCRAPED_ARTIST_REQUEST, SCRAPED_ARTIST_SUCCESS, SCRAPED_ARTIST_FAILURE]
		}
	}
}

export const ACCEPT_SCRAPED_ARTIST_REQUEST = 'ACCEPT_SCRAPED_ARTIST_REQUEST'
export const ACCEPT_SCRAPED_ARTIST_SUCCESS = 'ACCEPT_SCRAPED_ARTIST_SUCCESS'
export const ACCEPT_SCRAPED_ARTIST_FAILURE = 'ACCEPT_SCRAPED_ARTIST_FAILURE'

// Uses the API to get a show
export function acceptScrapedArtist(artistId) {
	const endpoint = `scraped/artists/${artistId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: { status: 'accepted' },
			authenticated: true,
			types: [ACCEPT_SCRAPED_ARTIST_REQUEST, ACCEPT_SCRAPED_ARTIST_SUCCESS, ACCEPT_SCRAPED_ARTIST_FAILURE]
		}
	}
}

export const REJECT_SCRAPED_ARTIST_REQUEST = 'REJECT_SCRAPED_ARTIST_REQUEST'
export const REJECT_SCRAPED_ARTIST_SUCCESS = 'REJECT_SCRAPED_ARTIST_SUCCESS'
export const REJECT_SCRAPED_ARTIST_FAILURE = 'REJECT_SCRAPED_ARTIST_FAILURE'

// Uses the API to get a show
export function rejectScrapedArtist(artistId) {
	const endpoint = `scraped/artists/${artistId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: { status: 'rejected' },
			authenticated: true,
			types: [REJECT_SCRAPED_ARTIST_REQUEST, REJECT_SCRAPED_ARTIST_SUCCESS, REJECT_SCRAPED_ARTIST_FAILURE]
		}
	}
}


/*
*
*	Gallery actions
*
*/

export const SCRAPED_GALLERIES_REQUEST = 'SCRAPED_GALLERIES_REQUEST'
export const SCRAPED_GALLERIES_SUCCESS = 'SCRAPED_GALLERIES_SUCCESS'
export const SCRAPED_GALLERIES_FAILURE = 'SCRAPED_GALLERIES_FAILURE'

// Uses the API to get a show
export function fetchScrapedGalleries() {
	return {
		[CALL_API]: {
			endpoint: 'scraped/galleries',
			authenticated: true,
			types: [SCRAPED_GALLERIES_REQUEST, SCRAPED_GALLERIES_SUCCESS, SCRAPED_GALLERIES_FAILURE]
		}
	}
}

export const SCRAPED_GALLERY_REQUEST = 'SCRAPED_GALLERY_REQUEST'
export const SCRAPED_GALLERY_SUCCESS = 'SCRAPED_GALLERY_SUCCESS'
export const SCRAPED_GALLERY_FAILURE = 'SCRAPED_GALLERY_FAILURE'

// Uses the API to get a show
export function fetchScrapedGallery(galleryId) {
	const endpoint = `scraped/galleries/${galleryId}`
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [SCRAPED_GALLERY_REQUEST, SCRAPED_GALLERY_SUCCESS, SCRAPED_GALLERY_FAILURE]
		}
	}
}

export const ACCEPT_SCRAPED_GALLERY_REQUEST = 'ACCEPT_SCRAPED_GALLERY_REQUEST'
export const ACCEPT_SCRAPED_GALLERY_SUCCESS = 'ACCEPT_SCRAPED_GALLERY_SUCCESS'
export const ACCEPT_SCRAPED_GALLERY_FAILURE = 'ACCEPT_SCRAPED_GALLERY_FAILURE'

// Uses the API to get a show
export function acceptScrapedGallery(galleryId) {
	const endpoint = `scraped/galleries/${galleryId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: { status: 'accepted' },
			authenticated: true,
			types: [ACCEPT_SCRAPED_GALLERY_REQUEST, ACCEPT_SCRAPED_GALLERY_SUCCESS, ACCEPT_SCRAPED_GALLERY_FAILURE]
		}
	}
}

export const REJECT_SCRAPED_GALLERY_REQUEST = 'REJECT_SCRAPED_GALLERY_REQUEST'
export const REJECT_SCRAPED_GALLERY_SUCCESS = 'REJECT_SCRAPED_GALLERY_SUCCESS'
export const REJECT_SCRAPED_GALLERY_FAILURE = 'REJECT_SCRAPED_GALLERY_FAILURE'

// Uses the API to get a show
export function rejectScrapedGallery(galleryId) {
	const endpoint = `scraped/galleries/${galleryId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: { status: 'rejected' },
			authenticated: true,
			types: [REJECT_SCRAPED_GALLERY_REQUEST, REJECT_SCRAPED_GALLERY_SUCCESS, REJECT_SCRAPED_GALLERY_FAILURE]
		}
	}
}
