import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../../components/Table/Table";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from "moment";
import Main from "../../../components/Main/Main";
import InspectDetails from "../../../components/Modal/Custom/Inspect/InspectDetails";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import { capitalizeString } from "../../../utils/FormatHelper";
import { fetchCancelledSubscribers } from "../../../actions/SubscriptionActions";
import "react-day-picker/lib/style.css";

class CancelledSubscriptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inspectOpen: false,
			selectedItem: {},
		};
	}

	componentWillMount() {
		this.props.dispatch(fetchCancelledSubscribers());
	}

	//Opens inspect modal
	inspectItem = (item) => {
		this.setState({
			inspectOpen: true,
			selectedItem: item,
		});
	};

	//Closes inspect modal
	closeInspect = () => {
		this.setState({
			inspectOpen: false,
			selectedItem: {},
		});
	};

	//Retrieves the date of the last ended subscription
	lastEnded = (item) => {
		//Lists of different subscriptions
		const stripeSubs = item.stripeSubscriptions ? item.stripeSubscriptions : [];
		const klarnaSubs =
			item.billing && item.billing.cancelledSubscriptions
				? item.billing.cancelledSubscriptions
				: [];

		//Last ended stripe
		let lastEndedStripe = null;
		stripeSubs.forEach((sub, i) => {
			if (sub.status === "canceled" || sub.status === "incomplete_expired") {
				lastEndedStripe = sub.currentPeriodEndDate;
			}
		});

		//Last ended klarna
		const lastEndedKlarna =
			klarnaSubs.length > 0 ? klarnaSubs[klarnaSubs.length - 1].date : null;

		//Prioritize latest
		let ended = null;
		if (lastEndedStripe && lastEndedKlarna) {
			ended = moment(lastEndedStripe).isAfter(moment(lastEndedKlarna))
				? DateUtil.dateMonthShort(lastEndedStripe, "en", true)
				: DateUtil.dateMonthShort(lastEndedKlarna, "en", true);
		} else {
			ended = lastEndedStripe
				? DateUtil.dateMonthShort(lastEndedStripe, "en", true)
				: DateUtil.dateMonthShort(lastEndedKlarna, "en", true);
		}

		return ended;
	};

	renderTable = (rows) => {
		return (
			<Table
				className="reactable"
				sortable={[
					"name",
					"email",
					"memberSince",
					"membershipLevel",
					"plan",
					"ended",
					"service",
					"endedSubs",
					{
						column: "memberSince",
						//Sort dates
						sortFunction: function (a, b) {
							return new Date(b) - new Date(a);
						},
					},
					{
						column: "ended",
						sortFunction: function (a, b) {
							//Sort dates
							console.log("a", a);
							console.log("b", b);
							return new Date(b) - new Date(a);
						},
					},
				]}
				defaultSort={{ column: "ended", direction: "asc" }}
				filterable={false}
				filterPlaceholder="Search subscriptions"
				itemsPerPage={20}
			>
				<Thead>
					<Th column="name">Name</Th>
					<Th column="email">Email</Th>
					<Th column="memberSince">Member since</Th>
					<Th column="membershipLevel">Membership</Th>
					<Th column="plan">Plan</Th>
					<Th column="ended">Ended</Th>
					<Th column="service">Service</Th>
					<Th column="subscriptions">Subscriptions</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	renderRows = () => {
		const { cancelled } = this.props;
		if (!cancelled.length) {
			return null;
		}
		let rows = [];

		cancelled.forEach((item, i) => {
			const {
				premiumDate,
				premiumType,
				proSource,
				klarnaSubscriptionOrderId,
				cancelledSubscriptions,
				membershipLevel,
			} = item.billing;
			const memberSince = premiumDate
				? DateUtil.dateMonthShort(premiumDate, "en", true)
				: "Not set";
			const level =
				membershipLevel && membershipLevel === "premium" ? "Pro" : "Bas";
			const plan = premiumType ? capitalizeString(premiumType) : "-";
			const ended = this.lastEnded(item);
			const service = proSource ? capitalizeString(proSource) : "Klarna";
			//Artists can have multiple cancelled subscriptions of each type
			const cancelledKlarna = cancelledSubscriptions
				? cancelledSubscriptions
				: [];
			const subscriptions =
				cancelledKlarna.length + item.stripeSubscriptions.length;

			const style = { cursor: "pointer" };

			rows.push(
				<Tr key={item._id}>
					<Td column="name" value={item.name}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{item.name}
						</p>
					</Td>
					<Td column="email" value={item.email}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{item.email}
						</p>
					</Td>
					<Td column="memberSince" value={memberSince}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{memberSince}
						</p>
					</Td>
					<Td column="membershipLevel" value={level}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{level}
						</p>
					</Td>
					<Td column="plan" value={plan}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{plan}
						</p>
					</Td>
					<Td column="ended" value={ended}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{ended}
						</p>
					</Td>
					<Td column="service" value={service}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{service}
						</p>
					</Td>
					<Td column="subscriptions" value={subscriptions}>
						<p onClick={() => this.inspectItem(item)} style={style}>
							{subscriptions}
						</p>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const { cancelled } = this.props;
		const { inspectOpen, selectedItem } = this.state;
		const cancelledCount = cancelled.length;
		const table = this.renderTable(this.renderRows());

		return (
			<Main>
				{inspectOpen && (
					<InspectDetails
						item={selectedItem}
						close={() => this.closeInspect()}
					/>
				)}
				<h2
					style={{ marginBottom: "10px" }}
				>{`Cancelled/Ended Pro subscriptions (${cancelledCount})`}</h2>
				{table}
			</Main>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		subscription: { subscribers: cancelled, isFetching },
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	return { cancelled, isAdmin, isFetching };
};

export default connect(mapStateToProps)(CancelledSubscriptions);
