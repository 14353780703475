import {
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR
}                         from '../../actions/FormActions/UserFormActions'
import {
  USER_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
}                         from '../../actions/UserActions'

export function userFormReducer(state = {
  showSuccess: false,
  showError: false,
  error: null
}, action) {
  switch (action.type) {
    case USER_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false
      })
    case USER_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true
      })
    case USER_UPDATE_FAILURE:
      return Object.assign({}, state, {
        showError: true,
        error: action.message
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    default:
      return state
  }
}
