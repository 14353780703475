import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import Main from "../../components/Main/Main";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import { withNamespaces } from "react-i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import { deleteMention } from "../../actions/ArtistActions";
import { fetchMentionsForArtist } from "@artworkslab/sharedmodules/src/actions/ArtistActions";

const BIN = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");

class ArtistMentions extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		const { isArtist, artist } = this.props;
		if (isArtist) {
			this.props.dispatch(fetchMentionsForArtist(artist._id));
		}
	}

	renderTable(isAdmin, rows, maxItems, t) {
		return (
			<Table
				className="reactable"
				sortable={["category", "title"]}
				filterable={isAdmin ? ["category", "title"] : false}
				filterPlaceholder={"Search mention"}
				defaultSort={"category"}
				defaultSortDescending={false}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="category">{t("list.category")}</Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="delete" key="delete">
						{""}
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	}

	renderRows(mentions, t) {
		let rows = [];
		const that = this;
		mentions.forEach((mention, i) => {
			const isExternal =
				mention.externalLink && mention.externalLink.length > 0 ? true : false;
			rows.push(
				<Tr key={i} style={{ textDecoration: "none" }}>
					<Td column="category" value={mention.category}>
						{isExternal ? (
							<a href={mention.externalLink}>{mention.category}</a>
						) : (
							<div>{mention.category}</div>
						)}
					</Td>
					<Td column="title" value={mention.title}>
						{isExternal ? (
							<a href={mention.externalLink}>{mention.title}</a>
						) : (
							<div>{mention.title}</div>
						)}
					</Td>
					{mention._id && (
						<Td column="delete" value="delete" key="delete">
							<BIN
								className={"remove-mention"}
								onClick={() => this.removeMention(mention._id)}
							/>
						</Td>
					)}
				</Tr>
			);
		});
		return rows;
	}

	removeMention = (mentionId) => {
		const { artist } = this.props;
		if (artist._id) {
			this.props.dispatch(deleteMention(artist._id, mentionId));
		}
	};

	// formatMentions = () => {
	//   const { mentions } = this.props
	//   let fixedMentions = []
	//   mentions.forEach(mentionType => {
	//     const category = mentionType.title
	//     mentionType.links.forEach(mention => {
	//       const format = {
	//         category: category,
	//         title: mention.title,
	//         internalLink: mention.address
	//       }
	//       fixedMentions.push(format)
	//     });
	//   });
	//   return fixedMentions
	// }

	render() {
		const { isAdmin, isArtist, artist, t } = this.props;
		// Mentions find the same 5 objects for all artist so stopped displaying them
		// const formatMentions = this.formatMentions().concat(artist.related)
		const formatMentions = artist.related;

		return (
			<div className="ArtistMentions">
				<Main>
					<div className="content">
						{isAdmin ? (
							this.renderTable(
								isAdmin,
								this.renderRows(formatMentions, t),
								100,
								t
							)
						) : (
							<div>
								<legend className="legend-nomargin">{`${t("mentions")} (${
									formatMentions.length
								})`}</legend>
								{formatMentions.length > 0 &&
									this.renderTable(
										isAdmin,
										this.renderRows(formatMentions, t),
										30,
										t
									)}
							</div>
						)}
					</div>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		artist: { artist },
	} = state;
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const isArtist = user.role === "artist";
	return { artist, isAdmin, isArtist };
}

const translated = withNamespaces("mention")(ArtistMentions);
export default connect(mapStateToProps)(translated);
