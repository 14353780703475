import Api from "@artworkslab/sharedmodules/src/Api";

export const FILES_FOR_USER_REQUEST = "portal/actions/FILES_FOR_USER_REQUEST";
export const FILES_FOR_USER_SUCCESS = "portal/actions/FILES_FOR_USER_SUCCESS";
export const FILES_FOR_USER_FAILURE = "portal/actions/FILES_FOR_USER_FAILURE";

export function fetchArchiveForUser(userId) {
	return (dispatch) => {
		dispatch(fetchArchiveForUserRequest());
		const endpoint = `archive/${userId}`;
		return Api(endpoint, "GET", false)
			.then((json) => {
				dispatch(fetchArchiveForUserSuccess(json));
			})
			.catch((error) => {
				dispatch(fetchArchiveForUserFail(error));
			});
	};
}

function fetchArchiveForUserRequest() {
	return {
		type: FILES_FOR_USER_REQUEST,
	};
}

function fetchArchiveForUserSuccess(files) {
	return {
		type: FILES_FOR_USER_SUCCESS,
		response: files,
	};
}

function fetchArchiveForUserFail(error) {
	return {
		type: FILES_FOR_USER_FAILURE,
		message: error,
	};
}

export const UPLOAD_FILE_REQUEST = "portal/actions/UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "portal/actions/UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "portal/actions/UPLOAD_FILE_FAILURE";

export function uploadFile(userId, file) {
	return (dispatch) => {
		const body = { file };
		dispatch(uploadFileRequest());
		const endpoint = `archive/${userId}`;
		return Api(endpoint, "POST", false, body)
			.then((json) => {
				dispatch(uploadFileSuccess(json));
				// return for Registry.jsx
				return json;
			})
			.catch((error) => {
				dispatch(uploadFileFail(error));
				// return for Registry.jsx
				return false;
			});
	};
}

function uploadFileRequest() {
	return {
		type: UPLOAD_FILE_REQUEST,
	};
}

function uploadFileSuccess(file) {
	return {
		type: UPLOAD_FILE_SUCCESS,
		response: file,
	};
}

function uploadFileFail(error) {
	return {
		type: UPLOAD_FILE_FAILURE,
		message: error,
	};
}

export const RENAME_FILE_REQUEST = "portal/actions/RENAME_FILE_REQUEST";
export const RENAME_FILE_SUCCESS = "portal/actions/RENAME_FILE_SUCCESS";
export const RENAME_FILE_FAILURE = "portal/actions/RENAME_FILE_FAILURE";

export function renameFile(userId, body) {
	return (dispatch) => {
		dispatch(renameFileRequest());
		const endpoint = `archive/${userId}`;
		return Api(endpoint, "PUT", true, body)
			.then((json) => {
				dispatch(renameFileSuccess(json));
			})
			.catch((error) => {
				dispatch(renameFileFail(error));
			});
	};
}

function renameFileRequest() {
	return {
		type: RENAME_FILE_REQUEST,
	};
}

function renameFileSuccess(userArchive) {
	return {
		type: RENAME_FILE_SUCCESS,
		response: userArchive,
	};
}

function renameFileFail(error) {
	return {
		type: RENAME_FILE_FAILURE,
		message: error,
	};
}

export const DELETE_FILE_REQUEST = "portal/actions/DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "portal/actions/DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "portal/actions/DELETE_FILE_FAILURE";

export function deleteFile(bucket, key) {
	return (dispatch) => {
		dispatch(deleteFileRequest());
		const endpoint = `archive/${encodeURIComponent(
			bucket
		)}/${encodeURIComponent(key)}`;
		return Api(endpoint, "DELETE", true)
			.then((json) => {
				dispatch(deleteFileSuccess(json));
				// return true/false for FileArchive.js to fetch files again.
				return true;
			})
			.catch((error) => {
				dispatch(deleteFileFail(error));
				return false;
			});
	};
}

function deleteFileRequest() {
	return {
		type: DELETE_FILE_REQUEST,
	};
}

function deleteFileSuccess(file) {
	return {
		type: DELETE_FILE_SUCCESS,
		response: file,
	};
}

function deleteFileFail(error) {
	return {
		type: DELETE_FILE_FAILURE,
		message: error,
	};
}

export const FETCH_EMAIL_HISTORY_REQUEST =
	"portal/actions/FETCH_EMAIL_HISTORY_REQUEST";
export const FETCH_EMAIL_HISTORY_SUCCESS =
	"portal/actions/FETCH_EMAIL_HISTORY_SUCCESS";
export const FETCH_EMAIL_HISTORY_FAILURE =
	"portal/actions/FETCH_EMAIL_HISTORY_FAILURE";

export function fetchEmailHistory(userId) {
	return (dispatch) => {
		dispatch(fetchEmailHistoryRequest());
		const endpoint = `archive/emailhistory/${userId}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchEmailHistorySuccess(json));
			})
			.catch((error) => {
				dispatch(fetchEmailHistoryFail(error));
			});
	};
}

function fetchEmailHistoryRequest() {
	return {
		type: FETCH_EMAIL_HISTORY_REQUEST,
	};
}

function fetchEmailHistorySuccess(emailHistory) {
	return {
		type: FETCH_EMAIL_HISTORY_SUCCESS,
		response: emailHistory,
	};
}

function fetchEmailHistoryFail(error) {
	return {
		type: FETCH_EMAIL_HISTORY_FAILURE,
		message: error,
	};
}

export const FETCH_EMAIL_REQUEST = "portal/actions/FETCH_EMAIL_REQUEST";
export const FETCH_EMAIL_SUCCESS = "portal/actions/FETCH_EMAIL_SUCCESS";
export const FETCH_EMAIL_FAILURE = "portal/actions/FETCH_EMAIL_FAILURE";

export function fetchEmail(key) {
	return (dispatch) => {
		dispatch(fetchEmailRequest());
		const endpoint = `archive/email/${encodeURIComponent(key)}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchEmailSuccess(json));
			})
			.catch((error) => {
				dispatch(fetchEmailFail(error));
			});
	};
}

function fetchEmailRequest() {
	return {
		type: FETCH_EMAIL_REQUEST,
	};
}

function fetchEmailSuccess(email) {
	return {
		type: FETCH_EMAIL_SUCCESS,
		response: email,
	};
}

function fetchEmailFail(error) {
	return {
		type: FETCH_EMAIL_FAILURE,
		message: error,
	};
}

export const FETCH_FAILED_EMAILS_REQUEST =
	"portal/actions/FETCH_FAILED_EMAILS_REQUEST";
export const FETCH_FAILED_EMAILS_SUCCESS =
	"portal/actions/FETCH_FAILED_EMAILS_SUCCESS";
export const FETCH_FAILED_EMAILS_FAILURE =
	"portal/actions/FETCH_FAILED_EMAILS_FAILURE";

export function fetchFailedEmails(date, galleryId) {
	return (dispatch) => {
		dispatch(fetchFailedEmailsRequest());
		const endpoint = `archive/failedemails/${date}/${galleryId}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchFailedEmailsSuccess(json));
			})
			.catch((error) => {
				dispatch(fetchFailedEmailsFail(error));
			});
	};
}

function fetchFailedEmailsRequest() {
	return {
		type: FETCH_FAILED_EMAILS_REQUEST,
	};
}

function fetchFailedEmailsSuccess(email) {
	return {
		type: FETCH_FAILED_EMAILS_SUCCESS,
		response: email,
	};
}

function fetchFailedEmailsFail(error) {
	return {
		type: FETCH_FAILED_EMAILS_FAILURE,
		message: error,
	};
}

export const FETCH_FAILED_GALLERIES_REQUEST =
	"portal/actions/FETCH_FAILED_GALLERIES_REQUEST";
export const FETCH_FAILED_GALLERIES_SUCCESS =
	"portal/actions/FETCH_FAILED_GALLERIES_SUCCESS";
export const FETCH_FAILED_GALLERIES_FAILURE =
	"portal/actions/FETCH_FAILED_GALLERIES_FAILURE";

export function fetchFailedGalleries(date) {
	return (dispatch) => {
		dispatch(fetchFailedGalleriesRequest());
		const endpoint = `archive/failedemails/galleries/${date}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchFailedGalleriesSuccess(json));
			})
			.catch((error) => {
				dispatch(fetchFailedGalleriesFail(error));
			});
	};
}

function fetchFailedGalleriesRequest() {
	return {
		type: FETCH_FAILED_GALLERIES_REQUEST,
	};
}

function fetchFailedGalleriesSuccess(email) {
	return {
		type: FETCH_FAILED_GALLERIES_SUCCESS,
		response: email,
	};
}

function fetchFailedGalleriesFail(error) {
	return {
		type: FETCH_FAILED_GALLERIES_FAILURE,
		message: error,
	};
}

export function fetchFile(bucket, key) {
	return new Promise(function (resolve, reject) {
		const endpoint = "archive/fetchfile";
		const body = { bucket, key };
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				resolve(json);
			})
			.catch((err) => {
				console.log("fetchFile Error", err);
				reject(false);
			});
	});
}
