import React, { Component } from "react";

const TextInput = (props) => {
	const customValidate = props.validate ? props.validate : false;
	let customError;
	if (customValidate) {
		customError = customValidate(props.input.value);
	}
	const green = props.green ? true : false;
	let marked = props.marked ? true : false;
	let required = props.required ? true : false;
	const empty = props.input.value === "";
	const valid = props.meta.valid && props.meta.touched && !empty ? true : false;
	const prevValid =
		props.meta.valid && !props.meta.touched && !empty ? true : false;
	const invalid =
		(props.meta.invalid || customError) && props.meta.touched ? true : false;
	const type = props.type || "text";
	const errorText = props.meta.error || customError;
	const children = props.children || null;
	const isResponsive = props.isResponsive || false;
	const style = props.style || {};

	return (
		<div
			className={
				"Input Input--text " +
				(invalid ? "is-error " : "") +
				(valid ? "is-valid " : "") +
				(prevValid ? "is-prev-valid " : "") +
				(required ? "is-required " : "") +
				(marked ? "is-marked " : "") +
				(green ? "is-green " : "") +
				(empty ? "is-empty " : "") +
				(isResponsive ? "is-responsive " : "")
			}
			style={style}
		>
			<input
				{...props.input}
				type={type}
				disabled={props.disabled}
				placeholder={props.placeholder}
			/>
			{invalid ? <span className="Input__error">{errorText}</span> : null}
			{children}
		</div>
	);
};

export default TextInput;
