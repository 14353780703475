import Api from "@artworkslab/sharedmodules/src/Api";
import { CALL_API } from "../utils/api";

export const DISCOUNT_CODE_SAVE_REQUEST =
	"artworksapp/discountcodes/DISCOUNT_CODE_SAVE_REQUEST";
export const DISCOUNT_CODE_SAVE_SUCCESS =
	"artworksapp/discountcodes/DISCOUNT_CODE_SAVE_SUCCESS";
export const DISCOUNT_CODE_SAVE_FAILURE =
	"artworksapp/discountcodes/DISCOUNT_CODE_SAVE_FAILURE";

export function saveDiscountCode(discountCode) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			const endpoint = discountCode._id
				? `discountcodes/single/${discountCode._id}`
				: "discountcodes/batch";
			const method = discountCode._id ? "PUT" : "POST";

			dispatch(saveDiscountCodeRequest());
			return Api(endpoint, method, true, discountCode)
				.then((json) => {
					dispatch(saveDiscountCodeSuccess(json));
					resolve(json);
				})
				.catch((error) => {
					dispatch(saveDiscountCodeFail(error));
					reject(error);
				});
		});
}

export function saveDiscountCodeRequest() {
	return {
		type: DISCOUNT_CODE_SAVE_REQUEST,
	};
}

export function saveDiscountCodeSuccess(json) {
	return {
		type: DISCOUNT_CODE_SAVE_SUCCESS,
		response: json,
	};
}

export function saveDiscountCodeFail(error) {
	return {
		type: DISCOUNT_CODE_SAVE_FAILURE,
		message: error,
	};
}

export const DISCOUNT_CODE_FETCH_REQUEST =
	"artworksapp/discountcodes/DISCOUNT_CODE_FETCH_REQUEST";
export const DISCOUNT_CODE_FETCH_SUCCESS =
	"artworksapp/discountcodes/DISCOUNT_CODE_FETCH_SUCCESS";
export const DISCOUNT_CODE_FETCH_FAILURE =
	"artworksapp/discountcodes/DISCOUNT_CODE_FETCH_FAILURE";

export function fetchDiscountCodes() {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			const endpoint = "discountcodes/all";

			dispatch(fetchDiscountCodesRequest());
			Api(endpoint, "GET", true)
				.then((json) => {
					dispatch(fetchDiscountCodesSuccess(json));
					resolve(json);
				})
				.catch((error) => {
					dispatch(fetchDiscountCodesFail(error));
					reject(error);
				});
		});
}

export function fetchDiscountCodesRequest() {
	return {
		type: DISCOUNT_CODE_FETCH_REQUEST,
	};
}

export function fetchDiscountCodesSuccess(json) {
	return {
		type: DISCOUNT_CODE_FETCH_SUCCESS,
		response: json,
	};
}

export function fetchDiscountCodesFail(error) {
	return {
		type: DISCOUNT_CODE_FETCH_FAILURE,
		message: error,
	};
}

export function fetchDiscountCode(id) {
	return {
		[CALL_API]: {
			endpoint: `discountcodes/${id}`,
			authenticated: true,
			types: [
				DISCOUNT_CODE_FETCH_REQUEST,
				DISCOUNT_CODE_FETCH_SUCCESS,
				DISCOUNT_CODE_FETCH_FAILURE,
			],
		},
	};
}

export const DELETE_DISCOUNT_CODE_REQUEST =
	"artworksapp/discountcodes/DELETE_DISCOUNT_CODE_REQUEST";
export const DELETE_DISCOUNT_CODE_SUCCESS =
	"artworksapp/discountcodes/DELETE_DISCOUNT_CODE_SUCCESS";
export const DELETE_DISCOUNT_CODE_FAILURE =
	"artworksapp/discountcodes/DELETE_DISCOUNT_CODE_FAILURE";

export function deleteDiscountCode(id) {
	return {
		[CALL_API]: {
			endpoint: `discountcodes/${id}`,
			method: "DELETE",
			authenticated: true,
			types: [
				DELETE_DISCOUNT_CODE_REQUEST,
				DELETE_DISCOUNT_CODE_SUCCESS,
				DELETE_DISCOUNT_CODE_FAILURE,
			],
		},
	};
}
