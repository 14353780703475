import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import CountBadge from "./CountBadge";
import Cogwheel from "../../../../assets/images/svg/fontAwesome/Cogwheel";
import { createWebbLink } from "../../../utils/Helpers";

const contentClass = "BurgerMenu__content";
const splitListsClass = "BurgerMenu__content__split-lists";
const menuListClass = "BurgerMenu__content__menu-list";
const listItemClass = "BurgerMenu__content__menu-list__item";
const contentBottomClass = "BurgerMenu__content__bottom";
const contentBottomIconClass = "BurgerMenu__content__bottom__icon";
const contentBottomIconSvgClass =
	"BurgerMenu__content__bottom__icon__svg-container";

const VIEW_ONLINE = require("../../../../assets/images/svg/share_22px.svg");
const SMILEY = require("../../../../assets/images/navbar_profile_60px.png");

class BurgerMenu extends Component {
	constructor(props) {
		super(props);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.pathName !== nextProps.pathName) {
			this.closeMenu();
		}
	}

	stopEventPropagation = (e) => {
		e.stopPropagation();
	};

	closeMenu = () => {
		this.props.close();
	};

	getAdminLinks = () => {
		const { t } = this.props;
		return (
			<div className={splitListsClass}>
				<ul className={menuListClass}>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/galleries"
							onlyActiveOnIndex={true}
						>
							{t("navbar.venues")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/shows" onlyActiveOnIndex={true}>
							{t("navbar.shows")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/artworks"
							onlyActiveOnIndex={true}
						>
							{t("navbar.artworks")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/prints"
							onlyActiveOnIndex={true}
						>
							(ASP) {t("navbar.prints")}
						</Link>
					</li>
					<li className={listItemClass} style={{ height: "auto" }}>
						<Link
							activeClassName="active"
							to="/influencers"
							onlyActiveOnIndex={true}
						>
							(ASP) {t("navbar.influencers")}
							<br />
							{t("common:and")} {t("navbar.artists")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/posts" onlyActiveOnIndex={true}>
							{t("navbar.posts")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/imagegallery"
							onlyActiveOnIndex={true}
						>
							{t("navbar.imageGalleries")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/artists"
							onlyActiveOnIndex={true}
						>
							{t("navbar.artists")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/events"
							onlyActiveOnIndex={true}
						>
							{t("navbar.events")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/rated">
							{t("navbar.rated")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/categories">
							{t("navbar.categories")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/orders">
							{t("navbar.orders")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/discounts">
							{t("navbar.discounts")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/artist-invoices">
							{t("navbar.transactions")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/data">
							{t("navbar.data")}
						</Link>
					</li>
					{/*<li className={ list('item') }>
            <Link activeClassName="active" to="/scraped/shows">{t('navbar.newShows')}</Link>
          </li>
          <li className={ list('item') }>
            <Link activeClassName="active" to="/scraped/artists">{t('navbar.newArtists')}</Link>
          </li>*/}
				</ul>
				{/* list().mix("right-menu")*/}
				<ul className={menuListClass + " right-menu"}>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/accounts"
							onlyActiveOnIndex={true}
						>
							{t("navbar.accounts")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/members">
							{t("navbar.members")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/selections"
							onlyActiveOnIndex={true}
						>
							{t("navbar.selections")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/raffles"
							onlyActiveOnIndex={true}
						>
							{t("navbar.raffle")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/contacts">
							{t("navbar.contacts")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/filearchive">
							{t("navbar.fileArchive")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/emails">
							{t("navbar.emails")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/xmascalendar">
							{t("navbar.xmascalendar")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/invoices">
							{t("navbar.invoices")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/subscriptions">
							{t("navbar.subscriptions")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/contactlists"
							onlyActiveOnIndex={true}
						>
							{t("navbar.contactlists")}
						</Link>
					</li>
					<li className={listItemClass} style={{ height: "auto" }}>
						<Link activeClassName="active" to="/messages">
							{t("navbar.enquiries")} &<br />
							{t("navbar.messagesString")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/announcements">
							{t("navbar.announcements")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/gallery-settings">
							{t("navbar.galleryPayment")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/artist-settings">
							{t("navbar.artistPayment")}
						</Link>
					</li>
					{/*
            <li className={ list('item') } style={{ marginTop: '15px' }}>
              <Link activeClassName="active" to="/settings">{t('navbar.userSettings')}</Link>
            </li>
          */}
				</ul>
			</div>
		);
	};

	getAuthorLinks = () => {
		const { notifications, t } = this.props;
		return (
			<ul className={menuListClass}>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/dashboard">
						{t("navbar.dashboard")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/gallery">
						{t("navbar.aboutUs")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/shows" onlyActiveOnIndex={true}>
						{t("navbar.shows")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/artists" onlyActiveOnIndex={true}>
						{t("navbar.artists")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/artworks">
						{t("navbar.artworks")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/events" onlyActiveOnIndex={true}>
						{t("navbar.events")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/gallery-settings">
						{t("navbar.settings")}
					</Link>
				</li>
				{/*
          <li className={ list('item') } style={{ marginTop: '15px' }}>
            <Link activeClassName="active" to="/settings">{t('navbar.userSettings')}</Link>
          </li>
        */}
			</ul>
		);
	};

	getSociteyLinks = () => {
		const { premium, gallery, xmasCalendar, notifications, t } = this.props;
		return (
			<div className={splitListsClass}>
				<ul className={menuListClass}>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/gallery">
							{t("navbar.aboutUs")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							style={{ position: "relative" }}
							to="/shows"
							onlyActiveOnIndex={true}
						>
							{t("navbar.shows")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							style={{ position: "relative" }}
							to="/events"
							onlyActiveOnIndex={true}
						>
							{t("navbar.events")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/posts" onlyActiveOnIndex={true}>
							{t("navbar.posts")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/imagegallery"
							onlyActiveOnIndex={true}
						>
							{t("navbar.imageGalleries")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							to="/raffles"
							onlyActiveOnIndex={true}
						>
							{t("navbar.raffle")}
						</Link>
					</li>
				</ul>
				<ul className={menuListClass + " right-menu"}>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/members">
							{t("navbar.members")}
						</Link>
						{notifications.awaitingApproval > 0 && (
							<CountBadge
								type="for-dropdown-burger-member"
								count={notifications.awaitingApproval}
							/>
						)}
					</li>
					<li className={listItemClass}>
						<Link activeClassName="active" to="/artworks">
							{t("navbar.artworks")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							style={{ position: "relative" }}
							to="/emails"
						>
							{t("navbar.emails")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							style={{ position: "relative" }}
							to="/contacts"
						>
							{t("navbar.contacts")}
						</Link>
					</li>
					<li className={listItemClass}>
						<Link
							activeClassName="active"
							style={{ position: "relative" }}
							to="/filearchive"
						>
							{t("navbar.fileArchive")}
						</Link>
					</li>
					{xmasCalendar && (
						<li className={listItemClass}>
							<Link
								activeClassName="active"
								style={{ position: "relative" }}
								to="/xmascalendar"
							>
								{t("navbar.xmascalendar")}
							</Link>
						</li>
					)}
					<li className={listItemClass}>
						<Link activeClassName="active" to="/gallery-settings">
							{t("navbar.settings")}
						</Link>
					</li>
					{/*
            <li className={ list('item') } style={{ marginTop: '15px' }}>
              <Link activeClassName="active" to="/settings">{t('navbar.userSettings')}</Link>
            </li>
          */}
				</ul>
			</div>
		);
	};

	getArtistLinks = (showActive, isResponsive) => {
		const { premium, notifications, t } = this.props;
		const webbLink = createWebbLink("club-follows/artists");
		const inboxNotis = notifications
			? notifications.messages + notifications.enquiries
			: 0;
		const premiumText = premium ? "" : "  -   Pro";
		return (
			<ul className={menuListClass}>
				<li
					className={listItemClass + (!premium ? " is-disabled" : "")}
					style={isResponsive ? { display: "none" } : {}}
				>
					<Link activeClassName="active" to="/dashboard">
						{"Dashboard" + premiumText}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/artworks">
						{t("navbar.myArtworks")}
					</Link>
					{notifications.artworks > 0 && !isResponsive && (
						<CountBadge
							type="for-dropdown-burger-art"
							count={notifications.artworks}
						/>
					)}
				</li>
				<li className={listItemClass + (!premium ? " is-disabled" : "")}>
					<Link activeClassName="active" to="/contactlists">
						{t("navbar.artistcontactlists") + premiumText}
					</Link>
				</li>
				<li
					className={listItemClass + (!premium ? " is-disabled" : "")}
					style={isResponsive ? { display: "none" } : {}}
				>
					<Link
						activeClassName="active"
						className={
							"BurgerMenu__custom-link" + (showActive ? " is-active" : "")
						}
						to="/activities"
					>
						{t("navbar.myEvents") + premiumText}
					</Link>
					{notifications.shows > 0 && (
						<CountBadge
							type="for-dropdown-burger-shows"
							count={notifications.shows}
						/>
					)}
				</li>
				<li className={listItemClass}>
					<Link
						activeClassName="active"
						to="/messages"
						onlyActiveOnIndex={true}
					>
						{t("navbar.messages")}
					</Link>
					{inboxNotis > 0 && (
						<CountBadge
							type="for-dropdown-burger-button-notify"
							count={inboxNotis}
						/>
					)}
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/artist-invoices">
						{t("navbar.transactions")}
					</Link>
					{notifications.transactions > 0 && (
						<CountBadge
							type="for-dropdown-burger-invoice"
							count={notifications.transactions}
						/>
					)}
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/artists/me">
						{t("navbar.aboutMe")}
					</Link>
				</li>
				<li className={listItemClass}>
					<Link activeClassName="active" to="/settings">
						{t("navbar.userSettings")}
					</Link>
				</li>
				{!isResponsive && (
					<li className={listItemClass}>
						<a
							className="BurgerMenu__custom-link"
							href={webbLink}
							target="_blank"
						>
							{t("navbar.webblink")}
						</a>
					</li>
				)}
				<li className={listItemClass}>
					<Link activeClassName="active" to="/help-center/artists">
						{t("navbar.helpCenter")}
					</Link>
				</li>
			</ul>
		);
	};

	render() {
		const {
			isOpen,
			pathName,
			isAdmin,
			isAuthor,
			isSociety,
			isArtist,
			webbLink,
			t,
		} = this.props;
		const isResponsive = appGlobals.useResponsive;

		const showActive =
			pathName.indexOf("/shows/") > -1 || pathName.indexOf("/events/") > -1
				? true
				: false;
		const onSettingsPage = pathName.indexOf("/settings") >= 0;

		return (
			<div
				className={
					"BurgerMenu" +
					(isOpen ? " is-open" : "") +
					(isResponsive ? " is-responsive" : "")
				}
				onClick={this.stopEventPropagation}
			>
				<nav className={contentClass} role="navigation">
					{isAdmin && this.getAdminLinks()}
					{isAuthor && this.getAuthorLinks()}
					{isSociety && this.getSociteyLinks()}
					{isArtist && this.getArtistLinks(showActive, isResponsive)}
					<div className={contentBottomClass}>
						<Link
							className={contentBottomIconClass + " settings"}
							to="/settings"
							title={t("navbar.userSettings")}
						>
							<div
								className={
									contentBottomIconSvgClass +
									(onSettingsPage ? " is-disabled" : "")
								}
							>
								<img src={SMILEY} />
							</div>
						</Link>
						{!isResponsive && !isAdmin && (
							<a
								href={webbLink}
								target="_blank"
								className={
									contentBottomIconClass +
									" webblink" +
									(webbLink ? " is-webblink" : "")
								}
								title={t("navbar.viewOnline")}
							>
								<div
									className={
										contentBottomIconSvgClass +
										(webbLink ? " is-webblink" : "") +
										(!webbLink ? " is-disabled" : "")
									}
								>
									<VIEW_ONLINE />
								</div>
							</a>
						)}
					</div>
				</nav>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
	} = state;
	const isArtist = !!(user.role === "artist");
	const isAuthor = !!(user.role === "author");
	const isSociety = !!(user.role === "society");

	return {
		isAdmin,
		isAuthor,
		isSociety,
		isArtist,
	};
}

export default connect(mapStateToProps)(BurgerMenu);
