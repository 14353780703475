import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";

const PRODUCT = require("../../../assets/images/product.png");

class ForArtists extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { user } = this.props;
		return (
			<div className="homepage">
				<div className="home-header">
					<div className="home-header__content">
						<h1 className="home-header__content__main-header">
							Join Artworks ❤️
						</h1>
						<h1 className="home-header__content__sub-header"></h1>
						<div className="homepage__buttons">
							<div
								className="homepage__buttons__button-container"
								style={{ textAlign: "center" }}
							>
								<Link
									to="/login"
									className="button--color_black"
									style={{ width: "100%" }}
								>
									LOGIN
								</Link>
							</div>
							<div
								className="homepage__buttons__button-container"
								style={{ textAlign: "center" }}
							>
								<Link
									to="/register"
									className="button--color_black"
									style={{ width: "100%" }}
								>
									SIGN UP
								</Link>
							</div>
						</div>
					</div>
				</div>
				<section className="homepage__content">
					<h1>Artworks for artists.</h1>
					<div className="statement-container">
						<p>
							A simple online tool to reach new audiences and buyers as well as
							connecting with the industry. We reach a wider audience and more
							buyers than any bricks and mortar approach do.
						</p>
					</div>
					<div className="benefit-container">
						<p>What we offer</p>
						<ul>
							<li>Connect with the industry.</li>
							<li style={{ listStyle: "none", paddingBottom: "5px" }}>
								<i>Get noticed by galleries, and find them too.</i>
							</li>
							<li>Sell your art to fans you didn't know existed</li>
							<li style={{ listStyle: "none", paddingBottom: "5px" }}>
								<i>
									We're promising a new type of customer who use Internet as the
									main channel for discovering news stuff.
								</i>
							</li>
						</ul>
					</div>
					<div className="statement-container">
						<p>
							In addition, our tool will help you answer the following
							questions, helping you make better business decisions:
						</p>
					</div>
					<div className="benefit-container">
						<ul>
							<li>How your shows are performing</li>
							<li>Who visit your shows</li>
							<li>Which shows trigger more engagement</li>
							<li>User ratings</li>
							<li>Who is interested in your work</li>
							<li>Which artworks are popular online</li>
						</ul>
					</div>
					<div className="statement-container">
						<p>
							We help to promote your program, expand your client base, and
							strengthen your relationship with current and future customers.
						</p>
					</div>
					<div className="statement-container">
						<p>
							Join us and we promise to listen carefully to your needs. After
							all, we are building Artworks features for you, only for us to see
							more art!
						</p>
					</div>
					<div className="homepage__buttons">
						<div
							className="homepage__buttons__button-container"
							style={{ textAlign: "center" }}
						>
							<Link
								to="/login"
								className="button--color_black"
								style={{ width: "100%" }}
							>
								LOGIN
							</Link>
						</div>
						<div
							className="homepage__buttons__button-container"
							style={{ textAlign: "center" }}
						>
							<Link
								to="/register"
								className="button--color_black"
								style={{ width: "100%" }}
							>
								SIGN UP
							</Link>
						</div>
					</div>
					<img className="homepage__content__image" src={PRODUCT} />
				</section>
			</div>
		);
	}
}

export default ForArtists;
