import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import Main from "../../components/Main/Main";
import EmailWriter from "../../components/EmailsPageComponents/EmailWriter";
import EmailHistory from "../../components/EmailsPageComponents/EmailHistory";
import FailedEmails from "../../components/EmailsPageComponents/FailedEmails";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import ArtistProModals from "../../components/Modal/Custom/ArtistProModals";
import {
	fetchMembers,
	sendEmailToMembersReset,
} from "../../actions/RegistryActions";
import { fetchAllGalleriesFast } from "../../actions/GalleryActions";
import { fetchFailedGalleries } from "../../actions/ArchiveActions";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const CLOSE = require("../../../assets/images/icons/close@2x.png");
const RELOAD = require("../../../assets/images/svg/redo-alt.svg");

class Emails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			selectedGallery: null,
			listsCount: {},
			mailingListName: "",
			modalOpen: false,
			modalType: "",
			modalMessage: "",
		};
	}

	componentDidMount() {
		const { isAdmin, user } = this.props;

		if (isAdmin) {
			this.props.dispatch(fetchAllGalleriesFast());

			const date = new Date();
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const today = `${year}/${month}`;
			this.props.dispatch(fetchFailedGalleries(today));
		} else if (user.gallery) {
			this.props.dispatch(fetchMembers(user.gallery._id || user.gallery));
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, isArtist, allGalleries, mailingLists, members } =
			nextProps;

		if (
			isAdmin &&
			this.state.galleryOptions.length !== Object.keys(allGalleries).length &&
			Object.keys(allGalleries).length > 0
		) {
			const galleryOptions = Object.keys(allGalleries).map((key) => {
				const venue = allGalleries[key];
				return {
					value: venue._id,
					label: venue.name,
					premium: __USE_TIERS__ ? true : isPremium(venue),
				};
			});

			this.setState({
				galleryOptions: galleryOptions.sort(this.sortOptions),
			});
		}

		const finisedFetching =
			this.props.fetchingMembers && !nextProps.fetchingMembers;
		const membersChange =
			Object.keys(this.props.members).length !== Object.keys(members).length;
		const listsChanged =
			this.getMailingLists(this.getGallery(this.props)).length !==
			this.getMailingLists(this.getGallery(nextProps)).length;
		// Need to check userGallery._id or it will work locally but not on staging.
		const galleryChanged =
			!isAdmin && this.props.userGallery._id !== nextProps.userGallery._id;
		if (finisedFetching || membersChange || listsChanged || galleryChanged) {
			this.countListMembers(nextProps);
		}
	}

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	getGallery = (props) => {
		const { isAdmin, allGalleries, userGallery } = props;
		const { selectedGallery } = this.state;
		return isAdmin
			? allGalleries[selectedGallery ? selectedGallery.value : 0]
			: userGallery;
	};

	getMailingLists = (gallery) => {
		return gallery && gallery.registry && gallery.registry.mailingLists
			? gallery.registry.mailingLists
			: [];
	};

	countListMembers = (props) => {
		const { members } = props;
		const gallery = this.getGallery(props);
		const mailingLists = this.getMailingLists(gallery);

		const listsCount = {};
		mailingLists.forEach((list) => {
			listsCount[list._id] = 0;
		});
		Object.keys(members).forEach((memberKey) => {
			const member = members[memberKey];
			if (member.mailingLists) {
				member.mailingLists.forEach((onList) => {
					if (listsCount[onList] || listsCount[onList] === 0)
						listsCount[onList] = listsCount[onList] + 1;
				});
			}
		});

		this.setState({
			listsCount,
			selectedMailingList: "",
			allMarked: false,
		});
	};

	selectGallery = (gallery) => {
		const { allGalleries } = this.props;
		this.setState(
			{
				selectedGallery: gallery,
			},
			() => {
				this.props.dispatch(fetchMembers(gallery.value));
			}
		);
	};

	toggleModalOpen = (type = false, message = "") => {
		if (!type) {
			this.props.dispatch(sendEmailToMembersReset());
			this.setState({
				modalOpen: false,
				modalType: "",
				modalMessage: "",
			});
		} else {
			this.setState({
				modalOpen: true,
				modalType: type,
				modalMessage: message,
			});
		}
	};

	drawGalleryNames = (galleryNames) => {
		const galleryArr = [];
		galleryNames.map((galleryName, index) => {
			galleryArr.push(<li key={index}>{galleryName}</li>);
		});
		return galleryArr;
	};

	render() {
		const {
			isAdmin,
			isArtist,
			user,
			userGallery,
			allGalleries,
			members,
			failedGalleries,
			isFetching,
			error,
			t,
		} = this.props;
		const {
			galleryOptions,
			selectedGallery,
			listsCount,
			modalOpen,
			modalType,
			modalMessage,
		} = this.state;
		const gallery = this.getGallery(this.props);
		// standardOrAbove and premium only for user gallery,
		// for gallery selected in dropdown by admin, premium is set on selectedGallery.
		const premium = isPremium(gallery);
		const standardOrAbove = isStandardOrAbove(userGallery);
		const mailingLists = this.getMailingLists(gallery);

		let galleries = [];
		if (failedGalleries.length > 0) {
			galleries = this.drawGalleryNames(failedGalleries);
		}

		// Don't show Premium placeholders before real content if user gallery is Premium.
		const waitForLoading = !isAdmin && !isArtist && !userGallery._id;
		if (waitForLoading) return null;

		// This file used in ContactLists.jsx
		// const showPlaceholderContent = !isAdmin && !isArtist && !premium
		// let showPlaceholderContent = true
		let showPlaceholderContent = false;
		// if (isAdmin || isArtist) {
		//   showPlaceholderContent = false
		// } else if (__USE_TIERS__ && basicOrAbove) {
		//   showPlaceholderContent = false
		// } else if (!__USE_TIERS__ && premium) {
		//   showPlaceholderContent = false
		// }
		// console.log('showPlaceholderContent', showPlaceholderContent);

		const premiumModal = showPlaceholderContent ? (
			<ArtistProModals
				type="artSociety"
				availableFor="Bas, Standard och Premium"
			/>
		) : (
			""
		);

		if (showPlaceholderContent) {
			return (
				<div className="emails-page">
					<Main preChildren={premiumModal}>
						<legend className="emails-page__disabled-legend">
							{"Skicka mail"}
							<ExpandButton
								expanded={false}
								click={() => {}}
								style={{ top: 0 }}
							/>
						</legend>
						<legend
							className={
								"emails-page__disabled-legend" + (isArtist ? " is-artist" : "")
							}
						>
							{t("history")}
							<ExpandButton
								expanded={false}
								click={() => {}}
								style={{ top: 0 }}
							/>
						</legend>
						<legend className="emails-page__disabled-legend">
							{"Misslyckade mejl"}
							<ExpandButton
								expanded={false}
								click={() => {}}
								style={{ top: 0 }}
							/>
						</legend>
					</Main>
				</div>
			);
		}

		return (
			<div className="emails-page">
				<Main>
					{isAdmin && (
						<div>
							<Select
								value={selectedGallery}
								options={galleryOptions}
								onChange={this.selectGallery}
								onInputChange={this.onInputChange}
								valueKey={"gallery"}
								labelKey="label"
								backspaceToRemoveMessage=""
								backspaceRemoves={false}
								isLoading={isFetching}
								placeholder={"Gallery"}
								multi={false}
								autosize={false}
								className={"gallery-picker-select"}
								isSearchable={true}
							/>
							<div>
								<h3>Föreningar med misslyckade mejl</h3>
								<ul>{galleries}</ul>
							</div>
						</div>
					)}
					{(selectedGallery || (!isAdmin && !isArtist)) && (
						<EmailWriter
							gallery={gallery}
							premium={__USE_TIERS__ ? true : premium}
							standardOrAbove={__USE_TIERS__ ? standardOrAbove : premium}
							members={members}
							mailingLists={mailingLists}
							listsCount={listsCount}
							toggleModalOpen={this.toggleModalOpen}
							t={t}
						/>
					)}
					{(selectedGallery || (!isAdmin && !isArtist)) && (
						<EmailHistory
							gallery={gallery}
							premium={__USE_TIERS__ ? true : premium}
							isArtist={isArtist}
							t={t}
						/>
					)}
					{(selectedGallery || (!isAdmin && !isArtist)) && (
						<FailedEmails
							gallery={gallery}
							premium={__USE_TIERS__ ? true : premium}
							t={t}
						/>
					)}
					{modalOpen && modalType && (
						<div className="emails-page__modal-container">
							<div
								className="emails-page__modal-container__backdrop"
								onClick={() => this.toggleModalOpen()}
							></div>
							<div
								className={"emails-page__modal-container__modal " + modalType}
							>
								<img src={CLOSE} onClick={() => this.toggleModalOpen()} />
								{false && modalType === "create-mailing-list" && (
									<h4>{t("createMailingList")}</h4>
								)}
								{false && modalType === "create-mailing-list" && (
									<div className="emails-page__modal-container__modal__create-list">
										<input
											className="emails-page__modal-container__modal__create-list__name"
											type="text"
											value={mailingListName}
											onChange={this.mailingListNameChange}
											placeholder="Namn på listan"
										/>
										<div
											className={
												"emails-page__modal-container__modal__create-list__save" +
												(!mailingListName ? " is-disabled" : "")
											}
											onClick={mailingListName ? this._createMailingList : null}
										>
											Spara
										</div>
									</div>
								)}
								{modalType === "message" && <p>{modalMessage}</p>}
							</div>
						</div>
					)}
				</Main>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		gallery: { gallery: userGallery, allGalleries, isFetching, error },
		registry: { members, isFetching: fetchingMembers },
		archive: { failedGalleries },
	} = state;
	const isAdmin = user.role === "admin";
	const isArtist = user.role === "artist";

	return {
		user,
		isAdmin,
		isArtist,
		user,
		isFetching,
		error,
		userGallery,
		allGalleries,
		members,
		fetchingMembers,
		failedGalleries,
	};
};

const translated = withNamespaces("emails")(Emails);
export default connect(mapStateToProps)(translated);
