import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { toggleMentions } from "../../../actions/DashboardActions/MentionsActions";
import ExpandButton from "../ExpandButton/ExpandButton";

const SMILE_IMAGE = require("../../../../assets/images/icons/smile.png");

const MentionsItem = (props) => {
	return (
		<div className="Mentions__Content__Item">
			<h4 className="muted">{props.title}</h4>
			<p>{props.total ? props.total : props.links.length}</p>
			<Links links={props.links} />
		</div>
	);
};

const Links = (props) => {
	let key = 0;
	return (
		<div>
			{props.links.map((link) => {
				return (
					<a href={link.address} key={key++}>
						{link.title}
					</a>
				);
			})}
		</div>
	);
};

class Mentions extends Component {
	constructor(props) {
		super(props);
	}

	getMentionsItems = (mentions) => {
		let items = [];
		let key = 0;
		const total = this.getTotalMentions(mentions);

		items.push(
			<MentionsItem title="Total" total={total} links={[]} key={key++} />
		);

		mentions.forEach((mention) => {
			items.push(<MentionsItem {...mention} key={key++} />);
		});

		return items;
	};

	getTotalMentions = (mentions) => {
		const sum = mentions.reduce((a, b) => {
			return a + b.links.length;
		}, 0);

		return sum;
	};

	render() {
		const { expanded, data } = this.props;

		const mentions = this.getMentionsItems(data.mentions);

		return (
			<div className="Mentions">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(toggleMentions())}
				/>
				<h4 className="underlined">Your mentions at Artworks</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded ? (
							<div className="Mentions__Content">
								{mentions}
								<div className="Mentions__Content__Description">
									<div>
										<img src={SMILE_IMAGE} />
										<h5>Mentions?</h5>
										<p className="muted">
											What are mentions? This is described here. Just so you
											know. This text is long just to test what it looks like.
										</p>
									</div>
								</div>
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { mentions } = state;
	const { expanded, data } = mentions;
	return { expanded, data };
}

export default connect(mapStateToProps)(Mentions);
