import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Fields, FormSection, reduxForm } from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import TextInput from "../../../components/FormInput/TextInput";
import TextDisplay from "../../../components/FormInput/TextDisplay";
import {
	validateVAT,
	validateClearNr,
	validateAccNr,
	isNumber,
} from "../../../utils/Validators";

class ArtistSettingsForm extends Component {
	render() {
		const { handleSubmit, pristine, submitting, isAdmin, t } = this.props;

		return (
			<form onSubmit={handleSubmit} className="ArtistForm__form Form">
				<div className="fieldset-block fieldset-block--nopadding">
					<div className="fieldset">
						<legend>{t("single.payment")}</legend>
						<div className="input-holder">
							<Field
								name="orgNumber"
								component={TextInput}
								placeholder={t("single.organisationNumber")}
								validate={validateVAT}
								green
							>
								{/*<Tooltip data={ TOOL_TIPS('orgNumber', t)} />*/}
							</Field>
						</div>
						<div className="input-holder">
							<Field
								name="bankName"
								component={TextInput}
								placeholder={t("single.bankName")}
								green
							/>
						</div>
					</div>
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="clearingNr"
								component={TextInput}
								placeholder={t("single.clearingNr")}
								validate={validateClearNr}
								green
							/>
						</div>
						<div className="input-holder">
							<Field
								name="accountNr"
								component={TextInput}
								placeholder={t("single.accountNr")}
								green
							/>
						</div>
						<div className="input-holder">
							<Field
								name="bankgiro"
								component={TextInput}
								placeholder={t("single.bankgiro")}
								green
							/>
						</div>
					</div>
				</div>

				{/*Save button*/}
				<div className="fieldset">
					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

ArtistSettingsForm = reduxForm({
	form: "artist",
})(ArtistSettingsForm);

const translated = withNamespaces("artist")(ArtistSettingsForm);
export default connect(null)(translated);
