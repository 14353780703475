import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import {
	showPremiumFunctionalityModal,
	showPremiumUpgradeModal,
} from "../../utils/Dispatcher";
import { sendEmailToMembers } from "../../actions/RegistryActions";
import { fetchArchiveForUser } from "../../actions/ArchiveActions";
import { sendHelpEmail } from "../../actions/EmailActions";
import { createMailingList } from "../../actions/GalleryActions";

const CLOSE = require("../../../assets/images/icons/close@2x.png");
const CHEVRON = require("../../../assets/images/icons/chevron-down@2x.png");
const PLUS_WHITE = require("../../../assets/images/svg/plus_32px_white.svg");

class EmailWriter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sectionOpen: false,

			mailingListsDropdownOpen: false,
			selectedMailingList: "",
			mailingListModalOpen: false,
			mailingListName: "",

			emailHeader: "",
			emailBody: "",
			lastSentEmailBody: "",
			lastSentToMailingList: "",

			attachmentDropdownOpen: false,
			attachmentOptions: [],
			selectedAttachmentOption: null,
			addedAttachments: [],
		};
	}

	componentDidMount() {
		this.fetchData(this.props);

		if (this.props.isSociety || this.props.isAdmin) {
			this.setState({
				sectionOpen: true,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		// If finished sending email.
		if (!this.props.mailSent && nextProps.mailSent) {
			if (nextProps.registryError) {
				nextProps.toggleModalOpen("message", nextProps.registryError);
			} else {
				this.setState(
					{
						// Don't send the same message to the same list twice.
						lastSentEmailBody: this.state.emailBody,
						lastSentToMailingList: this.state.selectedMailingList,
					},
					() => {
						nextProps.toggleModalOpen("message", "Mailet har skickats");
					}
				);
			}
		}

		if (nextProps.isAdmin && this.props.gallery._id !== nextProps.gallery._id) {
			this.fetchData(nextProps);
		}

		// archiveFetching not working great, especially locally. Check archive.length too.
		if (
			(this.props.archiveFetching && !nextProps.archiveFetching) ||
			this.props.archive.length !== nextProps.archive.length
		) {
			this.createAttachmentOptions(nextProps.archive);
		}
	}

	fetchData(props) {
		const { isAdmin, user, gallery } = props;
		if (isAdmin && gallery) {
			this.props.dispatch(fetchArchiveForUser(gallery._id || gallery));
		} else if (user.gallery) {
			this.props.dispatch(
				fetchArchiveForUser(user.gallery._id || user.gallery)
			);
		}
	}

	createAttachmentOptions = (archive) => {
		const nameRegex = RegExp(/(\/.{24})-/, "g");

		const attachments = [];
		archive.forEach((file, index) => {
			const { key, URL, knownLength, contentType } = file;
			/* file ex: {
        key: uploads/pdf/5d381f9000378e6fdaed35a1/5e6b961e9468cf41ac766a8e-Betalflöde skiss Artworks (2).pdf,
        date: 2020-03-13T14:18:08.000Z,
        bucket: 'artworks-dev-images',
        URL: url...,
      } */

			// Find the last index of /[24 figures]- , what comes after is the filename.
			let arr;
			let lastIndex = 0;
			while ((arr = nameRegex.exec(key)) !== null) {
				// console.log(`Found ${arr[0]}. Next starts at ${nameRegex.lastIndex}.`)
				lastIndex = nameRegex.lastIndex;
			}
			const fileName = key.substring(lastIndex);

			attachments.push({
				value: key,
				label: fileName,
				URL,
				knownLength,
				contentType,
			});
		});

		this.setState({ attachmentOptions: attachments });
	};

	mailingListSelector = (memberCount) => {
		const { mailingLists, members, listsCount } = this.props;
		const { selectedMailingList, allMarked } = this.state;

		return (
			<div className={mailSelector()}>
				<input
					type="checkbox"
					className={mailSelector("button")}
					onClick={() =>
						this.toggleSubscriptionAll(!allMarked, selectedMailingList)
					}
					checked={allMarked}
					disabled={!selectedMailingList || memberCount === 0}
				/>
			</div>
		);
	};

	selectMailingList = (id) => {
		const { members } = this.props;
		let allSelected = true;
		Object.keys(members).forEach((key) => {
			const member = members[key];
			if (member.mailingLists && member.mailingLists.indexOf(id) < 0)
				allSelected = false;
		});

		this.setState({
			selectedMailingList: id,
			allMarked: allSelected,
		});
	};

	toggleSectionOpen = () => {
		this.setState({
			sectionOpen: !this.state.sectionOpen,
		});
	};

	changeSendToList = (list) => {
		this.selectMailingList(list);
	};

	changeEmailHeader = (e) => {
		const text = e.target.value;
		this.setState({
			emailHeader: text,
		});
	};

	changeEmailBody = (e) => {
		const text = e.target.value;
		this.setState({
			emailBody: text,
		});
	};

	sendEmail = (senderEmail) => {
		const { gallery, mailingLists, userId, helpCenter } = this.props;
		const { emailHeader, emailBody, addedAttachments, selectedMailingList } =
			this.state;

		if (helpCenter) {
			this.props
				.dispatch(
					sendHelpEmail(userId, emailHeader, emailBody, addedAttachments)
				)
				.then((result) => {
					//Clear the fields after email has been sent
					this.clearFields();
				});
		} else {
			const selectedList = mailingLists.find(
				(list) => list._id === selectedMailingList
			);
			const metadata = {
				listName: selectedList.name,
			};

			this.props.dispatch(
				sendEmailToMembers(
					gallery._id,
					selectedMailingList,
					senderEmail,
					emailHeader,
					emailBody,
					addedAttachments,
					metadata,
					gallery.name
				)
			);
		}
	};

	togglMailingListsDropdownOpen = () => {
		this.setState({
			mailingListsDropdownOpen: !this.state.mailingListsDropdownOpen,
		});
	};

	toggleMailingListModal = () => {
		this.setState({
			mailingListModalOpen: !this.state.mailingListModalOpen,
		});
	};

	mailingListNameChange = (e) => {
		const value = e.target.value;
		this.setState({
			mailingListName: value,
		});
	};

	_createMailingList = (e) => {
		e.preventDefault();
		const { mailingListName } = this.state;
		const { gallery } = this.props;
		if (!mailingListName || !gallery) return;

		this.props
			.dispatch(createMailingList(gallery._id, mailingListName))
			.then((response) => {
				const gallery = response.response;
				const selectedList = gallery.registry.mailingLists.find(
					(list) => list.name === mailingListName
				);

				this.setState(
					{
						mailingListName: "",
						selectedMailingList: selectedList._id,
					},
					() => {
						this.toggleMailingListModal();
					}
				);
			});
	};

	selectOption = (id) => {
		this.changeSendToList(id);
		this.togglMailingListsDropdownOpen();
	};

	toggleAttachmentsDropdownOpen = () => {
		this.setState({
			attachmentDropdownOpen: !this.state.attachmentDropdownOpen,
		});
	};

	addAttachmentFromFileArchive = (attachment) => {
		const addedAttachments = this.state.addedAttachments.slice();
		// { data, filename, knownLength, contentType }, needed for mailgun attachment.
		const attachObj = {
			// data: use URL here instead.
			// URL needs to be "request"-ed in API and set as "data". (in artSocietyEmail.js, batchedMail())
			URL: encodeURI(attachment.URL),
			filename: attachment.label,
			knownLength: attachment.knownLength,
			contentType: attachment.contentType,
			// Value used see to see if file added or to remove it. Not used in backend.
			value: attachment.value,
		};

		addedAttachments.push(attachObj);

		this.setState({
			addedAttachments,
			attachmentDropdownOpen: false,
		});
	};

	removeAttachment = (attachment) => {
		const addedAttachments = [];
		this.state.addedAttachments.forEach((added) => {
			if (added.filename !== attachment.filename) addedAttachments.push(added);
		});

		this.setState({
			addedAttachments,
		});
	};

	importFile = (evt) => {
		evt.preventDefault();
		const file = this.fileUpload.files[0];

		const reader = new FileReader();
		reader.onload = (upload) => {
			const addedAttachments = this.state.addedAttachments.slice();
			// { data, filename, knownLength, contentType }, needed for mailgun attachment.
			const attachObj = {
				// data will be base64
				data: upload.target.result,
				filename: file.name,
				knownLength: file.size,
				contentType: file.type,
				// Value used see to see if file added or to remove it. Not used in backend.
				value: file.name,
			};

			addedAttachments.push(attachObj);

			this.setState({
				addedAttachments,
			});
		};

		reader.readAsDataURL(file);
	};

	isSendDisabled = (messageAlreadySent) => {
		const { helpCenter = false, listsCount, registryFetching } = this.props;
		const { emailHeader, emailBody, selectedMailingList } = this.state;
		const sendButtonDisabled = !helpCenter
			? !emailHeader ||
			  !emailBody ||
			  !selectedMailingList ||
			  messageAlreadySent ||
			  registryFetching ||
			  listsCount[selectedMailingList] < 1
			: !emailHeader || !emailBody || registryFetching;

		return sendButtonDisabled;
	};

	clearFields = () => {
		this.setState({
			emailHeader: "",
			emailBody: "",
			addedAttachments: [],
		});
	};

	render() {
		const {
			gallery,
			premium,
			standardOrAbove,
			isAdmin,
			user,
			registryFetching,
			archiveFetching,
			mailingLists,
			listsCount,
			archive,
			helpCenter = false,
			componentHeader = "Skicka mejl",
			t,
		} = this.props;
		const {
			sectionOpen,
			emailHeader,
			emailBody,
			lastSentEmailBody,
			lastSentToMailingList,
			mailingListsDropdownOpen,
			selectedMailingList,
			mailingListName,
			attachmentDropdownOpen,
			selectedAttachmentOption,
			attachmentOptions,
			addedAttachments,
		} = this.state;
		const senderEmail = gallery && gallery.email ? gallery.email : user.email;
		const senderToShowUser = gallery ? `${gallery.name}, ${senderEmail}` : "";
		// const senderForActualEmail = gallery ? `${gallery.name} <${senderEmail}>` : ''
		const selectedListForEmail = selectedMailingList
			? mailingLists.find((list) => list._id === selectedMailingList)
			: "";
		let sendMailButtonText = "Skicka";
		if (selectedMailingList)
			sendMailButtonText += ` till ${listsCount[selectedMailingList]} adresser`;
		const messageAlreadySent =
			lastSentEmailBody === emailBody &&
			lastSentToMailingList === selectedMailingList;
		const sendButtonDisabled = this.isSendDisabled(messageAlreadySent);
		const filArchiveDisabled = archive.length < 1 || !standardOrAbove;

		//Always display the content if helpcenter email
		const displayContent = helpCenter ? true : sectionOpen;

		return (
			<div className="email-writer">
				<legend className="email-writer__mail-opener">
					{componentHeader}
					{!helpCenter && (
						<ExpandButton
							expanded={sectionOpen}
							click={this.toggleSectionOpen}
							style={{ top: 0 }}
						/>
					)}
				</legend>
				<div
					className={
						"email-writer__mailing-lists" + (displayContent ? " is-open" : "")
					}
				>
					{/* Mailing lists are not enabled for helpcenter emails */}
					{/* Mailing lists */}
					{!helpCenter && (
						<p className="email-writer__mailing-lists__dropdown-header">
							Mottagare:
						</p>
					)}
					{!helpCenter && (
						<div className="email-writer__mailing-lists__dropdown-container">
							<div
								className={
									"email-writer__mailing-lists__dropdown" +
									(mailingLists.length < 1 ? " is-hidden" : "")
								}
							>
								<div
									className={
										"email-writer__mailing-lists__dropdown__selected" +
										(!selectedListForEmail ? " is-placeholder" : "")
									}
									onClick={this.togglMailingListsDropdownOpen}
								>
									{selectedListForEmail ? (
										<p>
											{selectedListForEmail.name}
											<span>{` ( ${
												listsCount[selectedListForEmail._id]
											} personer )`}</span>
										</p>
									) : (
										<p>{"Välj en lista"}</p>
									)}
									<img src={CHEVRON} />
								</div>
								{mailingLists.map((list) => {
									if (list._id === selectedMailingList) return null;
									return (
										<div
											className={
												"email-writer__mailing-lists__dropdown__option" +
												(mailingListsDropdownOpen ? "" : " is-closed")
											}
											onClick={() =>
												registryFetching ? {} : this.selectOption(list._id)
											}
											key={list._id}
										>
											<p>{`${list.name} ( ${
												listsCount[list._id]
											} personer )`}</p>
										</div>
									);
								})}
							</div>
							<div className="email-writer__create-list">
								<PLUS_WHITE
									className="email-writer__create-list__button"
									onClick={() => this.toggleMailingListModal()}
								/>
								<p onClick={() => this.toggleMailingListModal()}>
									{"Skapa lista"}
								</p>
							</div>
						</div>
					)}
					{selectedMailingList && (
						<Link to="/members">
							<p
								className={
									"email-writer__mailing-lists__add-recipients-link" +
									(listsCount[selectedMailingList] === 0 ? " is-animated" : "")
								}
							>
								Klicka <u>här</u> för att lägga till medlemmar till listan
							</p>
						</Link>
					)}
					{/* Mailing lists end */}

					{/* Email inputs */}
					<div className="email-writer__mailing-lists__write-email">
						{!helpCenter && <p>{`${"Avsändare:"} ${senderToShowUser}`}</p>}
						<input
							onChange={this.changeEmailHeader}
							value={emailHeader}
							placeholder="Rubrik"
							disabled={registryFetching}
						/>
						<textarea
							rows={10}
							onChange={this.changeEmailBody}
							value={emailBody}
							placeholder="Meddelande"
							disabled={registryFetching}
						/>
					</div>
					{/* Email inputs end */}

					{/* ATTACHMENTS */}
					{/* Attached files */}
					<div
						className={
							"added-attachments" +
							(addedAttachments.length > 0 ? " is-filled" : "")
						}
					>
						{addedAttachments.map((attachment, index) => {
							return (
								<div className="added-attachments__attachment" key={index}>
									{attachment.filename}
									<img
										src={CLOSE}
										onClick={() => this.removeAttachment(attachment)}
									/>
								</div>
							);
						})}
					</div>
					{/* Attached files end */}

					{/* Attachments dropdown uses same style as mailing lists dropdown. */}
					<div className="email-writer__attach-files">
						{/*Only available for societies*/}
						{/* Attach files from file archive */}
						{!helpCenter && (
							<div
								className={
									"email-writer__file-archive-list" +
									(filArchiveDisabled ? " is-disabled" : "")
								}
							>
								{standardOrAbove ? (
									<div
										className="email-writer__file-archive-list__header"
										onClick={() =>
											!filArchiveDisabled
												? this.toggleAttachmentsDropdownOpen()
												: null
										}
									>
										<p>{"Bifoga fil från filarkiv"}</p>
										<img src={CHEVRON} />
									</div>
								) : (
									<div
										className="email-writer__file-archive-list__header"
										onClick={() =>
											__USE_TIERS__
												? showPremiumUpgradeModal(this.props.dispatch)
												: showPremiumFunctionalityModal(this.props.dispatch)
										}
									>
										<p>{"Bifoga fil från filarkiv"}</p>
										<img src={CHEVRON} />
									</div>
								)}
								<div
									className={
										"email-writer__file-archive-list__dropdown" +
										(!attachmentDropdownOpen ? " is-closed" : "")
									}
								>
									<div className="email-writer__file-archive-list__dropdown__options">
										{attachmentOptions.map((file, index) => {
											if (
												addedAttachments.find(
													(added) => added.value === file.value
												)
											)
												return null;
											return (
												<div
													className="email-writer__file-archive-list__dropdown__options__option"
													onClick={() =>
														this.addAttachmentFromFileArchive(file)
													}
													key={file.value}
												>
													<p>{file.label}</p>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
						{/* Attach files from file archive end */}

						{/* Attach files from computer */}
						{premium && (
							<input
								type="file"
								id="file"
								ref={(ref) => (this.fileUpload = ref)}
								onChange={this.importFile}
								className={"hidden-file-upload-button"}
								disabled={registryFetching || attachmentDropdownOpen}
							/>
						)}
						{helpCenter && (
							<label
								htmlFor="file"
								// className={ b('attach-files')('file-import-label').state({ hidden: attachmentDropdownOpen || !premium }) }
								className="purple-button--wide button-nomargin"
								onClick={() => {}}
								style={{ padding: "6.5px 20px" }}
							>
								Bifoga fil från dator
							</label>
						)}
						{/* user.email === 'lf@artworks.io' &&
              <label
                htmlFor="file"
                // className={ b('attach-files')('file-import-label').state({ hidden: attachmentDropdownOpen || !premium }) }
                className="purple-button--wide button-nomargin"
                onClick={() => {}}
                style={{padding: '6.5px 20px'}}
                >Bifoga fil från dator</label>
          */}
						{/*<label
              htmlFor="file"
              className={ b('attach-files')('file-import-label').state({ hidden: attachmentDropdownOpen || !premium }) }
              onClick={() => premium ? null : (__USE_TIERS__ ? showPremiumUpgradeModal(this.props.dispatch) : showPremiumFunctionalityModal(this.props.dispatch))}
              >Bifoga fil från dator</label>*/}
						{/* Attach files from computer end */}
					</div>
					{/* ATTACHMENTS END */}
					<button
						className="purple-button--wide button-nomargin"
						onClick={() => this.sendEmail(senderEmail)}
						disabled={sendButtonDisabled}
					>
						{sendMailButtonText}
					</button>
				</div>
				{this.state.mailingListModalOpen && (
					<div className="email-writer__modal-container">
						<div
							className="email-writer__modal-container__backdrop"
							onClick={() => this.toggleMailingListModal()}
						></div>
						<div className="email-writer__modal-container__modal">
							<div className="email-writer__modal-container__modal__create-list">
								<input
									className="email-writer__modal-container__modal__create-list__name"
									type="text"
									value={mailingListName}
									onChange={this.mailingListNameChange}
									placeholder={t("emailLists.listName")}
								/>
								<div
									className={
										"email-writer__modal-container__modal__create-list__save" +
										(!mailingListName ? " is-disabled" : "")
									}
									onClick={mailingListName ? this._createMailingList : null}
								>
									{t("emailLists.save")}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		registry: { mailSent, isFetching: registryFetching, error: registryError },
		archive: { archive, isFetching: archiveFetching, error: archiveError },
	} = state;
	const isAdmin = user.role === "admin";
	const isSociety = user.role === "society";

	return {
		isAdmin,
		isSociety,
		user,
		mailSent,
		registryFetching,
		archiveFetching,
		registryError,
		archive,
	};
}

const translated = withNamespaces("member")(EmailWriter);
export default connect(mapStateToProps)(translated);
