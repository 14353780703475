import React, { Component } from "react";
// import PropTypes from "prop-types";
import {
	Field,
	Fields,
	FormSection,
	reduxForm,
	formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import Select from "../../components/FormInput/Select";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextArea from "../../components/FormInput/TextArea";
import ImageUpload from "../../components/FormInput/ImageUpload";
import {
	isEmail,
	isValidImage,
	isRequiredText,
	isNumber,
} from "../../utils/Validators";

// const propTypes = {
// 	onSubmit: PropTypes.func.isRequired,
// 	error: PropTypes.string,
// };

class ContactListForm extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { handleSubmit, isAdmin, pristine, submitting, t } = this.props;
		const isResponsive = appGlobals.useResponsive;
		const formClass = isResponsive ? "responsive-form" : "Form";

		return (
			<form onSubmit={handleSubmit} className={formClass}>
				<div className="fieldset">
					<div className={isResponsive ? "input-holder-r" : "input-holder"}>
						<Field
							name="name"
							component={TextInput}
							placeholder={t("single.listName")}
							required={true}
						/>
					</div>
					{isAdmin && (
						<div className="input-holder">
							<Field
								name="price"
								component={TextInput}
								placeholder={t("single.listPrice")}
								required={false}
							/>
						</div>
					)}
				</div>
				{isAdmin && (
					<div className="fieldset">
						<legend>{t("single.uploadImage")}</legend>
						<Fields
							names={[
								"featuredImage.src",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={ImageUpload}
							validate={isValidImage}
						/>
					</div>
				)}
				<div className="fixed-save-button mobile-only">
					<button
						type="submit"
						className="purple-button--circle"
						disabled={pristine || submitting}
					>
						<span>{t("common:save")}</span>
					</button>
				</div>
			</form>
		);
	}
}

ContactListForm = reduxForm({
	form: "contactlist",
})(ContactListForm);

const selector = formValueSelector("contactlist");

export default withNamespaces("contactlist")(ContactListForm);
