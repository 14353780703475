import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import Main from "../../components/Main/Main";
import CreateMember from "./CreateMember";
import { withNamespaces } from "react-i18next";
import Registry from "../../components/Members/Registry";
import InfoPage from "../../components/Members/InfoPage";
import AdminFunctions from "../../components/Members/AdminFunctions";
import {
	fetchMembers,
	addMember,
	approveMember,
	removeMember,
} from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";

// import {
//   registerUser,
// } from '@artworkslab/sharedmodules/src/actions/ArtSocietyActions'
import { fetchArtSocieties } from "../../actions/GalleryActions";

const ARROW = require("../../../assets/images/icons/chevron_right_white_22px.png");

class Members extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAddMember: false,
			selectedFilter: "all",
			selectedSociety: 0,
			registryOpen: true,
			displayMembersPage: true,
			displayInfoPage: false,
		};
	}

	componentDidMount() {
		const { isAdmin, gallery } = this.props;
		if (!isAdmin && gallery) {
			this.props.dispatch(fetchMembers());
		} else if (isAdmin) {
			this.props.dispatch(fetchArtSocieties());
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.isAdmin && nextProps.isAdmin) {
			// isAdmin is false on will mount
			this.props.dispatch(fetchArtSocieties());
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.isAdmin) {
			if (
				this.state.selectedSociety !== nextState.selectedSociety &&
				this.props.members === nextProps.members
			) {
				return false;
			}
		}
		return true;
	}

	addMember = (model) => {
		// console.log('ONSUBMIT', model);
		// this.props.dispatch( registerUser() )
		if (!this.props.isAdmin) {
			this.props.dispatch(addMember(model.email));
		} else if (this.state.selectedSociety) {
			this.props.dispatch(addMember(model.email, this.state.selectedSociety));
		}
	};

	approveMember = (model) => {
		if (!this.props.isAdmin) {
			this.props.dispatch(approveMember(model.email));
		} else if (this.state.selectedSociety) {
			this.props.dispatch(
				approveMember(model.email, this.state.selectedSociety)
			);
		}
	};

	removeMember = (userId) => {
		if (!this.props.isAdmin) {
			this.props.dispatch(removeMember(userId));
		} else if (this.state.selectedSociety) {
			this.props.dispatch(removeMember(userId, this.state.selectedSociety));
		}
	};

	toggleAddMember = () => {
		this.setState({
			showAddMember: !this.state.showAddMember,
		});
	};

	toggleRegistryOpen = () => {
		this.setState({
			registryOpen: !this.state.registryOpen,
		});
	};

	setFilter = (fltr) => {
		this.setState({
			selectedFilter: fltr,
		});
	};

	// onSubmit = (model) => {
	//   console.log('ONSUBMIT', model);
	// 	// this.props.dispatch( saveCategory( model ) )
	// }

	sortMembers = (members, galleryId, t) => {
		let membersArr = {};
		let awaitingApproval = [];

		Object.keys(members).forEach((key) => {
			let user = members[key];

			const membership = user.memberships.find(
				(memb) => memb.association === galleryId
			);
			const isApproved = membership ? membership.approved : false;

			if (isApproved === true || isApproved === "true") {
				const firstLetter = (user.email || "-").toUpperCase().charAt(0);
				let array = membersArr[firstLetter] || [];
				array.push(
					<div key={user._id} className="List__objects__object">
						<Link>
							<div>
								<span style={{ minHeight: "23px" }}>{user.name}</span>
								<span className="List__objects__object__name">
									{user.email}
								</span>
								<button
									style={{ marginTop: "5px" }}
									onClick={() => {
										let confirm = window.confirm("Are you sure?");
										if (confirm) this.removeMember(user._id);
									}}
								>
									{t("remove")}
								</button>
							</div>
						</Link>
					</div>
				);
				membersArr[firstLetter] = array;
			} else {
				awaitingApproval.push(
					<div key={user._id} style={{ paddingTop: "20px" }}>
						<p style={{ color: "#A8A8A8" }}>{user.name}</p>
						<p>{user.email}</p>
						<button
							onClick={() => this.approveMember({ email: user.email })}
							style={{ margin: "5px 10px 0 0" }}
						>
							{t("approve")}
						</button>
						<button
							onClick={() => {
								let confirm = window.confirm("Are you sure?");
								if (confirm) this.removeMember(user._id);
							}}
						>
							{t("reject")}
						</button>
					</div>
				);
			}
		});

		let orderedMembers = {};
		let notCharMembers = [];
		Object.keys(membersArr)
			.sort()
			.forEach((key) => {
				// Email addresses that start with a number and not a letter are put in the 'other' category.
				if (parseInt(key) != key) {
					orderedMembers[key] = membersArr[key];
				} else {
					notCharMembers = notCharMembers.concat(membersArr[key]);
				}
			});

		orderedMembers["other"] = notCharMembers;

		return {
			orderedMembers,
			awaitingApproval,
		};
	};

	createFilterButtons = () => {};

	createFilterButton = (key) => {
		const { selectedFilter } = this.state;
		let name = key;
		if (key === "all") name = "Alla";
		if (key === "other") name = "Övriga";
		return (
			<button
				className={
					"member-filters__filter" +
					(selectedFilter === key ? " is-selected" : "")
				}
				onClick={() => this.setFilter(key)}
				key={key}
			>
				{name}
			</button>
		);
	};

	createSocietyButtons = () => {
		const { selectedSociety } = this.state;
		const { artSocieties } = this.props;
		return (
			<div className="society-filters">
				{Object.keys(artSocieties).map((key) => {
					const society = artSocieties[key];
					return (
						<button
							className={
								"society-filters__filter" +
								(selectedSociety === key ? " is-selected" : "")
							}
							onClick={() => this.selectSociety(key)}
							key={key}
						>
							{society.name}
							{selectedSociety === key && (
								<Link to={`/galleries/${society.slug}`}>
									<img src={ARROW} className="society-filters__filter__arrow" />
								</Link>
							)}
						</button>
					);
				})}
			</div>
		);
	};

	selectSociety = (galleryId) => {
		this.setState({
			selectedSociety: galleryId,
		});
		this.props.dispatch(fetchMembers(galleryId));
	};

	// Draws buttons used for switching displayed page
	pageSelectButtons = () => {
		const { t } = this.props;
		const { displayMembersPage, displayInfoPage } = this.state;

		return (
			<div className="page-select">
				<div
					className={
						"page-select__button" + (displayMembersPage ? " is-selected" : "")
					}
					onClick={() => this.toggleDisplay("members")}
				>
					{t("pageSelect.members")}
				</div>
				<div
					className={
						"page-select__button" + (displayInfoPage ? " is-selected" : "")
					}
					onClick={() => this.toggleDisplay("info")}
				>
					{t("pageSelect.info")}
				</div>
			</div>
		);
	};

	// Toggles which page is currentyl being displayed
	toggleDisplay = (page) => {
		let members = false,
			info = false;

		if (page === "members") {
			members = true;
		} else if (page === "info") {
			info = true;
		}
		this.setState({
			displayMembersPage: members,
			displayInfoPage: info,
		});
	};

	render() {
		const { members, gallery, isAdmin, artSocieties, t } = this.props;
		const {
			showAddMember,
			selectedFilter,
			selectedSociety,
			registryOpen,
			displayMembersPage,
			displayInfoPage,
		} = this.state;
		// membershipRequested email links to /members. If user is not logged in it redirects to /login, but gallery is undefined here and causes crash.
		// If gallery is undefined let galleryId be undefined. Returning null means admin will see an empty page.
		let galleryId = gallery && gallery._id ? gallery._id : gallery;
		if (isAdmin) galleryId = selectedSociety;

		const sortedMembers = this.sortMembers(members, galleryId, t);
		const orderedMembers = sortedMembers.orderedMembers;
		const awaitingApproval = sortedMembers.awaitingApproval;

		let memberSections = [];
		let sortButtons = [this.createFilterButton("all")];
		const pageSelector = this.pageSelectButtons();

		Object.keys(orderedMembers).forEach((key) => {
			if (key === selectedFilter || selectedFilter === "all") {
				memberSections.push(
					<div key={`society-${key}`} className="List__section">
						<h3 className="List__section-title">
							{key === "other" ? t(key) : key}
						</h3>
						<div className="List__objects">{orderedMembers[key]}</div>
					</div>
				);
			}

			sortButtons.push(this.createFilterButton(key));
		});

		// If the new membership registry is open.
		if (registryOpen) {
			if (isAdmin) {
				return (
					<div>
						<Main>
							{isAdmin && this.createSocietyButtons()}
							{pageSelector}
							{!!selectedSociety && displayMembersPage && (
								<Registry
									organisation={selectedSociety}
									toggleRegistry={this.toggleRegistryOpen}
								/>
							)}
							{!!selectedSociety && displayInfoPage && (
								<InfoPage organisation={selectedSociety} />
							)}
							{isAdmin && <AdminFunctions />}
						</Main>
					</div>
				);
			} else {
				return (
					<div>
						<Main>
							{pageSelector}
							{displayMembersPage && <Registry organisation={galleryId} />}
							{displayInfoPage && <InfoPage organisation={galleryId} />}
						</Main>
					</div>
				);
			}
		}

		if (isAdmin && !selectedSociety) {
			return (
				<div>
					<Main>{isAdmin && this.createSocietyButtons()}</Main>
				</div>
			);
		}
		return (
			<div>
				<Main>
					{isAdmin && this.createSocietyButtons()}
					{isAdmin && (
						<button
							onClick={this.toggleRegistryOpen}
							style={{ marginBottom: "50px", width: "100%" }}
						>
							Nya medlemsregistret (bara admin)
						</button>
					)}
					<button
						onClick={this.toggleAddMember}
						style={{ marginBottom: "20px" }}
					>
						{t("newMember")}
					</button>
					<CreateMember
						displayStyle={showAddMember ? "inline" : "none"}
						onSubmit={this.addMember}
					/>
					<div className="member-filters">{sortButtons}</div>
					{awaitingApproval.length > 0 && (
						<div className="List">
							<h2 className="List__section-title" style={{ paddingTop: "1em" }}>
								{t("awaitingApproval")}
							</h2>
							{awaitingApproval}
						</div>
					)}
					<div className="List">
						<h2 className="List__section-title" style={{ paddingTop: "1em" }}>
							{t("members")}
						</h2>
						{memberSections}
					</div>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		user: { gallery },
		isAdmin,
	} = state.auth;
	const {
		gallery: { galleries: artSocieties },
	} = state;
	const { members } = state.artSociety;
	return { members, gallery, isAdmin, artSocieties };
}

const translated = withNamespaces("member")(Members);
export default connect(mapStateToProps)(translated);
