export const HIDE_SUCCESS_MODAL = 'artworksapp/modals/HIDE_SUCCESS_MODAL'
export const HIDE_ERROR_MODAL = 'artworksapp/modals/HIDE_ERROR_MODAL'

export function hideError(){
  return {
    type: HIDE_ERROR_MODAL,
    showError: false
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS_MODAL,
    showSuccess: false
  }
}
