import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
	initialize,
} from "redux-form";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import XmasCalendarForm from "./XmasCalendarForm";
import Main from "../../components/Main/Main";
import { fetchAllGalleriesFast } from "../../actions/GalleryActions";
import { submitCalendarForm } from "../../actions/FormActions/XmasCalendarFormActions";
import {
	fetchXmasCalendar,
	fetchXmasGalleries,
	saveXmasCalendar,
	reorderXmasArtwork,
	alterXmasArtwork,
	deleteCalendar,
	retireCalendar,
} from "@artworkslab/sharedmodules/src/actions/XmasActions";

const BIN_SVG = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");
const XMAS_IMAGE = require("../../../assets/images/christmas-socks.jpg");
const CLOSE_WHITE = require("../../../assets/images/icons/close.white@2x.png");

class OrderInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			index: 0,
		};
	}

	componentWillMount() {
		// Always run after updating.
		this.setInitialState(this.props);
	}

	componentWillReceiveProps(nextProps) {
		// To update when changing sort order in table. Nextprops belongs to another item (another id).
		this.setInitialState(nextProps);
	}

	setInitialState = (props) => {
		// When receivning props, update only when changin sort order in table.
		// if (props.id === this.state.id) return

		this.setState({
			id: props.id,
			index: props.initialValue,
		});
	};

	updateOrder = () => {
		const { id, update, xmasCalendarId, initialValue } = this.props;
		// Not strict equality, initialValue is a number, index from state is a string.
		if (this.state.index != initialValue) {
			// If setting index to 0, it will be updated to -1, = "removed" index.
			const newIndex = this.state.index;

			update(newIndex, initialValue, xmasCalendarId);
		}
	};

	changeIndex = (e) => {
		let newIndex = e.target.value;
		this.setState({ index: newIndex });
	};

	render() {
		const { index } = this.state;
		return (
			<input
				type="number"
				value={index >= 0 ? index : ""}
				min={0}
				onChange={this.changeIndex}
				onBlur={this.updateOrder}
				onKeyPress={(event) => {
					if (event.key === "Enter") {
						this.updateOrder();
					}
				}}
				className={"list-and-upload__order__input"}
			/>
		);
	}
}

class XmasCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			selectedGallery: null,
			viewedArtwork: false,
		};
	}

	componentWillMount() {
		const { isAdmin, gallery, xmasCalendar, isFetching } = this.props;
		if (isAdmin) {
			this.props.dispatch(fetchAllGalleriesFast());
			this.props.dispatch(fetchXmasGalleries());
		} else if (gallery && gallery._id) {
			this.fetchData(this.props);
		}
		if (xmasCalendar) {
			this.props.dispatch(initialize("xmascalendar", xmasCalendar));
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, gallery, xmasCalendar, isFetching } = this.props;

		const thisId = this.props.xmasCalendar
			? this.props.xmasCalendar._id
			: false;
		const nextId = nextProps.xmasCalendar ? nextProps.xmasCalendar._id : false;

		if (thisId !== nextId) {
			this.props.dispatch(initialize("xmascalendar", nextProps.xmasCalendar));
		}

		const thisGalleryId =
			this.props.gallery && this.props.gallery._id
				? this.props.gallery._id
				: false;
		const nextGalleryId =
			nextProps.gallery && nextProps.gallery._id
				? nextProps.gallery._id
				: false;

		if (thisGalleryId !== nextGalleryId && nextGalleryId) {
			this.fetchData(nextProps);
		}

		if (
			isAdmin &&
			this.state.galleryOptions.length !==
				Object.keys(nextProps.allGalleries).length > 0
		) {
			const galleryOptions = Object.keys(nextProps.allGalleries).map((key) => {
				const venue = nextProps.allGalleries[key];
				return {
					value: venue._id,
					label: venue.name,
				};
			});
			this.setState({
				galleryOptions: galleryOptions.sort(this.sortOptions),
			});
		}
	}

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	fetchData = (props) => {
		this.props.dispatch(fetchXmasCalendar(props.gallery._id));
	};

	selectGallery = (gallery) => {
		const { allGalleries } = this.props;
		this.setState(
			{
				selectedGallery: gallery,
			},
			() => {
				this.props.dispatch(fetchXmasCalendar(gallery.value));
			}
		);
	};

	createXmasCalendar = () => {
		const { gallery, isAdmin } = this.props;
		const galleryId = isAdmin ? this.state.selectedGallery.value : gallery._id;
		const body = {
			gallery: galleryId,
			data: { uploadedImage: { URI: XMAS_IMAGE } },
		};
		this.props.dispatch(saveXmasCalendar(body));
	};

	handleSubmit = () => {
		const {
			form: { xmascalendar, initial },
			gallery,
			isAdmin,
		} = this.props;
		const galleryId = isAdmin ? this.state.selectedGallery.value : gallery._id;
		this.props.dispatch(submitCalendarForm(xmascalendar, initial, galleryId));
	};

	updateOrder = (newIndex, oldIndex, xmasCalendarId) => {
		const fixNewIndex = newIndex > 0 ? newIndex - 1 : newIndex;
		const fixOldIndex = oldIndex - 1;
		if (xmasCalendarId) {
			this.props.dispatch(
				reorderXmasArtwork(fixNewIndex, fixOldIndex, xmasCalendarId)
			);
		} else {
			console.log("NOTHIN TO ORDER BY");
			return;
		}
	};

	removeXmasArtwork = (artworkId, xmasCalendarId, t) => {
		const removeArtwork = true;
		let confirm = window.confirm(t("areYouSure"));
		if (confirm) {
			this.props.dispatch(
				alterXmasArtwork(artworkId, xmasCalendarId, removeArtwork)
			);
		}
	};

	viewArtwork = (sources) => {
		this.setState({
			viewedArtwork: sources,
		});
	};

	deleteXmasCalendar = () => {
		const { xmasCalendar } = this.props;
		this.props.dispatch(deleteCalendar(xmasCalendar._id));
	};

	retireXmasCalendar = () => {
		const { xmasCalendar } = this.props;
		this.props.dispatch(retireCalendar(xmasCalendar._id));
	};

	createRows = (artworks) => {
		const { isFetching, xmasCalendar, t } = this.props;

		const rows = [];
		const xmasCalendarId = xmasCalendar._id;
		const iconStyle = Object.assign(
			{},
			{
				height: "25px",
				width: "auto",
				cursor: "pointer",
			},
			isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
		);

		Object.keys(artworks).forEach((key, index) => {
			const position = index + 1;
			const artwork = artworks[key];
			const image = artwork.images[0];
			const smallThumbnail = image.thumbnails
				? image.thumbnails["100x100"].src
				: image.src;
			const largeThumbnail = image.thumbnails
				? image.thumbnails["750x"].src
				: image.src;
			rows.push(
				<Tr key={artwork._id}>
					<Td column="order" value={position}>
						<div className={"list-and-upload__order"}>
							<OrderInput
								initialValue={position}
								keyForValue={"index"}
								id={artwork._id}
								xmasCalendarId={xmasCalendarId}
								update={this.updateOrder}
							/>
						</div>
					</Td>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{smallThumbnail && (
							<img
								src={smallThumbnail}
								onClick={() =>
									this.viewArtwork({
										fullSrc: image.src,
										thumbnail: largeThumbnail,
									})
								}
								onError={(elem) => (elem.target.src = image.src)}
								style={{ width: "60px", cursor: "pointer" }}
								alt="image of artwork"
							/>
						)}
					</Td>
					<Td column="title" value={artwork.title}>
						<input
							type="text"
							value={artwork.title}
							disabled={true}
							className="input-field"
						/>
					</Td>
					<Td column="delete" value={"delete"}>
						<BIN_SVG
							style={iconStyle}
							onClick={() =>
								this.removeXmasArtwork(artwork._id, xmasCalendarId, t)
							}
							data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
						/>
					</Td>
				</Tr>
			);
		});
		return rows;
	};

	drawGalleryNames = (galleryNames) => {
		const galleryArr = [];
		galleryNames.map((galleryName, index) => {
			galleryArr.push(<li key={index}>{galleryName}</li>);
		});
		return galleryArr;
	};

	render() {
		const {
			isAdmin,
			isFetching,
			xmasCalendar,
			xmasGalleries,
			gallery,
			form,
			t,
		} = this.props;
		const { galleryOptions, selectedGallery, viewedArtwork } = this.state;

		let rows = [];
		let galleries = [];
		if (xmasCalendar && xmasCalendar.artworks) {
			rows = this.createRows(xmasCalendar.artworks);
		}
		if (xmasGalleries.length > 0) {
			galleries = this.drawGalleryNames(xmasGalleries);
		}
		let tableHead = <Thead></Thead>;
		tableHead = (
			<Thead>
				<Th column="order">{t("table.order")}</Th>
				<Th column="image">{` `}</Th>
				<Th column="title">{t("table.title")}</Th>
				<Th column="delete">{` `}</Th>
			</Thead>
		);

		return (
			<div className="xmascalendar">
				<Main>
					{isAdmin && (
						<div className="input-holder input-holder--full input-holder--nopadding">
							<legend className="legend-nomargin">{t("common:venue")}</legend>
							<Select
								value={selectedGallery}
								options={galleryOptions}
								onChange={this.selectGallery}
								valueKey={"gallery"}
								labelKey="label"
								backspaceToRemoveMessage=""
								backspaceRemoves={false}
								isLoading={isFetching}
								placeholder={"Gallery"}
								multi={false}
								autosize={false}
								className={"gallery-picker-select"}
								isSearchable={true}
							/>
							<div>
								<h3>Föreningar med en julkalender</h3>
								<ul>{galleries}</ul>
							</div>
						</div>
					)}
					{!xmasCalendar && (gallery._id || selectedGallery) && (
						<button
							className="purple-button--wide button-nomargin"
							onClick={() => this.createXmasCalendar()}
						>
							{t("createXmas")}
						</button>
					)}
					{xmasCalendar && (
						<div className="xmascalendar__info-container">
							<legend
								className="legend-nomargin"
								style={{
									width: "100%",
									marginTop: "50px",
									marginBottom: "-1px",
								}}
							>
								{t("artworks")}
							</legend>
							<Table
								className="reactable"
								sortable={["title", "order"]}
								defaultSort={{ column: "order", direction: "asc" }}
								filterable={false}
								filterPlaceholder={"Search artwork"}
								itemsPerPage={30}
								pageButtonLimit={5}
							>
								{tableHead}
								{rows}
							</Table>
							<XmasCalendarForm
								isAdmin={isAdmin}
								handleSubmit={this.handleSubmit}
							/>
							<div className="fieldset">
								<legend style={{ paddingTop: 40 }}>
									{t("common:dangerZone")}
								</legend>
								<button
									type="button"
									className="xmascalendar__purple-button--wide--mod_danger"
									onClick={() => {
										let confirm = window.confirm(t("common:areYouSure"));
										if (confirm) this.deleteXmasCalendar();
									}}
								>
									{t("xmasDelete")}
								</button>
							</div>
							{isAdmin && (
								<div className="fieldset">
									<legend style={{ paddingTop: 40 }}>
										{t("common:dangerZone")} (Admin only)
									</legend>
									<button
										type="button"
										className="xmascalendar__purple-button--wide--mod_danger"
										onClick={() => {
											let confirm = window.confirm(t("common:areYouSure"));
											if (confirm) this.retireXmasCalendar();
										}}
									>
										Retire Calendar
									</button>
								</div>
							)}
						</div>
					)}
					{viewedArtwork && (
						<div
							className="xmascalendar__artwork-view"
							onClick={() => this.viewArtwork(false)}
						>
							<img
								src={CLOSE_WHITE}
								className="xmascalendar__artwork-view__close"
							/>
							<img
								src={viewedArtwork.thumbnail}
								className="xmascalendar__artwork-view__image"
								onError={(elem) => (elem.target.src = viewedArtwork.fullSrc)}
								alt="image of artwork"
							/>
						</div>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		xmascalendar: { xmasCalendar, xmasGalleries, isFetching },
		auth: { user },
		gallery: { gallery, allGalleries },
	} = state;
	const isAdmin = user.role === "admin";
	const form = {
		submitting: isSubmitting("xmascalendar")(state),
		submitSucceeded: hasSubmitSucceeded("xmascalendar")(state),
		submitFailed: hasSubmitFailed("xmascalendar")(state),
		xmascalendar: getFormValues("xmascalendar")(state),
		initial: getFormInitialValues("xmascalendar")(state),
	};
	return {
		gallery,
		allGalleries,
		isAdmin,
		isFetching,
		xmasCalendar,
		xmasGalleries,
		form,
	};
}

const translated = withNamespaces("xmascalendar")(XmasCalendar);
export default connect(mapStateToProps)(withRouter(translated));
