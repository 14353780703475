'use strict'
import Validator                from 'validatorjs'
import diff                     from 'object-diff'
import i18next                  from 'i18next'
import sv                       from './sv'
import {
  savePrint,
  removePrint,
} from '../PrintActions'
import {
  showErrorModal,
} from '../AppActions'
import {
  initialize,
  startSubmit,
  stopSubmit,
  change,
} from 'redux-form'

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

export function submitPrint(data, initial) {
  return (dispatch, getState) => {
    const { user } = getState().auth
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    let rules = {
      title: 'required',
    }

    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if (isSwedish) {
      validation.setAttributeNames({
        title: 'Titel',
      })
    }

    const imageIndexes = {}
    if (data.images && data.images.length > 0) {
      data.images.forEach(image => {
        imageIndexes[image._id] = image.index
      })
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)

    let changedValues = initial ? diff(initial, data) : data
    if (data._id) changedValues._id = data._id
    changedValues.created_by = user.id
    changedValues.imageIndexes = imageIndexes

    let removedImageIds = []
    // If an image has been removed
    if (changedValues.images) {
      const initialImages = initial.images ? initial.images.map(img => img._id) : []
      const currentImages = data.images.map(img => img._id)
      removedImageIds = initialImages.filter(imgId => !!!~currentImages.indexOf(imgId))
      changedValues.removes = removedImageIds
    }

    if (Object.keys(errors).length < 1) {
      dispatch( startSubmit('print') )
      return dispatch( savePrint( changedValues ) )
      .then(data => {
        dispatch( initialize('print', data, null, { keepSubmitSucceeded: true }) )
      })
      .catch(err => {
        console.log('ERROR', err);
      })
    } else {
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('print', errors) )
      dispatch( showErrorModal(translate('requiredFields')) )
    }
  }
}

export function deletePrint(data) {
  return dispatch => {
    dispatch( removePrint(data) )
  }
}

export function initPrint() {
  return (dispatch, getState) => {
    const { user, isAdmin } = getState().auth
    dispatch( initialize('print', {}) )
    dispatch( change('print', 'artworks', []) )
  }
}
