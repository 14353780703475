import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import Spinner from "react-spinkit";
import Select from "react-select";
import { withRouter } from "react-router";
import Main from "../../../components/Main/Main";
import MainResp from "../../../components/Main/MainResp";
import TransactionModal from "../../../components/Modal/Custom/TransactionModal";
import ExpandButton from "../../../components/DashboardComponents/ExpandButton/ExpandButton";
import {
	fetchArtistInvoices,
	removeInvoice,
} from "../../../actions/ArtworkInvoiceActions";
import {
	fetchArtistTransactions,
	removeTransaction,
} from "../../../actions/ArtworkTransactionActions";
import { fetchAllArtistsFast } from "../../../actions/ArtistActions";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const BIN_SVG = require("../../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");

class PreviousInvoices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			dropdownOptions: [],
			selectedArtist: null,
			modalOpen: false,
			curTransaction: {},
			sections: {
				transExpanded: true,
				invExpanded: true,
			},
		};
	}

	componentDidMount() {
		const { user, isAdmin, allArtists } = this.props;
		if (user.artist) {
			this.props.dispatch(fetchArtistInvoices(user.artist));
			this.props.dispatch(fetchArtistTransactions(user.artist));
		}
		//Fetch all artist if user is admin
		if (Object.keys(allArtists).length === 0 && isAdmin) {
			this.props.dispatch(fetchAllArtistsFast());
		}
	}

	componentWillReceiveProps(nextProps) {
		//Handles artist selector
		const { isAdmin, allArtists } = nextProps;
		const { selectedArtist } = this.state;
		const artistKeys = Object.keys(allArtists);
		let artistOptions = this.state.artistOptions;
		let updateOptions = false;

		//Update list if we are missing artists
		if (artistOptions.length !== artistKeys.length) {
			artistOptions = [];
			artistOptions.push({
				value: "All",
				label: "All",
				type: "artist",
			});
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				artistOptions.push({
					value: artist._id,
					label: artist.name,
					type: "artist",
				});
			});
			updateOptions = true;
		}

		//Update list if something changed
		if (updateOptions) {
			this.setState({
				artistOptions,
				dropdownOptions: artistOptions.sort(this.sortOptions),
			});
		}

		//Fetches artist invoices
		const thisArtist = this.props.user.artist || null;
		const nextArtist = nextProps.user.artist || null;
		if (thisArtist !== nextArtist && nextArtist) {
			this.fetchData(nextArtist);
		}
	}

	//Fetches data for artist of given id
	fetchData(artistId) {
		this.props.dispatch(fetchArtistInvoices(artistId));
		this.props.dispatch(fetchArtistTransactions(artistId));
	}

	//Action when selecting artist
	selectArtist = (artist) => {
		this.setState({
			selectedArtist: artist,
		});
		this.fetchData(artist.value);
	};

	//Renders dropdown artist-selector for admins
	renderArtistSelector = (isAdmin) => {
		const { fetchingArtist, t } = this.props;
		const { dropdownOptions, selectedArtist } = this.state;
		if (!isAdmin) return null;
		return (
			<div>
				<Select
					value={selectedArtist}
					options={dropdownOptions}
					onChange={this.selectArtist}
					onInputChange={() => {}}
					valueKey={"artist"}
					labelKey="label"
					backspaceToRemoveMessage=""
					backspaceRemoves={false}
					isLoading={fetchingArtist}
					placeholder={t("artworks:list.artist")}
					mulit={false}
					autosize={false}
					className={"gallery-picker-select"}
					isSearchable={true}
				/>
			</div>
		);
	};

	//Toggles the expanded status of given section
	toggleExpanded = (section) => {
		const { sections } = this.state;
		sections[section] = !this.state.sections[section];
		this.setState({
			sections,
		});
	};

	//Toggles modalOpen and sets curTransaction
	toggleModal = (transaction = {}) => {
		const { modalOpen } = this.state;
		this.setState({
			modalOpen: !modalOpen,
			curTransaction: transaction,
		});
	};

	//Call api to remove given transaction
	removeTransaction = (transactionId) => {
		const { t } = this.props;
		if (window.confirm(t("removeTransactionWarning"))) {
			if (window.confirm(t("secondWarning"))) {
				this.props.dispatch(removeTransaction(transactionId));
			}
		} else {
			return;
		}
	};

	//Call api to remove given invoice
	removeInvoice = (invoiceId) => {
		const { user, isAdmin, t } = this.props;
		const { selectedArtist } = this.state;
		if (window.confirm(t("removeInvoiceWarning"))) {
			if (window.confirm(t("secondWarning"))) {
				this.props.dispatch(removeInvoice(invoiceId)).then((response) => {
					const artistId = isAdmin ? selectedArtist.value : user.artist;
					this.props.dispatch(fetchArtistTransactions(artistId));
				});
			}
		} else {
			return;
		}
	};

	//Renders table for invoices or transactions
	renderTable = (rows) => {
		const { isAdmin, t } = this.props;
		const itemsPerPage = 10;
		const displayPagination = rows.length > itemsPerPage;
		const tableHead = (
			<Table
				className={
					"reactable reactable-responsive" +
					(!displayPagination ? " is-pageless" : "")
				}
				sortable={["title", "created"]}
				defaultSort={{ column: "created", direction: "desc" }}
				filterable={false}
				itemsPerPage={itemsPerPage}
				pageButtonLimit={0}
				previousPageLabel={t("artworks:list.prevPage")}
				nextPageLabel={t("artworks:list.nextPage")}
			>
				<Thead>
					<Th column="title">{""}</Th>
					<Th column="created">{""}</Th>
					{isAdmin && <Th column="delete">{""}</Th>}
				</Thead>
				{rows}
			</Table>
		);

		return tableHead;
	};

	//Renders rows of transactions which do not yet have an invoice
	renderTransactionRows = (transactions) => {
		const { isFetching, t } = this.props;
		let rows = [];

		Object.keys(transactions).forEach((key) => {
			const transaction = transactions[key];
			const artworkTitle =
				transaction.artwork && transaction.artwork.title
					? transaction.artwork.title
					: t("unknownArt");
			const hasInvoice = transaction.createdInvoice;
			const hasArtwork = transaction.artwork ? true : false;
			const iconStyle = Object.assign(
				{},
				{
					height: "25px",
					width: "auto",
					cursor: "pointer",
				},
				isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
			);

			// Assure that the transaction has no connected invoice
			if (!hasInvoice) {
				rows.push(
					<Tr key={transaction._id} className="PreviousInvoices__reactable__tr">
						<Td column="title" value={artworkTitle}>
							<p
								onClick={() =>
									hasArtwork ? this.toggleModal(transaction) : {}
								}
							>
								<u>{artworkTitle}</u>
							</p>
						</Td>
						<Td column="created" value={null}>
							<p
								onClick={() =>
									hasArtwork ? this.toggleModal(transaction) : {}
								}
							>
								{DateUtil.dateMonthShort(
									transaction.created_at,
									i18next.language,
									true
								)}
							</p>
						</Td>
						<Td column="delete" value={"delete"} style={{ width: "50px" }}>
							<BIN_SVG
								style={iconStyle}
								onClick={() => this.removeTransaction(transaction._id)}
								data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
							/>
						</Td>
					</Tr>
				);
			}
		});

		return rows;
	};

	//Renders rows of completed invoices
	renderInvoiceRows = (invoices) => {
		const { isFetching, t } = this.props;
		let rows = [];

		Object.keys(invoices).forEach((key) => {
			const invoice = invoices[key];
			const pdfLink = invoice.awsLink || null;
			const invoiceTitle =
				invoice.blob && invoice.blob.fileName
					? invoice.blob.fileName.split("-")
					: [];
			const artworkTitle =
				invoiceTitle.length > 0
					? `${invoiceTitle[0]}-${invoiceTitle[2]}`
					: t("unknownInvoice");
			const iconStyle = Object.assign(
				{},
				{
					height: "25px",
					width: "auto",
					cursor: "pointer",
				},
				isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
			);

			rows.push(
				<Tr key={invoice._id} className="PreviousInvoices__reactable__tr">
					<Td column="title" value={artworkTitle}>
						<a href={pdfLink} target="_blank" disabled={!pdfLink}>
							{pdfLink ? (
								<span>
									<u>{artworkTitle}</u>
								</span>
							) : (
								<span>{artworkTitle}</span>
							)}
						</a>
					</Td>
					<Td column="created" value={invoice.created_at}>
						{DateUtil.dateMonthShort(
							invoice.created_at,
							i18next.language,
							true
						)}
					</Td>
					<Td column="delete" value={"delete"} style={{ width: "50px" }}>
						<BIN_SVG
							style={iconStyle}
							onClick={() => this.removeInvoice(invoice._id)}
							data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
						/>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const { isAdmin, invoices, transactions, isFetching, t } = this.props;
		const {
			selectedArtist,
			modalOpen,
			curTransaction,
			sections: { transExpanded, invExpanded },
		} = this.state;
		const isResponsive = appGlobals.useResponsive;
		const MainComponent = isResponsive ? MainResp : Main;

		//Render transactions with delete option and invoices without
		const transactionTable = this.renderTable(
			this.renderTransactionRows(transactions)
		);
		const invoiceTable = this.renderTable(this.renderInvoiceRows(invoices));
		const hasTransactions = transactions.length > 0;
		const hasInvoices = invoices.length > 0;
		const empty = !hasTransactions && !hasInvoices && !isFetching;

		//Render admin selectior
		const selector = this.renderArtistSelector(isAdmin);

		return (
			<div className="PreviousInvoices">
				<MainComponent contentClassName="slim-content">
					{isAdmin && selector}
					{modalOpen && (
						<TransactionModal
							transaction={curTransaction}
							close={this.toggleModal}
						/>
					)}
					{hasTransactions && (
						<div className="PreviousInvoices__section">
							<legend>{t("transactions")}</legend>
							<ExpandButton
								expanded={transExpanded}
								click={() => this.toggleExpanded("transExpanded")}
								style={{ top: "0" }}
							/>
							<div
								className={
									"PreviousInvoices_table" +
									(transExpanded ? " is-expanded" : "")
								}
							>
								{transactionTable}
							</div>
						</div>
					)}
					{hasInvoices && (
						<div className="PreviousInvoices__section">
							<legend>{t("invoices")}</legend>
							<ExpandButton
								expanded={invExpanded}
								click={() => this.toggleExpanded("invExpanded")}
								style={{ top: "0" }}
							/>
							<div
								className={
									"PreviousInvoices__table" +
									(invExpanded ? " is-expanded" : "")
								}
							>
								{invoiceTable}
							</div>
						</div>
					)}
					{empty && (
						<div className="PreviousInvoices__empty">
							<p>{t("empty")}</p>
						</div>
					)}
				</MainComponent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		artist: { allArtists, isFetching: fetchingArtist },
		artworkInvoice: { invoices, isFetching: fetchingInvoice },
		artworkTransaction: { transactions, isFetching: fetchingTransaction },
	} = state;
	const isAdmin = user.role === "admin";
	const isFetching = fetchingArtist || fetchingInvoice || fetchingTransaction;

	return { user, isAdmin, invoices, transactions, allArtists, isFetching };
}

const translated = withNamespaces("transaction")(PreviousInvoices);
export default connect(mapStateToProps)(withRouter(translated));
