'use strict'
import { saveSelection }  from '../SelectionActions'
import { showErrorModal } from '../AppActions'
import {
  initialize,
  startSubmit,
  stopSubmit,
  change,
}                         from 'redux-form'
import diff               from 'object-diff'
import Validator          from 'validatorjs'
import {
  getObjectProp,
  hasObjectProp,
}                         from '../../utils/Helpers'

export const SHOW_SUCCESS = 'artworksapp/selection/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/selection/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/selection/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/selection/HIDE_ERROR'

export function submitSelection(data, initial) {
  return dispatch => {
    let customErrors = {}
    let rules = {
      title: 'required',
    }

    const currentHTML = data.htmlContent
    const contentBlock = {
      order: 0,
      content: currentHTML,
    }
    data.contentBlock = [contentBlock]

    const currentHTMLSwedish = data.htmlContentSwedish
    const contentBlockSwedish = {
      order: 0,
      content: currentHTMLSwedish,
    }
    data.contentBlockSwedish = [contentBlockSwedish]

    if ((hasObjectProp(data, 'uploadedImage.URI') &&
        hasObjectProp(data, 'uploadedImage.contentType') &&
        hasObjectProp(data, 'uploadedImage.fileName'))
        || hasObjectProp(data, 'featuredImage.src')) {
          console.log('image is valid');
    } else {
      // Image not required anymore. A completely black base64 image provided in add() in shows.controller.js
      // console.log('image is invalid');
      // customErrors.featuredImage = {src: 'Image is required'}
    }

    const imageIndexes = {}
    if (data.images && data.images.length > 0) {
      data.images.forEach(image => {
        imageIndexes[image._id] = image.index
      })
    }

    const validation = new Validator(data, rules)
    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({}, customErrors, validatorErrors)

    let changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined
    changedValues.imageIndexes = imageIndexes

    let removedImageIds = []
    // If an image has been removed from image gallery
    if (changedValues.images && __ALLOW_MULITPLE_ARTWORKS__) {
      const initialImages = initial.images ? initial.images.map(img => img._id) : []
      const currentImages = data.images.map(img => img._id)
      removedImageIds = initialImages.filter(imgId => !!!~currentImages.indexOf(imgId))
      changedValues.removes = removedImageIds
    }

    if (changedValues.images && changedValues.uploads && !__ALLOW_MULITPLE_ARTWORKS__) {
      if (initial.images && initial.images.length > 0) {
        changedValues.removes = [initial.images[0]._id]
      }
      changedValues.images = []
    }

    if (Object.keys(errors) < 1) {
      dispatch( startSubmit('selection') )
      return dispatch( saveSelection(changedValues) )
      .then(data => {
        let selection = data
        dispatch( initialize('selection', selection, null, {keepSubmitSucceeded: true}) )
      })
      .catch(err => {
        console.log('ERROR', err);
      })
    } else {
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('selection', errors) )
      dispatch( showErrorModal('Make sure all required fields are filled in correctly.') )
    }
  }
}

export function initSelection(data) {
  return dispatch => {
    dispatch( initialize('selection', {}) )
  }
}

export function loadSelection(data) {
  return dispatch => {
    dispatch( initialize('selection', data) )
  }
}

export function showSuccess(){
  return {
    type: SHOW_SUCCESS,
    showSuccess: true
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS,
    showSuccess: false
  }
}


export function showError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
