import React, { Component } from "react";
import i18next from "i18next";

const LABELS = {
	firstName: "First name",
	lastName: "Last name",
	fullName: "Full name",
	birthDate: "Date of birth",
	email: "Email",
	phone: "Phone",
	mobile: "Cellphone",
	address: "Address",
	zip: "Post code",
	city: "City",
	employeeNumber: "Employee number",
};

const LABELS_SV = {
	firstName: "Förnamn",
	lastName: "Efternamn",
	fullName: "Hela namnet",
	birthDate: "Födelseår",
	email: "Email",
	phone: "Telefon",
	mobile: "Mobil",
	address: "Adress",
	zip: "Postnummer",
	city: "Ort",
	employeeNumber: "Anställningsnummer",
};

class MemberApplication extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Default state for columns. True fields match state in .../Components/UserInput/Forms/Register.js in webb.
			columns: {
				firstName: true,
				lastName: true,
				fullName: false,
				birthDate: false,
				email: true,
				phone: false,
				mobile: false,
				address: false,
				zip: false,
				city: false,
				employeeNumber: false,
			},
			customColumns: [],
		};
	}

	componentWillMount() {
		if (
			this.props.registry.applicationColumns.input.value &&
			this.props.registry.customColumns.input.value
		) {
			this.setColumns(
				this.props.registry.applicationColumns.input.value,
				this.props.registry.customColumns.input.value
			);
		}
	}

	componentWillReceiveProps(nextProps) {
		const newApplicationColumns =
			!this.props.registry.applicationColumns.input.value &&
			nextProps.registry.applicationColumns.input.value;
		const newCustomColumns =
			!this.props.registry.customColumns.input.value &&
			nextProps.registry.customColumns.input.value;
		if (newApplicationColumns || newCustomColumns) {
			this.setColumns(
				nextProps.registry.applicationColumns.input.value,
				nextProps.registry.customColumns.input.value
			);
		}
	}

	setColumns = (applicationColumns, customColumns) => {
		let cols = this.state.columns;
		let customCols = [];
		// This instead of just setting columns in state to value, or newly added columns in state will disappear.
		Object.keys(applicationColumns).forEach((key) => {
			cols[key] = applicationColumns[key];
		});
		Object.keys(customColumns).forEach((key) => {
			const col = customColumns[key];
			// Must set _id to keep same _id as before, and not get new _id:s when saving.
			customCols.push({
				_id: col._id,
				name: col.name,
				display: col.display,
				application: col.application,
			});
		});

		this.setState({
			columns: cols,
			customColumns: customCols,
		});
	};

	handleChange = (column, use) => {
		const updated = Object.assign({}, this.state.columns, {
			[column]: use,
		});

		this.setState(
			{
				columns: updated,
			},
			() => {
				this.props.registry.applicationColumns.input.onChange(updated);
				this.props.registry.applicationColumns.input.onBlur(updated);
			}
		);
	};

	handleChangeCustom = (column, use) => {
		const updated = this.state.customColumns.slice();
		updated.forEach((item) => {
			if (item.name === column.name) {
				item.application = use;
			}
		});

		this.setState(
			{
				customColumns: updated,
			},
			() => {
				this.props.registry.customColumns.input.onChange(updated);
				this.props.registry.customColumns.input.onBlur(updated);
			}
		);
	};

	render() {
		const { columns, customColumns } = this.state;
		const { names } = this.props;

		const isSwedish = i18next.language === "sv";

		return (
			<div className="member-application">
				{Object.keys(columns).map((key) => {
					const active = columns[key];
					return (
						<div key={key} className="member-application__item">
							<p
								className={
									"member-application__item__name" +
									(active ? " is-active" : "")
								}
							>
								{isSwedish ? LABELS_SV[key] : LABELS[key]}
							</p>
							<input
								type="checkbox"
								checked={active}
								onChange={() => this.handleChange(key, !active)}
								className="member-application__checkbox"
								disabled={key === "email"}
							/>
						</div>
					);
				})}
				{customColumns.map((column, index) => {
					return (
						<div key={column.name + index} className="member-application__item">
							<p
								className={
									"member-application__item__name" +
									(column.application ? " is-active" : "")
								}
							>
								{column.name}
							</p>
							<input
								type="checkbox"
								checked={column.application}
								onChange={() =>
									this.handleChangeCustom(column, !column.application)
								}
								className="member-application__checkbox"
								disabled={false}
							/>
						</div>
					);
				})}
			</div>
		);
	}
}

export default MemberApplication;
