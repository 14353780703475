import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import Main from "../../components/Main/Main";
import { fetchSelections } from "../../actions/SelectionActions";
// import { createSelection }  from '@artworkslab/sharedmodules/src/actions/SelectionActions'
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";

class Selections extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		const { isAdmin } = this.props;
		if (isAdmin) this.props.dispatch(fetchSelections());
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin } = nextProps;
		if (!this.props.isAdmin && nextProps.isAdmin)
			this.props.dispatch(fetchSelections());
	}

	add = () => {
		// this.props.dispatch( createSelection('halloj') )
	};

	render() {
		const { selections, isAdmin } = this.props;
		if (!isAdmin) return null;
		const rows = Object.keys(selections).map((key) => {
			const selection = selections[key];
			const forHomePage =
				selection.title === "HOME" ||
				selection.slug.indexOf("home-highlight") >= 0;
			return (
				<Tr key={selection._id} className={forHomePage ? "for-home-page" : ""}>
					<Td column="image">
						{selection.featuredImage && (
							<Link to={`/selections/${selection.slug}`}>
								<div
									className="table-image"
									style={{
										backgroundImage: `url(${selection.featuredImage.src})`,
									}}
								/>
							</Link>
						)}
					</Td>
					<Td column="title" value={selection.title}>
						<Link to={`/selections/${selection.slug}`}>{selection.title}</Link>
					</Td>
					<Td column="titleSwedish" value={selection.titleSwedish || ""}>
						<Link to={`/selections/${selection.slug}`}>
							{selection.titleSwedish}
						</Link>
					</Td>
					<Td column="created" value={selection.created_at}>
						<Link to={`/selections/${selection.slug}`}>
							{moment(selection.created_at).format("MMM Do, YYYY")}
						</Link>
					</Td>
				</Tr>
			);
		});
		return (
			<Main>
				<div className="MainTop">
					<h1>Selections</h1>
					<Link
						to="/selections/new"
						className="purple-button--wide purple-button--wide--mod_create"
					>
						Create new selection
					</Link>
				</div>
				<Table
					className="reactable"
					sortable={["title", "titleSwedish", "created"]}
					filterable={["title", "titleSwedish"]}
					filterPlaceholder="Search selections"
				>
					<Thead>
						<Th column="image">Image</Th>
						<Th column="title">Title</Th>
						<Th column="titleSwedish">Title swedish</Th>
						<Th column="created">Created at</Th>
					</Thead>
					{rows}
				</Table>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin },
		selection: { selections },
	} = state;
	return { isAdmin, selections };
}

export default connect(mapStateToProps)(Selections);
