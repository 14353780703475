"use strict";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import GalleryMembershipModal from "../Modal/Custom/GalleryMembershipModal";
import { checkInvoiceDetails } from "../../utils/Validators";
import { showErrorModal } from "../../actions/AppActions";
import { TermsAndConditions } from "@artworkslab/sharedmodules/src/utils/DocumentUtil";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import {
	getTier,
	isBasicOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const ARROW = require("../../../assets/images/svg/arrow_right.svg");

const MEMBERSHIPS = {
	free: "Free",
	basic: "Basic",
	standard: "Standard",
	premium: "Premuim",
};

const MEMBERSHIPS_SV = {
	free: "Gratis",
	basic: "Bas",
	standard: "Standard",
	premium: "Premuim",
};

class GalleryMembership extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			messageModalOpen: false,
		};
	}

	// Opens the GalleryMembershipModal
	openModal = () => {
		const invoiceDetailsComplete = checkInvoiceDetails(this.props.gallery);
		if (invoiceDetailsComplete) {
			this.setState({
				modalOpen: true,
			});
		} else {
			const message =
				i18next.language === "sv"
					? "Fyll i alla fakturadetaljer längst upp på sidan."
					: "Complete all invoice details at the top of this page.";

			this.props.dispatch(showErrorModal(message));
		}
	};

	closeModal = () => {
		this.setState({
			modalOpen: false,
		});
	};

	// Returns string for gallery membership level
	getMembership = () => {
		const { gallery, isSwedish } = this.props;
		if (!gallery || !gallery.billing) {
			return "free";
		}

		return gallery.billing.membershipLevel;
	};

	// Format dates to be displayed regarding payment
	getInvoiceDates = () => {
		const { gallery } = this.props;
		if (!gallery || !gallery.billing) {
			return { nextInvoice: "-", terminateBy: "-", lastPayment: "-" };
		}

		const membershipEnds = gallery.billing.membershipEnds;

		// Dates for when membership ends, is to be terminated, and last payment
		const moment = DateUtil.getMoment(i18next.language);
		const nextInvoice = DateUtil.dateMonthYear(moment(membershipEnds));
		const terminateBy = DateUtil.dateMonthYear(
			moment(membershipEnds).subtract(3, "months")
		);
		const lastPayment = DateUtil.dateMonthYear(
			moment(membershipEnds).subtract(1, "years")
		);

		if (gallery.billing.membershipLevel === "free") {
			return { nextInvoice: "-", terminateBy: "-", lastPayment };
		} else {
			return { nextInvoice, terminateBy, lastPayment };
		}
	};

	render() {
		const { modalOpen } = this.state;
		const { gallery, user, t } = this.props;
		const level = this.getMembership();
		const invoiceDates = this.getInvoiceDates();
		const tocLink = TermsAndConditions.artSocietyTerms(i18next.language);
		let societyTocHeader = "";
		let societyTocLink = null;
		const subscriber = isBasicOrAbove(gallery);
		let invoiceLink = "";
		if (gallery && gallery.billing && gallery.billing.invoiceLink)
			invoiceLink = gallery.billing.invoiceLink;

		if (gallery.venueType === "ArtSociety" && subscriber) {
			if (getTier(gallery) === 1) {
				// Basic
				societyTocHeader = "Tjänsteavtal Bas";
				societyTocLink =
					"https://docs.google.com/file/d/1blCZS2zIlH3CKd_qPSKMPIXmbkzIIJLt/view";
			} else if (getTier(gallery) === 2) {
				// Standard
				societyTocHeader = "Tjänsteavtal Standard";
				societyTocLink =
					"https://docs.google.com/file/d/1dB2lCoi0BLXBD4A-ELY_01WJ5xa4asn_/view";
			} else if (getTier(gallery) === 3) {
				// Premiunm
				societyTocHeader = "Tjänsteavtal Premium";
				societyTocLink =
					"https://docs.google.com/file/d/1ONVrhNmjfOyCk-bZEuH-DLwUoz3D23ls/view";
			}
		}

		return (
			<div className="GalleryMembership">
				<legend className="legend-nomargin">
					{t("galleryMembership.manage")}
				</legend>
				{/*Subsctiption section*/}
				<div className="GalleryMembership__section">
					<div className="GalleryMembership__section__row">
						<p>{t("galleryMembership.subscription")}</p>
						<p>{t(`galleryMembership.tiers.${level}`)}</p>
					</div>
					<div
						className="GalleryMembership__section__row"
						onClick={() => this.openModal()}
						style={{ cursor: "pointer" }}
					>
						<p>{t("galleryMembership.manage")}</p>
						<div className="modal-arrow">
							<ARROW />
						</div>
					</div>
				</div>

				{/* Hiding these for now, society complained that the dates were wrong. */}
				{/*Payment section*/}
				<legend className="legend-nomargin">
					{t("galleryMembership.history")}
				</legend>
				<div className="GalleryMembership__section">
					<div className="GalleryMembership__section__row">
						<p>{t("galleryMembership.method")}</p>
						{invoiceLink ? (
							<a href={invoiceLink} target="_blank">
								<u>{t("galleryMembership.invoice")}</u>
							</a>
						) : (
							<p>{level !== "free" ? t("galleryMembership.invoice") : "-"}</p>
						)}
					</div>
					<div className="GalleryMembership__section__row">
						<p>{t("galleryMembership.nextInvoice")}</p>
						<p>{invoiceDates.nextInvoice}</p>
					</div>
					<div className="GalleryMembership__section__row">
						<p>{t("galleryMembership.terminate")}</p>
						<p>{invoiceDates.terminateBy}</p>
					</div>
				</div>
				{/*Last payment*/}
				{subscriber && (
					<div className="GalleryMembership__section">
						<div className="GalleryMembership__section__row">
							<p>{t("galleryMembership.lastInvoice")}</p>
							<p>{invoiceDates.lastPayment}</p>
						</div>
					</div>
				)}

				{/*TOC*/}
				{societyTocLink ? (
					<div className="GalleryMembership__section">
						<p>{t("common:TOC.agreeToArtworks")}:</p>
						{societyTocLink && (
							<a
								style={{ textDecoration: "underline" }}
								target="_blank"
								href={societyTocLink}
							>
								{societyTocHeader}
							</a>
						)}
						<a
							style={{ textDecoration: "underline" }}
							target="_blank"
							href={tocLink}
						>
							{t("common:TOC.termsAndConditionsOnly")}
						</a>
						<a
							style={{ textDecoration: "underline" }}
							target="_blank"
							href={TermsAndConditions.pdpa(i18next.language)}
						>
							{t("common:TOC.PDPA")}
						</a>
					</div>
				) : (
					<div className="GalleryMembership__section">
						<p>{t("common:TOC.agreeToArtworks")}:</p>
						<a
							style={{ textDecoration: "underline" }}
							target="_blank"
							href={tocLink}
						>
							{t("common:TOC.termsAndConditionsArtSociety")}
						</a>
						<a
							style={{ textDecoration: "underline" }}
							target="_blank"
							href={TermsAndConditions.pdpa(i18next.language)}
						>
							{t("common:TOC.PDPA")}
						</a>
					</div>
				)}

				{/*Update modal*/}
				{modalOpen && (
					<GalleryMembershipModal
						gallery={gallery}
						user={user}
						close={() => this.closeModal()}
						canTerminate={true}
					/>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		gallery: { gallery },
		auth: { user },
	} = state;
	const isSwedish = i18next.language === "sv";

	return { gallery, user, isSwedish };
}

const translated = withNamespaces("components")(GalleryMembership);
export default connect(mapStateToProps)(translated);
