import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Router,
	Route,
	Redirect,
	Link,
	browserHistory,
	IndexRoute,
} from "react-router";
import RoutesResponsive from "./RoutesResponsive";
// This comment is only for testing.
import App from "./views/App/App";
import Home from "./views/Home/Home";
import ForArtists from "./views/Home/ForArtists";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import UpdatePassword from "./views/ResetPassword/UpdatePassword";
import Login from "./views/Login/Login";
// import LoginNew             from './views/Login/LoginNew'
// import Register             from './views/Register/Register'
import RegisterNew from "./views/Register/RegisterNew";

import Dashboard from "./views/Dashboard/Dashboard";
import SocietyDashboard from "./views/Dashboard/SocietyDashboard";
import ArtistDashboard from "./views/Dashboard/ArtistDashboard";

import Gallery from "./views/Gallery/Gallery";
import Galleries from "./views/Gallery/Galleries";
import ArtSocieties from "./views/Gallery/ArtSocieties";

import Members from "./views/Members/Members";
import Newsletter from "./views/Newsletter/Newsletter";

import Selection from "./views/Selections/Selection";
import Selections from "./views/Selections/Selections";

import Shows from "./views/Shows/Shows";
import CreateShow from "./views/Shows/CreateShow";
import EditShow from "./views/Shows/EditShow";
import Activities from "./views/Activities/Activities";

import Artworks from "./views/Artworks/Artworks";
import CreateArtwork from "./views/Artworks/CreateArtwork";
import EditArtwork from "./views/Artworks/EditArtwork";
import ArtworksNew from "./views/Artworks/ArtworksNew";
import AwSplitForRouter from "./views/Artworks/AwSplitForRouter";

import ScrapedShows from "./views/Scraped/ScrapedShows";
import AddShow from "./views/Scraped/AddShow";
import AddArtist from "./views/Scraped/AddArtist";
import ScrapedArtists from "./views/Scraped/ScrapedArtists";
import AddGallery from "./views/Scraped/AddGallery";
import ScrapedGalleries from "./views/Scraped/ScrapedGalleries";

import Events from "./views/Event/Events";
import CreateEvent from "./views/Event/CreateEvent";
import EditEvent from "./views/Event/EditEvent";

import Posts from "./views/Posts/Posts";
import CreatePost from "./views/Posts/CreatePost";
import EditPost from "./views/Posts/EditPost";

import Accounts from "./views/Account/Accounts";
import UserAccount from "./views/Account/UserAccount";
import AdminAccount from "./views/Account/AdminAccount";

import GallerySettings from "./views/Settings/Gallery/GallerySettings";
import ArtistSettings from "./views/Settings/Artist/ArtistSettings";

import Categories from "./views/Category/Categories";

import OrdersSelect from "./views/Orders/OrdersSelect";
import Orders from "./views/Orders/Orders";
import InvoiceOrders from "./views/Orders/InvoiceOrders";
import DiscountCodes from "./views/DiscountCodes/DiscountCodes";
import DiscountCode from "./views/DiscountCodes/DiscountCode";

import Artists from "./views/Artist/Artists";
import CreateArtist from "./views/Artist/CreateArtist";
import EditArtist from "./views/Artist/EditArtist";
import SingleArtist from "./views/Artist/SingleArtist";

import Rated from "./views/Rated/Rated";

import Raffles from "./views/Raffle/Raffles";
import Raffle from "./views/Raffle/Raffle";

import FileArchive from "./views/FileArchive/FileArchive";
import Emails from "./views/Emails/Emails";
import ChatPage from "./views/ChatPage/ChatPage";
import XmasCalendar from "./views/XmasCalendar/XmasCalendar";

import ContactLists from "./views/ContactLists/ContactLists";
import ContactList from "./views/ContactLists/ContactList";

import Announcements from "./views/Announcements/Announcements";
import Announcement from "./views/Announcements/Announcement";

import ArtworkInvoice from "./views/Artworks/Invoice/ArtworkInvoice";
import PreviousInvoices from "./views/Artworks/PreviousInvoices/PreviousInvoices";

import DataPage from "./views/Data/DataPage";

import ArtistsHelpCenter from "./views/HelpCenter/Artists/ArtistsHelpCenter";

import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";

import Billecta from "./views/Billecta/Billecta";
import SubscriptionsMain from "./views/Subscription/SubscriptionsMain";
import CancelledSubscriptions from "./views/Subscription/Cancelled/CancelledSubscriptions";
import KlarnaSubscriptions from "./views/Subscription/Klarna/KlarnaSubscriptions";
import StripeSubscriptions from "./views/Subscription/Stripe/StripeSubscriptions";
import StripeSubscription from "./views/Subscription/Stripe/StripeSubscription";

import Prints from "./views/Prints/Prints";
import CreatePrint from "./views/Prints/CreatePrint";
import EditPrint from "./views/Prints/EditPrint";
import Influencers from "./views/Influencer/Influencers";
import EditInfluencer from "./views/Influencer/EditInfluencer";

class Routes extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { isSociety, isAdmin, isArtist, authenticated } = this.props;

		const useResponsive = appGlobals.useResponsive;

		// Components made for responsive display, and routes we need to work on.
		if (useResponsive && (isAdmin || isArtist)) {
			return (
				<RoutesResponsive
					components={{
						authenticated,
						App,
						ArtworksNew,
						Activities,
						ArtworkInvoice,
						ChatPage,
						ContactLists,
						ContactList,
						CreateArtist,
						CreateArtwork,
						Dashboard,
						EditArtist,
						EditArtwork,
						ForArtists,
						PreviousInvoices,
						Login,
						RegisterNew,
						ResetPassword,
						SingleArtist,
						UpdatePassword,
						UserAccount,
						GallerySettings,
						ArtistSettings,
						DataPage,
						ArtistsHelpCenter,
					}}
					isAdmin={isAdmin}
					isArtist={isArtist}
				/>
			);
		}

		return (
			<Router history={browserHistory}>
				<Route path="/" component={App}>
					<IndexRoute component={RegisterNew} />
					<Route path="for-artists" component={ForArtists} />
					<Route path="login" component={Login} />
					<Route path="forgot" component={ResetPassword} />
					<Route path="reset/:token" component={UpdatePassword} />
					<Route path="register">
						<IndexRoute component={RegisterNew} />
						<Route path="artsociety" component={RegisterNew} />
						<Route path="artist" component={RegisterNew} />
						<Route path="gallery" component={RegisterNew} />
						<Route path="konstnarshuset" component={RegisterNew} />
					</Route>
					<Route
						path="dashboard"
						component={Dashboard}
						userName="dashboard"
						adminName="dashboard"
					/>
					<Route path="dashboard/societies" component={SocietyDashboard} />
					<Route path="dashboard/artists" component={ArtistDashboard} />
					<Route path="galleries">
						<IndexRoute component={Galleries} />
						<Route path="artsocieties" component={ArtSocieties} />
						<Route path=":galleryId" component={Gallery} />
					</Route>
					<Route
						path="gallery"
						component={Gallery}
						userName="aboutUs"
						adminName="venues"
					/>
					<Route path="shows">
						<IndexRoute component={Shows} userName="shows" adminName="shows" />
						<Route
							path="new"
							component={CreateShow}
							userName="shows"
							adminName="shows"
						/>
						<Route
							path=":showId"
							component={EditShow}
							userName="shows"
							adminName="shows"
						/>
					</Route>
					<Route
						path="activities"
						component={Activities}
						userName="myEvents"
						adminName="myEvents"
					/>
					<Route
						path="scraped/shows"
						component={ScrapedShows}
						userName="newShows"
						adminName="newShows"
					/>
					<Route path="scraped/shows/:showId" component={AddShow} />
					<Route
						path="scraped/artists"
						component={ScrapedArtists}
						userName="newArtists"
						adminName="newArtists"
					/>
					<Route path="scraped/artists/:artistId" component={AddArtist} />
					<Route path="scraped/galleries" component={ScrapedGalleries} />
					<Route path="scraped/galleries/:galleryId" component={AddGallery} />
					<Route path="artworks">
						<IndexRoute
							component={AwSplitForRouter}
							userName="artworks"
							adminName="artworks"
						/>
						<Route
							path="new"
							component={CreateArtwork}
							userName="artworks"
							adminName="artworks"
						/>
						<Route
							path=":artworkId"
							component={EditArtwork}
							userName="artworks"
							adminName="artworks"
						/>
					</Route>
					<Route path="artworks-all">
						<IndexRoute component={Artworks} />
					</Route>
					<Route path="events">
						<IndexRoute
							component={Events}
							userName="events"
							adminName="events"
						/>
						<Route
							path="new"
							component={CreateEvent}
							userName="events"
							adminName="events"
						/>
						<Route
							path=":eventId"
							component={EditEvent}
							userName="events"
							adminName="events"
						/>
					</Route>
					<Route path="posts">
						<IndexRoute component={Posts} userName="posts" adminName="posts" />
						<Route
							path="new"
							component={CreatePost}
							userName="posts"
							adminName="posts"
						/>
						<Route
							path=":postId"
							component={EditPost}
							userName="posts"
							adminName="posts"
						/>
					</Route>
					<Route path="imagegallery">
						<IndexRoute
							component={Posts}
							userName="imageGalleries"
							adminName="imageGalleries"
						/>
						<Route
							path="new"
							component={CreatePost}
							userName="imageGalleries"
							adminName="imageGalleries"
						/>
						<Route
							path=":postId"
							component={EditPost}
							userName="imageGalleries"
							adminName="imageGalleries"
						/>
					</Route>
					<Route
						path="settings"
						component={UserAccount}
						userName="userSettings"
						adminName="userSettings"
					/>
					<Route
						path="gallery-settings"
						component={GallerySettings}
						userName="settings"
						adminName="settings"
					/>
					<Route
						path="artist-settings"
						component={ArtistSettings}
						userName="settings"
						adminName="settings"
					/>
					<Route path="accounts">
						<IndexRoute
							component={Accounts}
							userName="accounts"
							adminName="accounts"
						/>
						<Route
							path=":accountId"
							component={AdminAccount}
							userName="accounts"
							adminName="accounts"
						/>
					</Route>
					<Route
						path="categories"
						component={Categories}
						userName="categories"
						adminName="categories"
					/>
					<Route
						path="members"
						component={Members}
						userName="members"
						adminName="members"
					/>
					{/* Newsletter was renamed contacts. Keep old path too for now in case someone saved the link. */}
					<Route path="newsletter" component={Newsletter} />
					<Route
						path="contacts"
						component={Newsletter}
						userName="contacts"
						adminName="contacts"
					/>
					<Route path="orders">
						<IndexRoute
							component={OrdersSelect}
							userName="orders"
							adminName="orders"
						/>
						<Route
							path="person"
							component={Orders}
							userName="orders"
							adminName="orders"
						/>
						<Route
							path="organization"
							component={InvoiceOrders}
							userName="orders"
							adminName="orders"
						/>
					</Route>
					<Route path="artists">
						<IndexRoute
							component={Artists}
							userName="artists"
							adminName="artists"
						/>
						<Route
							path="new"
							component={CreateArtist}
							userName="artists"
							adminName="artists"
						/>
						<Route
							path="me"
							component={SingleArtist}
							userName="artists"
							adminName="artists"
						/>
						<Route
							path=":artistId"
							component={EditArtist}
							userName="artists"
							adminName="artists"
						/>
					</Route>
					<Route
						path="rated"
						component={Rated}
						userName="rated"
						adminName="rated"
					/>
					<Route path="selections">
						<IndexRoute
							component={Selections}
							userName="selections"
							adminName="selections"
						/>
						<Route
							path=":selectionId"
							component={Selection}
							userName="selections"
							adminName="selections"
						/>
					</Route>
					<Route path="raffles">
						<IndexRoute
							component={Raffles}
							userName="raffle"
							adminName="raffle"
						/>
						<Route
							path=":raffleId"
							component={Raffle}
							userName="raffle"
							adminName="raffle"
						/>
					</Route>
					<Route
						path="filearchive"
						component={FileArchive}
						userName="fileArchive"
						adminName="fileArchive"
					/>
					<Route
						path="emails"
						component={Emails}
						userName="emails"
						adminName="emails"
					/>
					<Route
						path="messages"
						component={ChatPage}
						userName="messages"
						adminName="messages"
					/>
					<Route
						path="xmascalendar"
						component={XmasCalendar}
						userName="xmascalendar"
						adminName="xmascalendar"
					/>
					<Route
						path="invoices"
						component={Billecta}
						userName="invoices"
						adminName="invoices"
					/>
					<Route
						path="subscriptions"
						component={SubscriptionsMain}
						userName="subscriptions"
						adminName="subscriptions"
					/>
					<Route path="klarna-subscriptions" component={KlarnaSubscriptions} />
					<Route path="stripe-subscriptions">
						<IndexRoute component={StripeSubscriptions} />
						<Route path=":subId" component={StripeSubscription} />
					</Route>
					<Route
						path="cancelled-subscriptions"
						component={CancelledSubscriptions}
					/>
					<Route path="contactlists">
						<IndexRoute
							component={ContactLists}
							userName="artistcontactlists"
							adminName="contactlists"
						/>
						<Route
							path=":contactListSlug"
							component={ContactList}
							userName="artistcontactlists"
							adminName="contactlists"
						/>
					</Route>
					<Route path="announcements">
						<IndexRoute
							component={Announcements}
							userName="announcements"
							adminName="announcements"
						/>
						<Route
							path=":announcementSlug"
							component={Announcement}
							userName="announcements"
							adminName="announcements"
						/>
					</Route>
					<Route path="artwork/invoice" component={ArtworkInvoice} />
					<Route
						path="artist-invoices"
						component={PreviousInvoices}
						userName="transactions"
						adminName="transactions"
					/>
					<Route path="discounts">
						<IndexRoute
							component={DiscountCodes}
							userName="dicounts"
							adminName="discounts"
						/>
						<Route
							path=":id"
							component={DiscountCode}
							userName="dicounts"
							adminName="discounts"
						/>
					</Route>
					<Route path="data" component={DataPage} />
					<Route path="help-center/artists" component={ArtistsHelpCenter} />
					<Route path="prints">
						<IndexRoute
							component={Prints}
							userName="prints"
							adminName="prints"
						/>
						<Route
							path="new"
							component={CreatePrint}
							userName="print"
							adminName="print"
						/>
						<Route
							path=":printId"
							component={EditPrint}
							userName="print"
							adminName="print"
						/>
					</Route>
					<Route path="influencers">
						<IndexRoute
							component={Influencers}
							userName="influencers"
							adminName="influencers"
						/>
						<Route
							path=":influencerId"
							component={EditInfluencer}
							userName="influencer"
							adminName="influencer"
						/>
					</Route>
				</Route>
			</Router>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user, authenticated },
	} = state;
	const isSociety = user.role === "society";
	const isAdmin = user.role === "admin";
	const isArtist = user.role === "artist";

	return { isSociety, isAdmin, isArtist, authenticated };
};

export default connect(mapStateToProps)(Routes);
