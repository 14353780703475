import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormInitialValues,
	getFormValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import { fetchArtist } from "../../actions/ArtistActions";
import {
	submitArtist,
	loadArtist,
	showSuccess,
	hideSuccess,
	hideError,
} from "../../actions/FormActions/ArtistFormActions";
import ArtistForm from "./ArtistForm";
import ArtistFormResp from "./ArtistFormResp";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import { withRouter } from "react-router";
import Modal from "../../components/Modal/Modal";
import { showCustomModal } from "../../actions/AppActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class SingleArtist extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { user } = this.props;
		const artistId = user.artist ? user.artist : false;
		if (artistId) this.props.dispatch(fetchArtist(artistId));
	}

	componentWillReceiveProps(newProps) {
		if (
			this.props.user.artist !== newProps.user.artist &&
			newProps.user.artist
		) {
			this.props.dispatch(fetchArtist(newProps.user.artist));
		}
		if (this.props.artist.artist !== newProps.artist.artist) {
			this.props.dispatch(loadArtist(newProps.artist.artist));
		}
		// TODO: Handle deleted artist
		if (false) {
			this.props.router.push("/artists");
		}
		//Display first login welcome
		const thisArtistId = this.props.artist.artist
			? this.props.artist.artist._id
			: false;
		const newArtistId = newProps.artist.artist
			? newProps.artist.artist._id
			: false;
		const welcomedArtist = localStorage.getItem("welcomedArtist");
		if (
			thisArtistId !== newArtistId &&
			newArtistId &&
			newProps.artist.artist.name &&
			newProps.user.activeInPortal === null &&
			!welcomedArtist
		) {
			this.showLoginModal(newProps.artist.artist);
			const date = new Date();
			localStorage.setItem("welcomedArtist", date.toISOString());
		}
		//Display first pro login welcome
		// const proWelcomed = localStorage.getItem('proWelcomed')
		// if(thisArtistId !== newArtistId && newArtistId && isPremium(newProps.artist.artist) && !proWelcomed) {
		// 	this.showProWelcome()
		// 	const date = new Date()
		// 	localStorage.setItem('proWelcomed', date.toISOString())
		// }
	}

	handleSubmit = () => {
		const {
			form: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};

	showLoginModal = (artist) => {
		const name = artist.name ? artist.name.split(" ") : "";
		const content = (
			<div>
				<p>{`Hej ${name[0]},`}</p>
				<p>Välkommen till Artworks, kul att se dig här!</p>
				<p>Nu kör vi 🚀</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	showProWelcome = () => {
		const content = (
			<div className="responsive-modal-content">
				<p>
					Välkommen till Artworks <i>PRO</i>!
				</p>
				<p>
					Nu har du tillgång till samtliga funktioner och förbättrad exponering.
				</p>
				<p>Prova dig fram och hör av dig om du har några funderingar.</p>
				<p>✌</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	render() {
		const { user, artist, form } = this.props;
		const showVat =
			user.role && (user.role === "artist" || user.role === "admin");
		const artistVideo =
			form.artist && form.artist.videoLink ? form.artist.videoLink : null;

		return (
			<div>
				{appGlobals.useResponsive ? (
					<MainResp contentClassName="slim-content">
						<ArtistFormResp
							onSubmit={this.handleSubmit}
							isAdmin={false}
							showVat={showVat}
							editArtist={true}
							isPremium={isPremium(form.artist)}
							artistVideo={artistVideo}
						/>
					</MainResp>
				) : (
					<Main>
						<ArtistForm
							onSubmit={this.handleSubmit}
							isAdmin={false}
							showVat={showVat}
							editArtist={true}
							isPremium={isPremium(form.artist)}
							artistVideo={artistVideo}
						/>
					</Main>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		artist,
	} = state;
	const form = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return { user, artist, form };
}

export default connect(mapStateToProps)(withRouter(SingleArtist));
