import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	fetchPosts,
	fetchAllPostsFast,
	fetchAllPostsPopulated,
	reorderPost,
} from "../../actions/PostActions";
import Main from "../../components/Main/Main";
import ArtistProModals from "../../components/Modal/Custom/ArtistProModals";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import moment from "moment";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

class OrderInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			index: -1,
		};
	}

	componentWillMount() {
		// Always run after updating.
		this.setInitialState(this.props);
	}

	componentWillReceiveProps(nextProps) {
		// To update when changing sort order in table. Nextprops belongs to another item (another id).
		this.setInitialState(nextProps);
	}

	setInitialState = (props) => {
		// When receivning props, update only when changin sort order in table.
		// if (props.id === this.state.id) return

		this.setState({
			id: props.id,
			index: props.initialValue,
		});
	};

	updateOrder = () => {
		const { id, galleryId } = this.props;
		// Not strict equality, initialValue is a number, index from state is a string.
		if (this.state.index != this.props.initialValue) {
			// If setting index to 0, it will be updated to -1, = "removed" index.
			const newIndex = this.state.index > 0 ? this.state.index : -1;
			this.props.update(id, newIndex, galleryId);
		}
	};

	changeIndex = (e) => {
		let newIndex = e.target.value;
		this.setState({ index: newIndex });
	};

	render() {
		const { index } = this.state;
		return (
			<input
				type="number"
				value={index >= 0 ? index : ""}
				min={0}
				onChange={this.changeIndex}
				onBlur={this.updateOrder}
				className="order-post__input"
			/>
		);
	}
}

class Posts extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		const { isAdmin, posts, deletedPost } = this.props;
		if (isAdmin) {
			// Posts for admin here is allPosts from state. If already fetched, only update.
			if (Object.keys(posts).length === 0 || deletedPost) {
				this.props.dispatch(fetchAllPostsFast()).then((result) => {
					this.props.dispatch(fetchAllPostsPopulated());
				});
			} else {
				this.props.dispatch(fetchAllPostsPopulated());
			}
		} else {
			this.props.dispatch(fetchPosts());
		}
	}

	hasProp = (object, key) => {
		if (object) {
			return object.hasOwnProperty(key);
		} else {
			return false;
		}
	};

	isImageGallery = () => {
		return this.props.location.pathname.indexOf("/imagegallery") === 0;
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	updateOrder = (postId, newIndex, galleryId) => {
		if (!galleryId) return; // Just in case. Or all posts will be returned (but also checked in API).
		this.props.dispatch(reorderPost(postId, newIndex, galleryId));
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	render() {
		const { user, isAdmin, posts, t } = this.props;
		const forImageGalleries = this.isImageGallery();

		const rows = [];
		const that = this;
		Object.keys(posts)
			.sort((a, b) => {
				let aObject = posts[a];
				let bObject = posts[b];
				return new Date(bObject.created_at) - new Date(aObject.created_at);
			})
			.forEach((key) => {
				const post = posts[key];

				const image = post.featuredImage
					? post.featuredImage
					: post.images && post.images[0]
					? post.images[0]
					: "";
				const thumbnailSrc = image ? this.getImageSrc(image) : "";

				const createdBy = that.hasProp(post.created_by, "name")
					? post.created_by.role === "admin"
						? "Artworks"
						: post.created_by.name
					: "N/A";

				const addPost =
					(forImageGalleries && post.isImageGallery) ||
					(!forImageGalleries && !post.isImageGallery);

				if (addPost) {
					const published =
						post.publish && post.publish.status === "published" ? true : false;
					rows.push(
						<Tr key={post._id}>
							{!isAdmin && (
								<Td
									column="order"
									value={post.index > 0 ? post.index : 10000000000}
								>
									<div className="order-post">
										<OrderInput
											initialValue={post.index || -1}
											id={post._id}
											galleryId={post.belongsTo}
											update={this.updateOrder}
										/>
									</div>
								</Td>
							)}
							<Td column="image" style={{ padding: 0, width: 70 }}>
								{image ? (
									<Link to={`/posts/${post.slug}`}>
										<img
											src={thumbnailSrc}
											className="table-image"
											onError={(e) =>
												this.thumbnailError(e, e.target.src, image.src)
											}
											alt="Article image"
										/>
									</Link>
								) : null}
							</Td>
							<Td column="title" value={post.title}>
								<Link to={`/posts/${post.slug}`}>{post.title}</Link>
							</Td>
							<Td column="createdBy" value={createdBy}>
								<Link to={`/posts/${post.slug}`}>{createdBy}</Link>
							</Td>
							<Td column="createdAt" value={post.created_at}>
								<Link to={`/posts/${post.slug}`}>
									{DateUtil.dateMonthShort(
										post.created_at,
										i18next.language,
										true
									)}
								</Link>
							</Td>
							<Td
								column="publishDate"
								value={
									post.publish.availiable_from
										? post.publish.availiable_from
										: "N/A"
								}
							>
								<Link to={`/posts/${post.slug}`}>
									{post.publish.availiable_from
										? DateUtil.dateMonthShort(
												post.publish.availiable_from,
												i18next.language,
												true
										  )
										: "N/A"}
								</Link>
							</Td>
							<Td
								column="status"
								value={post.publish.status ? post.publish.status : "N/A"}
							>
								<Link
									to={`/posts/${post.slug}`}
									className={
										"reactable__state" + (published ? " is-published" : "")
									}
								>
									{post.publish.status
										? t(`common:status.${post.publish.status}`)
										: "N/A"}
								</Link>
							</Td>
						</Tr>
					);
				}
			});

		return (
			<div>
				<Main>
					{forImageGalleries ? (
						<div style={{ marginBottom: "30px" }}>
							<Link
								to="/imagegallery/new"
								className="purple-button--wide purple-button--wide--mod_create"
							>
								{t("list.createNewImageGallery")}
							</Link>
						</div>
					) : (
						<div style={{ marginBottom: "30px" }}>
							<Link
								to="/posts/new"
								className="purple-button--wide purple-button--wide--mod_create"
							>
								{t("list.createNewPost")}
							</Link>
						</div>
					)}
					<Table
						className="reactable"
						sortable={[
							"title",
							"publishDate",
							"status",
							"createdBy",
							"createdAt",
							"order",
						]}
						defaultSort={{ column: "order", direction: "asc" }}
						filterable={isAdmin ? ["title", "createdBy"] : false}
						filterPlaceholder={"Search event"}
						itemsPerPage={rows.lenght > 100 ? 100 : false}
						pageButtonLimit={10}
					>
						<Thead>
							{!isAdmin && <Th column="order">{t("list.order")}</Th>}
							<Th column="image"> </Th>
							<Th column="title">{t("list.title")}</Th>
							{isAdmin && (
								<Th column="createdBy" style={{ width: 150 }}>
									{t("common:createdBy")}
								</Th>
							)}
							<Th column="createdAt" style={{ width: 150 }}>
								{t("common:createdAt")}
							</Th>
							<Th column="publishDate" style={{ width: 100 }}>
								{t("list.publishedOn")}
							</Th>
							<Th column="status" style={{ width: 100 }}>
								Status
							</Th>
						</Thead>
						{rows}
					</Table>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		post: { posts: selectedPosts, allPosts, deletedPost },
		auth: { user },
	} = state;
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const posts = isAdmin ? allPosts : selectedPosts;

	return { posts, user, isAdmin, deletedPost };
}

const translated = withNamespaces("post")(Posts);
export default connect(mapStateToProps)(translated);
