import React from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import moment from "moment";
import Main from "../../components/Main/Main";
import ArtistProModals from "../../components/Modal/Custom/ArtistProModals";
import {
	fetchArchiveForUser,
	uploadFile,
	renameFile,
	deleteFile,
} from "../../actions/ArchiveActions";
import { fetchAllGalleriesFast } from "../../actions/GalleryActions";
import { premiumUpgradeModalContent } from "../../utils/Dispatcher";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

// If larger than 5GB, we may have to rewrite upload/rename function.
// Limit also set in server.js in API. bodyParser.urlencoded and bodyParser.json,
// and there is a limit on the nginx server, might have to stream larger uploads than this (10mb is too big for this).
// const MAX_SIXE = 41943040, MAX_SIZE_STRING = '40MB' // 40MB in bytes
// const MAX_SIXE = 15728640, MAX_SIZE_STRING = '15MB' // 15MB in bytes // Too large for API, won't work.
const MAX_SIXE = 5242880,
	MAX_SIZE_STRING = "5MB"; // 5MB in bytes

const DOC_ICON = require("../../../assets/images/icons/fileArchive/doc.svg");
const DOCX_ICON = require("../../../assets/images/icons/fileArchive/docx.svg");
const GIF_ICON = require("../../../assets/images/icons/fileArchive/gif.svg");
const JPG_ICON = require("../../../assets/images/icons/fileArchive/jpg.svg");
const MOV_ICON = require("../../../assets/images/icons/fileArchive/mov.svg");
const MP4_ICON = require("../../../assets/images/icons/fileArchive/mp4.svg");
const PDF_ICON = require("../../../assets/images/icons/fileArchive/pdf.svg");
const PNG_ICON = require("../../../assets/images/icons/fileArchive/png.svg");
const PPT_ICON = require("../../../assets/images/icons/fileArchive/ppt.svg");
const XLSX_ICON = require("../../../assets/images/icons/fileArchive/xlsx.svg");

const DEFAULT_SVG = require("../../../assets/images/svg/fontAwesome/file-alt.svg");
const IMAGE_SVG = require("../../../assets/images/svg/fontAwesome/file-image.svg");
const AUDIO_SVG = require("../../../assets/images/svg/fontAwesome/file-audio.svg");
const BIN_SVG = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");
// import CLONE_COMP from '../../../assets/images/svg/fontAwesome/CloneComponent.js'

// Match FOLDERS in S3WrapperFileArchive.js in API.
// Use as default icons before checking file extension.
const ICONS = {
	pdf: PDF_ICON,
	documents: DEFAULT_SVG,
	images: IMAGE_SVG,
	video: MOV_ICON,
	audio: AUDIO_SVG,
};

// This component to not update name for file. Only updates in aws on blur.
class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
	}

	componentDidMount() {
		this.setValue(this.props.initialValue);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.initialValue !== nextProps.initialValue) {
			this.setValue(nextProps.initialValue);
		}
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	_update = (evt) => {
		if (this.props.initialValue !== this.state.value) {
			const newKey = this.props.awsKey.replace(
				this.props.initialValue,
				this.state.value
			);
			this.props.update(this.props.bucket, this.props.awsKey, newKey);
		}
	};

	render() {
		const { value } = this.state;
		return (
			<input
				type="text"
				value={value}
				onChange={(evt) => this.setValue(evt.target.value)}
				onBlur={(evt) => this._update(evt)}
				className="input-field"
			/>
		);
	}
}

class FileArchive extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			selectedGallery: null,
		};
	}

	componentWillMount() {
		const { isAdmin, user } = this.props;
		if (isAdmin) {
			this.props.dispatch(fetchAllGalleriesFast());
		} else if (user.gallery) {
			this.props.dispatch(
				fetchArchiveForUser(user.gallery._id || user.gallery)
			);
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, allGalleries, user, userGallery } = nextProps;

		if (
			isAdmin &&
			this.state.galleryOptions.length !== Object.keys(allGalleries).length > 0
		) {
			const galleryOptions = Object.keys(allGalleries).map((key) => {
				const venue = allGalleries[key];
				return {
					value: venue._id,
					label: venue.name,
					premium: __USE_TIERS__ ? isStandardOrAbove(venue) : isPremium(venue),
				};
			});

			this.setState({
				galleryOptions: galleryOptions.sort(this.sortOptions),
			});
		}
	}

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	selectGallery = (gallery) => {
		const { allGalleries } = this.props;
		this.setState(
			{
				selectedGallery: gallery,
			},
			() => {
				if (gallery) {
					this.props.dispatch(fetchArchiveForUser(gallery.value));
				}
			}
		);
	};

	fallbackCopyLink = (URL) => {
		const textArea = document.createElement("textarea");
		textArea.style.height = 0;
		textArea.value = URL;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		document.execCommand("copy");

		document.body.removeChild(textArea);
	};

	copyLink = (rawURL) => {
		const URL = encodeURI(rawURL);
		if (navigator && navigator.clipboard) {
			navigator.clipboard.writeText(URL).then(
				() => {
					// console.log('COPY SUCCESS');
				},
				() => {
					// console.log('COPY FAIL');
				}
			);
		} else if (document) {
			// console.log('USE FALLBACK');
			this.fallbackCopyLink(URL);
		}
	};

	onDrop = (acceptedFiles, e) => {
		const { t } = this.props;
		const { isAdmin, userGallery } = this.props;
		const { selectedGallery } = this.state;

		const uploadedFile = acceptedFiles[0];

		const reader = new FileReader();
		const userId =
			isAdmin && selectedGallery ? selectedGallery.value : userGallery._id;
		let file = {};

		reader.onload = (upload) => {
			file.URI = upload.target.result;

			this.props.dispatch(uploadFile(userId, file));
		};

		// Read data file
		if (uploadedFile) {
			file.fileName = uploadedFile.name;
			file.contentType = uploadedFile.type;

			reader.readAsDataURL(uploadedFile);
		}
	};

	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;
		const rejectedFile = rejectedFiles[0];

		if (rejectedFile && rejectedFile.size > MAX_SIXE) {
			window.alert(t("fileTooBig", { maxSize: MAX_SIZE_STRING }));
		}
	};

	deleteFile = (bucket, key) => {
		const { isAdmin, user, t } = this.props;
		const { selectedGallery } = this.state;

		let confirm = window.confirm(t("areYouSure"));
		if (confirm) {
			this.props.dispatch(deleteFile(bucket, key)).then((success) => {
				if (success) {
					const galleryId = isAdmin
						? selectedGallery.value
						: user.gallery._id || user.gallery;
					this.props.dispatch(fetchArchiveForUser(galleryId));
				}
			});
		}
	};

	rename = (bucket, awsKey, newKey) => {
		const { isAdmin, user } = this.props;
		const { selectedGallery } = this.state;

		const galleryId = isAdmin
			? selectedGallery.value
			: user.gallery._id || user.gallery;
		const body = { bucket, key: awsKey, newKey };

		this.props.dispatch(renameFile(galleryId, body));
	};

	createFiles = (archive) => {
		const nameRegex = RegExp(/(\/.{24})-/, "g");

		const files = [];
		archive.forEach((file, index) => {
			const { key, date, bucket, folder, URL } = file;
			/* file ex: {
        key: uploads/pdf/5d381f9000378e6fdaed35a1/5e6b961e9468cf41ac766a8e-Betalflöde skiss Artworks (2).pdf,
        date: 2020-03-13T14:18:08.000Z,
        bucket: 'artworks-dev-images',
        URL: url...,
      } */

			// Find the last index of /[24 figures]- , what comes after is the filename.
			let arr;
			let lastIndex = 0;
			while ((arr = nameRegex.exec(key)) !== null) {
				// console.log(`Found ${arr[0]}. Next starts at ${nameRegex.lastIndex}.`)
				lastIndex = nameRegex.lastIndex;
			}
			const fileName = key.substring(lastIndex);

			// File extensions listed in S3WrapperFileArchive.js in API.
			let icon = ICONS[folder];
			if (/\.(doc|dot)$/.test(fileName)) icon = DOC_ICON;
			if (/\.do(c|t)(x|m)$/.test(fileName)) icon = DOCX_ICON;
			if (/\.gif$/.test(fileName)) icon = GIF_ICON;
			if (/\.(jpg|jpeg)$/.test(fileName)) icon = JPG_ICON;
			if (/\.mp4$/.test(fileName)) icon = MP4_ICON;
			if (/\.png$/.test(fileName)) icon = PNG_ICON;
			if (/\.p(p|o)(t|s|a)(x|m)?$/.test(fileName)) icon = PPT_ICON;
			if (/\.xl(s|t|a)(x|m|b)$/.test(fileName)) icon = XLSX_ICON;
			// No need to check for .pdf, that icon is default for that folder.

			files.push({
				key,
				date,
				bucket,
				URL,
				name: fileName,
				ICON: icon, // ICONS[folder] || ICONS['documents'],
			});
		});

		return files;
	};

	createRows = (files) => {
		const { isFetching, t } = this.props;
		const rows = [];

		files.forEach((file, index) => {
			const iconStyle = Object.assign(
				{},
				{
					height: "25px",
					width: "auto",
					cursor: "pointer",
				},
				isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
			);

			const textStyle = Object.assign(
				{},
				{
					cursor: "pointer",
				},
				isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
			);

			// className not working on file.ICON, use style prop.
			rows.push(
				<Tr key={index}>
					<Td column="image">
						{!isFetching ? (
							<a href={file.URL} target="_blank">
								<file.ICON
									style={iconStyle}
									data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
								/>
							</a>
						) : (
							<file.ICON
								style={iconStyle}
								data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
							/>
						)}
					</Td>
					<Td column="name" value={file.name}>
						<InputField
							initialValue={file.name}
							bucket={file.bucket}
							awsKey={file.key}
							update={this.rename}
						/>
					</Td>
					<Td column="date" value={file.date}>
						{moment(file.date).format("Do MMM YYYY")}
					</Td>
					<Td column="copyLink" value={"copyLink"}>
						<div className="svg-styler">
							{/*<CLONE_COMP title={t('copyLink')} style={ iconStyle } clicked={() => this.copyLink(file.URL)} />*/}
							<div style={textStyle} onClick={() => this.copyLink(file.URL)}>
								{t("copyLink")}
							</div>
						</div>
					</Td>
					<Td column="delete" value={"delete"}>
						<BIN_SVG
							style={iconStyle}
							onClick={() => this.deleteFile(file.bucket, file.key)}
							data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
						/>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const {
			isAdmin,
			premium,
			standardOrAbove,
			archive,
			userGallery,
			isFetching,
			error,
			t,
		} = this.props;
		const { galleryOptions, selectedGallery } = this.state;
		const files = this.createFiles(archive);
		const rows = this.createRows(files);

		// Don't show Premium placeholders before real content if user gallery is Premium.
		const waitForLoading = !isAdmin && !userGallery._id;
		if (waitForLoading) return null;

		// const showPlaceholderContent = !isAdmin && !premium
		let showPlaceholderContent = true;
		// standardOrAbove and premium only for user gallery,
		// for gallery selected in dropdown by admin, premium is set on selectedGallery.
		if (isAdmin) {
			showPlaceholderContent = false;
		} else if (__USE_TIERS__ && standardOrAbove) {
			showPlaceholderContent = false;
		} else if (!__USE_TIERS__ && premium) {
			showPlaceholderContent = false;
		}
		// console.log('showPlaceholderContent', showPlaceholderContent);

		const premiumModal = showPlaceholderContent ? (
			<ArtistProModals type="artSociety" availableFor="Premium" />
		) : (
			""
		);

		if (showPlaceholderContent) {
			return (
				<div className="file-archive">
					<Main preChildren={premiumModal}>
						<Dropzone
							onDrop={() => {}}
							onDropRejected={() => {}}
							className={"ImageUpload__dropzone"}
							multiple={false}
							maxSize={MAX_SIXE}
							disabled={true}
							disableClick={true}
						>
							<div>{t("dropFilesHere")}</div>
						</Dropzone>
					</Main>
				</div>
			);
		}

		return (
			<div className="file-archive">
				<Main>
					{isAdmin && (
						<Select
							value={selectedGallery}
							options={galleryOptions}
							onChange={this.selectGallery}
							onInputChange={this.onInputChange}
							valueKey={"gallery"}
							labelKey="label"
							backspaceToRemoveMessage=""
							backspaceRemoves={false}
							isLoading={isFetching}
							placeholder={"Gallery"}
							multi={false}
							autosize={false}
							className={"gallery-picker-select"}
							isSearchable={true}
						/>
					)}
					{/* accept={ ACCEPT } on Dropzone if you want to restrict accepted formats. */}
					{((selectedGallery && selectedGallery.premium) || !isAdmin) && (
						<div>
							<Dropzone
								onDrop={this.onDrop}
								onDropRejected={this.dropRejected}
								className={"ImageUpload__dropzone"}
								multiple={false}
								maxSize={MAX_SIXE}
							>
								<div>{t("dropFilesHere")}</div>
								{error ? <span>{error}</span> : null}
							</Dropzone>
							<div className="file-archive__files">
								{false && files.map((file) => file.name)}
								<Table
									className="reactable"
									sortable={["name", "date"]}
									defaultSort={{ column: "date", direction: "desc" }}
									filterable={["name"]}
									filterPlaceholder={t("searchFiles")}
								>
									<Thead>
										<Th column="image"> </Th>
										<Th column="name">{t("name")}</Th>
										<Th column="date">{t("date")}</Th>
										<Th column="copyLink"> </Th>
										<Th column="delete"> </Th>
									</Thead>
									{rows}
								</Table>
							</div>
						</div>
					)}
					{isAdmin && selectedGallery && !selectedGallery.premium && (
						<div>
							<p>File archive only for Standard and Premium.</p>
						</div>
					)}
				</Main>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		archive: { archive, isFetching, error },
		gallery: { gallery: userGallery, allGalleries },
	} = state;
	const isAdmin = user.role === "admin";
	const premium = isPremium(userGallery);
	const standardOrAbove = isStandardOrAbove(userGallery);

	return {
		user,
		isAdmin,
		premium,
		standardOrAbove,
		archive,
		isFetching,
		error,
		userGallery,
		allGalleries,
	};
};

const translated = withNamespaces("archive")(FileArchive);
export default connect(mapStateToProps)(translated);
