import React from "react";

const PUBLISH_STATUS = [
	{ value: "published", name: "Publicerad" },
	{ value: "draft", name: "Utkast" },
];

const PublishStatus = (props) => {
	const disabled = false;
	const children = props.children || null;

	const defaultOption = [
		<option key="defaultOption" value="" disabled>
			Publiceringsstatus
		</option>,
	];
	const statusOptions = PUBLISH_STATUS.map((option) => (
		<option key={option.value} value={option.value}>
			{option.name}
		</option>
	));
	const options = defaultOption.concat(statusOptions);

	return (
		<div className="SimpleSelect Input--text">
			<select
				{...props.input}
				type="checkbox"
				disabled={disabled}
				style={{ opacity: disabled ? 0.3 : 1 }}
			>
				{options}
			</select>
			{children}
		</div>
	);
};

export default PublishStatus;
