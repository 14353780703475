import React from "react";
import i18next from "i18next";

const CountBadge = ({ count, type }) => {
	const isSwedish = i18next.language === "sv";
	return (
		<div
			className={
				"CountBadge" +
				(count > 9 ? " is-double_figure" : "") +
				(count > 99 ? " is-triple_figure" : "") +
				(isSwedish ? " is-swedish" : "") +
				(type ? ` ${type}` : "")
			}
		>
			<span>{count}</span>
		</div>
	);
};

export default CountBadge;
