import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import { saveAs } from "file-saver";
import {
	saveOnUpload,
	exportData,
	fetchMembers,
	updateMember,
	memberRemoveNew,
	deleteMember,
	deleteAllMembers,
	importMembersFromOldRegistry,
	updateMembersMailingLists,
	sendEmailToMembersReset,
	uploadMembersHelp,
} from "../../actions/RegistryActions";
import {
	fetchGallery,
	createMailingList,
	deleteMailingList,
	updateGalleryValue,
} from "../../actions/GalleryActions";
import { uploadFile } from "../../actions/ArchiveActions";
import { showErrorModal, showCustomModal } from "../../actions/AppActions";
import { showPremiumUpgradeModal } from "../../utils/Dispatcher";
import { fetchGalleryNotifications } from "@artworkslab/sharedmodules/src/actions/NotificationActions";
import {
	isPremiumOrAbove,
	hasAccessToFeature,
	MEMBER_IMP_EXP_FEATURE,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import RegistryOptions from "./RegistryOptions";

const PLUS_WHITE = require("../../../assets/images/svg/plus_32px_white.svg");
const BIN = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");
const PENCIL = require("../../../assets/images/svg/fontAwesome/fa_pencil.svg");
const DOC_EXAMPLE = require("../../../assets/images/excel-doc-example.png");
const CHEVRON = require("../../../assets/images/icons/chevron-down@2x.png");
const CLOSE = require("../../../assets/images/icons/close@2x.png");

const STICKY_APPROVED = "sticky-approved";
const STICKY_APPLICANTS = "sticky-applicants";
const IN_STICKY_COLUMN = "in-sticky-column";
const STICKY_FILTER = "sticky-filter";

// This component to not update state for all members when inputting values.
// Only updates in database on blur.
class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
	}

	componentDidMount() {
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setValue(nextProps.value);
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	_update = (evt) => {
		const { memberId, keyForValue, update, isNew, custom } = this.props;
		update(memberId, keyForValue, evt.target.value, isNew, custom);
		this.resetInputField(evt);
	};

	changeInputField = (evt) => {
		const newWidth = evt.target.value ? evt.target.value.length + 2 : 0;
		evt.target.style.width = `${newWidth}ch`;
		evt.target.style.position = "absolute";
		evt.target.style.top = "9px";
		evt.target.style.bottom = "11px";
		evt.target.style.zIndex = "5";
	};

	resetInputField = (evt) => {
		evt.target.style.width = "100%";
		evt.target.style.position = "relative";
		evt.target.style.top = "0";
		evt.target.style.bottom = "0";
		evt.target.style.zIndex = "0";
	};

	render() {
		const { value } = this.state;
		const { approved } = this.props;
		return (
			<input
				type="text"
				value={value}
				onChange={(evt) => this.setValue(evt.target.value)}
				onFocus={(evt) => this.changeInputField(evt)}
				onBlur={(evt) => this._update(evt)}
				className="input-field"
				disabled={!approved}
			/>
		);
	}
}

class MemberTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			importExportOpen: false,
			adminSectionOpen: false,
			selectedMailingList: "",
			allMarked: false,
			columns: [
				// { value: 'selected',          label: 'S',                   use: true,  sort: 'numeric' },
				{ value: "firstName", label: "Förnamn", use: true, sort: "alpha" },
				{ value: "lastName", label: "Efternamn", use: true, sort: "alpha" },
				{ value: "fullName", label: "Hela namnet", use: false, sort: "alpha" },
				{
					value: "personalIdentityNumber",
					label: "Personnummer",
					use: false,
					sort: "numeric",
				},
				{ value: "birthDate", label: "Född", use: false, sort: "alpha" },
				{ value: "email", label: "Email", use: true, sort: "alpha" },
				{ value: "phone", label: "Telefon", use: false, sort: "numeric" },
				{ value: "mobile", label: "Mobil", use: true, sort: "numeric" },
				{ value: "address", label: "Adress", use: false, sort: "alpha" },
				{ value: "zip", label: "Postnummer", use: false, sort: "numeric" },
				{ value: "city", label: "Ort", use: false, sort: "alpha" },
				{
					value: "feePayedInfo",
					label: "Betalat avgift",
					use: true,
					sort: "alpha",
				},
				{ value: "info", label: "Övrig info", use: false, sort: "alpha" },
				{
					value: "joinedDate",
					label: "Medlem datum",
					use: true,
					sort: "alpha",
				},
				{
					value: "membershipNumber",
					label: "Medlemsnummer",
					use: false,
					sort: "numeric",
				},
				{
					value: "employeeNumber",
					label: "Anställningsnummer",
					use: false,
					sort: "numeric",
				},
			],
			defaultColumns: [],
			columnLabels: [],
			listsCount: {},
			mailingListName: "",
			mailListSectionOpen: true,
			modalOpen: false,
			modalType: "",
			modalMessage: "",
			filterText: "",
			optionsOpen: false,
		};
		this.slideLeftID = -1;
		this.slideRightID = -1;
		this.sortDirection = 1;
	}

	componentWillMount() {
		if (this.props.organisation) {
			this.props.dispatch(fetchMembers(this.props.organisation));
			this.props.dispatch(fetchGallery(this.props.organisation));
		}
		this.setState({
			defaultColumns: this.state.columns,
		});
	}

	componentWillReceiveProps(nextProps) {
		const { members, mailingLists, gallery } = nextProps;

		if (
			nextProps.isAdmin &&
			this.props.organisation !== nextProps.organisation
		) {
			this.props.dispatch(fetchMembers(nextProps.organisation));
			this.props.dispatch(fetchGallery(nextProps.organisation));
			this.setState({
				selectedMailingList: "",
				allMarked: false,
			});
		}
		// this.isOverflown(this.applicants || this.members)

		if (
			this.props.mailingLists.length !== mailingLists.length ||
			Object.keys(this.props.members).length !== Object.keys(members).length ||
			this.props.organisation !== nextProps.organisation
		) {
			const listsCount = {};
			mailingLists.forEach((list) => {
				listsCount[list._id] = 0;
			});
			Object.keys(members).forEach((memberKey) => {
				const member = members[memberKey];
				if (member.mailingLists) {
					member.mailingLists.forEach((onList) => {
						if (listsCount[onList] || listsCount[onList] === 0)
							listsCount[onList] = listsCount[onList] + 1;
					});
				}
			});
			this.setState({
				listsCount,
				selectedMailingList: "",
				allMarked: false,
			});
		}

		if (!this.props.updateColumns && nextProps.updateColumns) {
			// this.setActiveColumns(nextProps.gallery)
			// console.log('updateColumns changed', this.props.gallery, nextProps.gallery);
			this.updateCustomColumns(nextProps.gallery);
		}

		if (!this.props.gallery._id && nextProps.gallery._id) {
			this.setActiveColumns(nextProps.gallery);
		}

		if (!this.props.exported && nextProps.exported) {
			// console.log('nextProps.exported', nextProps.exported);
			const type =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
			const blob = new Blob([this.s2ab(nextProps.exported)], { type: type });
			saveAs(blob, "medlemsregister.xlsx");
		}
	}

	s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	setActiveColumns = (organisation) => {
		// console.log('setActiveColumns', organisation);
		// console.log('this.state.columns', this.state.columns);
		const organisationColumns =
			organisation.registry && organisation.registry.displayColumns
				? organisation.registry.displayColumns
				: false;

		if (organisationColumns) {
			const customColumns =
				organisation.registry && organisation.registry.customColumns
					? organisation.registry.customColumns
					: [];

			// const columns = this.state.columns.map(column => {
			//   // If adding columns to model in db, but venue saved columns before that, need to set value to false. Undefined = text gray but box checked.
			//   return Object.assign({}, column, {
			//     use: organisationColumns[column.value] || false,
			//   })
			// })
			let columns = [];
			this.state.columns.forEach((column) => {
				// Got mulitple of the same custom columns when admin clicked on buttons to change society.
				if (!column.custom) {
					// If adding columns to model in db, but venue saved columns before that, need to set value to false. Undefined = text gray but box checked.
					columns.push(
						Object.assign({}, column, {
							use: organisationColumns[column.value] || false,
						})
					);
				}
			});

			customColumns.forEach((customColumn) => {
				columns.push({
					value: customColumn._id,
					label: customColumn.name,
					use: customColumn.display,
					sort: "alpha",
					custom: true,
				});
			});
			// console.log('customColumns', customColumns);
			// console.log('columns', columns);

			this.setState({
				columns,
				columnLabels: columns.map((column) => column.label.toLowerCase()),
			});
		} else {
			this.setState({
				columns: this.state.defaultColumns,
				columnLabels: this.state.defaultColumns.map((column) =>
					column.label.toLowerCase()
				),
			});
		}
	};

	// Adding custom columns to this.state after gallery saved.
	updateCustomColumns = (gallery) => {
		const { columns, columnLabels } = this.state;

		if (gallery) {
			// Copy without custom columns, then add custom. When gallery saved, don't know if column added or removed or both.
			const columnsCopy = [];
			columns.forEach((column) => {
				if (!column.custom) columnsCopy.push(column);
			});
			gallery.registry.customColumns.forEach((column) => {
				columnsCopy.push({
					value: column._id,
					label: column.name,
					use: column.display,
					sort: "alpha",
					custom: true,
				});
			});

			this.setState({
				columns: columnsCopy,
				columnLabels: columnsCopy.map((column) => column.label.toLowerCase()),
			});
		}
	};

	// For removing custom columns from this.state
	removeCustomColumns = (removeId) => {
		const { columns, columnLabels } = this.state;
		let toRemove = null;
		let removeIndex = -1;

		columns.forEach((column, index) => {
			if (column.value === removeId) {
				toRemove = column;
				removeIndex = index;
			}
		});

		if (toRemove && toRemove.custom) {
			let columnsCopy = columns.slice();
			columnsCopy.splice(removeIndex, 1);

			this.setState({
				columns: columnsCopy,
				columnLabels: columnsCopy.map((column) => column.label.toLowerCase()),
			});
		}
	};

	toggleImportOpen = () => {
		this.setState({
			importExportOpen: !this.state.importExportOpen,
		});
	};

	toggleAdminSectionOpen = () => {
		this.setState({
			adminSectionOpen: !this.state.adminSectionOpen,
		});
	};

	toggleMailListSectionOpen = () => {
		this.setState({
			mailListSectionOpen: !this.state.mailListSectionOpen,
		});
	};

	// Change if a column is shown or not, and if you look for it during import.
	changeImportUse = (key, check) => {
		const { columns } = this.state;

		let column = columns.filter((column) => column.value === key)[0];
		column.use = check;

		let columnIndex = -1;
		columns.forEach((column, index) => {
			if (column.value === key) columnIndex = index;
		});

		let updatedColumns = this.state.columns.slice();
		updatedColumns[columnIndex] = column;

		this.setState(
			{
				columns: updatedColumns,
			},
			() => {}
		);
	};

	addMember = () => {
		const { organisation } = this.props;
		this.props.dispatch(
			updateMember(false, { organisation, approved: true }, false)
		);
		this.setState({ allMarked: false });
	};

	approveMember = (memberId) => {
		this.updateMemberValue(memberId, "approved", true);
		this.setState({ allMarked: false });
	};

	removeMember = (memberId) => {
		let confirm = window.confirm(
			"Är du säker på att du vill ta bort den här medlemmen?"
		);
		if (confirm) this.props.dispatch(deleteMember(memberId));
	};

	removeAllMembers = () => {
		const { organisation } = this.props;
		let confirm = window.confirm(
			"Är du säker på att du vill ta bort alla medlemmar? Ansökningar kommer inte att tas bort."
		);
		if (confirm) {
			setTimeout(() => {
				let confirmAgain = window.confirm("ÄR DU HELT SÄKER?!!!");
				if (confirmAgain) this.props.dispatch(deleteAllMembers(organisation));
			}, 500);
		}
	};

	createMemberFromImport = (columnNames, memberValues) => {
		let member = {
			customColumns: [],
		};
		columnNames.forEach((column) => {
			if (!column.custom) {
				member[column.name] = memberValues[column.index];
			} else {
				member.customColumns.push({
					id: column.name,
					value: memberValues[column.index],
				});
			}
		});
		return member;
	};

	importFile = (evt) => {
		evt.preventDefault();
		const { columns } = this.state;
		const { members } = this.props;
		let columnNames = [];
		let newMembers = [];
		const file = this.fileUpload.files[0];
		readXlsxFile(file)
			.then((rows) => {
				rows.forEach((row, rowIndex) => {
					if (rowIndex === 0) {
						// First row with column names
						row.forEach((header, columnIndex) => {
							columns.forEach((column) => {
								if (header) {
									const importColumnHeader = header.toLowerCase();
									const ourColumnHeader = column.label.toLowerCase();
									if (importColumnHeader === ourColumnHeader) {
										columnNames.push({
											name: column.value,
											index: columnIndex,
											custom: column.custom,
										});
									}
								}
							});
						});
					} else {
						newMembers.push(this.createMemberFromImport(columnNames, row));
					}
				});
				this.props.dispatch(
					saveOnUpload(
						this.props.organisation,
						newMembers,
						Object.keys(members).length
					)
				);
			})
			.catch((error) => {
				console.log("upload error", error);
				const message = (
					<div style={{ paddingBottom: "20px" }}>
						<h1>Hoppsan!</h1>
						<p style={{ paddingTop: "20px" }}>
							<b>
								Medlemmarna kunde inte laddas upp. Prova något av följande och
								ladda sedan upp filen igen:
							</b>
						</p>
						<ul>
							<b>
								<li>
									Ändra rubriceringen så att den överensstämmer med exemplet.
								</li>
								<li>Ta bort sorteringar i filen.</li>
								<li>Ta bort låst rubrikrad i filen.</li>
							</b>
						</ul>
						<p style={{ paddingBottom: "20px" }}>
							<b>Om det fortfarande inte fungerar skriv till hey@artworks.io</b>
						</p>
					</div>
				);
				this.props.dispatch(showErrorModal(message));
			});
	};

	exportMembers = () => {
		const { members } = this.props;
		const { columns } = this.state;

		// Only export data for selected columns.
		const activeColumns = [];
		const headers = [];

		Object.keys(columns).forEach((key) => {
			const column = columns[key];
			if (column.use) {
				activeColumns.push(column);
				headers.push(column.label);
			}
		});

		const sheet = [];
		Object.keys(members).forEach((memberId) => {
			const member = members[memberId];
			let memberData = {};
			activeColumns.forEach((column) => {
				if (!column.custom) {
					if (typeof member[column.value] === "number") {
						memberData[column.label] = member[column.value].toString() || "";
					} else {
						memberData[column.label] = member[column.value] || "";
					}
				} else {
					let customValue = "";
					if (member.customColumns) {
						member.customColumns.forEach((customColumn) => {
							if (customColumn.id === column.value) {
								customValue = customColumn.value || "";
							}
						});
						memberData[column.label] = customValue;
					}
				}
			});
			sheet.push(memberData);
		});
		// console.log('headers', headers);
		// console.log('sheet', sheet);

		this.props.dispatch(exportData(headers, sheet));
	};

	importFromOldRegistry = () => {
		const { organisation } = this.props;
		this.props.dispatch(importMembersFromOldRegistry(organisation));
	};

	sendMemberListToUs = (evt) => {
		const { t } = this.props;
		evt.preventDefault();
		const { gallery } = this.props;
		// Can only upload one file at a time.
		const uploadedFile = this.easyFileUpload.files[0];
		const reader = new FileReader();
		// uploadedFile =
		// lastModified: 1598435186123
		// lastModifiedDate: Wed Aug 26 2020 11:46:26 GMT+0200 (centraleuropeisk sommartid) {}
		// name: "medlemsregister.xlsx"
		// size: 6754
		// type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		// webkitRelativePath: ""

		let file = {};
		reader.onload = (upload) => {
			file.URI = upload.target.result;
			// file =
			// URI: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,UEsDBAoAAAAIAMxdGlEo..."
			// contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			// fileName: "medlemsregister.xlsx"
			const userId = "5d381f9000378e6fdaed35a1"; // artworks-konstforening
			this.props
				.dispatch(uploadFile(userId, file))
				.then((result) => {
					const uploadedData = result ? result[0] : false;
					// uploadedData =
					// URL: "https://artworks-dev-file-archive.s3-eu-west-1.amazonaws.com/5d381f9000378e6fdaed35a1/documents/5fb9034b43b9bdb5a872c21c-medlemsregister.xlsx"
					// bucket: "artworks-dev-file-archive"
					// date: "2020-11-21T12:09:09.944Z"
					// folder: "documents"
					// key: "5d381f90...
					if (!uploadedData) throw new Error("Could not upload file!");
					const attachObj = {
						// URL needs to be "request"-ed in API and set as "data".
						URL: encodeURI(uploadedData.URL),
						fileName: uploadedFile.name,
						knownLength: uploadedFile.size,
						contentType: uploadedFile.type,
					};
					return this.props.dispatch(
						uploadMembersHelp(gallery._id || userId, attachObj)
					);
				})
				.then((success) => {
					let modalMessage = "";
					if (success) {
						modalMessage = (
							<p>
								<br />
								{t("emailLists.modalSuccess")}
							</p>
						);
					} else {
						modalMessage = (
							<p>
								<br />
								{t("emailLists.modalFailure")}
							</p>
						);
					}
					this.props.dispatch(showCustomModal(modalMessage));
				})
				.catch((err) => {
					this.props.dispatch(
						showCustomModal(
							<p>
								<br />
								{t("emailLists.modalFailure")}
							</p>
						)
					);
				});
		};

		// Read data file
		if (uploadedFile) {
			file.fileName = uploadedFile.name;
			file.contentType = uploadedFile.type;

			reader.readAsDataURL(uploadedFile);
		}
	};

	premiumOnly = (evt) => {
		const { t } = this.props;
		evt.preventDefault();

		showPremiumUpgradeModal(this.props.dispatch, "Premium");
	};

	updateMemberValue = (memberId, key, value, isNew, custom) => {
		const { gallery } = this.props;
		// console.log('updateMemberValue', memberId, key, value, isNew, custom);
		const params = !custom
			? { [key]: value }
			: { customColumn: { [key]: value } };
		this.props.dispatch(updateMember(memberId, params, isNew));
		//Update notifications upon approving member
		this.props.dispatch(fetchGalleryNotifications(gallery._id));
		// this.props.dispatch( updateMember(memberId, { [key]: value }, isNew, custom))
	};

	mailingListSelector = (memberCount) => {
		const { mailingLists, USE_MAIL_LISTS, members } = this.props;
		const { selectedMailingList, allMarked, listsCount } = this.state;

		return (
			<div
				className={
					"mailing-lists-selector" + (USE_MAIL_LISTS ? "" : " tmp-hide")
				}
			>
				<input
					type="checkbox"
					className="mailing-lists-selector__button"
					onClick={() =>
						this.toggleSubscriptionAll(!allMarked, selectedMailingList)
					}
					checked={allMarked}
					disabled={!selectedMailingList || memberCount === 0}
				/>
			</div>
		);
	};

	selectMailingList = (id) => {
		const { selectedMailingList } = this.state;
		const { members } = this.props;
		let allSelected = true;
		Object.keys(members).forEach((key) => {
			const member = members[key];
			if (member.mailingLists && member.mailingLists.indexOf(id) < 0)
				allSelected = false;
		});
		if (selectedMailingList === id) {
			this.setState({
				selectedMailingList: "",
				allMarked: allSelected,
			});
		} else {
			this.setState({
				selectedMailingList: id,
				allMarked: allSelected,
			});
		}
	};

	toggleSubscription = (memberId, checkbox, selectedMailingList) => {
		const { listsCount, allMarked } = this.state;
		const checked = checkbox.target.checked;
		this.updadeMailingListsForMembers([memberId], checked);

		const counts = Object.assign({}, listsCount);
		counts[selectedMailingList] =
			counts[selectedMailingList] + (checked ? 1 : -1);

		const allAreMarked = allMarked && checked;

		this.setState({
			listsCount: counts,
			allMarked: allAreMarked,
		});
	};

	toggleSubscriptionAll = (checked, selectedMailingList) => {
		const { members } = this.props;
		const { listsCount } = this.state;

		const memberIds = [];
		Object.keys(members).forEach((key) => {
			const member = members[key];
			if (member.approved) memberIds.push(member._id);
		});

		const counts = Object.assign({}, listsCount);
		counts[selectedMailingList] = checked ? memberIds.length : 0;

		this.updadeMailingListsForMembers(memberIds, checked);
		this.setState({
			allMarked: checked,
			listsCount: counts,
		});
	};

	updadeMailingListsForMembers = (memberIds, value) => {
		const { selectedMailingList } = this.state;
		this.props.dispatch(
			updateMembersMailingLists(selectedMailingList, memberIds, value)
		);
	};

	mailingListNameChange = (e) => {
		const value = e.target.value;
		this.setState({
			mailingListName: value,
		});
	};

	toggleModalOpen = (type = false, message = "") => {
		if (!type) {
			this.props.dispatch(sendEmailToMembersReset());
			this.setState({
				modalOpen: false,
				modalType: "",
				modalMessage: "",
			});
		} else {
			this.setState({
				modalOpen: true,
				modalType: type,
				modalMessage: message,
			});
		}
	};

	_createMailingList = () => {
		const { mailingListName } = this.state;
		const { gallery } = this.props;
		if (!mailingListName || !gallery) return;

		this.props
			.dispatch(createMailingList(gallery._id, mailingListName))
			.then((response) => {
				const gallery = response.response;
				const selectedList = gallery.registry.mailingLists.find(
					(list) => list.name === mailingListName
				);

				this.setState(
					{
						mailingListName: "",
						selectedMailingList: selectedList._id,
					},
					() => {
						this.toggleModalOpen();
					}
				);
			});
	};

	_deleteMailingList = (listId, listName) => {
		const { gallery, members } = this.props;
		let confirm = window.confirm(
			`Är du säker på att du vill ta bort listan ${listName}?`
		);
		if (confirm) {
			const memberIds = Object.keys(members).map((key) => members[key]._id);
			this.props.dispatch(deleteMailingList(gallery._id, listId));
			this.props.dispatch(updateMembersMailingLists(listId, memberIds, false));
		}
	};

	toggleChairman = (memberId, checkbox) => {
		const { organisation } = this.props;
		const checked = checkbox.target.checked;
		const chairmanId = checked ? memberId : null;

		this.props.dispatch(
			updateGalleryValue(organisation, { chairman: chairmanId })
		);
	};

	// When manually sorting / clicking table header.
	sortChanged = (e) => {
		this.sortDirection = e.direction;

		const { members } = this.props;
		Object.keys(members).forEach((key) => {
			const member = members[key];
			if (member.isNew) {
				// Remove isNew, or field will continue to be styled orange even after manually sorting.
				this.props.dispatch(memberRemoveNew(member));
			}
		});
	};

	// Number must be converted to String for localeCompare.
	// Tried with real undefined instead of String 'new-and-undefined', became a react element.
	getSort = (value, type) => {
		return {
			column: value,
			sortFunction: (a, b) => {
				if (a === b) {
					return 0;
				} else if (a !== "new-and-undefined" && b !== "new-and-undefined") {
					if (type === "alpha") {
						return a.localeCompare(b, "sv");
					}
					if (type === "numeric") {
						return String(a).localeCompare(String(b), undefined, {
							numeric: true,
						});
					}
					return 0;
					// Value will only be undefined (because item has isNew = true) when just added, isNew is removed when manually sorting.
				} else if (a === "new-and-undefined" && b !== "new-and-undefined") {
					return this.sortDirection ? 1 : -1;
				} else if (a !== "new-and-undefined" && b === "new-and-undefined") {
					return this.sortDirection ? 1 : -1;
				} else {
					return 0;
				}
			},
		};
	};

	// No rerenders when scrolling with buttons.
	slideLeft = (evt) => {
		if (this.slideLeftID === -1) {
			this.slideLeftID = setInterval(this.slidingLeft, 5);
		}
	};

	slideRight = (evt) => {
		if (this.slideRightID === -1) {
			this.slideRightID = setInterval(this.slidingRight, 5);
		}
	};

	slidingLeft = () => {
		this.members.scrollLeft += 2;
		// if (this.applicants) this.applicants.scrollLeft += 2
	};

	slidingRight = () => {
		this.members.scrollLeft -= 2;
		// if (this.applicants) this.applicants.scrollLeft -= 2
	};

	endSlideLeft = (evt) => {
		if (this.slideLeftID !== -1) {
			clearInterval(this.slideLeftID);
			this.slideLeftID = -1;
		}
	};

	endSlideRight = (evt) => {
		if (this.slideRightID !== -1) {
			clearInterval(this.slideRightID);
			this.slideRightID = -1;
		}
	};

	// isOverflown = (element) => {
	//   console.log('element', element);
	//   console.log('element.scrollWidth', element.scrollWidth);
	//   console.log('element.clientWidth', element.clientWidth);
	//   if (element.scrollWidth > element.clientWidth) {
	//     console.log('Can scroll');
	//   }
	// }

	filterChanged = (value) => {
		this.setState({
			filterText: value,
		});
	};

	createMemberTable = (memberRows, approved) => {
		const { columns, selectedMailingList, filterText } = this.state;
		const sorts = [];
		const filters = [];
		const tableHeads = [];

		// Approve and delete, use singel space { ` ` } as header to make it empty.
		if (!approved)
			tableHeads.push(
				<Th
					column={"approve"}
					key={"approve"}
					className={IN_STICKY_COLUMN}
				>{` `}</Th>
			);
		tableHeads.push(
			<Th
				column={"delete"}
				key={"delete"}
				className={IN_STICKY_COLUMN}
			>{` `}</Th>
		);
		if (approved)
			tableHeads.push(
				<Th
					column={"selected"}
					key={"selected"}
					className={
						IN_STICKY_COLUMN +
						" mailing-lists" +
						(memberRows.length === 0 ? " list-is-empty" : "") +
						(selectedMailingList ? "" : " tmp-hide")
					}
				>
					{this.mailingListSelector()}
				</Th>
			);

		columns.forEach((column, index) => {
			if (column.use) {
				sorts.push(this.getSort(column.value, column.sort));
				if (approved) filters.push(column.value);
				tableHeads.push(
					<Th
						column={column.value}
						key={column.value}
						className={approved ? STICKY_APPROVED : STICKY_APPLICANTS}
					>
						{column.label}
					</Th>
				);
			}
		});

		if (approved) {
			tableHeads.push(
				<Th column={"chairman"} key={"chairman"}>
					{"Ordförande"}
				</Th>
			);
		}

		if (!approved) {
			tableHeads.push(
				<Th
					column={"applicationDate"}
					key={"applicationDate"}
					className={STICKY_APPLICANTS}
				>
					{"Ansökte"}
				</Th>
			);
			sorts.push("applicationDate");
		}

		return (
			<Table
				className={`reactable ${STICKY_FILTER}`}
				sortable={sorts}
				onSort={this.sortChanged}
				filterable={filters}
				filterPlaceholder={"Sök bland medlemmar"}
				filterBy={filterText}
				onFilter={this.filterChanged}
				itemsPerPage={approved ? 100 : false}
				previousPageLabel={" <"}
				nextPageLabel={" >"}
			>
				<Thead>{tableHeads}</Thead>
				{memberRows}
			</Table>
		);
	};

	createMemberRows = () => {
		const { columns, selectedMailingList } = this.state;
		const { members, mailingLists, USE_MAIL_LISTS, gallery } = this.props;
		const approvedRows = [];
		const awaitingApprovalRows = [];
		let withEmail = 0;

		Object.keys(members).forEach((memberKey) => {
			const member = members[memberKey];
			// console.log('MEMBER', member);
			const approved = member.approved;
			if (approved && member.email) withEmail++;

			const isOnList = selectedMailingList
				? member.mailingLists &&
				  member.mailingLists.indexOf(selectedMailingList) >= 0
					? true
					: false
				: false;

			let memberData = approved
				? []
				: [
						<Td
							column={"approve"}
							value="approve"
							className={IN_STICKY_COLUMN}
							key="approve"
						>
							<PLUS_WHITE
								className="registry__members__approve-member"
								onClick={() => this.approveMember(member._id)}
							/>
						</Td>,
				  ];
			memberData.push(
				<Td
					column={"delete"}
					value="delete"
					className={IN_STICKY_COLUMN}
					key="delete"
				>
					<BIN
						className="registry__members__remove-member"
						onClick={() => this.removeMember(member._id)}
					/>
				</Td>
			);
			if (approved) {
				memberData.push(
					<Td
						column={"selected"}
						value={"selected"}
						className={
							IN_STICKY_COLUMN +
							" select-box" +
							(USE_MAIL_LISTS ? "" : " tmp-hide") +
							(selectedMailingList ? " enabled" : " tmp-hide")
						}
						key="selected"
					>
						<input
							type="checkbox"
							onChange={(checkBox) =>
								this.toggleSubscription(
									member._id,
									checkBox,
									selectedMailingList
								)
							}
							disabled={!selectedMailingList}
							checked={isOnList}
						/>
					</Td>
				);

				const chairmanId = gallery.chairman
					? gallery.chairman._id || gallery.chairman
					: false;
				const isChairman = chairmanId === member._id;

				memberData.push(
					<Td
						column={"chairman"}
						value={"chairman"}
						className={"select-box"}
						key="chairman"
					>
						<input
							type="checkbox"
							onChange={(checkBox) => this.toggleChairman(member._id, checkBox)}
							checked={isChairman}
							style={{ marginLeft: "5px" }}
						/>
					</Td>
				);
			}

			columns.forEach((column, index) => {
				let value = "";
				if (!column.custom) {
					value = member[column.value] || ""; // Value can not be null
				} else if (member.customColumns) {
					member.customColumns.forEach((customColumn) => {
						if (customColumn.id === column.value) {
							value = customColumn.value || ""; // Value can not be null
						}
					});
				}

				let sortValue = value;
				if (member.isNew) {
					// Newly added members sorts by this value, to always put them on top of the list when added.
					sortValue = "new-and-undefined";
				}

				memberData.push(
					<Td
						column={column.value}
						value={sortValue}
						className={approved ? STICKY_APPROVED : STICKY_APPLICANTS}
						key={column.value}
					>
						<InputField
							value={value}
							update={this.updateMemberValue}
							memberId={member._id}
							keyForValue={column.value}
							approved={approved}
							isNew={member.isNew}
							custom={column.custom || false}
						/>
					</Td>
				);
			});

			if (!approved) {
				memberData.push(
					<Td
						column={"applicationDate"}
						value={member.created_at}
						key="applicationDate"
					>
						{moment(member.created_at).format("MMM Do, YYYY")}
					</Td>
				);
			}

			if (approved) {
				approvedRows.push(
					<Tr key={memberKey} className={member.isNew ? "newmember" : ""}>
						{memberData}
					</Tr>
				);
			} else {
				awaitingApprovalRows.push(<Tr key={memberKey}>{memberData}</Tr>);
			}
		});

		return {
			approved: approvedRows,
			awaiting: awaitingApprovalRows,
			approvedAndHasEmail: withEmail,
		};
	};

	toggleOpen = () => {
		this.setState({
			optionsOpen: !this.state.optionsOpen,
		});
	};

	render() {
		const {
			organisation,
			isFetching,
			isAdmin,
			exported,
			mailingLists,
			gallery,
			USE_MAIL_LISTS,
			t,
		} = this.props;
		const {
			importExportOpen,
			adminSectionOpen,
			listsCount,
			selectedMailingList,
			mailingListName,
			mailListSectionOpen,
			modalOpen,
			modalType,
			modalMessage,
			columnLabels,
			optionsOpen,
		} = this.state;

		const memberRows = this.createMemberRows();
		const approvedMembers = memberRows.approved;
		const awaitingApproval = memberRows.awaiting;
		const emailCount = memberRows.approvedAndHasEmail;

		const approvedMemberTable = this.createMemberTable(approvedMembers, true);
		const awaitingApprovalTable = this.createMemberTable(
			awaitingApproval,
			false
		);
		const isPremium =
			isPremiumOrAbove(gallery) ||
			hasAccessToFeature(gallery, MEMBER_IMP_EXP_FEATURE);

		// Need to show spinner until all members have been drawn. On fetching makes it disappear too soon.
		return (
			<div className="registry">
				{false && isFetching && <div className="registry__loading-spinner" />}

				{/*Admin functionality*/}
				{isAdmin && (
					<div className="registry__admin-section">
						<button
							onClick={this.toggleAdminSectionOpen}
							className="registry__expand-button"
						>
							Admin
						</button>
						{adminSectionOpen && (
							<div className="registry__admin-section__content">
								<button onClick={this.props.toggleRegistry}>
									Gamla medlemsregistret
								</button>
								<button
									onClick={this.importFromOldRegistry}
									disabled={isFetching}
								>
									Importera medlemmar från gamla registret
								</button>
								<button
									onClick={this.removeAllMembers}
									disabled={isFetching}
									className="registry__admin-section__content__remove-button"
								>
									Ta bort alla godkända medlemmar?
								</button>
							</div>
						)}
					</div>
				)}

				{/*Import/export section*/}
				{/* Society can only mail list to us, and only for premium. */}
				<div className="registry__import-export__buttons">
					<input
						type="file"
						id="fileSend"
						ref={(ref) => (this.easyFileUpload = ref)}
						onChange={this.sendMemberListToUs}
						className={"hidden-file-upload-button"}
					/>
					{isPremium ? (
						<label
							htmlFor="fileSend"
							className="purple-button purple-button--wide purple-button--wide--mod_import"
						>
							{t("importExport.import")}
						</label>
					) : (
						<button
							className="purple-button purple-button--wide purple-button--wide--mod_import"
							onClick={this.premiumOnly}
						>
							{t("importExport.import")}
						</button>
					)}
					{isPremium ? (
						<button
							onClick={this.exportMembers}
							disabled={isFetching || approvedMembers.length < 1}
							className="purple-button purple-button--wide purple-button--wide--mod_export"
						>
							{t("importExport.export")}
						</button>
					) : (
						<button
							className="purple-button purple-button--wide purple-button--wide--mod_export"
							onClick={this.premiumOnly}
						>
							{t("importExport.export")}
						</button>
					)}
				</div>
				{/* Only admin can directly upload members from an excel file.
          If changing to let society do it, make so it only works for premium. */}
				{isAdmin && (
					<div className="registry__import-export__buttons">
						<input
							type="file"
							id="fileUpload"
							ref={(ref) => (this.fileUpload = ref)}
							onChange={this.importFile}
							className={"hidden-file-upload-button"}
						/>
						<label
							htmlFor="fileUpload"
							className="purple-button purple-button--wide purple-button--wide--mod_import"
						>
							{t("importExport.import")}
							{" (Admin)"}
						</label>
						<button
							onClick={this.toggleImportOpen}
							className="purple-button purple-button--wide purple-button--wide--mod_import"
						>
							{t("importExport.instructions")}
						</button>
					</div>
				)}
				{importExportOpen && (
					<div className="registry__import-export">
						{!isAdmin && (
							<div>
								<p style={{ padding: "20px 0" }}>{t("importExport.toUs")}</p>

								<p style={{ paddingBottom: "40px" }}>
									{t("importExport.toYou")}
								</p>
							</div>
						)}
						<p>{t("importExport.columns")}</p>
						<ul>
							<li>Förnamn</li>
							<li>Efternamn</li>
							<li>Hela namnet</li>
							<li>Email</li>
							<li>Telefon</li>
							<li>Mobil</li>
							<li>Adress</li>
							<li>Postnummer</li>
							<li>Ort</li>
							<li>Betalat avgift</li>
							<li>Medlemsnummer</li>
							<li>Övrig info</li>
						</ul>
						<p>
							{t("importExport.columns")}
							<br />
							<br />
						</p>
						<div className="registry__import-export__doc-example-image">
							<img src={DOC_EXAMPLE} />
						</div>
					</div>
				)}
				{/*Import/export section end*/}

				{/*Application section*/}
				{awaitingApproval.length > 0 && (
					<div className="registry__awaiting-approval">
						<div className="registry__top">
							<h4 className={"registry__top__header is-purple"}>
								{t("memberTable.applications")}
							</h4>
							<div className="registry__top__legend is-purple">
								<div>
									<PLUS_WHITE className="registry__top__legend__approve" />
									{t("memberTable.approve")}
								</div>
								<div>
									<BIN className="registry__top__legend__remove" />
									{t("memberTable.deny")}
								</div>
							</div>
						</div>
						<div className="registry__members-container">
							<div
								className="registry__members is-purple"
								ref={(ref) => (this.applicants = ref)}
							>
								{awaitingApprovalTable}
							</div>
						</div>
					</div>
				)}
				{/*Application section end*/}

				{/*Email lists*/}
				<legend
					className={
						"registry__mailing-lists-opener" +
						(mailListSectionOpen ? " is-open" : "") +
						(awaitingApproval.length < 1 ? " is-topmargin" : "")
					}
					onClick={this.toggleMailListSectionOpen}
				>
					<span style={{ cursor: "pointer" }}>{t("emailLists.title")}</span>
					<ExpandButton
						expanded={mailListSectionOpen}
						click={this.toggleMailListSectionOpen}
						style={{ top: 0 }}
					/>
				</legend>
				<div
					className={
						"registry__mailing-lists" + (mailListSectionOpen ? " is-open" : "")
					}
				>
					<div className="registry__mailing-lists__radio-selector">
						<div className="registry__mailing-lists__radio-selector__create-list">
							<PLUS_WHITE
								className="registry__mailing-lists__radio-selector__create-list__button"
								onClick={() => this.toggleModalOpen("create-mailing-list")}
							/>
							<p onClick={() => this.toggleModalOpen("create-mailing-list")}>
								{"Skapa lista"}
							</p>
						</div>
						{mailingLists.map((list) => {
							return (
								<div key={list._id}>
									<input
										type="radio"
										name="mailingLists"
										className="registry__checkbox"
										checked={list._id === selectedMailingList}
										onClick={() => this.selectMailingList(list._id)}
										id={list._id}
									/>
									<label htmlFor={list._id}>
										{list.name}
										<span>{` ( ${listsCount[list._id]} personer )`}</span>
									</label>
									<BIN
										className={"delete-mailing-list"}
										onClick={() => this._deleteMailingList(list._id, list.name)}
									/>
								</div>
							);
						})}
					</div>
					{/*REMOVE UPON PUBLISHING*/}
					{/*
            <div className={ b('mailing-lists')('help-text') }>
              <ol>
                <li>Skapa en mejllista till vänster om du inte redan har gjort det.</li>
                <li>Markera den mejllista du vill redigera. Kryssa i de personer du vill ha med i mejllistan i medlemsregistret nedan.</li>
                <li>Gå till sektionen ”Skicka mail” ovan och välj den mejllista du vill ha som mottagare.</li>
              </ol>
            </div>
          */}
				</div>
				{/*Create pop-up*/}
				{modalOpen && modalType && (
					<div className="registry__modal-container">
						<div
							className="registry__modal-container__backdrop"
							onClick={() => this.toggleModalOpen()}
						></div>
						<div className="registry__modal-container__modal modalType">
							<img src={CLOSE} onClick={() => this.toggleModalOpen()} />
							{modalType === "create-mailing-list" && (
								<h4>{t("emailLists.createList")}</h4>
							)}
							{modalType === "create-mailing-list" && (
								<div className="registry__modal-container__modal__create-list">
									<input
										className="registry__modal-container__modal__create-list__name"
										type="text"
										value={mailingListName}
										onChange={this.mailingListNameChange}
										placeholder={t("emailLists.listName")}
									/>
									<div
										className={
											"registry__modal-container__modal__create-list__save" +
											(!mailingListName ? " is-disabled" : "")
										}
										onClick={mailingListName ? this._createMailingList : null}
									>
										{t("emailLists.save")}
									</div>
								</div>
							)}
							{modalType === "message" && <p>{modalMessage}</p>}
						</div>
					</div>
				)}
				{/*Email lists ends*/}

				{/*Members-list*/}
				<div className="registry__top">
					<h4 className="registry__top__header">{`Medlemmar ( ${approvedMembers.length} st, ${emailCount} har email adress )`}</h4>
					<div className="registry__top__legend">
						<div onClick={this.addMember}>
							<PLUS_WHITE className="registry__top__legend__approve" />
							{t("memberTable.add")}
						</div>
						<div onClick={this.toggleOpen}>
							<PENCIL className="registry__top__legend__remove" />
							{t("memberTable.edit")}
						</div>
					</div>
				</div>
				{optionsOpen && (
					<RegistryOptions
						gallery={gallery}
						columns={this.state.columns}
						columnLabels={columnLabels}
						clicked={this.changeImportUse}
						organisation={organisation}
						initColumns={this.setActiveColumns}
						listsCount={listsCount}
						sendToList={selectedMailingList}
						selectMailingList={this.selectMailingList}
						toggleModalOpen={this.toggleModalOpen}
						useMailLists={USE_MAIL_LISTS}
						removeCustomColumns={this.removeCustomColumns}
					/>
				)}
				<div className="registry__members-container">
					<PLUS_WHITE
						className="registry__members-container__add-member"
						onClick={this.addMember}
					/>
					<div
						className="registry__members"
						ref={(ref) => (this.members = ref)}
					>
						{approvedMemberTable}
					</div>
					<div className="registry-scroller">
						<div className="registry-scroller__buttons">
							<div
								className="registry-scroller__buttons__button"
								onTouchStart={this.slideRight}
								onTouchEnd={this.endSlideRight}
								onMouseDown={this.slideRight}
								onMouseUp={this.endSlideRight}
								onMouseOut={this.endSlideRight}
							>
								<img src={CHEVRON} />
							</div>
							<div
								className="registry-scroller__buttons__button"
								onTouchStart={this.slideLeft}
								onTouchEnd={this.endSlideLeft}
								onMouseDown={this.slideLeft}
								onMouseUp={this.endSlideLeft}
								onMouseOut={this.endSlideLeft}
							>
								<img src={CHEVRON} />
							</div>
						</div>
					</div>
				</div>
				{/*Members-list ends*/}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin },
		registry: { members, isFetching, exported },
		gallery: { gallery, updateColumns },
	} = state;
	const mailingLists =
		gallery && gallery.registry && gallery.registry.mailingLists
			? gallery.registry.mailingLists
			: [];
	const USE_MAIL_LISTS = true; // isAdmin

	return {
		isAdmin,
		members,
		isFetching,
		exported,
		mailingLists,
		gallery,
		USE_MAIL_LISTS,
		updateColumns,
	};
}
const translated = withNamespaces("member")(MemberTable);
export default connect(mapStateToProps)(translated);
