import React from "react";
import { Link } from "react-router";

const OrderItem = ({ item, quantity }) => {
	return (
		<div className="order-item">
			<p>
				<Link to={`/artworks/${item._id}`}>{`${item.title} by ${
					item.artistMod ? item.artistMod.name : "Unknown"
				}`}</Link>
			</p>
			<p>{`Qty: ${quantity}`}</p>
			<p>{`Price: ${item.price} ${item.priceCurrency}`}</p>
		</div>
	);
};

const OrderItems = ({ items, orderTotal, orderVAT, orderSum, shipping }) => {
	return (
		<div className="order-items">
			<h4>Items</h4>
			{items.map((orderLine) => {
				if (orderLine.item && orderLine.item !== -1 && orderLine.item._id) {
					return <OrderItem key={orderLine._id} {...orderLine} />;
				} else {
					// If item is missing, it has been removed from the database. The shopping cart still references its id.
					return (
						<div key={orderLine._id}>{`Missing ${orderLine.itemType}`}</div>
					);
				}
			})}
			{shipping && (
				<p className="order-items__shipping">{`${shipping.friendlyName}, ${shipping.price} ${shipping.currency}`}</p>
			)}
			<p className="order-items__total">{`Order total: ${orderTotal} SEK`}</p>
			<p>{`Order VAT: ${orderVAT} SEK`}</p>
			<p>{`Order sum: ${orderSum} SEK`}</p>
		</div>
	);
};

export default OrderItems;
