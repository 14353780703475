import { CALL_API } from '../utils/api'

// fetch artworks

export const METRICS_REQUEST = 'METRICS_REQUEST'
export const METRICS_SUCCESS = 'METRICS_SUCCESS'
export const METRICS_FAILURE = 'METRICS_FAILURE'

export const ARTWORKS_METRICS_REQUEST = 'ARTWORKS_METRICS_REQUEST'
export const ARTWORKS_METRICS_SUCCESS = 'ARTWORKS_METRICS_SUCCESS'
export const ARTWORKS_METRICS_FAILURE = 'ARTWORKS_METRICS_FAILURE'

export const ARTWORKS_ADMIN_METRICS_REQUEST = 'ARTWORKS_ADMIN_METRICS_REQUEST'
export const ARTWORKS_ADMIN_METRICS_SUCCESS = 'ARTWORKS_ADMIN_METRICS_SUCCESS'
export const ARTWORKS_ADMIN_METRICS_FAILURE = 'ARTWORKS_ADMIN_METRICS_FAILURE'

export const VENUES_ADMIN_METRICS_REQUEST = 'VENUES_ADMIN_METRICS_REQUEST'
export const VENUES_ADMIN_METRICS_SUCCESS = 'VENUES_ADMIN_METRICS_SUCCESS'
export const VENUES_ADMIN_METRICS_FAILURE = 'VENUES_ADMIN_METRICS_FAILURE'

export const SHOWS_METRICS_REQUEST = 'SHOWS_METRICS_REQUEST'
export const SHOWS_METRICS_SUCCESS = 'SHOWS_METRICS_SUCCESS'
export const SHOWS_METRICS_FAILURE = 'SHOWS_METRICS_FAILURE'

export const GALLERY_METRICS_REQUEST = 'GALLERY_METRICS_REQUEST'
export const GALLERY_METRICS_SUCCESS = 'GALLERY_METRICS_SUCCESS'
export const GALLERY_METRICS_FAILURE = 'GALLERY_METRICS_FAILURE'

// Uses the API to get a artwork
export function fetchMetrics() {
	const endpoint = "stats/dashboard"
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [METRICS_REQUEST, METRICS_SUCCESS, METRICS_FAILURE]
		}
	}
}

export function fetchArtworksMetrics() {
	const endpoint = "stats/artworks"
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORKS_METRICS_REQUEST, ARTWORKS_METRICS_SUCCESS, ARTWORKS_METRICS_FAILURE]
		}
	}
}

export function fetchAdminArtworksMetrics() {
	const endpoint = "stats/admin/artworks"
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORKS_ADMIN_METRICS_REQUEST, ARTWORKS_ADMIN_METRICS_SUCCESS, ARTWORKS_ADMIN_METRICS_FAILURE]
		}
	}
}

export function fetchAdminVenuesMetrics() {
	const endpoint = "stats/admin/venues"
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [VENUES_ADMIN_METRICS_REQUEST, VENUES_ADMIN_METRICS_SUCCESS, VENUES_ADMIN_METRICS_FAILURE]
		}
	}
}

export function fetchShowsMetrics() {
	const endpoint = "stats/shows"
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [SHOWS_METRICS_REQUEST, SHOWS_METRICS_SUCCESS, SHOWS_METRICS_FAILURE]
		}
	}
}

export function fetchGalleryMetrics() {
	const endpoint = "stats/gallery"
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [GALLERY_METRICS_REQUEST, GALLERY_METRICS_SUCCESS, GALLERY_METRICS_FAILURE]
		}
	}
}
