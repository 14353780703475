import React, { Component } from "react";
// import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import { getOrientation } from "../../utils/Helpers";

const FIVE_MIB = 5242880;
const ACCEPT = "image/jpeg,image/jpg,image/png";
const CLOSE_CROSS = require("../../../assets/images/icons/close@2x.png");

// const propTypes = {
// 	defaultImage: PropTypes.any,
// };

const defaultProps = {
	defaultImage: false,
};

const validImage = (value, allValues, props) => {
	return undefined;
};

class ImageUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onDrop = (acceptedFiles, e) => {
		const reader = new FileReader();
		const {
			uploadedImage: { URI, contentType, fileName },
			t,
		} = this.props;
		const uploadedFile = acceptedFiles[0];

		//Load preview
		reader.onload = (upload) => {
			// Uploaded image
			const image = {
				URI: upload.target.result,
				fileName: uploadedFile.name,
				contentType: uploadedFile.type,
			};

			// Set state for preview
			this.setState({
				previewImage: uploadedFile,
			});

			// Update input states
			URI.input.onChange(image.URI);
			contentType.input.onChange(image.contentType);
			fileName.input.onChange(image.fileName);
			URI.input.onBlur(image.URI);
			contentType.input.onBlur(image.contentType);
			fileName.input.onBlur(image.fileName);
		};

		// Read data file
		if (uploadedFile) {
			reader.readAsDataURL(uploadedFile);
		}
	};

	// On multi upload, if one file fails, all files fails.
	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;

		rejectedFiles.forEach((rejected) => {
			this.showImageError(rejected, t);
		});
	};

	showImageError = (rejectedFile, t) => {
		// console.log('REJECTED:', rejectedFile);
		if (rejectedFile.size > FIVE_MIB) {
			window.alert(t("imageUpload.imageTooBig"));
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("imageUpload.imageWrongFormat"));
		}
	};

	removeImage = () => {
		const {
			featuredImage: { src },
			uploadedImage: { URI, contentType, fileName },
			t,
		} = this.props;
		const confirm = window.confirm(t("imageUpload.removeAreYouSure"));
		if (confirm) {
			// Only src of featuredImage will be set to null.
			// Other values remain and featuredImage (with src = null) will be sent as params to API when saving.
			// Need to set src to null or image won't disappear from preview.
			src.input.onChange(null);
			src.input.onBlur(null);

			// When adding image and then removing it without saving first, this.props.uploadedImage will still be there,
			// looks like this: { URI: ..., contentType: ..., fileName: ... }
			// Set URI to null and test for it in imageHelper.js -> setFeaturedImage() in API
			URI.input.onChange(null);
			URI.input.onBlur(null);
			contentType.input.onChange(null);
			contentType.input.onBlur(null);
			fileName.input.onChange(null);
			fileName.input.onBlur(null);

			this.setState({
				previewImage: null,
			});
		}
	};

	renderPreviewImage = (t) => {
		const { previewImage } = this.state;
		const featuredImage = this.props.featuredImage.src.input.value || null;
		const src = previewImage ? previewImage.preview : featuredImage;
		if (src) {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-image">
						<img className="ImageUpload__preview-image-img" src={src} />
					</div>
				</div>
			);
		} else {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<div>{t("imageUpload.previewHere")}</div>
					</div>
				</div>
			);
		}
	};

	renderRoundPreview = (t) => {
		const { previewImage } = this.state;
		const featuredImage = this.props.featuredImage.src.input.value || null;
		const src = previewImage ? previewImage.preview : featuredImage;
		if (src) {
			return (
				<div className="ImageUpload__preview is-round">
					<div
						className="ImageUpload__preview-round"
						style={{ backgroundImage: `url(${src})` }}
					></div>
				</div>
			);
		} else {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<div>{t("imageUpload.previewHere")}</div>
					</div>
				</div>
			);
		}
	};

	render() {
		// let {input, meta: { touched, error, valid, invalid }} = this.props;
		const { canRemoveImage = false, profileImage = false, t } = this.props;
		const hasFeaturedImg = this.props.featuredImage.src.input.value
			? true
			: false;
		const hasUploadedImg =
			this.props.uploadedImage.URI.input.value &&
			this.props.uploadedImage.fileName.input.value &&
			this.props.uploadedImage.contentType.input.value
				? true
				: false;
		const valid = hasFeaturedImg || hasUploadedImg;
		const error = this.props.featuredImage.src.meta.error || false;
		const required = this.props.required ? true : false;
		const marked = this.props.marked ? true : false;

		let classname = "";
		if (marked) {
			if (!valid) {
				classname = "ImageUpload__dropzone empty";
			} else {
				classname = "ImageUpload__dropzone marked";
			}
		} else {
			classname = "ImageUpload__dropzone";
		}

		return (
			<div
				className={
					"ImageUpload" +
					(error ? " is-error" : "") +
					(valid ? " is-valid" : "") +
					(required ? " is-required" : "")
				}
			>
				<div className="input-holder">
					<input type="hidden" name="uploadedImage.URI" />
					<input type="hidden" name="uploadedImage.fileName" />
					<input type="hidden" name="uploadedImage.contentType" />
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.dropRejected}
						className={classname}
						accept={ACCEPT}
						multiple={false}
						maxSize={FIVE_MIB}
					>
						<div>{t("imageUpload.dropHere")}</div>
					</Dropzone>
					{error ? (
						<span className="Input__error" style={{ marginTop: "10px" }}>
							{error}
						</span>
					) : null}
				</div>
				{profileImage ? (
					<div className="input-holder input-holder--center">
						{this.renderRoundPreview(t)}
					</div>
				) : (
					<div className="input-holder">{this.renderPreviewImage(t)}</div>
				)}
				{canRemoveImage && (
					<button
						className="close-button"
						type="button"
						onClick={this.removeImage}
						disabled={!hasFeaturedImg && !hasUploadedImg}
						style={{ backgroundImage: `url(${CLOSE_CROSS})` }}
					/>
				)}
			</div>
		);
	}
}

export default withNamespaces("components")(ImageUpload);
