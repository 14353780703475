import {
	USER_REQUEST,
	USER_SUCCESS,
	USER_FAILURE,
	SYNC_REQUEST,
	SYNC_SUCCESS,
	SYNC_FAILURE,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAILURE,
	USER_SAVE_REQUEST,
	USER_SAVE_SUCCESS,
	USER_SAVE_FAILURE,
	USERS_REQUEST,
	USERS_SUCCESS,
	USERS_FAILURE,
	USER_SAVED_GALLERY,
	USER_SAVED_SHOW,
	USER_SAVED_ARTWORK,
	USER_REMOVE_REQUEST,
	USER_REMOVE_FAILURE,
	USER_REMOVE_SUCCESS,
	SOCIETY_ADMIN_REQUEST,
	SOCIETY_ADMIN_SUCCESS,
	SOCIETY_ADMIN_FAILURE,
	USER_MAKE_SOCIETY_REQUEST,
	USER_MAKE_SOCIETY_SUCCESS,
	USER_MAKE_SOCIETY_FAILURE,
	UPDATE_ARTIST_PRO_REQUEST,
	UPDATE_ARTIST_PRO_SUCCESS,
	UPDATE_ARTIST_PRO_FAILURE,
	MEMBERFUL_MEMBER_REQUEST,
	MEMBERFUL_MEMBER_SUCCESS,
	MEMBERFUL_MEMBER_FAILURE,
} from "../actions/UserActions";
import {
	LIKE_REQUEST,
	LIKE_FAILURE,
	LIKE_SUCCESS,
} from "@artworkslab/sharedmodules/src/actions/UserActions";

export function userReducer(
	state = {
		isFetching: false,
		users: {},
		user: {},
		activeUser: {},
		valid: null,
		role: null,
		societyAdmins: {},
		onboarding: {
			showProgressbar: false,
			gallery: false,
			shows: false,
			artworks: false,
		},
	},
	action
) {
	switch (action.type) {
		case USER_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				user: {},
				activeUser: {},
			});
		}
		case USER_SUCCESS: {
			const user = action.response;
			const users = Object.assign({}, state.users);
			users[user._id] = action.response;
			return Object.assign({}, state, {
				users,
				user,
				activeUser: user,
				isFetching: false,
				error: null,
				valid: isValidUser(action.response),
				role: roleUser(action.response),
			});
		}
		case USER_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			});
		}
		case SYNC_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case SYNC_SUCCESS: {
			let showProgressbar = true;
			let onboarding = null;
			if (action.response.role === "author") {
				onboarding = action.response.onboarding;
				if (
					onboarding &&
					onboarding.gallery &&
					onboarding.artworks &&
					onboarding.shows
				) {
					showProgressbar = false;
				}
				onboarding.showProgressbar = showProgressbar;
			}
			return Object.assign({}, state, {
				isFetching: false,
				error: null,
				onboarding: onboarding,
				valid: isValidUser(action.response.onboarding),
			});
		}
		case SYNC_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			});
		}
		case USER_UPDATE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case USER_UPDATE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				error: null,
				user: action.response,
				activeUser: action.response,
			});
		}
		case "artworksapp/shared/UPDATE_USER_SUCCESS": {
			return Object.assign({}, state, {
				isFetching: false,
				error: null,
				user: action.response,
				activeUser: action.response,
			});
		}
		case USER_UPDATE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case USER_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case USER_SAVE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				error: null,
				user: action.response,
				activeUser: action.response,
			});
		}
		case USER_SAVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case USERS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				deletedUser: false,
			});
		}
		case USERS_SUCCESS: {
			let users = {};
			action.response.map((user) => {
				users[user._id] = user;
			});
			return Object.assign({}, state, {
				isFetching: false,
				error: null,
				users: users,
			});
		}
		case USERS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case USER_SAVED_GALLERY: {
			const onboarding = Object.assign({}, state.onboarding, {
				gallery: true,
			});
			return Object.assign({}, state, {
				isFetching: false,
				onboarding: onboarding,
				valid: isValidUser(onboarding),
			});
		}
		case USER_SAVED_SHOW: {
			const onboarding = Object.assign({}, state.onboarding, {
				shows: true,
			});
			return Object.assign({}, state, {
				isFetching: false,
				onboarding: onboarding,
				valid: isValidUser(onboarding),
			});
		}
		case USER_SAVED_ARTWORK: {
			const onboarding = Object.assign({}, state.onboarding, {
				artworks: true,
			});
			return Object.assign({}, state, {
				isFetching: false,
				onboarding: onboarding,
				valid: isValidUser(onboarding),
			});
		}
		case USER_REMOVE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				deletedUser: true,
			});
		}
		case USER_REMOVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case USER_REMOVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case SOCIETY_ADMIN_REQUEST: {
			return Object.assign({}, state, {
				societyAdmins: {},
				isFetching: true,
				error: null,
			});
		}
		case SOCIETY_ADMIN_SUCCESS: {
			let users = {};
			action.response.map((user) => {
				users[user._id] = user;
			});
			return Object.assign({}, state, {
				societyAdmins: users,
				isFetching: false,
			});
		}
		case SOCIETY_ADMIN_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case USER_MAKE_SOCIETY_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case USER_MAKE_SOCIETY_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
			});
		}
		case USER_MAKE_SOCIETY_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case LIKE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case LIKE_SUCCESS: {
			const user = action.response;
			return Object.assign({}, state, {
				isFetching: false,
				activeUser: user,
				error: null,
			});
		}
		case LIKE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			});
		}
		case UPDATE_ARTIST_PRO_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			});
		}
		case UPDATE_ARTIST_PRO_SUCCESS: {
			const updatedBilling = action.response;
			const updatedArtist = Object.assign({}, state.user.artist);
			updatedArtist.billing = updatedBilling;
			const updatedUser = Object.assign({}, state.user);
			updatedUser.artist = updatedArtist;
			return Object.assign({}, state, {
				isFetching: false,
				user: updatedUser,
			});
		}
		case UPDATE_ARTIST_PRO_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
			});
		}
		case MEMBERFUL_MEMBER_SUCCESS: {
			const userWithMem = Object.assign({}, state.user, {
				hasMembefulSubscription: action.response,
			});
			return Object.assign({}, state, {
				user: userWithMem,
			});
		}
		default:
			return state;
	}
}
/* TODO: Fix valid user for onboarding */
function isValidUser(onboarding) {
	if (!onboarding) {
		return false;
	} else if (onboarding.gallery && onboarding.shows && onboarding.artworks) {
		return true;
	} else {
		return false;
	}
}

function roleUser(user) {
	if (!user.role) return false;

	return user.role;
}
