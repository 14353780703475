import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	Field,
	Fields,
	FieldArray,
	FormSection,
	reduxForm,
	arrayRemove,
} from "redux-form";
import ImageUpload from "../../components/FormInput/ImageUpload";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextArea from "../../components/FormInput/TextArea";
import CheckBox from "../../components/FormInput/CheckBox";
import Tooltip from "../../components/Tooltip/Tooltip";
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import FormEditor from "../../components/FormComponents/FormEditor/FormEditor";
import FormEditorSwedish from "../../components/FormComponents/FormEditor/FormEditorSwedish";
import ArtworkUpload from "../../components/FormComponents/ArtworkUpload/ArtworkUpload";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";

const PROFILE = require("../../../assets/images/navbar_profile_60px.png");

const TOOL_TIPS = {
	colorPicker: {
		text: "Choose a color you want the image to be associated with",
		popupPos: "left",
	},
	showOnPage: {
		text: 'Page(s) to display this collection on. For example "artworks" or "shows artists"',
		popupPos: "left",
	},
	linkTo: {
		text: 'Page on artworks site to link users to. Do not include "https.artworks.io/.se", only write ex. "/shows/showname". Do include preceding slash.',
		popupPos: "left",
	},
};

const LinksList = (props) => {
	const about = props.input.value;
	if (!about) return false;
	const isSwedish = props.input.name === "aboutSwedish";
	const page = isSwedish ? "konstverk" : "artworks";
	const categories = about.split(",").map((cat) => cat.trim());
	const artLinks = [];
	let BASE_URL = process.env.BASE_URL;
	if (!isSwedish) BASE_URL = BASE_URL.replace(".se", ".io");

	categories.forEach((category, index) => {
		const medium = category
			.toLowerCase()
			.replace(" ", "-")
			.replace("å", "a")
			.replace("ä", "a")
			.replace("ö", "o");

		artLinks.push(`${BASE_URL}${page}?medium=${medium}`);
	});
	return (
		<div style={{ margin: "-20px 0 30px 0" }}>
			{artLinks.map((lnk, index) => {
				return (
					<p
						style={{ opacity: 0.5, fontSize: "0.75em", marginBottom: "2px" }}
						key={index}
					>
						{lnk}
					</p>
				);
			})}
		</div>
	);
};

const createRows = (items, type, removeItem) => {
	if (!items) return null;
	return items.map((item, index) => {
		// Articles that isImageGallery has no featuredImage.
		const altSrc = item.images && item.images[0] ? item.images[0].src : PROFILE;
		const imageSrc =
			type !== "artworks" && !item.isImageGallery && item.featuredImage
				? item.featuredImage.src
				: altSrc;
		const urlType = type === "articles" ? "posts" : type;
		const itemURL = `/${urlType}/${item._id}`;
		return (
			<Tr key={item._id}>
				<Td column="image">
					<Link to={itemURL}>
						<div
							className="table-image"
							style={{ backgroundImage: `url(${imageSrc})` }}
						/>
					</Link>
				</Td>
				<Td column="name" value={item.title || item.name}>
					<Link to={itemURL}>{item.title || item.name}</Link>
				</Td>
				<Td column="type">{type}</Td>
				<Td column="remove">
					<button
						type="button"
						className="purple-button--wide--mod_danger"
						onClick={() => {
							let confirm = window.confirm("Are you sure?");
							if (confirm) removeItem(type, index);
						}}
					>
						Remove
					</button>
				</Td>
			</Tr>
		);
	});
};

const ItemTables = (props) => {
	let rows = [];
	props.names.forEach((name) => {
		const items = props[name];
		const newRows = createRows(
			items.input.value,
			items.input.name,
			props.removeItem
		);
		if (newRows) {
			rows = rows.concat(newRows);
		}
	});
	return (
		<Table
			className="reactable"
			sortable={["name", "type"]}
			filterable={false}
			filterPlaceholder="Search selections"
		>
			<Thead>
				<Th column="image">Image</Th>
				<Th column="name">Name</Th>
				<Th column="type">Type</Th>
				<Th column="remove">Remove from this list</Th>
			</Thead>
			{rows}
		</Table>
	);
};

/*
// If we want different types to have their own tables:
// ex: <Field name="artworks" component={ ItemTable } removeItem={ removeItem } />
const ItemTable = (props) => {
  const items = props.input.value
  const type = props.input.name
  const rows = createRows(items, type, props.removeItem)
  return (
    <Table
      className="reactable"
      sortable={['name']}
      filterable={ false }
      filterPlaceholder="Search selections">
      <Thead>
        <Th column="image">Image</Th>
        <Th column="name">Name</Th>
        <Th column="remove">Remove from this list</Th>
      </Thead>
      { rows }
    </Table>
  )
}
*/

class SelectionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorChanged: false,
		};
	}

	updateChild = () => {
		if (!this.props.showEditor) return;
		this.editor.updateContentFromParent();
		this.editorSwedish.updateContentFromParentSwedish();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			isNewForm,
			removeItem,
			locked,
			lockedMessage,
			description,
			lockSlug,
			slug,
			multipleImages,
			showEditor,
			showLinks,
			hideTables,
		} = this.props;
		const { editorChanged } = this.state;
		// hideTables, selection inkopsportal-konstverk has PublicPurchaserList.js instead od ItemTables.

		// Locked, for HOME selection, don't show all fields, unnecessary and a little confusing.
		if (!locked)
			return (
				<form onSubmit={handleSubmit} className="selection__form">
					{lockedMessage && (
						<h3 style={{ paddingBottom: description ? "10px" : "50px" }}>
							{lockedMessage}
						</h3>
					)}
					{description && (
						<div style={{ paddingBottom: "50px" }}>
							{description.map((paragraph, index) => (
								<p key={index}>{paragraph}</p>
							))}
						</div>
					)}
					<div className="fieldset">
						<legend>General information</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="title"
								component={TextInput}
								placeholder="Title"
								required
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="titleSwedish"
								component={TextInput}
								placeholder="Swedish title (optional)"
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="showOnPage"
								component={TextInput}
								placeholder="Display on page(s)"
							>
								<Tooltip data={TOOL_TIPS["showOnPage"]} />
							</Field>
						</div>
						<div className="input-holder">
							<legend className="legend-nomargin">Slug (english)</legend>
							<Field
								name="slug"
								itemType="selection"
								component={TextDisplay}
								isAdmin={true}
								locked={lockSlug}
							/>
							<legend className="legend-nomargin">Slug (swedish)</legend>
							<Field
								name="slugSwedish"
								itemType="selection"
								component={TextDisplay}
								isAdmin={true}
								locked={lockSlug}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="visible"
								component={CheckBox}
								checkboxLabel="Visible"
							/>
						</div>
					</div>
					<div className="fieldset">
						<legend>Link user to:</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="linkTo.title"
								component={TextInput}
								placeholder="Title"
							/>
							<Field
								name="linkTo.titleSwedish"
								component={TextInput}
								placeholder="Title swedish"
							/>
							<Field
								name="linkTo.path"
								component={TextInput}
								placeholder="Link (ex. /artworks)"
							>
								<Tooltip data={TOOL_TIPS["linkTo"]} />
							</Field>
							<legend className="legend-nomargin">External link:</legend>
							<Field
								name="externalLink"
								component={TextInput}
								placeholder="External link"
							/>
						</div>
					</div>
					<div className="fieldset">
						<legend>About this selection</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="about"
								component={TextArea}
								placeholder="About the selection"
								extraClass={slug === "ranking" ? "tall-textarea" : ""}
							/>
						</div>
						{showLinks && (
							<div className="input-holder input-holder--full">
								<Field name="about" component={LinksList} />
							</div>
						)}
						<div className="input-holder input-holder--full">
							<Field
								name="aboutSwedish"
								component={TextArea}
								placeholder="About the selection, Swedish"
							/>
						</div>
						{showLinks && (
							<div className="input-holder input-holder--full">
								<Field name="aboutSwedish" component={LinksList} />
							</div>
						)}
					</div>
					<legend>Featured image</legend>
					{!multipleImages && (
						<div>
							<Fields
								names={[
									"featuredImage.src",
									"uploadedImage.URI",
									"uploadedImage.fileName",
									"uploadedImage.contentType",
								]}
								component={ImageUpload}
							/>
							<Field name="featuredImage.color" component={ColorPicker}>
								<Tooltip data={TOOL_TIPS["colorPicker"]} />
							</Field>
						</div>
					)}
					{/* Not for isNewForm, uploads not implemented on create new selection.
          Only activated for one premade selection for Home so far. */}
					{multipleImages && !isNewForm && (
						<Fields
							names={[
								"images",
								"uploads",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={ArtworkUpload}
							multi={true}
							sortable={true}
							captionOn={false}
							postId={false}
						/>
					)}
					{showEditor && (
						<div className="fieldset">
							<legend>Main body (English)</legend>
							<div className="input-holder input-holder--full">
								<Fields
									names={["draftContentState", "htmlContent"]}
									component={FormEditor}
									onRef={(ref) => (this.editor = ref)}
									onEditorChange={this.editorChange}
								/>
							</div>
						</div>
					)}
					{showEditor && (
						<div className="fieldset">
							<legend>Main body (Swedish)</legend>
							<div className="input-holder input-holder--full">
								<Fields
									names={["draftContentStateSwedish", "htmlContentSwedish"]}
									component={FormEditorSwedish}
									onRef={(ref) => (this.editorSwedish = ref)}
									onEditorChange={this.editorChange}
								/>
							</div>
						</div>
					)}
					{!hideTables && (
						<legend>Items for masonry (add from individual pages)</legend>
					)}
					{!hideTables && (
						<div
							className="input-holder input-holder--full"
							style={{ margin: "50px 0" }}
						>
							<Fields
								names={[
									"artworks",
									"artists",
									"galleries",
									"shows",
									"articles",
									"events",
									"announcements",
									"prints",
								]}
								component={ItemTables}
								removeItem={removeItem}
							/>
						</div>
					)}
					{/* Button to run onChange and onBlur in editor before submitting. */}
					<div className="fieldset">
						<div className="input-holder input-holder--full">
							<Field
								name="slug"
								itemType="selection"
								component={TextDisplay}
								isAdmin={true}
								locked={lockSlug}
							/>
						</div>
						<div className="fixed-save-button">
							<button
								className="purple-button--wide"
								type="submit"
								onClick={this.updateChild}
								disabled={(pristine || submitting) && !editorChanged}
							>
								Save
							</button>
						</div>
					</div>
				</form>
			);

		if (locked)
			return (
				<form onSubmit={handleSubmit} className="selection__form">
					<h3 style={{ paddingBottom: "50px" }}>{lockedMessage}</h3>
					<legend>Items for masonry (add from individual pages)</legend>
					<div
						className="input-holder input-holder--full"
						style={{ margin: "50px 0" }}
					>
						<Fields
							names={[
								"artworks",
								"artists",
								"galleries",
								"shows",
								"articles",
								"events",
								"prints",
							]}
							component={ItemTables}
							removeItem={removeItem}
						/>
					</div>
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="slug"
								itemType="selection"
								component={TextDisplay}
								isAdmin={true}
								locked={lockSlug}
							/>
						</div>
						<div className="fixed-save-button">
							<button
								className="purple-button--wide"
								type="submit"
								disabled={pristine || submitting}
							>
								Save
							</button>
						</div>
					</div>
				</form>
			);
	}
}

SelectionForm = reduxForm({
	form: "selection",
})(SelectionForm);

export default SelectionForm;
