import i18next           from 'i18next'
import jump              from 'jump.js'

const BLACK_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg=='

// Add method to get nested object
const getObjectProp = function (obj, key) {
	return key.split(".").reduce(function(o, x) {
      return (typeof o == "undefined" || o === null) ? o : o[x];
  }, obj);
}

// Add method to check if object has nested prop
const hasObjectProp = function (obj, key) {
  return key.split(".").every(function(x) {
      if(typeof obj != "object" || obj === null || ! x in obj)
          return false;
      obj = obj[x];
      return true;
  });
}

const arraysEqual = (_arr1, _arr2) => {
	if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
		return false
	}

	const arr1 = _arr1.concat().sort()
	const arr2 = _arr2.concat().sort()
	let areEqual = true

	arr1.forEach((value, index) => {
		if (arr2[index] !== value) {
			areEqual = false
		}
	})

	return areEqual
}

export const isUserArtSociety = (user) => {
	return user && user.role === 'society'
}

export function capitalizeFirstLetter(string) {
  const lowerCaseString = string.toLowerCase()
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
}

// Same function in webbUtil.js in webb project.
export const getFirstImage = (artwork) => {
  const { images } = artwork
  if (images.length === 1) return images[0]
  let firstImage = images[0]
  images.forEach(image => {
    // Must include 0 index.
    if ((image.index || image.index === 0) && ((!firstImage.index && firstImage.index !== 0) || image.index < firstImage.index)) {
      firstImage = image
    }
  })
  return firstImage
}

export const hasAccess = (user, item, type = '') => {
	if (user.role === 'admin') {
		return true
	}

	let itemGallery = item.gallery || item.belongsTo || {}
	if (type === 'artist') itemGallery = item.represented_by && item.represented_by.length > 0 ? (item.represented_by[0].gallery._id || item.represented_by[0].gallery) : {}
	const itemGalleryId = itemGallery._id || itemGallery
	let itemArtist = item.artist || {}
	const itemArtistOrganizer = item.artistOrganizer || {}
	const itemArtists = item.artists || {}
	if (type === 'artwork') itemArtist = item.artistMod || {}
	const itemArtistId = itemArtist._id || itemArtist
	const itemArtistOrganizerId = itemArtistOrganizer._id || itemArtistOrganizer
	let itemArtistIds = []
	if(itemArtists) {
		Object.keys(itemArtists).forEach(artist => {
			itemArtistIds.push(itemArtists[artist]._id)
		});
	}

	const userGallery = user.gallery || {}
	const userGalleryId = userGallery._id || userGallery
	const userArtist = user.artist || {}
	const userArtistId = userArtist._id || userArtist

	if (userGalleryId === itemGalleryId || userArtistId === itemArtistId || (itemArtistIds.includes(userArtistId) && itemArtistOrganizerId) || userArtistId === itemArtistOrganizerId) {
		return true
	} else {
		return false
	}
}

const imageRotationActivated = () => {
	return true
}

const propsIdDiffer = (prevProps, nextProps, key) => {
	const prevId = prevProps && prevProps[key] ? (prevProps[key]._id || prevProps[key]) : 0
	const nextId = nextProps && nextProps[key] ? (nextProps[key]._id || nextProps[key]) : 0
	return prevId !== nextId

}

// Compare arrays of objects.
const propsArrayDiffer = (prevProps, nextProps, key) => {
	const array1 = prevProps && prevProps[key] ? prevProps[key] : []
	const array2 = nextProps && nextProps[key] ? nextProps[key] : []

	if (array1.length !== array2.length) return true

	array1.forEach((item, index) => {
		const id1 = item._id || item
		const item2 = array2[index]
		const id2 = item2._id || item2

		if (id1 !== id2) return true
	})

	return false
}

const propsOjectId = (props, key) => {
	if (props && props[key]) return props[key]._id || props[key]
	return undefined
}

// Function from https://gist.github.com/jonleighton/958841
const base64ArrayBuffer = (arrayBuffer) => {
	var base64    = ''
	var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

	var bytes         = new Uint8Array(arrayBuffer)
	var byteLength    = bytes.byteLength
	var byteRemainder = byteLength % 3
	var mainLength    = byteLength - byteRemainder

	var a, b, c, d
	var chunk

	// Main loop deals with bytes in chunks of 3
	for (var i = 0; i < mainLength; i = i + 3) {
		// Combine the three bytes into a single integer
		chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

		// Use bitmasks to extract 6-bit segments from the triplet
		a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
		b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
		c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
		d = chunk & 63               // 63       = 2^6 - 1

		// Convert the raw binary segments to the appropriate ASCII encoding
		base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
	}

	// Deal with the remaining bytes and padding
	if (byteRemainder == 1) {
		chunk = bytes[mainLength]

		a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

		// Set the 4 least significant bits to zero
		b = (chunk & 3)   << 4 // 3   = 2^2 - 1

		base64 += encodings[a] + encodings[b] + '=='
	} else if (byteRemainder == 2) {
		chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

		a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
		b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4

		// Set the 2 least significant bits to zero
		c = (chunk & 15)    <<  2 // 15    = 2^4 - 1

		base64 += encodings[a] + encodings[b] + encodings[c] + '='
	}

	return base64
}

const createExcerpt = (post, minLength = 200) => {
	if (post.contentBlock && post.contentBlock[0] && post.contentBlock[0].content) {
		let content = post.contentBlock[0].content

		// Remove image caption, <p>Ett gammalt rum!</p>\n surrounded by several blank spaces.
		// Look for 3 or more spaces in front. Can't check for spaces after, they are removed along with \n.
		// Spaces explicitly added in the editor will look like be &nbsp; and placed inside the <p> tags.s
		content = content.replace(/\ {3,}<p>(.*?)<\/p>(\\n)*/g, '')
		const paragraphs = content.match(/<p>(.*?)<\/p>/g)
		let excerpt = ''


		if (paragraphs) {
			paragraphs.forEach((paragraph, index) => {
				if (minLength > 0) {
					// Remove any remaining html tags or newline breaks. Just in case.
					// Zero or one slash (tag start/end). One or more word character.
					const pWithoutTags = paragraph.replace(/(<\/*\w+>|\\n)/g, '')

					if (index > 0) excerpt += '<br /><br />'
					const cutAt = pWithoutTags.indexOf(' ', minLength)
					// cutAt will be -1 if paragraph is shorter than minLength.
					if (cutAt < 0) {
						excerpt += pWithoutTags
					} else {
						excerpt += pWithoutTags.substring(0, cutAt)
						// Remove trailing dot or comma.
						excerpt = excerpt.replace(/(\.|\,)$/, '')
						excerpt += '...'
					}
					minLength -= pWithoutTags.length
				}
			})
		}

		return `
		<row>
			<columns small="12" large="12">
				<p class="serif-text no-margin text-center">${excerpt}</p>
			</columns>
		</row>`
	} else {
		return ''
	}
}

const formatNumber = (number) => {
	const numberString = String(number).replace(
		/^\d+/,
		number => [...number].map(
			(digit, index, digits) => (
					!index || (digits.length - index) % 3 ? '' : ' '
			) + digit
		).join('')
	)
	return numberString
}

const getBaseURL = () => {
	let BASE_URL = process.env.BASE_URL

	if (i18next.language === 'sv') {
		BASE_URL = BASE_URL.replace('artworksapp.com', 'artworksapp.se')
		BASE_URL = BASE_URL.replace('artworks.io', 'artworks.se')
		BASE_URL = BASE_URL.replace('localhost.com', 'localhost.se')
	} else if (i18next.language === 'en') {
		BASE_URL = BASE_URL.replace('artworksapp.se', 'artworksapp.com')
		BASE_URL = BASE_URL.replace('artworks.se', 'artworks.io')
		BASE_URL = BASE_URL.replace('localhost.se', 'localhost.com')
	}

	return BASE_URL
}

// Creates a webblink depending on the environment and object type
const createWebbLink = (slug, type) => {
	const BASE_URL = getBaseURL()

	let webbLink = ''
	if(slug && type) {
		webbLink = `${BASE_URL}${type}/${slug}`
	} else if(slug) {
		webbLink = `${BASE_URL}${slug}`
	}

	return webbLink
}

const createPageWebbLink = (pathName) => {
	const BASE_URL = getBaseURL()
	// If on single artwork page, get link to artwork on webb.
	const re = /\/artworks\/(\d|\w)+/
	const match = re.exec(pathName)
	if (match) {
		return BASE_URL + pathName.replace('/artworks/', 'artworks/')
	} else {
		return false
	}
}

// Formats userName as first name and last name initial
const formatName = (userName) => {
	let name = ''
	if(userName.indexOf(' ') > 0) {
		name = userName.split(/\s/).shift() + ' ' + userName.split(/\s/).pop().charAt(0)
	} else {
		name = userName
	}
	return name
}

const filterArtByArtist = (artistId, artworks) => {
	// Artists should only be able to edit art uploaded by themselves
	// artistUser value added in the API to the artwork when fetching, it is the User connected to the Artist.
	// artistUser is an array, in case admin set themselves to the artist, or all artworks could be uneditable for the artist.
	const createdByArtist = []
	Object.keys(artworks).forEach(key => {
		const artwork = artworks[key]

		if (artistId) {
			let belongsToArtist = false
			// Newly uploaded have no artistUser set.
			if (!artwork.artistUser) belongsToArtist = true
			// If the User who created the artwork has been removed.
			if (!artwork.created_by) belongsToArtist = true
			// If the artwork was created by the artist herself.
			if (artwork.created_by && artwork.artistUser && artwork.artistUser.indexOf(artwork.created_by._id) >= 0) belongsToArtist = true

			if (belongsToArtist) {
				createdByArtist.push(artwork)
			} else {
				// other.push(artwork)
			}
		}

	})
	// console.log('createdByArtist', createdByArtist);

	return createdByArtist
}

const thumbnailError = (e, failedSource, fullSrc) => {
	// Must get failedSource from e.target.src inside img element.
	// If full source has failed, don't set as src, it will loop.
	if (failedSource !== fullSrc && fullSrc) {
		e.target.src = fullSrc
	} else if (__DEVELOPMENT__) {
		// Make it easier to work with locally if no internet connection.
		e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg=='
	}
}

const unresolvedTransactions = (transactions) => {
	let count = 0
	transactions.forEach(transaction => {
		if(!transaction.createdInvoice) {
			count ++
		}
	});

	return count
}

const sortByMemberCount = (a, b) => {
  if (a.members > b.members) return -1
  if (a.members < b.members) return 1
  return 0
}

const getMembersRanking = (selection) => {
  const about = selection ? selection.about : ''

  const societies = !about ? [] : about.split('\n').map(data => {
    const society = data.split('=')
    if (society.length > 1) {
      return {
        name: society[0].trim(),
        members: parseInt(society[1].trim()),
      }
    }
  })
  societies.sort(sortByMemberCount)

	return societies
}

const HASH_TO_ID = {
	'#latestsales': 'latest-sales'
}

const scrollToSection = (hash) => {
	const isHash = hash.indexOf('#') === 0
	const elementId = isHash ? HASH_TO_ID[hash] : hash
	const node = document.getElementById(elementId)
	if (!node) return
	const scrollNode = node.nodeName ? node : ReactDOM.findDOMNode(node)
	jump(scrollNode, {
		offset: -100,
	})
}

export {
	getObjectProp, hasObjectProp, arraysEqual, base64ArrayBuffer, imageRotationActivated,
	createExcerpt, propsIdDiffer, propsArrayDiffer, propsOjectId, formatNumber,
	createWebbLink, createPageWebbLink, formatName, filterArtByArtist, thumbnailError,
	unresolvedTransactions, getMembersRanking, getBaseURL, scrollToSection,
	BLACK_IMAGE,
}
