import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import {
	createExcerpt,
	createWebbLink,
	formatName,
} from "../../../utils/Helpers";
import { fetchArtworksWithQuery } from "../../../actions/ArtworkActions";
import { fetchUser } from "../../../actions/UserActions";
import { likeObject } from "@artworkslab/sharedmodules/src/actions/UserActions";
import { randomizeContent } from "@artworkslab/sharedmodules/src/utils/Helpers";
import CurrencyUtil from "@artworkslab/sharedmodules/src/CurrencyUtil";

const PROFILE = require("../../../../assets/images/navbar_profile_60px.png");
const ARROW_RIGHT = require("../../../../assets/images/svg/arrow_right.svg");

class ArtistNewsFeed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			randomArtist: [],
			randomArticles: [],
			followedArtists: [],
		};
	}

	componentWillMount() {
		const { activeUser, authUser, selection } = this.props;

		if (!activeUser._id || activeUser._id !== authUser.id) {
			this.props.dispatch(fetchUser(authUser.id));
		}
		//Fetching artworks here causes them to be displayed in the artist /artworks-page
		// if(activeUser._id) {
		//   this.getArt(activeUser)
		// }
		if (selection._id && activeUser._id) {
			this.initializeLists(selection, activeUser);
		}
	}

	componentWillReceiveProps(nextProps) {
		//Fetching artworks here causes them to be displayed in the artist /artworks-page
		// if(this.props.activeUser._id !== nextProps.activeUser._id && nextProps.activeUser._id) {
		//   this.getArt(nextProps.activeUser)
		// }

		if (
			(this.props.selection._id !== nextProps.selection._id &&
				nextProps.selection._id &&
				nextProps.activeUser._id) ||
			(this.props.activeUser._id !== nextProps.activeUser._id &&
				nextProps.activeUser._id &&
				nextProps.selection._id)
		) {
			this.initializeLists(nextProps.selection, nextProps.activeUser);
		}
	}

	/*
	 * Fetching artworks here causes them to be displayed in the artist /artworks-page due to
	 * them being stored in the reducer. This section is currently hidden in the newsfeed but
	 * if it is reinstated, one must assure that this error does not occur again.
	 */
	//Retrieves art based on which artists the user follows
	// getArt = (user) => {
	//   const date = moment().subtract(7, 'day').toISOString()
	//
	//   let liked = []
	//   if(user.liked_artists.length > 0) {
	//     user.liked_artists.forEach(artist => {
	//       if(artist && (!user.artist || artist._id !== user.artist._id)) {
	//         liked.push(artist._id)
	//       }
	//     });
	//   }
	//   // Using $and might fetch all or time out.
	//   // const artQuery = {
	//   //   $and: [
	//   //     { artistMod: { $in: liked }},
	//   //     { updatedAt: { $gte: date }},
	//   //     { publish_status : 'published'}
	//   //   ]
	//   // }
	//   const artQuery = {
	//     artistMod: { $in: liked },
	//     updatedAt: { $gte: date },
	//     publish_status : 'published',
	//   }
	//   if (liked.length > 0) {
	//     this.props.dispatch( fetchArtworksWithQuery(artQuery) )
	//   }
	// }

	//Initializes the local randomized lists
	initializeLists = (selection, activeUser) => {
		const followed = this.initializeFollowed(selection.artists, activeUser);
		this.setState({
			randomArtist: randomizeContent(selection.artists, "artists"),
			randomArticles: randomizeContent(selection.articles, "articles"),
			followedArtists: followed,
		});
	};

	//Initializes the local list of followed artists
	initializeFollowed = (artists, activeUser) => {
		let followed = [];
		artists.forEach((artist) => {
			activeUser.liked_artists.forEach((likedArtist) => {
				if (likedArtist._id === artist._id) {
					followed.push(artist._id);
				}
			});
		});
		return followed;
	};

	//Draws the promo section
	drawPromo = (items, t) => {
		let promos = [];
		items.forEach((item, i) => {
			const image = item.featuredImage ? item.featuredImage.src : null;
			const excerpt = createExcerpt(item, 80);
			const markup = excerpt ? this.createMarkup(excerpt) : null;
			const webbLink = createWebbLink(item.slug, "articles");

			promos.push(
				<div
					key={item._id}
					className={
						"promo" + (i === 0 ? " is-first" : "") + (i === 3 ? " is-last" : "")
					}
				>
					{item && (
						<div
							className="promo__image"
							style={{ backgroundImage: `url(${image})` }}
						/>
					)}
					<div className="promo__preview">
						<h2>{item.title}</h2>
						<div className="promo__preview__text">
							<p dangerouslySetInnerHTML={markup} />
						</div>
						<a className="read-more" href={webbLink} target="_blank">
							{t("promo.readMore")}
							<ARROW_RIGHT
								height="15"
								width="15"
								x="15px"
								alt="arrow"
								className="arrow"
							/>
						</a>
					</div>
				</div>
			);
		});

		return (
			<div className="section">
				<h1>{t("promo.title")}</h1>
				<div className="section__container">{promos}</div>
			</div>
		);
	};

	//Create a markup html
	createMarkup = (html) => {
		return { __html: html };
	};

	//Draws the featured artist-section
	drawArtists = (artists, t) => {
		const { followedArtists } = this.state;
		let rows = [];
		const link = "https://www.artworks.se/konstnarer";
		artists.forEach((artist, i) => {
			const image = artist.featuredImage ? artist.featuredImage.src : PROFILE;
			const name = artist.name ? formatName(artist.name) : t("artist.unknown");
			const isFollowing = this.checkFollowing(artist._id);
			const artistLink = createWebbLink(artist.slug, "artists");
			const animate = isFollowing && !followedArtists.includes(artist._id);

			if (rows.length < 12 && !followedArtists.includes(artist._id)) {
				rows.push(
					<div key={i} className={"artist" + (animate ? " animate" : "")}>
						<a
							href={artistLink}
							target="_blank"
							className="artist__image"
							style={{ backgroundImage: `url(${image})` }}
						/>
						<div className="artist__details">
							<h2>{name}</h2>
							<button
								className="follow-button"
								onClick={() => this.toggleFollow(artist._id, isFollowing)}
							>
								{isFollowing ? t("artist.unfollow") : t("artist.follow")}
							</button>
						</div>
					</div>
				);
			}
		});

		return (
			<div className="section">
				<h1>{t("artist.title")}</h1>
				<div className="section__container">{rows}</div>
				<a
					className="read-more"
					href={link}
					target="_blank"
					style={{ marginBottom: "20px" }}
				>
					{t("artist.discover")}
					<ARROW_RIGHT
						height="15"
						width="15"
						x="15px"
						alt="arrow"
						className="arrow"
					/>
				</a>
			</div>
		);
	};

	//Returns boolean whether user is following artist or not
	checkFollowing = (artistId) => {
		const { activeUser } = this.props;

		let following = false;

		if (activeUser.liked_artists) {
			activeUser.liked_artists.forEach((artist) => {
				if (artist && artist._id === artistId) {
					following = true;
				}
			});
		}

		return following;
	};

	//Toggles follow for an artist
	toggleFollow = (artistId, isFollowing) => {
		const type = isFollowing ? "unlike" : "like";
		this.props.dispatch(likeObject(type, artistId, "artist"));

		setTimeout(() => {
			let followed = this.state.followedArtists;
			if (type === "like" && !followed.includes(artistId)) {
				followed.push(artistId);
			} else if (type === "unlike" && followed.includes(artistId)) {
				const index = followed.indexOf(artistId);
				followed.splice(index, 1);
			}
			this.setState({
				followedArtists: followed,
			});
		}, 1000);
	};

	//Returns a list of artist names based on given list of artworks
	getArtists = (artworks, t) => {
		let artists = [];
		Object.keys(artworks).forEach((key, i) => {
			const artwork = artworks[key];
			const artistName =
				artwork.artistMod && artwork.artistMod.name
					? artwork.artistMod.name
					: t("artist.unknown");
			if (!artists.includes(artistName)) {
				artists.push(artistName);
			}
		});
		return artists;
	};

	//Draws given artworks
	drawArtMasonry = (artworks, t) => {
		let items = [];
		Object.keys(artworks).forEach((key, i) => {
			const artwork = artworks[key];
			const image = artwork.images[0];
			const thumbnail = image.thumbnails
				? image.thumbnails["500x"].src
				: image.src;
			const artistSlug =
				artwork.artistMod && artwork.artistMod.slug
					? artwork.artistMod.slug
					: "";
			const fullOnArtworks = items.length === 3;
			const webbLink =
				fullOnArtworks && artistSlug
					? createWebbLink(artistSlug, "artists")
					: createWebbLink(artwork.slug, "artworks");
			const isFirst = key === "0";
			if (items.length < 4) {
				items.push(
					<div
						key={i}
						className={
							"art-header__content__artwork" + (isFirst ? " is-first" : "")
						}
					>
						<a href={webbLink} target="_blank">
							{!fullOnArtworks ? (
								<img
									src={thumbnail}
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
								/>
							) : (
								<div className="more-art">
									{t("artwork.link")}
									<ARROW_RIGHT
										height="15"
										width="15"
										x="15px"
										alt="arrow"
										className="arrow"
									/>
								</div>
							)}
						</a>
					</div>
				);
			}
		});
		return items;
	};

	//Draws the newly published artworks-section
	drawArt = (artworks, t) => {
		let rows = [];
		const artists = this.getArtists(artworks, t);
		artists.forEach((artist, i) => {
			const artistArt = [];
			Object.keys(artworks).forEach((key) => {
				const art = artworks[key];
				const artistName =
					art.artistMod && art.artistMod.name
						? art.artistMod.name
						: t("artist.unknown");
				if (artistName === artist) {
					artistArt.push(art);
				}
			});
			if (artistArt.length > 0) {
				const artItems = this.drawArtMasonry(artistArt, t);
				rows.push(
					<div key={i} className="art-header">
						<h1>{artist}</h1>
						<div key={i} className="art-header__content">
							{artItems}
						</div>
					</div>
				);
			}
		});

		return (
			<div className="section">
				<h1>{t("artwork.title")}</h1>
				<div className="section__container">{rows}</div>
			</div>
		);
	};

	thumbnailError = (e, failedSrc, fullSrc) => {
		if (failedSrc !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	render() {
		const { activeUser, artist, artworks, selection, t } = this.props;
		const { randomArtist, randomArticles, followedArtists } = this.state;

		let promoContent = null;
		let artistContent = null;
		let artworkContent = null;

		// if(Object.keys(selection).length > 0 && selection.articles.length > 0) {
		//   promoContent = this.drawPromo(randomArticles, t)
		// }
		if (Object.keys(selection).length > 0 && selection.artists.length > 0) {
			artistContent = this.drawArtists(randomArtist, t);
		}
		// if(Object.keys(artworks).length > 0) {
		//   artworkContent = this.drawArt(artworks, t)
		// }

		return (
			<div className="NewsFeed">
				<div className="content">{artistContent}</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		user: { activeUser },
		auth: { user: authUser },
		artist: { artist },
		artwork: { artworks: artworks },
	} = state;

	return { activeUser, authUser, artist, artworks };
}

const translated = withNamespaces("newsfeed")(ArtistNewsFeed);
export default connect(mapStateToProps)(translated);
