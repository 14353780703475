import React from "react";
import ColorPickr from "@mapbox/react-colorpickr";

class ColorPicker extends React.Component {
	state = {
		showPicker: false,
	};

	handleClick = () => {
		this.setState({
			showPicker: !this.state.showPicker,
		});
	};

	handleChange = (color) => {
		const newColor = { r: color.r, g: color.g, b: color.b };
		this.props.input.onChange(newColor);
		this.props.input.onBlur(newColor);
	};

	rgbaToString = (color) => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
	};

	render() {
		const color = this.rgbaToString(this.props.input.value);
		const { showPicker } = this.state;
		const children = this.props.children || null;
		return (
			<div style={styles.container}>
				<div style={styles.pusher}></div>
				<div
					style={Object.assign({}, styles.swatch, { position: "relative" })}
					onClick={this.handleClick}
				>
					<div
						style={{
							background: color,
							width: 50,
							height: 14,
							borderRadius: 2,
						}}
					/>
					{showPicker ? (
						<div style={styles.popover}>
							<div style={styles.cover} onClick={this.handleClick}></div>
							<ColorPickr value={color} onChange={this.handleChange} />
						</div>
					) : null}
					{children}
				</div>
			</div>
		);
	}
}

const styles = {
	swatch: {
		padding: "5px",
		background: "#fff",
		borderRadius: "1px",
		boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
		display: "inline-block",
		cursor: "pointer",
		marginLeft: "40px",
	},
	popover: {
		position: "absolute",
		zIndex: "2",
	},
	cover: {
		position: "fixed",
		top: "0",
		right: "0",
		bottom: "0",
		left: "0",
	},
	container: {
		display: "flex",
		flex: "1 0 auto",
	},
	pusher: {
		width: "50%",
	},
};

export default ColorPicker;
