import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	reduxForm,
	formValueSelector,
} from "redux-form";
import TextInput from "../../components/FormInput/TextInput";
import TextArea from "../../components/FormInput/TextArea";
import TextDisplay from "../../components/FormInput/TextDisplay";
import Select from "../../components/FormInput/Select";
import LogoUpload from "../../components/FormInput/LogoUpload";
import ImageUploadCustomName from "../../components/FormInput/ImageUploadCustomName";
import CheckBox from "../../components/FormInput/CheckBox";
import ArtworkUpload from "../../components/FormComponents/ArtworkUpload/ArtworkUpload";
import FormEditorCustomName from "../../components/FormComponents/FormEditor/FormEditorCustomName";
import InfluencerPicker from "../../components/FormComponents/InfluencerPicker/InfluencerPicker";
import CategoryPicker from "../../components/FormComponents/CategoryPicker/CategoryPicker";
import ImagePicker from "../../components/FormComponents/ImagePicker/ImagePicker";
import { CENTER_IMAGE_OPTIONS } from "../../utils/values";

const PUBLISH_STATUS = [
	{ value: "draft", name: "Draft" },
	{ value: "published", name: "Published" },
];

class PrintForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			artistImageOptions: [],
			editorChanged: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const { printArtists: prevArtists } = this.props;
		const { printArtists: nextArtists } = nextProps;

		// For copying artist image from a print.
		if (Object.keys(prevArtists).length !== Object.keys(nextArtists).length) {
			const artists = Object.keys(nextArtists)
				.map((key) => nextArtists[key])
				.sort(this.sortByName);
			const options = [];
			artists.forEach((artist) => {
				options.push({
					label: artist.artistName,
					value: artist.artistImage,
				});
			});

			this.setState({
				artistImageOptions: options.sort(this.sortByLabel),
			});
		}
	}

	sortByLabel = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	updateChild = () => {
		this.editorArtist.updateContentFromParent();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			artworkId,
			currency,
			price,
			vatCode,
			t,
			printArtists,
		} = this.props;
		const { artistImageOptions, selectedArtist } = this.state;

		return (
			<form onSubmit={handleSubmit} className="Form">
				<div className="fieldset-block fieldset-block--nopadding">
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="title"
								component={TextArea}
								placeholder={"Title"}
								required
							/>
						</div>
						<div className="input-holder">
							<Field
								name="year"
								component={TextInput}
								placeholder={t("single.year")}
							/>
							<Field
								name="edition"
								component={TextInput}
								placeholder={t("single.editionOrInfinite")}
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="stripePriceId"
								component={TextInput}
								placeholder={"Stripe price Id"}
							/>
						</div>
					</div>

					<div className="fieldset">
						<div className="input-holder input-holder--full">
							<Field
								name="about"
								component={TextArea}
								placeholder={t("single.aboutTheArtwork")}
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="publish_status"
								component={Select}
								options={PUBLISH_STATUS}
							/>
						</div>
					</div>

					<div className="fieldset">
						<legend>{t("single.dimensionsAndMaterials")}</legend>
						<div className="input-holder">
							<Field
								name="height"
								component={TextInput}
								placeholder={t("single.height")}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="width"
								component={TextInput}
								placeholder={t("single.width")}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="depth"
								component={TextInput}
								placeholder={t("single.depth")}
							/>
						</div>
						<Field
							name="styleMod"
							type="style"
							component={CategoryPicker}
							placeholder={t("single.selectStyle")}
						/>
						<Field
							name="mediumMod"
							type="medium"
							component={CategoryPicker}
							placeholder={t("single.selectMedium")}
						/>
						<Field
							name="techniqueMod"
							type="technique"
							component={CategoryPicker}
							placeholder={t("single.selectTechnique")}
						/>
						<Field
							name="colorMod"
							type="color"
							component={CategoryPicker}
							placeholder={t("single.selectColor")}
						/>
						<div className="input-holder">
							<Field
								name="paper"
								component={TextInput}
								placeholder={t("single.paper")}
							/>
						</div>
					</div>

					<legend>{t("print.price")}</legend>
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="priceSEKincVAT"
								component={TextInput}
								placeholder={"Price"}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="signedByArtist"
								component={CheckBox}
								checkboxLabel={"Signed by the artist"}
							/>
						</div>
					</div>
					{/*<div className="fieldset">
    				<Fields
              names={["priceCurrency", "price", "status", "VATcode"]}
              component={ PricePicker }
              selectedCurrency={ currency }
              selectedPrice={ price }
              selectedVATCode={ vatCode }
              isSwedish={ true }
            />
          </div>*/}

					<div className="fieldset">
						<legend>{t("single.detailsUpload")}</legend>
						<Fields
							names={[
								"images",
								"uploads",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={ArtworkUpload}
							multi={true}
							sortable={true}
							useLargeImageLimit={true}
							borderToggleOn={true}
							required
						/>
					</div>

					{/* Inlfuencer start */}
					<legend>{"Influencer, choose in dropdown or fill in fields"}</legend>
					<div className="fieldset">
						<Field
							name="influencerMod"
							component={InfluencerPicker}
							category={["influencer"]}
							multi={false}
							canAdd={true}
							disabled={false}
						/>
						<div className="input-holder">
							<Field
								name="influencerName"
								component={TextArea}
								placeholder={"Influencer name"}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="influencerLink"
								component={TextInput}
								placeholder={"Link to influencers page, ex. Instagram"}
							/>
							<Field
								name="influencerLinkTitle"
								component={TextInput}
								placeholder={'Name of link, ex. "Till Instagram"'}
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="influencerHeader"
								component={TextArea}
								placeholder={"Influencer header"}
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="influencerAbout"
								component={TextArea}
								placeholder={"Influencer about"}
							/>
						</div>
						{/* imageName must be the same as in x.src. uploadedName same as in x.URI etc. Use the uploadedName in the controller etc. */}
						<Fields
							names={[
								"influencerImage.src",
								"uploadedInfluencerImage.URI",
								"uploadedInfluencerImage.fileName",
								"uploadedInfluencerImage.contentType",
							]}
							component={ImageUploadCustomName}
							imageName={"influencerImage"}
							uploadedName={"uploadedInfluencerImage"}
							maxSize={20000000}
						/>
					</div>
					{/* Inlfuencer end */}

					{/* Artist start */}
					<legend>{"Artist"}</legend>
					<div className="fieldset">
						<Field
							name="printArtistMod"
							component={InfluencerPicker}
							category={["artist", "artportable"]}
							multi={false}
							canAdd={true}
							disabled={false}
							noFetch={true}
						/>
						<div className="input-holder">
							<Field
								name="artistName"
								component={TextArea}
								placeholder={"Artist name"}
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="artistHeader"
								component={TextArea}
								placeholder={"Artist header for the artwork"}
							/>
						</div>
						<div className="input-holder input-holder--full">
							<Field
								name="artistAbout"
								component={TextArea}
								placeholder={"Artist describes the artwork"}
							/>
						</div>
						<legend>{"Use instead of above field if links are needed"}</legend>
						<div className="input-holder input-holder--full">
							<Fields
								names={["draftContentStateArtist", "htmlContentArtist"]}
								component={FormEditorCustomName}
								onRef={(ref) => (this.editorArtist = ref)}
								onEditorChange={this.editorChange}
								draftName="draftContentStateArtist"
								htmlName="htmlContentArtist"
							/>
						</div>
						<legend>{"Webb URL used to avoid duplicate artists"}</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="artistWebbURL"
								component={TextInput}
								placeholder={"Artist webb url"}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="artistImage"
								component={ImagePicker}
								options={artistImageOptions}
								placeholder="Import artist image"
								multi={false}
								canAdd={false}
								disabled={false}
							/>
						</div>
						{/* imageName must be the same as in x.src. uploadedName same as in x.URI etc. Use the uploadedName in the controller etc. */}
						<Fields
							names={[
								"artistImage.src",
								"uploadedArtistImage.URI",
								"uploadedArtistImage.fileName",
								"uploadedArtistImage.contentType",
							]}
							component={ImageUploadCustomName}
							imageName={"artistImage"}
							uploadedName={"uploadedArtistImage"}
							maxSize={20000000}
						/>
						<div className="input-holder">
							<legend className="legend-nomargin">Image center position</legend>
							<Field
								name="artistImage.center"
								options={CENTER_IMAGE_OPTIONS}
								component={Select}
							/>
						</div>
					</div>
					{/* Artist end */}

					<div className="fieldset">
						<legend>Extra</legend>
						<div className="input-holder">
							<Field
								name="publish_status"
								component={Select}
								options={PUBLISH_STATUS}
							/>
						</div>
						{artworkId && (
							<div className="input-holder">
								<Field
									name="slug"
									itemType="print"
									component={TextDisplay}
									isAdmin={true}
								/>
							</div>
						)}
					</div>

					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							onClick={this.updateChild}
							disabled={(pristine || submitting) && !this.state.editorChanged}
						>
							{"Save"}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

PrintForm = reduxForm({
	form: "print",
})(PrintForm);

const selector = formValueSelector("print");
PrintForm = connect((state) => {
	const artworkId = selector(state, "_id");
	// const currency = selector(state, 'priceCurrency')
	// const price = selector(state, 'price')
	// const vatCode = selector(state, 'VATcode')

	return {
		artworkId,
		// currency, price, vatCode,
	};
})(PrintForm);

export default PrintForm;
