import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { withNamespaces } from "react-i18next";

class SingleDateTimePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dateTime: moment(),
		};
	}

	componentDidMount() {
		if (this.props.input.value) {
			this.setStartDateTime(this.props.input.value);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.input.value && nextProps.input.value) {
			this.setStartDateTime(nextProps.input.value);
		}
	}

	setStartDateTime = (value) => {
		this.setState({
			dateTime: moment(value),
		});
	};

	handleChangeDate = (theDate) => {
		// Convert native Date to Moment.js
		const momentDate = moment(theDate);

		const clone = this.state.dateTime.clone();
		clone.set("year", momentDate.year());
		clone.set("month", momentDate.month());
		clone.set("date", momentDate.date());
		this.update(clone);
	};

	handleChangeTime = (theTime) => {
		const clone = this.state.dateTime.clone();
		clone.set("hour", theTime.hour());
		clone.set("minute", theTime.minute());
		this.update(clone);
	};

	update = (dateTime) => {
		this.setState(
			{
				dateTime,
			},
			() => {
				this.props.input.onChange(dateTime);
				this.props.input.onBlur(dateTime);
			}
		);
	};

	render() {
		const { dateTime } = this.state;
		const { disabled, t } = this.props;

		return (
			<div className="DateRangePicker">
				<div className="input-holder input-holder--nopadding">
					<div
						className={
							"DateRangePicker__input" + (disabled ? " is-disabled" : "")
						}
						style={{ paddingRight: "40px" }}
					>
						<DatePicker
							placeholderText={t("date")}
							dateFormat="yyyy-MM-dd"
							selected={dateTime ? dateTime.toDate() : null}  // Ensure it's a Date
							onChange={this.handleChangeDate}
							disabled={disabled}
						/>
					</div>
					<div
						className={
							"DateRangePicker__input" + (disabled ? " is-disabled" : "")
						}
						style={{ paddingRight: "40px" }}
					>
						<TimePicker
							value={dateTime}
							showSecond={false}
							onChange={this.handleChangeTime}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withNamespaces("common")(SingleDateTimePicker);
