import React        from 'react'
import { Link }     from 'react-router'
import { connect }  from 'react-redux'
import Main         from '../../components/Main/Main'

const SubscriptionsMain = (props) => {
  return (
    <Main>
      <div style={{
        display: 'flex',
        flexFlow: 'column nowrap',
      }}>
        <Link to="/klarna-subscriptions"><u>Klarna</u></Link>
        <Link to="/stripe-subscriptions"><u>Stripe</u></Link>
        <Link to="/cancelled-subscriptions"><u>Cancelled/Ended</u></Link>
      </div>
    </Main>
  )
}

export default SubscriptionsMain
