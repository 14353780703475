import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	fetchScrapedArtists,
	rejectScrapedArtist,
} from "../../actions/ScrapedActions";
import { getPendingArtists } from "../../selectors/scrapedSelectors";
import Main from "../../components/Main/Main";

const ScrapedArtist = ({ artist, rejectArtist }) => {
	return (
		<div style={{ paddingBottom: "20px" }}>
			<h4>{"Artist data"}</h4>
			<p>{artist.name}</p>
			<p>{artist.email}</p>
			<p>{artist.webSite}</p>
			<p>
				<b>Artist data</b>
			</p>
			{Object.keys(artist.artistData || {}).map((key) => {
				const data = artist.artistData[key];
				return <p key={key}>{`${data}`}</p>;
			})}
			<Link
				className="button"
				style={{ margin: "10px", marginLeft: 0 }}
				to={"/scraped/artists/" + artist._id}
			>
				Add
			</Link>
			<button
				style={{ margin: "10px", borderRadius: 0 }}
				className="button"
				onClick={() => {
					rejectArtist(artist);
				}}
			>
				Reject
			</button>
			<a
				style={{ margin: "10px", marginRight: 0 }}
				className="button"
				href={`http://www.google.com/search?q=${artist.name}`}
				target="_blank"
				rel="noopener"
			>
				Search
			</a>
		</div>
	);
};

class ScrapedArtists extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		this.props.dispatch(fetchScrapedArtists());
	}

	rejectArtist = (artist) => {
		this.props.dispatch(rejectScrapedArtist(artist._id));
	};

	render() {
		let artistsCount = Object.keys(this.props.scrapedArtists).length;
		const { scrapedArtists } = this.props;
		const admin = this.props.user.role === "admin";
		if (!admin) return null;
		return (
			<div className="Artists">
				<Main>
					<div className="MainTop">
						<h2>New artists ({artistsCount})</h2>
					</div>
					{Object.keys(scrapedArtists).map((key) => {
						const artist = scrapedArtists[key];
						return (
							<ScrapedArtist
								key={key}
								artist={artist}
								rejectArtist={this.rejectArtist}
							/>
						);
					})}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
	} = state;
	const scrapedArtists = getPendingArtists(state);
	return { scrapedArtists, user, isAdmin };
}

export default connect(mapStateToProps)(ScrapedArtists);
