import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Table, Thead, Th, Tr, Td } from "reactable";
import CheckBox from "../../FormInput/CheckBox";
import { getFirstImage } from "../../../utils/Helpers";
import { showPremiumUpgradeModal } from "../../../utils/Dispatcher";
import ExpandButton from "../../DashboardComponents/ExpandButton/ExpandButton";

class Vote extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	componentWillMount() {
		const voters = this.props.vote.input.value.voters;
		// Voters can be undefined.
		if (this.props.wishList && voters && voters.length > 0)
			this.setState({ expanded: true });
	}

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		} else if (__DEVELOPMENT__) {
			// Make it easier to work with locally if no internet connection.
			e.target.src =
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==";
		}
	};

	sortVotes = (a, b) => {
		if (a.voteCount < b.voteCount) return 1;
		if (a.voteCount > b.voteCount) return -1;
		return 0;
	};

	artworkVoteList = (artworks, t) => {
		const voters = this.props.vote.input.value.voters || [];

		const artAndVotes = {};
		artworks.forEach((artwork) => {
			artAndVotes[artwork._id] = {
				artwork,
				voteCount: 0,
			};
		});

		voters.forEach((voter) => {
			voter.artworks.forEach((artworkId) => {
				// voters are loaded before artworks
				if (artAndVotes[artworkId]) {
					artAndVotes[artworkId].voteCount =
						artAndVotes[artworkId].voteCount + 1;
				}
			});
		});

		// Put items in an array so we can sort them by number of votes.
		const artAndVotesArray = Object.keys(artAndVotes).map(
			(key) => artAndVotes[key]
		);
		artAndVotesArray.sort(this.sortVotes);

		return (
			<div className="vote-component__content__list">
				{artAndVotesArray.map((artAndVote) => {
					const artwork = artAndVote.artwork;
					const image = getFirstImage(artwork);
					// Artworks added in development, artworks-dev-images, has no thumbnails
					const source =
						image.thumbnails && image.src.indexOf("artworks-dev-images") < 0
							? image.thumbnails["100x100"].src
							: image.src;

					return (
						<div className="vote-item" key={artwork._id}>
							<Link to={`/artworks/${artwork.slug}`}>
								<img
									src={source}
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
								/>
							</Link>
							<p>{`${artAndVote.voteCount} ${
								artAndVote.voteCount === 1 ? t("vote.vote") : t("vote.votes")
							}`}</p>
						</div>
					);
				})}
			</div>
		);
	};

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	listVoters = (voters, t) => {
		return (
			<div className="voters">
				<div className="voters__list">
					<h4 style={{ marginRight: "50px" }}>{t("common:name")}</h4>
					{voters.map((voter, index) => (
						<p key={index}>{voter.name}</p>
					))}
				</div>
				<div className="voters__list">
					<h4>{t("common:email")}</h4>
					{voters.map((voter, index) => (
						<p key={index}>{voter.email}</p>
					))}
				</div>
			</div>
		);
	};

	listVotersWithArtworks = (voters, allArtworks, t) => {
		return (
			<Table className="reactable" sortable={["name", "email"]}>
				<Thead>
					<Th column="name">{t("common:name")}</Th>
					<Th column="email">{t("common:email")}</Th>
					<Th column="art">{` `}</Th>
				</Thead>
				{voters.map((voter, index) => {
					return (
						<Tr key={index}>
							<Td column="name" value={voter.name}>
								{voter.name}
							</Td>
							<Td column="email" value={voter.email}>
								{voter.email}
							</Td>
							<td column="art">
								<div className="voter-list__art-list">
									{voter.artworks.map((artworkId, index) => {
										const artwork = allArtworks[artworkId];
										// allArtworks is be {} before loaded.
										if (!artwork) return null;
										const image = artwork.images[0];
										// Artworks added in development, artworks-dev-images, has no thumbnails
										const source =
											image.thumbnails &&
											image.src.indexOf("artworks-dev-images") < 0
												? image.thumbnails["100x100"].src
												: image.src;

										return (
											<div
												className="voter-list__art-list__piece"
												key={artworkId}
											>
												<img
													src={source}
													onError={(e) =>
														this.thumbnailError(e, e.target.src, image.src)
													}
												/>
												<div>{index + 1}</div>
											</div>
										);
									})}
								</div>
							</td>
						</Tr>
					);
				})}
			</Table>
		);
	};

	render() {
		const {
			votingIsEnabled,
			artworks,
			artworksForEvent = [],
			isAdmin,
			wishList,
			standardOrAbove,
			t,
		} = this.props;
		const { expanded } = this.state;

		// artworks = artworks belonging to this gallery
		// artworksForEvent = list of artwork ids belonging to this event
		const art = [];
		artworksForEvent.forEach((id) => {
			if (artworks[id]) art.push(artworks[id]);
		});
		const votes = this.artworkVoteList(art, t);

		const voters = this.props.vote.input.value.voters || [];
		const voteCount = voters.length;
		const voterEmails = wishList
			? this.listVotersWithArtworks(voters, artworks, t)
			: this.listVoters(voters, t);

		if (!wishList) {
			return (
				<div className="vote-component">
					{standardOrAbove && !isAdmin && (
						<CheckBox
							{...this.props.voteEnabled}
							checkboxLabel={t("vote.votingEnabled")}
						/>
					)}
					{isAdmin && (
						<CheckBox
							{...this.props.voteEnabled}
							checkboxLabel={t("vote.votingEnabled") + " (Admin only)"}
						/>
					)}
					{!standardOrAbove && !isAdmin && (
						<div
							style={{ padding: "0 0 30px 3px" }}
							onClick={() =>
								showPremiumUpgradeModal(this.props.dispatch, "Premium")
							}
						>
							<input
								type="checkbox"
								id="no-vote"
								checked={votingIsEnabled}
								style={{ pointerEvents: "none" }}
								disabled
							/>
							<label
								htmlFor="no-vote"
								style={{ color: "#a8a8a8", paddingLeft: "6px" }}
							>
								{t("vote.votingEnabled")}
							</label>
						</div>
					)}
					{votingIsEnabled && (
						<div className="vote-component__content">
							<input {...this.props.vote.voteLimit.input} id="voteLimit" />
							<label htmlFor="voteLimit">{t("vote.maxVotesPerMember")}</label>
							{art.length === 0 && <p>{t("vote.noArtSelected")}</p>}
							{votes}
						</div>
					)}
					{votingIsEnabled && (
						<div style={{ marginBottom: "50px" }}>
							<legend
								className="legend-nomargin"
								style={{ position: "relative", marginTop: "30px" }}
							>
								{`${t("vote.whoVoted")} (${voteCount} ${t("vote.people")})`}
								<ExpandButton expanded={expanded} click={this.toggleExpanded} />
							</legend>
							{expanded && <div>{voterEmails}</div>}
						</div>
					)}
				</div>
			);
		} else {
			return (
				<div className="vote-component">
					<div className="vote-component__content">
						<input {...this.props.vote.voteLimit.input} id="voteLimit" />
						<label htmlFor="voteLimit">{t("vote.maxVotesPerMember")}</label>
					</div>
					<legend style={{ position: "relative", marginTop: "30px" }}>
						{`${t("vote.whoVoted")} (${voteCount} ${t("vote.people")})`}
						<ExpandButton expanded={expanded} click={this.toggleExpanded} />
					</legend>
					{expanded && <div className="voter-list">{voterEmails}</div>}
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const {
		artwork: { artworks },
		auth: { isAdmin },
	} = state;
	return { artworks, isAdmin };
};

const translated = withNamespaces("event")(Vote);
export default connect(mapStateToProps)(translated);
