import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Field }            from 'redux-form'
import { withNamespaces }		from 'react-i18next'
import Select               from '../../FormInput/Select'
import TextInput            from '../../FormInput/TextInput'
import Tooltip              from '../../Tooltip/Tooltip'

const RELATED_CATEGORIES = [
  {value:'Read more', name: 'Read more'},
  {value:'Watch this', name: 'Watch this'},
  {value:'Show', name: 'Show'},
  {value:'Seminar', name: 'Seminar'},
  {value:'Event', name: 'Event'},
  {value:'Public art', name: 'Public art'},
	{value:'Award', name: 'Award'},
]

class CreateRelated extends Component {
  render() {
    const { handleSubmit, submitting, responsive = false, t } = this.props
    // Get artist/gallery, for ArtistForm or GalleryForm
    const item = this.props.form.artist || this.props.form.gallery
    // No destructuring of props to get 'values,' 'values' doesn't exist at the start
    const values = item ? item.values : false
    const mentionCategory = values ? values.mentionCategory : false
    const mentionTitle = values ? values.mentionTitle : false
    const mentionLink = values ? values.mentionLink : false
    const isInternalLink = values && values.mentionLink && values.mentionLink.indexOf('artworksapp.com') > -1

    const titleToolTip = {
      text: t('createRelated.titleToolTip'),
      popupPos: 'left',
    }
    const linkToolTip = {
      text: t('createRelated.linkToolTip'),
      popupPos: 'left',
    }
    const categoryToolTip = {
      text: t('createRelated.categoryToolTip'),
      popupPos: 'left',
    }

    let fieldsetClass = 'fieldset'
    let inputClass = 'input-holder'
    if (responsive) {
      fieldsetClass = 'fieldset-r'
      inputClass = 'input-holder-r'
    }

    // button needs type="button" or it will add ?... to the url when saving. Also needs onClick or it won't save.
    return (
      <div className={ fieldsetClass }>
        <div className={ inputClass }>
          <Field
            name="mentionTitle"
            component={ TextInput }
            placeholder={t('common:title')}
            ref="title">
          </Field>
          <Field
            name="mentionLink"
            component={ TextInput }
            placeholder={t('common:link')}
            ref="link">
          </Field>
          <button className="purple-button--wide button-nomargin" type="button" disabled={ !mentionTitle || !mentionLink || submitting } onClick={ handleSubmit }>{t('common:add')}</button>
        </div>
        <div className={ inputClass }>
          <Field
            name="mentionCategory"
            component={ Select }
            options={ RELATED_CATEGORIES }
            disabled={ isInternalLink }
            ref="category">
          </Field>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

const translated = withNamespaces('components')(CreateRelated)
export default connect(mapStateToProps)(translated)
