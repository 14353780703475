import {
	NETWORK_ERROR,
	HIDE_NETWORK_ERROR,
	SHOW_SUCCESS_MODAL,
	HIDE_SUCCESS_MODAL,
	SHOW_RETRY_MODAL,
	HIDE_RETRY_MODAL,
	SHOW_ERROR_MODAL,
	HIDE_ERROR_MODAL,
	SHOW_CUSTOM_MODAL,
	HIDE_CUSTOM_MODAL,
	SHOW_LOADING_SPINNER,
	HIDE_LOADING_SPINNER
} from '../actions/AppActions'

const initialRetryModal = {
	show: false,
	itemData: {},
	itemType: '',
	text: null,
}

export function appReducer(state = {
		networkError: false,
		showErrorModal: false,
		showSuccessModal: false,
		showCustomModal: false,
		customModalClass: '',
		modalClass: '',
		showLoadingSpinner: false,
		message: '',
		error: null,
		retryModal: initialRetryModal,
	}, action) {
	switch (action.type) {
		case NETWORK_ERROR:
			// TODO: handle network errors
			return Object.assign({}, state, {
				networkError: false,
				error: action.error,
				modalClass: action.className,
			})
		case HIDE_NETWORK_ERROR:
			return Object.assign({}, state, {
				networkError: false,
				error: null,
				modalClass: '',
			})
		case SHOW_SUCCESS_MODAL:
			// TODO: handle network errors
			return Object.assign({}, state, {
				showSuccessModal: action.message !== 'NO MESSAGE' ? true : false,
				message: action.message,
				modalClass: action.className,
			})
		case HIDE_SUCCESS_MODAL:
			return Object.assign({}, state, {
				showSuccessModal: false,
				message: '',
				modalClass: '',
			})
		case SHOW_ERROR_MODAL:
			// TODO: handle network errors
			return Object.assign({}, state, {
				showErrorModal: true,
				message: action.message,
				modalClass: action.className,
			})
		case HIDE_ERROR_MODAL:
			return Object.assign({}, state, {
				showErrorModal: false,
				message: '',
				modalClass: '',
			})
		case SHOW_RETRY_MODAL:
			return Object.assign({}, state, {
				retryModal: {
					show: true,
					itemData: action.itemData,
					itemType: action.itemType,
					text: action.text,
				},
				message: action.message,
				modalClass: action.className,
			})
		case HIDE_RETRY_MODAL:
			return Object.assign({}, state, {
				retryModal: initialRetryModal,
				message: '',
				modalClass: '',
			})
			case SHOW_CUSTOM_MODAL:
				return Object.assign({}, state, {
					showCustomModal: true,
					customModalClass: action.className,
					message: action.message,
				})
			case HIDE_CUSTOM_MODAL:
				return Object.assign({}, state, {
					showCustomModal: false,
					customModalClass: '',
					message: '',
				})
		case SHOW_LOADING_SPINNER:
			return Object.assign({}, state, {
				showLoadingSpinner: true
			})
		case HIDE_LOADING_SPINNER:
			return Object.assign({}, state, {
				showLoadingSpinner: false
			})
		default:
			return state
	}
}
