import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import OpeningHourDay from "./OpeningHourDay";

const DAYS = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

const defaultProps = {
	openingHours: {
		monday: {},
		tuesday: {},
		wednesday: {},
		thursday: {},
		friday: {},
		saturday: {},
		sunday: {},
	},
	nameOfClass: "Gallery__openingHours OpeningHours",
};

class OpeningHourPicker extends Component {
	constructor(props) {
		super(props);

		// Bind functions
		this.onChangeOpen = this.onChangeOpen.bind(this);
		this.onChangeClose = this.onChangeClose.bind(this);
		this.onChangeByAppointment = this.onChangeByAppointment.bind(this);
	}

	componentDidMount() {}

	componentWillReceiveProps(newProps) {}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	onChangeOpen = (value, day) => {
		let selectedOpeningHours = { ...this.props.openingHours };
		selectedOpeningHours[day].opens = value;
		selectedOpeningHours[day].appointment = false;
		this.props.openingHoursChanged(selectedOpeningHours);
	};

	onChangeClose = (value, day) => {
		let selectedOpeningHours = { ...this.props.openingHours };
		selectedOpeningHours[day].closes = value;
		selectedOpeningHours[day].appointment = false;
		this.props.openingHoursChanged(selectedOpeningHours);
	};

	onChangeByAppointment = (value, day) => {
		let selectedOpeningHours = { ...this.props.openingHours };
		const dayOpen = {
			appointment: value,
			opens: null,
			closes: null,
		};
		selectedOpeningHours[day] = dayOpen;
		this.props.openingHoursChanged(selectedOpeningHours);
	};

	render() {
		const { nameOfClass, openingHours } = this.props;
		return (
			<table className={nameOfClass}>
				<tbody>
					{DAYS.map((day) => (
						<OpeningHourDay
							key={day}
							day={day}
							dayOpeningHours={openingHours[day]}
							onSelectDayOpen={this.onChangeOpen}
							onSelectDayClose={this.onChangeClose}
							onSelectDayByAppointment={this.onChangeByAppointment}
						/>
					))}
				</tbody>
			</table>
		);
	}
}

OpeningHourPicker.propTypes = {
	openingHours: PropTypes.object.isRequired,
	openingHoursChanged: PropTypes.func.isRequired,
	nameOfClass: PropTypes.string,
};
OpeningHourPicker.defaultProps = defaultProps;

export default OpeningHourPicker;
