import {
	CATEGORY_REQUEST, CATEGORY_FAILURE, CATEGORY_SUCCESS,
	CATEGORY_SAVE_REQUEST, CATEGORY_SAVE_FAILURE, CATEGORY_SAVE_SUCCESS,
	CATEGORY_DELETE_REQUEST, CATEGORY_DELETE_FAILURE, CATEGORY_DELETE_SUCCESS
} from '../actions/CategoryActions'

export function categoryReducer(state = {
		isFetching: false,
		categories: [],
		authenticated: false
	}, action) {
	switch (action.type) {
		case CATEGORY_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})
		case CATEGORY_SUCCESS: {
			let categories = state.categories
			action.response.map((category) => {
				categories[category._id] = category
			})
			return Object.assign({}, state, {
				isFetching: false,
				categories: categories,
				error: null
			})
		}
		case CATEGORY_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		case CATEGORY_SAVE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		case CATEGORY_SAVE_SUCCESS: {
			const category = action.response
			let categories = Object.assign({}, state.categories)
			categories[category._id] = category
			return Object.assign({}, state, {
				isFetching: false,
				categories: categories
			})
		}
		case CATEGORY_SAVE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		case CATEGORY_SAVE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		case CATEGORY_DELETE_SUCCESS: {
			console.log(action)
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				deletedCategory: true
			})
		}
		case CATEGORY_DELETE_REQUEST:
			console.log('delete request');
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				deletedCategory: false
			})
		case CATEGORY_DELETE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		default:
			return state
	}
}
