import React, { Component } from "react";
import { Field, Fields, reduxForm } from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import Select from "../../components/FormInput/Select";
import CheckBox from "../../components/FormInput/CheckBox";
import TextDisplay from "../../components/FormInput/TextDisplay";
import ArtistPicker from "../../components/FormComponents/ArtistPicker/ArtistPicker";
import { isTrue, notEmpty } from "../../utils/Validators";
import { TermsAndConditions } from "@artworkslab/sharedmodules/src/utils/DocumentUtil";

let ConvertUserForm = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		valid,
		mustAcceptTC,
		selectedRole,
		isAdmin,
		t,
	} = props;
	const canSubmit = valid && !pristine && !submitting;

	const TC_HTML_AUTHOR = (
		<label htmlFor="">
			{t("login.iAgreeToArtworks")}{" "}
			<a
				style={{ textDecoration: "underline" }}
				target="_blank"
				href={TermsAndConditions.galleryTerms(i18next.language)}
			>
				{t("login.termsAndConditions")}
			</a>
		</label>
	);
	const TC_HTML_ARTIST = (
		<label htmlFor="">
			{t("login.iAgreeToArtworks")}{" "}
			<a
				style={{ textDecoration: "underline" }}
				target="_blank"
				href={TermsAndConditions.artistTerms(i18next.language)}
			>
				{t("login.termsAndConditions")}
			</a>
		</label>
	);
	const TC_HTML = selectedRole === "artist" ? TC_HTML_ARTIST : TC_HTML_AUTHOR;

	const ROLES = [
		{ value: "", name: t("login.galleristOrArtist") },
		{ value: "author", name: t("login.gallerist") },
		{ value: "artist", name: t("login.artist") },
	];

	return (
		<form onSubmit={handleSubmit} className="Form">
			<Field
				name="role"
				component={Select}
				options={ROLES}
				validate={notEmpty}
				disabled={false}
				required
			/>
			{selectedRole === "artist" && (
				<div>
					<legend className="legend-nomargin">
						{t("login.existingArtists")}
					</legend>
					<Field
						name="connectToArtist"
						component={ArtistPicker}
						multi={false}
						canAdd={false}
						noPadding={true}
						fullWidth={true}
						searchForTagged={true}
					/>
				</div>
			)}
			{mustAcceptTC && selectedRole ? (
				<Field
					name="acceptedTC"
					component={CheckBox}
					checkboxLabel={TC_HTML}
					validate={isTrue}
					invalidText={t("login.tocInvalid")}
					hideInvalid={true}
					required
				/>
			) : null}
			<div className="fieldset">
				<div className="input-holder">
					<Field
						name="slug"
						itemType="convertUser"
						component={TextDisplay}
						isAdmin={isAdmin}
					/>
				</div>
				<div className="fixed-save-button">
					<button
						className="purple-button--wide"
						type="submit"
						disabled={!canSubmit}
					>
						{t("save")}
					</button>
				</div>
			</div>
		</form>
	);
};

ConvertUserForm = reduxForm({
	form: "convertUser",
})(ConvertUserForm);

export default withNamespaces("common")(ConvertUserForm);
