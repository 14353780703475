import React, { Component } from 'react'
import Select								from 'react-select'
import { connect }					from 'react-redux'
import {
  fetchSelections,
  saveSelection,
}	                          from '../../../actions/SelectionActions'

class SelectionPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      picked: null,
    }
  }

  componentDidMount() {
    this.props.dispatch( fetchSelections() )
  }

  pickSelection = (pick) => {
    this.setState({
      picked: pick,
    })
  }

  addItemToSelection = () => {
    const { type, item } = this.props
    const { picked } = this.state
    switch (type) {
      case 'artworks':
        picked.artworks = this.addItemToArray(picked.artworks, item)
        break
      case 'artists':
        picked.artists = this.addItemToArray(picked.artists, item)
        break
      case 'galleries':
        picked.galleries = this.addItemToArray(picked.galleries, item)
        break
      case 'shows':
        picked.shows = this.addItemToArray(picked.shows, item)
        break
      case 'articles':
        picked.articles = this.addItemToArray(picked.articles, item)
        break
      case 'events':
        picked.events = this.addItemToArray(picked.events, item)
        break
      case 'announcements':
        picked.announcements = this.addItemToArray(picked.announcements, item)
        break
      case 'prints':
        picked.prints = this.addItemToArray(picked.prints, item)
        break
      default:
        //
    }
    const addedItem = { item, type, selectionSlug: picked.slug }
    // Delete slugs so controller won't check for it. It will fail beause the selections slugs already exists ofc.
    delete picked.slug
    delete picked.slugSwedish

    this.props.dispatch( saveSelection(picked, addedItem) )
  }

  itemAlreadyAddedForType = (picked) => {
    const { item, type } = this.props
    let isAdded = false
    switch (type) {
      case 'artworks':
        isAdded = this.itemAlreadyAdded(picked.artworks, item._id)
        break
      case 'artists':
        isAdded = this.itemAlreadyAdded(picked.artists, item._id)
        break
      case 'galleries':
        isAdded = this.itemAlreadyAdded(picked.galleries, item._id)
        break
      case 'shows':
        isAdded = this.itemAlreadyAdded(picked.shows, item._id)
        break
      case 'articles':
        isAdded = this.itemAlreadyAdded(picked.articles, item._id)
        break
      case 'events':
        isAdded = this.itemAlreadyAdded(picked.events, item._id)
        break
      case 'announcements':
        isAdded = this.itemAlreadyAdded(picked.announcements, item._id)
        break
      case 'prints':
        isAdded = this.itemAlreadyAdded(picked.prints, item._id)
        break
      default:
        //
    }
    return isAdded
  }

  addItemToArray = (items = [], item) => {
    let newArray = items.slice()
    if (!this.itemAlreadyAdded(newArray, item._id)) {
      newArray.push(item)
    }
    return newArray
  }

  itemAlreadyAdded = (items = [], id) => {
    return items.some(item => {
      return item._id === id
    })
  }

  render() {
    const { selection: { isFetching, selections } } = this.props
    const { picked } = this.state
    const alreadyInCollection = picked ? this.itemAlreadyAddedForType(picked) : false
    const selectionOptions = []
    Object.keys(selections).map((key) => {
      let selection = Object.assign({}, selections[key])
      if (this.itemAlreadyAddedForType(selection)) {
        selection.title = `${selection.title} (already added)`
      }
      selectionOptions.push(selection)
    })
    // type="button" on <button> prevents it from submitting the form it is in.
    return (
      <div className="input-holder input-holder--full" style={{ marginTop: '30px' }}>
        <legend className="legend-nomargin">Add to selection</legend>
        <Select
          isLoading={ isFetching }
          onChange={ this.pickSelection }
          options={ selectionOptions }
          value={ picked }
          valueKey="_id"
          labelKey="title"
          placeholder="Selection"
          multi={ false }
          />
          <button className="purple-button--wide button-nomargin" type="button" onClick={ this.addItemToSelection } disabled={ !picked || alreadyInCollection }>Add to selection</button>
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log('state', state);
	const { selection } = state
	return { selection }
}

export default connect(mapStateToProps)(SelectionPicker)
