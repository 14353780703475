import React, { Component } from "react";
// import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";

const ATTACH_ICON = require("../../../assets/images/svg/icon_attach_file_48px.svg");

const FIVE_MIB = 5242880;
const ACCEPT = "application/pdf";
const CLOSE_CROSS = require("../../../assets/images/icons/close@2x.png");

// const propTypes = {
// 	defaultImage: PropTypes.any,
// };

const defaultProps = {
	defaultImage: false,
};

const validImage = (value, allValues, props) => {
	return undefined;
};

class PDFUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onDrop = (acceptedFiles, rejectedFiles, e) => {
		const reader = new FileReader();
		const {
			uploadedPDF: { URI, contentType, fileName },
			t,
		} = this.props;
		const uploadedFile = acceptedFiles[0];
		const rejectedFile = rejectedFiles[0];

		//Load preview
		reader.onload = (upload) => {
			// Uploaded image
			const image = {
				URI: upload.target.result,
				fileName: uploadedFile.name,
				contentType: uploadedFile.type,
			};

			// Set state for preview
			this.setState({
				previewImage: uploadedFile,
			});

			// Update input states
			URI.input.onChange(image.URI);
			contentType.input.onChange(image.contentType);
			fileName.input.onChange(image.fileName);
			URI.input.onBlur(image.URI);
			contentType.input.onBlur(image.contentType);
			fileName.input.onBlur(image.fileName);
		};

		// Read data file
		if (uploadedFile) {
			reader.readAsDataURL(uploadedFile);
		}
		// Handle errors
		if (rejectedFile) {
			if (rejectedFile.size > FIVE_MIB) {
				window.alert(t("pdfUpload.fileTooBig"));
			}
			if (ACCEPT.indexOf(rejectedFile.type) === -1) {
				window.alert(t("pdfUpload.fileWrongFormat"));
			}
		}
	};

	removeCV = () => {
		const {
			pdfCV,
			uploadedPDF: { URI },
			t,
		} = this.props;
		const confirm = window.confirm(t("pdfUpload.removeAreYouSure"));
		if (confirm) {
			pdfCV.input.onChange(null);
			pdfCV.input.onBlur(null);

			// When adding cv and then removing it without saving first, this.props.uploadedPDF will still be there,
			// looks like this: { URI: ..., contentType: ..., fileName: ... }
			// Set URI to null and test for it in imageHelper.js -> uploadPDF() in API
			URI.input.onChange(null);
			URI.input.onBlur(null);

			this.setState({
				previewImage: null,
			});
		}
	};

	renderPreviewImage = (t) => {
		const { previewImage } = this.state;
		const { responsive } = this.props;
		const pdfCV = this.props.pdfCV.input.value || null;
		// console.log(pdfCV);
		// console.log(previewImage);
		const fileUploaded = previewImage ? previewImage.preview : pdfCV;
		if (fileUploaded) {
			return (
				<div className="ImageUpload__preview" style={{ height: "100%" }}>
					<a
						className="ImageUpload__preview-icon"
						href={fileUploaded}
						target="_blank"
						style={{ minHeight: responsive ? 0 : "150px", textAlign: "center" }}
					>
						<ATTACH_ICON />
						<div style={{ textDecoration: "underline" }}>
							{t("pdfUpload.fileUploaded")}
						</div>
					</a>
				</div>
			);
		} else if (!responsive) {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<div>{t("pdfUpload.noFileUploaded")}</div>
					</div>
				</div>
			);
		}
	};

	render() {
		// let {input, meta: { touched, error, valid, invalid }} = this.props;
		const { responsive, t } = this.props;
		const hasFeaturedImg = this.props.pdfCV.input.value ? true : false;
		const hasUploadedImg =
			this.props.uploadedPDF.URI.input.value &&
			this.props.uploadedPDF.fileName.input.value &&
			this.props.uploadedPDF.contentType.input.value
				? true
				: false;
		const valid = hasFeaturedImg || hasUploadedImg;
		const error = this.props.pdfCV.meta.error || false;
		const required = this.props.required ? true : false;
		const marked = this.props.marked ? true : false;

		let classname = "";
		if (marked) {
			if (!valid) {
				classname = "ImageUpload__dropzone empty";
			} else {
				classname = "ImageUpload__dropzone marked";
			}
		} else {
			classname = "ImageUpload__dropzone";
		}

		return (
			<div
				className={
					"ImageUpload" +
					(error ? " is-error" : "") +
					(valid ? " is-valid" : "") +
					(required ? " is-required" : "") +
					(responsive ? " is-responsive" : "")
				}
			>
				<div className={responsive ? "image-upload-holder" : "input-holder"}>
					<input type="hidden" name="uploadedPDF.URI" />
					<input type="hidden" name="uploadedPDF.fileName" />
					<input type="hidden" name="uploadedPDF.contentType" />
					<Dropzone
						onDrop={this.onDrop}
						className={classname}
						accept={ACCEPT}
						multiple={false}
						maxSize={FIVE_MIB}
					>
						{responsive ? (
							<div>
								{t("pdfUpload.clickToUpload")}{" "}
								<span style={{ whiteSpace: "nowrap" }}>
									{t("pdfUpload.pdfFile")}
								</span>
							</div>
						) : (
							<div>{t("pdfUpload.dropHere")}</div>
						)}
						{/* <div>{ responsive ? t('pdfUpload.clickToUpload') : t('pdfUpload.dropHere')}</div> */}
					</Dropzone>
					{error ? <span>{error}</span> : null}
				</div>
				<div className={responsive ? "image-preview-holder" : "input-holder"}>
					{this.renderPreviewImage(t)}
					<button
						className="close-button"
						onClick={this.removeCV}
						disabled={!hasFeaturedImg && !hasUploadedImg}
						style={{
							backgroundImage: `url(${CLOSE_CROSS})`,
							marginTop: 0,
							right: 0,
						}}
					/>
				</div>
			</div>
		);
	}
}

export default withNamespaces("components")(PDFUpload);
