import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import TextDisplay from "../../components/FormInput/TextDisplay";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import ArtworkPicker from "../../components/FormComponents/ArtworkPicker/ArtworkPicker";
import { imageRotationActivated } from "../../utils/Helpers";

class XmasCalendarForm extends Component {
	constructor(props) {
		super(props);
	}

	formSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit();
	};

	render() {
		const { isAdmin, pristine, submitting, t } = this.props;

		const useUploadWithRotate = imageRotationActivated();

		return (
			<form onSubmit={(e) => this.formSubmit(e)}>
				<div className="fieldset-block fieldset-block--nopadding">
					<legend>{t("featuredImage")}</legend>
					<Fields
						names={[
							"featuredImage.src",
							"uploadedImage.URI",
							"uploadedImage.fileName",
							"uploadedImage.contentType",
						]}
						component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
					/>
				</div>
				{/*Save button*/}
				<div className="fieldset">
					{/* View-online link now in NavBar, except for admin. */}
					{isAdmin && (
						<div className="input-holder">
							<Field
								name="slug"
								itemType="xmascalendar"
								component={TextDisplay}
								isAdmin={isAdmin}
							/>
						</div>
					)}
					<div className="fixed-save-button mobile-only">
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("save")}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

XmasCalendarForm = reduxForm({
	form: "xmascalendar",
})(XmasCalendarForm);

const selector = formValueSelector("xmascalendar");

export default withNamespaces("xmascalendar")(XmasCalendarForm);
