import React, {Component} 			from 'react'
import Select										from 'react-select'
import { connect }							from 'react-redux'
import { fetchPostsForPicker } 	from '../../../actions/PostActions'


class PostPicker extends Component {
	static propTypes = {
	}
	static defaultProps = {
		placeholder: 'Choose related posts...',
		post: {
			isFetching: false,
		}
	}
	constructor(props){
		super(props);
	}

	componentDidMount(){
		this.props.dispatch( fetchPostsForPicker() )
	}

	componentWillReceiveProps(newProps) {
	}


	selectPosts = (selections) => {
		const posts = [].concat(this.props.post.posts);
		selections.forEach(selection => {
        const match = posts.find((entry) => (entry._id == selection._id));
        if (!match) {
					console.log('This is a new selection: ',selection);
        }
    });
    this.props.input.onChange(selections)
	}

	render() {
		const { type, placeholder, post: { isFetching, postsForPicker: posts }, input: { value } } = this.props;
		const postOptions = Object.keys(posts).map((key) => { return posts[key]	})

    return (
			<div className="input-holder">
				<Select
					isLoading={ isFetching }
					onChange={ this.selectPosts }
					options={ postOptions }
					value={ value }
					valueKey="_id"
					labelKey="title"
					placeholder={ placeholder }
					multi={ true }
					/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { post } = state
	return { post }
}

export default connect(mapStateToProps)(PostPicker)
