import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import Select from "react-select";
import { withNamespaces } from "react-i18next";
import Main from "../../components/Main/Main";
import { fetchAllArtistsFast } from "../../actions/ArtistActions";
import { fetchData as fetchSummary } from "../../actions/DashboardActions/SummaryActions";

// let getPremiumModal = block('get-premium-modal')
// let alreadyPremiumModal = block('already-premium-modal')
const CHECK_MARK = require("../../../assets/images/icons/dashboard_check_mark.jpg");
const MAN_SILHOUETTE = require("../../../assets/images/icons/dashboard_man.jpg");
const PARTY_CONE = require("../../../assets/images/icons/dashboard_party_cone.jpg");
const THUMBS_UP = require("../../../assets/images/icons/dashboard_thumbs_up.jpg");
const CLOSE = require("../../../assets/images/icons/close@2x.png");
const PEN = require("../../../assets/images/svg/fontAwesome/edit.svg");

class ArtistDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			selectedArtist: null,
			popupOpen: false,
		};
	}

	componentWillMount() {
		// this.props.dispatch( fetchSummary() )
	}

	componentDidMount() {
		const { isAdmin, userArtist } = this.props;
		if (isAdmin) {
			this.props.dispatch(fetchAllArtistsFast());
		} else if (userArtist) {
			// this.props.dispatch( fetchMembers(user.gallery._id || user.gallery) )
			// if (userGallery._id) {
			//   // When reloading on dashboard page, userGallery here is an empty object. Don't decide if popup shoud open until gallery has been loaded.
			//   this.openPopup(userGallery)
			// }
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, allArtists } = nextProps;

		if (
			isAdmin &&
			this.state.artistOptions.length !== Object.keys(allArtists).length &&
			Object.keys(allArtists).length > 0
		) {
			const artistOptions = [];
			Object.keys(allArtists).forEach((key) => {
				const artist = allArtists[key];
				artistOptions.push({
					value: artist._id,
					label: artist.name,
				});
			});

			this.setState({
				artistOptions: artistOptions.sort(this.sortOptions),
			});
		}
		// else if (!isAdmin && !this.props.userGallery._id && userGallery._id) {
		//   // After gallery has loaded.
		//   this.openPopup(userGallery)
		// }
	}

	selectArtist = (artist) => {
		// const { allArtists } = this.props
		this.setState(
			{
				selectedArtist: artist,
			},
			() => {
				// this.props.dispatch( fetchMembers(gallery.value) )
			}
		);
	};

	getArtist = (props) => {
		const { isAdmin, userArtist, allArtists } = props;
		const { selectedArtist } = this.state;
		return isAdmin
			? allArtists[selectedArtist ? selectedArtist.value : 0]
			: userArtist;
	};

	createCard = (title, count, image) => {
		if (count > 0) {
			return (
				<Link
					to={"/members"}
					className="society-dashboard__content__right__card"
				>
					<div className="society-dashboard__content__right__card__texts">
						<p>{title}</p>
						<div>{count}</div>
					</div>
					<img src={image} />
				</Link>
			);
		} else {
			return (
				<div className="society-dashboard__content__right__card">
					<div className="society-dashboard__content__right__card__texts">
						<p>{title}</p>
						<div>{count}</div>
					</div>
					<img src={image} />
				</div>
			);
		}
	};

	render() {
		const { isAdmin, userArtist, allArtists, isFetching, t } = this.props;
		const { artistOptions, selectedArtist, popupOpen } = this.state;
		let artist = this.getArtist(this.props);
		let address = "";
		const thisYear = new Date().getFullYear();
		// if (gallery && gallery.address) {
		//   address = `${gallery.address.street || ''}${', '}${gallery.address.zip || ''} ${gallery.address.city || ''}`
		// }

		return (
			<Main>
				<div className="society-dashboard">
					{artist && artist._id && (
						<div className="society-dashboard__content">
							<div className="society-dashboard__content__left">
								<div className="society-dashboard__content__left__header">
									<h1>{t("hello")},</h1>
									<img src={PARTY_CONE} />
									<h1>{artist.name.split(" ")[0]}!</h1>
								</div>
								<Link
									to="/gallery"
									className="society-dashboard__content__left__profile"
								>
									<div className="society-dashboard__content__left__profile__logo">
										{/* gallery.logo && gallery.logo.src &&
                      <img src={ gallery.logo.src } />
                    */}
									</div>
									<div className="society-dashboard__content__left__profile__info">
										<p>{t("profile")}</p>
										<div>
											<p>
												{t("common:email")}
												<br />
												<span>{"gallery.email" || ""}</span>
											</p>
											<p>
												{t("common:phoneNumber")}
												<br />
												<span>{"gallery.phone" || ""}</span>
											</p>
										</div>
										<p>
											{t("common:address")}
											<br />
											<span>{address}</span>
										</p>
									</div>
									<PEN
										className="society-dashboard__content__left__profile__edit"
										data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
									/>
								</Link>
							</div>
							<div className="society-dashboard__content__right">
								{this.createCard(t("membersToApprove"), 10, THUMBS_UP)}
								{this.createCard(
									t("newMembersSince") + ` ${thisYear}`,
									10,
									CHECK_MARK
								)}
								{this.createCard(t("membersTotal"), 10, MAN_SILHOUETTE)}
							</div>
						</div>
					)}
					{isAdmin && (
						<div className="society-dashboard__select-container">
							<Select
								value={selectedArtist}
								options={artistOptions}
								onChange={this.selectArtist}
								onInputChange={() => {}}
								valueKey={"artist"}
								labelKey="label"
								backspaceToRemoveMessage=""
								backspaceRemoves={false}
								isLoading={isFetching}
								placeholder={"Artist"}
								multi={false}
								autosize={false}
								className={"gallery-picker-select"}
								isSearchable={true}
							/>
						</div>
					)}
				</div>
			</Main>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		artist: { artist: userArtist, allArtists, isFetching },
	} = state;
	const isAdmin = user.role === "admin";

	return { user, isAdmin, userArtist, allArtists, isFetching };
};

const translated = withNamespaces("dashboard")(ArtistDashboard);
export default connect(mapStateToProps)(translated);
