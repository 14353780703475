import React                from 'react'
import { Link }  						from 'react-router'
import { connect }          from 'react-redux'
import { createWebbLink }   from './Helpers'
import {
	showCustomModal,
	hideCustomModal,
} from '../actions/AppActions'

const showPremiumFunctionalityModal = (dispatch, showLink = true) => {
	const content = (
		<div>
			<p>Just nu använder du Artworks Bas. Denna funktion ingår i Artworks Pro.</p>
			{ showLink &&
				<p>För att läsa mer och uppgradera er konstförening <Link to="/settings" onClick={ dispatch( hideCustomModal() )}>klicka här</Link>.</p>
			}
		</div>
	)

	dispatch( showCustomModal(content, '') )
}

const showPremiumUpgradeModal = (dispatch, availableFor = '') => {
	const content = premiumUpgradeModalContent(availableFor)
	dispatch( showCustomModal(content) )
}

const premiumUpgradeModalContent = (availableFor) => {
	return (
		<div style={{ marginTop: '10px' }}>
			{ availableFor ?
				<p>{ `Denna funktion är begränsad till ${availableFor}.` }</p>
				:
				<p>Denna funktion är begränsad.</p>
			}
			<p>Läs mer och uppgradera <a href={ createWebbLink('konstforening', false) } target="_blank">här</a>!</p>
		</div>
	)
}

export {
  showPremiumFunctionalityModal,
	showPremiumUpgradeModal,
	premiumUpgradeModalContent,
}
