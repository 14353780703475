import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import {
	fetchArtistShows,
	fetchAllShowsFast,
	fetchAllShowsPopulated,
	fetchShowsForGallery,
	fetchOtherShows,
	generateCoordinates,
	CITIES_FOR_SHOWS,
} from "../../actions/ShowActions";
import { saveArtist } from "../../actions/ArtistActions";
import { fetchGallery, saveGallery } from "../../actions/GalleryActions";
import Main from "../../components/Main/Main";
// import FormMeta                     from '../../components/Meta/FormMeta'
import RelatedShow from "../../components/FormComponents/ShowPicker/RelatedShow";
import ShowsList from "./ShowsList";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";

class Shows extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addRelatedOpen: false,
			selectedCity: CITIES_FOR_SHOWS[0],
		};
	}

	componentDidMount() {
		const {
			isAdmin,
			user: { role, gallery },
			user,
			isSociety,
			isArtist,
		} = this.props;
		if (!role) return;
		if (isAdmin) {
			// this.props.dispatch( fetchShows() )
			this.fetchShowsForAdmin(this.props);
		} else if (gallery && !isArtist) {
			const galleryId = gallery._id ? gallery._id : gallery;
			this.props.dispatch(fetchShowsForGallery(galleryId));
			this.props.dispatch(fetchGallery(galleryId));
			if (isSociety) {
				this.props.dispatch(fetchOtherShows(this.createOtherShowsQuery()));
			}
		} else if (isArtist) {
			this.props.dispatch(fetchArtistShows(user.artist));
		}
	}

	componentWillReceiveProps(newProps) {
		if (this.props.user._id !== newProps.user._id) {
			const {
				isAdmin,
				user: { gallery },
				user,
				isSociety,
				isArtist,
			} = newProps;
			if (isAdmin) {
				// this.props.dispatch( fetchShows() )
				this.fetchShowsForAdmin(newProps);
			} else if (gallery && !isArtist) {
				const galleryId = gallery._id ? gallery._id : gallery;
				this.props.dispatch(fetchShowsForGallery(galleryId));
				this.props.dispatch(fetchGallery(galleryId));
				if (isSociety) {
					this.props.dispatch(fetchOtherShows(this.createOtherShowsQuery()));
				}
			} else if (isArtist) {
				this.props.dispatch(fetchArtistShows(user.artist));
			}
		}
	}

	fetchShowsForAdmin = (props) => {
		const { shows, deletedShow } = props;
		// Shows for admin here is allShows from state. If already fetched, only update.
		if (Object.keys(shows).length === 0 || deletedShow) {
			this.props.dispatch(fetchAllShowsFast()).then((result) => {
				this.props.dispatch(fetchAllShowsPopulated());
			});
		} else {
			this.props.dispatch(fetchAllShowsPopulated());
		}
	};

	createOtherShowsQuery = () => {
		const endOfToday = moment().endOf("day").toISOString();

		const query = {
			$or: [
				{
					$and: [
						{ endDate: { $gt: endOfToday } },
						{ visibility: { $nin: ["restricted"] } },
						{ status: "published" },
					],
				},
				{ _id: { $in: [] } },
			],
		};

		return query;
	};

	selectCity = (city) => {
		this.setState({ selectedCity: city });
		if (city.value === "cityAll") {
			// Use allOtherShows instead, all fetched are already stored there.
			// this.props.dispatch( fetchOtherShows(this.createOtherShowsQuery()) )
		} else {
			generateCoordinates(city, false)
				.then((coords) => {
					const otherShowsQuery = this.createOtherShowsQuery();
					const fullQuery = { $and: [{ location: coords }, otherShowsQuery] };
					this.props.dispatch(fetchOtherShows(fullQuery));
				})
				.catch((err) => {
					console.log("Error selecting city", err);
				});
		}
	};

	renderAddRelated = () => {
		const { addRelatedOpen, selectedCity } = this.state;
		const { gallery, otherShows, allOtherShows, relatedShows, t } = this.props;

		const addedShowElements = [];
		const relatedShowElements = [];
		const relatedShowIds = relatedShows.map((relShow) => relShow._id);

		Object.keys(allOtherShows).forEach((key) => {
			const show = allOtherShows[key];

			// Don't add shows that already belong to this gallery.
			const belongsToGallery =
				show.gallery &&
				gallery &&
				(show.gallery._id || show.gallery) === gallery._id;

			if (!belongsToGallery) {
				const alreadyAdded = gallery && relatedShowIds.indexOf(show._id) > -1;
				const imageSource = show.featuredImage.thumbnails
					? show.featuredImage.thumbnails["100x100"].src
					: show.featuredImage.src;

				if (alreadyAdded) {
					addedShowElements.push(
						<RelatedShow
							show={show}
							alreadyAdded={true}
							addOrRemove={this.addRemoveRelated}
							key={show._id}
						/>
					);
				}
			}
		});

		// After viewing ex shows for Stockholm, then switching back to all shows, we don't fetch all shows again, since they are already stored in allOtherShows.
		const fetchedShows =
			selectedCity.value === "cityAll" ? allOtherShows : otherShows;
		Object.keys(fetchedShows).forEach((key) => {
			const show = fetchedShows[key];

			// Don't add shows that already belong to this gallery.
			const belongsToGallery =
				show.gallery &&
				gallery &&
				(show.gallery._id || show.gallery) === gallery._id;

			if (!belongsToGallery) {
				const alreadyAdded = gallery && relatedShowIds.indexOf(show._id) > -1;
				const imageSource = show.featuredImage.thumbnails
					? show.featuredImage.thumbnails["100x100"].src
					: show.featuredImage.src;

				if (!alreadyAdded) {
					relatedShowElements.push(
						<RelatedShow
							show={show}
							alreadyAdded={alreadyAdded}
							addOrRemove={this.addRemoveRelated}
							key={show._id}
						/>
					);
				}
			}
		});
		return (
			<div>
				{addRelatedOpen && (
					<div className="related-shows">
						<legend className="legend-nomargin">
							{t("list.currentRecommendations")}
						</legend>
						{addedShowElements}
					</div>
				)}
				{addRelatedOpen && (
					<div className="related-shows">
						{addedShowElements.length > 0 && (
							<legend className="legend-nomargin">
								{t("list.selectRecommendations")}
							</legend>
						)}
						<div className="related-shows__options">
							{CITIES_FOR_SHOWS.map((city) => {
								const isSelected = city.value === selectedCity.value;
								return (
									<div
										className={
											"related-shows__options__option" +
											(isSelected ? " is-selected" : "")
										}
										onClick={
											isSelected ? () => {} : () => this.selectCity(city)
										}
										key={city.value}
									>
										{i18next.language === "sv" ? city.label_SE : city.label_EN}
									</div>
								);
							})}
						</div>
						{relatedShowElements}
					</div>
				)}
			</div>
		);
	};

	toggleRelatedOpen = () => {
		this.setState({
			addRelatedOpen: !this.state.addRelatedOpen,
		});
	};

	addRemoveRelated = (show) => {
		const { gallery } = this.props;
		if (!gallery) return;

		let relatedIds = [];
		gallery.relatedShows.forEach((relatedShow) => {
			if (relatedShow) {
				relatedIds.push(relatedShow._id ? relatedShow._id : relatedShow);
			}
		});

		const index = relatedIds.indexOf(show._id);

		if (index < 0) {
			relatedIds.push(show._id);
		} else {
			relatedIds.splice(index, 1);
		}

		const update = {
			_id: gallery._id,
			relatedShows: relatedIds,
		};
		this.props.dispatch(saveGallery(update));
	};

	//Adds or removes show from related list
	addRemoveArtistRelated = (show) => {
		const { artist } = this.props;

		let related = artist.related;
		let listIndex = false;
		const index = related.map((obj) => obj._id).indexOf(show._id);

		if (index < 0) {
			//Format show as a related object
			const showMention = {
				_id: show._id,
				category: "Show",
				title: show.title,
				internalLink: "utstallningar/" + show.slug,
				externalLink: "",
			};
			related.push(showMention);
		} else {
			related.splice(index, 1);
		}

		const update = {
			_id: artist._id,
			related: related,
		};
		this.props.dispatch(saveArtist(update));
	};

	sortShows = (shows) => {
		const { user, myShowsOnly = false, isArtist } = this.props;
		const upcomingShows = [];
		const currentShows = [];
		const pastShows = [];

		Object.keys(shows).forEach((key) => {
			const show = shows[key];

			if (
				!isArtist ||
				(!myShowsOnly && show.artistOrganizer !== user.artist) ||
				(myShowsOnly && show.artistOrganizer === user.artist)
			) {
				if (new Date(show.startDate) > new Date()) {
					upcomingShows.push(show);
				} else if (
					new Date(show.endDate) > new Date() &&
					new Date(show.startDate) < new Date()
				) {
					currentShows.push(show);
				} else {
					pastShows.push(show);
				}
			}
		});

		return [upcomingShows, currentShows, pastShows];
	};

	renderShows = () => {
		const {
			gallery,
			shows,
			isArtist,
			myShowsOnly = false,
			relatedIds = [],
			t,
		} = this.props;
		const sortedShows = this.sortShows(shows);
		const upcomingShows = sortedShows[0];
		const currentShows = sortedShows[1];
		const pastShows = sortedShows[2];

		return (
			<div className={"table" + (isArtist ? " is-shows" : "")}>
				<legend className="legend-nomargin">
					{`${t("list.currentShows")} (${currentShows.length})`}
				</legend>
				{currentShows.length > 0 && (
					<ShowsList
						shows={currentShows}
						gallery={gallery}
						isAdmin={false}
						isArtist={isArtist}
						myShowsOnly={myShowsOnly}
						relatedIds={relatedIds}
						toggleRelated={this.addRemoveArtistRelated}
						maxItems={false}
						t={t}
					/>
				)}
				<legend className="legend-nomargin" style={{ marginTop: "30px" }}>
					{`${t("list.upcomingShows")} (${upcomingShows.length})`}
				</legend>
				{upcomingShows.length > 0 && (
					<ShowsList
						shows={upcomingShows}
						gallery={gallery}
						isAdmin={false}
						isArtist={isArtist}
						myShowsOnly={myShowsOnly}
						relatedIds={relatedIds}
						toggleRelated={this.addRemoveArtistRelated}
						maxItems={false}
						t={t}
					/>
				)}
				<legend className="legend-nomargin" style={{ marginTop: "30px" }}>
					{`${t("list.pastShows")} (${pastShows.length})`}
				</legend>
				{pastShows.length > 0 && (
					<ShowsList
						shows={pastShows}
						gallery={gallery}
						isAdmin={false}
						isArtist={isArtist}
						myShowsOnly={myShowsOnly}
						relatedIds={relatedIds}
						toggleRelated={this.addRemoveArtistRelated}
						maxItems={pastShows.length > 100 ? 100 : false}
						t={t}
					/>
				)}
			</div>
		);
	};

	render() {
		const { shows, user, isSociety, isAdmin, isArtist, t } = this.props;
		const { addRelatedOpen } = this.state;

		if (isAdmin) {
			return (
				<div className="Shows">
					<Main>
						<div style={{ marginBottom: "30px" }}>
							<Link
								to="/shows/new"
								className="purple-button--wide purple-button--wide--mod_create"
								style={{ minWidth: "180px" }}
							>
								{t("list.createNewShow")}
							</Link>
						</div>
						{/*<FormMeta type="shows" />*/}
						<ShowsList shows={shows} isAdmin={true} maxItems={100} t={t} />
					</Main>
				</div>
			);
		}

		return (
			<div className="Shows">
				<Main>
					{!isArtist && (
						<div style={{ marginBottom: "30px" }}>
							<Link
								to="/shows/new"
								className="purple-button--wide purple-button--wide--mod_create create-new-button"
								style={{ minWidth: "180px" }}
							>
								{t("list.createNewShow")}
							</Link>
							{isSociety && (
								<div
									className="purple-button--wide purple-button--wide--mod_create open-suggestions-button"
									style={{ minWidth: "180px" }}
									onClick={this.toggleRelatedOpen}
								>
									{addRelatedOpen
										? t("list.yourShows")
										: t("list.recommendShows")}
									{/* !addRelatedOpen && <div className="premium-style button-premium-header"><span>Premium</span></div> */}
								</div>
							)}
						</div>
					)}
					{addRelatedOpen ? this.renderAddRelated() : this.renderShows()}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		show,
		show: { shows: selectedShows, allShows, deletedShow },
		auth: { user },
		gallery: { gallery },
	} = state;
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const isSociety = !!(user.role === "society");
	const isArtist = user.role === "artist";
	const shows = isAdmin ? allShows : selectedShows;
	// Shows for other galleries, not past ones.
	const otherShows = show && show.otherShows ? show.otherShows : {};
	// Need allOtherShows for already selected recommendations. otherShows can include only the sones for stockholm etc.
	const allOtherShows = show && show.allOtherShows ? show.allOtherShows : {};

	const relatedShowsIds = [];
	if (gallery && gallery.relatedShows) {
		gallery.relatedShows.forEach((rel) => {
			if (rel) relatedShowsIds.push(rel._id || rel);
		});
	}

	// relatedShows are only populated on fetch, not after save, but they are included in otherShows (which they were added from)
	// shows that have expired will not be included in relatedShows even if they are still in gallery.relatedShows,
	// don't show expired shows on webb sincce you can't remove them here. But no need for users to manually remove recommended shows.
	const relatedShows = [];
	relatedShowsIds.forEach((id) => {
		if (allOtherShows[id]) relatedShows.push(allOtherShows[id]);
	});

	return {
		shows,
		user,
		isAdmin,
		isSociety,
		isArtist,
		gallery,
		otherShows,
		allOtherShows,
		relatedShows,
		deletedShow,
	};
}

const translated = withNamespaces("show")(Shows);
export default connect(mapStateToProps)(translated);
