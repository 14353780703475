import React, { Component } from "react"
import {
	Router,
	Route,
	Redirect,
	Link,
	browserHistory,
	IndexRoute,
} from 'react-router'
import ArtworksResponsive from './views/Artworks/ArtworksResponsive'
import ArtworkResponsive from './views/Artworks/ArtworkResponsive'

const pathChanged = (e) => {
	// Use location.pathname if we don't want to scroll to top on a page. Ex:
	// if (location.pathname === '/artworks') { return	}

	window.scrollTo(0, 0)
	if ('scrollRestoration' in history) {
		history.scrollRestoration = 'manual'
	}
}

const RoutesResponsive = (props) => {
	const {
		authenticated,
		App,
		ArtworksNew,
		Activities,
		ArtworkInvoice,
		ChatPage,
		ContactLists,
		ContactList,
		CreateArtist,
		CreateArtwork,
		Dashboard,
		EditArtist,
		EditArtwork,
		ForArtists,
		PreviousInvoices,
		Login,
		RegisterNew,
		ResetPassword,
		SingleArtist,
		UpdatePassword,
		UserAccount,
		GallerySettings,
		ArtistSettings,
		DataPage,
		ArtistsHelpCenter,
	} = props.components
	const {
		isAdmin,
		isArtist,
	} = props

	let authRedirect = '/settings'
	let notAuthRedirect = '/login'
	if (isArtist) {
		authRedirect = '/artists/me'
	}

	return (
		<Router history={browserHistory} onUpdate={ pathChanged }>
			<Route path="/" component={ App }>
				<IndexRoute component={ RegisterNew } />
				<Route path="login" component={ Login }/>
				<Route path="forgot" component={ ResetPassword } />
				<Route path="reset/:token" component={ UpdatePassword } />
				<Route path="register">
					<IndexRoute component={ RegisterNew } />{/* Works */}
					<Route path="artsociety" component={ RegisterNew } />
					<Route path="artist" component={ RegisterNew } />
					<Route path="gallery" component={ RegisterNew } />
					<Route path="konstnarshuset" component={ RegisterNew } />
				</Route>
				<Route path="artworks">
					<IndexRoute component={ ArtworksNew } />
					<Route path="new" component={ CreateArtwork } />
					<Route path=":artworkId" component={ EditArtwork } />
				</Route>
				<Route path="settings" component={ UserAccount }/>
				<Route path="gallery-settings" component={ GallerySettings }/>
				<Route path="artist-settings" component={ ArtistSettings }/>
				<Route path="artists">
					<Route path="me" component={ SingleArtist }/>
				</Route>
				<Route path="messages" component={ChatPage} />
				<Route path="contactlists">
					<IndexRoute component={ContactLists}/>
					<Route path=":contactListSlug" component={ContactList}/>
				</Route>
				<Route path="artwork/invoice" component={ArtworkInvoice}/>
				<Route path="artist-invoices" component={PreviousInvoices}/>
				<Route path="data" component={DataPage}/>
				<Route path="help-center/artists" component={ArtistsHelpCenter}/>
				{/*	If logged in, /login redirects to routes we may not have implemented yet, so redirect to me. */}
				{/*<Redirect from="/*" to="/artists/me" />*/}
				{/* authenticated ?
					<Redirect from="/*" to="/settings" />
					:
					<Redirect from="/*" to="/login" />
				*/}
				{/*
					<Redirect from="/*" to="/settings" />
				*/}
				<Redirect from="/*" to={ authenticated ? authRedirect : notAuthRedirect } />
			</Route>
		</Router>
	)

	return (
		<Router history={browserHistory}>
			<Route path="/" component={ App }>
				<IndexRoute component={ RegisterNew } />
				<Route path="for-artists" component={ ForArtists } />
				<Route path="login" component={ Login }/>{/* Works */}
				<Route path="forgot" component={ ResetPassword } />
				<Route path="reset/:token" component={ UpdatePassword } />
				<Route path="register">
					<IndexRoute component={ RegisterNew } />{/* Works */}
					<Route path="artsociety" component={ RegisterNew } />
					<Route path="artist" component={ RegisterNew } />
					<Route path="gallery" component={ RegisterNew } />
					<Route path="konstnarshuset" component={ RegisterNew } />
				</Route>
				<Route path="dashboard" component={ Dashboard }/>
				{/*<Route path="dashboards" component={ SocietyDashboard }/>*/}
				{/*<Route path="galleries">
					<IndexRoute component={ Galleries }/>
					<Route path=":galleryId" component={ Gallery }/>
				</Route>*/}
				{/*<Route path="gallery" component={ Gallery }/>*/}
				{/*<Route path="shows">
					<IndexRoute component={ Shows }/>
					<Route path="new" component={ CreateShow } />
					<Route path=":showId" component={ EditShow } />
				</Route>*/}
				<Route path="activities" component={ Activities } />
				{/*<Route path="scraped/shows" component={ ScrapedShows } />
				<Route path="scraped/shows/:showId" component={ AddShow } />
				<Route path="scraped/artists" component={ ScrapedArtists } />
				<Route path="scraped/artists/:artistId" component={ AddArtist } />
				<Route path="scraped/galleries" component={ ScrapedGalleries } />
				<Route path="scraped/galleries/:galleryId" component={ AddGallery } />*/}
				<Route path="artworks">
					<IndexRoute component={ ArtworksNew } />
					<Route path="new" component={ CreateArtwork } />
					<Route path=":artworkId" component={ EditArtwork } />
				</Route>
				{/*<Route path="artworks-all">
					<IndexRoute component={ Artworks } />
				</Route>*/}
				{/*<Route path="events">
					<IndexRoute component={ Events }/>
					<Route path="new" component={ CreateEvent }/>
					<Route path=":eventId" component={ EditEvent }/>
				</Route>*/}
				{/*<Route path="posts">
					<IndexRoute component={ Posts }/>
					<Route path="new" component={ CreatePost }/>
					<Route path=":postId" component={ EditPost }/>
				</Route>*/}
				{/*<Route path="imagegallery">
					<IndexRoute component={ Posts }/>
					<Route path="new" component={ CreatePost }/>
					<Route path=":postId" component={ EditPost }/>
				</Route>*/}
				<Route path="settings" component={ UserAccount }/>
				<Route path="gallery-settings" component={ GallerySettings }/>
				<Route path="artist-settings" component={ ArtistSettings }/>
				{/*<Route path="accounts">
					<IndexRoute component={ Accounts }/>
					<Route path=":accountId" component={ AdminAccount }/>
				</Route>*/}
				{/*<Route path="categories" component={ Categories }/>
				<Route path="members" component={ Members }/>*/}
				{/* Newsletter was renamed contacts.
				<Redirect from="newsletter" to="contacts" />
				<Route path="contacts" component={ Newsletter }/>
				<Route path="orders" component={ Orders }/>*/}
				<Route path="artists">
				 	{/*<IndexRoute component={ Artists }/>*/}
					<Route path="new" component={ CreateArtist }/>
					<Route path="me" component={ SingleArtist }/>
					<Route path=":artistId" component={ EditArtist }/>
				</Route>
				{/*<Route path="rated" component={ Rated }/>*/}
				{/*<Route path="selections">
					<IndexRoute component={ Selections } />
					<Route path=":selectionId" component={ Selection } />
				</Route>*/}
				{/*<Route path="raffles">
					<IndexRoute component={ Raffles } />
					<Route path=":raffleId" component={ Raffle } />
				</Route>*/}
				{/*<Route path="filearchive" component={ FileArchive } />
				<Route path="emails" component={ Emails } />*/}
				<Route path="messages" component={ChatPage} />
				{/*<Route path="xmascalendar" component={XmasCalendar} />
				<Route path="invoices" component={Billecta} />
				<Route path="subscriptions" component={Subscriptions} />*/}
				<Route path="contactlists">
					<IndexRoute component={ContactLists}/>
					<Route path=":contactListSlug" component={ContactList}/>
				</Route>
				{/*<Route path="announcements">
					<IndexRoute component={Announcements}/>
					<Route path=":announcementSlug" component={Announcement}/>
				</Route>*/}
				<Route path="artwork/invoice" component={ArtworkInvoice}/>
				<Route path="artist-invoices" component={PreviousInvoices}/>
				<Route path="data" component={DataPage}/>
				{/*<Route path="discounts">
					<IndexRoute component={DiscountCodes}/>
					<Route path=":id" component={DiscountCode}/>
				</Route>*/}
				{/*
					Redirect not responsive paths.
					If logged in, /login redirects to routes we may not have implemented yet, so redirect to settings.
				*/}
				{ authenticated ?
					<Redirect from="*" to="/settings" />
					:
					<Redirect from="*" to="/login" />
				}
			</Route>
		</Router>
	)
}

export default RoutesResponsive
