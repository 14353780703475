import React, { Component } from "react";

class ImageBlock extends Component {
	onInputChange = (e) => {
		e.stopPropagation();
		const text = e.target.value;
		const { data } = this.props;
		data.subtitle = text;
		this.props.container.updateData(data);
	};

	render() {
		const { src, subtitle, srcSet } = this.props.data;
		return (
			<div>
				<img
					style={{ width: "100%", height: "auto" }}
					src={src}
					srcSet={srcSet}
				/>
				<textarea
					placeholder="Image caption"
					rows="1"
					onChange={this.onInputChange}
					value={subtitle ? subtitle : ""}
				/>
			</div>
		);
	}
}

export default ImageBlock;
