import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import { fetchArtist } from "../../actions/ArtistActions";
import {
	submitArtist,
	loadArtist,
	showSuccess,
	removeArtist,
	hideSuccess,
	hideError,
} from "../../actions/FormActions/ArtistFormActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import ArtistForm from "./ArtistForm";
import ArtistFormResp from "./ArtistFormResp";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import Modal from "../../components/Modal/Modal";
import MetaButton from "../../components/MetaButton/MetaButton";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import { hasAccess } from "../../utils/Helpers";

class EditArtist extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		const { artistId } = this.props.params;
		this.props.dispatch(fetchArtist(artistId));
	}
	componentWillReceiveProps(newProps) {
		if (newProps.artist.deletedArtist) {
			return this.props.router.push("/artists");
		}
		if (this.props.artist.artist !== newProps.artist.artist) {
			this.props.dispatch(loadArtist(newProps.artist.artist));
		}
	}

	handleSubmit = () => {
		const {
			form: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};

	handleRemove = () => {
		const artist = this.props.form.artist;
		this.props.dispatch(removeArtist(artist));
	};

	render() {
		const {
			isAdmin,
			user,
			artist: { artist },
			form,
			t,
		} = this.props;
		const showVat =
			user.role && (user.role === "artist" || user.role === "admin");
		const hasEndedSub =
			artist.billing && artist.billing.premiumEndedDate ? true : false;

		if (!hasAccess(user, artist, "artist")) return null;
		const artistVideo =
			form.artist && form.artist.videoLink ? form.artist.videoLink : null;

		const MainComponent = appGlobals.useResponsive ? MainResp : Main;
		const FormComponent = appGlobals.useResponsive
			? ArtistFormResp
			: ArtistForm;

		// contentClassName only used by MainResp.
		return (
			<div>
				<MainComponent contentClassName="slim-content">
					<FormComponent
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						showVat={showVat}
						editArtist={true}
						isPremium={isPremium(form.artist)}
						hasEndedSub={hasEndedSub}
						artistVideo={artistVideo}
						dispatch={this.props.dispatch}
					/>
					{isAdmin && (
						<div className="fieldset">
							<legend style={{ paddingTop: 40 }}>Admin</legend>
							<SelectionPicker type="artists" item={artist} />
							<legend style={{ paddingTop: 40 }}>
								{t("common:dangerZone")}
							</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={() => {
									let confirm = window.confirm("Are you sure?");
									if (confirm) this.handleRemove();
								}}
							>
								Delete this artist?
							</button>
							<MetaButton item={this.props.artist.artist} />
						</div>
					)}
				</MainComponent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artist,
		auth: { user, isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return { user, isAdmin, artist, form };
}

const translated = withNamespaces("artworks")(EditArtist);
export default connect(mapStateToProps)(withRouter(translated));
