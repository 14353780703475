import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	RadialBarChart,
	RadialBar,
	Legend,
	PieChart,
	Pie,
	ResponsiveContainer,
	Text,
} from "recharts";
import Main from "../../components/Main/Main";
import Summary from "../../components/DashboardComponents/Summary/Summary";
import CurrentShow from "../../components/DashboardComponents/CurrentShow/CurrentShow";
import PastShows from "../../components/DashboardComponents/PastShows/PastShows";
import PopularArtwork from "../../components/DashboardComponents/PopularArtwork/PopularArtwork";
import Mentions from "../../components/DashboardComponents/Mentions/Mentions";
import Visits from "../../components/DashboardComponents/Visits/Visits";
import PopularOnArtworks from "../../components/DashboardComponents/PopularOnAw/PopularOnAw";
import Header from "../../components/DashboardComponents/Header/Header";
import AdminData from "../../components/DashboardComponents/AdminData/AdminData";
import LatestSales from "../../components/DashboardComponents/LatestSales/LatestSales";
import { scrollToSection } from "../../utils/Helpers";
import { fetchAdminVenuesMetrics } from "../../actions/DashboardActions";

class AdminDashboard extends React.Component {
	/**
	 * defaultProps
	 */
	static defaultProps = {
		isAdmin: false,
	};

	static propTypes = {
		isAdmin: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			metrics: {},
		};
	}

	componentDidMount() {
		const { hash } = this.props;

		this.props.dispatch(fetchAdminVenuesMetrics());

		//Scroll if there is a hash
		if (hash) {
			setTimeout(() => {
				scrollToSection(hash);
			}, 1000);
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.metrics) {
			this.setState({
				metrics: newProps.metrics,
			});
		}

		//Scroll if new hash
		if (this.props.hash !== newProps.hash) {
			scrollToSection(newProps.hash);
		}
	}

	render() {
		const { metrics, adminVenuesMetrics, isFetching } = this.props;
		let venueData = [];
		adminVenuesMetrics.map((type) => {
			venueData.push(
				<div
					key={type._id}
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<p>{type._id ? type._id : "Not set -> Gallery"}</p>
					<p>{type.count}</p>
				</div>
			);
		});
		return (
			<Main>
				<AdminData />
				<div id="latest-sales">
					<LatestSales />
				</div>
				{/*<p>Welcome to Artworks Portal!</p>
				<h4>{"Number of venues listed:"}</h4>
				<div style={{maxWidth: '300px'}}>
					{ venueData }
				</div>
				<Summary />
				<CurrentShow />
				<PastShows />
				<PopularArtwork />
				<Mentions />
				<Visits />
				<PopularOnArtworks />*/}
				{isFetching && (
					<div className="App__loader">
						<Spinner spinnerName="cube-grid" noFadeIn />
					</div>
				)}
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		dashboard: { adminVenuesMetrics },
		adminData: { dataFetching },
	} = state;

	const isFetching = dataFetching;

	return { adminVenuesMetrics, isFetching };
}

export default connect(mapStateToProps)(AdminDashboard);
