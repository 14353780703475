import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

export const FETCH_PREVIEW_REQUEST = "emailActions/FETCH_PREVIEW_REQUEST";
export const FETCH_PREVIEW_SUCCESS = "emailActions/FETCH_PREVIEW_SUCCESS";
export const FETCH_PREVIEW_FAILURE = "emailActions/FETCH_PREVIEW_FAILURE";

//Fetch preview of email depending on given type
export function fetchPreview(items, message, sender, recipients, type) {
	const endpoint = "contactemail";
	const data = { items, message, type };
	const body = { data, sender, recipients, endpoint };
	return (dispatch) => {
		const url = "emails/preview";
		dispatch(fetchPreviewRequest());
		return Api(url, "POST", false, body)
			.then((response) => {
				dispatch(fetchPreviewSuccess(response));
				return response;
			})
			.catch((error) => {
				console.log("Fetch preview error", error);
				dispatch(fetchPreviewFailure(error));
			});
	};
}

function fetchPreviewRequest() {
	return {
		type: FETCH_PREVIEW_REQUEST,
	};
}

function fetchPreviewSuccess(response) {
	return {
		type: FETCH_PREVIEW_SUCCESS,
		response: response,
	};
}

function fetchPreviewFailure(error) {
	console.log("FAILURE", error);
	return {
		type: FETCH_PREVIEW_REQUEST,
		message: error,
	};
}

export const SEND_CONTACT_EMAIL_REQUEST =
	"emailActions/SEND_CONTACT_EMAIL_REQUEST";
export const SEND_CONTACT_EMAIL_SUCCESS =
	"emailActions/SEND_CONTACT_EMAIL_SUCCESS";
export const SEND_CONTACT_EMAIL_FAILURE =
	"emailActions/SEND_CONTACT_EMAIL_FAILURE";

export function sendContactEmail(items, message, sender, emailLists, type) {
	const endpoint = "contactemail";
	const data = { items, message, type };
	const body = { data, sender, emailLists, endpoint };
	return (dispatch) => {
		const url = "emails/sendcontact";
		dispatch(sendContactEmailRequest());
		return Api(url, "POST", false, body)
			.then((response) => {
				dispatch(sendContactEmailSuccess(response));
				return response;
			})
			.catch((error) => {
				console.log("Fetch preview error", error);
				dispatch(sendContactEmailFailure(error));
			});
	};
}

export function sendHelpEmail(userId, header, emailBody, attachments) {
	return (dispatch) => {
		const endpoint = "emails/sendhelp";
		const body = { userId, header, emailBody, attachments };
		dispatch(sendContactEmailRequest());
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(sendContactEmailSuccess(json));
				return "success";
			})
			.catch((error) => {
				dispatch(sendContactEmailFailure(error));
				return "failure";
			});
	};
}

function sendContactEmailRequest() {
	return {
		type: SEND_CONTACT_EMAIL_REQUEST,
	};
}

function sendContactEmailSuccess(response) {
	const isSwedish = i18next.language === "sv";
	return {
		type: SEND_CONTACT_EMAIL_SUCCESS,
		response: response,
		message: isSwedish ? "Skickat!" : "Sent!",
	};
}

function sendContactEmailFailure(error) {
	console.log("FAILURE", error);
	return {
		type: SEND_CONTACT_EMAIL_REQUEST,
		message: error,
	};
}
