import React from "react";
import { initialize, startSubmit, stopSubmit, change } from "redux-form";

import i18next from "i18next";
import Validator from "validatorjs";
import sv from "./sv";
import diff from "object-diff";

import { showErrorModal } from "../AppActions";
import { validateShipping } from "../../utils/Validators";
import { confirmPurchase } from "@artworkslab/sharedmodules/src/actions/EnquiryActions";

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

export function submitConfirmPurchase(enqId, data, initial) {
	return (dispatch, getState) => {
		const isSwedish = i18next.language === "sv";
		let customErrors = {};
		const rules = {
			price: "numeric",
			shipping: {
				transport: "string",
				swePrice: "numeric",
				euPrice: "numeric",
				globalPrice: "numeric",
			},
		};

		let validation = isSwedish
			? new Validator(data, rules, { ...sv })
			: new Validator(data, rules);

		if (isSwedish) {
			validation.setAttributeNames({
				price: "Pris",
				shipping: {
					transport: "Leverans",
					swePrice: "Leverans i Sverige",
					euPrice: "Leverans inom EU",
					globalPrice: "Leverans utanför EU",
				},
			});
		}

		if (!data.price) {
			customErrors.price = isSwedish
				? "Pris är obligatoriskt vid försäljning"
				: "Price is required upon sale";
		}

		//Validate that relevat shipping fields are filled in
		const shippingErrors = validateShipping(data, isSwedish);
		if (shippingErrors) {
			customErrors.shipping = shippingErrors;
		}

		validation.passes();
		const validatorErrors = validation.errors.all();
		const errors = Object.assign({}, customErrors, validatorErrors);

		const changedValues = initial ? diff(initial, data) : data;
		if (data._id) changedValues._id = data._id;

		// Return early if errors
		if (Object.keys(errors).length !== 0) {
			dispatch(showErrorModal(translate("requiredFields")));
			return dispatch(stopSubmit("purchaseArtwork", errors));
		}
		dispatch(confirmPurchase(enqId, changedValues));
		//Return SUCCESS if there are no errors
		return "SUCCESS";
	};
}
