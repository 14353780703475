import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

class ImagePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedImage: null,
		};
	}

	selectImage = (option) => {
		this.setState(
			{
				selectedImage: option,
			},
			() => {
				const image = option ? option.value : null;
				this.props.input.onChange(image);
				this.props.input.onBlur(image);
			}
		);
	};

	render() {
		const { options, isFetching } = this.props;
		const { selectedImage } = this.state;

		return (
			<Select
				value={selectedImage}
				options={options}
				onChange={this.selectImage}
				isLoading={isFetching}
				placeholder={this.props.placeholder || "Import image"}
				multi={false}
			/>
		);
	}
}

export default ImagePicker;
