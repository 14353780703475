import { CALL_API } from "../utils/api";
import LocationUtil from "../utils/LocationUtil";
import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

// fetch shows

export const SHOWS_REQUEST = "SHOWS_REQUEST";
export const SHOWS_SUCCESS = "SHOWS_SUCCESS";
export const SHOWS_FAILURE = "SHOWS_FAILURE";

// Uses the API to get a show
export function fetchShows() {
	return {
		[CALL_API]: {
			endpoint: "shows",
			authenticated: true,
			types: [SHOWS_REQUEST, SHOWS_SUCCESS, SHOWS_FAILURE],
		},
	};
}

export function fetchArtistShows(artistId) {
	return {
		[CALL_API]: {
			endpoint: `shows/artist/${artistId}`,
			authenticated: true,
			types: [SHOWS_REQUEST, SHOWS_SUCCESS, SHOWS_FAILURE],
		},
	};
}

export function fetchShowsForGallery(galleryId) {
	const endpoint = "shows/gallery/" + galleryId;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [SHOWS_REQUEST, SHOWS_SUCCESS, SHOWS_FAILURE],
		},
	};
}

export const SHOWS_ALL_REQUEST = "SHOWS_ALL_REQUEST";
export const SHOWS_ALL_SUCCESS = "SHOWS_ALL_SUCCESS";
export const SHOWS_ALL_FAILURE = "SHOWS_ALL_FAILURE";

export function fetchAllShowsFast() {
	return {
		[CALL_API]: {
			endpoint: "shows/fetch-all-fast",
			authenticated: true,
			types: [SHOWS_ALL_REQUEST, SHOWS_ALL_SUCCESS, SHOWS_ALL_FAILURE],
		},
	};
}

export const SHOWS_ALL_POPULATE_REQUEST = "SHOWS_ALL_POPULATE_REQUEST";
export const SHOWS_ALL_POPULATE_SUCCESS = "SHOWS_ALL_POPULATE_SUCCESS";
export const SHOWS_ALL_POPULATE_FAILURE = "SHOWS_ALL_POPULATE_FAILURE";

export function fetchAllShowsPopulated() {
	// Request was too slow, timed out.
	return { type: "NOT-DOING-ANYTHING" };
	return {
		[CALL_API]: {
			endpoint: "shows/fetch-all-populated",
			authenticated: true,
			types: [
				SHOWS_ALL_POPULATE_REQUEST,
				SHOWS_ALL_POPULATE_SUCCESS,
				SHOWS_ALL_POPULATE_FAILURE,
			],
		},
	};
}

// fetch show

export const SHOW_REQUEST = "SHOW_REQUEST";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
export const SHOW_FAILURE = "SHOW_FAILURE";

// Uses the API to get a show
export function fetchShow(id) {
	return {
		[CALL_API]: {
			endpoint: "shows/" + id,
			authenticated: true,
			types: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
		},
	};
}

// save show

export const SHOW_SAVE_REQUEST = "SHOW_SAVE_REQUEST";
export const SHOW_SAVE_SUCCESS = "SHOW_SAVE_SUCCESS";
export const SHOW_SAVE_FAILURE = "SHOW_SAVE_FAILURE";

// Uses the API to get a show
export function saveShow(show, displayMessage = true) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = show._id ? `shows/${show._id}` : "shows";
			const method = show._id ? "PUT" : "POST"; // update if id is present, otherwise create
			const track = show._id ? "Updated show" : "Saved show";
			const payload = show._id ? { show: show._id } : {};
			const analytics = {
				track,
				payload,
				incrementScore: 1,
			};

			dispatch(saveShowRequest(analytics));
			Api(endpoint, method, true, show)
				.then((json) => {
					dispatch(
						saveShowSuccess(json, displayMessage, show.createVernissage)
					);
					resolve(json);
				})
				.catch((error) => {
					dispatch(saveShowFail(error));
					reject(error);
				});
		});
}

export function saveShowRequest(analytics) {
	return {
		type: SHOW_SAVE_REQUEST,
		analytics,
	};
}

export function saveShowSuccess(json, displayMessage, createVernissage) {
	let translateString = "showSaved";
	if (createVernissage) {
		translateString = "vernissageShowSaved";
	}
	return {
		type: SHOW_SAVE_SUCCESS,
		response: json,
		message: displayMessage ? translate(translateString) : "NO MESSAGE",
	};
}

export function saveShowFail(error) {
	return {
		type: SHOW_SAVE_FAILURE,
		error,
	};
}

// delete show

export const SHOW_DELETE_REQUEST = "SHOW_DELETE_REQUEST";
export const SHOW_DELETE_SUCCESS = "SHOW_DELETE_SUCCESS";
export const SHOW_DELETE_FAILURE = "SHOW_DELETE_FAILURE";

// Uses the API to get a show
export function deleteShow(show) {
	return {
		[CALL_API]: {
			endpoint: "shows/" + show._id,
			authenticated: true,
			types: [SHOW_DELETE_REQUEST, SHOW_DELETE_SUCCESS, SHOW_DELETE_FAILURE],
			method: "delete",
		},
	};
}

// nearby shows

export const SHOWS_NEARBY_REQUEST = "SHOWS_NEARBY_REQUEST";
export const SHOWS_NEARBY_SUCCESS = "SHOWS_NEARBY_SUCCESS";
export const SHOWS_NEARBY_FAILURE = "SHOWS_NEARBY_FAILURE";

// Uses the API to get a show
export function nearbyShows(lat, lng, dist = 10000) {
	return {
		[CALL_API]: {
			endpoint: "shows/near?lat=" + lat + "&lng=" + lng + "&dist=" + dist,
			authenticated: true,
			types: [SHOWS_NEARBY_REQUEST, SHOWS_NEARBY_SUCCESS, SHOWS_NEARBY_FAILURE],
		},
	};
}

// use query to fetch shows

export const SHOWS_OTHER_REQUEST = "SHOWS_OTHER_REQUEST";
export const SHOWS_OTHER_SUCCESS = "SHOWS_OTHER_SUCCESS";
export const SHOWS_OTHER_FAILURE = "SHOWS_OTHER_FAILURE";

// Uses the API to get shows
export function fetchOtherShows(query) {
	return {
		[CALL_API]: {
			endpoint: `shows/query?query=${JSON.stringify(query)}`,
			authenticated: true,
			types: [SHOWS_OTHER_REQUEST, SHOWS_OTHER_SUCCESS, SHOWS_OTHER_FAILURE],
		},
	};
}

export const INFORM_MEMBERS_SHOW_REQUEST =
	"showActions/INFORM_MEMBERS_SHOW_REQUEST";
export const INFORM_MEMBERS_SHOW_SUCCESS =
	"showActions/INFORM_MEMBERS_SHOW_SUCCESS";
export const INFORM_MEMBERS_SHOW_FAILURE =
	"showActions/INFORM_MEMBERS_SHOW_FAILURE";

export function informMembersAboutShow(showId, sendToMembers, sendToContacts) {
	return {
		[CALL_API]: {
			endpoint: `shows/inform/${showId}?members=${sendToMembers}&contacts=${sendToContacts}`,
			authenticated: true,
			types: [
				INFORM_MEMBERS_SHOW_REQUEST,
				INFORM_MEMBERS_SHOW_SUCCESS,
				INFORM_MEMBERS_SHOW_FAILURE,
			],
		},
	};
}

export const INFORM_CONTACTS_SHOW_REQUEST =
	"showActions/INFORM_CONTACTS_SHOW_REQUEST";
export const INFORM_CONTACTS_SHOW_SUCCESS =
	"showActions/INFORM_CONTACTS_SHOW_SUCCESS";
export const INFORM_CONTACTS_SHOW_FAILURE =
	"showActions/INFORM_CONTACTS_SHOW_FAILURE";

// Not used anymore.
export function informContactsShow(showId, emailLists, message) {
	const messageText = message && message.text ? message.text : "";
	const messageSubject = message && message.subject ? message.subject : "";
	return {
		[CALL_API]: {
			endpoint: `shows/contacts/${showId}?emails=${emailLists}&messageText=${messageText}&messageSubject=${messageSubject}`,
			authenticated: true,
			types: [
				INFORM_CONTACTS_SHOW_REQUEST,
				INFORM_CONTACTS_SHOW_SUCCESS,
				INFORM_CONTACTS_SHOW_FAILURE,
			],
		},
	};
}

export const SHOW_MAIL_PREVIEW_REQUEST =
	"showActions/SHOW_MAIL_PREVIEW_REQUEST";
export const SHOW_MAIL_PREVIEW_SUCCESS =
	"showActions/SHOW_MAIL_PREVIEW_SUCCESS";
export const SHOW_MAIL_PREVIEW_FAILURE =
	"showActions/SHOW_MAIL_PREVIEW_FAILURE";

export function fetchShowEmailPreview(showId, message = false) {
	const messageText = message && message.text ? message.text : "";
	const messageSubject = message && message.subject ? message.subject : "";
	return {
		[CALL_API]: {
			endpoint: `shows/preview/${showId}?messageText=${messageText}&messageSubject=${messageSubject}`,
			authenticated: true,
			types: [
				SHOW_MAIL_PREVIEW_REQUEST,
				SHOW_MAIL_PREVIEW_SUCCESS,
				SHOW_MAIL_PREVIEW_FAILURE,
			],
		},
	};
}

export const SHOW_MAIL_PREVIEW_RESET = "showActions/SHOW_MAIL_PREVIEW_RESET";

export function resetShowEmailPreview() {
	return {
		type: SHOW_MAIL_PREVIEW_RESET,
	};
}

export const CITIES_FOR_SHOWS = [
	{ label_SE: "Alla städer", label_EN: "All cities", value: "cityAll" },
	// { label_SE: 'Nära mig',   label_EN: 'Near me',    value: 'nearby'}, // Not working for everyone, but for some.
	{ label_SE: "Stockholm", label_EN: "Stockholm", value: "cityStockholm" },
	{ label_SE: "Göteborg", label_EN: "Gothenburg", value: "cityGothenburg" },
	{ label_SE: "Malmö", label_EN: "Malmö", value: "cityMalmo" },
];

const CITY_COORDINATES = {
	cityStockholm: {
		lng: 17.8419718,
		lat: 59.326242,
	},
	cityGothenburg: {
		lng: 11.7537556,
		lat: 57.7010982,
	},
	cityMalmo: {
		lng: 12.9459332,
		lat: 55.5702456,
	},
};

// Also in ShowActions in sharedmodules.
export function generateCoordinates(cityFilter, userPosition) {
	return new Promise(function (resolve, reject) {
		let lat,
			lng,
			minDistance = 0,
			maxDistance = 100000;

		const generateLocation = (lat, lng, minDistance, maxDistance) => {
			resolve({
				$near: {
					$geometry: {
						type: "Point",
						coordinates: [lat, lng],
					},
					$maxDistance: maxDistance,
					$minDistance: minDistance,
				},
			});
		};
		if (cityFilter.value === "cityAll") {
			resolve(false);
		} else if (cityFilter.value !== "nearby") {
			(lat = CITY_COORDINATES[cityFilter.value].lat),
				(lng = CITY_COORDINATES[cityFilter.value].lng);
			generateLocation(lat, lng, minDistance, maxDistance);
		} else {
			if (userPosition) {
				generateLocation(
					userPosition.latitude,
					userPosition.longitude,
					minDistance,
					maxDistance
				);
			} else {
				const LocUtil = new LocationUtil(navigator);
				LocUtil.followPosition()
					.then((position) => {
						if (!position.latitude) {
							if (__DEVELOPMENT__) {
								// Using stockholm to test. Chrome considers localhost safe, but not localhost.se or localhost.com, and getting position for those will fail.
								return generateLocation(
									59.340914500000004,
									18.0808446,
									minDistance,
									maxDistance
								);
							} else {
								return resolve(false);
							}
						}
						generateLocation(
							position.latitude,
							position.longitude,
							minDistance,
							maxDistance
						);
					})
					.catch((err) => {
						resolve(false);
					});
			}
		}
	});
}
