import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	change,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitGallery,
	loadGallery,
	initGallery,
} from "../../actions/FormActions/GalleryFormActions";
import {
	fetchScrapedGallery,
	acceptScrapedGallery,
} from "../../actions/ScrapedActions";
import GalleryForm from "../Gallery/GalleryForm";
import Main from "../../components/Main/Main";
import { withRouter } from "react-router";

class AddGallery extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		const galleryId = this.props.params.galleryId;
		this.props.dispatch(fetchScrapedGallery(galleryId));
		this.props.dispatch(initGallery());
	}
	componentWillReceiveProps(newProps) {
		const galleryId = this.props.params.galleryId;
		const gallery = newProps.scrapedGalleries[galleryId];
		if (this.props.scrapedGalleries !== newProps.scrapedGalleries && gallery) {
			this.props.dispatch(loadGallery(gallery));
		}
	}

	handleSubmit = () => {
		const galleryId = this.props.params.galleryId;
		let {
			form: { gallery },
		} = this.props;
		delete gallery._id;
		this.props.dispatch(acceptScrapedGallery(galleryId));
		this.props.dispatch(submitGallery(gallery, {}));
	};

	render() {
		const {
			scrapedGalleries,
			params: { showId },
		} = this.props;
		const gallery = scrapedGalleries[galleryId]
			? scrapedGalleries[galleryId]
			: {};

		const { isAdmin } = this.props;

		return (
			<div>
				<Main>
					<GalleryForm onSubmit={this.handleSubmit} isAdmin={isAdmin} />
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		scraped: { scrapedGalleries },
		auth: { user, isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("gallery")(state),
		submitSucceeded: hasSubmitSucceeded("gallery")(state),
		submitFailed: hasSubmitFailed("gallery")(state),
		gallery: getFormValues("gallery")(state),
		initial: getFormInitialValues("gallery")(state),
	};
	return {
		scrapedGalleries,
		user,
		isAdmin,
		form,
	};
}

export default connect(mapStateToProps)(withRouter(AddGallery));
