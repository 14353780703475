import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import { Field, Fields, FormSection, reduxForm } from "redux-form";
import { COUNTRIES_SELECTED, ROLES } from "./FormValues";
import FormMeta from "../../components/Meta/FormMeta";
import TextInput from "../../components/FormInput/TextInput";
import Select from "../../components/FormInput/Select";
import ReactSelect from "react-select";
import ArtistProLink from "../../components/ArtworksLite/ArtistProLink";
import SingleDateTimePicker from "../../components/FormComponents/DateRange/SingleDateTimePicker";
import { validateEmail } from "../../utils/Validators";
import { isUserArtSociety } from "../../utils/Helpers";
// import PDPA                   from './PDPA'
import { TermsAndConditions } from "@artworkslab/sharedmodules/src/utils/DocumentUtil";
import {
	isPremium,
	isKlarnaSub,
	subscriptionCancelled,
	finalRenewalDate,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const ARROW_RIGHT = require("../../../assets/images/svg/arrow_right.svg");

const MEMBERSHIPS = [
	{ name: "BAS", value: "free" },
	{ name: "PRO", value: "premium" },
];

const DEVICE_OPTIONS = (isSwedish) => {
	return [
		{ label: isSwedish ? "Mobil" : "Mobile", value: "mobile" },
		{ label: isSwedish ? "Dator" : "Desktop", value: "desktop" },
	];
};

const changeDevice = (option, isResponsive) => {
	if (option.value === "mobile" && !isResponsive) {
		appGlobals.setResponsiveDisplay(true);
	} else if (option.value === "desktop" && isResponsive) {
		appGlobals.setResponsiveDisplay(false);
	}
};

let UserAccountForm = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		role,
		userIsSociety,
		gallery,
		artist,
		isPro,
		stripeCustomerId,
		isStripeSubscriber,
		openStripePortal,
		hasMemberfulSubscription,
		t,
	} = props;
	const isAdmin = role === "admin";
	const isArtist = role === "artist";

	let tocLink = "";
	if (role === "artist") {
		tocLink = TermsAndConditions.artistTerms(i18next.language);
	} else if (role === "society") {
		tocLink = TermsAndConditions.artSocietyTerms(i18next.language);
	} else if (role === "author") {
		tocLink = TermsAndConditions.galleryTerms(i18next.language);
	}

	// Replaced by code inside return(...). Remove.
	// If not premium, show link to webb.
	// If Memberful membership, show links to Memberful.
	// If Klarna membership, don't show links (handle subscription for Klarna not implemented yet).
	// let membershipLink = ''
	// if(!isPremium(artist)) {
	//   // membershipLink = <ArtistProLink children={ [<b key="text">{t('account.artist.base')}</b>, <ARROW_RIGHT height="20" width="20" x='20px' alt="arrow" className="arrow" key="arrow" />] } />
	//   membershipLink = <ArtistProLink children={[
	//     <b key="text">{t('account.artist.base')}</b>,
	//     <button type="button" key="button" className="subscribe-button is-enabled" style={{ marginLeft: '15px' }}>{t('account.artist.upgrade')}</button>,
	//   ]} />
	// } else if (hasMemberfulSubscription) {
	//   membershipLink = <a href="https://artworks.memberful.com/account/orders" target="_blank"><i style={{fontWeight: 'bold', marginRight: '5px'}}>{t('account.artist.pro')}</i> {artist.billing.premiumType ? t(`account.artist.${artist.billing.premiumType}`) : ''}<ARROW_RIGHT height="20" width="20" x="20px" alt="arrow" className="arrow"/></a>
	// }

	let subscriptionType = "";
	if (isPremium(artist)) {
		const type = artist.billing.premiumType || "";
		if (type.toLowerCase().indexOf("monthly") >= 0) {
			subscriptionType = t("account.artist.monthly");
		} else if (type.toLowerCase().indexOf("yearly") >= 0) {
			subscriptionType = t("account.artist.yearly");
		}
	}

	const isResponsive = appGlobals.useResponsive;
	const formClassName = isResponsive ? "responsive-form" : "";
	const fieldsetClass = isResponsive
		? "fieldset-block-r"
		: "fieldset-block fieldset-block--nopadding";
	const inputHolderClass = isResponsive
		? "input-holder-r"
		: "input-holder input-holder--nopadding";
	const inputHolderFullClass = isResponsive
		? "input-holder-r input-holder-r--full"
		: "input-holder input-holder--full input-holder--nopadding";
	let canSwapDevice = isAdmin ? true : false;
	if (isArtist) canSwapDevice = true;
	const deviceOptions = DEVICE_OPTIONS(i18next.language === "sv");
	const selectedDeviceOption = isResponsive
		? deviceOptions[0]
		: deviceOptions[1];

	const klarnaSubscriptionCancelled = subscriptionCancelled(artist);
	const finalDates = finalRenewalDate(artist);
	/* finalDates ex:
  finalPaymentDate: '2022-10-25T10:04:09.081Z', can be an empty string.
  finalEndDate: '2022-11-24T11:04:09.081Z',
  type: 'yearly',
  inTrial: false,
  paymentsMade: 11 */
	// Only showing finalPaymentDate, not necessary to also show finalEndDate.
	// if (!__PRODUCTION__ && artist) {
	//   console.log('artist', artist);
	//   console.log('finalDates', finalDates);
	// }

	return (
		<form onSubmit={handleSubmit} className={formClassName}>
			{canSwapDevice && (
				<div className={inputHolderFullClass} style={{ marginBottom: "20px" }}>
					<legend style={{ marginLeft: 0 }}>
						{t("account.portalVersion")}
					</legend>
					<ReactSelect
						options={deviceOptions}
						value={selectedDeviceOption}
						onChange={(option) => changeDevice(option, isResponsive)}
						placeholder={t("account.device")}
						multi={false}
						className="no-clear-all"
					/>
				</div>
			)}
			{/*<FormMeta text={t('account.editAccount')} />*/}
			<legend className="legend-nomargin">{t("account.editAccount")}</legend>
			<div className="fieldset">
				<div className={inputHolderFullClass}>
					<Field
						name="name"
						component={TextInput}
						placeholder={t("fullName")}
					/>
				</div>
				<div className={inputHolderFullClass}>
					<Field
						name="email"
						component={TextInput}
						placeholder={t("email")}
						validate={validateEmail}
						disabled={!isAdmin}
					/>
				</div>
				<div className={inputHolderFullClass}>
					<Field
						name="password"
						component={TextInput}
						placeholder={t("newPassword")}
						type="password"
						disabled={isAdmin}
					/>
				</div>
				{!userIsSociety && (
					<div className={inputHolderFullClass}>
						<Field
							name="country"
							component={Select}
							options={COUNTRIES_SELECTED}
						/>
					</div>
				)}
				{/* isArtist && membershipLink && Has been replaced with code below. Remove.
          <div className={ inputHolderFullClass + ' input-holder--flex' } style={{display: 'flex', flexFlow: 'column'}}>
            <legend className="legend-nomargin">{t('account.artist.premium')}</legend>
            {membershipLink}
            {isPremium(artist) && hasMemberfulSubscription && <a href="https://artworks.memberful.com/account/card/edit" target="_blank"><p>{t('account.artist.payInfo')}</p><ARROW_RIGHT height="20" width="20" alt="arrow" className="arrow"/></a>}
            {isPremium(artist) && !isKlarnaSub(artist) && <a href="https://artworks.memberful.com/account/card/edit" target="_blank"><p>{t('account.artist.payInfo')}</p><ARROW_RIGHT height="20" width="20" alt="arrow" className="arrow"/></a>}
          </div>
        */}
				{isArtist && !isPremium(artist) && (
					<div
						className={inputHolderFullClass + " input-holder--flex"}
						style={{ display: "flex", flexFlow: "column" }}
					>
						<legend className="legend-nomargin">
							{t("account.artist.premium")}
						</legend>
						<ArtistProLink
							children={[
								<b key="text">{t("account.artist.base")}</b>,
								<button
									type="button"
									key="button"
									className="subscribe-button is-enabled"
									style={{ marginLeft: "15px" }}
								>
									{t("account.artist.upgrade")}
								</button>,
							]}
							hash={"#erbjudanden"}
						/>
					</div>
				)}
				{isArtist && hasMemberfulSubscription && !isStripeSubscriber && (
					<div
						className={inputHolderFullClass + " input-holder--flex"}
						style={{ display: "flex", flexFlow: "column" }}
					>
						<a
							href="https://artworks.memberful.com/account/orders"
							target="_blank"
						>
							<i style={{ fontWeight: "bold", marginRight: "5px" }}>
								{t("account.artist.pro")}
							</i>{" "}
							{artist.billing.premiumType
								? t(`account.artist.${artist.billing.premiumType}`)
								: ""}
							<ARROW_RIGHT
								height="20"
								width="20"
								x="20px"
								alt="arrow"
								className="arrow"
							/>
						</a>
						<a
							href="https://artworks.memberful.com/account/card/edit"
							target="_blank"
						>
							<p style={{ marginBottom: 0 }}>{t("account.artist.payInfo")}</p>
							<ARROW_RIGHT
								height="20"
								width="20"
								alt="arrow"
								className="arrow"
							/>
						</a>
					</div>
				)}
				{/* If user is an active subscriber through Klarna: */}
				{isArtist &&
					isKlarnaSub(artist) &&
					isPremium(artist) &&
					!isStripeSubscriber && (
						<div
							className={inputHolderFullClass + " input-holder--flex"}
							style={{ display: "flex", flexFlow: "column" }}
						>
							<legend className="legend-nomargin">
								Artworks <i>PRO</i> {` ${subscriptionType}`}
							</legend>
							{klarnaSubscriptionCancelled && finalDates && (
								<div>
									{!finalDates.finalPaymentDate && finalDates.finalEndDate ? (
										<p>
											{t("account.subscriptionCancelled")}{" "}
											{finalDates.finalEndDate.slice(0, 10)}.
										</p>
									) : (
										<p>{t("account.subscriptionCancelled")}.</p>
									)}
									{finalDates.finalPaymentDate && (
										<p>
											{t("account.finalPaymentDate")}{" "}
											{finalDates.finalPaymentDate.slice(0, 10)}.
										</p>
									)}
								</div>
							)}
							{klarnaSubscriptionCancelled && !finalDates && (
								<div>
									<p>{t("account.subscriptionCancelled")}.</p>
								</div>
							)}
							{!klarnaSubscriptionCancelled && (
								<button
									type="button"
									className="purple-button--wide button-nomargin"
									style={{
										width: "200px",
										marginBottom: "30px",
									}}
									onClick={() => props.endArtistProSub()}
								>
									{t("account.endSubscription")}
								</button>
							)}
						</div>
					)}
				{/* If user is an active subscriber through Stripe: */}
				{isArtist &&
					stripeCustomerId &&
					isStripeSubscriber &&
					isPremium(artist) && (
						<div
							className={inputHolderFullClass + " input-holder--flex"}
							style={{ display: "flex", flexFlow: "column" }}
						>
							<legend className="legend-nomargin">
								Artworks <i>PRO</i>
							</legend>
							<button
								type="button"
								className="purple-button--wide button-nomargin"
								style={{
									width: "200px",
									marginBottom: "30px",
								}}
								onClick={() => openStripePortal(stripeCustomerId)}
							>
								{t("account.yourAccount")}
							</button>
						</div>
					)}
				{!isAdmin && !userIsSociety && (
					<div
						className={inputHolderFullClass}
						style={{ marginBottom: "1.54em" }}
					>
						<label htmlFor="" style={{ width: "auto" }}>
							{t("TOC.agreeToArtworks")}{" "}
							<a
								style={{ textDecoration: "underline" }}
								target="_blank"
								href={tocLink}
							>
								{t("TOC.termsAndConditions")}{" "}
							</a>
						</label>
						{/*Moved to GallerySettings*/}
						{/* && userIsSociety &&
              <label htmlFor="">{t('TOC.andThis')}{' '}
                <a style={{ textDecoration: 'underline' }} target="_blank" href={ TermsAndConditions.pdpa(i18next.language) }>
                  {t('TOC.PDPA')}
                </a>
              </label>
            */}
						{"."}
						{/* userIsSociety && gallery &&
              <PDPA galleryId={ gallery._id } isAdmin={ false } />
            */}
					</div>
				)}
				{isAdmin && (
					<div className={fieldsetClass}>
						<legend className="legend-nomargin">
							Club {t("premiumMembership")} (Admin)
						</legend>
						<div className={inputHolderFullClass}>
							<Field
								name="billing.membershipLevel"
								options={MEMBERSHIPS}
								component={Select}
							/>
						</div>
						{isPro && (
							<div className={inputHolderFullClass}>
								<p>Became Pro</p>
								<Field
									name="billing.premiumDate"
									component={SingleDateTimePicker}
								/>
							</div>
						)}
					</div>
				)}
				{/*Save button*/}
				<div className="fieldset">
					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("save")}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

UserAccountForm = reduxForm({
	form: "userAccount",
})(UserAccountForm);

UserAccountForm = connect(
	(state, ownProps) => {
		const userIsSociety = isUserArtSociety(state.auth.user);

		return { userIsSociety };
	},
	(dispatch) => {
		return {};
	}
)(UserAccountForm);

export default withNamespaces("common")(UserAccountForm);
