import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	change,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitArtist,
	loadArtist,
	initArtist,
} from "../../actions/FormActions/ArtistFormActions";
import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
} from "../../actions/FormActions/ArtworkFormActions";
import { hideArtistModal } from "../../actions/FormActions/ArtistFormActions";
import {
	fetchScrapedArtist,
	acceptScrapedArtist,
} from "../../actions/ScrapedActions";
import ArtistForm from "../Artist/ArtistForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import Main from "../../components/Main/Main";
import { withRouter } from "react-router";
import { saveArtwork } from "../../actions/ArtworkActions";
import {
	getGalleryOptions,
	getArtistOptions,
} from "../../actions/PickersActions";
import { getObjectProp } from "../../utils/Helpers";
import Modal from "../../components/Modal/Modal";

class AddArtist extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		const artistId = this.props.params.artistId;
		this.props.dispatch(fetchScrapedArtist(artistId));
		this.props.dispatch(initArtist());
	}
	componentWillReceiveProps(newProps) {
		const artistId = this.props.params.artistId;
		let artist = newProps.scrapedArtists[artistId];
		if (this.props.scrapedArtists !== newProps.scrapedArtists && artist) {
			artist.status = "draft";
			this.props.dispatch(loadArtist(artist));
		}
	}

	handleSubmit = () => {
		const artistId = this.props.params.artistId;
		let {
			form: { artist },
		} = this.props;
		delete artist._id;
		this.props.dispatch(acceptScrapedArtist(artistId));
		this.props.dispatch(submitArtist(artist, {}));
	};

	render() {
		const { isAdmin, scrapedArtists } = this.props;
		const artistId = this.props.params.artistId;

		const artist = scrapedArtists[artistId];

		return (
			<div>
				<Main>
					<ArtistForm onSubmit={this.handleSubmit} isAdmin={isAdmin} />
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		scraped: { scrapedArtists },
		auth: { user, isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return {
		scrapedArtists,
		user,
		isAdmin,
		form,
	};
}

export default connect(mapStateToProps)(withRouter(AddArtist));
