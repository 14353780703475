import React, { Component } from "react";
import { connect } from "react-redux";
import { DraftJS, insertDataBlock } from "megadraft";
import Dropzone from "react-dropzone";
import { MegadraftIcons } from "megadraft";
import { uploadPostImage } from "../../../actions/PostActions";

const FIVE_MIB = 5242880;

class ImageButton extends Component {
  uploadImage = (file) => {
    if (!this.props.uploadImage) {
      return;
    }
    this.props.uploadImage(file)
      .then((upload) => {
        const data = {
          type: "aw_image",
          ...upload.response,
        };
        this.props.onChange(insertDataBlock(this.props.editorState, data));
      })
      .catch((err) => {
        alert("Upload failed!");
      });
  };

  onDrop = (files) => {
    const reader = new FileReader();
    const file = files[0];
    reader.onload = (upload) => {
      const image = {
        URI: upload.target.result,
        fileName: file.name,
        contentType: file.type,
      };
      this.uploadImage(image);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    return (
      <Dropzone
        className={this.props.className}
        onDrop={this.onDrop}
        accept="image/jpeg,image/jpg,image/png"
        maxSize={FIVE_MIB}
      >
        <MegadraftIcons.ImageIcon style={{ position: "absolute", left: "4px", top: "4px" }} />
      </Dropzone>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (image) => dispatch(uploadPostImage(image)),
});

export const ConnectedImageButton = connect(null, mapDispatchToProps)(ImageButton);
