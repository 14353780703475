import React, { Component } from "react";
import { withRouter, Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Main from "../../components/Main/Main";
import { fetchInfluencers } from "../../actions/InfluencerActions";
import {
	submitInfluencer,
	deleteInfluencer,
} from "../../actions/FormActions/InfluencerFormActions";
import { fetchPrintArtists } from "../../actions/PrintActions";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import InfluencerForm from "./InfluencerForm";

class Influencer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
			artistImageOptions: [],
		};
	}

	componentWillMount() {
		this.fetchData(this.props);
	}

	componentWillReceiveProps(nextProps) {
		const { influencer, printArtists: nextArtists } = nextProps;
		const { printArtists: prevArtists } = this.props;

		if (nextProps.deletedInfluencer) {
			return this.props.router.push("/influencers");
		}

		if (
			!this.state.initialized &&
			nextProps.influencer &&
			nextProps.influencer._id
		) {
			this.setState(
				{
					initialized: true,
				},
				() => {
					this.props.dispatch(initialize("influencer", influencer));
				}
			);
		} else if (this.props.form.submitting && !nextProps.form.submitting) {
			// Redirect to influencers/slug after saving.
			if (nextProps.influencer && nextProps.influencer.slug) {
				this.props.router.replace(`/influencers/${nextProps.influencer.slug}`);
			}
		}

		// For copying artist image from a print.
		if (Object.keys(prevArtists).length !== Object.keys(nextArtists).length) {
			const artists = Object.keys(nextArtists)
				.map((key) => nextArtists[key])
				.sort(this.sortByName);
			const options = [];
			artists.forEach((artist) => {
				options.push({
					label: artist.artistName,
					value: artist.artistImage,
				});
			});

			this.setState({
				artistImageOptions: options.sort(this.sortByLabel),
			});
		}
	}

	fetchData = (props) => {
		const influencerId = props.params.influencerId;
		if (influencerId === "new") return;

		this.setState(
			{
				initialized: false,
			},
			() => {
				this.props.dispatch(fetchInfluencers(influencerId));
				this.props.dispatch(fetchPrintArtists());
			}
		);
	};

	handleSubmit = () => {
		const {
			form: { influencer, initial },
		} = this.props;
		this.props.dispatch(submitInfluencer(influencer, initial));
	};

	handleRemove = () => {
		const influencer = this.props.form.influencer;
		this.props.dispatch(deleteInfluencer(influencer));
	};

	render() {
		const { isAdmin, influencer, influencers, t } = this.props;
		const influencerId = this.props.params.influencerId;

		if (!isAdmin) return null;

		return (
			<Main>
				<div className="influencers">
					<div style={{ marginBottom: "30px" }}>
						<Link
							to="/influencers"
							className="purple-button--wide purple-button--wide--mod_create"
							style={{ minWidth: "180px" }}
						>
							{t("list.stepBack")}
						</Link>
					</div>
					<InfluencerForm
						onSubmit={this.handleSubmit}
						influencer={influencer}
						isAdmin={isAdmin}
						artistImageOptions={this.state.artistImageOptions}
						t={t}
					/>
					{isAdmin && (
						<div className="fieldset">
							<legend style={{ paddingTop: 40 }}>
								{t("common:dangerZone")}
							</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={() => {
									let confirm = window.confirm(t("common:areYouSure"));
									if (confirm) this.handleRemove();
								}}
							>
								{"Delete influencer"}
							</button>
						</div>
					)}
				</div>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		influencer: { influencer, allInfluencers: influencers, deletedInfluencer },
		print: { printArtists },
	} = state;
	const isAdmin = user.role === "admin";

	const form = {
		submitting: isSubmitting("influencer")(state),
		submitSucceeded: hasSubmitSucceeded("influencer")(state),
		submitFailed: hasSubmitFailed("influencer")(state),
		influencer: getFormValues("influencer")(state),
		initial: getFormInitialValues("influencer")(state),
	};

	return {
		isAdmin,
		influencer,
		influencers,
		form,
		deletedInfluencer,
		printArtists,
	};
}

const translated = withNamespaces("artworks")(Influencer);
export default connect(mapStateToProps)(withRouter(translated));
