import React from "react";
import { ConnectedImageButton } from './ImageButton'
import ImageBlock from "./ImageBlock";

console.log("🔍 ConnectedImageButton:", ConnectedImageButton);

export default {
  title: "Image",
  type: "aw_image",
  buttonComponent: (props) => <ConnectedImageButton {...props} />, 
  blockComponent: ImageBlock
};
