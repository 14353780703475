import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FieldArray,
	FormSection,
	hasSubmitSucceeded,
	reduxForm,
} from "redux-form";
import UserList from "../../components/FormComponents/UserList/UserList";

let AccessForm = (props) => {
	const { handleSubmit, pristine, submitting, isAdmin, submitSucceeded } =
		props;

	return (
		<form onSubmit={handleSubmit} className="Form">
			<div>
				<legend>{"Edit who can access this material"}</legend>
				<FieldArray name="allowed" component={UserList} type={props.type} />
			</div>

			<button
				className={
					"button" + (pristine && submitSucceeded ? " is-success" : "")
				}
				type="submit"
				disabled={pristine || submitting}
			>
				{pristine && submitSucceeded ? "Saved" : "Save"}
			</button>
		</form>
	);
};

AccessForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	isAdmin: PropTypes.bool,
};

AccessForm = reduxForm({
	form: "access",
})(AccessForm);

export default AccessForm;
