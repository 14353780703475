import mixpanel from 'mixpanel-browser'

export default function createMiddleware(token) {
  if (!mixpanel.hasOwnProperty('get_distinct_id')) {
    mixpanel.init(token, { loaded: function(mixpanel) {
        const distinct_id = mixpanel.get_distinct_id();
      }
    })
  }
  return store => next => action => {
    if (action.analytics) {
      const analytics = action.analytics;
      let setProps = {};
      switch (analytics.setProps) {
        case 'user':
            setProps['$email'] = action.response.email
            setProps['$name'] = action.response.name
            setProps['Role'] = action.response.role
            setProps['Gallery'] = action.response.gallery ? action.response.gallery.name : null
            setProps['Artist'] = action.response.artist ? action.response.artist.name : null
          break;
        default:
          setProps = false;
      }
      try {
        if (analytics.track) {
          const payload = analytics.payload || {}
          mixpanel.track(analytics.track, payload)
        }
        if (analytics.incrementScore) {
          mixpanel.people.increment('User score', analytics.incrementScore)
        }
        if (analytics.login && !analytics.login.register) {
          console.log('Identify: ', analytics.login._id);
          mixpanel.identify(analytics.login._id)
          mixpanel.people.set({
            '$email': analytics.login.email,
            '$name': analytics.login.name,
            'Role': analytics.login.role
          })
          mixpanel.register({
            '$email': analytics.login.email,
            '$name': analytics.login.name,
            'Role': analytics.login.role
          })
        }
        if (analytics.register) {
          console.log('Alias: ', analytics.register._id);
          mixpanel.alias(analytics.register._id)
          mixpanel.people.set({
            '$email': analytics.register.email,
            'Role': analytics.register.role
          })
          mixpanel.register({
            '$email': analytics.register.email,
            'Role': analytics.register.role
          })
          mixpanel.people.set_once({
            '$created': new Date().toISOString()
          })
        }
        if (setProps) {
          mixpanel.people.set(setProps)
        }
        if (analytics.reset) {
          mixpanel.reset()
        }
      } catch (e) {
        console.log(e);
      }
    }
    return next(action)
  }
}
