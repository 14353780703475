import React, { Component } from "react";
import { withRouter } from "react-router";

const Select = (props) => {
	const disabled = props.disabled || false;
	const children = props.children || null;
	const showPlaceholderInitially = props.showPlaceholderInitially || false;
	// Until user opens menu, make placeholder the only option visible.
	const placeholderOnly =
		showPlaceholderInitially && !props.meta.visited ? true : false;
	const extraStyle = props.style || {};

	const options = [];
	props.options.forEach((option) => {
		if (option.value === "placeholder") {
			options.push(
				<option key={option.value} value={""} disabled={true}>
					{option.name}
				</option>
			);
		} else if (!placeholderOnly) {
			options.push(
				<option key={option.value} value={option.value}>
					{option.name}
				</option>
			);
		}
	});

	function handleOption(value) {
		if (value.includes("https://")) {
			window.open(value, "_blank");
		} else {
			props.input.onChange(value);
			props.input.onBlur(value);
		}
	}
	// For signatureArtwork in ArtistForm.jsx, props.input.value is the whole artwork, we only want the id for select.
	if (props.useSelectedId) {
		if (props.input.value && props.input.value._id) {
			props.input.value = props.input.value._id;
		}
	}

	//Prev disabled opacity was 0.3
	return (
		<div
			className={
				"SimpleSelect Input--text" +
				(props.meta.invalid ? " is-error" : "") +
				(props.meta.valid ? " is-valid" : "") +
				(props.meta.required ? " is-required" : "")
			}
		>
			<select
				{...props.input}
				type="checkbox"
				disabled={disabled}
				style={Object.assign({}, { opacity: disabled ? 0.6 : 1 }, extraStyle)}
				onChange={(event) => handleOption(event.target.value)}
			>
				{options}
			</select>
			{children}
		</div>
	);
};

export default Select;
