import { CALL_API } from "../utils/api";
import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

// fetch artworks

export const ARTWORKS_REQUEST = "ARTWORKS_REQUEST";
export const ARTWORKS_SUCCESS = "ARTWORKS_SUCCESS";
export const ARTWORKS_FAILURE = "ARTWORKS_FAILURE";

// Uses the API to get a artwork
export function fetchArtworks(userId, skip = 0) {
	const endpoint = userId ? "artworks?userId=" + userId : "artworks";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORKS_REQUEST, ARTWORKS_SUCCESS, ARTWORKS_FAILURE],
		},
	};
}

export function fetchArtworksForGallery(galleryId) {
	const endpoint = "artworks/gallery/" + galleryId;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORKS_REQUEST, ARTWORKS_SUCCESS, ARTWORKS_FAILURE],
		},
	};
}

export function fetchArtworksWithQuery(dbQuery) {
	// For old GET request. Use POST instead.
	// const endpoint = `artworks/dbquery?dbquery=${encodeURIComponent(JSON.stringify(dbQuery))}`
	const endpoint = "artworks/dbquery";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORKS_REQUEST, ARTWORKS_SUCCESS, ARTWORKS_FAILURE],
			method: "POST",
			body: { dbquery: dbQuery },
		},
	};
}

export function fetchArtworksForArtist(artistId) {
	const endpoint = "artworks/artist/" + artistId;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORKS_REQUEST, ARTWORKS_SUCCESS, ARTWORKS_FAILURE],
		},
	};
}

// export const ARTWORKS_ALL_REQUEST = 'ARTWORKS_ALL_REQUEST'
// export const ARTWORKS_ALL_SUCCESS = 'ARTWORKS_ALL_SUCCESS'
// export const ARTWORKS_ALL_FAILURE = 'ARTWORKS_ALL_FAILURE'
//
// // Uses the API to get all artwork
// export function fetchAllArtworks() {
// 	return {
// 		[CALL_API]: {
// 			endpoint: 'artworks',
// 			authenticated: true,
// 			types: [ARTWORKS_ALL_REQUEST, ARTWORKS_ALL_SUCCESS, ARTWORKS_ALL_FAILURE]
// 		}
// 	}
// }
export const ARTWORKS_ALL_REQUEST = "ARTWORKS_ALL_REQUEST";
export const ARTWORKS_ALL_SUCCESS = "ARTWORKS_ALL_SUCCESS";
export const ARTWORKS_ALL_FAILURE = "ARTWORKS_ALL_FAILURE";

export function fetchAllArtworksFast(dateStart, dateEnd) {
	const endpoint = `artworks/fetch-all-fast?start=${dateStart}&end=${dateEnd}`;
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [ARTWORKS_ALL_REQUEST, ARTWORKS_ALL_SUCCESS, ARTWORKS_ALL_FAILURE],
		},
	};
}

export const ARTWORKS_ALL_POPULATE_REQUEST = "ARTWORKS_ALL_POPULATE_REQUEST";
export const ARTWORKS_ALL_POPULATE_SUCCESS = "ARTWORKS_ALL_POPULATE_SUCCESS";
export const ARTWORKS_ALL_POPULATE_FAILURE = "ARTWORKS_ALL_POPULATE_FAILURE";

export function fetchAllArtworksPopulated() {
	// Request was too slow, timed out.
	return { type: "NOT-DOING-ANYTHING" };
	return {
		[CALL_API]: {
			endpoint: "artworks/fetch-all-populated",
			authenticated: true,
			types: [
				ARTWORKS_ALL_POPULATE_REQUEST,
				ARTWORKS_ALL_POPULATE_SUCCESS,
				ARTWORKS_ALL_POPULATE_FAILURE,
			],
		},
	};
}

// fetch artwork

export const ARTWORK_REQUEST = "ARTWORK_REQUEST";
export const ARTWORK_SUCCESS = "ARTWORK_SUCCESS";
export const ARTWORK_FAILURE = "ARTWORK_FAILURE";

// Uses the API to get a artwork
export function fetchArtwork(id) {
	return {
		[CALL_API]: {
			endpoint: "artworks/" + id,
			authenticated: true,
			types: [ARTWORK_REQUEST, ARTWORK_SUCCESS, ARTWORK_FAILURE],
		},
	};
}

// save artwork

export const ARTWORK_SAVE_REQUEST = "ARTWORK_SAVE_REQUEST";
export const ARTWORK_SAVE_SUCCESS = "ARTWORK_SAVE_SUCCESS";
export const ARTWORK_SAVE_FAILURE = "ARTWORK_SAVE_FAILURE";

// Uses the API to save an artwork
export function saveArtwork(
	artwork,
	displayMessage = true,
	removedArtistId = null
) {
	const method = artwork._id ? "PUT" : "POST"; // update if id is present, otherwise create
	const endpoint = artwork._id ? "artworks/" + artwork._id : "artworks";
	const body = { artwork, removedArtistId };
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORK_SAVE_REQUEST, ARTWORK_SAVE_SUCCESS, ARTWORK_SAVE_FAILURE],
			method: method,
			body: body,
			message: displayMessage ? translate("artworkSaved") : "NO MESSAGE",
			analytics: {
				track: "Saved artwork",
				payload: {
					artwork: artwork._id,
				},
				incrementScore: 1,
			},
		},
	};
}

export function createCopyForArtist(artworkId, artistId) {
	const endpoint = `artworks/${artworkId}/create-copy`;
	const body = { artistId };
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTWORK_SAVE_REQUEST, ARTWORK_SAVE_SUCCESS, ARTWORK_SAVE_FAILURE],
			method: "PUT",
			body: body,
			message: "NO MESSAGE",
		},
	};
}

export const ARTWORK_FROM_IMAGE_REQUEST = "ARTWORK_FROM_IMAGE_REQUEST";
export const ARTWORK_FROM_IMAGE_SUCCESS = "ARTWORK_FROM_IMAGE_SUCCESS";
export const ARTWORK_FROM_IMAGE_FAILURE = "ARTWORK_FROM_IMAGE_FAILURE";

export const createArtworkFromImage = (image, params, dispatch) => {
	return new Promise((resolve, reject) => {
		const endpoint = "artworks/fromimage";
		const body = {
			image,
			params,
		};

		dispatch(createArtworkFromImageRequest());
		Api(endpoint, "POST", false, body)
			.then((json) => {
				dispatch(createArtworkFromImageSuccess(json));
				// Return for progress bar.
				resolve(json);
			})
			.catch((error) => {
				dispatch(createArtworkFromImageFail(error));
				resolve(false);
			});
	});
};

function createArtworkFromImageRequest() {
	return {
		type: ARTWORK_FROM_IMAGE_REQUEST,
	};
}

function createArtworkFromImageSuccess(json) {
	return {
		type: ARTWORK_FROM_IMAGE_SUCCESS,
		response: json,
		// message: translate('artworkSaved'),
		message: "NO MESSAGE",
	};
}

function createArtworkFromImageFail(error) {
	return {
		type: ARTWORK_FROM_IMAGE_FAILURE,
		message: error,
	};
}

// delete artwork
export const ARTWORK_DELETE_REQUEST = "ARTWORK_DELETE_REQUEST";
export const ARTWORK_DELETE_SUCCESS = "ARTWORK_DELETE_SUCCESS";
export const ARTWORK_DELETE_FAILURE = "ARTWORK_DELETE_FAILURE";

// Uses the API to get a artwork
export function deleteArtwork(artwork) {
	return {
		[CALL_API]: {
			endpoint: "artworks/" + artwork._id,
			authenticated: true,
			types: [
				ARTWORK_DELETE_REQUEST,
				ARTWORK_DELETE_SUCCESS,
				ARTWORK_DELETE_FAILURE,
			],
			method: "delete",
			analytics: {
				track: "Deleted Artwork",
				payload: {
					id: artwork._id,
					name: artwork.title,
				},
				incrementScore: 1,
			},
		},
	};
}

export const CLEAR_ARTWORKS = "artworkActions/CLEAR_ARTWORKS";

export function clearArtworks() {
	return {
		type: CLEAR_ARTWORKS,
	};
}

export const ARTWORK_MAIL_PREVIEW_REQUEST =
	"artworkActions/ARTWORK_MAIL_PREVIEW_REQUEST";
export const ARTWORK_MAIL_PREVIEW_SUCCESS =
	"artworkActions/ARTWORK_MAIL_PREVIEW_SUCCESS";
export const ARTWORK_MAIL_PREVIEW_FAILURE =
	"artworkActions/ARTWORK_MAIL_PREVIEW_FAILURE";

export function fetchArtworkEmailPreview(artworkId) {
	return {
		[CALL_API]: {
			endpoint: `artworks/preview/${artworkId}`,
			authenticated: true,
			types: [
				ARTWORK_MAIL_PREVIEW_REQUEST,
				ARTWORK_MAIL_PREVIEW_SUCCESS,
				ARTWORK_MAIL_PREVIEW_FAILURE,
			],
		},
	};
}

export const ARTWORK_MAIL_PREVIEW_RESET =
	"artworkActions/ARTWORK_MAIL_PREVIEW_RESET";

export function resetArtworkEmailPreview() {
	return {
		type: ARTWORK_MAIL_PREVIEW_RESET,
	};
}

export const ARTWORK_ORDER_REQUEST = "artworkActions/ARTWORK_ORDER_REQUEST";
export const ARTWORK_ORDER_SUCCESS = "artworkActions/ARTWORK_ORDER_SUCCESS";
export const ARTWORK_ORDER_FAILURE = "artworkActions/ARTWORK_ORDER_FAILURE";

export function reorderArtwork(artworkId, newIndex, artistId, artworkIds) {
	const body = { newIndex, artistId, artworkIds };
	return {
		[CALL_API]: {
			endpoint: `artworks/${artworkId}/reorder`,
			authenticated: true,
			types: [
				ARTWORK_ORDER_REQUEST,
				ARTWORK_ORDER_SUCCESS,
				ARTWORK_ORDER_FAILURE,
			],
			method: "PUT",
			message: "NO MESSAGE",
			body,
		},
	};
}

export const INDEX_ANIMATION_RESET = "artworkActions/INDEX_ANIMATION_RESET";

export function resetIndexAnimation() {
	return {
		type: INDEX_ANIMATION_RESET,
	};
}
