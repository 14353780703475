import React, { Component } from "react";
import moment               from "moment"
import i18next              from 'i18next'

export const CENTER_IMAGE_OPTIONS = [
  { name: 'None', value: '' },
  { name: 'Center', value: 'center' },
  { name: 'Top', value: 'top' },
  { name: 'Bottom', value: 'bottom' },
  { name: 'Left', value: 'left' },
  { name: 'Right', value: 'right' },
  { name: 'Top Left', value: 'top left' },
  { name: 'Top Right', value: 'top right' },
  { name: 'Bottom Left', value: 'bottom left' },
  { name: 'Bottom Right', value: 'bottom right' },
]

export const videoTooltip = () => {
  const iframeStart = '<iframe width="560" height="315" src="'
  const iframeEnd = '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  const content = (
    <div>
      <p style={{color: 'white'}}>För att bädda in din videofil. Ladda upp filen på Youtube/Vimeo. Välj “bädda in” och klistra in den del vi markerat i exemplet nedan:</p>
      <p style={{color: 'white'}}>{iframeStart}<b style={{fontSize: '1.2em', overflowWrap: 'break-word'}}>https://www.youtube.com/embed/kknVfOJZ1w0</b>{iframeEnd}</p>
    </div>
  )
  return ({
    text: content,
    popupPos: 'top',
  })
}

export const getYearList = () => {
  const isSwedish = i18next.language === 'sv'
	const YEARS = []
  YEARS.push({
    name: isSwedish ? 'Välj' : 'Select',
    value: 'placeholder'
  })
  for (let i = moment().year(); i >= 1900; i--) {
    YEARS.push(
      {name:i, value:i}
    )
  }
  return YEARS
}
