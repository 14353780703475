import { combineReducers } from "redux";
import { userReducer } from "./UserReducer";
import { scrapedReducer } from "./ScrapedReducer";
import { galleryReducer } from "./GalleryReducer";
import { showReducer } from "./ShowReducer";
import { artworkReducer } from "./ArtworkReducer";
import { postReducer } from "./PostReducer";
import { authReducer } from "@artworkslab/sharedmodules/src/reducers/AuthReducer";
import { dashboardReducer } from "./DashboardReducer";
import { summaryReducer } from "./DashboardReducers/SummaryReducer";
import { currentShowReducer } from "./DashboardReducers/CurrentShowReducer";
import { pastShowsReducer } from "./DashboardReducers/PastShowsReducer";
import { upcomingShowsReducer } from "./DashboardReducers/UpcomingShowsReducer";
import { popularArtworkReducer } from "./DashboardReducers/PopularArtworkReducer";
import { mentionsReducer } from "./DashboardReducers/MentionsReducer";
import { visitsReducer } from "./DashboardReducers/VisitsReducer";
import { popularOnAwReducer } from "./DashboardReducers/PopularOnAwReducer";
import { mostPopularReducer } from "./DashboardReducers/MostPopularReducer";
import { leastPopularReducer } from "./DashboardReducers/LeastPopularReducer";
import { adminDataReducer } from "./DashboardReducers/AdminDataReducer";
import { latestSalesReducer } from "./DashboardReducers/LatestSalesReducer";
import { categoryReducer } from "./CategoryReducer";
import { artistReducer } from "./ArtistReducer";
import { accessReducer } from "./AccessReducer";
import { eventReducer } from "./EventReducer";
import { pickersReducer } from "./PickersReducer";
import { appReducer } from "./AppReducer";
import { orderReducer } from "./OrderReducer";
import { selectionReducer } from "./SelectionReducer";
import { registryReducer } from "./RegistryReducer";
import { raffleReducer } from "./RaffleReducer";
import { archiveReducer } from "./ArchiveReducer";
import { billectaReducer } from "./BillectaReducer";
import { announcementReducer } from "./AnnouncementReducer";
import { subscriptionReducer } from "./SubscriptionReducer";
import { stripeSubReducer } from "./Stripe/StripeSubReducer";
import { artworkInvoiceReducer } from "./ArtworkInvoiceReducer";
import { artworkTransactionReducer } from "./ArtworkTransactionReducer";
import { discountCodeReducer } from "./DiscountCodeReducer";
import { emailReducer } from "./EmailReducer";
import { dataReducer } from "./DataReducer";
import { modalReducer } from "./ModalReducer";
import { printReducer } from "./PrintReducer";
import { influencerReducer } from "./InfluencerReducer";
import { galleryFormReducer } from "./FormReducers/GalleryFormReducer";
import { accessFormReducer } from "./FormReducers/AccessFormReducer";
import { eventFormReducer } from "./FormReducers/EventFormReducer";
import { artworkFormReducer } from "./FormReducers/ArtworkFormReducer";
import { artistFormReducer } from "./FormReducers/ArtistFormReducer";
import { showFormReducer } from "./FormReducers/ShowFormReducer";
import { userFormReducer } from "./FormReducers/UserFormReducer";
import { selectionFormReducer } from "./FormReducers/SelectionFormReducer";
import { ratedReducer } from "@artworkslab/sharedmodules/src/reducers/RatedReducer";
import { artSocietyReducer } from "@artworkslab/sharedmodules/src/reducers/ArtSocietyReducer";
import { newsletterReducer } from "@artworkslab/sharedmodules/src/reducers/NewsletterReducer";
import { xmasReducer } from "@artworkslab/sharedmodules/src/reducers/XmasReducer";
import { contactReducer } from "@artworkslab/sharedmodules/src/reducers/ContactReducer";
import { enquiryReducer } from "@artworkslab/sharedmodules/src/reducers/EnquiryReducer";
import { billingReducer } from "@artworkslab/sharedmodules/src/reducers/Stripe/BillingReducer";
import { artworkReducer as artPreviewReducer } from "@artworkslab/sharedmodules/src/reducers/ArtworkReducer";
import { notificationReducer } from "@artworkslab/sharedmodules/src/reducers/NotificationReducer";
import { reducer as formReducer } from "redux-form";

export const Reducers = {
	auth: authReducer,
	user: userReducer,
	gallery: galleryReducer,
	show: showReducer,
	artwork: artworkReducer,
	order: orderReducer,
	post: postReducer,
	dashboard: dashboardReducer,
	summary: summaryReducer,
	currentShow: currentShowReducer,
	pastShows: pastShowsReducer,
	upcomingShows: upcomingShowsReducer,
	popularArtwork: popularArtworkReducer,
	mentions: mentionsReducer,
	visits: visitsReducer,
	popularOnAw: popularOnAwReducer,
	mostPopular: mostPopularReducer,
	leastPopular: leastPopularReducer,
	adminData: adminDataReducer,
	latestSales: latestSalesReducer,
	category: categoryReducer,
	artist: artistReducer,
	event: eventReducer,
	access: accessReducer,
	pickers: pickersReducer,
	accessForm: accessFormReducer,
	galleryForm: galleryFormReducer,
	eventForm: eventFormReducer,
	artworkForm: artworkFormReducer,
	showForm: showFormReducer,
	userForm: userFormReducer,
	artistForm: artistFormReducer,
	app: appReducer,
	form: formReducer,
	scraped: scrapedReducer,
	rated: ratedReducer,
	artSociety: artSocietyReducer,
	selection: selectionReducer,
	selectionForm: selectionFormReducer,
	registry: registryReducer,
	raffle: raffleReducer,
	newsletter: newsletterReducer,
	archive: archiveReducer,
	xmascalendar: xmasReducer,
	billecta: billectaReducer,
	contactList: contactReducer,
	enquiries: enquiryReducer,
	artPreview: artPreviewReducer,
	announcement: announcementReducer,
	subscription: subscriptionReducer,
	stripeSubscriptions: stripeSubReducer,
	artworkInvoice: artworkInvoiceReducer,
	artworkTransaction: artworkTransactionReducer,
	discountCode: discountCodeReducer,
	email: emailReducer,
	billing: billingReducer,
	notifications: notificationReducer,
	data: dataReducer,
	modal: modalReducer,
	print: printReducer,
	influencer: influencerReducer,
};

export const Reducer = combineReducers(Object.assign({}, Reducers));
