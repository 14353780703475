import React from "react";
import { Link } from "react-router";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import { dateMonthShort } from "@artworkslab/sharedmodules/src/DateUtil";
import { updateGalleryValue } from "../../actions/GalleryActions";

// This component to not update state for whole component when inputting values.
// Only updates in database on blur.
class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
	}

	componentDidMount() {
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setValue(nextProps.value);
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	update = (evt) => {
		const { galleryId } = this.props;
		const { value } = this.state;

		const params = {
			"billing.membershipDiscountPercentage": value,
		};

		this.props.dispatch(updateGalleryValue(galleryId, params));
	};

	render() {
		const { value } = this.state;

		return (
			<input
				type="text"
				value={value || ""}
				onChange={(evt) => this.setValue(evt.target.value)}
				onBlur={(evt) => this.update(evt)}
				type={this.props.type}
				min={this.props.min}
				max={this.props.max}
				className="input-field"
			/>
		);
	}
}

const getMembershipLevel = (gallery, t) => {
	const membershipLevel =
		gallery.billing && gallery.billing.membershipLevel
			? t(gallery.billing.membershipLevel)
			: t("noneSet");
	return membershipLevel;
};

const membershipEndDate = (gallery) => {
	const membershipEnds =
		gallery.billing && gallery.billing.membershipEnds
			? gallery.billing.membershipEnds
			: false;
	return membershipEnds;
};

const formattedEndDate = (date, language, t) => {
	const formattedEndDate = date
		? dateMonthShort(date, language, true)
		: t("noneSet");
	return formattedEndDate;
};

const getMembershipDiscount = (gallery, language, t) => {
	const membershipDiscount =
		gallery.billing && gallery.billing.membershipDiscountPercentage
			? gallery.billing.membershipDiscountPercentage
			: 0;
	return membershipDiscount;
};

const createRows = (galleries, language, dispatch, datePassed, t) => {
	return galleries.map((gallery) => {
		const membershipLevel = getMembershipLevel(gallery, t);
		const endDate = membershipEndDate(gallery);
		const membershipEnds = formattedEndDate(endDate, language, t);
		const membershipDiscount = getMembershipDiscount(gallery);
		const isPassedDate = datePassed(endDate);

		return (
			<Tr key={gallery._id} className="customers__row">
				<Td column="name" value={gallery.name}>
					<Link to={`/galleries/${gallery.slug}`}>{gallery.name}</Link>
				</Td>
				<Td column="membershipLevel" value={membershipLevel}>
					{membershipLevel}
				</Td>
				<Td
					column="membershipEnds"
					value={membershipEnds}
					className={
						"customers__data" + (isPassedDate ? " is-passed-date" : "")
					}
				>
					{membershipEnds}
				</Td>
				<Td column="membershipDiscount" value={membershipDiscount}>
					<div style={{ display: "flex" }}>
						<InputField
							value={membershipDiscount}
							galleryId={gallery._id}
							dispatch={dispatch}
							type="number"
							min="0"
							max="100"
						/>
						<div style={{ padding: "2px 0 0 10px" }}>
							{membershipDiscount ? "%" : ""}
						</div>
					</div>
				</Td>
			</Tr>
		);
	});
};

const Customers = ({ galleries, language, dispatch, datePassed, t }) => {
	const rows = createRows(galleries, language, dispatch, datePassed, t);
	return (
		<div className="customers">
			<h3 className="customers__header">{t("customers")}</h3>
			<Table
				className="reactable"
				sortable={[
					"name",
					"membershipLevel",
					"membershipEnds",
					"membershipDiscount",
				]}
				filterable={["name", "membershipLevel", "membershipEnds"]}
				filterPlaceholder={t("searchCustomers")}
				itemsPerPage={50}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="name">{t("customer")}</Th>
					<Th column="membershipLevel">{t("membershipLevel")}</Th>
					<Th column="membershipEnds">{t("membershipEnds")}</Th>
					<Th column="membershipDiscount">{t("membershipDiscount")}</Th>
				</Thead>
				{rows}
			</Table>
		</div>
	);
};

export default Customers;
