import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router";
import Main from "../../components/Main/Main";
import AdminDashboard from "./AdminDashboard";
import AuthorDashboard from "./AuthorDashboard";
import SocietyDashboard from "./SocietyDashboard";
import ArtistDashboard from "./ArtistDashboard";
import ArtistDashboardNew from "./ArtistDashboardNew";
import UserDashboard from "./UserDashboard";
import Metrics from "../../components/Metrics/Metrics";
import GalleryStats from "../../components/GalleryStats/GalleryStats";
import ShowStats from "../../components/ShowStats/ShowStats";
import {
	fetchMetrics,
	fetchArtworksMetrics,
	fetchShowsMetrics,
	fetchGalleryMetrics,
} from "../../actions/DashboardActions";
import { loginSmooch } from "../../actions/UserActions";
import { isPremiumActivated } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class Dashboard extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { authenticated, isAdmin, user, artist, t } = this.props;
		if (authenticated && user.role !== "user") {
			this.props.dispatch(fetchMetrics());
			this.props.dispatch(fetchArtworksMetrics());
		}

		if (
			!isAdmin &&
			user &&
			user.email &&
			(user.role !== "artist" || isPremium(artist))
		) {
			// loginSmooch(user, t)
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			this.props.authenticated !== nextProps.authenticated &&
			nextProps.authenticated
		) {
			this.props.dispatch(fetchMetrics());
			this.props.dispatch(fetchArtworksMetrics());
		}
	}

	render() {
		const {
			user: { role },
			isAdmin,
			valid,
			onboarding,
			location,
		} = this.props;
		const isAuthor = role === "author";
		const isSociety = role === "society";
		const isArtist = role === "artist";
		const isUser = role === "user";
		const hash = location ? location.hash : null;

		if (isAdmin) {
			return (
				<div>
					<Main>
						<AdminDashboard hash={hash} />
						{/*
						<SocietyDashboard />
						<ArtistDashboardNew />
						*/}
					</Main>
				</div>
			);
		} else if (isAuthor) {
			return (
				<div>
					<Main>
						<AuthorDashboard valid={valid} onboarding={onboarding} />
					</Main>
				</div>
			);
		} else if (isSociety) {
			return <SocietyDashboard hash={hash} />;
		} else if (isArtist) {
			return <ArtistDashboard hash={hash} />;
			return (
				<div>
					<Main>
						{/*<ArtistDashboardNew />*/}
						<ArtistDashboard hash={hash} />
					</Main>
				</div>
			);
		} else if (isUser) {
			return (
				<div>
					<Main>
						<UserDashboard
							updateOnLangChange={this.props.update_DO_NOT_REMOVE}
						/>
					</Main>
				</div>
			);
		} else {
			return (
				<div>
					<Main></Main>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin, user, authenticated },
		user: { valid, onboarding },
		artist: { artist },
	} = state;
	return { user, isAdmin, authenticated, valid, onboarding, artist };
}

const translated = withNamespaces("common")(Dashboard);
export default connect(mapStateToProps)(withRouter(translated));
