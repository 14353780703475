import { CALL_API } from '../utils/api'

export const RESET_ALL_OPTIONS = 'RESET_ALL_OPTIONS'

export function resetAllOptions() {
  return {
    type: RESET_ALL_OPTIONS,
  }
}


export const ARTIST_SEARCH_REQUEST = 'ARTIST_SEARCH_REQUEST'
export const ARTIST_SEARCH_SUCCESS = 'ARTIST_SEARCH_SUCCESS'
export const ARTIST_SEARCH_FAILURE = 'ARTIST_SEARCH_FAILURE'

export function getArtistOptions(searchString) {
  const endpoint = `search/portal/artist?searchString=${searchString}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: false,
      types: [ARTIST_SEARCH_REQUEST, ARTIST_SEARCH_SUCCESS, ARTIST_SEARCH_FAILURE]
    }
  }
}

export const RESET_ARTIST_OPTIONS = 'RESET_ARTIST_OPTIONS'

export function resetArtistOptions(initial = false) {
  return {
    type: RESET_ARTIST_OPTIONS,
    initial,
  }
}

export const GALLERY_SEARCH_REQUEST = 'GALLERY_SEARCH_REQUEST'
export const GALLERY_SEARCH_SUCCESS = 'GALLERY_SEARCH_SUCCESS'
export const GALLERY_SEARCH_FAILURE = 'GALLERY_SEARCH_FAILURE'

export function getGalleryOptions(searchString, forEducation) {
  const endpoint = `search/portal/gallery?searchString=${searchString}&forEducation=${forEducation}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: false,
      types: [GALLERY_SEARCH_REQUEST, GALLERY_SEARCH_SUCCESS, GALLERY_SEARCH_FAILURE]
    }
  }
}
export const ART_INSTITUTIONS_SUCCESS = 'ART_INSTITUTIONS_SUCCESS'
// Since on master, elasticsearch wont search for venueType.
export function getArtInstitutions(query) {
  const endpoint = `galleries/query?query=${encodeURIComponent(JSON.stringify(query))}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: false,
      types: [GALLERY_SEARCH_REQUEST, ART_INSTITUTIONS_SUCCESS, GALLERY_SEARCH_FAILURE]
    }
  }
}

export const RESET_GALLERY_OPTIONS = 'RESET_GALLERY_OPTIONS'

export function resetGalleryOptions(initial = false) {
  return {
    type: RESET_GALLERY_OPTIONS,
    initial,
  }
}

export const SHOW_SEARCH_REQUEST = 'SHOW_SEARCH_REQUEST'
export const SHOW_SEARCH_SUCCESS = 'SHOW_SEARCH_SUCCESS'
export const SHOW_SEARCH_FAILURE = 'SHOW_SEARCH_FAILURE'

export function getShowOptions(searchString) {
  const endpoint = `search/portal/show?searchString=${searchString}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: false,
      types: [SHOW_SEARCH_REQUEST, SHOW_SEARCH_SUCCESS, SHOW_SEARCH_FAILURE]
    }
  }
}

export const RESET_SHOW_OPTIONS = 'RESET_SHOW_OPTIONS'

export function resetShowOptions(initial = false) {
  return {
    type: RESET_SHOW_OPTIONS,
    initial,
  }
}

export const POST_SEARCH_REQUEST = 'POST_SEARCH_REQUEST'
export const POST_SEARCH_SUCCESS = 'POST_SEARCH_SUCCESS'
export const POST_SEARCH_FAILURE = 'POST_SEARCH_FAILURE'

export function getPostOptions(searchString) {
  const endpoint = `search/portal/post?searchString=${searchString}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: false,
      types: [POST_SEARCH_REQUEST, POST_SEARCH_SUCCESS, POST_SEARCH_FAILURE]
    }
  }
}

export const RESET_POST_OPTIONS = 'RESET_POST_OPTIONS'

export function resetPostOptions(initial = false) {
  return {
    type: RESET_POST_OPTIONS,
    initial,
  }
}
