import { CALL_API } from '../utils/api'

// fetch categories

export const CATEGORY_REQUEST = 'CATEGORY_REQUEST'
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS'
export const CATEGORY_FAILURE = 'CATEGORY_FAILURE'

// Uses the API to get categories
export function fetchCategories(type) {
	let endpoint = "categories"
	if (type) {
		endpoint += "?type=" + type
	}
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [CATEGORY_REQUEST, CATEGORY_SUCCESS, CATEGORY_FAILURE]
		}
	}
}

// save category

export const CATEGORY_SAVE_REQUEST = 'CATEGORY_SAVE_REQUEST'
export const CATEGORY_SAVE_SUCCESS = 'CATEGORY_SAVE_SUCCESS'
export const CATEGORY_SAVE_FAILURE = 'CATEGORY_SAVE_FAILURE'

// Uses the API to save a category
export function saveCategory(category) {
	const method = category._id ? "PUT" : "POST" // update if id is present, otherwise create
	const endpoint = category._id ? 'categories/'+category._id : 'categories'
	console.log('saving');
	console.log(category);
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [CATEGORY_SAVE_REQUEST, CATEGORY_SAVE_SUCCESS, CATEGORY_SAVE_FAILURE],
			method: method,
			body: category
		}
	}
}



// delete category

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAILURE = 'CATEGORY_DELETE_FAILURE'

// Uses the API to get a category
export function deleteCategory(category) {
	return {
		[CALL_API]: {
			endpoint: "categories/"+category._id,
			authenticated: true,
			types: [CATEGORY_DELETE_REQUEST, CATEGORY_DELETE_SUCCESS, CATEGORY_DELETE_FAILURE],
			method: "delete"
		}
	}
}
