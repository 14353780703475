'use strict'
import {
  DraftJS,
  MegadraftIcons,
  createTypeStrategy
} from "megadraft";
import LinkComponent          from './LinkComponent'
import LinkInput              from "megadraft/lib/entity_inputs/LinkInput";
import InternalLinkInput      from './InternalLink/InternalLinkInput'
import InternalLinkComponent  from './InternalLink/InternalLinkComponent'


export const renderHelpers = {
    blockRenderers: {
      atomic: (block) => {
        let data = block.getData()
        const type = data.get('type')
        if (type === 'aw_image') {
          const src = data.get('src')
          const subtitle = data.get('subtitle')
          return `
          <div class="article-image">
            <img style="width: 100%; height: auto;" src="${src}" />
            ${subtitle ? '<p>'+subtitle+ '</p>' : ''}
          </div>`
        }else if (type === 'aw_embed_iframe') {
          const src = data.get('src')
          const width = data.get('width')
          const height = data.get('height')
          const subtitle = data.get('subtitle')
          return `
          <div class="article-iframe">
            <iframe src="${src}" height="${height}" width="${width}" allowfullscreen frameborder="0"></iframe>
            ${subtitle ? '<p>'+subtitle+ '</p>' : ''}
          </div>`
        }
      },
    }
  }


export const parseHTMLhelper = {
  customBlockFn: (element) => {
    let {tagName, style, className, id, children } = element;
    //Handle images
    if (children && children.length && children[0].tagName === 'IFRAME') {
      const { src, width, height } = children[0]
      return {
        type: 'atomic',
        data: {
          type: 'aw_embed_iframe',
          src: src,
          subtitle: '',
          width,
          height
        }
      }
    }
    if (tagName === 'DIV' && className === 'wp-caption alignnone') {
      if (children.length === 2) {
        if (children[0].tagName === 'IMG' && children[1].tagName === 'P') {
          const { src, alt, srcset } = children[0]
          const caption = children[1].innerHTML
          return {
                    type: 'atomic',
                    data: {
                      type: 'aw_image',
                      src,
                      srcSet: srcset,
                      // alt,
                      subtitle: caption
                    }
                  }
        }
      }
    }
  }
}

export const EDITOR_ACTIONS = [
    {type: "inline", label: "B", style: "BOLD", icon: MegadraftIcons.BoldIcon},
    {type: "inline", label: "I", style: "ITALIC", icon: MegadraftIcons.ItalicIcon},
    // these actions correspond with the entityInputs above
    {type: "entity", label: "Link", style: "link", entity: "LINK", icon: MegadraftIcons.LinkIcon},
    {type: "separator"},
    {type: "entity", label: "ArtistLink", style: "link", entity: "ARTIST_LINK", icon: MegadraftIcons.LinkIcon},
    {type: "block", label: "UL", style: "unordered-list-item", icon: MegadraftIcons.ULIcon},
    {type: "block", label: "OL", style: "ordered-list-item", icon: MegadraftIcons.OLIcon},
    {type: "block", label: "H2", style: "header-two", icon: MegadraftIcons.H2Icon},
    {type: "block", label: "QT", style: "blockquote", icon: MegadraftIcons.BlockQuoteIcon},
  ]

export const ENTITY_INPUTS = {
  LINK: LinkInput,
  ARTIST_LINK: InternalLinkInput
}

export const DRAFT_DECORATOR = new DraftJS.CompositeDecorator([
    {
      strategy: createTypeStrategy("LINK"),
      component: LinkComponent,
    },
    {
      strategy: createTypeStrategy("ARTIST_LINK"),
      component: InternalLinkComponent,
    }
  ])
