import { CALL_API } from "../utils/api";
import Api from "@artworkslab/sharedmodules/src/Api";
// import {
// 	showLoadingSpinner,
// 	hideLoadingSpinner,
// } from './AppActions'

export const PRINT_GET_REQUEST = "printActions/PRINT_GET_REQUEST";
export const PRINT_GET_SUCCESS = "printActions/PRINT_GET_SUCCESS";
export const PRINTS_GET_SUCCESS = "printActions/PRINTS_GET_SUCCESS";
export const PRINT_GET_FAILURE = "printActions/PRINT_GET_FAILURE";

export function fetchPrints(printId) {
	const SUCCESS_TYPE =
		printId === "all" ? PRINTS_GET_SUCCESS : PRINT_GET_SUCCESS;
	return {
		[CALL_API]: {
			endpoint: `selected-prints/prints/${printId}/portal`,
			authenticated: true,
			method: "GET",
			types: [PRINT_GET_REQUEST, SUCCESS_TYPE, PRINT_GET_FAILURE],
		},
	};
}

export const PRINT_SAVE_REQUEST = "printActions/PRINT_SAVE_REQUEST";
export const PRINT_SAVE_SUCCESS = "printActions/PRINT_SAVE_SUCCESS";
export const PRINT_SAVE_FAILURE = "printActions/PRINT_SAVE_FAILURE";

export function savePrint(print) {
	const method = print._id ? "PUT" : "POST"; // update if id is present, otherwise create
	const endpoint = print._id
		? `selected-prints/prints/${print._id}`
		: "selected-prints/prints";
	const body = { print };

	return async (dispatch) => {
		dispatch(savePrintRequest());
		return Api(endpoint, method, true, body)
			.then((json) => {
				dispatch(savePrintSuccess(json));
				// Return json for initialize in submitPrint in PrinFormActions,
				// or form will be empty after save.
				return json;
			})
			.catch((err) => {
				console.log("savePrintFailure", err);
				dispatch(savePrintFailure(err));
				return null;
			});
	};
}

function savePrintRequest() {
	return {
		type: PRINT_SAVE_REQUEST,
	};
}

function savePrintSuccess(json) {
	return {
		type: PRINT_SAVE_SUCCESS,
		response: json,
	};
}

function savePrintFailure(error) {
	return {
		type: PRINT_SAVE_FAILURE,
		message: error,
	};
}

export const PRINT_REMOVE_REQUEST = "printActions/PRINT_REMOVE_REQUEST";
export const PRINT_REMOVE_SUCCESS = "printActions/PRINT_REMOVE_SUCCESS";
export const PRINT_REMOVE_FAILURE = "printActions/PRINT_REMOVE_FAILURE";

export function removePrint(print) {
	return {
		[CALL_API]: {
			endpoint: `selected-prints/prints/${print._id}`,
			authenticated: true,
			method: "DELETE",
			types: [PRINT_REMOVE_REQUEST, PRINT_REMOVE_SUCCESS, PRINT_REMOVE_FAILURE],
		},
	};
}

export const PRINT_ARTIST_GET_REQUEST = "printActions/PRINT_ARTIST_GET_REQUEST";
export const PRINT_ARTIST_GET_SUCCESS = "printActions/PRINT_ARTIST_GET_SUCCESS";
export const PRINT_ARTIST_GET_FAILURE = "printActions/PRINT_ARTIST_GET_FAILURE";

export function fetchPrintArtists() {
	return {
		[CALL_API]: {
			endpoint: `selected-prints/print-artists`,
			authenticated: true,
			method: "GET",
			types: [
				PRINT_ARTIST_GET_REQUEST,
				PRINT_ARTIST_GET_SUCCESS,
				PRINT_ARTIST_GET_FAILURE,
			],
		},
	};
}
