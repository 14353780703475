import {
	SET_ACCESS_REQUEST, SET_ACCESS_FAILURE, SET_ACCESS_SUCCESS,
	GET_ACCESS_REQUEST, GET_ACCESS_FAILURE, GET_ACCESS_SUCCESS,
} from '../actions/AccessActions'

import {
	SET_TYPE
} from '../actions/FormActions/AccessFormActions'

export function accessReducer(state = {
		isFetching: false,
		access: {},
		type: '',
		authenticated: false,
	}, action) {
	switch (action.type) {
		case SET_TYPE: {
			return Object.assign({}, state, {
				type: action.accessType
			})
		}
		case GET_ACCESS_REQUEST:
		return Object.assign({}, state, {
			isFetching: true,
			error: null
		})
		case GET_ACCESS_SUCCESS: {
			const access = action.response
			return Object.assign({}, state, {
				access,
				isFetching: false
			})
		}
		case GET_ACCESS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		case SET_ACCESS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		case SET_ACCESS_SUCCESS: {
			const access = action.response
			return Object.assign({}, state, {
				access,
				isFetching: false
			})
		}
		case SET_ACCESS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		default:
			return state
	}
}
