import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { fetchOrders } from "../../actions/OrderActions";
import {
	getPendingOrders,
	getPaidOrders,
	getDeliveredOrders,
	getToDeliverOrders,
} from "../../selectors/orderSelectors";
import Main from "../../components/Main/Main";
import Order from "../../components/OrderComponents/Order";

class Orders extends Component {
	constructor(props) {
		super(props);
		this.scrollRefs = {};
	}

	componentDidMount() {
		this.props.dispatch(fetchOrders());
	}

	scrollClick = (ref) => {
		const elem = this.scrollRefs[ref];
		window.scrollTo(0, elem.offsetTop);
	};

	render() {
		let pendingCount = Object.keys(this.props.pendingOrders).length;
		let paidCount = Object.keys(this.props.paidOrders).length;
		let deliveredCount = Object.keys(this.props.deliveredOrders).length;
		let toDeliverCount = Object.keys(this.props.toDeliverOrders).length;
		const { pendingOrders, paidOrders, deliveredOrders, toDeliverOrders } =
			this.props;

		const admin = this.props.user.role === "admin";
		if (!admin) return false;

		return (
			<div className="orders">
				<Main>
					<div className="orders__buttons">
						<button
							className="purple-button--wide"
							onClick={() => this.scrollClick("paid")}
						>
							Paid
						</button>
						<button
							className="purple-button--wide"
							onClick={() => this.scrollClick("todeliver")}
						>
							To deliver
						</button>
						<button
							className="purple-button--wide"
							onClick={() => this.scrollClick("delivered")}
						>
							Delivered
						</button>
						<button
							className="purple-button--wide"
							onClick={() => this.scrollClick("pending")}
						>
							Pending
						</button>
					</div>
					<div
						className="MainTop"
						ref={(ref) => (this.scrollRefs["paid"] = ref)}
					>
						<h2>Paid orders ({paidCount})</h2>
					</div>
					{Object.keys(paidOrders).map((key) => {
						const order = paidOrders[key];
						return <Order key={key} order={order} />;
					})}
					<div
						className="MainTop"
						ref={(ref) => (this.scrollRefs["todeliver"] = ref)}
					>
						<h2>Orders to deliver ({toDeliverCount})</h2>
					</div>
					{Object.keys(toDeliverOrders).map((key) => {
						const order = toDeliverOrders[key];
						return <Order key={key} order={order} />;
					})}
					<div
						className="MainTop"
						ref={(ref) => (this.scrollRefs["delivered"] = ref)}
					>
						<h2>Delivered orders ({deliveredCount})</h2>
					</div>
					{Object.keys(deliveredOrders).map((key) => {
						const order = deliveredOrders[key];
						return <Order key={key} order={order} />;
					})}
					<div
						className="MainTop"
						ref={(ref) => (this.scrollRefs["pending"] = ref)}
					>
						<h2>Pending orders ({pendingCount})</h2>
					</div>
					{Object.keys(pendingOrders).map((key) => {
						const order = pendingOrders[key];
						return <Order key={key} order={order} />;
					})}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
	} = state;
	const pendingOrders = getPendingOrders(state);
	const paidOrders = getPaidOrders(state);
	const deliveredOrders = getDeliveredOrders(state);
	const toDeliverOrders = getToDeliverOrders(state);
	return {
		pendingOrders,
		paidOrders,
		deliveredOrders,
		toDeliverOrders,
		user,
		isAdmin,
	};
}

export default connect(mapStateToProps)(Orders);
