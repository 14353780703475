import {
  SAVE_ON_UPLOAD_REQUEST, SAVE_ON_UPLOAD_SUCCESS, SAVE_ON_UPLOAD_FAILURE,
  FETCH_MEMBERS_REQUEST, FETCH_MEMBERS_SUCCESS, FETCH_MEMBERS_FAILURE,
  UPDATE_MEMBER_REQUEST, UPDATE_MEMBER_SUCCESS, UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBERS_REQUEST, UPDATE_MEMBERS_SUCCESS, UPDATE_MEMBERS_FAILURE,
  DELETE_MEMBER_REQUEST, DELETE_MEMBER_SUCCESS, DELETE_MEMBER_FAILURE,
  DELETE_ALL_MEMBERS_REQUEST, DELETE_ALL_MEMBERS_SUCCESS, DELETE_ALL_MEMBERS_FAILURE,
  EXPORT_MEMBERS_REQUEST, EXPORT_MEMBERS_SUCCESS, EXPORT_MEMBERS_FAILURE,
  SEND_EMAIL_TO_MEMBERS_REQUEST, SEND_EMAIL_TO_MEMBERS_SUCCESS, SEND_EMAIL_TO_MEMBERS_FAILURE, SEND_EMAIL_TO_MEMBERS_RESET,
  UPLOAD_MEMBERS_HELP_REQUEST, UPLOAD_MEMBERS_HELP_SUCCESS, UPLOAD_MEMBERS_HELP_FAILURE,
} from '../actions/RegistryActions'

export function registryReducer(state = {
  isFetching: false,
  members: {},
  exported: null,
  error: null,
  mailSent: false,
}, action) {
  switch(action.type) {
    case SAVE_ON_UPLOAD_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case SAVE_ON_UPLOAD_SUCCESS: {
      let members = {}
      action.response.forEach(member => {
        members[member._id] = member
      })
      return Object.assign({}, state, {
        isFetching: false,
        members,
      })
    }
    case SAVE_ON_UPLOAD_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case FETCH_MEMBERS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case FETCH_MEMBERS_SUCCESS: {
      let members = {}
      action.response.forEach(member => {
        members[member._id] = member
      })
      return Object.assign({}, state, {
        isFetching: false,
        members,
      })
    }
    case FETCH_MEMBERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case UPDATE_MEMBER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case UPDATE_MEMBER_SUCCESS: {
      const member = action.response
      const method = action.method
      let members = {}

      if (method === 'PUT') {
        members = Object.assign({}, state.members)
        members[member._id] = member
      } else if (method === 'POST') {
        // Makes shure new member is added on top of list.
        members = Object.assign({}, {
          [member._id]: member
        }, state.members)
      }

      return Object.assign({}, state, {
        isFetching: false,
        members,
      })
    }
    case UPDATE_MEMBER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case UPDATE_MEMBERS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case UPDATE_MEMBERS_SUCCESS: {
      const members = Object.assign({}, state.members)

      action.response.forEach(member => {
        members[member._id] = member
      })

      return Object.assign({}, state, {
        isFetching: false,
        members,
      })
    }
    case UPDATE_MEMBERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case DELETE_MEMBER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case DELETE_MEMBER_SUCCESS: {
      const deletedId = action.response
      let members = Object.assign({}, state.members)
      delete members[deletedId]

      return Object.assign({}, state, {
        isFetching: false,
        members,
      })
    }
    case DELETE_MEMBER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case DELETE_ALL_MEMBERS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case DELETE_ALL_MEMBERS_SUCCESS: {
      // Members who are applicants should not have been removed.
      let members = {}
      action.response.forEach(member => {
        members[member._id] = member
      })
      return Object.assign({}, state, {
        isFetching: false,
        members: action.response,
      })
    }
    case DELETE_ALL_MEMBERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case EXPORT_MEMBERS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        exported: null,
        error: false,
      })
    }
    case EXPORT_MEMBERS_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        exported: action.response,
      })
    }
    case EXPORT_MEMBERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case SEND_EMAIL_TO_MEMBERS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        mailSent: false,
        error: false,
      })
    }
    case SEND_EMAIL_TO_MEMBERS_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        mailSent: true,
      })
    }
    case SEND_EMAIL_TO_MEMBERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    case SEND_EMAIL_TO_MEMBERS_RESET: {
      return Object.assign({}, state, {
        mailSent: false,
        error: null,
      })
    }
    case UPLOAD_MEMBERS_HELP_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case UPLOAD_MEMBERS_HELP_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        message: action.message,
      })
    }
    case UPLOAD_MEMBERS_HELP_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message,
      })
    }
    default:
      return state
  }
}
