import React, { Component } from 'react'
import Select								from 'react-select'
import { connect }					from 'react-redux'
import {
  fetchInfluencers,
}	                          from '../../../actions/InfluencerActions'

class InfluencerPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      picked: null,
    }
  }

  componentDidMount() {
    // If multiple of this component on the same page, only need one of them to fetch.
    if (this.props.noFetch) return
		this.props.dispatch( fetchInfluencers('all') )
  }

	componentWillReceiveProps(nextProps) {
		if (
			!this.state.picked &&
			nextProps.input.value &&
			Object.keys(nextProps.influencers).length > 0
		) {
			const selected = nextProps.influencers[nextProps.input.value._id]
			this.setState({
				picked: selected,
			})
		}
	}

  pickInfluencer = (pick) => {
    this.setState({
      picked: pick,
    }, () => {
			this.props.input.onChange(pick)
	    this.props.input.onBlur(pick)
		})
  }

  render() {
    const { influencers, category, isAdmin, isFetching } = this.props
    const { picked } = this.state

		const options = []
    Object.keys(influencers).map((key) => {
      let influencer = Object.assign({}, influencers[key])
      if (category.indexOf(influencer.category) >= 0) {
        options.push(influencer)
      }
    })

    // type="button" on <button> prevents it from submitting the form it is in.
    return (
      <div className="input-holder input-holder--full" style={{ marginTop: '0px' }}>
        <Select
          isLoading={ isFetching }
          onChange={ this.pickInfluencer }
          options={ options }
          value={ picked }
          valueKey="_id"
          labelKey="name"
          placeholder={ category.indexOf('influencer') >= 0 ? 'Influencer' : 'Artist' }
          multi={ false }
          />
      </div>
    )
  }
}

function mapStateToProps(state) {
	const { influencer: { allInfluencers: influencers, isFetching }, auth: { user } } = state
	const isAdmin = state.auth.isAdmin || user.role === 'admin'
	return { influencers, isAdmin, isFetching }
}

export default connect(mapStateToProps)(InfluencerPicker)
