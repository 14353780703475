import {
  STRIPE_FETCH_SUBSCRIBER_REQUEST, STRIPE_FETCH_SUBSCRIBER_SUCCESS, STRIPE_FETCH_SUBSCRIBER_FAILURE,
  STRIPE_FETCH_SUBSCRIPTION_REQUEST, STRIPE_FETCH_SUBSCRIPTION_SUCCESS, STRIPE_FETCH_SUBSCRIPTION_FAILURE,
} from '../../actions/Stripe/StripeSubActions'

export function stripeSubReducer(state = {
  subscribers: [],
  subscriptions: {},
  isFetching: false,
  error: '',
}, action) {
  switch(action.type) {
    case STRIPE_FETCH_SUBSCRIBER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case STRIPE_FETCH_SUBSCRIBER_SUCCESS: {
      const subscribers = action.response
      return Object.assign({}, state, {
        subscribers,
        isFetching: false,
      })
    }
    case STRIPE_FETCH_SUBSCRIBER_FAILURE: {
      return Object.assign({}, state, {
        error: action.message,
        isFetching: false,
      })
    }
    case STRIPE_FETCH_SUBSCRIPTION_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case STRIPE_FETCH_SUBSCRIPTION_SUCCESS: {
      const allSubs = Object.assign({}, state.subscriptions)
      // console.log('STRIPE_FETCH_SUBSCRIPTION_SUCCESS');
      // console.log('allSubs 1', allSubs);
      // console.log('action.response', action.response);
      action.response.forEach(asub => {
        allSubs[asub._id] = asub
      })
      console.log('allSubs 2', allSubs);
      return Object.assign({}, state, {
        subscriptions: allSubs,
        isFetching: false,
      })
    }
    case STRIPE_FETCH_SUBSCRIPTION_FAILURE: {
      return Object.assign({}, state, {
        error: action.message,
        isFetching: false,
      })
    }
    default: {
      return state
    }
  }
}
