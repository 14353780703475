import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
	togglePopularArtwork,
	fetchData,
} from "../../../actions/DashboardActions/PopularArtworkActions";
import ExpandButton from "../ExpandButton/ExpandButton";
import ItemStats from "../ItemStats/ItemStats";

class PopularArtwork extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true,
		};
	}

	componentWillMount() {
		this.props.dispatch(fetchData());
	}

	expand = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	render() {
		const { expanded, artwork } = this.props;

		if (!artwork) return null;

		const artist =
			artwork.artistMod && artwork.artistMod.name
				? artwork.artistMod.name
				: "Unknown";
		const stats = [
			{ title: "Artwork", value: artwork.title, name: artist },
			{ title: "Visits", value: artwork.visits },
			{ title: "Likes", value: artwork.likes },
			// { title: 'Visits', value: artwork.visits }, // Don't use this since visits don't increment as it should.
		];

		return (
			<div className="PopularArtwork">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(togglePopularArtwork())}
				/>
				<h4 className="underlined">Your most popular artwork</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded && artwork.images ? (
							<div className="PopularArtwork__Content">
								<ItemStats
									image={{ backgroundImage: `url(${artwork.images[0].src})` }}
									stats={stats}
								/>
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		popularArtwork: { expanded, artwork },
	} = state;
	return { expanded, artwork };
}

export default connect(mapStateToProps)(PopularArtwork);
