import {
	TOGGLE_EXPANDED, SHOW_MORE, DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE
} from '../../actions/DashboardActions/UpcomingShowsActions'

export function upcomingShowsReducer(state = {
		isFetching: false,
		authenticated: false,
		shows: [],
    expanded: true
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		case DATA_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			});
		case DATA_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				shows: action.response.shows
			});
		case DATA_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			});
		default:
			return state
	}
}
