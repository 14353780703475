import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import RelatedShow from "./RelatedShow";
import ExpandButton from "../../DashboardComponents/ExpandButton/ExpandButton";
import { fetchOtherShows } from "../../../actions/ShowActions";

// const PLUS_SYMBOL = require('../../../../assets/images/svg/plus_32px.svg');
// const MINUS_SYMBOL = require('../../../../assets/images/svg/minus_32px.svg');

class ShowPickerBig extends Component {
	static propTypes = {};
	static defaultProps = {
		placeholder: "Choose related shows...",
		show: {
			isFetching: false,
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(fetchOtherShows(this.createOtherShowsQuery()));
	}

	createOtherShowsQuery = () => {
		const endOfToday = moment().endOf("day").toISOString();
		return {
			$or: [
				{
					$and: [
						{ endDate: { $gt: endOfToday } },
						{ visibility: { $nin: ["restricted"] } },
						{ status: "published" },
					],
				},
				{ _id: { $in: [] } },
			],
		};
	};

	selectShows = (show) => {
		const {
			input: { value },
		} = this.props;
		let selections = [];
		let alreadySelected = false;
		let selectedIndex = 0;

		value.forEach((val, index) => {
			// Val are full shows at first, after save values are only ids
			if ((val._id || val) === show._id) {
				alreadySelected = true;
				selectedIndex = index;
			}
		});

		if (alreadySelected) {
			selections = value.slice();
			selections.splice(selectedIndex, 1);
		} else {
			selections = value.slice();
			selections.push(show);
		}

		this.props.input.onChange(selections);
	};

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	render() {
		const {
			isFetching,
			shows,
			type,
			placeholder,
			input: { value },
			gallery,
			t,
		} = this.props;
		const { expanded } = this.state;

		const showsArray = [];
		Object.keys(shows).forEach((key) => {
			const show = shows[key];
			if (show.gallery && show.gallery._id === gallery) {
				// Put shows belonging to users gallery on top.
				showsArray.unshift(show);
			} else {
				showsArray.push(show);
			}
		});

		// Val are full shows at first, after save values are only ids
		const selectedIds = value ? value.map((show) => show._id || show) : [];

		return (
			<div className="show-picker-big">
				<div className="show-picker-big__top">
					<ExpandButton expanded={expanded} click={this.toggleExpanded} />
					<legend>{t("showPickerBig.relatedShows")}</legend>
				</div>
				<div
					className={
						"show-picker-big__shows input-holder input-holder--full" +
						(!expanded ? " is-hidden" : "")
					}
				>
					{showsArray.map((show) => {
						const alreadyAdded = selectedIds.indexOf(show._id) > -1;
						return (
							<RelatedShow
								show={show}
								alreadyAdded={alreadyAdded}
								addOrRemove={this.selectShows}
								key={show._id}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		show: { otherShows: shows, isFetching },
		auth: { user },
	} = state;
	const gallery = user.gallery
		? user.gallery._id
			? user.gallery._id
			: user.gallery
		: false;
	return { shows, isFetching, gallery };
}

const translated = withNamespaces("components")(ShowPickerBig);
export default connect(mapStateToProps)(translated);
