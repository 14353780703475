import {
  initialize,
  stopSubmit,
  startSubmit,
  change,
}                       from 'redux-form'
import diff             from 'object-diff'
import Validator        from 'validatorjs'
import {
  showErrorModal
} from '../AppActions'
import {
  saveDiscountCode,
} from '../DiscountCodeActions'

export function submitDiscountCodeForm(data, initial) {
  return (dispatch, getState) => {
    const { user } = getState().auth

    const rules = {
      batchName: 'required',
      code: 'required',
    }
    if (!data._id) {
      // Code count only required when creating new discount code.
      rules.codeCount = 'required|numeric|min:1|max:1000'
    }
    const validation = new Validator(data, rules)

    validation.setAttributeNames({
      batchName: 'Batch name',
      codeCount: 'Number of codes',
      code: 'Code',
    })
    validation.passes()
    const validatorErrors = validation.errors.all()

    if (Object.keys(validatorErrors) < 1) {
      const changedValues = initial ? diff(initial, data) : data
      changedValues._id = data._id ? data._id : undefined

      dispatch( startSubmit('discountCode'))
      return dispatch( saveDiscountCode(changedValues) )
      .then(result => {
        dispatch( stopSubmit('discountCode', {}) )
      })
      .catch(err => {
        dispatch( stopSubmit('discountCode', {}) )
      })
    } else {
      // dispatch( stopSubmit('discountCode', validatorErrors) )
      const firstErrorMessage = validatorErrors[Object.keys(validatorErrors)[0]]
      dispatch( showErrorModal(`${firstErrorMessage}`) )
    }
  }
}
