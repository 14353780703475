import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import Dropzone from "react-dropzone";
import Select from "react-select";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import CurrencyUtil from "@artworkslab/sharedmodules/src/CurrencyUtil";
import ArtworkDropzone from "./ArtworkDropzone";
import TinySelect from "./TinySelect";
import SwitchButton from "../Buttons/SwitchButton/SwitchButton";
import {
	getFirstImage,
	filterArtByArtist,
	thumbnailError,
} from "../../utils/Helpers";
import {
	fetchArtworksForArtist,
	saveArtwork,
	createArtworkFromImage,
	deleteArtwork,
} from "../../actions/ArtworkActions";
import { showCustomModal, hideCustomModal } from "../../actions/AppActions";
import { isSlugAutoGenerated } from "@artworkslab/sharedmodules/src/utils/Helpers";

// const BIN_SVG = require('../../../assets/images/svg/fontAwesome/fa_trash_bin.svg')
const BIN_PNG = require("../../../assets/images/icons/trashcan.png");

class ListAndUploadResponsive extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			artworkStatusOpen: "", // The id of an artwork, or empty.
			publishStatusUpdated: false,
			publishMessage: "",
			hasArt: false,
		};
		this.refs = {};
		this.currentSortColumn = "";
		this.currentSortDirection = "";
	}

	componentWillMount() {
		const { artistId } = this.props;
		if (artistId) {
			this.props.dispatch(fetchArtworksForArtist(artistId));
		}
	}

	componentWillReceiveProps(nextProps) {
		const newArtist =
			this.props.artistId !== nextProps.artistId && nextProps.artistId;
		const artworkWasDeleted =
			!this.props.deletedArtwork && nextProps.deletedArtwork;

		if (newArtist || artworkWasDeleted) {
			this.props.dispatch(fetchArtworksForArtist(nextProps.artistId));
		}

		if (!this.state.hasArt && Object.keys(nextProps.artworks).length > 0) {
			this.setState({
				hasArt: true,
			});
		}
	}

	updateArtwork = (
		body,
		showMessage = true,
		noLoadingSpinner = false,
		callback = null
	) => {
		this.props
			.dispatch(saveArtwork(body, showMessage, noLoadingSpinner))
			.then((response) => {
				if (callback) callback();
			});
	};

	removeArtwork = (artwork, t) => {
		let confirm = window.confirm(t("list.areYouSure"));
		if (confirm) {
			this.props.dispatch(deleteArtwork(artwork));
		}
	};

	toggleStatusSelect = (artworkId) => {
		this.setState({ artworkStatusOpen: artworkId });
	};

	setPublishStatusUpdated = (isPublished) => {
		const { t } = this.props;
		// Start @keyframes FADE_OUT animation in ArtworksListAndUpload.sass
		this.setState(
			{
				publishStatusUpdated: true,
				publishMessage: isPublished
					? t("common:status.draft")
					: t("common:status.published"),
			},
			() => {
				setTimeout(() => {
					this.setState({
						publishStatusUpdated: false,
						publishMessage: "",
					});
				}, 1000);
			}
		);
	};

	togglePublishStatus = (artworkId, isPublished) => {
		const newStatus = isPublished ? "draft" : "published";

		this.updateArtwork(
			{ _id: artworkId, publish_status: newStatus },
			false,
			true,
			() => this.setPublishStatusUpdated(isPublished)
		);
	};

	openPublishStatusModal = (artworkId, publishStatus) => {
		// Close custom modal before updating, or will show message "NO MESSAGE" after updating.
		const content = (
			<div>
				<p>Publicera konstverket i profil?</p>
				<div className="buttons">
					<button
						onClick={
							publishStatus !== "published"
								? () => {
										this.props.dispatch(hideCustomModal());
										this.updateArtwork(
											{ _id: artworkId, publish_status: "published" },
											false
										);
								  }
								: () => this.props.dispatch(hideCustomModal())
						}
					>
						Ja
					</button>
					<button
						onClick={
							publishStatus !== "draft"
								? () => {
										this.props.dispatch(hideCustomModal());
										this.updateArtwork(
											{ _id: artworkId, publish_status: "draft" },
											false
										);
								  }
								: () => this.props.dispatch(hideCustomModal())
						}
					>
						Nej
					</button>
				</div>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	selectSort = (option) => {
		// Using id, can't put ref on <Th>.
		const element = document.getElementById(option.value);
		if (element) element.click();
	};

	// When manually sorting / clicking table header.
	sortChanged = (e) => {
		this.currentSortColumn = e.column;
		this.currentSortDirection = e.direction; // -1 = latest first, 1 = oldest first
	};

	// After uplading new artwork, sort on latest updated, or new artworks will be at the bottom of the list.
	sortOnLatest = () => {
		const element = document.getElementById("createdAt");

		if (this.currentSortColumn !== "createdAt") {
			// First click on creted puts oldest first. Need to click twice.
			if (element) {
				element.click();
				element.click();
			}
		} else if (
			this.currentSortColumn === "createdAt" &&
			this.currentSortDirection !== -1
		) {
			if (element) {
				element.click();
			}
		}
	};

	createTableHead = (artistId, t) => {
		// <Th> with {display: 'none'} only used for sorting dropdown.
		const tableHead = (
			<Thead>
				<Th column="publishStatus" id="publishStatus">
					{t("list.status")}
				</Th>
				{/* <Th column="publishStatus" id="publishStatus">
          { !artistId ? t('list.published') : <span>{t('list.showInProfile1')}<br />{t('list.showInProfile2')}</span> }
          </Th> */}
				{artistId && (
					<Th
						column="forPublicPurchaser"
						id="forPublicPurchaser"
						style={{ whiteSpace: "pre-line" }}
					>
						{t("list.showForPublicPurchaser")}
					</Th>
				)}
				<Th column="image">{` `}</Th>
				<Th column="titleAndPrice" id="titleAndPrice">{` `}</Th>
				<Th column="delete">{` `}</Th>
				<Th
					column="createdAt"
					id="createdAt"
					style={{ display: "none" }}
				>{` `}</Th>
			</Thead>
		);

		return tableHead;
	};

	createTableRows = (artworks) => {
		const { isFetching, t } = this.props;
		const { artworkStatusOpen } = this.state;
		const rows = [];
		const iconStyle = {
			height: "25px",
			width: "auto",
			cursor: "pointer",
		};

		Object.keys(artworks).forEach((key) => {
			const artwork = artworks[key];
			const image = getFirstImage(artwork);
			const smallThumbnail = image.thumbnails
				? image.thumbnails["100x100"].src
				: image.src;
			const isNewest = false;
			const isAutoTitle = isSlugAutoGenerated(artwork.title);
			let publicPurchaserValue = 0;
			if (
				artwork.forPublicPurchaserRequested &&
				!artwork.forPublicPurchaser &&
				!artwork.forPublicPurchaserOnly
			) {
				publicPurchaserValue = 1;
			} else if (
				artwork.forPublicPurchaserRequested &&
				(artwork.forPublicPurchaser || artwork.forPublicPurchaserOnly)
			) {
				publicPurchaserValue = 2;
			}

			rows.push(
				<Tr
					key={artwork._id}
					className={
						"list-and-upload__reactable__tr" + (isNewest ? " is-newest" : "")
					}
				>
					<Td
						column="publishStatus"
						value={artwork.publish_status}
						style={
							artwork.publish_status === "published"
								? { cursor: "default", color: "#059b05" }
								: { cursor: "default", color: "#a4a4a4" }
						}
					>
						{/* Clicking switch button onnly opens form as popup. */}
						{/* switched={() => this.togglePublishStatus(artwork._id, artwork.publish_status === 'published')} */}
						{/* <Link to={ `/artworks/${artwork.slug}` }>
              <SwitchButton
                isOn={ artwork.publish_status === 'published' }
                switched={() => {}}
                disabled={ isFetching }
                markerOnly={ true }
                />
            </Link> */}
						{/*<TinySelect
              artworkId={ artwork._id }
              field="publish_status"
              options={ [
                { value: 'published', label: t('common:status.published') },
                { value: 'draft', label: t('common:status.draft') },
              ] }
              selectedOption={ artwork.publish_status }
              doOpen={() => this.openPublishStatusModal(artwork._id, artwork.publish_status)}
              doOpenPrev={() => this.toggleStatusSelect(artwork._id)}
              isOpen={ artworkStatusOpen === artwork._id }
              doClose={() => this.toggleStatusSelect('')}
              update={ this.updateArtwork }
              isResponsive={ true }
              t={ t }
              />*/}
						<Link to={`/artworks/${artwork.slug}`}>
							{t(`single.${artwork.publish_status}`)}
						</Link>
					</Td>
					{/* Clicking switch button onnly opens form as popup. */}
					<Td column="forPublicPurchaser" value={publicPurchaserValue}>
						<Link to={`/artworks/${artwork.slug}`}>
							<SwitchButton
								isOn={
									artwork.forPublicPurchaserRequested ||
									artwork.forPublicPurchaserOnly
								}
								switched={() => {}}
								disabled={false}
								isWaiting={
									artwork.forPublicPurchaserRequested &&
									!artwork.forPublicPurchaser &&
									!artwork.forPublicPurchaserOnly
								}
								markerOnly={false}
							/>
						</Link>
					</Td>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{smallThumbnail && (
							<Link to={`/artworks/${artwork.slug}`}>
								<img
									src={smallThumbnail}
									style={{ width: "60px", cursor: "pointer" }}
									onError={(e) => thumbnailError(e, e.target.src, image.src)}
									alt="image of artwork"
								/>
							</Link>
						)}
					</Td>
					<Td column="titleAndPrice" value={artwork.title}>
						<Link to={`/artworks/${artwork.slug}`}>
							{!isAutoTitle && <span>{artwork.title}</span>}
							{!isAutoTitle && !!artwork.price && <br />}
							{!!artwork.price && (
								<span style={{ whiteSpace: "nowrap" }}>
									{CurrencyUtil.format(
										artwork.price,
										artwork.currency,
										artwork.VATcode
									)}
								</span>
							)}
							{isAutoTitle && !artwork.price && (
								<span>
									<u>{t("list.addMoreInfo")}</u>
								</span>
							)}
						</Link>
					</Td>
					{/*
            <Td column="titleAndPrice" value={ artwork.title }>
              <Link to={ `/artworks/${artwork.slug}` }>
                <span>{ artwork.title }</span>
                <br />
                <span style={{ whiteSpace: 'nowrap' }}>{ CurrencyUtil.format(artwork.price, artwork.currency, artwork.VATcode) }</span>
              </Link>
            </Td>
          */}
					<Td column="delete" value={"delete"}>
						{/* <BIN_SVG style={ iconStyle } onClick={() => this.removeArtwork(artwork, t)} data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io" /> */}
						<img
							src={BIN_PNG}
							style={iconStyle}
							onClick={() => this.removeArtwork(artwork, t)}
						/>
					</Td>
					<Td
						column="createdAt"
						value={artwork.created_at}
						style={{ display: "none" }}
					>
						{artwork.created_at}
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	openDropzone = () => {
		const dz = document.getElementById("artwork-dropzone");
		if (dz && dz.childNodes && dz.childNodes[1] && dz.childNodes[1].click) {
			dz.childNodes[1].click();
		}
	};

	render() {
		const { artworks, artistName, artistId, isFetching, error, t } = this.props;
		const { publishStatusUpdated, publishMessage, hasArt } = this.state;
		const isSwedish = i18next.language === "sv";
		const artForArtist = filterArtByArtist(artistId, artworks);
		const tableHead = this.createTableHead(artistId, t);
		const rows = this.createTableRows(artForArtist);
		const artCount = Object.keys(artworks).length;

		const SORT_OPTIONS = [
			{ label: isSwedish ? "Titel" : "Title", value: "titleAndPrice" },
			{ label: isSwedish ? "Status" : "Status", value: "publishStatus" },
			{
				label: isSwedish ? "Inköpare" : "Purchaser",
				value: "forPublicPurchaser",
			},
			{ label: isSwedish ? "Skapad" : "Created", value: "createdAt" },
		];

		return (
			<div className="responsive-list-and-upload">
				<div className="responsive-list-and-upload__dropzone-container">
					<ArtworkDropzone
						artistId={artistId}
						artistName={artistName}
						galleryId={false}
						raffle={false}
						show={false}
						isSociety={false}
						showIntallations={false}
						selectedFilterId={artistId}
						selectedFilterName=""
						isResponsive={true}
						onDoneCallback={this.sortOnLatest}
					/>
				</div>
				{hasArt && (
					<div className="responsive-list-and-upload__header">
						<Select
							isLoading={isFetching}
							onChange={this.selectSort}
							options={SORT_OPTIONS}
							placeholder={
								isSwedish ? `Sortera (${artCount})` : `Sort by (${artCount})`
							}
							multi={false}
							className="aw-list-sort"
							searchable={false}
						/>
					</div>
				)}
				{hasArt && (
					<div className="responsive-list-and-upload__table-container">
						<Table
							className="reactable reactable-responsive"
							sortable={[
								"titleAndPrice",
								"createdAt",
								{
									column: "publishStatus",
									sortFunction: function (a, b) {
										// Initial sort put "published" above "draft".
										return a < b ? 1 : -1;
									},
								},
								{
									column: "forPublicPurchaser",
									sortFunction: function (a, b) {
										// Sort on publicPurchaserValue which is 0/1/2.
										return a < b ? 1 : -1;
									},
								},
							]}
							defaultSort={{ column: "createdAt", direction: "desc" }}
							onSort={this.sortChanged}
							filterable={false}
							filterPlaceholder={"Search artwork"}
							itemsPerPage={20}
							pageButtonLimit={0}
							previousPageLabel={t("list.prevPage")}
							nextPageLabel={t("list.nextPage")}
						>
							{tableHead}
							{rows}
						</Table>
					</div>
				)}
				{!hasArt && !isFetching && (
					<div
						className="responsive-list-and-upload__table-placeholder"
						onClick={this.openDropzone}
					>
						<p>
							{t("list.artWillBeHere1")}
							<br />
							{t("list.artWillBeHere2")}
						</p>
					</div>
				)}
				<div
					className={
						"responsive-list-and-upload__inform-published" +
						(publishStatusUpdated ? " is-visible" : "")
					}
				>
					{publishMessage}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		artwork: { artworks, isFetching: fetchingArtwork, deletedArtwork, error },
		artist: { artist, isFetching: fetchingArtist },
	} = state;
	const isFetching = fetchingArtist || fetchingArtwork;

	return { artworks, isFetching, deletedArtwork, error };
};

const translated = withNamespaces("artworks")(ListAndUploadResponsive);
export default connect(mapStateToProps)(translated);
