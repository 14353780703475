import React, { Component } from "react"
import {  Field,
          Fields,
          reduxForm }       from 'redux-form'
import TextInput            from '../../components/FormInput/TextInput'
import { validateEmail, notEmpty }    from '../../utils/Validators'


let LoginForm = (props) => {

	const { handleSubmit, className, pristine, submitting, valid, t } = props
  const canSubmit = (valid && !pristine && !submitting)
	return (
		<form onSubmit={ handleSubmit } className={className} >
      <Field
        name="email"
        component={ TextInput }
        placeholder={t('email')}
        validate={[validateEmail, notEmpty]}
        required
      />
      <Field
        name="password"
        type="password"
        component={ TextInput }
        placeholder={t('password')}
        validate={ notEmpty }
        required
      />
      <button className="purple-button--wide button-nomargin" type="submit" disabled={ !canSubmit }>{t('logIn')}</button>
    </form>
  )
}

LoginForm = reduxForm({
  form: 'login'
})(LoginForm)

export default LoginForm
