import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import {
	fetchAllArtworksFast,
	// fetchAllArtworksPopulated,
	fetchArtworksForGallery,
	fetchArtworksForArtist,
	fetchArtworksWithQuery,
	saveArtwork,
} from "../../actions/ArtworkActions";
import Main from "../../components/Main/Main";
import FormMeta from "../../components/Meta/FormMeta";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
// import ArtworkPickerMasonry         from '../../components/ArtworkPicker/ArtworkPickerMasonry'
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const ALL_FILTER = "All";

class Artworks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFilter: ALL_FILTER,
			dateStart: "",
			dateEnd: "",
			displayType: "", // all/purchasingPortal
		};
	}

	// componentDidMount() {
	// 	const { role, artist, gallery } = this.props.user
	// 	const { isAdmin, artworks, deletedArtwork } = this.props
	// 	// const skip = Object.keys(this.props.artworks).length
	// 	if (!role) return;
	// 	if (role === 'artist') {
	// 		const artistId = artist._id ? artist._id : artist
	// 		this.props.dispatch( fetchArtworksForArtist(artistId))
	// 	} else if (isAdmin) {
	// 		// Artworks for admin here is allArtworks from state. If already fetched, only update.
	// 		if (Object.keys(artworks).length === 0 || deletedArtwork) {
	// 			this.props.dispatch( fetchAllArtworksFast() )
	// 			.then(result => {
	// 				this.props.dispatch( fetchAllArtworksPopulated() )
	// 			})
	// 		} else {
	// 			this.props.dispatch( fetchAllArtworksPopulated() )
	// 		}
	// 	} else {
	// 		const galleryId = gallery._id ? gallery._id : gallery
	// 		this.props.dispatch( fetchArtworksForGallery(galleryId) )
	// 	}
	// }

	// componentWillReceiveProps(newProps) {
	// 	// this.props.user can have lost id, so don't compare with that. Not a permanent thing, just strange.
	// 	if (this.props.user.email !== newProps.user.email) {
	// 		const { role, artist, gallery } = newProps.user
	// 		if (role === 'artist') {
	// 			const artistId = artist._id ? artist._id : artist
	// 			this.props.dispatch( fetchArtworksForArtist(artistId))
	// 		}else if (role === 'admin') {
	// 			if (Object.keys(this.props.artworks).length === 0 || newProps.deletedArtwork) {
	// 				this.props.dispatch( fetchAllArtworksFast() )
	// 				.then(result => {
	// 					// this.props.dispatch( fetchAllArtworksPopulated() )
	// 				})
	// 			} else {
	// 				// this.props.dispatch( fetchAllArtworksPopulated() )
	// 			}
	// 		}else{
	// 			const galleryId = gallery._id ? gallery._id : gallery
	// 			this.props.dispatch( fetchArtworksForGallery(galleryId) )
	// 		}
	// 	}
	// }

	createFilterButton = (key) => {
		const { selectedFilter } = this.state;
		let name = key;
		return (
			<button
				className={
					"artist-filters__filter" +
					(selectedFilter === key ? " is-selected" : "")
				}
				onClick={() => this.setFilter(key)}
				key={key}
			>
				{name !== ALL_FILTER
					? name
					: i18next.language === "sv"
					? "Alla"
					: "All"}
			</button>
		);
	};

	setFilter = (fltr) => {
		this.setState({
			selectedFilter: fltr,
		});
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	changeDates = (dateStart, dateEnd) => {
		const ISOstartDate = new Date(moment(dateStart)).toISOString();
		const ISOendDate = new Date(moment(dateEnd)).toISOString();

		this.setState({
			dateStart: ISOstartDate,
			dateEnd: ISOendDate,
		});
	};

	awBetweenDates = (e) => {
		const { dateStart, dateEnd } = this.state;
		if (!dateStart || !dateEnd) return;

		this.setState(
			{
				displayType: "all",
			},
			() => {
				this.props.dispatch(fetchAllArtworksFast(dateStart, dateEnd));
			}
		);
	};

	fetchForPublicPurchaser = (e) => {
		const dbQuery = {
			$or: [
				{ forPublicPurchaser: true },
				{ forPublicPurchaserRequested: true },
			],
		};

		this.setState(
			{
				displayType: "purchasingPortal",
			},
			() => {
				this.props.dispatch(fetchArtworksWithQuery(dbQuery));
			}
		);
	};

	updateArtwork = (body, showMessage = true, callback = null) => {
		this.props.dispatch(saveArtwork(body, showMessage)).then((response) => {
			if (callback) callback();
		});
	};

	toggleForPublicPurchaser = (artwork) => {
		const artworkBody = {
			_id: artwork._id,
			forPublicPurchaser: artwork.forPublicPurchaser ? false : true,
		};

		this.updateArtwork(artworkBody, false);
	};

	render() {
		const {
			allArtworks,
			selectedArtworks,
			isAdmin,
			isAuthor,
			isArtSociety,
			isArtist,
			user,
			t,
		} = this.props;
		// Authour can chose to show only one artists artworks.
		const { selectedFilter, displayType } = this.state;

		if (!isAdmin) return null;

		let awCollection = {};
		const forPurchasingPortal = displayType === "purchasingPortal";
		if (displayType === "all") awCollection = allArtworks;
		else if (forPurchasingPortal) awCollection = selectedArtworks;

		let artworks = [];
		let rows = [];
		let artistNames = [];

		Object.keys(awCollection)
			.sort((a, b) => {
				let aObject = awCollection[a];
				let bObject = awCollection[b];
				return new Date(bObject.created_at) - new Date(aObject.created_at);
			})
			.forEach((key) => {
				let artwork = awCollection[key];
				const image = artwork.images ? artwork.images[0] : "";
				const thumbnailSrc = image ? this.getImageSrc(image) : "";
				if (!artwork.artist) {
					artwork.artist = { name: "no artist" };
				}
				let creator;
				let gallery;
				if ((creator = artwork.gallery)) {
					if (creator.name) {
						gallery = creator.name;
					} else if (creator.email) {
						gallery = creator.email;
					}
				} else {
					gallery = "";
				}

				let pubPurRequestDate = t("common:no");
				let pubPurSortValue = "2000-01-01"; // If no date, sort last.
				if (artwork.forPublicPurchaserRequested) {
					if (artwork.forPublicPurchaserRequestedDate) {
						pubPurRequestDate = DateUtil.dateMonthShort(
							artwork.forPublicPurchaserRequestedDate,
							i18next.language,
							true
						);
						pubPurSortValue = artwork.forPublicPurchaserRequestedDate;
					} else {
						pubPurRequestDate = t("common:yes");
					}
				}

				const artistName =
					artwork.artistMod && artwork.artistMod.name
						? artwork.artistMod.name
						: artwork.artist.name;
				// Author only shows these if a single artist is selected, otherwise Table is displayed.
				if (!isAuthor || (isAuthor && selectedFilter === artistName))
					artworks.push(
						<div key={artwork._id} className="List__objects__object">
							<Link to={"/artworks/" + artwork._id}>
								<img
									src={thumbnailSrc}
									className="table-image"
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
									alt="Artwork image"
								/>
								<div>
									<span>{artwork.title}</span>
									<span className="List__objects__object__name">
										By {artistName}
									</span>
									<span className="List__objects__object__name">
										{" "}
										{gallery}
									</span>
								</div>
							</Link>
						</div>
					);

				rows.push(
					<Tr key={artwork._id}>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{image ? (
								<Link to={`/artworks/${artwork.slug}`}>
									<img
										src={thumbnailSrc}
										className="table-image"
										onError={(e) =>
											this.thumbnailError(e, e.target.src, image.src)
										}
										alt="Artwork image"
									/>
								</Link>
							) : null}
						</Td>
						<Td column="title" value={artwork.title}>
							<Link to={`/artworks/${artwork.slug}`}>{artwork.title}</Link>
						</Td>
						<Td column="name" value={artwork.artist.name}>
							<Link to={`/artworks/${artwork.slug}`}>{artistName}</Link>
						</Td>
						{!forPurchasingPortal && (
							<Td column="gallery" value={gallery}>
								<Link to={`/artworks/${artwork.slug}`}>{gallery}</Link>
							</Td>
						)}
						{forPurchasingPortal && (
							<Td column="forPubPurRequested" value={pubPurSortValue}>
								<div>{pubPurRequestDate}</div>
							</Td>
						)}
						{forPurchasingPortal && (
							<Td
								column="forPubPur"
								value={artwork.forPublicPurchaser ? true : false}
							>
								<input
									type="checkbox"
									className={"list-and-upload__raffle-checkbox"}
									onChange={() => this.toggleForPublicPurchaser(artwork)}
									checked={artwork.forPublicPurchaser ? true : false}
									disabled={false}
								/>
							</Td>
						)}
						{!forPurchasingPortal && (
							<Td column="visibility" value={artwork.visibility}>
								<Link to={`/artworks/${artwork.slug}`}>
									{artwork.visibility}
								</Link>
							</Td>
						)}
						<Td column="created" value={artwork.created_at}>
							<Link to={`/artworks/${artwork.slug}`}>
								{DateUtil.dateMonthShort(
									artwork.created_at,
									i18next.language,
									true
								)}
							</Link>
						</Td>
						<Td column="status" value={artwork.publish_status}>
							<Link to={`/artworks/${artwork.slug}`}>
								{t(`common:status.${artwork.publish_status}`)}
							</Link>
						</Td>
					</Tr>
				);

				if (artistNames.indexOf(artistName) < 0) {
					artistNames.push(artistName);
				}
			});

		let sortButtons =
			Object.keys(awCollection).length > 0
				? [this.createFilterButton(ALL_FILTER)]
				: [];
		artistNames.forEach((name) => {
			sortButtons.push(this.createFilterButton(name));
		});

		// Search field hidden for artSociety
		const filters = isArtSociety ? [] : ["title", "name", "gallery"];

		let tableHead = <Thead></Thead>;
		if (isAdmin) {
			tableHead = (
				<Thead>
					<Th column="image">{` `}</Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="name">{t("list.artist")}</Th>
					{!forPurchasingPortal && (
						<Th column="gallery">{t("list.gallery")}</Th>
					)}
					{forPurchasingPortal && (
						<Th column="forPubPurRequested" style={{ width: 80 }}>
							{t("list.requestedPpAccess")}
						</Th>
					)}
					{forPurchasingPortal && (
						<Th column="forPubPur" style={{ width: 80 }}>
							{t("list.allowedPpAccess")}
						</Th>
					)}
					{!forPurchasingPortal && (
						<Th column="visibility" style={{ width: 80 }}>
							{t("list.visibility")}
						</Th>
					)}
					<Th column="created">{t("list.createdAt")}</Th>
					<Th column="status" style={{ width: 80 }}>
						Status
					</Th>
				</Thead>
			);
		} else if (isArtist) {
			tableHead = (
				<Thead>
					<Th column="image">{` `}</Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="gallery">{t("list.gallery")}</Th>
					<Th column="created">{t("list.createdAt")}</Th>
					<Th column="status" style={{ width: 80 }}>
						Status
					</Th>
				</Thead>
			);
		} else {
			tableHead = (
				<Thead>
					<Th column="image">{` `}</Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="name">{t("list.artist")}</Th>
					<Th column="created">{t("list.createdAt")}</Th>
					<Th column="status" style={{ width: 80 }}>
						Status
					</Th>
				</Thead>
			);
		}

		return (
			<div>
				<Main>
					<p style={{ marginBottom: "30px" }}>{t("list.about")}</p>
					<div className="fieldset" style={{ marginBottom: "40px" }}>
						<DateRangePicker
							withTime={false}
							updateCallbackMs={this.changeDates}
						/>
						<button
							onClick={this.awBetweenDates}
							disabled={this.props.isFetching}
						>
							Find artworks
						</button>
					</div>
					<div className="fieldset" style={{ marginBottom: "40px" }}>
						<button
							onClick={this.fetchForPublicPurchaser}
							style={{ marginRight: "20px" }}
							disabled={this.props.isFetching}
						>
							Art for public purchaser
						</button>
						{/*<h2>{t('Artworks')} ({isAuthor && selectedFilter === ALL_FILTER ? rows.length : artworks.length})</h2>*/}
						<Link to="/artworks/new" className="button--mod_create">
							{t("list.createNewArtwork")}
						</Link>
					</div>
					{/*<FormMeta text={t('list.about')} />*/}
					{isAuthor && (
						<div className="artist-filters" style={{ padding: "0 0 30px 0" }}>
							{sortButtons}
						</div>
					)}
					{(isAdmin || (isAuthor && selectedFilter === ALL_FILTER)) && (
						<Table
							className="reactable"
							sortable={[
								"name",
								"gallery",
								"title",
								"likes",
								"status",
								"visibility",
								"created",
								"forPubPur",
								"forPubPurRequested",
							]}
							defaultSort={{ column: "forPubPurRequested", direction: "desc" }}
							filterable={filters}
							filterPlaceholder={"Search artwork"}
							itemsPerPage={100}
							pageButtonLimit={5}
						>
							{tableHead}
							{rows}
						</Table>
					)}
					{false && isArtSociety && (
						<ArtworkPickerMasonry
							galleryId={user.gallery._id || user.gallery}
						/>
					)}
					{isArtist && (
						<Table
							className="reactable"
							sortable={[
								"name",
								"gallery",
								"title",
								"likes",
								"status",
								"created",
							]}
							filterable={false}
							filterPlaceholder={"Search artwork"}
							itemsPerPage={100}
							pageButtonLimit={5}
						>
							{tableHead}
							{rows}
						</Table>
					)}
					{((!isAdmin && !isAuthor && !isArtist) ||
						(isAuthor && selectedFilter !== ALL_FILTER)) && (
						<div className="List__objects">{artworks}</div>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artwork: {
			artworks: selectedArtworks,
			allArtworks,
			deletedArtwork,
			isFetching,
		},
		auth: { user },
	} = state;
	const isAuthor = user.role === "author" || user.role === "society";
	const isArtSociety = user.role === "society";
	const isArtist = user.role === "artist";
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	// fetchArtworksWithQuery and fetch for artist/gallery returns selectedArtworks
	// const artworks = isAdmin ? allArtworks : selectedArtworks
	return {
		allArtworks,
		selectedArtworks,
		deletedArtwork,
		user,
		isAdmin,
		isAuthor,
		isArtSociety,
		isArtist,
		isFetching,
	};
}

const translated = withNamespaces("artworks")(Artworks);
export default connect(mapStateToProps)(translated);
