import {
	DISCOUNT_CODE_SAVE_REQUEST, DISCOUNT_CODE_SAVE_FAILURE, DISCOUNT_CODE_SAVE_SUCCESS,
	DISCOUNT_CODE_FETCH_REQUEST, DISCOUNT_CODE_FETCH_FAILURE, DISCOUNT_CODE_FETCH_SUCCESS,
	DELETE_DISCOUNT_CODE_REQUEST, DELETE_DISCOUNT_CODE_SUCCESS, DELETE_DISCOUNT_CODE_FAILURE,
} from '../actions/DiscountCodeActions'


export function discountCodeReducer(state = {
		isFetching: false,
		allCodes: {},
		singleCode: null,
		deletingCode: false,
	}, action) {
	switch (action.type) {
		case DISCOUNT_CODE_FETCH_REQUEST: {
      return Object.assign({}, state, {
				singleCode: null,
				isFetching: true,
			})
    }
    case DISCOUNT_CODE_SAVE_REQUEST: {
      return Object.assign({}, state, {
				isFetching: true,
			})
    }
		case DISCOUNT_CODE_FETCH_SUCCESS:
    case DISCOUNT_CODE_SAVE_SUCCESS: {
      const allCodes = Object.assign({}, state.allCodes)
			let singleCode = null
			const fetchedMulitple = Array.isArray(action.response)
			if (fetchedMulitple) {
	      action.response.forEach(code => {
	        allCodes[code._id] = code
	      })
			} else {
				const code = action.response
				singleCode = code
				allCodes[code._id] = code
			}
      return Object.assign({}, state, {
				allCodes,
				singleCode,
				isFetching: false,
			})
    }
		case DISCOUNT_CODE_FETCH_FAILURE:
    case DISCOUNT_CODE_SAVE_FAILURE: {
      return Object.assign({}, state, {
				isFetching: false,
			})
    }
		case DELETE_DISCOUNT_CODE_REQUEST: {
			return Object.assign({}, state, {
				deletingCode: true,
				isFetching: false,
			})
		}
		case DELETE_DISCOUNT_CODE_SUCCESS: {
			const allCodes = Object.assign({}, state.allCodes)
			const deletedId = action.response.deletedId
			delete allCodes[deletedId]
			return Object.assign({}, state, {
				deletingCode: false,
				allCodes,
				singleCode: null,
				isFetching: false,
			})
		}
		case DELETE_DISCOUNT_CODE_FAILURE: {
			return Object.assign({}, state, {
				deletingCode: false,
				isFetching: false,
			})
		}
		default:
			return state
	}
}
