import {
	ARTISTS_REQUEST, ARTISTS_SUCCESS, ARTISTS_FAILURE,
	ARTISTS_ALL_REQUEST, ARTISTS_ALL_FAILURE, ARTISTS_ALL_SUCCESS,
	ARTISTS_ALL_POPULATE_REQUEST, ARTISTS_ALL_POPULATE_SUCCESS, ARTISTS_ALL_POPULATE_FAILURE,
	ARTIST_REQUEST, ARTIST_FAILURE, ARTIST_SUCCESS,
	ARTIST_SAVE_REQUEST, ARTIST_SAVE_FAILED, ARTIST_SAVE_SUCCESS,
	ARTIST_DELETE_REQUEST, ARTIST_DELETE_FAILED, ARTIST_DELETE_SUCCESS,
	MENTIONS_REQUEST, MENTIONS_SUCCESS, MENTIONS_FAILURE,
	PRO_INCREASE_REQUEST, PRO_INCREASE_SUCCESS, PRO_INCREASE_FAILURE,
} from '../actions/ArtistActions'

export function artistReducer(state = {
		isFetching: false,
		authenticated: false,
		artists: {},
		artist: {},
		allArtists: {},
		saved: false,
		mentions: {},
		prosIncrease: [],
		artistArtworks: [],
	}, action) {
	switch (action.type) {
		case ARTISTS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				artists: {},
				error: null,
				deletedArtist: false
			})
		}
		case ARTISTS_SUCCESS: {
			let artists = {}
			action.response.map((artist) => {
				artists[artist._id] = artist
			})
			return Object.assign({}, state, {
				isFetching: false,
				artists: artists
			})
		}
		case ARTISTS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case ARTISTS_ALL_POPULATE_REQUEST:
		case ARTISTS_ALL_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case ARTISTS_ALL_SUCCESS: {
			let artists = {}
			action.response.map((artist) => {
				artists[artist._id] = artist
			})
			return Object.assign({}, state, {
				isFetching: false,
				allArtists: artists,
			})
		}
		case ARTISTS_ALL_POPULATE_SUCCESS: {
			let artists = Object.assign({}, state.allArtists)
			action.response.map((artist) => {
				artists[artist._id] = artist
			})
			return Object.assign({}, state, {
				isFetching: false,
				allArtists: artists,
			})
		}
		case ARTISTS_ALL_POPULATE_FAILURE:
		case ARTISTS_ALL_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error,
			})
		}
		case ARTIST_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				artist: {},
				error: null,
				deletedArtist: false,
				artistArtworks: [],
			})
		}
		case ARTIST_SUCCESS: {
			const artist = action.response
			return Object.assign({}, state, {
				isFetching: false,
				artist: artist,
				// artworks populated on fetch, but not populated on update.
				// Need them for signature artwork select in ArtistForm.js
				artistArtworks: artist.artworks,
			})
		}
		case ARTIST_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case ARTIST_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				saved: false,
				deletedArtist: false
			})
		}
		case ARTIST_SAVE_SUCCESS: {
			const artist = action.response
			let allArtists = Object.assign({}, state.allArtists)
			if (!allArtists[artist._id]) {
				allArtists[artist._id] = artist
			}
			return Object.assign({}, state, {
				isFetching: false,
				artist,
				allArtists,
				saved: true
			})
		}
		case ARTIST_SAVE_FAILED: {
			return Object.assign({}, state, {
				isFetching: false,
				saved: false,
				error: action.message
			})
		}
		case MENTIONS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case MENTIONS_SUCCESS: {
			const { artistId } = action
			let mentions = Object.assign({}, state.mentions)
			mentions[artistId] = action.response
			return Object.assign({}, state, {
				isFetching: false,
				mentions,
				error: null,
			});
		}
		case MENTIONS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			})
    }
		case ARTIST_DELETE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				deletedArtist: false
			})
		}
		case ARTIST_DELETE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				deletedArtist: true
			})
		}
		case ARTIST_DELETE_FAILED: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case PRO_INCREASE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true
			})
		}
		case PRO_INCREASE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				prosIncrease: action.response
			})
		}
		case PRO_INCREASE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		default:
			return state
	}
}
