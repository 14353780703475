import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { Field, arrayPush } from "redux-form";
import Select from "../../FormInput/Select";
import GalleryPicker from "../GalleryPicker/GalleryPicker";
import ArtistPicker from "../ArtistPicker/ArtistPicker";
import MdAdd from "react-icons/lib/md/add";
import MdClear from "react-icons/lib/md/clear";

const renderField = ({ input, label, type, meta: { touched, error } }) => (
	<div>
		<p>{input.value}</p>
		<div style={{ display: "none" }}>
			<input {...input} type={type} />
		</div>
	</div>
);

const RenderUser = (member, index, fields) => {
	return (
		<li className="userlist__item" key={index}>
			<Field name={`${member}.email`} type="text" component={renderField} />
			<MdClear
				className="userlist__item__remove"
				onClick={() => fields.remove(index)}
			/>
		</li>
	);
};

class AddUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addMail: "",
		};
	}
	handleMail = (e) => {
		this.setState({
			addMail: e.currentTarget.value,
		});
	};
	handleAdd = () => {
		const separators = [",", ";", ":"];
		const emails = this.state.addMail.split(
			new RegExp(separators.join("|"), "g")
		);
		emails.map((email) => {
			if (email.length > 0) {
				this.props.addField({ email: email.trim() });
			}
		});
		this.setState({
			addMail: "",
		});
	};
	render() {
		return (
			<div className="userlist__add_user">
				<input
					className="userlist__add_user__add"
					type="text"
					value={this.state.addMail}
					onChange={this.handleMail}
				/>
				<MdAdd
					className="userlist__add_user__add__icon"
					onClick={this.handleAdd}
				/>
			</div>
		);
	}
}

const UserList = (props) => {
	const { fields } = props;
	return (
		<div>
			<ul className="userlist">{fields.map(RenderUser)}</ul>
			<AddUser addField={props.addField} />
		</div>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		addField: (email) => {
			dispatch(arrayPush("access", "allowed", email));
		},
	};
}

export default connect(null, mapDispatchToProps)(UserList);
