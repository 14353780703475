import React, { Component } from "react";
import { connect } from "react-redux";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import Input from "../../components/Input/Input";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import { resetPassword } from "@artworkslab/sharedmodules/src/actions/AuthActions";

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailSent: false,
			inputText: "",
		};
	}

	submit = (e) => {
		e.preventDefault();
		const { inputText } = this.state;
		this.props
			.dispatch(resetPassword(inputText))
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				console.log(err);
			});
		this.setState({
			emailSent: true,
		});
	};

	onInputChange = (e) => {
		e.preventDefault();
		const inputText = e.currentTarget.value;
		this.setState({
			inputText,
		});
	};

	tryAgain = (e) => {
		e.preventDefault();
		this.setState({
			emailSent: false,
			inputText: "",
		});
	};

	render() {
		const { user, t } = this.props;
		return (
			<div>
				<MainResp contentClassName="slim-content">
					{/*<div className="MainTop">
						<h1>{t('login.reset.resetPassword')}</h1>
					</div>*/}
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div style={{ width: "800px" }}>
							{!this.state.emailSent ? (
								<form className="responsive-form">
									<div className="input-holder input-holder--full input-holder--nopadding">
										<input
											className="reset-input"
											name="email"
											placeholder={t("email")}
											onChange={this.onInputChange}
											value={this.state.inputText}
											required
										/>
										<button
											className="purple-button--wide button-nomargin"
											type="submit"
											onClick={(e) => this.submit(e)}
										>
											{t("login.reset.resetPassword")}
										</button>
									</div>
								</form>
							) : (
								<div>
									<p>{t("login.reset.checkYourEmail")}</p>
									<p>
										{t("login.reset.noEmail")}{" "}
										<a
											href="#"
											onClick={this.tryAgain}
											style={{ textDecoration: "underline" }}
										>
											{t("login.reset.tryAgain")}
										</a>
										.
									</p>
								</div>
							)}
						</div>
					</div>
				</MainResp>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state;
	return { user };
}

const translated = withNamespaces("common")(ResetPassword);
export default connect(mapStateToProps)(translated);
