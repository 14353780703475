import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
// import PropTypes                  from "prop-types"
import { withNamespaces } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { updatePostImageCaption } from "../../../actions/PostActions";
import { fetchFile } from "../../../actions/ArchiveActions";
import { base64ArrayBuffer } from "../../../utils/Helpers";

const FIVE_MIB = 5242880;
const ACCEPT = "image/jpeg,image/jpg,image/png";

const CLOSE_BUTTON = require("../../../../assets/images/icons/close.white@2x.png");
const DIRECTION_ICON = require("../../../../assets/images/svg/redo_white.svg");

// const propTypes = {
//   defaultImage: PropTypes.any,
//   multi: PropTypes.bool
// }

const defaultProps = {
	defaultImage: false,
	multi: false,
};

const ImagePreview = ({ src, click, title }) => {
	return (
		<div className="ImageUpload__preview" onClick={click}>
			<div className="ImageUpload__preview-image">
				<img
					className="ImageUpload__preview-image-img"
					src={src}
					title={title}
				/>
			</div>
		</div>
	);
};

const ImageGalleryPreview = ({ src, click, title, index }) => {
	return (
		<div className="image-gallery__item" onClick={click}>
			{index && <span>{index}</span>}
			<div className="image-gallery__item__content">
				<img
					className="image-gallery__item__content__image"
					src={src}
					title={title}
				/>
			</div>
		</div>
	);
};

const SortableItem = SortableElement(({ value, showModal, sortIndex }) => {
	// const source = value.URI ? value.URI : (value.thumbnails ? value.thumbnails['750x'].src : value.src)
	const source = value.src || value.URI;
	return (
		<ImageGalleryPreview
			src={source}
			click={() =>
				showModal(value._id || value.tmpId, source, value.caption || "")
			}
			title={value.caption}
			index={Number(sortIndex) + 1}
		/>
	);
});

const SortableList = SortableContainer(({ items, images, showModal }) => {
	return (
		<ul className={"image-gallery"}>
			{items.map((value, index) => {
				const image = images.find(
					(elem) =>
						elem.id === value || elem._id === value || elem.tmpId === value
				);
				const number = 10573;
				if (!image) return null;
				return (
					<SortableItem
						key={`item-${index}`}
						index={index}
						value={image}
						showModal={showModal}
						sortIndex={index}
					/>
				);
			})}
		</ul>
	);
});

class ArtworkUploadRotate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			modalImage: {
				identifier: "",
				source: "",
				caption: "",
			},
			sortedImageIds: [],
			// Rotation:
			previewImage: null,
			angle: 0,
			size: {
				width: 0,
				height: 0,
			},
		};
		this.rotation = 0;
	}

	componentDidMount() {
		const { value } = this.props.images.input;
		if (value) {
			const imageIds = value.sort(this.sortImages).map((val) => val._id);
			this.setState({
				sortedImageIds: imageIds,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		const { value } = nextProps.images.input;
		if (this.state.sortedImageIds.length === 0 && value) {
			// For single artwork, images are not available on did mount.
			const imageIds = value.sort(this.sortImages).map((val) => val._id);
			this.setState({
				sortedImageIds: imageIds,
			});
		}

		if (!!this.props.uploads.input.value && !nextProps.uploads.input.value) {
			// Uploaded images have been saved.
			const imageIds = value.sort(this.sortImages).map((val) => val._id);
			this.setState({
				sortedImageIds: imageIds,
			});
		}
	}

	onDrop = (acceptedFiles, e) => {
		const {
			uploadedImage: { URI, contentType, fileName },
			multi,
			images,
			images: {
				input: { value },
			},
			t,
		} = this.props;

		let totalSize = 0;
		acceptedFiles.forEach((file) => {
			totalSize += file.size;
		});
		// If too large, saving will fail.
		if (totalSize > FIVE_MIB) {
			window.alert(t("imageUpload.imagesTooBig"));
			return;
		}

		let promises = [];
		if (__ALLOW_MULITPLE_ARTWORKS__) {
			acceptedFiles.map((file) => {
				promises.push(this.loadImage(file));
				// if (__DEVELOPMENT__ || __STAGING__) {
				//   promises.push(this.loadImageWithBinary(file))
				// } else {
				//   promises.push(this.loadImage(file))
				// }
			});
		} else {
			if (acceptedFiles && acceptedFiles.length > 0)
				promises.push(this.loadImage(acceptedFiles[0]));
		}

		Promise.all(promises)
			.then((data) => {
				const { uploads } = this.props;
				const alreadyUploadedImages = Array.isArray(uploads.input.value)
					? uploads.input.value
					: [];
				const uploadedImgs = alreadyUploadedImages.concat(data);
				if (__ALLOW_MULITPLE_ARTWORKS__) {
					let alreadySorted = [...this.state.sortedImageIds]; // clone array
					let addToSorted = [];
					// Create random tmp id, set first in sortedImageIds
					uploadedImgs.forEach((uplImg, index) => {
						if (!uplImg.tmpId) {
							const tmpId = `uploaded${10000 - index}`;
							uploadedImgs[index].tmpId = tmpId;
							addToSorted.push(tmpId);
						}
					});

					const allSortedIds = addToSorted.concat(alreadySorted);
					this.setState({
						sortedImageIds: allSortedIds,
					});

					const existingImages = Array.isArray(value) ? value : [];
					this.updateImages(allSortedIds, existingImages, uploadedImgs);
				} else {
					uploads.input.onChange(data);
					uploads.input.onBlur(data);
					images.input.onChange([]);
					images.input.onBlur([]);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// On multi upload, if one file fails, all files fails.
	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;
		const rejectedFile = rejectedFiles[0];

		rejectedFiles.forEach((rejected) => {
			this.showImageError(rejected, t);
		});
	};

	showImageError = (rejectedFile, t) => {
		// console.log('REJECTED:', rejectedFile);
		if (rejectedFile.size > FIVE_MIB) {
			window.alert(t("imageUpload.imageTooBig"));
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("imageUpload.imageWrongFormat"));
		}
	};

	loadImage = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(uploadedImage);
		});
	};

	loadImageData = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(uploadedImage);
		});
	};

	loadImageBinary = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			return reader.readAsBinaryString(uploadedImage);
		});
	};

	loadImageWithBinary = (uploadedImage) => {
		const that = this;
		return new Promise(function (resolve, reject) {
			Promise.all([
				that.loadImageData(uploadedImage),
				that.loadImageBinary(uploadedImage),
			])
				.then(([data, binary]) => {
					const full = Object.assign({}, data, {
						binaryData: binary.URI,
					});
					resolve(full);
				})
				.catch((err) => {
					console.log("loadImageWithBinary catch err", err);
					reject(err);
				});
		});
	};

	sortImages = (a, b) => {
		if (a.index < b.index) return -1;
		if (a.index > b.index) return 1;
		return 0;
	};

	updateImages = (sortedIds, existingImages, uploadedImages) => {
		const { images, uploads } = this.props;

		existingImages.forEach((existing, index) => {
			existing.index = sortedIds.indexOf(existing._id);
			existingImages[index] = existing;
		});

		uploadedImages.forEach((uploaded, index) => {
			uploaded.index = sortedIds.indexOf(uploaded.tmpId);
			uploadedImages[index] = uploaded;
		});

		images.input.onChange(existingImages);
		images.input.onBlur(existingImages);
		uploads.input.onChange(uploadedImages);
		uploads.input.onBlur(uploadedImages);
	};

	renderPreviewImage = (t) => {
		const {
			multi,
			sortable,
			images,
			images: {
				input: { value },
			},
			uploads,
		} = this.props;
		const { sortedImageIds } = this.state;
		let uploadedImages = Array.isArray(uploads.input.value)
			? uploads.input.value
			: [];
		let existingImages = Array.isArray(value) ? value : [];
		let allImages = existingImages.concat(uploadedImages);

		const onSortEnd = ({ oldIndex, newIndex }) => {
			// Already saved image has source "https..."
			// Not yet save upload has source "data:image/jpeg;base64..."
			const movedImageId = sortedImageIds[oldIndex];
			// const alreadySavedImage = allImages[oldIndex].src && allImages[oldIndex].src.indexOf('http') === 0

			let resortedIds = [...sortedImageIds];
			resortedIds.splice(oldIndex, 1);
			resortedIds.splice(newIndex, 0, movedImageId);
			this.setState({
				sortedImageIds: resortedIds,
			});

			this.updateImages(resortedIds, existingImages, uploadedImages);
		};

		if (allImages.length === 0) {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<p>
							{multi
								? t("imageUpload.previewHereMulti")
								: t("imageUpload.previewHere")}
						</p>
					</div>
				</div>
			);
		} else if (!multi || !sortable) {
			return (
				<div className={"masonry" + (allImages.length > 1 ? " multiple" : "")}>
					{allImages.map((img, index) => {
						return (
							<ImagePreview
								src={img.src || img.URI}
								click={() =>
									this.openModal(
										img._id || img.fileName,
										img.src || img.URI,
										img.caption || ""
									)
								}
								title={img.caption}
								key={img.index}
							/>
						);
					})}
				</div>
			);
		} else {
			return (
				<SortableList
					items={sortedImageIds}
					onSortEnd={onSortEnd}
					images={allImages}
					showModal={this.openModal}
					pressDelay={200}
					axis="xy"
				/>
			);
		}
	};

	openModal = (imageIdentifier, imageSource, caption) => {
		// createPreviewForUpload creates preview for not saved image.
		const previewImage =
			imageSource.indexOf("http") < 0
				? this.createPreviewForUpload(imageIdentifier, imageSource, caption)
				: null;
		this.rotation = 0;
		this.setState(
			{
				modalOpen: true,
				modalImage: {
					identifier: imageIdentifier,
					// imageIdentifier,
					source: imageSource,
					caption,
				},
				previewImage,
				angle: 0,
				size: {
					width: 0,
					height: 0,
				},
			},
			() => {
				if (imageSource.indexOf("http") === 0) {
					// Create preview for already uploaded image.
					this.createPreview(imageSource, imageIdentifier, caption);
				}
			}
		);
	};

	shouldRemove = (identifier, t) => {
		this.closeModal();
		let confirm = window.confirm(t("imageUpload.areYouSure"));
		if (confirm) this.removeImage(identifier);
	};

	closeModal = () => {
		const { rotation } = this.state;
		const {
			images: {
				input: { value },
			},
			uploads,
			t,
		} = this.props;
		// Only update image if rotation changed.
		if (this.rotation !== 0) {
			this.replaceImageAfterRotate();
		}
		this.rotation = 0;
		this.setState({
			modalOpen: false,
			previewImage: null,
			// rotation: 0,
		});
	};

	replaceImageAfterRotate = () => {
		const { previewImage, sortedImageIds } = this.state;
		const {
			images: {
				input: { value },
			},
			uploads,
			t,
		} = this.props;
		// previewImage has been replaced with rotated version.
		// Make a copy of sortedImageIds.
		const imageOrder = sortedImageIds.slice();
		// Added images not saved yet.
		const notSavedImages = Array.isArray(uploads.input.value)
			? uploads.input.value
			: [];
		// Images that has been saved previoulsy.
		const alreadySavedImages = Array.isArray(value) ? value : [];

		const allImages = notSavedImages.concat(alreadySavedImages);
		// Find the image that was rotated. image.tmpId is for not saved images. image.id is for already saved images.
		// Already saved image, when editing from "Add more info" - popup, has no_id, only _id
		const selectedImage = allImages.find(
			(image) =>
				image.tmpId === previewImage.identifier ||
				image.id === previewImage.identifier ||
				image._id === previewImage.identifier
		);
		const selectedId = selectedImage.tmpId || selectedImage.id;
		const selectedIndex = imageOrder.indexOf(selectedId);

		const newImage = {
			URI: previewImage.preview,
			contentType: previewImage.type,
			fileName: previewImage.name,
			tmpId: selectedId,
			caption: previewImage.caption,
		};

		// If the rotated image was an uploaded image,
		// create an array where the original upload is replaced with the rotated one.
		const uploadedImages = [newImage];
		notSavedImages.forEach((notSavedImage) => {
			if (notSavedImage.tmpId !== previewImage.identifier) {
				uploadedImages.push(notSavedImage);
			}
		});
		// If the rotated image was a saved image,
		// create an array where that image is removed.
		const existingImages = [];
		alreadySavedImages.forEach((alreadySavedImage) => {
			if (alreadySavedImage.id !== previewImage.identifier) {
				existingImages.push(alreadySavedImage);
			}
		});
		// Now the original image, whether saved or a new upload, has been removed from the correspondeing array,
		// and the rotated image has been added to the array with new uploads.
		// Set the newly created rotated image in the right place.
		// Don't need to set image order, newImage gets its tmpId from the image it replaces.
		// imageOrder[selectedIndex] = newImage.tmpId
		this.updateImages(
			this.state.sortedImageIds,
			existingImages,
			uploadedImages
		);
	};

	captionChange = (e) => {
		const caption = e.target.value;
		const update = Object.assign({}, this.state.modalImage, {
			caption,
		});
		this.setState({
			modalImage: update,
		});
	};

	saveCaption = () => {
		const {
			modalImage: { identifier, source, caption },
		} = this.state;
		const {
			postId,
			images,
			images: {
				input: { value: savedImages },
			},
			uploads,
		} = this.props;
		// Already saved image has source "https..."
		// Not yet save upload has source "data:image/jpeg;base64..."
		const alreadySavedImage = source.indexOf("http") === 0;
		// Saving post redirects to /posts/slug, don't really need to check for posiId here, unless removing redirect in future.
		if (postId && alreadySavedImage) {
			this.props
				.dispatch(updatePostImageCaption(postId, identifier, caption))
				.then((updated) => {
					const updatedImages = updated.response.images;
					images.input.onChange(updatedImages);
					images.input.onBlur(updatedImages);
				});
		} else if (!postId || !alreadySavedImage) {
			const uploadedImages = Array.isArray(uploads.input.value)
				? uploads.input.value
				: [];
			const updatedUploads = uploadedImages.map((img) => {
				if (img.tmpId === identifier) img.caption = caption;
				return img;
			});
			uploads.input.onChange(updatedUploads);
			uploads.input.onBlur(updatedUploads);
		}
		this.closeModal();
	};

	// When opening popup, create preview for already uploaded image here.
	createPreview = (urlOriginal, imageIdentifier, caption) => {
		let tempPreview = {};
		const url = urlOriginal.replace(
			"images.artworksapp.com",
			"artworks-lab-app-images"
		);
		const bucketStart = url.indexOf("://") + 3;
		let bucketEnd = url.indexOf(".s3");
		if (bucketEnd < 0) bucketEnd = url.indexOf("/featuredImages/");
		const bucket = url.slice(bucketStart, bucketEnd);
		const keyStart = url.indexOf("/featuredImages/") + 1;
		let key = url.slice(keyStart);
		// Need to decode, if url is KIRBY%20SUPER%20STAR.jpg, aws key is KIRBY SUPER STAR.jpg
		// decodeURI did not turn %2B into a plus symbol (+). Using decodeURIComponent instead.
		key = decodeURIComponent(key);

		fetchFile(bucket, key)
			.then((result) => {
				const { Body, ContentType } = result;
				tempPreview.type =
					ContentType === "image/jpg" ? "image/jpeg" : ContentType;

				let base64String = base64ArrayBuffer(Body.data);
				base64String = "data:image/png;base64," + base64String;
				return base64String;
			})
			.then((source) => {
				let canvas = document.createElement("canvas");
				// For viewing when testing.
				// let canvas = document.getElementById('test-canvas')
				let img = new Image();
				img.src = source;

				img.onload = function () {
					saveImage(img.src.replace(/^.*[\\\/]/, ""));
				};

				const saveImage = (img_name) => {
					canvas.width = Math.floor(img.width);
					canvas.height = Math.floor(img.height);
					let ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					/* Want tempPreview to look something like this:
        name: "94037477-8jijf.jpg"
        preview: "blob:http://localhost:3000/4fb17168-742c-4451-824b-28303ae25a3b"
        // size: 73348
        type: "image/jpeg"
        */
					tempPreview.preview = canvas.toDataURL("image/png");
					const regex = /\/.{24}-.+\..+/;
					const matched = regex.exec(url);
					const fileName = matched
						? url.slice(matched.index + 26)
						: "unnamed.jpg";
					tempPreview.name = fileName;
					// tempPreview.imageIdentifier = imageIdentifier
					tempPreview.identifier = imageIdentifier;
					tempPreview.caption = caption;
					ctx.restore();

					this.setState({ previewImage: tempPreview });
				};
			})
			.catch((err) => {
				console.log("createPreview fetchFile Error", err);
			});
	};

	createPreviewForUpload = (imageIdentifier, imageSource, caption) => {
		const {
			images: { input },
			uploads,
		} = this.props;
		const uploadedImages = Array.isArray(uploads.input.value)
			? uploads.input.value
			: [];
		const existingImages = Array.isArray(input.value) ? input.value : [];

		const allImages = uploadedImages.concat(existingImages);
		const selectedImage = allImages.find(
			(image) => image.tmpId === imageIdentifier || image.id === imageIdentifier
		);

		const previewImage = selectedImage
			? {
					preview: selectedImage.URI,
					type: selectedImage.contentType,
					name: selectedImage.fileName,
					// imageIdentifier: imageIdentifier,
					identifier: imageIdentifier,
					caption,
			  }
			: null;

		return previewImage;
	};

	rotateImage = (direction) => {
		const { previewImage } = this.state;
		const {
			uploadedImage: { URI, contentType, fileName },
		} = this.props;
		let angle = this.state.angle;
		let rotation = this.rotation;

		let img = new Image();
		img.src = previewImage.preview;
		let tempPreview = Object.assign({}, previewImage);

		// Create a canvas
		let canvas = document.createElement("canvas");
		// For viewing when testing.
		// let canvas = document.getElementById('test-canvas')

		// Wait until img has loaded
		img.onload = function () {
			// resizeImage(img.width, img.height, direction)
			rotateImage(img.width, img.height);
			saveImage(img.src.replace(/^.*[\\\/]/, ""));
		};

		// let resizeImage = (width, height) => {
		//   switch (direction) {
		//     case 'right':
		//       angle += 90
		//       break
		//     case 'left':
		//       angle -= 90
		//       break
		//   }
		//
		//   const rads = (angle * Math.PI/180)
		//   let cos = Math.cos(rads)
		//   let sin = Math.sin(rads)
		//   if(cos < 0) { cos = -cos }
		//   if(sin < 0) { sin = -sin }
		//   const newWidth = height * sin + width * cos
		//   const newHeight = height * cos + width * sin
		//
		//   this.setState({
		//     angle: angle,
		//     size: {
		//       width: newWidth,
		//       height: newHeight
		//     }
		//   })
		// }

		let rotateImage = (width, height) => {
			// Create canvas context
			let ctx = canvas.getContext("2d");
			// Assign canvas dimension
			canvas.width = height;
			canvas.height = width;
			ctx.save();
			ctx.translate(canvas.width / 2, canvas.height / 2);

			switch (direction) {
				case "right":
					ctx.rotate(Math.PI / 2);
					rotation += 90;
					break;
				case "left":
					ctx.rotate(-Math.PI / 2);
					rotation -= 90;
					break;
			}
			this.rotation = rotation;
			ctx.drawImage(img, -img.width / 2, -img.height / 2);
			ctx.restore();
		};

		let saveImage = (img_name) => {
			tempPreview.preview = canvas.toDataURL("image/jpeg");
			this.setState({
				previewImage: tempPreview,
			});
		};
	};

	renderModal = (t) => {
		const {
			modalOpen,
			modalImage: { identifier, source, caption },
			previewImage,
		} = this.state;
		const { captionOn, isAdmin } = this.props;

		if (modalOpen) {
			return (
				<div className="upload-modal">
					<img
						className="upload-modal__close"
						src={CLOSE_BUTTON}
						onClick={() => this.closeModal(identifier, caption)}
					/>
					<h4
						onClick={() => {
							this.shouldRemove(identifier, t);
						}}
					>
						⚠️ {t("imageUpload.deleteImage")}
					</h4>
					{previewImage && (
						<div className="upload-modal__rotate-buttons">
							<DIRECTION_ICON
								className="upload-modal__rotate-buttons__button"
								onClick={() => this.rotateImage("left")}
							/>
							<DIRECTION_ICON
								className="upload-modal__rotate-buttons__button"
								onClick={() => this.rotateImage("right")}
							/>
						</div>
					)}
					<div
						className="upload-modal__image"
						style={{
							backgroundImage: `url(${
								previewImage ? previewImage.preview : source
							})`,
						}}
						onClick={() => this.closeModal(identifier, caption)}
					/>
					{captionOn && (
						<div className="upload-modal__caption">
							<input
								type="text"
								placeholder={t("imageUpload.caption")}
								onChange={this.captionChange}
								value={caption}
							></input>
							<div
								className="upload-modal__caption__button"
								onClick={this.saveCaption}
							>
								{t("common:save")}
							</div>
						</div>
					)}
				</div>
			);
		} else {
			return null;
		}
	};

	removeImage = (identifier) => {
		const {
			images: { input },
			uploads,
		} = this.props;
		const uploadedImages = Array.isArray(uploads.input.value)
			? uploads.input.value
			: [];
		const existingImages = Array.isArray(input.value) ? input.value : [];

		const uploadsToKeep = uploadedImages.filter((upload) => {
			return upload.tmpId && upload.tmpId !== identifier;
		});

		const existingToKeep = existingImages.filter((img) => {
			return img._id && img._id !== identifier;
		});

		if (existingToKeep.length !== existingImages.length) {
			input.onChange(existingToKeep);
			input.onBlur(existingToKeep);
		}
		if (uploadsToKeep.length !== uploadedImages.length) {
			uploads.input.onChange(uploadsToKeep);
			uploads.input.onBlur(uploadsToKeep);
		}

		let imageIds = this.state.sortedImageIds.slice();
		imageIds.splice(imageIds.indexOf(identifier), 1);
		this.setState({
			sortedImageIds: imageIds,
		});
	};

	render() {
		// let {input, meta: { touched, error, valid, invalid }} = this.props;
		// let selectedFile = (input && input.value && input.value[0]) || null;
		let input = {};
		let invalid = false;
		let valid = true;
		const { multi, t } = this.props;
		const { error } = this.props.images.meta;
		// TODO: Format errors and set state in a nice way
		return (
			<div
				className={
					"ImageUpload" +
					(invalid ? " is-error" : "") +
					(valid ? " is-valid" : "")
				}
			>
				{this.renderModal(t)}
				<div className="input-holder">
					<input type="hidden" name="uploadedImage.URI" disabled {...input} />
					<input
						type="hidden"
						name="uploadedImage.fileName"
						disabled
						{...input}
					/>
					<input
						type="hidden"
						name="uploadedImage.contentType"
						disabled
						{...input}
					/>
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.dropRejected}
						className={"ImageUpload__dropzone"}
						accept={ACCEPT}
						multiple={multi}
						maxSize={FIVE_MIB}
					>
						<div>
							{multi
								? t("imageUpload.dropHereMulti")
								: t("imageUpload.dropHere")}
						</div>
					</Dropzone>
					{error ? (
						<span className="Input__error" style={{ marginTop: "10px" }}>
							{error}
						</span>
					) : null}
				</div>
				<div className="input-holder">{this.renderPreviewImage(t)}</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	return { isAdmin };
};

const translated = withNamespaces("components")(ArtworkUploadRotate);
export default connect(mapStateToProps)(translated);
