import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import {
	toggleCurrentShow,
	fetchData,
} from "../../../actions/DashboardActions/CurrentShowActions";
import ExpandButton from "../ExpandButton/ExpandButton";
import ItemStats from "../ItemStats/ItemStats";

class CurrentShow extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.dispatch(fetchData());
	}

	getSubHeader = (shows, upcomingShows) => {
		const { type, t } = this.props;
		if (upcomingShows.length > 0) {
			return null;
		}

		if (shows.length === 0) {
			return (
				<p className="CurrentShow__Content__no_shows">
					{`${t("currentShows.noShows")} `}
					<a href="/shows/new">{t("currentShows.noShowsLink")}</a>
				</p>
			);
		}

		const nextWeek = moment().add(7, "days");

		if (
			shows.find((show) => {
				return moment(show.endDate).isAfter(nextWeek);
			})
		) {
			return null;
		} else {
			const ENDS_SOON =
				shows.length > 1
					? t("currentShows.endingMulti")
					: t("currentShows.ending");
			return (
				<p
					className={
						"CurrentShow__Content__no_shows" +
						(type === "artist" ? " is-artist" : "")
					}
				>
					{`${ENDS_SOON} `}
					<a href="/shows/new">{t("currentShows.endingLink")}</a>
				</p>
			);
		}
	};

	render() {
		const { expanded, data, shows, upcomingShows, type = "", t } = this.props;
		const header =
			shows.length > 1
				? t("currentShows.headerMulti")
				: t("currentShows.header");
		const headerClass = type + " underlined";
		// { title: t('currentShows.followers'), value: show.likes }

		return (
			<div className="CurrentShow">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(toggleCurrentShow())}
				/>
				<h4 className={headerClass}>{header}</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
						transitionName="expand"
					>
						{expanded ? (
							<div className="CurrentShow__Content">
								{this.getSubHeader(shows, upcomingShows)}
								{shows.map((show, index) => {
									show.stats = [
										{ title: t("currentShows.show"), value: show.title },
										{ title: t("currentShows.visits"), value: show.visits },
									];
									return (
										<ItemStats
											image={{
												backgroundImage: `url(${show.featuredImage.src})`,
											}}
											stats={show.stats}
											key={`currentShow_${index}`}
											type={type}
											slug={show.slug}
										/>
									);
								})}
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { currentShow } = state;
	const { expanded, data, shows } = currentShow;
	const upcomingShows = state.upcomingShows.shows;
	return { expanded, data, shows, upcomingShows };
}

const translated = withNamespaces("components")(CurrentShow);
export default connect(mapStateToProps)(translated);
