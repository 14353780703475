import React             from "react";
import i18next           from 'i18next'

const noEmailCheckbox = (type) => {
  const isSwedish = i18next.language === 'sv'

  if (isSwedish) {
    return <p style={{display: 'inline'}}>
    <b>Automatiska påminnelser 14 dagar och 2 dagar innan {type}. </b>
    Kryssa i om du <b>inte</b> vill att påminnelser ska gå ut till medlemmar.
    </p>
  } else {
    return <p style={{display: 'inline'}}>
    <b>Automatic reminders are sent 14 and 2 days before the {type}. </b>
    Check this box if you <b>do not</b> want these reminders to be sent to members.
    </p>
  }
}

export {
  noEmailCheckbox
}
