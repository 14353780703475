import {
  LOAD,
  SHOW_ARTIST_MODAL,
  HIDE_ARTIST_MODAL,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR
}                         from '../../actions/FormActions/ArtistFormActions'
import {
  HIDE_SUCCESS_MODAL,
  HIDE_ERROR_MODAL
}                         from '../../actions/FormActions/FormActions'
import {
  ARTIST_SAVE_SUCCESS,
  ARTIST_SAVE_FAILURE,
  ARTIST_UPDATE_SUCCESS,
  ARTIST_UPDATE_FAILURE,
}                         from '../../actions/ArtistActions'

export function artistFormReducer(state = {
  showArtistModal: false,
  error: null
}, action) {
  switch (action.type) {
    case LOAD:
      return Object.assign({}, state, {
        data: action.data
      })
    case ARTIST_SAVE_SUCCESS:
      return Object.assign({}, state, {
        showArtistModal: false
      })
    case ARTIST_SAVE_FAILURE:
      return Object.assign({}, state, {
        showError: true,
        error: action.error
      })
    case ARTIST_UPDATE_SUCCESS:
      return Object.assign({}, state, {
      })
    case ARTIST_UPDATE_FAILURE:
      return Object.assign({}, state, {
        showError: true,
        error: action.error
      })
    case SHOW_ARTIST_MODAL:
      return Object.assign({}, state, {
        showArtistModal: true
      })
    case HIDE_ARTIST_MODAL:
      return Object.assign({}, state, {
        showArtistModal: false
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    case HIDE_ERROR_MODAL:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    case HIDE_SUCCESS_MODAL:
      return Object.assign({}, state, {
        showSuccess: false
      })
    default:
      return state
  }
}
