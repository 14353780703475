import React from "react";
import { stopSubmit } from "redux-form";
import { showErrorModal, showSuccessModal } from "../actions/AppActions";
import { SHOW_SAVE_SUCCESS, SHOW_SAVE_FAILURE } from "../actions/ShowActions";
import { POST_SAVE_SUCCESS, POST_SAVE_FAILURE } from "../actions/PostActions";
import {
	EVENT_SAVE_SUCCESS,
	EVENT_SAVE_FAILURE,
	EVENT_UPDATE_SUCCESS,
	EVENT_UPDATE_FAILURE,
	RSVP_MAIL_SUCCESS,
	RSVP_MAIL_FAILURE,
	RSVP_MAIL_FEE_MISSING_REQUEST,
	RSVP_MAIL_FEE_MISSING_SUCCESS,
	RSVP_MAIL_FEE_MISSING_FAILURE,
} from "../actions/EventActions";
import {
	GALLERY_SAVE_SUCCESS,
	GALLERY_SAVE_FAILURE,
	GALLERY_REMOVE_FAILURE,
} from "../actions/GalleryActions";
import {
	ARTWORK_SAVE_SUCCESS,
	ARTWORK_SAVE_FAILURE,
} from "../actions/ArtworkActions";
import {
	USER_SAVE_SUCCESS,
	USER_SAVE_FAILURE,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAILURE,
	userSavedGallery,
	userSavedShow,
	userSavedArtwork,
	USER_REMOVE_FAILURE,
	USER_REMOVE_SUCCESS,
	USER_MAKE_SOCIETY_SUCCESS,
	USER_MAKE_SOCIETY_FAILURE,
} from "../actions/UserActions";
import {
	ARTIST_SAVE_SUCCESS,
	ARTIST_SAVE_FAILURE,
	ARTIST_DELETE_FAILURE,
	ARTIST_UPDATE_SUCCESS,
	ARTIST_UPDATE_FAILURE,
} from "../actions/ArtistActions";
import {
	SELECTION_SAVE_SUCCESS,
	SELECTION_SAVE_FAILURE,
	SELECTION_REMOVE_FAILURE,
} from "../actions/SelectionActions";
import {
	SEND_CONTACT_EMAIL_SUCCESS,
	SEND_CONTACT_EMAIL_FAILURE,
} from "../actions/EmailActions";
import { SAVE_ON_UPLOAD_SUCCESS } from "../actions/RegistryActions";
import {
	RAFFLE_SAVE_SUCCESS,
	RAFFLE_SAVE_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";
import { LOGIN_FAILURE } from "@artworkslab/sharedmodules/src/actions/AuthActions";

const createMarkup = (html) => {
	return { __html: html };
};

const formSubmitMiddleware =
	({ dispatch }) =>
	(next) =>
	(action) => {
		// Dispatch show error/success modal action
		switch (action.type) {
			case SHOW_SAVE_SUCCESS:
			// fall through
			case POST_SAVE_SUCCESS:
			// fall through
			case EVENT_SAVE_SUCCESS:
			// fall through
			case EVENT_UPDATE_SUCCESS:
			// fall through
			case ARTWORK_SAVE_SUCCESS:
			// fall through
			case ARTIST_SAVE_SUCCESS:
			// fall through
			case GALLERY_SAVE_SUCCESS:
			// fall through
			case USER_SAVE_SUCCESS:
			// fall through
			case USER_UPDATE_SUCCESS:
			// fall through
			case SELECTION_SAVE_SUCCESS:
			// fall through
			case RAFFLE_SAVE_SUCCESS:
			// fall through
			case ARTIST_UPDATE_SUCCESS:
			// fall through
			case SAVE_ON_UPLOAD_SUCCESS:
			// fall through
			case USER_REMOVE_SUCCESS:
				dispatch(showSuccessModal(action.message || action.response));
				break;
			case SHOW_SAVE_FAILURE:
			// fall through
			case POST_SAVE_FAILURE:
			// fall through
			case EVENT_SAVE_FAILURE:
			// fall through
			case EVENT_UPDATE_FAILURE:
			// fall through
			case ARTWORK_SAVE_FAILURE:
			// fall through
			case GALLERY_SAVE_FAILURE:
			// fall through
			case USER_SAVE_FAILURE:
			// fall through
			case USER_UPDATE_FAILURE:
			// fall through
			case ARTIST_SAVE_FAILURE:
			// fall through
			case SELECTION_SAVE_FAILURE:
			// fall through
			case SELECTION_REMOVE_FAILURE:
			// fall through
			case RAFFLE_SAVE_FAILURE:
			// fall through
			case LOGIN_FAILURE:
			// fall through
			case ARTIST_UPDATE_FAILURE:
			// fall through
			case USER_REMOVE_FAILURE:
				let error = action.error || action.message;
				if (typeof error !== "string") error = error.toString();
				dispatch(showErrorModal(error));
				break;
			default:
				break;
		}
		// Stop submit action
		switch (action.type) {
			case SHOW_SAVE_FAILURE:
				dispatch(stopSubmit("show", {}));
				break;
			case POST_SAVE_FAILURE:
				// TODO: Implement post in redux form
				// dispatch( stopSubmit('post', {}) )
				break;
			case EVENT_SAVE_FAILURE:
			case EVENT_UPDATE_FAILURE:
				dispatch(stopSubmit("event", {}));
				break;
			case ARTWORK_SAVE_FAILURE:
				dispatch(stopSubmit("artwork", {}));
				break;
			case GALLERY_SAVE_FAILURE:
				dispatch(stopSubmit("gallery", {}));
				break;
			case USER_SAVE_FAILURE:
			case USER_UPDATE_FAILURE:
				dispatch(stopSubmit("user", {}));
				break;
			case ARTIST_SAVE_FAILURE:
			case ARTIST_UPDATE_FAILURE:
				dispatch(stopSubmit("artist", {}));
				break;
			case RAFFLE_SAVE_FAILURE:
				dispatch(stopSubmit("raffle", {}));
				break;
			default:
				break;
		}
		// Updating onboarding status
		switch (action.type) {
			case SHOW_SAVE_SUCCESS:
				dispatch(userSavedShow());
				break;
			case ARTWORK_SAVE_SUCCESS:
				dispatch(userSavedArtwork());
				break;
			case GALLERY_SAVE_SUCCESS:
				dispatch(userSavedGallery());
				break;
			default:
				break;
		}

		// Show modal for non form submit actions
		switch (action.type) {
			case RSVP_MAIL_SUCCESS:
			case RSVP_MAIL_FEE_MISSING_SUCCESS:
			case USER_MAKE_SOCIETY_SUCCESS:
			case SEND_CONTACT_EMAIL_SUCCESS:
				dispatch(showSuccessModal(action.message || action.response));
				break;
			case RSVP_MAIL_FAILURE:
			case RSVP_MAIL_FEE_MISSING_FAILURE:
			case USER_MAKE_SOCIETY_FAILURE:
			case SEND_CONTACT_EMAIL_FAILURE:
				const error = action.error || action.message;
				dispatch(showErrorModal(error));
				break;
			default:
				break;
		}

		switch (action.type) {
			case ARTIST_DELETE_FAILURE:
			case GALLERY_REMOVE_FAILURE:
				let error = action.error || action.message;
				if (typeof error !== "string") error = error.toString();
				const markup = createMarkup(error);
				dispatch(showErrorModal(<div dangerouslySetInnerHTML={markup} />));
			default:
				break;
		}

		return next(action);
	};

export default formSubmitMiddleware;
