import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import UserAccountForm from "./UserAccountForm";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import {
	fetchUser,
	endArtistProSubscription,
	fetchMemberfulMember,
} from "../../actions/UserActions";
import { syncStripeUser } from "../../actions/Stripe/StripeSubActions";
import { b_testing } from "../../actions/BillectaActions";
import {
	loadAccountUser,
	submitAccountUser,
} from "../../actions/FormActions/AccountFormActions";
import { showCustomModal, hideCustomModal } from "../../actions/AppActions";
import { removeMyArtist } from "../../actions/ArtistActions";
import { logoutUser } from "@artworkslab/sharedmodules/src/actions/AuthActions";
import {
	isPremium,
	getStripeCustomerId,
	hasStripeSubscription,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { portalSession } from "@artworkslab/sharedmodules/src/actions/Stripe/BillingActions";

const FORM_NAME = "userAccount";

let count = 0;

class UserAccount extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.didSyncStripeUser = false;
	}

	componentDidMount() {
		const { authUser } = this.props;
		if (authUser && authUser.id) {
			this.props.dispatch(fetchUser(authUser.id));
		}
	}

	componentWillReceiveProps(newProps) {
		// if (this.props.user !== newProps.user) {
		// 	this.props.dispatch( loadAccountUser(newProps.user, FORM_NAME) )
		// }

		if (this.props.user.email !== newProps.user.email && newProps.user.email) {
			this.props.dispatch(loadAccountUser(newProps.user, FORM_NAME));

			this.props.dispatch(fetchMemberfulMember(newProps.user.email));

			// Not using this function, multiple Stripe customers can have the same email.
			// In case user would be missing the stripeCustomerId they should have.
			// if ((!newProps.user.stripeCustomerId && !this.didSyncStripeUser) || __DEVELOPMENT__) {
			// 	this.didSyncStripeUser = true
			//
			// 	syncStripeUser(newProps.user._id, newProps.user.email)
			// 	.then(updatedUser => {
			// 		// If not updated, updatedUser is an object { message: "..." }
			// 		if (updatedUser && updatedUser.stripeCustomerId) {
			// 			this.props.dispatch( fetchUser(newProps.authUser.id) )
			// 		}
			// 	})
			// 	.catch(err => {
			// 		console.log('syncStripeUser error:', err);
			// 	})
			// }
		}

		// _self = URL replaces the current page.
		if (!this.props.portalSessionURL && newProps.portalSessionURL) {
			return window.open(newProps.portalSessionURL, "_self");
		}
	}

	submit = () => {
		const {
			form: { userAccount, initial },
		} = this.props;
		this.props.dispatch(submitAccountUser(userAccount, initial, FORM_NAME));
	};

	logout = () => {
		const { t } = this.props;
		const confirm = window.confirm(t("account.logoutConfirm"));
		if (confirm) {
			this.props.dispatch(logoutUser());
			this.props.router.push("/login");
		}
	};

	testing = () => {
		this.props.dispatch(b_testing());
	};

	createPortalSession = (customerId) => {
		// When returning from portal to our site.
		const baseURL = window.location.origin;
		const stepBackURL = `${baseURL}/${this.props.location.pathname.substring(
			1
		)}`;
		// "sv" or "en"
		const locale = i18next.language;

		this.props.dispatch(portalSession(customerId, stepBackURL, locale));
	};

	endArtistProSub = () => {
		const { user, t } = this.props;
		const confirm = window.confirm(t("account.endSubscriptionConfirm"));
		if (confirm) {
			this.props.dispatch(endArtistProSubscription(user._id));
		}
	};

	handleRemove = (artist) => {
		const artistId = artist._id ? artist._id : artist;
		const artistName = artist.name ? artist.name : "Undefined";

		this.props.dispatch(removeMyArtist(artistId, artistName));
		this.props.dispatch(logoutUser());
		this.props.router.push("/login");
	};

	render() {
		const { user: realUser, stripeCustomerId, isArtist, t } = this.props;
		let user = realUser;
		const padding = user.role === "artist" ? "0px 40px" : "0";
		const isResponsive = appGlobals.useResponsive;
		const MainComponent = isResponsive ? MainResp : Main;
		const contentStyle = isResponsive ? {} : { padding: "0 40px" };

		// if (__DEVELOPMENT__) {
		// 	user = createTestUser(user)
		// }

		// contentClassName only used by MainResp.
		return (
			<div>
				<MainComponent contentClassName="slim-content">
					{user && user.error && <p>{user.error}</p>}
					<div style={contentStyle}>
						<UserAccountForm
							onSubmit={this.submit}
							role={user.role}
							gallery={user.gallery || false}
							artist={user.artist}
							endArtistProSub={this.endArtistProSub}
							isPro={isPremium(user)}
							stripeCustomerId_NOT_USED={getStripeCustomerId(user)}
							stripeCustomerId={stripeCustomerId}
							isStripeSubscriber={hasStripeSubscription(user.artist)}
							openStripePortal={this.createPortalSession}
							hasMemberfulSubscription={user.hasMemberfulSubscription || false}
						/>
						<button
							className="purple-button--wide button-nomargin"
							type="button"
							onClick={this.logout}
							style={{ marginTop: "0.77em" }}
						>
							{t("logOut")}
						</button>
					</div>
					{/* Hide until its decided how it should handle artworks, and Strip subscription automatically stopped on delete. */}
					{/* (user.artist && isArtist) &&
						<div className="fieldset">
							<legend style={{paddingTop: 40}}>{t('common:dangerZone')}</legend>
							<button
								type="button"
								className={b('purple-button--wide')({mod: 'danger'})} onClick={() => {
									let confirm = window.confirm(t('common:areYouSure'))
									if(confirm) this.handleRemove(user.artist)
								}}>{t('common:account.artist.delete')}</button>
						</div>
					*/}
				</MainComponent>
				{/* user.role === 'admin' && (__DEVELOPMENT__ || __STAGING__) &&
					<button type="button" onClick={ this.testing } style={{ marginLeft: '50px' }}>{ 'Test' }</button>
				*/}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user: authUser },
		user: { user },
		billing: { portalSessionURL },
	} = state;

	const stripeCustomerId = getStripeCustomerId(user);

	const form = {
		submitting: isSubmitting(FORM_NAME)(state),
		submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
		submitFailed: hasSubmitFailed(FORM_NAME)(state),
		[FORM_NAME]: getFormValues(FORM_NAME)(state),
		initial: getFormInitialValues(FORM_NAME)(state),
	};
	const isArtist = user.role === "artist";

	return { authUser, user, stripeCustomerId, portalSessionURL, form, isArtist };
}

const translated = withNamespaces("common")(UserAccount);
export default connect(mapStateToProps)(withRouter(translated));

const createTestUser = (user) => {
	// Users and artists for testing.
	const freeArtist = Object.assign({}, user.artist, {
		billing: {
			membershipLevel: "free",
			klarnaSubscriptionOrderId: "12345",
		},
		stripeSubscriptions: ["12345"],
	});
	const freeUser = Object.assign({}, user, {
		hasMemberfulSubscription: true,
		stripeCustomerId: "12345",
		stripeSubscriptions: ["12345"],
		artist: freeArtist,
	});
	const memberfulArtist = Object.assign({}, user.artist, {
		billing: {
			membershipLevel: "premium",
			klarnaSubscriptionOrderId: "",
		},
		stripeSubscriptions: [],
	});
	const memberfulUser = Object.assign({}, user, {
		hasMemberfulSubscription: true,
		stripeCustomerId: "",
		stripeSubscriptions: [],
		artist: memberfulArtist,
	});
	const klarnaArtist = Object.assign({}, user.artist, {
		billing: {
			membershipLevel: "premium",
			klarnaSubscriptionOrderId: "12345",
		},
		stripeSubscriptions: [],
	});
	const klarnaUser = Object.assign({}, user, {
		hasMemberfulSubscription: false,
		stripeCustomerId: "",
		stripeSubscriptions: [],
		artist: klarnaArtist,
	});
	const stripeArtist = Object.assign({}, user.artist, {
		billing: {
			membershipLevel: "premium",
			klarnaSubscriptionOrderId: "12345",
		},
		stripeSubscriptions: ["12345"],
	});
	const stripeUser = Object.assign({}, user, {
		hasMemberfulSubscription: false,
		stripeCustomerId: "cus_L4XrhTRuXBZzxu",
		stripeSubscriptions: [],
		artist: stripeArtist,
	});

	// return freeUser
	// return memberfulUser
	// return klarnaUser
	return stripeUser;
};
