import {
  LOAD,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR,
  SHOW_GALLERY_MODAL,
  HIDE_GALLERY_MODAL
}                         from '../../actions/FormActions/GalleryFormActions'
import {
  GALLERY_SAVE_SUCCESS,
  GALLERY_SAVE_FAILED,
}                         from '../../actions/GalleryActions'

export function galleryFormReducer(state = {
  showSuccess: false,
  showError: false,
  showGalleryModal: false,
  error: null
}, action) {
  switch (action.type) {
    case LOAD:
      return Object.assign({}, state, {
        data: action.data
      })
    case GALLERY_SAVE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true
      })
    case GALLERY_SAVE_FAILED:
      return Object.assign({}, state, {
        showError: true,
        error: action.message
      })
    case SHOW_GALLERY_MODAL:
      return Object.assign({}, state, {
        showGalleryModal: true
      })
    case HIDE_GALLERY_MODAL:
      return Object.assign({}, state, {
        showGalleryModal: false
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    default:
      return state
  }
}
