import React from "react";

const DeliveryAddress = ({
	name,
	email,
	country,
	street,
	postalCode,
	city,
}) => {
	return (
		<div className="delivery-address">
			<h4>Delivery</h4>
			<p>{`Name: ${name}`}</p>
			<p>{`Email: ${email}`}</p>
			<p>{`Street: ${street}`}</p>
			<p>{`PostalCode: ${postalCode}`}</p>
			<p>{`City: ${city}`}</p>
			<p>{`Country: ${country}`}</p>
		</div>
	);
};

export default DeliveryAddress;
