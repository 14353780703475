import React, { Component } from 'react'
import { connect }          from 'react-redux'
import {
  Field,
  Fields,
  FormSection,
  formValueSelector,
  reduxForm,
} from 'redux-form'
import { withNamespaces }	  from 'react-i18next'
import TextInput            from '../../components/FormInput/TextInput'
import CheckBox             from '../../components/FormInput/CheckBox'
import TextArea             from '../../components/FormInput/TextArea'
import TextDisplay          from '../../components/FormInput/TextDisplay'
// import Select               from "../../FormInput/Select"
import SingleDateTimePicker from '../../components/FormComponents/DateRange/SingleDateTimePicker'

// Values on the model:
// code: What the user enters on checkout. Randomly created when submitting,
// multiple discounts can be created at once, but need individual codes.
// batchName: Name for a batch of codes.
// artworkId: If discount for a particular artwork. Not used yet.
// artistId: If discount for a particular artist. Not used yet.
// wholeOrder: If discount for a whole order. Always true now.
// percent: Discount in percent. Use this OR SEK (but should work with booth).
// SEK: Discount in SEK. Use this OR percent (but should work with booth).
// valid: Always true when created. Set to false when code is redeemed.
// singleUse: After one use, valid is set to false.
// expirationDate: Last use date.

// class DiscountCodeForm extends Component {
let DiscountCodeForm = (props) => {
  const { handleSubmit, pristine, submitting, isNew, t } = props

  return (
    <form onSubmit={ handleSubmit } className="Form">
      { isNew ?
        <legend>Create a new batch of discount codes</legend>
        :
        <legend>Update a single discount code</legend>
      }
			<div className="fieldset">
				<div className="input-holder" style={{ flexDirection: 'column' }}>
          <Field
            name="batchName"
            component={ TextInput }
            placeholder="Batch name"
            disabled={ !isNew }
            required={ isNew }
            />
          <Field
            name="code"
            component={ TextInput }
            placeholder="Code for user"
            disabled={ !isNew }
            required={ isNew }
            />
          <Field
            name="singleUse"
            checkboxLabel="Only usable once"
            component={ CheckBox }
            />
        </div>
        <div className="input-holder">
          <Field
            name="codeCount"
            component={ TextInput }
            placeholder="Number of codes"
            type="number"
            disabled={ !isNew }
            required={ isNew }
            />
          <Field
            name="percent"
            component={ TextInput }
            placeholder="Discount percent"
            type="number"
            />
          <Field
            name="SEK"
            component={ TextInput }
            placeholder="Discount SEK"
            type="number"
            />
				</div>
      </div>
      <div className="fieldset">
        <div className="input-holder">
          <p style={{ color: '#A8A8A8' }}>Expiration date</p>
          <Field
            name="expirationDate"
            component={ SingleDateTimePicker }
            />
        </div>
        <div className="input-holder">
          <Field
            name="about"
            component={ TextArea }
            placeholder="Description (only for admin)"
          />
          { !isNew &&
            <Field
              name="valid"
              checkboxLabel="Not been used / is valid"
              component={ CheckBox }
              />
          }
        </div>
      </div>
      {/*Save button*/}
      <div className="fieldset">
        <div className="input-holder">
          <Field
            name="slug"
            itemType="discountCode"
            component={ TextDisplay }
          />
        </div>
        <div className="fixed-save-button">
          <button className="purple-button--wide" type="submit" disabled={ pristine || submitting }>{t('common:save')}</button>
        </div>
      </div>
    </form>
  )
}

DiscountCodeForm = reduxForm({
  form: 'discountCode',
})(DiscountCodeForm)

// const selector = formValueSelector('discountCode')
DiscountCodeForm = connect(state => {
//   const eventId = selector(state, '_id')
  return {}
})(DiscountCodeForm)

export default withNamespaces('common')(DiscountCodeForm)
