import React, { Component } from "react";

const DataPoint = (props) => {
	const hasName = props.name && props.name.length > 0;
	const showVal =
		typeof props.value === "string" ||
		(typeof props.value === "number" && props.value > 0);
	const headerClass = props.type + " muted";
	return (
		<div className="ItemStats__Data__DataEntry">
			<h4 className={headerClass}>{props.title}</h4>
			<p className={props.type}>
				{showVal ? props.value : "-"}
				{hasName ? ", " : ""}
				<span>{hasName ? props.name : ""}</span>
			</p>
		</div>
	);
};

const Data = (props) => {
	let key = 0;
	return (
		<div className="ItemStats__Data">
			{props.stats.map((datapoint) => {
				return <DataPoint {...datapoint} key={key++} type={props.type} />;
			})}
		</div>
	);
};

const ItemStats = (props) => {
	const type = props.type || "";
	const webbLink = `${process.env.BASE_URL}utstallningar/${props.slug}`;
	return (
		<div className="ItemStats">
			<a
				className="ItemStats__Image"
				style={props.image}
				href={webbLink}
				target="_blank"
			></a>
			<Data stats={props.stats} type={type} />
		</div>
	);
};

export default ItemStats;
