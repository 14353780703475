import { CALL_API } from '../../utils/api';

export const TOGGLE_EXPANDED = 'artworksapp/dashboard/popularOnAw/TOGGLE_EXPANDED';
export const DATA_REQUEST = 'artworksapp/dashboard/popularOnAw/DATA_REQUEST';
export const DATA_SUCCESS = 'artworksapp/dashboard/popularOnAw/DATA_SUCCESS';
export const DATA_FAILURE = 'artworksapp/dashboard/popularOnAw/DATA_FAILURE';

export function togglePopularOnAw() {
  return {
    type: TOGGLE_EXPANDED
  }
}

export function fetchData() {
  const endpoint = 'stats/dash/awpopular';
  return {
    [CALL_API]: {
      endpoint,
      authenticated: true,
      types: [DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE]
    }
  }
}
