import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import Select from "react-select";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Main from "../../components/Main/Main";
import Graph from "../../components/DashboardComponents/Graph/Graph";
import GalleryMembershipModal from "../../components/Modal/Custom/GalleryMembershipModal";
import LatestSales from "../../components/DashboardComponents/LatestSales/LatestSales";
import { fetchMembers } from "../../actions/RegistryActions";
import {
	fetchAllGalleriesFast,
	mixpanelTrack,
} from "../../actions/GalleryActions";
import { fetchGalleryStatsAndComparison } from "../../actions/DashboardActions/SummaryActions";
import { PREMIUM_POPUP_CLOSED } from "../../actions/FormActions/LoginFormActions";
import { fetchSelections } from "../../actions/SelectionActions";
import {
	getBaseURL,
	getMembersRanking,
	scrollToSection,
} from "../../utils/Helpers";
import { formatRanking } from "../../utils/FormatHelper";
import {
	isPremium,
	isBasicOrAbove,
	isStandardOrAbove,
	isPremiumOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";
import NumberUtil, {
	formatPhoneNumber,
} from "@artworkslab/sharedmodules/src/NumberUtil";

const CHECK_MARK = require("../../../assets/images/icons/dashboard_check_mark.jpg");
const MAN_SILHOUETTE = require("../../../assets/images/icons/dashboard_man.jpg");
const PARTY_CONE = require("../../../assets/images/icons/dashboard_party_cone.jpg");
const THUMBS_UP = require("../../../assets/images/icons/dashboard_thumbs_up.jpg");
const CLOSE = require("../../../assets/images/icons/close@2x.png");
const PEN = require("../../../assets/images/svg/fontAwesome/edit.svg");
const ARROW = require("../../../assets/images/svg/arrow_right.svg");
const ARROW_WHITE = require("../../../assets/images/svg/arrow_right_white.svg");
import StarComponent from "../../../assets/images/svg/material/StarComponent.js";
// const HIGHLIGHT_COLOR = '#8E7CC3'
const HIGHLIGHT_COLOR = "#000000";
const ARTWORKS_PINK = "#F4CADB";
const SOCIETY_DASHBOARD = "Society Dashboard";
const EARLIEST_STATS_DATE = "2022-11-19T15:00:00.000Z";
const STATS_COMPARISON_GALLERY = "5d7a04deff0091702bcd8a60"; // Väsby Konsthall

const MIXPANEL_EVENTS = {
	UPGRADE_MODAL_OPENED: "Upgrade modal openened",
	EDIT_PROFILE: "Edit profile",
	APPROVE_MEMBERS: "Approve members",
	UPLOAD_MEMBERS: "Upload members",
	EMAIL_MEMBERS: "Email members",
	FILE_ARCHIVE: "File archive",
	IMAGE_GALLERY: "Image gallery",
	ARRANGE_SHOW: "Arrange show",
	ARRANGE_RAFFLE: "Arrange raffle",
	ARRANGE_EVENT: "Arrange event",
	ARRANGE_VOTE: "Arrange vote",
	FIND_ART_ON_WEB: "Find art on webb",
	VISIT_RANKING: "Visit ranking",
};

class SocietyDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			selectedGallery: null,
			popupOpen: false,
			rankedElement: null,
			membershipModalOpen: false,
		};
	}

	componentDidMount() {
		const { isAdmin, user, userGallery, hash } = this.props;
		if (isAdmin) {
			this.props.dispatch(fetchAllGalleriesFast());
		} else if (user.gallery) {
			const galleryId = user.gallery._id || user.gallery;
			this.props.dispatch(fetchMembers(galleryId));
			this.props.dispatch(fetchGalleryStatsAndComparison(galleryId));
			if (userGallery._id) {
				// When reloading on dashboard page, userGallery here is an empty object. Don't decide if popup should open until gallery has been loaded.
				this.openPopup(userGallery);
			}
		}

		this.props.dispatch(fetchSelections("ranking"));

		//Scroll if there is a hash
		if (hash) {
			setTimeout(() => {
				scrollToSection(hash);
			}, 1000);
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, allGalleries, userGallery, mailingLists, members } =
			nextProps;

		if (
			isAdmin &&
			this.state.galleryOptions.length !== Object.keys(allGalleries).length &&
			Object.keys(allGalleries).length > 0
		) {
			const galleryOptions = [];
			Object.keys(allGalleries).forEach((key) => {
				const venue = allGalleries[key];
				if (isVenueArtSociety(venue))
					galleryOptions.push({
						value: venue._id,
						label: venue.name,
						premium: __USE_TIERS__ ? true : isPremium(venue),
					});
			});

			this.setState({
				galleryOptions: galleryOptions.sort(this.sortOptions),
			});
		} else if (!isAdmin && !this.props.userGallery._id && userGallery._id) {
			// After gallery has loaded.
			this.openPopup(userGallery);
		}

		// Not using right now. Member count won't match count on ranking page https://artworks.se/helarankningen unless regularly updated.
		// Does not work properly. Try using it as admin.
		//
		// const prevGallery = this.getGallery(this.props) || {} // Can return undefined for admin.
		// const nextGallery = this.getGallery(nextProps) || {} // Can return undefined for admin.
		// const galleryFetched = !prevGallery._id && nextGallery._id
		// const selectionFetched = !this.props.selection._id && nextProps.selection._id
		// if ((selectionFetched && nextGallery._id) || (galleryFetched && nextProps.selection._id)) {
		//   const ranking = getMembersRanking(nextProps.selection)
		//   const ourRank = ranking.findIndex(element => element.name === nextGallery.name)
		//
		//   if (ourRank || ourRank === 0) {
		//     const formattedRanking = formatRanking(ourRank + 1, getBaseURL())
		//     this.setState({
		//       rankedElement: formattedRanking,
		//     })
		//   }
		// }

		//Scroll if new hash
		if (this.props.hash !== nextProps.hash) {
			scrollToSection(nextProps.hash);
		}
	}

	createStatsQuery = (galleryId) => {
		// Don't use data from earlier than this, scheduler job failed and data missing.
		// Data from 2022-11-14T15:00:00.000Z has same visits as totalVisits.
		console.log("__PRODUCTION__", __PRODUCTION__);
		const earliest = __PRODUCTION__
			? EARLIEST_STATS_DATE
			: "2022-10-14T15:00:00.000Z";
		return {
			gallery: galleryId,
			time: { $gt: earliest },
		};
	};

	trackEvent = (clicked, user, gallery, scrollUp = false) => {
		if (user.role === "admin") {
			console.log("Not tracking event for admin.");
			return;
		}
		console.log("Track event", clicked);

		if (scrollUp) window.scrollTo(0, 0);

		const payload = {
			clicked,
			id: user.id,
			name: user.name,
			email: user.email,
			gallery: gallery.name,
		};

		this.props.dispatch(mixpanelTrack(SOCIETY_DASHBOARD, payload));
	};

	trackEventTest = (clicked, user, gallery) => {
		const payload = {
			clicked,
			id: user.id,
			name: user.name,
			email: user.email,
			gallery: gallery.name,
		};
		this.props.dispatch(mixpanelTrack("Test Data", payload));
	};

	openPopup = (userGallery) => {
		const popupClosed = localStorage.getItem(PREMIUM_POPUP_CLOSED) === "closed";
		const premium = __USE_TIERS__ ? true : isPremium(userGallery);
		if (!premium || !popupClosed) {
			setTimeout(() => {
				this.setState({ popupOpen: true });
			}, 1000);
		}
	};

	closePopup = () => {
		this.setState({ popupOpen: false });
		localStorage.setItem(PREMIUM_POPUP_CLOSED, "closed");
	};

	selectGallery = (gallery) => {
		const { allGalleries } = this.props;
		this.setState(
			{
				selectedGallery: gallery,
			},
			() => {
				this.props.dispatch(fetchMembers(gallery.value));
				const statsQuery = this.createStatsQuery(gallery.value);
				this.props.dispatch(fetchGalleryStatsAndComparison(gallery.value));
			}
		);
	};

	getGallery = (props) => {
		const { isAdmin, allGalleries, userGallery } = props;
		const { selectedGallery } = this.state;
		return isAdmin
			? allGalleries[selectedGallery ? selectedGallery.value : 0]
			: userGallery;
	};

	// Toggles the GalleryMembershipModal
	toggleModal = (open, user, gallery) => {
		if (open && user && gallery)
			this.trackEvent(MIXPANEL_EVENTS.UPGRADE_MODAL_OPENED, user, gallery);

		this.setState({
			membershipModalOpen: open,
		});
	};

	createCard = (title, count, image) => {
		if (count > 0) {
			return (
				<Link
					to={"/members"}
					className="society-dashboard__content__right__card"
				>
					<div className="society-dashboard__content__right__card__texts">
						<p>{title}</p>
						<div>{count}</div>
					</div>
					<img src={image} />
				</Link>
			);
		} else {
			return (
				<div className="society-dashboard__content__right__card">
					<div className="society-dashboard__content__right__card__texts">
						<p>{title}</p>
						<div>{count}</div>
					</div>
					<img src={image} />
				</div>
			);
		}
	};

	render() {
		const { isAdmin, user, members, selection, isFetching, error, t } =
			this.props;
		const {
			galleryOptions,
			selectedGallery,
			popupOpen,
			rankedElement,
			membershipModalOpen,
		} = this.state;
		let gallery = this.getGallery(this.props);
		const premium = __USE_TIERS__ ? true : isPremium(gallery);
		const standardOrAbove = isStandardOrAbove(gallery);
		const baseURL = getBaseURL();

		if (!isAdmin && !premium) return null;

		let membersToApprove = Object.keys(members).filter(
			(key) => !members[key].approved
		).length;
		const startOfYear = new Date(new Date().getFullYear(), 0, 1);
		const thisYear = new Date().getFullYear();
		let newMembers = Object.keys(members).filter(
			(key) => new Date(members[key].created_at) > startOfYear
		).length;
		let totalMembers = Object.keys(members).length;

		let address = "";
		if (gallery && gallery.address) {
			address = `${gallery.address.street || ""}${", "}${
				gallery.address.zip || ""
			} ${gallery.address.city || ""}`;
		}
		let streetAddress = "";
		let postAddress = "";
		if (gallery && gallery.address) {
			streetAddress = gallery.address.street || "";
			postAddress = `${gallery.address.zip || ""} ${
				gallery.address.city || ""
			}`;
		}

		const ONLY_TESTING = __DEVELOPMENT__ || __STAGING__ || __PRODUCTION__;

		const gallerySelector = isAdmin ? (
			<div className="society-dashboard__select-container">
				<Select
					value={selectedGallery}
					options={galleryOptions}
					onChange={this.selectGallery}
					onInputChange={() => {}}
					valueKey={"gallery"}
					labelKey="label"
					backspaceToRemoveMessage=""
					backspaceRemoves={false}
					isLoading={isFetching}
					placeholder={"Gallery"}
					multi={false}
					autosize={false}
					className={"gallery-picker-select"}
					isSearchable={true}
				/>
			</div>
		) : null;

		if (!gallery || !gallery._id) {
			return (
				<Main>
					<div className="society-dashboard-next">
						{isAdmin && gallerySelector}
					</div>
				</Main>
			);
		}

		if (ONLY_TESTING) {
			return (
				<Main>
					<div className="society-dashboard-next">
						<div
							className="society-dashboard-next__main-row left-to-right"
							style={{ marginBottom: "30px" }}
						>
							{/* Left column */}
							<div className="society-dashboard-next__left-column top-to-bottom">
								{/* Email card */}
								<div className="dash-card height-33 with-arrow justify-between">
									<p className="with-ellipsis greeting section-header">
										<b>{gallery.name ? gallery.name : t("worldsBest")}</b>
									</p>
									<p className="with-ellipsis no-wrap">
										<span className="profile-header">{t("common:email")}</span>
										<br />
										<Link
											to="/gallery"
											className="profile-email"
											onClick={() =>
												this.trackEvent(
													MIXPANEL_EVENTS.EDIT_PROFILE,
													user,
													gallery,
													true
												)
											}
										>
											{gallery.email || ""}
										</Link>
									</p>
								</div>
								{/* Phone card */}
								<div className="dash-card height-33 with-arrow justify-start">
									<p className="with-ellipsis no-wrap">
										<span className="profile-header">
											{t("common:phoneNumber")}
										</span>
										<br />
										<Link
											to="/gallery"
											onClick={() =>
												this.trackEvent(
													MIXPANEL_EVENTS.EDIT_PROFILE,
													user,
													gallery
												)
											}
										>
											{formatPhoneNumber(gallery.phone)}
										</Link>
										<br />
									</p>
								</div>
								{/* Approve members & new members */}
								<Link
									to="/members"
									className="dash-card height-33 with-arrow dash-purple-light hover-shadow"
									onClick={() =>
										this.trackEvent(
											MIXPANEL_EVENTS.APPROVE_MEMBERS,
											user,
											gallery,
											true
										)
									}
								>
									<div className="left-to-right">
										<p
											className={
												membersToApprove > 0 ? "animate-on-hover-small" : ""
											}
											style={{
												fontWeight: membersToApprove > 0 ? "bold" : "normal",
											}}
										>
											{t("membersToApprove")}
										</p>
										<p
											className="dashboard-card__count"
											style={{
												fontWeight: membersToApprove > 0 ? "bold" : "normal",
											}}
										>
											{NumberUtil.format(membersToApprove, { thousand: " " })}
										</p>
									</div>
									<p>
										{t("society.newMembersThisYear", {
											count: NumberUtil.format(newMembers, { thousand: " " }),
										})}
									</p>
								</Link>
								{/* Activities / Upload members */}
								{totalMembers > 0 ? (
									<Link
										to="/events"
										className="dash-card height-33 with-arrow dash-purple-light hover-shadow"
										onClick={() =>
											this.trackEvent(
												MIXPANEL_EVENTS.ARRANGE_EVENT,
												user,
												gallery,
												true
											)
										}
									>
										<p>{t("society.easierActivities2")}</p>
										<p>
											{t("society.useOurFunction")}{" "}
											<b>{t("society.clickHere")}</b>
										</p>
									</Link>
								) : (
									<Link
										to="/members"
										className="dash-card height-33 with-arrow dash-purple-light hover-shadow"
										onClick={() =>
											this.trackEvent(
												MIXPANEL_EVENTS.UPLOAD_MEMBERS,
												user,
												gallery,
												true
											)
										}
									>
										<p>{t("society.easierActivities")}</p>
										<p>
											{t("society.uploadMembersFirst")}{" "}
											<b>{t("society.here")}</b>
										</p>
									</Link>
								)}
							</div>
							{/* End Left column */}

							{/* Right column */}
							<div className="society-dashboard-next__right-column top-to-bottom">
								{/* Logo card */}
								<div className="dash-card height-33 with-arrow justify-end">
									<div className="dash-card-logo">
										{gallery.logo && gallery.logo.src ? (
											<Link
												to="/gallery"
												onClick={() =>
													this.trackEvent(
														MIXPANEL_EVENTS.EDIT_PROFILE,
														user,
														gallery,
														true
													)
												}
											>
												<img src={gallery.logo.src} />
											</Link>
										) : (
											<Link
												to="/gallery"
												onClick={() =>
													this.trackEvent(
														MIXPANEL_EVENTS.EDIT_PROFILE,
														user,
														gallery,
														true
													)
												}
											>
												<div className="dash-card-logo logo-placeholder">
													{t("society.logo")}
												</div>
											</Link>
										)}
									</div>
								</div>
								{/* Address card */}
								<div className="dash-card height-33 with-arrow justify-start">
									<p className="with-ellipsis no-wrap">
										<span className="profile-header">
											{t("common:address")}
										</span>
										<br />
										{streetAddress && (
											<Link
												to="/gallery"
												onClick={() =>
													this.trackEvent(
														MIXPANEL_EVENTS.EDIT_PROFILE,
														user,
														gallery,
														true
													)
												}
											>
												{streetAddress}
											</Link>
										)}
										{streetAddress && <br />}
										{postAddress && (
											<Link
												to="/gallery"
												onClick={() =>
													this.trackEvent(
														MIXPANEL_EVENTS.EDIT_PROFILE,
														user,
														gallery,
														true
													)
												}
											>
												{postAddress}
											</Link>
										)}
									</p>
								</div>
								{/* Members total */}
								<a
									href={`${baseURL}helarankningen`}
									target="_blank"
									className="dash-card height-33 with-arrow dash-purple-light hover-shadow"
									onClick={() =>
										this.trackEvent(
											MIXPANEL_EVENTS.VISIT_RANKING,
											user,
											gallery,
											true
										)
									}
								>
									<p>
										{t("society.membersTotalAndCompare", {
											count: NumberUtil.format(totalMembers, { thousand: " " }),
										})}{" "}
										<b>{t("society.here")}</b>
									</p>
									{/* rankedElement &&
                    <div className="bottom-text">{ rankedElement }</div>
                  */}
								</a>
								{/* File archive */}
								{standardOrAbove ? (
									<Link
										to="/filearchive"
										className="dash-card height-33 with-arrow dash-purple-light hover-shadow"
										onClick={() =>
											this.trackEvent(
												MIXPANEL_EVENTS.FILE_ARCHIVE,
												user,
												gallery,
												true
											)
										}
									>
										<p>{t("society.fileArchive")}</p>
										<p>
											{t("society.saveDocumentation")}{" "}
											<b>{t("society.here")}</b>
										</p>
									</Link>
								) : (
									<div
										className="dash-card height-33 with-arrow dash-purple-light hover-shadow"
										style={{ cursor: "pointer" }}
										onClick={() => this.toggleModal(true, user, gallery)}
									>
										<p>{t("society.fileArchive")}</p>
										<p>
											{t("society.upgradeTo")} <b>{t("society.premiumHere")}</b>{" "}
											{t("society.handleDocuments")}
										</p>
									</div>
								)}
							</div>
							{/* End Right column */}
						</div>

						{(isAdmin || __DEVELOPMENT__) && (
							<Graph
								galleryStats={this.props.galleryStats}
								galleryStatsCompare={this.props.galleryStatsCompare}
								t={t}
							/>
						)}

						{/* Popular activities, not fixed card height */}
						<h4 className="section-header" style={{ margin: "30px 0 10px 0" }}>
							{t("society.tipsFromUs")}
						</h4>
						<div className="society-dashboard-next__main-row left-to-right">
							{/* Left column */}
							<div className="society-dashboard-next__left-column top-to-bottom">
								{/* Upgrade to premium */}
								{standardOrAbove ? (
									<Link
										to="/imagegallery"
										className="dash-card height-33 with-arrow dash-purple-dark hover-shadow"
										onClick={() =>
											this.trackEvent(
												MIXPANEL_EVENTS.IMAGE_GALLERY,
												user,
												gallery,
												true
											)
										}
									>
										<p>
											{t("society.presentBoard")}{" "}
											<b>{t("society.clickHere")}</b>{" "}
											{t("society.createImageGallery")}
										</p>
									</Link>
								) : (
									<div
										className="dash-card dash-purple-dark hover-shadow"
										style={{ cursor: "pointer" }}
										onClick={() => this.toggleModal(true, user, gallery)}
									>
										<p>{t("society.upgradeToPremium")}</p>
										<p>
											{t("society.getAccessTo")}{" "}
											{t("society.readMoreAndUpgrade")}{" "}
											<b>{t("society.here")}</b>
										</p>
										<StarComponent
											svgClass={"premium-star"}
											style={{ fill: ARTWORKS_PINK }}
										/>
									</div>
								)}
							</div>
							{/* Right column */}
							<div className="society-dashboard-next__right-column top-to-bottom">
								{/* Create show */}
								<Link
									to="/raffles"
									className="dash-card dash-purple-dark hover-shadow"
									style={{ flexGrow: 1 }}
									onClick={() =>
										this.trackEvent(
											MIXPANEL_EVENTS.ARRANGE_RAFFLE,
											user,
											gallery,
											true
										)
									}
								>
									<p>{t("society.createDigitalShow")}</p>
									<p>
										{t("society.createRaffle")} <b>{t("society.here")}</b>
									</p>
								</Link>
							</div>
						</div>
						{/* End Popular activities, not fixed card height */}
						{/* Popular activities */}
						<div className="society-dashboard-next__main-row left-to-right">
							{/* Left column */}
							<div className="society-dashboard-next__left-column top-to-bottom">
								{/* Find art */}
								<a
									href="https://artworks.se/konstverk"
									target="_blank"
									className="dash-card height-33 dash-purple-dark hover-shadow"
									onClick={() =>
										this.trackEvent(
											MIXPANEL_EVENTS.FIND_ART_ON_WEB,
											user,
											gallery
										)
									}
								>
									<p>{t("society.exploreArt")}</p>
									<p>
										{t("society.weHaveArt")}{" "}
										<b>{t("society.getInspiredHere")}</b>
									</p>
								</a>
							</div>
							{/* Right column */}
							<div className="society-dashboard-next__right-column top-to-bottom">
								{/* Create vote event */}
								<Link
									to="/events"
									className="dash-card height-33 dash-purple-dark hover-shadow"
									onClick={() =>
										this.trackEvent(
											MIXPANEL_EVENTS.ARRANGE_VOTE,
											user,
											gallery,
											true
										)
									}
								>
									<p>{t("society.letMembersVote")}</p>
									<p className="animate-on-hover-smaller">
										{t("society.arrangeDigitalVote")}{" "}
										<b>{t("society.getStarted")}</b>
									</p>
								</Link>
							</div>
						</div>
						{/* End Popular activities */}

						<div id="latest-sales">
							<LatestSales />
						</div>
					</div>
					{isAdmin && gallerySelector}
					{/*Update modal*/}
					{membershipModalOpen && (
						<GalleryMembershipModal
							gallery={gallery}
							user={user}
							close={() => this.toggleModal(false)}
						/>
					)}
				</Main>
			);
		}

		return (
			<Main>
				<div className="society-dashboard-new">
					{gallery && gallery._id && premium && (
						<div className="society-dashboard-new__content">
							<div className="society-dashboard-new__content__left">
								<div className="society-profile white-bg">
									<div className="society-profile__header">
										<div className="society-profile__header__greeting">
											<p>{t("hello")},</p>
											<p>{gallery.name || t("worldsBest")}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="society-dashboard-new__content__right">
								<div
									className="dashboard-card logo-container"
									style={{ backgroundColor: "white" }}
								>
									{gallery.logo && gallery.logo.src && (
										<div className="dashboard-logo">
											<img src={gallery.logo.src} />
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{gallery && gallery._id && premium && (
						<div className="society-dashboard-new__content">
							<div className="society-dashboard-new__content__left">
								<div className="society-profile">
									<div className="society-profile__info">
										<div className="society-profile__info__left-column">
											<p className="with-ellipsis">
												<span className="profile-header">
													{t("common:email")}
												</span>
												<br />
												<span className="profile-email">
													{gallery.email || ""}
												</span>
											</p>
											<p style={{ marginTop: "20px" }}>
												<span className="profile-header">
													{t("common:phoneNumber")}
												</span>
												<br />
												<span>{gallery.phone || ""}</span>
											</p>
										</div>
										<div className="society-profile__info__right-column">
											<p style={{ marginBottom: "20px" }}>
												<span className="profile-header">
													{t("common:address")}
												</span>
												<br />
												<span>{address}</span>
											</p>
											<Link to="/gallery">
												<span>{t("common:edit")}</span>
												<ARROW />
											</Link>
										</div>
									</div>
								</div>
								{totalMembers === 0 && (
									<Link
										to="/members"
										className="society-profile upload-members"
									>
										<div style={{ width: "100%" }}>
											<p>{t("society.automaticReminders")}</p>
											<p style={{ color: "#8E7CC3", whiteSpace: "nowrap" }}>
												<b>{t("society.uploadMembers")}</b>
												<ARROW />
											</p>
										</div>
									</Link>
								)}
							</div>
							<div className="society-dashboard-new__content__right">
								<div className="dashboard-card" style={{ marginTop: 0 }}>
									<Link to={"/members"} className="dashboard-card__texts">
										{membersToApprove > 0 ? (
											<p style={{ color: "#8E7CC3" }}>
												{t("membersToApprove")} <ARROW />
											</p>
										) : (
											<p>{t("membersToApprove")}</p>
										)}
									</Link>
									<div className="dashboard-card__count">
										{membersToApprove}
									</div>
								</div>
								<div to={"/members"} className="dashboard-card">
									<Link to={"/members"} className="dashboard-card__texts">
										<p>{t("newMembersSince") + ` ${thisYear}`}</p>
									</Link>
									<div className="dashboard-card__count">{newMembers}</div>
								</div>
								<div className="dashboard-card">
									<Link to={"/members"} className="dashboard-card__texts">
										<p>{t("membersTotal")}</p>
									</Link>
									<div className="dashboard-card__count">{totalMembers}</div>
								</div>
							</div>

							{false && !premium && (
								<div className="get-premium-modal">
									<div className="get-premium-modal__popup">
										<p className="large-text" style={{ marginBottom: "12px" }}>
											Hej!
										</p>
										<p>
											Du är för närvarande på Artworks BAS. Uppgradera till{" "}
											<span className="get-premium-modal__popup__upgrade-link">
												Artworks Premium
											</span>{" "}
											och få tillgång till bland annat:
										</p>
										<ul className="large-text">
											<li>
												Får du viktig information om antal visningar,
												övergripande info om medlemmar mm
											</li>
											<li>
												Automatiska avstämningar av medlemsavgifter och
												deltagaravgifter
											</li>
											<li>Nyhetsbrevsfunktion</li>
											<li>Filarkiv</li>
											<li>Tipsfunktion för nya utställningar</li>
											<li>Mejlhistorik</li>
										</ul>
										<div className="get-premium-modal__popup__upgrade-button">
											Uppgradera nu!
										</div>
									</div>
								</div>
							)}
							{false && premium && popupOpen && (
								<div className="already-premium-modal">
									<div className="already-premium-modal__popup">
										<img
											src={CLOSE}
											className="already-premium-modal__popup__close"
											onClick={this.closePopup}
										/>
										<p className="large-text" style={{ marginBottom: "12px" }}>
											Grattis!
										</p>
										<p className="large-text">
											<span>
												Du har för närvarande <b>Artworks Premium</b>
											</span>{" "}
											som ger dig tillgång till bland annat:
										</p>
										<ul className="large-text">
											<li>
												Automatiska avstämningar av medlemsavgifter och
												deltagaravgifter
											</li>
											<li>Nyhetsbrevsfunktion</li>
											<li>Filarkiv</li>
											<li>Tipsfunktion för nya utställningar</li>
											<li>Mejlhistorik</li>
										</ul>
										{/*<p>Din prenumeration av Artworks Premium går ut 2020-12-31. <span className={ getPremiumModal('popup')('upgrade-link') }>Klicka här för att förlänga din prenumeration.</span></p>*/}
									</div>
								</div>
							)}
						</div>
					)}
					{/* premium && */}
					{/* isPremium(gallery) && false &&
            <div className={ bnew('premium-info') }>
              <p>Du har för närvarande <b>Artworks Premium</b> som ger dig tillgång till bland annat <b>automatiska avstämningar</b> av medlemsavgifter och deltagaravgifter, <b>nyhetsbrevsfunktion</b>, <b>filarkiv</b>, <b>tipsfunktion</b> för nya utställningar och <b>mejlhistorik</b>.</p>
            </div>
          */}
					<div id="latest-sales">
						<LatestSales />
					</div>
					{isAdmin && (
						<div className="society-dashboard-new__select-container">
							<Select
								value={selectedGallery}
								options={galleryOptions}
								onChange={this.selectGallery}
								onInputChange={() => {}}
								valueKey={"gallery"}
								labelKey="label"
								backspaceToRemoveMessage=""
								backspaceRemoves={false}
								isLoading={isFetching}
								placeholder={"Gallery"}
								multi={false}
								autosize={false}
								className={"gallery-picker-select"}
								isSearchable={true}
							/>
							{/* For testing Mixpanel on master, remove when not used. */}
							{/* user.email === 'lf@artworks.io' &&
                <div style={{ marginTop: '50px' }}>
                  <button onClick={() => this.trackEventTest('Test event 1', user, gallery, true)}>Mixpanel test 1</button>
                  <button onClick={() => this.trackEventTest('Test event 2', user, gallery, true)}>Mixpanel test 2</button>
                </div>
              */}
						</div>
					)}
				</div>
			</Main>
		);

		// return (
		// 	<Main>
		// 		<div className={b()}>
		// 			{gallery && gallery._id && premium && (
		// 				<div className={b("content")}>
		// 					<div className={b("content")("left")}>
		// 						{/*
		//           <div className={ b('content')('left')('header') }>
		//             <h1>{t('hello')},</h1>
		//             <img src={ PARTY_CONE } />
		//             <h1>{ gallery.name || t('worldsBest') }!</h1>
		//           </div>
		//         */}
		// 						<Link to="/gallery" className={b("content")("left")("profile")}>
		// 							<div className={b("content")("left")("profile")("logo")}>
		// 								{gallery.logo && gallery.logo.src && (
		// 									<img src={gallery.logo.src} />
		// 								)}
		// 							</div>
		// 							<div className={b("content")("left")("profile")("info")}>
		// 								<p>{t("profile")}</p>
		// 								<div>
		// 									<p>
		// 										{t("common:email")}
		// 										<br />
		// 										<span>{gallery.email || ""}</span>
		// 									</p>
		// 									<p>
		// 										{t("common:phoneNumber")}
		// 										<br />
		// 										<span>{gallery.phone || ""}</span>
		// 									</p>
		// 								</div>
		// 								<p>
		// 									{t("common:address")}
		// 									<br />
		// 									<span>{address}</span>
		// 								</p>
		// 							</div>
		// 							<PEN
		// 								className={b("content")("left")("profile")("edit")}
		// 								data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
		// 							/>
		// 						</Link>
		// 						<Link
		// 							to="/members"
		// 							className={b("content")("left")("profile").mix(
		// 								"upload-members"
		// 							)}
		// 						>
		// 							<div style={{ width: "100%" }}>
		// 								<p>{t("society.automaticReminders")}</p>
		// 								<p style={{ color: "#8E7CC3" }}>
		// 									<b>{t("society.uploadMembers")}</b>
		// 								</p>
		// 							</div>
		// 							<img src={MAN_SILHOUETTE} />
		// 						</Link>
		// 					</div>
		// 					<div className={b("content")("right")}>
		// 						{this.createCard(
		// 							t("membersToApprove"),
		// 							membersToApprove,
		// 							THUMBS_UP
		// 						)}
		// 						{this.createCard(
		// 							t("newMembersSince") + ` ${thisYear}`,
		// 							newMembers,
		// 							CHECK_MARK
		// 						)}
		// 						{this.createCard(
		// 							t("membersTotal"),
		// 							totalMembers,
		// 							MAN_SILHOUETTE
		// 						)}
		// 					</div>

		// 					{false && !premium && (
		// 						<div className={getPremiumModal()}>
		// 							<div className={getPremiumModal("popup")}>
		// 								<p className="large-text" style={{ marginBottom: "12px" }}>
		// 									Hej!
		// 								</p>
		// 								<p>
		// 									Du är för närvarande på Artworks BAS. Uppgradera till{" "}
		// 									<span
		// 										className={getPremiumModal("popup")("upgrade-link")}
		// 									>
		// 										Artworks Premium
		// 									</span>{" "}
		// 									och få tillgång till bland annat:
		// 								</p>
		// 								<ul className="large-text">
		// 									<li>
		// 										Får du viktig information om antal visningar,
		// 										övergripande info om medlemmar mm
		// 									</li>
		// 									<li>
		// 										Automatiska avstämningar av medlemsavgifter och
		// 										deltagaravgifter
		// 									</li>
		// 									<li>Nyhetsbrevsfunktion</li>
		// 									<li>Filarkiv</li>
		// 									<li>Tipsfunktion för nya utställningar</li>
		// 									<li>Mejlhistorik</li>
		// 								</ul>
		// 								<div className={getPremiumModal("popup")("upgrade-button")}>
		// 									Uppgradera nu!
		// 								</div>
		// 							</div>
		// 						</div>
		// 					)}
		// 					{false && premium && popupOpen && (
		// 						<div className={alreadyPremiumModal()}>
		// 							<div className={alreadyPremiumModal("popup")}>
		// 								<img
		// 									src={CLOSE}
		// 									className={alreadyPremiumModal("popup")("close")}
		// 									onClick={this.closePopup}
		// 								/>
		// 								<p className="large-text" style={{ marginBottom: "12px" }}>
		// 									Grattis!
		// 								</p>
		// 								<p className="large-text">
		// 									<span>
		// 										Du har för närvarande <b>Artworks Premium</b>
		// 									</span>{" "}
		// 									som ger dig tillgång till bland annat:
		// 								</p>
		// 								<ul className="large-text">
		// 									<li>
		// 										Automatiska avstämningar av medlemsavgifter och
		// 										deltagaravgifter
		// 									</li>
		// 									<li>Nyhetsbrevsfunktion</li>
		// 									<li>Filarkiv</li>
		// 									<li>Tipsfunktion för nya utställningar</li>
		// 									<li>Mejlhistorik</li>
		// 								</ul>
		// 								{/*<p>Din prenumeration av Artworks Premium går ut 2020-12-31. <span className={ getPremiumModal('popup')('upgrade-link') }>Klicka här för att förlänga din prenumeration.</span></p>*/}
		// 							</div>
		// 						</div>
		// 					)}
		// 				</div>
		// 			)}
		// 			{/* premium && */}
		// 			{isPremium(gallery) && false && (
		// 				<div className={b("premium-info")}>
		// 					<p>
		// 						Du har för närvarande <b>Artworks Premium</b> som ger dig
		// 						tillgång till bland annat <b>automatiska avstämningar</b> av
		// 						medlemsavgifter och deltagaravgifter, <b>nyhetsbrevsfunktion</b>
		// 						, <b>filarkiv</b>, <b>tipsfunktion</b> för nya utställningar och{" "}
		// 						<b>mejlhistorik</b>.
		// 					</p>
		// 				</div>
		// 			)}
		// 			{isAdmin && (
		// 				<div className={b("select-container")}>
		// 					<Select
		// 						value={selectedGallery}
		// 						options={galleryOptions}
		// 						onChange={this.selectGallery}
		// 						onInputChange={() => {}}
		// 						valueKey={"gallery"}
		// 						labelKey="label"
		// 						backspaceToRemoveMessage=""
		// 						backspaceRemoves={false}
		// 						isLoading={isFetching}
		// 						placeholder={"Gallery"}
		// 						multi={false}
		// 						autosize={false}
		// 						className={"gallery-picker-select"}
		// 						isSearchable={true}
		// 					/>
		// 				</div>
		// 			)}
		// 		</div>
		// 	</Main>
		// );
	}
}

const mapStateToProps = (state) => {
	// console.log('state', state);
	const {
		auth: { user },
		gallery: { gallery: userGallery, allGalleries, isFetching, error },
		registry: { members, isFetching: fetchingMembers },
		selection: { paths, selections, selection },
		summary: { galleryStats, galleryStatsCompare },
	} = state;
	// console.log('state', state);
	// console.log('userGallery', userGallery);
	const isAdmin = user.role === "admin";

	return {
		user,
		isAdmin,
		isFetching,
		error,
		userGallery,
		allGalleries,
		members,
		fetchingMembers,
		selection,
		galleryStats,
		galleryStatsCompare,
	};
};

const translated = withNamespaces("dashboard")(SocietyDashboard);
export default connect(mapStateToProps)(translated);

// <div className={ bnext('main-row').mix('left-to-right') }>
//   {/* Left column */}
//   <div className={ bnext('left-column').mix('top-to-bottom') }>
//
//     {/* Info card */}
//     <div className="dash-card height-66">
//       <div className={ bnext().mix('left-to-right') } style={{ marginBottom: '10px' }}>
//         {/*<p>{t('hello')},<br />{ gallery.name || t('worldsBest') }</p>*/}
//         <p className="with-ellipsis greeting"><b>{ gallery.name ? gallery.name : t('worldsBest') }</b></p>
//         <div className="dash-card-logo">
//           { gallery.logo && gallery.logo.src ?
//             <img src={ gallery.logo.src } />
//             :
//             <Link to="/gallery" onClick={() => this.trackEvent('Edit profile', user, gallery, true)}>
//               <div className="dash-card-logo logo-placeholder">{t('society.logo')}</div>
//             </Link>
//           }
//         </div>
//       </div>
//       <div className={ bnext().mix('left-to-right') } style={{ alignItems: 'flex-start', paddingBottom: '20px' }}>
//         <p className="with-ellipsis no-wrap">
//           <span className="profile-header">{t('common:email')}</span>
//           <br />
//           <Link to="/gallery" className="profile-email" onClick={() => this.trackEvent('Edit profile', user, gallery, true)}>{ gallery.email || '' }</Link>
//         </p>
//         <p className="with-ellipsis no-wrap">
//           <span className="profile-header">{t('common:address')}</span>
//           <br />
//           { streetAddress && <Link to="/gallery" onClick={() => this.trackEvent('Edit profile', user, gallery, true)}>{ streetAddress }</Link> }
//           { streetAddress && <br /> }
//           { postAddress && <Link to="/gallery" onClick={() => this.trackEvent('Edit profile', user, gallery, true)}>{ postAddress }</Link> }
//         </p>
//       </div>
//       <div className={ bnext().mix('left-to-right') } style={{ alignItems: 'flex-end', paddingBottom: '20px' }}>
//         <p className="with-ellipsis no-wrap">
//           <span className="profile-header">{t('common:phoneNumber')}</span>
//           <br />
//           <Link to="/gallery" onClick={() => this.trackEvent('Edit profile', user, gallery)}>{ gallery.phone || '' }</Link>
//         </p>
//       </div>
//     </div>
//     {/* Upload members */}
//     <div className="dash-card height-33 with-arrow dash-purple-light">
//       <p>{t('society.emailMembers')}</p>
//       <Link to="/emails" style={{ alignSelf: 'flex-start' }} onClick={() => this.trackEvent('Email members', user, gallery, true)}>
//         <p className="animate-on-hover" style={{ whiteSpace: 'nowrap' }}><b>{t('society.writeAnEmail')}</b>{/*<ARROW />*/}</p>
//       </Link>
//     </div>
//   </div>
//   {/* End Left column */}
//
//   {/* Right column */}
//   <div className={ bnext('right-column').mix('top-to-bottom') }>
//     {/* Approve members & new members */}
//     <div className="dash-card height-33 with-arrow dash-purple-light justify-around">
//       <Link to="/members" className="left-to-right" onClick={() => this.trackEvent('Approve members', user, gallery, true)}>
//         <p className={ membersToApprove > 0 ? 'animate-on-hover-small' : '' } style={{ fontWeight: membersToApprove > 0 ? 'bold' : 'normal' }}>{t('membersToApprove')} {/*<ARROW />*/}</p>
//         <p className={ block('dashboard-card')('count') } style={{ fontWeight: membersToApprove > 0 ? 'bold' : 'normal' }}>{ membersToApprove }</p>
//       </Link>
//       <div className="left-to-right">
//         <p className="NOT-animate-on-hover-small">{t('newMembersSince') + ` ${thisYear}`} {/*<ARROW />*/}</p>
//         <div className={ block('dashboard-card')('count') }>{ newMembers }</div>
//       </div>
//     </div>
//     {/* Upload members */}
//     <div className="dash-card height-33 with-arrow dash-purple-light">
//       <p>{t('society.automaticReminders')}</p>
//       <Link to="/members" style={{ alignSelf: 'flex-start' }} onClick={() => this.trackEvent('Upload members', user, gallery, true)}>
//         <p className="animate-on-hover-small" style={{ whiteSpace: 'nowrap' }}><b>{t('society.uploadMembers')}</b>{/*<ARROW />*/}</p>
//       </Link>
//     </div>
//     {/* Members total */}
//     <div className="dash-card height-33 with-arrow dash-purple-light">
//       <Link to="/members" className="left-to-right">
//         <p className="NOT-animate-on-hover-small">{t('membersTotal')} {/*<ARROW />*/}</p>
//         <div className={ block('dashboard-card')('count') }>{ totalMembers }</div>
//       </Link>
//       {/* rankedElement &&
//         <div className="bottom-text">{ rankedElement }</div>
//       */}
//       <div className="bottom-text">
//         <p style={{ lineHeight: '1em' }}>{t('society.compareRanking')} <a href={ `${baseURL}helarankningen` } target="_blank" style={{ textDecoration: 'none' }}><b>{t('society.here')}</b></a></p>
//       </div>
//     </div>
//   </div>
//   {/* End Right column */}
// </div>

// {/* Popular activities */}
// <div className={ bnext('main-row').mix('left-to-right') }>
//   <div className={ bnext('left-column').mix('top-to-bottom') }>
//     { isPremiumOrAbove(gallery) ?
//       <Link to="/shows" className="dash-card height-33 with-arrow dash-purple-dark" onClick={() => this.trackEvent(MIXPANEL_EVENTS.ARRANGE_SHOW, user, gallery, true)}>
//         <p>{t('society.organizeShow')}</p>
//         <p className="animate-on-hover"><b>{t('society.tryHere')}</b></p>
//       </Link>
//       :
//       <div className="dash-card height-33 with-arrow dash-purple-dark">
//         <p>{t('society.tryOffer')} <b>{t('society.tryOfferPrice')}<br />{t('society.tryOfferDiscount')}</b></p>
//         <p className="animate-on-hover" onClick={() => this.toggleModal(true, user, gallery)}><b>{t('society.upgradeHere')}</b>{/*<ARROW_WHITE />*/}</p>
//       </div>
//     }
//     <Link to="/raffles" className="dash-card height-33 with-arrow dash-purple-dark" onClick={() => this.trackEvent(MIXPANEL_EVENTS.ARRANGE_RAFFLE, user, gallery, true)}>
//       <p>{t('society.organizeRaffle')}</p>
//       <p className="animate-on-hover"><b>{t('society.tryHere')}</b></p>
//     </Link>
//
//     {/*<div className="image-card dash-purple-dark">
//       <div className="info-square">
//         <p>{t('society.organizeRaffle')}</p>
//         <p className="animate-on-hover"><b>{t('society.tryHere')}</b><ARROW /></p>
//       </div>
//       <div className="image-square">
//         <img src="https://images.unsplash.com/photo-1590622974113-66a9160acf20?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80" />
//       </div>
//     </div>*/}
//     {/*<div className="divider-square"></div>*/}
//
//   </div>
//   <div className={ bnext('right-column').mix('top-to-bottom') }>
//     <a href="https://artworks.se/konstverk" target="_blank" className="dash-card height-33 with-arrow dash-purple-dark" onClick={() => this.trackEvent(MIXPANEL_EVENTS.FIND_ART_ON_WEB, user, gallery)}>
//       <p>{t('society.exploreArt')}</p>
//       <p className="animate-on-hover"><b>{t('society.findArtHere')}</b>{/*<ARROW_WHITE />*/}</p>
//     </a>
//     <Link to="/events" className="dash-card height-33 with-arrow dash-purple-dark" onClick={() => this.trackEvent(MIXPANEL_EVENTS.ARRANGE_EVENT, user, gallery, true)}>
//       <p>{t('society.organizeVote')}</p>
//       <p className="animate-on-hover"><b>{t('society.tryHere')}</b>{/*<ARROW />*/}</p>
//     </Link>
//   </div>
// </div>
