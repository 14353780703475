import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import Dropzone from "react-dropzone";
import Select from "react-select";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormInitialValues,
	getFormValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import AccessForm from "../../views/Access/AccessForm";
import {
	// fetchAllArtworksFast,
	// fetchAllArtworksPopulated,
	fetchArtworksForGallery,
	fetchArtworksForArtist,
	saveArtwork,
	// createArtworkFromImages,
	createArtworkFromImage,
	deleteArtwork,
	clearArtworks,
	reorderArtwork,
	resetIndexAnimation,
} from "../../actions/ArtworkActions";
import {
	showArtworkModal,
	hideArtworkModal,
	submitArtwork,
	// fetchArtworks,
	// fetchArtistArtworks
} from "../../actions/FormActions/ArtworkFormActions";
// import {
// 	fetchAllArtistsFast,
// } from '../../actions/ArtistActions'
// import {
// 	fetchAllGalleriesFast,
// } from '../../actions/GalleryActions'
import {
	showArtistModal,
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import { saveRaffle } from "../../actions/RaffleActions";
import { saveShow } from "../../actions/ShowActions";
import { showCustomModal } from "../../actions/AppActions";
import { markNotifications } from "@artworkslab/sharedmodules/src/actions/NotificationActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import ArtworkForm from "../../views/Artworks/ArtworkForm";
import ArtistForm from "../../views/Artist/ArtistForm";
import Modal from "../Modal/Modal";
import ArtworksListSelector from "./ArtworksListSelector";
import MentionedArtworksList from "./MentionedArtworksList";
import TinySelect from "./TinySelect";
import Tooltip from "../../components/Tooltip/Tooltip";
import SwitchButton from "../../components/Buttons/SwitchButton/SwitchButton";
import { getFirstImage, thumbnailError } from "../../utils/Helpers";
import { isSlugAutoGenerated } from "@artworkslab/sharedmodules/src/utils/Helpers";
import {
	submitAccess,
	hideAccessModal,
} from "../../actions/FormActions/AccessFormActions";

const MAX_SIZE = 5242880,
	MAX_SIZE_STRING = "5MB"; // 5MB in bytes
const ALL_FILTER = "All";
const BIN_SVG = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");
const PLUS_WHITE = require("../../../assets/images/svg/plus_32px_white.svg");
const CLOSE_WHITE = require("../../../assets/images/icons/close.white@2x.png");
const ACCEPT = "image/jpeg,image/jpg,image/png";
// const PENCIL = require('../../../assets/images/svg/fontAwesome/fa_pencil.svg')

const PLACEHOLDER_TITLE = {
	swedish: "Lägg till titel",
	english: "Add title",
};

// This component to not update state for all members when inputting values.
// Only updates in database on blur.
class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
	}

	componentDidMount() {
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setValue(nextProps.value);
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	_update = (evt) => {
		const { artworkId, keyForValue, prevValue, currentSlug, update } =
			this.props;
		const inputValue = evt.target.value;

		if (!inputValue) {
			this.setValue(prevValue);
			return;
		}

		const body = {
			_id: artworkId,
			[keyForValue]: inputValue,
		};

		// Creating slug for title "Untitled" became too slow when too many artworks have that title.
		if (keyForValue === "title") {
			const titleIsPlaceholder =
				inputValue.indexOf(PLACEHOLDER_TITLE.swedish) >= 0 ||
				inputValue.indexOf(PLACEHOLDER_TITLE.english) >= 0;
			// Don't update if not changing placeholder. Could just be focus/unfocus on field.
			if (titleIsPlaceholder) return;

			// !!! Don't update slug, let it keep auto generated slug.
			// const isAutoSlug = isSlugAutoGenerated(currentSlug)
			// const untitledRe = /untitled|utan\stitel/ig
			// const titleIsUntitled = untitledRe.test(inputValue)
			// if (isAutoSlug && !titleIsUntitled) {
			// 	body.updateSlug = true
			// }
		}

		// Don't save if no change.
		if (inputValue !== prevValue) update(body, false);
	};

	render() {
		const { value } = this.state;
		const { markAsEditable } = this.props;

		return (
			<input
				type="text"
				value={value}
				onChange={(evt) => this.setValue(evt.target.value)}
				onBlur={(evt) => this._update(evt)}
				className={"input-field" + (markAsEditable ? "is-highlighted" : "")}
			/>
		);
	}
}

class OrderInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			index: -1,
		};
	}

	componentWillMount() {
		// Always run after updating.
		this.setInitialState(this.props);
	}

	componentWillReceiveProps(nextProps) {
		// To update when changing sort order in table. Nextprops belongs to another item (another id).
		this.setInitialState(nextProps);
	}

	setInitialState = (props) => {
		// When receivning props, update only when changing sort order in table.
		// if (props.id === this.state.id) return

		this.setState({
			id: props.id,
			index: props.initialValue,
		});
	};

	updateOrder = () => {
		const { id, update, artistId, initialValue } = this.props;
		// Not strict equality, initialValue is a number, index from state is a string.
		if (this.state.index != initialValue) {
			// If setting index to 0, it will be updated to -1, = "removed" index.
			const newIndex = this.state.index > 0 ? this.state.index : -1;

			update(id, newIndex, artistId);
		}
	};

	changeIndex = (e) => {
		const { id, update, artistId, initialValue, highestIndex } = this.props;
		let inputIndex = e.target.value;
		// Reverse index, so that clicking on up arrow decreases the index and moves the item up in the list.
		const changeValue = initialValue - inputIndex;
		let newIndex = initialValue + changeValue;

		// Going from 1 to 0 changes index to -1, removeing it from order.
		// This code if we do not want that to happen.
		// if (initialValue === 1 && newIndex === 0) {
		// 	return
		// }

		// If clicking arrows on an item without index. Give it an index one higher than the current highest index.
		if (newIndex < 0 && typeof highestIndex === "number") {
			newIndex = highestIndex + 1;
		}

		this.setState({ index: newIndex });
		if (newIndex != initialValue) {
			// If setting index to 0, it will be updated to -1, = "removed" index.
			const updateIndex = newIndex > 0 ? newIndex : -1;

			update(id, newIndex, artistId);
		}
	};

	typedInput = (e) => {
		// Prevent typing number in input field since it is confusing when items change places immediately.
		// Only arrows works for changing order.
		let inputIndex = e.target.value;
		e.preventDefault();
	};

	render() {
		const { index } = this.state;
		const { t } = this.props;
		const hasIndex = index >= 0;
		return (
			<div className="list-and-upload__order__container">
				{/* { !hasIndex &&
					<div style={{
						paddingLeft: '20px',
					}}>{t('list.orderIndexMissing')}</div>
				} */}
				<input
					type="number"
					value={hasIndex ? index : ""}
					min={0}
					onChange={this.changeIndex}
					className={
						"list-and-upload__order__input" +
						(true || hasIndex ? "" : " is-indexed")
					}
					onKeyDown={this.typedInput}
				/>
			</div>
		);
	}
}

class ArtworksListAndUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			galleryOptions: [],
			viewedArtwork: false,
			uploadCount: 0,
			successCount: 0,
			failCount: 0,
			artworkStatusOpen: "", // The id of an artwork, or empty.
			selectedFilterName: ALL_FILTER,
			selectedFilterId: "",
			showEditableArtworks: true,
			showIntallations: false,
			newestArt: [],
		};
		this.currentSortColumn = "";
		this.currentSortDirection = "";
	}

	componentWillMount() {
		const { allArtists, allGalleries, isAdmin, show, raffle } = this.props;
		// If on show or raffle, artworks are fetched from those.
		const onArtworksPage = !show && !raffle;

		if (this.props.galleryId && onArtworksPage) {
			this.props.dispatch(fetchArtworksForGallery(this.props.galleryId));
		} else if (this.props.artistId && onArtworksPage) {
			this.props.dispatch(fetchArtworksForArtist(this.props.artistId));
		} else {
			// When stepping back as admin.
			this.props.dispatch(clearArtworks());
		}

		// Fetched by input in ArtistListSelector now.
		// Don't fetch if already in state.
		// if (Object.keys(allArtists).length === 0) this.props.dispatch( fetchAllArtistsFast() )
		// if (Object.keys(allGalleries).length === 0) this.props.dispatch( fetchAllGalleriesFast() )
	}

	componentWillReceiveProps(nextProps) {
		const { galleryId, artistId, allArtists, allGalleries, show, raffle, t } =
			nextProps;
		// If on show or raffle, artworks are fetched from those.
		const onArtworksPage = !show && !raffle;

		if (
			nextProps.galleryId &&
			onArtworksPage &&
			(!this.props.galleryId ||
				this.props.galleryId !== galleryId ||
				nextProps.deletedArtwork)
		) {
			this.props.dispatch(fetchArtworksForGallery(galleryId));
		} else if (
			artistId &&
			onArtworksPage &&
			(!this.props.artistId ||
				this.props.artistId !== artistId ||
				nextProps.deletedArtwork)
		) {
			this.props.dispatch(fetchArtworksForArtist(artistId));
		} else if (
			!nextProps.galleryId &&
			!nextProps.artistId &&
			Object.keys(nextProps.artworks).length > 0
		) {
			// When stepping back to this page, no gallery or artist selected, clear artworks.
			// this.props.dispatch( clearArtworks() )
		}

		let updateArtistOptions = false;
		let updateGalleryOptions = false;

		const artistKeys = Object.keys(allArtists);
		let artistOptions = this.state.artistOptions;
		// Don't create artistOptions if already in state.
		// Minus one to account for Create new artist - option.
		const optionsLength =
			artistOptions.length > 0
				? artistOptions.length - 1
				: artistOptions.length;
		if (optionsLength !== artistKeys.length) {
			artistOptions = [
				{
					value: "createNewArtist",
					label: (
						<div style={{ position: "relative" }}>
							<PLUS_WHITE className="dropdown-container__add-artist" />
							{t("list.addNewArtist")}
						</div>
					),
				},
			];
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				artistOptions.push({
					value: artist._id,
					label: artist.name,
				});
			});

			updateArtistOptions = true;
			// this.setState({
			// 	artistOptions,
			// })
		}

		const galleryKeys = Object.keys(allGalleries);
		let galleryOptions = this.state.galleryOptions;
		// Don't create galleryOptions if already in state.
		if (galleryOptions.length !== galleryKeys.length) {
			galleryOptions = [];
			galleryKeys.forEach((key) => {
				const gallery = allGalleries[key];
				galleryOptions.push({
					value: gallery._id,
					label: gallery.name,
				});
			});

			updateGalleryOptions = true;
			// this.setState({
			// 	galleryOptions,
			// })
		}

		if (updateArtistOptions || updateGalleryOptions) {
			this.setState({
				artistOptions,
				galleryOptions,
			});
		}

		if (!this.props.wasSorted && nextProps.wasSorted) {
			setTimeout(() => {
				this.props.dispatch(resetIndexAnimation());
			}, 1000);
		}
	}

	// Create a new artwork from an image, then call this function again for the next image.
	nextCall = (data, params, index, previousArtId = false) => {
		const { raffle = false, show = false } = this.props;
		const newArt = this.state.newestArt;
		const forRaffle = raffle;
		const forShow = show;
		const item = raffle || show;
		let itemArtIds = item
			? item.artworks.map((artwork) => artwork._id || artwork)
			: false;

		// Uploads as installation if that tab is selected
		if (this.state.showIntallations) {
			params.installation = true;
		}

		createArtworkFromImage(data[index], params, this.props.dispatch)
			.then((success) => {
				let func = Promise.resolve(true);
				if (itemArtIds) {
					if (itemArtIds.indexOf(itemArtIds) < 0) {
						itemArtIds.push(success._id);
						newArt.push(success._id);
					}
					const itemBody = {
						_id: item._id,
						artworks: itemArtIds,
					};
					let saveFunction = () => {};
					if (forRaffle) saveFunction = saveRaffle;
					if (forShow) saveFunction = saveShow;
					func = this.props.dispatch(saveFunction(itemBody, false));
				}
				func.then((saved) => {
					this.setState(
						{
							successCount: success
								? this.state.successCount + 1
								: this.state.successCount,
							failCount: !success
								? this.state.failCount + 1
								: this.state.failCount,
							newestArt: newArt,
						},
						() => {
							if (data[index + 1]) {
								this.nextCall(data, params, ++index);
							} else {
								this.sortOnLatest();
							}
						}
					);
				});
			})
			.catch((err) => {
				console.log("error", err);
				this.setState(
					{
						failCount: this.state.failCount + 1,
					},
					() => {
						if (data[index + 1]) {
							this.nextCall(data, params, ++index);
						} else {
							this.sortOnLatest();
						}
					}
				);
			});
	};

	dropImage = (acceptedFiles, e) => {
		// selectedFilterId = gallery has buttons to select which artists artworks to display. False for others.
		const { selectedFilterId, selectedFilterName } = this.state;
		const { t } = this.props;

		const forRaffle = !!this.props.raffle;
		// const forShow = !!this.props.show
		const selectedArtistId =
			selectedFilterId && selectedFilterId !== "ALL" ? selectedFilterId : false;

		// Reset newestArt
		this.setState({
			newestArt: [],
		});

		let totalSize = 0;
		acceptedFiles.forEach((file) => {
			totalSize += file.size;
		});
		// If too large, saving will fail.
		if (totalSize > MAX_SIZE * acceptedFiles.length) {
			window.alert(t("components:imageUpload.imagesTooBig"));
			return;
		}

		let promises = acceptedFiles.map((file) => this.loadImage(file));

		const params = { forRaffle };
		if (this.props.galleryId) {
			// If uploading for gallery.
			params.gallery = this.props.galleryId;
			params.createdFor = "gallery";
			if (selectedArtistId) {
				params.artistMod = selectedArtistId;
			}
			if (this.props.selectedFilterName) {
				params.artist = {
					name: this.props.selectedFilterName,
				};
			}
		} else if (this.props.artistId) {
			// If uploading for artist.
			params.artistMod = this.props.artistId;
			params.artist = {
				name: this.props.artistName,
			};
			params.createdFor = "artist";
		}
		// If user is society, there is no published status selector in the list. Set to published from start for them.
		// params.publish_status = this.props.isSociety ? 'published' : 'draft'
		// Society also has status in dropdown now.
		params.publish_status = "draft";

		Promise.all(promises)
			.then((data) => {
				// this.props.dispatch( createArtworkFromImages(data, params) )
				// if (data.length > 0) this.nextCall(data, params, 0)
				if (data.length < 1) return;
				this.setState(
					{
						uploadCount: data.length,
						successCount: 0,
						failCount: 0,
					},
					() => {
						this.nextCall(data, params, 0);
					}
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	loadImage = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(uploadedImage);
		});
	};

	// On multi upload, if one file fails, all files fail.
	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;

		rejectedFiles.forEach((rejected) => {
			this.showImageError(rejected, t);
		});
	};

	showImageError = (rejectedFile, t) => {
		if (rejectedFile.size > MAX_SIZE) {
			window.alert(
				`${t("components:imageUpload.imageTooBigMulti1")} ${
					rejectedFile.name
				} ${t("components:imageUpload.imageTooBigMulti2")}`
			);
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("list.imageWrongFormat"));
		}
	};

	updateArtwork = (body, showMessage = true, callback = null) => {
		// console.log('UPDATE BODY:', body);
		this.props.dispatch(saveArtwork(body, showMessage)).then((response) => {
			if (callback) callback();
		});
	};

	updateOrder = (artworkId, newIndex, artistId) => {
		const { raffle, show } = this.props;
		const item = raffle || show || false;
		const itemArtIds = item
			? item.artworks.map((artwork) => artwork._id)
			: false;

		this.props.dispatch(
			reorderArtwork(artworkId, newIndex, artistId, itemArtIds)
		);
	};

	// When manually sorting / clicking table header.
	sortChanged = (e) => {
		this.currentSortColumn = e.column;
		this.currentSortDirection = e.direction; // -1 = latest first, 1 = oldest first
	};

	// After uplading new artwork, sort on latest updated, or new artworks will be at the bottom of the list.
	sortOnLatest = () => {
		const element = document.getElementById("created");

		if (this.currentSortColumn !== "created") {
			// First click on creted puts oldest first. Need to click twice.
			if (element) {
				element.click();
				element.click();
			}
		} else if (
			this.currentSortColumn === "created" &&
			this.currentSortDirection !== -1
		) {
			if (element) {
				element.click();
			}
		}
	};

	removeArtwork = (artwork, t) => {
		let confirm = window.confirm(t("list.areYouSure"));
		if (confirm) {
			this.props.dispatch(deleteArtwork(artwork));
		}
	};

	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	handleAccessSubmit = (access) => {
		const artworkId = this.props.artworkForm.artwork._id;
		this.props.dispatch(submitAccess(artworkId, access, "artworks"));
	};
	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};
	toggleAccessModal = () => {
		this.props.dispatch(hideAccessModal());
	};

	setVisibleList = (value, type) => {
		const { artist = {}, isArtist } = this.props;
		if (type === "installation") {
			if (isPremium(artist)) {
				this.setState({ showEditableArtworks: false, showIntallations: value });
			} else {
				this.showProModal();
			}
		} else {
			this.setState({ showEditableArtworks: value, showIntallations: false });
			if (!value && artist._id && isArtist) {
				this.props.dispatch(markNotifications(artist._id, "artwork"));
			}
		}
	};

	showProModal = () => {
		const content = (
			<div>
				<p>
					Installationsbilder är en <i>PRO</i> funktion.
				</p>
				<p>
					Läs mer och testa gratis{" "}
					<a href="https://artworks.se/pro" target="_blank">
						här
					</a>
					! 🎨
				</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	viewArtwork = (sources) => {
		this.setState({
			viewedArtwork: sources,
		});
	};

	toggleStatusSelect = (artworkId) => {
		this.setState({ artworkStatusOpen: artworkId });
	};

	// If artwork is used in raffle/show.
	toggleForItem = (item, artwork, checked, forRaffle, forShow) => {
		const artworkId = artwork._id;
		let itemArtIds = item.artworks.map((artwork) => artwork._id || artwork);

		if (checked) {
			// It was checked, so removed it.
			const index = itemArtIds.indexOf(artworkId);
			if (index >= 0) itemArtIds.splice(index, 1);
		} else {
			// It was unchecked, so add it. Check if already added just in case.
			if (itemArtIds.indexOf(itemArtIds) < 0) itemArtIds.push(artworkId);
		}
		const itemBody = {
			_id: item._id,
			artworks: itemArtIds,
		};
		const artworkBody = {
			_id: artworkId,
		};
		if (checked) {
			artworkBody.index = -1;
		}
		// Always set forRaffle to true for raffle, might be used in another raffle (possible technically).
		if (forRaffle) artworkBody.forRaffle = true;
		if (forShow) {
			let showIds = artwork.shows.map((show) => show._id || show);

			if (checked) {
				// It was checked, so removed it.
				const index = showIds.indexOf(item._id);
				if (index >= 0) showIds.splice(index, 1);
			} else {
				// It was unchecked, so add it. Check if already added just in case.
				if (showIds.indexOf(item._id) < 0) showIds.push(item._id);
			}

			artworkBody.shows = showIds;
		}

		let saveFunction = () => {};
		if (forRaffle) saveFunction = saveRaffle;
		if (forShow) saveFunction = saveShow;

		this.props.dispatch(saveFunction(itemBody, false)).then((updatedRaffle) => {
			// If unchecking artwork (only for show or raffle), set its index to -1 and update order afterwards.
			const callback = checked
				? this.updateOrder(artworkBody._id, artworkBody.index, false)
				: () => {};
			this.updateArtwork(artworkBody, false, callback);
		});
	};

	// Artworks created by gallery should not be editable by the artist, and vice versa. (Changed now for artists.)
	splitArtworks = (artworks) => {
		// galleryId and artistId are from user if user is artist/author/society, or from select dropdown if user is admin.
		const { galleryId, artistId } = this.props;
		const createdByArtist = [];
		const createdByGallery = [];
		const other = []; // Created by admin.

		Object.keys(artworks).forEach((key) => {
			const artwork = artworks[key];
			const createdByRole =
				artwork.created_by && artwork.created_by.role
					? artwork.created_by.role
					: false;
			// Galleries/art societies can have mulitple users.
			// Should only be able to edit art uploaded by other author/society users.
			if (galleryId) {
				if (createdByRole === "artist") {
					createdByArtist.push(artwork);
				} else if (createdByRole === "author" || createdByRole === "society") {
					createdByGallery.push(artwork);
				} else if (artwork.createdFor && artwork.createdFor === "artist") {
					createdByArtist.push(artwork);
				} else if (artwork.createdFor && artwork.createdFor === "gallery") {
					createdByGallery.push(artwork);
				} else {
					other.push(artwork);
				}
			}
			// Artists should only be able to edit art uploaded by themselves
			// artistUser value added in the API to the artwork when fetching, it is the User connected to the Artist.
			// artistUser is an array, in case admin set themselves to the artist, or all artworks could be uneditable for the artist.
			if (artistId) {
				let belongsToArtist = false;
				// Newly uploaded have no artistUser set.
				if (!artwork.artistUser) belongsToArtist = true;
				// If the User who created the artwork has been removed.
				if (!artwork.created_by) belongsToArtist = true;
				// If the artwork was created by the artist herself.
				if (
					artwork.created_by &&
					artwork.artistUser &&
					artwork.artistUser.indexOf(artwork.created_by._id) >= 0
				)
					belongsToArtist = true;

				if (belongsToArtist) {
					createdByArtist.push(artwork);
				} else {
					other.push(artwork);
				}
			}
		});

		let editableList = [];
		let uneditableList = [];
		if (galleryId) {
			editableList = createdByGallery.concat(other);
			uneditableList = createdByArtist;
		} else if (artistId) {
			editableList = createdByArtist;
			uneditableList = other;
			// editableList = createdByArtist.concat(other)
			// uneditableList = createdByGallery
		}

		return {
			editableList,
			uneditableList,
		};
	};

	splitEditable = (artworks) => {
		let normalArt = [];
		let installations = [];
		artworks.forEach((art, i) => {
			if (art.installation) {
				installations.push(art);
			} else {
				normalArt.push(art);
			}
		});
		return { normalArt, installations };
	};

	setFilter = (artistName, artistId) => {
		this.setState({
			selectedFilterName: artistName,
			selectedFilterId: artistId,
		});
	};

	// Filters, for gallery to show works from a single artist.
	createFilterButton = (name, id) => {
		const { selectedFilterName } = this.state;
		return (
			<button
				className={
					"list-and-upload__artist-filters__filter" +
					(selectedFilterName === name ? " is-selected" : "")
				}
				onClick={() => this.setFilter(name, id)}
				key={name}
			>
				{name !== ALL_FILTER
					? name
					: i18next.language === "sv"
					? "Alla"
					: "All"}
			</button>
		);
	};

	createSortButtons = (artworks, show) => {
		const featuredArtists = show
			? show.artists.map((artist) => artist._id)
			: [];
		let sortButtons = [];
		if (Object.keys(artworks).length > 0) {
			sortButtons = [this.createFilterButton(ALL_FILTER)];
			// A list of added artist names.
			let artistNames = [];
			const artistNameIdPairs = {};
			Object.keys(artworks).forEach((key, index) => {
				const artwork = this.props.artworks[key];
				const artistData =
					artwork.artistMod && artwork.artistMod.name
						? { name: artwork.artistMod.name, id: artwork.artistMod._id }
						: false;

				// For shows, only include artists that are part of the show.
				const excludeArtist = show
					? featuredArtists.indexOf(artistData.id) < 0
					: false;

				// Add data to array, but not if artist already added, or artist not part of the show (if show).
				if (
					artistData &&
					artistNames.indexOf(artistData.name) < 0 &&
					!excludeArtist
				) {
					artistNames.push(artistData.name);
					artistNameIdPairs[artistData.name] = artistData.id;
				}
			});

			artistNames.sort().forEach((name) => {
				sortButtons.push(
					this.createFilterButton(name, artistNameIdPairs[name])
				);
			});
		}

		return sortButtons;
	};

	createRows = (
		artworks,
		item,
		isFetching,
		forRaffle,
		forShow,
		highestIndex,
		t
	) => {
		const {
			artistOptions,
			galleryOptions,
			artworkStatusOpen,
			selectedFilterName,
			newestArt,
		} = this.state;
		const { artistId, isArtist, isAdmin } = this.props;
		// const { selectedFilterName } = this.props
		const isSwedish = i18next.language === "sv";

		const rows = [];
		const iconStyle = Object.assign(
			{},
			{
				height: "25px",
				width: "auto",
				cursor: "pointer",
			},
			isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
		);

		// const pencilStyle = Object.assign({}, {
		// 	height: '25px',
		// 	width: 'auto',
		// 	opacity: .5,
		// 	marginLeft: '5px'
		// })

		// item is show or raffle
		const itemArtIds = item ? item.artworks.map((artwork) => artwork._id) : [];

		Object.keys(artworks).forEach((key) => {
			const artwork = artworks[key];
			const image = getFirstImage(artwork);

			const smallThumbnail = image.thumbnails
				? image.thumbnails["100x100"].src
				: image.src;
			const largeThumbnail = image.thumbnails
				? image.thumbnails["750x"].src
				: image.src;

			const artistName = artwork.artistMod ? artwork.artistMod.name : "";
			const galleryName = artwork.gallery ? artwork.gallery.name : "";
			const artworkArtistId = artwork.artistMod ? artwork.artistMod._id : "";
			const artworkGalleryId = artwork.gallery
				? artwork.gallery._id || artwork.gallery
				: "";

			const isChecked = item ? itemArtIds.indexOf(artwork._id) >= 0 : false;
			const hideOrder = item && !isChecked;
			const isNewest = newestArt.indexOf(artwork._id) >= 0;
			const hasAutoTitle = isSlugAutoGenerated(artwork.title);
			let placeholderTitle = isSwedish
				? PLACEHOLDER_TITLE.swedish
				: PLACEHOLDER_TITLE.english;
			// placeholderTitle = placeholderTitle + '...'

			let publicPurchaserValue = 0;
			if (
				artwork.forPublicPurchaserRequested &&
				!artwork.forPublicPurchaser &&
				!artwork.forPublicPurchaserOnly
			) {
				publicPurchaserValue = 1;
			} else if (
				artwork.forPublicPurchaserRequested &&
				(artwork.forPublicPurchaser || artwork.forPublicPurchaserOnly)
			) {
				publicPurchaserValue = 2;
			}

			/*const approvedToolTip = {
				text: isSwedish
					? 'Verket är godkänt för offentliga inköpare'
					: 'Artwork is approved for public purchasers',
				popupPos: 'right',
			}
			const appliedToolTip = {
				text: isSwedish
					? 'Du har ansökt om att verket ska synas för offentliga inköpare'
					: 'You have applied for this artwork to be visible for public purchasers',
				popupPos: 'right',
			}*/

			if (
				!selectedFilterName ||
				selectedFilterName === "All" ||
				selectedFilterName === artistName
			) {
				rows.push(
					<Tr
						key={artwork._id}
						className={
							"list-and-upload__reactable__tr" +
							(isNewest ? " is-newest" : "") +
							(artwork.animated ? " is-animated" : "")
						}
					>
						<Td
							column="publishStatus"
							value={artwork.publish_status}
							style={
								artwork.publish_status === "published"
									? { cursor: "pointer", color: "#059b05" }
									: { cursor: "pointer", color: "#a4a4a4" }
							}
						>
							<div
								onClick={() => this.props.dispatch(showArtworkModal(artwork))}
							>
								{/*<TinySelect
									artworkId={artwork._id}
									field="publish_status"
									options={[
										{ value: 'published', label: t('common:status.published') },
										{ value: 'draft', label: t('common:status.draft') },
									]}
									selectedOption={artwork.publish_status}
									doOpen={() => this.toggleStatusSelect(artwork._id)}
									isOpen={artworkStatusOpen === artwork._id}
									doClose={() => this.toggleStatusSelect('')}
									update={this.updateArtwork}
									t={t}
								/>*/}
								{/* Clicking switch button only opens form as popup. */}
								{/* <SwitchButton
									isOn={artwork.publish_status === 'published'}
									switched={() => this.props.dispatch(showArtworkModal(artwork))}
									disabled={false}
									markerOnly={true}
								/> */}
								{t(`single.${artwork.publish_status}`)}
								{/* Tooltip for approved for public purchaser. */}
								{/*(isAdmin || isArtist) && artwork.forPublicPurchaser &&
									<div style={{
										position: 'relative',
										marginTop: '10px',
									}}>
										{isSwedish ? 'Godkänt' : 'Approved'}
										<Tooltip
											data={approvedToolTip}
											style={{ left: 'calc(100% - 20px)', right: 'auto', top: '2px' }}
										/>
									</div>
								*/}
								{/* Tooltip for applied for public purchaser. */}
								{/*(isAdmin || isArtist) && !artwork.forPublicPurchaser && artwork.forPublicPurchaserRequested &&
									<div style={{
										position: 'relative',
										marginTop: '10px',
									}}>
										{isSwedish ? 'Ansökt' : 'Applied'}
										<Tooltip
											data={appliedToolTip}
											style={{ left: 'calc(100% - 20px)', right: 'auto', top: '2px' }}
										/>
									</div>
								*/}
							</div>
						</Td>
						{/* Clicking switch button only opens form as popup. */}
						<Td column="forPublicPurchaser" value={publicPurchaserValue}>
							<SwitchButton
								isOn={
									artwork.forPublicPurchaserRequested ||
									artwork.forPublicPurchaserOnly
								}
								switched={() => this.props.dispatch(showArtworkModal(artwork))}
								disabled={false}
								isWaiting={
									artwork.forPublicPurchaserRequested &&
									!artwork.forPublicPurchaser &&
									!artwork.forPublicPurchaserOnly
								}
								markerOnly={false}
							/>
						</Td>
						{item && (
							<Td column="forItem">
								<input
									type="checkbox"
									className={"list-and-upload__raffle-checkbox"}
									onChange={() =>
										this.toggleForItem(
											item,
											artwork,
											isChecked,
											forRaffle,
											forShow
										)
									}
									checked={isChecked}
									disabled={false}
								/>
							</Td>
						)}
						{/* onClick={() => this.viewArtwork({ fullSrc: image.src, thumbnail: largeThumbnail })} */}{" "}
						{/* Showing large image in popup. */}
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{smallThumbnail && (
								<img
									src={smallThumbnail}
									style={{ width: "60px", cursor: "pointer" }}
									onError={(e) => thumbnailError(e, e.target.src, image.src)}
									alt="image of artwork"
								/>
							)}
							{/*
								Using div instead of image, so we can animate on background-image when changing sort order.
								But then we have no onError function to replace a failed thumbnail.
							*/}
							{/* {smallThumbnail &&
								<div
									style={{
										backgroundImage: `url(${smallThumbnail})`,
										width: '60px',
										height: '60px',
										cursor: 'pointer',
										backgroundSize: 'contain',
    									backgroundPosition: 'center',
										transition: artwork.animated ? 'background-image 1s linear' : 'none',
									}}
									onClick={() => this.props.dispatch(showArtworkModal(artwork))}
								/>
							} */}
						</Td>
						<Td column="title" value={artwork.title}>
							<div style={{ display: "flex", flexFlow: "row" }}>
								<div
									onClick={() => this.props.dispatch(showArtworkModal(artwork))}
									style={{
										cursor: "pointer",
										minWidth: "70px", // Width and height to make empty field clickable.
										minHeight: "19px",
									}}
								>
									{!hasAutoTitle ? artwork.title : ""}
									{/* {!hasAutoTitle ? artwork.title : <u>{placeholderTitle}</u>} */}
								</div>
								{/* <InputField
									value={hasAutoTitle ? placeholderTitle : artwork.title}
									keyForValue={'title'}
									update={this.updateArtwork}
									artworkId={artwork._id}
									currentSlug={artwork.slug}
									prevValue={artwork.title}
									markAsEditable={hasAutoTitle}
								/> */}
								{/*<PENCIL style={pencilStyle} />*/}
							</div>
						</Td>
						<Td column="artist" value={artistName}>
							<ArtworksListSelector
								isForType="artist"
								options={artistOptions}
								selectedId={artworkArtistId}
								artworkId={artwork._id}
								isFetching={isFetching}
								update={this.updateArtwork}
								openArtistModal={() => this.props.dispatch(showArtistModal())}
								placeholder={t("common:navbar.artists")}
								loadingPlaceholder={t("list.loadingArtists")}
								savedOption={
									!artwork.artistMod
										? false
										: {
												value: artwork.artistMod._id,
												label: artwork.artistMod.name,
										  }
								}
								t={t}
							/>
						</Td>
						<Td column="gallery" value={galleryName}>
							<ArtworksListSelector
								isForType="gallery"
								options={galleryOptions}
								selectedId={artworkGalleryId}
								artworkId={artwork._id}
								isFetching={isFetching}
								update={this.updateArtwork}
								placeholder={t("list.hasExhibitor")}
								loadingPlaceholder={t("list.loadingGalleries")}
								savedOption={
									!artwork.gallery
										? false
										: {
												value: artwork.gallery._id,
												label: artwork.gallery.name,
										  }
								}
								t={t}
							/>
						</Td>
						<Td column="addInfo" value={"addInfo"}>
							<div
								style={{ cursor: "pointer", textDecoration: "underline" }}
								onClick={() => this.props.dispatch(showArtworkModal(artwork))}
							>
								{t("list.addMoreInfo")}
							</div>
						</Td>
						{hideOrder ? (
							<Td column="order" value={10000000000}>
								{""}
							</Td>
						) : (
							<Td
								column="order"
								value={artwork.index > 0 ? artwork.index : 10000000000}
							>
								<div className={"list-and-upload__order"}>
									<OrderInput
										initialValue={artwork.index || -1}
										keyForValue={"index"}
										id={artwork._id}
										artistId={artistId}
										update={this.updateOrder}
										highestIndex={highestIndex}
										t={t}
									/>
								</div>
							</Td>
						)}{" "}
						{/* Can't put ternary operator inside Td, it will display "[object Object]" or "false". */}
						<Td column="created" value={artwork.created_at}>
							<div
								onClick={() => this.props.dispatch(showArtworkModal(artwork))}
								style={{ cursor: "pointer" }}
							>
								{DateUtil.dateMonthShort(
									artwork.created_at,
									i18next.language,
									true
								)}
							</div>
						</Td>
						<Td column="delete" value={"delete"}>
							<BIN_SVG
								style={iconStyle}
								onClick={() => this.removeArtwork(artwork, t)}
								data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
							/>
						</Td>
					</Tr>
				);
			}
		});

		return rows;
	};

	render() {
		const {
			isAdmin,
			isArtist = false,
			isSociety,
			galleryId,
			artistId,
			artworks,
			isFetching,
			showDropZone = true,
			showArtworkModal,
			showArtistModal,
			showAccessModal,
			raffle = false,
			show = false,
			includeTagged = false,
			user,
			artist = {},
			artistFormAlreadyOnPage = false,
			error,
			t,
		} = this.props;
		// artistFormAlreadyOnPage ex. EditShow.jsx already includes an <ArtistForm...

		// galleryId/artistId is either from the user, or from gallery/artist selected by admin in dropdown.
		const {
			viewedArtwork,
			uploadCount,
			successCount,
			failCount,
			showEditableArtworks,
			selectedFilterName,
			showIntallations,
		} = this.state;
		const item = raffle || show;
		let onUploadCount = successCount + failCount + 1;
		// Just in case:
		if (onUploadCount > uploadCount) onUploadCount = uploadCount;

		let tableHead = <Thead></Thead>;
		tableHead = (
			<Thead>
				<Th column="publishStatus" style={{ width: "120px" }}>
					{t("list.status")}
				</Th>
				{artistId && (
					<Th column="forPublicPurchaser" style={{ width: "150px" }}>
						{t("list.showForPublicPurchaser")}
					</Th>
				)}
				{item && <Th column="forItem">{` `}</Th>}
				<Th column="image">{` `}</Th>
				<Th column="title">{` `}</Th>
				{galleryId && <Th column="artist">{t("list.artist")}</Th>}
				{/* artistId &&
					<Th column="gallery">{t('list.exhibitor')}</Th>
				*/}
				<Th column="addInfo">{` `}</Th>
				{(isAdmin || isArtist || show) && (
					<Th column="order">{t("list.order")}</Th>
				)}
				<Th column="created" id="created">
					{t("list.createdAt")}
				</Th>
				<Th column="delete">{` `}</Th>
			</Thead>
		);

		const splitArt = this.splitArtworks(artworks);
		const splitEditable = this.splitEditable(splitArt.editableList);
		// Get the highest index set on an artwork in the array. Klicking sort arrow sets item index to highestIndex + 1
		let highestIndex = 0;
		let rows = [];
		if (showEditableArtworks) {
			splitEditable.normalArt.forEach((artwork) => {
				if (artwork.index && artwork.index > highestIndex)
					highestIndex = artwork.index;
			});
			rows = this.createRows(
				splitEditable.normalArt,
				item,
				isFetching,
				!!raffle,
				!!show,
				highestIndex,
				t
			);
		} else if (showIntallations) {
			splitEditable.installations.forEach((artwork) => {
				if (artwork.index && artwork.index > highestIndex)
					highestIndex = artwork.index;
			});
			rows = this.createRows(
				splitEditable.installations,
				item,
				isFetching,
				!!raffle,
				!!show,
				highestIndex,
				t
			);
		}
		/*
		Setting Table defaultSort like this won't work. Since artworks is empty at first, the first sort will be set.
		Then when defaultSortColumn changes, the Table won't update with the new order.
		const defaultSortColumn = highestIndex < 1 ?
		{ column: 'created', direction: 'desc' } :
		{ column: 'order', direction: 'asc' }
		*/

		const sortButtons = galleryId ? this.createSortButtons(artworks, show) : [];

		const sortSection =
			sortButtons.length > 1 ? (
				<div className="ist-and-upload__artist-filters">{sortButtons}</div>
			) : null;

		let myArtworkCount = 0;
		let installationCount = 0;
		let taggedArtworkCount = 0;
		if (!selectedFilterName || selectedFilterName === "All") {
			myArtworkCount = splitEditable.normalArt.length;
			installationCount = splitEditable.installations.length;
			taggedArtworkCount = splitArt.uneditableList.length;
		} else {
			splitEditable.normalArt.forEach((artwork) => {
				const artistName = artwork.artistMod ? artwork.artistMod.name : "";
				if (selectedFilterName === artistName) {
					myArtworkCount += 1;
				}
			});
			splitEditable.installations.forEach((artwork) => {
				const artistName = artwork.artistMod ? artwork.artistMod.name : "";
				if (selectedFilterName === artistName) {
					installationCount += 1;
				}
			});
			splitArt.uneditableList.forEach((artwork) => {
				const artistName = artwork.artistMod ? artwork.artistMod.name : "";
				if (selectedFilterName === artistName) {
					taggedArtworkCount += 1;
				}
			});
		}

		return (
			<div className="list-and-upload">
				<div className="list-and-upload__top-container">
					{showDropZone && (
						<Dropzone
							onDrop={this.dropImage}
							onDropRejected={this.dropRejected}
							className={`ImageUpload__dropzone ${isArtist ? "is-artist" : ""}`}
							multiple={true}
							maxSize={MAX_SIZE}
						>
							<div>{isSociety ? t("society") : t("list.dropHere")}</div>
							{error ? <span>{error}</span> : null}
						</Dropzone>
					)}
				</div>
				<div className="list-and-upload__progress-bar">
					{uploadCount > 0 && successCount + failCount < uploadCount && (
						<div>
							{`${t("list.uploading")} `}
							{`${onUploadCount}/${uploadCount}`}
							{failCount > 0 && `, ${failCount} ${t("list.failedUploads")}`}
						</div>
					)}
				</div>
				{sortSection}
				{includeTagged && (
					<div className="list-and-upload__list-select">
						<div
							className={
								"list-and-upload__list-select__button" +
								(showEditableArtworks ? " is-selected" : "")
							}
							onClick={() => this.setVisibleList(true, "normal")}
						>{`${myArtworkCount} ${t("list.uploadedArtworks")}`}</div>
						{isArtist && (
							<div
								className={
									"list-and-upload__list-select__button" +
									(showIntallations ? " is-selected" : "")
								}
								onClick={() => this.setVisibleList(true, "installation")}
							>{`${installationCount} ${t("list.uploadedInstallations")}`}</div>
						)}
						<div
							className={
								"list-and-upload__list-select__button" +
								(!showEditableArtworks && !showIntallations
									? " is-selected"
									: "")
							}
							onClick={() => this.setVisibleList(false, "none")}
						>
							{galleryId && !artistId
								? `${t("list.weTaggedIn")} (${taggedArtworkCount})`
								: `${t("list.meTaggedIn")} (${taggedArtworkCount})`}
						</div>
					</div>
				)}
				{/*
				If we want to sort on more values:
				sortable={[
					'title',
					'artist',
					'gallery',
					'created',
					{column: 'publishStatus', sortFunction: function(a, b){
						// Initial sort put "published" above "draft".
						return a < b ? 1 : -1;
					}},
					{column: 'forPublicPurchaser', sortFunction: function(a, b){
						// Sort on publicPurchaserValue which is 0/1/2.
						return a < b ? 1 : -1;
					}},
					'order',
				]}
				*/}
				{(showEditableArtworks || showIntallations) && (
					<Table
						className="reactable"
						sortable={["created", "order"]}
						defaultSort={{ column: "order", direction: "asc" }}
						onSort={this.sortChanged}
						filterable={false}
						filterPlaceholder={"Search artwork"}
						itemsPerPage={20}
						pageButtonLimit={0}
					>
						{tableHead}
						{rows}
					</Table>
				)}
				{!showEditableArtworks && !showIntallations && (
					<MentionedArtworksList
						artworks={splitArt.uneditableList}
						galleryId={galleryId}
						artistId={artistId}
						viewArtwork={this.viewArtwork}
						selectedFilterName={this.state.selectedFilterName}
						show={show}
						toggleForItem={this.toggleForItem}
						isAdmin={isAdmin}
						artist={artist}
						OrderInput={OrderInput}
						updateOrder={this.updateOrder}
						t={t}
					/>
				)}
				{showArtworkModal && (
					<Modal
						onCloseClick={this.toggleArtworksModal}
						className="modal-with-sticky-footer"
					>
						<ArtworkForm
							onSubmit={this.handleArtworkSubmit}
							isAdmin={isAdmin}
							isArtist={!!artistId}
							infoUpdate={true}
							artist={artist}
							header={t("list.addMoreInfo")}
						/>
					</Modal>
				)}
				{showAccessModal ? (
					<Modal onCloseClick={this.toggleAccessModal}>
						<AccessForm onSubmit={this.handleAccessSubmit} isAdmin={isAdmin} />
					</Modal>
				) : null}
				{showArtistModal && !artistFormAlreadyOnPage && (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
							header={t("list.addNewArtist")}
						/>
					</Modal>
				)}
				{viewedArtwork && (
					<div
						className="list-and-upload__artwork-view"
						onClick={() => this.viewArtwork(false)}
					>
						<img
							src={CLOSE_WHITE}
							className="list-and-upload__artwork-view__close"
						/>
						<img
							src={viewedArtwork.thumbnail}
							className="list-and-upload__artwork-view__image"
							onError={(elem) => (elem.target.src = viewedArtwork.fullSrc)}
							alt="image of artwork"
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		artwork: { artworks, deletedArtwork, wasSorted, error },
		artist: { allArtists, isFetching: fetchingArtist },
		gallery: { allGalleries, isFetching: fetchingGallery },
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		accessForm: { showAccessModal },
	} = state;

	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};

	const isFetching = fetchingGallery || fetchingArtist;

	return {
		artworks,
		deletedArtwork,
		allArtists,
		allGalleries,
		showArtistModal,
		showArtworkModal,
		showAccessModal,
		artistForm,
		artworkForm,
		user,
		wasSorted,
		isFetching,
		error,
	};
};

const translated = withNamespaces("artworks")(ArtworksListAndUpload);
export default connect(mapStateToProps)(translated);
