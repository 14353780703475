import React, { Component } from "react";
// import PropTypes from "prop-types";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
	getFormInitialValues,
} from "redux-form";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import Select from "../../components/FormInput/Select";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextArea from "../../components/FormInput/TextArea";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import CheckBox from "../../components/FormInput/CheckBox";
import DateRangePicker from "../../components/FormComponents/DateRange/DateRangePicker";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import CategoryPicker from "../../components/FormComponents/CategoryPicker/CategoryPicker";
import PostPicker from "../../components/FormComponents/PostPicker/PostPicker";
import ShowPicker from "../../components/FormComponents/ShowPicker/ShowPicker";
import ShowPickerBig from "../../components/FormComponents/ShowPicker/ShowPickerBig";
import LocationPicker from "../../components/FormComponents/LocationPicker/LocationPicker";
import ArtworkPicker from "../../components/FormComponents/ArtworkPicker/ArtworkPicker";
import ArtworkPickerList from "../../components/FormComponents/ArtworkPicker/ArtworkPickerList";
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import ArtistPicker from "../../components/FormComponents/ArtistPicker/ArtistPicker";
import Rsvp from "../../components/FormComponents/Rsvp/Rsvp";
import RsvpStandalone from "../../components/Rsvp/Rsvp";
import Vote from "../../components/FormComponents/Vote/Vote";
import PublishStatus from "../../components/FormComponents/PublishStatus/PublishStatus";
import Tooltip from "../../components/Tooltip/Tooltip";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import { CENTER_IMAGE_OPTIONS, videoTooltip } from "../../utils/values";
import {
	isEmail,
	isValidImage,
	isRequiredText,
	isNumber,
	validateEmbed,
} from "../../utils/Validators";
import { imageRotationActivated } from "../../utils/Helpers";
import { showPremiumUpgradeModal } from "../../utils/Dispatcher";
import { noEmailCheckbox } from "../../utils/languageUtil";
import { showCustomModal } from "../../actions/AppActions";
import {
	isPremium,
	isStandardOrAbove,
	isPremiumOrAbove,
	hasAccessToFeature,
	RSVP_FEATURE,
	VOTE_FEATURE,
	AUTOMATIC_REMINDERS_FEATURE,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const VISIBILITY_OPTIONS = [
	{ value: "restricted", name: "Restricted" },
	{ value: "web", name: "Webb only" },
	{ value: "app", name: "App only" },
	{ value: "all", name: "Everywhere" },
	{ value: "notlisted", name: "Not listed" },
];

// const propTypes = {
// 	onSubmit: PropTypes.func.isRequired,
// 	error: PropTypes.string,
// };

const defaultProps = {
	event: {
		featuredImage: {},
		locationName: "",
		location: {
			type: "Point",
			coordinates: [59, 18],
		},
		artworks: [],
	},
};

class EventForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		// console.log('nextProps', nextProps);
	}

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	showProModal = () => {
		const { isArtist, isSociety } = this.props;

		if (__USE_TIERS__ && isSociety) {
			return showPremiumUpgradeModal(this.props.dispatch, "Premium");
		}

		let premium = "";
		let link = "";
		if (isArtist) {
			premium = "PRO";
			link = "https://artworks.se/pro";
		} else {
			premium = "Premium";
			link = "https://artworks.se/konstforening";
		}

		const content = (
			<div>
				<p>
					Här kan du som <i>{premium}</i> ladda upp en videofil.
				</p>
				<p>
					Läs mer och testa <i>{premium}</i> gratis{" "}
					<a href={link} target="_blank">
						här
					</a>
					! 🎨
				</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	premiumOnly = (evt) => {
		const { t } = this.props;
		evt.preventDefault();

		showPremiumUpgradeModal(this.props.dispatch, "Premium");
	};

	render() {
		const {
			handleSubmit,
			invalid,
			pristine,
			submitting,
			isAdmin,
			artist,
			artists,
			gallery,
			isSociety,
			isArtist,
			isArtSociety,
			eventId,
			votingIsEnabled,
			voteData,
			artworksForEvent,
			wishList,
			eventVideo,
			isNew,
			registeredSocieties,
			rsvpIsAllowed,
			t,
		} = this.props;

		const { expanded } = this.state;
		const rsvpTooltip = {
			text: t("single.toolTips.rsvp"),
			popupPos: "left",
		};
		const expandButtonStyle = {
			position: "absolute",
			right: "40px",
			top: 0,
		};
		const displayAsArtist = isArtist || (artist && !gallery);

		let premium = false;
		let premiumOrAbove = false;
		let standardOrAbove = false;
		let hasRsvpAccess = false;
		let hasVoteAccess = false;
		let automaticRemindersAvailable = false;

		if (isArtSociety) {
			standardOrAbove = isStandardOrAbove(gallery);
			premiumOrAbove = isPremiumOrAbove(gallery);
			hasRsvpAccess = hasAccessToFeature(gallery, RSVP_FEATURE);
			hasVoteAccess = hasAccessToFeature(gallery, VOTE_FEATURE);
			const hasAutomaticReminderAccess = hasAccessToFeature(
				gallery,
				AUTOMATIC_REMINDERS_FEATURE
			);
			automaticRemindersAvailable =
				premiumOrAbove || hasAutomaticReminderAccess;
			// premium used for uploading video files, use standardOrAbove.
			premium = standardOrAbove;
		} else if (isArtist) {
			premium = isPremium(artist);
		}

		const useUploadWithRotate = imageRotationActivated();

		// Also limited by isAdmin in Vote.jsx
		// const canStartVote = isArtSociety && (
		//   eventId === '5e11df526e8a291cce1c6f71' || // slug = roesta-pa-dina-favoriter
		//   eventId === '5e873cb6d0937c503db10313' || // slug = paskpresent
		//   eventId === '5ed8f4a5ca63836d80833224' || // slug = rosta-pa-konst
		//   eventId === '5edf92f9cb8e126c0bc5ee2a' // slug = litet-lotteri (test)
		// )

		// Need to rename pristine and submitting when sending down to Rsvp component, or they disappear.
		return (
			<form onSubmit={handleSubmit} className="Form">
				<legend>{t("single.generalInformation")}</legend>
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="title"
							component={TextInput}
							placeholder={t("single.eventName")}
							required
						/>
					</div>
					<div className="input-holder">
						<Field
							name="email"
							component={TextInput}
							placeholder={t("single.email")}
						/>
					</div>
					{!isSociety && (
						<div className="input-holder">
							<Field
								name="phone"
								component={TextInput}
								placeholder={t("common:phoneNumber")}
							/>
						</div>
					)}
					<div className="input-holder">
						<Field
							name="entranceFee"
							component={TextInput}
							placeholder={t("single.fee")}
							validate={isNumber}
						/>
					</div>
				</div>

				<legend>{t("single.dateAndTime")}</legend>
				<Fields names={["startDate", "endDate"]} component={DateRangePicker} />

				<div className="input-holder input-holder--full">
					<Field
						name="textArea"
						component={TextArea}
						placeholder={t("single.aboutEvent")}
					/>
				</div>

				<div className="fieldset">
					<legend>{t("single.uploadImage")}</legend>
					<Fields
						names={[
							"featuredImage.src",
							"uploadedImage.URI",
							"uploadedImage.fileName",
							"uploadedImage.contentType",
						]}
						component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
						validate={isValidImage}
						profileImage={true}
						canRemoveImage={true}
					/>
					{isAdmin && (
						<Field name="featuredImage.color" component={ColorPicker} />
					)}
				</div>
				{isAdmin && (
					<div className="input-holder">
						<legend className="legend-nomargin">Image center position</legend>
						<Field
							name="featuredImage.center"
							options={CENTER_IMAGE_OPTIONS}
							component={Select}
						/>
					</div>
				)}

				{(isAdmin || isSociety || isArtist) && (
					<div className="fieldset">
						<div className="input-holder input-holder--flex">
							<legend className="legend-nomargin">
								{t("common:uploadVideo")}
							</legend>
							<Tooltip
								data={videoTooltip()}
								style={{ left: "190px", right: "auto" }}
							/>
							<Field
								name="videoLink"
								component={TextInput}
								placeholder={t("common:youtubeExamle")}
								validate={validateEmbed}
								disabled={!premium && !isAdmin}
								green
								video
							/>
							{!premium && !isAdmin && (
								<div
									className="block-div"
									onClick={() => this.showProModal()}
								></div>
							)}
						</div>
						{eventVideo && (premium || isAdmin) && (
							<div className="videoContainer">
								<iframe
									width="440px"
									height="247px"
									src={eventVideo}
									frameBorder="0"
								/>
							</div>
						)}
					</div>
				)}

				{isArtSociety && <legend>{t("rsvp.rsvp")}</legend>}
				<div className="input-holder input-holder--full">
					{/* The old Rsvp, updating through form. */}
					{(premiumOrAbove || hasRsvpAccess || rsvpIsAllowed) &&
						isArtSociety &&
						false && (
							<Fields
								names={[
									"rsvpAllowed",
									"rsvp",
									"waitingList",
									"rsvpMaxUsers",
									"notInformContact",
									"rsvpDetails.rsvpWelcomeMailBody",
									"rsvpDetails.binding",
									"rsvpDetails.lastRegistrationDate",
									"title",
									"email",
									"_id",
								]}
								component={Rsvp}
								gallery={gallery}
								artist={artist}
								artists={artists}
								isPristine={pristine}
								isSubmitting={submitting}
								isArtistEvent={displayAsArtist}
							/>
						)}
					{/* Update rsvp and waitingList separately, not through form update. */}
					{(premiumOrAbove || hasRsvpAccess || rsvpIsAllowed) &&
						isArtSociety && (
							<Fields
								names={[
									"rsvpAllowed",
									"rsvpMaxUsers",
									"notInformContact",
									"rsvpDetails.rsvpWelcomeMailBody",
									"rsvpDetails.binding",
									"rsvpDetails.lastRegistrationDate",
									"title",
									"email",
									"_id",
								]}
								component={RsvpStandalone}
								responders={this.props.rsvp}
								onWaitingList={this.props.waitingList}
								gallery={gallery}
								artist={artist}
								artists={artists}
								isPristine={pristine}
								isSubmitting={submitting}
								isArtistEvent={displayAsArtist}
								isNew={isNew}
							/>
						)}
					{!premiumOrAbove &&
						!hasRsvpAccess &&
						!rsvpIsAllowed &&
						isArtSociety && (
							<div className="Input Input--text" onClick={this.premiumOnly}>
								{/* pointerEvents: none, or onClick on parent element won't be triggered. */}
								<input
									id="placeholder-checkbox"
									type="checkbox"
									disabled
									style={{ pointerEvents: "none" }}
								/>
								<label htmlFor="placeholder-checkbox">
									{t("rsvp.requiresRsvp", {
										type: displayAsArtist
											? t("rsvp.typeInvitation")
											: t("rsvp.typeEvent"),
									})}
								</label>
							</div>
						)}
					{isArtSociety && (
						<Fields
							names={["voteEnabled", "vote", "vote.voteLimit"]}
							component={Vote}
							votingIsEnabled={votingIsEnabled}
							artworksForEvent={artworksForEvent}
							wishList={wishList}
							standardOrAbove={standardOrAbove || hasVoteAccess}
							isAdmin={isAdmin}
						/>
					)}
				</div>
				{isAdmin && (
					<div>
						<legend>Pick organizer</legend>
						<Field name="gallery" component={GalleryPicker} />
					</div>
				)}
				{isAdmin && (
					<div
						className="input-holder input-holder--full input-holder--flex"
						style={{ opacity: 0.4, lineHeight: 0.7, fontSize: "14px" }}
					>
						<p>First artist picker for old events with single artist.</p>
						<p>Second artist picker for newer events with multiple artist.</p>
						<p>
							New events should only use the latter. Only admin can see both.
						</p>
					</div>
				)}
				{(isAdmin || (artist && artist._id)) && (
					<Field name="artist" component={ArtistPicker} multi={false} />
				)}
				{(isAdmin || !artist || !artist._id) && (
					<Field name="artists" component={ArtistPicker} multi={true} />
				)}
				{isAdmin && (
					<div className="fieldset">
						<Field name="category" type="event" component={CategoryPicker} />
						{!isArtist && <Field name="relatedPosts" component={PostPicker} />}
					</div>
				)}
				{!isArtist && (
					<div className="fieldset">
						{isSociety || isAdmin ? (
							<Field
								name="relatedShows"
								gallery={gallery}
								component={ShowPickerBig}
								isSociety={isSociety}
							/>
						) : (
							<Field name="relatedShows" component={ShowPicker} />
						)}
					</div>
				)}
				<div className="fieldset">
					<legend>{t("single.location")}</legend>
					<Fields
						names={[
							"locationName",
							"locationSearch",
							"location.type",
							"location.coordinates",
						]}
						component={LocationPicker}
					/>
				</div>
				{/* votingIsEnabled &&
          <Field
            name="artworks"
            component={ ArtworkPicker }
            defaultValue={[]}
            isAdmin={ isAdmin }
            gallery={ gallery || false }
            artist={ artist || false }
            artists={ artists || false }
          />
        */}
				{votingIsEnabled && !isNew && (
					<div className="input-holder input-holder--full">
						<Field
							name="artworks"
							component={ArtworkPickerList}
							toggleForItem={this.props.toggleForItem}
							addedArtworkIds={this.props.addedArtworkIds}
							toggleHeader={
								<div>
									{t("artworks:list.addTo")}
									<br />
									{t("artworks:list.vote")}
								</div>
							}
						/>
					</div>
				)}
				{isAdmin ? (
					<div className="fieldset">
						<legend>Visibility</legend>
						<div className="input-holder">
							<Field
								name="visibility"
								component={Select}
								options={VISIBILITY_OPTIONS}
							/>
						</div>
					</div>
				) : null}

				<div className="fieldset" style={{ position: "relative" }}>
					<div className="input-holder input-holder--full">
						<Field name="publish_status" component={PublishStatus} />
					</div>
					{(isSociety || isAdmin) && automaticRemindersAvailable && (
						<div
							className="input-holder input-holder--full"
							style={{ marginBottom: "20px" }}
						>
							<Field
								name="noAutomaticEmails"
								component={CheckBox}
								checkboxLabel={noEmailCheckbox(
									i18next.language === "sv" ? "aktiviteten" : "event"
								)}
							/>
						</div>
					)}
					{(isSociety || isAdmin) && !automaticRemindersAvailable && (
						<div
							className="input-holder input-holder--full"
							style={{ marginBottom: "20px", opacity: 0.5 }}
						>
							<div className="Input Input--text" onClick={this.premiumOnly}>
								{/* pointerEvents: none, or onClick on parent element won't be triggered. */}
								<input
									id="placeholder-checkbox"
									type="checkbox"
									checked
									disabled
									style={{ pointerEvents: "none" }}
								/>
								<label htmlFor="placeholder-checkbox">
									{noEmailCheckbox(
										i18next.language === "sv" ? "aktiviteten" : "event"
									)}
								</label>
							</div>
						</div>
					)}
				</div>
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="slug"
							itemType="event"
							component={TextDisplay}
							isAdmin={isAdmin}
						/>
					</div>
					{!this.props.formModalOpen && (
						<div className="fixed-save-button">
							<button
								className="purple-button--wide"
								type="submit"
								disabled={pristine || submitting}
							>
								{t("common:save")}
							</button>
						</div>
					)}
				</div>
				{false && isSociety && !eventId && (
					<div className="fieldset">
						<p>{t("single.checkInfo")}</p>
					</div>
				)}
				{isAdmin && (
					<div className="fieldset" style={{ marginTop: "30px" }}>
						<legend>Last email sent (admin only)</legend>
						<div className="input-holder">
							<Field name="emailsSentDate" component={TextInput} />
						</div>
					</div>
				)}
				{isAdmin && (
					<div className="fieldset" style={{ marginTop: "30px" }}>
						<div className="input-holder">
							<Field
								name="requiresSocietySignUp"
								component={CheckBox}
								checkboxLabel={"Event is for Activity Calendar (admin only)"}
							/>
							<legend>{`Signed up societies (${
								registeredSocieties ? registeredSocieties.length : 0
							}):`}</legend>
							{registeredSocieties && registeredSocieties.length > 0 && (
								<div style={{ marginBottom: "30px" }}>
									{registeredSocieties.map((society) => society.name)}
								</div>
							)}
						</div>
					</div>
				)}
			</form>
		);
	}
}

EventForm = reduxForm({
	form: "event",
})(EventForm);

const selector = formValueSelector("event");
EventForm = connect((state) => {
	const eventId = selector(state, "_id");
	const wishList = selector(state, "wishList");
	const {
		auth: { user },
	} = state;
	const isArtist = user.role === "artist";
	const votingIsEnabled = selector(state, "voteEnabled");
	const voteData = selector(state, "vote");
	const artworksForEvent = selector(state, "artworks");
	const registeredSocieties = selector(state, "registeredSocieties");

	const {
		artistForm: { showArtistModal },
		artworkForm: { showArtworkModal },
	} = state;
	const formModalOpen = showArtistModal || showArtworkModal;

	/*
    Rsvp is only for Premium, but we want it to keep working if it's already been set on the event.
    Get rsvpIsAllowed from initial values, not with selector above. Value from selector will change
    as soon as you click the checkbox.
  */
	const initialValues = getFormInitialValues("event")(state);
	const rsvpIsAllowed = initialValues ? initialValues.rsvpAllowed : false;

	// getting props.gallery and props.artist to send to subcomponents
	// const gallery = selector(state, 'gallery')
	// const artist = selector(state, 'artist')
	return {
		eventId,
		isArtist,
		votingIsEnabled,
		voteData,
		artworksForEvent,
		registeredSocieties,
		wishList,
		formModalOpen,
		rsvpIsAllowed,
	};
})(EventForm);

export default withNamespaces("event")(EventForm);
