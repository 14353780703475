import {
  INFLUENCER_GET_REQUEST, INFLUENCER_GET_SUCCESS, INFLUENCERS_GET_SUCCESS, INFLUENCER_GET_FAILURE,
  INFLUENCER_SAVE_REQUEST, INFLUENCER_SAVE_SUCCESS, INFLUENCER_SAVE_FAILURE,
  INFLUENCER_REMOVE_REQUEST, INFLUENCER_REMOVE_SUCCESS, INFLUENCER_REMOVE_FAILURE,
} from '../actions/InfluencerActions'

export function influencerReducer(state = {
  influencer: {},
  allInfluencers: {},
  isFetching: false,
  error: null,
}, action) {
  switch(action.type) {
    case INFLUENCER_GET_REQUEST: {
      return Object.assign({}, state, {
        influencer: {},
        deletedInfluencer: false,
        isFetching: true,
				error: null,
      })
    }
    case INFLUENCER_SAVE_REQUEST:
    case INFLUENCER_REMOVE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
				error: null,
      })
    }
    case INFLUENCER_SAVE_SUCCESS:
    case INFLUENCER_GET_SUCCESS: {
      let influencer = action.response
      let allInfluencers = Object.assign({}, state.allInfluencers)
      allInfluencers[influencer._id] = influencer
      return Object.assign({}, state, {
        influencer,
        allInfluencers,
        isFetching: false,
      });
		}
    case INFLUENCERS_GET_SUCCESS: {
      let allInfluencers = {}
      action.response.map(influencer => {
        allInfluencers[influencer._id] = influencer
      })
      return Object.assign({}, state, {
        allInfluencers,
        isFetching: false,
      });
		}
    case INFLUENCER_REMOVE_SUCCESS: {
      const { removedId } = action.response
      let allInfluencers = Object.assign({}, state.allInfluencers)
      delete allInfluencers[removedId]
      return Object.assign({}, state, {
        allInfluencers,
        influencer: {},
        deletedInfluencer: true,
				isFetching: false,
			})
    }
    case INFLUENCER_SAVE_FAILURE:
    case INFLUENCER_REMOVE_FAILURE:
		case INFLUENCER_GET_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			})
		}
    default:
      return state
  }
}
