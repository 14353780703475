import { CALL_API } from "../utils/api";
import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";
import { ART_SOCIETY_ARRAY } from "@artworkslab/sharedmodules/src/utils/Helpers";

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

// fetch galleries

export const GALLERIES_REQUEST = "GALLERIES_REQUEST";
export const GALLERIES_SUCCESS = "GALLERIES_SUCCESS";
export const GALLERIES_FAILURE = "GALLERIES_FAILURE";

// Uses the API to get a gallery
export function fetchGalleries() {
	return {
		[CALL_API]: {
			endpoint: "galleries",
			authenticated: true,
			types: [GALLERIES_REQUEST, GALLERIES_SUCCESS, GALLERIES_FAILURE],
		},
	};
}

export function fetchArtSocieties() {
	const query = {
		venueType: { $in: ART_SOCIETY_ARRAY },
		name: { $ne: null },
	};
	const sort = "name";
	return {
		[CALL_API]: {
			endpoint: `galleries/query?query=${encodeURIComponent(
				JSON.stringify(query)
			)}&sort=${sort}`,
			authenticated: true,
			types: [GALLERIES_REQUEST, GALLERIES_SUCCESS, GALLERIES_FAILURE],
		},
	};
}

// fetch gallery

export const GALLERY_REQUEST = "GALLERY_REQUEST";
export const GALLERY_SUCCESS = "GALLERY_SUCCESS";
export const GALLERY_FAILURE = "GALLERY_FAILURE";
export const GALLERY_NO_RESET_REQUEST = "GALLERY_NO_RESET_REQUEST";

// Uses the API to get a gallery
export function fetchGallery(galleryId) {
	const endpoint = galleryId ? "galleries/" + galleryId : "galleries";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [GALLERY_REQUEST, GALLERY_SUCCESS, GALLERY_FAILURE],
		},
	};
}

export const GALLERIES_ALL_REQUEST = "GALLERIES_ALL_REQUEST";
export const GALLERIES_ALL_SUCCESS = "GALLERIES_ALL_SUCCESS";
export const GALLERIES_ALL_FAILURE = "GALLERIES_ALL_FAILURE";

export function fetchAllGalleriesFast(name = "") {
	return {
		[CALL_API]: {
			endpoint: `galleries/fetch-all-fast/${name}`,
			authenticated: true,
			types: [
				GALLERIES_ALL_REQUEST,
				GALLERIES_ALL_SUCCESS,
				GALLERIES_ALL_FAILURE,
			],
		},
	};
}

export const GALLERIES_ALL_POPULATE_REQUEST = "GALLERIES_ALL_POPULATE_REQUEST";
export const GALLERIES_ALL_POPULATE_SUCCESS = "GALLERIES_ALL_POPULATE_SUCCESS";
export const GALLERIES_ALL_POPULATE_FAILURE = "GALLERIES_ALL_POPULATE_FAILURE";

export function fetchAllGalleriesPopulated() {
	// Request was too slow, timed out.
	return { type: "NOT-DOING-ANYTHING" };
	return {
		[CALL_API]: {
			endpoint: "galleries/fetch-all-populated",
			authenticated: true,
			types: [
				GALLERIES_ALL_POPULATE_REQUEST,
				GALLERIES_ALL_POPULATE_SUCCESS,
				GALLERIES_ALL_POPULATE_FAILURE,
			],
		},
	};
}

// remove gallery

export const GALLERY_REMOVE_REQUEST = "GALLERY_REMOVE_REQUEST";
export const GALLERY_REMOVE_SUCCESS = "GALLERY_REMOVE_SUCCESS";
export const GALLERY_REMOVE_FAILURE = "GALLERY_REMOVE_FAILURE";

// Uses the API to get a gallery
export function removeGallery(galleryId) {
	const endpoint = `galleries/${galleryId}`;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			method: "DELETE",
			types: [
				GALLERY_REMOVE_REQUEST,
				GALLERY_REMOVE_SUCCESS,
				GALLERY_REMOVE_FAILURE,
			],
		},
	};
}

// save gallery

export const GALLERY_SAVE_REQUEST = "GALLERY_SAVE_REQUEST";
export const GALLERY_SAVE_SUCCESS = "GALLERY_SAVE_SUCCESS";
export const GALLERY_SAVE_FAILURE = "GALLERY_SAVE_FAILURE";

// Uses the API to save a gallery
export function saveGallery(gallery) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			// console.log('getState', getState().gallery.gallery.registry.customColumns);
			const endpoint = gallery._id ? `galleries/${gallery._id}` : "galleries/";
			const method = gallery._id ? "PUT" : "POST";
			const analytics = gallery._id
				? {
						track: "Updated gallery",
						payload: {
							gallery: gallery._id,
						},
						incrementScore: 1,
				  }
				: false;

			const updatedColumns =
				gallery && gallery.registry && gallery.registry.customColumns
					? true
					: false;

			dispatch(saveGalleryRequest(analytics));
			Api(endpoint, method, true, gallery)
				.then((json) => {
					dispatch(saveGallerySuccess(json, updatedColumns));
					resolve(json);
				})
				.catch((error) => {
					dispatch(saveGalleryFail(error));
					reject(error);
				});
		});
}

export function saveGalleryRequest(analytics) {
	return {
		type: GALLERY_SAVE_REQUEST,
		analytics,
	};
}

export function saveGallerySuccess(json, updatedColumns) {
	return {
		type: GALLERY_SAVE_SUCCESS,
		response: json,
		message: translate("gallerySaved"),
		updatedColumns,
	};
}

export function saveGalleryFail(error) {
	return {
		type: GALLERY_SAVE_FAILURE,
		message: error,
	};
}

export function updatePremium(gallery, level, ISOdate) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = `galleries/${gallery._id}/activate-premium`;
			const method = "PUT";
			const body = {
				billing: {
					membershipLevel: level,
					membershipEnds: ISOdate,
				},
			};

			dispatch(saveGalleryRequest(false));
			Api(endpoint, method, true, body)
				.then((json) => {
					dispatch(saveGallerySuccess(json));
					resolve(json);
				})
				.catch((error) => {
					dispatch(saveGalleryFail(error));
					reject(error);
				});
		});
}

export const UPDATE_VALUE_REQUEST =
	"artworksapp/galleryActions/UPDATE_VALUE_REQUEST";
export const UPDATE_VALUE_SUCCESS =
	"artworksapp/galleryActions/UPDATE_VALUE_SUCCESS";
export const UPDATE_VALUE_FAILURE =
	"artworksapp/galleryActions/UPDATE_VALUE_FAILURE";

export function updateGalleryValue(galleryId, params) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = `galleries/${galleryId}/updatevalue`;
			dispatch(updateGalleryValueRequest({}));
			Api(endpoint, "PUT", true, params)
				.then((json) => {
					dispatch(updateGalleryValueSuccess(json));
					resolve(json);
				})
				.catch((error) => {
					dispatch(updateGalleryValueFailure(error));
					reject(error);
				});
		});
}

export function updateGalleryValueRequest() {
	return {
		type: UPDATE_VALUE_REQUEST,
	};
}

export function updateGalleryValueSuccess(json) {
	return {
		type: UPDATE_VALUE_SUCCESS,
		response: json,
		message: "NO MESSAGE",
	};
}

export function updateGalleryValueFailure(error) {
	return {
		type: UPDATE_VALUE_FAILURE,
		message: error,
	};
}

export function createMailingList(galleryId, listName) {
	const endpoint = "registry/mailinglist";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			method: "POST",
			body: {
				galleryId,
				listName,
			},
			types: [GALLERY_NO_RESET_REQUEST, GALLERY_SUCCESS, GALLERY_FAILURE],
		},
	};
}

export function createMailingListFailure(error) {
	return {
		type: GALLERY_FAILURE,
		message: error,
	};
}

export function deleteMailingList(galleryId, listId) {
	const endpoint = `registry/mailinglist/${galleryId}/${listId}`;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			method: "DELETE",
			types: [GALLERY_REQUEST, GALLERY_SUCCESS, GALLERY_FAILURE],
		},
	};
}

export function updateColumns(body) {
	const endpoint = "galleries/columns";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			method: "POST",
			body,
			types: [GALLERY_REQUEST, GALLERY_SUCCESS, GALLERY_FAILURE],
		},
	};
}

export const GALLERY_BY_QUERY_REQUEST = "GALLERY_BY_QUERY_REQUEST";
export const GALLERY_BY_QUERY_SUCCESS = "GALLERY_BY_QUERY_SUCCESS";
export const GALLERY_BY_QUERY_FAILURE = "GALLERY_BY_QUERY_FAILURE";

export function fetchGalleriesByQuery(query, sort) {
	let endpoint = `galleries/query?query=${encodeURIComponent(
		JSON.stringify(query)
	)}`;
	if (sort) endpoint += `&sort=${sort}`;
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [
				GALLERY_BY_QUERY_REQUEST,
				GALLERY_BY_QUERY_SUCCESS,
				GALLERY_BY_QUERY_FAILURE,
			],
		},
	};
}

export const COPY_GALLERY_REQUEST = "COPY_GALLERY_REQUEST";
export const COPY_GALLERY_SUCCESS = "COPY_GALLERY_SUCCESS";
export const COPY_GALLERY_FAILURE = "COPY_GALLERY_FAILURE";

export function copyGallery(galleryId) {
	const endpoint = `galleries/copy-gallery/${galleryId}`;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			method: "POST",
			types: [COPY_GALLERY_REQUEST, COPY_GALLERY_SUCCESS, COPY_GALLERY_FAILURE],
		},
	};
}

export const GALLERY_TIER_REQUEST = "GALLERY_TIER_REQUEST";
export const GALLERY_TIER_SUCCESS = "GALLERY_TIER_SUCCESS";
export const GALLERY_TIER_FAILURE = "GALLERY_TIER_FAILURE";

export function requestTierUpdate(tier, galleryId, userId, source) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = `galleries/update-tier/${galleryId}`;
			const body = { tier, userId, source };
			dispatch(requestTierUpdateRequest({}));
			Api(endpoint, "POST", true, body)
				.then((json) => {
					dispatch(requestTierUpdateSuccess(json));
					resolve(json);
				})
				.catch((error) => {
					dispatch(requestTierUpdateFailure(error));
					reject(error);
				});
		});
}

export function requestTierUpdateRequest() {
	return {
		type: GALLERY_TIER_REQUEST,
	};
}

export function requestTierUpdateSuccess(json) {
	return {
		type: GALLERY_TIER_SUCCESS,
		response: json,
		message: "NO MESSAGE",
	};
}

export function requestTierUpdateFailure(error) {
	return {
		type: GALLERY_TIER_FAILURE,
		message: error,
	};
}

export const MIXPANEL_TRACK = "mixpanelTrack";
export function mixpanelTrack(track, payload) {
	console.log("mixpanelTrack");
	console.log("track", track);
	console.log("payload", payload);
	// return dispatch => {
	return {
		type: MIXPANEL_TRACK,
		analytics: {
			track,
			payload,
		},
	};
	// }
}
