import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../../components/Table/Table";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from "moment";
import {
	fetchSubscribers,
	fetchUpdatePreview,
	updateSubscriptions,
} from "../../../actions/SubscriptionActions";
import Main from "../../../components/Main/Main";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import "react-day-picker/lib/style.css";

class KlarnaSubscriptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: "",
			freeSubsOpen: false,
		};
	}
	componentWillMount() {
		this.props.dispatch(fetchSubscribers());
	}

	handleDayClick = (e, day) => {
		this.setState({
			selectedDate: day.toISOString(),
		});
	};

	reloadSubscribers = () => {
		this.props.dispatch(fetchSubscribers());
	};

	fetchFullPreview = () => {
		const { selectedDate } = this.state;

		if (selectedDate)
			this.props.dispatch(fetchUpdatePreview(selectedDate, false));
	};

	fetchSinglePreview = (orderId) => {
		this.props.dispatch(fetchUpdatePreview(false, orderId));
	};

	renewSubscriptions = () => {
		const { selectedDate } = this.state;

		if (selectedDate)
			this.props.dispatch(updateSubscriptions(selectedDate, false));
	};

	renewSingleSubscriptions = (orderId) => {
		this.props.dispatch(updateSubscriptions(false, orderId));
	};

	toggleFreeSubsTable = () => {
		this.setState({
			freeSubsOpen: !this.state.freeSubsOpen,
		});
	};

	mapData = (obj, header) => {
		return (
			<div>
				<h4>{header}</h4>
				{Object.keys(obj).map((key) => {
					const value = obj[key];
					return <p key={key}>{`${key}: ${JSON.stringify(value)}`}</p>;
				})}
			</div>
		);
	};

	formatUpdateData = (subscription, index) => {
		const isFutureDate = moment(
			subscription.billing.klarnaSubscriptionEndDate
		).isAfter(moment().endOf("day"));
		const updateDisabled = isFutureDate; // && !__DEVELOPMENT__ ? true : false

		return (
			<div style={{ marginBottom: "30px" }} key={subscription.id + index}>
				<h4>
					<u>{subscription.name}</u>
				</h4>
				{!updateDisabled && (
					<div
						onClick={() =>
							this.renewSingleSubscriptions(
								subscription.billing.klarnaSubscriptionOrderId
							)
						}
						style={{
							display: "inline-block",
							padding: "5px 10px",
							margin: "5px 0",
							border: "1px solid black",
							cursor: "pointer",
						}}
					>
						Renew this subscription
					</div>
				)}
				<p>{subscription.product}</p>
				<p>{subscription.email}</p>
				<p>{subscription.id}</p>
				{this.mapData(subscription.tokenStatus, "Recurring token status")}
				{this.mapData(subscription.billing, "Billing")}
				{this.mapData(
					subscription.billingUpdateBody,
					"Body for Billing update"
				)}
				{subscription.updateOrderBody && (
					<div>
						<h4>Body for Order update</h4>
						{Object.keys(subscription.updateOrderBody).map((key) => {
							const value = subscription.updateOrderBody[key];
							if (key === "order_lines") {
								return (
									<div key={"order_lines"}>
										<h4>Order lines</h4>
										{value.map((orderLine, index) => {
											return (
												<div key={index}>
													<p>
														<u>{`Line ${++index}`}</u>
													</p>
													{Object.keys(orderLine).map((lineKey) => {
														return (
															<p key={lineKey}>{`${lineKey}: ${JSON.stringify(
																orderLine[lineKey]
															)}`}</p>
														);
													})}
												</div>
											);
										})}
									</div>
								);
							} else {
								return <p key={key}>{`${key}: ${JSON.stringify(value)}`}</p>;
							}
						})}
					</div>
				)}
				{subscription.renewedOrder &&
					this.mapData(subscription.renewedOrder, "Renewed order")}
				{subscription.updatedBilling &&
					this.mapData(subscription.updatedBilling, "Updated billing")}
				{subscription.updatedOriginalSubInDb &&
					this.mapData(
						subscription.updatedOriginalSubInDb,
						"Updated original Subscription"
					)}
				{subscription.savedRecurringSubInDb &&
					this.mapData(
						subscription.savedRecurringSubInDb,
						"Updated recurring Subscription"
					)}
			</div>
		);
	};

	createRows = (subscribers, subscriptionType) => {
		const proSubscribers = [];
		const freeSubscribers = [];
		const proIds = [];

		subscribers.forEach((subscriber) => {
			let level = "";
			let type = "";
			let memberSince = "";
			let memberSinceFormatted = "";
			let nextDraw = "";
			let nextDrawFormatted = "";
			let orderId = "";

			let billing = false;
			if (subscriptionType === "artistPro") billing = subscriber.artist.billing;
			if (subscriptionType === "awClub") billing = subscriber.billing;

			if (billing) {
				const {
					membershipLevel,
					premiumType,
					premiumDate,
					klarnaSubscriptionEndDate,
				} = billing;
				if (membershipLevel === "premium") level = "Pro";
				if (membershipLevel === "free") level = "Bas";
				type = premiumType;
				memberSince = premiumDate || "";
				memberSinceFormatted = premiumDate
					? DateUtil.dateMonthShort(premiumDate, "en", true)
					: "Not set";
				nextDraw = klarnaSubscriptionEndDate || "";
				nextDrawFormatted = klarnaSubscriptionEndDate
					? DateUtil.dateMonthShort(klarnaSubscriptionEndDate, "en", true)
					: "Not set";
				orderId = billing.klarnaSubscriptionOrderId;
			}

			let endDate = "";
			if (billing && billing.cancelledSubscriptions) {
				const latestOrderId = billing.klarnaSubscriptionOrderId;
				billing.cancelledSubscriptions.forEach((cancelledSub) => {
					if (cancelledSub.orderId === latestOrderId) {
						endDate = cancelledSub.date;
					}
				});
			}

			const row = (
				<Tr key={subscriber._id}>
					<Td column="name" value={subscriber.name}>
						<Link to={`/accounts/${subscriber._id}`}>{subscriber.name}</Link>
					</Td>
					<Td column="email" value={subscriber.email}>
						<Link to={`/accounts/${subscriber._id}`}>{subscriber.email}</Link>
					</Td>
					{subscriptionType === "artistPro" && (
						<Td column="artist" value={subscriber.artist.name}>
							<Link to={`/artists/${subscriber.artist.slug}`}>
								{subscriber.artist.name}
							</Link>
						</Td>
					)}
					<Td column="memberSince" value={memberSince}>
						{memberSinceFormatted}
					</Td>
					<Td column="membershipLevel" value={level}>
						{level}
					</Td>
					<Td
						column="premiumType"
						value={type}
						style={{ textTransform: "capitalize" }}
					>
						{type}
					</Td>
					{level === "Pro" && (
						<Td column="nextDraw" value={nextDraw}>
							<div
								style={{ cursor: "pointer" }}
								onClick={() => this.fetchSinglePreview(orderId)}
							>
								{nextDrawFormatted}
							</div>
						</Td>
					)}
					<Td column="cancelledSub" value={endDate}>
						{endDate ? DateUtil.dateMonthShort(endDate, "en", true) : ""}
					</Td>
				</Tr>
			);

			if (level === "Pro") {
				proSubscribers.push(row);
				if (subscriptionType === "artistPro") {
					if (proIds.indexOf(subscriber.artist._id) < 0) {
						proIds.push(subscriber.artist._id);
					}
				}
			} else {
				freeSubscribers.push(row);
			}
		});

		const returnData = {
			pro: proSubscribers,
			free: freeSubscribers,
		};

		if (subscriptionType === "artistPro") {
			returnData.proIds = proIds;
		}

		return returnData;
	};

	getTable = (rows, type) => {
		return (
			<Table
				className="reactable"
				sortable={[
					"name",
					"email",
					"artist",
					"membershipLevel",
					"premiumType",
					"memberSince",
					"nextDraw",
					"cancelledSub",
				]}
				filterable={false}
				filterPlaceholder="Search subscriptions"
			>
				<Thead>
					<Th column="name">Name</Th>
					<Th column="email">Email</Th>
					{(type === "artistPro" || type === "artistProFree") && (
						<Th column="artist">Artist</Th>
					)}
					<Th column="memberSince">Member since</Th>
					<Th column="membershipLevel">Membership</Th>
					<Th column="premiumType">Plan</Th>
					{type !== "artistProFree" && <Th column="nextDraw">Renewal</Th>}
					<Th column="cancelledSub">Cancelled</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	render() {
		const { subscribers, previewData, isAdmin, isFetching } = this.props;
		const { selectedDate } = this.state;

		if (!isAdmin) return null;

		const artistProSubscribers = subscribers.artistProUsers || [];
		const awClubSubscribers = subscribers.awClubUsers || [];

		const artistRows = this.createRows(artistProSubscribers, "artistPro");
		const artistProRows = artistRows.pro;
		const artistFreeRows = artistRows.free;
		const artistProCount = artistRows.proIds.length;
		const awClubRows = this.createRows(awClubSubscribers, "awClub");
		const awClubProRows = awClubRows.pro;
		// const artistBasRows = this.createRows()

		const isFutureDate =
			selectedDate && moment(selectedDate).isAfter(moment().endOf("day"));
		const updateDisabled = isFutureDate; // && !__DEVELOPMENT__ ? true : false

		return (
			<Main>
				<button
					onClick={this.reloadSubscribers}
					style={{ float: "right" }}
					disabled={isFetching}
				>
					Reload
				</button>
				<h2
					style={{ marginBottom: "10px" }}
				>{`Artist Pro with Klarna (${artistProCount})`}</h2>
				{this.getTable(artistProRows, "artistPro")}
				<h2 style={{ margin: "50px 0 10px 0" }}>Artworks Club</h2>
				{this.getTable(awClubProRows, "awClub")}
				<div style={{ marginTop: "50px" }}>
					<DayPicker ref="daypicker" onDayClick={this.handleDayClick} />
					<p style={{ padding: "10px 0 10px 40px" }}>
						{selectedDate.split("T")[0]}
					</p>
					<button
						onClick={this.fetchFullPreview}
						disabled={!selectedDate || isFetching}
					>
						Preview date
					</button>
					<button
						onClick={this.renewSubscriptions}
						disabled={!selectedDate || updateDisabled || isFetching}
					>
						Renew subs for date
					</button>
				</div>
				{previewData && (
					<div>
						<p style={{ marginTop: "50px" }}>
							Users/Artists with email ...@artworks.io don't get subs renewed or
							<br />
							ended automatically, so won't appear here.
						</p>
						<h3 style={{ margin: "20px 0 10px 0" }}>Renew subscriptions</h3>
						{previewData.subscriptionsToRenew.map((subscription, index) => {
							return this.formatUpdateData(subscription, index);
						})}
						<h3 style={{ margin: "50px 0 10px 0" }}>End subscriptions</h3>
						{previewData.subscriptionsToEnd.map((subscription, index) => {
							return this.formatUpdateData(subscription, index);
						})}
					</div>
				)}
				<button
					onClick={this.toggleFreeSubsTable}
					style={{ marginTop: "50px" }}
				>
					Free
				</button>
				{this.state.freeSubsOpen && (
					<div style={{ margin: "50px 0 50px 0" }}>
						<h2>Artist Pro Free</h2>
						{this.getTable(artistFreeRows, "artistProFree")}
					</div>
				)}
			</Main>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		subscription: { subscribers, previewData, isFetching },
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	return { subscribers, previewData, isAdmin, isFetching };
};

export default connect(mapStateToProps)(KlarnaSubscriptions);
