import React, { Component } from "react";

const Main = ({ children, preChildren = null, className = "" }) => {
	return (
		<div className="Main">
			{preChildren}
			<div className="Main__content">{children}</div>
		</div>
	);
};

export default Main;
