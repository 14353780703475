import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

class DateRangePicker extends React.Component {
	static defaultProps = {
		withTime: true,
	};

	static propTypes = {};

	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			endDate: moment(),
		};
	}

	componentWillMount() {
		if (!this.props.withTime) {
			this.setState({
				startDate: moment().startOf("day"),
				endDate: moment().endOf("day"),
			});
		}
	}

	componentDidMount() {
		if (this.props.updateCallbackMs) {
			this.props.updateCallbackMs(
				this.state.startDate.valueOf(),
				this.state.endDate.valueOf()
			);
		}
	}

	componentWillReceiveProps(newProps) {}

	handleStartTime = (startTime) => {
		const { endDate } = this.state;
		this.updateDates(startTime, endDate);
	};

	handleEndTime = (endTime) => {
		const { startDate } = this.state;
		this.updateDates(startDate, endTime);
	};

	handleChangeStart = (newStartDate) => {
		const { startDate, endDate } = this.state;

		// DatePicker resets the time. Restore it here.
		const hour = startDate.hour();
		const minute = startDate.minute();
		newStartDate.set("hour", hour);
		newStartDate.set("minute", minute);

		this.updateDates(newStartDate, endDate);
	};

	handleChangeEnd = (newEndDate) => {
		const { startDate, endDate } = this.state;
		// DatePicker resets the time. Restore it here.
		const hour = endDate.hour();
		const minute = endDate.minute();
		newEndDate.set("hour", hour);
		newEndDate.set("minute", minute);

		this.updateDates(startDate, newEndDate);
	};

	updateDates = (startDate, endDate) => {
		this.setState(
			{
				startDate,
				endDate,
			},
			() => {
				if (this.props.updateCallbackMs) {
					this.props.updateCallbackMs(startDate.valueOf(), endDate.valueOf());
				}
			}
		);
	};

	renderOk = (props) => {
		// TODO: Add OK button
		return null;
	};

	render() {
		const { startDate, endDate } = this.state;
		const { withTime, t } = this.props;

		return (
			<div className="DateRangePicker is-valid is-required">
				<div className="input-holder">
					<div className="DateRangePicker__input">
						<DatePicker
							placeholderText={t("startDate")}
							dateFormat="yyyy-MM-dd"
							selected={startDate.toDate()}
							onChange={this.handleChangeStart}
							required
						/>
					</div>
					{withTime && (
						<div className="DateRangePicker__input">
							<TimePicker
								value={startDate}
								showSecond={false}
								addon={this.renderOk}
								onChange={this.handleStartTime}
							/>
						</div>
					)}
				</div>

				<div className="input-holder">
					<div className="DateRangePicker__input">
						<DatePicker
							placeholderText={t("endDate")}
							dateFormat="yyyy-MM-dd"
							selected={endDate.toDate()}
							onChange={this.handleChangeEnd}
							required
						/>
					</div>
					{withTime && (
						<div className="DateRangePicker__input">
							<TimePicker
								value={endDate}
								showSecond={false}
								addon={this.renderOk}
								onChange={this.handleEndTime}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withNamespaces("common")(DateRangePicker);
