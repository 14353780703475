import React from "react";
import PropTypes from "prop-types";
import Input from "../../components/Input/Input";

const OpeningHourDaypropTypes = {
	onSelectDayOpen: PropTypes.func.isRequired,
	onSelectDayClose: PropTypes.func.isRequired,
	onSelectDayByAppointment: PropTypes.func.isRequired,
	dayOpeningHours: PropTypes.object.isRequired,
};
const OpeningHourDaydefaultProps = {
	dayOpeningHours: {},
	day: "Monday",
};

function OpeningHourDay({
	dayOpeningHours,
	day,
	onSelectDayOpen,
	onSelectDayClose,
	onSelectDayByAppointment,
}) {
	const { opens, closes, appointment } = dayOpeningHours;
	const byAppointment = appointment ? true : false;
	const opensPlaceholder = appointment ? "By appointment" : "Opens";
	const closesPlaceholder = appointment ? "" : "Closes";
	return (
		<tr>
			<td>{day}</td>
			<td>
				<Input
					name={`${day} opens`}
					value={opens}
					disabled={byAppointment}
					placeholder={opensPlaceholder}
					onChange={(value) => onSelectDayOpen(value, day)}
				/>
			</td>
			<td>
				<Input
					name={`${day} closes`}
					value={closes}
					disabled={byAppointment}
					placeholder={closesPlaceholder}
					onChange={(value) => onSelectDayClose(value, day)}
				/>
			</td>
			<td>
				<Input
					name={`${day} appointment`}
					value={byAppointment}
					type="checkbox"
					checkboxLabel="By appointment"
					onChange={(value) => {
						onSelectDayByAppointment(value, day);
					}}
				/>
			</td>
		</tr>
	);
}

OpeningHourDay.propTypes = OpeningHourDaypropTypes;
OpeningHourDay.defaultProps = OpeningHourDaydefaultProps;

export default OpeningHourDay;
