import {
	TOGGLE_EXPANDED,
  SALES_REQUEST, SALES_SUCCESS, SALES_FAILURE,
} from '../../actions/DashboardActions/LatestSalesActions'

export function latestSalesReducer(state = {
    latest: [],
    sales: [],
    expanded: true,
    isFetching: false,
  }, action) {
  switch (action.type) {
    case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
    case SALES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case SALES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        sales: action.response,
      });
    case SALES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    default:
      return state
  }
}
