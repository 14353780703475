import { CALL_API } from "../utils/api";
import Api from "@artworkslab/sharedmodules/src/Api";
import cookie from "react-cookie";
import Smooch from "smooch";
import { fetchArtist } from "./ArtistActions";
import { fetchGallery } from "./GalleryActions";

const API_BASE = process.env.API_BASE;

// fetch user
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";

// Uses the API to get a user
export function fetchUser(userId, currentUser = false) {
	return {
		[CALL_API]: {
			endpoint: "user/" + userId,
			authenticated: true,
			types: [USER_REQUEST, USER_SUCCESS, USER_FAILURE],
			method: "GET",
		},
	};
}

export function populateUser(user) {
	console.log("POPULATING USER");
	return async (dispatch) => {
		if (user.role === "artist") {
			console.log("POPULATING ARTIST");
			dispatch(fetchArtist(user.artist._id || user.artist));
		} else if (user.role === "author" || user.role === "society") {
			console.log("POPULATING GALLERY");
			dispatch(fetchGallery(user.gallery._id || user.gallery));
		}
	};
}

// login user
export const SYNC_REQUEST = "SYNC_REQUEST";
export const SYNC_SUCCESS = "SYNC_SUCCESS";
export const SYNC_FAILURE = "SYNC_FAILURE";

// Uses the API to get a user
export function syncUser(userId) {
	return {
		[CALL_API]: {
			endpoint: "user/" + userId,
			authenticated: true,
			types: [SYNC_REQUEST, SYNC_SUCCESS, SYNC_FAILURE],
			method: "GET",
			analytics: {
				setProps: "user",
			},
		},
	};
}

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

// Uses the API to update a user
export function updateUser(user) {
	return {
		[CALL_API]: {
			endpoint: "user/" + user._id + "/settings",
			authenticated: true,
			types: [USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE],
			method: "PUT",
			body: user,
			message: "Saved!",
			analytics: {
				incrementScore: 1,
				track: "Updated profile",
			},
		},
	};
}

export const USER_SAVE_REQUEST = "USER_SAVE_REQUEST";
export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const USER_SAVE_FAILURE = "USER_SAVE_FAILURE";

// Uses the API to save a user
export function saveUser(user) {
	return {
		[CALL_API]: {
			endpoint: "auth/register",
			authenticated: true,
			types: [USER_SAVE_REQUEST, USER_SAVE_SUCCESS, USER_SAVE_FAILURE],
			method: "POST",
			body: user,
			message: "Saved!",
		},
	};
}

// fetch users

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAILURE = "USERS_FAILURE";

// Uses the API to update a user
export function fetchUsers() {
	return {
		[CALL_API]: {
			endpoint: "user",
			authenticated: true,
			types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
			method: "GET",
		},
	};
}

export const USER_SAVED_GALLERY = "UserActions/USER_SAVED_GALLERY";
export const USER_SAVED_SHOW = "UserActions/USER_SAVED_SHOW";
export const USER_SAVED_ARTWORK = "UserActions/USER_SAVED_ARTWORK";

export function userSavedGallery() {
	return {
		type: USER_SAVED_GALLERY,
	};
}

export function userSavedShow() {
	return {
		type: USER_SAVED_SHOW,
	};
}

export function userSavedArtwork() {
	return {
		type: USER_SAVED_ARTWORK,
	};
}

export const USER_REMOVE_REQUEST = "USER_REMOVE_REQUEST";
export const USER_REMOVE_SUCCESS = "USER_REMOVE_SUCCESS";
export const USER_REMOVE_FAILURE = "USER_REMOVE_FAILURE";

export function removeUser(userId, deleteArtist, deleteGallery) {
	const endpoint = `user/${userId}?deleteartist=${deleteArtist}&deletegallery=${deleteGallery}`;
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			method: "DELETE",
			types: [USER_REMOVE_REQUEST, USER_REMOVE_SUCCESS, USER_REMOVE_FAILURE],
		},
	};
}

export function loginSmooch(user = false, t) {
	var smoochProps = {
		appId: process.env.SMOOCH_APP_ID,
		customText: {
			headerText: t("smooch.needHelp"),
			inputPlaceholder: t("smooch.typeHere"),
			sendButtonText: t("smooch.send"),
			introductionText: t("smooch.askUs"),
		},
	};
	if (user) {
		smoochProps.email = user.email || "No email";
		smoochProps.givenName = user.name || "No name";
		smoochProps.user_Id = user.id;
	}
	if (Smooch && (!user || user.role !== "admin")) {
		Smooch.destroy();
		Smooch.init(smoochProps).then(() => {
			const usr = Smooch.getUser();
			Smooch.updateUser({
				email: smoochProps.email,
				givenName: smoochProps.givenName,
				properties: {
					"User ID": smoochProps.user_Id,
				},
			});
		});
	}
}

export const UPDATE_USERISACTIVE_REQUEST =
	"authActions/UPDATE_USERISACTIVE_REQUEST";
export const UPDATE_USERISACTIVE_SUCCESS =
	"authActions/UPDATE_USERISACTIVE_SUCCESS";
export const UPDATE_USERISACTIVE_FAILURE =
	"authActions/UPDATE_USERISACTIVE_FAILURE";

export function updateUserIsActive(date) {
	return (dispatch) => {
		return Api("user/userisactive", "PUT", true, { date })
			.then((json) => {
				return dispatch(updateUserIsActiveSuccess(json));
			})
			.catch((err) => {
				return dispatch(updateUserIsActiveFailure(err));
			});
	};
}

function updateUserIsActiveSuccess(json) {
	return {
		type: UPDATE_USERISACTIVE_SUCCESS,
		response: true,
	};
}

function updateUserIsActiveFailure(err) {
	return {
		type: UPDATE_USERISACTIVE_FAILURE,
		response: false,
	};
}

// User who has role society (admin for an art society) can invite other users to become admins (role society).
// Not to be confused with users who have role admin, which is only for us here at Artworks.
export const SOCIETY_ADMIN_REQUEST = "userActions/SOCIETY_ADMIN_REQUEST";
export const SOCIETY_ADMIN_SUCCESS = "userActions/SOCIETY_ADMIN_SUCCESS";
export const SOCIETY_ADMIN_FAILURE = "userActions/SOCIETY_ADMIN_FAILURE";

export function fetchSocietyAdmins(galleryId) {
	return {
		[CALL_API]: {
			endpoint: `user/society-admins/${galleryId}`,
			authenticated: true,
			types: [
				SOCIETY_ADMIN_REQUEST,
				SOCIETY_ADMIN_SUCCESS,
				SOCIETY_ADMIN_FAILURE,
			],
			method: "GET",
		},
	};
}

export function removeSocietyAdmins(userToRemove, galleryId, removerName) {
	const body = { userToRemove, galleryId, removerName };
	return {
		[CALL_API]: {
			endpoint: "user/remove-society-admin",
			authenticated: true,
			types: [
				SOCIETY_ADMIN_REQUEST,
				SOCIETY_ADMIN_SUCCESS,
				SOCIETY_ADMIN_FAILURE,
			],
			method: "PUT",
			body,
		},
	};
}

// User who has role society (admin for an art society) can invite other users to become admins (role society).
// Not to be confused with users who have role admin, which is only for us here at Artworks.
export const USER_MAKE_SOCIETY_REQUEST =
	"userActions/USER_MAKE_SOCIETY_REQUEST";
export const USER_MAKE_SOCIETY_SUCCESS =
	"userActions/USER_MAKE_SOCIETY_SUCCESS";
export const USER_MAKE_SOCIETY_FAILURE =
	"userActions/USER_MAKE_SOCIETY_FAILURE";

export function inviteUserSocietyAdmin(
	galleryId,
	invitedsName,
	invitedsEmail,
	inviterName
) {
	const body = {
		galleryId,
		invitedsName,
		invitedsEmail,
		inviterName,
	};
	return (dispatch) => {
		dispatch(inviteUserSocietyAdminRequest());
		return Api("user/make-society-admin", "POST", true, body)
			.then((json) => {
				dispatch(inviteUserSocietyAdminSuccess(json));
			})
			.catch((err) => {
				dispatch(inviteUserSocietyAdminFailure(err));
			});
	};
}

function inviteUserSocietyAdminRequest() {
	return {
		type: USER_MAKE_SOCIETY_REQUEST,
	};
}

function inviteUserSocietyAdminSuccess(message) {
	return {
		type: USER_MAKE_SOCIETY_SUCCESS,
		message,
	};
}

function inviteUserSocietyAdminFailure(message) {
	return {
		type: USER_MAKE_SOCIETY_FAILURE,
		error: message,
	};
}

export const UPDATE_ARTIST_PRO_REQUEST =
	"userActions/UPDATE_ARTIST_PRO_REQUEST";
export const UPDATE_ARTIST_PRO_SUCCESS =
	"userActions/UPDATE_ARTIST_PRO_SUCCESS";
export const UPDATE_ARTIST_PRO_FAILURE =
	"userActions/UPDATE_ARTIST_PRO_FAILURE";

// End subscription for Klarna subscriber.
export function endArtistProSubscription(userId) {
	return (dispatch) => {
		dispatch(endSubscriptionRequest());
		return Api(`user/${userId}/endartistprosubscription`, "PUT", true, {})
			.then((json) => {
				dispatch(endSubscriptionSuccess(json));
			})
			.catch((err) => {
				dispatch(endSubscriptionFailure(err));
			});
	};
}

function endSubscriptionRequest() {
	return {
		type: UPDATE_ARTIST_PRO_REQUEST,
	};
}

function endSubscriptionSuccess(response) {
	return {
		type: UPDATE_ARTIST_PRO_SUCCESS,
		response,
	};
}

function endSubscriptionFailure(message) {
	return {
		type: UPDATE_ARTIST_PRO_FAILURE,
		error: message,
	};
}

export const MEMBERFUL_MEMBER_REQUEST = "userActions/MEMBERFUL_MEMBER_REQUEST";
export const MEMBERFUL_MEMBER_SUCCESS = "userActions/MEMBERFUL_MEMBER_SUCCESS";
export const MEMBERFUL_MEMBER_FAILURE = "userActions/MEMBERFUL_MEMBER_FAILURE";

export function fetchMemberfulMember(userEmail) {
	return {
		[CALL_API]: {
			endpoint: "user/memberful-member",
			authenticated: true,
			types: [
				MEMBERFUL_MEMBER_REQUEST,
				MEMBERFUL_MEMBER_SUCCESS,
				MEMBERFUL_MEMBER_FAILURE,
			],
			method: "POST",
			body: { userEmail },
		},
	};
}
