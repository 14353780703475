'use strict'
import React, { Component } from 'react'
import RichTextEditor       from 'react-rte';
import Editor               from '../../Editor/Editor'
import { DRAFT_DECORATOR }  from '../../Editor/editorhelpers'
import { DraftJS }          from 'megadraft'
import { fromJS }           from 'immutable'

class FormEditorSwedish extends Component {
  constructor(props){
    super(props)
    this.state = {
      draftContentState: null,
      htmlContent: false,
      markup: null,
      rawEditorState: null,
    }
  }

  componentDidMount(){
    if (typeof this.props.draftContentStateSwedish.input.value === 'string' && this.props.draftContentStateSwedish.input.value.length > 0) {
      const draftContentState = this.props.draftContentStateSwedish.input.value.length
      this.setState({ draftContentState })
    }
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  componentWillReceiveProps(nextProps){
    if (this.props.draftContentStateSwedish.input.value !== nextProps.draftContentStateSwedish.input.value) {
      if (typeof nextProps.draftContentStateSwedish.input.value === 'string' &&
          nextProps.draftContentStateSwedish.input.value.length > 0) {
        const draftContentState = JSON.parse(nextProps.draftContentStateSwedish.input.value)
        this.setState({ draftContentState })
      }
    }
  }

  onEditorUpdate = (markup, rawEditorState) => {
    console.log('UPDATING EDITOR');
    this.props.htmlContentSwedish.input.onChange(markup)
    this.props.htmlContentSwedish.input.onBlur(markup)
    this.props.draftContentStateSwedish.input.onChange(rawEditorState)
    this.props.draftContentStateSwedish.input.onBlur(rawEditorState)
  }

  // Set content to be updated and tell form that editor content is changed.
  setUpdateContent = (markup, rawEditorState) => {
    this.setState({
      markup,
      rawEditorState,
    })
    this.props.onEditorChange()
  }

  // Only run onChange and onBlur after button press from parent. Otherwise images can end up on top in editor.
  // Possibly because these updates are run while image is being uploaded.
  updateContentFromParentSwedish = () => {
    const { markup, rawEditorState } = this.state
    if (markup && rawEditorState) {
      this.props.htmlContentSwedish.input.onChange(markup)
      this.props.htmlContentSwedish.input.onBlur(markup)
      this.props.draftContentStateSwedish.input.onChange(rawEditorState)
      this.props.draftContentStateSwedish.input.onBlur(rawEditorState)
    }
  }

  render(){
    const { draftContentState } = this.state
    return (
      <Editor
        editorState={ draftContentState }
        onEditorLooseFocus={ this.onEditorUpdate }
        updateContent={ this.setUpdateContent }
        />
    )
  }
}

export default FormEditorSwedish
