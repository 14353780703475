import React from "react";
import { connect } from "react-redux";
import { artistProSubscribeWindowOpened } from "@artworkslab/sharedmodules/src/actions/MessageActions";

class ArtistProLink extends React.Component {
	slackUs = () => {
		this.props.dispatch(artistProSubscribeWindowOpened(this.props.user, true));
	};

	render() {
		const { children, hash = "" } = this.props;
		return (
			<a
				href={`https://artworks.se/pro` + hash}
				target="_blank"
				onClick={this.slackUs}
			>
				{children}
			</a>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
	} = state;
	return { user };
};

export default connect(mapStateToProps)(ArtistProLink);
