import React from "react";
import { DraftJS } from "megadraft"

export default ({entityKey, children, contentState}) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a className="editor__link" href={url} title={url}>
      {children}
    </a>
  );
};
