import React from "react";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import { createCopyForArtist } from "../../actions/ArtworkActions";
import { saveArtist } from "../../actions/ArtistActions";

// Artworks created by gallery should be visible for artist, but not editable.
// Same for artworks created by artist, not editable for the gallery.
class MentionedArtworksList extends React.Component {
	makeArtistCreator = (artworkId, creatorId) => {
		const { t } = this.props;
		const confirm = window.confirm(t("common:areYouSure"));
		if (confirm) {
			this.props.dispatch(createCopyForArtist(artworkId, creatorId));
		}
	};

	toggleTagged = (artId) => {
		const { artist } = this.props;

		let tagged = artist.tagged || [];
		let listIndex = false;
		const index = tagged.map((obj) => obj).indexOf(artId);

		if (index < 0) {
			tagged.push(artId);
		} else {
			tagged.splice(index, 1);
		}

		const update = {
			_id: artist._id,
			tagged: tagged,
		};
		this.props.dispatch(saveArtist(update));
	};

	render() {
		// galleryId/artistId is either from the user, or from gallery/artist selected by admin in dropdown.
		const {
			artworks,
			galleryId,
			artistId,
			selectedFilterName,
			show = false,
			toggleForItem,
			isAdmin,
			user,
			artist,
			OrderInput,
			t,
		} = this.props;
		const showArtIds = show ? show.artworks.map((artwork) => artwork._id) : [];

		const tableHead = (
			<Thead>
				{artist && artist._id && (
					<Th column="taggedCheck">{t("list.profile")}</Th>
				)}
				{!!show && <Th column="forItem">{` `}</Th>}
				<Th column="image">{` `}</Th>
				<Th column="title">{t("list.title")}</Th>
				{galleryId && <Th column="artist">{t("list.artist")}</Th>}
				{artistId && <Th column="gallery">{t("list.exhibitor")}</Th>}
				<Th column="created">{t("list.createdAt")}</Th>
				{!!show && <Th column="order">{t("list.order")}</Th>}
				<Th column="viewHere">{``}</Th>
				{isAdmin && <Th column="createdBy">{`Copy (admin)`}</Th>}
			</Thead>
		);

		const rows = [];
		artworks.forEach((artwork) => {
			const image = artwork.images[0];
			const smallThumbnail = image.thumbnails
				? image.thumbnails["100x100"].src
				: image.src;
			const largeThumbnail = image.thumbnails
				? image.thumbnails["750x"].src
				: image.src;
			const artistName = artwork.artistMod ? artwork.artistMod.name : "N/A";
			const galleryName = artwork.gallery ? artwork.gallery.name : "";
			const isChecked = show ? showArtIds.indexOf(artwork._id) >= 0 : false;
			const createdByArtist =
				artwork.created_by && artwork.created_by.role === "artist"
					? true
					: false;
			const checked =
				artist._id && artist.tagged && artist.tagged.includes(artwork._id)
					? true
					: false;

			if (
				!selectedFilterName ||
				selectedFilterName === "All" ||
				selectedFilterName === artistName
			) {
				rows.push(
					<Tr key={artwork._id}>
						{artist._id && (
							<Td column="taggedCheck">
								<input
									type="checkbox"
									className={"list-and-upload__raffle-checkbox"}
									onChange={() => this.toggleTagged(artwork._id)}
									checked={checked}
									disabled={false}
								/>
							</Td>
						)}
						{!!show && (
							<Td column="forItem">
								<input
									type="checkbox"
									className={"list-and-upload__raffle-checkbox"}
									onChange={() =>
										toggleForItem(show, artwork, isChecked, false, true)
									}
									checked={isChecked}
									disabled={false}
								/>
							</Td>
						)}
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{smallThumbnail && (
								<img
									src={smallThumbnail}
									onClick={() =>
										this.props.viewArtwork({
											fullSrc: image.src,
											thumbnail: largeThumbnail,
										})
									}
									onError={(elem) => (elem.target.src = image.src)}
									style={{ width: "60px", cursor: "pointer" }}
									alt="image of artwork"
								/>
							)}
						</Td>
						<Td column="title" value={artwork.title}>
							{artwork.title}
						</Td>
						{galleryId && (
							<Td column="artist" value={artistName}>
								{artistName}
							</Td>
						)}
						{artistId && galleryName && (
							<Td column="gallery" value={galleryName}>
								{galleryName}
							</Td>
						)}
						<Td column="created" value={artwork.created_at}>
							{DateUtil.dateMonthShort(
								artwork.created_at,
								i18next.language,
								true
							)}
						</Td>
						{!!show && isChecked && (
							<Td
								column="order"
								value={artwork.index > 0 ? artwork.index : 10000000000}
							>
								<OrderInput
									initialValue={artwork.index || -1}
									keyForValue={"index"}
									id={artwork._id}
									artistId={artistId}
									update={this.props.updateOrder}
									t={t}
								/>
							</Td>
						)}
						<Td column="viewHere" value="ViewHere">
							<a
								href={`${process.env.BASE_URL}konstverk/${artwork.slug}`}
								target="_blank"
								style={{ textDecoration: "underline" }}
							>
								{t("list.viewHere")}
							</a>
						</Td>
						{artwork.artistMod && !createdByArtist && (
							<Td column="createdBy" value={artwork.publish_status}>
								<div
									style={{ cursor: "pointer" }}
									onClick={() =>
										this.makeArtistCreator(artwork._id, artwork.artistMod._id)
									}
								>
									<u>Copy for artist</u>
								</div>
							</Td>
						)}
					</Tr>
				);
			}
		});

		return (
			<Table
				className="reactable"
				sortable={["title", "artist", "gallery", "created"]}
				defaultSort={{ column: "created", direction: "desc" }}
				filterable={false}
				filterPlaceholder={"Search artwork"}
				itemsPerPage={100}
				pageButtonLimit={5}
			>
				{tableHead}
				{rows}
			</Table>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
	} = state;
	return { user };
};

export default connect(mapStateToProps)(MentionedArtworksList);
