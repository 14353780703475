import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	fetchScrapedShows,
	rejectScrapedShow,
} from "../../actions/ScrapedActions";
import { getPendingShows } from "../../selectors/scrapedSelectors";
import Main from "../../components/Main/Main";

const ScrapedShow = ({ show, rejectShow }) => {
	return (
		<div style={{ paddingBottom: "20px" }}>
			<h4>{"Show data"}</h4>
			<p>{`${show.artist ? show.artist : "No artist"} - ${
				show.title ? show.title : "No title"
			}`}</p>
			<p>{`${show.startDate} - ${show.endDate}`}</p>
			<p>
				<b>Gallery data</b>
			</p>
			{Object.keys(show.galleryData).map((key) => {
				const data = show.galleryData[key];
				return <p key={key}>{data}</p>;
			})}
			<Link className="button" to={"/scraped/shows/" + show._id}>
				Add
			</Link>
			<button
				className="button"
				onClick={() => {
					rejectShow(show);
				}}
			>
				Reject
			</button>
			<a
				className="button"
				href={`http://www.google.com/search?q=${show.artist}+${show.title}`}
				target="_blank"
				rel="noopener"
			>
				Search
			</a>
		</div>
	);
};

class ScrapedShows extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		this.props.dispatch(fetchScrapedShows());
	}

	rejectShow = (show) => {
		this.props.dispatch(rejectScrapedShow(show._id));
	};

	render() {
		let showsCount = Object.keys(this.props.scrapedShows).length;
		const { scrapedShows } = this.props;
		const admin = this.props.user.role === "admin";
		if (!admin) return null;
		return (
			<div className="Shows">
				<Main>
					<div className="MainTop">
						<h2>New shows ({showsCount})</h2>
					</div>
					{Object.keys(scrapedShows).map((key) => {
						const show = scrapedShows[key];
						return (
							<ScrapedShow key={key} show={show} rejectShow={this.rejectShow} />
						);
					})}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
	} = state;
	const scrapedShows = getPendingShows(state);
	return { scrapedShows, user, isAdmin };
}

export default connect(mapStateToProps)(ScrapedShows);
