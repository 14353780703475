import React, { Component } from "react";
import PropTypes from "prop-types";

function Artwork(props) {
	function _toggleArtwork(e) {
		e.preventDefault();
		props.toggleArtwork(props.artwork._id);
	}

	const { artwork, selected } = props;
	let image = artwork.images || [{}];
	if (image.length < 1) image = [{}];
	return (
		<div
			className={"ShowForm__artwork" + (selected ? "--selected" : "")}
			key={artwork._id}
			href="#"
			onClick={_toggleArtwork}
		>
			<img src={image[0].src} style={{ height: 60 }} />
		</div>
	);
}

Artwork.propTypes = {
	toggleArtwork: PropTypes.func.isRequired,
	artwork: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
};

export default Artwork;
