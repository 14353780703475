import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { getObjectProp } from "../../utils/Helpers";
import readXlsxFile from "read-excel-file";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import ContactListForm from "./ContactListForm";

import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormInitialValues,
	getFormValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import {
	fetchContactList,
	resetContactList,
	updateContacts,
	updateContact,
	sendContactsUpload,
} from "@artworkslab/sharedmodules/src/actions/ContactActions";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { submitContactList } from "../../actions/FormActions/ContactFormActions";
import { uploadFile } from "../../actions/ArchiveActions";
import { showCustomModal } from "../../actions/AppActions";

const BIN_PNG = require("../../../assets/images/icons/trashcan.png");
const PLUS_WHITE = require("../../../assets/images/svg/plus_32px_white.svg");
const CLOSE = require("../../../assets/images/icons/close@2x.png");
const ARROW = require("../../../assets/images/svg/arrow_right.svg");

class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
	}

	componentDidMount() {
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setValue(nextProps.value);
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	_update = (evt) => {
		const { contactId, keyForValue, update } = this.props;
		update(contactId, keyForValue, evt.target.value);
	};

	render() {
		const { value } = this.state;
		const { approved } = this.props;

		return (
			<input
				type="text"
				value={value}
				onChange={(evt) => this.setValue(evt.target.value)}
				onBlur={(evt) => this._update(evt)}
				className="input-field"
			/>
		);
	}
}

class ContactList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newName: "",
			newEmail: "",
			newEmailValid: false,
			addContactOpen: false,
			initialized: false,
		};
	}

	componentDidMount() {
		const contactListSlug = this.props.params.contactListSlug;
		const { user } = this.props;
		if (contactListSlug !== "new") {
			this.props.dispatch(fetchContactList(contactListSlug));
		} else {
			this.props.dispatch(resetContactList());
			this.props.dispatch(initialize("contactlist", {}));
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.deleteContactList) {
			return this.props.router.push("/contactlists");
		}
		if (
			(!this.state.initialized && nextProps.contactList._id) ||
			(this.props.contactList._id !== nextProps.contactList._id &&
				nextProps.contactList._id)
		) {
			this.setState({
				initialized: true,
			});
			this.props.dispatch(initialize("contactlist", nextProps.contactList));
		}
	}

	sendContactsToUs = (evt) => {
		evt.preventDefault();
		const { user, contactList, t } = this.props;
		const uploadedFile = this.sendFileUpload.files[0];
		const reader = new FileReader();

		let file = {};
		reader.onload = (upload) => {
			file.URI = upload.target.result;

			const artworksId = "5d381f9000378e6fdaed35a1";
			this.props
				.dispatch(uploadFile(artworksId, file))
				.then((result) => {
					const uploadedData = result ? result[0] : false;
					if (!uploadedData) throw new Error("Failed to upload file");

					const attachObj = {
						URI: encodeURI(uploadedData.URL),
						fileName: uploadedFile.name,
						knownLength: uploadedFile.size,
						contentType: uploadedFile.type,
					};
					return this.props.dispatch(
						sendContactsUpload(attachObj, contactList.name)
					);
				})
				.then((success) => {
					if (success) {
						this.showSuccessModal();
					} else {
						this.showFailureModal();
					}
				})
				.catch((err) => {
					this.showFailureModal();
				});
		};

		if (uploadedFile) {
			file.fileName = `${user.name}-${uploadedFile.name}`;
			file.contentType = uploadedFile.type;

			reader.readAsDataURL(uploadedFile);
		}
	};

	showSuccessModal = () => {
		const content = (
			<div>
				<p>Toppen, din lista är uppladdad!</p>
				<p>Du aviseras så snart som din lista är uppdaterad.</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	showFailureModal = () => {
		const content = (
			<div>
				<p>Något gick fel.</p>
				<p>Kontakta oss via hey@artworks.io eller försök igen lite senare</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	importFile = (evt) => {
		evt.preventDefault();
		const columns = [
			{ value: "name", label: "Namn" },
			{ value: "email", label: "Email" },
		];
		let newContacts = [];
		const contactEmails = this.props.contactList.contacts.map(
			(contact) => contact.email
		);
		let feedbackMessage = "";

		const file = this.fileUpload.files[0];
		readXlsxFile(file).then((rows) => {
			// Get column index of name and email headers.
			let nameIndex = -1,
				emailIndex = -1;
			const nameRegex = /^(namn|name)$/i;
			const emailRegex = /^(mejl|email)$/i;
			rows[0].forEach((header, index) => {
				if (nameRegex.test(header)) {
					nameIndex = index;
				} else if (emailRegex.test(header)) {
					emailIndex = index;
				}
			});

			if (emailIndex < 0) {
				if (feedbackMessage) {
					this.setState({
						feedbackMessage: "Ingen kolumn för Email",
					});
				}
				throw new Error("No email column");
			}

			rows.forEach((row, rowIndex) => {
				// Don't include header row.
				if (rowIndex > 0) {
					let newContact = {
						name: "",
						email: "",
					};
					newContact.email = row[emailIndex];
					if (nameIndex >= 0) newContact.name = row[nameIndex];

					const valid = validateEmail(newContact.email);
					const existingContact = contactEmails.indexOf(newContact.email) > -1;

					if (valid && !existingContact) {
						newContacts.push(newContact);
					} else if (!valid) {
						feedbackMessage =
							feedbackMessage + `Ogiltig email adress: ${newContact.email}\n`;
					} else if (existingContact) {
						feedbackMessage =
							feedbackMessage + `${newContact.email} finns redan i listan.\n`;
					}
				}
			});

			const params = {
				addContacts: newContacts,
			};
			this.props.dispatch(updateContacts(this.props.contactList._id, params));

			if (feedbackMessage) {
				this.setState({
					feedbackMessage,
				});
			}
		});
	};

	toggleAddContactOpen = (e) => {
		e.preventDefault();
		this.setState({
			addContactOpen: !this.state.addContactOpen,
		});
	};

	updateNewEmail = (e) => {
		e.preventDefault();
		this.setState({
			newEmail: e.target.value,
			newEmailValid: validateEmail(e.target.value),
		});
	};

	updateNewName = (e) => {
		e.preventDefault();
		this.setState({
			newName: e.target.value,
		});
	};

	removeNewContact = (e) => {
		e.preventDefault();
		this.setState({
			newName: "",
			newEmail: "",
			newEmailValid: true,
			addContactOpen: false,
		});
	};

	addContact = (e) => {
		e.preventDefault();
		const { contactList } = this.props;
		const { newName, newEmail } = this.state;

		const params = {
			addContacts: [
				{
					name: newName,
					email: newEmail,
				},
			],
		};

		this.setState(
			{
				newName: "",
				newEmail: "",
				newEmailValid: true,
				addContactOpen: false,
			},
			() => {
				this.props.dispatch(updateContacts(contactList._id, params));
			}
		);
	};

	updateContactValue = (contactId, key, value) => {
		const { contactList, t } = this.props;
		const contactListId = contactList._id;
		const body = { key, value, contactListId };
		if (key === "email") {
			if (!validateEmail(value)) {
				window.alert(t("badEmail"));
				return;
			}
		}
		this.props.dispatch(updateContact(contactId, body));
	};

	removeContact = (id) => {
		const { contactList, t } = this.props;
		let confirm = window.confirm(t("single.confirmRemoveContact"));
		if (!confirm) return;

		const params = {
			removeContacts: [id],
		};

		this.props.dispatch(updateContacts(contactList._id, params));
	};

	createTable = (rows, t) => {
		const { newName, newEmail, newEmailValid, addContactOpen } = this.state;
		const addButtonDisabled = !newEmailValid;

		return (
			<Table className="reactable" sortable={["name", "email"]}>
				<Thead>
					<Th column="name">{t("common:name")}</Th>
					<Th column="email">{t("common:email")}</Th>
					<Th column="add">{``}</Th>
					<Th
						column={"delete"}
						key={"delete"}
						style={{ position: "relative" }}
					>{``}</Th>
				</Thead>
				{addContactOpen && (
					<Tr
						key={"index"}
						className={`new-contact ${addContactOpen ? "" : "is-closed"}`}
					>
						<Td column="name" value={"sort-first"}>
							<input value={newName} onChange={this.updateNewName} />
						</Td>
						<Td column="email" value={"sort-first"}>
							<input
								value={newEmail}
								onChange={this.updateNewEmail}
								style={{ color: newEmailValid ? "black" : "red" }}
							/>
						</Td>
						<td column="add">
							<div
								className={`add-contact ${addButtonDisabled ? "disabled" : ""}`}
								onClick={addButtonDisabled ? null : this.addContact}
							>
								{t("single.saveContact")}
							</div>
						</td>
						<Td column={"delete"} value="delete" key="delete">
							<img
								src={BIN_PNG}
								className={"remove-contact"}
								onClick={this.removeNewContact}
							/>
						</Td>
					</Tr>
				)}
				{rows}
			</Table>
		);
	};

	createRows = (contacts = []) => {
		return contacts.map((contact, index) => {
			return (
				<Tr key={index}>
					<Td column="name" value={contact.name}>
						<InputField
							value={contact.name}
							update={this.updateContactValue}
							contactId={contact._id}
							keyForValue={"name"}
						/>
					</Td>
					<Td column="email" value={contact.email}>
						<InputField
							value={contact.email}
							update={this.updateContactValue}
							contactId={contact._id}
							keyForValue={"email"}
						/>
					</Td>
					<Td column={"delete"} value="delete" key="delete">
						<img
							src={BIN_PNG}
							className={"remove-contact"}
							onClick={() => this.removeContact(contact._id)}
						/>
					</Td>
				</Tr>
			);
		});
	};

	handleSubmit = () => {
		const {
			form: { contactlist, initial },
			contactList,
		} = this.props;
		const contactListId = contactList._id ? contactList._id : undefined;
		this.props.dispatch(submitContactList(contactlist, initial, contactListId));
	};

	stepBack = () => {
		this.props.router.push("/contactlists");
	};

	render() {
		const {
			params: { contactListSlug },
			contactList,
			isAdmin,
			isFetching,
			t,
		} = this.props;
		const isResponsive = appGlobals.useResponsive;
		const MainComponent = isResponsive ? MainResp : Main;
		const table = this.createTable(this.createRows(contactList.contacts), t);

		return (
			<div className="contact-list">
				<MainComponent contentClassName="slim-content">
					<div className="step-back-button" onClick={this.stepBack}>
						<ARROW />
						<span>{t("artworks:list.stepBack")}</span>
					</div>
					<ContactListForm
						isAdmin={isAdmin}
						onSubmit={this.handleSubmit}
						t={t}
					/>
					{contactList._id && (
						<div
							className={
								"contact-list__content" + (!isResponsive ? " is-desktop" : "")
							}
						>
							{isAdmin && (
								<div
									className="contact-list__content__import"
									style={{ marginBottom: "40px" }}
								>
									<input
										type="file"
										id="file"
										ref={(ref) => (this.fileUpload = ref)}
										onChange={this.importFile}
										className={"input"}
										disabled={isFetching}
									/>
									<label
										htmlFor="file"
										className="contact-list__content__import__button purple-button--wide"
									>
										{t("single.adminImport")}
									</label>
								</div>
							)}
							<div className="contact-list__content__import">
								<input
									type="file"
									id="sendFile"
									ref={(ref) => (this.sendFileUpload = ref)}
									onChange={this.sendContactsToUs}
									className={"input"}
									disabled={isFetching}
								/>
								<label
									htmlFor="sendFile"
									className="contact-list__content__import__button purple-button--wide"
								>
									{t("single.import")}
								</label>
							</div>
							<button
								className="contact-list__content__import__button purple-button--wide"
								onClick={this.toggleAddContactOpen}
							>
								{t("single.addContact")}
							</button>
							<div className="contact-list__table">{table}</div>
						</div>
					)}
				</MainComponent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		contactList: { contactList, isFetching },
		auth: { user, isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("contactlist")(state),
		submitSucceeded: hasSubmitSucceeded("contactlist")(state),
		submitFailed: hasSubmitFailed("contactlist")(state),
		contactlist: getFormValues("contactlist")(state),
		initial: getFormInitialValues("contactlist")(state),
	};
	return {
		contactList,
		user,
		isAdmin,
		form,
		isFetching,
	};
}

const translated = withNamespaces("contactlist")(ContactList);
export default connect(mapStateToProps)(withRouter(translated));
