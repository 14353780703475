import React, { Component } from "react";
import { connect } from "react-redux";
import Main from "../../components/Main/Main";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import { loginSmooch } from "../../actions/UserActions";

const PRODUCT = require("../../../assets/images/product.png");

class Home extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { user, t } = this.props;
		// loginSmooch(false, t)
	}

	render() {
		const { user } = this.props;
		return (
			<div className="homepage">
				<div className="home-header">
					<div className="home-header__content">
						<h1 className="home-header__content__main-header">
							Join Artworks ❤️
						</h1>
						<h1 className="home-header__content__sub-header"></h1>
						<div className="homepage__buttons">
							<div
								className="homepage__buttons__button-container"
								style={{ textAlign: "center" }}
							>
								<Link
									to="/login"
									className="button--color_black"
									style={{ width: "100%" }}
								>
									LOGIN
								</Link>
							</div>
							<div
								className="homepage__buttons__button-container"
								style={{ textAlign: "center" }}
							>
								<Link
									to="/register"
									className="button--color_black"
									style={{ width: "100%" }}
								>
									SIGN UP
								</Link>
							</div>
						</div>
					</div>
				</div>
				<section className="homepage__content">
					<h1>Why Artworks?</h1>
					<div className="statement-container">
						<p>
							Artworks Portal is a powerful and user-friendly online tool that
							will drive your art venue into the digital space, exposing your
							program in our channels and giving you the metrics needed to
							further grow your business
						</p>
					</div>
					<div className="statement-container">
						<p>
							We will help you answer the following questions, helping you make
							better business decisions:
						</p>
					</div>
					<div className="benefit-container">
						<p>How your shows are performing</p>
						<ul>
							<li>Who visit your shows</li>
							<li>Which shows trigger more engagement</li>
							<li>User ratings</li>
						</ul>
					</div>
					<div className="benefit-container">
						<p>Who is interested in your works of art</p>
						<ul>
							<li>Which artworks are popular online</li>
							<li>Handle enquiries for artworks</li>
						</ul>
					</div>
					<div className="statement-container">
						<p>
							We help to promote your program, expand your client base, and
							strengthen your relationship with current and future customers.
						</p>
					</div>
					<div className="statement-container">
						<p>
							Join us and we promise to listen carefully to your needs. After
							all, we are builiding Artworks features for you, only for us to
							see more art!
						</p>
					</div>
					<div className="homepage__buttons">
						<div
							className="homepage__buttons__button-container"
							style={{ textAlign: "center" }}
						>
							<Link
								to="/login"
								className="button--color_black"
								style={{ width: "100%" }}
							>
								LOGIN
							</Link>
						</div>
						<div
							className="homepage__buttons__button-container"
							style={{ textAlign: "center" }}
						>
							<Link
								to="/register"
								className="button--color_black"
								style={{ width: "100%" }}
							>
								SIGN UP
							</Link>
						</div>
					</div>
					<img className="homepage__content__image" src={PRODUCT} />
				</section>
			</div>
		);
	}
}

export default withNamespaces("common")(Home);
