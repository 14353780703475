import React, { Component } from "react";

const InternalLinkComponent = ({ entityKey, children, contentState }) => {
	const { _id, slug, type } = contentState.getEntity(entityKey).getData();
	const data = contentState.getEntity(entityKey).getData();
	return (
		<a
			className="editor__link"
			href={`https://artworksapp.com/${type}/${slug}`}
			data-id={_id}
			data-type={type}
		>
			{children}
		</a>
	);
};

export default InternalLinkComponent;
