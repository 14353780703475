import i18next                from 'i18next'
import resBundle              from 'i18next-resource-store-loader!../assets/locales/index.js'
import detector               from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

const OPTIONS = {
  whitelist: ['sv', 'en'],
  nonExplicitWhitelist: true,
  // If detector finds no language saved in localstorage, use "sv".
  fallbackLng: 'sv',
  load: 'languageOnly',
  react: {
    wait: true,
    bindI18n: false,
    bindStore: false,
    nsMode: 'default',
  }
}

const DETECTOR_OPTIONS = {
  order: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
}

const i18n = () => {
  return i18next
    .use(detector)
    .use(reactI18nextModule)
    .init({
      detection: DETECTOR_OPTIONS,
      debug: __DEVELOPMENT__,
      ...OPTIONS,
      resources: resBundle,
    }, (err, t) => {
      if (__DEVELOPMENT__) {
        console.log('i18n error', err);
        console.log('tclient', t);
      }
    }
  )
}

export default i18n
