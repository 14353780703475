import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class SingleDatePicker extends Component {
	handleChangeDate = (theDate) => {
		theDate.set("hour", 8);
		this.props.input.onChange(theDate);
		this.props.input.onBlur(theDate);
	};

	render() {
		const theDate = this.props.input.value ? this.props.input.value : moment();

		const required = this.props.required ? true : false;

		return (
			<div className="DateRangePicker is-required">
				<div className="input-holder">
					<div className="DateRangePicker__input">
						<DatePicker
							placeholderText="Date"
							dateFormat="yyyy-MM-dd"
							selected={theDate.toDate()}
							onChange={this.handleChangeDate}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default SingleDatePicker;
