export const TOGGLE_SOCIETY_PACKAGE_MODAL = 'modal/TOGGLE_SOCIETY_PACKAGE_MODAL'

function toggleSocietyPackageSelector(doOpen = false, forceChoice = false, header = '', footer = '') {
  return {
    type: TOGGLE_SOCIETY_PACKAGE_MODAL,
    doOpen,
    forceChoice,
    header,
    footer,
  }
}

export {
  toggleSocietyPackageSelector,
}
