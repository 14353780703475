import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
	arrayRemove,
} from "redux-form";
import Main from "../../components/Main/Main";
import PublicPurchaserList from "../../components/Artworks/PublicPurchaserList";
import SelectionForm from "./SelectionForm";
import {
	fetchSelections,
	removeSelection,
	addArtistsFromGallery,
} from "../../actions/SelectionActions";
import {
	initSelection,
	loadSelection,
	submitSelection,
	showSuccess,
	hideSuccess,
	showError,
	hideError,
} from "../../actions/FormActions/SelectionFormActions";
// import { fetchSelection }   from '@artworkslab/sharedmodules/src/actions/SelectionActions'
// import {
//   fetchSelection,
//  	submitSelection,
// }                           from "../../actions/SelectionFormActions"

class Selection extends Component {
	componentWillMount() {
		const { selectionId } = this.props.params;
		if (selectionId !== "new") {
			this.props.dispatch(fetchSelections(selectionId));
		} else {
			this.props.dispatch(initSelection());
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.selection.deletedSelection) {
			return this.props.router.push("/selections");
		}

		if (
			this.props.selection.selection._id !== nextProps.selection.selection._id
		) {
			this.props.dispatch(loadSelection(nextProps.selection.selection));
		}

		const { selectionId } = nextProps.params;
		if (this.props.params.selectionId !== selectionId) {
			if (selectionId !== "new") {
				this.props.dispatch(fetchSelections(selectionId));
			} else {
				this.props.dispatch(initSelection());
			}
		}
	}

	submit = () => {
		const {
			form: { selection, initial },
		} = this.props;
		this.props.dispatch(submitSelection(selection, initial));
	};

	handleRemove = () => {
		const {
			form: { selection },
		} = this.props;
		this.props.dispatch(removeSelection(selection._id));
	};

	removeItem = (type, index) => {
		this.props.dispatch(arrayRemove("selection", type, index));
	};

	addArtistsFromGallery = (selectionId, type) => {
		this.props.dispatch(addArtistsFromGallery(selectionId, type));
	};

	render() {
		const {
			selection: { selection },
			isAdmin,
			params: { selectionId },
		} = this.props;
		if (!isAdmin) return null;
		let locked = false;
		let lockedMessage = false;
		let description = false;
		let lockSlug = false;
		let multipleImages = false;
		let showEditor = true;
		let showLinks = false;

		const purchasingPortalSelection =
			selection.slug === "inkopsportal-konstnarer" ||
			selection.slug === "inkopsportal-konstverk" ||
			selection.slug === "inkopsportal-artiklar";

		if (selectionId === "new") {
			// Don't show these messages when creating a new selection.
		} else if (!selection._id) {
			// If selection not fetched yet.
		} else if (
			selection.title === "HOME - GENERAL" ||
			selection.title === "HOME - UTSTÄLLNINGAR" ||
			selection.title === "HOME - I BLICKFÅNGET"
		) {
			//Lock all selections which are displayed on the home page
			locked = true;
			lockedMessage =
				"Artworks, artists, and latest added article are displayed on our Home page. Events and two latest shows are displayed on our /shows page.";
		} else if (selection.slug === "home-highlight") {
			lockSlug = true;
			lockedMessage =
				"This is the highlighted item displayed on our Home page.";
		} else if (selection.slug === "home-highlight-artist") {
			lockSlug = true;
			lockedMessage =
				"This is the highlighted artist displayed on our Home page.";
			// description = ['Add an artist. Also add an artwork that will be used as background image.']
			description = ["Add an artist."];
		} else if (selection.slug === "home-highlight-information") {
			lockSlug = true;
			lockedMessage =
				"This is the highlighted information displayed on our Home page.";
		} else if (selection.slug === "home-highlight-gallery") {
			lockSlug = true;
			lockedMessage =
				"This is the highlighted gallery displayed on our Home page.";
		} else if (selection.slug === "home-highlight-categories") {
			lockSlug = true;
			lockedMessage = "Links to artworks categories on our Home page.";
			description = [
				"Mediums in about fields. Images in order matching the mediums.",
			];
			multipleImages = true;
			showEditor = false;
			showLinks = true;
		} else if (selection.slug === "ranking") {
			lockSlug = true;
			lockedMessage = "This is used on webb pages /ranking and /helarankingen.";
		} else if (selection.slug.indexOf("public-purchaser") >= 0) {
			lockSlug = true;
			lockedMessage = "This is used on webb page /inkopsportal";
		} else if (purchasingPortalSelection) {
			lockSlug = true;
		}

		return (
			<Main>
				<SelectionForm
					onSubmit={this.submit}
					isNewForm={selectionId === "new"}
					removeItem={this.removeItem}
					locked={locked}
					lockedMessage={lockedMessage}
					description={description}
					lockSlug={lockSlug}
					slug={selection.slug}
					multipleImages={multipleImages}
					showEditor={showEditor}
					showLinks={showLinks}
					hideTables={selection.slug === "inkopsportal-konstverk"}
				/>
				{!purchasingPortalSelection && (
					<div className="fieldset" style={{ paddingTop: 40 }}>
						<legend>
							Add artists from associated galleries (reload page after)
						</legend>
						<button
							className="selection__purple-button--wide"
							onClick={() => {
								let confirm = window.confirm("Are you sure?");
								if (confirm)
									this.addArtistsFromGallery(selection._id, "education");
							}}
						>
							Education
						</button>
						<button
							className="selection__purple-button--wide"
							onClick={() => {
								let confirm = window.confirm("Are you sure?");
								if (confirm)
									this.addArtistsFromGallery(selection._id, "member");
							}}
						>
							Member
						</button>
					</div>
				)}
				{selection.slug === "inkopsportal-konstverk" && (
					<div
						style={{
							marginTop: "50px",
						}}
					>
						<PublicPurchaserList isAdmin={isAdmin} selection={selection} />
					</div>
				)}
				{!locked && !lockSlug && (
					<div
						className="fieldset"
						style={{ paddingTop: 40, justifyContent: "space-between" }}
					>
						<legend>Danger zone</legend>
						<button
							className="selection__purple-button--wide--mod_danger"
							onClick={() => {
								let confirm = window.confirm("Are you sure?");
								if (confirm) this.handleRemove();
							}}
						>
							Delete selection
						</button>
					</div>
				)}
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		selection,
		auth: { isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("selection")(state),
		submitSucceeded: hasSubmitSucceeded("selection")(state),
		submitFailed: hasSubmitFailed("selection")(state),
		selection: getFormValues("selection")(state),
		initial: getFormInitialValues("selection")(state),
	};
	return { selection, isAdmin, form };
}

export default connect(mapStateToProps)(withRouter(Selection));
