import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
	togglePastShows,
	showMore,
	fetchData,
} from "../../../actions/DashboardActions/PastShowsActions";
import ExpandButton from "../ExpandButton/ExpandButton";
import ItemStats from "../ItemStats/ItemStats";

class PastShows extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true,
		};
	}

	componentWillMount() {
		this.props.dispatch(fetchData());
	}

	expand = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	render() {
		const { expanded, shows, displayCount } = this.props;

		return (
			<div className="PastShows">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(togglePastShows())}
				/>
				<h4 className="underlined">Past shows</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded ? (
							<div className="PastShows__Content">
								{shows.map((show, index) => {
									if (index < displayCount) {
										show.stats = [
											{ title: "Show", value: show.title },
											{ title: "Visits", value: show.visits },
											{ title: "Followers", value: show.likes },
										];
										return (
											<ItemStats
												image={{
													backgroundImage: `url(${show.featuredImage.src})`,
												}}
												stats={show.stats}
												key={`pastShow_${index}`}
											/>
										);
									} else {
										return null;
									}
								})}
								{shows.length > displayCount ? (
									<button
										className="PastShows__Content__LoadMore"
										onClick={() => this.props.dispatch(showMore())}
									>
										Show more
									</button>
								) : null}
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { pastShows } = state;
	const { expanded, shows, displayCount } = pastShows;
	return { expanded, shows, displayCount };
}

export default connect(mapStateToProps)(PastShows);
