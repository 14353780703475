import React from 'react'
import { connect } from 'react-redux'
import Select							          from 'react-select'
import {
	fetchAllArtistsFast,
} from '../../actions/ArtistActions'
import {
	fetchAllGalleriesFast,
} from '../../actions/GalleryActions'

// Selector for artist (for gallery) and gallery (for artist).
class ArtworksListSelector extends React.Component {

  selectOption = (selectedOption, option) => {
    // No selected option = null.
    const currentOptionId = selectedOption ? selectedOption.value : null
    const nextOptionId = option ? option.value : null
    // Don't save if no change.
    if (nextOptionId === currentOptionId) return
		// Can not create new gallery from this list.
		if (nextOptionId === 'createNewArtist' && this.props.openArtistModal) {
			this.props.openArtistModal()
			return
		}

		this._update(nextOptionId)
  }

  _update = (selectedId) => {
    const { isForType } = this.props

    const body = { _id: this.props.artworkId }
    if (isForType === 'artist') {
      body.artistMod = selectedId
    } else if (isForType === 'gallery') {
      body.gallery = selectedId
    }

    this.props.update(body)
  }

	inputChanged = (input) => {
    const { isForType } = this.props
    // Only fetch when exact numbers of letters entered.
    if (input.length === 2 && isForType === 'artist') {
      this.props.dispatch( fetchAllArtistsFast(input) )
    } else if (input.length === 2 && isForType === 'gallery') {
      this.props.dispatch( fetchAllGalleriesFast(input) )
    }
  }

  render() {
    const { options, selectedId, artworkId, isForType, savedOption, isFetching, t } = this.props
    let allOptions = options.slice()
		let selectedOption = options.find(option => option.value === selectedId)
    if (!selectedOption && savedOption) {
      allOptions.unshift(savedOption)
      selectedOption = savedOption
    }
    // Never disabled, fetching from database on change now.
    const dropdownClass = 'dropdown-container'
		// const dropdownClass = `dropdown-container + ${options.length === 0 ? ' is-disabled' : ''}`
    // Loading when typing now. Options always length 0 until typing.
		// const placeholder = options.length === 0 ? this.props.loadingPlaceholder : this.props.placeholder
    const placeholder = this.props.placeholder

    return (
      <div className={ dropdownClass }>
        <Select
          value={ selectedOption }
          options={ allOptions }
          onChange={(nextOption) => this.selectOption(selectedOption, nextOption)}
          onInputChange={ this.inputChanged }
          valueKey={ isForType }
          labelKey="label"
          backspaceToRemoveMessage=""
          backspaceRemoves={ false }
          isLoading={ isFetching }
          placeholder={ placeholder }
          multi={ false }
          autosize={ false }
          className={ 'artist-picker-select' }
          isSearchable={ true }
          />
      </div>
    )
  }
}

export default connect(null)(ArtworksListSelector)
