"use strict";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import {
	MegadraftEditor,
	editorStateFromRaw,
	editorStateToJSON,
	DraftJS,
	createTypeStrategy,
	MegadraftIcons,
} from "megadraft";
import {
	renderHelpers,
	parseHTMLhelper,
	EDITOR_ACTIONS,
	DRAFT_DECORATOR,
	ENTITY_INPUTS,
} from "./editorhelpers";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import ImagePlugin from "./Image";

import styles from "../../../../node_modules/megadraft/dist/css/megadraft.css";

class Editor extends Component {
	static propTypes = {
		onEditorUpdate: PropTypes.func,
		onMarkupUpdate: PropTypes.func,
		onEditorLooseFocus: PropTypes.func,
		editorState: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			editorState: editorStateFromRaw(null, DRAFT_DECORATOR),
		};
	}

	componentDidMount() {
		if (this.props.htmlContent) {
			const editorState = this.formatFromHTML(this.props.htmlContent);
			this.setState({ editorState });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.editorState !== nextProps.editorState) {
			this.setState({
				editorState: editorStateFromRaw(nextProps.editorState, DRAFT_DECORATOR),
			});
		}
	}

	formatFromHTML(htmlContent) {
		const contentState = stateFromHTML(htmlContent, parseHTMLhelper);
		const editorState = DraftJS.EditorState.createWithContent(
			contentState,
			DRAFT_DECORATOR
		);
		return editorState;
	}

	formatToHTML = (editorState) => {
		const currentContent = editorState.getCurrentContent();
		const markup = stateToHTML(currentContent, renderHelpers);
		return markup;
	};

	onBlur = (e) => {
		// if (this.props.onEditorLooseFocus) {
		// console.log('ON BLUR CALLED');
		// const { editorState } = this.state
		// const markup = this.formatToHTML( editorState )
		// const rawEditorState = editorStateToJSON(editorState)
		// this.props.onEditorLooseFocus( markup, rawEditorState )
		// }
	};

	customBlock = (block) => {
		console.log("BLOCK NOT FOUND");
	};

	onChange = (editorState) => {
		// if (this.props.onMarkupUpdate) {
		//   const markup = this.formatToHTML(editorState)
		//   this.props.onMarkupUpdate(markup)
		// }
		const newContent = editorState.getCurrentContent();
		const prevContent = this.state.editorState.getCurrentContent();
		const hasChanged = newContent !== prevContent;

		if (hasChanged) {
			const markup = this.formatToHTML(editorState);
			const rawEditorState = editorStateToJSON(editorState);
			this.props.updateContent(markup, rawEditorState);
		}
		this.setState({
			editorState,
		});
	};

	render() {
		const { editorState } = this.state;
		const { t } = this.props;
		// onMouseLeave so Save button will be activated without having to click outside of editor

		return (
			<div className="editor__container" onMouseLeave={this.onBlur}>
				<MegadraftEditor
					editorState={editorState}
					onBlur={this.onBlur}
					actions={EDITOR_ACTIONS}
					placeholder={t("contentEditor.addContentHere")}
					blockStyleFn={this.blockStyleFn}
					handleBlockNotFound={this.customBlock}
					entityInputs={ENTITY_INPUTS}
					plugins={[ImagePlugin]}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

export default withNamespaces("components")(Editor);
