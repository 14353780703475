import React from 'react'
import i18next from 'i18next'

const formatRanking = (rank, baseURL) => {
  let ordinal = ''
  // Get no ordinal for number 1, only display "largest".
  if (rank > 1) {
    ordinal = i18next.language === 'sv' ? getOrdinalSwedish(rank) : getOrdinalEnglish(rank)
    if (ordinal) ordinal
  }
  console.log('rank', rank);
  console.log('ordinal', ordinal);

  let formatted = i18next.language === 'sv' ?
  <p>{ `Ni är den ${ordinal} största föreningen på vår ` }<a href={ `${baseURL}helarankningen` } target="_blank" style={{ textDecoration: 'underline' }}>rankning</a>!</p> :
  <p>{ `You are the ${ordinal} largest art society on our ` }<a href={ `${baseURL}helarankningen` } target="_blank" style={{ textDecoration: 'underline' }}>ranking</a>!</p>

  return formatted
}

const getOrdinalSwedish = (n) => {
  if (n <= 2) {
    return n + 'a'
  } else {
    return n + 'e'
  }
}

// Taken from https://www.sitepoint.com/convert-numbers-to-ordinals-javascript/
const getOrdinalEnglish = (n) => {
  let ord = 'th'

  if (n % 10 == 1 && n % 100 != 11) {
    ord = 'st'
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = 'nd'
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = 'rd'
  }

  return n + ord
}

//Capitalize the first letter of given string
const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export {
  formatRanking, capitalizeString
}
