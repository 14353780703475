import { CALL_API }     from '../utils/api'
import i18next          from 'i18next'

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

// fetch events

export const EVENTS_REQUEST = 'EVENTS_REQUEST'
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS'
export const EVENTS_FAILURE = 'EVENTS_FAILURE'

// Uses the API to get events
export function fetchEvents() {
  return {
    [CALL_API]: {
      endpoint: 'events/user',
      authenticated: true,
      types: [EVENTS_REQUEST, EVENTS_SUCCESS, EVENTS_FAILURE]
    }
  }
}

export const EVENTS_ALL_REQUEST = 'EVENTS_ALL_REQUEST'
export const EVENTS_ALL_SUCCESS = 'EVENTS_ALL_SUCCESS'
export const EVENTS_ALL_FAILURE = 'EVENTS_ALL_FAILURE'

export function fetchAllEventsFast() {
  return {
    [CALL_API]: {
      endpoint: 'events/fetch-all-fast',
      authenticated: true,
      types: [EVENTS_ALL_REQUEST, EVENTS_ALL_SUCCESS, EVENTS_ALL_FAILURE]
    }
  }
}

export const EVENTS_ALL_POPULATE_REQUEST = 'EVENTS_ALL_POPULATE_REQUEST'
export const EVENTS_ALL_POPULATE_SUCCESS = 'EVENTS_ALL_POPULATE_SUCCESS'
export const EVENTS_ALL_POPULATE_FAILURE = 'EVENTS_ALL_POPULATE_FAILURE'

export function fetchAllEventsPopulated() {
  // Request was too slow, timed out.
  return { type: 'NOT-DOING-ANYTHING' }
  return {
    [CALL_API]: {
      endpoint: 'events/fetch-all-populated',
      authenticated: true,
      types: [EVENTS_ALL_POPULATE_REQUEST, EVENTS_ALL_POPULATE_SUCCESS, EVENTS_ALL_POPULATE_FAILURE]
    }
  }
}

// fetch event

export const EVENT_REQUEST = 'EVENT_REQUEST'
export const EVENT_SUCCESS = 'EVENT_SUCCESS'
export const EVENT_FAILURE = 'EVENT_FAILURE'

// Uses the API to get a event
export function fetchEvent(eventId) {
	const endpoint = eventId ? "events/" + eventId : "events/"
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [EVENT_REQUEST, EVENT_SUCCESS, EVENT_FAILURE]
    }
  }
}

// save event

export const EVENT_SAVE_REQUEST = 'EVENT_SAVE_REQUEST'
export const EVENT_SAVE_SUCCESS = 'EVENT_SAVE_SUCCESS'
export const EVENT_SAVE_FAILURE  = 'EVENT_SAVE_FAILURE'

// Uses the API to get a event
export function saveEvent(event) {
  return {
    [CALL_API]: {
      endpoint: 'events/',
      authenticated: true,
      types: [EVENT_SAVE_REQUEST, EVENT_SAVE_SUCCESS, EVENT_SAVE_FAILURE],
      method: "POST",
      body: event,
      message: translate('eventSaved'),
			analytics: {
				track: 'Saved Event',
				incrementScore: 1
			}
    }
  }
}

export function saveWishListEvent(event) {
  return {
    [CALL_API]: {
      endpoint: 'events/wish-list-event',
      authenticated: true,
      types: [EVENT_SAVE_REQUEST, EVENT_SAVE_SUCCESS, EVENT_SAVE_FAILURE],
      method: "POST",
      body: event,
      message: translate('eventSaved'),
			analytics: {
				track: 'Saved Event',
				incrementScore: 1
			}
    }
  }
}

// save event

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST'
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_UPDATE_FAILURE  = 'EVENT_UPDATE_FAILURE'

// Uses the API to get a event
export function updateEvent(event, noMessage = false) {
  return {
    [CALL_API]: {
      endpoint: 'events/' + event._id,
      authenticated: true,
      types: [EVENT_UPDATE_REQUEST, EVENT_UPDATE_SUCCESS, EVENT_UPDATE_FAILURE],
      method: "PUT",
      message: noMessage ? 'NO MESSAGE' : translate('eventSaved'),
      body: event,
			analytics: {
				track: 'Updated Event',
				payload: {
					event: event._id
				},
				incrementScore: 1
			}
    }
  }
}

export function updateRsvp(eventId, body, noMessage = false) {
  return {
    [CALL_API]: {
      endpoint: 'events/' + eventId + '/updatersvp',
      authenticated: true,
      types: [EVENT_UPDATE_REQUEST, EVENT_UPDATE_SUCCESS, EVENT_UPDATE_FAILURE],
      method: "PUT",
      message: noMessage ? 'NO MESSAGE' : translate('eventSaved'),
      body,
			analytics: {
				track: 'Updated Event',
				payload: {
					event: event._id
				},
				incrementScore: 1
			}
    }
  }
}

export const INFORM_MEMBERS_EVENT_REQUEST = 'eventActions/INFORM_MEMBERS_EVENT_REQUEST'
export const INFORM_MEMBERS_EVENT_SUCCESS = 'eventActions/INFORM_MEMBERS_EVENT_SUCCESS'
export const INFORM_MEMBERS_EVENT_FAILURE = 'eventActions/INFORM_MEMBERS_EVENT_FAILURE'

export function informMembersAboutEvent(eventId, sendToMembers, sendToContacts) {
	return {
		[CALL_API]: {
			endpoint: `events/inform/${eventId}?members=${sendToMembers}&contacts=${sendToContacts}`,
			authenticated: true,
			types: [INFORM_MEMBERS_EVENT_REQUEST, INFORM_MEMBERS_EVENT_SUCCESS, INFORM_MEMBERS_EVENT_FAILURE]
		}
	}
}

export const INFORM_CONTACTS_EVENT_REQUEST = 'showActions/INFORM_CONTACTS_EVENT_REQUEST'
export const INFORM_CONTACTS_EVENT_SUCCESS = 'showActions/INFORM_CONTACTS_EVENT_SUCCESS'
export const INFORM_CONTACTS_EVENT_FAILURE = 'showActions/INFORM_CONTACTS_EVENT_FAILURE'

// Not used anymore.
export function informContactsEvent(eventId, emailLists, message) {
  const messageText = (message && message.text) ? message.text : ''
  const messageSubject = (message && message.subject) ? message.subject : ''
  return {
    [CALL_API]: {
      endpoint: `events/contacts/${eventId}?emails=${emailLists}&messageText=${messageText}&messageSubject=${messageSubject}`,
      authenticated: true,
      types: [INFORM_CONTACTS_EVENT_REQUEST, INFORM_CONTACTS_EVENT_SUCCESS, INFORM_CONTACTS_EVENT_FAILURE]
    }
  }
}

export const EVENT_MAIL_PREVIEW_REQUEST = 'eventActions/EVENT_MAIL_PREVIEW_REQUEST'
export const EVENT_MAIL_PREVIEW_SUCCESS = 'eventActions/EVENT_MAIL_PREVIEW_SUCCESS'
export const EVENT_MAIL_PREVIEW_FAILURE = 'eventActions/EVENT_MAIL_PREVIEW_FAILURE'

export function fetchEventEmailPreview(eventId, message = false) {
  const messageText = (message && message.text) ? message.text : ''
  const messageSubject = (message && message.subject) ? message.subject : ''
	return {
		[CALL_API]: {
			endpoint: `events/preview/${eventId}?messageText=${messageText}&messageSubject=${messageSubject}`,
			authenticated: true,
			types: [EVENT_MAIL_PREVIEW_REQUEST, EVENT_MAIL_PREVIEW_SUCCESS, EVENT_MAIL_PREVIEW_FAILURE]
		}
	}
}

export const EVENT_MAIL_PREVIEW_RESET = 'eventActions/EVENT_MAIL_PREVIEW_RESET'

export function resetEventEmailPreview() {
  return {
    type: EVENT_MAIL_PREVIEW_RESET,
  }
}

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST'
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_DELETE_FAILURE  = 'EVENT_DELETE_FAILURE'

// Uses the API to get a event
export function deleteEvent(event) {
  return {
    [CALL_API]: {
      endpoint: 'events/' + event._id,
      authenticated: true,
      types: [EVENT_DELETE_REQUEST, EVENT_DELETE_SUCCESS, EVENT_DELETE_FAILURE],
      method: "delete",
      analytics: {
        track: 'Deleted Event',
        payload: {
          id: event._id,
          name: event.name
        },
        incrementScore: 1
      }
    }
  }
}

// export const RSVP_REQUEST = 'eventActions/RSVP_REQUEST'
// export const RSVP_SUCCESS = 'eventActions/RSVP_SUCCESS'
// export const RSVP_FAILURE = 'eventActions/RSVP_FAILURE'
//
// export function respondRsvp(eventId, answer, email, name) {
//   const body = {
//     answer,
//     email,
//     name,
//   }
// 	return {
// 		[CALL_API]: {
// 			endpoint: `events/${eventId}/rsvp`,
// 			authenticated: true,
//       method: 'PUT',
//       body,
// 			types: [EVENT_MAIL_PREVIEW_REQUEST, EVENT_MAIL_PREVIEW_SUCCESS, EVENT_MAIL_PREVIEW_FAILURE]
// 		}
// 	}
// }

export const RSVP_MAIL_REQUEST = 'eventActions/RSVP_MAIL_REQUEST'
export const RSVP_MAIL_SUCCESS = 'eventActions/RSVP_MAIL_SUCCESS'
export const RSVP_MAIL_FAILURE = 'eventActions/RSVP_MAIL_FAILURE'
export const RSVP_MAIL_FEE_MISSING_REQUEST = 'eventActions/RSVP_MAIL_FEE_MISSING_REQUEST'
export const RSVP_MAIL_FEE_MISSING_SUCCESS = 'eventActions/RSVP_MAIL_FEE_MISSING_SUCCESS'
export const RSVP_MAIL_FEE_MISSING_FAILURE = 'eventActions/RSVP_MAIL_FEE_MISSING_FAILURE'

export function mailResponders(eventId, sender, subject, emailBody, galleryId, eventTitle, forFeeMissing, senderName) {
  const body = {
    eventId,
    sender,
    senderName,
    subject,
    emailBody,
    galleryId,
    eventTitle,
    forFeeMissing,
  }
  const types = !forFeeMissing
  ? [RSVP_MAIL_REQUEST, RSVP_MAIL_SUCCESS, RSVP_MAIL_FAILURE]
  : [RSVP_MAIL_FEE_MISSING_REQUEST, RSVP_MAIL_FEE_MISSING_SUCCESS, RSVP_MAIL_FEE_MISSING_FAILURE]
	return {
		[CALL_API]: {
			endpoint: 'events/mailresponders',
			authenticated: true,
      method: 'POST',
      body,
			types,
		}
	}
}

export const RESET_EVENT = 'artworksapp/shared/RESET_EVENT'

export function resetEvent() {
  return {
    type: RESET_EVENT,
  }
}
