import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";

class DateRangePicker extends Component {
	handleChangeStart = (startDate) => {
		startDate.set("hour", 10);
		this.props.startDate.input.onChange(startDate);
		this.props.startDate.input.onBlur(startDate);
	};

	handleChangeEnd = (endDate) => {
		endDate.set("hour", 18);
		this.props.endDate.input.onChange(endDate);
		this.props.endDate.input.onBlur(endDate);
	};

	render() {
		const { t } = this.props;
		const startDate = this.props.startDate.input.value
			? this.props.startDate.input.value.toDate()
			: moment().toDate();
		const endDate = this.props.endDate.input.value
			? this.props.endDate.input.value.toDate()
			: moment().toDate();

		const required = this.props.required ? true : false;
		const green = this.props.green ? true : false;

		return (
			<div className="DateRangePicker is-required">
				<div className="input-holder">
					<div
						className={"DateRangePicker__input" + (green ? " is-green" : "")}
					>
						<DatePicker
							placeholderText={t("startDate")}
							dateFormat="yyyy-MM-dd"
							selected={startDate}
							startDate={startDate}
							endDate={endDate}
							onChange={this.handleChangeStart}
						/>
					</div>
				</div>

				<div className="input-holder">
					<div
						className={"DateRangePicker__input" + (green ? " is-green" : "")}
					>
						<DatePicker
							placeholderText={t("endDate")}
							dateFormat="yyy-MM-dd"
							selected={endDate}
							startDate={startDate}
							endDate={endDate}
							onChange={this.handleChangeEnd}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withNamespaces("common")(DateRangePicker);
