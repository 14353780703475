import {
  FETCH_PREVIEW_REQUEST, FETCH_PREVIEW_SUCCESS, FETCH_PREVIEW_FAILURE,
  SEND_CONTACT_EMAIL_REQUEST, SEND_CONTACT_EMAIL_SUCCESS, SEND_CONTACT_EMAIL_FAILURE,
} from '../actions/EmailActions'

export function emailReducer(state = {
  isFetching: false,
  emailPreview: '',
  emailSentTo: '',
  error: null
}, action) {
  switch(action.type) {
    case FETCH_PREVIEW_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null
      })
    }
    case FETCH_PREVIEW_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        emailPreview: action.response
      })
    }
    case FETCH_PREVIEW_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    }
    case SEND_CONTACT_EMAIL_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null
      })
    }
    case SEND_CONTACT_EMAIL_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        emailSentTo: action.response
      })
    }
    case SEND_CONTACT_EMAIL_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    }
    default:
      return state;
  }
}
