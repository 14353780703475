import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import TextInput from "../../components/FormInput/TextInput";
import TitleInput from "../../components/FormInput/TitleInput";
import TextArea from "../../components/FormInput/TextArea";
import TextDisplay from "../../components/FormInput/TextDisplay";
import Tooltip from "../../components/Tooltip/Tooltip";
import Select from "../../components/FormInput/Select";
import CheckBox from "../../components/FormInput/CheckBox";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import CategoryPicker from "../../components/FormComponents/CategoryPicker/CategoryPicker";
import ArtistPicker from "../../components/FormComponents/ArtistPicker/ArtistPicker";
import ArtworkUpload from "../../components/FormComponents/ArtworkUpload/ArtworkUpload";
import ArtworkUploadRotate from "../../components/FormComponents/ArtworkUpload/ArtworkUploadRotate";
import PricePicker from "../../components/FormComponents/PricePicker/PricePicker";
import ShippingPicker from "../../components/FormComponents/ShippingPicker/ShippingPicker";
import { FormSwitchButton } from "../../components/Buttons/SwitchButton/SwitchButton";
import { isUserArtSociety, imageRotationActivated } from "../../utils/Helpers";
import { fetchAccess } from "../../actions/FormActions/AccessFormActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { isSlugAutoGenerated } from "@artworkslab/sharedmodules/src/utils/Helpers";

const PUBLISH_STATUS = [
	{ value: "draft", name: "Draft" },
	{ value: "in_review", name: "In review" },
	{ value: "published", name: "Published" },
];

const VISIBILITY_OPTIONS = [
	{ value: "restricted", name: "Restricted" },
	{ value: "web", name: "Webb only" },
	{ value: "app", name: "App only" },
	{ value: "all", name: "Everywhere" },
	{ value: "notlisted", name: "Not listed" },
];

const VISIBILITY_OPTIONS_SHORT = [
	{ value: "all", name: "Everywhere" },
	{ value: "restricted", name: "VIP" },
];

const VISIBILITY_OPTIONS_SHORT_SV = [
	{ value: "all", name: "Alla" },
	{ value: "restricted", name: "VIP" },
];

const hiddenStyle = {
	height: 0,
	overflow: "hidden",
	opacity: 0,
};

const PURCHASE_TOOLTIP = (t) => {
	const content = (
		<div style={{ width: "200px" }}>
			<p style={{ color: "white" }}>{t("single.purchase_tooltip")}</p>
		</div>
	);
	return {
		text: content,
		popupPos: "right",
	};
};

let ArtworkForm = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		isAdmin,
		isArtist,
		isArtSociety,
		isForRaffle,
		isForRaffleChosen,
		provider = false,
		artworkId,
		canAddAccess,
		currency,
		price,
		vatCode,
		transport,
		infoUpdate,
		header,
		artist = {},
		artistIsProMember,
		artistOrgNr,
		publishStatus,
		stickyFooter,
		t,
		appliedForPublicPurchaser,
		approvedForPublicPurchaser,
		onlyForPublicPurchaser,
	} = props;

	const isNew = !artworkId;

	const editionHeader = provider
		? `Editions from ${provider}`
		: t("single.editions");
	// console.log('props', props);
	// console.log('isForRaffle', isForRaffle);

	let showPriceSection = false;
	let showValueSection = false;

	if (isArtSociety || isAdmin) {
		if (isForRaffleChosen && !isForRaffle) {
			showPriceSection = true;
		} else if (isForRaffleChosen && isForRaffle) {
			showValueSection = true;
		}
	} else {
		showPriceSection = true;
	}

	const useUploadWithRotate = imageRotationActivated();

	{
		/* Same texts in ArtworkFormResponsive.jsx */
	}
	let publicPurchaserStatus =
		i18next.language === "sv"
			? "Detta verk visas för alla användare"
			: "This artwork is displayed to all users";
	if (appliedForPublicPurchaser && !approvedForPublicPurchaser) {
		publicPurchaserStatus =
			i18next.language === "sv"
				? "Du har ansökt om att verket bara ska synas för offentliga inköpare"
				: "You have applied for this artwork to only be displayed to public purchasers";
	} else if (appliedForPublicPurchaser && approvedForPublicPurchaser) {
		publicPurchaserStatus =
			i18next.language === "sv"
				? "Verket är godkänt och syns för offentliga inköpare"
				: "This artwork is approved for display to public purchasers";
	}

	if (onlyForPublicPurchaser) {
		publicPurchaserStatus =
			i18next.language === "sv"
				? "Detta verk syns bara för offentliga inköpare"
				: "This artwork is displayed only to public purchasers";
	}

	// Check for artworkId, public purchaser emails only sent on update.
	const ppButtonsVisible = artworkId && (isAdmin || isArtist);

	return (
		<form onSubmit={handleSubmit} className="Form">
			{header && (
				<div style={{ marginBottom: "20px", paddingLeft: "40px" }}>
					<h3>{header}</h3>
				</div>
			)}

			{/* Apply for public purchaser. Also in ArtworkFormResponsive.jsx */}
			{/* { !stickyFooter && artworkId && ppButtonsVisible && false &&
        <div className="input-holder input-holder--full" style={{
          marginBottom: '1rem',
        }}>
          { !onlyForPublicPurchaser &&
          <Field
            name="forPublicPurchaserRequested"
            offLabel={t('single.forPublicPurchaserRequest')}
            waitingLabel={t('single.forPublicPurchaserAwaitingApproval')}
            onLabel={t('single.forPublicPurchaserApproved')}
            isWaiting={ !approvedForPublicPurchaser }
            disabled={ false }
            component={ FormSwitchButton }
            />
          }
          <p style={{
            width: '100%',
            margin: '0.77rem 0',
          }}>{ onlyForPublicPurchaser ? publicPurchaserStatus : '' }</p>
          { isAdmin &&
            <Field
              name="forPublicPurchaser"
              offLabel={'(Admin) ' + t('single.forPublicPurchaser')}
              disabled={ false }
              component={ FormSwitchButton }
              />
          }
          <div style={{ width: '100%' }}></div>
          { isAdmin &&
            <Field
              name="forPublicPurchaserOnly"
              offLabel={'(Admin) ' + t('single.forPublicPurchaserOnly')}
              disabled={ false }
              component={ FormSwitchButton }
              />
          }
        </div>
      } */}
			{/* End: Apply for public purchaser */}

			{/* (isAdmin || isArtist) && !infoUpdate && */}
			{/* infoUpdate, some values are changed in the list instead of the form. */}
			{/* { (isAdmin || isArtist) && */}
			{/* { (isAdmin || isArtist) && !infoUpdate &&
  			<div>
  				<legend>{t('single.selectGallery')}</legend>
  				<Field
            name="gallery"
            component={ GalleryPicker }
          />
  			</div>
			}

      {/* infoUpdate, some values are changed in the list instead of the form. */}
			<div className="fieldset-block fieldset-block--nopadding">
				<div className="fieldset">
					{/* { !infoUpdate &&
  					<div className="input-holder">
  						<Field
                name="title"
                component={ TextInput }
                placeholder={t('single.title')}
                required
              />
  					</div>
          } */}
					<div className="input-holder">
						{!props.isAutoTitle ? (
							<Field
								name="title"
								component={TextInput}
								placeholder={t("single.title")}
								required
							/>
						) : (
							<Field
								name="title"
								component={TitleInput}
								placeholder={t("single.title")}
								description={t("single.titleDesc")}
								isResponsive={true}
								isAutoTitle={props.isAutoTitle}
								t={t}
								required
							/>
						)}
					</div>
					{(isAdmin || isArtist) && (
						<div className="input-holder" style={{ padding: 0 }}>
							<Field name="gallery" component={GalleryPicker} />
						</div>
					)}

					<div className="input-holder">
						<Field
							name="year"
							component={TextInput}
							placeholder={t("single.year")}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="edition"
							component={TextInput}
							placeholder={t("single.edition")}
							disabled={provider}
						/>
					</div>
				</div>
				{!isArtist && !infoUpdate && (
					<legend>{t("single.searchArtists")}</legend>
				)}
				{/* Do not include only hidden ArtistPicker for artist, form will be not pristine from start. */}
				{!isArtist && (
					<div style={isArtist || infoUpdate ? hiddenStyle : {}}>
						<Field
							name="artistMod"
							component={ArtistPicker}
							multi={false}
							canAdd={!isArtist}
							disabled={isArtist}
						/>
					</div>
				)}
				<div className="fieldset">
					<div className="input-holder input-holder--full">
						<Field
							name="about"
							component={TextArea}
							placeholder={t("single.aboutTheArtwork")}
						/>
					</div>
				</div>
			</div>

			{(isArtSociety || isAdmin) && (
				<div className="for-raffle-radio-buttons input-holder input-holder--full">
					<label>
						<Field
							name="forRaffle"
							component="input"
							type="radio"
							value={true}
							parse={(val) => val === "true"}
							required
						/>
						<p style={{ marginBottom: "0px" }}>{t("single.forRaffle")}</p>
					</label>
					<label>
						<Field
							name="forRaffle"
							component="input"
							type="radio"
							value={false}
							parse={(val) => val === "true"}
							required
						/>
						<p style={{ marginBottom: "0px" }}>{t("single.forSale")}</p>
					</label>
				</div>
			)}

			<div className="fieldset">
				<legend>{t("single.dimensionsAndMaterials")}</legend>
				<div className="input-holder">
					<Field
						name="height"
						component={TextInput}
						placeholder={t("single.height")}
					/>
				</div>
				<div className="input-holder">
					<Field
						name="width"
						component={TextInput}
						placeholder={t("single.width")}
					/>
				</div>
				<div className="input-holder">
					<Field
						name="depth"
						component={TextInput}
						placeholder={t("single.depth")}
					/>
				</div>
				<Field
					name="styleMod"
					type="style"
					component={CategoryPicker}
					placeholder={t("single.selectStyle")}
				/>
				<Field
					name="mediumMod"
					type="medium"
					component={CategoryPicker}
					placeholder={t("single.selectMedium")}
				/>
				<Field
					name="techniqueMod"
					type="technique"
					component={CategoryPicker}
					placeholder={t("single.selectTechnique")}
				/>
				<Field
					name="colorMod"
					type="color"
					component={CategoryPicker}
					placeholder={t("single.selectColor")}
				/>
				<div className="input-holder">
					<Field
						name="showOnWall"
						checkboxLabel={t("single.showOnWall")}
						component={CheckBox}
					/>
				</div>
			</div>

			{showPriceSection && (
				<div className="fieldset">
					<Fields
						names={["priceCurrency", "price", "status", "VATcode"]}
						component={PricePicker}
						selectedCurrency={currency}
						selectedPrice={price}
						selectedVATCode={vatCode}
						isSwedish={i18next.language === "sv"}
						artistIsProMember={artistIsProMember}
						artistOrgNr={artistOrgNr}
					/>
					<div
						className="input-holder input-holder--full"
						style={{ marginTop: "20px" }}
					>
						<Field
							name="directPurchase"
							checkboxLabel={t("single.purchase")}
							component={CheckBox}
						/>
						<Tooltip data={PURCHASE_TOOLTIP(t)} extraClass="even-more-left" />
					</div>
					{isAdmin && (
						<div className="input-holder">
							<legend className="legend-nomargin">
								{t("single.framePrice")}
							</legend>
							<Field
								name="framePrice"
								component={TextInput}
								placeholder={currency}
							/>
						</div>
					)}
					<Fields
						names={[
							"shipping.location",
							"shipping.transport",
							"shipping.customPrice",
							"shipping.swePrice",
							"shipping.euPrice",
							"shipping.globalPrice",
						]}
						component={ShippingPicker}
						currency={currency}
						transport={transport}
						isSwedish={i18next.language === "sv"}
					/>
				</div>
			)}
			{showValueSection && (
				<div className="fieldset">
					<legend>{t("single.valueForRaffle")}</legend>
					<div className="input-holder input-holder--full">
						<Field name="valueForRaffle" component={TextInput} />
					</div>
				</div>
			)}

			<div className="fieldset">
				<legend>{t("single.detailsUpload")}</legend>
				<Fields
					names={[
						"images",
						"uploads",
						"uploadedImage.URI",
						"uploadedImage.fileName",
						"uploadedImage.contentType",
					]}
					component={useUploadWithRotate ? ArtworkUploadRotate : ArtworkUpload}
					multi={true}
					sortable={true}
					required
				/>
			</div>

			{isAdmin ? (
				<div className="fieldset">
					<legend>Extra (admin only)</legend>
					{/* <div className="input-holder input-holder--full">
						<Field
              name="publish_status"
              component={ Select }
              options={ PUBLISH_STATUS }
            />
					</div> */}
					<legend>Editor rating</legend>
					<div className="input-holder">
						<Field
							name="editorRating"
							component={TextInput}
							placeholder="Editor rating(1-10)"
						/>
					</div>
				</div>
			) : null}

			{isAdmin && (
				<div className="fieldset">
					<legend>
						{i18next.language === "sv" ? "Synlighet" : "Visibility"}
					</legend>
					<div
						className="input-holder input-holder--full"
						style={{ marginBottom: "20px" }}
					>
						{i18next.language === "sv" ? (
							<p>
								(Admin) Välj här om konstverket ska kunna ses av vem som helst
								eller om du vill att det ska visas som förhandsvisning (då krävs
								det att man behöver godkännas av dig för att kunna se verket).
							</p>
						) : (
							<p>
								(Admin) Choose if the uploaded artwork should have any
								restrictions for users to view.
							</p>
						)}
					</div>
					<div className="input-holder">
						<Field
							name="visibility"
							component={Select}
							options={
								isAdmin
									? VISIBILITY_OPTIONS
									: i18next.language === "sv"
									? VISIBILITY_OPTIONS_SHORT_SV
									: VISIBILITY_OPTIONS_SHORT
							}
						/>
					</div>
					{canAddAccess && (
						<div className="input-holder">
							<button
								type="button"
								onClick={(e) => {
									e.preventDefault();
									props.openAccess(artworkId);
								}}
							>
								Handle access
							</button>
						</div>
					)}
				</div>
			)}
			<div className="fieldset">
				{/* { artworkId &&
          <div className="input-holder">
            <Field
              name="slug"
              itemType="artwork"
              component={ TextDisplay }
              isAdmin={ isAdmin }
            />
          </div>
        } */}

				{/* {(isAdmin || isArtist) && (__DEVELOPMENT__ || __STAGING__) &&
          <div className="input-holder input-holder--full" style={{
            margin: '1rem 0',
          }}>
            <Field
              name="publish_status"
              offLabel={t('single.draft')}
              onLabel={t('single.published')}
              isWaiting={false}
              disabled={false}
              checkedValue="published"
              uncheckedValue="draft"
              component={FormSwitchButton}
            />
          </div>
        } */}

				{/* { !stickyFooter && false &&
          <div className="fixed-save-button fixed-save-button--artwork">
            <button className="purple-button--wide" type="submit" disabled={ pristine || submitting }>{t('common:save')}</button>
          </div>
        } */}
			</div>

			<div className={"artwork-sticky-footer" + (isAdmin ? " is-admin" : "")}>
				{ppButtonsVisible && isAdmin && (
					<div
						className="artwork-footer-flexed-content"
						style={{
							margin: "1rem 0 0 0",
						}}
					>
						<div
							className="artwork-footer-flexed-content"
							style={{
								margin: ".5rem 0 1rem 0",
							}}
						>
							<Field
								name="forPublicPurchaser"
								offLabel={"(Admin) " + t("single.forPublicPurchaser")}
								disabled={false}
								component={FormSwitchButton}
								styling={{ paddingRight: "20px" }}
							/>
							<Field
								name="forPublicPurchaserOnly"
								offLabel={"(Admin) " + t("single.forPublicPurchaserOnly")}
								disabled={false}
								component={FormSwitchButton}
							/>
						</div>
					</div>
				)}
				<div
					className="artwork-footer-flexed-content"
					style={{
						marginBottom: "0rem",
					}}
				>
					{artworkId && (
						<Field
							name="slug"
							itemType="artwork"
							component={TextDisplay}
							isAdmin={isAdmin}
							styling={
								isAdmin
									? {}
									: {
											width: "auto",
											paddingRight: "50px",
											marginBottom: 0,
									  }
							}
						/>
					)}
					<Field
						name="publish_status"
						offLabel={t("single.draft")}
						onLabel={t("single.published")}
						isWaiting={false}
						disabled={false}
						checkedValue="published"
						uncheckedValue="draft"
						component={FormSwitchButton}
						styling={{
							minWidth: "155px",
						}}
					/>
					{ppButtonsVisible && !onlyForPublicPurchaser && (
						<Field
							name="forPublicPurchaserRequested"
							offLabel={t("single.forPublicPurchaserRequest")}
							waitingLabel={t("single.forPublicPurchaserAwaitingApproval")}
							onLabel={t("single.forPublicPurchaserApproved")}
							isWaiting={!approvedForPublicPurchaser}
							disabled={false}
							component={FormSwitchButton}
						/>
					)}
					{ppButtonsVisible && onlyForPublicPurchaser && (
						<p
							style={{
								margin: "0.25rem 0",
							}}
						>
							{publicPurchaserStatus}
						</p>
					)}
					<div
						className="round-save-button"
						style={{
							// position: 'absolute',
							// top: '10px',
							// right: '40px',
							flexGrow: 1,
							textAlign: "right",
						}}
					>
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

ArtworkForm = reduxForm({
	form: "artwork",
})(ArtworkForm);

const selector = formValueSelector("artwork");
ArtworkForm = connect(
	(state) => {
		const isArtSociety = isUserArtSociety(state.auth.user);
		let isForRaffle = false;
		let isForRaffleChosen = false;
		if (state.form.artwork && state.form.artwork.values) {
			isForRaffle = !!state.form.artwork.values.forRaffle;
			isForRaffleChosen =
				typeof state.form.artwork.values.forRaffle !== "undefined";
		}

		const artworkId = selector(state, "_id");
		const restrictedAccess = selector(state, "visibility") === "restricted";
		const canAddAccess = artworkId && restrictedAccess;
		const publishStatus = selector(state, "publish_status");

		const currency = selector(state, "priceCurrency");
		const price = selector(state, "price");
		const vatCode = selector(state, "VATcode");
		const transport = selector(state, "shipping.transport");

		const artist = selector(state, "artistMod");
		const artistIsProMember = isPremium(artist);
		const artistOrgNr = artist && artist.orgNumber ? artist.orgNumber : null;

		const title = selector(state, "title");
		const isAutoTitle = isSlugAutoGenerated(title);

		const appliedForPublicPurchaser = selector(
			state,
			"forPublicPurchaserRequested"
		);
		const approvedForPublicPurchaser = selector(state, "forPublicPurchaser");
		const onlyForPublicPurchaser = selector(state, "forPublicPurchaserOnly");

		return {
			isArtSociety,
			isForRaffle,
			isForRaffleChosen,
			artworkId,
			canAddAccess,
			isAutoTitle,
			currency,
			price,
			vatCode,
			transport,
			artistIsProMember,
			artistOrgNr,
			appliedForPublicPurchaser,
			approvedForPublicPurchaser,
			onlyForPublicPurchaser,
			publishStatus,
		};
	},
	(dispatch) => {
		return {
			openAccess: (artworkId) => {
				dispatch(fetchAccess(artworkId, "artworks"));
			},
		};
	}
)(ArtworkForm);

export default withNamespaces("artworks")(ArtworkForm);
