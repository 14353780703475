import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import { fetchEmailHistory, fetchEmail } from "../../actions/ArchiveActions";
import { dateMonthShort } from "@artworkslab/sharedmodules/src/DateUtil";

const CLOSE = require("../../../assets/images/icons/close.white@2x.png");
const VIEW = require("../../../assets/images/svg/share_22px.svg");
const RELOAD = require("../../../assets/images/svg/redo-alt.svg");

class EmailHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sectionOpen: false,
			openEmailKey: "",
		};
	}

	componentDidMount() {
		const { isArtist, isSociety, isAdmin } = this.props;
		this.fetchData(this.props);
		if (isArtist || isSociety || isAdmin) {
			this.setState({
				sectionOpen: true,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isAdmin && this.props.gallery._id !== nextProps.gallery._id) {
			this.fetchData(nextProps);
		}
	}

	fetchData(props) {
		const { isAdmin, user, gallery } = props;

		if (isAdmin && gallery) {
			this.props.dispatch(fetchEmailHistory(gallery._id || gallery));
		} else if (user.gallery) {
			this.props.dispatch(fetchEmailHistory(user.gallery._id || user.gallery));
		} else if (user.artist) {
			this.props.dispatch(fetchEmailHistory(user.id));
		}
	}

	showFullEmail = (key) => {
		if (!this.props.emailsWithHTML[key]) {
			this.props.dispatch(fetchEmail(key));
		}

		this.setState({ openEmailKey: key });
	};

	hideFullEmail = () => {
		this.setState({ openEmailKey: "" });
	};

	toggleSectionOpen = () => {
		this.setState({
			sectionOpen: !this.state.sectionOpen,
		});
	};

	createMarkup = (html) => {
		return { __html: html };
	};

	getFileName = (key) => {
		const nameRegex = RegExp(/(\/.{24})-/, "g");
		// Find the last index of /[24 figures]- , what comes after is the filename.
		let arr;
		let lastIndex = 0;
		while ((arr = nameRegex.exec(key)) !== null) {
			// console.log(`Found ${arr[0]}. Next starts at ${nameRegex.lastIndex}.`)
			lastIndex = nameRegex.lastIndex;
		}
		const fileName = key.substring(lastIndex);

		return fileName;
	};

	createRows = () => {
		const { emailHistory, emailsWithHTML, isFetching, t } = this.props;
		const rows = [];

		emailHistory.forEach((email) => {
			const fetchedHTML = emailsWithHTML[email.key];
			const fileName = this.getFileName(email.key);
			const receivers = email.metadata.receivers
				? decodeURI(email.metadata.receivers)
				: "";
			const receiversCount = email.metadata.receiverscount || "-";

			rows.push(
				<Tr key={email.key}>
					<Td column="subject" value={fileName}>
						{fileName}
					</Td>
					<Td column="date" value={email.date}>
						{dateMonthShort(email.date, "sv", true, true)}
					</Td>
					<Td column="receivers" value={receivers}>
						{receivers}
					</Td>
					<Td column="receiversCount" value={receiversCount}>
						{receiversCount}
					</Td>
					<Td column="open" value="open">
						<div onClick={() => this.showFullEmail(email.key)}>
							<VIEW className="email-history__show-full-email" />
						</div>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	createMarkup = (html) => {
		return { __html: html };
	};

	render() {
		const {
			isAdmin,
			isArtist,
			gallery,
			emailHistory,
			emailsWithHTML,
			premium,
			t,
		} = this.props;
		const { sectionOpen, openEmailKey } = this.state;

		if (!premium && !isAdmin && !isArtist) {
			return (
				<div className="email-history">
					<legend className="email-history__history-opener">
						{t("history")}
					</legend>
				</div>
			);
		}

		const rows = this.createRows();
		const openEmail = emailsWithHTML[openEmailKey] || "";
		const isHTML = openEmail.indexOf("<!DOCTYPE html") >= 0;
		const markup = openEmail && isHTML ? this.createMarkup(openEmail) : "";

		const metaData = openEmailKey
			? emailHistory.find((file) => file.key === openEmailKey).metadata
			: [];
		let attachments = metaData.attachments || "[]";
		attachments = decodeURIComponent(attachments);
		attachments = JSON.parse(attachments);

		return (
			<div className={"email-history" + (isArtist ? " is-artist" : "")}>
				<legend
					className={
						"email-history__history-opener" + (isArtist ? " is-artist" : "")
					}
				>
					{t("history")}
					{!isArtist && (
						<RELOAD
							className="reload-button"
							onClick={() => this.fetchData(this.props)}
						/>
					)}
					{!isArtist && (
						<ExpandButton
							expanded={sectionOpen}
							click={this.toggleSectionOpen}
							style={{ top: 0 }}
						/>
					)}
				</legend>
				<div
					className={
						"email-history__history-section" + (sectionOpen ? " is-open" : "")
					}
				>
					<div className="email-history__email-table">
						<Table
							className="reactable"
							sortable={["subject", "date", "receivers"]}
							defaultSort={{ column: "date", direction: "desc" }}
							filterable={!isArtist ? ["subject", "date", "receivers"] : null}
							filterPlaceholder={"Sök bland mejl"}
							itemsPerPage={emailHistory.length >= 30 ? 20 : false}
							noDataText="Inget att visa"
						>
							<Thead>
								<Th column="subject">Ämne</Th>
								<Th column="date">Datum</Th>
								{!isArtist && <Th column="receivers">Mottagare</Th>}
								<Th column="receiversCount">Antal mottagare</Th>
								<Th column="open"> </Th>
							</Thead>
							{rows}
						</Table>
					</div>
				</div>
				{openEmail && (
					<div
						className="email-history__inspect-email"
						onClick={this.hideFullEmail}
					>
						<img
							src={CLOSE}
							className="email-history__inspect-email__close"
							onClick={this.hideFullEmail}
						/>
						<div
							className={
								"email-history__inspect-email__content" +
								(isHTML ? " is-html" : "")
							}
						>
							{isHTML ? (
								<div
									className="email-history__inspect-email__content__html"
									dangerouslySetInnerHTML={markup}
								></div>
							) : (
								<div className="email-history__inspect-email__content__text">
									<h4>Meddelande:</h4>
									<p>{decodeURIComponent(openEmail)}</p>
									<h4 style={{ marginTop: "25px" }}>Bifogade filer:</h4>
									{attachments.map((attachment, index) => {
										return <p key={index}>{attachment}</p>;
									})}
									{attachments.length === 0 && <p>Inga</p>}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		archive: { emailHistory, emailsWithHTML, isFetching, error },
		gallery: { gallery: userGallery, allGalleries },
		registry: { mailSent, error: registryError },
	} = state;
	const isAdmin = user.role === "admin";
	const isArtist = user.role === "artist";
	const isSociety = user.role === "society";

	return {
		user,
		isAdmin,
		isArtist,
		isSociety,
		emailHistory,
		emailsWithHTML,
		isFetching,
		error,
		userGallery,
		allGalleries,
		mailSent,
		registryError,
	};
};

export default connect(mapStateToProps)(EmailHistory);
