import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	fetchCategories,
	saveCategory,
	deleteCategory,
} from "../../actions/CategoryActions";
import Main from "../../components/Main/Main";
import CreateCategory from "./CreateCategory";
import EditCategory from "./EditCategory";
import Modal from "../../components/Modal/Modal";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";

class Categories extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: true,
			showRemoveModal: false,
			categoryToRemove: null,
			categoryToEdit: null,
			showAddCategory: false,
			showEditCategory: false,
		};
	}
	componentWillMount() {
		this.props.dispatch(fetchCategories());
	}

	componentWillReceiveProps(newProps, nextState) {
		if (!newProps.category.isFetching) {
			this.setState({
				canSubmit: true,
			});
		}
		if (
			this.props.category.deletedCategory !==
				newProps.category.deletedCategory &&
			newProps.category.deletedCategory
		) {
			this.props.dispatch(fetchCategories());
		}
		// if (newProps.category.deletedCategory) {
		// 	this.props.dispatch( fetchCategories() )
		// }
	}

	onSubmit = (model) => {
		this.props.dispatch(saveCategory(model));
		this.setState({
			canSubmit: false,
			showAddCategory: false,
			showEditCategory: false,
		});
	};

	toggleAddCategory = () => {
		this.setState({
			showAddCategory: !this.state.showAddCategory,
		});
	};

	toggleEditCategory = (category) => {
		this.setState({
			showEditCategory: !this.state.showEditCategory,
			categoryToEdit: category,
		});
	};

	shouldRemove = (category) => {
		this.setState({
			showRemoveModal: true,
			categoryToRemove: category,
		});
	};

	remove = () => {
		let category = this.state.categoryToRemove;
		this.props.dispatch(deleteCategory(category));
		this.setState({
			categoryToRemove: null,
			showRemoveModal: false,
		});
	};

	toggleRemoveModal = () => {
		this.setState({
			showRemoveModal: false,
		});
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	renderRows = () => {
		let rows = [];
		Object.keys(this.props.category.categories).forEach((key) => {
			let category = this.props.category.categories[key];
			rows.push(
				<Tr key={category._id}>
					{category.imgLink && (
						<Td column="img">
							<Link to={"/categories/" + category._id}>
								<img
									src={category.imgLink}
									className="table-image"
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
									alt=""
								/>
							</Link>
						</Td>
					)}
					<Td column="type" value={category.type}>
						<Link to={"/categories/" + category._id}>{category.type}</Link>
					</Td>
					<Td column="text" value={category.text}>
						<Link to={"/categories/" + category._id}>{category.text}</Link>
					</Td>
					<Td column="hexColor" value={category.hexColor}>
						<Link to={"/categories/" + category._id}>{category.hexColor}</Link>
					</Td>
					<Td column="edit">
						<button
							className="purple-button--wide button-nomargin"
							onClick={() => {
								this.toggleEditCategory(category);
							}}
						>
							Edit
						</button>
					</Td>
					<Td column="remove">
						<button
							className="purple-button--wide button-nomargin"
							onClick={() => {
								this.shouldRemove(category);
							}}
						>
							Remove
						</button>
					</Td>
				</Tr>
			);
		});
		return rows;
	};

	render() {
		const { user } = this.props;
		const admin = user.role === "admin";
		if (!admin) return false;

		let rows = this.renderRows();
		let showRemoveModal = this.state.showRemoveModal;

		let canSubmit = this.state.canSubmit ? this.state.canSubmit : false;
		let showAddCategory = this.state.showAddCategory;
		let showEditCategory = this.state.showEditCategory;
		return (
			<div>
				<Main>
					<div className="MainTop">
						<h1>Categories</h1>
						<button
							className="purple-button--wide button-nomargin"
							onClick={this.toggleAddCategory}
						>
							Add
						</button>
					</div>
					{showAddCategory ? (
						<CreateCategory onSubmit={this.onSubmit} canSubmit={canSubmit} />
					) : null}
					<Table
						className="reactable"
						sortable={["type", "text", "hexColor"]}
						filterable={["type", "text", "hexColor"]}
					>
						<Thead>
							<Th column="img" style={{ width: 80 }} />
							<Th column="type" style={{ width: 200 }}>
								Type
							</Th>
							<Th column="text" style={{ width: 200 }}>
								Text
							</Th>
							<Th column="hexColor" style={{ width: 200 }}>
								Hex color
							</Th>
							<Th column="edit" style={{ width: 80 }}>
								Edit
							</Th>
							<Th column="remove" style={{ width: 80 }}>
								Remove
							</Th>
						</Thead>
						{rows}
					</Table>
				</Main>
				{showEditCategory ? (
					<Modal onCloseClick={this.toggleEditCategory}>
						<div style={{ height: 49 }}>Edit category text</div>
						<EditCategory
							onSubmit={this.onSubmit}
							canSubmit={canSubmit}
							category={this.state.categoryToEdit}
						/>
					</Modal>
				) : null}
				{showRemoveModal ? (
					<Modal onCloseClick={this.toggleRemoveModal}>
						<div style={{ height: 150 }}>Removing category - are you sure?</div>
						<button onClick={this.remove}>Remove category</button>
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		category,
	} = state;
	return { user, category };
}

export default connect(mapStateToProps)(Categories);
