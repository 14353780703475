import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import {
	// fetchAllArtists,
	fetchArtists,
	fetchAllArtistsFast,
	fetchAllArtistsPopulated,
	fetchProIncrease,
} from "../../actions/ArtistActions";
import { getObjectProp } from "../../utils/Helpers";
import Main from "../../components/Main/Main";
import moment from "moment";
import Modal from "../../components/Modal/Modal";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const MEMBERSHIPS = {
	free: "Bas",
	premium: "Pro",
};

class Artists extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentWillMount() {
		const { user, isAdmin, isAuthor, artists, deletedArtist } = this.props;
		if (isAdmin) {
			this.getArtistsForAdmin(artists, deletedArtist);
		} else if (isAuthor) {
			this.getArtists(user);
		}
	}

	componentWillReceiveProps(newProps, nextState) {
		if (!this.props.isAuthor && newProps.isAuthor && newProps.user) {
			this.getArtists(newProps.user);
		}
	}

	getArtists = (user) => {
		const galleryId = user.gallery._id || user.gallery;
		const repQuery = {
			"represented_by.gallery": { $in: [galleryId] },
		};
		this.props.dispatch(fetchArtists(repQuery));
	};

	getArtistsForAdmin = (artists, deletedArtist) => {
		// Artists for admin here is allArtists from state. If already fetched, only update.
		if (Object.keys(artists).length === 0 || deletedArtist) {
			this.props.dispatch(fetchAllArtistsFast()).then((result) => {
				this.props.dispatch(fetchAllArtistsPopulated());
			});
		} else {
			this.props.dispatch(fetchAllArtistsPopulated());
		}
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	getProIncrease = () => {
		this.props.dispatch(fetchProIncrease());
	};

	proIncreaseRows = () => {
		const { prosIncrease } = this.props;
		let rows = [];
		Object.keys(prosIncrease).map((key) => {
			const proData = prosIncrease[key];
			const date = proData.premiumDate
				? DateUtil.dateMonthShort(
						proData.premiumDate,
						i18next.language,
						true,
						false
				  )
				: "Undefined";
			const image = proData.featuredImage || "";
			const thumbnailSrc = image ? this.getImageSrc(image) : "";

			rows.push(
				<Tr key={proData._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{proData.featuredImage ? (
							<Link to={`/artists/${proData.slug || proData._id}`}>
								<img
									src={thumbnailSrc}
									className="table-image"
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
									alt="Artist image"
								/>
							</Link>
						) : null}
					</Td>
					<Td column="name" value={proData.name}>
						<Link to={`/artists/${proData.slug || proData._id}`}>
							{proData.name}
						</Link>
					</Td>
					<Td column="visits" value={proData.visits}>
						<Link to={`/artists/${proData.slug || proData._id}`}>
							{proData.visits}
						</Link>
					</Td>
					<Td column="likes" value={proData.likes}>
						<Link to={`/artists/${proData.slug || proData._id}`}>
							{proData.likes}
						</Link>
					</Td>
					<Td column="date" value={date}>
						<Link to={`/artists/${proData.slug || proData._id}`}>{date}</Link>
					</Td>
				</Tr>
			);
		});
		return rows;
	};

	drawProIncrease = () => {
		const { t } = this.props;
		return (
			<Table
				className="reactable"
				sortable={["name", "visits", "likes", "date"]}
				defaultSort={"name"}
				defaultSortDescending={false}
				itemsPerPage={20}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="image"> </Th>
					<Th column="name">{t("proStats.name")}</Th>
					<Th column="visits">{t("proStats.visits")}</Th>
					<Th column="likes">{t("proStats.likes")}</Th>
					<Th column="date">{t("proStats.date")}</Th>
				</Thead>
				{this.proIncreaseRows()}
			</Table>
		);
	};

	render() {
		const { isAdmin, isAuthor, artists, prosIncrease, t } = this.props;
		let rows = [];
		Object.keys(artists).forEach((key) => {
			const artist = artists[key];
			const image = artist.featuredImage || "";
			const thumbnailSrc = image ? this.getImageSrc(image) : "";
			// const repGallery = (artist.represented_by && artist.represented_by.length > 0) ? getObjectProp(artist.represented_by[0], 'gallery') : false
			// let representedBy = ''
			// if (repGallery && isAdmin) {
			// 	try {
			// 		representedBy = gallery.galleries[repGallery].name
			// 	} catch (e) {
			// 		console.log('e', e);
			// 	}
			// }
			const representedBy =
				artist.represented_by &&
				artist.represented_by.length > 0 &&
				artist.represented_by[0].gallery
					? artist.represented_by[0].gallery
					: false;
			const membershipLevel =
				artist.billing && artist.billing.membershipLevel === "premium"
					? MEMBERSHIPS.premium
					: MEMBERSHIPS.free;
			rows.push(
				<Tr key={artist._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{artist.featuredImage ? (
							<Link to={`/artists/${artist.slug || artist._id}`}>
								<img
									src={thumbnailSrc}
									className="table-image"
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
									alt="Artist image"
								/>
							</Link>
						) : null}
					</Td>
					<Td column="name" value={artist.name}>
						<Link to={`/artists/${artist.slug || artist._id}`}>
							{artist.name}
						</Link>
					</Td>
					{isAdmin && (
						<Td column="membershipLevel" value={membershipLevel}>
							<Link to={`/artists/${artist.slug || artist._id}`}>
								{membershipLevel}
							</Link>
						</Td>
					)}
					{isAdmin && (
						<Td column="represented_by" value={representedBy.name}>
							<Link to={`/galleries/${representedBy.slug}`}>
								{representedBy.name}
							</Link>
						</Td>
					)}
					<Td column="created" value={artist.created_at}>
						<Link to={`/artists/${artist.slug || artist._id}`}>
							{DateUtil.dateMonthShort(
								artist.created_at,
								i18next.language,
								true,
								false
							)}
						</Link>
					</Td>
					<Td column="status" value={artist.status}>
						<Link to={`/artists/${artist.slug || artist._id}`}>
							{t(`common:status.${artist.status}`)}
						</Link>
					</Td>
				</Tr>
			);
		});

		let proTable = null;
		if (isAdmin && prosIncrease.length > 0) {
			proTable = this.drawProIncrease();
		}

		return (
			<div>
				<Main>
					<div style={{ marginBottom: "40px" }}>
						<Link
							to="/artists/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							{t("list.createNewArtist")}
						</Link>
					</div>
					{isAdmin && (
						<div style={{ marginBottom: "40px" }}>
							<button
								className="button-nomargin purple-button--wide"
								style={{ marginBottom: "40px" }}
								onClick={() => this.getProIncrease()}
							>
								{t("proStats.button")}
							</button>
							{proTable}
						</div>
					)}
					{isAdmin ? (
						<Table
							className="reactable"
							sortable={[
								"name",
								"membershipLevel",
								"represented_by",
								"status",
								"created",
							]}
							filterable={["name", "represented_by"]}
							itemsPerPage={100}
						>
							<Thead>
								<Th column="image"> </Th>
								<Th column="name">{t("list.name")}</Th>
								<Th column="membershipLevel">{t("list.membershipLevel")}</Th>
								<Th column="represented_by">{t("list.representedBy")}</Th>
								<Th column="created">{t("list.createdAt")}</Th>
								<Th column="status">{t("list.status")}</Th>
							</Thead>
							{rows}
						</Table>
					) : (
						<Table
							className="reactable"
							sortable={["name", "status", "created"]}
							filterable={["name"]}
							itemsPerPage={100}
						>
							<Thead>
								<Th column="image"> </Th>
								<Th column="name">{t("list.name")}</Th>
								<Th column="created">{t("list.createdAt")}</Th>
								<Th column="status">{t("list.status")}</Th>
							</Thead>
							{rows}
						</Table>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artist: {
			artists: selectedArtists,
			allArtists,
			deletedArtist,
			prosIncrease,
		},
		auth: { user },
	} = state;
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const isAuthor = user.role === "author";
	const artists = isAdmin ? allArtists : selectedArtists;
	return { artists, user, isAdmin, isAuthor, deletedArtist, prosIncrease };
}

const translated = withNamespaces("artist")(Artists);
export default connect(mapStateToProps)(translated);
