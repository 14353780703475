import React, { Component } from "react";

const Modal = ({
	children,
	title = false,
	type = "default",
	className = "",
	onCloseClick = () => {},
	styling = {},
}) => {
	const responsive = className === "responsive";
	const respModal = className === "responsive-modal";
	return (
		<div className={"Modal " + className + " Modal--" + type} style={styling}>
			<div
				className="Modal__background"
				onClick={respModal ? (e) => onCloseClick(e) : null}
			></div>
			<div className="Modal__wrapper">
				{!responsive && (
					<div className="Modal__close" onClick={(e) => onCloseClick(e)} />
				)}
				<div className="Modal__content">
					{title ? (
						<div className="Modal__header">
							<h1 className="Modal__title">{title}</h1>
						</div>
					) : null}
					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
