import React, { Component } from "react";
import { connect }          from 'react-redux'
import Formsy               from "formsy-react"
import Input                from "../../components/Input/Input"
import { saveCategory }     from "../../actions/CategoryActions"
import { withRouter }       from 'react-router'
import Select               from "../../components/Select/Select"

const SAMPLE_LINK = "https://artworks-app-images-original.s3-eu-west-1.amazonaws.com/uploads/original/5937b9310b682a301f18eeeb/594bbea363665d7216165b03-Carl_Johan_De_Geer_p%C3%A5_Stockholms_Kulturfestival_-_2.JPG"

class CreateCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false
		}
	}
	enableSubmit = () => {
		this.setState({
			canSubmit: true
		})
	}
	disableSubmit = () => {
		this.setState({
			canSubmit: false
		})
	}
	submit = (model) => {
		if (model.value !== null) {
			this.props.onSubmit(model)
		}
	}
	componentWillReceiveProps(newProps) {
		// redirect to new page
	}
	render() {
		return (
			<div style={{paddingBottom: '20px'}}>
				<Formsy.Form onValidSubmit={this.submit} onValid={this.enableSubmit} onInvalid={this.disableSubmit}>
					<Select name="type" options={[
						{value: null, name: "Choose type of category"},
						{value:"post", name: "Post"},
						{value:"style", name: "Style"},
						{value:"medium", name: "Medium"},
						{value:"technique", name: "Technique"},
						{value:"show", name: "Show"},
						{value:"event", name: "Event"},
						{value:"color", name: "Color"}
					]} required/>
					<Input name="text" placeholder="Category text" required />
					<Input name="hexColor" placeholder="#ffffff" />
					<Input name="imgLink" placeholder={SAMPLE_LINK} />
					{/*Save button*/}
	        <div className="fieldset">
	          <div className="fixed-save-button">
	            <button className="purple-button--wide button-nomargin" type="submit" disabled={!this.props.canSubmit}>Create category</button>
	          </div>
	        </div>
				</Formsy.Form>
			</div>
		);
	}
}

export default CreateCategory
