import React, { Component } from "react";
import { withRouter, Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Main from "../../components/Main/Main";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import { fetchPrints, fetchPrintArtists } from "../../actions/PrintActions";
import {
	submitPrint,
	deletePrint,
} from "../../actions/FormActions/PrintFormActions";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import PrintForm from "./PrintForm";

class Print extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	componentWillMount() {
		this.fetchData(this.props);
	}

	componentWillReceiveProps(nextProps) {
		const { print } = nextProps;

		if (nextProps.deletedPrint) {
			return this.props.router.push("/prints");
		}

		if (!this.state.initialized && nextProps.print && nextProps.print._id) {
			this.setState(
				{
					initialized: true,
				},
				() => {
					this.props.dispatch(initialize("print", print));
				}
			);
		}
		// push or replace?
		// this.props.router.push(`/prints/${print.slug}`)
	}

	fetchData = (props) => {
		const printId = props.params.printId;
		this.setState(
			{
				initialized: false,
			},
			() => {
				this.props.dispatch(fetchPrints(printId));
				this.props.dispatch(fetchPrintArtists());
			}
		);
	};

	handleSubmit = () => {
		const {
			form: { print, initial },
		} = this.props;
		this.props.dispatch(submitPrint(print, initial));
	};

	handleRemove = () => {
		const print = this.props.form.print;
		this.props.dispatch(deletePrint(print));
	};

	render() {
		const { isAdmin, print, prints, t } = this.props;
		const printId = this.props.params.printId;

		if (!isAdmin) return null;

		return (
			<Main>
				<div className="prints">
					<div style={{ marginBottom: "30px" }}>
						<Link
							to="/prints"
							className="purple-button--wide purple-button--wide--mod_create"
							style={{ minWidth: "180px" }}
						>
							{t("list.stepBack")}
						</Link>
					</div>
					<PrintForm
						onSubmit={this.handleSubmit}
						print={print}
						printArtists={this.props.printArtists}
						isFetching={this.props.isFetching}
						t={t}
					/>
					<div className="fieldset">
						<legend style={{ paddingTop: 40 }}>{t("common:dangerZone")}</legend>
						<button
							type="button"
							className="purple-button--wide--mod_danger"
							onClick={() => {
								let confirm = window.confirm(t("common:areYouSure"));
								if (confirm) this.handleRemove();
							}}
						>
							{t("prints:single.deletePrint")}
						</button>
					</div>
					<SelectionPicker type="prints" item={print} />
				</div>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		print: { print, allPrints: prints, deletedPrint, printArtists, isFetching },
	} = state;
	const isAdmin = user.role === "admin";

	const form = {
		submitting: isSubmitting("print")(state),
		submitSucceeded: hasSubmitSucceeded("print")(state),
		submitFailed: hasSubmitFailed("print")(state),
		print: getFormValues("print")(state),
		initial: getFormInitialValues("print")(state),
	};

	return {
		isAdmin,
		print,
		prints,
		form,
		deletedPrint,
		printArtists,
		isFetching,
	};
}

const translated = withNamespaces("artworks")(Print);
export default connect(mapStateToProps)(withRouter(translated));
