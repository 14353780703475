import React from "react";
import {
	saveArtwork,
	deleteArtwork,
	fetchArtworksForGallery,
	fetchArtworksForArtist,
} from "../ArtworkActions";
import { validateShipping } from "../../utils/Validators";
import { showErrorModal } from "../AppActions";
import { showRetryModal } from "../AppActions";

import { initialize, startSubmit, stopSubmit, change } from "redux-form";

import diff from "object-diff";
import Validator from "validatorjs";
import sv from "./sv";
import moment from "moment";
import i18next from "i18next";
import { getObjectProp, hasObjectProp } from "../../utils/Helpers";
import { isSlugAutoGenerated } from "@artworkslab/sharedmodules/src/utils/Helpers";

export const LOAD = "artworksapp/artwork/LOAD";
export const SUBMIT = "artworksapp/artwork/SUBMIT";

export const FETCH_ARTWORKS = "artworksapp/artwork/FETCH_ARTWORKS";

export const SHOW_SUCCESS = "artworksapp/artwork/SHOW_SUCCESS";
export const HIDE_SUCCESS = "artworksapp/artwork/HIDE_SUCCESS";

export const SHOW_ERROR = "artworksapp/artwork/SHOW_ERROR";
export const HIDE_ERROR = "artworksapp/artwork/HIDE_ERROR";

export const SHOW_ARTWORK_MODAL = "artworksapp/artwork/SHOW_ARTWORK_MODAL";
export const HIDE_ARTWORK_MODAL = "artworksapp/artwork/HIDE_ARTWORK_MODAL";

const DAYS = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

export function submitArtwork(data, initial) {
	return (dispatch, getState) => {
		const { auth } = getState();
		const isSwedish = i18next.language === "sv";
		let customErrors = {};
		const rules = {
			title: "required",
			year: "numeric",
			height: "numeric",
			width: "numeric",
			depth: "numeric",
			price: "numeric",
			directPurchase: "boolean",
			edition: "numeric",
			valueForRaffle: "numeric",
			shipping: {
				transport: "string",
				swePrice: "numeric",
				euPrice: "numeric",
				globalPrice: "numeric",
			},
		};

		if (getObjectProp(data, "artistMod.name")) {
			data.artist = { name: data.artistMod.name };
		}
		// Artist not required anymore.
		// if (!getObjectProp(data, 'artistMod.name')) {
		//   customErrors.artistMod = isSwedish ? 'Konstnär är obligatoriskt.' : 'Artist is required.'
		// } else {
		//   data.artist = { name: data.artistMod.name }
		// }
		if (
			(hasObjectProp(data, "uploadedImage.URI") &&
				hasObjectProp(data, "uploadedImage.contentType") &&
				hasObjectProp(data, "uploadedImage.fileName")) ||
			(data.images && data.images.length > 0) ||
			(data.uploads && data.uploads.length > 0)
		) {
		} else {
			customErrors.images = isSwedish
				? "Bild är obligatoriskt."
				: "Image is required.";
		}

		//Validate that relevat shipping fields are filled in
		const shippingErrors = validateShipping(data, isSwedish);
		if (shippingErrors) {
			customErrors.shipping = shippingErrors;
		}

		const imageIndexes = {};
		if (data.images && data.images.length > 0) {
			data.images.forEach((image) => {
				imageIndexes[image._id] = image.index;
			});
		}

		let validation = isSwedish
			? new Validator(data, rules, { ...sv })
			: new Validator(data, rules);

		if (isSwedish) {
			validation.setAttributeNames({
				title: "Titel",
				year: "År",
				height: "Höjd",
				width: "Bredd",
				depth: "Djup",
				price: "Pris",
				directPurchase: "Direktköp",
				edition: "Upplaga",
				valueForRaffle: "Värdet",
				shipping: {
					transport: "Leverans",
					swePrice: "Leverans i Sverige",
					euPrice: "Leverans inom EU",
					globalPrice: "Leverans utanför EU",
				},
			});
		} else {
			validation.setAttributeNames({
				valueForRaffle: "value",
			});
		}

		validation.passes();
		const validatorErrors = validation.errors.all();
		const errors = Object.assign({}, customErrors, validatorErrors);

		const changedValues = initial ? diff(initial, data) : data;
		if (data._id) changedValues._id = data._id;
		changedValues.imageIndexes = imageIndexes;

		let removedImageIds = [];
		// If an image has been removed
		if (changedValues.images && __ALLOW_MULITPLE_ARTWORKS__) {
			const initialImages = initial.images
				? initial.images.map((img) => img._id)
				: [];
			const currentImages = data.images.map((img) => img._id);
			removedImageIds = initialImages.filter(
				(imgId) => !!!~currentImages.indexOf(imgId)
			);
			changedValues.removes = removedImageIds;
		}

		if (
			changedValues.images &&
			changedValues.uploads &&
			!__ALLOW_MULITPLE_ARTWORKS__
		) {
			if (initial.images && initial.images.length > 0) {
				changedValues.removes = [initial.images[0]._id];
			}
			changedValues.images = [];
		}

		if (
			changedValues.title &&
			changedValues.title !== "Untitled" &&
			initial.title === "Untitled"
		) {
			// If slug is to be automatically changed on title update.
			changedValues.updateSlug = true;
		}

		// Return early if errors
		if (Object.keys(errors).length !== 0) {
			dispatch(showErrorModal(translate("requiredFields")));
			return dispatch(stopSubmit("artwork", errors));
		}
		// Return modal asking for price if no price
		if (!data.price && !auth.isAdmin && !data.forRaffle)
			return dispatch(
				showRetryModal(
					changedValues,
					"artwork",
					isSwedish
						? "Vill du verkligen spara utan att sätta ett pris på konstverket?"
						: "Are you sure you want to save without a price?"
				)
			);

		//Register if artistMod was removed from artwork to update in controller
		const removedArtistId =
			changedValues.artistMod === null && initial.artistMod
				? initial.artistMod._id
				: null;

		// All good, start saving
		dispatch(saveArtworkData(changedValues, removedArtistId));
	};
}

export function saveArtworkData(changedValues, removedArtistId = null) {
	return (dispatch) => {
		dispatch(startSubmit("artwork"));
		return dispatch(saveArtwork(changedValues, true, removedArtistId))
			.then((data) => {
				let artwork = data.response;
				dispatch(
					initialize("artwork", artwork, null, { keepSubmitSucceeded: true })
				);
			})
			.catch((err) => {
				// TODO: Handle error for form!
				console.log("ERROR", err);
			});
	};
}

export function fetchArtworks(id) {
	return (dispatch) => {
		dispatch(fetchArtworksForGallery(id));
	};
}

export function fetchArtistArtworks(id) {
	return (dispatch) => {
		dispatch(fetchArtworksForArtist(id));
	};
}

export function removeArtwork(artwork) {
	return (dispatch) => {
		dispatch(deleteArtwork(artwork));
	};
}

export function initArtwork() {
	return (dispatch, getState) => {
		const {
			auth: { user },
			artist: { artist },
			gallery: { gallery },
		} = getState();
		dispatch(initialize("artwork", {}));
		dispatch(change("artwork", "styleMod", []));
		dispatch(change("artwork", "mediumMod", []));
		dispatch(change("artwork", "images", []));
		dispatch(change("artwork", "status", "for-sale"));
		dispatch(change("artwork", "directPurchase", false));
		dispatch(change("artwork", "priceCurrency", "SEK"));
		dispatch(change("artwork", "VATcode", 0));
		dispatch(change("artwork", "year", moment().year()));
		// dispatch( change('artwork', 'forRaffle', false) ) // None selected. We want to force users to chose so they don't ignore it.
		if (getObjectProp(user, "role") === "artist") {
			let repBy = getObjectProp(artist, "represented_by");
			dispatch(
				change(
					"artwork",
					"gallery",
					Array.isArray(repBy) && repBy.length > 0 ? repBy[0].gallery : null
				)
			);
			dispatch(change("artwork", "artistMod", artist));
			// TODO: Handle location search on load
		}
		if (
			getObjectProp(user, "role") === "author" ||
			getObjectProp(user, "role") === "society"
		) {
			// gallery is undefined, use users gallery.
			// dispatch( change('artwork', 'gallery', gallery) ) (How did this code ever work?!)
			dispatch(change("artwork", "gallery", user.gallery));
		}
	};
}

export function loadArtwork(artwork) {
	return (dispatch, getState) => {
		dispatch(initialize("artwork", artwork));
	};
}

export function showSuccess() {
	return {
		type: SHOW_SUCCESS,
		showSuccess: true,
	};
}

export function hideSuccess() {
	return {
		type: HIDE_SUCCESS,
		showSuccess: false,
	};
}

export function showArtworkModal(artwork = false) {
	return (dispatch) => {
		if (artwork) {
			dispatch(loadArtwork(artwork));
		} else {
			dispatch(initArtwork());
		}
		dispatch(showModal());
	};
}

function showModal() {
	return {
		type: SHOW_ARTWORK_MODAL,
		showArtworkModal: true,
		showSuccess: false,
	};
}

export function hideArtworkModal() {
	return {
		type: HIDE_ARTWORK_MODAL,
		showArtworkModal: false,
	};
}

export function showError() {
	return {
		type: SHOW_ERROR,
		showError: true,
	};
}

export function hideError() {
	return {
		type: HIDE_ERROR,
		showError: false,
	};
}
