import React, { Component } from "react";

const MainResp = ({
	children,
	preChildren = null,
	className = "",
	contentClassName = "",
	styling = {},
}) => {
	return (
		<div className="MainResp" style={styling}>
			{preChildren}
			<div className={`MainResp__content ${contentClassName}`}>{children}</div>
		</div>
	);
};

export default MainResp;
