import React, { Component } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import { base64ArrayBuffer } from "../../utils/Helpers";
import { fetchFile } from "../../actions/ArchiveActions";

const FIVE_MIB = 5242880;
const ACCEPT = "image/jpeg,image/jpg,image/png";
// Image gotten from getBlackDefaultImage in imageHelper in API.
const DEFAULT_IMAGE =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==";
const RAFFLE_DEFAULT_URL = "https://artworks-app-webb-link";
const CLOSE_CROSS = require("../../../assets/images/icons/close@2x.png");
const ROTATE = require("../../../assets/images/svg/redo.svg");

// const propTypes = {
// 	defaultImage: PropTypes.any,
// };

const defaultProps = {
	defaultImage: false,
};

const validImage = (value, allValues, props) => {
	return undefined;
};

class ImageUploadRotate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			previewImage: null,
			angle: 0,
			size: {
				width: 0,
				height: 0,
			},
		};
		this.createdPreview = false;
	}

	componentDidMount() {
		if (this.props.featuredImageSrc) {
			this.createdPreview = true;
			this.createPreview(this.props.featuredImageSrc);
		}
	}

	componentWillReceiveProps(nextProps) {
		// this.props.featuredImageSrc and nextProps.featuredImageSrc updated at the same time for Post.
		if (!this.createdPreview && nextProps.featuredImageSrc) {
			this.createdPreview = true;
			this.createPreview(nextProps.featuredImageSrc);
		}
	}

	onDrop = (acceptedFiles, e) => {
		const reader = new FileReader();
		const {
			uploadedImage: { URI, contentType, fileName },
			t,
		} = this.props;
		const uploadedFile = acceptedFiles[0];

		//Load preview
		reader.onload = (upload) => {
			// Uploaded image
			const image = {
				URI: upload.target.result,
				fileName: uploadedFile.name,
				contentType: uploadedFile.type,
			};

			// Set state for preview
			this.setState({
				previewImage: uploadedFile,
			});

			// Update input states
			URI.input.onChange(image.URI);
			contentType.input.onChange(image.contentType);
			fileName.input.onChange(image.fileName);
			URI.input.onBlur(image.URI);
			contentType.input.onBlur(image.contentType);
			fileName.input.onBlur(image.fileName);
		};

		// Read data file
		if (uploadedFile) {
			reader.readAsDataURL(uploadedFile);
		}
	};

	// On multi upload, if one file fails, all files fails.
	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;

		rejectedFiles.forEach((rejected) => {
			this.showImageError(rejected, t);
		});
	};

	showImageError = (rejectedFile, t) => {
		// console.log('REJECTED:', rejectedFile);
		if (rejectedFile.size > FIVE_MIB) {
			window.alert(t("imageUpload.imageTooBig"));
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("imageUpload.imageWrongFormat"));
		}
	};

	removeImage = () => {
		const {
			featuredImage: { src },
			uploadedImage: { URI, contentType, fileName },
			t,
		} = this.props;
		const confirm = window.confirm(t("imageUpload.removeAreYouSure"));
		if (confirm) {
			// Only src of featuredImage will be set to null.
			// Other values remain and featuredImage (with src = null) will be sent as params to API when saving.
			// Need to set src to null or image won't disappear from preview.
			src.input.onChange(null);
			src.input.onBlur(null);

			// When adding image and then removing it without saving first, this.props.uploadedImage will still be there,
			// looks like this: { URI: ..., contentType: ..., fileName: ... }
			// Set URI to null and test for it in imageHelper.js -> setFeaturedImage() in API
			URI.input.onChange(null);
			URI.input.onBlur(null);
			contentType.input.onChange(null);
			contentType.input.onBlur(null);
			fileName.input.onChange(null);
			fileName.input.onBlur(null);

			this.setState({
				previewImage: null,
			});
		}
	};

	renderPreviewImage = (t) => {
		const { previewImage, size } = this.state;
		const featuredImage = this.props.featuredImage.src.input.value || null;
		const src = previewImage ? previewImage.preview : featuredImage;
		const cw = this.preview ? this.preview.clientWidth : 0;
		const height = size.height > size.width ? "100%" : "";
		const isDefault = src && src === DEFAULT_IMAGE;
		// Removed from "<div className={b('preview')} ...": style={{ height: `${cw}` }}

		if (src && !isDefault) {
			return (
				<div
					className="ImageUpload__preview"
					ref={(ref) => (this.preview = ref)}
				>
					<div className="ImageUpload__preview-image">
						<img className="ImageUpload__preview-image-img" src={src} />
						{/*
              <img className={b('preview-image-img')} style={{ height: `${height}` }} src={src}/>
              */}
					</div>
				</div>
			);
		} else {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<p>{t("imageUpload.previewHere")}</p>
					</div>
				</div>
			);
		}
	};

	renderRoundPreview = (responsive, t) => {
		const { previewImage } = this.state;
		const featuredImage = this.props.featuredImage.src.input.value || null;
		const src = previewImage ? previewImage.preview : featuredImage;
		if (src) {
			return (
				<div
					className={
						"ImageUpload__preview is-round" +
						(responsive ? " is-responsive" : "")
					}
				>
					<div className="ImageUpload__preview-dummy" />
					<div
						className="ImageUpload__preview-round"
						style={{ backgroundImage: `url(${src})` }}
					></div>
				</div>
			);
		} else {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<p>{t("imageUpload.previewHere")}</p>
					</div>
				</div>
			);
		}
	};

	// When coming to page, create preview from featuredImage.
	createPreview = (url) => {
		// Create no preview for black default image, pointless to rotate it.
		if (url.indexOf("data:image/png;base64") === 0) return;
		let tempPreview = {};
		const bucketStart = url.indexOf("://") + 3;
		let bucketEnd = url.indexOf(".s3");
		if (bucketEnd < 0) bucketEnd = url.indexOf("/featuredImages/");
		const bucket = url.slice(bucketStart, bucketEnd);
		const keyStart = url.indexOf("/featuredImages/") + 1;
		let key = url.slice(keyStart);
		// Need to decode, if url is KIRBY%20SUPER%20STAR.jpg, aws key is KIRBY SUPER STAR.jpg
		// decodeURI did not turn %2B into a plus symbol (+). Using decodeURIComponent instead.
		key = decodeURIComponent(key);

		fetchFile(bucket, key)
			.then((result) => {
				const { Body, ContentType } = result;
				tempPreview.type =
					ContentType === "image/jpg" ? "image/jpeg" : ContentType;

				let base64String = base64ArrayBuffer(Body.data);
				base64String = "data:image/png;base64," + base64String;
				return base64String;
			})
			.then((source) => {
				let canvas = document.createElement("canvas");
				// For viewing when testing.
				// let canvas = document.getElementById('test-canvas')
				let img = new Image();
				img.src = source;

				img.onload = function () {
					saveImage(img.src.replace(/^.*[\\\/]/, ""));
				};

				const saveImage = (img_name) => {
					canvas.width = Math.floor(img.width);
					canvas.height = Math.floor(img.height);
					let ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					/* Want tempPreview to look something like this:
        name: "94037477-8jijf.jpg"
        preview: "blob:http://localhost:3000/4fb17168-742c-4451-824b-28303ae25a3b"
        // size: 73348
        type: "image/jpeg"
        */
					tempPreview.preview = canvas.toDataURL("image/png");
					const regex = /\/.{24}-.+\..+/;
					const matched = regex.exec(url);
					const fileName = matched
						? url.slice(matched.index + 26)
						: "unnamed.jpg";
					tempPreview.name = fileName;
					ctx.restore();

					this.setState({ previewImage: tempPreview });
				};
			})
			.catch((err) => {
				console.log("createPreview fetchFile Error", err);
			});
	};

	rotateImage = (direction) => {
		const { previewImage } = this.state;
		const {
			uploadedImage: { URI, contentType, fileName },
		} = this.props;
		let angle = this.state.angle;

		let img = new Image();
		img.src = previewImage.preview;
		let tempPreview = previewImage;

		// Create a canvas
		let canvas = document.createElement("canvas");
		// For viewing when testing.
		// let canvas = document.getElementById('test-canvas')

		// Wait until img has loaded
		img.onload = function () {
			// resizeImage(img.width, img.height, direction)
			rotateImage(img.width, img.height);
			saveImage(img.src.replace(/^.*[\\\/]/, ""));
		};

		// let resizeImage = (width, height) => {
		//   switch (direction) {
		//     case 'right':
		//       angle += 90
		//       break
		//     case 'left':
		//       angle -= 90
		//       break
		//   }
		//
		//   const rads = (angle * Math.PI/180)
		//   let cos = Math.cos(rads)
		//   let sin = Math.sin(rads)
		//   if(cos < 0) { cos = -cos }
		//   if(sin < 0) { sin = -sin }
		//   const newWidth = height * sin + width * cos
		//   const newHeight = height * cos + width * sin
		//   this.setState({
		//     angle: angle,
		//     size: {
		//       width: newWidth,
		//       height: newHeight
		//     }
		//   })
		// }

		let rotateImage = (width, height) => {
			// Create canvas context
			let ctx = canvas.getContext("2d");
			// Assign canvas dimension
			canvas.width = height;
			canvas.height = width;
			ctx.save();
			ctx.translate(canvas.width / 2, canvas.height / 2);

			switch (direction) {
				case "right":
					ctx.rotate(Math.PI / 2);
					break;
				case "left":
					ctx.rotate(-Math.PI / 2);
					break;
			}
			ctx.drawImage(img, -img.width / 2, -img.height / 2);
			ctx.restore();
		};

		let saveImage = (img_name) => {
			tempPreview.preview = canvas.toDataURL("image/jpeg");
			// tempPreview.preview = canvas.toDataURL('image/png')
			this.setState(
				{
					previewImage: tempPreview,
				},
				() => {
					URI.input.onChange(tempPreview.preview);
					URI.input.onBlur(tempPreview.preview);
					contentType.input.onChange(tempPreview.type);
					contentType.input.onBlur(tempPreview.type);
					fileName.input.onChange(tempPreview.name);
					fileName.input.onBlur(tempPreview.name);
				}
			);
		};
	};

	render() {
		// let { input, meta: { touched, error, valid, invalid }} = this.props;
		const { previewImage, angle } = this.state;
		const {
			canRemoveImage = false,
			profileImage = false,
			roundImage = false,
			centerRotateButtons = false,
			responsive = false,
			t,
		} = this.props;

		const hasFeaturedImg = this.props.featuredImage.src.input.value
			? true
			: false;
		const hasUploadedImg =
			this.props.uploadedImage.URI.input.value &&
			this.props.uploadedImage.fileName.input.value &&
			this.props.uploadedImage.contentType.input.value
				? true
				: false;
		const valid = hasFeaturedImg || hasUploadedImg;
		const error = this.props.featuredImage.src.meta.error || false;
		const required = this.props.required ? true : false;
		const hasImageToShow =
			hasUploadedImg ||
			(hasFeaturedImg &&
				this.props.featuredImage.src.input.value !== DEFAULT_IMAGE);
		const isRaffleDefaultImage =
			!hasUploadedImg &&
			hasFeaturedImg &&
			this.props.featuredImage.src.input.value.indexOf(RAFFLE_DEFAULT_URL) ===
				0;

		const rotateButtons = (
			<div
				className={
					"rotate-buttons" + (centerRotateButtons ? " is-centered" : "")
				}
			>
				<ROTATE
					className="rotate-buttons__button"
					onClick={() => this.rotateImage("left")}
				/>
				<ROTATE
					className="rotate-buttons__button"
					onClick={() => this.rotateImage("right")}
				/>
			</div>
		);

		return (
			<div
				className={
					"ImageUpload" +
					(error ? " is-error" : "") +
					(valid ? " is-valid" : "") +
					(required ? " is-required" : "") +
					(responsive ? " is-responsive" : "")
				}
			>
				<div
					className={
						responsive ? "image-upload-holder with-flex" : "input-holder"
					}
				>
					<input type="hidden" name="uploadedImage.URI" />
					<input type="hidden" name="uploadedImage.fileName" />
					<input type="hidden" name="uploadedImage.contentType" />
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.dropRejected}
						className={`ImageUpload__dropzone ${
							previewImage ? "is-rotatable" : ""
						}`}
						accept={ACCEPT}
						multiple={false}
						maxSize={FIVE_MIB}
					>
						<div>
							{responsive
								? t("imageUpload.clickToUpload")
								: t("imageUpload.dropHere")}
						</div>
					</Dropzone>
					{error ? (
						<span className="Input__error" style={{ marginTop: "10px" }}>
							{error}
						</span>
					) : null}
				</div>
				{profileImage ? (
					<div
						className={
							responsive
								? "image-preview-holder flex-column flex-centered"
								: "input-holder input-holder--center"
						}
					>
						{roundImage
							? this.renderRoundPreview(responsive, t)
							: this.renderPreviewImage(t)}
						{previewImage && rotateButtons}
						{canRemoveImage && hasImageToShow && !isRaffleDefaultImage && (
							<button
								className={
									responsive
										? "close-button remove-uploaded-image-button"
										: "close-button"
								}
								onClick={this.removeImage}
								disabled={!hasFeaturedImg && !hasUploadedImg}
								style={{ backgroundImage: `url(${CLOSE_CROSS})` }}
								type="button"
							/>
						)}
					</div>
				) : (
					<div className="input-holder">
						{this.renderPreviewImage(t)}
						{previewImage && rotateButtons}
					</div>
				)}
				{/* canRemoveImage &&
          <button
            className={ responsive ? 'close-button remove-uploaded-image-button' : 'close-button' }
            onClick={ this.removeImage }
            disabled={ !hasFeaturedImg && !hasUploadedImg }
            style={{backgroundImage: `url(${CLOSE_CROSS})` }}
          />
        */}
				{/* For viewing canvas when testing:
          <canvas id="test-canvas" width="200" height="200" style={{ border: '2px dotted red' }}></canvas>
        */}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let imageSrc = ownProps.featuredImage.src.input.value;
	imageSrc = imageSrc.replace(
		"images.artworksapp.com",
		"artworks-lab-app-images"
	);

	return { featuredImageSrc: imageSrc };
};

const translated = withNamespaces("components")(ImageUploadRotate);
export default connect(mapStateToProps)(translated);
