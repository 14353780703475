import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import { createWebbLink } from "../../../utils/Helpers";

class Banner extends Component {
	constructor(props) {
		super(props);
	}

	//Formats announcement for banner
	getAnnouncement = (list, t) => {
		if (list.length <= 0) {
			return null;
		}
		const item = list[0];
		const title = t("newsfeed:banner.announcement");

		return (
			<p key={item._id}>
				{title}{" "}
				<a href={item.link} target="_blank">
					{item.text}
				</a>
			</p>
		);
	};

	//Returns link for banner-object with appropriate title
	getNames = (list, type, t) => {
		let names = [];
		let typeTitle = "";
		switch (type) {
			case "shows":
				typeTitle = t("newsfeed:banner.showTitle");
				break;
			default:
		}
		list.forEach((item) => {
			const title = item.title ? item.title : item.name;
			const webbLink = createWebbLink(item.slug, type);
			names.push(
				<p key={item._id}>
					{typeTitle}{" "}
					<a href={webbLink} target="_blank">
						{title}
					</a>
				</p>
			);
		});
		return names;
	};

	render() {
		const { selection, t } = this.props;

		if (!selection._id) {
			return null;
		}

		const announcement = selection.announcements
			? this.getAnnouncement(selection.announcements, t)
			: null;
		const showNames = this.getNames(selection.shows, "shows", t);
		const eventNames = this.getNames(selection.events, "events", t);
		const galleryNames = this.getNames(selection.galleries, "galleries", t);

		let content = [];
		content = content.concat(announcement, showNames, eventNames, galleryNames);

		const half = Math.ceil(content.length / 2);
		let first = content.splice(0, half);
		let second = content.splice(-half);

		return (
			<div className="NewsFeed">
				<div className="banner">
					<h1>{first}</h1>
					<h1 className="delayed">{second}</h1>
				</div>
			</div>
		);
	}
}

export default Banner;
