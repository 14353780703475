import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
	getGalleryOptions,
	resetGalleryOptions,
	getArtInstitutions,
} from "../../../actions/PickersActions";
import { fetchArtworks } from "../../../actions/FormActions/ArtworkFormActions";
import {
	showGalleryModal,
	hideGalleryModal,
} from "../../../actions/FormActions/GalleryFormActions";

class GalleryPicker extends Component {
	static propTypes = {
		multi: PropTypes.bool,
	};
	static defaultProps = {
		multi: false,
	};
	constructor(props) {
		super(props);
	}

	componentWillReceiveProps(newProps) {
		const { multi, input } = this.props;
		if (this.props.gallery.gallery != newProps.gallery.gallery) {
			const selectedGallery = [];
			if (multi && Array.isArray(input.value)) {
				selectedGallery.push.apply(selectedGallery, input.value);
				selectedGallery.push(newProps.gallery.gallery);
			} else {
				selectedGallery.push(newProps.gallery.gallery);
			}
			const selection = multi ? selectedGallery : selectedGallery[0];
			this.props.dispatch(hideGalleryModal());
			this.props.input.onChange(selection);
			this.props.input.onBlur(selection);
			this.props.dispatch(resetGalleryOptions(selection));
		}
	}

	selectGallery = (selection) => {
		const { multi, forEducation } = this.props;
		// Empty selection for not multi is null. (Empty selection for multi is empty array.)
		if (!selection) {
			this.props.input.onChange(null);
			this.props.input.onBlur(null);
			return;
		}
		let selectedGallery = null;
		if (multi && forEducation) {
			selectedGallery = selection.map((item) => {
				// Only newly selected item has an _id, previously selected has "gallery" and "name".
				return {
					gallery: item._id || item.gallery,
					name: item.name,
				};
			});
		} else {
			selectedGallery = selection;
		}
		// Update parent
		this.props.input.onChange(selectedGallery);
		this.props.input.onBlur(selectedGallery);
		this.props.dispatch(resetGalleryOptions(selection));
		if (!multi) {
			this.props.dispatch(fetchArtworks(selection._id));
		}
	};

	toggleGalleryModal = (e) => {
		e.preventDefault();
		this.props.dispatch(showGalleryModal());
	};

	searchGallery = (searchString) => {
		this.props.dispatch(
			getGalleryOptions(searchString, this.props.forEducation || false)
		);
	};

	onInputChange = (text) => {
		if (!this.props.forEducation) {
			this.searchGallery(text);
		} else {
			// This is a fix, elasticsearch do not want to search for venueType = Art institution. This fix really only needed for master, staging works.
			// Already saved educations have gallery populated.
			const alreadyAdded = this.props.input.value
				? this.props.input.value.map((item) => item.gallery._id || item.gallery)
				: [];
			this.props.dispatch(
				getArtInstitutions({
					venueType: "Art institution",
					_id: { $nin: alreadyAdded },
				})
			);
		}
	};

	render() {
		const {
			multi,
			noFieldSet = false,
			extraStyle = {},
			meta: { error },
			input: { value },
			isFetching,
			forOrganizer = false,
			options,
			optionsEducation,
			forEducation,
			raffle = false,
			responsive = false,
			t,
		} = this.props;

		const allowAdd = this.props.allowAdd ? true : false;
		const green = this.props.green ? true : false;
		const marked = this.props.marked ? true : false;
		const required = this.props.required ? true : false;
		const empty =
			!this.props.input.value || this.props.input.value.length === 0;
		const valid = this.props.meta.valid && !empty ? true : false;
		const galleryOptions = forEducation ? optionsEducation : options;

		if (multi && forEducation && value) {
			// Previously saved educations had only gallery, not name.
			value.forEach((val) => {
				if (!val.name) {
					val.name = val.gallery.name;
				}
			});
		}

		let placeholder = "";
		if (this.props.placeholder) {
			placeholder = this.props.placeholder;
		} else {
			placeholder = forOrganizer
				? t("galleryPicker.selectOrganizer")
				: t("galleryPicker.selectVenue");
		}
		if (this.props.tagVenuePlaceholder) {
			placeholder = t("galleryPicker.tagVenue");
		}

		let extraClass = "fieldset";
		if (noFieldSet) extraClass = "";
		let inputClassName = `input-holder${allowAdd ? "" : " input-holder--full"}${
			raffle ? " input-holder--nopadding" : ""
		}`;

		if (responsive) {
			extraClass = "fieldset-r";
			inputClassName = "input-holder-r";
			if (!allowAdd) inputClassName += " input-holder-r--full";
		}

		let selectClassName = multi ? "gallery-picker-select" : "";
		if (multi && responsive) selectClassName += " no-clear-all";

		// When multi, autosize makes text float off to the left. Resizing is wrong.
		return (
			<div
				className={
					"GalleryPicker" +
					(required ? " is-required" : "") +
					(valid ? " is-valid" : "") +
					(marked ? " is-marked" : "") +
					(empty ? " is-empty" : "") +
					(green ? " is-green" : "")
				}
			>
				<div className={inputClassName} style={extraStyle}>
					<Select
						value={value}
						options={galleryOptions}
						onChange={this.selectGallery}
						onInputChange={this.onInputChange}
						valueKey={forEducation ? "gallery" : "_id"}
						labelKey="name"
						backspaceToRemoveMessage=""
						backspaceRemoves={false}
						isLoading={isFetching}
						placeholder={placeholder}
						noResultsText={t("galleryPicker.noResultsFound")}
						multi={multi}
						autosize={multi ? false : false}
						className={selectClassName}
					/>
					{error ? <span className="GalleryPicker__error">{error}</span> : null}
				</div>
				{allowAdd ? (
					<div className={responsive ? "input-holder-r" : "input-holder"}>
						<button
							type="button"
							onClick={(e) => {
								this.toggleGalleryModal(e);
							}}
						>
							{"Add new Gallery"}
						</button>
					</div>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
    const {
        gallery,
        pickers: {
            galleryPicker: { isFetching, options, optionsEducation },
        },
    } = state;

    return { gallery, isFetching, options, optionsEducation };
}

const translated = withNamespaces("components")(GalleryPicker);
export default connect(mapStateToProps)(translated);
