import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
	getFormValues,
	getFormInitialValues,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import {
	submitAccountUser,
	loadAccountUser,
	initAccountUser,
} from "../../actions/FormActions/AccountFormActions";
import { fetchUser, removeUser } from "../../actions/UserActions";

import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";

import {
	hideGalleryModal,
	submitGallery,
} from "../../actions/FormActions/GalleryFormActions";

import Main from "../../components/Main/Main";
import Modal from "../../components/Modal/Modal";
import MetaButton from "../../components/MetaButton/MetaButton";
import ArtistForm from "../Artist/ArtistForm";
import GalleryForm from "../Gallery/GalleryForm";
// import PDPA										 from './PDPA'
import AdminAccountForm from "./AdminAccountForm";
import { slackUs } from "@artworkslab/sharedmodules/src/actions/MessageActions";

const FORM_NAME = "adminAccount";

class AdminAccount extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const {
			isAdmin,
			params: { accountId },
		} = this.props;
		if (isAdmin && accountId !== "new") {
			this.props.dispatch(fetchUser(accountId));
		} else if (isAdmin && accountId === "new") {
			this.props.dispatch(initAccountUser(FORM_NAME));
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.deletedUser) {
			return this.props.router.push("/accounts");
		}
		const {
			params: { accountId },
			isAdmin,
		} = this.props;
		if (isAdmin !== newProps.isAdmin && newProps.isAdmin) {
			if (accountId !== "new") {
				this.props.dispatch(fetchUser(accountId));
			} else if (accountId === "new") {
				this.props.dispatch(initAccountUser(FORM_NAME));
			}
		}
		if (this.props.users !== newProps.users) {
			const fetchedUser = newProps.users[accountId] || false;
			if (fetchedUser)
				this.props.dispatch(loadAccountUser(fetchedUser, FORM_NAME));
		}
	}

	submit = () => {
		const {
			form: { adminAccount, initial },
		} = this.props;
		if (adminAccount.stripeCustomerId !== initial.stripeCustomerId) {
			const message = `We changed User ${adminAccount.email} ${adminAccount._id} Stripe customer id from ${initial.stripeCustomerId} to ${adminAccount.stripeCustomerId}`;
			this.props.dispatch(slackUs(message, "stripeSubscriptions"));
		}
		this.props.dispatch(submitAccountUser(adminAccount, initial, FORM_NAME));
	};

	handleArtistSubmit = () => {
		const {
			artistForm: { artist },
		} = this.props;
		this.props.dispatch(submitArtist(artist, {}));
	};

	handleGallerySubmit = () => {
		const {
			galleryForm: { gallery },
		} = this.props;
		this.props.dispatch(submitGallery(gallery, {}));
	};

	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};

	toggleGalleryModal = () => {
		this.props.dispatch(hideGalleryModal());
	};

	handleRemove = () => {
		const {
			isAdmin,
			params: { accountId },
			users,
		} = this.props;
		if (!isAdmin) return;
		const user = users[accountId];
		if (user) {
			let deleteArtist = false;
			let deleteGallery = false;
			if (user.artist)
				deleteArtist = window.confirm(
					`Do you also want to delete the associated artist with id ${user.artist._id}, name ${user.artist.name}? (Cancel/Avbryt = no)`
				);
			// if (user.gallery) deleteGallery = window.confirm(`Do you also want to delete the associated gallery with id ${user.gallery._id}, name ${user.gallery.name}? (Cancel/Avbryt = no)`)
			const sure = window.confirm("Are you sure you want to delete this user?");
			if (sure)
				this.props.dispatch(removeUser(user._id, deleteArtist, deleteGallery));
		}
	};

	render() {
		const {
			isAdmin,
			showArtistModal,
			showGalleryModal,
			params: { accountId },
			users,
			t,
		} = this.props;
		const isNew = accountId === "new";
		const user = users[accountId];
		if (!isAdmin) return null;

		return (
			<div>
				<Main>
					<AdminAccountForm
						isAdmin={isAdmin}
						onSubmit={this.submit}
						user={user}
						t={t}
					/>
					{/* user && user.gallery &&
						<PDPA adminFormGallery={ user.gallery } isAdmin={ true } />
					*/}
					{isAdmin && !isNew && (
						<div
							className="fieldset"
							style={{ paddingTop: 40, justifyContent: "space-between" }}
						>
							<legend>Danger zone</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={this.handleRemove}
							>
								Delete account
							</button>
						</div>
					)}
				</Main>
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
						/>
					</Modal>
				) : null}
				{showGalleryModal ? (
					<Modal onCloseClick={this.toggleGalleryModal}>
						<GalleryForm
							onSubmit={this.handleGallerySubmit}
							isAdmin={isAdmin}
						/>
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin },
		user: { users, deletedUser },
		artistForm: { showArtistModal },
		galleryForm: { showGalleryModal },
	} = state;
	const form = {
		submitting: isSubmitting(FORM_NAME)(state),
		submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
		submitFailed: hasSubmitFailed(FORM_NAME)(state),
		[FORM_NAME]: getFormValues(FORM_NAME)(state),
		initial: getFormInitialValues(FORM_NAME)(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	const galleryForm = {
		submitting: isSubmitting("gallery")(state),
		submitSucceeded: hasSubmitSucceeded("gallery")(state),
		submitFailed: hasSubmitFailed("gallery")(state),
		gallery: getFormValues("gallery")(state),
		initial: getFormInitialValues("gallery")(state),
	};
	return {
		users,
		deletedUser,
		isAdmin,
		artistForm,
		galleryForm,
		showArtistModal,
		showGalleryModal,
		form,
	};
}

const translated = withNamespaces("common")(AdminAccount);
export default connect(mapStateToProps)(withRouter(translated));
