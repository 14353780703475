import Api from "@artworkslab/sharedmodules/src/Api";

export const FETCH_SUBSCRIBERS_REQUEST =
	"subscriptionActions/FETCH_SUBSCRIBERS_REQUEST";
export const FETCH_SUBSCRIBERS_SUCCESS =
	"subscriptionActions/FETCH_SUBSCRIBERS_SUCCESS";
export const FETCH_SUBSCRIBERS_FAILURE =
	"subscriptionActions/FETCH_SUBSCRIBERS_FAILURE";

export function fetchSubscribers(subscriptionType) {
	return (dispatch) => {
		dispatch(fetchSubscribersRequest());
		return Api("subscription/subscribers", "GET", true)
			.then((json) => {
				dispatch(fetchSubscribersSuccess(json));
			})
			.catch((err) => {
				dispatch(fetchSubscribersFailure(err));
			});
	};
}

export function fetchCancelledSubscribers() {
	return (dispatch) => {
		dispatch(fetchSubscribersRequest());
		return Api("subscription/cancelled-subscribers", "GET", true)
			.then((json) => {
				dispatch(fetchSubscribersSuccess(json));
			})
			.catch((err) => {
				dispatch(fetchSubscribersFailure(err));
			});
	};
}

function fetchSubscribersRequest() {
	return {
		type: FETCH_SUBSCRIBERS_REQUEST,
	};
}

function fetchSubscribersSuccess(response) {
	return {
		type: FETCH_SUBSCRIBERS_SUCCESS,
		response,
	};
}

function fetchSubscribersFailure(message) {
	return {
		type: FETCH_SUBSCRIBERS_FAILURE,
		error: message,
	};
}

export const FETCH_DATA_REQUEST = "subscriptionActions/FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "subscriptionActions/FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "subscriptionActions/FETCH_DATA_FAILURE";

export function fetchUpdatePreview(date, orderId) {
	return (dispatch) => {
		const body = { date, orderId };
		dispatch(fetchDataRequest("previewData", null));
		return Api("subscription/update/updatepreview", "POST", true, body)
			.then((json) => {
				dispatch(fetchDataSuccess(json, "previewData"));
			})
			.catch((err) => {
				dispatch(fetchDataFailure(err));
			});
	};
}

export function updateSubscriptions(date, orderId) {
	return (dispatch) => {
		const body = { date, orderId };
		dispatch(fetchDataRequest("previewData", null));
		return Api("subscription/update/updatesubscriptions", "POST", true, body)
			.then((json) => {
				dispatch(fetchDataSuccess(json, "previewData"));
			})
			.catch((err) => {
				dispatch(fetchDataFailure(err));
			});
	};
}

function fetchDataRequest(clearName, clearValue) {
	return {
		type: FETCH_DATA_REQUEST,
		clearName,
		clearValue,
	};
}

function fetchDataSuccess(response, responseName) {
	return {
		type: FETCH_DATA_SUCCESS,
		response,
		responseName,
	};
}

function fetchDataFailure(message) {
	return {
		type: FETCH_DATA_FAILURE,
		error: message,
	};
}
