import Api from "@artworkslab/sharedmodules/src/Api";
import { showLoadingSpinner, hideLoadingSpinner } from "./AppActions";

export const UPDATE_CUSTOMERS_REQUEST =
	"artworksapp/BillectaActions/UPDATE_CUSTOMERS_REQUEST";
export const UPDATE_CUSTOMERS_SUCCESS =
	"artworksapp/BillectaActions/UPDATE_CUSTOMERS_SUCCESS";
export const UPDATE_CUSTOMERS_FAILURE =
	"artworksapp/BillectaActions/UPDATE_CUSTOMERS_FAILURE";

// Create Billecta debtors for one or all galleries.
export function updateOrCreateCustomers(galleryId = "") {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			// Endoint without galleryId will update all.
			const endpoint = `billecta/customers/${galleryId}`;

			dispatch(updateOrCreateCustomersRequest());
			Api(endpoint, "PUT", true)
				.then((json) => {
					dispatch(updateOrCreateCustomersSuccess(json));
				})
				.catch((error) => {
					dispatch(updateOrCreateCustomersFailure(error));
				});
		});
}

export function updateOrCreateCustomersRequest() {
	return {
		type: UPDATE_CUSTOMERS_REQUEST,
	};
}

export function updateOrCreateCustomersSuccess(json) {
	return {
		type: UPDATE_CUSTOMERS_SUCCESS,
		response: json,
	};
}

export function updateOrCreateCustomersFailure(error) {
	return {
		type: UPDATE_CUSTOMERS_FAILURE,
		response: error,
	};
}

// Create Billecta creditor for an art society.
export const updateOrCreateCreditor = (galleryId) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			// Endoint without galleryId will update all.
			const endpoint = `billecta/creditor/${galleryId}`;

			dispatch(showLoadingSpinner());
			Api(endpoint, "PUT", true)
				.then((json) => {
					dispatch(hideLoadingSpinner());
					return json;
				})
				.catch((error) => {
					dispatch(hideLoadingSpinner());
					return error;
				});
		});
};

// Create Billecta debtors for an art societys members who have name and personalIdentityNumber.
export const updateMemberDebtors = (galleryId) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			// Endoint without galleryId will update all.
			const endpoint = `billecta/members/${galleryId}`;

			dispatch(showLoadingSpinner());
			Api(endpoint, "PUT", true)
				.then((json) => {
					dispatch(hideLoadingSpinner());
					return json;
				})
				.catch((error) => {
					dispatch(hideLoadingSpinner());
					return error;
				});
		});
};

export const CREATE_INVOICE_REQUEST =
	"artworksapp/BillectaActions/CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS =
	"artworksapp/BillectaActions/CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICES_SUCCESS =
	"artworksapp/BillectaActions/CREATE_INVOICES_SUCCESS";
export const CREATE_INVOICE_FAILURE =
	"artworksapp/BillectaActions/CREATE_INVOICE_FAILURE";

export function createInvoice(galleryId) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			// Create invoice for galleryId. If no galleryId, create invoices for all.
			const endpoint = `billecta/premium-membership-invoice/${galleryId}`;

			dispatch(createInvoiceRequest());
			Api(endpoint, "POST", true)
				.then((json) => {
					dispatch(createInvoiceSuccess(json, galleryId));
				})
				.catch((error) => {
					dispatch(createInvoiceFail(error));
				});
		});
}

export function createInvoiceRequest() {
	return {
		type: CREATE_INVOICE_REQUEST,
	};
}

export function createInvoiceSuccess(json, galleryId) {
	return {
		type: galleryId ? CREATE_INVOICE_SUCCESS : CREATE_INVOICES_SUCCESS,
		response: json,
	};
}

export function createInvoiceFail(error) {
	return {
		type: CREATE_INVOICE_FAILURE,
		response: error,
	};
}

export const INVOICES_REQUEST = "artworksapp/BillectaActions/INVOICES_REQUEST";
export const INVOICES_SUCCESS = "artworksapp/BillectaActions/INVOICES_SUCCESS";
export const INVOICES_FAILURE = "artworksapp/BillectaActions/INVOICES_FAILURE";

export function fetchInvoices(debtorId = false) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			const endpoint = debtorId
				? `billecta/invoices/${debtorId}`
				: "billecta/invoices/all";

			dispatch(fetchInvoicesRequest());
			Api(endpoint, "GET", true)
				.then((json) => {
					dispatch(fetchInvoicesSuccess(json));
				})
				.catch((error) => {
					dispatch(fetchInvoicesFail(error));
				});
		});
}

export function fetchInvoicesRequest() {
	return {
		type: INVOICES_REQUEST,
	};
}

export function fetchInvoicesSuccess(json) {
	return {
		type: INVOICES_SUCCESS,
		response: json,
	};
}

export function fetchInvoicesFail(error) {
	return {
		type: INVOICES_FAILURE,
		response: error,
	};
}

export const INVOICE_REQUEST = "artworksapp/BillectaActions/INVOICE_REQUEST";
export const INVOICE_SUCCESS = "artworksapp/BillectaActions/INVOICE_SUCCESS";
export const INVOICE_FAILURE = "artworksapp/BillectaActions/INVOICE_FAILURE";

export function fetchInvoice(invoiceId) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			const endpoint = `billecta/invoice/${invoiceId}`;

			dispatch(fetchInvoiceRequest());
			Api(endpoint, "GET", true)
				.then((json) => {
					dispatch(fetchInvoiceSuccess(json));
				})
				.catch((error) => {
					dispatch(fetchInvoiceFail(error));
				});
		});
}

export function fetchInvoiceRequest() {
	return {
		type: INVOICE_REQUEST,
	};
}

export function fetchInvoiceSuccess(json) {
	return {
		type: INVOICE_SUCCESS,
		response: json,
	};
}

export function fetchInvoiceFail(error) {
	return {
		type: INVOICE_FAILURE,
		response: error,
	};
}

export const INVOICE_PREVIEW_REQUEST =
	"artworksapp/BillectaActions/INVOICE_PREVIEW_REQUEST";
export const INVOICE_PREVIEW_SUCCESS =
	"artworksapp/BillectaActions/INVOICE_PREVIEW_SUCCESS";
export const INVOICE_PREVIEW_FAILURE =
	"artworksapp/BillectaActions/INVOICE_PREVIEW_FAILURE";

export function fetchInvoicePreview(invoiceId) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			const endpoint = `billecta/preview/${invoiceId}`;

			dispatch(fetchInvoicePreviewRequest());
			Api(endpoint, "GET", true)
				.then((object) => {
					// object is an ArrayBuffer
					dispatch(fetchInvoicePreviewSuccess(object));
				})
				.catch((error) => {
					dispatch(fetchInvoicePreviewFail(error));
				});
		});
}

export function fetchInvoicePreviewRequest() {
	return {
		type: INVOICE_PREVIEW_REQUEST,
	};
}

export function fetchInvoicePreviewSuccess(object) {
	return {
		type: INVOICE_PREVIEW_SUCCESS,
		response: object,
	};
}

export function fetchInvoicePreviewFail(error) {
	return {
		type: INVOICE_PREVIEW_FAILURE,
		response: error,
	};
}

export const FILE_REQUEST = "artworksapp/BillectaActions/FILE_REQUEST";
export const FILE_SUCCESS = "artworksapp/BillectaActions/FILE_SUCCESS";
export const FILE_FAILURE = "artworksapp/BillectaActions/FILE_FAILURE";

export function fetchFile(guid) {
	return (dispatch, getState) =>
		new Promise((resolve, reject) => {
			const endpoint = `billecta/files/${guid}`;

			dispatch(fetchFileRequest());
			Api(endpoint, "GET", true)
				.then((object) => {
					// object is an ArrayBuffer
					dispatch(fetchFileSuccess(object));
				})
				.catch((error) => {
					dispatch(fetchFileFail(error));
				});
		});
}

export function fetchFileRequest() {
	return {
		type: FILE_REQUEST,
	};
}

export function fetchFileSuccess(string) {
	return {
		type: FILE_SUCCESS,
		response: string,
	};
}

export function fetchFileFail(error) {
	return {
		type: FILE_FAILURE,
		response: error,
	};
}
