import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import TextInput from "../../components/FormInput/TextInput";
import TitleInput from "../../components/FormInput/TitleInput";
import TextArea from "../../components/FormInput/TextArea";
import TextDisplay from "../../components/FormInput/TextDisplay";
import Tooltip from "../../components/Tooltip/Tooltip";
import Select from "../../components/FormInput/Select";
import CheckBox from "../../components/FormInput/CheckBox";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import CategoryPicker from "../../components/FormComponents/CategoryPicker/CategoryPicker";
import ArtistPicker from "../../components/FormComponents/ArtistPicker/ArtistPicker";
// import ArtworkUpload       	from "../../components/FormComponents/ArtworkUpload/ArtworkUpload"
// import ArtworkUploadRotate  from "../../components/FormComponents/ArtworkUpload/ArtworkUploadRotate"
import ArtworkUploadResponsive from "../../components/FormComponents/ArtworkUpload/ArtworkUploadResponsive";
import PricePicker from "../../components/FormComponents/PricePicker/PricePicker";
import ShippingPicker from "../../components/FormComponents/ShippingPicker/ShippingPicker";
import { FormSwitchButton } from "../../components/Buttons/SwitchButton/SwitchButton";
import PublicPurchaserApply from "../../components/Artworks/PublicPurchaserApply";
import { isUserArtSociety, imageRotationActivated } from "../../utils/Helpers";
import { fetchAccess } from "../../actions/FormActions/AccessFormActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { isSlugAutoGenerated } from "@artworkslab/sharedmodules/src/utils/Helpers";

const PUBLISH_STATUS = [
	{ value: "draft", name: "Draft" },
	{ value: "in_review", name: "In review" },
	{ value: "published", name: "Published" },
];

const DIRECT_PURCHASE_SWE = [
	// { value: 'placeholder', name: 'Betala med Klarna/kort, eller skicka förfrågan?' },
	{ value: false, name: "Manuell förfrågan" },
	{ value: true, name: "Direktbetalning" },
];

const DIRECT_PURCHASE_ENG = [
	// { value: 'placeholder', name: 'Pay with Klarna/kort, or send enquiry?' },
	{ value: false, name: "Manual enquiry" },
	{ value: true, name: "Direkt purchase" },
];

const VISIBILITY_OPTIONS = [
	{ value: "restricted", name: "Restricted" },
	{ value: "web", name: "Webb only" },
	{ value: "app", name: "App only" },
	{ value: "all", name: "Everywhere" },
	{ value: "notlisted", name: "Not listed" },
];

const VISIBILITY_OPTIONS_SHORT = [
	{ value: "all", name: "Everywhere" },
	{ value: "restricted", name: "VIP" },
];

const VISIBILITY_OPTIONS_SHORT_SV = [
	{ value: "all", name: "Alla" },
	{ value: "restricted", name: "VIP" },
];

const hiddenStyle = {
	height: 0,
	overflow: "hidden",
	opacity: 0,
};

const PURCHASE_TOOLTIP = (t, responsive = false) => {
	const content = (
		<div style={{ width: "200px" }}>
			<p style={{ color: "white" }}>
				{responsive
					? t("single.purchase_tooltip_for_dropdown")
					: t("single.purchase_tooltip")}
			</p>
		</div>
	);
	return {
		text: content,
		popupPos: "left",
	};
};

let ArtworkFormResponsive = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		isAdmin,
		isArtist,
		isArtSociety,
		isForRaffle,
		isForRaffleChosen,
		provider = false,
		artworkId,
		canAddAccess,
		currency,
		price,
		vatCode,
		status,
		transport,
		infoUpdate,
		artist = {},
		artistIsProMember,
		artistOrgNr,
		appliedForPublicPurchaser,
		approvedForPublicPurchaser,
		onlyForPublicPurchaser,
		publishStatus,
		t,
	} = props;

	// Check for artworkId, public purchaser emails only sent on update.
	const ppButtonsVisible =
		artworkId && (isAdmin || isArtist) && (__DEVELOPMENT__ || __STAGING__);

	const editionHeader = provider
		? `Editions from ${provider}`
		: t("single.editions");

	let showPriceSection = false;
	let showValueSection = false;

	if (isArtSociety || isAdmin) {
		if (isForRaffleChosen && !isForRaffle) {
			showPriceSection = true;
		} else if (isForRaffleChosen && isForRaffle) {
			showValueSection = true;
		}
	} else {
		showPriceSection = true;
	}

	{
		/* Same texts in ArtworkForm.jsx */
	}
	let publicPurchaserStatus =
		i18next.language === "sv"
			? "Detta verk visas för alla användare"
			: "This artwork is displayed to all users";
	if (appliedForPublicPurchaser && !approvedForPublicPurchaser) {
		publicPurchaserStatus =
			i18next.language === "sv"
				? "Du har ansökt om att verket bara ska synas för offentliga inköpare"
				: "You have applied for this artwork to only be displayed to public purchasers";
	} else if (appliedForPublicPurchaser && approvedForPublicPurchaser) {
		publicPurchaserStatus =
			i18next.language === "sv"
				? "Verket är godkänt och syns bara för offentliga inköpare"
				: "This artwork is approved for display only to public purchasers";
	}

	if (onlyForPublicPurchaser) {
		publicPurchaserStatus =
			i18next.language === "sv"
				? "Detta verk syns bara för offentliga inköpare"
				: "This artwork is displayed only to public purchasers";
	}

	return (
		<form onSubmit={handleSubmit} className="responsive-form">
			<div className="input-holder-r input-holder-r--full">
				<Fields
					names={[
						"images",
						"uploads",
						"uploadedImage.URI",
						"uploadedImage.fileName",
						"uploadedImage.contentType",
					]}
					component={ArtworkUploadResponsive}
					multi={true}
					sortable={true}
					required
				/>
			</div>

			{/* Apply for public purchaser. Also in ArtworkForm.jsx */}
			{/* { (isAdmin || isArtist) && (__DEVELOPMENT__ || __STAGING__) && false &&
        <div className="input-holder input-holder--full input-holder--nopadding" style={{
          marginBottom: '1rem',
        }}>
          <Field
            name="forPublicPurchaserRequested"
            offLabel={t('single.forPublicPurchaserRequest')}
            waitingLabel={t('single.forPublicPurchaserAwaitingApproval')}
            onLabel={t('single.forPublicPurchaserApproved')}
            isWaiting={ !approvedForPublicPurchaser }
            disabled={ false }
            component={ FormSwitchButton }
            />
          <p style={{
            width: '100%',
            marginTop: '0.77rem',
          }}></p>
          { isAdmin &&
            <Field
              name="forPublicPurchaser"
              offLabel={'(Admin) ' + t('single.forPublicPurchaser')}
              disabled={ false }
              component={ FormSwitchButton }
              />
          }
        </div>
      } */}
			{/* End: Apply for public purchaser */}

			<div className="fieldset-r">
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="title"
						component={TitleInput}
						placeholder={t("single.title")}
						description={t("single.titleDesc")}
						isResponsive={true}
						isAutoTitle={props.isAutoTitle}
						t={t}
						required
					/>
				</div>
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="year"
						component={TextInput}
						placeholder={t("single.year")}
					/>
				</div>
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="about"
						component={TextArea}
						placeholder={t("single.describeArtwork")}
						isResponsive={true}
					/>
				</div>
			</div>

			{showPriceSection && (
				<div className="fieldset-r">
					<Fields
						names={["priceCurrency", "price", "status", "VATcode"]}
						component={PricePicker}
						selectedCurrency={currency}
						selectedPrice={price}
						selectedVATCode={vatCode}
						isSwedish={i18next.language === "sv"}
						artistIsProMember={artistIsProMember}
						artistOrgNr={artistOrgNr}
						isResponsive={true}
					/>
					{/*<div className="input-holder-r input-holder-r--full" style={{marginTop: '20px'}}>
            <Field
              name="directPurchase"
              checkboxLabel={t('single.purchase')}
              component={ CheckBox }
            />
            <Tooltip data={ PURCHASE_TOOLTIP(t) } extraClass='resp-left'/>
          </div>*/}
					{/* showPlaceholderInitially does not work her, unlike in PricePicker. */}
					{status === "for-sale" && (
						<div className="input-holder-r input-holder-r--full">
							<Field
								name="directPurchase"
								component={Select}
								options={
									i18next.language === "sv"
										? DIRECT_PURCHASE_SWE
										: DIRECT_PURCHASE_ENG
								}
								showPlaceholderInitially={false}
							/>
						</div>
					)}
					{/*<Tooltip data={ PURCHASE_TOOLTIP(t, true) } extraClass='resp-left'/>*/}
					{/*<Fields
            names={["shipping.location", "shipping.transport",
              "shipping.customPrice", "shipping.swePrice", "shipping.euPrice",
              "shipping.globalPrice"]}
            component={ ShippingPicker }
            currency={ currency }
            transport={ transport }
            isSwedish={ i18next.language === 'sv' }
            isResponsive={ true }
          />*/}
				</div>
			)}

			<div className="fieldset-r">
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="edition"
						component={TextInput}
						placeholder={t("single.edition")}
						disabled={provider}
					/>
				</div>
			</div>

			<div className="fieldset-r">
				{/*<legend>{t('single.dimensionsAndMaterials')}</legend>*/}
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="height"
						component={TextInput}
						placeholder={t("single.height")}
					/>
				</div>
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="width"
						component={TextInput}
						placeholder={t("single.width")}
					/>
				</div>
				<div className="input-holder-r input-holder-r--full">
					<Field
						name="depth"
						component={TextInput}
						placeholder={t("single.depth")}
					/>
				</div>
				<Field
					name="styleMod"
					type="style"
					component={CategoryPicker}
					placeholder={t("single.selectStyle")}
					isResponsive={true}
				/>
				<Field
					name="mediumMod"
					type="medium"
					component={CategoryPicker}
					placeholder={t("single.selectMedium")}
					isResponsive={true}
				/>
				<Field
					name="techniqueMod"
					type="technique"
					component={CategoryPicker}
					placeholder={t("single.selectTechnique")}
					isResponsive={true}
				/>
				<Field
					name="colorMod"
					type="color"
					component={CategoryPicker}
					placeholder={t("single.selectColor")}
					isResponsive={true}
				/>
				<Field
					name="showOnWall"
					checkboxLabel={t("single.showOnWall")}
					component={CheckBox}
				/>
			</div>

			{showPriceSection && (
				<div className="fieldset-r">
					<Fields
						names={[
							"shipping.location",
							"shipping.transport",
							"shipping.customPrice",
							"shipping.swePrice",
							"shipping.euPrice",
							"shipping.globalPrice",
						]}
						component={ShippingPicker}
						currency={currency}
						transport={transport}
						isSwedish={i18next.language === "sv"}
						isResponsive={true}
					/>
				</div>
			)}

			{showValueSection && (
				<div className="fieldset-r">
					<legend>{t("single.valueForRaffle")}</legend>
					<div className="input-holder-r input-holder-r--full">
						<Field name="valueForRaffle" component={TextInput} />
					</div>
				</div>
			)}

			{(isAdmin || isArtist) && !infoUpdate && (
				<div>
					{/*<legend>{t('single.selectGallery')}</legend>*/}
					<Field
						name="gallery"
						component={GalleryPicker}
						responsive={true}
						tagVenuePlaceholder={true}
					/>
				</div>
			)}
			{/*<div className="fieldset-block-r fieldset-block--nopadding">*/}
			{!isArtist && !infoUpdate && <legend>{t("single.searchArtists")}</legend>}
			{/* Do not include only hidden ArtistPicker for artist, form will be not pristine from start. */}
			{!isArtist && (
				<div style={isArtist || infoUpdate ? hiddenStyle : {}}>
					<Field
						name="artistMod"
						component={ArtistPicker}
						multi={false}
						canAdd={!isArtist}
						disabled={isArtist}
						isResponsive={true}
					/>
				</div>
			)}
			{/*<div className="fieldset-r" style={{ marginTop: '20px' }}>
          <div className="input-holder-r input-holder-r--full">
            <Field
              name="about"
              component={ TextArea }
              placeholder={t('single.aboutTheArtwork')}
              isResponsive={ true }
            />
          </div>
        </div>*/}
			{/*</div>*/}

			{(isArtSociety || isAdmin) && (
				<div className="for-raffle-radio-buttons input-holder-r input-holder-r--full">
					<label>
						<Field
							name="forRaffle"
							component="input"
							type="radio"
							value={true}
							parse={(val) => val === "true"}
							required
						/>
						<p style={{ marginBottom: "0px" }}>{t("single.forRaffle")}</p>
					</label>
					<label>
						<Field
							name="forRaffle"
							component="input"
							type="radio"
							value={false}
							parse={(val) => val === "true"}
							required
						/>
						<p style={{ marginBottom: "0px" }}>{t("single.forSale")}</p>
					</label>
				</div>
			)}

			{isAdmin ? (
				<div className="fieldset-r">
					<legend>Extra (admin only)</legend>
					<div className="input-holder-r input-holder-r--full">
						<Field
							name="publish_status"
							component={Select}
							options={PUBLISH_STATUS}
						/>
					</div>
					<legend>Editor rating</legend>
					<div className="input-holder-r">
						<Field
							name="editorRating"
							component={TextInput}
							placeholder="Editor rating(1-10)"
						/>
					</div>
				</div>
			) : null}

			{isAdmin && (
				<div className="fieldset-r">
					<legend className="mobile-padding-top">
						{i18next.language === "sv" ? "Synlighet" : "Visibility"}
					</legend>
					<div
						className="input-holder-r input-holder-r--full"
						style={{ marginBottom: "20px" }}
					>
						{i18next.language === "sv" ? (
							<p>
								Välj här om konstverket ska kunna ses av vem som helst eller om
								du vill att det ska visas som förhandsvisning (då krävs det att
								man behöver godkännas av dig för att kunna se verket).
							</p>
						) : (
							<p>
								Choose if the uploaded artwork should have any restrictions for
								users to view.
							</p>
						)}
					</div>
					<div className="input-holder-r">
						<Field
							name="visibility"
							component={Select}
							options={
								isAdmin
									? VISIBILITY_OPTIONS
									: i18next.language === "sv"
									? VISIBILITY_OPTIONS_SHORT_SV
									: VISIBILITY_OPTIONS_SHORT
							}
						/>
					</div>
					{canAddAccess && (
						<div className="input-holder-r">
							<button
								type="button"
								onClick={(e) => {
									e.preventDefault();
									props.openAccess(artworkId);
								}}
								className="purple-button--wide"
							>
								Handle access
							</button>
						</div>
					)}
				</div>
			)}

			{/* <div className="fixed-save-button mobile-only">
        <button type="submit" disabled={ pristine || submitting }><span>{t('common:save')}</span></button>
      </div> */}
			{/*<div className="fieldset-r">
        <button className="purple-button--wide desktop-only" type="submit" disabled={ pristine || submitting }>{t('common:save')}</button>
      </div>*/}
			{/* <div className="fieldset-r" style={{ paddingTop: '20px' }}>
        { artworkId &&
          <Field
            name="slug"
            itemType="artwork"
            component={ TextDisplay }
            isAdmin={ isAdmin }
            isResponsive={ true }
          />
        }
      </div> */}

			{(isAdmin || isArtist) && (__DEVELOPMENT__ || __STAGING__) && false && (
				<div
					className="input-holder input-holder--full input-holder--nopadding"
					style={{
						marginBottom: "2rem",
					}}
				>
					<Field
						name="publish_status"
						offLabel={t("single.draft")}
						onLabel={t("single.published")}
						isWaiting={false}
						disabled={false}
						checkedValue="published"
						uncheckedValue="draft"
						component={FormSwitchButton}
					/>
				</div>
			)}

			<div
				className={
					"artwork-sticky-footer responsive-footer" +
					(isAdmin ? " is-admin" : "")
				}
			>
				<div
					className="artwork-footer-flexed-content"
					style={{
						margin: "0 0 20px 0",
					}}
				>
					<Field
						name="publish_status"
						offLabel={t("single.draft")}
						onLabel={t("single.published")}
						isWaiting={false}
						disabled={false}
						checkedValue="published"
						uncheckedValue="draft"
						component={FormSwitchButton}
						styling={{
							paddingTop: "4px",
							minWidth: "155px",
						}}
					/>
					{ppButtonsVisible && !onlyForPublicPurchaser && (
						<Field
							name="forPublicPurchaserRequested"
							offLabel={t("single.forPublicPurchaserRequest")}
							waitingLabel={t("single.forPublicPurchaserAwaitingApproval")}
							onLabel={t("single.forPublicPurchaserApproved")}
							isWaiting={!approvedForPublicPurchaser}
							disabled={false}
							component={FormSwitchButton}
							styling={{ paddingTop: "4px" }}
						/>
					)}
					{ppButtonsVisible && onlyForPublicPurchaser && (
						<p
							style={{
								// margin: '0.77rem 0',
								margin: "4px 0 0 0",
							}}
						>
							{publicPurchaserStatus}
						</p>
					)}
				</div>
				{ppButtonsVisible && isAdmin && (
					<div
						className="artwork-footer-flexed-content"
						style={{
							margin: "1rem 0",
						}}
					>
						<Field
							name="forPublicPurchaser"
							offLabel={"(Admin) " + t("single.forPublicPurchaser")}
							disabled={false}
							component={FormSwitchButton}
							styling={{ paddingTop: "4px", paddingRight: "20px" }}
						/>
						<Field
							name="forPublicPurchaserOnly"
							offLabel={"(Admin) " + t("single.forPublicPurchaserOnly")}
							disabled={false}
							component={FormSwitchButton}
							styling={{ paddingTop: "4px" }}
						/>
					</div>
				)}
				<div
					className="artwork-footer-flexed-content"
					style={{
						marginBottom: "0rem",
					}}
				>
					{artworkId && (
						<Field
							name="slug"
							itemType="artwork"
							component={TextDisplay}
							isAdmin={isAdmin}
						/>
					)}
					<div
						className="round-save-button-XXX"
						style={
							isAdmin
								? {
										position: "absolute",
										top: "10px",
										right: 0,
								  }
								: {
										position: "absolute",
										bottom: "30px",
										right: 0,
								  }
						}
					>
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

ArtworkFormResponsive = reduxForm({
	form: "artwork",
})(ArtworkFormResponsive);

const selector = formValueSelector("artwork");
ArtworkFormResponsive = connect(
	(state) => {
		const isArtSociety = isUserArtSociety(state.auth.user);
		let isForRaffle = false;
		let isForRaffleChosen = false;
		if (state.form.artwork && state.form.artwork.values) {
			isForRaffle = !!state.form.artwork.values.forRaffle;
			isForRaffleChosen =
				typeof state.form.artwork.values.forRaffle !== "undefined";
		}

		const artworkId = selector(state, "_id");
		const restrictedAccess = selector(state, "visibility") === "restricted";
		const canAddAccess = artworkId && restrictedAccess;
		const publishStatus = selector(state, "publish_status");

		const status = selector(state, "status");
		const currency = selector(state, "priceCurrency");
		const price = selector(state, "price");
		const vatCode = selector(state, "VATcode");
		const transport = selector(state, "shipping.transport");

		const artist = selector(state, "artistMod");
		const artistIsProMember = isPremium(artist);
		const artistOrgNr = artist && artist.orgNumber ? artist.orgNumber : null;

		const title = selector(state, "title");
		const isAutoTitle = isSlugAutoGenerated(title);

		const appliedForPublicPurchaser = selector(
			state,
			"forPublicPurchaserRequested"
		);
		const approvedForPublicPurchaser = selector(state, "forPublicPurchaser");
		const onlyForPublicPurchaser = selector(state, "forPublicPurchaserOnly");

		return {
			isArtSociety,
			isForRaffle,
			isForRaffleChosen,
			artworkId,
			canAddAccess,
			currency,
			price,
			vatCode,
			transport,
			artistIsProMember,
			artistOrgNr,
			isAutoTitle,
			status,
			publishStatus,
			appliedForPublicPurchaser,
			approvedForPublicPurchaser,
			onlyForPublicPurchaser,
		};
	},
	(dispatch) => {
		return {
			openAccess: (artworkId) => {
				dispatch(fetchAccess(artworkId, "artworks"));
			},
		};
	}
)(ArtworkFormResponsive);

export default withNamespaces("artworks")(ArtworkFormResponsive);
