import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router";
import ExpandButton from "../ExpandButton/ExpandButton";
import moment from "moment";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	RadialBarChart,
	RadialBar,
	Legend,
	PieChart,
	Pie,
	ResponsiveContainer,
	Text,
} from "recharts";
import { fetchWeeklyData } from "../../../actions/DashboardActions/AdminDataActions";
import { formatNumber } from "../../../utils/Helpers";

const dataPoint = "Admin-data__sheet__data__data-point";

const ARROW = require("../../../../assets/images/svg/arrow_right.svg");

class AdminData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchWeek: null,
			curWeek: null,
		};
	}

	componentWillMount() {
		const week = moment().week();
		this.fetchData(week);
		this.setState({
			curWeek: week,
		});
	}

	fetchData = (week) => {
		this.props.dispatch(fetchWeeklyData(week));
		this.setState({
			fetchWeek: week,
		});
	};

	renderDataPoint = (title, value, key) => {
		const { t } = this.props;
		const formatValue = formatNumber(value);
		return (
			<div key={key} className={dataPoint}>
				<h4 className="artist">{t(`admin.data.${title}`)}</h4>
				<p>{formatValue}</p>
			</div>
		);
	};

	//Splits data for a section and renders each data point
	renderData = (section) => {
		let renderedData = [];
		Object.keys(section).forEach((key, i) => {
			//Ignores first data point which is always the section title
			if (key !== "title") {
				const title = key;
				const value = section[key];
				const dataPoint = this.renderDataPoint(title, value, i);
				renderedData.push(dataPoint);
			}
		});
		return renderedData;
	};

	//Renders the content of a given sheet
	renderSheet = (sheet) => {
		const { t } = this.props;
		let renderedSheet = [];
		Object.keys(sheet).forEach((key) => {
			const dataSection = sheet[key];
			//Does not render data for the sheet _id nor empty sections
			if (typeof dataSection === "object" && dataSection.title) {
				const title =
					dataSection && dataSection.title
						? dataSection.title
						: t("admin.unknownSection");
				const data = this.renderData(dataSection);
				renderedSheet.push(
					<div key={key} className="Admin-data__sheet">
						<h2>{title}</h2>
						<div key={key} className="Admin-data__sheet__data">
							{data}
						</div>
					</div>
				);
			}
		});
		return renderedSheet;
	};

	//Renders the content for each sheet within the data
	renderWeeklyData = (data) => {
		if (Object.keys(data).length === 0) {
			return;
		}
		let renderedData = [];
		Object.keys(data).forEach((key) => {
			const sheet = data[key];
			//Does not render data for the week number
			if (typeof sheet == "object") {
				const sheetData = this.renderSheet(sheet);
				renderedData.push(sheetData);
			}
		});
		return renderedData;
	};

	drawArrows = () => {
		const { fetchWeek, curWeek } = this.state;
		const noRight = fetchWeek >= curWeek;
		return (
			<div className="Admin-data__arrows">
				<button
					className="Admin-data__arrows__arrow"
					onClick={() => this.fetchData(fetchWeek - 1)}
				>
					<ARROW className="left" />
				</button>
				<button
					className="Admin-data__arrows__arrow"
					onClick={() => this.fetchData(fetchWeek + 1)}
					disabled={noRight}
				>
					<ARROW className="right" />
				</button>
			</div>
		);
	};

	render() {
		const { expanded, data, follows, t } = this.props;
		const week = data && data.week ? data.week : t("admin.unknown");
		const year = data && data.year ? data.year : t("admin.unknown");
		const arrows = this.drawArrows();
		const weeklyData = this.renderWeeklyData(data);

		return (
			<div className="Admin-data">
				<div className="Admin-data__week">
					<h1 className="year">{year}</h1>
					<h1>{t("admin.week")}</h1>
					<h1 className="second">{week}</h1>
				</div>
				{arrows}
				{weeklyData}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		adminData: { expanded, data },
	} = state;
	return { expanded, data };
}

const translated = withNamespaces("dashboard")(AdminData);
export default connect(mapStateToProps)(withRouter(translated));
