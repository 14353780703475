import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Fields, FormSection, reduxForm } from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import TextInput from "../../../components/FormInput/TextInput";
import TextDisplay from "../../../components/FormInput/TextDisplay";
import {
	validateVAT,
	validateClearNr,
	validateAccNr,
	isNumber,
} from "../../../utils/Validators";

class ArtistSettingsFormResp extends Component {
	render() {
		const { handleSubmit, pristine, submitting, isAdmin, isArtist, t } =
			this.props;

		return (
			<form onSubmit={handleSubmit} className="responsive-form">
				<div className="fieldset-block-r">
					<div className="fieldset-r">
						<legend className="legend-nomargin">{t("single.payment")}</legend>
						<div className="input-holder-r input-holder-r--full">
							<Field
								name="orgNumber"
								component={TextInput}
								placeholder={t("single.organisationNumber")}
								validate={validateVAT}
								green
							>
								{/*<Tooltip data={ TOOL_TIPS('orgNumber', t)} />*/}
							</Field>
						</div>
						<div className="input-holder-r input-holder-r--full">
							<Field
								name="bankName"
								component={TextInput}
								placeholder={t("single.bankName")}
								green
							/>
						</div>
					</div>
					<div className="fieldset-r">
						<div className="input-holder-r input-holder-r--full">
							<Field
								name="clearingNr"
								component={TextInput}
								placeholder={t("single.clearingNr")}
								validate={validateClearNr}
								green
							/>
						</div>
						<div className="input-holder-r input-holder-r--full">
							<Field
								name="accountNr"
								component={TextInput}
								placeholder={t("single.accountNr")}
								green
							/>
						</div>
						<div className="input-holder-r input-holder-r--full">
							<Field
								name="bankgiro"
								component={TextInput}
								placeholder={t("single.bankgiro")}
								green
							/>
						</div>
					</div>
				</div>

				{/* Mobile only. */}
				<div className="fixed-save-button mobile-only">
					<button
						className="purple-button--wide"
						type="submit"
						disabled={pristine || submitting}
					>
						{t("common:save")}
					</button>
				</div>
				{/* Desktop only. */}
				<div className="buttons-row" style={{ marginTop: "50px" }}>
					<button
						className="purple-button--wide desktop-only"
						type="submit"
						disabled={pristine || submitting}
					>
						{t("common:save")}
					</button>
					<Field
						name="slug"
						itemType="artist"
						component={TextDisplay}
						isAdmin={isAdmin}
						isArtist={isArtist}
						isResponsive={true}
					/>
				</div>
			</form>
		);
	}
}

ArtistSettingsFormResp = reduxForm({
	form: "artist",
})(ArtistSettingsFormResp);

const translated = withNamespaces("artist")(ArtistSettingsFormResp);
export default connect(null)(translated);
