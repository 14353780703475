import { CALL_API } from "../utils/api";
import Api from "@artworkslab/sharedmodules/src/Api";
// import {
// 	showLoadingSpinner,
// 	hideLoadingSpinner,
// } from './AppActions'

export const INFLUENCER_GET_REQUEST =
	"influencerActions/INFLUENCER_GET_REQUEST";
export const INFLUENCER_GET_SUCCESS =
	"influencerActions/INFLUENCER_GET_SUCCESS";
export const INFLUENCERS_GET_SUCCESS =
	"influencerActions/INFLUENCERS_GET_SUCCESS";
export const INFLUENCER_GET_FAILURE =
	"influencerActions/INFLUENCER_GET_FAILURE";

export function fetchInfluencers(influencerId) {
	const SUCCESS_TYPE =
		influencerId === "all" ? INFLUENCERS_GET_SUCCESS : INFLUENCER_GET_SUCCESS;
	return {
		[CALL_API]: {
			endpoint: `prints-inspiration/${influencerId}/portal`,
			authenticated: true,
			method: "GET",
			types: [INFLUENCER_GET_REQUEST, SUCCESS_TYPE, INFLUENCER_GET_FAILURE],
		},
	};
}

export const INFLUENCER_SAVE_REQUEST =
	"influencerActions/INFLUENCER_SAVE_REQUEST";
export const INFLUENCER_SAVE_SUCCESS =
	"influencerActions/INFLUENCER_SAVE_SUCCESS";
export const INFLUENCER_SAVE_FAILURE =
	"influencerActions/INFLUENCER_SAVE_FAILURE";

export function saveInfluencer(influencer) {
	const method = influencer._id ? "PUT" : "POST"; // update if id is present, otherwise create
	const endpoint = influencer._id
		? `prints-inspiration/${influencer._id}`
		: "prints-inspiration";
	const body = { influencer };

	return async (dispatch) => {
		dispatch(saveInfluencerRequest());
		return Api(endpoint, method, true, body)
			.then((json) => {
				dispatch(saveInfluencerSuccess(json));
				// Return json for initialize in submitInfluencer in PrinFormActions,
				// or form will be empty after save.
				return json;
			})
			.catch((err) => {
				dispatch(saveInfluencerFailure(err));
				return null;
			});
	};
}

function saveInfluencerRequest() {
	return {
		type: INFLUENCER_SAVE_REQUEST,
	};
}

function saveInfluencerSuccess(json) {
	return {
		type: INFLUENCER_SAVE_SUCCESS,
		response: json,
	};
}

function saveInfluencerFailure(error) {
	return {
		type: INFLUENCER_SAVE_FAILURE,
		message: error,
	};
}

export const INFLUENCER_REMOVE_REQUEST =
	"influencerActions/INFLUENCER_REMOVE_REQUEST";
export const INFLUENCER_REMOVE_SUCCESS =
	"influencerActions/INFLUENCER_REMOVE_SUCCESS";
export const INFLUENCER_REMOVE_FAILURE =
	"influencerActions/INFLUENCER_REMOVE_FAILURE";

export function removeInfluencer(influencer) {
	return {
		[CALL_API]: {
			endpoint: `prints-inspiration/${influencer._id}`,
			authenticated: true,
			method: "DELETE",
			types: [
				INFLUENCER_REMOVE_REQUEST,
				INFLUENCER_REMOVE_SUCCESS,
				INFLUENCER_REMOVE_FAILURE,
			],
		},
	};
}
