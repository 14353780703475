import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Fields, FormSection, reduxForm } from "redux-form";
import Select from "../../components/FormInput/Select";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextArea from "../../components/FormInput/TextArea";
import CheckBox from "../../components/FormInput/CheckBox";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import PDFUpload from "../../components/FormInput/PDFUpload";
import RepresentedByPicker from "../../components/FormComponents/RepresentedByPicker/RepresentedByPicker";
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import RelatedPicker from "../../components/FormComponents/RelatedPicker/RelatedPicker";
import CreateRelated from "../../components/FormComponents/RelatedPicker/CreateRelated";
import LocationPicker from "../../components/FormComponents/LocationPicker/LocationPicker";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import SingleDateTimePicker from "../../components/FormComponents/DateRange/SingleDateTimePicker";
import ArtworkPicker from "../../components/FormComponents/ArtworkPicker/ArtworkPicker";
import Tooltip from "../../components/Tooltip/Tooltip";
import ArtistProLink from "../../components/ArtworksLite/ArtistProLink";
import {
	validateEmail,
	validatePhone,
	validateWebAddress,
	validateEmbed,
	validateVAT,
	validateClearNr,
	validateAccNr,
	isNumber,
} from "../../utils/Validators";
import {
	isUserArtSociety,
	capitalizeFirstLetter,
	imageRotationActivated,
} from "../../utils/Helpers";
import {
	CENTER_IMAGE_OPTIONS,
	videoTooltip,
	getYearList,
} from "../../utils/values";
import {
	COUNTRIES_SELECTED,
	COUNTRIES_ALL_ENG,
	COUNTRIES_ALL_SWE,
	GENDER_OPTIONS,
	GENDER_OPTIONS_SWE,
} from "../Account/FormValues";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import { showCustomModal } from "../../actions/AppActions";

const TOOL_TIPS = (type, t) => {
	if (type === "colorPicker") {
		return {
			text: "Choose a color you want the image to be associated with",
			popupPos: "left",
		};
	} else if (type === "orgNumber") {
		return {
			text: t("single.toolTips.orgNumberTip"),
			popupPos: "left",
		};
	} else if (type === "vatReg") {
		return {
			text: "Check this box if your company is registered for VAT in Sweden. If you have any problems, please contact artworks: hey@artworks.io",
			popupPos: "left",
		};
	} else if (type === "artistEmail") {
		return {
			text: t("single.toolTips.artistEmailTip"),
			popupPos: "left",
		};
	}
};

const showProModal = (dispatch) => {
	// const content = (
	//   <div>
	//     <p>Här kan du som <i>PRO</i> ladda upp en videofil.</p>
	//     {/*<p>Under Juli fortsätter vi fira 100! Därmed erbjuder vi <b>alla nya</b> <i>PRO</i> <b>medlemmar halva priset</b> vid årlig fakturering.</p>*/}
	//     <p><i>PRO</i> konstnärer har flest besök i sina profiler, står för ¼  av all försäljning 2021* och har lägre kommission.</p>
	//     <p>Läs mer och testa <i>PRO</i> gratis <ArtistProLink children={ 'här' } />! 🎨</p>
	//     <div className="modal-footnote">*Enligt data från 18/8 2021</div>
	//   </div>
	// )
	const content = (
		<div>
			<p>
				Här kan du som <i>PRO</i> ladda upp en videofil.
			</p>
			<p>
				Läs mer och prova <ArtistProLink children={"här"} />!
			</p>
		</div>
	);
	dispatch(showCustomModal(content, ""));
};

const VISIBILITY_OPTIONS = [
	{ value: "restricted", name: "Restricted" },
	{ value: "web", name: "Webb only" },
	{ value: "app", name: "App only" },
	{ value: "all", name: "Everywhere" },
	{ value: "notlisted", name: "Not listed" },
];

const MEMBERSHIPS = [
	{ name: "BAS", value: "free" },
	{ name: "PRO", value: "premium" },
];

const PREMIUMS = [
	{ name: "Månadsvis", value: "monthly" },
	{ name: "Årligen", value: "yearly" },
];

let ArtistForm = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		isAdmin,
		isAuthor,
		isAssociation,
		isArtist,
		showVat,
		header,
		editArtist = false,
		isPremium,
		artistVideo,
		dispatch,
		isModal = false,
		forArtSociety = false,
		artworks,
		signatureArtwork,
		t,
	} = props;
	// editArtist: Tooltip about email only when creating new artist. Email not sent when updating an artists email.
	const useUploadWithRotate = imageRotationActivated();
	const isSwedish = i18next.language === "sv";
	let saveClass = "fixed-save-button";
	if (isModal) saveClass += " fixed-save-button--artwork";

	let artworkSelectOptions = Object.keys(artworks).map((key) => {
		const artwork = artworks[key];
		return {
			name: artwork.title,
			value: artwork._id,
		};
	});
	if (!signatureArtwork) {
		artworkSelectOptions.unshift({
			name: t("single.chooseArtwork"),
			value: "placeholder",
		});
	}

	if (forArtSociety && isModal) {
		return (
			<form onSubmit={handleSubmit} className="Form">
				{header && (
					<div style={{ marginBottom: "20px" }}>
						<h3>{header}</h3>
					</div>
				)}
				<div className="fieldset-block fieldset-block--nopadding">
					<legend>{t("single.generalInformation")}</legend>
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="name"
								component={TextInput}
								placeholder={t("common:name")}
								required
								marked
							/>
						</div>
						<div className="input-holder">
							<Field
								name="email"
								component={TextInput}
								placeholder={t("common:email")}
								validate={validateEmail}
								disabled={isArtist}
								marked
							/>
							{!editArtist && (
								<Tooltip
									data={TOOL_TIPS("artistEmail", t)}
									style={{ left: "30px", right: "auto", top: "22px" }}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="fieldset" style={{ height: "70px" }}>
					<div className="input-holder">
						<Field
							name="slug"
							itemType="artist"
							component={TextDisplay}
							isAdmin={isAdmin}
							isArtist={isArtist}
						/>
					</div>
					<div className="fixed-save-button fixed-save-button--artist-picker">
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</form>
		);
	}

	return (
		<form onSubmit={handleSubmit} className="Form">
			{header && (
				<div style={{ marginBottom: "20px" }}>
					<h3>{header}</h3>
				</div>
			)}
			{isAdmin && (
				<div>
					<legend>{t("single.representedBy")}</legend>
					<Field name="represented_by" component={RepresentedByPicker} />
				</div>
			)}
			{isAuthor && (
				<div>
					<Field
						name="representedByYou"
						component={CheckBox}
						checkboxLabel={"This artist is represented by you."}
						secondLabel={"Tick the box if this artist is represented by you."}
					></Field>
				</div>
			)}
			<div className="fieldset-block fieldset-block--nopadding">
				<legend>{t("single.generalInformation")}</legend>
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="name"
							component={TextInput}
							placeholder={t("common:name")}
							required
							marked
						/>
					</div>
					<div className="input-holder">
						<Field
							name="email"
							component={TextInput}
							placeholder={t("common:email")}
							validate={validateEmail}
							disabled={isArtist}
							marked
						/>
						{!editArtist && (
							<Tooltip
								data={TOOL_TIPS("artistEmail", t)}
								extraClass="more-left"
							/>
						)}
					</div>
					{!isAssociation && (
						<div className="input-holder">
							<Field
								name="phone"
								component={TextInput}
								placeholder={t("common:phoneNumber")}
								validate={validatePhone}
								required={!isAdmin}
							/>
						</div>
					)}
					{!isAssociation && (
						<div className="input-holder">
							<Field
								name="webSite"
								component={TextInput}
								placeholder={t("common:websiteExample")}
								validate={validateWebAddress}
								green
							/>
						</div>
					)}
					{/* !isAssociation &&
            <div className="input-holder">
              <Field
                name="country"
                component={ Select }
                options={ COUNTRIES_SELECTED }
                />
            </div>
          */}
					<div className="input-holder input-holder--nopadding">
						{/*<legend>{ isArtist ? t('single.locationArtist') : t('single.location') }</legend>*/}
						<Fields
							names={[
								"locationName",
								"locationSearch",
								"location.type",
								"location.coordinates",
							]}
							mapSize={{ width: 300, height: 300 }}
							isArtist={true}
							component={LocationPicker}
							marked
						/>
					</div>
					{!isAssociation && (
						<div className="input-holder">
							<Field
								name="social.instagram"
								component={TextInput}
								placeholder="@ Instagram"
								normalize={normalizeInstagram}
								green
							/>
						</div>
					)}
					{!isAssociation && (
						<div className="input-holder">
							<Field
								name="social.facebook"
								component={TextInput}
								placeholder="Facebook"
								green
							/>
						</div>
					)}
					{false && showVat && (
						<div className="input-holder">
							<Field
								name="vatReg"
								component={CheckBox}
								checkboxLabel={"Registered for VAT."}
							>
								{/*<Tooltip data={ TOOL_TIPS('vatReg', t)} />*/}
							</Field>
						</div>
					)}
				</div>
				<div className="input-holder input-holder--full">
					<Field
						name="about"
						component={TextArea}
						placeholder={
							isArtist
								? t("single.aboutMePlaceholder")
								: t("single.aboutTheArtistPlaceholder")
						}
						marked
					/>
				</div>
				<div className="input-holder--full">
					<legend>{t("single.education")}</legend>
					<Field
						name="education"
						component={GalleryPicker}
						type="education"
						multi={true}
						placeholder={capitalizeFirstLetter(t("single.education"))}
						forEducation={true}
						marked
					/>
				</div>
				{/*Additional data section*/}
				{(isArtist || isAdmin) && (
					<div className="fieldset">
						{/*Left checkbox section*/}
						<div className="input-holder">
							<Field
								name="professional"
								component={CheckBox}
								checkboxLabel={t("single.additionalData.professional")}
							/>
							<Field
								name="autodidact"
								component={CheckBox}
								checkboxLabel={t("single.additionalData.autodidact")}
							/>
							<Field
								name="student"
								component={CheckBox}
								checkboxLabel={t("single.additionalData.student")}
							/>
						</div>
						{/*More visibility checkbox*/}
						<div className="input-holder input-holder--full">
							<Field
								name="moreVisibility"
								component={CheckBox}
								checkboxLabel={t("single.additionalData.moreVisibilityLabel")}
							/>
						</div>
						{/*Dropdowns*/}
						<div className="input-holder input-holder--full">
							<div className="input-holder input-holder--quarter">
								<legend className="legend-nomargin">
									{t("single.additionalData.activeSince")}
								</legend>
								<Field
									name="activeSince"
									component={Select}
									options={getYearList()}
								/>
							</div>
							<div className="input-holder input-holder--quarter">
								<legend className="legend-nomargin">
									{t("single.additionalData.nationality")}
								</legend>
								<Field
									name="nationality"
									component={Select}
									options={isSwedish ? COUNTRIES_ALL_SWE : COUNTRIES_ALL_ENG}
								/>
							</div>
							<div className="input-holder input-holder--quarter">
								<legend className="legend-nomargin">
									{t("single.additionalData.birthYear")}
								</legend>
								<Field
									name="birthYear"
									component={Select}
									options={getYearList()}
								/>
							</div>
							<div className="input-holder input-holder--quarter">
								<legend className="legend-nomargin">
									{t("single.additionalData.gender")}
								</legend>
								<Field
									name="gender"
									component={Select}
									options={isSwedish ? GENDER_OPTIONS_SWE : GENDER_OPTIONS}
								/>
							</div>
						</div>
					</div>
				)}
				<div className="input-holder--full">
					<legend>{t("single.memberOfSociety")}</legend>
					<Field
						name="member"
						component={GalleryPicker}
						multi={true}
						allowAdd={false}
						green
					/>
				</div>
			</div>

			<div className="fieldset">
				<legend>{t("single.uploadImage")}</legend>
				<Fields
					names={[
						"featuredImage.src",
						"uploadedImage.URI",
						"uploadedImage.fileName",
						"uploadedImage.contentType",
					]}
					component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
					centerRotateButtons={true}
					canRemoveImage={true}
					profileImage={true}
					marked
				/>
				{isAdmin ? (
					<Field name="featuredImage.color" component={ColorPicker}>
						<Tooltip data={TOOL_TIPS("colorPicker", t)} />
					</Field>
				) : null}
			</div>
			{isAdmin && (
				<div className="input-holder">
					<legend className="legend-nomargin">Image center position</legend>
					<Field
						name="featuredImage.center"
						options={CENTER_IMAGE_OPTIONS}
						component={Select}
					/>
				</div>
			)}

			{!isAssociation && (
				<div className="fieldset">
					<div className="input-holder input-holder--flex">
						<legend className="legend-nomargin">
							{t("common:uploadVideo")}
						</legend>
						<Tooltip data={videoTooltip()} />
						<Field
							name="videoLink"
							component={TextInput}
							placeholder={t("common:youtubeExamle")}
							validate={validateEmbed}
							disabled={!isPremium && !isAdmin}
							green
							video
						/>
						{!isPremium && !isAdmin && (
							<div
								className="block-div"
								onClick={() => showProModal(dispatch)}
							></div>
						)}
					</div>
					{artistVideo && (isPremium || isAdmin) && (
						<div className="videoContainer">
							<iframe
								width="440px"
								height="247px"
								src={artistVideo}
								frameBorder="0"
							/>
						</div>
					)}
				</div>
			)}

			{!isAssociation && (
				<div className="fieldset">
					<legend>{t("single.uploadCV")}</legend>
					<Fields
						names={[
							"pdfCV",
							"uploadedPDF.URI",
							"uploadedPDF.fileName",
							"uploadedPDF.contentType",
						]}
						component={PDFUpload}
						marked
					/>
				</div>
			)}
			{editArtist && !isAssociation && (
				<div className="fieldset">
					<legend>{t("single.signatureArtwork")}</legend>
					<div className="input-holder">
						<Field
							name="signatureArtwork"
							component={Select}
							options={artworkSelectOptions}
							useSelectedId={true}
							disabled={artworkSelectOptions.length === 0}
						/>
					</div>
				</div>
			)}

			{/*Payment settings*/}
			{!isAssociation && (
				<div className="fieldset-block fieldset-block--nopadding">
					<div className="fieldset">
						<legend>{t("single.payment")}</legend>
						<div className="input-holder">
							<Field
								name="orgNumber"
								component={TextInput}
								placeholder={t("single.organisationNumber")}
								validate={validateVAT}
								green
							>
								{/*<Tooltip data={ TOOL_TIPS('orgNumber', t)} />*/}
							</Field>
						</div>
						<div className="input-holder">
							<Field
								name="bankName"
								component={TextInput}
								placeholder={t("single.bankName")}
								green
							/>
						</div>
					</div>
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="clearingNr"
								component={TextInput}
								placeholder={t("single.clearingNr")}
								validate={validateClearNr}
								green
							/>
						</div>
						<div className="input-holder">
							<Field
								name="accountNr"
								component={TextInput}
								placeholder={t("single.accountNr")}
								green
							/>
						</div>
						<div className="input-holder">
							<Field
								name="bankgiro"
								component={TextInput}
								placeholder={t("single.bankgiro")}
								green
							/>
						</div>
					</div>
				</div>
			)}

			{isAdmin && (
				<div className="fieldset-block fieldset-block--nopadding">
					<legend>{t("common:mentions.linksToMentions")}</legend>
					<div className="fieldset">
						<div className="input-holder input-holder--full">
							<Field name="related" component={RelatedPicker} />
						</div>
					</div>
					<legend>{t("common:mentions.addNewMention")}</legend>
					<CreateRelated handleSubmit={handleSubmit} submitting={submitting} />
				</div>
			)}

			{isAdmin && (
				<div className="fieldset" style={{ marginTop: "30px" }}>
					<legend>Extra (admin only)</legend>
					<div className="input-holder">
						<legend className="legend-nomargin">Editor rating</legend>
						<Field
							name="editorRating"
							component={TextInput}
							placeholder="Editor rating(1-10)"
						/>
					</div>
					<div className="input-holder">
						<legend className="legend-nomargin">Visibility</legend>
						<Field
							name="visibility"
							component={Select}
							options={VISIBILITY_OPTIONS}
						/>
					</div>
				</div>
			)}
			{isAdmin && (
				<div className="fieldset" style={{ marginBottom: "50px" }}>
					<legend style={{ width: "50%" }}>
						{t("single.premiumMembership")} (Admin)
					</legend>
					<div className="input-holder">
						<Field
							name="billing.membershipLevel"
							options={MEMBERSHIPS}
							component={Select}
						/>
					</div>
					{isPremium && (
						<div className="input-holder">
							<Field
								name="billing.premiumType"
								options={PREMIUMS}
								component={Select}
							/>
						</div>
					)}
					{isPremium && (
						<div className="input-holder">
							<p style={{ color: "#A8A8A8" }}>Became Pro</p>
							<Field
								name="billing.premiumDate"
								component={SingleDateTimePicker}
							/>
						</div>
					)}
					{!isPremium && props.hasEndedSub && (
						<div className="input-holder">
							<p style={{ color: "#A8A8A8" }}>Pro Ended</p>
							<Field
								name="billing.premiumEndedDate"
								component={SingleDateTimePicker}
							/>
						</div>
					)}
					{isPremium && (
						<div className="input-holder">
							<Field
								name="ambassador"
								component={CheckBox}
								checkboxLabel={"This artist is an ambassador."}
								secondLabel={"Tick the box if this artist is an ambassador."}
							/>
						</div>
					)}
					<div className="input-holder input-holder--full">
						<Field
							name="noCommission"
							component={CheckBox}
							checkboxLabel={"This artist pays no commission"}
							secondLabel={
								"Tick the box if this artist is absolved of commission costs"
							}
						/>
					</div>
				</div>
			)}

			{isAdmin && (
				<div className="fieldset" style={{ marginBottom: "50px" }}>
					<legend style={{ width: "50%" }}>
						{t("single.quarterData.title")} (Admin)
					</legend>
					<div className="input-holder">
						<legend className="legend-nomargin">
							{t("single.quarterData.newsletters")}
						</legend>
						<Field
							name="newsletters"
							component={TextInput}
							placeholder={t("single.quarterData.newsletters")}
							validate={isNumber}
						/>
					</div>
					<div className="input-holder">
						<legend className="legend-nomargin">
							{t("single.quarterData.tips")}
						</legend>
						<Field
							name="tips"
							component={TextInput}
							placeholder={t("single.quarterData.tips")}
							validate={isNumber}
						/>
					</div>
					<div className="input-holder">
						<legend className="legend-nomargin">
							{t("single.quarterData.suggestions")}
						</legend>
						<Field
							name="suggestions"
							component={TextInput}
							placeholder={t("single.quarterData.suggestions")}
							validate={isNumber}
						/>
					</div>
					<div className="input-holder">
						<legend className="legend-nomargin">
							{t("single.quarterData.events")}
						</legend>
						<Field
							name="events"
							component={TextInput}
							placeholder={t("single.quarterData.events")}
							validate={isNumber}
						/>
					</div>
					<div className="input-holder">
						<legend className="legend-nomargin">
							{t("single.quarterData.exposure")}
						</legend>
						<Field
							name="exposure"
							component={TextInput}
							placeholder={t("single.quarterData.exposure")}
							validate={isNumber}
						/>
					</div>
					<div className="input-holder">
						<legend className="legend-nomargin">
							{t("single.quarterData.talks")}
						</legend>
						<Field
							name="talks"
							component={TextInput}
							placeholder={t("single.quarterData.talks")}
							validate={isNumber}
						/>
					</div>
				</div>
			)}

			{/*Save button*/}
			{false && !isAdmin && <legend>Save your profile</legend>}
			<div className="fieldset">
				<div className="input-holder">
					<Field
						name="slug"
						itemType="artist"
						component={TextDisplay}
						isAdmin={isAdmin}
						isArtist={isArtist}
					/>
				</div>
				<div className={saveClass}>
					<button
						className="purple-button--wide"
						type="submit"
						disabled={pristine || submitting}
					>
						{t("common:save")}
					</button>
				</div>
			</div>
		</form>
	);
};

const normalizeInstagram = (value) => {
	if (!value) return value;

	if (value.charAt(0) === "@") return value.slice(1);

	return value;
};

ArtistForm.defaultProps = {
	showVat: false,
};

ArtistForm = reduxForm({
	form: "artist",
})(ArtistForm);

ArtistForm = connect(
	(state) => {
		const isAssociation = isUserArtSociety(state.auth.user);
		const isArtist = state.auth.user.role === "artist";

		const {
			artist: { signatureArtwork },
			artistArtworks: artworks,
		} = state.artist;

		return { isAssociation, isArtist, artworks, signatureArtwork };
	},
	(dispatch) => {
		return {};
	}
)(ArtistForm);

export default withNamespaces("artist")(ArtistForm);
