import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
// import PropTypes                  from "prop-types"
import { withNamespaces } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { updatePostImageCaption } from "../../../actions/PostActions";

const FIVE_MIB = 5242880;
const LARGE_IMAGE_MAX = 50000000;
const ACCEPT = "image/jpeg,image/jpg,image/png";

const CLOSE_BUTTON = require("../../../../assets/images/icons/close.white@2x.png");

// const propTypes = {
//   defaultImage: PropTypes.any,
//   multi: PropTypes.bool
// }

const defaultProps = {
	defaultImage: false,
	multi: false,
};

const ImagePreview = ({ src, click, title }) => {
	return (
		<div className="ImageUpload__preview" onClick={click}>
			<div className="ImageUpload__preview-image">
				<img
					className="ImageUpload__preview-image-img"
					src={src}
					title={title}
				/>
			</div>
		</div>
	);
};

const ImageGalleryPreview = ({ src, click, title, index }) => {
	return (
		<div className="image-gallery__item" onClick={click}>
			{index && <span>{index}</span>}
			<div className="image-gallery__item__content">
				<img
					className="image-gallery__item__content__image"
					src={src}
					title={title}
				/>
			</div>
		</div>
	);
};

const SortableItem = SortableElement(({ index, value, showModal }) => {
	// const source = value.URI ? value.URI : (value.thumbnails ? value.thumbnails['750x'].src : value.src)
	const source = value.src || value.URI;
	return (
		<ImageGalleryPreview
			src={source}
			click={() =>
				showModal(
					value._id || value.tmpId,
					source,
					value.caption || "",
					value.drawBorder || false
				)
			}
			title={value.caption}
			index={Number(index) + 1}
		/>
	);
});

const SortableList = SortableContainer(({ items, images, showModal }) => {
	return (
		<ul className={"image-gallery"}>
			{items.map((value, index) => {
				const image = images.find(
					(elem) =>
						elem.id === value || elem._id === value || elem.tmpId === value
				);
				if (!image) return null;
				return (
					<SortableItem
						key={`item-${index}`}
						index={index}
						value={image}
						showModal={showModal}
					/>
				);
			})}
		</ul>
	);
});

class ArtworkUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			modalImage: {
				identifier: "",
				source: "",
				caption: "",
				drawBorder: false,
			},
			sortedImageIds: [],
		};
	}

	componentDidMount() {
		const { value } = this.props.images.input;
		if (value) {
			const imageIds = value.sort(this.sortImages).map((val) => val._id);
			this.setState({
				sortedImageIds: imageIds,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		const { value } = nextProps.images.input;
		if (this.state.sortedImageIds.length === 0 && value) {
			// For single artwork, images are not available on did mount.
			const imageIds = value.sort(this.sortImages).map((val) => val._id);
			this.setState({
				sortedImageIds: imageIds,
			});
		}

		if (!!this.props.uploads.input.value && !nextProps.uploads.input.value) {
			// Uploaded images have been saved.
			const imageIds = value.sort(this.sortImages).map((val) => val._id);
			this.setState({
				sortedImageIds: imageIds,
			});
		}
	}

	onDrop = (acceptedFiles, e) => {
		const {
			uploadedImage: { URI, contentType, fileName },
			multi,
			images,
			images: {
				input: { value },
			},
			t,
		} = this.props;
		const { useLargeImageLimit = false } = this.props;

		let totalSize = 0;
		acceptedFiles.forEach((file) => {
			totalSize += file.size;
		});
		// If too large, saving will fail.
		if (totalSize > (useLargeImageLimit ? LARGE_IMAGE_MAX : FIVE_MIB)) {
			window.alert(
				useLargeImageLimit ? "Image is too big" : t("imageUpload.imagesTooBig")
			);
			return;
		}

		let promises = [];
		if (__ALLOW_MULITPLE_ARTWORKS__) {
			acceptedFiles.map((file) => {
				promises.push(this.loadImage(file));
				// if (__DEVELOPMENT__ || __STAGING__) {
				//   promises.push(this.loadImageWithBinary(file))
				// } else {
				//   promises.push(this.loadImage(file))
				// }
			});
		} else {
			if (acceptedFiles && acceptedFiles.length > 0)
				promises.push(this.loadImage(acceptedFiles[0]));
		}

		Promise.all(promises)
			.then((data) => {
				const { uploads } = this.props;
				const alreadyUploadedImages = Array.isArray(uploads.input.value)
					? uploads.input.value
					: [];
				const uploadedImgs = alreadyUploadedImages.concat(data);
				if (__ALLOW_MULITPLE_ARTWORKS__) {
					let alreadySorted = [...this.state.sortedImageIds]; // clone array
					let addToSorted = [];
					// Create random tmp id, set first in sortedImageIds
					uploadedImgs.forEach((uplImg, index) => {
						if (!uplImg.tmpId) {
							const tmpId = `uploaded${10000 - index}`;
							uploadedImgs[index].tmpId = tmpId;
							addToSorted.push(tmpId);
						}
					});

					const allSortedIds = addToSorted.concat(alreadySorted);
					this.setState({
						sortedImageIds: allSortedIds,
					});

					const existingImages = Array.isArray(value) ? value : [];
					this.updateImages(allSortedIds, existingImages, uploadedImgs);
				} else {
					uploads.input.onChange(data);
					uploads.input.onBlur(data);
					images.input.onChange([]);
					images.input.onBlur([]);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// On multi upload, if one file fails, all files fails.
	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;
		const rejectedFile = rejectedFiles[0];

		rejectedFiles.forEach((rejected) => {
			this.showImageError(rejected, t);
		});
	};

	showImageError = (rejectedFile, t) => {
		const { useLargeImageLimit = false } = this.props;
		// console.log('REJECTED:', rejectedFile);
		if (rejectedFile.size > (useLargeImageLimit ? LARGE_IMAGE_MAX : FIVE_MIB)) {
			window.alert(
				useLargeImageLimit ? "Image is too big" : t("imageUpload.imageTooBig")
			);
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("imageUpload.imageWrongFormat"));
		}
	};

	loadImage = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(uploadedImage);
		});
	};

	loadImageData = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(uploadedImage);
		});
	};

	loadImageBinary = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			return reader.readAsBinaryString(uploadedImage);
		});
	};

	loadImageWithBinary = (uploadedImage) => {
		const that = this;
		return new Promise(function (resolve, reject) {
			Promise.all([
				that.loadImageData(uploadedImage),
				that.loadImageBinary(uploadedImage),
			])
				.then(([data, binary]) => {
					const full = Object.assign({}, data, {
						binaryData: binary.URI,
					});
					resolve(full);
				})
				.catch((err) => {
					console.log("catch err", err);
					reject(err);
				});
		});
	};

	sortImages = (a, b) => {
		if (a.index < b.index) return -1;
		if (a.index > b.index) return 1;
		return 0;
	};

	updateImages = (sortedIds, existingImages, uploadedImages) => {
		const { images, uploads } = this.props;
		const { modalImage } = this.state;

		existingImages.forEach((existing, index) => {
			existing.index = sortedIds.indexOf(existing._id);
			existingImages[index] = existing;
		});

		uploadedImages.forEach((uploaded, index) => {
			uploaded.index = sortedIds.indexOf(uploaded.tmpId);
			uploadedImages[index] = uploaded;
		});

		images.input.onChange(existingImages);
		images.input.onBlur(existingImages);
		uploads.input.onChange(uploadedImages);
		uploads.input.onBlur(uploadedImages);
	};

	updateImagesOnModalClose = () => {
		const {
			images,
			images: {
				input: { value },
			},
			uploads,
		} = this.props;
		const { modalImage } = this.state;
		let uploadedImages = Array.isArray(uploads.input.value)
			? uploads.input.value.slice()
			: [];
		let existingImages = Array.isArray(value) ? value.slice() : [];
		let imageChanged = false;

		existingImages = existingImages.map((image) => {
			if (
				image.id === modalImage.identifier &&
				image.drawBorder !== modalImage.drawBorder
			) {
				imageChanged = true;
				return Object.assign({}, image, {
					drawBorder: modalImage.drawBorder,
				});
			} else {
				return image;
			}
		});

		uploadedImages = uploadedImages.map((image) => {
			if (
				image.tmpId === modalImage.identifier &&
				image.drawBorder !== modalImage.drawBorder
			) {
				imageChanged = true;
				return Object.assign({}, image, {
					drawBorder: modalImage.drawBorder,
				});
			} else {
				return image;
			}
		});

		if (imageChanged) {
			images.input.onChange(existingImages);
			images.input.onBlur(existingImages);
			uploads.input.onChange(uploadedImages);
			uploads.input.onBlur(uploadedImages);
		}
	};

	renderPreviewImage = (t) => {
		const {
			multi,
			sortable,
			images,
			images: {
				input: { value },
			},
			uploads,
		} = this.props;
		const { sortedImageIds } = this.state;
		let uploadedImages = Array.isArray(uploads.input.value)
			? uploads.input.value
			: [];
		let existingImages = Array.isArray(value) ? value : [];
		let allImages = existingImages.concat(uploadedImages);

		const onSortEnd = ({ oldIndex, newIndex }) => {
			// Already saved image has source "https..."
			// Not yet save upload has source "data:image/jpeg;base64..."
			const movedImageId = sortedImageIds[oldIndex];
			// const alreadySavedImage = allImages[oldIndex].src && allImages[oldIndex].src.indexOf('http') === 0

			let resortedIds = [...sortedImageIds];
			resortedIds.splice(oldIndex, 1);
			resortedIds.splice(newIndex, 0, movedImageId);
			this.setState({
				sortedImageIds: resortedIds,
			});

			this.updateImages(resortedIds, existingImages, uploadedImages);
		};

		if (allImages.length === 0) {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<div>
							{multi
								? t("imageUpload.previewHereMulti")
								: t("imageUpload.previewHere")}
						</div>
					</div>
				</div>
			);
		} else if (!multi || !sortable) {
			return (
				<div
					className={"masonry" + (allImages.length > 1 ? " is-multiple" : "")}
				>
					{allImages.map((img, index) => {
						return (
							<ImagePreview
								src={img.src || img.URI}
								click={() =>
									this.openModal(
										img._id || img.fileName,
										img.src || img.URI,
										img.caption || ""
									)
								}
								title={img.caption}
								key={img.index}
							/>
						);
					})}
				</div>
			);
		} else {
			return (
				<SortableList
					items={sortedImageIds}
					onSortEnd={onSortEnd}
					images={allImages}
					showModal={this.openModal}
					pressDelay={200}
					axis="xy"
				/>
			);
		}
	};

	openModal = (imageIdentifier, imageSource, caption, drawBorder) => {
		this.setState({
			modalOpen: true,
			modalImage: {
				identifier: imageIdentifier,
				source: imageSource,
				caption,
				drawBorder,
			},
		});
	};

	shouldRemove = (identifier, t) => {
		this.closeModal();
		let confirm = window.confirm(t("imageUpload.areYouSure"));
		if (confirm) this.removeImage(identifier);
	};

	closeModal = () => {
		this.setState(
			{
				modalOpen: false,
			},
			() => {
				if (this.props.borderToggleOn) {
					this.updateImagesOnModalClose();
				}
			}
		);
	};

	captionChange = (e) => {
		const caption = e.target.value;
		const update = Object.assign({}, this.state.modalImage, {
			caption,
		});
		this.setState({
			modalImage: update,
		});
	};

	toggleBorderChange = (e) => {
		const drawBorder = e.target.checked;
		const update = Object.assign({}, this.state.modalImage, {
			drawBorder,
		});
		this.setState({
			modalImage: update,
		});
	};

	saveCaption = () => {
		const {
			modalImage: { identifier, source, caption },
		} = this.state;
		const {
			postId,
			images,
			images: {
				input: { value: savedImages },
			},
			uploads,
		} = this.props;
		// Already saved image has source "https..."
		// Not yet save upload has source "data:image/jpeg;base64..."
		const alreadySavedImage = source.indexOf("http") === 0;
		// Saving post redirects to /posts/slug, don't really need to check for posiId here, unless removing redirect in future.
		if (postId && alreadySavedImage) {
			this.props
				.dispatch(updatePostImageCaption(postId, identifier, caption))
				.then((updated) => {
					const updatedImages = updated.response.images;
					images.input.onChange(updatedImages);
					images.input.onBlur(updatedImages);
				});
		} else if (!postId || !alreadySavedImage) {
			const uploadedImages = Array.isArray(uploads.input.value)
				? uploads.input.value
				: [];
			const updatedUploads = uploadedImages.map((img) => {
				if (img.tmpId === identifier) img.caption = caption;
				return img;
			});
			uploads.input.onChange(updatedUploads);
			uploads.input.onBlur(updatedUploads);
		}
		this.closeModal();
	};

	renderModal = (t) => {
		const {
			modalOpen,
			modalImage: { identifier, source, caption, drawBorder },
		} = this.state;
		const { captionOn, borderToggleOn } = this.props;

		if (modalOpen) {
			return (
				<div className="upload-modal">
					<img
						className="upload-modal__close"
						src={CLOSE_BUTTON}
						onClick={() => this.closeModal(identifier, caption)}
					/>
					<h4
						onClick={() => {
							this.shouldRemove(identifier, t);
						}}
					>
						⚠️ {t("imageUpload.deleteImage")}
					</h4>
					<div
						className="upload-modal__image"
						style={{ backgroundImage: `url(${source})` }}
						onClick={() => this.closeModal(identifier, caption)}
					/>
					{captionOn && (
						<div className="upload-modal__caption">
							<input
								type="text"
								placeholder={t("imageUpload.caption")}
								onChange={this.captionChange}
								value={caption}
							></input>
							{/*<button type="button" onClick={ this.saveCaption }>{t('common:save')}</button>*/}
							<div
								className="upload-modal__caption__button"
								onClick={this.saveCaption}
							>
								{t("common:save")}
							</div>
						</div>
					)}
					{borderToggleOn && (
						<div className="upload-modal__toggle-border">
							<input
								type="checkbox"
								id="toggle-border-checkbox"
								className="toggle-border-checkbox"
								onChange={this.toggleBorderChange}
								checked={drawBorder}
							/>
							<label htmlFor="toggle-border-checkbox">
								{"Show border around image on webb"}
							</label>
						</div>
					)}
				</div>
			);
		} else {
			return null;
		}
	};

	removeImage = (identifier) => {
		const {
			images: { input },
			uploads,
		} = this.props;
		const uploadedImages = Array.isArray(uploads.input.value)
			? uploads.input.value
			: [];
		const existingImages = Array.isArray(input.value) ? input.value : [];

		const uploadsToKeep = uploadedImages.filter((upload) => {
			return upload.tmpId && upload.tmpId !== identifier;
		});

		const existingToKeep = existingImages.filter((img) => {
			return img._id && img._id !== identifier;
		});

		if (existingToKeep.length !== existingImages.length) {
			input.onChange(existingToKeep);
			input.onBlur(existingToKeep);
		}
		if (uploadsToKeep.length !== uploadedImages.length) {
			uploads.input.onChange(uploadsToKeep);
			uploads.input.onBlur(uploadsToKeep);
		}

		let imageIds = this.state.sortedImageIds.slice();
		imageIds.splice(imageIds.indexOf(identifier), 1);
		this.setState({
			sortedImageIds: imageIds,
		});
	};

	render() {
		// let {input, meta: { touched, error, valid, invalid }} = this.props;
		// let selectedFile = (input && input.value && input.value[0]) || null;
		let input = {};
		let invalid = false;
		let valid = true;
		const { multi, useLargeImageLimit = false, t } = this.props;
		const { error } = this.props.images.meta;
		// TODO: Format errors and set state in a nice way
		return (
			<div
				className={
					"ImageUpload" +
					(invalid ? " is-error" : "") +
					(valid ? " is-valid" : "")
				}
			>
				{this.renderModal(t)}
				<div className="input-holder">
					<input type="hidden" name="uploadedImage.URI" disabled {...input} />
					<input
						type="hidden"
						name="uploadedImage.fileName"
						disabled
						{...input}
					/>
					<input
						type="hidden"
						name="uploadedImage.contentType"
						disabled
						{...input}
					/>
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.dropRejected}
						className={"ImageUpload__dropzone"}
						accept={ACCEPT}
						multiple={multi}
						maxSize={useLargeImageLimit ? LARGE_IMAGE_MAX : FIVE_MIB}
					>
						<div>
							{multi
								? t("imageUpload.dropHereMulti")
								: t("imageUpload.dropHere")}
						</div>
					</Dropzone>
					{error ? (
						<span className="Input__error" style={{ marginTop: "10px" }}>
							{error}
						</span>
					) : null}
				</div>
				<div className="input-holder">{this.renderPreviewImage(t)}</div>
			</div>
		);
	}
}

export default withNamespaces("components")(connect()(ArtworkUpload));
