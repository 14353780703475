import { initialize, stopSubmit, startSubmit } from "redux-form";

import Validator from "validatorjs";

import {
	loginUser,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/AuthActions";

export const PREMIUM_POPUP_CLOSED = "premPpClosed";

export function initLoginUser() {
	return (dispatch) => {
		const usr = {};
		dispatch(initialize("login", usr, false));
	};
}

export function submitLoginUser(data) {
	return (dispatch, getState) => {
		let customErrors = {};
		let rules = {
			email: "required|email",
			password: "required",
		};
		const validation = new Validator(data, rules);
		validation.passes();
		const validatorErrors = validation.errors.all();
		const errors = Object.assign({}, customErrors, validatorErrors);
		if (Object.keys(errors) < 1) {
			dispatch(startSubmit("login"));
			return dispatch(loginUser(data))
				.then((action) => {
					const { type } = action;
					if (type === LOGIN_FAILURE) {
						dispatch(stopSubmit("login", {}));
					}
				})
				.catch((err) => {
					console.error(err);
					dispatch(stopSubmit("login", {}));
				});
		} else {
			console.log("Errors, stopping submit:", errors);
			dispatch(stopSubmit("login", errors));
		}
	};
}
