import {
  LOAD,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR
}                         from '../../actions/FormActions/EventFormActions'
import {
  EVENTS_REQUEST,
  EVENT_REQUEST,
  EVENT_SAVE_SUCCESS,
  EVENT_SAVE_FAILED,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAILURE,
}                         from '../../actions/EventActions'

export function eventFormReducer(state = {
  showSuccess: false,
  showError: false,
  error: null
}, action) {
  switch (action.type) {
    case LOAD:
      return Object.assign({}, state, {
        data: action.data
      })
    case EVENTS_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false,
      })
    case EVENT_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false
      })
    case EVENT_SAVE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true
      })
    case EVENT_SAVE_FAILED:
      return Object.assign({}, state, {
        showError: true,
        error: action.message
      })
    case EVENT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true
      })
    case EVENT_UPDATE_FAILURE:
      return Object.assign({}, state, {
        showError: true,
        error: action.message
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    default:
      return state
  }
}
