import {
  COLLECT_AND_EXPORT_REQUEST, COLLECT_AND_EXPORT_SUCCESS, COLLECT_AND_EXPORT_FAILURE,
} from '../actions/DataActions'

export function dataReducer(state = {
    isFetching: false,
    xlsFile: null,
  }, action) {
  switch (action.type) {
    case COLLECT_AND_EXPORT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        xlsFile: null,
        error: false,
      })
    case COLLECT_AND_EXPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        xlsFile: action.response,
      })
    case COLLECT_AND_EXPORT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    default:
      return state
  }
}
