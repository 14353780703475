'use strict'
import {
  savePost,
  removePost,
} from '../PostActions'
import {
  showErrorModal
} from '../AppActions'
import {
  initialize,
  stopSubmit,
  startSubmit,
} from 'redux-form'
import {
  renderHelpers
} from '../../components/Editor/editorhelpers'
import {
  editorStateFromRaw,
  editorStateToJSON
} from 'megadraft'
import { stateToHTML } from 'draft-js-export-html';
import Validator              from 'validatorjs'
import sv                     from './sv'
import i18next                from 'i18next'
import moment                 from 'moment'
import diff                   from 'object-diff'

import {
  getObjectProp,
  hasObjectProp
} from '../../utils/Helpers'

export const LOAD = 'artworksapp/post/LOAD'
export const SUBMIT = 'artworksapp/post/SUBMIT'

export const FETCH_SHOWS = 'artworksapp/post/FETCH_SHOWS'

export const SHOW_SUCCESS = 'artworksapp/post/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/post/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/post/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/post/HIDE_ERROR'

export const SHOW_POST_MODAL = 'artworksapp/post/SHOW_POST_MODAL'
export const HIDE_POST_MODAL = 'artworksapp/post/HIDE_POST_MODAL'

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

export function submitPost(data, initial) {
  return (dispatch, getState) => {
    const { user } = getState()
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    let rules = {
      title: 'required',
    }
    const currentHTML = data.htmlContent
    const contentBlock = {
  		order: 0,
  		content: currentHTML
    }

    data.contentBlock = [contentBlock]
    if ((hasObjectProp(data, 'uploadedImage.URI') &&
        hasObjectProp(data, 'uploadedImage.contentType') &&
        hasObjectProp(data, 'uploadedImage.fileName'))
        || hasObjectProp(data, 'featuredImage.src')) {
          console.log('image is valid');
    } else {
      console.log('image is invalid');
      if (!hasObjectProp(data, 'isImageGallery')) {
        // Image not required anymore. A completely black base64 image provided in add() in posts.controller.js
        // console.log('no an image gallery');
        // customErrors.featuredImage = { src: isSwedish ? 'Bild är obligatoriskt.' : 'Image is required.' }
      }
    }

    const imageIndexes = {}
    if (data.images && data.images.length > 0) {
      data.images.forEach(image => {
        imageIndexes[image._id] = image.index
      })
    }

    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if (isSwedish) {
      validation.setAttributeNames({
        title: 'Rubrik',
      })
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)
    const changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined
    changedValues.imageIndexes = imageIndexes

    let removedImageIds = []
    // If an image has been removed from image gallery
    if (changedValues.images && __ALLOW_MULITPLE_ARTWORKS__) {
      const initialImages = initial.images ? initial.images.map(img => img._id) : []
      const currentImages = data.images.map(img => img._id)
      removedImageIds = initialImages.filter(imgId => !!!~currentImages.indexOf(imgId))
      changedValues.removes = removedImageIds
    }

    if (changedValues.images && changedValues.uploads && !__ALLOW_MULITPLE_ARTWORKS__) {
      if (initial.images && initial.images.length > 0) {
        changedValues.removes = [initial.images[0]._id]
      }
      changedValues.images = []
    }

    if (Object.keys(errors) < 1) {
      dispatch( startSubmit('post') )
      return dispatch( savePost(changedValues) )
      .then((data) => {
        let post = data.response
        post.publish.availiable_from = moment(data.response.publish.availiable_from)
        dispatch( initialize('post', post, null, {keepSubmitSucceeded:true}) )
      })
      .catch((err) => {
        console.log('ERROR', err);
      })
    }else{
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('post', errors) )
      dispatch( showErrorModal(translate('requiredFields')) )
    }
  }
}

export function deletePost(data) {
  return dispatch => {
    dispatch( removePost(data) )
  }
}

export function loadPost(data) {
  //Set default start & end date
  data.publish.availiable_from = moment(data.publish.availiable_from)
  if (!data.draftContentState) {
    data.draftContentState = null
  }
  //Set default visibility
  if (!data.visibility) {
    data.visibility = 'all'
  }
  return (dispatch, getState) => {
    dispatch( initialize('post', data) )
  }
}

export function initPost() {
  //Set default start & end date
  return (dispatch, getState) => {
    let post = {}
    post.publish = {
      availiable_from: moment(),
      status: 'published'
    }
    post.draftContentState = null
    post.category = []
    post.relatedPosts = []
    post.visibility = 'all'
    dispatch( initialize('post', post) )
  }
}

export function postSuccess(){
  return {
    type: SHOW_SUCCESS,
    postSuccess: true
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS,
    postSuccess: false
  }
}

export function showPostModal(){
  return {
    type: SHOW_POST_MODAL,
    showPostModal: true
  }
}

export function hidePostModal(){
  return {
    type: HIDE_POST_MODAL,
    showPostModal: false
  }
}


export function showError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
