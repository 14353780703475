import { render } from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import { AppContainer } from "react-hot-loader";
import Routes from "./Routes";
import { configureStore } from "./utils/configurestore";
import ScrollHandler from "./utils/ScrollHandler";
import i18n from "./i18n";

const init = i18n();

const store = configureStore({});

render(
	<AppContainer className="App">
		<Provider store={store} className="Test">
			<Routes />
		</Provider>
	</AppContainer>,
	document.getElementById("container")
);

if (module.hot) {
	module.hot.accept("./Routes", () => {
		const NextRoutes = require("./Routes").default;
		ReactDOM.render(
			<AppContainer>
				<Provider store={store}>
					<NextRoutes />
				</Provider>
			</AppContainer>,
			document.getElementById("container")
		);
	});
}
