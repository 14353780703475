import {
  ORDERS_REQUEST, ORDERS_SUCCESS, ORDERS_FAILURE,
  ORDER_REQUEST, ORDER_SUCCESS, ORDER_FAILURE,
  UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE,
  INVOICE_ORDER_REQUEST, INVOICE_ORDER_SUCCESS, INVOICE_ORDER_FAILURE,
  UPDATE_INVOICE_ORDER_REQUEST,
} from '../actions/OrderActions'

export function orderReducer(state = {
  isFetching: false,
  orders: {},
  invoiceOrders: {},
  error: null,
}, action) {
  switch (action.type) {
    case ORDERS_REQUEST: {}
    case ORDER_REQUEST: {}
    case UPDATE_INVOICE_ORDER_REQUEST: {}
    case UPDATE_ORDER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null
      });
    }
    case INVOICE_ORDER_REQUEST: {
      return Object.assign({}, state, {
        invoiceOrders: {},
        isFetching: true,
        error: null
      });
    }
    case ORDERS_FAILURE: {}
    // case ORDER_REQUEST: {}
    case INVOICE_ORDER_FAILURE: {}
    case UPDATE_ORDER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      });
    }
    case ORDERS_SUCCESS: {
      let orders = Object.assign({}, state.orders)
      action.response.forEach((order) => orders[order._id] = order)
      return Object.assign({}, state, {
        isFetching: false,
        error: null,
        orders,
      });
    }
    case ORDER_SUCCESS: {}
    case UPDATE_ORDER_SUCCESS: {
      const order = action.response
      let orders = Object.assign({}, state.orders)
      orders[order._id] = order
      return Object.assign({}, state, {
        isFetching: false,
        error: null,
        orders,
      });
    }
    case INVOICE_ORDER_SUCCESS: {
      let orders = Object.assign({}, state.invoiceOrders)
      action.response.forEach((order) => orders[order._id] = order)
      return Object.assign({}, state, {
        invoiceOrders: orders,
        isFetching: false,
        error: null,
      });
    }
    default:
      return state;
  }
}
