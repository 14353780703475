import { initialize, stopSubmit, startSubmit } from "redux-form";

import Validator from "validatorjs";

import { registerUser } from "@artworkslab/sharedmodules/src/actions/AuthActions";

export function initRegisterUser(query) {
	return (dispatch) => {
		let usr = {};
		if (query.id && query.role) {
			usr.role = query.role;
			if (query.role === "artist") usr.artist = { _id: query.id };
			if (query.role === "author") usr.gallery = { _id: query.id };
		}
		dispatch(initialize("register", usr, false));
	};
}

export function submitRegisterUser(data) {
	return (dispatch, getState) => {
		let customErrors = {};
		let rules = {
			email: "required|email",
			password: "required|min:5",
			role: "required",
			// acceptedTC: 'required_if:role,author', // Option to accept removed in Portal.
			name: "required",
			phone: "required",
		};
		const validation = new Validator(data, rules);
		validation.passes();
		const validatorErrors = validation.errors.all();
		const errors = Object.assign({}, customErrors, validatorErrors);
		if (Object.keys(errors) < 1) {
			// TODO: Handle new users
			dispatch(startSubmit("register"));
			return dispatch(registerUser(data))
				.then((json) => {
					console.log("register", json);
				})
				.catch((err) => {
					console.error(err);
					dispatch(stopSubmit("register", {}));
				});
		} else {
			console.log("Errors, stopping submit:", errors);
			dispatch(stopSubmit("register", errors));
		}
	};
}
