import {
	TOGGLE_EXPANDED
} from '../../actions/DashboardActions/MentionsActions'

export function mentionsReducer(state = {
		isFetching: false,
		authenticated: false,
		data: {
			mentions: [
				{ title: 'Newsletter',
					links: [
						{
							title: 'Artworks Newsletter 15',
							address: '#'
						},
						{
							title: 'Artworks Newsletter 20',
							address: '#'
						}
					]
				},
				{ title: 'Articles',
					links: [
						{
							title: 'An article about art',
							address: '#'
						},
						{
							title: 'Another article about art',
							address: '#'
						},
						{
							title: 'Read this article about art',
							address: '#'
						}
					]
				}
			]
		},
    expanded: true
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		default:
			return state
	}
}
