import React, { Component } from "react";
// import { withRouter }       from 'react-router'
import { connect } from "react-redux";
import {
	Field,
	Fields,
	// FormSection,
	reduxForm,
	formValueSelector,
} from "redux-form";
import TextInput from "../../components/FormInput/TextInput";
import TextArea from "../../components/FormInput/TextArea";
import TextDisplay from "../../components/FormInput/TextDisplay";
import Select from "../../components/FormInput/Select";
import ImageUpload from "../../components/FormInput/ImageUpload";
// import ImageUploadCustomName from '../../components/FormInput/ImageUploadCustomName'
import ArtworkUpload from "../../components/FormComponents/ArtworkUpload/ArtworkUpload";
import FormEditorCustomName from "../../components/FormComponents/FormEditor/FormEditorCustomName";
import ImagePicker from "../../components/FormComponents/ImagePicker/ImagePicker";
import RadioButtons from "../../components/FormInput/RadioButtons";
import { CENTER_IMAGE_OPTIONS } from "../../utils/values";
import { validateWebAddress, normalizeInstagram } from "../../utils/Validators";

const PUBLISH_STATUS = [
	{ value: "draft", name: "Draft" },
	{ value: "published", name: "Published" },
];

class InfluencerForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorChanged: false,
		};
	}

	updateChild = () => {
		this.editorIntro.updateContentFromParent();
		this.editorBody.updateContentFromParent();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	render() {
		const { handleSubmit, pristine, submitting, isAdmin, category, t } =
			this.props;

		if (!isAdmin) return null;

		let influencerCategory = "inspiration";
		if (category === "artist" || category === "artportable") {
			influencerCategory = "printartist";
		}

		return (
			<form onSubmit={handleSubmit} className="Form">
				<div className="fieldset-block fieldset-block--nopadding">
					<div className="fieldset">
						<div
							className="input-holder input-holder--full"
							style={{
								paddingBottom: "20px",
							}}
						>
							<Field
								name="category"
								component={RadioButtons}
								options={[
									{ value: "influencer", label: "Influencer" },
									{ value: "artist", label: "Artist" },
									{ value: "artportable", label: "Artportable" },
								]}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="name"
								component={TextInput}
								placeholder={t("common:name")}
								required
							/>
						</div>
						<div className="input-holder">
							<Field
								name="webSite"
								component={TextInput}
								placeholder={t("common:websiteExample")}
								validate={validateWebAddress}
								green
							/>
						</div>
						<div className="input-holder">
							<Field
								name="social.instagram"
								component={TextInput}
								placeholder="@ Instagram"
								normalize={normalizeInstagram}
								green
							/>
						</div>
						<div className="input-holder">
							<Field
								name="social.facebook"
								component={TextInput}
								placeholder="Facebook"
								green
							/>
						</div>

						{category === "artist" && (
							<div className="input-holder input-holder--full">
								<Field
									name="webSiteOnArtworks"
									component={TextInput}
									placeholder={"Link to artists page on Artworks.se"}
								/>
							</div>
						)}

						{category === "artportable" && (
							<div className="input-holder input-holder--full">
								<Field
									name="webSiteOnArtportable"
									component={TextInput}
									placeholder={"Link to artists page on Artportable.com"}
								/>
							</div>
						)}

						<div className="input-holder input-holder--full">
							<Field
								name="header"
								component={TextArea}
								placeholder={"Header text"}
							/>
						</div>

						<div className="input-holder input-holder--full">
							<Field
								name="about"
								component={TextArea}
								placeholder={"About the influencer"}
							/>
						</div>

						<div className="input-holder input-holder--full">
							<Fields
								names={["draftContentStateIntro", "htmlContentIntro"]}
								component={FormEditorCustomName}
								onRef={(ref) => (this.editorIntro = ref)}
								onEditorChange={this.editorChange}
								draftName="draftContentStateIntro"
								htmlName="htmlContentIntro"
							/>
						</div>

						<div className="input-holder input-holder--full">
							<Fields
								names={["draftContentStateBody", "htmlContentBody"]}
								component={FormEditorCustomName}
								onRef={(ref) => (this.editorBody = ref)}
								onEditorChange={this.editorChange}
								draftName="draftContentStateBody"
								htmlName="htmlContentBody"
							/>
						</div>

						<div className="input-holder">
							<Field
								name="featuredImage"
								component={ImagePicker}
								options={this.props.artistImageOptions}
								placeholder="Import image"
								multi={false}
								canAdd={false}
								disabled={false}
							/>
						</div>

						<div className="fieldset">
							<legend>{"Profile image"}</legend>
							<Fields
								names={[
									"featuredImage.src",
									"uploadedImage.URI",
									"uploadedImage.fileName",
									"uploadedImage.contentType",
								]}
								component={ImageUpload}
								canRemoveImage={true}
								profileImage={false}
							/>
						</div>
						<div className="input-holder">
							<legend className="legend-nomargin">Image center position</legend>
							<Field
								name="featuredImage.center"
								options={CENTER_IMAGE_OPTIONS}
								component={Select}
							/>
						</div>
					</div>

					<div className="fieldset">
						<legend>{"Images"}</legend>
						<Fields
							names={[
								"images",
								"uploads",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={ArtworkUpload}
							multi={true}
							sortable={true}
							captionOn={true}
						/>
					</div>

					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="slug"
								itemType={influencerCategory}
								component={TextDisplay}
								isAdmin={isAdmin}
								isArtist={false}
							/>
							<Field
								name="publish_status"
								component={Select}
								options={PUBLISH_STATUS}
							/>
						</div>
					</div>

					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							onClick={this.updateChild}
							disabled={(pristine || submitting) && !this.state.editorChanged}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

InfluencerForm = reduxForm({
	form: "influencer",
})(InfluencerForm);

const selector = formValueSelector("influencer");
InfluencerForm = connect((state, ownProps) => {
	const category = selector(state, "category");

	return {
		category,
	};
})(InfluencerForm);

export default InfluencerForm;
