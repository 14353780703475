import React from "react";
import { Field } from "redux-form";
import { withNamespaces } from "react-i18next";
import TextInput from "../../FormInput/TextInput";
import CheckBox from "../../FormInput/CheckBox";
import { validateTime } from "../../../utils/Validators";

// const propTypes = {
//   // onSelectDayOpen:  PropTypes.func.isRequired,
//   // onSelectDayClose: PropTypes.func.isRequired,
//   // onSelectDayByAppointment: PropTypes.func.isRequired,
//   // dayOpeningHours:  PropTypes.object.isRequired
// }
const defaultProps = {
	dayOpeningHours: {},
	day: "Monday",
};

const OpeningHourDay = (props) => {
	// False -> appointment
	const { day, names, t } = props;
	const byAppointment = props[day].appointment.input.value ? true : false;
	const opensPlaceholder = byAppointment
		? t("openingHours.byAppointment")
		: t("openingHours.opens");
	const closesPlaceholder = byAppointment ? "" : t("openingHours.closes");
	return (
		<tr>
			<td>{t(`common:days.${day}`)}</td>
			<td>
				<TextInput
					{...props[day].opens}
					name={names[0]}
					component={TextInput}
					disabled={byAppointment}
					placeholder={opensPlaceholder}
					validate={validateTime}
				/>
			</td>
			<td>
				<TextInput
					{...props[day].closes}
					name={names[1]}
					component={TextInput}
					disabled={byAppointment}
					placeholder={closesPlaceholder}
					validate={validateTime}
				/>
			</td>
			<td>
				<CheckBox
					{...props[day].appointment}
					name={names[2]}
					component={CheckBox}
					checkboxLabel={t("openingHours.byAppointment")}
				/>
			</td>
		</tr>
	);
};

// OpeningHourDay.propTypes = propTypes
OpeningHourDay.defaultProps = defaultProps;

export default withNamespaces("common")(OpeningHourDay);
