import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Dropzone from "react-dropzone";
import Spinner from "react-spinkit";
import moment from "moment";
import i18next from "i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { formatName } from "../../../utils/Helpers";

const ENTER = 13;
const MAX_SIZE = 5242880,
	MAX_SIZE_STRING = "5MB"; // 5MB in bytes
const ACCEPT = "image/jpeg,image/jpg,image/png";
const ARROW = require("../../../../assets/images/svg/arrow_right.svg");
const PLACEHOLDER_IMG =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==";

const BLOCKED_STRINGS = /(@|snabel-a|snabela)/gi;

class ChatWindow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newMessage: "",
			addedListener: false,
			scroll: false,
			newChat: false,
		};
	}

	//Adds eventlisteners for keyup
	// componentDidMount() {
	//   if(!this.state.addedListener) {
	//     document.addEventListener("keyup", this.onKeyPressed)
	//     this.setState({
	//       addedListener: true
	//     })
	//   }
	// }

	//Removes eventlisteners
	// componentWillUnmount() {
	//   document.removeEventListener("keyup", this.onKeyPressed)
	//   this.setState({
	//     addedListener: false
	//   })
	// }

	//Scroll to bottom if we have new data and reset check upon change
	componentWillReceiveProps(nextProps) {
		const thisId = this.props.data ? this.props.data.id : false;
		const nextId = nextProps.data ? nextProps.data.id : false;
		if (thisId && thisId !== nextId) {
			this.setState({
				newChat: true,
			});
		} else if (this.state.newChat && thisId && thisId === nextId) {
			this.setState({
				newChat: false,
			});
		}
		if (this.state.scroll && thisId && thisId === nextId) {
			this.updateScroll();
			this.setState({
				scroll: false,
			});
		}
		const thisMessages = this.props.data ? this.props.data.messages : false;
		const nextMessages = nextProps.data ? nextProps.data.messages : false;
		if (
			(thisId !== nextId && nextId) ||
			thisMessages.length !== nextMessages.length
		) {
			this.setState({
				scroll: true,
			});
		}
	}

	//Sends message if user presses ENTER
	onKeyPressed = (e) => {
		const { newMessage } = this.state;
		const activeEl = document.activeElement.id;
		if (
			activeEl === "message-input" &&
			e.keyCode === ENTER &&
			newMessage.length > 0
		) {
			this.sendMessage();
		}
	};

	//Scrolls the chat-window to the bottom
	updateScroll = () => {
		const elem = this.scrollElem;
		if (elem) {
			elem.scrollTop = elem.scrollHeight;
		}
	};

	//Updates newMessage value
	updateMessage = (value) => {
		let message = this.state.newMessage;
		message = value;
		this.setState({
			newMessage: message,
		});
	};

	//Modifies textarea size upon typing
	chatKeyDown = (e) => {
		// Reset field height
		e.target.style.height = "inherit";

		// Get the computed styles for the element
		const computed = window.getComputedStyle(e.target);

		// Calculate the height based on scrollHeight + 3 to equal buttons
		let height = e.target.scrollHeight + 3;

		e.target.style.height = `${height}px`;
	};

	//Resets the size of the textarea for messages
	resetMessageInput = () => {
		this.messageInput.style.height = "41px";
	};

	//Prevent phones from scrolling inputfield upon focus
	handleFocus = (e) => {
		e.preventDefault();
	};

	//Sends message to given function
	sendMessage = () => {
		const { newMessage } = this.state;
		const { data, user } = this.props;
		if (this.checkMessage(newMessage)) {
			const timestamp = new Date().toISOString();
			const messageData = {
				id: user.id,
				name: user.name,
				message: newMessage,
				timestamp: timestamp,
			};
			data.messages.push(messageData);
			this.props.sendMessage(data, "text");
			this.updateMessage("");
		}
		this.updateScroll();
		this.resetMessageInput();
	};

	//Sends a message containing images
	sendImages = (imgData) => {
		const { data, user } = this.props;
		const timestamp = new Date().toISOString();
		const messageData = {
			id: user.id,
			name: user.name,
			message: "",
			images: imgData,
			timestamp: timestamp,
		};
		data.messages.push(messageData);
		this.props.sendMessage(data, "images");
		this.updateScroll();
	};

	//Calls given confirmEnq function
	confirmEnq = () => {
		this.props.confirmEnq();
	};

	//Returns true if the message contains an email address or a phone number
	checkMessage = (message) => {
		const { t } = this.props;
		// let hasEmail = false
		// if(message.match(BLOCKED_STRINGS)) {
		//   hasEmail = true
		// }
		// if(hasEmail) {
		//   window.alert(t('chatWarning'))
		//   return false
		// }
		//Checks if the message only contains whitespace
		if (!message.replace(/\s/g, "").length) {
			return false;
		}
		return true;
	};

	//Handle image-upload
	onDrop = (acceptedFiles, e) => {
		const { user, status, t } = this.props;

		if (!status) {
			window.alert(t("disabledWarning"));
			return;
		}

		let totalSize = 0;
		acceptedFiles.forEach((file) => {
			totalSize += file.size;
		});
		if (totalSize > MAX_SIZE * acceptedFiles.length) {
			window.alert(t("components:imageUpload.imagesTooBig"));
			return;
		}

		let promises = acceptedFiles.map((file) => this.loadImage(file));
		Promise.all(promises)
			.then((data) => {
				if (data.length >= 1) {
					this.sendImages(data);
				} else {
					window.alert("Upload data length less than 1?");
					return;
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	//Reads an uploaded image
	loadImage = (uploadedImage) => {
		const that = this;
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};
			reader.readAsDataURL(uploadedImage);
		});
	};

	//Reject "bad" files
	onDropRejected = (rejectedFiles, e) => {
		const { t } = this.props;
		rejectedFiles.forEach((rejectedFile) => {
			this.showImageError(rejectedFile, t);
		});
	};

	//Alerts user of image error
	showImageError = (rejectedFile, t) => {
		if (rejectedFile.size > MAX_SIZE) {
			window.alert(
				`${t("components:imageUpload.imageTooBigMulti1")} ${
					rejectedFile.name
				} ${t("components:imageUpload.imageTooBigMulti2")}`
			);
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("list.imageWrongFormat"));
		}
	};

	//Formats message timestamp depending on current time
	getTimeFormat = (timestamp) => {
		const today = moment().day();
		return moment(timestamp).format("MMM Do HH:mm");
	};

	//Draws message images
	drawImages = (images) => {
		const drawnImages = [];
		images.map((image, i) => {
			drawnImages.push(
				<a key={i} href={image} target="_blank">
					<img src={image} />
				</a>
			);
		});
		return drawnImages;
	};

	//Draws time and name above the message
	drawTime = (message, isMe) => {
		const time = this.getTimeFormat(message.timestamp);
		const sender = message.name ? message.name : null;
		const firstName =
			sender && sender.indexOf(" ") > 0
				? sender.slice(0, sender.indexOf(" "))
				: sender;
		if (isMe) {
			return (
				<div className="ChatWindow__message-window__scroll-window__message__time">
					<div style={{ paddingRight: "4px" }}>{firstName}</div>
					{time}
				</div>
			);
		}
		return (
			<div className="ChatWindow__message-window__scroll-window__message__time">
				<div style={{ paddingRight: "4px" }}>{time}</div>
				{firstName}
			</div>
		);
	};

	//Draws all messages in the chat-window
	drawMessages = () => {
		const { data, user } = this.props;

		if (!data) return null;

		const messages = [];
		data.messages.forEach((message, i) => {
			const isMe = message.id === user.id ? true : false;
			const timeAndName = this.drawTime(message, isMe);
			const images = message.images ? this.drawImages(message.images) : null;
			messages.push(
				<div
					key={i}
					className={
						"ChatWindow__message-window__scroll-window__message" +
						(isMe ? " is-mine" : "")
					}
				>
					{timeAndName}
					<div
						className={
							"ChatWindow__message-window__scroll-window__message__text-container" +
							(isMe ? " is-mine" : "")
						}
					>
						{message.message}
						{images && (
							<div className="ChatWindow__message-window__scroll-window__message__text-container__images">
								{images}
							</div>
						)}
					</div>
				</div>
			);
		});
		return messages;
	};

	//Draws the headerSection wich is displayed for small devices
	drawHeaderSection = () => {
		const { chatObj, tab, t } = this.props;
		const userName = chatObj.userName
			? formatName(chatObj.userName)
			: t("chatObj.userName");
		let image = "";
		if (tab === "enquiries") {
			image =
				chatObj.artwork && chatObj.artwork.images
					? chatObj.artwork.images[0].src
					: PLACEHOLDER_IMG;
		} else {
			image =
				chatObj.artist && chatObj.artist.featuredImage
					? chatObj.artist.featuredImage.src
					: PLACEHOLDER_IMG;
		}
		return (
			<div className="ChatWindow__enquiry">
				<button
					className="enquiry__back-image"
					onClick={() => this.props.back()}
					style={{ padding: "0px" }}
				>
					<ARROW />
				</button>
				{image && (
					<div
						className="ChatList__side-container__enquiries__enquiry__image"
						style={{ backgroundImage: `url(${image})` }}
					/>
				)}
				<div
					className="ChatList__side-container__enquiries__enquiry__details"
					style={{ width: "60%" }}
				>
					<div className="ChatList__side-container__enquiries__enquiry__details__detail">
						{userName}
					</div>
					<div className="ChatList__side-container__enquiries__enquiry__details__detail">
						{chatObj.updated_chat
							? DateUtil.format(chatObj.updated_chat)
							: DateUtil.format(chatObj.updated_at)}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { newMessage, newChat } = this.state;
		const { data, chatObj, status, confirmed, webbLink, tab, isFetching, t } =
			this.props;

		let headerSection = null;
		if (chatObj) {
			headerSection = this.drawHeaderSection();
		}
		const messages = this.drawMessages();

		const artwork = (chatObj && chatObj.artwork) || null;
		const isSold = artwork && artwork.status && artwork.status === "sold";
		const isEmpty = !data && !isFetching;
		const isTerminated = !status && (!isFetching || !newChat) && data;
		const spinning = isFetching && (!data || newChat);
		const reserved = confirmed || isSold;

		if (isEmpty) {
			return (
				<div className="ChatWindow">
					{chatObj && <div>{headerSection}</div>}
					<div className="ChatWindow__empty">
						{t("empty")}
						<p>{t("emptySelect")}</p>
					</div>
				</div>
			);
		}

		return (
			<div className="ChatWindow">
				{isTerminated && (
					<div className="ChatWindow__confirmed ChatWindow__terminated">
						<p>{t("terminated")}</p>
					</div>
				)}
				{headerSection}
				<div
					className={
						"ChatWindow__message-window" + (!status ? " is-terminated" : "")
					}
					ref={(ref) => (this.scrollElem = ref)}
				>
					{spinning ? (
						<div className="ChatWindow__message-window__spinner">
							<Spinner spinnerName="cube-grid" noFadeIn />
						</div>
					) : (
						<div className="ChatWindow__message-window__scroll-window">
							{messages}
						</div>
					)}
				</div>
				{!reserved && tab !== "messages" && (
					<button
						onClick={() => this.confirmEnq()}
						disabled={!status}
						className="ChatWindow__confirmed ChatWindow__inverse"
					>
						<i>{t("confirmButton")}</i>
					</button>
				)}
				{reserved && tab !== "messages" && (
					<div
						className={
							"ChatWindow__confirmed" + (!status ? " is-terminated" : "")
						}
					>
						<p>{isSold ? t("sold") : t("purchaseLink1")}</p>
					</div>
				)}
				<div
					className={
						"ChatWindow__message-input" + (!status ? " is-terminated" : "")
					}
				>
					<textarea
						id="message-input"
						type="text"
						placeholder={t("typeHere")}
						value={newMessage}
						disabled={!status}
						onChange={(e) => this.updateMessage(e.target.value)}
						onKeyDown={this.chatKeyDown}
						rows={1}
						ref={(ref) => (this.messageInput = ref)}
						onFocus={this.handleFocus}
					/>
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.onDropRejected}
						className={"ChatWindow__message-input__dropzone"}
						multiple={true}
						maxSize={MAX_SIZE}
					>
						<div style={{ marginBottom: "7px" }}>+</div>
					</Dropzone>
					<button disabled={!status} onClick={() => this.sendMessage()}>
						{t("send")}
					</button>
				</div>
			</div>
		);
	}
}

export default withNamespaces("chat")(ChatWindow);
