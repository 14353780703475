import React, {Component} 	from 'react'
import Select								from 'react-select'
import { connect }					from 'react-redux'
import moment								from 'moment'
import { fetchOtherShows } 	from '../../../actions/ShowActions'


class ShowPicker extends Component {
	static propTypes = {
	}
	static defaultProps = {
		placeholder: 'Choose related shows...',
		show: {
			isFetching: false,
		}
	}
	constructor(props){
		super(props);
	}

	componentDidMount(){
		this.props.dispatch( fetchOtherShows(this.createOtherShowsQuery()) )
	}

	componentWillMount() {
    if(this.props.isSociety) {
      this.toggleExpanded()
    }
  }

	componentWillReceiveProps(newProps) {
	}

	createOtherShowsQuery = () => {
		const endOfToday = moment().endOf('day').toISOString()
		return {
			$or: [
				{ $and: [
					{ endDate: { $gt: endOfToday } },
					{ visibility: { '$nin': [ 'restricted' ] } },
					{ status: 'published' },
				]},
				{ _id: { $in: [] } }
			]
		}
	}

	selectShows = (selections) => {
		const shows = [].concat(this.props.show.shows);
		selections.forEach(selection => {
        const match = shows.find((entry) => (entry._id == selection._id));
        if (!match) {
					console.log('This is a new selection: ',selection);
        }
    });
    this.props.input.onChange(selections)
	}

	render() {
		const { show: { isFetching, otherShows: shows }, type, placeholder, input: { value } } = this.props;
		const showOptions = []
		Object.keys(shows).map((key) => {
			const show = shows[key]
			showOptions.push(show)
		})
		return (
			<div className="input-holder">
				<Select
					isLoading={ isFetching }
					onChange={ this.selectShows }
					options={ showOptions }
					value={ value }
					valueKey="_id"
					labelKey="title"
					placeholder={ placeholder }
					multi={ true }
					/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { show } = state
	return { show }
}

export default connect(mapStateToProps)(ShowPicker)
