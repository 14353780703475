import React, { Component } from "react";
import { connect } from "react-redux";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import { withNamespaces } from "react-i18next";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	fetchGallery,
	removeGallery,
	saveGallery,
	copyGallery,
} from "../../actions/GalleryActions";
import {
	submitPartialGallery,
	showSuccess,
	hideSuccess,
	initGallery,
	loadGallery,
	hideError,
} from "../../actions/FormActions/GalleryFormActions";
import InfoForm from "./InfoForm";

class InfoPage extends Component {
	componentWillMount() {
		if (this.props.organisation) {
			this.props.dispatch(fetchGallery(this.props.organisation));
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.isAdmin &&
			this.props.organisation !== nextProps.organisation
		) {
			this.props.dispatch(fetchGallery(nextProps.organisation));
		}

		if (
			this.props.gallery.gallery !== nextProps.gallery.gallery &&
			nextProps.gallery.gallery._id
		) {
			this.props.dispatch(loadGallery(nextProps.gallery.gallery));
		}
	}

	submitForm = () => {
		const {
			form: { gallery, initial },
		} = this.props;
		this.props.dispatch(submitPartialGallery(gallery, initial));
	};

	render() {
		const { isAdmin, gallery, form } = this.props;

		const galleryName = gallery.gallery ? gallery.gallery.name : "";

		return (
			<div className="info-page">
				<InfoForm
					onSubmit={this.submitForm}
					isAdmin={isAdmin}
					galleryName={galleryName}
					email={gallery.gallery.email}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin },
		gallery,
	} = state;

	const form = {
		submitting: isSubmitting("gallery")(state),
		submitSucceeded: hasSubmitSucceeded("gallery")(state),
		submitFailed: hasSubmitFailed("gallery")(state),
		gallery: getFormValues("gallery")(state),
		initial: getFormInitialValues("gallery")(state),
	};

	return { isAdmin, gallery, form };
}

const translated = withNamespaces("member")(InfoPage);
export default connect(mapStateToProps)(translated);
