import Api from "@artworkslab/sharedmodules/src/Api";

export const CREATE_INVOICE_REQUEST =
	"artworksapp/enquiries/CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS =
	"artworksapp/enquiries/CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILURE =
	"artworksapp/enquiries/CREATE_INVOICE_FAILURE";

//Sends data for enquiry invoice
export function createInvoice(artworkId, artistId, transId, data) {
	return (dispatch) => {
		dispatch(createInvoiceRequest());
		const body = {
			artworkId,
			artistId,
			transId,
			data,
		};
		const endpoint = "artworks/artworkinvoice";
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(createInvoiceSuccess(json));
				return "SUCCESS";
			})
			.catch((error) => {
				console.log("createInvoice failure", error);
				dispatch(createInvoiceFailure(error));
				return "FAILURE";
			});
	};
}

function createInvoiceRequest() {
	return {
		type: CREATE_INVOICE_REQUEST,
	};
}

function createInvoiceSuccess(json) {
	return {
		type: CREATE_INVOICE_SUCCESS,
		response: json,
	};
}

function createInvoiceFailure(err) {
	return {
		type: CREATE_INVOICE_FAILURE,
		message: err,
	};
}

export const MANUAL_ORDER_REQUEST =
	"artworksapp/enquiries/MANUAL_ORDER_REQUEST";
export const MANUAL_ORDER_SUCCESS =
	"artworksapp/enquiries/MANUAL_ORDER_SUCCESS";
export const MANUAL_ORDER_FAILURE =
	"artworksapp/enquiries/MANUAL_ORDER_FAILURE";

//Manually sends order confirmation to artist
export function sendManualOrder(changedValues) {
	return (dispatch) => {
		dispatch(sendManualOrderRequest());
		const body = {
			data: changedValues,
		};
		const endpoint = "artworks/manualorder";
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(sendManualOrderSuccess());
			})
			.catch((error) => {
				console.log("sendManualOrder failure", error);
				dispatch(sendManualOrderFailure(error));
			});
	};
}

function sendManualOrderRequest() {
	return {
		type: MANUAL_ORDER_REQUEST,
	};
}

function sendManualOrderSuccess(json) {
	return {
		type: MANUAL_ORDER_SUCCESS,
	};
}

function sendManualOrderFailure(err) {
	return {
		type: MANUAL_ORDER_FAILURE,
		message: err,
	};
}

export const ARTIST_INVOICES_REQUEST =
	"artworksapp/enquiries/ARTIST_INVOICES_REQUEST";
export const ARTIST_INVOICES_SUCCESS =
	"artworksapp/enquiries/ARTIST_INVOICES_SUCCESS";
export const ARTIST_INVOICES_FAILURE =
	"artworksapp/enquiries/ARTIST_INVOICES_FAILURE";

//Fetches invoices related to given artstistId
export function fetchArtistInvoices(artistId) {
	return (dispatch) => {
		dispatch(fetchArtistInvoicesRequest());
		const endpoint = `artworks/artistinvoices/${artistId}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchArtistInvoicesSuccess(json));
			})
			.catch((error) => {
				console.log("fetchArtistInvoices failure", error);
				dispatch(fetchArtistInvoicesFailure(error));
			});
	};
}

function fetchArtistInvoicesRequest() {
	return {
		type: ARTIST_INVOICES_REQUEST,
	};
}

function fetchArtistInvoicesSuccess(json) {
	return {
		type: ARTIST_INVOICES_SUCCESS,
		response: json,
	};
}

function fetchArtistInvoicesFailure(err) {
	return {
		type: ARTIST_INVOICES_FAILURE,
		message: err,
	};
}

export const REMOVE_INVOICE_REQUEST =
	"artworksapp/enquiries/REMOVE_INVOICE_REQUEST";
export const REMOVE_INVOICE_SUCCESS =
	"artworksapp/enquiries/REMOVE_INVOICE_SUCCESS";
export const REMOVE_INVOICE_FAILURE =
	"artworksapp/enquiries/REMOVE_INVOICE_FAILURE";

export function removeInvoice(invoiceId) {
	return (dispatch) => {
		dispatch(removeInvoiceRequest());
		const endpoint = `artworks/invoice/remove/${invoiceId}`;
		return Api(endpoint, "POST", true)
			.then((json) => {
				dispatch(removeInvoiceSuccess(json));
			})
			.catch((error) => {
				console.log("removeInvoice failure", error);
				dispatch(removeInvoiceFailure(error));
			});
	};
}

function removeInvoiceRequest() {
	return {
		type: REMOVE_INVOICE_REQUEST,
	};
}

function removeInvoiceSuccess(json) {
	return {
		type: REMOVE_INVOICE_SUCCESS,
		response: json,
	};
}

function removeInvoiceFailure(err) {
	return {
		type: REMOVE_INVOICE_FAILURE,
		message: err,
	};
}
