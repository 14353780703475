import React from 'react'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import ArtworkFormResponsive from './ArtworkFormResponsive'

class ArtworkResponsive extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <ArtworkFormResponsive />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const translated = withNamespaces('artworks')(ArtworkResponsive)
export default connect(mapStateToProps)(translated)
