import {
  FETCH_SUBSCRIBERS_REQUEST, FETCH_SUBSCRIBERS_SUCCESS, FETCH_SUBSCRIBERS_FAILURE,
  FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE,
} from '../actions/SubscriptionActions'

export function subscriptionReducer(state = {
  subscribers: {},
  previewData: null,
  isFetching: false,
  error: null,
}, action) {
  switch(action.type) {
    case FETCH_SUBSCRIBERS_REQUEST: {
      return Object.assign({}, state, {
        subscribers: [],
        isFetching: true,
        error: null,
      })
    }
    case FETCH_SUBSCRIBERS_SUCCESS: {
      return Object.assign({}, state, {
        subscribers: action.response,
        isFetching: false,
        error: null,
      })
    }
    case FETCH_SUBSCRIBERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      })
    }
    case FETCH_DATA_REQUEST: {
      return Object.assign({}, state, {
        [action.clearName]: action.clearValue,
        isFetching: true,
        error: null,
      })
    }
    case FETCH_DATA_SUCCESS: {
      return Object.assign({}, state, {
        [action.responseName]: action.response,
        isFetching: false,
        error: null,
      })
    }
    case FETCH_DATA_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      })
    }
    default: {
      return state
    }
  }
}
