import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";
import {
	toggleUpcomingShows,
	fetchData,
} from "../../../actions/DashboardActions/UpcomingShowsActions";
import ExpandButton from "../ExpandButton/ExpandButton";
import ItemStats from "../ItemStats/ItemStats";

class UpcomingShows extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.dispatch(fetchData());
	}

	render() {
		const { expanded, data, shows } = this.props;
		const header = "Upcoming shows";

		return (
			<div className="UpcomingShows">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(toggleUpcomingShows())}
				/>
				<h4 className="underlined">{header}</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded ? (
							<div className="UpcomingShows__Content">
								{shows.length === 0 ? (
									<p className="UpcomingShows__Content__no_shows">
										Do you have any upcoming shows? Don't forget to{" "}
										<a href="/shows/new">add them here!</a>
									</p>
								) : null}
								{shows.map((show, index) => {
									show.stats = [
										{ title: "Show", value: show.title },
										{ title: "Visits", value: show.visits },
										{ title: "Followers", value: show.likes },
									];
									return (
										<ItemStats
											image={{
												backgroundImage: `url(${show.featuredImage.src})`,
											}}
											stats={show.stats}
											key={`upcomingShow_${index}`}
										/>
									);
								})}
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { upcomingShows } = state;
	const { expanded, data, shows } = upcomingShows;
	return { expanded, data, shows };
}

export default connect(mapStateToProps)(UpcomingShows);
