import React, { Component } from "react";
import { formatNumber } from "../../../utils/Helpers";

class LeastMostPopular extends Component {
	constructor(props) {
		super(props);
	}

	drawArtworksList = () => {
		const { artworks, t } = this.props;

		const artSection = [];
		artworks.forEach((art, i) => {
			const visits = art.visits ? formatNumber(art.visits) : null;
			artSection.push(
				<div className="LeastMostPopular__Content__Data" key={i}>
					<div
						className="LeastMostPopular__Content__Data__Image"
						style={{ backgroundImage: `url(${art.artwork.images[0].src})` }}
					></div>
					{visits && (
						<div className="LeastMostPopular__Content__Data__Details">
							{/*<h4 className="artist small">{ art.artwork.title }</h4>*/}
							<h4 className="artist">{t("totalVisits")}</h4>
							<p>{visits}</p>
						</div>
					)}
				</div>
			);
		});

		return artSection;
	};

	render() {
		const artSection = this.drawArtworksList();

		return <div className="LeastMostPopular__Content">{artSection}</div>;
	}
}

export default LeastMostPopular;
