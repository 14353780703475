import React, { Component } from "react";
// import PropTypes from "prop-types";
import { Field, Fields, FormSection, reduxForm } from "redux-form";
import { COUNTRIES_SELECTED, ROLES } from "./FormValues";
import TextInput from "../../components/FormInput/TextInput";
import Select from "../../components/FormInput/Select";
import UserRolePicker from "../../components/FormComponents/UserRolePicker/UserRolePicker";
import AdditionalSettings from "./AdditionalSettings";

// const propTypes = {
// 	onSubmit: PropTypes.func.isRequired,
// 	isAdmin: PropTypes.bool,
// };

const MEMBERSHIPS = [
	{ name: "BAS", value: "free" },
	{ name: "PRO", value: "premium" },
];

let AdminAccountForm = (props) => {
	const { handleSubmit, pristine, submitting, isAdmin, user } = props;

	return (
		<form onSubmit={handleSubmit} className="Form">
			<div className="fieldset-block fieldset-block--nopadding">
				<legend>General information</legend>
				<div className="input-holder input-holder--full">
					<Field name="name" component={TextInput} placeholder="User name" />
				</div>
				<div className="input-holder input-holder--full">
					<Field
						name="email"
						component={TextInput}
						placeholder="Email"
						required
					/>
				</div>
				<div className="input-holder input-holder--full">
					<Field
						name="password"
						component={TextInput}
						placeholder="New password"
						type="password"
					/>
				</div>
				<div className="input-holder input-holder--full">
					<Field
						name="country"
						component={Select}
						options={COUNTRIES_SELECTED}
					/>
				</div>
				<Fields
					names={["gallery", "artist", "role", "secondaryRole"]}
					component={UserRolePicker}
				/>
				{/* When Admin changed gallery of User, gallery did not update, wrong additional setting was saved.
          Removing this for now. */}
				{false && user && user.role === "society" && (
					<div
						className="input-holder input-holder--full"
						style={{ marginBottom: "1em" }}
					>
						{/* These field names do not belong to the user model,  but to the gallery model. Added to user in API. */}
						<Fields
							names={[
								"applicationEmailAddress",
								"applicationEmailAddressCopy",
								"layout",
							]}
							component={AdditionalSettings}
							gallery={user.gallery || false}
						/>
					</div>
				)}
				<div className="input-holder">
					<legend className="legend-nomargin">Registered through</legend>
					<Field
						name="registeredThrough"
						component={TextInput}
						placeholder="No url"
						disabled={true}
					/>
				</div>
			</div>
			{isAdmin && (
				<div className="fieldset-block fieldset-block--nopadding">
					<legend style={{ width: "50%" }}>
						Premium membership, Aw Club, (Admin)
					</legend>
					<div className="input-holder">
						<Field
							name="billing.membershipLevel"
							options={MEMBERSHIPS}
							component={Select}
						/>
					</div>
					<div className={"input-holder"}>
						<Field
							name="stripeCustomerId"
							component={TextInput}
							placeholder="stripeCustomerId"
						/>
					</div>
				</div>
			)}

			{/*Save button*/}
			<div className="fieldset">
				<div className="fixed-save-button">
					<button
						className="purple-button--wide"
						type="submit"
						disabled={pristine || submitting}
					>
						Save
					</button>
				</div>
			</div>
		</form>
	);
};

AdminAccountForm = reduxForm({
	form: "adminAccount",
})(AdminAccountForm);

export default AdminAccountForm;
