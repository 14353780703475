import React, { Component } from 'react'
import { connect }          from 'react-redux'
import Formsy               from 'formsy-react'
import { withNamespaces }   from 'react-i18next'
import Input                from '../../components/Input/Input'

class CreateMember extends Component {
	constructor(props) {
		super(props);
    this.state = {
			canSubmit: false,
			emailAddresses: '',
		}
	}

  componentWillUnmount() {
    console.log('componentWillUnmount');
  }

  enableSubmit = () => {
		this.setState({
			canSubmit: true
		})
	}

	disableSubmit = () => {
		this.setState({
			canSubmit: false
		})
	}

	submit = (model) => {
		this.setState({
			emailAddresses: '',
		})
		this.props.onSubmit(model)
	}

	handleEmailInput = (value) => {
		this.setState({
			emailAddresses: value,
		})
	}

	render() {
    const { isFetching, displayStyle, t } = this.props
    const { canSubmit, emailAddresses} = this.state
		return (
			<div style={{paddingBottom: '20px', display: displayStyle}}>
				<Formsy.Form onValidSubmit={this.submit} onValid={this.enableSubmit} onInvalid={this.disableSubmit}>
					<Input name="email" placeholder={t('addMemberInput')} value={ emailAddresses } onChange={(value) => this.handleEmailInput(value)} required />
          <button type="submit" disabled={isFetching || !canSubmit}>{t('addMember')}</button>
				</Formsy.Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
  // console.log('state', state);
  const { isFetching } = state.artSociety
  return { isFetching }
}

const translated = withNamespaces('member')(CreateMember)
export default connect(mapStateToProps)(translated)
