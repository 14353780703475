import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";

class EmailMaker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disabled: false,
		};
	}

	//Resets disabled to false if recipients are added
	componentWillReceiveProps(nextProps) {
		if (
			this.props.hasRecipient !== nextProps.hasRecipient &&
			nextProps.hasRecipient
		) {
			this.setDisabled(!nextProps.hasRecipient);
		}
	}

	//Sets disabled value in state
	setDisabled = (value) => {
		this.setState({
			disabled: value,
		});
	};

	//Updates the message to be sent in parent
	updateMessage = (key, value) => {
		let message = Object.assign({}, this.props.message);
		message[key] = value;
		this.props.setMessage(message);
	};

	//Checks wether any recipient(s) are added and displays warning if not
	checkRecipient = () => {
		const { hasRecipient, t } = this.props;
		if (!hasRecipient) {
			window.alert(t("warning.recipient"));
		}
		this.setDisabled(!hasRecipient);
	};

	render() {
		const { message, attachments, t } = this.props;
		const { disabled } = this.state;

		return (
			<div className="writer responsive-form">
				<div
					className={`text-container subject ${
						message.subject.length < 1 ? "is-empty" : ""
					}`}
				>
					<textarea
						name="subject-area"
						className="responsive-form"
						placeholder={t("artistEmail.subject")}
						value={message.subject}
						onChange={(e) => this.updateMessage("subject", e.target.value)}
						onFocus={() => this.checkRecipient()}
						disabled={disabled}
					></textarea>
				</div>
				<div
					className={`text-container message ${
						message.text.length < 1 ? "is-empty" : ""
					}`}
				>
					<textarea
						className="list-and-upload__mail-containter__message responsive-form"
						name="message-area"
						placeholder={t("artistEmail.message")}
						value={message.text}
						onChange={(e) => this.updateMessage("text", e.target.value)}
						onFocus={() => this.checkRecipient()}
						disabled={disabled}
					></textarea>
					{attachments.length > 0 && (
						<div className={"added-items"}>
							{`${attachments.length} ${
								attachments.length > 1
									? t("artistEmail.addedMulti")
									: t("artistEmail.addedSingle")
							}`}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withNamespaces("emails")(EmailMaker);
