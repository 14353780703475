import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import {
	submit,
	startSubmit,
	formValueSelector,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import RegisterForm from "./RegisterForm";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import {
	initRegisterUser,
	submitRegisterUser,
} from "../../actions/FormActions/RegisterFormActions";
import Modal from "../../components/Modal/Modal";

const DEVICES = require("../../../assets/images/devices.png");
const DEVICES_KF = require("../../../assets/images/devices_kf.png");
const DEVICES_KONSTNARSHUSET = require("../../../assets/images/portal_konstnarshuset.png");
const MOBILE_MOVIE =
	"https://artworks-wp-images.s3-eu-west-1.amazonaws.com/kurppa_hosk-artworks-hero.mp4";

class Register extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			modalMessage: "",
			signUpAsArtSociety: false,
			signUpAsGallery: false,
			signUpAsArtist: false,
			signUpForKonstnarshuset: false,
			captchaVerified: false,
		};
	}

	componentWillReceiveProps(newProps) {
		// redirect to new page
		if (newProps.authenticated) {
			this.redirectToDashboard(newProps.user);
		}

		// Reset captcha when closing or changing form.
		if (this.props.form.selectedRole && !newProps.form.selectedRole) {
			this.setState({
				captchaVerified: false,
			});
		}

		if (!this.props.error && newProps.error) {
			let message = newProps.error;
			if (message === "Email already taken" && i18next.language === "sv")
				message = "Emailadressen redan tagen";
			this.toggleModal(message);
		}
	}

	componentWillMount() {
		const {
			location: { query },
			route,
			authenticated,
			user,
		} = this.props;

		const signUpAsArtSociety = route.path && route.path === "artsociety";
		const signUpAsGallery = route.path && route.path === "gallery";
		const signUpAsArtist = route.path && route.path === "artist";
		const signUpForKonstnarshuset =
			route.path && route.path === "konstnarshuset";
		const artistId = signUpAsArtist && query.artistid ? query.artistid : false;

		this.setState({
			signUpAsArtSociety,
			signUpAsGallery,
			signUpAsArtist,
			signUpForKonstnarshuset,
			artistId,
		});

		if (authenticated) {
			this.redirectToDashboard(user);
		} else {
			this.props.dispatch(initRegisterUser(query));
		}
	}

	redirectToDashboard = (user) => {
		const isSociety = !!(user.role === "society");

		if (!isSociety) {
			this.props.router.push("/dashboard");
		} else {
			this.props.router.push("/gallery");
		}
	};

	handleSubmit = () => {
		const {
			form: { register },
			location: { pathname },
		} = this.props;
		const {
			signUpAsArtSociety,
			signUpAsGallery,
			signUpAsArtist,
			signUpForKonstnarshuset,
			artistId,
		} = this.state;

		if (signUpAsArtSociety) {
			register.role = "society";
		} else if (signUpAsGallery) {
			register.role = "author";
		} else if (signUpAsArtist) {
			register.role = "artist";
			if (artistId) register.artist = { _id: artistId };
		} else if (signUpForKonstnarshuset) {
			register.role = "artist";
			register.memberships = [
				{
					// Set this on users artist in Api models/user.js
					association: "5762cd9fba1134c325171183", // Konstnärshuset
					approved: true,
				},
			];
		}

		if (pathname) register.registeredThrough = `${pathname} in Portal`;

		// Must return promise or "submitting" in form is not reset after error.
		return this.props.dispatch(submitRegisterUser(register));
	};

	toggleModal = (message = "") => {
		this.setState({
			showModal: !this.state.showModal,
			modalMessage: message,
		});
	};

	videoError = (e) => {
		console.log("Video error", e);
	};

	onVerifyCaptcha = (token) => {
		this.setState({
			captchaVerified: true,
		});
	};

	onExpireCaptcha = () => {
		this.setState({
			captchaVerified: false,
		});
	};

	render() {
		const {
			showModal,
			modalMessage,
			signUpAsArtSociety,
			signUpAsGallery,
			signUpAsArtist,
			signUpForKonstnarshuset,
		} = this.state;
		const {
			form: { mustAcceptTC, selectedRole },
			location: { query },
			route,
			t,
		} = this.props;
		const { id, role } = query;
		const roleSet = !!(id && role);

		// const signUpHeader = signUpAsArtSociety ? t('login.registerArtSociety') : t('login.register')

		// let information = i18next.language === 'sv'
		// ? [
		// 	<h4 key="1">Skaffa profil på Artworks!</h4>,
		// 	<p key="2">Exponera dig och din konst på Sveriges mest kvalitativa marknadsplats för konst.</p>,
		// 	<p key="3">Ett digitalt verktyg som enkelt samlar all din konst, dina aktiviteter och kontakter på en och samma plats.</p>,
		// ]
		// : [
		// 	<h4 key="1">Create your profile with Artworks!</h4>,
		// 	<p key="2">Artworks is a simple digital tool to enchance your online visibility and to establish new relationships.</p>,
		// ]

		// if (signUpAsArtist) {
		// 	information = i18next.language === 'sv'
		// 	? [
		// 		<h4 key="1">Skaffa profil på Artworks!</h4>,
		// 		<p key="2">Exponera dina aktiviteter och din konst på Sveriges största marknadsplats för konst. Skaffa nya utställningsmöjligheter och få förmåner som till exempel rabatter på produktion, inramning och logistik.</p>,
		// 		<p key="3">I din profil på Artworks ingår en mängd finesser. Du får bland annat info om hur många som besöker din profil, vilka konstverk som folk är inne och kikar på och kan enkelt skapa inbjudningar till events så som öppningar och konstnärssamtal.</p>
		// 	]
		// 	: [
		// 		<h4 key="1">Create your profile at Artworks!</h4>,
		// 		<p key="2">Expose your programme, activities and your art with Sweden’s largest marketplace for art. Establish relationships with exhibition partners and get favourable deals on things such as framing and production equipments.</p>,
		// 		<p key="3">Your profile with Artwork comes with plenty of smart functionality. You get information and learn  about your followers and the people interacting with your profile. For example, you’ll find insights such as which artworks people are looking at. You can also create invitations to events such as openings and artist talks.</p>
		// 	]
		// }
		//
		// if (signUpAsArtSociety) information = []
		//
		// if (signUpForKonstnarshuset) {
		// 	information = [
		// 		<h4 key="1">Skaffa profil på Artworks!</h4>,
		// 		<p key="2">Välkommen till Konstnärshusets Bilddatabas i samarbete med Artworks. Som medlem i Konstnärshuset kan du som konstnär, utan någon kostnad, ladda upp din konst till salu.</p>,
		// 		<p key="3">I din profil på Artworks ingår en mängd finesser. Du får bland annat info om hur många som besöker dina utställningar, vilka konstnärer du ställer ut, vilka konstverk som folk är inne och kikar på och enkelt skapa inbjudningar till events så som öppningar och konstnärssamtal.</p>
		// 	]
		// }

		let imageSrc = DEVICES;
		if (signUpAsArtSociety) imageSrc = DEVICES_KF;
		if (signUpForKonstnarshuset) imageSrc = DEVICES_KONSTNARSHUSET;

		const faq = "https://artworks.se/faq";

		return (
			<MainResp contentClassName="slim-content">
				<div className="register-new">
					<div className="register-new__content">
						{/*<div className={ register('content')('info-section') }>
								<img src={ imageSrc } className={ register('content')('info-section')('picture').state({ marginbottom: signUpForKonstnarshuset }) } />
								<div className={ register('content')('info-section')('text') }>
									{ information }
								</div>
							</div>*/}
						{/*<div className={ register('content')('info-section') }>
								<div className={ register('content')('info-section')('video-container').state({ marginbottom: signUpForKonstnarshuset }) }>
									<video className={ register('content')('info-section')('video-container')('video').state({ marginbottom: signUpForKonstnarshuset }) }
										autoPlay loop muted playsInline onError={e => this.videoError(e)}>
										<source src={ MOBILE_MOVIE } type="video/mp4" />
									</video>
								</div>
							</div>*/}
						{!signUpAsArtist && !signUpAsArtSociety && (
							<div className="register-new__content__register-section">
								<RegisterForm
									mustAcceptTC={mustAcceptTC}
									onSubmit={this.handleSubmit}
									roleSet={roleSet}
									selectedRole={selectedRole}
									signUpAsArtSociety={signUpAsArtSociety}
									signUpAsGallery={signUpAsGallery}
									signUpAsArtist={signUpAsArtist}
									signUpForKonstnarshuset={signUpForKonstnarshuset}
									t={t}
									updateOnLangChange={this.props.update_DO_NOT_REMOVE}
									captchaVerified={this.state.captchaVerified}
									onVerifyCaptcha={this.onVerifyCaptcha}
									onExpireCaptcha={this.onExpireCaptcha}
								/>
							</div>
						)}
						{/*<div className={ register('content')('info-section')}>
								{information}
								<a className="purple-button--wide" href={} target='_blank' style={{marginTop: '50px'}}>{t('contactUs')}</a>
								<a className="purple-button--wide" href={faq} target='_blank'>{t('questions')}</a>
							</div>*/}
						{signUpAsArtist && (
							<div className="register-new__content__about-us-link">
								<p>{t("login.aboutUs")}</p>
								<a href="http://artworks.se/pro" target="_blank">
									{t("login.aboutUsLink")}
								</a>
							</div>
						)}
						<div className="register-new_content__login-link">
							<p>{t("login.isRegistered")}</p>
							<Link to="/login">{t("login.logInHere")}</Link>
						</div>
					</div>
					{showModal ? (
						<Modal type="small" onCloseClick={this.toggleModal}>
							<h1 style={{ marginBottom: "1em" }}>{modalMessage}</h1>
							<button
								className="purple-button--wide button-nomargin"
								onClick={this.toggleModal}
							>
								Ok
							</button>
						</Modal>
					) : null}
				</div>
			</MainResp>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { authenticated, user, error },
	} = state;
	const form = {
		submitting: isSubmitting("register")(state),
		submitSucceeded: hasSubmitSucceeded("register")(state),
		submitFailed: hasSubmitFailed("register")(state),
		register: getFormValues("register")(state),
		initial: getFormInitialValues("register")(state),
		// mustAcceptTC: formValueSelector('register')(state, 'role') === 'author',
		mustAcceptTC: true,
		selectedRole: formValueSelector("register")(state, "role"),
	};
	return { form, authenticated, user, error };
}

const translated = withNamespaces("common")(Register);
export default connect(mapStateToProps)(withRouter(translated));
