import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ArtistProLink from "../../../components/ArtworksLite/ArtistProLink";
import { createWebbLink } from "../../../utils/Helpers";

class ArtistProModals extends Component {
	constructor(props) {
		super(props);
	}

	getProModal = (content) => {
		return (
			<div className="ArtistProModals__modal-container">
				<div className="ArtistProModals__pro-modal">{content}</div>
			</div>
		);
	};

	getResponsiveModal = (content) => {
		return (
			<div className="ArtistProModals__modal-container">
				<div className="ArtistProModals__small-content">{content}</div>
			</div>
		);
	};

	getDashboardModal = () => {
		const content = (
			<div>
				<p>
					Här kan du som <i>PRO</i> få insyn i hur din profil presterar och
					vilka verk som får mest uppmärksamhet.
				</p>
				<p>
					Läs mer och prova <ArtistProLink children={"här"} />!
				</p>
				<p>Artworks 🌟</p>
			</div>
		);
		return this.getProModal(content);
	};

	getEnqModal = () => {
		const content = (
			<div>
				<p>
					Svara, skicka och håll koll. Alla dina förfrågningar på ett och samma
					ställe.
				</p>
				{/*<p>Under Juli fortsätter vi fira 100! Därmed erbjuder vi <b>alla nya</b> <i>PRO</i> <b>medlemmar halva priset</b> vid årlig fakturering.</p>*/}
				<p>
					<i>PRO</i> konstnärer har flest besök i sina profiler, står för ¼ av
					all försäljning 2021* och har lägre kommission.
				</p>
				<p>
					Läs mer och testa <i>PRO</i> gratis <ArtistProLink children={"här"} />
					! 🎨
				</p>
				<p style={{ color: "#a8a8a8", fontSize: "20px", paddingTop: "20px" }}>
					Psst.. i Bas fungerar förfrågningar som tidigare.
				</p>
				<div className="modal-footnote">*Enligt data från 18/8 2021</div>
			</div>
		);
		return this.getProModal(content);
	};

	getArtSocietyModal = () => {
		const content = (
			<div>
				<p>Innehållet på denna sida är begränsat.</p>
				<p>
					Läs mer och testa{" "}
					<i>
						<b>Premium</b>
					</i>{" "}
					<Link to="/settings">här</Link>!
				</p>
			</div>
		);

		return this.getProModal(content);
	};

	getArtSocietyModalForTiers = (availableFor) => {
		const content = (
			<div>
				{availableFor ? (
					<p>{`Innehållet på denna sida är begränsat till ${availableFor}.`}</p>
				) : (
					<p>Innehållet på denna sida är begränsat.</p>
				)}
				<p>
					Läs mer och uppgradera{" "}
					<a href={createWebbLink("konstforening", false)} target="_blank">
						här
					</a>
					!
				</p>
			</div>
		);

		return this.getResponsiveModal(content);
	};

	render() {
		const { type, availableFor = "" } = this.props;
		const styling =
			type === "artSociety" && __USE_TIERS__
				? { background: "rgba(0,0,0,.5)" }
				: {};

		return (
			<div className="ArtistProModals" style={styling}>
				{type === "dashboard" && this.getDashboardModal()}
				{type === "enquiries" && this.getEnqModal()}
				{type === "artSociety" && !__USE_TIERS__ && this.getArtSocietyModal()}
				{
					type === "artSociety" &&
						__USE_TIERS__ &&
						this.getArtSocietyModalForTiers(availableFor)
					// this.getArtSocietyModal(availableFor)
				}
			</div>
		);
	}
}

export default connect(null)(ArtistProModals);
