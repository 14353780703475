import {
  RAFFLE_MAIL_PREVIEW_REQUEST, RAFFLE_MAIL_PREVIEW_SUCCESS, RAFFLE_MAIL_PREVIEW_FAILURE, RAFFLE_MAIL_PREVIEW_RESET,
} from '../actions/RaffleActions'

export function raffleReducer(state = {
  preview: '',
  isFetching: false,
  error: null,
}, action) {
  switch(action.type) {
    case RAFFLE_MAIL_PREVIEW_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
				preview: '',
				error: null,
      })
    }
    case RAFFLE_MAIL_PREVIEW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				preview: action.response,
			})
		}
		case RAFFLE_MAIL_PREVIEW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'raffleMailPreviewError',
			})
		}
    case RAFFLE_MAIL_PREVIEW_RESET: {
			return Object.assign({}, state, {
				preview: '',
			})
		}
    default:
      return state
  }
}
