import React from "react";

const CLOSE = require("../../../assets/images/icons/close@2x.png");

const TinySelect = ({
	artworkId,
	field,
	options,
	selectedOption,
	isOpen,
	doOpen,
	doClose,
	update,
	isResponsive,
	t,
}) => {
	if (selectedOption === "in_review") {
		// Need this check to prevent crash. Artworks nowadays aren't supposed to be in review, only draft and published.
		return <div className="tiny-select">{t("common:status.in_review")}</div>;
	}

	// For responsive, show choice as modal.
	if (!isOpen || isResponsive) {
		const option = options.find((option) => option.value === selectedOption);
		return (
			<div className="tiny-select">
				<div className={`tiny-select__option ${option.value}`} onClick={doOpen}>
					{/* options.find(option => option.value === selectedOption).label */}
					{option.label}
				</div>
			</div>
		);
	}

	return (
		<div className="tiny-select">
			<img src={CLOSE} className="tiny-select__close" onClick={doClose} />
			<div>
				{options.map((option) => {
					return (
						<div
							className={`tiny-select__option ${option.value}`}
							onClick={() => {
								option.value !== selectedOption
									? update(
											{ _id: artworkId, [field]: option.value },
											false,
											doClose
									  )
									: doClose();
							}}
							key={option.value}
						>
							{option.label}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default TinySelect;
