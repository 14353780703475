import {
  CREATE_INVOICE_REQUEST, CREATE_INVOICE_SUCCESS, CREATE_INVOICE_FAILURE,
  MANUAL_ORDER_REQUEST, MANUAL_ORDER_SUCCESS, MANUAL_ORDER_FAILURE,
  ARTIST_INVOICES_REQUEST, ARTIST_INVOICES_SUCCESS, ARTIST_INVOICES_FAILURE,
  REMOVE_INVOICE_REQUEST, REMOVE_INVOICE_SUCCESS, REMOVE_INVOICE_FAILURE,
} from '../actions/ArtworkInvoiceActions'

export function artworkInvoiceReducer(state = {
  isFetching: false,
  invoice: {},
  invoices: []
}, action) {
  switch (action.type) {
    case REMOVE_INVOICE_REQUEST:
    case ARTIST_INVOICES_REQUEST:
    case MANUAL_ORDER_REQUEST:
    case CREATE_INVOICE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case CREATE_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        invoice: action.response
      });
    case ARTIST_INVOICES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        invoices: action.response
      })
    case REMOVE_INVOICE_SUCCESS:
      //Find item index and remove from list
      let invoiceList = state.invoices.slice()
      const index = invoiceList.findIndex(x => x._id === action.response)
      invoiceList.splice(index, 1)

      return Object.assign({}, state, {
        isFetching: false,
        invoices: invoiceList
      })
    case REMOVE_INVOICE_FAILURE:
    case ARTIST_INVOICES_FAILURE:
    case MANUAL_ORDER_SUCCESS:
    case MANUAL_ORDER_FAILURE:
    case CREATE_INVOICE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state
  }
}
