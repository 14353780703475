import React from "react";

class TitleInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prevTitle: "",
			inputValue: "",
			hasFocus: false,
			useRegularInput: false,
		};
	}

	componentDidMount() {
		this.setState({
			inputValue: this.props.isAutoTitle ? "" : this.props.input.value,
			prevTitle: this.props.input.value,
			useRegularInput: this.props.isAutoTitle ? false : true,
		});
	}

	fakeInputGotFocus = (e) => {
		this.setState({
			hasFocus: true,
		});
	};

	realInputWasBlurred = (e, isAutoTitle) => {
		const { prevTitle } = this.state;
		const newTitle = e.target.value;

		this.setState(
			{
				hasFocus: false,
			},
			() => {
				if (newTitle) {
					this.setState(
						{
							prevTitle: newTitle,
							useRegularInput: true,
						},
						() => {
							// Update form with new title.
							this.props.input.onChange(newTitle);
							this.props.input.onBlur(newTitle);
						}
					);
				} else {
					// If leaving field empty, update form with last title.
					this.props.input.onChange(prevTitle);
					this.props.input.onBlur(prevTitle);
				}
			}
		);
	};

	inputChanged = (e) => {
		const title = e.target.value;
		this.setState({
			inputValue: title,
		});
	};

	render() {
		const {
			input: { value },
			type = "text",
			isAutoTitle,
			required,
			t,
		} = this.props;
		const { prevTitle, inputValue, useRegularInput } = this.state;
		const empty = value === "";
		const valid = !empty;

		return (
			<div className="Input title-input-container">
				{/* If auto generated title, show input that disappears when clicked. */}
				{isAutoTitle && !this.state.hasFocus && !useRegularInput && (
					<input
						className="title-input is-empty"
						type={type}
						disabled={this.props.disabled}
						value={""}
						placeholder={
							t("artworks:single.titleDesc") + " " + this.props.input.value
						}
						onFocus={this.fakeInputGotFocus}
					/>
				)}
				{/* If auto generated title, this input will show empty value instead of title from start.
            autoFocus to give input focus as soon as it is rendered. */}
				{isAutoTitle && this.state.hasFocus && !useRegularInput && (
					<input
						{...this.props.input}
						className={`title-input ${!inputValue ? "empty" : ""}`}
						type={type}
						value={inputValue}
						onChange={this.inputChanged}
						disabled={this.props.disabled}
						placeholder={this.props.placeholder}
						onBlur={(e) => this.realInputWasBlurred(e, isAutoTitle)}
						autoFocus
					/>
				)}
				{/* If not auto generated title, show a regular form input field.
            useRegularInput to not swap back to inputs for auto title if entering title resembling such. */}
				{useRegularInput && (
					<input
						{...this.props.input}
						className={`title-input ${!this.props.input.value ? "empty" : ""}`}
						type={type}
						disabled={this.props.disabled}
						placeholder={this.props.placeholder}
					/>
				)}
			</div>
		);
	}
}

export default TitleInput;
