export const COUNTRIES = [{
	name: "Sweden",
	value: "sweden"
},{
	name: "Norway",
	value: "norway"
},{
	name: "Finland",
	value: "finland"
},{
	name: "Iceland",
	value: "iceland"
},{
	name: "Denmark",
	value: "denmark"
}]

export const ROLES = [{
	name: "Admin",
	value: "admin"
},{
	name: "Author",
	value: "author"
},{
	name: "Society",
	value: "society"
},{
	name: "Artist",
	value: "artist"
},{
	name: "User",
	value: "user"
}]

export const COUNTRIES_SELECTED = [
	{"name": "Sweden", 		"value": "sweden"},
	{"name": "Åland", 		"value": "åland"},
	{"name": "Canada",		"value": "canada"},
	{"name": "Croatia",		"value": "croatia"},
	{"name": "Denmark", 	"value": "denmark"},
	{"name": "Estonia", 	"value": "estonia"},
	{"name": "Finland", 	"value": "finland"},
	{"name": "France",		"value": "france"},
	{"name": "Germany",		"value": "germany"},
	{"name": "Iceland", 	"value": "iceland"},
	{"name": "Italy",			"value": "italy"},
	{"name": "Latvia", 		"value": "latvia"},
	{"name": "Lithuania", "value": "lithuania"},
	{"name": "Norway", 		"value": "norway"},
	{"name": "Poland", 		"value": "poland"},
	{"name": "Portugal", 	"value": "portugal"},
	{"name": "Russia", 		"value": "russia"},
	{"name": "Serbia",		"value":"serbia"},
	{"name": "Spain",			"value": "spain"},
	{"name": "Turkey",		"value": "turkey"},
	{"name": "United Kingdom",						"value": "united kingdom"},
	{"name": "United States of America",	"value": "united states of america"},
]

export const COUNTRIES_ALL_ENG = [
	{"name":"Select","value":"placeholder"},
	{"name":"Afghanistan","value":"afghanistan"},
	{"name":"Åland Islands","value":"åland islands"},
	{"name":"Albania","value":"albania"},
	{"name":"Algeria","value":"algeria"},
	{"name":"American Samoa","value":"american samoa"},
	{"name":"Andorra","value":"andorra"},
	{"name":"Angola","value":"angola"},
	{"name":"Anguilla","value":"anguilla"},
	{"name":"Antarctica","value":"antarctica"},
	{"name":"Antigua and Barbuda","value":"antigua and barbuda"},
	{"name":"Argentina","value":"argentina"},
	{"name":"Armenia","value":"armenia"},
	{"name":"Aruba","value":"aruba"},
	{"name":"Australia","value":"australia"},
	{"name":"Austria","value":"austria"},
	{"name":"Azerbaijan","value":"azerbaijan"},
	{"name":"Bahamas","value":"bahamas"},
	{"name":"Bahrain","value":"bahrain"},
	{"name":"Bangladesh","value":"bangladesh"},
	{"name":"Barbados","value":"barbados"},
	{"name":"Belarus","value":"belarus"},
	{"name":"Belgium","value":"belgium"},
	{"name":"Belize","value":"belize"},
	{"name":"Benin","value":"benin"},
	{"name":"Bermuda","value":"bermuda"},
	{"name":"Bhutan","value":"bhutan"},
	{"name":"Bolivia (Plurinational State of)","value":"bolivia (plurinational state of)"},
	{"name":"Bonaire, Sint Eustatius and Saba","value":"bonaire, sint eustatius and saba"},
	{"name":"Bosnia and Herzegovina","value":"bosnia and herzegovina"},
	{"name":"Botswana","value":"botswana"},
	{"name":"Bouvet Island","value":"bouvet island"},
	{"name":"Brazil","value":"brazil"},
	{"name":"British Indian Ocean Territory","value":"british indian ocean territory"},
	{"name":"United States Minor Outlying Islands","value":"united states minor outlying islands"},
	{"name":"Virgin Islands (British)","value":"virgin islands (british)"},
	{"name":"Virgin Islands (U.S.)","value":"virgin islands (u.s.)"},
	{"name":"Brunei Darussalam","value":"brunei darussalam"},
	{"name":"Bulgaria","value":"bulgaria"},
	{"name":"Burkina Faso","value":"burkina faso"},
	{"name":"Burundi","value":"burundi"},
	{"name":"Cambodia","value":"cambodia"},
	{"name":"Cameroon","value":"cameroon"},
	{"name":"Canada","value":"canada"},
	{"name":"Cabo Verde","value":"cabo verde"},
	{"name":"Cayman Islands","value":"cayman islands"},
	{"name":"Central African Republic","value":"central african republic"},
	{"name":"Chad","value":"chad"},
	{"name":"Chile","value":"chile"},
	{"name":"China","value":"china"},
	{"name":"Christmas Island","value":"christmas island"},
	{"name":"Cocos (Keeling) Islands","value":"cocos (keeling) islands"},
	{"name":"Colombia","value":"colombia"},
	{"name":"Comoros","value":"comoros"},
	{"name":"Congo","value":"congo"},
	{"name":"Congo (Democratic Republic of the)","value":"congo (democratic republic of the)"},
	{"name":"Cook Islands","value":"cook islands"},
	{"name":"Costa Rica","value":"costa rica"},
	{"name":"Croatia","value":"croatia"},
	{"name":"Cuba","value":"cuba"},
	{"name":"Curaçao","value":"curaçao"},
	{"name":"Cyprus","value":"cyprus"},
	{"name":"Czech Republic","value":"czech republic"},
	{"name":"Denmark","value":"denmark"},
	{"name":"Djibouti","value":"djibouti"},
	{"name":"Dominica","value":"dominica"},
	{"name":"Dominican Republic","value":"dominican republic"},
	{"name":"Ecuador","value":"ecuador"},
	{"name":"Egypt","value":"egypt"},
	{"name":"El Salvador","value":"el salvador"},
	{"name":"Equatorial Guinea","value":"equatorial guinea"},
	{"name":"Eritrea","value":"eritrea"},
	{"name":"Estonia","value":"estonia"},
	{"name":"Ethiopia","value":"ethiopia"},
	{"name":"Falkland Islands (Malvinas)","value":"falkland islands (malvinas)"},
	{"name":"Faroe Islands","value":"faroe islands"},
	{"name":"Fiji","value":"fiji"},
	{"name":"Finland","value":"finland"},
	{"name":"France","value":"france"},
	{"name":"French Guiana","value":"french guiana"},
	{"name":"French Polynesia","value":"french polynesia"},
	{"name":"French Southern Territories","value":"french southern territories"},
	{"name":"Gabon","value":"gabon"},
	{"name":"Gambia","value":"gambia"},
	{"name":"Georgia","value":"georgia"},
	{"name":"Germany","value":"germany"},
	{"name":"Ghana","value":"ghana"},
	{"name":"Gibraltar","value":"gibraltar"},
	{"name":"Greece","value":"greece"},
	{"name":"Greenland","value":"greenland"},
	{"name":"Grenada","value":"grenada"},
	{"name":"Guadeloupe","value":"guadeloupe"},
	{"name":"Guam","value":"guam"},
	{"name":"Guatemala","value":"guatemala"},
	{"name":"Guernsey","value":"guernsey"},
	{"name":"Guinea","value":"guinea"},
	{"name":"Guinea-Bissau","value":"guinea-bissau"},
	{"name":"Guyana","value":"guyana"},
	{"name":"Haiti","value":"haiti"},
	{"name":"Heard Island and McDonald Islands","value":"heard island and mcdonald islands"},
	{"name":"Holy See","value":"holy see"},
	{"name":"Honduras","value":"honduras"},
	{"name":"Hong Kong","value":"hong kong"},
	{"name":"Hungary","value":"hungary"},
	{"name":"Iceland","value":"iceland"},
	{"name":"India","value":"india"},
	{"name":"Indonesia","value":"indonesia"},
	{"name":"Côte d'Ivoire","value":"côte d'ivoire"},
	{"name":"Iran (Islamic Republic of)","value":"iran (islamic republic of)"},
	{"name":"Iraq","value":"iraq"},
	{"name":"Ireland","value":"ireland"},
	{"name":"Isle of Man","value":"isle of man"},
	{"name":"Israel","value":"israel"},
	{"name":"Italy","value":"italy"},
	{"name":"Jamaica","value":"jamaica"},
	{"name":"Japan","value":"japan"},
	{"name":"Jersey","value":"jersey"},
	{"name":"Jordan","value":"jordan"},
	{"name":"Kazakhstan","value":"kazakhstan"},
	{"name":"Kenya","value":"kenya"},
	{"name":"Kiribati","value":"kiribati"},
	{"name":"Kuwait","value":"kuwait"},
	{"name":"Kyrgyzstan","value":"kyrgyzstan"},
	{"name":"Lao People's Democratic Republic","value":"lao people's democratic republic"},
	{"name":"Latvia","value":"latvia"},
	{"name":"Lebanon","value":"lebanon"},
	{"name":"Lesotho","value":"lesotho"},
	{"name":"Liberia","value":"liberia"},
	{"name":"Libya","value":"libya"},
	{"name":"Liechtenstein","value":"liechtenstein"},
	{"name":"Lithuania","value":"lithuania"},
	{"name":"Luxembourg","value":"luxembourg"},
	{"name":"Macao","value":"macao"},
	{"name":"Macedonia (the former Yugoslav Republic of)","value":"macedonia (the former yugoslav republic of)"},
	{"name":"Madagascar","value":"madagascar"},
	{"name":"Malawi","value":"malawi"},
	{"name":"Malaysia","value":"malaysia"},
	{"name":"Maldives","value":"maldives"},
	{"name":"Mali","value":"mali"},
	{"name":"Malta","value":"malta"},
	{"name":"Marshall Islands","value":"marshall islands"},
	{"name":"Martinique","value":"martinique"},
	{"name":"Mauritania","value":"mauritania"},
	{"name":"Mauritius","value":"mauritius"},
	{"name":"Mayotte","value":"mayotte"},
	{"name":"Mexico","value":"mexico"},
	{"name":"Micronesia (Federated States of)","value":"micronesia (federated states of)"},
	{"name":"Moldova (Republic of)","value":"moldova (republic of)"},
	{"name":"Monaco","value":"monaco"},
	{"name":"Mongolia","value":"mongolia"},
	{"name":"Montenegro","value":"montenegro"},
	{"name":"Montserrat","value":"montserrat"},
	{"name":"Morocco","value":"morocco"},
	{"name":"Mozambique","value":"mozambique"},
	{"name":"Myanmar","value":"myanmar"},
	{"name":"Namibia","value":"namibia"},
	{"name":"Nauru","value":"nauru"},
	{"name":"Nepal","value":"nepal"},
	{"name":"Netherlands","value":"netherlands"},
	{"name":"New Caledonia","value":"new caledonia"},
	{"name":"New Zealand","value":"new zealand"},
	{"name":"Nicaragua","value":"nicaragua"},
	{"name":"Niger","value":"niger"},
	{"name":"Nigeria","value":"nigeria"},
	{"name":"Niue","value":"niue"},
	{"name":"Norfolk Island","value":"norfolk island"},
	{"name":"Korea (Democratic People's Republic of)","value":"korea (democratic people's republic of)"},
	{"name":"Northern Mariana Islands","value":"northern mariana islands"},
	{"name":"Norway","value":"norway"},
	{"name":"Oman","value":"oman"},
	{"name":"Pakistan","value":"pakistan"},
	{"name":"Palau","value":"palau"},
	{"name":"Palestine, State of","value":"palestine, state of"},
	{"name":"Panama","value":"panama"},
	{"name":"Papua New Guinea","value":"papua new guinea"},
	{"name":"Paraguay","value":"paraguay"},
	{"name":"Peru","value":"peru"},
	{"name":"Philippines","value":"philippines"},
	{"name":"Pitcairn","value":"pitcairn"},
	{"name":"Poland","value":"poland"},
	{"name":"Portugal","value":"portugal"},
	{"name":"Puerto Rico","value":"puerto rico"},
	{"name":"Qatar","value":"qatar"},
	{"name":"Republic of Kosovo","value":"republic of kosovo"},
	{"name":"Réunion","value":"réunion"},
	{"name":"Romania","value":"romania"},
	{"name":"Russian Federation","value":"russian federation"},
	{"name":"Rwanda","value":"rwanda"},
	{"name":"Saint Barthélemy","value":"saint barthélemy"},
	{"name":"Saint Helena, Ascension and Tristan da Cunha","value":"saint helena, ascension and tristan da cunha"},
	{"name":"Saint Kitts and Nevis","value":"saint kitts and nevis"},
	{"name":"Saint Lucia","value":"saint lucia"},
	{"name":"Saint Martin (French part)","value":"saint martin (french part)"},
	{"name":"Saint Pierre and Miquelon","value":"saint pierre and miquelon"},
	{"name":"Saint Vincent and the Grenadines","value":"saint vincent and the grenadines"},
	{"name":"Samoa","value":"samoa"},
	{"name":"San Marino","value":"san marino"},
	{"name":"Sao Tome and Principe","value":"sao tome and principe"},
	{"name":"Saudi Arabia","value":"saudi arabia"},
	{"name":"Senegal","value":"senegal"},
	{"name":"Serbia","value":"serbia"},
	{"name":"Seychelles","value":"seychelles"},
	{"name":"Sierra Leone","value":"sierra leone"},
	{"name":"Singapore","value":"singapore"},
	{"name":"Sint Maarten (Dutch part)","value":"sint maarten (dutch part)"},
	{"name":"Slovakia","value":"slovakia"},
	{"name":"Slovenia","value":"slovenia"},
	{"name":"Solomon Islands","value":"solomon islands"},
	{"name":"Somalia","value":"somalia"},
	{"name":"South Africa","value":"south africa"},
	{"name":"South Georgia and the South Sandwich Islands","value":"south georgia and the south sandwich islands"},
	{"name":"Korea (Republic of)","value":"korea (republic of)"},
	{"name":"South Sudan","value":"south sudan"},
	{"name":"Spain","value":"spain"},
	{"name":"Sri Lanka","value":"sri lanka"},
	{"name":"Sudan","value":"sudan"},
	{"name":"Suriname","value":"suriname"},
	{"name":"Svalbard and Jan Mayen","value":"svalbard and jan mayen"},
	{"name":"Swaziland","value":"swaziland"},
	{"name":"Sweden","value":"sweden"},
	{"name":"Switzerland","value":"switzerland"},
	{"name":"Syrian Arab Republic","value":"syrian arab republic"},
	{"name":"Taiwan","value":"taiwan"},
	{"name":"Tajikistan","value":"tajikistan"},
	{"name":"Tanzania, United Republic of","value":"tanzania, united republic of"},
	{"name":"Thailand","value":"thailand"},
	{"name":"Timor-Leste","value":"timor-leste"},
	{"name":"Togo","value":"togo"},
	{"name":"Tokelau","value":"tokelau"},
	{"name":"Tonga","value":"tonga"},
	{"name":"Trinidad and Tobago","value":"trinidad and tobago"},
	{"name":"Tunisia","value":"tunisia"},
	{"name":"Turkey","value":"turkey"},
	{"name":"Turkmenistan","value":"turkmenistan"},
	{"name":"Turks and Caicos Islands","value":"turks and caicos islands"},
	{"name":"Tuvalu","value":"tuvalu"},
	{"name":"Uganda","value":"uganda"},
	{"name":"Ukraine","value":"ukraine"},
	{"name":"United Arab Emirates","value":"united arab emirates"},
	{"name":"United Kingdom of Great Britain and Northern Ireland","value":"united kingdom of great britain and northern ireland"},
	{"name":"United States of America","value":"united states of america"},
	{"name":"Uruguay","value":"uruguay"},
	{"name":"Uzbekistan","value":"uzbekistan"},
	{"name":"Vanuatu","value":"vanuatu"},
	{"name":"Venezuela (Bolivarian Republic of)","value":"venezuela (bolivarian republic of)"},
	{"name":"Viet Nam","value":"viet nam"},
	{"name":"Wallis and Futuna","value":"wallis and futuna"},
	{"name":"Western Sahara","value":"western sahara"},
	{"name":"Yemen","value":"yemen"},
	{"name":"Zambia","value":"zambia"},
	{"name":"Zimbabwe","value":"zimbabwe"}
]

export const COUNTRIES_ALL_SWE = [
	{
			"name":"Välj",
			"value":"placeholder"
	},
  {
      "name": "Afghanistan",
      "value": "afghanistan"
  },
  {
      "name": "Albanien",
      "value": "albanien"
  },
  {
      "name": "Algeriet",
      "value": "algeriet"
  },
  {
      "name": "Andorra",
      "value": "andorra"
  },
  {
      "name": "Angola",
      "value": "angola"
  },
  {
      "name": "Antigua och Barbuda",
      "value": "antigua och barbuda"
  },
  {
      "name": "Argentina",
      "value": "argentina"
  },
  {
      "name": "Armenien",
      "value": "armenien"
  },
  {
      "name": "Australien",
      "value": "australien"
  },
  {
      "name": "Azerbajdzjan",
      "value": "azerbajdzjan"
  },
  {
      "name": "Bahamas",
      "value": "bahamas"
  },
  {
      "name": "Bahrain",
      "value": "bahrain"
  },
  {
      "name": "Bangladesh",
      "value": "bangladesh"
  },
  {
      "name": "Barbados",
      "value": "barbados"
  },
  {
      "name": "Belgien",
      "value": "belgien"
  },
  {
      "name": "Belize",
      "value": "belize"
  },
  {
      "name": "Benin",
      "value": "benin"
  },
  {
      "name": "Bhutan",
      "value": "bhutan"
  },
  {
      "name": "Bolivia",
      "value": "bolivia"
  },
  {
      "name": "Bosnien och Hercegovina",
      "value": "bosnien och hercegovina"
  },
  {
      "name": "Botswana",
      "value": "botswana"
  },
  {
      "name": "Brasilien",
      "value": "brasilien"
  },
  {
      "name": "Brunei",
      "value": "brunei"
  },
  {
      "name": "Bulgarien",
      "value": "bulgarien"
  },
  {
      "name": "Burkina Faso",
      "value": "burkina faso"
  },
  {
      "name": "Burundi",
      "value": "burundi"
  },
  {
      "name": "Centralafrikanska republiken",
      "value": "centralafrikanska republiken"
  },
  {
      "name": "Chile",
      "value": "chile"
  },
  {
      "name": "Colombia",
      "value": "colombia"
  },
  {
      "name": "Comorerna",
      "value": "comorerna"
  },
  {
      "name": "Costa Rica",
      "value": "costa rica"
  },
  {
      "name": "Cypern",
      "value": "cypern"
  },
  {
      "name": "Danmark",
      "value": "danmark"
  },
  {
      "name": "Demokratiska republiken Kongo",
      "value": "demokratiska republiken kongo"
  },
  {
      "name": "Djibouti",
      "value": "djibouti"
  },
  {
      "name": "Dominica",
      "value": "dominica"
  },
  {
      "name": "Dominikanska republiken",
      "value": "dominikanska republiken"
  },
  {
      "name": "Ecuador",
      "value": "ecuador"
  },
  {
      "name": "Egypten",
      "value": "egypten"
  },
  {
      "name": "Ekvatorialguinea",
      "value": "ekvatorialguinea"
  },
  {
      "name": "El Salvador",
      "value": "el salvador"
  },
  {
      "name": "Elfenbenskusten",
      "value": "elfenbenskusten"
  },
  {
      "name": "Eritrea",
      "value": "eritrea"
  },
  {
      "name": "Estland",
      "value": "estland"
  },
  {
      "name": "Etiopien",
      "value": "etiopien"
  },
  {
      "name": "Fiji",
      "value": "fiji"
  },
  {
      "name": "Filippinerna",
      "value": "filippinerna"
  },
  {
      "name": "Frankrike",
      "value": "frankrike"
  },
  {
      "name": "Förenade Arabemiraten",
      "value": "förenade arabemiraten"
  },
  {
      "name": "Gabon",
      "value": "gabon"
  },
  {
      "name": "Gambia",
      "value": "gambia"
  },
  {
      "name": "Georgien",
      "value": "georgien"
  },
  {
      "name": "Ghana",
      "value": "ghana"
  },
  {
      "name": "Grekland",
      "value": "grekland"
  },
  {
      "name": "Grenada",
      "value": "grenada"
  },
  {
      "name": "Guatemala",
      "value": "guatemala"
  },
  {
      "name": "Guinea",
      "value": "guinea"
  },
  {
      "name": "Guinea-Bissau",
      "value": "guinea-bissau"
  },
  {
      "name": "Guyana",
      "value": "guyana"
  },
  {
      "name": "Haiti",
      "value": "haiti"
  },
  {
      "name": "Honduras",
      "value": "honduras"
  },
  {
      "name": "Indien",
      "value": "indien"
  },
  {
      "name": "Indonesien",
      "value": "indonesien"
  },
  {
      "name": "Irak",
      "value": "irak"
  },
  {
      "name": "Iran",
      "value": "iran"
  },
  {
      "name": "Irland",
      "value": "irland"
  },
  {
      "name": "Island",
      "value": "island"
  },
  {
      "name": "Israel",
      "value": "israel"
  },
  {
      "name": "Italien",
      "value": "italien"
  },
  {
      "name": "Jamaica",
      "value": "jamaica"
  },
  {
      "name": "Japan",
      "value": "japan"
  },
  {
      "name": "Jemen",
      "value": "jemen"
  },
  {
      "name": "Jordanien",
      "value": "jordanien"
  },
  {
      "name": "Kambodja",
      "value": "kambodja"
  },
  {
      "name": "Kamerun",
      "value": "kamerun"
  },
  {
      "name": "Kanada",
      "value": "kanada"
  },
  {
      "name": "Kap Verde",
      "value": "kap verde"
  },
  {
      "name": "Kazakstan",
      "value": "kazakstan"
  },
  {
      "name": "Kenya",
      "value": "kenya"
  },
  {
      "name": "Kina",
      "value": "kina"
  },
  {
      "name": "Kirgizistan",
      "value": "kirgizistan"
  },
  {
      "name": "Kiribati",
      "value": "kiribati"
  },
  {
      "name": "Kongo (Republiken Kongo)",
      "value": "kongo (republiken kongo)"
  },
  {
      "name": "Kosovo",
      "value": "kosovo"
  },
  {
      "name": "Kroatien",
      "value": "kroatien"
  },
  {
      "name": "Kuba",
      "value": "kuba"
  },
  {
      "name": "Kuwait",
      "value": "kuwait"
  },
  {
      "name": "Laos",
      "value": "laos"
  },
  {
      "name": "Lesotho",
      "value": "lesotho"
  },
  {
      "name": "Lettland",
      "value": "lettland"
  },
  {
      "name": "Libanon",
      "value": "libanon"
  },
  {
      "name": "Liberia",
      "value": "liberia"
  },
  {
      "name": "Libyen",
      "value": "libyen"
  },
  {
      "name": "Liechtenstein",
      "value": "liechtenstein"
  },
  {
      "name": "Litauen",
      "value": "litauen"
  },
  {
      "name": "Luxemburg",
      "value": "luxemburg"
  },
  {
      "name": "Madagaskar",
      "value": "madagaskar"
  },
  {
      "name": "Makedonien",
      "value": "makedonien"
  },
  {
      "name": "Malawi",
      "value": "malawi"
  },
  {
      "name": "Malaysia",
      "value": "malaysia"
  },
  {
      "name": "Maldiverna",
      "value": "maldiverna"
  },
  {
      "name": "Mali",
      "value": "mali"
  },
  {
      "name": "Malta",
      "value": "malta"
  },
  {
      "name": "Marocko",
      "value": "marocko"
  },
  {
      "name": "Marshallöarna",
      "value": "marshallöarna"
  },
  {
      "name": "Mauretanien",
      "value": "mauretanien"
  },
  {
      "name": "Mauritius",
      "value": "mauritius"
  },
  {
      "name": "Mexiko",
      "value": "mexiko"
  },
  {
      "name": "Mikronesien",
      "value": "mikronesien"
  },
  {
      "name": "Moçambique",
      "value": "moçambique"
  },
  {
      "name": "Moldavien",
      "value": "moldavien"
  },
  {
      "name": "Monaco",
      "value": "monaco"
  },
  {
      "name": "Mongoliet",
      "value": "mongoliet"
  },
  {
      "name": "Montenegro",
      "value": "montenegro"
  },
  {
      "name": "Myanmar",
      "value": "myanmar"
  },
  {
      "name": "Namibia",
      "value": "namibia"
  },
  {
      "name": "Nauru",
      "value": "nauru"
  },
  {
      "name": "Nederländerna",
      "value": "nederländerna"
  },
  {
      "name": "Nepal",
      "value": "nepal"
  },
  {
      "name": "Nicaragua",
      "value": "nicaragua"
  },
  {
      "name": "Niger",
      "value": "niger"
  },
  {
      "name": "Nigeria",
      "value": "nigeria"
  },
  {
      "name": "Nordkorea",
      "value": "nordkorea"
  },
  {
      "name": "Norge",
      "value": "norge"
  },
  {
      "name": "Nya Zeeland",
      "value": "nya zeeland"
  },
  {
      "name": "Oman",
      "value": "oman"
  },
  {
      "name": "Pakistan",
      "value": "pakistan"
  },
  {
      "name": "Palau",
      "value": "palau"
  },
  {
      "name": "Palestina",
      "value": "palestina"
  },
  {
      "name": "Panama",
      "value": "panama"
  },
  {
      "name": "Papua Nya Guinea",
      "value": "papua nya guinea"
  },
  {
      "name": "Paraguay",
      "value": "paraguay"
  },
  {
      "name": "Peru",
      "value": "peru"
  },
  {
      "name": "Polen",
      "value": "polen"
  },
  {
      "name": "Portugal",
      "value": "portugal"
  },
  {
      "name": "Qatar",
      "value": "qatar"
  },
  {
      "name": "Rumänien",
      "value": "rumänien"
  },
  {
      "name": "Rwanda",
      "value": "rwanda"
  },
  {
      "name": "Ryssland",
      "value": "ryssland"
  },
  {
      "name": "Saint Kitts och Nevis",
      "value": "saint kitts och nevis"
  },
  {
      "name": "Saint Lucia",
      "value": "saint lucia"
  },
  {
      "name": "Saint Vincent och Grenadinerna",
      "value": "saint vincent och grenadinerna"
  },
  {
      "name": "Salomonöarna",
      "value": "salomonöarna"
  },
  {
      "name": "Samoa",
      "value": "samoa"
  },
  {
      "name": "San Marino",
      "value": "san marino"
  },
  {
      "name": "São Tomé och Principe",
      "value": "são tomé och principe"
  },
  {
      "name": "Saudiarabien",
      "value": "saudiarabien"
  },
  {
      "name": "Schweiz",
      "value": "schweiz"
  },
  {
      "name": "Senegal",
      "value": "senegal"
  },
  {
      "name": "Serbien",
      "value": "serbien"
  },
  {
      "name": "Seychellerna",
      "value": "seychellerna"
  },
  {
      "name": "Sierra Leone",
      "value": "sierra leone"
  },
  {
      "name": "Singapore",
      "value": "singapore"
  },
  {
      "name": "Slovakien",
      "value": "slovakien"
  },
  {
      "name": "Slovenien",
      "value": "slovenien"
  },
  {
      "name": "Somalia",
      "value": "somalia"
  },
  {
      "name": "Spanien",
      "value": "spanien"
  },
  {
      "name": "Sri Lanka",
      "value": "sri lanka"
  },
  {
      "name": "Storbritannien",
      "value": "storbritannien"
  },
  {
      "name": "Sudan",
      "value": "sudan"
  },
  {
      "name": "Surinam",
      "value": "surinam"
  },
  {
      "name": "Swaziland",
      "value": "swaziland"
  },
  {
      "name": "Sverige",
      "value": "sverige"
  },
  {
      "name": "Sydafrika",
      "value": "sydafrika"
  },
  {
      "name": "Sydkorea",
      "value": "sydkorea"
  },
  {
      "name": "Sydsudan",
      "value": "sydsudan"
  },
  {
      "name": "Syrien",
      "value": "syrien"
  },
  {
      "name": "Tadzjikistan",
      "value": "tadzjikistan"
  },
  {
      "name": "Taiwan",
      "value": "taiwan"
  },
  {
      "name": "Tanzania",
      "value": "tanzania"
  },
  {
      "name": "Tchad",
      "value": "tchad"
  },
  {
      "name": "Thailand",
      "value": "thailand"
  },
  {
      "name": "Tjeckien",
      "value": "tjeckien"
  },
  {
      "name": "Togo",
      "value": "togo"
  },
  {
      "name": "Tonga",
      "value": "tonga"
  },
  {
      "name": "Trinidad och Tobago",
      "value": "trinidad och tobago"
  },
  {
      "name": "Tunisien",
      "value": "tunisien"
  },
  {
      "name": "Turkiet",
      "value": "turkiet"
  },
  {
      "name": "Turkmenistan",
      "value": "turkmenistan"
  },
  {
      "name": "Tuvalu",
      "value": "tuvalu"
  },
  {
      "name": "Tyskland",
      "value": "tyskland"
  },
  {
      "name": "Uganda",
      "value": "uganda"
  },
  {
      "name": "Ukraina",
      "value": "ukraina"
  },
  {
      "name": "Ungern",
      "value": "ungern"
  },
  {
      "name": "Uruguay",
      "value": "uruguay"
  },
  {
      "name": "USA",
      "value": "usa"
  },
  {
      "name": "Uzbekistan",
      "value": "uzbekistan"
  },
  {
      "name": "Vanuatu",
      "value": "vanuatu"
  },
  {
      "name": "Vatikanen",
      "value": "vatikanen"
  },
  {
      "name": "Venezuela",
      "value": "venezuela"
  },
  {
      "name": "Vietnam",
      "value": "vietnam"
  },
  {
      "name": "Vitryssland",
      "value": "vitryssland"
  },
  {
      "name": "Zambia",
      "value": "zambia"
  },
  {
      "name": "Zimbabwe",
      "value": "zimbabwe"
  },
  {
      "name": "Åland",
      "value": "åland"
  },
  {
      "name": "Österrike",
      "value": "österrike"
  },
  {
      "name": "Östtimor",
      "value": "östtimor"
  }
]

export const GENDER_OPTIONS = [
	{name: "Select", value:"placeholder"},
  {name: "Male", value:'male'},
	{name: "Female", value:'female'},
  {name: "Other", value:'other'},
  {name: "Prefer not to respond", value:'notSay'},
]

export const GENDER_OPTIONS_SWE = [
	{name: "Välj", value:"placeholder"},
  {name: "Man", value:'male'},
	{name: "Kvinna", value:'female'},
  {name: "Annat", value:'other'},
  {name: "Föredrar att inte uppge", value:'notSay'},
]
