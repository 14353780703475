import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Overlay } from "react-overlays";

const TooltipStyle = {
	position: "absolute",
	padding: "0 5px",
};

const TooltipInnerStyle = {
	padding: "10px 15px",
	color: "#fff",
	borderRadius: 3,
	backgroundColor: "#000",
	opacity: 0.75,
	fontSize: "14px",
};

const TooltipArrowStyle = {
	position: "absolute",
	width: 0,
	height: 0,
	borderRightColor: "transparent",
	borderLeftColor: "transparent",
	borderTopColor: "transparent",
	borderBottomColor: "transparent",
	borderStyle: "solid",
	opacity: 0.75,
};

const PlacementStyles = {
	left: {
		tooltip: { marginLeft: -3, padding: "0 5px" },
		arrow: {
			right: 0,
			marginTop: -5,
			borderWidth: "5px 0 5px 5px",
			borderLeftColor: "#000",
		},
	},
	right: {
		tooltip: { marginRight: 3, padding: "0 5px" },
		arrow: {
			left: 0,
			marginTop: -5,
			borderWidth: "5px 5px 5px 0",
			borderRightColor: "#000",
		},
	},
	top: {
		tooltip: { marginTop: -3, padding: "5px 0" },
		arrow: {
			bottom: 0,
			marginLeft: -5,
			borderWidth: "5px 5px 0",
			borderTopColor: "#000",
		},
	},
	bottom: {
		tooltip: { marginBottom: 3, padding: "5px 0" },
		arrow: {
			top: 0,
			marginLeft: -5,
			borderWidth: "0 5px 5px",
			borderBottomColor: "#000",
		},
	},
};

const Popup = (props) => {
	let placementStyle = PlacementStyles[props.placement];

	const {
		style,
		arrowOffsetLeft: left = placementStyle.arrow.left,
		arrowOffsetTop: top = placementStyle.arrow.top,
		children,
	} = props;

	return (
		<div style={{ ...TooltipStyle, ...placementStyle.tooltip, ...style }}>
			<div
				style={{ ...TooltipArrowStyle, ...placementStyle.arrow, left, top }}
			/>
			<div style={TooltipInnerStyle}>{children}</div>
		</div>
	);
};

class Tooltip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}

	toggle = (e) => {
		e.stopPropagation();
		this.setState({
			show: !this.state.show,
		});
	};

	render() {
		const { data, extraClass = "", style = {} } = this.props;

		return (
			<div
				className={"tooltip " + data.popupPos + " " + extraClass}
				style={style}
			>
				<div
					ref="target"
					onClick={this.toggle}
					className={"tooltip__marker " + data.popupPos}
				>
					i
				</div>
				<Overlay
					show={this.state.show}
					onHide={() => this.setState({ show: false })}
					rootClose={true}
					placement={data.popupPos}
					container={this}
					target={(props) => ReactDOM.findDOMNode(this.refs.target)}
				>
					<Popup>{data.text}</Popup>
				</Overlay>
			</div>
		);
	}
}

export default Tooltip;
