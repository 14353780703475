import React, { Component } from "react";
import { connect } from "react-redux";
import { Fields } from "redux-form";
import { Table, Tr, Td, Thead, Th } from "../Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { updateRsvp, mailResponders } from "../../actions/EventActions";
import { exportData } from "../../actions/RegistryActions";
import CheckBox from "../FormInput/CheckBox";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import SingleDateTimePicker from "../FormComponents/DateRange/SingleDateTimePicker";

const BIN = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");
const PLUS_WHITE = require("../../../assets/images/svg/plus_32px_white.svg");
const ARROW = require("../../../assets/images/icons/next.white.png");

// This component to not update state for all members when inputting values.
// Only updates in database on blur.
class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
		this.defaultStyle = {
			width: "100%",
			position: "relative",
			top: 0,
			bottom: 0,
			zIndex: 0,
		};
	}

	componentDidMount() {
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setValue(nextProps.value);
	}

	setValue = (value) => {
		this.setState({
			value,
		});
	};

	_update = (evt) => {
		this.props.update(evt.target.value);
		this.resetInputField(evt);
	};

	changeInputField = (evt) => {
		const newWidth = evt.target.value ? evt.target.value.length + 2 : 0;
		// evt.target.style.width = `${newWidth}ch`
		evt.target.style.width = "90%";
		evt.target.style.position = "absolute";
		evt.target.style.top = "9px";
		evt.target.style.bottom = "11px";
		evt.target.style.zIndex = "5";
	};

	resetInputField = (evt) => {
		evt.target.style.width = this.defaultStyle.width;
		evt.target.style.position = this.defaultStyle.position;
		evt.target.style.top = this.defaultStyle.top;
		evt.target.style.bottom = this.defaultStyle.bottom;
		evt.target.style.zIndex = this.defaultStyle.zIndex;
	};

	render() {
		const { value } = this.state;
		const { approved, canUpdate } = this.props;
		return (
			<input
				type="text"
				value={value}
				onChange={(evt) => this.setValue(evt.target.value)}
				onFocus={(evt) => this.changeInputField(evt)}
				onBlur={(evt) => this._update(evt)}
				className="input-field"
				disabled={!canUpdate}
				style={this.defaultStyle}
			/>
		);
	}
}

// Instead of FormComponents/Rsvp
// Update values directly instead of updating form and saving on Save-button click.
// So it works the same way as in Registry.jsx.
class Rsvp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true,

			addResponderOpen: false,
			newResponderName: "",
			newResponderEmail: "",
			newResponderEmailValid: true,

			addWaitingOpen: false,
			newWaitingName: "",
			newWaitingEmail: "",
			newWaitingEmailValid: true,

			confirmSignupOpen: false,

			mailAllRespondersOpen: false,
			mailAllRespondersSubject: "",
			mailAllRespondersBody: "",
			// Previous mail body and subject (don't send the same mail twice):
			prevMailSubject: "",
			prevMailBody: "",

			mailFeeMissingOpen: false,
			mailFeeMissingSubject: "",
			mailFeeMissingBody: "",
			// Previous mail body and subject (don't send the same mail twice):
			prevFeeMissingSubject: "",
			prevFeeMissingBody: "",
		};
		this.sortDirection = 1;
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.rsvpMailSent && nextProps.rsvpMailSent) {
			const { mailAllRespondersSubject, mailAllRespondersBody } = this.state;
			this.setState({
				prevMailSubject: mailAllRespondersSubject,
				prevMailBody: mailAllRespondersBody,
			});
		}

		if (
			!this.props.rsvpMailFeeMissingSent &&
			nextProps.rsvpMailFeeMissingSent
		) {
			const { mailFeeMissingSubject, mailFeeMissingBody } = this.state;
			this.setState({
				prevFeeMissingSubject: mailFeeMissingSubject,
				prevFeeMissingBody: mailFeeMissingBody,
			});
		}

		if (!this.props.exported && nextProps.exported) {
			const type =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
			const blob = new Blob([this.s2ab(nextProps.exported)], { type: type });
			saveAs(
				blob,
				i18next.language === "sv"
					? `Deltagare ${this.props.title.input.value}.xlsx`
					: `Participants ${this.props.title.input.value}.xlsx`
			);
		}
	}

	s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	// Same default mail used in rsvpResponse() artSocietyEmails.js in API.
	// Explicit space ${' '} because Safari don't read linebreak and spaces in placeholder as we wan't it to.
	rsvpComfirmationEmail = (
		eventTitle = "vår aktivitet",
		contactEmail = "oss på föreningen",
		sender = "Styrelsen på din förening",
		isArtSociety = true
	) => {
		return `Hej!${" "}

Vad kul att du kommer på ${eventTitle}!${" "}

Kontakta ${contactEmail} om du har några frågor.${" "}

${isArtSociety ? "Vi" : "Jag"} ser fram emot att ses!${" "}

Vänliga hälsningar,${" "}
${sender}`;
	};

	toggleAddResponderOpen = (e) => {
		e.preventDefault();
		this.setState({
			addResponderOpen: !this.state.addResponderOpen,
			newResponderName: "",
			newResponderEmail: "",
			newResponderEmailValid: true,
		});
	};

	toggleAddWaitingOpen = (e) => {
		e.preventDefault();
		this.setState({
			addWaitingOpen: !this.state.addWaitingOpen,
		});
	};

	addResponder = (e) => {
		e.preventDefault();
		const { newResponderName, newResponderEmail } = this.state;
		const eventId = this.props._id.input.value;

		const participant = {
			name: newResponderName,
			email: newResponderEmail,
			answer: "yes",
			answered: new Date(),
		};

		const update = { rsvp: [participant] };

		this.props.dispatch(updateRsvp(eventId, update, true));

		this.setState({
			newResponderName: "",
			newResponderEmail: "",
			newResponderEmailValid: true,
			addResponderOpen: false,
		});
	};

	removeResponder = (participant, t) => {
		const eventId = this.props._id.input.value;

		let confirm = window.confirm(t("rsvp.confirmRemoveParticipant"));
		if (!confirm) return;

		const participantUpdate = {
			_id: participant._id,
			name: participant.name,
			email: participant.email,
			remove: true,
		};

		const update = { rsvp: [participantUpdate] };

		this.props.dispatch(updateRsvp(eventId, update, true));
	};

	addWaiting = (e) => {
		e.preventDefault();
		const { newWaitingName, newWaitingEmail } = this.state;
		const eventId = this.props._id.input.value;

		const waiting = {
			name: newWaitingName,
			email: newWaitingEmail,
			answer: "yes",
			answered: new Date(),
		};

		const update = { waitingList: [waiting] };

		this.props.dispatch(updateRsvp(eventId, update, true));

		this.setState({
			newWaitingName: "",
			newWaitingEmail: "",
			newWaitingEmailValid: true,
			addWaitingOpen: false,
		});
	};

	removeWaiting = (waiting, t) => {
		const eventId = this.props._id.input.value;

		let confirm = window.confirm(t("rsvp.confirmRemoveWaiting"));
		if (!confirm) return;

		const waitingUpdate = {
			_id: waiting._id,
			name: waiting.name,
			email: waiting.email,
			remove: true,
		};

		const update = { waitingList: [waitingUpdate] };

		this.props.dispatch(updateRsvp(eventId, update, true));
	};

	updateNewName = (e) => {
		e.preventDefault();
		this.setState({
			newResponderName: e.target.value,
		});
	};

	updateNewEmail = (e) => {
		e.preventDefault();
		this.setState({
			newResponderEmail: e.target.value,
			newResponderEmailValid: validateEmail(e.target.value),
		});
	};

	removeNewResponder = (e) => {
		e.preventDefault();
		this.setState({
			newResponderName: "",
			newResponderEmail: "",
			newResponderEmailValid: true,
			addResponderOpen: false,
		});
	};

	updateNewWaitingName = (e) => {
		e.preventDefault();
		this.setState({
			newWaitingName: e.target.value,
		});
	};

	updateNewWaitingEmail = (e) => {
		e.preventDefault();
		this.setState({
			newWaitingEmail: e.target.value,
			newWaitingEmailValid: validateEmail(e.target.value),
		});
	};

	removeNewWaiting = (e) => {
		e.preventDefault();
		this.setState({
			newWaitingName: "",
			newWaitingEmail: "",
			newWaitingEmailValid: true,
			addWaitingOpen: false,
		});
	};

	moveWaitingToPrimary = (waiting, index, t) => {
		const eventId = this.props._id.input.value;
		let confirm = window.confirm(t("rsvp.confirmMoveToPrimaryList"));
		if (!confirm) return;

		const waitingUpdate = Object.assign({}, waiting, {
			moveToPrimary: true,
		});

		const update = {
			waitingList: [waitingUpdate],
		};

		this.props.dispatch(updateRsvp(eventId, update, true));
	};

	updateFeePayed = (id, email, value) => {
		const eventId = this.props._id.input.value;

		const participant = {
			_id: id,
			feePayed: value,
		};

		const update = { rsvp: [participant] };

		this.props.dispatch(updateRsvp(eventId, update, true));
	};

	// Rsvp are not members, only array of participants, but all object in the array are given an id automatically.
	updateResponderValue = (
		listName,
		column,
		value,
		originalValue,
		responderId
	) => {
		const eventId = this.props._id.input.value;
		const trimmedValue = value.trim();
		// Don't update if value didn't change.
		if (trimmedValue === originalValue) return;
		const participant = {
			_id: responderId,
			[column]: trimmedValue,
		};

		const update = { [listName]: [participant] };

		this.props.dispatch(updateRsvp(eventId, update, true));
	};

	toggleConfirmSignupOpen = () => {
		this.setState({
			confirmSignupOpen: !this.state.confirmSignupOpen,
		});
	};

	// Mail all responders
	toggleMailAllRespondersOpen = () => {
		this.setState({
			mailAllRespondersOpen: !this.state.mailAllRespondersOpen,
		});
	};

	updateMailAllRespondersSubject = (e) => {
		e.preventDefault();
		this.setState({
			mailAllRespondersSubject: e.target.value,
		});
	};

	updateMailAllRespondersBody = (e) => {
		e.preventDefault();
		this.setState({
			mailAllRespondersBody: e.target.value,
		});
	};

	sendMailToAllResponders = (e, senderEmail) => {
		e.preventDefault();
		const { mailAllRespondersSubject, mailAllRespondersBody } = this.state;
		const { gallery } = this.props;
		const galleryId = gallery ? gallery._id : false;
		const eventTile = this.props.title.input.value || "Aktivitet";
		this.props.dispatch(
			mailResponders(
				this.props._id.input.value,
				senderEmail,
				mailAllRespondersSubject,
				mailAllRespondersBody,
				galleryId,
				eventTile,
				false,
				gallery.name
			)
		);
	};
	// end Mail all responders

	// Mail responders not payed yet
	toggleMailFeeMissingOpen = () => {
		this.setState({
			mailFeeMissingOpen: !this.state.mailFeeMissingOpen,
		});
	};

	updateMailFeeMissingSubject = (e) => {
		e.preventDefault();
		this.setState({
			mailFeeMissingSubject: e.target.value,
		});
	};

	updateMailFeeMissingBody = (e) => {
		e.preventDefault();
		this.setState({
			mailFeeMissingBody: e.target.value,
		});
	};

	sendMailToFeeMissing = (e, senderEmail) => {
		e.preventDefault();
		const { mailFeeMissingSubject, mailFeeMissingBody } = this.state;
		const { gallery } = this.props;
		const galleryId = gallery ? gallery._id : false;
		const eventTile = this.props.title.input.value || "Aktivitet";
		this.props.dispatch(
			mailResponders(
				this.props._id.input.value,
				senderEmail,
				mailFeeMissingSubject,
				mailFeeMissingBody,
				galleryId,
				eventTile,
				true,
				gallery.name
			)
		);
	};
	// end Mail responders not payed yet

	// When manually sorting / clicking table header.
	sortChanged = (e) => {
		this.sortDirection = e.direction;
	};
	// Number must be converted to String for localeCompare.
	// Tried with real undefined instead of String 'sort-first', became a react element.
	getSort = (value) => {
		return {
			column: value,
			sortFunction: (a, b) => {
				if (a === b) {
					return 0;
				} else if (a !== "sort-first" && b !== "sort-first") {
					return a.localeCompare(b, "sv");
				} else if (a === "sort-first" && b !== "sort-first") {
					return this.sortDirection ? 1 : -1;
				} else if (a !== "sort-first" && b === "sort-first") {
					return this.sortDirection ? 1 : -1;
				} else {
					return 0;
				}
			},
		};
	};

	exportRsvp = () => {
		const { responders } = this.props;

		let columns = [];
		let data = [];
		const isSwedish = i18next.language === "sv";
		if (isSwedish) {
			columns = {
				Namn: "name",
				Email: "email",
				Svarade: "answered",
				"Betalat avgift": "feePayed",
			};
		} else {
			columns = {
				Name: "name",
				Email: "email",
				Answered: "answered",
				"Fee paid": "feePayed",
			};
		}

		const headers = Object.keys(columns);

		responders.forEach((responder) => {
			const temp = {};
			Object.keys(columns).forEach((key) => {
				const column = columns[key];
				if (column === "answered") {
					temp[key] = DateUtil.dateMonthShort(
						responder[column],
						i18next.language,
						false,
						true
					);
				} else if (column === "feePayed") {
					temp[key] = responder[column]
						? isSwedish
							? "Ja"
							: "Yes"
						: isSwedish
						? "Nej"
						: "No";
				} else {
					temp[key] = responder[column];
				}
			});
			data.push(temp);
		});
		this.props.dispatch(exportData(headers, data));
	};

	render() {
		const {
			addResponderOpen,
			newResponderName,
			newResponderEmail,
			newResponderEmailValid,
			addWaitingOpen,
			newWaitingName,
			newWaitingEmail,
			newWaitingEmailValid,
			mailAllRespondersSubject,
			mailAllRespondersBody,
			prevMailSubject,
			prevMailBody,
			mailAllRespondersOpen,
			mailFeeMissingSubject,
			mailFeeMissingBody,
			prevFeeMissingSubject,
			prevFeeMissingBody,
			mailFeeMissingOpen,
			confirmSignupOpen,
		} = this.state;

		const {
			gallery,
			artist,
			isFetching,
			isPristine,
			isSubmitting,
			isArtistEvent,
			registryFetching,
			responders = [],
			onWaitingList = [],
			isNew,
			t,
		} = this.props;

		if (isNew) {
			return (
				<CheckBox
					{...this.props.rsvpAllowed}
					checkboxLabel={t("rsvp.requiresRsvp", {
						type: isArtistEvent
							? t("rsvp.typeInvitation")
							: t("rsvp.typeEvent"),
					})}
				/>
			);
		}

		const expanded = this.props.rsvpAllowed.input.value === true;
		const addButtonDisabled =
			(!newResponderName && !newResponderEmail) || !newResponderEmailValid;
		const addWaitingDisabled =
			(!newWaitingName && !newWaitingEmail) || !newWaitingEmailValid;
		// Some old events have participants without an _id, those are not updateable in the API,
		// so disable updating participants for those events.
		// .find returns undefined if no responder without _id is found.
		let canUpdateResponders = true;
		try {
			if (responders.concat(onWaitingList).find((element) => !element._id))
				canUpdateResponders = false;
		} catch (err) {
			// console.log(err);
		}

		const mailAlreadySent =
			mailAllRespondersSubject === prevMailSubject &&
			mailAllRespondersBody === prevMailBody;
		const feeMissingAlreadySent =
			mailFeeMissingSubject === prevFeeMissingSubject &&
			mailFeeMissingBody === prevFeeMissingBody;

		const maxParticipants = this.props.rsvpMaxUsers
			? this.props.rsvpMaxUsers.input.value
			: false;
		const showWaitingList =
			maxParticipants || maxParticipants === 0 || onWaitingList.length > 0;

		let senderEmail = this.props.email.input.value || false;
		let senderName = "";
		let confirmationEmail = this.rsvpComfirmationEmail();
		// let premium = false
		let hasRsvpAccess = false;
		if (gallery) {
			const galleryName = gallery.name || false;
			const galleryEmail = gallery.email || false;
			senderEmail = `${galleryName}<${galleryEmail}>`;
			senderName = galleryName;

			confirmationEmail = this.rsvpComfirmationEmail(
				this.props.title.input.value || "vår aktivitet",
				galleryEmail || "oss på föreningen",
				galleryName || "Styrelsen på din förening",
				true
			);
		} else if (artist) {
			const artistName = artist.name || false;
			const artistEmail = artist.email || false;
			senderEmail = senderEmail || artistEmail;
			senderName = artistName;

			confirmationEmail = this.rsvpComfirmationEmail(
				this.props.title.input.value || "min aktivitet",
				this.props.email.input.value || artistEmail || "mig",
				artist.name || "Konstnären",
				false
			);
		}

		const rows = responders.map((participant, index) => {
			// Need feePayed to be boolean (not undefined) or chackbox won't show correct value after adding a new responder.
			const feePayed = participant.feePayed ? true : false;
			const phoneNumber = participant.phone || "";

			return (
				<Tr key={index}>
					<Td column={"delete"} value="delete" key="delete">
						<BIN
							className={"remove-rsvp-responder"}
							onClick={() => this.removeResponder(participant, t)}
						/>
					</Td>
					<Td column="name" value={participant.name}>
						<InputField
							value={participant.name}
							update={(value) =>
								this.updateResponderValue(
									"rsvp",
									"name",
									value,
									participant.name,
									participant._id
								)
							}
							canUpdate={canUpdateResponders}
						/>
					</Td>
					<Td column="email" value={participant.email}>
						<InputField
							value={participant.email}
							update={(value) =>
								this.updateResponderValue(
									"rsvp",
									"email",
									value,
									participant.email,
									participant._id
								)
							}
							canUpdate={canUpdateResponders}
						/>
					</Td>
					<Td column="phone" value={phoneNumber}>
						<InputField
							value={phoneNumber}
							update={(value) =>
								this.updateResponderValue(
									"rsvp",
									"phone",
									value,
									phoneNumber,
									participant._id
								)
							}
							canUpdate={canUpdateResponders}
						/>
					</Td>
					<Td column="answered" value={participant.answered}>
						{participant.answered
							? DateUtil.dateMonthShort(
									participant.answered,
									i18next.language,
									false,
									true
							  )
							: ""}
					</Td>
					<Td column="feePayed" value={participant.feePayed}>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div style={{ width: "25px" }}>
								{participant.feePayed ? t("rsvp.yes") : t("rsvp.no")}
							</div>
							<input
								type="checkbox"
								value={feePayed}
								onChange={(evt) =>
									this.updateFeePayed(
										participant._id,
										participant.email,
										!feePayed
									)
								}
								onBlur={(evt) => {}}
								checked={feePayed}
								style={{ marginLeft: "10px", cursor: "pointer" }}
							/>
						</div>
					</Td>
				</Tr>
			);
		});

		const waitingRows = onWaitingList.map((waiting, index) => {
			const phoneNumber = waiting.phone || "";

			return (
				<Tr key={index}>
					<Td column={"delete"} value="delete" key="delete">
						<BIN
							className={"remove-rsvp-responder"}
							onClick={() => this.removeWaiting(waiting, t)}
						/>
					</Td>
					<Td column="name" value={waiting.name}>
						<InputField
							value={waiting.name}
							update={(value) =>
								this.updateResponderValue(
									"waitingList",
									"name",
									value,
									waiting.name,
									waiting._id
								)
							}
							canUpdate={canUpdateResponders}
						/>
					</Td>
					<Td column="email" value={waiting.email}>
						<InputField
							value={waiting.email}
							update={(value) =>
								this.updateResponderValue(
									"waitingList",
									"email",
									value,
									waiting.email,
									waiting._id
								)
							}
							canUpdate={canUpdateResponders}
						/>
					</Td>
					<Td column="phone" value={phoneNumber}>
						<InputField
							value={phoneNumber}
							update={(value) =>
								this.updateResponderValue(
									"waitingList",
									"phone",
									value,
									phoneNumber,
									waiting._id
								)
							}
							canUpdate={canUpdateResponders}
						/>
					</Td>
					<Td column="answered" value={waiting.answered}>
						{waiting.answered
							? DateUtil.dateMonthShort(
									waiting.answered,
									i18next.language,
									false,
									true
							  )
							: ""}
					</Td>
					<Td column="moveToPrimaryList" style={{ position: "relative" }}>
						<div
							className="rsvp__content__move-to-primary"
							onClick={() => this.moveWaitingToPrimary(waiting, index, t)}
						>
							<img
								className="rsvp__content__move-to-primary__image"
								src={ARROW}
								title={t("rsvp.moveToPrimaryList")}
							/>
						</div>
					</Td>
				</Tr>
			);
		});

		const sorts = ["name", "email", "answered"].map((sortName) =>
			this.getSort(sortName)
		);
		// Delete, use singel space { ` ` } as header to make it empty.
		return (
			<div className="rsvp">
				<CheckBox
					{...this.props.rsvpAllowed}
					checkboxLabel={t("rsvp.requiresRsvp", {
						type: isArtistEvent
							? t("rsvp.typeInvitation")
							: t("rsvp.typeEvent"),
					})}
				/>
				{/*<ExpandButton expanded={ expanded } click={ this.expand } />*/}
				<div className={"rsvp__content" + (expanded ? " is-expanded" : "")}>
					<div className="rsvp__content__max-responders">
						<input {...this.props.rsvpMaxUsers.input} />
						<div>
							<p>{t("rsvp.maxRsvp")}</p>
							<p>{t("rsvp.leaveEmpty")}</p>
						</div>
					</div>
					<CheckBox
						{...this.props.rsvpDetails.binding}
						checkboxLabel={t("rsvp.bindingRegistration")}
					/>
					<CheckBox
						{...this.props.notInformContact}
						checkboxLabel={t("rsvp.notNotifyOrganizer")}
					/>
					<legend className="legend-nomargin" style={{ borderBottom: "none" }}>
						{t("rsvp.lastRegistrationDate")}
					</legend>
					<SingleDateTimePicker
						{...this.props.rsvpDetails.lastRegistrationDate}
					/>

					<legend
						className="legend-nomargin"
						style={{ borderBottom: "none", paddingTop: "20px" }}
					>
						{t("rsvp.respondingMembers")}{" "}
						{`(${responders.length} ${t("rsvp.people")})`}
					</legend>
					<Table
						className="reactable"
						sortable={sorts}
						onSort={this.sortChanged}
					>
						<Thead>
							<Th
								column={"delete"}
								key={"delete"}
								style={{ position: "relative" }}
							>
								<PLUS_WHITE
									className="rsvp__content__add-rsvp-responder"
									onClick={this.toggleAddResponderOpen}
								/>
							</Th>
							<Th column="name">{t("common:name")}</Th>
							<Th column="email">{t("common:email")}</Th>
							<Th column="phone">{t("common:phoneNumber")}</Th>
							<Th column="answered">{t("rsvp.answered")}</Th>
							<Th column="feePayed">{t("rsvp.feePayed")}</Th>
							<Th column="add">{``}</Th>
						</Thead>
						{addResponderOpen && (
							<Tr
								key={"index"}
								className={`new-responder ${
									addResponderOpen ? "" : "is-closed"
								}`}
							>
								<Td column={"delete"} value="delete" key="delete">
									<BIN
										className={"remove-rsvp-responder"}
										onClick={this.removeNewResponder}
									/>
								</Td>
								<Td column="name" value={"sort-first"}>
									<input
										value={newResponderName}
										onChange={this.updateNewName}
									/>
								</Td>
								<Td column="email" value={"sort-first"}>
									<input
										value={newResponderEmail}
										onChange={this.updateNewEmail}
										style={{ color: newResponderEmailValid ? "black" : "red" }}
									/>
								</Td>
								<td column="add">
									<div
										className={`add-new-responder ${
											addButtonDisabled ? "disabled" : ""
										}`}
										onClick={addButtonDisabled ? null : this.addResponder}
									>
										{t("rsvp.addResponder")}
									</div>
								</td>
							</Tr>
						)}
						{rows}
					</Table>

					{showWaitingList && (
						<legend
							className="legend-nomargin"
							style={{ borderBottom: "none", marginTop: "30px" }}
						>
							{t("rsvp.waitingMembers")}{" "}
							{`(${waitingRows.length} ${t("rsvp.people")})`}
						</legend>
					)}
					{showWaitingList && (
						<Table className="reactable" sortable={sorts}>
							<Thead>
								<Th
									column={"delete"}
									key={"delete"}
									style={{ position: "relative" }}
								>
									<PLUS_WHITE
										className="rsvp__content__add-rsvp-responder"
										onClick={this.toggleAddWaitingOpen}
									/>
								</Th>
								<Th column="name">{t("common:name")}</Th>
								<Th column="email">{t("common:email")}</Th>
								<Th column="phone">{t("common:phoneNumber")}</Th>
								<Th column="answered">{t("rsvp.answered")}</Th>
								<Th column="moveToPrimaryList">{``}</Th>
								<Th column="add">{``}</Th>
							</Thead>
							{addWaitingOpen && (
								<Tr
									key={"index"}
									className={`new-responder ${
										addWaitingOpen ? "" : "is-closed"
									}`}
								>
									<Td column={"delete"} value="delete" key="delete">
										<BIN
											className={"remove-rsvp-responder"}
											onClick={this.removeNewWaiting}
										/>
									</Td>
									<Td column="name" value={"sort-first"}>
										<input
											value={newWaitingName}
											onChange={this.updateNewWaitingName}
										/>
									</Td>
									<Td column="email" value={"sort-first"}>
										<input
											value={newWaitingEmail}
											onChange={this.updateNewWaitingEmail}
											style={{ color: newWaitingEmailValid ? "black" : "red" }}
										/>
									</Td>
									<td column="add">
										<div
											className={`add-new-responder ${
												addWaitingDisabled ? "disabled" : ""
											}`}
											onClick={addWaitingDisabled ? null : this.addWaiting}
										>
											{t("rsvp.addResponder")}
										</div>
									</td>
								</Tr>
							)}
							{waitingRows}
						</Table>
					)}

					<div
						className={
							"rsvp__content__confirmation-email" +
							(!confirmSignupOpen ? " is-closed" : "")
						}
					>
						<ExpandButton
							expanded={confirmSignupOpen}
							click={this.toggleConfirmSignupOpen}
						/>
						<legend className="legend-nomargin">
							{t("rsvp.automaticConfirmation")}
						</legend>
						<div>
							<p>{t("rsvp.confirmationClarification")}</p>
							<textarea
								{...this.props.rsvpDetails.rsvpWelcomeMailBody.input}
								rows={12}
								placeholder={confirmationEmail}
							/>
						</div>
					</div>
					{/* Mail responders */}
					<div
						className={
							"rsvp__content__mail-responders" +
							(!mailAllRespondersOpen ? " is-closed" : "")
						}
					>
						<ExpandButton
							expanded={mailAllRespondersOpen}
							click={this.toggleMailAllRespondersOpen}
						/>
						<legend className="legend-nomargin">
							{t("rsvp.mailResponders")}
						</legend>
						<div>
							{senderEmail ? (
								<p>{`${t("rsvp.sender")}: ${senderName}`}</p>
							) : (
								<p style={{ color: "red" }}>{`${t("rsvp.sender")}, ${t(
									"rsvp.noSender"
								)}`}</p>
							)}
							<p>{t("rsvp.receiversAll")}</p>
							<textarea
								value={mailAllRespondersSubject}
								onChange={this.updateMailAllRespondersSubject}
								rows={1}
								placeholder={t("rsvp.subject")}
							/>
							<textarea
								value={mailAllRespondersBody}
								onChange={this.updateMailAllRespondersBody}
								rows={12}
								placeholder={t("rsvp.message")}
							/>
							<button
								type="button"
								className="purple-button--wide button-nomargin"
								onClick={(e) => this.sendMailToAllResponders(e, senderEmail)}
								disabled={
									!senderEmail ||
									!mailAllRespondersSubject ||
									!mailAllRespondersBody ||
									isFetching ||
									mailAlreadySent
								}
							>
								{t("rsvp.send")}
							</button>
						</div>
					</div>
					{/* Mail responders who have not payed */}
					<div
						className={
							"rsvp__content__mail-responders" +
							(!mailFeeMissingOpen ? " is-closed" : "")
						}
					>
						<ExpandButton
							expanded={mailFeeMissingOpen}
							click={this.toggleMailFeeMissingOpen}
						/>
						<legend className="legend-nomargin">
							{t("rsvp.mailRespondersFeeMissing")}
						</legend>
						<div>
							{senderEmail ? (
								<p>{`${t("rsvp.sender")}: ${senderName}`}</p>
							) : (
								<p style={{ color: "red" }}>{`${t("rsvp.sender")}, ${t(
									"rsvp.noSender"
								)}`}</p>
							)}
							<p>{t("rsvp.receiversFeeMissing")}</p>
							<textarea
								value={mailFeeMissingSubject}
								onChange={this.updateMailFeeMissingSubject}
								rows={1}
								placeholder={t("rsvp.subject")}
							/>
							<textarea
								value={mailFeeMissingBody}
								onChange={this.updateMailFeeMissingBody}
								rows={12}
								placeholder={t("rsvp.message")}
							/>
							<button
								type="button"
								className="purple-button--wide button-nomargin"
								onClick={(e) => this.sendMailToFeeMissing(e, senderEmail)}
								disabled={
									!senderEmail ||
									!mailFeeMissingSubject ||
									!mailFeeMissingBody ||
									isFetching ||
									feeMissingAlreadySent
								}
							>
								{t("rsvp.send")}
							</button>
						</div>
					</div>
					<button
						type="button"
						className="purple-button--wide"
						onClick={this.exportRsvp}
						disabled={registryFetching}
						style={{ margin: "30px 0" }}
					>
						{t("rsvp.export")}
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		event: { rsvpMailSent, rsvpMailFeeMissingSent, isFetching },
		registry: { exported, isFetching: registryFetching },
	} = state;
	return {
		rsvpMailSent,
		rsvpMailFeeMissingSent,
		isFetching,
		exported,
		registryFetching,
	};
}

const translated = withNamespaces("event")(Rsvp);
export default connect(mapStateToProps)(translated);
