import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { withNamespaces } from "react-i18next";

import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";

import {
	submitEvent,
	showSuccess,
	removeEvent,
	hideSuccess,
	hideError,
} from "../../actions/FormActions/EventFormActions";

import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
	fetchArtistArtworks,
} from "../../actions/FormActions/ArtworkFormActions";

import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";

import {
	fetchEvent,
	updateEvent,
	resetEvent,
} from "../../actions/EventActions";
import { fetchArtworksWithQuery } from "../../actions/ArtworkActions";

import EventForm from "./EventForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import ArtistForm from "../Artist/ArtistForm";

import Main from "../../components/Main/Main";
import Modal from "../../components/Modal/Modal";
import InformMemberButton from "../../components/Buttons/InformMemberButton/InformMemberButton";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import {
	hasAccess,
	propsIdDiffer,
	propsArrayDiffer,
	propsOjectId,
} from "../../utils/Helpers";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";

class EditEvent extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentWillMount() {
		// prevEvent and nextEvent could be the same initially in componentWillReceiveProps,
		// so form wasn't filled in.
		const { eventId } = this.props.params;
		this.props.dispatch(resetEvent(eventId));
	}
	componentDidMount() {
		const { eventId } = this.props.params;
		this.props.dispatch(fetchEvent(eventId));
	}
	componentWillReceiveProps(newProps) {
		const prevEvent = this.props.event.event;
		const nextEvent = newProps.event.event;

		if (newProps.event.deletedEvent) {
			return this.props.router.push("/events");
		}

		const changedEvent = prevEvent._id !== nextEvent._id && nextEvent._id;
		// artist/artists/gallery on props won't change here, only changes on page reload.
		// Check those values on form.event instead, to update artworks list in real time.
		const changedGallery = propsIdDiffer(
			this.props.form.event,
			newProps.form.event,
			"gallery"
		);
		const changedArtist = propsIdDiffer(
			this.props.form.event,
			newProps.form.event,
			"artist"
		);
		const changedArtists = propsArrayDiffer(
			this.props.form.event,
			newProps.form.event,
			"artists"
		);
		const prevForVoting =
			this.props.form.event && this.props.form.event.voteEnabled ? true : false;
		const nextForVoting =
			newProps.form.event && newProps.form.event.voteEnabled ? true : false;
		const voteEnabledChanged = prevForVoting !== nextForVoting;

		if (
			changedEvent ||
			changedGallery ||
			changedArtist ||
			changedArtists ||
			voteEnabledChanged
		) {
			const galleryId = propsOjectId(newProps.form.event, "gallery"); // (newProps.form.event && newProps.form.event.gallery) ? nextEvent.gallery._id : nextEvent.gallery
			const artistId = propsOjectId(newProps.form.event, "artist"); // (nextEvent.artist && nextEvent.artist._id) ? nextEvent.artist._id : nextEvent.artist
			const artistIds =
				newProps.form.event && newProps.form.event.artists
					? newProps.form.event.artists.map((artist) => artist._id || artist)
					: [];

			const alreadyAdded =
				newProps.form.event && newProps.form.event.artworks
					? newProps.form.event.artworks
					: [];
			const alreadyAddedIds = alreadyAdded.map(
				(artwork) => artwork._id || artwork
			);

			let query = { $or: [{ _id: { $in: alreadyAddedIds } }] };
			const statusQuery = { status: "for-sale" };
			// Old events only had one artist, can have mulitple now.
			if (artistIds.length > 0) {
				query.$or.push({
					$and: [{ artistMod: { $in: artistIds } }, statusQuery],
				});
			} else if (artistId) {
				query.$or.push({
					$and: [{ artistMod: { $in: artistId } }, statusQuery],
				});
			}

			// Artworks list only shown if event has vote. Not sure if this query will be used in future.
			if (!nextForVoting && galleryId) {
				query.$or.push({ gallery: galleryId });
			}

			if (Object.keys(query).length > 0) {
				// Don't fetch with empty object or all artworks will be fetched.
				this.props.dispatch(fetchArtworksWithQuery(query));
			}
		}

		if (changedEvent) {
			this.props.dispatch(initialize("event", newProps.event.event));
		}
	}

	handleSubmit = () => {
		// TODO: Handle save with redux
		const {
			form: { event, initial },
		} = this.props;
		if (event.rsvpMaxUsers) {
			const toNumber = parseInt(event.rsvpMaxUsers);
			if (typeof toNumber === "number") event.rsvpMaxUsers = toNumber;
			else event.rsvpMaxUsers = null;
		}
		if (event.vote && event.vote.voteLimit) {
			const toNumber = parseInt(event.vote.voteLimit);
			if (typeof toNumber === "number" && !isNaN(toNumber))
				event.vote.voteLimit = toNumber;
			else event.vote.voteLimit = 1;
		}

		this.props.dispatch(submitEvent(event, initial));
	};
	handleRemove = () => {
		const event = this.props.form.event;
		this.props.dispatch(removeEvent(event));
	};
	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};

	toggleForItem = (updatedArtworksList) => {
		console.log("updatedArtworksList.length", updatedArtworksList.length);
		const {
			event: { event },
		} = this.props;
		const params = {
			_id: event._id,
			artworks: updatedArtworksList,
		};
		console.log("params", params);
		this.props.dispatch(updateEvent(params, true));
	};

	render() {
		const {
			showArtworkModal,
			showArtistModal,
			isAdmin,
			isSociety,
			isAuthor,
			isArtist,
			user,
			form,
			t,
		} = this.props;
		const {
			event,
			event: { artist, gallery, endDate },
			isFetching,
		} = this.props.event;

		const isOver = moment(endDate).isBefore(moment());
		const isArtSociety = isVenueArtSociety(gallery);
		// Artists should not be able to edit events created by someone else.
		let blockEditing = false;
		if (isArtist) {
			const createdBy = event.created_by
				? event.created_by._id || event.created_by
				: false;
			if (createdBy && createdBy !== user.id) blockEditing = true;
		}

		const eventVideo =
			form.event && form.event.videoLink ? form.event.videoLink : null;
		const addedArtworkIds =
			event && event.artworks
				? event.artworks.map((artwork) => artwork._id || artwork)
				: [];

		if (!hasAccess(user, event)) return null;

		if (event.gallery || event.artist || event.artists) {
			return (
				<div>
					<Main>
						<div
							style={
								blockEditing ? { pointerEvents: "none", opacity: 0.7 } : {}
							}
						>
							<EventForm
								onSubmit={this.handleSubmit}
								isAdmin={isAdmin}
								artist={artist}
								isSociety={isSociety}
								gallery={event.gallery}
								isArtSociety={isArtSociety}
								eventVideo={eventVideo}
								addedArtworkIds={addedArtworkIds}
								toggleForItem={this.toggleForItem}
								rsvp={event.rsvp}
								waitingList={event.waitingList}
								isNew={false}
							/>
						</div>
						{!isOver && isArtSociety && (isAdmin || isSociety) && (
							<InformMemberButton
								item={event}
								type={"event"}
								isFetching={isFetching}
							/>
						)}
						{!isOver && !isArtSociety && isAdmin && (
							<InformMemberButton
								item={event}
								type={"event"}
								isFetching={isFetching}
								previewOnly={true}
							/>
						)}
						{isAdmin && (
							<div className="fieldset">
								<SelectionPicker type="events" item={event} />
							</div>
						)}
						{(isAdmin || isSociety || isAuthor) && (
							<div className="fieldset">
								<legend style={{ paddingTop: 40 }}>
									{t("common:dangerZone")}
								</legend>
								<button
									type="button"
									className="purple-button--wide--mod_danger"
									onClick={() => {
										let confirm = window.confirm(t("common:areYouSure"));
										if (confirm) this.handleRemove();
									}}
								>
									{t("single.deleteEvent")}
								</button>
							</div>
						)}
					</Main>
					{showArtworkModal ? (
						<Modal onCloseClick={this.toggleArtworksModal}>
							<ArtworkForm
								onSubmit={this.handleArtworkSubmit}
								isAdmin={isAdmin}
								isArtist={artist ? true : false}
							/>
						</Modal>
					) : null}
					{showArtistModal ? (
						<Modal onCloseClick={this.toggleArtistModal}>
							<ArtistForm
								onSubmit={this.handleArtistSubmit}
								isAdmin={isAdmin}
								isModal={true}
								forArtSociety={gallery && gallery.venueType === "ArtSociety"}
							/>
						</Modal>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	}
}

function mapStateToProps(state) {
	const {
		event,
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		auth: { user, isAdmin },
	} = state;
	const isSociety = user.role === "society";
	const isAuthor = user.role === "author";
	const isArtist = user.role === "artist";
	const form = {
		submitting: isSubmitting("event")(state),
		submitSucceeded: hasSubmitSucceeded("event")(state),
		submitFailed: hasSubmitFailed("event")(state),
		event: getFormValues("event")(state),
		initial: getFormInitialValues("event")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	return {
		event,
		user,
		isAdmin,
		isSociety,
		isAuthor,
		isArtist,
		form,
		artworkForm,
		artistForm,
		showArtworkModal,
		showArtistModal,
	};
}

// Need withNamespaces here or children (InformMemberButton) won't update on language change.
const translated = withNamespaces("event")(EditEvent);
export default connect(mapStateToProps)(withRouter(translated));
