import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import Select from "react-select";
import { fetchAllArtistsFast } from "../../actions/ArtistActions";
import ChatList from "../../components/Chat/ChatList";
import EmailConstructor from "../../components/EmailConstructor/EmailConstructor";
import ArtistProModals from "../../components/Modal/Custom/ArtistProModals";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { countUnreadChats } from "@artworkslab/sharedmodules/src/utils/Helpers";

const ARROW = require("../../../assets/images/svg/arrow_right.svg");

class ChatPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			dropdownOptions: [],
			selectedArtist: null,
			selectedTab: "enquiries",
		};
	}

	componentDidMount() {
		const { isAdmin, allArtists, artist } = this.props;
		if (Object.keys(allArtists).length === 0) {
			this.props.dispatch(fetchAllArtistsFast());
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, allArtists, artist } = nextProps;
		const artistKeys = Object.keys(allArtists);
		let artistOptions = this.state.artistOptions;
		let updateOptions = false;

		//Update list if we are missing artists
		if (artistOptions.length !== artistKeys.length) {
			artistOptions = [];
			artistOptions.push({
				value: "All",
				label: "All",
				type: "artist",
			});
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				artistOptions.push({
					value: artist._id,
					label: artist.name,
					type: "artist",
				});
			});
			updateOptions = true;
		}

		//Update list if something changed
		if (updateOptions) {
			this.setState({
				artistOptions,
				dropdownOptions: artistOptions.sort(this.sortOptions),
			});
		}
	}

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	selectArtist = (artist) => {
		this.setState({
			selectedArtist: artist,
		});
	};

	renderArtistSelector = (isAdmin) => {
		const { fetchingArtist, t } = this.props;
		const { dropdownOptions, selectedArtist } = this.state;
		if (!isAdmin) return null;
		return (
			<div>
				<Select
					value={selectedArtist}
					options={dropdownOptions}
					onChange={this.selectArtist}
					onInputChange={() => {}}
					valueKey={"artist"}
					labelKey="label"
					backspaceToRemoveMessage=""
					backspaceRemoves={false}
					isLoading={fetchingArtist}
					placeholder={t("list.artist")}
					multi={false}
					autosize={false}
					className={"gallery-picker-select"}
					isSearchable={true}
				/>
			</div>
		);
	};

	renderChatContent = (artistId) => {
		const { isAdmin, notifications, buttonDisabled, t } = this.props;
		const { selectedTab } = this.state;
		const firstPage = selectedTab === "enquiries" || selectedTab === "messages";

		if (!artistId) return null;
		return (
			<div className="ChatPage__content">
				{firstPage ? (
					<div className="ChatPage__header">
						<button
							className="purple-button--square"
							onClick={() => this.setState({ selectedTab: "email" })}
							disabled={buttonDisabled}
						>
							{t("emails:artistEmail.title")}
						</button>
						<div className="ChatPage__tabs">
							<button
								className={
									"ChatPage__tabs" +
									(selectedTab === "enquiries" ? " is-selected" : "")
								}
								onClick={() => this.setState({ selectedTab: "enquiries" })}
								disabled={buttonDisabled}
							>
								{`${t("chat:enquiries")} (${notifications.enquiries || 0})`}
							</button>
							<button
								className={
									"ChatPage__tabs" +
									(selectedTab === "messages" ? " is-selected" : "")
								}
								onClick={() => this.setState({ selectedTab: "messages" })}
								disabled={buttonDisabled}
							>
								{`${t("chat:messages")} (${notifications.messages || 0})`}
							</button>
						</div>
					</div>
				) : (
					<div className="ChatPage__header">
						<div className="ChatPage__tabs">
							<div
								className="step-back-button"
								onClick={() => this.setState({ selectedTab: "enquiries" })}
							>
								<ARROW />
								<span>{t("emails:artistEmail.back")}</span>
							</div>
						</div>
					</div>
				)}
				{selectedTab === "email" ? (
					<EmailConstructor />
				) : (
					<ChatList
						isAdmin={isAdmin}
						artistId={artistId}
						selectedTab={selectedTab}
					/>
				)}
			</div>
		);
	};

	render() {
		const { isAdmin, userArtistId } = this.props;
		const { selectedArtist } = this.state;
		const isResponsive = appGlobals.useResponsive;
		let artistId = null;
		let artistName = null;

		if (isAdmin && selectedArtist) {
			artistId =
				selectedArtist && selectedArtist.type === "artist"
					? selectedArtist.value
					: false;
			artistName =
				selectedArtist && selectedArtist.type === "artist"
					? selectedArtist.label
					: false;
		} else {
			artistId = userArtistId;
		}
		const selector = this.renderArtistSelector(isAdmin);
		const chatContent = this.renderChatContent(artistId);

		if (isResponsive) {
			return (
				<div className="ChatPage">
					<MainResp>
						{isAdmin && selector}
						{chatContent}
					</MainResp>
				</div>
			);
		}

		return (
			<div className="ChatPage">
				<Main>
					{isAdmin && selector}
					{chatContent}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		artist: { artist },
		artist: { allArtists, isFetching: fetchingArtist },
		enquiries: { chat, isFetching: fetchingChat },
		notifications: { notifications },
	} = state;

	const isAdmin = user.role === "admin";
	const userArtistId = user.artist ? user.artist._id || user.artist : false;
	const buttonDisabled = fetchingChat && chat.length <= 0;

	return {
		artist,
		isAdmin,
		userArtistId,
		allArtists,
		fetchingArtist,
		buttonDisabled,
		notifications,
	};
}

const translated = withNamespaces("artworks")(ChatPage);
export default connect(mapStateToProps)(translated);
