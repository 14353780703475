import React from "react";
import { withNamespaces } from "react-i18next";

const ThumbnailError = (e, failedSource, fullSrc) => {
	// Must get failedSource from e.target.src inside img element.
	// If full source has failed, don't set as src, it will loop.
	if (failedSource !== fullSrc && fullSrc) {
		e.target.src = fullSrc;
	} else if (__DEVELOPMENT__) {
		// Make it easier to work with locally if no internet connection.
		e.target.src =
			"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==";
	}
};

const RelatedShow = ({ show, alreadyAdded, addOrRemove, t }) => {
	const fullSrc = show.featuredImage ? show.featuredImage.src : "";
	const thumbnail =
		show.featuredImage && show.featuredImage.thumbnails
			? show.featuredImage.thumbnails["100x100"].src
			: fullSrc;
	//<div style={{ backgroundImage: `url(${imageSource})` }} className={ related('image').state({ added: alreadyAdded }) } />
	return (
		<div className={"related-show" + (alreadyAdded ? " is-added" : "")}>
			<img
				src={thumbnail}
				onError={(e) => ThumbnailError(e, e.target.src, fullSrc)}
				className={"related-show__image" + (alreadyAdded ? " is-added" : "")}
			/>
			<div className="related-show__info">
				<div className="related-show__info__texts">
					<div
						className={
							"related-show__info__texts__text" +
							(alreadyAdded ? " is-added" : "")
						}
					>
						{show.name}
					</div>
					<div
						className={
							"related-show__info__texts__text" +
							(alreadyAdded ? " is-added" : "")
						}
					>
						{show.gallery ? show.gallery.name : ""}
					</div>
				</div>
				<div className="related-show__info__buttons">
					<div
						onClick={() => addOrRemove(show)}
						className="related-show__info__buttons__button"
					>
						{alreadyAdded ? t("showPickerBig.remove") : t("showPickerBig.add")}
					</div>
					<a
						href={`https://artworksapp.se/shows/${show.slug}`}
						target="_blank"
						className="related-show__info__buttons__button"
					>
						{t("showPickerBig.viewShow")}
					</a>
				</div>
			</div>
		</div>
	);
};

export default withNamespaces("components")(RelatedShow);
