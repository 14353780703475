import React from 'react'
import { connect } from 'react-redux'
import DateRangePicker from '../DatePicker/DateRangePicker'
import {
  countMembers,
  findMembers,
  memberCountDifference,
}                           from '../../actions/RegistryActions'

class AdminFunctions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formattedMemberCount: '',
      formattedNewMembers: '',
      disableButtons: false,
    }
    this.startDate = ''
    this.endDate = ''
  }

  sortSocieties = (a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  }

  showMemberCount = (e) => {
    e.preventDefault()
    this.setState({ disableButtons: true })
    this.props.dispatch( countMembers() )
    .then(response => {
      if (response.error) {
        this.setState({
          formattedMemberCount: response.error,
        })
      } else {
        let text = ''
        let totalMembers = 0
        let totalMembersWithEmail = 0

        let societyArray = Object.keys(response).map(key => response[key])
        societyArray.sort(this.sortSocieties)

        societyArray.forEach(society => {
          // society = { name: galleryName, members: number, withEmail: number }
          text += `${society.name}, members: ${society.members}, with email: ${society.withEmail}\n\n`
          totalMembers += society.members
          totalMembersWithEmail += society.withEmail
        })
        text += `Total: ${totalMembers} members, ${totalMembersWithEmail} with email`

        this.setState({
          formattedMemberCount: text,
          disableButtons: false,
        })
      }
    })
    .catch(err => {
      this.setState({
        formattedMemberCount: err,
        disableButtons: false,
      })
    })
  }

  findMembersWithinTimespan = (appliedFromWebb) => {
    this.setState({ disableButtons: true })
    this.props.dispatch( findMembers(this.startDate, this.endDate, appliedFromWebb) )
    .then(response => {
      let text = ''
      let totalNewMembers = 0

      let societyArray = response.slice()
      societyArray.sort(this.sortSocieties)

      societyArray.forEach(society => {
        // society = { name: galleryName, newMembersCount: number, newMembersData: string }
        text += `${society.name}, nya medlemmar: ${society.newMembersCount}\n${society.newMembersData}\n\n`
        totalNewMembers += society.newMembersCount
      })
      text += `Totalt: ${totalNewMembers} nya medlemmar`

      this.setState({
        formattedNewMembers: text,
        disableButtons: false,
      })
    })
    .catch(err => {
      this.setState({
        formattedNewMembers: err,
        disableButtons: false,
      })
    })
  }

  memberCountDifference = () => {
    this.setState({ disableButtons: true })
    this.props.dispatch( memberCountDifference(this.startDate, this.endDate) )
    .then(response => {
      let text = '(Not including removed members)\n\n'

      let societyArray = Object.keys(response).map(key => response[key])
      societyArray.sort(this.sortSocieties)

      societyArray.forEach(society => {
        // society = {
        //   name: galleryName,
        //   firstDateAll: number,
        //   secondDateAll: number,
        //   firstDateFromWebb: number,
        //   secondDateFromWebb: number,
        // }
        text += `${society.name}\nAll members:\nDate 1: ${society.firstDateAll}, Date 2: ${society.secondDateAll}\nApplied from webb:\nDate 1: ${society.firstDateFromWebb}, Date 2: ${society.secondDateFromWebb}\n\n`
      })

      this.setState({
        formattedNewMembers: text,
        disableButtons: false,
      })
    })
    .catch(err => {
      this.setState({
        formattedNewMembers: err,
        disableButtons: false,
      })
    })
  }

  changeDates = (startDate, endDate) => {
    this.startDate = startDate
    this.endDate = endDate
  }

  render() {
    const { formattedMemberCount, formattedNewMembers, disableButtons } = this.state

    if (__DEVELOPMENT__ || __STAGING__) {
      return (
        <div style={{ marginBottom: '100px 0 30px' }}>
          <legend className="legend-nomargin">Admin</legend>
          <button className="purple-button--wide button-nomargin" onClick={ this.showMemberCount } disabled={ disableButtons }>Count members</button>
          <textarea style={{ marginTop: '20px', backgroundColor: '#f9f9f9', border: '1px solid #e5e5e5', resize: 'vertical' }} rows="10" value={ formattedMemberCount } />
          <button className="purple-button--wide" onClick={() => this.findMembersWithinTimespan(false)} disabled={ disableButtons } style={{ margin: '20px 20px 20px 0' }}>All new members</button>
          <button className="purple-button--wide" onClick={() => this.findMembersWithinTimespan(true)} disabled={ disableButtons } style={{ margin: '20px 20px 20px 0' }}>Applied from webb</button>
          <button className="purple-button--wide" onClick={() => this.memberCountDifference()} disabled={ disableButtons } style={{ margin: '20px 0 20px 0' }}>Difference</button>
          <DateRangePicker withTime={ false } updateCallbackMs={ this.changeDates }/>
          <textarea style={{ marginTop: '5px', backgroundColor: '#f9f9f9', border: '1px solid #e5e5e5', resize: 'vertical' }} rows="10" value={ formattedNewMembers } />
        </div>
      )
    }

    return (
      <div style={{ marginBottom: '100px 0 30px' }}>
        <legend className="legend-nomargin">Admin</legend>
        <button className="purple-button--wide button-nomargin" onClick={ this.showMemberCount } disabled={ disableButtons }>Count members</button>
        <textarea style={{ marginTop: '20px', backgroundColor: '#f9f9f9', border: '1px solid #e5e5e5', resize: 'vertical' }} rows="10" value={ formattedMemberCount } />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { registry: { isFetching } } = state
  return { isFetching }
}

export default connect(mapStateToProps)(AdminFunctions)
