import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Fields, formValueSelector, reduxForm } from "redux-form";
import { withNamespaces } from "react-i18next";
import Select from "../../components/FormInput/Select";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextArea from "../../components/FormInput/TextArea";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import PostPicker from "../../components/FormComponents/PostPicker/PostPicker";
import FormEditor from "../../components/FormComponents/FormEditor/FormEditor";
import CategoryPicker from "../../components/FormComponents/CategoryPicker/CategoryPicker";
import SingleDatePicker from "../../components/FormComponents/DateRange/SingleDatePicker";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import ArtworkUpload from "../../components/FormComponents/ArtworkUpload/ArtworkUpload";
import PublishStatus from "../../components/FormComponents/PublishStatus/PublishStatus";
import ArtworkUploadRotate from "../../components/FormComponents/ArtworkUpload/ArtworkUploadRotate";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import Tooltip from "../../components/Tooltip/Tooltip";
import { validateEmbed } from "../../utils/Validators";
import { CENTER_IMAGE_OPTIONS, videoTooltip } from "../../utils/values";
import { imageRotationActivated } from "../../utils/Helpers";
import { showPremiumUpgradeModal } from "../../utils/Dispatcher";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { showCustomModal } from "../../actions/AppActions";

import "react-datepicker/dist/react-datepicker.css";

// let PostForm = (props) => {
class PostForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorChanged: false,
			expanded: false,
		};
	}

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	updateChild = () => {
		this.editor.updateContentFromParent();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	sectionEmailInfo = () => {
		return (
			<div className="fieldset-block" style={{ marginBottom: "1rem" }}>
				<p>
					När du sparat så kan du välja att det ska gå ut ett mejl till dina
					medlemmar om den här informationen.
				</p>
			</div>
		);
	};

	sectionEmailsSent = () => {
		return (
			<div className="fieldset" style={{ marginTop: "30px" }}>
				<legend>Last email sent (admin only)</legend>
				<div className="input-holder">
					<Field name="emailsSentDate" component={TextInput} />
				</div>
			</div>
		);
	};

	showProModal = () => {
		if (__USE_TIERS__ && this.props.isSociety) {
			return showPremiumUpgradeModal(this.props.dispatch, "Premium");
		}

		const content = (
			<div>
				<p>
					Här kan du som <i>Premium</i> ladda upp en videofil.
				</p>
				<p>
					Läs mer och testa <i>Premium</i> gratis{" "}
					<a href="https://artworks.se/konstforening" target="_blank">
						här
					</a>
					! 🎨
				</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			isAdmin,
			isSociety,
			postId,
			importFromOldEditor,
			belongsToArtSociety,
			imageGallery,
			blockEditing,
			isPremium,
			postVideo,
			isFetching,
			t,
		} = this.props;
		const { editorChanged, expanded } = this.state;
		// isSociety if user is society
		let hideOldEditor = isSociety;
		if (isAdmin) {
			hideOldEditor = !postId || belongsToArtSociety ? true : false;
		}
		const expandButtonStyle = {
			position: "absolute",
			right: "40px",
			top: 0,
		};

		hideOldEditor = false; // __DEVELOPMENT__ || __STAGING__ ? true : false
		const hideNewEditor = !hideOldEditor;

		const oldEditorStyle = hideOldEditor
			? { display: "none", marginBottom: "30px" }
			: { marginBottom: "30px" };
		const newEditorStyle = hideNewEditor ? { display: "none" } : {};

		const postIsForArtSociety = isSociety || belongsToArtSociety;

		const useUploadWithRotate = imageRotationActivated();

		if (imageGallery) {
			return (
				<form onSubmit={handleSubmit} className="Form">
					{isAdmin && (
						<div>
							<legend>{t("common:venue")}</legend>
							<Field name="belongsTo" component={GalleryPicker} />
						</div>
					)}
					<div className="input-holder input-holder--full">
						<legend className="legend-nomargin">{t("single.title")}</legend>
						<Field
							name="title"
							component={TextInput}
							placeholder={t("single.title")}
							required
						/>
					</div>
					<div className="fieldset">
						<legend>{t("single.descriptionHeader")}</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="excerpt"
								component={TextArea}
								placeholder={t("single.descriptionPlaceholder")}
							/>
						</div>
					</div>
					<div className="fieldset">
						<legend>{t("single.featuredImage")}</legend>
						<Fields
							names={[
								"featuredImage.src",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
						/>
						{isAdmin && (
							<Field name="featuredImage.color" component={ColorPicker} />
						)}
					</div>

					<div className="fieldset">
						<div className="input-holder input-holder--flex">
							<legend className="legend-nomargin">
								{t("common:uploadVideo")}
							</legend>
							<Tooltip
								data={videoTooltip()}
								style={{ left: "190px", right: "auto" }}
							/>
							<Field
								name="videoLink"
								component={TextInput}
								placeholder={t("common:youtubeExamle")}
								validate={validateEmbed}
								disabled={!isPremium && !isAdmin}
								green
								video
							/>
							{!isPremium && !isAdmin && (
								<div
									className="block-div"
									onClick={() => this.showProModal()}
								></div>
							)}
						</div>
						{postVideo && (isPremium || isAdmin) && (
							<div className="videoContainer">
								<iframe
									width="440px"
									height="247px"
									src={postVideo}
									frameBorder="0"
								/>
							</div>
						)}
					</div>

					<div className="fieldset">
						<legend>{t("single.imageGallery")}</legend>
						<Fields
							names={[
								"images",
								"uploads",
								"uploadedImage.URI",
								"uploadedImage.fileName",
								"uploadedImage.contentType",
							]}
							component={
								useUploadWithRotate ? ArtworkUploadRotate : ArtworkUpload
							}
							multi={true}
							sortable={true}
							captionOn={true}
							postId={postId}
						/>
					</div>
					{(isSociety || isAdmin) && (
						<div className="fieldset" style={{ position: "relative" }}>
							<div className="input-holder input-holder--full">
								<Field name="publish.status" component={PublishStatus} />
							</div>
						</div>
					)}
					{(isSociety || belongsToArtSociety) &&
						!postId &&
						this.sectionEmailInfo()}
					{!blockEditing && (
						<div className="fieldset">
							<div className="input-holder">
								<Field
									name="slug"
									itemType="post"
									component={TextDisplay}
									isAdmin={isAdmin}
								/>
							</div>
							<div className="fixed-save-button">
								<button
									className="purple-button--wide"
									type="submit"
									disabled={pristine || submitting || isFetching}
								>
									{t("common:save")}
								</button>
							</div>
						</div>
					)}

					{isAdmin && this.sectionEmailsSent()}
				</form>
			);
		}

		return (
			<form onSubmit={handleSubmit} className="Form">
				{isAdmin && (
					<div>
						<legend>{t("common:venue")}</legend>
						<Field name="belongsTo" component={GalleryPicker} />
					</div>
				)}

				<div className="input-holder input-holder--full">
					<legend className="legend-nomargin">{t("single.title")}</legend>
					<Field
						name="title"
						component={TextInput}
						placeholder={t("single.title")}
						required
					/>
				</div>

				<div className="fieldset">
					<legend>{t("single.featuredImage")}</legend>
					<Fields
						names={[
							"featuredImage.src",
							"uploadedImage.URI",
							"uploadedImage.fileName",
							"uploadedImage.contentType",
						]}
						component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
						profileImage={true}
						canRemoveImage={true}
					/>
					{isAdmin && (
						<Field name="featuredImage.color" component={ColorPicker} />
					)}
				</div>

				<div className="fieldset">
					<div className="input-holder input-holder--flex">
						<legend className="legend-nomargin">
							{t("common:uploadVideo")}
						</legend>
						<Tooltip
							data={videoTooltip()}
							style={{ left: "auto", right: "30px" }}
						/>
						<Field
							name="videoLink"
							component={TextInput}
							placeholder={t("common:youtubeExamle")}
							validate={validateEmbed}
							disabled={!isPremium && !isAdmin}
							green
							video
						/>
						{!isPremium && !isAdmin && (
							<div
								className="block-div"
								onClick={() => this.showProModal()}
							></div>
						)}
					</div>
					{postVideo && (isPremium || isAdmin) && (
						<div className="videoContainer">
							<iframe
								width="440px"
								height="247px"
								src={postVideo}
								frameBorder="0"
							/>
						</div>
					)}
				</div>

				{isAdmin && (
					<div className="input-holder input-holder--full">
						<legend className="legend-nomargin">
							Image center position (admin only)
						</legend>
						<Field
							name="featuredImage.center"
							options={CENTER_IMAGE_OPTIONS}
							component={Select}
						/>
					</div>
				)}

				{!postIsForArtSociety && (
					<div className="fieldset">
						<legend>{t("single.excerpt")}</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="excerpt"
								component={TextArea}
								placeholder={t("single.excerptDescription")}
							/>
						</div>
					</div>
				)}

				<div className="fieldset" style={oldEditorStyle}>
					<legend>{t("single.mainBody")}</legend>
					<div className="input-holder input-holder--full">
						<Fields
							names={["draftContentState", "htmlContent"]}
							component={FormEditor}
							onRef={(ref) => (this.editor = ref)}
							onEditorChange={this.editorChange}
						/>
					</div>
				</div>
				{!isSociety && (
					<div className="fieldset">
						<legend>Related posts</legend>
						<div className="input-holder input-holder--full input-holder--nopadding">
							<Field name="relatedPosts" component={PostPicker} />
						</div>
					</div>
				)}
				{!isSociety && (
					<div className="fieldset">
						<legend>Categories</legend>
						<Field name="category" type="post" component={CategoryPicker} />
					</div>
				)}

				{/*<div className="fieldset">
    				<legend>Available from</legend>
    				<Field
    					name="publish.availiable_from"
    					component={ SingleDatePicker }
    					/>
    			</div>*/}

				{isAdmin && (
					<div className="fieldset">
						<legend>Editor rating</legend>
						<div className="input-holder">
							<Field
								name="editorRating"
								component={TextInput}
								placeholder="Editor rating(1-10)"
							/>
						</div>
					</div>
				)}

				{(isSociety || isAdmin) && (
					<div className="fieldset" style={{ position: "relative" }}>
						<div className="input-holder input-holder--full">
							<Field name="publish.status" component={PublishStatus} />
						</div>
					</div>
				)}

				{(isSociety || belongsToArtSociety) &&
					!postId &&
					this.sectionEmailInfo()}
				{/*<button type="submit" disabled={ pristine || submitting || isFetching }>{t('common:save')}</button>*/}
				{/* Button to run onChange and onBlur in editor before submitting. */}
				{!blockEditing && (
					<div className="fieldset">
						<div className="input-holder">
							<Field
								name="slug"
								itemType="post"
								component={TextDisplay}
								isAdmin={isAdmin}
							/>
						</div>
						<div className="fixed-save-button">
							<button
								className="purple-button--wide"
								type="submit"
								onClick={this.updateChild}
								disabled={
									(pristine || submitting || isFetching) && !editorChanged
								}
							>
								{t("common:save")}
							</button>
						</div>
					</div>
				)}

				{isAdmin && this.sectionEmailsSent()}
			</form>
		);
	}
}

PostForm = reduxForm({
	form: "post",
})(PostForm);

const selector = formValueSelector("post");
PostForm = connect((state) => {
	// isFetching used to disable save button when uploading image in Jodit editor.
	const { isFetching } = state.post;
	const postId = selector(state, "_id");

	const oldEditorState = selector(state, "htmlContent");
	const newEditorState = selector(state, "editorContent");
	const importFromOldEditor =
		oldEditorState && !newEditorState ? oldEditorState : false;

	const post = state.post.post;
	const belongsToGallery = selector(state, "belongsTo");
	const belongsToArtSociety = isVenueArtSociety(belongsToGallery);

	return { postId, importFromOldEditor, belongsToArtSociety, isFetching };
})(PostForm);

export default withNamespaces("post")(PostForm);
