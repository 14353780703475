import { createSelector } from 'reselect'

const getScrapedArtists = state => state.scraped.scrapedArtists
const getScrapedShows = state => state.scraped.scrapedShows
const getScrapedGalleries = state => state.scraped.scrapedGalleries

export const getPendingArtists = createSelector(
  [ getScrapedArtists ],
  ( scrapedArtists ) => {
    let artists = {}
    Object.keys(scrapedArtists).map((artistId) => {
      const artist = scrapedArtists[artistId]
      if (artist.status === 'pending') {
        artists[artist._id] = artist
      }
    })
    return artists
  }
)

export const getPendingShows = createSelector(
  [ getScrapedShows ],
  ( scrapedShows ) => {
    let shows = {}
    Object.keys(scrapedShows).map((showId) => {
      const show = scrapedShows[showId]
      if (show.status === 'pending') {
        shows[show._id] = show
      }
    })
    return shows
  }
)

export const getPendingGalleries = createSelector(
  [ getScrapedGalleries ],
  ( scrapedGalleries ) => {
    let galleries = {}
    Object.keys(scrapedGalleries).map((galleryId) => {
      const gallery = scrapedGalleries[galleryId]
      if (gallery.status === 'pending') {
        galleries[gallery._id] = gallery
      }
    })
    return galleries
  }
)
