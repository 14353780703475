'use strict'

const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 1000
}

let instance = null

class LocationUtil {
  constructor(navigator) {
    if (!instance) {
      instance = this
      instance.navigator = navigator
      instance.watchID = null
      instance.currentPosition = {}
    }
    return instance
  }

  // Used in Web environment
  static getPosition() {
    return new Promise(function(resolve, reject) {
      if (typeof navigator === undefined) {
        reject('Navigator not availiable')
      }else if(!navigator.geolocation) {
        reject('GeoLocation not availiable')
      }else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            resolve(pos)
          },
          (err) => {
            reject(err)
          }
        )
      }
    });
  }

  positionError = (error) => {
    console.error('locationError', error)
  }

  positionUpdated = (position) => {
    console.log('locationChanged', position.coords);
    this.currentPosition = position.coords
  }

  checkPosition = () => {
    const that = this
    return new Promise(function(resolve, reject) {
      if (!that.navigator.geolocation){
        const error = new PositionError('Position unavailiable')
        that.positionError(error)
        reject(error)
      }
      that.navigator.geolocation.getCurrentPosition(
        (position) => {
          that.positionUpdated(position)
          resolve(position.coords)
        },
        (error) => {
          that.positionError(error)
          resolve(error);
        },
        GEOLOCATION_OPTIONS
      )
    });
  }

  followPosition = () => {
    const that = this
    return new Promise(function(resolve, reject) {
      if (!that.navigator.geolocation){
        const error = new PositionError('Position unavailiable')
        that.positionError(error)
        return reject(error)
      }
      if (that.currentPosition && that.currentPosition.longitude) {
        return resolve(that.currentPosition)
      }
      that.watchID = that.navigator.geolocation.watchPosition(
        (position) => {
          that.positionUpdated(position)
          resolve(position.coords)
        },
        (error) => {
          that.positionError(error)
          resolve(error);
        },
        GEOLOCATION_OPTIONS
      )
    });
  }

  unFollowPosition = () => {
    this.navigator.geolocation.clearWatch(this.watchID)
  }

}

export default LocationUtil
