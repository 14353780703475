import {
  saveShow,
  deleteShow,
}   from '../ShowActions'
import {
  showErrorModal
}   from '../AppActions'
import {
  initialize,
  stopSubmit,
  startSubmit,
  change,
}   from 'redux-form'

import Validator              from 'validatorjs'
import sv                     from './sv'
import moment                 from 'moment'
import diff                   from 'object-diff'
import i18next                from 'i18next'
import {  getObjectProp,
          hasObjectProp }     from '../../utils/Helpers'

export const LOAD = 'artworksapp/show/LOAD'
export const SUBMIT = 'artworksapp/show/SUBMIT'

export const FETCH_SHOWS = 'artworksapp/show/FETCH_SHOWS'

export const SHOW_SUCCESS = 'artworksapp/show/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/show/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/show/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/show/HIDE_ERROR'

export const SHOW_SHOW_MODAL = 'artworksapp/show/SHOW_SHOW_MODAL'
export const HIDE_SHOW_MODAL = 'artworksapp/show/HIDE_SHOW_MODAL'

const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

export function submitShow(data, initial) {
  return (dispatch, getState) => {
    const { user, isAdmin } = getState().auth
    const isArtist = user.role === 'artist'
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    let rules = {
      title: 'required',
      artists: 'array|min:1',
      editorRating: 'numeric',
      priceRange: {
        max: 'numeric',
        min: 'numeric'
      }
    }

    if (!isAdmin && !isArtist) {
      data.gallery = user.gallery
    } else if (isArtist) {
      data.artistOrganizer = user.artist
    } else if (isAdmin) {
      //Specific validation for admin
      if (!data.gallery && !data.artistOrganizer) {
        customErrors.gallery = 'Gallery missing.'
      }
    }
    // if (!data.locationName && !data.location) {
    //   customErrors.locationName = isSwedish ? 'Adress är obligatoriskt.' : 'Location is required.'
    // }
    if ((hasObjectProp(data, 'uploadedImage.URI') &&
        hasObjectProp(data, 'uploadedImage.contentType') &&
        hasObjectProp(data, 'uploadedImage.fileName'))
        || hasObjectProp(data, 'featuredImage.src')) {
          console.log('image is valid');
    }else{
      // Image not required anymore. A completely black base64 image provided in add() in shows.controller.js
      // console.log('image is invalid');
      // customErrors.featuredImage = { src: isSwedish ? 'Bild är obligatoriskt.' : 'Image is required.' }
    }
    if (!data.email) {
      data.email = 'hey@artworks.io'
    }

    // "Validator.useLang('sv')" should work but doesn't because of missing "attributes" prop in sv.js
    // Copied file and added "attributes" prop. Passing it as argument to Validator.
    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if (isSwedish) {
      validation.setAttributeNames({
        title: 'Titel',
        artists: 'Konstnär',
      })
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({}, customErrors, validatorErrors)

    const changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined

    if (Object.keys(errors) < 1) {
      dispatch( startSubmit('show') )
      return dispatch( saveShow(changedValues) ).then((data) => {
        let show = data
        show.startDate = moment(data.startDate)
        show.endDate = moment(data.endDate)
        dispatch( initialize('show', show, null, {keepSubmitSucceeded:true}) )
      })
      .catch((err) => {
        console.log('ERROR', err);
      })
    }else{
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('show', errors) )
      dispatch( showErrorModal(translate('requiredFields')) )
    }
  }
}

export function removeShow(data) {
  return dispatch => {
    dispatch( deleteShow(data) )
  }
}

export function loadShow(data) {
  //Set default start & end date
  data.startDate = moment(data.startDate)
  data.endDate = moment(data.endDate)
  //Set default visibility
  if (!data.visibility) {
    data.visibility = 'all'
  }
  return (dispatch, getState) => {
    dispatch( initialize('show', data) )
  }
}

export function initShow() {
  //Set default start & end date
  return (dispatch, getState) => {
    const { auth: { user }, gallery: { gallery }, artist: { artist } } = getState()
    dispatch( initialize('show', {}) )
    dispatch( change('show', 'startDate', moment() ) )
    dispatch( change('show', 'endDate', moment() ) )
    dispatch( change('show', 'artworks', [] ) )
    dispatch( change('show', 'artists', [] ) )
    dispatch( change('show', 'category', [] ) )
    dispatch( change('show', 'relatedShows', [] ) )
    dispatch( change('show', 'visibility', 'all' ) )
    if((getObjectProp(user, 'role') === 'artist') && artist) {
      dispatch( change('show', 'artists', [artist]) )
    }
    if ((getObjectProp(user, 'role') !== 'admin') && (getObjectProp(user, 'role') !== 'artist')) {
      dispatch( change('show', 'email', getObjectProp(user, 'email') ) )
      const userGalleryId = getObjectProp(user, 'gallery._id') ? getObjectProp(user, 'gallery._id') : getObjectProp(user, 'gallery')
      const galleryId = (gallery && gallery._id) ? gallery._id : userGalleryId
      dispatch( change('show', 'gallery', galleryId) )
      dispatch( change('show', 'organizer', galleryId) )
      // TODO: Handle location search on load
      // show.locationSearch = getObjectProp(user, 'gallery.address.street')
    }
  }
}

export function showSuccess(){
  return {
    type: SHOW_SUCCESS,
    showSuccess: true
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS,
    showSuccess: false
  }
}

export function showShowModal(){
  return {
    type: SHOW_SHOW_MODAL,
    showShowModal: true
  }
}

export function hideShowModal(){
  return {
    type: HIDE_SHOW_MODAL,
    showShowModal: false
  }
}


export function showError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
