import {
  saveArtist,
  deleteArtist,
}  from '../ArtistActions'
import { showRetryModal } from '../AppActions'
import { showErrorModal } from '../AppActions'
import {
  initialize,
  getFormValues,
  stopSubmit,
  startSubmit,
}  from 'redux-form'

import Validator              from 'validatorjs'
import sv                     from './sv'
import diff                   from 'object-diff'
import i18next                from 'i18next'

export const LOAD = 'artworksapp/artist/LOAD'
export const SUBMIT = 'artworksapp/artist/SUBMIT'

export const FETCH_ARTWORKS = 'artworksapp/artist/FETCH_ARTWORKS'
export const REMOVE_ARTWORK = 'artworksapp/artist/REMOVE_ARTWORK'

export const SHOW_SUCCESS = 'artworksapp/artist/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/artist/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/artist/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/artist/HIDE_ERROR'

export const SHOW_ARTIST_MODAL = 'artworksapp/artist/SHOW_ARTIST_MODAL'
export const HIDE_ARTIST_MODAL = 'artworksapp/artist/HIDE_ARTIST_MODAL'

const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

export function initArtist() {
  return (dispatch, getState) => {
    const { user } = getState().auth
    let artist = {}
    dispatch( initialize('artist', artist) )
  }
}

export function loadArtist(artist) {
  if (artist.social && artist.social.instagram && artist.social.instagram.charAt(0) === '@') {
  	artist.social.instagram = artist.social.instagram.slice(1);
  }
  return (dispatch, getState) => {
    dispatch( initialize('artist', artist) )
  }
}

export function submitArtist(data, initial, customRules = false) {
  return (dispatch, getState) => {
    const { auth, auth: { user } } = getState()
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    let rules = {
      name: 'required|min:1',
      email: 'email',
      webSite: 'url'
    }
    if (user.role === 'artist') {
      rules['phone'] = 'required'
    }
    // Fields name/email/website/phone not available when saving from ArtistSettings.
    rules = customRules || rules

    // TODO: Add represented_by for author when submitting new artists
    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if (isSwedish) {
      validation.setAttributeNames({
        name: 'Namn',
        email: 'Email',
        phone: 'Telefonnummer',
        webSite: 'Hemsida',
      })
    }

    if(user.role === 'artist' && (!data.featuredImage || !data.featuredImage.src) && (!data.uploadedImage || !data.uploadedImage.URI)) {
      customErrors.featuredImage = isSwedish ? {src: 'Profilbild är obligatorisk'} : {src: 'A profile picture is required'}
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)

    let changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined

    // If artist has added a new mention
    if (changedValues.mentionTitle && changedValues.mentionLink) {
        changedValues = AddNewMention(changedValues, initial)
    }

    // Return early if errors
    if (Object.keys(errors).length !== 0) {
      dispatch( showErrorModal(translate('requiredFields')) )
      return dispatch( stopSubmit('artist', errors) )
    }
    // Return modal asking for price if no price
    // if ((!data.orgNumber || !data.bankName || !data.clearingNr || !data.accountNr) && user.artist && !auth.isAdmin) return dispatch( showRetryModal(
    //   changedValues,
    //   'artist',
    //   isSwedish ? 'Vill du spara utan att lägga till bankdetaljer?' : 'Do you want to save without adding an organisation number?',
    //   null,
    // ) )
    // All good, start saving
    dispatch( saveArtistData(changedValues) )
  }
}

export function saveArtistData(changedValues) {
  console.log('SAVE ARTIST');
  return dispatch => {
    dispatch( startSubmit('artist') )
    return dispatch( saveArtist(changedValues) )
    .then((data) => {
      let artist = data.response
      dispatch( initialize('artist', artist, null, {keepSubmitSucceeded: true}))
    })
    .catch((err) => {
      console.log('ERROR', err);
    })
  }
}

export function submitMentionForArtist(data, initial) {
  return dispatch => {
    let changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined
    // If artist has added a new mention
    if (changedValues.mentionTitle && changedValues.mentionLink) {
        changedValues = AddNewMention(changedValues, initial)
    }
    dispatch( saveArtistData(changedValues) )
  }
}

function AddNewMention(changedValues, initial) {

  const mention = formatNewMention(changedValues.mentionTitle, changedValues.mentionLink, changedValues.mentionCategory)

  // A new mention must be added to existing related
  if (changedValues.related) {
    changedValues.related = changedValues.related.concat([mention])
  } else if (initial.related) {
    changedValues.related = initial.related.concat([mention])
  } else {
    changedValues.related = [mention]
  }
  delete changedValues.mentionTitle
  delete changedValues.mentionLink

  return changedValues
}

function formatNewMention(title, link, category = '') {
  let formattedMention = {}
  const isInternalLink = (link.indexOf('artworksapp.') > -1 || link.indexOf('artworks.') > -1) ? true : false

  if (isInternalLink) {
    let category = ''
    if (link.indexOf('/artworks/') > -1 || link.indexOf('/konstverk/') > -1) category = 'Artwork'
    if (link.indexOf('/artists/') > -1 || link.indexOf('/konstnarer/') > -1) category = 'Artist'
    if (link.indexOf('/shows/') > -1 || link.indexOf('/utstallningar/') > -1) category = 'Show'
    if (link.indexOf('/venues/') > -1 || link.indexOf('/gallerier/') > -1) category = 'Venue'
    if (link.indexOf('/articles/') > -1 || link.indexOf('/artiklar/') > -1) category = 'Article'
    if (link.indexOf('/events/') > -1 || link.indexOf('/aktiviteter/') > -1) category = 'Event'

    let linkParts = ['/']
    if (link.indexOf('artworksapp.com') > -1) linkParts = link.split('artworksapp.com')
    if (link.indexOf('artworksapp.se') > -1) linkParts = link.split('artworksapp.se')
    if (link.indexOf('artworks.se') > -1) linkParts = link.split('artworks.se')
    if (link.indexOf('artworks.io') > -1) linkParts = link.split('artworks.io')
    const intLink = linkParts[linkParts.length - 1]

    formattedMention = {
      category,
      title,
      internalLink: intLink,
      externalLink: '',
    }
  } else {
    formattedMention = {
      category,
      title,
      internalLink: '',
      externalLink: link,
    }
  }

  return formattedMention
}

export function fetchArtists(id) {
  return (dispatch, getState) => {
    dispatch( fetchArtistsForGallery(id) )
  }
}

export function removeArtist(artist){
  return dispatch => {
    dispatch( deleteArtist(artist) )
  }
}

export function showSuccess(){
  return {
    type: SHOW_SUCCESS,
    showSuccess: true
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS,
    showSuccess: false
  }
}

export function showArtistModal(){
  return dispatch => {
    dispatch( initArtist() )
    dispatch( {
      type: SHOW_ARTIST_MODAL,
      showArtistModal: true
    } )
  }
}

export function hideArtistModal(){
  return {
    type: HIDE_ARTIST_MODAL,
    showArtistModal: false
  }
}


export function showError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
