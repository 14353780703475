import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { fetchUsers } from "../../actions/UserActions";
import Main from "../../components/Main/Main";

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSort: 0,
			sortOptions: [
				{ value: "name", buttonText: "Sort by newest" },
				{ value: "newest", buttonText: "Sort by oldest" },
				{ value: "oldest", buttonText: "Sort by name" },
			],
			currentRoleSort: "all",
			roleSortOptions: [
				{ value: "all", buttonText: "All" },
				{ value: "author", buttonText: "Venue" },
				{ value: "society", buttonText: "Society" },
				{ value: "artist", buttonText: "Artist" },
				{ value: "admin", buttonText: "Admin" },
				{ value: "user", buttonText: "User" },
			],
		};
	}

	componentWillMount() {
		this.props.dispatch(fetchUsers());
	}

	changeSort = () => {
		let nextSort = this.state.currentSort + 1;
		if (nextSort >= this.state.sortOptions.length) nextSort = 0;
		this.setState({
			currentSort: nextSort,
		});
	};

	changeRoleSort = (role) => {
		this.setState({
			currentRoleSort: role,
		});
	};

	sortAccounts = (type) => {
		if (type === "newest") {
			return (a, b) => {
				const aDate = a.props["data-date"];
				const bDate = b.props["data-date"];
				if (aDate > bDate) return -1;
				if (aDate < bDate) return 1;
				return 0;
			};
		} else if (type === "oldest") {
			return (a, b) => {
				const aDate = a.props["data-date"];
				const bDate = b.props["data-date"];
				if (aDate > bDate) return 1;
				if (aDate < bDate) return -1;
				return 0;
			};
		} else {
			return (a, b) => {
				return 0;
			};
		}
	};

	render() {
		const { currentSort, sortOptions, currentRoleSort, roleSortOptions } =
			this.state;
		if (!this.props.isAdmin) return null;
		let users = {};
		let usersArr = {};
		let artists = {};
		let artistArr = {};
		let authors = {};
		let authorsArr = {};
		let societies = {};
		let societiesArr = {};
		let admins = {};
		let adminsArr = {};
		// all... used for other sort than by name.
		const allAuthors = [];
		const allSocieties = [];
		const allArtists = [];
		const allAdmins = [];
		const allUsers = [];

		const sortBy = sortOptions[currentSort].value;
		const sortButtonText = sortOptions[currentSort].buttonText;
		const sortFunction = this.sortAccounts(sortBy);

		const viewAll = currentRoleSort === "all";
		const viewAuthors = currentRoleSort === "author";
		const viewSocieties = currentRoleSort === "society";
		const viewArtists = currentRoleSort === "artist";
		const viewAdmins = currentRoleSort === "admin";
		const viewUsers = currentRoleSort === "user";

		Object.keys(this.props.user.users).map((key) => {
			let user = this.props.user.users[key];
			if (user.role === "artist") {
				artists[key] = user;
			} else if (user.role === "author") {
				authors[key] = user;
			} else if (user.role === "society") {
				societies[key] = user;
			} else if (user.role === "admin") {
				admins[key] = user;
			} else {
				users[key] = user;
			}
		});
		Object.keys(artists).forEach((key) => {
			let user = artists[key];
			const firstLetter = (user.email || "-").toUpperCase().charAt(0);
			let array = artistArr[firstLetter] || [];
			const artistElement = (
				<div
					key={user._id}
					className="List__objects__object"
					data-date={user.created_at}
				>
					<Link to={"/accounts/" + user._id}>
						<div>
							<span>{user.email}</span>
							<span className="List__objects__object__name">
								{user.name ? user.name : "-"}
							</span>
							<span className="List__objects__object_name">
								{user.artist ? user.artist.name : "No artist set"}
							</span>
							<span className="List__objects__object__small">
								{user.created_at
									? "Created: " + user.created_at.split("T")[0]
									: ""}
							</span>
						</div>
					</Link>
				</div>
			);
			array.push(artistElement);
			artistArr[firstLetter] = array;
			allArtists.push(artistElement);
		});
		Object.keys(authors).forEach((key) => {
			let user = authors[key];
			const firstLetter = (user.email || "-").toUpperCase().charAt(0);
			let array = authorsArr[firstLetter] || [];
			const authorElement = (
				<div
					key={user._id}
					className="List__objects__object"
					data-date={user.created_at}
				>
					<Link to={"/accounts/" + user._id}>
						<div>
							<span>{user.email}</span>
							<span className="List__objects__object__name">
								{user.gallery ? user.gallery.name : "No gallery set"}
							</span>
							<span className="List__objects__object__name">{user.name}</span>
							<span className="List__objects__object__small">
								{user.created_at
									? "Created: " + user.created_at.split("T")[0]
									: ""}
							</span>
						</div>
					</Link>
				</div>
			);
			array.push(authorElement);
			authorsArr[firstLetter] = array;
			allAuthors.push(authorElement);
		});
		Object.keys(societies).forEach((key) => {
			let user = societies[key];
			const firstLetter = (user.email || "-").toUpperCase().charAt(0);
			let array = societiesArr[firstLetter] || [];
			const societyElement = (
				<div
					key={user._id}
					className="List__objects__object"
					data-date={user.created_at}
				>
					<Link to={"/accounts/" + user._id}>
						<div>
							<span>{user.email}</span>
							<span className="List__objects__object__name">
								{user.gallery ? user.gallery.name : "No gallery set"}
							</span>
							<span className="List__objects__object__name">{user.name}</span>
							<span className="List__objects__object__small">
								{user.created_at
									? "Created: " + user.created_at.split("T")[0]
									: ""}
							</span>
						</div>
					</Link>
				</div>
			);
			array.push(societyElement);
			societiesArr[firstLetter] = array;
			allSocieties.push(societyElement);
		});
		Object.keys(admins).forEach((key) => {
			let user = admins[key];
			const firstLetter = (user.email || "-").toUpperCase().charAt(0);
			let array = adminsArr[firstLetter] || [];
			const adminElement = (
				<div
					key={user._id}
					className="List__objects__object"
					data-date={user.created_at}
				>
					<Link to={"/accounts/" + user._id}>
						<div>
							<span>{user.email}</span>
							<span className="List__objects__object__name">{user.name}</span>
							<span className="List__objects__object__small">
								{user.created_at
									? "Created: " + user.created_at.split("T")[0]
									: ""}
							</span>
						</div>
					</Link>
				</div>
			);
			array.push(adminElement);
			adminsArr[firstLetter] = array;
			allAdmins.push(adminElement);
		});
		Object.keys(users).forEach((key) => {
			let user = users[key];
			const firstLetter = (user.email || "-").toUpperCase().charAt(0);
			let array = usersArr[firstLetter] || [];
			const userElement = (
				<div
					key={user._id}
					className="List__objects__object"
					data-date={user.created_at}
				>
					<Link to={"/accounts/" + user._id}>
						<div>
							<span>{user.email}</span>
							<span className="List__objects__object__name">{user.name}</span>
							<span className="List__objects__object__small">
								{user.created_at
									? "Created: " + user.created_at.split("T")[0]
									: ""}
							</span>
						</div>
					</Link>
				</div>
			);
			array.push(userElement);
			usersArr[firstLetter] = array;
			allUsers.push(userElement);
		});
		let orderedUsers = {};
		let orderedAdmins = {};
		let orderedAuthors = {};
		let orderedSocieties = {};
		let orderedArtists = {};
		Object.keys(usersArr)
			.sort()
			.forEach((key) => {
				orderedUsers[key] = usersArr[key];
			});
		Object.keys(adminsArr)
			.sort()
			.forEach((key) => {
				orderedAdmins[key] = adminsArr[key];
			});
		Object.keys(authorsArr)
			.sort()
			.forEach((key) => {
				orderedAuthors[key] = authorsArr[key];
			});
		Object.keys(societiesArr)
			.sort()
			.forEach((key) => {
				orderedSocieties[key] = societiesArr[key];
			});
		Object.keys(artistArr)
			.sort()
			.forEach((key) => {
				orderedArtists[key] = artistArr[key];
			});
		let userSections = [];
		let adminSections = [];
		let authorSections = [];
		let societySections = [];
		let artistSections = [];
		Object.keys(orderedAdmins).forEach((key) => {
			adminSections.push(
				<div key={`admin-${key}`} className="List__section">
					<h3 className="List__section-title">{key}</h3>
					<div className="List__objects">{orderedAdmins[key]}</div>
				</div>
			);
		});
		Object.keys(orderedAuthors).forEach((key) => {
			authorSections.push(
				<div key={`author-${key}`} className="List__section">
					<h3 className="List__section-title">{key}</h3>
					<div className="List__objects">{orderedAuthors[key]}</div>
				</div>
			);
		});
		Object.keys(orderedSocieties).forEach((key) => {
			societySections.push(
				<div key={`society-${key}`} className="List__section">
					<h3 className="List__section-title">{key}</h3>
					<div className="List__objects">{orderedSocieties[key]}</div>
				</div>
			);
		});
		Object.keys(orderedArtists).forEach((key) => {
			artistSections.push(
				<div key={`artist-${key}`} className="List__section">
					<h3 className="List__section-title">{key}</h3>
					<div className="List__objects">{orderedArtists[key]}</div>
				</div>
			);
		});
		Object.keys(orderedUsers).forEach((key) => {
			userSections.push(
				<div key={`user-${key}`} className="List__section">
					<h3 className="List__section-title">{key}</h3>
					<div className="List__objects">{orderedUsers[key]}</div>
				</div>
			);
		});

		return (
			<div>
				<Main>
					<div className="MainTop">
						<h1>Accounts</h1>
						<Link
							to="/accounts/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							Create new account
						</Link>
					</div>
					<div
						style={{
							display: "flex",
							flexFlow: "column nowrap",
							alignItems: "flex-end",
						}}
					>
						<button className="purple-button--wide" onClick={this.changeSort}>
							{sortButtonText}
						</button>
						<div
							style={{
								paddingTop: "10px",
								display: "flex",
								flexFlow: "row nowrap",
								alignItems: "flex-end",
							}}
						>
							{roleSortOptions.map((option) => {
								return (
									<div
										onClick={() => this.changeRoleSort(option.value)}
										style={{
											padding: "5px 10px",
											margin: "5px",
											border: "2px solid black",
											cursor: "pointer",
											opacity: currentRoleSort === option.value ? 0.5 : 1,
										}}
										key={option.value}
									>
										{option.buttonText}
									</div>
								);
							})}
						</div>
					</div>
					<div className="List">
						{(viewAll || viewAuthors) && (
							<div>
								<h2
									className="List__section-title"
									style={{ paddingTop: "2em" }}
								>
									Venues
								</h2>
								{sortBy === "name" && authorSections}
								{sortBy !== "name" && (
									<div className="List__section">
										<div className="List__objects">
											{allAuthors.sort(sortFunction)}
										</div>
									</div>
								)}
							</div>
						)}
						{(viewAll || viewSocieties) && (
							<div>
								<h2
									className="List__section-title"
									style={{ paddingTop: "2em" }}
								>
									Societies
								</h2>
								{sortBy === "name" && societySections}
								{sortBy !== "name" && (
									<div className="List__section">
										<div className="List__objects">
											{allSocieties.sort(sortFunction)}
										</div>
									</div>
								)}
							</div>
						)}
						{(viewAll || viewArtists) && (
							<div>
								<h2
									className="List__section-title"
									style={{ paddingTop: "2em" }}
								>
									Artists
								</h2>
								{sortBy === "name" && artistSections}
								{sortBy !== "name" && (
									<div className="List__section">
										<div className="List__objects">
											{allArtists.sort(sortFunction)}
										</div>
									</div>
								)}
							</div>
						)}
						{(viewAll || viewAdmins) && (
							<div>
								<h2
									className="List__section-title"
									style={{ paddingTop: "2em" }}
								>
									Admins
								</h2>
								{sortBy === "name" && adminSections}
								{sortBy !== "name" && (
									<div className="List__section">
										<div className="List__objects">
											{allAdmins.sort(sortFunction)}
										</div>
									</div>
								)}
							</div>
						)}
						{(viewAll || viewUsers) && (
							<div>
								<h2
									className="List__section-title"
									style={{ paddingTop: "2em" }}
								>
									Users
								</h2>
								{sortBy === "name" && userSections}
								{sortBy !== "name" && (
									<div className="List__section">
										<div className="List__objects">
											{allUsers.sort(sortFunction)}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state;
	const isAdmin = state.auth.isAdmin || state.auth.user.role === "admin";
	return { user, isAdmin };
}

export default connect(mapStateToProps)(Accounts);
