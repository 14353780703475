import { CALL_API } from "../utils/api";
import LocationUtil from "../utils/LocationUtil";
import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

export const COLLECT_AND_EXPORT_REQUEST = "COLLECT_AND_EXPORT_REQUEST";
export const COLLECT_AND_EXPORT_SUCCESS = "COLLECT_AND_EXPORT_SUCCESS";
export const COLLECT_AND_EXPORT_FAILURE = "COLLECT_AND_EXPORT_FAILURE";

export function collectAndExport(type) {
	return (dispatch) => {
		dispatch(collectAndExportRequest());
		let endpoint = "data/exportdatatypes";
		const body = { type };
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(collectAndExportSuccess(json));
			})
			.catch((err) => {
				dispatch(collectAndExportFailure(err));
				console.log("exportData failure", err);
			});
	};
}

function collectAndExportRequest() {
	return {
		type: COLLECT_AND_EXPORT_REQUEST,
	};
}

function collectAndExportSuccess(json) {
	return {
		type: COLLECT_AND_EXPORT_SUCCESS,
		response: json,
	};
}

function collectAndExportFailure(error) {
	return {
		type: COLLECT_AND_EXPORT_FAILURE,
		message: error,
	};
}
