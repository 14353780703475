import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import {
	informMembersAboutShow,
	fetchShowEmailPreview,
	resetShowEmailPreview,
} from "../../../actions/ShowActions";
import {
	informMembersAboutEvent,
	fetchEventEmailPreview,
	resetEventEmailPreview,
} from "../../../actions/EventActions";
import {
	informMembersAboutPost,
	fetchPostEmailPreview,
	resetPostEmailPreview,
} from "../../../actions/PostActions";
import {
	informMembersAboutRaffle,
	fetchRaffleEmailPreview,
	resetRaffleEmailPreview,
} from "../../../actions/RaffleActions";
import {
	fetchArtworkEmailPreview,
	resetArtworkEmailPreview,
} from "../../../actions/ArtworkActions";
import {
	showErrorModal,
	showSuccessModal,
	showCustomModal,
	hideCustomModal,
} from "../../../actions/AppActions";
import { fetchNewsletterByGallery } from "@artworkslab/sharedmodules/src/actions/NewsletterActions";
import { fetchMembers } from "../../../actions/RegistryActions";

const sendInterval = { days: 7 };

class SendModalContentClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sendToMembers: false,
			sendToContacts: false,
		};
	}

	toggleSendToMembers = () => {
		this.setState({ sendToMembers: !this.state.sendToMembers });
	};

	toggleSendToContacts = () => {
		this.setState({ sendToContacts: !this.state.sendToContacts });
	};

	render() {
		const {
			markup,
			inform,
			sendEmail,
			hasSubscribers,
			hasMembers,
			isFetching,
			t,
		} = this.props;
		const { sendToMembers, sendToContacts } = this.state;

		let buttonDisabled = true;
		if (sendToMembers) buttonDisabled = false;
		if (hasSubscribers && sendToContacts) buttonDisabled = false;

		return (
			<div className="inform-member-modal">
				<div className="inform-member-modal__send-section">
					<h1>{t("informMemberButton.letsGo")}</h1>
					<div style={{ marginTop: "30px" }}>
						<input
							type="checkbox"
							id="members-checkbox"
							ref={(ref) => (this.membersCheckbox = ref)}
							className="inform-member-modal__send-section__checkbox"
							onClick={() => this.toggleSendToMembers()}
							checked={sendToMembers}
							disabled={!hasMembers}
						/>
						<label
							htmlFor="members-checkbox"
							style={{ opacity: hasMembers ? 1 : 0.3 }}
						>
							{t("informMemberButton.members")}
						</label>
						{!hasMembers && (
							<div className="inform-member-modal__send-section__disabled-label">
								{t("informMemberButton.uploadMembers")}
							</div>
						)}
					</div>
					<div style={{ marginBottom: "30px" }}>
						<input
							type="checkbox"
							id="contacts-checkbox"
							ref={(ref) => (this.contactsCheckbox = ref)}
							className="inform-member-modal__send-section__checkbox"
							onClick={() => this.toggleSendToContacts()}
							checked={sendToContacts}
							disabled={!hasSubscribers}
						/>
						<label
							htmlFor="contacts-checkbox"
							style={{ opacity: hasSubscribers ? 1 : 0.3 }}
						>
							{t("informMemberButton.contacts")}
						</label>
						{!hasSubscribers && (
							<div className="inform-member-modal__send-section__disabled-label">
								{t("informMemberButton.uploadContacts")}
							</div>
						)}
					</div>
					<button
						type="button"
						className={
							"purple-button--wide button-nomargin button" +
							(buttonDisabled || isFetching ? " is-disabled" : "")
						}
						onClick={() => sendEmail(inform, sendToMembers, sendToContacts)}
					>
						{t("informMemberButton.send")}
					</button>
				</div>
				<div className="inform-member-modal__markup">
					<div className="inform-member-modal__markup__content">
						<div dangerouslySetInnerHTML={markup}></div>
					</div>
				</div>
			</div>
		);
	}
}

const mapState = (state) => {
	const {
		show: { isFetching: showFetching },
		post: { isFetching: postFetching },
		event: { isFetching: eventFetching },
		raffle: { isFetching: raffleFetching },
		artwork: { isFetching: artworkFetching },
		newsletter: { newsletter },
		registry: { members },
	} = state;
	const isFetching =
		showFetching ||
		postFetching ||
		eventFetching ||
		raffleFetching ||
		artworkFetching;
	const hasSubscribers =
		newsletter.subscribers && newsletter.subscribers.length > 0;
	const hasMembers = Object.keys(members).length > 0;

	return { newsletter, hasSubscribers, hasMembers, isFetching };
};

const SendModalContent = connect(mapState)(SendModalContentClass);

class InformMemberButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previewOpen: false,
		};
	}

	componentWillMount() {
		const { item } = this.props;
		const gallery = item.gallery || item.belongsTo;
		const belongsToId = gallery ? gallery._id || gallery : false;

		if (belongsToId) {
			this.props.dispatch(fetchNewsletterByGallery(belongsToId));
			this.props.dispatch(fetchMembers(belongsToId));
		}
	}

	componentWillUnmount() {
		this.props.dispatch(resetShowEmailPreview());
		this.props.dispatch(resetEventEmailPreview());
		this.props.dispatch(resetPostEmailPreview());
		this.props.dispatch(resetRaffleEmailPreview());
	}

	resizeIframe = (obj) => {
		// const elem = obj.target
		// obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px'
	};

	createMarkup = (html) => {
		return { __html: html };
	};

	openPreview = (itemId, fetchPreview) => {
		this.props.dispatch(fetchPreview(itemId));
		if (!this.state.previewOpen) this.setState({ previewOpen: true });
	};

	openSendConfirmModal = (itemId, preview, fetchPreview, inform) => {
		const { item, t } = this.props;

		if (!preview) {
			this.props
				.dispatch(fetchPreview(item._id))
				.then((result) => {
					this.openSendModal(result.response, inform);
				})
				.catch((err) => {
					console.log("openSendConfirmModal error", err);
				});
		} else {
			this.openSendModal(preview, inform);
		}
	};

	openSendModal = (preview, inform) => {
		const { t } = this.props;
		const markup = this.createMarkup(preview);

		// const modalContent = (
		//   <div className={ i() }>
		//     <div className={ i('send-section') }>
		//       <h1>{t('informMemberButton.letsGo')}</h1>
		//       <div style={{ marginTop: '30px' }}>
		//         <input
		//           type="checkbox"
		//           id="members-checkbox"
		//           ref={ref => this.membersCheckbox = ref}
		//           className={ i('send-section__checkbox') }
		//           disabled={ false } />
		//         <label htmlFor="members-checkbox">{t('informMemberButton.members')}</label>
		//       </div>
		//       <div style={{ marginBottom: '30px' }}>
		//         <input
		//           type="checkbox"
		//           id="contacts-checkbox"
		//           ref={ref => this.contactsCheckbox = ref}
		//           className={ i('send-section__checkbox') }
		//           disabled={ false } />
		//         <label htmlFor="contacts-checkbox">{t('informMemberButton.contacts')}</label>
		//       </div>
		//       <button
		//         type="button"
		//         className={ b('button').state({
		//           disabled: (this.membersCheckbox && !this.membersCheckbox.checked) && (this.contactsCheckbox && !this.contactsCheckbox.checked)
		//         }).mix('button') }
		//         onClick={() => this.sendEmail(inform, this.membersCheckbox.checked, this.contactsCheckbox.checked)}>
		//           {t('informMemberButton.send')}
		//       </button>
		//     </div>
		//     <div className={ i('markup') }>
		//       <div className={ i('markup__content') }>
		//         <div dangerouslySetInnerHTML={ markup }></div>
		//       </div>
		//     </div>
		//   </div>
		// )

		// this.props.dispatch( showCustomModal(modalContent) )
		this.props.dispatch(
			showCustomModal(
				<SendModalContent
					markup={markup}
					inform={inform}
					sendEmail={this.sendEmail}
					t={t}
				/>,
				"no-class"
			)
		);
	};

	sendEmail = (inform, sendToMembers, sendToContacts) => {
		const { item, t } = this.props;

		this.props
			.dispatch(inform(item._id, sendToMembers, sendToContacts))
			.then((result) => {
				this.props.dispatch(hideCustomModal());
				if (result.error) {
					console.log("sendEmail result.error", result.error);
					this.props.dispatch(showErrorModal(t("informMemberButton.error")));
				} else {
					this.props.dispatch(
						showSuccessModal(t("informMemberButton.sendSuccess"))
					);
				}
			})
			.catch((err) => {
				console.log("sendEmail error", err);
				this.props.dispatch(hideCustomModal());
				this.props.dispatch(showErrorModal("Generic error"));
			});
	};

	render() {
		const { item, type, isFetching, dispatch, previewOnly, t, preview, error } =
			this.props;
		const { previewOpen } = this.state;

		const moment = DateUtil.getMoment(i18next.language);

		let inform = () => {};
		let fetchPreview = () => {};

		switch (type) {
			case "show":
				inform = informMembersAboutShow;
				fetchPreview = fetchShowEmailPreview;
				break;
			case "event":
				inform = informMembersAboutEvent;
				fetchPreview = fetchEventEmailPreview;
				break;
			case "post":
				inform = informMembersAboutPost;
				fetchPreview = fetchPostEmailPreview;
				break;
			case "raffle":
				inform = informMembersAboutRaffle;
				fetchPreview = fetchRaffleEmailPreview;
				break;
			case "artwork":
				fetchPreview = fetchArtworkEmailPreview;
				break;
			default:
			//
		}

		let recentlySent = false;
		let tryAgainIn = false;
		let informError = false;

		if (error && error === "informMembersError") {
			informError = true;
		}
		// Could not remind members again unless seven days had passed.
		// else if (item.emailsSentDate) {
		//   const rightNow = moment()
		//   const sentDate = moment(item.emailsSentDate)
		//   const earlistNextSendDate = moment(item.emailsSentDate).add(sendInterval)
		//
		//   if (earlistNextSendDate.isAfter(rightNow)) {
		//     recentlySent = true
		//     tryAgainIn = moment(earlistNextSendDate).fromNow()
		//   }
		// }

		// markup could inherit sass from rest of page (for example if class is "button"). Check this if changing emails.
		const IEorEDGE =
			document.documentMode ||
			/Edge/.test(navigator.userAgent) ||
			/Edg/.test(navigator.userAgent);
		// const markup = IEorEDGE ? this.createMarkup(preview) : ''
		const markup = this.createMarkup(preview);

		if (previewOnly) {
			return (
				<div className="inform-member-button">
					<button
						type="button"
						className="purple-button--wide button-somemargin"
						onClick={() => this.openPreview(item._id, fetchPreview)}
						disabled={isFetching}
					>
						{t("informMemberButton.preview2")} (Admin)
					</button>
					{preview && previewOpen && (
						<div className="inform-member-button__preview-container">
							{/*<div onClick={() => closePreview()}></div>*/}
							<div className="inform-member-button__iframe-container">
								<iframe
									width="100%"
									height="1000px"
									src={`data:text/html;charset=utf-8,${encodeURIComponent(
										preview
									)}`}
									onLoad={(frame) => this.resizeIframe(frame)}
								></iframe>
							</div>
						</div>
					)}
				</div>
			);
		}

		return (
			<div className="inform-member-button">
				<legend>{t(`informMemberButton.emailMembers.${type}`)}</legend>
				<button
					type="button"
					className="purple-button--wide button-somemargin"
					onClick={() =>
						this.openSendConfirmModal(item._id, preview, fetchPreview, inform)
					}
					disabled={isFetching || recentlySent}
				>
					{t("informMemberButton.sendEmail")}
				</button>
				{/*<button
          type="button"
          className={ b('button').mix('button') }
          onClick={() => dispatch( inform(item._id) )}
          disabled={ isFetching || recentlySent }>
            {t('informMemberButton.sendEmail')}
        </button>*/}
				<button
					type="button"
					className="purple-button--wide button-somemargin"
					onClick={() => this.openPreview(item._id, fetchPreview)}
					disabled={isFetching}
				>
					{t("informMemberButton.preview2")}
				</button>
				{!informError && item.emailsSentDate && (
					<p
						className="inform-member-button__last-sent"
						style={{ marginLeft: "40px" }}
					>
						{t("informMemberButton.lastSent")}{" "}
						{DateUtil.dateMonthShort(
							item.emailsSentDate,
							i18next.language,
							true,
							true
						)}
					</p>
				)}
				{false && !informError && tryAgainIn && (
					<p className="inform-member-button__wait-until">
						{t("informMemberButton.sendAgain")} {tryAgainIn}
					</p>
				)}
				{false && informError && (
					<p className="inform-member-button__error">
						{t("informMemberButton.error")}
					</p>
				)}
				{preview && previewOpen && !IEorEDGE && (
					<div className="inform-member-button__preview-container">
						<div className="inform-member-button__iframe-container">
							{/*<div onClick={() => closePreview()}></div>*/}
							<iframe
								width="100%"
								height="1000px"
								src={`data:text/html;charset=utf-8,${encodeURIComponent(
									preview
								)}`}
								onLoad={(frame) => this.resizeIframe(frame)}
							></iframe>
						</div>
					</div>
				)}
				{preview && previewOpen && IEorEDGE && (
					<div className="inform-member-button__preview-container">
						<div className="inform-member-button__markup-container">
							{/*<div onClick={() => closePreview()}></div>*/}
							<div
								width="100%"
								height="1000px"
								dangerouslySetInnerHTML={markup}
							></div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	// console.log('mapStateToProps', ownProps, state);
	const {
		show: { preview: showPreview, isFetching: showFetching, error: showError },
		post: { preview: postPreview, isFetching: postFetching, error: postError },
		event: {
			preview: eventPreview,
			isFetching: eventFetching,
			error: eventError,
		},
		raffle: {
			preview: rafflePreview,
			isFetching: raffleFetching,
			error: raffleError,
		},
		artwork: {
			preview: artworkPreview,
			isFetching: artworkFetching,
			error: artworkError,
		},
	} = state;
	const { type } = ownProps;

	let error = false;
	let preview = false;
	let isFetching = false;

	switch (type) {
		case "show":
			error = showError;
			preview = showPreview;
			isFetching = showFetching;
			break;
		case "event":
			error = eventError;
			preview = eventPreview;
			isFetching = eventFetching;
			break;
		case "post":
			error = postError;
			preview = postPreview;
			isFetching = postFetching;
			break;
		case "raffle":
			error = raffleError;
			preview = rafflePreview;
			isFetching = raffleFetching;
			break;
		case "artwork":
			error = artworkError;
			preview = artworkPreview;
			isFetching = artworkFetching;
			break;
		default:
		//
	}

	return { preview, isFetching, error };
}

const translated = withNamespaces("components")(InformMemberButton);
export default connect(mapStateToProps)(translated);
