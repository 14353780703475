import React, { Component } from "react";
import { connect } from "react-redux";
import { MegadraftIcons } from "megadraft";
import Select from "react-select";
import { fetchArtist } from "../../../actions/ArtistActions";
import { fetchArtwork } from "../../../actions/ArtworkActions";
import { fetchGallery } from "../../../actions/GalleryActions";
import { fetchShow } from "../../../actions/ShowActions";
import { fetchPost } from "../../../actions/PostActions";
import {
	getArtistOptions,
	resetArtistOptions,
	getGalleryOptions,
	resetGalleryOptions,
	getShowOptions,
	resetShowOptions,
	getPostOptions,
	resetPostOptions,
	resetAllOptions,
} from "../../../actions/PickersActions";

const SELECTABLE_TYPES = [
	{ type: "artists", label: "Artist" },
	{ type: "venues", label: "Venue" },
	{ type: "shows", label: "Show" },
	{ type: "articles", label: "Post" },
];

const DEFAULT_STATE = {
	searchString: "",
	selectedType: {},
	selectedItem: undefined,
};

class InternalLinkInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...DEFAULT_STATE,
		};
	}

	componentDidMount() {
		console.log("DID MOUNT");
		console.log(this.props);
		const { type, _id, label } = this.props;
		const selectedType = { type };
		const selectedItem = { _id, label };
		this.setState({ selectedType, selectedItem });
	}

	onSelectType = (selectedType) => {
		this.props.dispatch(resetAllOptions());
		this.setState({ selectedType });
	};

	resetInput = () => {
		this.setState({ ...DEFAULT_STATE });
		this.props.cancelEntity();
	};

	removeEntity = () => {
		console.log("REMOVE ENTITY?");
		console.log(this.props);
		this.props.removeEntity();
	};

	setEntity = (item) => {
		console.log("SET ENTITY");
		this.props.setEntity(item);
		this.resetInput();
	};

	selectOption = (selectedOption) => {
		const {
			selectedType: { type },
		} = this.state;
		const that = this;
		if (type === "artists") {
			this.props
				.dispatch(fetchArtist(selectedOption._id))
				.then(({ response }) => {
					that.setEntity({
						_id: response._id,
						slug: response.slug,
						type,
						label: response.name,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (type === "venues") {
			this.props
				.dispatch(fetchGallery(selectedOption._id))
				.then(({ response }) => {
					that.setEntity({
						_id: response._id,
						slug: response.slug,
						type,
						label: response.name,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (type === "shows") {
			this.props
				.dispatch(fetchShow(selectedOption._id))
				.then(({ response }) => {
					that.setEntity({
						_id: response._id,
						slug: response.slug,
						type,
						label: response.name,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (type === "articles") {
			this.props
				.dispatch(fetchPost(selectedOption._id))
				.then(({ response }) => {
					that.setEntity({
						_id: response._id,
						slug: response.slug,
						type,
						label: response.title,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (type === "art") {
			this.props
				.dispatch(fetchArtwork(selectedOption._id))
				.then(({ response }) => {
					that.setEntity({
						_id: response._id,
						slug: response.slug,
						type,
						label: response.title,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	onInputChange = (searchString) => {
		const {
			selectedType: { type },
		} = this.state;
		console.log("SHOULD SEARCH FOR:", type);
		switch (type) {
			case "artists":
				this.props.dispatch(getArtistOptions(searchString));
				break;
			case "venues":
				this.props.dispatch(getGalleryOptions(searchString));
				break;
			case "shows":
				this.props.dispatch(getShowOptions(searchString));
				break;
			case "articles":
				this.props.dispatch(getPostOptions(searchString));
				break;
			default:
				console.error("NO TYPE SELECTED FOR INTERNAL LINK INPUT");
				break;
		}
	};

	render() {
		const { options, isFetching } = this.props;
		const {
			selectedItem,
			selectedType: { type },
		} = this.state;
		return (
			<div className="InternalLinkInput">
				<Select
					className="InternalLinkInput__type_input"
					value={type}
					valueKey="type"
					clearable={false}
					options={SELECTABLE_TYPES}
					onChange={this.onSelectType}
					placeholder="Type:"
				/>
				<Select
					className="InternalLinkInput__item_input"
					value={selectedItem}
					options={options}
					onChange={this.selectOption}
					onInputChange={this.onInputChange}
					valueKey="_id"
					labelKey="label"
					placeholder="Search..."
					disabled={!type}
					backspaceToRemoveMessage=""
					backspaceRemoves={true}
					isLoading={isFetching}
					multi={false}
				/>
				<span className="toolbar__item" style={{ verticalAlign: "bottom" }}>
					<button
						onClick={this.removeEntity}
						type="button"
						className="toolbar__button toolbar__input-button"
					>
						{this.props.entity ? (
							<MegadraftIcons.UnlinkIcon />
						) : (
							<MegadraftIcons.CloseIcon />
						)}
					</button>
				</span>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		pickers: {
			artistPicker: { isFetching: artistFetch, options: artistOptions },
			galleryPicker: { isFetching: galleryFetch, options: galleryOptions },
			showPicker: { isFetching: showFetch, options: showOptions },
			postPicker: { isFetching: postFetch, options: postOptions },
		},
	} = state;
	const isFetching = artistFetch || galleryFetch || showFetch || postFetch;
	const options = artistOptions.concat(
		galleryOptions,
		showOptions,
		postOptions
	);
	return { isFetching, options };
}

export default connect(mapStateToProps)(InternalLinkInput);
