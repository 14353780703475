import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { fetchSales } from "../../../actions/DashboardActions/LatestSalesActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const ANIMATION_DURATION = 1000;
const FADE_RESET = 500;
const TICK_COUNTDOWN = 5000;
const TICK_COUNTER = 2;
const ITEMS_DISPLAYED = 5;
const MOBILE_DISPLAYED = 2;
//Multiplier must be % of space that individual displayed item fills
const ITEM_WIDTH = 20;
const MOBILE_ITEM_WIDTH = 50;
const SLIDE_WIDTH = 120;
const MOBILE_SLIDE_WIDTH = 200;

class LatestSalesBanner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayItems: null,
			mobileItems: null,
			fade: true,
			slideOffset: 0,
			slideTimer: TICK_COUNTER,
		};
		this.updateTimer = false;
	}

	//Fetch items and initialize countdown
	componentWillMount() {
		this.props.dispatch(fetchSales());
		this.sliderCountdown();
	}

	//Clear timer upon unmount
	componentWillUnmount() {
		clearInterval(this.updateTimer);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.sales !== nextProps.sales && nextProps.sales) {
			this.setScrollItems(nextProps.sales);
		}
	}

	//Sets scrollitems in state
	setScrollItems(items) {
		let displayItems = [];
		let mobileItems = [];
		let allItems = [];

		Object.keys(items).forEach((key, i) => {
			if (i < MOBILE_DISPLAYED) {
				mobileItems.push(items[key]);
			}
			//Add item to list of displayed if index smaller than maximum displayed
			if (i < ITEMS_DISPLAYED) {
				displayItems.push(items[key]);
			}
			allItems.push(items[key]);
		});

		this.setState({
			displayItems,
			mobileItems,
			allItems,
		});
	}

	//Initiates an interval that ticks every second
	sliderCountdown = () => {
		this.updateTimer = setInterval(this.tick, TICK_COUNTDOWN);
	};

	//Updates slider when interval timer reached
	tick = () => {
		const { displayItems, mobileItems, slideTimer, slideOffset } = this.state;
		if (displayItems || mobileItems) {
			const newCount = slideTimer - 1;
			//Slide items to the right
			if (newCount === 0) {
				let newOffset = slideOffset;
				//Adjust content offset
				this.setState({
					slideOffset: (newOffset += 1),
				});

				//Call to update display
				setTimeout(() => {
					this.updateDisplay();
				}, ANIMATION_DURATION);
			} else {
				this.setState({
					slideTimer: newCount,
				});
			}
		}
	};

	//Updates displayItems and resets slide offset
	updateDisplay = (dir) => {
		const { displayItems, mobileItems } = this.state;

		//Create copies and retrieve neighbours
		let newItems = displayItems.slice();
		let newMobItems = mobileItems.slice();
		const neighbours = this.getNeighbours(newItems);
		const mobNeighbours = this.getNeighbours(newMobItems);

		//Update item lists with neighbours
		newItems.splice(0, 1);
		newItems.push(neighbours.nextItem);
		newMobItems.splice(0, 1);
		newMobItems.push(mobNeighbours.nextItem);

		//Updates items
		this.setState({
			displayItems: newItems,
			mobileItems: newMobItems,
		});
		//Reset slide
		this.resetSlide();

		//Activate fade after slide-post has been updated and allow slide updates
		setTimeout(() => {
			this.setState({ fade: true, disabled: false });
		}, FADE_RESET);
	};

	//Returns next and prev items for currently displayed indexes
	getNeighbours = (curItems) => {
		const { allItems } = this.state;

		//(Finally) successfully retrieves previous and next items from allItems depending on current displayItems
		const firstIndex = allItems.indexOf(curItems[0]);
		const lastIndex = allItems.indexOf(curItems[curItems.length - 1]);

		//Prev item in allItems or last item if index === 0
		const prevItem =
			firstIndex - 1 >= 0
				? allItems[firstIndex - 1]
				: allItems[allItems.length - 1];
		//next item in allItems or first item if index === allItems.length
		const nextItem =
			lastIndex + 1 <= allItems.length - 1
				? allItems[lastIndex + 1]
				: allItems[0];

		return { prevItem, nextItem };
	};

	//Returns the list with neighbours added
	addNeighboursToList = (list) => {
		let withNeighbours = list.slice();

		//Fetch neighbours
		const neighbours = this.getNeighbours(list);

		//Insert neighbour at start
		withNeighbours.splice(0, 0, neighbours.prevItem);
		//Insert neighbour at end
		withNeighbours.push(neighbours.nextItem);

		return withNeighbours;
	};

	//Resets the state of the slide
	resetSlide = () => {
		this.setState({
			slideTimer: TICK_COUNTER,
			slideOffset: 0,
			fade: false,
		});
	};

	//Renders the slideshow
	renderSlide = (itemList, mobile = false) => {
		const { allItems, slideOffset, fade } = this.state;
		if (!itemList || !itemList.length > 0 || !allItems) {
			return null;
		}

		const withNeighbours = this.addNeighboursToList(itemList);
		let rows = [];

		withNeighbours.forEach((item, i) => {
			if (item && item._id) {
				const title = item.title ? item.title : "";
				const itemWidth = mobile ? MOBILE_ITEM_WIDTH : ITEM_WIDTH;
				rows.push(
					<div
						key={i}
						className="LatestSalesBanner__slide-container__slide__item"
						style={{ width: `${itemWidth}%` }}
					>
						{title}
					</div>
				);
			}
		});

		//Add offset to hide neighbours
		//Offset adjusted when moving slide
		const leftIndex = mobile
			? slideOffset * -MOBILE_ITEM_WIDTH
			: slideOffset * -ITEM_WIDTH;
		const slideWidth = mobile ? MOBILE_SLIDE_WIDTH : SLIDE_WIDTH;
		return (
			<div
				className={
					"LatestSalesBanner__slide-container__slide" +
					(fade ? " is-fade" : "") +
					(mobile ? " is-mobile" : "")
				}
				style={{ left: `${leftIndex}%`, width: `${slideWidth}%` }}
			>
				{rows}
			</div>
		);
	};

	render() {
		const { isArtist, artist, mobile = false, t } = this.props;
		const { displayItems, mobileItems } = this.state;
		const slide = mobile
			? this.renderSlide(mobileItems, mobile)
			: this.renderSlide(displayItems);
		const header = mobile
			? t("latestSales.bannerMobile")
			: t("latestSales.banner");

		//Link disabled for base artists as they do not have access to the dashboard
		const linkDisabled = isArtist && !isPremium(artist) ? true : false;

		return (
			<div className={"LatestSalesBanner" + (mobile ? " is-fixed" : "")}>
				<Link
					className={"LatestSalesBanner" + (linkDisabled ? " is-disabled" : "")}
					to="/dashboard#latestsales"
				>
					<div className="LatestSalesBanner__header">{`${header}:`}</div>
					<div className="LatestSalesBanner__slide-container">{slide}</div>
				</Link>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		artist: { artist },
		latestSales: { sales, isFetching },
	} = state;
	const isArtist = user.role === "artist";

	return { isArtist, artist, sales, isFetching };
}

const translated = withNamespaces("components")(LatestSalesBanner);
export default connect(mapStateToProps)(translated);
