import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router";
import {
	// fetchGalleries,
	fetchAllGalleriesFast,
	fetchAllGalleriesPopulated,
} from "../../actions/GalleryActions";
import Main from "../../components/Main/Main";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";

class Galleries extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		const { isAdmin, galleries, deletedGallery } = this.props;
		// this.props.dispatch( fetchGalleries() )
		if (!isAdmin) return;
		// Galleries for admin here is allGalleries from state. If already fetched, only update.
		if (Object.keys(galleries).length === 0 || deletedGallery) {
			this.props.dispatch(fetchAllGalleriesFast()).then((result) => {
				this.props.dispatch(fetchAllGalleriesPopulated());
			});
		} else {
			this.props.dispatch(fetchAllGalleriesPopulated());
		}
	}

	hasProp = (object, key) => {
		if (object) {
			return object.hasOwnProperty(key);
		} else {
			return false;
		}
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	render() {
		const { galleries, isAdmin } = this.props;
		if (!isAdmin) return null;
		let rows = [];
		const that = this;
		let galleriesCount = Object.keys(galleries).length;
		Object.keys(galleries).forEach((key) => {
			let gallery = galleries[key];
			const createdBy = that.hasProp(gallery.created_by, "name")
				? gallery.created_by.role === "admin"
					? "Artworks"
					: gallery.created_by.name || gallery.created_by.email
				: "N/A";
			const image = gallery.featuredImage || "";
			const thumbnailSrc = image ? this.getImageSrc(image) : "";

			rows.push(
				<Tr key={gallery._id}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{gallery.featuredImage ? (
							<Link to={"/galleries/" + gallery.slug}>
								<img
									src={thumbnailSrc}
									className="table-image"
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
									alt="Gallery image"
								/>
							</Link>
						) : null}
					</Td>
					<Td column="type" value={gallery.venueType || "Gallery"}>
						<Link to={"/galleries/" + gallery.slug}>
							{gallery.venueType || "Gallery"}
						</Link>
					</Td>
					<Td column="name" value={gallery.name || "--"}>
						<Link to={"/galleries/" + gallery.slug}>
							{gallery.name || "--"}
						</Link>
					</Td>
					<Td column="visibility" value={gallery.visibility}>
						<Link to={"/galleries/" + gallery.slug}>{gallery.visibility}</Link>
					</Td>
					<Td column="owner" value={createdBy}>
						<Link to={"/galleries/" + gallery.slug}>{createdBy}</Link>
					</Td>
					<Td column="created" value={gallery.created_at}>
						<Link to={"/galleries/" + gallery.slug}>
							{moment(gallery.created_at).format("MMM Do, YYYY")}
						</Link>
					</Td>
				</Tr>
			);
		});
		return (
			<div className="Galleries">
				<Main>
					<Link
						to="/galleries/artsocieties"
						style={{
							position: "absolute",
							top: "10px",
							right: "10px",
							opacity: ".5",
						}}
					>
						Art societies
					</Link>
					<div className="MainTop">
						<h1>Venues ({galleriesCount})</h1>
						<Link
							to="/galleries/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							Create new venue
						</Link>
					</div>
					<Table
						className="reactable"
						sortable={["name", "owner", "type", "created", "visibility"]}
						filterable={["type", "name", "owner", "visibility"]}
						filterPlaceholder={"Search venue"}
					>
						<Thead>
							<Th column="image">Image</Th>
							<Th column="type">Type</Th>
							<Th column="name">Venue name</Th>
							<Th column="visibility">Visibility</Th>
							<Th column="owner">Created by</Th>
							<Th column="created">Created at</Th>
						</Thead>
						{rows}
					</Table>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		gallery: { allGalleries: galleries, deletedGallery },
		auth: { user },
	} = state;
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	return { galleries, deletedGallery, isAdmin };
}

export default connect(mapStateToProps)(Galleries);
