import {
	TOGGLE_EXPANDED, WEEKLY_REQUEST, WEEKLY_SUCCESS, WEEKLY_FAILURE,
} from '../../actions/DashboardActions/AdminDataActions'

export function adminDataReducer(state = {
  dataFetching: false,
  authenticated: false,
  data: {},
  expanded: true
}, action) {
  switch (action.type) {
    case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      })
    case WEEKLY_REQUEST:
      return Object.assign({}, state, {
        dataFetching: true
      })
    case WEEKLY_SUCCESS:
      return Object.assign({}, state, {
        dataFetching: false,
        data: action.response
      })
    case WEEKLY_FAILURE:
      return Object.assign({}, state, {
        dataFetching: false,
        error: action.error
      })
    default:
      return state
  }
}
