import {
	SHOWS_REQUEST, SHOWS_FAILURE, SHOWS_SUCCESS,
	SHOWS_ALL_REQUEST, SHOWS_ALL_FAILURE, SHOWS_ALL_SUCCESS,
	SHOWS_ALL_POPULATE_REQUEST, SHOWS_ALL_POPULATE_SUCCESS, SHOWS_ALL_POPULATE_FAILURE,
	SHOW_REQUEST, SHOW_FAILURE, SHOW_SUCCESS,
	SHOW_SAVE_REQUEST, SHOW_SAVE_FAILURE, SHOW_SAVE_SUCCESS,
	SHOW_DELETE_REQUEST, SHOW_DELETE_FAILURE, SHOW_DELETE_SUCCESS,
	SHOWS_NEARBY_REQUEST, SHOWS_NEARBY_SUCCESS, SHOWS_NEARBY_FAILURE,
	SHOWS_OTHER_REQUEST, SHOWS_OTHER_SUCCESS, SHOWS_OTHER_FAILURE,
	INFORM_MEMBERS_SHOW_REQUEST, INFORM_MEMBERS_SHOW_SUCCESS, INFORM_MEMBERS_SHOW_FAILURE,
	INFORM_CONTACTS_SHOW_REQUEST, INFORM_CONTACTS_SHOW_SUCCESS, INFORM_CONTACTS_SHOW_FAILURE,
	SHOW_MAIL_PREVIEW_REQUEST, SHOW_MAIL_PREVIEW_SUCCESS, SHOW_MAIL_PREVIEW_FAILURE, SHOW_MAIL_PREVIEW_RESET,
} from '../actions/ShowActions'

export function showReducer(state = {
		isFetching: false,
		shows: {},
		show: {},
		allShows: {},
		nearby: {},
		otherShows: {},
		allOtherShows: {},
		authenticated: false,
		saved: false,
		preview: ''
	}, action) {
	switch (action.type) {
		case SHOWS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				deletedShow: false
			})
		}
		case SHOWS_SUCCESS: {
			let shows = {}
			action.response.map((show) => {
				shows[show._id] = show
			})
			return Object.assign({}, state, {
				isFetching: false,
				shows: shows,
				error: null
			})
		}
		case SHOWS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case SHOWS_ALL_POPULATE_REQUEST:
		case SHOWS_ALL_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case SHOWS_ALL_SUCCESS: {
			let shows = {}
			action.response.map((show) => {
				shows[show._id] = show
			})
			return Object.assign({}, state, {
				isFetching: false,
				allShows: shows,
			})
		}
		case SHOWS_ALL_POPULATE_SUCCESS: {
			let shows = Object.assign({}, state.allShows)
			action.response.map((show) => {
				shows[show._id] = show
			})
			return Object.assign({}, state, {
				isFetching: false,
				allShows: shows,
			})
		}
		case SHOWS_ALL_POPULATE_FAILURE:
		case SHOWS_ALL_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error,
			})
		}
		case SHOW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				show: {}
			})
		}
		case SHOW_SUCCESS: {
			const show = action.response
			let shows = Object.assign({}, state.shows)
			shows[show._id] = show
			return Object.assign({}, state, {
				isFetching: false,
				shows: shows,
				show: action.response
			})
		}
		case SHOW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				show: {},
				error: action.message
			})
		}
		case SHOW_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				saved: false
			})
		}
		case SHOW_SAVE_SUCCESS: {
			const show = action.response
			let shows = Object.assign({}, state.shows)
			shows[show._id] = show
			return Object.assign({}, state, {
				isFetching: false,
				shows: shows,
				show: show,
				saved: true
			})
		}
		case SHOW_SAVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error,
				saved: false
			})
		}
		case SHOW_DELETE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				deletedShow: true
			})
		}
		case SHOW_DELETE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case SHOW_DELETE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case SHOWS_NEARBY_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				nearby: action.response
			})
		}
		case SHOWS_NEARBY_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case SHOWS_NEARBY_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case SHOWS_OTHER_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				otherShows: {},
			})
		}
		case SHOWS_OTHER_SUCCESS: {
			let shows = {}
			let allShows = Object.assign({}, state.allOtherShows)
			action.response.map((show) => {
				shows[show._id] = show
				allShows[show._id] = show
			})
			return Object.assign({}, state, {
				isFetching: false,
				otherShows: shows,
				allOtherShows: allShows,
			})
		}
		case SHOWS_OTHER_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case INFORM_MEMBERS_SHOW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case INFORM_MEMBERS_SHOW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				show: action.response,
			})
		}
		case INFORM_MEMBERS_SHOW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informMembersError',
			})
		}
		case INFORM_CONTACTS_SHOW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case INFORM_CONTACTS_SHOW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				show: action.response,
			})
		}
		case INFORM_CONTACTS_SHOW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informContactsError',
			})
		}
		case SHOW_MAIL_PREVIEW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				preview: '',
				error: null,
			})
		}
		case SHOW_MAIL_PREVIEW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				preview: action.response,
			})
		}
		case SHOW_MAIL_PREVIEW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informMembersError',
			})
		}
		case SHOW_MAIL_PREVIEW_RESET: {
			return Object.assign({}, state, {
				preview: '',
			})
		}
		default:
			return state
	}
}
