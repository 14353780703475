import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import { saveAs } from "file-saver";
import Select from "react-select";
import Main from "../../components/Main/Main";
import { showErrorModal } from "../../actions/AppActions";
import Customers from "./Customers";
import Invoices from "./Invoices";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import {
	fetchInvoices,
	updateOrCreateCustomers,
	createInvoice,
	updateOrCreateCreditor,
	updateMemberDebtors,
} from "../../actions/BillectaActions";
import { fetchAllGalleriesFast } from "../../actions/GalleryActions";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";
import DateUtil, {
	dateMonthShort,
} from "@artworkslab/sharedmodules/src/DateUtil";

class Billecta extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			selectedGalleryOption: false,
		};
	}

	componentWillMount() {
		if (this.props.isAdmin) {
			this.props.dispatch(fetchAllGalleriesFast());
		} else if (this.props.user.gallery) {
			// Page not activated for galleries yet.
			// this.props.dispatch( fetchInvoices(this.props.user.gallery) )
		}
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, allGalleries, t } = nextProps;

		if (this.props.preview !== nextProps.preview) {
			const type = "application/pdf";
			const blob = new Blob([nextProps.preview], { type });
			saveAs(blob, "faktura.pdf");
		} else if (this.props.file !== nextProps.file) {
			const type = "application/pdf";
			const blob = new Blob([nextProps.file], { type });
			saveAs(blob, "faktura.pdf");
		}

		if (
			isAdmin &&
			this.state.galleryOptions.length === 0 &&
			Object.keys(allGalleries).length > 0
		) {
			let galleryOptions = [
				{
					value: "all",
					label: t("all"),
					galleryId: "",
				},
			];

			Object.keys(allGalleries).forEach((key) => {
				const venue = allGalleries[key];
				// Don't include venues without names. Messes up sorting.
				if (venue.name && isVenueArtSociety(venue)) {
					galleryOptions.push({
						value: venue.billectaDebtorId || venue._id,
						label: venue.name,
						galleryId: venue._id,
					});
				}
			});

			galleryOptions = galleryOptions.sort(this.sortOptions);

			this.setState({
				galleryOptions,
			});
		}
	}

	s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	selectGallery = (gallery) => {
		this.setState(
			{
				selectedGalleryOption: gallery,
			},
			() => {
				// gallery.galleryId is an empty string for "All" option.
				this.props.dispatch(fetchInvoices(gallery.galleryId));
			}
		);
	};

	updateCustomers = (galleryId) => {
		this.props.dispatch(updateOrCreateCustomers(galleryId));
	};

	createAnInvoice = (galleryId) => {
		this.props.dispatch(createInvoice(galleryId));
	};

	updateCreditor = (galleryId) => {
		this.props.dispatch(updateOrCreateCreditor(galleryId));
	};

	updateMembers = (galleryId) => {
		this.props.dispatch(updateMemberDebtors(galleryId));
	};

	datePassed = (date) => {
		if (!date) return false;
		return moment().isAfter(moment(date));
	};

	render() {
		const {
			isAdmin,
			user,
			invoices,
			results,
			userGallery,
			allGalleries,
			isFetching,
			error,
			t,
		} = this.props;
		const { galleryOptions, selectedGalleryOption } = this.state;

		if (!isAdmin) return null;

		const galleryDebtorId = isAdmin
			? selectedGalleryOption
				? selectedGalleryOption.value
				: 0
			: userGallery.billectaDebtorId || 0;

		const invoicesArray = Object.keys(invoices).map((key) => invoices[key]);

		let galleries = [];
		if (selectedGalleryOption) {
			switch (selectedGalleryOption.value) {
				case "all":
					galleryOptions.forEach((option) => {
						if (option.galleryId) {
							const gallery = allGalleries[option.galleryId];
							galleries.push(gallery);
						}
					});
					break;
				case false:
					galleries = [];
					break;
				default:
					galleries = [allGalleries[selectedGalleryOption.galleryId]];
			}
		}

		if (isAdmin) {
			return (
				<Main>
					<Select
						value={selectedGalleryOption}
						options={galleryOptions}
						onChange={this.selectGallery}
						onInputChange={() => {}}
						valueKey={"gallery"}
						labelKey="label"
						backspaceToRemoveMessage=""
						backspaceRemoves={false}
						isLoading={isFetching}
						placeholder={"Gallery"}
						multi={false}
						autosize={false}
						className={"gallery-picker-select"}
						isSearchable={true}
					/>
					{selectedGalleryOption && (
						<div style={{ margin: "20px 0" }}>
							<p style={{ marginBottom: "10px" }}>
								{t("updateGalleryDebtorInfo", {
									venue: selectedGalleryOption.label,
								})}
							</p>
							<button
								className="purple-button--wide button-nomargin"
								onClick={() =>
									this.updateCustomers(selectedGalleryOption.galleryId)
								}
								disabled={isFetching}
							>
								{t("updateGalleryDebtor")}
							</button>
							{selectedGalleryOption.value !== "all" && (
								<div style={{ marginTop: "20px" }}>
									<p style={{ marginBottom: "10px" }}>
										{t("createPremiumInvoiceInfo", {
											venue: selectedGalleryOption.label,
										})}
									</p>
									<button
										className="purple-button--wide button-nomargin"
										onClick={() => this.createAnInvoice(galleries[0]._id)}
										disabled={isFetching}
									>
										{t("createPremiumInvoice")}
									</button>
									{/*
                  <p style={{ margin: '20px 0 10px 0' }}>{ t('updateGalleryCreditorInfo', { venue: selectedGalleryOption.label }) }</p>
                  <button className="purple-button--wide button-nomargin" onClick={() => this.updateCreditor(galleries[0]._id)} disabled={ isFetching }>{t('updateGalleryCreditor')}</button>
                  <p style={{ margin: '20px 0 10px 0' }}>{ t('updateMemberDebtorsInfo', { venue: selectedGalleryOption.label }) }</p>
                  <button className="purple-button--wide button-nomargin" onClick={() => this.updateMembers(galleries[0]._id)} disabled={ isFetching }>{t('updateMemberDebtors')}</button>
                  */}
								</div>
							)}
							{selectedGalleryOption.value === "all" && (
								<div style={{ marginTop: "20px" }}>
									<p style={{ marginBottom: "10px" }}>
										{t("createPremiumInvoiceInfo", {
											venue: selectedGalleryOption.label,
										})}
									</p>
									<button
										className="purple-button--wide button-nomargin"
										onClick={() => this.updateCreditor("")}
										disabled={isFetching}
									>
										{t("createPremiumInvoice")}
									</button>
								</div>
							)}
							{results &&
								typeof results === "object" &&
								Object.keys(results).map((key) => {
									const resultArray = results[key];
									return (
										<div style={{ marginTop: "10px" }} key={key}>
											<h4>{key}</h4>
											{resultArray.map((result, index) => (
												<p key={index}>{result}</p>
											))}
										</div>
									);
								})}
							{results && typeof results === "string" && (
								<p style={{ marginTop: "10px", fontWeight: "bold" }}>
									{results}
								</p>
							)}
							{error && (
								<p
									style={{
										marginTop: "10px",
										fontWeight: "bold",
										color: "red",
									}}
								>
									{error}
								</p>
							)}
						</div>
					)}
					<Customers
						galleries={galleries}
						language={i18next.language}
						dispatch={this.props.dispatch}
						datePassed={this.datePassed}
						t={t}
					/>
					<Invoices
						invoices={invoicesArray}
						language={i18next.language}
						isAdmin={isAdmin}
						dispatch={this.props.dispatch}
						datePassed={this.datePassed}
						t={t}
					/>
				</Main>
			);
		} else {
			return (
				<Main>
					<Invoices
						invoices={invoicesArray}
						language={i18next.language}
						isAdmin={false}
						dispatch={this.props.dispatch}
						datePassed={this.datePassed}
						t={t}
					/>
				</Main>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		billecta: { invoices, invoice, preview, file, results, isFetching, error },
		gallery: { gallery: userGallery, allGalleries },
	} = state;
	const isAdmin = user.role === "admin";

	return {
		isAdmin,
		user,
		invoices,
		invoice,
		preview,
		file,
		results,
		userGallery,
		allGalleries,
		isFetching,
		error,
	};
};

const translated = withNamespaces("business")(Billecta);
export default connect(mapStateToProps)(translated);
