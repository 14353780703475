import React, { Component } from "react";
import { withRouter, Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Shows from "../Shows/Shows";
import Events from "../Event/Events";
import ArtistMentions from "../Mentions/ArtistMentions";
import Modal from "../../components/Modal/Modal";
import ArtistProLink from "../../components/ArtworksLite/ArtistProLink";
import EditMentions from "../Event/EditMentions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { markNotifications } from "@artworkslab/sharedmodules/src/actions/NotificationActions";
import { showCustomModal } from "../../actions/AppActions";

class Activities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayShows: true,
			displayEvents: false,
			displayMentions: false,
			myShowsOnly: true,
			showMentionModal: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const nextArtistId = nextProps.artist ? nextProps.artist._id : false;

		if (nextArtistId && !isPremium(nextProps.artist)) {
			this.props.router.push("/artworks");
		}
	}

	toggleDisplay = (display) => {
		let shows = false;
		let events = false;
		let mentions = false;
		if (display === "events") {
			events = true;
		} else if (display === "mentions") {
			mentions = true;
		} else {
			shows = true;
		}
		this.setState({
			displayShows: shows,
			displayEvents: events,
			displayMentions: mentions,
			myShowsOnly: false,
		});
	};

	setMyShowsOnly = (value) => {
		const { artist } = this.props;
		this.setState({
			myShowsOnly: value,
			displayShows: true,
			displayEvents: false,
			displayMentions: false,
		});
		if (artist._id) {
			this.props.dispatch(markNotifications(artist._id, "show"));
		}
	};

	showProModal = () => {
		// const content = (
		//   <div>
		//     <p>Här kan du som <i>PRO</i> själv arrangera digitala utställningar, och bjuda in andra att medverka.</p>
		//     {/*<p>Under Juli fortsätter vi fira 100! Därmed erbjuder vi <b>alla nya</b> <i>PRO</i> <b>medlemmar halva priset</b> vid årlig fakturering.</p>*/}
		//     <p><i>PRO</i> konstnärer har flest besök i sina profiler, står för ¼  av all försäljning 2021* och har lägre kommission.</p>
		//     <p>Läs mer och testa <i>PRO</i> gratis <ArtistProLink children={ 'här' } />! 🎨</p>
		//     <div className="modal-footnote">*Enligt data från 18/8 2021</div>
		//   </div>
		// )
		const content = (
			<div>
				<p>
					Här kan du som <i>PRO</i> själv arrangera digitala utställningar, och
					bjuda in andra att medverka.
				</p>
				<p>
					Läs mer och prova <ArtistProLink children={"här"} />!
				</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	toggleMentionModal = () => {
		this.setState({ showMentionModal: !this.state.showMentionModal });
	};

	render() {
		const {
			displayShows,
			displayEvents,
			displayMentions,
			myShowsOnly,
			showMentionModal,
		} = this.state;
		const { artist, premiumArtist, t } = this.props;

		let relatedIds = [];
		if (artist._id) {
			artist.related.forEach((relatedItem) => {
				relatedIds.push(relatedItem._id ? relatedItem._id : relatedItem);
			});
		}

		return (
			<div className="Activities">
				<div className="Activities__buttons">
					{displayShows && (
						<Link
							to="/shows/new"
							className={"purple-button--square--mod_create create-new-button"}
						>
							{t("list.arrangeShow")}
						</Link>
					)}
					{displayEvents && (
						<Link
							to="/events/new"
							className={"purple-button--square--mod_create create-new-button"}
						>
							{t("list.createNewEvent")}
						</Link>
					)}
					{displayMentions && (
						<button
							onClick={this.toggleMentionModal}
							className={"purple-button--square--mod_create create-new-button"}
						>
							{t("common:mentions.addMention")}
						</button>
					)}
				</div>
				<div className="artist-select">
					<div
						className={
							"artist-select__button" +
							(myShowsOnly && displayShows ? " is-selected" : "")
						}
						onClick={() => this.setMyShowsOnly(true)}
					>
						{t("list.yourShowsOnly")}
					</div>
					<div
						className={
							"artist-select__button" +
							(!myShowsOnly && displayShows ? " is-selected" : "")
						}
						onClick={() => this.setMyShowsOnly(false)}
					>
						{t("list.taggedShowsOnly")}
					</div>
					<div
						className={
							"artist-select__button" + (displayEvents ? " is-selected" : "")
						}
						onClick={() => this.toggleDisplay("events")}
					>
						{t("artist.myInvitations")}
					</div>
					<div>
						<div
							className={
								"artist-select__button" +
								(displayMentions ? " is-selected" : "")
							}
							onClick={() => this.toggleDisplay("mentions")}
						>
							{t("common:mentions.mentions")}
						</div>
					</div>
				</div>
				{displayShows && (
					<Shows
						myShowsOnly={myShowsOnly}
						relatedIds={relatedIds}
						artist={artist}
					/>
				)}
				{displayEvents && <Events />}
				{displayMentions && <ArtistMentions />}
				{showMentionModal && (
					<Modal onCloseClick={this.toggleMentionModal}>
						<EditMentions
							artist={artist}
							header={"MENTIONS HEADER"}
							toggleModal={this.toggleMentionModal}
						/>
					</Modal>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artist: { artist },
	} = state;
	const premiumArtist = isPremium(artist);
	return { artist, premiumArtist };
}

const translated = withNamespaces("show")(Activities);
export default connect(mapStateToProps)(withRouter(translated));
