import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";

// const BASE_URL = 'https://artworksapp.se'

const getUrl = (type, slug) => {
	if (!slug) return false;
	let BASE_URL = process.env.BASE_URL;
	if (i18next.language === "sv") {
		BASE_URL = BASE_URL.replace("artworksapp.com", "artworksapp.se");
		BASE_URL = BASE_URL.replace("artworks.io", "artworks.se");
		BASE_URL = BASE_URL.replace("localhost.com", "localhost.se");
	} else if (i18next.language === "en") {
		BASE_URL = BASE_URL.replace("artworksapp.se", "artworksapp.com");
		BASE_URL = BASE_URL.replace("artworks.se", "artworks.io");
		BASE_URL = BASE_URL.replace("localhost.se", "localhost.com");
	}

	switch (type) {
		case "gallery":
			return `${BASE_URL}venues/${slug}`;
		case "show":
			return `${BASE_URL}shows/${slug}`;
		case "artwork":
			return `${BASE_URL}artworks/${slug}`;
		case "post":
			return `${BASE_URL}articles/${slug}`;
		case "event":
			return `${BASE_URL}events/${slug}`;
		case "artist":
			return `${BASE_URL}artists/${slug}`;
		case "selection":
			return `${BASE_URL}selections/${slug}`;
		case "raffle":
			return `${BASE_URL}raffles/${slug}`;
		case "print":
			return `${process.env.PRINTS_URL}print/${slug}`;
		case "inspiration":
			return `${process.env.PRINTS_URL}inspiration/${slug}`;
		case "printartist":
			return `${process.env.PRINTS_URL}konstnar/${slug}`;
		default:
			return false;
	}
};

const TextDisplay = (props) => {
	const value =
		props.input.value && props.input.value.length > 0
			? props.input.value
			: false;
	const {
		itemType,
		isAdmin,
		isArtist,
		locked,
		isResponsive = false,
		t,
	} = props;
	const slugUrl = getUrl(itemType, value);
	const linkText = t("preview");
	const styling = props.styling || {};
	const style = Object.assign(
		{},
		{
			display: "flex",
			alignItems: "center",
		},
		styling
	);

	if (!isAdmin && isResponsive) return null;

	return (
		<div className="Input Input--text" style={style}>
			{!isAdmin ? (
				slugUrl ? (
					<a
						className={
							"purple-button--wide--mod_webb" + (isAdmin ? " is-admin" : "")
						}
						href={slugUrl}
						target="_blank"
					>
						{linkText}
					</a>
				) : null
			) : (
				<div className={"slugEdit" + (isResponsive ? " is-responsive" : "")}>
					<input
						className="slugEdit__input"
						type="text"
						{...props.input}
						placeholder="Slug"
						disabled={locked}
					/>
					{/* View on Webb link is in NavBar for responsive page. */}
					{!isResponsive && (
						<button
							className={
								"purple-button--wide--mod_webb" + (isAdmin ? " is-admin" : "")
							}
							type="button"
							onClick={() => window.open(slugUrl, "_blank")}
						>
							{linkText}
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default withNamespaces("common")(TextDisplay);
