import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import moment from "moment";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitGallery,
	showSuccess,
	hideSuccess,
	initGallery,
	loadGallery,
	hideError,
} from "../../../actions/FormActions/GalleryFormActions";
import {
	fetchGallery,
	fetchAllGalleriesFast,
} from "../../../actions/GalleryActions";
import {
	fetchSocietyAdmins,
	inviteUserSocietyAdmin,
	removeSocietyAdmins,
} from "../../../actions/UserActions";
import Main from "../../../components/Main/Main";
import SelectionPicker from "../../../components/FormComponents/SelectionPicker/SelectionPicker";
import GalleryMembership from "../../../components/GalleryMembership/GalleryMembership";
import GalleryUpgrades from "../../../components/GalleryMembership/GalleryUpgrades";
import GallerySettingsForm from "./GallerySettingsForm";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";

const BIN_SVG = require("../../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");

class GallerySettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			selectedOption: null,
		};
	}

	componentDidMount() {
		const {
			user: { gallery },
		} = this.props;
		const galleryId = gallery ? gallery : false;
		if (galleryId) {
			this.props.dispatch(fetchGallery(galleryId));
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			isAdmin,
			gallery: { gallery },
		} = this.props;
		const {
			gallery: { allGalleries },
		} = nextProps;

		//Fetch / load gallery if changed
		if (gallery._id !== nextProps.gallery.gallery._id) {
			this.props.dispatch(loadGallery(nextProps.gallery.gallery));
		}

		//Handle options for admin dropdown
		const galleryKeys = Object.keys(allGalleries);
		let galleryOptions = this.state.galleryOptions;
		let updateOptions = false;

		//Update options if more are retrieved
		if (galleryOptions.length !== galleryKeys.length) {
			galleryOptions = [];
			galleryKeys.forEach((key) => {
				const venue = allGalleries[key];
				galleryOptions.push({
					value: venue._id,
					label: venue.name,
					type: "venue",
				});
			});
			updateOptions = true;
		}

		//Update state if changed
		if (updateOptions) {
			this.setState({
				galleryOptions,
			});
		}
	}

	//Updates state selected option and fetches
	selectOption = (option) => {
		this.setState({
			selectedOption: option,
		});
		this.props.dispatch(fetchGallery(option.value));
	};

	//Fetch galleries depending on updated input
	inputChanged = (input) => {
		// Only fetch when exact numbers of letters entered.
		if (input.length === 2) {
			this.props.dispatch(fetchAllGalleriesFast(input));
		}
	};

	//Submits form
	handleSubmit = () => {
		const {
			form: { gallery, initial },
		} = this.props;
		this.props.dispatch(submitGallery(gallery, initial, {}));
	};

	render() {
		const { user, isAdmin, gallery, form, isFetching, societyAdmins, t } =
			this.props;
		const {
			galleryOptions,
			selectedOption,
			newSocietyAdminName,
			newSocietyAdminEmail,
			newSocietyAdminEmailValid,
		} = this.state;

		return (
			<div>
				<Main contentClassName="slim-content">
					{isAdmin && (
						<div className="input-holder input-holder--full">
							<Select
								value={selectedOption}
								options={galleryOptions}
								onChange={this.selectOption}
								onInputChange={this.inputChanged}
								valueKey={"gallery"}
								labelKey="label"
								backspaceToRemoveMessage=""
								backspaceRemoves={false}
								isLoading={gallery.isFetching}
								placeholder={t("artworks:list.gallery")}
								multi={false}
								autosize={false}
								className={"gallery-picker-select"}
								isSearchable={true}
							/>
						</div>
					)}
					{gallery.gallery._id && (
						<GallerySettingsForm
							onSubmit={this.handleSubmit}
							isAdmin={isAdmin}
							gallery={gallery.gallery}
						/>
					)}

					{gallery.gallery._id && <GalleryMembership />}

					{gallery.gallery._id && (
						<GalleryUpgrades
							gallery={gallery.gallery}
							isAdmin={isAdmin}
							t={t}
						/>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
		user: { societyAdmins, isFetching },
		gallery,
	} = state;

	const form = {
		submitting: isSubmitting("gallery")(state),
		submitSucceeded: hasSubmitSucceeded("gallery")(state),
		submitFailed: hasSubmitFailed("gallery")(state),
		gallery: getFormValues("gallery")(state),
		initial: getFormInitialValues("gallery")(state),
	};
	return { user, isAdmin, gallery, form, societyAdmins, isFetching };
}

const translated = withNamespaces("gallery")(GallerySettings);
export default connect(mapStateToProps)(withRouter(translated));
