"use strict";
import { saveGallery } from "../GalleryActions";
import { showErrorModal, showRetryModal } from "../AppActions";
import { initialize, startSubmit, stopSubmit, change } from "redux-form";
import Validator from "validatorjs";
import sv from "./sv";
import i18next from "i18next";
import diff from "object-diff";
import { getObjectProp, hasObjectProp } from "../../utils/Helpers";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

export const LOAD = "artworksapp/gallery/LOAD";
export const SUBMIT = "artworksapp/gallery/SUBMIT";

export const SHOW_SUCCESS = "artworksapp/gallery/SHOW_SUCCESS";
export const HIDE_SUCCESS = "artworksapp/gallery/HIDE_SUCCESS";

export const SHOW_ERROR = "artworksapp/gallery/SHOW_ERROR";
export const HIDE_ERROR = "artworksapp/gallery/HIDE_ERROR";

export const SHOW_GALLERY_MODAL = "artworksapp/gallery/SHOW_GALLERY_MODAL";
export const HIDE_GALLERY_MODAL = "artworksapp/gallery/HIDE_GALLERY_MODAL";

const DAYS = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

/**
 * Simulates data loaded into this reducer from somewhere
 */
export function load(data) {
	return {
		type: LOAD,
		data,
	};
}

export function submitGallery(data, initial, customRules = false) {
	return (dispatch, getState) => {
		const isSociety =
			getState().auth.user.role === "society" || isVenueArtSociety(data);
		const premium = __USE_TIERS__ ? true : isPremium(data);
		const isSwedish = i18next.language === "sv";
		let customErrors = {};
		// Fields name/email/phone not available when saving from GallerySettings.
		let rules = customRules || {
			name: "required",
			email: "required|email",
			phone: "required",
		};
		// if (!isSociety) {
		//   rules.address = {
		//     city: 'required',
		//     country: 'required',
		//     street: 'required',
		//     zip: 'required',
		//   }
		// }

		if (
			(hasObjectProp(data, "uploadedImage.URI") &&
				hasObjectProp(data, "uploadedImage.contentType") &&
				hasObjectProp(data, "uploadedImage.fileName")) ||
			hasObjectProp(data, "featuredImage.src")
		) {
			console.log("image is valid");
		} else {
			// Image not required anymore. A completely black base64 image provided in add() in gallerys.controller.js
			// console.log('image is invalid');
			// customErrors.featuredImage = { src: isSwedish ? 'Bild är obligatoriskt.' : 'Image is required.' }
		}

		// Ensure openingHours are formatted correctly
		let submittedOpeningHours = data.openingHours;
		let openingHours = {};
		if (submittedOpeningHours) {
			DAYS.map((day) => {
				let openDay = submittedOpeningHours[day] || {};
				if (openDay.appointment) {
					openDay.opens = null;
					openDay.closes = null;
				} else {
					openDay.appointment = false;
				}
				openingHours[day] = openDay;
			});
		}
		data.openingHours = openingHours;

		let validation = isSwedish
			? new Validator(data, rules, { ...sv })
			: new Validator(data, rules);

		if (isSwedish) {
			validation.setAttributeNames({
				name: "Namn",
				email: "Email",
				phone: "Telefonnummer",
			});
		}

		validation.passes();
		const validatorErrors = validation.errors.all();
		const errors = Object.assign({}, customErrors, validatorErrors);

		let changedValues = initial ? diff(initial, data) : data;
		changedValues._id = data._id ? data._id : undefined;
		// if (data._id) changedValues._id = data._id

		// If gallery has added a new mention
		if (changedValues.mentionTitle && changedValues.mentionLink) {
			changedValues = AddNewMention(changedValues, initial);
		}

		// Save date when membershipLevel was updated.
		if (changedValues.billing && changedValues.billing.membershipLevel) {
			const authUser = getState().auth.user;
			const upgradedLevel = membershipLevelChanged(initial, data);
			if (upgradedLevel) {
				const premiumDates = changedValues.billing.premiumDates
					? changedValues.billing.premiumDates.slice()
					: [];
				premiumDates.push({
					date: new Date(),
					level: upgradedLevel,
					user: authUser.id,
				});
				changedValues.billing.premiumDates = premiumDates;
			}
		}

		if (Object.keys(errors).length !== 0) {
			dispatch(showErrorModal(translate("requiredFields")));
			return dispatch(stopSubmit("gallery", errors));
		}

		// const missingData = missingBillingData(data)
		// if (isSociety && premium && missingData) return dispatch( showRetryModal(
		//   changedValues,
		//   'gallery',
		//   isSwedish ? `Vill du spara utan ${missingData}?` : `Do you want to save without ${missingData}?`,
		//   null,
		// ))

		dispatch(saveGalleryData(changedValues));
	};
}

export function submitPartialGallery(data, initial) {
	return (dispatch, getState) => {
		const isSwedish = i18next.language === "sv";
		let customErrors = {};
		let rules = {};

		let validation = isSwedish
			? new Validator(data, rules, { ...sv })
			: new Validator(data, rules);

		if (isSwedish) {
			validation.setAttributeNames({
				name: "Namn",
				about: "Om",
				email: "Email",
				phone: "Telefonnummer",
				artists: "Konstnär",
			});
		}

		validation.passes();
		const validatorErrors = validation.errors.all();
		const errors = Object.assign({}, customErrors, validatorErrors);

		let changedValues = initial ? diff(initial, data) : data;
		changedValues._id = data._id ? data._id : undefined;
		// if (data._id) changedValues._id = data._id

		// If gallery has added a new mention
		if (changedValues.mentionTitle && changedValues.mentionLink) {
			changedValues = AddNewMention(changedValues, initial);
		}

		if (Object.keys(errors).length !== 0) {
			dispatch(showErrorModal(translate("requiredFields")));
			return dispatch(stopSubmit("gallery", errors));
		}

		dispatch(saveGalleryData(changedValues));
	};
}

const missingBillingData = (data) => {
	const isSwedish = i18next.language === "sv";
	let missing = [];
	if (!data.orgNumber)
		missing.push(isSwedish ? "organisationsnummer" : "organisation number");
	if (!data.VATnumber)
		missing.push(isSwedish ? "momsregistreringsnummer" : "VAT number");
	if (!data.invoiceEmail)
		missing.push(isSwedish ? "faktura email" : "invoice email");
	if (!data.invoiceAddress) {
		missing.push(isSwedish ? "faktura adress" : "invoice address");
	} else if (
		!data.invoiceAddress.street ||
		!data.invoiceAddress.city ||
		!data.invoiceAddress.zip ||
		!data.invoiceAddress.country
	) {
		missing.push(
			isSwedish ? "komplett faktura adress" : "complete invoice address"
		);
	}

	return missing.join(", ");
};

const membershipLevelChanged = (initial, data) => {
	const changed = diff(initial.billing || {}, data.billing || {});
	if (changed.membershipLevel) return changed.membershipLevel;
	return false;
};

export function saveGalleryData(changedValues) {
	return (dispatch) => {
		dispatch(startSubmit("gallery"));
		return dispatch(saveGallery(changedValues))
			.then((gallery) => {
				dispatch(
					initialize("gallery", gallery, null, { keepSubmitSucceeded: true })
				);
			})
			.catch((err) => {
				console.log("ERROR", err);
			});
	};
}

function AddNewMention(changedValues, initial) {
	const mention = formatNewMention(
		changedValues.mentionTitle,
		changedValues.mentionLink,
		changedValues.mentionCategory
	);

	// A new mention must be added to existing related
	if (changedValues.related) {
		changedValues.related = changedValues.related.concat([mention]);
	} else if (initial.related) {
		changedValues.related = initial.related.concat([mention]);
	} else {
		changedValues.related = [mention];
	}
	delete changedValues.mentionTitle;
	delete changedValues.mentionLink;

	return changedValues;
}

function formatNewMention(title, link, category = "") {
	let formattedMention = {};
	const isInternalLink =
		link.indexOf("artworksapp.") > -1 || link.indexOf("artworks.") > -1
			? true
			: false;

	if (isInternalLink) {
		let category = "";
		if (link.indexOf("/artworks/") > -1 || link.indexOf("/konstverk/") > -1)
			category = "Artwork";
		if (link.indexOf("/artists/") > -1 || link.indexOf("/konstnarer/") > -1)
			category = "Artist";
		if (link.indexOf("/shows/") > -1 || link.indexOf("/utstallningar/") > -1)
			category = "Show";
		if (link.indexOf("/venues/") > -1 || link.indexOf("/gallerier/") > -1)
			category = "Venue";
		if (link.indexOf("/articles/") > -1 || link.indexOf("/artiklar/") > -1)
			category = "Article";
		if (link.indexOf("/events/") > -1 || link.indexOf("/aktiviteter/") > -1)
			category = "Event";

		let linkParts = ["/"];
		if (link.indexOf("artworksapp.com") > -1)
			linkParts = link.split("artworksapp.com");
		if (link.indexOf("artworksapp.se") > -1)
			linkParts = link.split("artworksapp.se");
		if (link.indexOf("artworks.se") > -1) linkParts = link.split("artworks.se");
		if (link.indexOf("artworks.io") > -1) linkParts = link.split("artworks.io");
		const intLink = linkParts[linkParts.length - 1];

		formattedMention = {
			category,
			title,
			internalLink: intLink,
			externalLink: "",
		};
	} else {
		formattedMention = {
			category,
			title,
			internalLink: "",
			externalLink: link,
		};
	}

	return formattedMention;
}

export function loadGallery(data) {
	if (!data.visibility) data.visibility = "all";
	return (dispatch, getState) => {
		if (!data.VATnumber) data.VATnumber = "";
		dispatch(initialize("gallery", data));
	};
}

export function initGallery() {
	return (dispatch, getState) => {
		dispatch(initialize("gallery", {}));
	};
}

export function showGalleryModal() {
	return {
		type: SHOW_GALLERY_MODAL,
		showGalleryModal: true,
	};
}

export function hideGalleryModal() {
	return {
		type: HIDE_GALLERY_MODAL,
		showGalleryModal: false,
	};
}

export function showSuccess() {
	return {
		type: SHOW_SUCCESS,
		showSuccess: true,
	};
}

export function hideSuccess() {
	return {
		type: HIDE_SUCCESS,
		showSuccess: false,
	};
}

export function showError() {
	return {
		type: SHOW_ERROR,
		showError: true,
	};
}

export function hideError() {
	return {
		type: HIDE_ERROR,
		showError: false,
	};
}
