import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Main from "../../components/Main/Main";
import { fetchInfluencers } from "../../actions/InfluencerActions";
import { BLACK_IMAGE, thumbnailError } from "../../utils/Helpers";
import { capitalizeString } from "../../utils/FormatHelper";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
// import './Influencers.sass'

const ARROW = require("../../../assets/images/icons/chevron_right_white_22px.png");

class Influencers extends Component {
	componentWillMount() {
		this.fetchData(this.props);
	}

	fetchData = (props) => {
		this.props.dispatch(fetchInfluencers("all"));
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	createRows = (influencers, t) => {
		return Object.keys(influencers).map((key) => {
			const influencer = influencers[key];

			let image = influencer.featuredImage || null;
			const thumbnailSrc = image ? this.getImageSrc(image) : "";
			if (!image) image = BLACK_IMAGE;

			const published =
				influencer.publish_status === "published" ? true : false;

			return (
				<Tr key={key}>
					<Td column="image" style={{ padding: 0, width: 70 }}>
						<Link to={`/influencers/${influencer.slug || influencer._id}`}>
							<img
								src={thumbnailSrc}
								className="table-image"
								onError={(e) => thumbnailError(e, e.target.src, image.src)}
								alt="Influencer image"
							/>
						</Link>
					</Td>
					<Td column="name" value={influencer.name}>
						<Link to={`/influencers/${influencer.slug || influencer._id}`}>
							{influencer.name}
						</Link>
					</Td>
					<Td column="category" value={influencer.category}>
						<Link to={`/influencers/${influencer.slug || influencer._id}`}>
							{capitalizeString(influencer.category || "")}
						</Link>
					</Td>
					<Td column="createdAt" value={influencer.created_at}>
						<Link to={`/influencers/${influencer.slug || influencer._id}`}>
							{DateUtil.dateMonthShort(
								influencer.created_at,
								i18next.language,
								true,
								true
							)}
						</Link>
					</Td>
					<Td column="status" value={influencer.publish_status}>
						<Link
							to={`/influencers/${influencer.slug || influencer._id}`}
							className={
								"reactable__state" + (published ? " is-published" : "")
							}
						>
							{t(`common:status.${influencer.publish_status}`)}
						</Link>
					</Td>
				</Tr>
			);
		});
	};

	render() {
		const { influencers, isAdmin, t } = this.props;

		if (!isAdmin) return null;

		const rows = this.createRows(influencers, t);

		return (
			<Main>
				<div className="influencers">
					<h1>Influencers</h1>
					<div style={{ marginBottom: "40px" }}>
						<Link
							to="/influencers/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							{"Create influencer"}
						</Link>
					</div>
					<Table
						className="reactable"
						sortable={["name", "status", "createdAt"]}
						filterable={["name"]}
						itemsPerPage={20}
						pageButtonLimit={10}
					>
						<Thead>
							<Th column="image"> </Th>
							<Th column="name">{"Name"}</Th>
							<Th column="category">{"Category"}</Th>
							<Th column="status">{"Status"}</Th>
							<Th column="createdAt">{"Created at"}</Th>
						</Thead>
						{rows}
					</Table>
				</div>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		influencer: { allInfluencers: influencers },
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	return { influencers, isAdmin };
}

const translated = withNamespaces("common")(Influencers);
export default connect(mapStateToProps)(translated);
