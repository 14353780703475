import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	fetchEvents,
	fetchAllEventsFast,
	fetchAllEventsPopulated,
} from "../../actions/EventActions";
import Main from "../../components/Main/Main";
import FormMeta from "../../components/Meta/FormMeta";
import Modal from "../../components/Modal/Modal";
import EditMentions from "./EditMentions";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import moment from "moment";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

class Events extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		const { isAdmin, user, events, deletedEvent } = this.props;
		if (isAdmin) {
			// Events for admin here is allEvents from state. If already fetched, only update.
			if (Object.keys(events).length === 0 || deletedEvent) {
				this.props.dispatch(fetchAllEventsFast()).then((result) => {
					this.props.dispatch(fetchAllEventsPopulated());
				});
			} else {
				this.props.dispatch(fetchAllEventsPopulated());
			}
		} else {
			this.props.dispatch(fetchEvents());
		}
	}

	hasProp = (object, key) => {
		if (object) {
			return object.hasOwnProperty(key);
		} else {
			return false;
		}
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	renderTable(isAdmin, rows, maxItems, t) {
		return (
			<Table
				className="reactable"
				sortable={["title", "startDate", "status", "createdBy", "createdAt"]}
				filterable={isAdmin ? ["title", "createdBy"] : false}
				filterPlaceholder={"Search event"}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="image"> </Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="startDate" style={{ width: 100 }}>
						{t("common:startDate")}
					</Th>
					{isAdmin && (
						<Th column="createdBy" style={{ width: 150 }}>
							{t("common:createdBy")}
						</Th>
					)}
					<Th column="createdAt" style={{ width: 150 }}>
						{t("common:createdAt")}
					</Th>
					<Th column="status" style={{ width: 100 }}>
						Status
					</Th>
				</Thead>
				{rows}
			</Table>
		);
	}

	renderRows(events, t) {
		let rows = [];
		const that = this;
		Object.keys(events)
			.sort((a, b) => {
				let aObject = events[a];
				let bObject = events[b];
				return new Date(bObject.startDate) - new Date(aObject.startDate);
			})
			.forEach((key) => {
				let event = events[key];
				const image = event.featuredImage || "";
				const thumbnailSrc = image ? this.getImageSrc(image) : "";
				const createdBy = that.hasProp(event.created_by, "name")
					? event.created_by.role === "admin"
						? "Artworks"
						: event.created_by.name
					: "N/A";

				rows.push(
					<Tr key={event._id}>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{image ? (
								<Link to={`/events/${event.slug}`}>
									<img
										src={thumbnailSrc}
										className="table-image"
										onError={(e) =>
											this.thumbnailError(e, e.target.src, image.src)
										}
										alt="Event image"
									/>
								</Link>
							) : null}
						</Td>
						<Td column="title" value={event.title}>
							<Link to={`/events/${event.slug}`}>{event.title}</Link>
						</Td>
						<Td column="startDate" value={event.startDate}>
							<Link to={`/events/${event.slug}`}>
								{event.startDate
									? DateUtil.dateMonthShort(
											event.startDate,
											i18next.language,
											true
									  )
									: "N/A"}
							</Link>
						</Td>
						<Td column="createdBy" value={createdBy}>
							<Link to={`/events/${event.slug}`}>{createdBy}</Link>
						</Td>
						<Td column="createdAt" value={event.created_at}>
							<Link to={`/events/${event.slug}`}>
								{DateUtil.dateMonthShort(
									event.created_at,
									i18next.language,
									true
								)}
							</Link>
						</Td>
						<Td
							column="status"
							value={event.publish_status ? event.publish_status : "N/A"}
						>
							<Link to={`/events/${event.slug}`}>
								{event.publish_status
									? t(`common:status.${event.publish_status}`)
									: "N/A"}
							</Link>
						</Td>
					</Tr>
				);
			});

		return rows;
	}

	renderNewTable(isAdmin, rows, maxItems, t) {
		return (
			<Table
				className="reactable"
				sortable={[
					"title",
					"name",
					"startDate",
					"endDate",
					"createdBy",
					"createdAt",
					"status",
					"owner",
				]}
				filterable={
					isAdmin ? ["title", "name", "createdBy", "status", "owner"] : false
				}
				filterPlaceholder={"Search event"}
				defaultSort={isAdmin ? "startDate" : false}
				defaultSortDescending={true}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="image"> </Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="name">{t("list.artist")}</Th>
					{isAdmin && <Th column="owner">{t("list.organizer")}</Th>}
					<Th column="startDate">{t("common:startDate")}</Th>
					<Th column="endDate">{t("common:endDate")}</Th>
					{isAdmin && <Th column="createdBy">{t("common:createdBy")}</Th>}
					{isAdmin && <Th column="createdAt">{t("common:createdAt")}</Th>}
					<Th column="status">Status</Th>
				</Thead>
				{rows}
			</Table>
		);
	}

	renderNewRows(events, t) {
		let rows = [];
		const that = this;
		Object.keys(events)
			.sort((a, b) => {
				let aObject = events[a];
				let bObject = events[b];
				return new Date(bObject.startDate) - new Date(aObject.startDate);
			})
			.forEach((key) => {
				let event = events[key];
				const image = event.featuredImage || "";
				const thumbnailSrc = image ? this.getImageSrc(image) : "";
				const createdBy = that.hasProp(event.created_by, "name")
					? event.created_by.role === "admin"
						? "Artworks"
						: event.created_by.name
					: "N/A";
				const galleryName =
					event.gallery && event.gallery.name ? event.gallery.name : "";
				const artistName =
					event.artist && event.artist.name ? event.artist.name : "";
				const published = event.publish_status === "published" ? true : false;

				rows.push(
					<Tr key={event._id}>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{image ? (
								<Link to={`/events/${event.slug}`}>
									<img
										src={thumbnailSrc}
										className="table-image"
										onError={(e) =>
											this.thumbnailError(e, e.target.src, image.src)
										}
										alt="Event image"
									/>
								</Link>
							) : null}
						</Td>
						<Td column="title" value={event.title}>
							<Link to={`/events/${event.slug}`}>{event.title}</Link>
						</Td>
						<Td column="name" value={artistName}>
							<Link to={`/events/${event.slug}`}>{artistName}</Link>
						</Td>
						<Td column="owner" value={galleryName}>
							<Link to={`/events/${event.slug}`}>{galleryName}</Link>
						</Td>
						<Td column="startDate" value={event.startDate}>
							<Link to={`/events/${event.slug}`}>
								{event.startDate
									? DateUtil.dateMonthShort(
											event.startDate,
											i18next.language,
											true
									  )
									: "N/A"}
							</Link>
						</Td>
						<Td column="endDate" value={event.endDate}>
							<Link to={`/events/${event.slug}`}>
								{event.endDate
									? DateUtil.dateMonthShort(
											event.endDate,
											i18next.language,
											true
									  )
									: "N/A"}
							</Link>
						</Td>
						<Td column="createdBy" value={createdBy}>
							<Link to={`/events/${event.slug}`}>{createdBy}</Link>
						</Td>
						<Td column="createdAt" value={event.created_at}>
							<Link to={`/events/${event.slug}`}>
								{DateUtil.dateMonthShort(
									event.created_at,
									i18next.language,
									true
								)}
							</Link>
						</Td>
						<Td column="status" value={event.publish_status}>
							<Link
								to={`/events/${event.slug}`}
								className={
									"reactable__state" + (published ? " is-published" : "")
								}
							>
								{t(`common:status.${event.publish_status}`)}
							</Link>
						</Td>
					</Tr>
				);
			});

		return rows;
	}

	render() {
		const { isAdmin, isArtist, events, t } = this.props;
		const upcomingEvents = [];
		const pastEvents = [];
		const today = moment();
		if (!isAdmin) {
			Object.keys(events).forEach((key) => {
				const event = events[key];
				if (moment(event.startDate).isAfter(today)) {
					upcomingEvents.push(event);
				} else {
					pastEvents.push(event);
				}
			});
		}

		return (
			<div className="Events">
				<Main>
					<div className={"content" + (isArtist ? " is-artist" : "")}>
						{!isArtist && (
							<div>
								<div style={{ marginBottom: "30px" }}>
									<Link
										to="/events/new"
										className="purple-button--wide purple-button--wide--mod_create"
									>
										{t("list.createNewEvent")}
									</Link>
								</div>
							</div>
						)}
						{isAdmin &&
							this.renderTable(isAdmin, this.renderRows(events, t), 100, t)}
						{!isAdmin && (
							<div>
								<legend className="legend-nomargin">
									{isArtist
										? `${t("list.upcomingInvitations")} (${
												upcomingEvents.length
										  })`
										: `${t("list.upcomingEvents")} (${upcomingEvents.length})`}
								</legend>
								{upcomingEvents.length > 0 &&
									this.renderNewTable(
										isAdmin,
										this.renderNewRows(upcomingEvents, t),
										false,
										t
									)}
								<legend
									className="legend-nomargin"
									style={{ marginTop: "30px" }}
								>
									{isArtist
										? `${t("list.pastInvitations")} (${pastEvents.length})`
										: `${t("list.pastEvents")} (${pastEvents.length})`}
								</legend>
								{pastEvents.length > 0 &&
									this.renderNewTable(
										isAdmin,
										this.renderNewRows(pastEvents, t),
										pastEvents.length > 100 ? 100 : false,
										t
									)}
							</div>
						)}
					</div>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		event: { events: selectedEvents, allEvents, deletedEvent },
		auth: { user },
	} = state;
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const events = isAdmin ? allEvents : selectedEvents;
	const isArtist = user.role === "artist";
	return { events, user, isAdmin, isArtist, deletedEvent };
}

const translated = withNamespaces("event")(Events);
export default connect(mapStateToProps)(translated);
