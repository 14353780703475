import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../../components/Table/Table";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import Main from "../../../components/Main/Main";
import { fetchStripeSubscribers } from "../../../actions/Stripe/StripeSubActions";

class StripeSubscriptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		this.props.dispatch(fetchStripeSubscribers("pro"));
	}

	getActiveCount = (subscriptions) => {
		let activeCount = 0;
		subscriptions.forEach((subscription) => {
			if (!subscription.ended) activeCount++;
		});
		return activeCount;
	};

	createTable = (rows, active) => {
		return (
			<Table
				className="reactable"
				sortable={[
					"name",
					"email",
					"plan",
					"membershipLevel",
					"createdAt",
					"cancelledSub",
					"endedSub",
					"duplicate",
					"hasActive",
				]}
				filterable={false}
				filterPlaceholder="Search subscriptions"
			>
				<Thead>
					<Th column="name">Name</Th>
					<Th column="email">Email</Th>
					<Th column="createdAt">Member since</Th>
					<Th column="membershipLevel">Membership</Th>
					<Th column="plan">Plan</Th>
					{active ? (
						<Th column="duplicate">Duplicates</Th>
					) : (
						<Th column="endedSub">Ended</Th>
					)}
					<Th column="cancelledSub">Cancelled</Th>
					{!active && <Th column="hasActive">Has active sub</Th>}
				</Thead>
				{rows}
			</Table>
		);
	};

	createRows = (subscribers) => {
		const rows = { active: [], ended: [] };

		subscribers.forEach((subscriber) => {
			// Subscripber is an Artist in db.
			let activeCount = this.getActiveCount(subscriber.stripeSubscriptions);
			// Mark in list if a subscriber has mulitple active subscriptions.
			const hasDuplicates = activeCount > 1;
			if (hasDuplicates)
				console.log("%cHELLO " + subscriber.email, "color:red");
			subscriber.stripeSubscriptions.forEach((subscription) => {
				// Showing duplicates only interesting on active subscriptions.
				const duplicate = hasDuplicates && !subscription.ended;
				// Show if an artist with an ended subscription also has an active subscription.
				const hasAnActiveSub = activeCount > 0;
				let membershipLevel = subscriber.billing
					? subscriber.billing.membershipLevel
					: "";
				if (membershipLevel === "premium") membershipLevel = "Pro";
				if (membershipLevel === "free") membershipLevel = "Bas";

				const row = (
					<Tr key={subscriber._id}>
						<Td column="name" value={subscriber.name}>
							<Link to={`/artists/${subscriber.slug}`}>{subscriber.name}</Link>
						</Td>
						<Td column="email" value={subscriber.email}>
							{subscriber.email}
						</Td>
						<Td column="createdAt" value={subscription.created_at}>
							{subscription.created_at
								? DateUtil.dateMonthShort(subscription.created_at, "en", true)
								: ""}
						</Td>
						<Td column="membershipLevel" value={membershipLevel}>
							{membershipLevel}
						</Td>
						<Td
							column="plan"
							value={subscription.planName || subscription.planNickname}
						>
							<Link to={`/stripe-subscriptions/${subscription._id}`}>
								{subscription.planNickname}
							</Link>
						</Td>
						<Td column="duplicate" style={{ color: "red" }} value={duplicate}>
							{duplicate ? "Yes" : ""}
						</Td>
						<Td column="cancelledSub" value={subscription.canceled}>
							{subscription.canceled
								? DateUtil.dateMonthShort(subscription.canceled, "en", true)
								: ""}
						</Td>
						<Td column="endedSub" value={subscription.ended}>
							{subscription.ended
								? DateUtil.dateMonthShort(subscription.ended, "en", true)
								: ""}
						</Td>
						<Td column="hasActive" value={hasAnActiveSub}>
							{hasAnActiveSub ? "Yes" : ""}
						</Td>
					</Tr>
				);

				if (!subscription.ended) {
					rows.active.push(row);
				} else {
					rows.ended.push(row);
				}
			});
		});

		return rows;
	};

	render() {
		const { isAdmin, subscribers } = this.props;
		if (!isAdmin) return null;

		const rows = this.createRows(subscribers);
		const activeTable = this.createTable(rows.active, true);
		const endedTable = this.createTable(rows.ended, false);
		const activeCount = rows.active.length;
		const endedCount = rows.ended.length;

		return (
			<Main>
				<h2
					style={{ marginBottom: "10px" }}
				>{`Artist Pro with Stripe (${activeCount})`}</h2>
				{activeTable}
				<h2
					style={{ margin: "100px 0 10px 0" }}
				>{`Ended subscriptions (${endedCount})`}</h2>
				{endedTable}
			</Main>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		stripeSubscriptions: { subscribers },
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	return { isAdmin, subscribers };
};

export default connect(mapStateToProps)(StripeSubscriptions);
