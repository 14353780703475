import { CALL_API } from '../utils/api'

export const SET_ACCESS_REQUEST = 'SET_ACCESS_REQUEST'
export const SET_ACCESS_SUCCESS = 'SET_ACCESS_SUCCESS'
export const SET_ACCESS_FAILURE = 'SET_ACCESS_FAILURE'

// Uses the API to get a show
export function setAccess(id, data, type = 'shows') {
	const endpoint = `${type}/${id}/setaccess`
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [SET_ACCESS_REQUEST, SET_ACCESS_SUCCESS, SET_ACCESS_FAILURE],
			method: "POST",
			body: data
		}
	}
}


export const GET_ACCESS_REQUEST = 'GET_ACCESS_REQUEST'
export const GET_ACCESS_SUCCESS = 'GET_ACCESS_SUCCESS'
export const GET_ACCESS_FAILURE = 'GET_ACCESS_FAILURE'

// Uses the API to get a show
export function getAccess(id, type = 'shows') {
	const endpoint = `${type}/${id}/access`
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [GET_ACCESS_REQUEST, GET_ACCESS_SUCCESS, GET_ACCESS_FAILURE],
			method: "GET"
		}
	}
}
