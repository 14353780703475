import React, { Component } from 'react'
import { Link }             from 'react-router'
import { connect }          from 'react-redux'
import {
  Field,
  Fields,
  FormSection,
  formValueSelector,
  reduxForm,
} from 'redux-form'
import TextInput            from '../../../components/FormInput/TextInput'

class StripeSubForm extends Component {
  formSubmit = (e) => {
    e.preventDefault()
    this.props.handleSubmit()
  }

  render() {
    const { isAdmin } = this.props
    if (!isAdmin) return null

    return (
      <div>
        <form onSubmit={ (e) => this.formSubmit(e) }>
          <div className="fieldset">
            <div className="input-holder input-holder--full">
              <div className="input-holder">
                <legend>Subscription id</legend>
                <Field
                  name="subscriptionId"
                  component={ TextInput }
                  placeholder="subscriptionId"
                  disabled={ true }
                />
                <Field
                  name="planName"
                  component={ TextInput }
                  placeholder="planName"
                  disabled={ true }
                />
                <legend>Current period start</legend>
                <Field
                  name="currentPeriodStartDate"
                  component={ TextInput }
                  placeholder="currentPeriodStartDate"
                  disabled={ true }
                />
                <legend>Trial start</legend>
                <Field
                  name="trialStartDate"
                  component={ TextInput }
                  placeholder="trialStartDate"
                  disabled={ true }
                />
                <legend>Cancel at period end</legend>
                <Field
                  name="cancelAtPeriodEnd"
                  component={ TextInput }
                  placeholder="cancelAtPeriodEnd"
                  disabled={ true }
                />
                <legend>Status</legend>
                <Field
                  name="status"
                  component={ TextInput }
                  placeholder="status"
                  disabled={ true }
                />
              </div>
              <div className="input-holder">
                <legend>Customer id</legend>
                <Field
                  name="customerId"
                  component={ TextInput }
                  placeholder="customerId"
                  disabled={ true }
                  />
                <Field
                  name="planNickname"
                  component={ TextInput }
                  placeholder="planNickname"
                  disabled={ true }
                />
                <legend>Current period end</legend>
                <Field
                  name="currentPeriodEndDate"
                  component={ TextInput }
                  placeholder="currentPeriodEndDate"
                  disabled={ true }
                />
                <legend>Trial end</legend>
                <Field
                  name="trialEndDate"
                  component={ TextInput }
                  placeholder="trialEndDate"
                  disabled={ true }
                />
                <legend>Canceled</legend>
                <Field
                  name="canceled"
                  component={ TextInput }
                  placeholder="Not canceled"
                  disabled={ true }
                />
                <legend>Ended</legend>
                <Field
                  name="ended"
                  component={ TextInput }
                  placeholder="Not ended"
                  disabled={ true }
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

StripeSubForm = reduxForm({
  form: 'stripeSub',
})(StripeSubForm)

const selector = formValueSelector('stripeSub')
StripeSubForm = connect(
  (state) => {
    const id = selector(state, '_id')

    return {
      id,
    }
  },
  dispatch => {
    return {
      testFunc: (id) => {
        () => {}
      }
    }
  }
)(StripeSubForm)

export default StripeSubForm
