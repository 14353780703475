import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import queryString from "query-string";
import BurgerButton from "./BurgerMenu/BurgerButton";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import LatestSalesBanner from "./SalesBanner/LatestSalesBanner";
import GalleryMembershipModal from "../Modal/Custom/GalleryMembershipModal";
import { artistProSubscribeWindowOpened } from "@artworkslab/sharedmodules/src/actions/MessageActions";

const LOGO = require("../../../assets/images/portal-logo.png");
const LOGO_MOBILE = require("../../../assets/images/logo.png");
const TRIANGLE = require("../../../assets/images/svg/triangle_up_black.svg");
// const VIEW_ONLINE = require('../../../assets/images/svg/share_22px.svg')
const BURGER_MENU = "burger-menu";

const userActive = (user, updateUser) => {
	// If user not logged in:
	if (!user.id) return;
	if (!user.activeInPortal) {
		updateUser(user);
		return;
	}
	// Update user.activeInPortal, but no need to do it more than once per hour.
	// Use to send email to user after being away for some amount of time.
	const activeAgain = moment().diff(moment(user.activeInPortal), "hours") > 1;
	if (activeAgain) updateUser(user);
};

const openMemberfulModal = (user, dispatch) => {
	window.open("https://artworks.se/pro#erbjudanden");
	// Will send a Slack message.
	dispatch(artistProSubscribeWindowOpened(user, true));
};

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menusOpen: false,
			burgerMenuOpen: false,
			societyPremiumModalOpen: false,
		};
	}

	openMenu = (menu) => {
		this.setState({
			menusOpen: true,
			burgerMenuOpen: menu === BURGER_MENU,
		});
	};

	closeMenus = () => {
		this.setState({
			menusOpen: false,
			burgerMenuOpen: false,
		});
	};

	setResponsive = (responsive) => {
		appGlobals.setResponsiveDisplay(responsive);
	};

	getPageName = (pathName) => {
		switch (pathName) {
			case expression:
				break;
			default:
		}
	};

	toggleSocietyPremiumModal = () => {
		this.setState({
			societyPremiumModalOpen: !this.state.societyPremiumModalOpen,
		});
	};

	render() {
		const {
			handleLogout,
			authenticated,
			isAdmin,
			isAuthor,
			isSociety,
			isArtist,
			toggleGuide,
			webbLink,
			gallery,
			user,
			pathName,
			premium,
			updateUserActive,
			xmasCalendar,
			notifications,
			route,
			dispatch,
			t,
		} = this.props;
		const { menusOpen, burgerMenuOpen } = this.state;
		const isResponsive = appGlobals.useResponsive;

		let logoLink = authenticated ? "/dashboard" : "/";
		// Before an art society has created its gallery.
		if (isSociety && !gallery._id) logoLink = "/gallery";
		// const premium = isPremium(gallery)
		if (isSociety && !premium) logoLink = "/gallery";
		// Check for gallery._id, or Bas logo will show before Premium logo.
		// New society user will have gallery false until they create a gallery.
		// const showPremiumSymbol = isSociety && premium && (gallery._id || !user.gallery)
		const showPremiumSymbol = isSociety && premium;

		const premiumPage =
			pathName === "/newsletter" ||
			pathName === "/contacts" ||
			pathName === "/filearchive";

		let navText = "";
		switch (pathName) {
			case "/":
			case "/register":
			case "/register/gallery":
			case "/register/artsociety":
			case "/register/konstnarshuset":
				navText = t("login.registerTitle");
				break;
			case "/login":
				navText = t("login.loginTitle");
				break;
			case "/forgot":
				navText = t("login.forgotTitle");
				break;
			case "/for-artists":
				navText = t("login.forArtistTitle");
				break;
			default:
				break;
		}
		if (pathName.indexOf("/reset/") >= 0) {
			navText = t("login.updateTitle");
		}

		// Additional indication of current page
		let routeName = isAdmin ? route.adminName : route.userName;
		if (routeName === "undefined") routeName = "";

		const showActive =
			pathName.indexOf("/shows/") > -1 || pathName.indexOf("/events/") > -1
				? true
				: false;
		let subscribeButtonStyle = "navbar-subscribe-button";

		if (isSociety) subscribeButtonStyle += " gallery";

		return (
			<div>
				<div className="NavBar">
					{authenticated ? (
						<div>
							<div
								className="NavBar__inner"
								onClick={() => userActive(user, updateUserActive)}
							>
								{false && showPremiumSymbol && (
									<div
										className={`membership-symbol ${
											premium ? " is-premium" : ""
										}`}
									>
										{premium ? "Premium" : "Bas"}
									</div>
								)}
								{/*
  								<h1 className={'title'}><Link to={ isAdmin ? '/dashboards' : logoLink }>Artworks Portal</Link></h1>
  							*/}
								<h1 className={"title"}>
									<Link
										className="image"
										to="/dashboard"
										style={{ backgroundImage: `url(${LOGO})`, border: "0px" }}
									></Link>
								</h1>
								{isArtist && (
									<Link className="mobile-logo" to="/dashboard">
										<img src={LOGO_MOBILE} />
									</Link>
								)}
								{isSociety && !premium && (
									<button
										onClick={() => this.toggleSocietyPremiumModal()}
										target="_blank"
										className={subscribeButtonStyle}
									>
										Uppgradera
									</button>
								)}
								{/* (isSociety && !premium) &&
                  <a href="https://artworks.se/konstforening" target="_blank" className={ subscribeButtonStyle }>Uppgradera</a>
                */}
								{isSociety && premium && (
									<Link to="/gallery-settings" className={subscribeButtonStyle}>
										Premium
									</Link>
								)}
								<nav className="NavBar__nav">
									<div
										className={
											"NavBar__nav__links horizontal " +
											(isArtist ? "is-artist " : "") +
											(isResponsive ? "is-responsive" : "")
										}
									>
										{isArtist && !premium && (
											<button
												className="navbar-subscribe-button"
												onClick={() => openMemberfulModal(user, dispatch)}
											>
												{t("navbar.premium")}
												<i>PRO</i>
											</button>
										)}
										{isArtist && premium && (
											<button className="navbar-subscribe-button">
												<i>PRO</i>
											</button>
										)}
										{isSociety && routeName && (
											<div className="navbar-location-name">
												{t(`navbar.${routeName}`)}
											</div>
										)}
										<TRIANGLE
											className={
												"NavBar__nav__links__burgermenu-tail " +
												(burgerMenuOpen ? "is-visible" : "")
											}
										/>
										<BurgerButton
											isOpen={burgerMenuOpen}
											notifications={notifications.total || 0}
											open={() => this.openMenu(BURGER_MENU)}
											close={this.closeMenus}
										/>
									</div>
									{false && isSociety && (
										<Link activeClassName="active" to="/dashboard">
											{t("navbar.dashboard")}
										</Link>
									)}
									{!isAdmin && !isAuthor && !isSociety && !isArtist && (
										<div className="NavBar__nav__links fail-safe">
											<div className="fail-safe__link" onClick={handleLogout}>
												{t("logIn")}
											</div>
										</div>
									)}
								</nav>
							</div>
							<div className="NavBar__sideMenu">
								{/* (isAdmin || isSociety) &&
  								<button onClick={ toggleGuide }>Guide</button>
  							*/}
								{isArtist && (
									<div className="spacer" style={{ marginTop: "-24px" }} />
								)}
								<div className="NavBar__sideMenu__icons"></div>
							</div>
							<div className="menus">
								<div
									className={
										"menus__background " + (menusOpen ? " is-open" : "")
									}
									onClick={this.closeMenus}
								/>
								<div
									className={
										"menus__container " +
										(menusOpen ? " is-open " : "") +
										(!isArtist ? " is-notArtist" : "")
									}
									onClick={this.closeMenus}
								>
									<BurgerMenu
										isOpen={burgerMenuOpen}
										pathName={pathName}
										premium={premium}
										gallery={gallery}
										xmasCalendar={xmasCalendar}
										notifications={notifications}
										webbLink={webbLink}
										close={this.closeMenus}
										t={t}
									/>
								</div>
							</div>
							{false && premium && (
								<div className="navbar-ticker">
									<div className="navbar-ticker__content">
										{premiumPage ? (
											<span>Hela denna sektion är</span>
										) : (
											<span>
												BREAKING NEWS! Du är inloggad som premiumkund. Leta
												efter rosa bollar för premiumfunktionalitet.
											</span>
										)}
										<div
											className="premium-style"
											style={{ margin: "0 0 1px 5px" }}
										>
											<span>Premium</span>
										</div>
									</div>
								</div>
							)}
						</div>
					) : (
						<div
							className={
								"NavBar__inner " + (!authenticated ? "is-notauthenticated" : "")
							}
						>
							<Link to="/">
								<h1 className={"title"}>{navText}</h1>
							</Link>
						</div>
					)}
				</div>
				{/* {(isArtist || isSociety || isAdmin) && !__PRODUCTION__ &&
          <LatestSalesBanner />
        } */}
				{isSociety && !premium && (
					<div className="Phone-bar">
						{isSociety && (
							<p>
								{t("navbar.societyUpdate")}{" "}
								<a href="https://www.artworks.se/konstforening" target="_blank">
									<u>{t("navbar.societyUpdateLink")}</u>
								</a>
							</p>
						)}
					</div>
				)}

				{/* Update modal for art society membership. */}
				{this.state.societyPremiumModalOpen && (
					<GalleryMembershipModal
						gallery={gallery}
						user={user}
						close={() => this.toggleSocietyPremiumModal()}
						canTerminate={false}
					/>
				)}
			</div>
		);
	}
}

NavBar.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	authenticated: PropTypes.bool.isRequired,
};

export default connect(null)(NavBar);
