import React, { Component } from "react";
import { Field, Fields, reduxForm } from "redux-form";
import i18next from "i18next";
import TextInput from "../../components/FormInput/TextInput";
import Select from "../../components/FormInput/Select";
import CheckBox from "../../components/FormInput/CheckBox";
import ArtistPicker from "../../components/FormComponents/ArtistPicker/ArtistPicker";
import ReCAPTCHA from "../../components/ReCaptcha/recaptcha";
import {
	validateEmail,
	validatePassword,
	validatePhone,
	isTrue,
	notEmpty,
} from "../../utils/Validators";
import { TermsAndConditions } from "@artworkslab/sharedmodules/src/utils/DocumentUtil";

let RegisterForm = (props) => {
	const {
		handleSubmit,
		pristine,
		submitting,
		valid,
		mustAcceptTC,
		roleSet,
		selectedRole,
		signUpAsArtSociety,
		signUpAsGallery,
		signUpAsArtist,
		signUpForKonstnarshuset,
		captchaVerified,
		t,
	} = props;
	const captchaMustBeVerified = selectedRole === "author";
	const canSubmit =
		valid &&
		!pristine &&
		!submitting &&
		(captchaVerified || !captchaMustBeVerified);

	// Labels for checkbox, not used now.
	// const TC_HTML_AUTHOR = (<label htmlFor="">{t('login.iAgreeToArtworks')} <a style={{textDecoration: 'underline'}} target="_blank" href={ TermsAndConditions.galleryTerms(i18next.language) }>{t('login.termsAndConditions')}</a></label>)
	// const TC_HTML_ARTIST = (<label htmlFor="">{t('login.iAgreeToArtworks')} <a style={{textDecoration: 'underline'}} target="_blank" href={ TermsAndConditions.artistTerms(i18next.language) }>{t('login.termsAndConditions')}</a></label>)
	// const TC_HTML_ART_SOCIETY = (<label htmlFor="">{t('login.iAgreeToArtworks')} <a style={{textDecoration: 'underline'}} target="_blank" href={ TermsAndConditions.artSocietyTerms(i18next.language) }>{t('login.termsAndConditionsArtSociety')}</a></label>)

	const TC_HTML_AUTHOR = (
		<p>
			{t("login.byCreatingProfile")}{" "}
			<a
				style={{ textDecoration: "underline" }}
				target="_blank"
				href={TermsAndConditions.galleryTerms(i18next.language)}
			>
				{t("login.termsAndConditions")}
			</a>
		</p>
	);
	const TC_HTML_ARTIST = (
		<p>
			{t("login.byCreatingProfile")}{" "}
			<a
				style={{ textDecoration: "underline" }}
				target="_blank"
				href={TermsAndConditions.artistTerms(i18next.language)}
			>
				{t("login.termsAndConditions")}
			</a>
		</p>
	);
	const TC_HTML_ART_SOCIETY = (
		<p>
			{t("login.byCreatingProfile")}{" "}
			<a
				style={{ textDecoration: "underline" }}
				target="_blank"
				href={TermsAndConditions.artSocietyTerms(i18next.language)}
			>
				{t("login.termsAndConditionsArtSociety")}
			</a>
		</p>
	);

	let TC_HTML =
		selectedRole === "artist" || signUpAsArtist || signUpForKonstnarshuset
			? TC_HTML_ARTIST
			: TC_HTML_AUTHOR;
	if (selectedRole === "society" || signUpAsArtSociety)
		TC_HTML = TC_HTML_ART_SOCIETY;

	//Artist removed as they can only register as pro
	const ROLES = [
		{ value: "", name: t("login.galleristOrArtist") },
		{ value: "author", name: t("login.gallerist") },
		{ value: "https://artworks.se/konstforening", name: t("login.artSociety") },
		{ value: "https://artworks.se/pro#erbjudanden", name: t("login.artist") },
	];

	return (
		<form onSubmit={handleSubmit} className="responsive-form">
			{!signUpAsArtSociety &&
				!signUpAsGallery &&
				!signUpAsArtist &&
				!signUpForKonstnarshuset && (
					<div className="role-select">
						<Field
							name="role"
							component={Select}
							options={ROLES}
							validate={notEmpty}
							disabled={roleSet}
							required
							link
						/>
					</div>
				)}
			{(selectedRole === "artist" || signUpAsArtist) && (
				<div>
					<legend className="legend-nomargin">
						{t("login.existingArtists")}
					</legend>
					<Field
						name="connectToArtist"
						component={ArtistPicker}
						multi={false}
						canAdd={false}
						noPadding={true}
						fullWidth={true}
						searchForTagged={true}
					/>
				</div>
			)}
			{(selectedRole ||
				signUpAsArtSociety ||
				signUpAsGallery ||
				signUpAsArtist ||
				signUpForKonstnarshuset) && (
				<div>
					<Field
						name="name"
						component={TextInput}
						placeholder={t("fullName")}
						validate={notEmpty}
						required
					/>
					<Field
						name="email"
						component={TextInput}
						placeholder={t("email")}
						validate={[validateEmail, notEmpty]}
						required
					/>
					<Field
						name="phone"
						component={TextInput}
						placeholder={t("common:phoneNumber")}
						validate={validatePhone}
						required
					/>
					<Field
						name="password"
						type="password"
						component={TextInput}
						placeholder={t("password")}
						validate={[validatePassword, notEmpty]}
						required
					/>
				</div>
			)}
			{(signUpAsGallery || selectedRole === "author") && (
				<Field
					name="galleryName"
					component={TextInput}
					placeholder={t("login.galleryName")}
					validate={notEmpty}
					required
				/>
			)}
			{(signUpAsArtSociety || selectedRole === "society") && (
				<Field
					name="galleryName"
					component={TextInput}
					placeholder={t("societyRegister")}
					validate={notEmpty}
					required
				/>
			)}
			{/* ((mustAcceptTC && selectedRole) || (signUpAsArtSociety || signUpAsGallery || signUpAsArtist || signUpForKonstnarshuset)) ?
        <Field
          name="acceptedTC"
          component={ CheckBox }
          checkboxLabel={ TC_HTML }
          validate={ isTrue }
          invalidText={ signUpAsArtSociety ? ' ' : t('login.tocInvalid')}
          hideInvalid={ true }
          required
          />
      : null */}
			{((mustAcceptTC && selectedRole) ||
				signUpAsArtSociety ||
				signUpAsGallery ||
				signUpAsArtist ||
				signUpForKonstnarshuset) && (
				<div style={{ marginBottom: "20px" }}>{TC_HTML}</div>
			)}
			{captchaMustBeVerified && (
				<div style={{ paddingBottom: "20px" }}>
					<ReCAPTCHA
						siteKey={process.env.CAPTCHA_SITE_KEY}
						onVerify={props.onVerifyCaptcha}
						onExpire={props.onExpireCaptcha}
						size={"normal"}
						theme={"light"}
					/>
				</div>
			)}
			{(selectedRole ||
				signUpAsArtSociety ||
				signUpAsGallery ||
				signUpAsArtist ||
				signUpForKonstnarshuset) && (
				<button
					className="purple-button--wide button-nomargin"
					type="submit"
					disabled={!canSubmit}
				>
					{t("login.save")}
				</button>
			)}
		</form>
	);
};

RegisterForm = reduxForm({
	form: "register",
})(RegisterForm);

export default RegisterForm;
