import React, { Component } from "react";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	formValueSelector,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import { updateUser } from "@artworkslab/sharedmodules/src/actions/UserActions";
import { syncUser, populateUser } from "../../actions/UserActions";
import { saveArtist } from "../../actions/ArtistActions";
import { syncAuth } from "@artworkslab/sharedmodules/src/actions/AuthActions";
import ConvertUserForm from "../Register/ConvertUserForm";

class UserDashboard extends Component {
	constructor(props) {
		super(props);
	}

	handleSubmit = () => {
		const {
			form: { register },
		} = this.props;
		this.props
			.dispatch(updateUser(register))
			.then(({ response }) => {
				/* TODO: Change to sharedmodules userReducer */
				this.props.dispatch(syncAuth());
				this.props.dispatch(syncUser(response.id || response._id));
				this.props.dispatch(populateUser(response));
			})
			.catch((err) => {
				console.log("error", err);
			});
	};

	render() {
		const {
			form: { mustAcceptTC, selectedRole },
			t,
		} = this.props;
		return (
			<div className="user-dashboard">
				<h1>{t("login.welcome")}</h1>
				<p>{t("login.choseRoleToContinue")}</p>
				<ConvertUserForm
					mustAcceptTC={mustAcceptTC}
					onSubmit={this.handleSubmit}
					selectedRole={selectedRole}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { authenticated },
	} = state;
	const form = {
		submitting: isSubmitting("convertUser")(state),
		submitSucceeded: hasSubmitSucceeded("convertUser")(state),
		submitFailed: hasSubmitFailed("convertUser")(state),
		register: getFormValues("convertUser")(state),
		initial: getFormInitialValues("convertUser")(state),
		// mustAcceptTC: formValueSelector('convertUser')(state, 'role') === 'author',
		mustAcceptTC: true,
		selectedRole: formValueSelector("convertUser")(state, "role"),
	};
	return { form, authenticated };
}

const translated = withNamespaces("common")(UserDashboard);
export default connect(mapStateToProps)(translated);
