import {
  SCRAPED_SHOWS_REQUEST, SCRAPED_SHOWS_FAILURE, SCRAPED_SHOWS_SUCCESS,
  SCRAPED_SHOW_REQUEST, SCRAPED_SHOW_FAILURE, SCRAPED_SHOW_SUCCESS,
  ACCEPT_SCRAPED_SHOW_REQUEST, ACCEPT_SCRAPED_SHOW_FAILURE, ACCEPT_SCRAPED_SHOW_SUCCESS,
  REJECT_SCRAPED_SHOW_REQUEST, REJECT_SCRAPED_SHOW_FAILURE, REJECT_SCRAPED_SHOW_SUCCESS,
  SCRAPED_ARTISTS_REQUEST, SCRAPED_ARTISTS_FAILURE, SCRAPED_ARTISTS_SUCCESS,
	SCRAPED_ARTIST_REQUEST, SCRAPED_ARTIST_FAILURE, SCRAPED_ARTIST_SUCCESS,
  ACCEPT_SCRAPED_ARTIST_REQUEST, ACCEPT_SCRAPED_ARTIST_FAILURE, ACCEPT_SCRAPED_ARTIST_SUCCESS,
  REJECT_SCRAPED_ARTIST_REQUEST, REJECT_SCRAPED_ARTIST_FAILURE, REJECT_SCRAPED_ARTIST_SUCCESS,
  SCRAPED_GALLERIES_REQUEST, SCRAPED_GALLERIES_FAILURE, SCRAPED_GALLERIES_SUCCESS,
	SCRAPED_GALLERY_REQUEST, SCRAPED_GALLERY_FAILURE, SCRAPED_GALLERY_SUCCESS,
  ACCEPT_SCRAPED_GALLERY_REQUEST, ACCEPT_SCRAPED_GALLERY_FAILURE, ACCEPT_SCRAPED_GALLERY_SUCCESS,
  REJECT_SCRAPED_GALLERY_REQUEST, REJECT_SCRAPED_GALLERY_FAILURE, REJECT_SCRAPED_GALLERY_SUCCESS,
} from '../actions/ScrapedActions'

export function scrapedReducer(state = {
		isFetching: false,
    scrapedShows: {},
    scrapedGalleries: {},
		scrapedArtists: {},
	}, action) {
	switch (action.type) {
		case SCRAPED_SHOWS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})
		case SCRAPED_SHOWS_SUCCESS: {
			let scrapedShows = {}
			action.response.map((show) => {
				scrapedShows[show._id] = show
			})
			return Object.assign({}, state, {
				isFetching: false,
				scrapedShows: scrapedShows,
				error: null
			})
		}
		case SCRAPED_SHOWS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
    case SCRAPED_SHOW_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SCRAPED_SHOW_SUCCESS: {
      let scrapedShows = Object.assign({}, state.scrapedShows)
      const show = action.response
      scrapedShows[show._id] = show
      return Object.assign({}, state, {
        isFetching: false,
        scrapedShows: scrapedShows,
        error: null
      })
    }
    case SCRAPED_SHOW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    case ACCEPT_SCRAPED_SHOW_REQUEST:
    case REJECT_SCRAPED_SHOW_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case ACCEPT_SCRAPED_SHOW_SUCCESS:
    case REJECT_SCRAPED_SHOW_SUCCESS:
      let scrapedShows = Object.assign({}, state.scrapedShows)
      const show = action.response
      scrapedShows[show._id] = show
      return Object.assign({}, state, {
        isFetching: false,
        scrapedShows: scrapedShows,
        error: null
      })
    case ACCEPT_SCRAPED_SHOW_FAILURE:
    case REJECT_SCRAPED_SHOW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    case SCRAPED_ARTISTS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})
		case SCRAPED_ARTISTS_SUCCESS: {
			let scrapedArtists = {}
			action.response.map((artist) => {
				scrapedArtists[artist._id] = artist
			})
			return Object.assign({}, state, {
				isFetching: false,
				scrapedArtists: scrapedArtists,
				error: null
			})
		}
		case SCRAPED_ARTISTS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
    case SCRAPED_ARTIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SCRAPED_ARTIST_SUCCESS: {
      let scrapedArtists = Object.assign({}, state.scrapedArtists)
      const artist = action.response
      scrapedArtists[artist._id] = artist
      return Object.assign({}, state, {
        isFetching: false,
        scrapedArtists: scrapedArtists,
        error: null
      })
    }
    case SCRAPED_ARTIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    case ACCEPT_SCRAPED_ARTIST_REQUEST:
    case REJECT_SCRAPED_ARTIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case ACCEPT_SCRAPED_ARTIST_SUCCESS:
    case REJECT_SCRAPED_ARTIST_SUCCESS:
      let scrapedArtists = Object.assign({}, state.scrapedArtists)
      const artist = action.response
      scrapedArtists[artist._id] = artist
      return Object.assign({}, state, {
        isFetching: false,
        scrapedArtists: scrapedArtists,
        error: null
      })
    case ACCEPT_SCRAPED_ARTIST_FAILURE:
    case REJECT_SCRAPED_ARTIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
    case SCRAPED_GALLERIES_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})
		case SCRAPED_GALLERIES_SUCCESS: {
			let scrapedGalleries = {}
			action.response.map((gallery) => {
				scrapedGalleries[gallery._id] = gallery
			})
			return Object.assign({}, state, {
				isFetching: false,
				scrapedGalleries: scrapedGalleries,
				error: null
			})
		}
		case SCRAPED_GALLERIES_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
    case ACCEPT_SCRAPED_GALLERY_REQUEST:
    case REJECT_SCRAPED_GALLERY_REQUEST:
    case SCRAPED_GALLERY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case ACCEPT_SCRAPED_GALLERY_SUCCESS:
    case REJECT_SCRAPED_GALLERY_SUCCESS:
    case SCRAPED_GALLERY_SUCCESS:
      let scrapedGalleries = Object.assign({}, state.scrapedGalleries)
      const gallery = action.response
      scrapedGalleries[gallery._id] = gallery
      return Object.assign({}, state, {
        isFetching: false,
        scrapedGalleries: scrapedGalleries,
        error: null
      })
    case ACCEPT_SCRAPED_GALLERY_FAILURE:
    case REJECT_SCRAPED_GALLERY_FAILURE:
    case SCRAPED_GALLERY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.message
      })
		default:
			return state
	}
}
