import React from "react";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import { dateMonthShort } from "@artworkslab/sharedmodules/src/DateUtil";
import {
	fetchInvoices,
	fetchInvoicePreview,
	fetchFile,
} from "../../actions/BillectaActions";
const PDF_ICON = require("../../../assets/images/icons/fileArchive/pdf.svg");

// getFile and getPreview does the same thing, both get an invoice as a pdf.
const getFile = (invoice, dispatch) => {
	dispatch(fetchFile(invoice.filePublicId));
};

const getPreview = (invoice, dispatch) => {
	dispatch(fetchInvoicePreview(invoice.invoiceId));
};

const createRows = (invoices, language, dispatch, datePassed, t) => {
	return invoices.map((invoice) => {
		const isPassedDate = datePassed(invoice.dueDate);
		const debtorName = invoice.galleryDebtor
			? invoice.galleryDebtor.name
			: "N/A";

		return (
			<Tr key={invoice._id} className="invoices__row">
				<Td column="debtor" value={debtorName}>
					{debtorName}
				</Td>
				<Td column="amount" value={invoice.invoicedAmount}>
					{`${(invoice.invoicedAmount / 100).toFixed(2)} ${
						invoice.currencyCode
					}`}
				</Td>
				<Td column="attested" value={invoice.attestedDate}>
					{invoice.attestedDate
						? dateMonthShort(invoice.attestedDate, language, true)
						: t("notAttested")}
				</Td>
				<Td column="created" value={invoice.created_at}>
					{dateMonthShort(invoice.created_at, language, true)}
				</Td>
				<Td
					column="dueDate"
					value={invoice.dueDate}
					className={"invoices__data" + (isPassedDate ? " is-passed-date" : "")}
				>
					{dateMonthShort(invoice.dueDate, language, true)}
				</Td>
				{/* invoice.filePublicId only exists on attested invoices. */}
				{invoice.filePublicId && (
					<Td column="pdf">
						<PDF_ICON
							style={{ height: "25px", width: "auto", cursor: "pointer" }}
							onClick={() => getFile(invoice, dispatch)}
							data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
						/>
					</Td>
				)}
			</Tr>
		);
	});
};

const Invoices = ({ invoices, language, isAdmin, dispatch, datePassed, t }) => {
	const rows = createRows(invoices, language, dispatch, datePassed, t);
	return (
		<div className="invoices">
			<h3 className="invoices__header">{t("invoices")}</h3>
			<Table
				className="reactable"
				sortable={["debtor", "amount", "attested", "created", "dueDate"]}
				filterable={
					isAdmin
						? ["debtor", "amount", "attested", "created", "dueDate"]
						: false
				}
				filterPlaceholder={t("searchInvoices")}
				itemsPerPage={50}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="debtor">{t("customer")}</Th>
					<Th column="amount">{t("amount")}</Th>
					<Th column="attested">{t("attested")}</Th>
					<Th column="created">{t("created")}</Th>
					<Th column="dueDate">{t("dueDate")}</Th>
					<Th column="pdf">{` `}</Th>
				</Thead>
				{rows}
			</Table>
		</div>
	);
};

export default Invoices;
