import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router";
import Spinner from "react-spinkit";
import Select from "react-select";
import Main from "../../components/Main/Main";
import Visits from "../../components/DashboardComponents/Visits/Visits";
import PopularOnArtworks from "../../components/DashboardComponents/PopularOnAw/PopularOnAw";
import Header from "../../components/DashboardComponents/Header/Header";
import PopularArtworks from "../../components/DashboardComponents/PopularArtworks/PopularArtworks";
import MostPopularArtworks from "../../components/DashboardComponents/PopularArtworks/MostPopularArtworks";
import Summary from "../../components/DashboardComponents/Summary/Summary";
import ArtistNewsFeed from "../../components/DashboardComponents/NewsFeed/ArtistNewsFeed";
import Banner from "../../components/DashboardComponents/NewsFeed/Banner";
import CurrentShow from "../../components/DashboardComponents/CurrentShow/CurrentShow";
import LatestSales from "../../components/DashboardComponents/LatestSales/LatestSales";
import ArtistProModals from "../../components/Modal/Custom/ArtistProModals";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { scrollToSection } from "../../utils/Helpers";
import { fetchSelections } from "../../actions/SelectionActions";
import { fetchAllArtistsFast, fetchArtist } from "../../actions/ArtistActions";

const NEWSFEED_SLUG = "newsfeed";

class ArtistDashboard extends React.Component {
	/**
	 * defaultProps
	 */
	static defaultProps = {
		isAdmin: false,
	};

	static propTypes = {
		isAdmin: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			artistOptions: [],
			dropdownOptions: [],
			selectedArtist: null,
		};
	}

	componentDidMount() {
		const { user, artist, selection, isAdmin, allArtists, hash } = this.props;
		const welcomedArtist = localStorage.getItem("welcomedArtist");
		if (!user.activeInPortal && !welcomedArtist) {
			this.props.router.push("/artists/me");
		}
		if (!selection._id || selection.slug !== NEWSFEED_SLUG) {
			this.props.dispatch(fetchSelections(NEWSFEED_SLUG));
		}

		//Fetch all artist if user is admin
		if (Object.keys(allArtists).length === 0 && isAdmin) {
			this.props.dispatch(fetchAllArtistsFast());
		}

		//Scroll if there is a hash
		if (hash) {
			setTimeout(() => {
				scrollToSection(hash);
			}, 1000);
		}
	}

	componentWillReceiveProps(nextProps) {
		//Handles artist selector
		const { isAdmin, allArtists } = nextProps;
		const { selectedArtist } = this.state;
		const artistKeys = Object.keys(allArtists);
		let artistOptions = this.state.artistOptions;
		let updateOptions = false;

		//Update list if we are missing artists
		if (artistOptions.length !== artistKeys.length) {
			artistOptions = [];
			artistOptions.push({
				value: "All",
				label: "All",
				type: "artist",
			});
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				artistOptions.push({
					value: artist._id,
					label: artist.name,
					type: "artist",
				});
			});
			updateOptions = true;
		}

		//Update list if something changed
		if (updateOptions) {
			this.setState({
				artistOptions,
				dropdownOptions: artistOptions.sort(this.sortOptions),
			});
		}

		//Redirect base artists
		const thisArtistId = this.props.artist ? this.props.artist._id : false;
		const nextArtistId = nextProps.artist ? nextProps.artist._id : false;
		if (
			thisArtistId !== nextArtistId &&
			nextArtistId &&
			!isPremium(nextProps.artist) &&
			!isAdmin
		) {
			this.props.router.push("/artworks");
		}

		//Scroll if new hash
		if (this.props.hash !== nextProps.hash) {
			scrollToSection(nextProps.hash);
		}
	}

	//Action when selecting artist
	selectArtist = (artist) => {
		this.setState({
			selectedArtist: artist,
		});
		this.props.dispatch(fetchArtist(artist.value));
	};

	//Renders dropdown artist-selector for admins
	renderArtistSelector = (isAdmin) => {
		const { fetchingArtist, t } = this.props;
		const { dropdownOptions, selectedArtist } = this.state;

		if (!isAdmin) return null;
		return (
			<div>
				<Select
					value={selectedArtist}
					options={dropdownOptions}
					onChange={this.selectArtist}
					onInputChange={() => {}}
					valueKey={"artist"}
					labelKey="label"
					backspaceToRemoveMessage=""
					backspaceRemoves={false}
					isLoading={fetchingArtist}
					placeholder={t("artworks:list.artist")}
					mulit={false}
					autosize={false}
					className={"gallery-picker-select"}
					isSearchable={true}
				/>
			</div>
		);
	};

	openMemberfulModal = () => {
		window.open("", "newWindow", "width=450px,height=650px");
	};

	render() {
		const { artist, user, isAdmin, selection, isFetching, t } = this.props;

		const artistIsPremium = isPremium(artist);
		const loaded = artist._id ? true : false;

		const mainClass = artistIsPremium ? null : "artist-dashboard-main";

		const premiumModal =
			!artistIsPremium && loaded ? <ArtistProModals type="dashboard" /> : "";

		//Render admin selectior
		const selector = this.renderArtistSelector(isAdmin);

		return (
			<div>
				<Main preChildren={premiumModal} className={mainClass}>
					{isAdmin && selector}
					{loaded && artist && (
						<div style={{ padding: "0px 40px" }}>
							<Header role={"artist"} t={t} />
							<Visits role={"artist"} t={t} />
							<PopularArtworks t={t} />
							<MostPopularArtworks t={t} />
							<CurrentShow type={"artist"} />
							<div id="latest-sales">
								<LatestSales />
							</div>
							<ArtistNewsFeed selection={selection} />
							{isFetching && (
								<div className="App__loader">
									<Spinner spinnerName="cube-grid" noFadeIn />
								</div>
							)}
						</div>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artist: { artist, allArtists, isFetching: fetchingArtist },
		auth: { user, isFetching: authFetching },
		artwork: { isFetching: artFetching },
		selection: { isFetching: selectFetching, selection },
		summary: { isFetching: sumFetching },
		visits: { isFetching: visitsFetching },
		mostPopular: { isFetching: mostFetching },
		popularArtwork: { isFetching: popFetching },
	} = state;

	const isAdmin = user.role === "admin";
	const isFetching =
		authFetching ||
		artFetching ||
		selectFetching ||
		sumFetching ||
		visitsFetching ||
		mostFetching ||
		popFetching ||
		fetchingArtist;

	return {
		artist,
		user,
		isAdmin,
		selection,
		allArtists,
		isFetching,
		fetchingArtist,
	};
}

const translated = withNamespaces("dashboard")(ArtistDashboard);
export default connect(mapStateToProps)(withRouter(translated));
