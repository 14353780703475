import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const ARROW = require("../../../../assets/images/svg/arrow_right.svg");

class TransactionModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmedDelivery: false,
		};
	}

	toggleConfirm = () => {
		this.setState({
			confirmedDelivery: !this.state.confirmedDelivery,
		});
	};

	renderBuyerDetails = (buyerAddress) => {
		return (
			<p style={{ opacity: "1" }}>
				{buyerAddress.name ? `${buyerAddress.name}` : null}
				<br />
				{buyerAddress.street ? `${buyerAddress.street}, ` : null}
				{buyerAddress.postalCode ? `${buyerAddress.postalCode} ` : null}
				{buyerAddress.city ? buyerAddress.city : null}
				<br />
				{buyerAddress.country ? buyerAddress.country : ""}
			</p>
		);
	};

	render() {
		const { transaction, t } = this.props;
		const { confirmedDelivery } = this.state;
		//Details for creating an invoice
		const artworkId = transaction.artwork._id || transaction.artwork || null;
		const artworkTitle =
			transaction.artwork && transaction.artwork.title
				? transaction.artwork.title
				: "";
		const invoiceLink = `artwork/invoice?artworkId=${artworkId}&transId=${transaction._id}`;
		const buyerDetails = this.renderBuyerDetails(transaction.buyerAddress);

		return (
			<div className="TransactionModal">
				<div className="TransactionModal__container">
					<div className="TransactionModal__content">
						<div
							className="step-back-button"
							onClick={() => this.props.close()}
						>
							<ARROW />
							<span>{t("back")}</span>
						</div>
						<div className="TransactionModal__content__section">
							<p>
								{artworkTitle}
								<br />
								{DateUtil.dateMonthShort(
									transaction.created_at,
									i18next.language,
									true
								)}
							</p>
						</div>
						<div className="TransactionModal__content__section">
							<p>
								<b>{t("trans.delivery")}</b>
								<br />
								{transaction.selectedShipping}
							</p>
						</div>
						<div className="TransactionModal__content__section">
							<p>
								<b>{t("trans.customerDetails")}</b>
								<br />
								{buyerDetails}
							</p>
						</div>
						<div className="TransactionModal__content__section">
							<p>
								<b>{t("trans.next")}</b>
								<br />
								<input
									type="checkbox"
									id="invoice-checkbox"
									ref={(ref) => (this.invoiceCheckbox = ref)}
									onClick={() => this.toggleConfirm()}
									checked={confirmedDelivery}
								/>
								<label htmlFor="invoice-checkbox">{t("trans.invoice")}</label>
								<br />
								<Link
									to={invoiceLink}
									className={!confirmedDelivery ? "disabled" : ""}
								>
									{t("trans.sendInvoice")}
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const translated = withNamespaces("transaction")(TransactionModal);
export default connect(null)(withRouter(translated));
