import React from "react";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import {
	hasSubmitSucceeded,
	getFormInitialValues,
	getFormValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import {
	fetchArtworksWithQuery,
	deleteArtwork,
} from "../../actions/ArtworkActions";
import {
	showArtworkModal,
	hideArtworkModal,
	submitArtwork,
} from "../../actions/FormActions/ArtworkFormActions";
import ArtworkForm from "../../views/Artworks/ArtworkForm";
import { addItem } from "../../actions/SelectionActions";
import Modal from "../Modal/Modal";
import SwitchButton from "../../components/Buttons/SwitchButton/SwitchButton";
import { getFirstImage, thumbnailError } from "../../utils/Helpers";

const BIN_SVG = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");

class PublicPurchaserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// Keep artworks in list until page reload. selection.artworks updated immediately when removing from selection.
			recentlyRemoved: [],
		};
	}

	componentWillMount() {
		this.props.dispatch(
			fetchArtworksWithQuery({
				$or: [
					{ forPublicPurchaserRequested: true },
					{ forPublicPurchaser: true },
					{ forPublicPurchaserOnly: true },
				],
			})
		);
	}

	componentWillReceiveProps(nextProps) {}

	removeArtwork = (artwork, t) => {
		let confirm = window.confirm(t("list.areYouSure"));
		if (confirm) {
			this.props.dispatch(deleteArtwork(artwork));
		}
	};

	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};

	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};

	addItemToSelection = (selectionId, listName, artwork, doAdd) => {
		this.props.dispatch(addItem(selectionId, listName, artwork._id, doAdd));

		if (!doAdd) {
			const removedArtwork = Object.assign({}, artwork, {
				recentlyRemoved: true,
			});
			this.setState({
				recentlyRemoved: this.state.recentlyRemoved.concat(removedArtwork),
			});
		}
	};

	createRows = (artworks, selection, t) => {
		const isSwedish = i18next.language === "sv";

		const rows = [];
		const iconStyle = Object.assign(
			{},
			{
				height: "25px",
				width: "auto",
				cursor: "pointer",
			}
		);

		const addedArtworkIds = selection
			? selection.artworks.map((artwork) => artwork._id)
			: [];
		// console.log('selection', selection);
		// console.log('addedArtworkIds', addedArtworkIds);

		Object.keys(artworks).forEach((key) => {
			const artwork = artworks[key];
			const image = getFirstImage(artwork);

			const smallThumbnail = image.thumbnails
				? image.thumbnails["100x100"].src
				: image.src;
			const largeThumbnail = image.thumbnails
				? image.thumbnails["750x"].src
				: image.src;

			const artistName = artwork.artistMod ? artwork.artistMod.name : "";

			let publicPurchaserValue = 0;
			if (
				artwork.forPublicPurchaserRequested &&
				!artwork.forPublicPurchaser &&
				!artwork.forPublicPurchaserOnly
			) {
				publicPurchaserValue = 1;
			} else if (
				artwork.forPublicPurchaserRequested &&
				(artwork.forPublicPurchaser || artwork.forPublicPurchaserOnly)
			) {
				publicPurchaserValue = 2;
			}

			const addedToSelection = addedArtworkIds.indexOf(artwork._id) >= 0;

			const willBeRemovedOnReload =
				!artwork.forPublicPurchaserRequested &&
				!artwork.forPublicPurchaser &&
				!artwork.forPublicPurchaserOnly &&
				!addedToSelection;

			rows.push(
				<Tr
					key={artwork._id}
					className={
						"list-and-upload__reactable__tr" +
						(willBeRemovedOnReload ? " is-removed_on_reload" : "")
					}
				>
					<Td
						column="publishStatus"
						value={artwork.publish_status}
						style={
							artwork.publish_status === "published"
								? { cursor: "pointer", color: "#059b05" }
								: { cursor: "pointer", color: "#a4a4a4" }
						}
					>
						<div onClick={() => this.props.dispatch(showArtworkModal(artwork))}>
							{t(`single.${artwork.publish_status}`)}
						</div>
					</Td>
					{/* Clicking switch button only opens form as popup. */}
					<Td column="forPublicPurchaser" value={publicPurchaserValue}>
						<SwitchButton
							isOn={
								artwork.forPublicPurchaserRequested ||
								artwork.forPublicPurchaser ||
								artwork.forPublicPurchaserOnly
							}
							switched={() => this.props.dispatch(showArtworkModal(artwork))}
							disabled={false}
							isWaiting={
								artwork.forPublicPurchaserRequested &&
								!artwork.forPublicPurchaser &&
								!artwork.forPublicPurchaserOnly
							}
							markerOnly={false}
						/>
					</Td>
					<Td
						column="forPublicPurchaserOnly"
						value={artwork.forPublicPurchaserOnly ? true : false}
					>
						<SwitchButton
							isOn={artwork.forPublicPurchaserOnly}
							switched={() => this.props.dispatch(showArtworkModal(artwork))}
							disabled={false}
							isWaiting={false}
							markerOnly={false}
						/>
					</Td>
					{selection && (
						<Td column="addToSelection" value={addedToSelection}>
							<SwitchButton
								isOn={addedToSelection}
								switched={() =>
									this.addItemToSelection(
										selection._id,
										"artworks",
										artwork,
										!addedToSelection
									)
								}
								disabled={false}
								isWaiting={false}
								markerOnly={false}
								styling={{ marginRight: "20px" }}
							/>
						</Td>
					)}
					<Td column="image" style={{ padding: 0, width: 70 }}>
						{smallThumbnail && (
							<img
								src={smallThumbnail}
								style={{ width: "60px", cursor: "pointer" }}
								onError={(e) => thumbnailError(e, e.target.src, image.src)}
								alt="image of artwork"
							/>
						)}
					</Td>
					<Td column="title" value={artwork.title}>
						<div style={{ display: "flex", flexFlow: "row" }}>
							<div
								onClick={() => this.props.dispatch(showArtworkModal(artwork))}
								style={{
									cursor: "pointer",
									minWidth: "70px", // Width and height to make empty field clickable.
									minHeight: "19px",
								}}
							>
								{artwork.title}
							</div>
						</div>
					</Td>
					<Td column="artist" value={artistName}>
						{artistName}
					</Td>
					<Td column="addInfo" value={"addInfo"}>
						<div
							style={{ cursor: "pointer", textDecoration: "underline" }}
							onClick={() => this.props.dispatch(showArtworkModal(artwork))}
						>
							{t("list.addMoreInfo")}
						</div>
					</Td>
					{/* Can't put ternary operator inside Td, it will display "[object Object]" or "false". */}
					<Td column="created" value={artwork.created_at}>
						<div
							onClick={() => this.props.dispatch(showArtworkModal(artwork))}
							style={{ cursor: "pointer" }}
						>
							{DateUtil.dateMonthShort(
								artwork.created_at,
								i18next.language,
								true
							)}
						</div>
					</Td>
					<Td column="delete" value={"delete"}>
						<BIN_SVG
							style={iconStyle}
							onClick={() => this.removeArtwork(artwork, t)}
							data-attribution="Font Awesome by Dave Gandy – http://fontawesome.io"
						/>
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const { isAdmin, artworks, selection, showArtworkModal, t } = this.props;
		const { recentlyRemoved } = this.state;
		// artworks come from query: forPublicPurchaserRequested/forPublicPurchaser/forPublicPurchaserOnly
		// Add artworks from selection to the other artworks.
		const allArtworks = Object.assign({}, artworks);
		selection.artworks.forEach((artwork) => {
			if (!allArtworks[artwork._id]) {
				allArtworks[artwork._id] = artwork;
			}
		});
		recentlyRemoved.forEach((artwork) => {
			if (!allArtworks[artwork._id]) {
				allArtworks[artwork._id] = artwork;
			}
		});

		let tableHead = <Thead></Thead>;
		tableHead = (
			<Thead>
				<Th column="publishStatus" style={{ width: "120px" }}>
					{t("list.status")}
				</Th>
				<Th column="forPublicPurchaser" style={{ whiteSpace: "wrap" }}>
					{t("list.showForPublicPurchaser")}
				</Th>
				<Th column="forPublicPurchaserOnly" style={{ whiteSpace: "wrap" }}>
					{t("list.showForPublicPurchaserOnly")}
				</Th>
				{selection && (
					<Th column="addToSelection">{t("list.addToSelection")}</Th>
				)}
				<Th column="image">{` `}</Th>
				<Th column="title">{` `}</Th>
				<Th column="artist">{t("list.artist")}</Th>
				<Th column="addInfo">{` `}</Th>
				<Th column="created" id="created">
					{t("list.createdAt")}
				</Th>
				{/* <Th column="delete">{` `}</Th> */}
			</Thead>
		);

		let rows = this.createRows(allArtworks, selection, t);

		return (
			<div className="list-and-upload">
				<Table
					className="reactable"
					sortable={[
						"publishStatus",
						"forPublicPurchaser",
						"forPublicPurchaserOnly",
						"addToSelection",
						"artist",
						"created",
					]}
					defaultSort={{ column: "created", direction: "desc" }}
					onSort={this.sortChanged}
					filterable={false}
					filterPlaceholder={"Search artwork"}
					itemsPerPage={20}
					pageButtonLimit={0}
				>
					{tableHead}
					{rows}
				</Table>
				{showArtworkModal && (
					<Modal
						onCloseClick={this.toggleArtworksModal}
						className="modal-with-sticky-footer"
						styling={{
							zIndex: 10000, // Put above save button in Selection.jsx
						}}
					>
						<ArtworkForm
							onSubmit={this.handleArtworkSubmit}
							isAdmin={isAdmin}
							isArtist={false}
							infoUpdate={true}
							artist={{}}
							header={t("list.addMoreInfo")}
						/>
					</Modal>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		artwork: { artworks },
		artworkForm: { showArtworkModal },
	} = state;

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};

	return {
		artworks,
		artworkForm,
		showArtworkModal,
	};
};

const translated = withNamespaces("artworks")(PublicPurchaserList);
export default connect(mapStateToProps)(translated);
