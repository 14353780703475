import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import { withRouter } from "react-router";
import { Link } from "react-router";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import {
	submitAccess,
	hideAccessModal,
} from "../../actions/FormActions/AccessFormActions";
import {
	submitShow,
	loadShow,
	removeShow,
} from "../../actions/FormActions/ShowFormActions";
import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
} from "../../actions/FormActions/ArtworkFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import { fetchShow } from "../../actions/ShowActions";
import {
	saveArtwork,
	fetchArtworksWithQuery,
} from "../../actions/ArtworkActions";
import Main from "../../components/Main/Main";
import Modal from "../../components/Modal/Modal";
import MetaButton from "../../components/MetaButton/MetaButton";
import InformMemberButton from "../../components/Buttons/InformMemberButton/InformMemberButton";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import ArtworksListAndUpload from "../../components/Artworks/ArtworksListAndUpload";
import { hasAccess } from "../../utils/Helpers";
import ArtistForm from "../Artist/ArtistForm";
import AccessForm from "../Access/AccessForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import ShowForm from "./ShowForm";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";
import {
	isPremium,
	isStandardOrAbove,
	hasAccessToFeature,
	AUTOMATIC_REMINDERS_FEATURE,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class EditShow extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		const showId = this.props.params.showId;
		this.props.dispatch(fetchShow(showId));
	}
	componentWillReceiveProps(newProps) {
		const { isArtist, isAdmin, user } = this.props;
		if (newProps.show.deletedShow) {
			if (isArtist) {
				return this.props.router.push("/activities");
			} else {
				return this.props.router.push("/shows");
			}
		}
		const { show: prevShow } = this.props.show;
		const { show: nextShow } = newProps.show;
		// Don't run this after updating in ArtworksListAndUpload, fetchArtworksWithQuery makes the whole page rerender.
		if (
			(prevShow._id !== nextShow._id && nextShow._id) ||
			(!this.props.deletedArtwork && newProps.deletedArtwork)
		) {
			const showGallery = nextShow.gallery || {};
			const galleryId = showGallery._id ? showGallery._id : showGallery;
			const showArtistsIds = nextShow.artists.map(
				(artist) => artist._id || artist
			);
			const artistId = user.artist._id || user.artist;
			// Used before also fetching artworks for artists:
			// this.props.dispatch( fetchArtworks(galleryId) )

			let dbQuery = {};
			if (isAdmin) {
				dbQuery = {
					artistMod: { $in: showArtistsIds },
				};
			} else if (isArtist) {
				dbQuery = {
					artistMod: { $in: [artistId] },
				};
			} else {
				dbQuery = {
					$or: [
						{ gallery: galleryId },
						// Show only artworks tagged for this gallery.
						// { artistMod: { $in: showArtistsIds } },
					],
				};
			}

			this.props.dispatch(fetchArtworksWithQuery(dbQuery));

			this.props.dispatch(loadShow(newProps.show.show));
		}
	}
	handleSubmit = (vernissageStart, vernissageEnd) => {
		const {
			form: { show, initial },
			t,
		} = this.props;
		show.vernissageStart = vernissageStart;
		show.vernissageEnd = vernissageEnd;
		if (show.artists.length === 0) {
			const confirm = window.confirm(t("single.noArtists"));
			if (confirm) {
				this.props.dispatch(submitShow(show, initial));
			}
		} else {
			this.props.dispatch(submitShow(show, initial));
		}
	};
	handleRemove = () => {
		const show = this.props.form.show;
		this.props.dispatch(removeShow(show));
	};
	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	handleAccessSubmit = (access) => {
		const showId = this.props.show.show._id || this.props.params.showId;
		this.props.dispatch(submitAccess(showId, access, "shows"));
	};

	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};
	toggleAccessModal = () => {
		this.props.dispatch(hideAccessModal());
	};

	render() {
		const { showArtworkModal } = this.props;
		const { showArtistModal } = this.props;
		const { showAccessModal } = this.props;
		const {
			isAdmin,
			isSociety,
			isArtist,
			isAuthor,
			show: {
				show,
				show: { endDate },
				isFetching,
			},
			user,
			artist,
			gallery,
			form,
			t,
		} = this.props;

		const isOver = moment(show.endDate).isBefore(moment());
		const isArtSociety = isVenueArtSociety(show.gallery);

		const artistId = user.artist._id || user.artist;
		const artistName = user.name;

		const organizerId = show.artistOrganizer
			? show.artistOrganizer._id
			: show.artistOrganizer;
		const isOrganizer = artistId === organizerId;

		const showVideo =
			form.show && form.show.videoLink ? form.show.videoLink : null;
		let premium = false;
		let automaticRemindersAvailable = false;
		if (isArtist) {
			premium = isPremium(artist);
		} else if (isSociety) {
			premium = isStandardOrAbove(gallery);
			automaticRemindersAvailable =
				premium || hasAccessToFeature(gallery, AUTOMATIC_REMINDERS_FEATURE);
		}

		if (!hasAccess(user, show)) return null;

		return (
			<div>
				<Main>
					<div style={{ marginBottom: "30px" }}>
						<Link
							to="/shows"
							className="purple-button--wide purple-button--wide--mod_create"
							style={{ minWidth: "180px" }}
						>
							{t("list.yourShows")}
						</Link>
					</div>
					<ShowForm
						handleSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isArtist={isArtist}
						isSociety={isSociety}
						artist={artist}
						gallery={gallery}
						isPremium={premium}
						showVideo={showVideo}
						automaticRemindersAvailable={automaticRemindersAvailable}
					/>
					{!isOver && isArtSociety && (
						<InformMemberButton
							item={show}
							type={"show"}
							isFetching={isFetching}
						/>
					)}
					{!isOver && !isArtSociety && isAdmin && (
						<InformMemberButton
							item={show}
							type={"show"}
							isFetching={isFetching}
							previewOnly={true}
						/>
					)}
					{isAdmin && <SelectionPicker type="shows" item={show} />}
					<legend style={{ marginTop: "50px" }}>
						{t("single.selectArtworks")}
					</legend>
					{show.gallery && !show.artistOrganizer && (
						<ArtworksListAndUpload
							isAdmin={isAdmin}
							isSociety={isSociety}
							galleryId={show.gallery._id}
							artistId={false}
							showDropZone={show.gallery._id}
							show={show}
							includeTagged={true}
							artistFormAlreadyOnPage={true}
						/>
					)}
					{show.artistOrganizer && (
						<ArtworksListAndUpload
							isAdmin={isAdmin}
							isSociety={false}
							galleryId={false}
							artistId={isAdmin ? organizerId : artistId}
							artistName={artistName}
							show={show}
							includeTagged={true}
							artistFormAlreadyOnPage={true}
						/>
					)}
					{(isAdmin || isSociety || isOrganizer || isAuthor) && (
						<div
							className="fieldset"
							style={{ paddingTop: 40, justifyContent: "space-between" }}
						>
							<legend>{t("common:dangerZone")}</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={() => {
									let confirm = window.confirm(t("common:areYouSure"));
									if (confirm) this.handleRemove();
								}}
							>
								{t("single.deleteShow")}
							</button>
							<MetaButton item={show} />
						</div>
					)}
				</Main>
				{/* ArtworkForm already in ArtworksListAndUpload. */}
				{/* showArtworkModal ?
					<Modal onCloseClick={this.toggleArtworksModal}>
						<ArtworkForm
							onSubmit={ this.handleArtworkSubmit }
							isAdmin={ isAdmin }
						/>
					</Modal>
				: null */}
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
							forArtSociety={gallery && gallery.venueType === "ArtSociety"}
						/>
					</Modal>
				) : null}
				{showAccessModal ? (
					<Modal onCloseClick={this.toggleAccessModal}>
						<AccessForm onSubmit={this.handleAccessSubmit} isAdmin={isAdmin} />
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		show,
		artwork: { deletedArtwork },
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		accessForm: { showAccessModal },
		auth: { isAdmin, user },
		artist: { artist },
		gallery: { gallery },
	} = state;
	const isSociety = user.role === "society";
	const isArtist = user.role === "artist";
	const isAuthor = user.role === "author";
	const form = {
		submitting: isSubmitting("show")(state),
		submitSucceeded: hasSubmitSucceeded("show")(state),
		submitFailed: hasSubmitFailed("show")(state),
		show: getFormValues("show")(state),
		initial: getFormInitialValues("show")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};

	return {
		show,
		deletedArtwork,
		isAdmin,
		isSociety,
		isArtist,
		isAuthor,
		user,
		artist,
		gallery,
		form,
		artworkForm,
		artistForm,
		showArtworkModal,
		showArtistModal,
		showAccessModal,
	};
}

// Need withNamespaces here or children (InformMemberButton) won't update on language change.
const translated = withNamespaces("show")(EditShow);
export default connect(mapStateToProps)(withRouter(translated));
