import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import FormMeta from "../../../components/Meta/FormMeta";
import Main from "../../../components/Main/Main";
import MainResp from "../../../components/Main/MainResp";
import EmailWriter from "../../../components/EmailsPageComponents/EmailWriter";

class ArtistsHelpCenter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { user, t } = this.props;

		const isResponsive = appGlobals.useResponsive;
		const MainClass = isResponsive ? MainResp : Main;
		const userId = user ? user.id : null;

		return (
			<div className="HelpCenter">
				<MainClass>
					<FormMeta text={t("video")} />
					<div style={{ marginBottom: "30px" }}>
						<iframe
							width="640"
							height="360"
							className="responsive-big-video"
							src="https://www.youtube.com/embed/0iqKD8sKfxk?rel=0&modestbranding=1&hl=sv"
							frameBorder="0"
							allowFullScreen
						></iframe>
					</div>
					<div className="outward-section">
						<div className="outward-section__item">
							<p>{t("faq")}</p>
							<a href="https://artworks.se/pro#faq" target="_blank">
								{t("faqLink")}
							</a>
						</div>
						<div className="outward-section__item">
							<p>{t("sales")}</p>
							<a href="https://artworks.se/pro#sales" target="_blank">
								{t("salesLink")}
							</a>
						</div>
					</div>
					{userId && (
						<EmailWriter
							userId={userId}
							componentHeader={t("email")}
							premium
							helpCenter
							t={t}
						/>
					)}
				</MainClass>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
	} = state;

	return { user };
};

const translated = withNamespaces("helpcenter")(ArtistsHelpCenter);
export default connect(mapStateToProps)(withRouter(translated));
