import React, { Component } from "react";
// import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";

const MAX_MIB = 204800;
const ACCEPT = "image/jpeg,image/jpg,image/png";
const CLOSE_CROSS = require("../../../assets/images/icons/close@2x.png");

// const propTypes = {
// 	defaultImage: PropTypes.any,
// };

const defaultProps = {
	defaultImage: false,
};

const validImage = (value, allValues, props) => {
	return undefined;
};

class LogoUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onDrop = (acceptedFiles, e) => {
		const reader = new FileReader();
		const {
			uploadedLogo: { URI, contentType, fileName },
			t,
		} = this.props;
		const uploadedFile = acceptedFiles[0];

		//Load preview
		reader.onload = (upload) => {
			// Uploaded image
			const image = {
				URI: upload.target.result,
				fileName: uploadedFile.name,
				contentType: uploadedFile.type,
			};

			// Set state for preview
			this.setState({
				previewImage: uploadedFile,
			});

			// Update input states
			URI.input.onChange(image.URI);
			contentType.input.onChange(image.contentType);
			fileName.input.onChange(image.fileName);
			URI.input.onBlur(image.URI);
			contentType.input.onBlur(image.contentType);
			fileName.input.onBlur(image.fileName);
		};

		// Read data file
		if (uploadedFile) {
			reader.readAsDataURL(uploadedFile);
		}
	};

	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;
		const rejectedFile = rejectedFiles[0];

		this.showImageError(rejectedFile, t);
	};

	showImageError = (rejectedFile, t) => {
		// console.log('REJECTED:', rejectedFile);
		if (rejectedFile.size > MAX_MIB) {
			window.alert(t("imageUpload.imageTooBig2"));
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("imageUpload.imageWrongFormat"));
		}
	};

	removeImage = () => {
		const {
			logo: { src },
			uploadedLogo: { URI, contentType, fileName },
			t,
		} = this.props;
		const confirm = window.confirm(t("imageUpload.removeAreYouSure"));
		if (confirm) {
			// Only src of logo will be set to null.
			// Other values remain and logo (with src = null) will be sent as params to API when saving.
			// Need to set src to null or image won't disappear from preview.
			src.input.onChange(null);
			src.input.onBlur(null);

			// When adding image and then removing it without saving first, this.props.uploadedLogo will still be there,
			// looks like this: { URI: ..., contentType: ..., fileName: ... }
			// Set URI to null and test for it in imageHelper.js -> setFeaturedImage() in API
			URI.input.onChange(null);
			URI.input.onBlur(null);
			contentType.input.onChange(null);
			contentType.input.onBlur(null);
			fileName.input.onChange(null);
			fileName.input.onBlur(null);

			this.setState({
				previewImage: null,
			});
		}
	};

	renderPreviewImage = (t) => {
		const { previewImage } = this.state;
		const logoImage = this.props.logo.src.input.value || null;
		const src = previewImage ? previewImage.preview : logoImage;
		// Styling img so logo can't be super heigh.
		if (src) {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-image">
						<img
							className="ImageUpload__preview-image-img"
							src={src}
							style={{
								width: "auto",
								height: "auto",
								maxWidth: "100%",
								maxHeight: "250px",
							}}
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<div>{t("imageUpload.previewHere")}</div>
					</div>
				</div>
			);
		}
	};

	render() {
		const { t } = this.props;
		const hasFeaturedImg = this.props.logo.src.input.value ? true : false;
		const hasUploadedImg =
			this.props.uploadedLogo.URI.input.value &&
			this.props.uploadedLogo.fileName.input.value &&
			this.props.uploadedLogo.contentType.input.value
				? true
				: false;
		const valid = hasFeaturedImg || hasUploadedImg;
		const error = this.props.logo.src.meta.error || false;
		const required = this.props.required ? true : false;
		return (
			<div
				className={
					"ImageUpload" +
					(error ? " is-error" : "") +
					(valid ? " is-valid" : "") +
					(required ? " is-required" : "")
				}
			>
				<div className="input-holder">
					<input type="hidden" name="uploadedLogo.URI" />
					<input type="hidden" name="uploadedLogo.fileName" />
					<input type="hidden" name="uploadedLogo.contentType" />
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.dropRejected}
						className={"ImageUpload__dropzone"}
						accept={ACCEPT}
						multiple={false}
						maxSize={MAX_MIB}
					>
						<div>{t("imageUpload.dropHere")}</div>
					</Dropzone>
					{error ? (
						<span className="Input__error" style={{ marginTop: "10px" }}>
							{error}
						</span>
					) : null}
				</div>
				<div className="input-holder">
					{this.renderPreviewImage(t)}
					<button
						className={"close-button"}
						onClick={this.removeImage}
						disabled={!hasFeaturedImg && !hasUploadedImg}
						style={{ backgroundImage: `url(${CLOSE_CROSS})` }}
						type="button"
					/>
				</div>
			</div>
		);
	}
}

export default withNamespaces("components")(LogoUpload);
