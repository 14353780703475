// https://github.com/auth0-blog/redux-auth/blob/master/middleware/api.js
const BASE_URL = process.env.API_BASE || 'http://localhost:4000/api/'
const NETWORK_ERROR = 'NETWORK_ERROR'

function callApi(endpoint, authenticated, method = "GET", body = null) {

	let token = localStorage.getItem('token') || null
	let config = {}

	if(authenticated) {
		if(token) {
			config = {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			}
		} else {
			throw "No token saved!"
		}
	} else {
		config = {
			headers: {
				'Content-Type': 'application/json'
			}
		}
	}

	config.method = method
	config.body = body ? JSON.stringify(body) : undefined

	return fetch(BASE_URL + endpoint, config)
		.then(response =>
			response.text()
			.then(text => ({ text, response }))
		).then(({ text, response }) => {
			if (!response.ok) {
				return Promise.reject(text)
			}
			return JSON.parse(text)
		})
		.catch(error => {
			let err = error.toString()
			try {
				err = JSON.parse(error).message
			} catch (e) {
				console.log('Cannot parse error: ', e);
				console.log('Unparsable error: ', err);
			}
			return Promise.reject({ message: err, type: NETWORK_ERROR})
		})
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {

	const callAPI = action[CALL_API]

	// So the middleware doesn't get applied to every single action
	if (typeof callAPI === 'undefined') {
		return next(action)
	}

	const { endpoint, types, authenticated, message, analytics, method, body} = callAPI

	const [ requestType, successType, errorType ] = types

	// start request
	next({type: requestType})

	// Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
	return callApi(endpoint, authenticated, method, body).then(
		response =>
			next({
				response,
				authenticated,
				type: successType,
				message,
				analytics
			}),
		error => next({
			error: error.message || 'There was an error.',
			type: errorType,
			network: (error.type === 'NETWORK_ERROR') ? NETWORK_ERROR : false
		})
	)
}
