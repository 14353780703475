import {
	POSTS_REQUEST, POSTS_FAILURE, POSTS_SUCCESS,
	POST_REQUEST, POST_FAILURE, POST_SUCCESS,
	POSTS_ALL_REQUEST, POSTS_ALL_FAILURE, POSTS_ALL_SUCCESS,
	POSTS_ALL_POPULATE_REQUEST, POSTS_ALL_POPULATE_SUCCESS, POSTS_ALL_POPULATE_FAILURE,
	POSTS_FOR_PICKER_REQUEST, POSTS_FOR_PICKER_SUCCESS, POSTS_FOR_PICKER_FAILURE,
	POST_SAVE_REQUEST, POST_SAVE_FAILURE, POST_SAVE_SUCCESS,
	POST_REMOVE_REQUEST, POST_REMOVE_FAILURE, POST_REMOVE_SUCCESS,
	INFORM_MEMBERS_POST_REQUEST, INFORM_MEMBERS_POST_SUCCESS, INFORM_MEMBERS_POST_FAILURE,
	POST_MAIL_PREVIEW_REQUEST, POST_MAIL_PREVIEW_SUCCESS, POST_MAIL_PREVIEW_FAILURE, POST_MAIL_PREVIEW_RESET,
	POST_IMAGE_REQUEST, POST_IMAGE_SUCCESS, POST_IMAGE_FAILURE,
	UPDATE_CAPTION_REQUEST, UPDATE_CAPTION_SUCCESS, UPDATE_CAPTION_FAILURE,
	UPDATE_POSTS_REQUEST, UPDATE_POSTS_SUCCESS, UPDATE_POSTS_FAILURE,
} from '../actions/PostActions'

export function postReducer(state = {
		isFetching: false,
		posts: {},
		post: {},
		allPosts: {},
		postsForPicker: {},
		authenticated: false,
		preview: ''
	}, action) {
	switch (action.type) {
		case POSTS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				deletedPost: false,
				posts: {},
			})
		}
		case POSTS_SUCCESS: {
			let posts = {}
			action.response.map((post) => {
				posts[post._id] = post
			})
			return Object.assign({}, state, {
				isFetching: false,
				posts: posts,
				error: null
			})
		}
		case POSTS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case POSTS_ALL_POPULATE_REQUEST:
		case UPDATE_POSTS_REQUEST:
		case POSTS_ALL_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case POSTS_ALL_SUCCESS: {
			let posts = {}
			action.response.map((post) => {
				posts[post._id] = post
			})
			return Object.assign({}, state, {
				isFetching: false,
				allPosts: posts,
			})
		}
		case POSTS_ALL_POPULATE_SUCCESS: {
			let posts = Object.assign({}, state.allPosts)
			action.response.map((post) => {
				posts[post._id] = post
			})
			return Object.assign({}, state, {
				isFetching: false,
				allPosts: posts,
			})
		}
		case UPDATE_POSTS_SUCCESS: {
			let posts = Object.assign({}, state.posts)
			let allPosts = Object.assign({}, state.allPosts)
			action.response.map((post) => {
				posts[post._id] = post
				allPosts[post._id] = post
			})
			return Object.assign({}, state, {
				isFetching: false,
				posts,
				allPosts,
			})
		}
		case POSTS_ALL_POPULATE_FAILURE:
		case UPDATE_POSTS_FAILURE:
		case POSTS_ALL_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error,
			})
		}
		case POST_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				post: {}
			})
		}
		case POST_SUCCESS: {
			const post = action.response
			let posts = Object.assign({}, state.posts)
			posts[post._id] = post
			return Object.assign({}, state, {
				isFetching: false,
				posts: posts,
				post: post
			})
		}
		case POST_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case UPDATE_CAPTION_REQUEST:
		case POST_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case POST_SAVE_SUCCESS: {
			const post = action.response
			let posts = Object.assign({}, state.posts)
			posts[post._id] = post
			return Object.assign({}, state, {
				isFetching: false,
				posts: posts
			})
		}
		case UPDATE_CAPTION_FAILURE:
		case POST_SAVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case UPDATE_CAPTION_SUCCESS: {
			const post = action.response
			let posts = Object.assign({}, state.posts)
			posts[post._id] = post
			return Object.assign({}, state, {
				isFetching: false,
				posts: posts
			})
		}
		case POST_REMOVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case POST_REMOVE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				deletedPost: true,
				error: null
			})
		}
		case POST_REMOVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case INFORM_MEMBERS_POST_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case INFORM_MEMBERS_POST_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				post: action.response,
			})
		}
		case INFORM_MEMBERS_POST_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informMembersError',
			})
		}
		case POST_MAIL_PREVIEW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				preview: '',
				error: null,
			})
		}
		case POST_MAIL_PREVIEW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				preview: action.response,
			})
		}
		case POST_MAIL_PREVIEW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informMembersError',
			})
		}
		case POST_MAIL_PREVIEW_RESET: {
			return Object.assign({}, state, {
				preview: '',
			})
		}
		case POST_IMAGE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case POST_IMAGE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
			})
		}
		case POST_IMAGE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
			})
		}
		case POSTS_FOR_PICKER_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case POSTS_FOR_PICKER_SUCCESS: {
			console.log('POSTS_FOR_PICKER_SUCCESS');
			let posts = {}
			action.response.map((post) => {
				posts[post._id] = post
			})
			return Object.assign({}, state, {
				postsForPicker: posts,
				isFetching: false,
			})
		}
		case POSTS_FOR_PICKER_FAILURE: {
			return Object.assign({}, state, {
				postsForPicker: {},
				isFetching: false,
			})
		}
		default:
			return state
	}
}
