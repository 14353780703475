import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import {
	getGalleryOptions,
	resetGalleryOptions,
} from "../../../actions/PickersActions";
import { hideGalleryModal } from "../../../actions/FormActions/GalleryFormActions";

class RepresentedByPicker extends Component {
	static propTypes = {
		multi: PropTypes.bool,
	};
	static defaultProps = {
		multi: false,
		placeholder: "Select venue..",
	};
	constructor(props) {
		super(props);
	}

	componentWillReceiveProps(newProps) {
		const { multi, input } = this.props;
		if (this.props.gallery.gallery != newProps.gallery.gallery) {
			const selectedGallery = [];
			if (multi && Array.isArray(input.value)) {
				selectedGallery.push.apply(selectedGallery, input.value);
				selectedGallery.push(newProps.gallery.gallery);
			} else {
				selectedGallery.push(newProps.gallery.gallery);
			}
			const selection = multi ? selectedGallery : selectedGallery[0];
			this.props.dispatch(hideGalleryModal());
			this.props.input.onChange(selection);
			this.props.input.onBlur(selection);
			this.props.dispatch(resetGalleryOptions(selection));
		}
	}

	selectGallery = (selection) => {
		// Update parent
		if (!selection) {
			this.props.input.onChange(null);
			this.props.input.onBlur(null);
			return;
		}
		const representedBy = [
			{
				location: "Sweden",
				gallery: selection._id,
				name: selection.name,
			},
		];
		this.props.input.onChange(representedBy);
		this.props.input.onBlur(representedBy);
	};

	searchGallery = (searchString) => {
		this.props.dispatch(getGalleryOptions(searchString));
	};

	onInputChange = (text) => {
		this.searchGallery(text);
	};

	render() {
		const {
			multi,
			placeholder,
			input: { value },
			isFetching,
			options,
			responsive = false,
		} = this.props;

		let selected = false;
		if (value[0] && value[0].gallery && value[0].gallery._id) {
			// On load, gallery is populated.
			selected = value[0].gallery;
		} else if (value[0]) {
			// After selecting an option, gallery is not populated, just an object from selectGallery() above.
			selected = value[0];
		}

		let classes = "input-holder input-holder--full";
		if (responsive) classes = "input-holder-r input-holder-r--full";

		return (
			<div className={classes}>
				<Select
					isLoading={isFetching}
					onChange={this.selectGallery}
					onInputChange={this.onInputChange}
					value={selected}
					options={options}
					valueKey="gallery"
					labelKey="name"
					placeholder={placeholder}
					multi={multi}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		gallery,
		pickers: {
			galleryPicker: { isFetching, options },
		},
	} = state;
	return { gallery, isFetching, options };
}

export default connect(mapStateToProps)(RepresentedByPicker);
