import {
	ARTIST_SEARCH_REQUEST, ARTIST_SEARCH_SUCCESS, ARTIST_SEARCH_FAILURE,
	RESET_ARTIST_OPTIONS,
	GALLERY_SEARCH_REQUEST, GALLERY_SEARCH_SUCCESS, GALLERY_SEARCH_FAILURE, ART_INSTITUTIONS_SUCCESS,
	RESET_GALLERY_OPTIONS,
	SHOW_SEARCH_REQUEST, SHOW_SEARCH_SUCCESS, SHOW_SEARCH_FAILURE,
	RESET_SHOW_OPTIONS,
	POST_SEARCH_REQUEST, POST_SEARCH_SUCCESS, POST_SEARCH_FAILURE,
	RESET_POST_OPTIONS,
	RESET_ALL_OPTIONS,
} from '../actions/PickersActions'

import {
	OFFLINE_ARTISTS, OFFLINE_GALLERIES
}	from '../utils/offlineResults'

export function pickersReducer(state = {
		artistPicker: {
			isFetching: false,
			options: [],
		},
		galleryPicker: {
			isFetching: false,
			options: [],
			optionsEducation: [],
		},
		showPicker: {
			isFetching: false,
			options: [],
		},
		postPicker: {
			isFetching: false,
			options: [],
		},
	}, action) {
	switch (action.type) {
		case RESET_ALL_OPTIONS: {
			return Object.assign({}, state, {
				artistPicker: { ...state.artistPicker, options: [] },
				galleryPicker: { ...state.galleryPicker, options: [] },
				showPicker: { ...state.showPicker, options: [] },
				postPicker: { ...state.postPicker, options: [] },
			})
		}
		case ARTIST_SEARCH_REQUEST: {
			return Object.assign({}, state, {
				artistPicker: {
					...state.artistPicker,
					isFetching: true
				}
			})
		}
		case ARTIST_SEARCH_SUCCESS: {
			let options = []
			const response = __LOCAL_PICKERS__ && false ? OFFLINE_ARTISTS : action.response
			response.map((artist) => {
				options.push({_id: artist._id, name: artist.name, label: artist.name})
			})
			return Object.assign({}, state, {
				artistPicker: {
					isFetching: false,
					options,
				}
			})
		}
		case ARTIST_SEARCH_FAILURE: {
			return Object.assign({}, state, {
				artistPicker: {
					isFetching: false,
					error: action.message,
					options: []
				}
			})
		}
		case RESET_ARTIST_OPTIONS: {
			let options = []
			if (action.initial && Array.isArray(action.initial)) {
				action.initial.map((item) => { options.push(item)})
			}else if(action.initial){
				options.push(action.initial)
			}
			return Object.assign({}, state, {
				artistPicker: {
					isFetching: false,
					options
				}
			})
		}
		case GALLERY_SEARCH_REQUEST: {
			return Object.assign({}, state, {
				galleryPicker: {
					...state.galleryPicker,
					isFetching: true,
				}
			})
		}
		case GALLERY_SEARCH_SUCCESS: {
			let options = []
			let optionsEducation = []
			const response = __LOCAL_PICKERS__ && false ? OFFLINE_GALLERIES : action.response
			response.map((gallery) => {
				options.push({_id: gallery._id, name: gallery.name, venueType: gallery.venueType})
				if (gallery.venueType === 'Art institution') {
					optionsEducation.push({_id: gallery._id, name: gallery.name, venueType: gallery.venueType})
				}
			})
			return Object.assign({}, state, {
				galleryPicker: {
					isFetching: false,
					options,
					optionsEducation,
				}
			})
		}
		case GALLERY_SEARCH_FAILURE: {
			return Object.assign({}, state, {
				galleryPicker: {
					isFetching: false,
					error: action.message,
					options: [],
					optionsEducation: [],
				}
			})
		}
		case ART_INSTITUTIONS_SUCCESS: {
			// Since on master, elasticsearch wont search for venueType.
			let optionsEducation = []
			action.response.map((gallery) => {
				optionsEducation.push({_id: gallery._id, name: gallery.name, venueType: gallery.venueType})
			})
			return Object.assign({}, state, {
				galleryPicker: {
					isFetching: false,
					options: [],
					optionsEducation,
				}
			})
		}
		case RESET_GALLERY_OPTIONS: {
			let options = []
			let optionsEducation = []
			if (action.initial && Array.isArray(action.initial)) {
				action.initial.map((item) => {
					options.push(item)
					if (item.venueType === 'Art institution') {
						optionsEducation.push(item)
					}
				})
			} else if (action.initial) {
				options.push(action.initial)
				if (action.initial.venueType === 'Art institution') {
					optionsEducation.push(action.initial)
				}
			}
			return Object.assign({}, state, {
				galleryPicker: {
					isFetching: false,
					options
				}
			})
		}
		case SHOW_SEARCH_REQUEST: {
			return Object.assign({}, state, {
				showPicker: {
					...state.showPicker,
					isFetching: true
				}
			})
		}
		case SHOW_SEARCH_SUCCESS: {
			let options = []
			action.response.map((item) => {
				console.log(item);
				options.push({_id: item._id, name: item.name, label: item.title})
			})
			return Object.assign({}, state, {
				showPicker: {
					isFetching: false,
					options,
				}
			})
		}
		case SHOW_SEARCH_FAILURE: {
			return Object.assign({}, state, {
				showPicker: {
					isFetching: false,
					error: action.message,
					options: [],
				}
			})
		}
		case RESET_SHOW_OPTIONS: {
			let options = []
			if (action.initial && Array.isArray(action.initial)) {
				action.initial.map((item) => { options.push(item)})
			}else if(action.initial){
				options.push(action.initial)
			}
			return Object.assign({}, state, {
				showPicker: {
					isFetching: false,
					options,
				}
			})
		}
		case POST_SEARCH_REQUEST: {
			return Object.assign({}, state, {
				postPicker: {
					...state.postPicker,
					isFetching: true,
				}
			})
		}
		case POST_SEARCH_SUCCESS: {
			let options = []
			action.response.map((item) => {
				options.push({_id: item._id, name: item.title, label: item.title})
			})
			return Object.assign({}, state, {
				postPicker: {
					isFetching: false,
					options,
				}
			})
		}
		case POST_SEARCH_FAILURE: {
			return Object.assign({}, state, {
				postPicker: {
					isFetching: false,
					error: action.message,
					options: [],
				}
			})
		}
		case RESET_POST_OPTIONS: {
			let options = []
			if (action.initial && Array.isArray(action.initial)) {
				action.initial.map((item) => { options.push(item)})
			}else if(action.initial){
				options.push(action.initial)
			}
			return Object.assign({}, state, {
				postPicker: {
					isFetching: false,
					options,
				}
			})
		}
		default:
			return state
	}
}
