import {
  updateUser,
  saveUser
}  from '../UserActions'

import {
  initialize,
  getFormValues,
  stopSubmit,
  startSubmit,
}  from 'redux-form'

import Validator                from 'validatorjs'

export const LOAD = 'artworksapp/account/LOAD'
export const SUBMIT = 'artworksapp/account/SUBMIT'

export const SHOW_SUCCESS = 'artworksapp/account/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/account/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/account/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/account/HIDE_ERROR'

const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const userModel = {
  name: '',
  email: '',
  role: 'user',
  country: '',
  phone: '',
  gallery: '',
  artist: ''
}

export function initAccountUser(formName) {
  return dispatch => {
    const usr = Object.assign({}, userModel)
    console.log('initializing: ', usr);
    dispatch( initialize(formName, usr, false) )
  }
}

export function loadAccountUser(user, formName) {
  return dispatch => {
    const usr = Object.assign({}, userModel, user)
    dispatch( initialize(formName, usr) )
  }
}

export function submitAccountUser(data, initial, formName) {
  return (dispatch, getState) => {
    let customErrors = {}
    let rules = {
      email: 'required|email',
    }
    const validation = new Validator(data, rules)
    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({}, customErrors, validatorErrors)
    if (Object.keys(errors) < 1) {
      dispatch( startSubmit(formName) )
      if (data._id) {
        dispatch( updateUser(data) )
      } else {
        dispatch( saveUser(data) )
      }
    } else {
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit(formName, errors) )
    }
  }
}

export function removeAccountUser(user){
  return dispatch => {
    dispatch( deleteUser(user) )
  }
}

export function showAccountSuccess(){
  return {
    type: SHOW_SUCCESS,
    showSuccess: true
  }
}

export function hideAccountSuccess(){
  return {
    type: HIDE_SUCCESS,
    showSuccess: false
  }
}


export function showAccountError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideAccountError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
