import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import {
	toggleLatestSales,
	fetchSales,
} from "../../../actions/DashboardActions/LatestSalesActions";
import ExpandButton from "../ExpandButton/ExpandButton";
import ItemStats from "../ItemStats/ItemStats";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

class LatestSales extends Component {
	componentWillMount() {
		this.props.dispatch(fetchSales());
	}

	//Dev function used for testing
	partialList = (list, len) => {
		let partial = [];
		list.reverse().map((item, index) => {
			if (index < len) {
				partial.push(item);
			}
		});
		return partial;
	};

	//Draws individual art element
	drawArt = (art, key, styling) => {
		const imgSrc = art.images.length > 0 ? art.images[0].src : null;
		// const year = DateUtil.year(art.created_at)
		const year = moment(art.created_at).format("YYYY");
		const artistName = art.artistMod ? art.artistMod.name : "";
		const slug = art.slug || "";
		const link = "https://artworks.se/konstverk/" + slug;

		return (
			<a
				key={key}
				className="LatestSales__item"
				style={styling}
				href={link}
				target="_blank"
			>
				<div
					className="LatestSales__item__image"
					style={{ backgroundImage: `url(${imgSrc})` }}
				/>
				<div className="LatestSales__item__details">
					<b>{artistName}</b>
					<p>{`${art.title}, ${year}`}</p>
				</div>
			</a>
		);
	};

	//Adjusts item styling depending on amount of items
	calcStyling = (artList) => {
		const len = artList.length;
		let itemStyling = {};
		let contentStyling = {};
		switch (true) {
			case len <= 4:
				contentStyling = {
					height: "400px",
					gridTemplateColumns: "repeat(auto-fill,minmax(25%, 1fr))",
				};
				break;
			case len === 5:
				contentStyling = {
					height: "400px",
					gridTemplateColumns: "repeat(auto-fill,minmax(20%, 1fr))",
				};
				break;
			case len <= 10:
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(20%, 1fr))",
				};
				break;
			case len <= 12:
				itemStyling = {
					fontSize: "12px",
				};
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(16.6%, 1fr))",
				};
				break;
			case len <= 18:
				itemStyling = {
					fontSize: "12px",
				};
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(16.6%, 1fr))",
				};
				break;
			case len <= 28:
				itemStyling = {
					fontSize: "9px",
				};
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(14.15%, 1fr))",
				};
				break;
			case len <= 40:
				itemStyling = {
					fontSize: "7px",
				};
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(12.5%, 1fr))",
				};
				break;
			case len <= 54:
				itemStyling = {
					fontSize: "5px",
				};
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(11.1%, 1fr))",
				};
				break;
			case len <= 70:
				itemStyling = {
					fontSize: "3px",
				};
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(10%, 1fr))",
				};
				break;
			default:
				contentStyling = {
					gridTemplateColumns: "repeat(auto-fill,minmax(25%, 1fr))",
				};
				break;
		}

		return { itemStyling, contentStyling };
	};

	render() {
		const { sales, expanded, isFetching, t } = this.props;

		// const partial = this.partialList(sales, 27)
		const styling = expanded ? this.calcStyling(sales) : {};

		return (
			<div className="LatestSales">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(toggleLatestSales())}
				/>
				<h4 className={"artist underlined"}>{t("latestSales.header")}</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded ? (
							<div
								className="LatestSales__content"
								style={styling.contentStyling}
							>
								{sales.map((art, index) => {
									return this.drawArt(art, index, styling.itemStyling);
								})}
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		latestSales: { sales, expanded, isFetching },
	} = state;
	return { sales, expanded, isFetching };
}

const translated = withNamespaces("components")(LatestSales);
export default connect(mapStateToProps)(translated);
