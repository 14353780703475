import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormValues,
	getFormInitialValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import {
	submitArtwork,
	initArtwork,
	showSuccess,
} from "../../actions/FormActions/ArtworkFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import { hideError, hideSuccess } from "../../actions/FormActions/FormActions";
import { fetchArtwork } from "../../actions/ArtworkActions";
import { saveArtist } from "../../actions/ArtistActions";
import { fetchGalleries } from "../../actions/GalleryActions";
import ArtworkForm from "./ArtworkForm";
import ArtistForm from "../Artist/ArtistForm";
import { withRouter } from "react-router";
import Main from "../../components/Main/Main";
import Modal from "../../components/Modal/Modal";
import { withNamespaces } from "react-i18next";

class CreateArtworkNew extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentWillMount() {
		// console.log('MOUNTING CREATE ARTWORK');
		this.props.dispatch(initArtwork());
	}
	componentWillReceiveProps(newProps) {
		// redirect to new artwork if saved
		if (this.props.user !== newProps.user) {
			// This will init the form a second time, causing initialValues set in connect in ArtworkForm.jsx to disappear.
			// But we set initialValues in initArtwork in ArtworkFormActions.jsx
			this.props.dispatch(initArtwork());
		}
	}
	handleSubmit = () => {
		// save artwork
		const {
			form: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};

	render() {
		const { showArtistModal, isAdmin, isArtist, t } = this.props;

		// initialValues={{ forRaffle: false }}
		return (
			<div>
				<Main>
					<ArtworkForm
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isArtist={isArtist}
					/>
					{false && isAdmin ? (
						<div className="fieldset" style={{ paddingTop: 40 }}>
							<legend>Admin</legend>
							<button
								type="button"
								className="button--mod_danger"
								onClick={() => {
									let confirm = window.confirm("Are you sure?");
									if (confirm) this.props.dispatch(deleteArtwork(artwork));
								}}
							>
								{t("single.deleteArtwork")}
							</button>
						</div>
					) : null}
				</Main>
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
						/>
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artwork,
		artistForm: { showArtistModal },
		auth: { user, isAdmin },
	} = state;

	const isArtist = user.role === "artist";

	const form = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return {
		artwork,
		user,
		isAdmin,
		isArtist,
		showArtistModal,
		form,
		artistForm,
	};
}

const translated = withNamespaces("artworks")(CreateArtworkNew);
export default connect(mapStateToProps)(withRouter(translated));
