import React, { Component } from "react";
import { connect } from "react-redux";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import { withNamespaces } from "react-i18next";
import { saveGallery } from "../../actions/GalleryActions";
import {
	// sendEmailToMembers,
	sendEmailToMembersReset,
} from "../../actions/RegistryActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const CLOSE = require("../../../assets/images/icons/close@2x.png");
const CHEVRON = require("../../../assets/images/icons/chevron-down@2x.png");

class RegistryOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mailOpen: false,
			mailingListName: "",
			emailHeader: "",
			emailBody: "",
			lastSentEmailBody: "",
			lastSentToMailingList: "",
			mailDropdownOpen: false,
			customColumnName: "",
			columnNameTaken: false,
			columnsToRemove: [],
			appliedEmail: "",
			approvedEmail: "",
		};
	}

	componentDidMount() {
		if (this.props.gallery && this.props.gallery._id) {
			this.setMemberEmails(this.props.gallery);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			this.props.gallery &&
			nextProps.gallery &&
			this.props.gallery._id !== nextProps.gallery._id
		) {
			this.props.initColumns(nextProps.gallery);
			this.setMemberEmails(nextProps.gallery);
		}

		// If finished sending email.
		if (!this.props.mailSent && nextProps.mailSent) {
			if (nextProps.registryError) {
				nextProps.toggleModalOpen("message", nextProps.registryError);
			} else {
				this.setState(
					{
						// Don't send the same message to the same list twice.
						lastSentEmailBody: this.state.emailBody,
						lastSentToMailingList: this.props.sendToList,
					},
					() => {
						nextProps.toggleModalOpen("message", "Mailet har skickats");
					}
				);
			}
		}
	}

	setMemberEmails = (gallery) => {
		const { artSocietyData } = gallery;
		const { appliedEmail, approvedEmail } = this.state;

		let appliedMessage = "";
		if (artSocietyData && artSocietyData.memberAppliedMail)
			appliedMessage = artSocietyData.memberAppliedMail;
		let approvedMessage = "";
		if (artSocietyData && artSocietyData.memberApprovedMail)
			approvedMessage = artSocietyData.memberApprovedMail;

		this.setState({
			appliedEmail: appliedMessage,
			approvedEmail: approvedMessage,
		});
	};

	updateOptions = (e) => {
		e.preventDefault();
		const { organisation, columns, gallery } = this.props;
		const {
			customColumnName,
			columnNameTaken,
			columnsToRemove,
			appliedEmail,
			approvedEmail,
		} = this.state;

		const updatedArtSocietyData = gallery.artSocietyData || {};

		let params = Object.assign(
			{},
			{
				_id: organisation,
				registry: {
					displayColumns: {},
				},
				artSocietyData: updatedArtSocietyData,
			}
		);

		// Have to include existing applicationColumns or they will be overwritten with default values.
		if (gallery.registry && gallery.registry.applicationColumns) {
			params.registry["applicationColumns"] =
				gallery.registry.applicationColumns;
		}
		if (gallery.registry && gallery.registry.mailingLists) {
			params.registry["mailingLists"] = gallery.registry.mailingLists;
		}
		if (gallery.registry && gallery.registry.customColumns) {
			// params.registry['customColumns'] = gallery.registry.customColumns
			params.registry.customColumns = [];
			gallery.registry.customColumns.forEach((column) => {
				if (columnsToRemove.indexOf(column._id) < 0) {
					// "display" in gallery object has not been updated yet. Get value from "use" in columns.
					const updatedColumn = columns.find((col) => col.value === column._id);
					let withUpdatedDisplay = column;
					if (updatedColumn && updatedColumn.use !== column.display) {
						withUpdatedDisplay = Object.assign({}, column, {
							display: updatedColumn.use,
							updated: true,
						});
					}
					params.registry.customColumns.push(withUpdatedDisplay);
				} else {
					// console.log('not add');
				}
			});
		} else {
			params.registry["customColumns"] = [];
		}
		if (customColumnName && !columnNameTaken) {
			params.registry["customColumns"].push({
				name: customColumnName,
				display: true,
			});
		}

		columns.forEach((column) => {
			params.registry.displayColumns[column.value] = column.use;
		});

		this.props.dispatch(saveGallery(params));
		this.setState({
			customColumnName: !columnNameTaken ? "" : this.state.customColumnName,
			columnsToRemove: [],
		});
	};

	toggleMailOpen = () => {
		this.setState({
			mailOpen: !this.state.mailOpen,
		});
	};

	mailingListNameChange = (e) => {
		const value = e.target.value;
		this.setState({
			mailingListName: value,
		});
	};

	changeSendToList = (list) => {
		this.props.selectMailingList(list);
	};

	changeEmailHeader = (e) => {
		const text = e.target.value;
		this.setState({
			emailHeader: text,
		});
	};

	changeEmailBody = (e) => {
		const text = e.target.value;
		this.setState({
			emailBody: text,
		});
	};

	// sendEmail = (senderEmail) => {
	//   const { gallery, sendToList } = this.props
	//   const { emailHeader, emailBody } = this.state
	//
	//   const mailingLists = gallery && gallery.registry && gallery.registry.mailingLists ? gallery.registry.mailingLists : []
	//   const selectedList = mailingLists.find(list => list._id === sendToList)
	//   const metadata = {
	//     listName: selectedList.name,
	//   }
	//
	//   this.props.dispatch( sendEmailToMembers(gallery._id, sendToList, senderEmail, emailHeader, emailBody, [], metadata) )
	// }

	togglEmailDropdownOpen = () => {
		this.setState({
			mailDropdownOpen: !this.state.mailDropdownOpen,
		});
	};

	selectOption = (id) => {
		this.changeSendToList(id);
		this.togglEmailDropdownOpen();
	};

	changeCustomColumnName = (e) => {
		const { columnLabels } = this.props;
		const value = e.target.value;
		const alreadyExists = columnLabels.indexOf(value.toLowerCase()) >= 0;

		this.setState({
			customColumnName: value,
			columnNameTaken: alreadyExists,
		});
	};

	removeCustomColumn = (id, name) => {
		const { t } = this.props;
		const { columnsToRemove } = this.state;
		let confirm = window.confirm(`${t("memberTable.removeColumn")} ${name}?`);
		if (confirm) {
			const toRemoveCopy = columnsToRemove.slice();
			toRemoveCopy.push(id);

			this.setState({
				columnsToRemove: toRemoveCopy,
			});
			this.props.removeCustomColumns(id);
		}
	};

	toggleModalOpen = (type = false, message = "") => {
		if (!type) {
			this.props.dispatch(sendEmailToMembersReset());
			this.setState({
				modalOpen: false,
				modalType: "",
				modalMessage: "",
			});
		} else {
			this.setState({
				modalOpen: true,
				modalType: type,
				modalMessage: message,
			});
		}
	};

	render() {
		const {
			columns,
			clicked,
			gallery,
			isFetching,
			user,
			registryFetching,
			listsCount,
			sendToList,
			members,
			t,
		} = this.props;
		const {
			mailOpen,
			mailingListName,
			emailHeader,
			emailBody,
			lastSentEmailBody,
			lastSentToMailingList,
			mailDropdownOpen,
			customColumnName,
			columnNameTaken,
			appliedEmail,
			approvedEmail,
		} = this.state;
		const rows = [];
		const mailingLists =
			gallery && gallery.registry && gallery.registry.mailingLists
				? gallery.registry.mailingLists
				: [];
		const senderEmail = gallery && gallery.email ? gallery.email : user.email;
		// const senderToShowUser = gallery ? `${gallery.name}, ${senderEmail}` : ''
		// const senderForActualEmail = gallery ? `${gallery.name} <${senderEmail}>` : ''
		const selectedListForEmail = sendToList
			? mailingLists.find((list) => list._id === sendToList)
			: "";
		const premium = isPremium(gallery);

		let sendMailButtonText = "Skicka";
		if (sendToList)
			sendMailButtonText += ` till ${listsCount[sendToList]} adresser`;
		const messageAlreadySent =
			lastSentEmailBody === emailBody && lastSentToMailingList === sendToList;

		columns.forEach((column) => {
			rows.push(
				<div key={column.value} className="registry-options__content__option">
					<input
						type="checkbox"
						checked={column.use}
						onChange={() => clicked(column.value, !column.use)}
						className="registry-options__content__option__checkbox"
					/>
					<p style={{ color: column.use ? "black" : "gray" }}>{column.label}</p>
					{column.custom && (
						<img
							src={CLOSE}
							onClick={() =>
								this.removeCustomColumn(column.value, column.label)
							}
							className="registry-options__content__option__remove"
						/>
					)}
				</div>
			);
		});
		return (
			<div className="registry-options">
				<div className="registry-options__content is-open">
					<p className="registry-options__content__header">
						{t("memberTable.whichColumns")}
					</p>
					<div className="registry-options__content__column-selector">
						{rows}
					</div>
					<div
						className={
							"registry-options__content__custom-column" +
							(columnNameTaken ? " is-taken" : "")
						}
					>
						<p>{t("memberTable.createColumn")}</p>
						<input
							type="text"
							value={customColumnName}
							onChange={this.changeCustomColumnName}
						/>
					</div>
					<button
						className="purple-button--wide button-nomargin"
						onClick={this.updateOptions}
						disabled={isFetching}
						style={{ margin: "0" }}
					>
						{t("memberTable.saveColumns")}
					</button>
				</div>
			</div>
		);
	}
}
// { mailingLists.map(list => {
//   return (
//     <div key={ list._id }>
//       <input type="text" value={ list.name } onChange={() => {}} />
//     </div>
//   )
// }) }

function mapStateToProps(state) {
	const {
		gallery: { gallery, isFetching },
		auth: { user },
		registry: {
			members,
			mailSent,
			isFetching: registryFetching,
			error: registryError,
		},
	} = state;
	return {
		gallery,
		isFetching,
		user,
		mailSent,
		registryFetching,
		registryError,
		members,
	};
}

const translated = withNamespaces("member")(RegistryOptions);
export default connect(mapStateToProps)(translated);
