import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Artwork from "./Artwork";
import { showArtworkModal } from "../../../actions/FormActions/ArtworkFormActions";

class ArtworkPicker extends Component {
	static propTypes = {};
	static defaultProps = {
		selectedArtworks: [],
	};
	constructor(props) {
		super(props);
		this.state = {
			artworkModalOpened: false,
		};
	}

	componentDidMount() {}

	componentWillReceiveProps(newProps) {
		// When artwork added from raffle/show page, make it selected from start.
		const prevIds = Object.keys(this.props.artworks).map((key) => key);
		const nextIds = Object.keys(newProps.artworks).map((key) => key);
		// Check for artworkModalOpened. Don't want to select artwork id first time artworks have been loaded.
		if (
			newProps.addOnUpload &&
			this.state.artworkModalOpened &&
			nextIds.length > prevIds.length
		) {
			// Find added id, should be the last key.
			let addedArtworkId = "";
			for (let i = nextIds.length - 1; i >= 0; i--) {
				if (prevIds.indexOf(nextIds[i]) < 0) {
					addedArtworkId = nextIds[i];
					break;
				}
			}
			// Don't use toggleArtwork below to add id here. On show page, artworks in props go back to 0 before being fetched again.
			if (addedArtworkId) {
				const selectedArtworks = newProps.input.value.slice() || [];
				selectedArtworks.push(addedArtworkId);
				newProps.input.onChange(selectedArtworks);
			}
		}
	}

	toggleArtwork = (artworkId) => {
		const selectedArtworks = this.props.input.value.slice() || [];

		let found = false;
		selectedArtworks.filter((aw, key) => {
			if (aw._id === artworkId) {
				console.log("found");
				found = true;
				selectedArtworks.splice(key, 1);
			} else if (aw === artworkId) {
				console.log("found");
				found = true;
				selectedArtworks.splice(key, 1);
			}
		});
		if (!found) {
			console.log("not found");
			selectedArtworks.push(artworkId);
		}
		this.props.input.onChange(selectedArtworks);
	};

	toggleArtworksModal = (e) => {
		e.preventDefault();
		this.props.dispatch(showArtworkModal());
		this.setState({ artworkModalOpened: true });
	};

	getArtworksList = (input, artworks, onlyForSale) => {
		const selectedArtworks = [];
		if (input.value.length) {
			input.value.map((artwork) => {
				if (artwork._id) {
					selectedArtworks[artwork._id] = artwork;
				} else {
					selectedArtworks[artwork] = { _id: artwork };
				}
			});
		}
		let artworksList = [];
		Object.keys(artworks)
			.sort((a, b) => {
				let aObject = artworks[a];
				let bObject = artworks[b];
				return new Date(bObject.created_at) - new Date(aObject.created_at);
			})
			.forEach((key) => {
				const artwork = artworks[key];
				const selected = selectedArtworks[artwork._id] ? true : false;
				if (!onlyForSale || artwork.status === "for-sale") {
					artworksList.push(
						<Artwork
							artwork={artwork}
							selected={selected}
							key={artwork._id}
							toggleArtwork={this.toggleArtwork}
						/>
					);
				}
			});
		return artworksList;
	};

	render() {
		const {
			input,
			artworks,
			isAdmin,
			artist,
			header = false,
			upload = true,
			onlyForSale = false,
			t,
		} = this.props;

		const artworksList = this.getArtworksList(input, artworks, onlyForSale);

		return (
			<div className="fieldset">
				<legend>{header ? header : t("artworkPicker.chooseArtworks")}</legend>
				<div className="input-holder">{artworksList}</div>
				{upload && (
					<button type="button" onClick={(e) => this.toggleArtworksModal(e)}>
						{t("artworkPicker.uploadArtwork")}
					</button>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artwork: { artworks },
	} = state;
	return { artworks };
}

const translated = withNamespaces("components")(ArtworkPicker);
export default connect(mapStateToProps)(translated);
