import React, { Component, PropTypes } from "react";
import { Field } from "redux-form";
import Select from "../../FormInput/Select";
import GalleryPicker from "../GalleryPicker/GalleryPicker";
import ArtistPicker from "../ArtistPicker/ArtistPicker";

const ROLES = [
	{
		name: "Author",
		value: "author",
	},
	{
		name: "Artist",
		value: "artist",
	},
	{
		name: "Society",
		value: "society",
	},
	{
		name: "User",
		value: "user",
	},
	{
		name: "Admin",
		value: "admin",
	},
];

const SECONDARY_ROLES = [
	{
		name: "None",
		value: "none",
	},
	{
		name: "Public purchaser / Offentlig inköpare",
		value: "publicPurchaser",
	},
];

const UserRolePicker = (props) => {
	const role = props.role.input.value;
	const secondaryRole = props.secondaryRole.input.value;

	return (
		<div>
			<div className="input-holder input-holder--full">
				<Select {...props.role} options={ROLES} />
			</div>
			{role === "artist" ? (
				<ArtistPicker multi={false} {...props.artist} />
			) : null}
			{role === "author" || role === "society" ? (
				<GalleryPicker {...props.gallery} multi={false} allowAdd={true} />
			) : null}
			<div className="input-holder input-holder--full">
				<Select {...props.secondaryRole} options={SECONDARY_ROLES} />
			</div>
		</div>
	);
};

export default UserRolePicker;
