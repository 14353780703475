import React, { PropTypes }         from 'react';
import { connect }                  from 'react-redux'
import { 	Field,
 					Fields }                  from 'redux-form';
import TextInput                    from "../../FormInput/TextInput"
import Select                       from "../../FormInput/Select"
import CheckBox                     from '../../FormInput/CheckBox'
import { withNamespaces }           from 'react-i18next'

const TRANSPORTS_SWE = [
  { value: "extra", name: "Kontakta konstnären angående leverans" },
  { value: "swe", name: "Leverans inom Sverige" },
  { value: "swe-eu", name: "Leverans inom Sverige & EU" },
  { value: "global", name: "Leverans inom Sverige, EU och utanför EU" }
]

const TRANSPORTS_ENG = [
  { value: "extra", name: "Contact the artist regarding shipping" },
  { value: "swe", name: "Shipping within Sweden" },
  { value: "swe-eu", name: "Shipping within Sweden & the EU" },
  { value: "global", name: "Global Shipping" }
]

const ShippingPicker = (props) => {
  const {
    currency, transport, disabled, invoice = false, order = false,
    manualOrder = false, isSwedish, isResponsive = false, t,
  } = props

  const extra = (transport === 'extra' || !transport) && (order || manualOrder)
  const sweShipping = transport === 'swe' || transport === 'swe-eu' || transport === 'global'
  const euShipping = transport === 'swe-eu' || transport === 'global'
  const globalShipping = transport === 'global'

  const fieldsetClass = isResponsive ? 'fieldset-r' : 'fieldset'
  const inputHolderClass = isResponsive ? 'input-holder-r' : 'input-holder'
  const inputHolderFullClass = isResponsive ? 'input-holder-r input-holder-r--full' : 'input-holder input-holder--full'

  return (
    <div className={ fieldsetClass }>
      { !isResponsive && <legend>{manualOrder ? t('shippingPicker.manualTitle') : t('shippingPicker.title')}</legend> }
      <div className={ isResponsive ? inputHolderFullClass : inputHolderClass }>
        <Field
          name="shipping.location"
          component={ TextInput }
          placeholder={ t('shippingPicker.location') }
          disabled={ disabled }
        />
      </div>
      { (!order && !invoice) &&
        <div className={ inputHolderFullClass }>
          <Select
            {...props.shipping.transport}
            options={ isSwedish ? TRANSPORTS_SWE : TRANSPORTS_ENG }
            disabled={ disabled }
          />
        </div>
      }
      <div className={ fieldsetClass }>
        { extra &&
          <div className={ (invoice || isResponsive) ? inputHolderFullClass : inputHolderClass }>
            <Field
              name="shipping.customPrice"
              component={ TextInput }
              placeholder={ `${t('shippingPicker.customShipping')} (${currency} ${t('shippingPicker.exVAT')})` }
              disabled={ disabled && invoice }
            />
          </div>
        }
        { sweShipping &&
          <div className={ (order || invoice || isResponsive) ? inputHolderFullClass : inputHolderClass }>
            <Field
              name="shipping.swePrice"
              component={ TextInput }
              placeholder={ `${t('shippingPicker.sweShipping')} (${currency} ${t('shippingPicker.exVAT')})` }
              disabled={ disabled }
              required
            />
          </div>
        }
        { euShipping &&
          <div className={ (order || invoice || isResponsive) ? inputHolderFullClass : inputHolderClass }>
            <Field
              name="shipping.euPrice"
              component={ TextInput }
              placeholder={ `${t('shippingPicker.euShipping')} (${currency} ${t('shippingPicker.exVAT')})` }
              disabled={ disabled }
              required
            />
          </div>
        }
        { globalShipping &&
          <div className={ (order || invoice || isResponsive) ? inputHolderFullClass : inputHolderClass }>
            <Field
              name="shipping.globalPrice"
              component={ TextInput }
              placeholder={ `${t('shippingPicker.globalShipping')} (${currency} ${t('shippingPicker.exVAT')})` }
              disabled={ disabled }
              required
            />
          </div>
        }
      </div>
    </div>
  )
}

const translated = withNamespaces('components')(ShippingPicker)
export default connect(null)(translated)
