import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
// import Select               	from "../../FormInput/Select"
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
	fetchCategories,
	saveCategory,
} from "../../../actions/CategoryActions";

class CategoryPicker extends Component {
	static propTypes = {
		placeholder: PropTypes.string,
		category: PropTypes.object,
	};
	static defaultProps = {
		placeholder: "Choose category...",
		category: {
			isFetching: false,
		},
	};
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { type } = this.props;
		this.props.dispatch(fetchCategories(type));
	}

	selectCategory = (selections) => {
		const categories = [].concat(this.props.category.categories);
		selections.forEach((selection) => {
			const match = categories.find((entry) => entry._id == selection._id);
			if (!match) {
				console.log("This is a new selection: ", selection);
			}
		});

		this.props.input.onChange(selections);
	};

	//Fetches colours of selected values
	getColours = (values, options) => {
		if (!values) return [];
		let colours = [];
		values.forEach((value) => {
			//	Match with options as values are not populated upon initialization
			let fullOption = {};
			if (!value._id) {
				options.forEach((option, i) => {
					if (option._id === value) {
						fullOption = option;
					}
				});
			}
			if (value.hexColor || fullOption.hexColor) {
				const hexColor = value.hexColor || fullOption.hexColor;
				colours.push(hexColor);
			}
		});
		return colours;
	};

	render() {
		const {
			type,
			category: { isFetching, categories },
			input: { value },
			placeholder,
			isResponsive = false,
			t,
		} = this.props;
		const inputHolderClass = isResponsive
			? "input-holder-r input-holder-r--full"
			: "input-holder";
		let selectClassName = isResponsive ? "no-clear-all" : "";

		const categoryOptions = [];
		Object.keys(categories).map((key) => {
			const category = categories[key];
			if (category.type === type) {
				categoryOptions.push(category);
			}
		});
		const colours = this.getColours(value, categoryOptions);

		return (
			<div className={inputHolderClass}>
				<Select
					isLoading={isFetching}
					onChange={this.selectCategory}
					options={categoryOptions}
					value={value}
					valueKey="_id"
					labelKey="text"
					placeholder={placeholder || t("categoryPicker.placeholder")}
					multi={true}
					className={selectClassName}
				/>
				{colours.length > 0 && (
					<div
						style={{
							maxWidth: "100%",
							minHeight: "40px",
							display: "flex",
							flexFlow: "row wrap",
							marginBottom: "20px",
						}}
					>
						{colours.map((colour) => (
							<div
								key={colour}
								style={{
									backgroundColor: colour,
									height: "20px",
									width: "20px",
									margin: "10px",
									borderRadius: "20px",
									border: "1px solid #eeeeee",
								}}
							></div>
						))}
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { category } = state;
	return { category };
}

const translated = withNamespaces("components")(CategoryPicker);
export default connect(mapStateToProps)(translated);
