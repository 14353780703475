import React, {Component, PropTypes} 	from 'react'
import { Field }                      from 'redux-form'
import { withNamespaces }							from 'react-i18next'
import CheckBox                       from '../../FormInput/CheckBox'
import TextInput                      from '../../FormInput/TextInput'
import Select                         from '../../FormInput/Select'

const PRICE_CURRENCIES = [
	{value:"SEK", name: "SEK"},
	{value:"USD", name: "USD"},
	{value:"EUR", name: "EUR"},
	{value:"NOK", name: "NOK"},
	{value:"ISK", name: "ISK"},
	{value:"DKK", name: "DKK"}
]

const TicketPicker = (props) => {
	const { t } = props

	const ENTRANCE_FEE_TYPES = [
		{value:"From:", name: t('ticketPicker.from')},
		{value:"", name: t('ticketPicker.all')},
	]

  const showMuseum = props.isMuseum.input.value ? true : false
  // TODO: Check if this really works...
  return (
    <div className="fieldset">
      <div className="input-holder">
        <Field name="isMuseum" component={ CheckBox } checkboxLabel={t('ticketPicker.isTicketRequired')} />
      </div>
      { showMuseum ?
        <div style={{display: 'flex', flex: '1 0 auto', flexWrap: 'wrap', width: '100%'}}>
          <div className="input-holder">
            <Field name="museumPage" component={ TextInput } placeholder={t('ticketPicker.ticketPurchaseURL')} required />
          </div>
					<div className="input-holder">
						<Field name="entranceFeeCurrency" options={ PRICE_CURRENCIES } component={ Select } placeholder="Currency" required />
					</div>
					<div className="input-holder">
						<Field name="entranceFeeType" options={ ENTRANCE_FEE_TYPES } component={ Select } placeholder="From:" required />
					</div>
					<div className="input-holder">
						<Field name="entranceFee" component={ TextInput } placeholder={t('ticketPicker.entranceFee')} required />
					</div>
        </div>
      : null }
    </div>
  )
}

export default withNamespaces('components')(TicketPicker)
