import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

class DateRangePicker extends React.Component {
	/**
	 * defaultProps
	 */
	static defaultProps = {
		withTime: true,
	};

	static propTypes = {};

	constructor(props) {
		super(props);
		this.state = {};
	}

	/**
	 * componentWillReceiveProps
	 */
	componentWillReceiveProps(newProps) {}

	handleStartTime = (startTime) => {
		this.props.startDate.input.onChange(startTime);
		this.props.startDate.input.onBlur(startTime);
	};

	handleEndTime = (endTime) => {
		this.props.endDate.input.onChange(endTime);
		this.props.endDate.input.onBlur(endTime);
	};

	handleChangeStart = (startDate) => {
		const newStartDate = moment(startDate); // Convert to Moment.js object
		newStartDate.set("hour", 10);
		newStartDate.set("minute", 0);
		
		this.props.startDate.input.onChange(newStartDate.toDate()); // Convert back to JS Date
		this.props.startDate.input.onBlur(newStartDate.toDate());
	};
	
	handleChangeEnd = (endDate) => {
		const newEndDate = moment(endDate); // Convert to Moment.js object
		newEndDate.set("hour", 18);
		newEndDate.set("minute", 0);
	
		this.props.endDate.input.onChange(newEndDate.toDate()); // Convert back to JS Date
		this.props.endDate.input.onBlur(newEndDate.toDate());
	};	

	renderOk = (props) => {
		// TODO: Add OK button
		return null;
	};

	render() {
		const { startDate, endDate, withTime, t } = this.props;
		const startDateTs = startDate.input.value
			? moment(startDate.input.value)
			: moment();
		const endDateTs = endDate.input.value
			? moment(endDate.input.value)
			: moment();
		const startTime = startDateTs.format("HH:mm");
		const endTime = endDateTs.format("HH:mm");
		return (
			<div className="DateRangePicker is-valid is-required">
				<div className="input-holder">
					<div className="DateRangePicker__input">
						<DatePicker
							placeholderText={t("startDate")}
							dateFormat="yyyy-MM-dd"
							required
							selected={startDateTs.toDate()}
							startDate={startDateTs.toDate()}
							endDate={endDateTs.toDate()}
							onChange={this.handleChangeStart}
						/>
					</div>
					{withTime ? (
						<div className="DateRangePicker__input">
							<TimePicker
								value={startDateTs}
								showSecond={false}
								addon={this.renderOk}
								onChange={this.handleStartTime}
							/>
						</div>
					) : null}
				</div>

				<div className="input-holder">
					<div className="DateRangePicker__input">
						<DatePicker
							placeholderText={t("endDate")}
							dateFormat="yyyy-MM-dd"
							required
							selected={endDateTs.toDate()}
							startDate={startDateTs.toDate()}
							endDate={endDateTs.toDate()}
							onChange={this.handleChangeEnd}
						/>
					</div>
					{withTime ? (
						<div className="DateRangePicker__input">
							<TimePicker
								value={endDateTs}
								showSecond={false}
								addon={this.renderOk}
								onChange={this.handleEndTime}
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default withNamespaces("common")(DateRangePicker);
