import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Dropzone from "react-dropzone";
import {
	createArtworkFromImage,
	// createArtworkFromImages,
} from "../../actions/ArtworkActions";
import { saveRaffle } from "../../actions/RaffleActions";
import { saveShow } from "../../actions/ShowActions";

const MAX_SIZE = 5242880,
	MAX_SIZE_STRING = "5MB"; // 5MB in bytes

class ArtworkDropzone extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadCount: 0,
			successCount: 0,
			failCount: 0,
			newestArt: [],
		};
	}

	// Create a new artwork from an image, then call this function again for the next image.
	nextCall = (data, params, index, previousArtId = false) => {
		const {
			raffle = false,
			show = false,
			onDoneCallback = () => {},
		} = this.props;
		const newArt = this.state.newestArt;
		const forRaffle = raffle;
		const forShow = show;
		const item = raffle || show;
		let itemArtIds = item
			? item.artworks.map((artwork) => artwork._id || artwork)
			: false;

		// Uploads as installation if that tab is selected
		if (this.props.showIntallations) {
			params.installation = true;
		}

		createArtworkFromImage(data[index], params, this.props.dispatch)
			.then((success) => {
				let func = Promise.resolve(true);
				if (itemArtIds) {
					if (itemArtIds.indexOf(itemArtIds) < 0) {
						itemArtIds.push(success._id);
						newArt.push(success._id);
					}
					const itemBody = {
						_id: item._id,
						artworks: itemArtIds,
					};
					let saveFunction = () => {};
					if (forRaffle) saveFunction = saveRaffle;
					if (forShow) saveFunction = saveShow;
					func = this.props.dispatch(saveFunction(itemBody, false));
				}
				func.then((saved) => {
					this.setState(
						{
							successCount: success
								? this.state.successCount + 1
								: this.state.successCount,
							failCount: !success
								? this.state.failCount + 1
								: this.state.failCount,
							newestArt: newArt,
						},
						() => {
							if (data[index + 1]) {
								this.nextCall(data, params, ++index);
							} else {
								onDoneCallback();
							}
						}
					);
				});
			})
			.catch((err) => {
				console.log("error", err);
				this.setState(
					{
						failCount: this.state.failCount + 1,
					},
					() => {
						if (data[index + 1]) {
							this.nextCall(data, params, ++index);
						} else {
							onDoneCallback();
						}
					}
				);
			});
	};

	dropImage = (acceptedFiles, e) => {
		// selectedFilterId = gallery has buttons to select which artists artworks to display. False for others.
		const { selectedFilterId, selectedFilterName } = this.props;
		const { t } = this.props;

		const forRaffle = !!this.props.raffle;
		// const forShow = !!this.props.show
		const selectedArtistId =
			selectedFilterId && selectedFilterId !== "ALL" ? selectedFilterId : false;

		// Reset newestArt
		this.setState({
			newestArt: [],
		});

		let totalSize = 0;
		acceptedFiles.forEach((file) => {
			totalSize += file.size;
		});
		// If too large, saving will fail.
		if (totalSize > MAX_SIZE * acceptedFiles.length) {
			window.alert(t("components:imageUpload.imagesTooBig"));
			return;
		}

		let promises = acceptedFiles.map((file) => this.loadImage(file));

		const params = { forRaffle };
		if (this.props.galleryId) {
			// If uploading for gallery.
			params.gallery = this.props.galleryId;
			params.createdFor = "gallery";
			if (selectedArtistId) {
				params.artistMod = selectedArtistId;
			}
			if (this.props.selectedFilterName) {
				params.artist = {
					name: this.props.selectedFilterName,
				};
			}
		} else if (this.props.artistId) {
			// If uploading for artist.
			params.artistMod = this.props.artistId;
			params.artist = {
				name: this.props.artistName,
			};
			params.createdFor = "artist";
		}
		// If user is society, there is no published status selector in the list. Set to published from start for them.
		params.publish_status = this.props.isSociety ? "published" : "draft";

		Promise.all(promises)
			.then((data) => {
				// this.props.dispatch( createArtworkFromImages(data, params) )
				// if (data.length > 0) this.nextCall(data, params, 0)
				if (data.length < 1) return;
				this.setState(
					{
						uploadCount: data.length,
						successCount: 0,
						failCount: 0,
					},
					() => {
						this.nextCall(data, params, 0);
					}
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	loadImage = (uploadedImage) => {
		return new Promise(function (resolve, reject) {
			const reader = new FileReader();
			reader.onload = (upload) => {
				const image = {
					URI: upload.target.result,
					fileName: uploadedImage.name,
					contentType: uploadedImage.type,
				};
				resolve(image);
			};
			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(uploadedImage);
		});
	};

	// On multi upload, if one file fails, all files fail.
	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;

		rejectedFiles.forEach((rejected) => {
			this.showImageError(rejected, t);
		});
	};

	showImageError = (rejectedFile, t) => {
		if (rejectedFile.size > MAX_SIZE) {
			window.alert(
				`${t("components:imageUpload.imageTooBigMulti1")} ${
					rejectedFile.name
				} ${t("components:imageUpload.imageTooBigMulti2")}`
			);
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("list.imageWrongFormat"));
		}
	};

	render() {
		const { isResponsive, error, t } = this.props;

		return (
			<Dropzone
				id="artwork-dropzone"
				onDrop={this.dropImage}
				onDropRejected={this.dropRejected}
				className={"artwork-dropzone" + (isResponsive ? " is-responsive" : "")}
				multiple={true}
				maxSize={MAX_SIZE}
			>
				<div className="artwork-dropzone__drop-area">
					<h1>{t("list.dropZoneHeader")}</h1>
				</div>
				{error ? <span>{error}</span> : null}
			</Dropzone>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		artwork: { artworks, error },
		isFetching,
	} = state;
	return { artworks, isFetching, error };
};

const translated = withNamespaces("artworks")(ArtworkDropzone);
export default connect(mapStateToProps)(translated);
