import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Main from "../../components/Main/Main";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import { updatePassword } from "@artworkslab/sharedmodules/src/actions/AuthActions";

class UpdatePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailSent: false,
			inputText: "",
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.updated !== nextProps.updated && nextProps.updated) {
			this.redirectToLogin();
		}
	}

	redirectToLogin = () => {
		setTimeout(() => {
			this.props.router.push("/login");
		}, 1500);
	};

	submit = (e) => {
		e.preventDefault();
		const { token } = this.props.routeParams;
		const password = this.state.inputText;
		this.props.sendUpdate(token, password);
	};

	onInputChange = (e) => {
		e.preventDefault();
		const inputText = e.currentTarget.value;
		this.setState({
			inputText,
		});
	};

	render() {
		const { isFetching, error, updated, t } = this.props;
		const passwordValid = this.state.inputText.length > 4;
		return (
			<div>
				<Main>
					{/*<div className="MainTop">
						<h1>{t('login.update.updatePassword')}</h1>
					</div>*/}
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div style={{ width: "800px" }}>
							{error ? (
								<p style={{ marginBottom: 20 }}>
									{t("login.update.couldNotSave")}{" "}
									<Link to="/forgot" style={{ textDecoration: "underline" }}>
										{t("login.update.tryAgain")}
									</Link>
									.
								</p>
							) : null}
							{!updated && !error ? (
								<form>
									<div className="input-holder input-holder--full input-holder--nopadding">
										<input
											className="update-input"
											name="password"
											type="password"
											placeholder={t("login.update.newPassword")}
											onChange={this.onInputChange}
											value={this.state.inputText}
											required
										/>
										<button
											className="purple-button--wide button-nomargin"
											type="submit"
											onClick={(e) => this.submit(e)}
											disabled={!passwordValid || isFetching}
										>
											{t("login.update.updatePassword")}
										</button>
									</div>
								</form>
							) : null}
							{updated && !error ? (
								<p style={{ marginBottom: 20 }}>
									{t("login.update.passwordWasUpdated")}{" "}
									<Link to="/login" style={{ textDecoration: "underline" }}>
										{t("logIn")}
									</Link>
									.
								</p>
							) : null}
						</div>
					</div>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { updated, isFetching, error },
	} = state;
	return { updated, isFetching, error };
}

function mapDispatchToProps(dispatch) {
	return {
		sendUpdate: (token, password) => dispatch(updatePassword(token, password)),
	};
}

const translated = withNamespaces("common")(UpdatePassword);
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(translated));
