import Api from "@artworkslab/sharedmodules/src/Api";

export const ARTIST_TRANSACTIONS_REQUEST =
	"artworksapp/enquiries/ARTIST_TRANSACTIONS_REQUEST";
export const ARTIST_TRANSACTIONS_SUCCESS =
	"artworksapp/enquiries/ARTIST_TRANSACTIONS_SUCCESS";
export const ARTIST_TRANSACTIONS_FAILURE =
	"artworksapp/enquiries/ARTIST_TRANSACTIONS_FAILURE";

//Fetches transactions related to given artstistId
export function fetchArtistTransactions(artistId) {
	return (dispatch) => {
		dispatch(fetchArtistTransactionsRequest());
		const endpoint = `artworks/artisttransactions/${artistId}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchArtistTransactionsSuccess(json));
			})
			.catch((error) => {
				console.log("fetchArtistTransactions failure", error);
				dispatch(fetchArtistTransactionsFailure(error));
			});
	};
}

function fetchArtistTransactionsRequest() {
	return {
		type: ARTIST_TRANSACTIONS_REQUEST,
	};
}

function fetchArtistTransactionsSuccess(json) {
	return {
		type: ARTIST_TRANSACTIONS_SUCCESS,
		response: json,
	};
}

function fetchArtistTransactionsFailure(err) {
	return {
		type: ARTIST_TRANSACTIONS_FAILURE,
		message: err,
	};
}

export const TRANSACTION_REQUEST = "artworksapp/enquiries/TRANSACTION_REQUEST";
export const TRANSACTION_SUCCESS = "artworksapp/enquiries/TRANSACTION_SUCCESS";
export const TRANSACTION_FAILURE = "artworksapp/enquiries/TRANSACTION_FAILURE";

//Fetches single transaction
export function fetchTransaction(transId) {
	return (dispatch) => {
		dispatch(fetchTransactionRequest());
		const endpoint = `artworks/transaction/${transId}`;
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchTransactionSuccess(json));
			})
			.catch((error) => {
				console.log("fetchTransaction failure", error);
				dispatch(fetchTransactionFailure(error));
			});
	};
}

function fetchTransactionRequest() {
	return {
		type: TRANSACTION_REQUEST,
	};
}

function fetchTransactionSuccess(json) {
	return {
		type: TRANSACTION_SUCCESS,
		response: json,
	};
}

function fetchTransactionFailure(err) {
	return {
		type: TRANSACTION_FAILURE,
		message: err,
	};
}

export const REMOVE_TRANSACTION_REQUEST =
	"artworksapp/enquiries/REMOVE_TRANSACTION_REQUEST";
export const REMOVE_TRANSACTION_SUCCESS =
	"artworksapp/enquiries/REMOVE_TRANSACTION_SUCCESS";
export const REMOVE_TRANSACTION_FAILURE =
	"artworksapp/enquiries/REMOVE_TRANSACTION_FAILURE";

export function removeTransaction(transId) {
	return (dispatch) => {
		dispatch(removeTransactionRequest());
		const endpoint = `artworks/transaction/remove/${transId}`;
		return Api(endpoint, "POST", true)
			.then((json) => {
				dispatch(removeTransactionSuccess(json));
			})
			.catch((error) => {
				console.log("removeTransaction failure", error);
				dispatch(removeTransactionFailure(error));
			});
	};
}

function removeTransactionRequest() {
	return {
		type: REMOVE_TRANSACTION_REQUEST,
	};
}

function removeTransactionSuccess(json) {
	return {
		type: REMOVE_TRANSACTION_SUCCESS,
		response: json,
	};
}

function removeTransactionFailure(err) {
	return {
		type: REMOVE_TRANSACTION_FAILURE,
		message: err,
	};
}
