import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Main from "../../components/Main/Main";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import {
	fetchAnnouncements,
	deleteAnnouncement,
} from "../../actions/AnnouncementActions";

const BIN_SVG = require("../../../assets/images/svg/fontAwesome/fa_trash_bin.svg");

class Announcements extends Component {
	componentWillMount() {
		this.props.dispatch(fetchAnnouncements());
	}

	removeAnnouncement = (id, t) => {
		if (window.confirm(t("deleteWarning"))) {
			this.props.dispatch(deleteAnnouncement(id));
		}
	};

	renderTable(rows, maxItems, t) {
		return (
			<Table
				className="reactable"
				sortable={["title", "createdAt"]}
				defaultSort={{ column: "createdAt", direction: "desc" }}
				filterable={["title"]}
				filterPlaceholder={"Search announcements"}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="title">{t("single.title")}</Th>
					<Th column="createdAt" style={{ width: 150 }}>
						{t("common:createdAt")}
					</Th>
					<Th column="delete" style={{ width: 60 }}>{` `}</Th>
				</Thead>
				{rows}
			</Table>
		);
	}

	renderRows() {
		const { announcements, isFetching, t } = this.props;
		let rows = [];

		const iconStyle = Object.assign(
			{},
			{
				height: "25px",
				width: "auto",
				cursor: "pointer",
			},
			isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
		);

		Object.keys(announcements).forEach((key) => {
			let announcement = announcements[key];
			rows.push(
				<Tr key={announcement._id}>
					<Td column="title" value={announcement.title}>
						<Link to={`/announcements/${announcement.slug}`}>
							{announcement.title}
						</Link>
					</Td>
					<Td column="createdAt" value={announcement.createdAt}>
						<Link to={`/announcements/${announcement.slug}`}>
							{DateUtil.dateMonthShort(
								announcement.created_at,
								i18next.language,
								true
							)}
						</Link>
					</Td>
					<Td column="delete" value={"delete"}>
						<BIN_SVG
							style={iconStyle}
							onClick={() => this.removeAnnouncement(announcement._id, t)}
						/>
					</Td>
				</Tr>
			);
		});
		return rows;
	}

	render() {
		const { announcements, t } = this.props;
		return (
			<div className="announcements">
				<Main>
					<div className="announcements__content">
						<div style={{ marginBottom: "30px" }}>
							<Link to="/announcements/new" className="purple-button--wide">
								{t("createNewAnnouncement")}
							</Link>
						</div>
						{this.renderTable(this.renderRows(), 10, t)}
					</div>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		announcement: { announcements, isFetching },
	} = state;
	return { announcements, isFetching };
}

const translated = withNamespaces("announcement")(Announcements);
export default connect(mapStateToProps)(translated);
