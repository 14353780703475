import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import ExpandButton from "../DashboardComponents/ExpandButton/ExpandButton";
import { fetchFailedEmails } from "../../actions/ArchiveActions";
import { fetchMembers } from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";
import { validateEmail } from "@artworkslab/sharedmodules/src/utils/Helpers";

class FailedEmails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sectionOpen: false,
		};
	}

	componentDidMount() {
		this.fetchData(this.props);
		if (this.props.isSociety || this.props.isAdmin) {
			this.setState({
				sectionOpen: true,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isAdmin && this.props.gallery._id !== nextProps.gallery._id) {
			this.fetchData(nextProps);
		}
	}

	fetchData(props) {
		const { isAdmin, user, gallery } = props;

		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const today = `${year}/${month}`;

		if (isAdmin && gallery) {
			this.props.dispatch(fetchFailedEmails(today, gallery._id || gallery));
			this.props.dispatch(fetchMembers(gallery._id || gallery));
		} else if (user.gallery) {
			this.props.dispatch(
				fetchFailedEmails(today, user.gallery._id || user.gallery)
			);
			this.props.dispatch(fetchMembers(user.gallery._id || user.gallery));
		}
	}

	toggleSectionOpen = () => {
		this.setState({
			sectionOpen: !this.state.sectionOpen,
		});
	};

	getUnvalidated = (members) => {
		const unvalidated = [];
		Object.keys(members).forEach((key) => {
			const member = members[key];
			if (member && member.email && !validateEmail(member.email)) {
				unvalidated.push(member);
			}
		});

		return unvalidated;
	};

	createRows = () => {
		const { failedEmails, members } = this.props;
		const rows = [];
		const bounces = failedEmails.bouncedEmails || [];

		Object.keys(bounces).forEach((key) => {
			const email = failedEmails.bouncedEmails[key].recipient;
			const errorMessage = failedEmails.bouncedEmails[key].error.message;
			const errorDesc = failedEmails.bouncedEmails[key].error.description;
			const error = errorMessage !== "" ? errorMessage : errorDesc;

			rows.push(
				<Tr key={email}>
					<Td column="email" value={email}>
						{email}
					</Td>
					<Td column="error" value={error}>
						{error}
					</Td>
				</Tr>
			);
		});

		const invalid = this.getUnvalidated(members);
		const errorMessage = "Felaktig adress";
		invalid.forEach((member) => {
			rows.push(
				<Tr key={member.email}>
					<Td column="email" value={member.email}>
						{member.email}
					</Td>
					<Td column="error" value={errorMessage}>
						{errorMessage}
					</Td>
				</Tr>
			);
		});

		return rows;
	};

	render() {
		const { isAdmin, gallery, failedEmails, premium, t } = this.props;
		const { sectionOpen } = this.state;

		if (!premium && !isAdmin) {
			return (
				<div className="email-history">
					<legend className="email-history__history-opener">
						{"Misslyckade mejl"}
					</legend>
				</div>
			);
		}

		const rows = this.createRows();

		return (
			<div className="email-history">
				<legend className="email-history__history-opener">
					{"Misslyckade mejl"}
					<ExpandButton
						expanded={sectionOpen}
						click={this.toggleSectionOpen}
						style={{ top: 0 }}
					/>
				</legend>
				<div
					className={
						"email-history__history-section" + (sectionOpen ? " is-open" : "")
					}
				>
					<div className="email-history__email-table">
						<Table
							className="reactable"
							sortable={["email", "error"]}
							defaultSort={{ column: "email", direction: "desc" }}
							filterable={["email", "error"]}
							filterPlaceholder={"Sök bland mejldresser och felmeddelanden"}
							noDataText="Inga misslyckade mejl"
						>
							<Thead>
								<Th column="email">Mejladress</Th>
								<Th column="error">Felmeddelande</Th>
							</Thead>
							{rows}
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: { user },
		archive: { failedEmails, isFetching },
		registry: { members },
	} = state;
	const isAdmin = user.role === "admin";
	const isSociety = user.role === "society";

	return { user, isAdmin, isSociety, failedEmails, members, isFetching };
};

export default connect(mapStateToProps)(FailedEmails);
