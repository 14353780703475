import {
  SHOW_ACCESS_MODAL,
  HIDE_ACCESS_MODAL,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR
}                         from '../../actions/FormActions/AccessFormActions'

export function accessFormReducer(state = {
  showAccessModal: false,
  error: null
}, action) {
  switch (action.type) {
    case SHOW_ACCESS_MODAL:
      return Object.assign({}, state, {
        showAccessModal: true
      })
    case HIDE_ACCESS_MODAL:
      return Object.assign({}, state, {
        showAccessModal: false
      })
    default:
      return state
  }
}
