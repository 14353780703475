import React, { Component } from "react";
// import PropTypes from "prop-types";
import {
	Field,
	Fields,
	FormSection,
	reduxForm,
	formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import TextInput from "../../components/FormInput/TextInput";
import TextArea from "../../components/FormInput/TextArea";
import ImageUpload from "../../components/FormInput/ImageUpload";
import { isValidImage } from "../../utils/Validators";

// const propTypes = {
// 	onSubmit: PropTypes.func.isRequired,
// 	error: PropTypes.string,
// };

class AnnouncementForm extends Component {
	render() {
		const { handleSubmit, pristine, submitting, t } = this.props;
		return (
			<form onSubmit={handleSubmit} className="Form">
				<legend>{t("single.generalInformation")}</legend>
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="title"
							component={TextInput}
							placeholder={t("single.title")}
							required={true}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="text"
							component={TextArea}
							placeholder={t("single.text")}
							required={true}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="link"
							component={TextInput}
							placeholder={t("single.link")}
							required={true}
						/>
					</div>
				</div>
				<div className="fieldset">
					<legend>{t("single.uploadImage")}</legend>
					<Fields
						names={[
							"featuredImage.src",
							"uploadedImage.URI",
							"uploadedImage.fileName",
							"uploadedImage.contentType",
						]}
						component={ImageUpload}
						validate={isValidImage}
					/>
				</div>
				{/*Save button*/}
				<div className="fieldset">
					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

AnnouncementForm = reduxForm({
	form: "announcement",
})(AnnouncementForm);

const selector = formValueSelector("announcement");

export default withNamespaces("announcement")(AnnouncementForm);
