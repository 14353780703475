import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormsy } from "formsy-react";

// Input is a Formsy component. The parent component tells this component what
// validations that should run onChange.
// The built-in valdations can be found here: https://github.com/christianalfoni/formsy-react/blob/master/API.md#validators

class Input extends Component {
	static propTypes = {
		type: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
		placeholder: PropTypes.string,
	};
	static defaultProps = {
		type: "text",
		disabled: false,
		onClick: false,
		className: "",
	};
	changeValue = (event) => {
		if (this.props.type === "checkbox") {
			this.props.setValue(event.currentTarget.checked);
			if (this.props.onChange) {
				this.props.onChange(event.currentTarget.checked);
			}
		} else {
			this.props.setValue(event.currentTarget.value);
			if (this.props.onChange) {
				this.props.onChange(event.currentTarget.value);
			}
		}
	};

	handleClick(e) {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	render() {
		const { props } = this;
		const errorMessage = props.getErrorMessage();
		const value = props.getValue() || "";
		const checked = props.type === "checkbox" ? value : null;

		return (
			<div
				onClick={(e) => this.handleClick(e)}
				className={
					"Input " +
					"Input--" +
					props.type +
					" " +
					(props.showError() ? " is-error " : "") +
					(props.isValid() ? " is-valid " : "") +
					(props.isRequired() ? " is-required " : "")
				}
			>
				<input
					type={props.type}
					onChange={this.changeValue}
					value={value}
					placeholder={props.placeholder}
					checked={checked}
					disabled={props.disabled}
				/>
				{props.checkboxLabel ? (
					<label htmlFor="">{props.checkboxLabel}</label>
				) : null}
				{props.checkboxHtml ? props.checkboxHtml : null}
				{errorMessage ? (
					<span className="Input__error">{errorMessage}</span>
				) : null}
			</div>
		);
	}
}

export default withFormsy(Input);
