import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import { fetchAllGalleriesFast } from "../../actions/GalleryActions";
import { fetchAllArtistsFast } from "../../actions/ArtistActions";
import ArtworksListAndUpload from "../../components/Artworks/ArtworksListAndUpload";
import ListAndUploadResponsive from "../../components/Artworks/ListAndUploadResponsive";

class ArtworksNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			galleryOptions: [],
			artistOptions: [],
			dropdownOptions: [],
			selectedOption: null,
		};
	}

	componentWillMount() {
		const { isAdmin, allGalleries, allArtists } = this.props;

		// Fetch all for non responsive. Fetch by input for resposive, just trying something new to see if it's faster.
		// if (!appGlobals.useResponsive) {
		//   // Don't fetch if already in state.
		//   if (Object.keys(allGalleries).length === 0) this.props.dispatch( fetchAllGalleriesFast() )
		//   if (Object.keys(allArtists).length === 0) this.props.dispatch( fetchAllArtistsFast() )
		// }
	}

	componentWillReceiveProps(nextProps) {
		const { isAdmin, allGalleries, allArtists } = nextProps;

		// Set options for admin to chose gallery/artist from dropdown.
		const galleryKeys = Object.keys(allGalleries);
		const artistKeys = Object.keys(allArtists);
		let galleryOptions = this.state.galleryOptions;
		let artistOptions = this.state.artistOptions;
		let updateOptions = false;

		// if (isAdmin && this.state.dropdownOptions.length !== galleryKeys.length + artistKeys.length) {
		if (galleryOptions.length !== galleryKeys.length) {
			galleryOptions = [];
			galleryKeys.forEach((key) => {
				const venue = allGalleries[key];
				galleryOptions.push({
					value: venue._id,
					label: venue.name,
					type: "venue",
				});
			});

			updateOptions = true;
		}

		if (artistOptions.length !== artistKeys.length) {
			artistOptions = [];
			artistKeys.forEach((key) => {
				const artist = allArtists[key];
				//Value is artist instead of artist._id to allow checking tagged artworks
				artistOptions.push({
					value: artist,
					label: artist.name,
					type: "artist",
				});
			});

			updateOptions = true;
		}

		if (updateOptions) {
			const dropdownOptions = galleryOptions.concat(artistOptions);
			this.setState({
				galleryOptions,
				artistOptions,
				dropdownOptions: dropdownOptions.sort(this.sortOptions),
			});
		}
	}

	sortOptions = (a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	};

	selectOption = (option) => {
		const { allGalleries, allArtists } = this.props;

		this.setState({
			selectedOption: option,
		});
	};

	inputChanged = (input) => {
		// Only fetch when exact numbers of letters entered.
		if (input.length === 2) {
			this.props.dispatch(fetchAllGalleriesFast(input));
			this.props.dispatch(fetchAllArtistsFast(input));
		}
	};

	render() {
		const {
			isAdmin,
			isAuthor,
			isArtist,
			isSociety,
			userGalleryId,
			userArtistId,
			userArtistName,
			allGalleries,
			allArtists,
			artist,
			isFetching,
			t,
		} = this.props;
		const { dropdownOptions, selectedOption } = this.state;
		const isResponsive = appGlobals.useResponsive;
		let galleryId = null;
		let selectedArtist = {};
		let artistName = null;

		if (isAdmin && selectedOption) {
			// gallery = selectedOption && selectedOption.type === 'venue' ? allGalleries[selectedOption.value] : null
			// artist = selectedOption && selectedOption.type === 'artist' ? allArtists[selectedOption.value] : null
			galleryId =
				selectedOption && selectedOption.type === "venue"
					? selectedOption.value
					: false;
			selectedArtist =
				selectedOption && selectedOption.type === "artist"
					? selectedOption.value
					: false;
			artistName =
				selectedOption && selectedOption.type === "artist"
					? selectedOption.label
					: false;
		} else if (isAuthor) {
			galleryId = userGalleryId;
		} else if (isArtist) {
			selectedArtist._id = userArtistId;
			artistName = userArtistName;
		}

		const allArtPageLink = (
			<Link
				to="/artworks-all"
				style={{
					position: "absolute",
					top: "10px",
					right: "60px",
					fontSize: "14px",
					textDecoration: "underline",
				}}
			>
				Admin old artworks page here
			</Link>
		);

		const selectorClass = isResponsive ? "" : "input-holder input-holder--full";
		const adminSelector = (
			<div className={selectorClass}>
				<Select
					value={selectedOption}
					options={dropdownOptions}
					onChange={this.selectOption}
					onInputChange={this.inputChanged}
					valueKey={"galleryOrArtist"}
					labelKey="label"
					backspaceToRemoveMessage=""
					backspaceRemoves={false}
					isLoading={isFetching}
					placeholder={t("list.galleryOrArtist")}
					multi={false}
					autosize={false}
					className={"gallery-picker-select"}
					isSearchable={true}
				/>
			</div>
		);

		if ((isArtist || isAdmin) && isResponsive) {
			return (
				<div>
					<MainResp contentClassName="slim-content">
						{/* isAdmin && allArtPageLink */}
						{isAdmin && adminSelector}
						<ListAndUploadResponsive
							isAdmin={isAdmin}
							isArtist={isArtist}
							isAuthor={isAuthor}
							isSociety={isSociety}
							galleryId={galleryId}
							artistId={selectedArtist._id}
							artistName={artistName}
							artist={isAdmin ? selectedArtist : artist}
							showDropZone={galleryId || selectedArtist._id}
							includeTagged={true}
						/>
					</MainResp>
				</div>
			);
		}

		return (
			<div>
				<Main>
					{isAdmin && allArtPageLink}
					{isAdmin && adminSelector}
					<ArtworksListAndUpload
						isAdmin={isAdmin}
						isArtist={isArtist}
						isAuthor={isAuthor}
						isSociety={isSociety}
						galleryId={galleryId}
						artistId={selectedArtist._id}
						artistName={artistName}
						artist={isAdmin ? selectedArtist : artist}
						showDropZone={galleryId || selectedArtist._id}
						includeTagged={true}
					/>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		gallery: { allGalleries, isFetching: fetchingGallery },
		artist: { allArtists, artist, isFetching: fetchingArtist },
		artwork: { artworks: selectedArtworks, allArtworks },
	} = state;
	const isAuthor = user.role === "author" || user.role === "society";
	const isArtist = user.role === "artist";
	// isAdmin is false here at first, but role is still "admin"
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const isSociety = user.role === "society";
	const artworks = isAdmin ? allArtworks : selectedArtworks;
	const userGalleryId = user.gallery ? user.gallery._id || user.gallery : false;
	const userArtistId = user.artist ? user.artist._id || user.artist : false;
	const userArtistName = user.artist ? user.artist.name || artist.name : false;
	const isFetching = fetchingGallery || fetchingArtist;

	return {
		artworks,
		user,
		isAdmin,
		isAuthor,
		isArtist,
		isSociety,
		allGalleries,
		allArtists,
		isFetching,
		userGalleryId,
		userArtistId,
		userArtistName,
		artist,
	};
}

const translated = withNamespaces("artworks")(ArtworksNew);
export default connect(mapStateToProps)(translated);
