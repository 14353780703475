import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ExpandButton from "../ExpandButton/ExpandButton";
import moment from "moment";
import i18next from "i18next";
import {
	toggleVisits,
	fetchVisitsData,
} from "../../../actions/DashboardActions/VisitsActions";
import { fetchSummaryData } from "../../../actions/DashboardActions/SummaryActions";
import { formatNumber } from "../../../utils/Helpers";

const INFLUENCER_PLACEHOLDER = require("../../../../assets/images/svg/PROFILE-LOGO_LINES_COLOR.svg");
// const INFLUENCER_PLACEHOLDER = require('../../../../assets/images/svg/navbar_profile_60px.svg')
// const INFLUENCER_PLACEHOLDER = require('../../../../assets/images/navbar_profile_60px.png')

const ENG_MONTHS = [
	"january",
	"february",
	"march",
	"april",
	"may",
	"june",
	"july",
	"august",
	"september",
	"october",
	"november",
	"december",
];
const SWE_MONTHS = [
	"januari",
	"februari",
	"mars",
	"april",
	"maj",
	"juni",
	"juli",
	"augusti",
	"september",
	"oktober",
	"november",
	"december",
];

const InfluencerItem = (props) => {
	const source = props.image || INFLUENCER_PLACEHOLDER;
	const name = props.name.split(" ");
	return (
		<div className="Visits__Content__Influencers__InfluencerItem">
			{props.image ? (
				<img
					src={props.image}
					className="Visits__Content__Influencers__InfluencerItem__image"
				/>
			) : (
				<INFLUENCER_PLACEHOLDER className="Visits__Content__Influencers__InfluencerItem__image" />
			)}
			<a href={props.link}>
				{name[0]}
				<br />
				{name[1]}
			</a>
		</div>
	);
};

class Visits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			month: "",
		};
	}

	componentWillMount() {
		const { artist } = this.props;
		this.props.dispatch(fetchVisitsData(artist._id));
		this.props.dispatch(fetchSummaryData(artist._id));

		const date = new Date();
		const monthNum = date.getMonth();
		let prevMonth = "";
		if (i18next.language === "sv") {
			prevMonth = SWE_MONTHS[monthNum];
		} else {
			prevMonth = ENG_MONTHS[monthNum];
		}
		this.setState({
			month: prevMonth,
		});
	}

	drawContent = (visitsData, summaryData, t) => {
		const summaryValue = summaryData.length > 0 ? summaryData[0].value : 0;
		const formatWV = formatNumber(summaryValue);
		const formatTV = formatNumber(visitsData.totalVisits);
		const formatAV = formatNumber(visitsData.avgVisits);
		const formatEnq = formatNumber(visitsData.enquiries);
		const formatTL = formatNumber(visitsData.totalLikes);
		const formatSW = formatNumber(visitsData.shows);

		return (
			<div className="Visits__Content__Stats">
				<div className="Visits__Content__Stats__Stat">
					<h4 className="artist">{t("artist.visitsWeek")}</h4>
					<p>{formatWV}</p>
				</div>
				<div className="Visits__Content__Stats__Stat">
					<h4 className="artist">{t("artist.visitsTotal")}</h4>
					<p>{formatTV}</p>
				</div>
				<div className="Visits__Content__Stats__Stat">
					<h4 className="artist">{t("artist.visitsAvg")}</h4>
					<p>{formatAV}</p>
				</div>
				<div className="Visits__Content__Stats__Stat">
					<h4 className="artist">{t("enquiries")}</h4>
					<p>{formatEnq}</p>
				</div>
				<div className="Visits__Content__Stats__Stat">
					<h4 className="artist">{t("artist.followers")}</h4>
					<p>{formatTL}</p>
				</div>
				<div className="Visits__Content__Stats__Stat">
					<h4 className="artist">{t("shows")}</h4>
					<p>{formatSW}</p>
				</div>
			</div>
		);
	};

	render() {
		const { expanded, visitsData, influencers, summaryData, role, t } =
			this.props;
		const { month } = this.state;
		const content = this.drawContent(visitsData, summaryData, t);

		return (
			<div className="Visits">
				{/*<ExpandButton expanded={ expanded } click={() => this.props.dispatch( toggleVisits() )} />
        <h4 className="underlined">Visits to your page</h4>*/}
				{role === "artist" && (
					<h4
						className="artist"
						style={{
							textAlign: "center",
							color: "black",
							fontWeight: "200",
							fontSize: "28px",
							marginBottom: "40px",
						}}
					>
						{t("insights")}
					</h4>
				)}
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded ? (
							<div className="Visits__Content">
								{content}
								{/* (influencers.length > 0) &&
                <div className={ b('Content')('Influencers') }>
                  <h4 className="artist">{`${t('artist.followersMonth')} ${month}`}</h4>
                  { influencers.map((influencer, index) => <InfluencerItem { ...influencer } key={`influencer_${index}`} />) }
                </div>
              */}
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		visits,
		summary,
		artist: { artist },
	} = state;
	const {
		expanded,
		totalVisits,
		avgVisits,
		totalLikes,
		shows,
		enquiries,
		influencers,
	} = visits;
	const { data: summaryData } = summary;
	const visitsData = { totalVisits, avgVisits, totalLikes, shows, enquiries };
	return {
		expanded,
		visitsData,
		summaryData,
		influencers,
		artist,
	};
}

export default connect(mapStateToProps)(Visits);
