import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PostForm from "./PostForm";
import Main from "../../components/Main/Main";
import { withRouter } from "react-router";
import {
	getFormInitialValues,
	getFormValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import {
	initPost,
	submitPost,
} from "../../actions/FormActions/PostFormActions";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class CreatePost extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.dispatch(initPost());
	}

	componentWillReceiveProps(nextProps) {
		// Redirect to posts/slug so art society can use inform members button immediately after saving.
		if (this.props.form.submitting && !nextProps.form.submitting) {
			if (nextProps.form.post && nextProps.form.post.slug) {
				this.props.router.push(`/posts/${nextProps.form.post.slug}`);
			}
		}
	}

	handleSubmit = () => {
		let {
			form: { post, initial },
		} = this.props;
		if (this.isImageGallery()) post.isImageGallery = true;
		this.props.dispatch(submitPost(post, initial));
	};

	isImageGallery = () => {
		return this.props.location.pathname.indexOf("/imagegallery") === 0;
	};

	render() {
		const { isAdmin, isSociety, gallery, form } = this.props;
		const newImageGallery = this.isImageGallery();

		const postVideo =
			form.post && form.post.videoLink ? form.post.videoLink : null;

		return (
			<div>
				<Main>
					<PostForm
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isSociety={isSociety}
						imageGallery={newImageGallery}
						isPremium={
							__USE_TIERS__ ? isStandardOrAbove(gallery) : isPremium(gallery)
						}
						postVideo={postVideo}
					/>
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin, user },
		gallery: { gallery },
	} = state;
	const isSociety = user.role === "society";
	const form = {
		submitting: isSubmitting("post")(state),
		submitSucceeded: hasSubmitSucceeded("post")(state),
		submitFailed: hasSubmitFailed("post")(state),
		post: getFormValues("post")(state),
		initial: getFormInitialValues("post")(state),
	};
	return { isAdmin, form, isSociety, gallery };
}

export default connect(mapStateToProps)(withRouter(CreatePost));
