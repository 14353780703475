import React        from 'react'
import { connect }  from 'react-redux'
import Artworks     from './Artworks'
import ArtworksNew  from './ArtworksNew'

class AwSplitForRouter extends React.Component {
  render() {
    const { isAdmin, isSociety, isArtist } = this.props
    return (
      <ArtworksNew />
    )
    // return (
    //   <div>
    //     { (isAdmin || isSociety || isArtist) ?
    //       <ArtworksNew />
    //       :
    //       <Artworks />
    //     }
    //   </div>
    // )
  }
}

const mapStateToProps = (state) => {
	const { auth: { user } } = state
	const isSociety = user.role === 'society'
	const isAdmin = user.role === 'admin'
  const isArtist = user.role === 'artist'

	return { isSociety, isAdmin, isArtist }
}

export default connect(mapStateToProps)(AwSplitForRouter)
