import i18next from 'i18next'
import Validator from 'validatorjs'

// Add method to get nested object
const isEmail = (email) => {
  if (email === undefined) {
    return undefined;
  }
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) ? 'Not a valid email' : undefined;
}

const isRequiredText = (text) => {
  if (text === undefined) {
    return undefined;
  }
  if (text.length < 1) {
    return 'Required field!'
  }
  return undefined
}

const isValidImage = (image, allValues) => {
  console.log('validator:', image);
  console.log('validator:', allValues);
  return undefined
}

const validateTime = (value) => {

  const re = /^\d{1,2}(:\d{2})?$/

  if (value && !re.test(value)) {
    return 'Format HH:MM'
  } else {
    return undefined
  }
}

const validatePhone = (value) => {

  const re = /^(\+?\d+)([-\s]?\d+)+$/

  if (value) {
    if (re.test(value)) {
      return undefined
    } else {
      // return 'Not a valid phone number'
      return translate('phone')
    }
  } else {
    return undefined
  }
}

const validateEmail = (value) => {
  // Make sure no special character before @, would validate but entire batch failed in Mailgun. And more letters allowed at the end.
  const re = /^[A-Z0-9._%+-]*[A-Z0-9]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i

  if (value && !re.test(value)) {
    // return 'Invalid email address'
    return translate('email')
  } else {
    return undefined
  }
}

const validateWebAddress = (value) => {

  const re = /^(http|https):\/\/.+\..+/

  if (value && !re.test(value)) {
    return 'Invalid web address'
  } else {
    return undefined
  }
}

const validateEmbed = (value) => {
  // Embeded youtube regex
  const ytRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:embed)?\/)([\w\-]+)(\S+)?$/
  // Embeded vimeo regex
  const vmRegex = /(http|https)?:\/\/(player\.)?vimeo\.com\/(?:video\/)(\d+)/

  // Full youtube regex
  // const ytRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
  // Full vimeo regex
  // const vmRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/

  if (value && !ytRegex.test(value) && !vmRegex.test(value)) {
    return 'Invalid video link'
  } else {
    return undefined
  }
}

const validatePassword = (value) => {
  if (value && value.length < 5) {
    // return 'Password should be at least 5 letters long'
    return translate('password')
  } else {
    return undefined
  }
}

const isTrue = (value) => {
  if (value) {
    return undefined
  } else {
    return 'Accept to proceed'
  }
}

const notEmpty = (value) => {
  // Turned off for now.
  return undefined
  if (value) {
    return undefined
  } else {
    // return 'Field can not be empty'
    return translate('empty')
  }
}

const isNumber = (value) => {
  if (value && isNaN(value)) {
    return translate('number')
  } else {
    return undefined
  }
}

const validateOrgNo = (value) => {
  const re = /^\d{6}-\d{4}$/

  if (value && !re.test(value)) {
    return 'Invalid organisation number'
  } else {
    return undefined
  }
}

const validateVAT = (value) => {
  const re = /^[a-zA-Z]{1,2}[a-zA-Z0-9]{2,12}$/

  if (value && !re.test(value)) {
    return 'Invalid VAT number'
  } else {
    return undefined
  }
}

const validateClearNr = (value) => {
  const re = /^(\d{4})(-\d)?$/

  if(value && !re.test(value)) {
    return 'Invalid clearing number'
  } else {
    return undefined
  }
}

const validateAccNr = (value) => {
  const re = /^\d{2,3}\s\d{3}\s\d{2,4}$/

  if(value && !re.test(value)) {
    return 'Invalid bank account number'
  } else {
    return undefined
  }
}

const translate = (name) => {
  return i18next.t(`common:validate.${name}`)
}

//Check that prices are filled depending on transport option
const validateShipping = (data, isSwedish) => {
  if(!data.shipping) {
    return null
  }
  let shipping = {}
  const shipData = data.shipping
  const swe = shipData.transport === 'swe'
  const eu = shipData.transport === 'swe-eu'
  const global = shipData.transport === 'global'
  const shippingError = isSwedish ? 'Fraktpris är obligatoriskt.' : 'Shipping price is required.'

  //Assure value is numeric
  const swePrice = Number(shipData.swePrice)
  const euPrice = Number(shipData.euPrice)
  const globalPrice = Number(shipData.globalPrice)

  //Adds error if field is relevant but the value is undefined or less than one
  if((swe || eu || global) && !shipData.swePrice && shipData.swePrice !== 0) {
    shipping.swePrice = shippingError
  }
  if((eu || global) && !shipData.euPrice && shipData.euPrice !== 0) {
    shipping.euPrice = shippingError
  }
  if(global && !shipData.globalPrice && shipData.globalPrice !== 0) {
    shipping.globalPrice = shippingError
  }
  return (Object.keys(shipping).length > 0) ? shipping : null
}

const checkInvoiceDetails = (gallery) => {
  const rules = {
    invoiceEmail: 'required',
    VATnumber: 'required',
    orgNumber: 'required',
    invoiceAddress: {
      street: 'required',
      city: 'required',
      zip: 'required',
      country: 'required',
    },
  }

  const validation = new Validator(gallery, rules)
  validation.passes()

  const validatorErros = validation.errors.all()
  const errorCount = Object.keys(validatorErros).length

  return errorCount <= 0
}

const normalizeInstagram = (value) => {
  if (!value) return value;

  if (value.charAt(0) === '@') return value.slice(1);

  return value;
}

export {
  isEmail, isRequiredText, isValidImage,
  validateTime, validatePhone, validateEmail,
  validateWebAddress, validateEmbed, validatePassword,
  isTrue, notEmpty, isNumber, validateOrgNo, validateVAT,
  validateClearNr, validateAccNr, validateShipping, checkInvoiceDetails,
  normalizeInstagram,
}
