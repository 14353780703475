import Api from "@artworkslab/sharedmodules/src/Api";

export const SELECTION_FETCH_REQUEST =
	"artworksapp/shared/SELECTION_FETCH_REQUEST";
export const SELECTION_FETCH_SUCCESS =
	"artworksapp/shared/SELECTION_FETCH_SUCCESS";
export const SELECTION_FETCH_FAILURE =
	"artworksapp/shared/SELECTION_FETCH_FAILURE";

export function fetchSelections(slugOrId) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = slugOrId ? `selections/${slugOrId}` : "selections/all";
			const method = "GET";

			dispatch(fetchSelectionsRequest());
			Api(endpoint, method, true)
				.then((json) => {
					dispatch(fetchSelectionsSuccess(json));
					resolve(json);
				})
				.catch((err) => {
					console.log("fetchSelections error", err);
					dispatch(fetchSelectionsFailure(err));
					reject(err);
				});
		});
}

function fetchSelectionsRequest() {
	return {
		type: SELECTION_FETCH_REQUEST,
	};
}

function fetchSelectionsSuccess(selections) {
	return {
		type: SELECTION_FETCH_SUCCESS,
		response: selections,
	};
}

function fetchSelectionsFailure(error) {
	return {
		type: SELECTION_FETCH_FAILURE,
		message: error,
	};
}

export const SELECTION_SAVE_REQUEST =
	"artworksapp/shared/SELECTION_SAVE_REQUEST";
export const SELECTION_SAVE_SUCCESS =
	"artworksapp/shared/SELECTION_SAVE_SUCCESS";
export const SELECTION_SAVE_FAILURE =
	"artworksapp/shared/SELECTION_SAVE_FAILURE";

export function saveSelection(selection, newItem = null) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = selection._id
				? `selections/${selection._id}`
				: "selections/";
			const method = selection._id ? "PUT" : "POST";
			const body = { selection, newItem };

			dispatch(saveSelectionRequest());
			Api(endpoint, method, true, body)
				.then((json) => {
					dispatch(saveSelectionSuccess(json));
					resolve(json);
				})
				.catch((err) => {
					console.log("saveSelection error", err);
					dispatch(saveSelectionFailure(err));
					reject(err);
				});
		});
}

function saveSelectionRequest() {
	return {
		type: SELECTION_SAVE_REQUEST,
	};
}

function saveSelectionSuccess(selection) {
	return {
		type: SELECTION_SAVE_SUCCESS,
		response: selection,
		message: "Selection saved!",
	};
}

function saveSelectionFailure(error) {
	return {
		type: SELECTION_SAVE_FAILURE,
		message: error,
	};
}

export function addArtistsFromGallery(selectionId, type) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = "selections/artistsfromgallery";
			const method = "PUT";
			const body = { selectionId, type };

			dispatch(saveSelectionRequest());
			Api(endpoint, method, true, body)
				.then((json) => {
					dispatch(saveSelectionSuccess(json));
				})
				.catch((err) => {
					console.log("addArtistsFromGallery error", err);
					dispatch(saveSelectionFailure(err));
				});
		});
}

export const SELECTION_ADD_ITEM_REQUEST =
	"artworksapp/shared/SELECTION_ADD_ITEM_REQUEST";
export const SELECTION_ADD_ITEM_SUCCESS =
	"artworksapp/shared/SELECTION_ADD_ITEM_SUCCESS";
export const SELECTION_ADD_ITEM_FAILURE =
	"artworksapp/shared/SELECTION_ADD_ITEM_FAILURE";

export function addItem(id, listName, itemId, doAdd) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = `selections/${id}/additem`;
			const method = "PUT";

			dispatch(addItemRequest());
			Api(endpoint, method, true, { listName, itemId, doAdd })
				.then((json) => {
					dispatch(addItemSuccess(json));
					resolve(json);
				})
				.catch((err) => {
					console.log("addItem error", err);
					dispatch(addItemFailure(err));
					reject(err);
				});
		});
}

function addItemRequest() {
	return {
		type: SELECTION_ADD_ITEM_REQUEST,
	};
}

function addItemSuccess(id) {
	return {
		type: SELECTION_ADD_ITEM_SUCCESS,
		response: id,
	};
}

function addItemFailure(error) {
	return {
		type: SELECTION_ADD_ITEM_FAILURE,
		message: error,
	};
}

export const SELECTION_REMOVE_REQUEST =
	"artworksapp/shared/SELECTION_REMOVE_REQUEST";
export const SELECTION_REMOVE_SUCCESS =
	"artworksapp/shared/SELECTION_REMOVE_SUCCESS";
export const SELECTION_REMOVE_FAILURE =
	"artworksapp/shared/SELECTION_REMOVE_FAILURE";

export function removeSelection(id) {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			const endpoint = `selections/${id}`;
			const method = "DELETE";

			dispatch(removeSelectionRequest());
			Api(endpoint, method, true)
				.then((json) => {
					dispatch(removeSelectionSuccess(json));
					resolve(json);
				})
				.catch((err) => {
					console.log("removeSelection error", err);
					dispatch(removeSelectionFailure(err));
					reject(err);
				});
		});
}

function removeSelectionRequest() {
	return {
		type: SELECTION_REMOVE_REQUEST,
	};
}

function removeSelectionSuccess(id) {
	return {
		type: SELECTION_REMOVE_SUCCESS,
		response: id,
	};
}

function removeSelectionFailure(error) {
	return {
		type: SELECTION_REMOVE_FAILURE,
		message: error,
	};
}
