import React, { Component } from "react";
import { withRouter, Link } from "react-router";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Main from "../../components/Main/Main";
import { fetchPrints, fetchPrintArtists } from "../../actions/PrintActions";
import {
	submitPrint,
	initPrint,
} from "../../actions/FormActions/PrintFormActions";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import PrintForm from "./PrintForm";

class Print extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(initPrint());
		this.props.dispatch(fetchPrintArtists());
	}

	componentWillReceiveProps(nextProps) {
		// Redirect to prints/slug after saving.
		if (this.props.form.submitting && !nextProps.form.submitting) {
			if (nextProps.print && nextProps.print.slug) {
				this.props.router.push(`/prints/${nextProps.print.slug}`);
			}
		}
	}

	handleSubmit = () => {
		const {
			isAdmin,
			form: { print, initial },
		} = this.props;
		this.props.dispatch(submitPrint(print, initial));
	};

	render() {
		const { isAdmin, print, prints, t } = this.props;
		const printId = this.props.params.printId;

		if (!isAdmin) return null;

		return (
			<Main>
				<div className="prints">
					<div style={{ marginBottom: "30px" }}>
						<Link
							to="/prints"
							className="purple-button--wide purple-button--wide--mod_create"
							style={{ minWidth: "180px" }}
						>
							{t("list.stepBack")}
						</Link>
					</div>
					<PrintForm
						onSubmit={this.handleSubmit}
						print={print}
						printArtists={this.props.printArtists}
						isFetching={this.props.isFetching}
						t={t}
					/>
				</div>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		print: { print, allPrints: prints, printArtists, isFetching },
	} = state;
	const isAdmin = user.role === "admin";

	const form = {
		submitting: isSubmitting("print")(state),
		submitSucceeded: hasSubmitSucceeded("print")(state),
		submitFailed: hasSubmitFailed("print")(state),
		print: getFormValues("print")(state),
		initial: getFormInitialValues("print")(state),
	};

	return { isAdmin, print, prints, printArtists, isFetching, form };
}

const translated = withNamespaces("artworks")(Print);
export default connect(mapStateToProps)(withRouter(translated));
