import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import ArtistProLink from "../../components/ArtworksLite/ArtistProLink";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";
import Emails from "../Emails/Emails";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import { showCustomModal } from "../../actions/AppActions";
import {
	fetchContactLists,
	deleteContactlist,
} from "@artworkslab/sharedmodules/src/actions/ContactActions";

const BIN_PNG = require("../../../assets/images/icons/trashcan.png");

class ContactLists extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openProModal: false,
		};
	}

	componentWillMount() {
		const { isAdmin, isArtist, user } = this.props;
		if (isAdmin) {
			this.props.dispatch(fetchContactLists());
		} else if (isArtist && user.id) {
			this.props.dispatch(fetchContactLists(false, user.id));
		}
	}

	componentWillReceiveProps(nextProps) {
		const nextArtistId = nextProps.artist ? nextProps.artist._id : false;

		if (nextArtistId && !isPremium(nextProps.artist)) {
			this.props.router.push("/artworks");
		}
	}

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	hasProp = (object, key) => {
		if (object) {
			return object.hasOwnProperty(key);
		} else {
			return false;
		}
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	// TODO: Remove later
	showProModal = () => {
		const content = (
			<div>
				<p>
					Här kan du som <i>PRO</i> samla alla dina kontakter <br />
					på en och samma plats, tryggt uppe i molnet.
				</p>
				{/*<p>Under Juli fortsätter vi fira 100! Därmed erbjuder vi <b>alla nya</b> <i>PRO</i> <b>medlemmar halva priset</b> vid årlig fakturering.</p>*/}
				<p>
					<i>PRO</i> konstnärer har flest besök i sina profiler, står för ¼ av
					all försäljning 2021* och har lägre kommission.
				</p>
				<p>
					Läs mer och testa <i>PRO</i> gratis <ArtistProLink children={"här"} />
					! 🎨
				</p>
				<div className="modal-footnote">*Enligt data från 18/8 2021</div>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	removeContactlist = (contactList, t) => {
		if (window.confirm(t("deleteWarning"))) {
			this.props.dispatch(deleteContactlist(contactList._id));
		}
	};

	renderTable(isAdmin, rows, maxItems, t) {
		return (
			<Table
				className="reactable"
				sortable={["name", "createdBy"]}
				defaultSort={{ column: "name", direction: "desc" }}
				filterable={isAdmin ? ["name", "createdBy"] : false}
				filterPlaceholder={"Search contact lists"}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					{isAdmin && <Th column="image">{t("image")}</Th>}
					<Th column="name">{t("list")}</Th>
					{isAdmin && (
						<Th column="editedBy" style={{ width: 150 }}>
							{t("common:editedBy")}
						</Th>
					)}
					{isAdmin && (
						<Th column="updatedAt" style={{ width: 150 }}>
							{t("common:updatedAt")}
						</Th>
					)}
					{isAdmin && (
						<Th column="createdBy" style={{ width: 150 }}>
							{t("common:createdBy")}
						</Th>
					)}
					{isAdmin && (
						<Th column="createdAt" style={{ width: 150 }}>
							{t("common:createdAt")}
						</Th>
					)}
					<Th column="delete" style={{ width: 60 }}>{` `}</Th>
				</Thead>
				{rows}
			</Table>
		);
	}

	renderRows(contactLists, t) {
		const { isFetching } = this.props;

		let rows = [];
		const that = this;

		const iconStyle = Object.assign(
			{},
			{
				height: "25px",
				width: "auto",
				cursor: "pointer",
			},
			isFetching ? { opacity: 0.1, pointerEvents: "none" } : {}
		);

		Object.keys(contactLists)
			.sort((a, b) => {
				let aObject = contactLists[a];
				let bObject = contactLists[b];
				return bObject.name - aObject.name;
			})
			.forEach((key) => {
				let contactList = contactLists[key];
				const image = contactList.featuredImage || "";
				const thumbnailSrc = image ? this.getImageSrc(image) : "";
				const createdBy = that.hasProp(contactList.created_by, "name")
					? contactList.created_by.role === "admin"
						? "Artworks"
						: contactList.created_by.name
					: "N/A";
				const editedBy = that.hasProp(contactList.edited_by, "name")
					? contactList.edited_by.name
					: "N/A";

				rows.push(
					<Tr key={contactList._id}>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							{image ? (
								<Link to={`/contactlists/${contactList.slug}`}>
									<img
										src={thumbnailSrc}
										className="table-image"
										onError={(e) =>
											this.thumbnailError(e, e.target.src, image.src)
										}
										alt="ContactList image"
									/>
								</Link>
							) : null}
						</Td>
						<Td column="name" value={contactList.name}>
							<Link to={`/contactlists/${contactList.slug}`}>
								{contactList.name}
							</Link>
						</Td>
						<Td column="editedBy" value={editedBy}>
							<Link to={`/contactlists/${contactList.slug}`}>{editedBy}</Link>
						</Td>
						<Td column="updatedAt" value={contactList.updated_at}>
							<Link to={`/contactlists/${contactList.slug}`}>
								{DateUtil.dateMonthShort(
									contactList.updated_at,
									i18next.language,
									true
								)}
							</Link>
						</Td>
						<Td column="createdBy" value={createdBy}>
							<Link to={`/contactlists/${contactList.slug}`}>{createdBy}</Link>
						</Td>
						<Td column="createdAt" value={contactList.created_at}>
							<Link to={`/contactlists/${contactList.slug}`}>
								{DateUtil.dateMonthShort(
									contactList.created_at,
									i18next.language,
									true
								)}
							</Link>
						</Td>
						<Td column="delete" value={"delete"}>
							<img
								src={BIN_PNG}
								style={iconStyle}
								onClick={() => this.removeContactlist(contactList, t)}
							/>
						</Td>
					</Tr>
				);
			});

		return rows;
	}

	render() {
		const { isAdmin, isArtist, contactLists, artist, t } = this.props;
		const isResponsive = appGlobals.useResponsive;
		const MainComponent = isResponsive ? MainResp : Main;
		return (
			<div className="contact-lists">
				<MainComponent contentClassName="slim-content">
					<div className="contact-lists__content">
						<div style={{ marginBottom: "30px" }}>
							{isPremium(artist) || !isArtist ? (
								<Link to="/contactlists/new" className="purple-button--square">
									{t("createNewList")}
								</Link>
							) : (
								<button
									className="purple-button--square button-nomargin"
									onClick={() => this.showProModal()}
								>
									{t("createNewList")}
								</button>
							)}
						</div>
						{contactLists.length > 0 ? (
							<div>
								{this.renderTable(
									isAdmin,
									this.renderRows(contactLists, t),
									10,
									t
								)}
							</div>
						) : (
							<div className="contact-lists__content__empty">
								<p>{t("empty")}</p>
							</div>
						)}
						{isAdmin && <Emails />}
					</div>
				</MainComponent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		contactList: { contactLists, isFetching },
		artist: { artist },
	} = state;
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	const isArtist = state.auth.isArtist || user.role === "artist";
	return { isAdmin, isArtist, user, contactLists, artist, isFetching };
}

const translated = withNamespaces("contactlist")(ContactLists);
export default connect(mapStateToProps)(withRouter(translated));
