'use strict'
import React, { Component } from 'react'
import RichTextEditor       from 'react-rte';
import Editor               from '../../Editor/Editor'
import { DRAFT_DECORATOR }  from '../../Editor/editorhelpers'
import { DraftJS }          from 'megadraft'
import { fromJS }           from 'immutable'

class FormEditor extends Component {
  constructor(props){
    super(props)
    this.state = {
      draftContentState: null,
      htmlContent: false,
      markup: null,
      rawEditorState: null,
    }
  }

  componentDidMount(){
    const thisDraftContentState = this.getPropContent(this.props).draftContentState

    if (typeof thisDraftContentState.input.value === 'string' && thisDraftContentState.input.value.length > 0) {
      const draftContentState = JSON.parse(thisDraftContentState.input.value)
      this.setState({ draftContentState })
    }
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  componentWillReceiveProps(nextProps){
    const thisDraftContentState = this.getPropContent(this.props).draftContentState
    const nextDraftContentState = this.getPropContent(nextProps).draftContentState

    if (thisDraftContentState.input.value !== nextDraftContentState.input.value) {
      if (typeof nextDraftContentState.input.value === 'string' &&
          nextDraftContentState.input.value.length > 0) {
        const draftContentState = JSON.parse(nextDraftContentState.input.value)
        this.setState({ draftContentState })
      }
    }
  }

  // Retrieves appropriate draftContentState and htmlContent
  getPropContent = (props) => {
    const draftContentState =
      props.draftContentState ||
      props.draftContentStateAbout ||
      props.artSocietyData.draftContentStateMember
    const htmlContent =
      props.htmlContent ||
      props.htmlContentAbout ||
      props.artSocietyData.htmlContentMember

    return { draftContentState, htmlContent }
  }

  onEditorUpdate = (markup, rawEditorState) => {
    const { htmlContent, draftContentState } = this.getPropContent(this.props)

    // console.log('UPDATING EDITOR');
    htmlContent.input.onChange(markup)
    htmlContent.input.onBlur(markup)
    draftContentState.input.onChange(rawEditorState)
    draftContentState.input.onBlur(rawEditorState)
  }

  // Set content to be updated and tell form that editor content is changed.
  setUpdateContent = (markup, rawEditorState) => {
    this.setState({
      markup,
      rawEditorState,
    })
    this.props.onEditorChange()
  }

  // Only run onChange and onBlur after button press from parent. Otherwise images can end up on top in editor.
  // Possibly because these updates are run while image is being uploaded.
  updateContentFromParent = () => {
    const { markup, rawEditorState } = this.state
    if (markup && rawEditorState) {
      const { htmlContent, draftContentState } = this.getPropContent(this.props)

      htmlContent.input.onChange(markup)
      htmlContent.input.onBlur(markup)
      draftContentState.input.onChange(rawEditorState)
      draftContentState.input.onBlur(rawEditorState)
    }
  }

  render(){
    const { draftContentState } = this.state
    return (
      <Editor
        editorState={ draftContentState }
        onEditorLooseFocus={ this.onEditorUpdate }
        updateContent={ this.setUpdateContent }
        />
    )
  }
}

export default FormEditor
