'use strict'
import {
  saveRaffle,
  removeRaffle,
}                               from '../RaffleActions'
// import { saveRaffle }           from '../../ArtSocietyActions'
import { showErrorModal }       from '../AppActions'
import {
  initialize,
  startSubmit,
  stopSubmit,
  change,
} from 'redux-form'
import Validator                from 'validatorjs'
import sv                       from './sv'
import i18next                  from 'i18next'
import diff                     from 'object-diff'

export const SHOW_SUCCESS = 'artworksapp/raffle/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/raffle/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/raffle/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/raffle/HIDE_ERROR'

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

export function submitRaffle(data, initial) {
  return (dispatch, getState) => {
    const { user, isAdmin } = getState().auth
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    let rules = {
      title: 'required',
    }
    if (!isAdmin) {
      data.gallery = user.gallery
    } else {
      //Specific validation for admin
      if (!data.gallery) {
        customErrors.gallery = 'Gallery missing.'
      }
    }

    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if (isSwedish) {
      validation.setAttributeNames({
        title: 'Titel',
      })
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)

    let changedValues = initial ? diff(initial, data) : data
    if (data._id) changedValues._id = data._id

    if (Object.keys(errors).length < 1) {
      dispatch( startSubmit('raffle') )
      return dispatch( saveRaffle( changedValues ) )
      .then(data => {
        dispatch( initialize('raffle', data, null, { keepSubmitSucceeded: true }) )
      })
      .catch(err => {
        console.log('ERROR', err);
      })
    } else {
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('raffle', errors) )
      dispatch( showErrorModal(translate('requiredFields')) )
    }
  }
}

export function deleteRaffle(data) {
  return dispatch => {
    dispatch( removeRaffle(data) )
  }
}
