import {
	TOGGLE_EXPANDED, DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE
} from '../../actions/DashboardActions/CurrentShowActions'

export function currentShowReducer(state = {
		isFetching: false,
		authenticated: false,
		data: {
			visitors: '',
			popular: '',
		},
		shows: [],
    expanded: true
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		case DATA_REQUEST:
		const test = 5
			return Object.assign({}, state, {
				isFetching: true
			});
		case DATA_SUCCESS: {
			let data = {
				visitors: 0,
				popular: 0
			}
			action.response.shows.map((show) => { data.visitors = data.visitors + show.visits })
			return Object.assign({}, state, {
				data,
				isFetching: false,
				shows: action.response.shows,
			});
		}
		case DATA_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			});
		default:
			return state
	}
}
