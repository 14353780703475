import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	fetchGallery,
	removeGallery,
	copyGallery,
} from "../../actions/GalleryActions";
import {
	submitGallery,
	showSuccess,
	hideSuccess,
	initGallery,
	loadGallery,
	hideError,
} from "../../actions/FormActions/GalleryFormActions";
import { fetchArtworks } from "../../actions/FormActions/ArtworkFormActions";
import Main from "../../components/Main/Main";
import { syncUser } from "../../actions/UserActions";
import GalleryForm from "./GalleryForm";
import { withNamespaces } from "react-i18next";
import Modal from "../../components/Modal/Modal";
import OpeningHourPicker from "../../components/OpeningHourPicker/OpeningHourPicker";
import MetaButton from "../../components/MetaButton/MetaButton";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import {
	ART_SOCIETY_ARRAY,
	isVenueArtSociety,
} from "@artworkslab/sharedmodules/src/utils/Helpers";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class Gallery extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = { copyLink: "" };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.gallery.deletedGallery) {
			return this.props.router.push("/galleries");
		}
		if (this.props.gallery.gallery !== nextProps.gallery.gallery) {
			this.props.dispatch(loadGallery(nextProps.gallery.gallery));
		}
		if (this.props.isAdmin !== nextProps.isAdmin && nextProps.isAdmin) {
			const { galleryId } = this.props.params;
			if (galleryId !== "new") {
				this.props.dispatch(fetchGallery(galleryId));
				this.props.dispatch(fetchArtworks(galleryId));
			} else {
				this.props.dispatch(initGallery());
			}
		}
	}

	componentDidMount() {
		const {
			isAdmin,
			user: { gallery },
		} = this.props;
		if (isAdmin) {
			const { galleryId } = this.props.params;
			if (galleryId !== "new") {
				this.props.dispatch(fetchGallery(galleryId));
				this.props.dispatch(fetchArtworks(galleryId));
			} else {
				this.props.dispatch(initGallery());
			}
		} else {
			const galleryId = gallery._id ? gallery._id : gallery;
			// Check if galleryId exists, user admin not necessarily admin on Did Mount.
			if (galleryId && galleryId !== "new") {
				this.props.dispatch(fetchGallery(galleryId));
				this.props.dispatch(fetchArtworks(galleryId));
			}
		}
	}

	handleRemove = () => {
		const {
			form: { gallery },
		} = this.props;
		this.props.dispatch(removeGallery(gallery._id));
	};
	submit = () => {
		const {
			form: { gallery, initial },
		} = this.props;
		this.props.dispatch(submitGallery(gallery, initial));
	};

	getSelectedVenueType = (form) => {
		return form.gallery && form.gallery.venueType ? form.gallery.venueType : "";
	};

	copyThisGallery = () => {
		const {
			gallery: {
				gallery: { _id },
			},
			t,
		} = this.props;
		let confirm = window.confirm(t("common:areYouSure"));
		if (!confirm) {
			return;
		}
		this.props
			.dispatch(copyGallery(_id))
			.then((result) => {
				console.log("result:", result);
				this.setState({ copyLink: result.response });
				//this.props.router.push(`/galleries/${result.response}`)
			})
			.catch((err) => {
				console.log("copyThisGallery error", err);
			});
	};

	render() {
		const {
			isAdmin,
			gallery,
			params: { galleryId },
			form,
			t,
		} = this.props;
		const { copyLink } = this.state;
		const isAssociation = isVenueArtSociety(gallery.gallery);
		const selectedVenueType = this.getSelectedVenueType(form);
		const associationSelectedInForm =
			ART_SOCIETY_ARRAY.indexOf(selectedVenueType) >= 0;
		let isArtSociety = isAdmin ? associationSelectedInForm : isAssociation;
		const reqUpdateId =
			gallery.gallery && gallery.gallery.updateMembership
				? gallery.gallery.updateMembership.requesterId
				: null;

		return (
			<div>
				<Main>
					<GalleryForm
						onSubmit={this.submit}
						isAdmin={isAdmin}
						isAssociation={isArtSociety}
						standardOrAbove={
							__USE_TIERS__
								? isStandardOrAbove(gallery.gallery)
								: isPremium(gallery.gallery)
						}
						isNewForm={galleryId === "new"}
						name={gallery.gallery ? gallery.gallery.name : false}
						reqUpdateId={reqUpdateId}
					/>
					{isAdmin && galleryId !== "new" && (
						<SelectionPicker
							type="galleries"
							item={this.props.gallery.gallery}
						/>
					)}
					{isAdmin && (
						<div
							className="fieldset"
							style={{ paddingTop: 40, justifyContent: "space-between" }}
						>
							<legend>Danger zone</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={() => {
									let confirm = window.confirm("Are you sure?");
									if (confirm) this.handleRemove();
								}}
							>
								Delete venue
							</button>
							<MetaButton item={this.props.gallery.gallery} />
							{(__DEVELOPMENT__ || __STAGING__) && (
								<div className="fieldset" style={{ marginTop: "30px" }}>
									<div className="input-holder input-holder--full input-holder--nopadding">
										<legend>Create copy of society page.</legend>
										<button
											className="purple-button--wide button-somemargin"
											onClick={this.copyThisGallery}
										>
											Copy
										</button>
										{copyLink && (
											<Link
												to={`/galleries/${copyLink}`}
												target="_blank"
												style={{ marginTop: "15px", marginLeft: "60px" }}
											>
												{t("single.copyDirect")}
											</Link>
										)}
									</div>
								</div>
							)}
						</div>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		gallery,
		auth: { user, isAdmin },
	} = state;
	const form = {
		submitting: isSubmitting("gallery")(state),
		submitSucceeded: hasSubmitSucceeded("gallery")(state),
		submitFailed: hasSubmitFailed("gallery")(state),
		gallery: getFormValues("gallery")(state),
		initial: getFormInitialValues("gallery")(state),
	};
	return { gallery, user, form, isAdmin };
}

const translated = withNamespaces("gallery")(Gallery);
export default connect(mapStateToProps)(withRouter(translated));
