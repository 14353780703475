import React, { Component } from "react";
// import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";

const MAX_MIB = 204800;
const ACCEPT = "image/jpeg,image/jpg,image/png";
const CLOSE_CROSS = require("../../../assets/images/icons/close@2x.png");

// const propTypes = {
// 	defaultImage: PropTypes.any,
// };

const defaultProps = {
	defaultImage: false,
};

const validImage = (value, allValues, props) => {
	return undefined;
};

class ImageUploadCustomName extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onDrop = (acceptedFiles, e) => {
		const reader = new FileReader();
		const {
			[this.props.uploadedName]: { URI, contentType, fileName },
			t,
		} = this.props;
		const uploadedFile = acceptedFiles[0];

		//Load preview
		reader.onload = (upload) => {
			// Uploaded image
			const image = {
				URI: upload.target.result,
				fileName: uploadedFile.name,
				contentType: uploadedFile.type,
			};

			// Set state for preview
			this.setState({
				previewImage: uploadedFile,
			});

			// Update input states
			URI.input.onChange(image.URI);
			contentType.input.onChange(image.contentType);
			fileName.input.onChange(image.fileName);
			URI.input.onBlur(image.URI);
			contentType.input.onBlur(image.contentType);
			fileName.input.onBlur(image.fileName);
		};

		// Read data file
		if (uploadedFile) {
			reader.readAsDataURL(uploadedFile);
		}
	};

	dropRejected = (rejectedFiles, e) => {
		const { t } = this.props;
		const rejectedFile = rejectedFiles[0];

		this.showImageError(rejectedFile, t);
	};

	showImageError = (rejectedFile, t) => {
		// console.log('REJECTED:', rejectedFile);
		if (rejectedFile.size > (this.props.maxSize || MAX_MIB)) {
			window.alert(t("imageUpload.imageTooBig2"));
		}
		if (ACCEPT.indexOf(rejectedFile.type) === -1) {
			window.alert(t("imageUpload.imageWrongFormat"));
		}
	};

	removeImage = () => {
		const {
			[this.props.imageName]: { src },
			[this.props.uploadedName]: { URI, contentType, fileName },
			t,
		} = this.props;
		const confirm = window.confirm(t("imageUpload.removeAreYouSure"));
		if (confirm) {
			// Only src of image will be set to null.
			// Other values remain and image (with src = null) will be sent as params to API when saving.
			// Remove rest of image data when saving/updating a form that uses this component.
			// Need to set src to null or image won't disappear from preview.
			src.input.onChange(null);
			src.input.onBlur(null);

			// When adding image and then removing it without saving first, this.props[uploadedName] will still be there,
			// looks like this: { URI: ..., contentType: ..., fileName: ... }
			// Set URI to null and test for it in imageHelper.js -> setFeaturedImage() in API
			URI.input.onChange(null);
			URI.input.onBlur(null);
			contentType.input.onChange(null);
			contentType.input.onBlur(null);
			fileName.input.onChange(null);
			fileName.input.onBlur(null);

			this.setState({
				previewImage: null,
			});
		}
	};

	renderPreviewImage = (t) => {
		const { previewImage } = this.state;
		const image = this.props[this.props.imageName].src.input.value || null;
		const src = previewImage ? previewImage.preview : image;

		if (src) {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-image">
						{/*<img className={b('preview-image-img')} src={src} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '250px' }}/>*/}
						<img className="ImageUpload__preview-image-img" src={src} />
					</div>
				</div>
			);
		} else {
			return (
				<div className="ImageUpload__preview">
					<div className="ImageUpload__preview-placeholder">
						<div>{t("imageUpload.previewHere")}</div>
					</div>
				</div>
			);
		}
	};

	render() {
		const { imageName, uploadedName, t } = this.props;
		const hasFeaturedImg = this.props[imageName].src.input.value ? true : false;
		const hasUploadedImg =
			this.props[uploadedName].URI.input.value &&
			this.props[uploadedName].fileName.input.value &&
			this.props[uploadedName].contentType.input.value
				? true
				: false;
		const valid = hasFeaturedImg || hasUploadedImg;
		const error = this.props[imageName].src.meta.error || false;
		const required = this.props.required ? true : false;
		return (
			<div
				className={
					"ImageUpload" +
					(error ? " is-error" : "") +
					(valid ? " is-valid" : "") +
					(required ? " is-required" : "")
				}
			>
				<div className="input-holder">
					<input type="hidden" name={`${uploadedName}.URI`} />
					<input type="hidden" name={`${uploadedName}.fileName`} />
					<input type="hidden" name={`${uploadedName}.contentType`} />
					<Dropzone
						onDrop={this.onDrop}
						onDropRejected={this.dropRejected}
						className={"ImageUpload__dropzone"}
						accept={ACCEPT}
						multiple={false}
						maxSize={this.props.maxSize || MAX_MIB}
					>
						<div>{t("imageUpload.dropHere")}</div>
					</Dropzone>
					{error ? (
						<span className="Input__error" style={{ marginTop: "10px" }}>
							{error}
						</span>
					) : null}
				</div>
				<div className="input-holder">
					{this.renderPreviewImage(t)}
					<button
						className={"close-button"}
						onClick={this.removeImage}
						disabled={!hasFeaturedImg && !hasUploadedImg}
						style={{ backgroundImage: `url(${CLOSE_CROSS})` }}
						type="button"
					/>
				</div>
			</div>
		);
	}
}

export default withNamespaces("components")(ImageUploadCustomName);
