import {  saveEvent,
          deleteEvent,
          updateEvent }       from '../EventActions'
import { showErrorModal }     from '../AppActions'

import { initialize,
         change,
         startSubmit,
         stopSubmit }         from 'redux-form'

import Validator              from 'validatorjs'
import sv                     from './sv'
import moment                 from 'moment'
import diff                   from 'object-diff'
import i18next            from 'i18next'

import {  getObjectProp,
          hasObjectProp }     from '../../utils/Helpers'

export const LOAD = 'artworksapp/event/LOAD'
export const SUBMIT = 'artworksapp/event/SUBMIT'

export const SHOW_SUCCESS = 'artworksapp/event/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/event/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/event/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/event/HIDE_ERROR'

const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

export function submitEvent(data, initial) {
  return (dispatch, getState) => {
    const { user, isAdmin } = getState().auth
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    let rules = {
      title: 'required',
      email: 'email'
    }

    if (!isAdmin) {
      data.gallery = user.gallery
      data.artist = user.artist
    }else{
      // Specific validation for admin
      if (!data.gallery && !data.artist){
        customErrors.gallery = 'Gallery or artist required.'
      }
    }
    // if (!data.locationName && !data.location) {
    //   customErrors.locationName = isSwedish ? 'Adress är obligatoriskt.' : 'Location is required.'
    // }
    if ((hasObjectProp(data, 'uploadedImage.URI') &&
        hasObjectProp(data, 'uploadedImage.contentType') &&
        hasObjectProp(data, 'uploadedImage.fileName'))
        || hasObjectProp(data, 'featuredImage.src')) {
          console.log('image is valid');
    }else{
      // Image not required anymore. A completely black base64 image provided in add() in events.controller.js
      // console.log('image is invalid');
      // customErrors.featuredImage = {src: isSwedish ? 'Bild är obligatoriskt.' : 'Image is required.'}
    }

    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if (isSwedish) {
      validation.setAttributeNames({
        title: 'Titel',
        email: 'Email',
      })
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)

    const changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined

    // Need to find removed ones. Could be updated in db from webb.
    if (changedValues.rsvp && initial.rsvp) {
      const removedRsvpIds = findRemoved(data.rsvp, initial.rsvp)
      changedValues.removedRsvpIds = removedRsvpIds
    }
    if (changedValues.waitingList && initial.waitingList) {
      const removedWaitingListIds = findRemoved(data.waitingList, initial.waitingList)
      changedValues.removedWaitingListIds = removedWaitingListIds
    }

    if (Object.keys(errors) < 1) {
      dispatch( startSubmit('event') )
      if (data._id) {
        return dispatch( updateEvent(changedValues) )
        .then((data) => {
          let item = data.response
          dispatch( initialize('event', item, null, { keepSubmitSucceeded: true }))
        })
        .catch((err) => {
          console.log('ERROR', err);
        })
      }else{
        return dispatch( saveEvent(changedValues) ).then((data) => {
          let item = data.response
          dispatch( initialize('event', item, null, { keepSubmitSucceeded: true }))
        })
        .catch((err) => {
          console.log('ERROR', err);
        })
      }
    }else{
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('event', errors) )
      dispatch( showErrorModal(translate('requiredFields')) )
    }
  }
}

const findRemoved = (dataList, initialList) => {
  const removedIds = []

  let initL = initialList.length
  while(initL--) {
    const initId = initialList[initL]
    const initNameEmail = `${initId.name || ''}${initId.email || ''}`
    let rsvpRemoved = true
    let dataL = dataList.length
    while(dataL--) {
      if (initId._id && initId._id === dataList[dataL]._id) {
        rsvpRemoved = false
        break
      }
      const dataNameEmail = `${dataList[dataL].name || ''}${dataList[dataL].email || ''}`
      if (initNameEmail === dataNameEmail) {
        rsvpRemoved = false
        break
      }
    }
    if (rsvpRemoved) {
      removedIds.push(initId)
    }
  }

  return removedIds
}

export function removeEvent(data) {
  return dispatch => {
    dispatch( deleteEvent(data) )
  }
}

export function loadEvent(data) {
  return (dispatch, getState) => {
    dispatch( hideSuccess() )
    dispatch( hideError() )
    dispatch( initialize('event', data) )
  }
}

export function initEvent() {
  return (dispatch, getState) => {
    const { user, isAdmin } = getState().auth
    dispatch( initialize('event', {}) )
    dispatch( change('event', 'startDate', moment()) )
    dispatch( change('event', 'endDate', moment()) )
    dispatch( change('event', 'artworks', []) )
    dispatch( change('event', 'relatedPosts', []) )
    dispatch( change('event', 'relatedShows', []) )
    dispatch( change('event', 'entranceCurrency', 'SEK') )
    if (!isAdmin) {
      dispatch( change('event', 'email', user.email ) )
      dispatch( change('event', 'gallery', user.gallery ) )
    }
    dispatch( hideSuccess() )
    dispatch( hideError() )
  }
}

export function showSuccess(){
  return {
    type: SHOW_SUCCESS,
    showSuccess: true
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS,
    showSuccess: false
  }
}


export function showError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
