import {
  SELECTION_FETCH_REQUEST, SELECTION_FETCH_SUCCESS, SELECTION_FETCH_FAILURE,
  SELECTION_SAVE_REQUEST, SELECTION_SAVE_SUCCESS, SELECTION_SAVE_FAILURE,
  SELECTION_REMOVE_REQUEST, SELECTION_REMOVE_SUCCESS, SELECTION_REMOVE_FAILURE,
  SELECTION_ADD_ITEM_REQUEST, SELECTION_ADD_ITEM_SUCCESS, SELECTION_ADD_ITEM_FAILURE,
} from '../actions/SelectionActions'

export function selectionReducer(state = {
  isFetching: false,
  selection: {},
  selections: {},
  deletedSelection: false,
  error: null,
}, action) {
  switch (action.type) {
    case SELECTION_FETCH_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case SELECTION_FETCH_SUCCESS: {
      let selection = Object.assign({}, state.selection)
      let selections = Object.assign({}, state.selections)
      if (action.response._id) {
        // If single selection
        selection = action.response
      } else {
        // If array of selections
        action.response.forEach(resp => {
          selections[resp._id] = resp
        })
      }
      return Object.assign({}, state, {
        selection,
        selections,
        deletedSelection: false,
        isFetching: false,
      })
    }
    case SELECTION_FETCH_FAILURE: {
      return Object.assign({}, state, {
        error: action.message,
        isFetching: false,
      })
    }
    case SELECTION_ADD_ITEM_REQUEST:
    case SELECTION_SAVE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case SELECTION_ADD_ITEM_SUCCESS:
    case SELECTION_SAVE_SUCCESS: {
      const selection = action.response
      let selections = Object.assign({}, state.selections, {
        [selection._id]: selection,
      })
      return Object.assign({}, state, {
        selection,
        selections,
        isFetching: false,
      })
    }
    case SELECTION_ADD_ITEM_FAILURE:
    case SELECTION_SAVE_FAILURE: {
      return Object.assign({}, state, {
        error: action.message,
        isFetching: false,
      })
    }
    case SELECTION_REMOVE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      })
    }
    case SELECTION_REMOVE_SUCCESS: {
      return Object.assign({}, state, {
        deletedSelection: true,
        isFetching: false,
      })
    }
    case SELECTION_REMOVE_FAILURE: {
      return Object.assign({}, state, {
        error: action.message,
        isFetching: false,
      })
    }
    default:
      return state
  }
}
