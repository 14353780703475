import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import api from "./api";
import mixpanelmiddleware from "../middlewares/mixpanelmiddleware";
import formSubmitMiddleware from "../middlewares/formSubmitMiddleware";
import loadingMiddleware from "../middlewares/loadingMiddleware";
import authMiddleware from "@artworkslab/sharedmodules/src/middlewares/AuthMiddleware";
import { Reducer } from "../reducers";
import RavenMiddleware from "redux-raven-middleware";

const middleWares = process.env.__DEV__
	? [
			thunkMiddleware,
			api,
			mixpanelmiddleware(process.env.MIXPANEL_TOKEN),
			formSubmitMiddleware,
			loadingMiddleware,
			authMiddleware,
	  ]
	: [
			RavenMiddleware(process.env.SENTRY, {
				release: process.env.RELEASE,
			}),
			thunkMiddleware,
			api,
			mixpanelmiddleware(process.env.MIXPANEL_TOKEN),
			formSubmitMiddleware,
			loadingMiddleware,
			authMiddleware,
	  ];

export function configureStore(initialState) {
	const store = createStore(
		Reducer,
		{}, // initial state
		compose(
			applyMiddleware(...middleWares),
			window.devToolsExtension ? window.devToolsExtension() : (f) => f
		)
	);

	if (module.hot) {
		module.hot.accept("../reducers/index.jsx", () => {
			const nextReducer = require("../reducers/index.jsx").Reducer;
			store.replaceReducer(nextReducer);
		});
	}

	return store;
}
