import { CALL_API } from '../utils/api'

// fetch shows

export const ORDERS_REQUEST = 'artworksapp/orderactions/ORDERS_REQUEST'
export const ORDERS_SUCCESS = 'artworksapp/orderactions/ORDERS_SUCCESS'
export const ORDERS_FAILURE = 'artworksapp/orderactions/ORDERS_FAILURE'

// Uses the API to get a show
export function fetchOrders() {
	return {
		[CALL_API]: {
			endpoint: 'cart/admin/',
			authenticated: true,
			types: [ORDERS_REQUEST, ORDERS_SUCCESS, ORDERS_FAILURE]
		}
	}
}

// fetch shows

export const ORDER_REQUEST = 'artworksapp/orderactions/ORDER_REQUEST'
export const ORDER_SUCCESS = 'artworksapp/orderactions/ORDER_SUCCESS'
export const ORDER_FAILURE = 'artworksapp/orderactions/ORDER_FAILURE'

// Uses the API to get a show
export function fetchOrder(orderId) {
	const endpoint = `cart/admin/${orderId}`
	return {
		[CALL_API]: {
			endpoint,
			authenticated: true,
			types: [ORDER_REQUEST, ORDER_SUCCESS, ORDER_FAILURE]
		}
	}
}

export const UPDATE_ORDER_REQUEST = 'artworksapp/orderactions/UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'artworksapp/orderactions/UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAILURE = 'artworksapp/orderactions/UPDATE_ORDER_FAILURE'

export function updateOrder(orderId, update) {
	const endpoint = `cart/admin/${orderId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: update,
			authenticated: true,
			types: [UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE]
		}
	}
}

export const INVOICE_ORDER_REQUEST = 'artworksapp/orderactions/INVOICE_ORDER_REQUEST'
export const INVOICE_ORDER_SUCCESS = 'artworksapp/orderactions/INVOICE_ORDER_SUCCESS'
export const INVOICE_ORDER_FAILURE = 'artworksapp/orderactions/INVOICE_ORDER_FAILURE'

export function fetchInvoiceOrders(queryFor = 'all') {
	return {
		[CALL_API]: {
			endpoint: `invoicecart/orders/${queryFor}`,
			authenticated: true,
			types: [INVOICE_ORDER_REQUEST, INVOICE_ORDER_SUCCESS, INVOICE_ORDER_FAILURE]
		}
	}
}

export const UPDATE_INVOICE_ORDER_REQUEST = 'artworksapp/orderactions/UPDATE_INVOICE_ORDER_REQUEST'

export function updateInvoiceOrder(orderId, update) {
	const endpoint = `invoicecart/order/${orderId}`
	return {
		[CALL_API]: {
			endpoint,
			method: 'PUT',
			body: update,
			authenticated: true,
			types: [UPDATE_INVOICE_ORDER_REQUEST, INVOICE_ORDER_SUCCESS, INVOICE_ORDER_FAILURE]
		}
	}
}
