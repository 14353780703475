import React                from 'react'
import {
  initialize,
  startSubmit,
  stopSubmit,
  change,
}                           from 'redux-form'
import i18next              from 'i18next'
import Validator            from 'validatorjs'
import sv                   from './sv'
import diff                 from 'object-diff'
import { showErrorModal }   from '../AppActions'
import {
  validateShipping
}                           from '../../utils/Validators'
import {
  createInvoice,
  sendManualOrder
}                           from '../ArtworkInvoiceActions'

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

//Checks and formats data from invoiceForm to be sent to controller
export function submitInvoice(artworkId, artistId, proAtSale, noCommission, data, selectedTransport, transId) {
  return async (dispatch, getState) => {
		const isSwedish = i18next.language === 'sv'
		let customErrors = {}
		const rules = {
      shipping: {
        transport: 'string',
        swePrice: 'numeric',
        euPrice: 'numeric',
        globalPrice: 'numeric',
      },
			orgNumber: 'string',
      bankName: 'string',
      clearingNr: 'string',
      accountNr: 'string',
      bankgiro: 'string',
      locationName: 'string'
		}

		let validation = isSwedish
		? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

		if(isSwedish) {
      validation.setAttributeNames({
        shipping: {
          transport: 'Leverans',
          swePrice: 'Leverans i Sverige',
          euPrice: 'Leverans inom EU',
          globalPrice: 'Leverans utanför EU',
        },
				orgNumber: 'Moms reg nr.',
        bankName: 'Banknamn',
        clearingNr: 'Clearingnummer',
        accountNr: 'Kontonummer',
        bankgiro: 'Bankgironummer',
        locationName: 'Adress'
      })
    }

    if(!data.bankName) {
      customErrors.bankName = isSwedish ? 'Banknamn är obligatoriskt vid fakturering' : 'Bank name is required for invoice'
    }
    if((!data.clearingNr || !data.accountNr) && !data.bankgiro) {
      customErrors.clearingNr = isSwedish ? 'Clearing- och kontonummer eller bankgironummer är obligatoriskt vid fakturering' : 'Clearing- and account number or a bankgiro number is required for invoice'
      customErrors.accountNr = isSwedish ? 'Clearing- och kontonummer eller bankgironummer är obligatoriskt vid fakturering' : 'Clearing- and account number or a bankgiro number is required for invoice'
      customErrors.bankgiro = isSwedish ? 'Clearing- och kontonummer eller bankgironummer är obligatoriskt vid fakturering' : 'Clearing- and account number or a bankgiro number is required for invoice'
    }
    if(!data.locationName) {
      customErrors.locationName = isSwedish ? 'Adress är obligatoriskt vid fakturering' : 'An address is required for invoice'
    }
    if(!data.orgNumber && data.VATcode != 0) {
      customErrors.orgNumber = isSwedish ? 'Momsregistreringsnummer är obligatoriskt om momssatsen är över 0%' : 'Organisation number is required if the VAT is above 0%'
    }

    const shipping = getShippingPrice(data.shipping, selectedTransport)

		validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({}, customErrors, validatorErrors)

		// Return early if errors
    if (Object.keys(errors).length !== 0) {
      dispatch( showErrorModal(translate('requiredFields')) )
      return dispatch( stopSubmit('invoice', errors) )
    }

    //Set commissionCode and calculate commission costs
    let commissionCode = proAtSale ? 15 : 30
    commissionCode = noCommission ? 0 : commissionCode
    const commissionExVAT = data.price * (commissionCode/100)
    const commissionIncVAT = commissionExVAT * 1.25
    const framePriceVAT = data.framePrice * 1.25

    const dispatchData = {
      _id: data._id,
      incVAT: Number(data.price) + Number(data.price * (data.VATcode/100)),
      exVAT: data.price,
      VATcode: data.VATcode,
      priceCurrency: data.priceCurrency,
      framePrice: Number(data.framePrice) || 0,
      framePriceVAT: roundValue(framePriceVAT),
      shipping: shipping.price,
      shippingVAT: shipping.priceVAT,
      transport: selectedTransport,
      commissionCode: commissionCode,
      commissionIncVAT: roundValue(commissionIncVAT),
      commissionExVAT: roundValue(commissionExVAT),
      orgNumber: data.orgNumber,
      bankName: data.bankName,
      clearingNr: data.clearingNr ? data.clearingNr : null,
      accountNr: data.accountNr,
      bankgiro: data.bankgiro,
      locationName: data.locationName
    }

    const result =  await dispatch( createInvoice(artworkId, artistId, transId, dispatchData) )
    return result
	}
}

const getShippingPrice = (shipping, selectedTransport) => {
  let shippingPrice = 0
  let shippingVAT = 0
  if(selectedTransport === 'Artist' && shipping && shipping.customPrice) {
    shippingPrice = Number(shipping.customPrice)
    shippingVAT = Number(shipping.customPrice) + (Number(shipping.customPrice) * 0.25)
  }
  if(selectedTransport === 'Sweden' && shipping && shipping.swePrice) {
    shippingPrice = Number(shipping.swePrice)
    shippingVAT = Number(shipping.swePrice) + (Number(shipping.swePrice) * 0.25)
  }
  if(selectedTransport === 'EU' && shipping && shipping.euPrice) {
    shippingPrice = Number(shipping.euPrice)
    shippingVAT = Number(shipping.euPrice) + (Number(shipping.euPrice) * 0.25)
  }
  if(selectedTransport === 'Global' && shipping && shipping.globalPrice) {
    shippingPrice = Number(shipping.globalPrice)
    shippingVAT = Number(shipping.globalPrice) + (Number(shipping.globalPrice) * 0.25)
  }
  return {price: roundValue(shippingPrice), priceVAT: roundValue(shippingVAT)}
}

const roundValue = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

//Assures required order data is included and sends order data to controller
export function submitManualOrder(data, initial) {
  return (dispatch, getState) => {
    const isSwedish = i18next.language === 'sv'
    let customErrors = {}
    const rules = {
      shipping: {
        transport: 'string',
        swePrice: 'numeric',
        euPrice: 'numeric',
        globalPrice: 'numeric',
      },
      price: 'numeric',
      buyerName: 'string',
      buyerEmail: 'string',
      buyerAddress: 'string',
      buyerPost: 'string',
      buyerCity: 'string',
      buyerCountry: 'string',
      description: 'string',
      wasProAtSale: 'boolean'
    }

    let validation = isSwedish
    ? new Validator(data, rules, {...sv})
    : new Validator(data, rules)

    if(isSwedish) {
      validation.setAttributeNames({
        shipping: {
          transport: 'Leverans',
          swePrice: 'Leverans i Sverige',
          euPrice: 'Leverans inom EU',
          globalPrice: 'Leverans utanför EU',
        },
        price: 'Pris',
        buyerName: 'Köparens namn',
        buyerEmail: 'Köparens mejladress',
        buyerAddress: 'Köpares adress',
        buyerPost: 'Köpares postkod',
        buyerCity: 'Köparens stad',
        buyerCountry: 'Köparens land',
        description: 'Vald leverans',
        wasProAtSale: 'Konstnärens PRO-status'
      })
    }

    if(!data.price) {
      customErrors.price = isSwedish ? 'Pris är obligatoriskt vid försäljning' : 'Price is required upon sale'
    }
    if(!data.buyerName) {
      customErrors.buyerName = isSwedish ? 'Köparens namn är obligatoriskt' : 'The buyers name is required'
    }
    if(!data.buyerEmail) {
      customErrors.buyerEmail = isSwedish ? 'Köparens mejladress är obligatoriskt' : 'The buyers email is required'
    }
    if(!data.buyerAddress) {
      customErrors.buyerAddress = isSwedish ? 'Köparens adress är obligatoriskt' : 'The buyers adress is required'
    }
    if(!data.buyerPost) {
      customErrors.buyerPost = isSwedish ? 'Köparens postkod är obligatoriskt' : 'The buyers postcode is required'
    }
    if(!data.buyerCity) {
      customErrors.buyerCity = isSwedish ? 'Köparens ort är obligatoriskt' : 'The buyers city of residence is required'
    }
    if(!data.buyerCountry) {
      customErrors.buyerCountry = isSwedish ? 'Köparens land är obligatoriskt' : 'The buyers country of residence is required'
    }
    if(!data.description) {
      customErrors.description = isSwedish ? 'Leverans alternativ är obligatoriskt' : 'Shipping option is required'
    }
    if(!data.wasProAtSale) {
      customErrors.wasProAtSale = isSwedish ? 'Ange om konstnären var PRO eller ej' : 'The artists PRO-status is required'
    }

    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({}, customErrors, validatorErrors)

    const changedValues = initial ? diff(initial, data) : data
    if (data._id) changedValues._id = data._id

    // Return early if errors
    if (Object.keys(errors).length !== 0) {
      dispatch( showErrorModal(translate('requiredFields')) )
      return dispatch( stopSubmit('purchaseArtwork', errors) )
    }
    dispatch( sendManualOrder(changedValues) )
    //Return SUCCESS if there are no errors
    return 'SUCCESS'
  }
}
