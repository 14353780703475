import { CALL_API } from "../utils/api";
import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

const translate = (name) => {
	return i18next.t(`common:feedback.${name}`);
};

// fetch artists

export const ARTISTS_REQUEST = "ARTISTS_REQUEST";
export const ARTISTS_SUCCESS = "ARTISTS_SUCCESS";
export const ARTISTS_FAILURE = "ARTISTS_FAILURE";

// Uses the API to get artists
export function fetchArtists(query = {}) {
	return {
		[CALL_API]: {
			endpoint: `artists/?query=${JSON.stringify(query)}`,
			authenticated: true,
			types: [ARTISTS_REQUEST, ARTISTS_SUCCESS, ARTISTS_FAILURE],
		},
	};
}

export function fetchAllArtists() {
	return {
		[CALL_API]: {
			endpoint: "artists/all",
			authenticated: true,
			types: [ARTISTS_REQUEST, ARTISTS_SUCCESS, ARTISTS_FAILURE],
		},
	};
}

export const ARTISTS_ALL_REQUEST = "ARTISTS_ALL_REQUEST";
export const ARTISTS_ALL_SUCCESS = "ARTISTS_ALL_SUCCESS";
export const ARTISTS_ALL_FAILURE = "ARTISTS_ALL_FAILURE";

export function fetchAllArtistsFast(name = "") {
	return {
		[CALL_API]: {
			endpoint: `artists/fetch-all-fast/${name}`,
			authenticated: true,
			types: [ARTISTS_ALL_REQUEST, ARTISTS_ALL_SUCCESS, ARTISTS_ALL_FAILURE],
		},
	};
}

export const ARTISTS_ALL_POPULATE_REQUEST = "ARTISTS_ALL_POPULATE_REQUEST";
export const ARTISTS_ALL_POPULATE_SUCCESS = "ARTISTS_ALL_POPULATE_SUCCESS";
export const ARTISTS_ALL_POPULATE_FAILURE = "ARTISTS_ALL_POPULATE_FAILURE";

export function fetchAllArtistsPopulated() {
	// Request was too slow, timed out.
	return { type: "NOT-DOING-ANYTHING" };
	return {
		[CALL_API]: {
			endpoint: "artists/fetch-all-populated",
			authenticated: true,
			types: [
				ARTISTS_ALL_POPULATE_REQUEST,
				ARTISTS_ALL_POPULATE_SUCCESS,
				ARTISTS_ALL_POPULATE_FAILURE,
			],
		},
	};
}

// fetch artist

export const ARTIST_REQUEST = "ARTIST_REQUEST";
export const ARTIST_SUCCESS = "ARTIST_SUCCESS";
export const ARTIST_FAILURE = "ARTIST_FAILURE";

// Uses the API to get an artist
export function fetchArtist(artistId) {
	const endpoint = artistId ? "artists/" + artistId : "artists/";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [ARTIST_REQUEST, ARTIST_SUCCESS, ARTIST_FAILURE],
		},
	};
}

// save artist

export const ARTIST_SAVE_REQUEST = "ARTIST_SAVE_REQUEST";
export const ARTIST_SAVE_SUCCESS = "ARTIST_SAVE_SUCCESS";
export const ARTIST_SAVE_FAILURE = "ARTIST_SAVE_FAILURE";

export function saveArtist(artist) {
	return async (dispatch) => {
		const endpoint = artist._id ? `artists/${artist._id}` : "artists/";
		const method = artist._id ? "PUT" : "POST";
		const analytics = artist._id
			? {
					track: "Updated Artist",
					payload: {
						id: artist._id,
						name: artist.name,
					},
					incrementScore: 1,
			  }
			: {
					track: "Saved Artist",
					payload: {
						name: artist.name,
					},
					incrementScore: 1,
			  };

		dispatch(saveArtistRequest(analytics));
		return Api(endpoint, method, true, artist)
			.then((json) => {
				return dispatch(saveArtistSuccess(json));
			})
			.catch((error) => {
				return dispatch(saveArtistFail(error));
			});
	};
}

export function deleteMention(artistId, mentionId) {
	return (dispatch) => {
		dispatch(saveArtistRequest());
		const endpoint = `artists/mentions/${artistId}`;
		const body = {
			mentionId: mentionId,
		};
		return Api(endpoint, "PUT", false, body)
			.then((json) => {
				dispatch(saveArtistSuccess(json));
			})
			.catch((error) => {
				dispatch(saveArtistFail(error));
			});
	};
}

export function saveArtistRequest(analytics) {
	return {
		type: ARTIST_SAVE_REQUEST,
		analytics,
	};
}

export function saveArtistSuccess(json) {
	return {
		type: ARTIST_SAVE_SUCCESS,
		response: json,
		message: translate("artistSaved"),
	};
}

export function saveArtistFail(error) {
	return {
		type: ARTIST_SAVE_FAILURE,
		message: error,
	};
}

export const MENTIONS_REQUEST = "artworksapp/shared/MENTIONS_REQUEST";
export const MENTIONS_SUCCESS = "artworksapp/shared/MENTIONS_SUCCESS";
export const MENTIONS_FAILURE = "artworksapp/shared/MENTIONS_FAILURE";

export function fetchMentionsForArtist(artistId) {
	return (dispatch) => {
		dispatch(mentionsForArtistRequest());
		const endpoint = `artists/${artistId}/mentions`;
		return Api(endpoint, "GET", false)
			.then((json) => {
				dispatch(mentionsForArtistSuccess(json, artistId));
			})
			.catch((error) => {
				dispatch(mentionsForArtistFail(error));
			});
	};
}

function mentionsForArtistRequest() {
	return {
		type: MENTIONS_REQUEST,
	};
}

function mentionsForArtistSuccess(mentions, artistId) {
	return {
		type: MENTIONS_SUCCESS,
		response: mentions,
		artistId: artistId,
	};
}

function mentionsForArtistFail(error) {
	return {
		type: MENTIONS_FAILURE,
		message: error,
	};
}

export const ARTIST_DELETE_REQUEST = "ARTIST_DELETE_REQUEST";
export const ARTIST_DELETE_SUCCESS = "ARTIST_DELETE_SUCCESS";
export const ARTIST_DELETE_FAILURE = "ARTIST_DELETE_FAILURE";

// Uses the API to get a artist
export function deleteArtist(artist) {
	return {
		[CALL_API]: {
			endpoint: "artists/" + artist._id,
			authenticated: true,
			types: [
				ARTIST_DELETE_REQUEST,
				ARTIST_DELETE_SUCCESS,
				ARTIST_DELETE_FAILURE,
			],
			method: "delete",
			analytics: {
				track: "Delete Artist",
				payload: {
					id: artist._id,
					name: artist.name,
				},
				incrementScore: 1,
			},
		},
	};
}

export function removeMyArtist(artistId, artistName) {
	return {
		[CALL_API]: {
			endpoint: "artists/remove/" + artistId,
			authenticated: true,
			types: [
				ARTIST_DELETE_REQUEST,
				ARTIST_DELETE_SUCCESS,
				ARTIST_DELETE_FAILURE,
			],
			method: "DELETE",
			analytics: {
				track: "Remove My Artist",
				payload: {
					id: artistId,
					name: artistName,
				},
				incrementScore: 1,
			},
		},
	};
}

// fetch pro artist stat-increase

export const PRO_INCREASE_REQUEST = "PRO_INCREASE_REQUEST";
export const PRO_INCREASE_SUCCESS = "PRO_INCREASE_SUCCESS";
export const PRO_INCREASE_FAILURE = "PRO_INCREASE_FAILURE";

export function fetchProIncrease() {
	const endpoint = "stats/proincrease";
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [PRO_INCREASE_REQUEST, PRO_INCREASE_SUCCESS, PRO_INCREASE_FAILURE],
		},
	};
}
