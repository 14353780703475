import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
	toggleMostPopularArtwork,
	fetchData,
} from "../../../actions/DashboardActions/MostPopularActions";
import LeastMostPopular from "./LeastMostPopular";
import i18next from "i18next";

const ENG_MONTHS = [
	"january",
	"february",
	"march",
	"april",
	"may",
	"june",
	"july",
	"august",
	"september",
	"october",
	"november",
	"december",
];
const SWE_MONTHS = [
	"januari",
	"februari",
	"mars",
	"april",
	"maj",
	"juni",
	"juli",
	"augusti",
	"september",
	"oktober",
	"november",
	"december",
];

// Fetches the artists 3 most liked artworks
class MostPopularArtworks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			month: "",
		};
	}

	componentWillMount() {
		this.props.dispatch(fetchData());

		const date = new Date();
		const monthNum = date.getMonth();
		let prevMonth = "";
		if (i18next.language === "sv") {
			prevMonth = SWE_MONTHS[monthNum];
		} else {
			prevMonth = ENG_MONTHS[monthNum];
		}
		this.setState({
			month: prevMonth,
		});
	}

	render() {
		const { expanded, artworks, t } = this.props;
		const { month } = this.state;

		return (
			<div className="LeastMostPopular">
				<h4 className="artist">{t("artist.popularLikes")}</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded && artworks.length > 0 ? (
							<LeastMostPopular artworks={artworks} t={t} />
						) : (
							<div className="LeastMostPopular__Empty">
								<h4 className="artist">
									{t("artist.noPopularMonth1")}
									<br />
									{t("artist.noPopularMonth2")}
								</h4>
							</div>
						)}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		mostPopular: { expanded, artworks },
	} = state;
	return { expanded, artworks };
}

export default connect(mapStateToProps)(MostPopularArtworks);
