import { showLoadingSpinner, hideLoadingSpinner } from "../actions/AppActions";
import {
	SHOWS_REQUEST,
	SHOWS_SUCCESS,
	SHOWS_FAILURE,
	SHOW_REQUEST,
	SHOW_SUCCESS,
	SHOW_FAILURE,
	SHOWS_ALL_REQUEST,
	SHOWS_ALL_FAILURE,
	SHOWS_ALL_SUCCESS,
	SHOWS_ALL_POPULATE_SUCCESS,
	SHOW_SAVE_REQUEST,
	SHOW_SAVE_SUCCESS,
	SHOW_SAVE_FAILURE,
	SHOW_MAIL_PREVIEW_REQUEST,
	SHOW_MAIL_PREVIEW_SUCCESS,
	SHOW_MAIL_PREVIEW_FAILURE,
	INFORM_MEMBERS_SHOW_REQUEST,
	INFORM_MEMBERS_SHOW_SUCCESS,
	INFORM_MEMBERS_SHOW_FAILURE,
} from "../actions/ShowActions";
import {
	GET_ACCESS_REQUEST,
	GET_ACCESS_SUCCESS,
	GET_ACCESS_FAILURE,
} from "../actions/AccessActions";
import {
	POSTS_REQUEST,
	POSTS_SUCCESS,
	POSTS_FAILURE,
	POST_REQUEST,
	POST_SUCCESS,
	POST_FAILURE,
	POSTS_ALL_REQUEST,
	POSTS_ALL_FAILURE,
	POSTS_ALL_SUCCESS,
	POSTS_ALL_POPULATE_SUCCESS,
	POST_SAVE_REQUEST,
	POST_SAVE_SUCCESS,
	POST_SAVE_FAILURE,
	POST_MAIL_PREVIEW_REQUEST,
	POST_MAIL_PREVIEW_SUCCESS,
	POST_MAIL_PREVIEW_FAILURE,
	INFORM_MEMBERS_POST_REQUEST,
	INFORM_MEMBERS_POST_SUCCESS,
	INFORM_MEMBERS_POST_FAILURE,
	UPDATE_POSTS_REQUEST,
	UPDATE_POSTS_SUCCESS,
	UPDATE_POSTS_FAILURE,
} from "../actions/PostActions";
import {
	EVENTS_REQUEST,
	EVENTS_SUCCESS,
	EVENTS_FAILURE,
	EVENT_REQUEST,
	EVENT_SUCCESS,
	EVENT_FAILURE,
	EVENTS_ALL_REQUEST,
	EVENTS_ALL_FAILURE,
	EVENTS_ALL_SUCCESS,
	EVENTS_ALL_POPULATE_SUCCESS,
	EVENT_SAVE_REQUEST,
	EVENT_SAVE_SUCCESS,
	EVENT_SAVE_FAILURE,
	EVENT_UPDATE_REQUEST,
	EVENT_UPDATE_SUCCESS,
	EVENT_UPDATE_FAILURE,
	RSVP_MAIL_REQUEST,
	RSVP_MAIL_SUCCESS,
	RSVP_MAIL_FAILURE,
	RSVP_MAIL_FEE_MISSING_REQUEST,
	RSVP_MAIL_FEE_MISSING_SUCCESS,
	RSVP_MAIL_FEE_MISSING_FAILURE,
	EVENT_MAIL_PREVIEW_REQUEST,
	EVENT_MAIL_PREVIEW_SUCCESS,
	EVENT_MAIL_PREVIEW_FAILURE,
	INFORM_MEMBERS_EVENT_REQUEST,
	INFORM_MEMBERS_EVENT_SUCCESS,
	INFORM_MEMBERS_EVENT_FAILURE,
} from "../actions/EventActions";
import {
	GALLERY_REQUEST,
	GALLERY_SUCCESS,
	GALLERY_FAILURE,
	GALLERIES_REQUEST,
	GALLERIES_SUCCESS,
	GALLERIES_FAILURE,
	GALLERIES_ALL_REQUEST,
	GALLERIES_ALL_FAILURE,
	GALLERIES_ALL_SUCCESS,
	GALLERIES_ALL_POPULATE_SUCCESS,
	GALLERY_SAVE_REQUEST,
	GALLERY_SAVE_SUCCESS,
	GALLERY_SAVE_FAILURE,
	COPY_GALLERY_REQUEST,
	COPY_GALLERY_SUCCESS,
	COPY_GALLERY_FAILURE,
} from "../actions/GalleryActions";
import {
	ARTWORKS_REQUEST,
	ARTWORKS_SUCCESS,
	ARTWORKS_FAILURE,
	ARTWORK_REQUEST,
	ARTWORK_SUCCESS,
	ARTWORK_FAILURE,
	ARTWORKS_ALL_REQUEST,
	ARTWORKS_ALL_SUCCESS,
	ARTWORKS_ALL_FAILURE,
	ARTWORKS_ALL_POPULATE_SUCCESS,
	ARTWORK_SAVE_REQUEST,
	ARTWORK_SAVE_SUCCESS,
	ARTWORK_SAVE_FAILURE,
	ARTWORK_MAIL_PREVIEW_REQUEST,
	ARTWORK_MAIL_PREVIEW_SUCCESS,
	ARTWORK_MAIL_PREVIEW_FAILURE,
	ARTWORK_FROM_IMAGE_REQUEST,
	ARTWORK_FROM_IMAGE_SUCCESS,
	ARTWORK_FROM_IMAGE_FAILURE,
} from "../actions/ArtworkActions";
import {
	USERS_REQUEST,
	USERS_SUCCESS,
	USERS_FAILURE,
	USER_REQUEST,
	USER_SUCCESS,
	USER_FAILURE,
	SOCIETY_ADMIN_REQUEST,
	SOCIETY_ADMIN_SUCCESS,
	SOCIETY_ADMIN_FAILURE,
	USER_MAKE_SOCIETY_REQUEST,
	USER_MAKE_SOCIETY_SUCCESS,
	USER_MAKE_SOCIETY_FAILURE,
} from "../actions/UserActions";
import {
	ARTISTS_REQUEST,
	ARTISTS_SUCCESS,
	ARTISTS_FAILURE,
	ARTIST_REQUEST,
	ARTIST_SUCCESS,
	ARTIST_FAILURE,
	ARTISTS_ALL_REQUEST,
	ARTISTS_ALL_FAILURE,
	ARTISTS_ALL_SUCCESS,
	ARTISTS_ALL_POPULATE_SUCCESS,
	ARTIST_SAVE_REQUEST,
	ARTIST_SAVE_SUCCESS,
	ARTIST_SAVE_FAILURE,
	PRO_INCREASE_REQUEST,
	PRO_INCREASE_SUCCESS,
	PRO_INCREASE_FAILURE,
} from "../actions/ArtistActions";
import {
	RAFFLE_MAIL_PREVIEW_REQUEST,
	RAFFLE_MAIL_PREVIEW_SUCCESS,
	RAFFLE_MAIL_PREVIEW_FAILURE,
} from "../actions/RaffleActions";
import {
	RAFFLE_SAVE_REQUEST,
	RAFFLE_SAVE_SUCCESS,
	RAFFLE_SAVE_FAILURE,
	INFORM_MEMBERS_RAFFLE_REQUEST,
	INFORM_MEMBERS_RAFFLE_SUCCESS,
	INFORM_MEMBERS_RAFFLE_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";
import {
	FILES_FOR_USER_REQUEST,
	FILES_FOR_USER_SUCCESS,
	FILES_FOR_USER_FAILURE,
	UPLOAD_FILE_REQUEST,
	UPLOAD_FILE_SUCCESS,
	UPLOAD_FILE_FAILURE,
	DELETE_FILE_REQUEST,
	DELETE_FILE_SUCCESS,
	DELETE_FILE_FAILURE,
	RENAME_FILE_REQUEST,
	RENAME_FILE_SUCCESS,
	RENAME_FILE_FAILURE,
	FETCH_EMAIL_HISTORY_REQUEST,
	FETCH_EMAIL_HISTORY_SUCCESS,
	FETCH_EMAIL_HISTORY_FAILURE,
	FETCH_EMAIL_REQUEST,
	FETCH_EMAIL_SUCCESS,
	FETCH_EMAIL_FAILURE,
} from "../actions/ArchiveActions";
import {
	SAVE_ON_UPLOAD_REQUEST,
	SAVE_ON_UPLOAD_SUCCESS,
	SAVE_ON_UPLOAD_FAILURE,
	FETCH_MEMBERS_REQUEST,
	FETCH_MEMBERS_SUCCESS,
	FETCH_MEMBERS_FAILURE,
	EXPORT_MEMBERS_REQUEST,
	EXPORT_MEMBERS_SUCCESS,
	EXPORT_MEMBERS_FAILURE,
	SEND_EMAIL_TO_MEMBERS_REQUEST,
	SEND_EMAIL_TO_MEMBERS_SUCCESS,
	SEND_EMAIL_TO_MEMBERS_FAILURE,
	UPLOAD_MEMBERS_HELP_REQUEST,
	UPLOAD_MEMBERS_HELP_SUCCESS,
	UPLOAD_MEMBERS_HELP_FAILURE,
} from "../actions/RegistryActions";
import {
	SAVE_XMASCALENDAR_REQUEST,
	SAVE_XMASCALENDAR_SUCCESS,
	SAVE_XMASCALENDAR_FAILURE,
	ALTER_XMAS_ARTWORK_REQUEST,
	ALTER_XMAS_ARTWORK_SUCCESS,
	ALTER_XMAS_ARTWORK_FAILURE,
	DELETE_XMAS_REQUEST,
	DELETE_XMAS_SUCCESS,
	DELETE_XMAS_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/XmasActions";
import {
	UPDATE_CUSTOMERS_REQUEST,
	UPDATE_CUSTOMERS_SUCCESS,
	UPDATE_CUSTOMERS_FAILURE,
	CREATE_INVOICE_REQUEST,
	CREATE_INVOICE_SUCCESS,
	CREATE_INVOICES_SUCCESS,
	CREATE_INVOICE_FAILURE,
	INVOICES_REQUEST,
	INVOICES_SUCCESS,
	INVOICES_FAILURE,
	INVOICE_REQUEST,
	INVOICE_SUCCESS,
	INVOICE_FAILURE,
	INVOICE_PREVIEW_REQUEST,
	INVOICE_PREVIEW_SUCCESS,
	INVOICE_PREVIEW_FAILURE,
	FILE_REQUEST,
	FILE_SUCCESS,
	FILE_FAILURE,
} from "../actions/BillectaActions";
import {
	CREATE_INVOICE_REQUEST as ARTWORK_CREATE_INVOICE_REQUEST,
	CREATE_INVOICE_SUCCESS as ARTWORK_CREATE_INVOICE_SUCCESS,
	CREATE_INVOICE_FAILURE as ARTWORK_CREATE_INVOICE_FAILURE,
	MANUAL_ORDER_REQUEST,
	MANUAL_ORDER_SUCCESS,
	MANUAL_ORDER_FAILURE,
	ARTIST_INVOICES_REQUEST,
	ARTIST_INVOICES_SUCCESS,
	ARTIST_INVOICES_FAILURE,
	REMOVE_INVOICE_REQUEST,
	REMOVE_INVOICE_SUCCESS,
	REMOVE_INVOICE_FAILURE,
} from "../actions/ArtworkInvoiceActions";
import {
	CONTACTLIST_FETCH_REQUEST,
	CONTACTLIST_FETCH_SUCCESS,
	CONTACTLIST_FETCH_FAILURE,
	CONTACTLISTS_REQUEST,
	CONTACTLISTS_SUCCESS,
	CONTACTLISTS_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/ContactActions";
import {
	CLOSE_ENQUIRY_REQUEST,
	CLOSE_ENQUIRY_SUCCESS,
	CLOSE_ENQUIRY_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/EnquiryActions";
import {
	SELECTION_FETCH_REQUEST,
	SELECTION_FETCH_SUCCESS,
	SELECTION_FETCH_FAILURE,
} from "../actions/SelectionActions";
import {
	SEND_CONTACT_EMAIL_REQUEST,
	SEND_CONTACT_EMAIL_SUCCESS,
	SEND_CONTACT_EMAIL_FAILURE,
} from "../actions/EmailActions";
import {
	PRINT_GET_REQUEST,
	PRINT_SAVE_REQUEST,
	PRINT_REMOVE_REQUEST,
	PRINT_GET_SUCCESS,
	PRINTS_GET_SUCCESS,
	PRINT_SAVE_SUCCESS,
	PRINT_REMOVE_SUCCESS,
	PRINT_GET_FAILURE,
	PRINT_SAVE_FAILURE,
	PRINT_REMOVE_FAILURE,
} from "../actions/PrintActions";
import {
	INFLUENCER_SAVE_REQUEST,
	INFLUENCER_REMOVE_REQUEST,
	INFLUENCER_SAVE_SUCCESS,
	INFLUENCER_REMOVE_SUCCESS,
	INFLUENCER_SAVE_FAILURE,
	INFLUENCER_REMOVE_FAILURE,
} from "../actions/InfluencerActions";

const loadingMiddleware =
	({ dispatch }) =>
	(next) =>
	(action) => {
		// Dispatch show error/success modal action
		switch (action.type) {
			case SHOWS_REQUEST:
			case SHOW_REQUEST:
			case SHOWS_ALL_REQUEST:
			case SHOW_SAVE_REQUEST:
			case GET_ACCESS_REQUEST:
			case POSTS_REQUEST:
			case POST_REQUEST:
			case POSTS_ALL_REQUEST:
			case POST_SAVE_REQUEST:
			case UPDATE_POSTS_REQUEST:
			case EVENTS_REQUEST:
			case EVENT_REQUEST:
			case EVENTS_ALL_REQUEST:
			case EVENT_SAVE_REQUEST:
			case EVENT_UPDATE_REQUEST:
			case RSVP_MAIL_REQUEST:
			case RSVP_MAIL_FEE_MISSING_REQUEST:
			case GALLERIES_REQUEST:
			case GALLERY_REQUEST:
			case GALLERIES_ALL_REQUEST:
			case GALLERY_SAVE_REQUEST:
			case COPY_GALLERY_REQUEST:
			case ARTWORKS_REQUEST:
			case ARTWORK_REQUEST:
			case ARTWORKS_ALL_REQUEST:
			case ARTWORK_SAVE_REQUEST:
			case ARTWORK_FROM_IMAGE_REQUEST:
			case USERS_REQUEST:
			case USER_REQUEST:
			case SOCIETY_ADMIN_REQUEST:
			case USER_MAKE_SOCIETY_REQUEST:
			case ARTISTS_REQUEST:
			case ARTIST_REQUEST:
			case ARTISTS_ALL_REQUEST:
			case ARTIST_SAVE_REQUEST:
			case RAFFLE_SAVE_REQUEST:
			case SHOW_MAIL_PREVIEW_REQUEST:
			case POST_MAIL_PREVIEW_REQUEST:
			case EVENT_MAIL_PREVIEW_REQUEST:
			case RAFFLE_MAIL_PREVIEW_REQUEST:
			case ARTWORK_MAIL_PREVIEW_REQUEST:
			case INFORM_MEMBERS_SHOW_REQUEST:
			case INFORM_MEMBERS_POST_REQUEST:
			case INFORM_MEMBERS_EVENT_REQUEST:
			case INFORM_MEMBERS_RAFFLE_REQUEST:
			case FILES_FOR_USER_REQUEST:
			case UPLOAD_FILE_REQUEST:
			case DELETE_FILE_REQUEST:
			case RENAME_FILE_REQUEST:
			case FETCH_EMAIL_HISTORY_REQUEST:
			case FETCH_EMAIL_REQUEST:
			case SAVE_ON_UPLOAD_REQUEST:
			case FETCH_MEMBERS_REQUEST:
			case EXPORT_MEMBERS_REQUEST:
			case SEND_EMAIL_TO_MEMBERS_REQUEST:
			case UPLOAD_MEMBERS_HELP_REQUEST:
			case SAVE_XMASCALENDAR_REQUEST:
			case ALTER_XMAS_ARTWORK_REQUEST:
			case DELETE_XMAS_REQUEST:
			case UPDATE_CUSTOMERS_REQUEST:
			case CREATE_INVOICE_REQUEST:
			case INVOICES_REQUEST:
			case INVOICE_REQUEST:
			case INVOICE_PREVIEW_REQUEST:
			case FILE_REQUEST:
			case CONTACTLIST_FETCH_REQUEST:
			case CONTACTLISTS_REQUEST:
			case CLOSE_ENQUIRY_REQUEST:
			case PRO_INCREASE_REQUEST:
			case SELECTION_FETCH_REQUEST:
			case ARTWORK_CREATE_INVOICE_REQUEST:
			case MANUAL_ORDER_REQUEST:
			case ARTIST_INVOICES_REQUEST:
			case REMOVE_INVOICE_REQUEST:
			case SEND_CONTACT_EMAIL_REQUEST:
			case PRINT_GET_REQUEST:
			case PRINT_SAVE_REQUEST:
			case PRINT_REMOVE_REQUEST:
			case INFLUENCER_SAVE_REQUEST:
			case INFLUENCER_REMOVE_REQUEST:
				dispatch(showLoadingSpinner());
				break;
			case SHOWS_SUCCESS:
			case SHOW_SUCCESS:
			case SHOWS_ALL_SUCCESS:
			case SHOWS_ALL_POPULATE_SUCCESS:
			case SHOW_SAVE_SUCCESS:
			case GET_ACCESS_SUCCESS:
			case POSTS_SUCCESS:
			case POST_SUCCESS:
			case POSTS_ALL_SUCCESS:
			case POSTS_ALL_POPULATE_SUCCESS:
			case POST_SAVE_SUCCESS:
			case UPDATE_POSTS_SUCCESS:
			case EVENTS_SUCCESS:
			case EVENT_SUCCESS:
			case EVENTS_ALL_SUCCESS:
			case EVENTS_ALL_POPULATE_SUCCESS:
			case EVENT_SAVE_SUCCESS:
			case EVENT_UPDATE_SUCCESS:
			case RSVP_MAIL_SUCCESS:
			case RSVP_MAIL_FEE_MISSING_SUCCESS:
			case GALLERIES_SUCCESS:
			case GALLERY_SUCCESS:
			case GALLERIES_ALL_SUCCESS:
			case GALLERIES_ALL_POPULATE_SUCCESS:
			case GALLERY_SAVE_SUCCESS:
			case COPY_GALLERY_SUCCESS:
			case ARTWORKS_SUCCESS:
			case ARTWORK_SUCCESS:
			case ARTWORKS_ALL_SUCCESS:
			case ARTWORKS_ALL_POPULATE_SUCCESS:
			case ARTWORK_SAVE_SUCCESS:
			case ARTWORK_FROM_IMAGE_SUCCESS:
			case USERS_SUCCESS:
			case USER_SUCCESS:
			case SOCIETY_ADMIN_SUCCESS:
			case USER_MAKE_SOCIETY_SUCCESS:
			case ARTISTS_SUCCESS:
			case ARTIST_SUCCESS:
			case ARTISTS_ALL_SUCCESS:
			case ARTISTS_ALL_POPULATE_SUCCESS:
			case ARTIST_SAVE_SUCCESS:
			case RAFFLE_SAVE_SUCCESS:
			case SHOW_MAIL_PREVIEW_SUCCESS:
			case POST_MAIL_PREVIEW_SUCCESS:
			case EVENT_MAIL_PREVIEW_SUCCESS:
			case RAFFLE_MAIL_PREVIEW_SUCCESS:
			case ARTWORK_MAIL_PREVIEW_SUCCESS:
			case INFORM_MEMBERS_SHOW_SUCCESS:
			case INFORM_MEMBERS_POST_SUCCESS:
			case INFORM_MEMBERS_EVENT_SUCCESS:
			case INFORM_MEMBERS_RAFFLE_SUCCESS:
			case FILES_FOR_USER_SUCCESS:
			case UPLOAD_FILE_SUCCESS:
			case DELETE_FILE_SUCCESS:
			case RENAME_FILE_SUCCESS:
			case FETCH_EMAIL_HISTORY_SUCCESS:
			case FETCH_EMAIL_SUCCESS:
			case SAVE_ON_UPLOAD_SUCCESS:
			case FETCH_MEMBERS_SUCCESS:
			case EXPORT_MEMBERS_SUCCESS:
			case SEND_EMAIL_TO_MEMBERS_SUCCESS:
			case UPLOAD_MEMBERS_HELP_SUCCESS:
			case SAVE_XMASCALENDAR_SUCCESS:
			case ALTER_XMAS_ARTWORK_SUCCESS:
			case DELETE_XMAS_SUCCESS:
			case UPDATE_CUSTOMERS_SUCCESS:
			case CREATE_INVOICE_SUCCESS:
			case CREATE_INVOICES_SUCCESS:
			case INVOICES_SUCCESS:
			case INVOICE_SUCCESS:
			case INVOICE_PREVIEW_SUCCESS:
			case FILE_SUCCESS:
			case CONTACTLIST_FETCH_SUCCESS:
			case CONTACTLISTS_SUCCESS:
			case CLOSE_ENQUIRY_SUCCESS:
			case PRO_INCREASE_SUCCESS:
			case SELECTION_FETCH_SUCCESS:
			case ARTWORK_CREATE_INVOICE_SUCCESS:
			case MANUAL_ORDER_SUCCESS:
			case ARTIST_INVOICES_SUCCESS:
			case REMOVE_INVOICE_SUCCESS:
			case SEND_CONTACT_EMAIL_SUCCESS:
			case PRINT_GET_SUCCESS:
			case PRINTS_GET_SUCCESS:
			case PRINT_SAVE_SUCCESS:
			case PRINT_REMOVE_SUCCESS:
			case INFLUENCER_SAVE_SUCCESS:
			case INFLUENCER_REMOVE_SUCCESS:
				dispatch(hideLoadingSpinner());
				break;
			case SHOWS_FAILURE:
			case SHOW_FAILURE:
			case SHOWS_ALL_FAILURE:
			case SHOW_SAVE_FAILURE:
			case GET_ACCESS_FAILURE:
			case POSTS_FAILURE:
			case POST_FAILURE:
			case POSTS_ALL_FAILURE:
			case POST_SAVE_FAILURE:
			case UPDATE_POSTS_FAILURE:
			case EVENTS_FAILURE:
			case EVENT_FAILURE:
			case EVENTS_ALL_FAILURE:
			case EVENT_SAVE_FAILURE:
			case EVENT_UPDATE_FAILURE:
			case RSVP_MAIL_FAILURE:
			case RSVP_MAIL_FEE_MISSING_FAILURE:
			case GALLERIES_FAILURE:
			case GALLERY_FAILURE:
			case GALLERIES_ALL_FAILURE:
			case GALLERY_SAVE_FAILURE:
			case COPY_GALLERY_FAILURE:
			case ARTWORKS_FAILURE:
			case ARTWORK_FAILURE:
			case ARTWORKS_ALL_FAILURE:
			case ARTWORK_SAVE_FAILURE:
			case ARTWORK_FROM_IMAGE_FAILURE:
			case USERS_FAILURE:
			case USER_FAILURE:
			case SOCIETY_ADMIN_FAILURE:
			case USER_MAKE_SOCIETY_FAILURE:
			case ARTISTS_FAILURE:
			case ARTIST_FAILURE:
			case ARTISTS_ALL_FAILURE:
			case ARTIST_SAVE_FAILURE:
			case RAFFLE_SAVE_FAILURE:
			case SHOW_MAIL_PREVIEW_FAILURE:
			case POST_MAIL_PREVIEW_FAILURE:
			case EVENT_MAIL_PREVIEW_FAILURE:
			case RAFFLE_MAIL_PREVIEW_FAILURE:
			case ARTWORK_MAIL_PREVIEW_FAILURE:
			case INFORM_MEMBERS_SHOW_FAILURE:
			case INFORM_MEMBERS_POST_FAILURE:
			case INFORM_MEMBERS_EVENT_FAILURE:
			case INFORM_MEMBERS_RAFFLE_FAILURE:
			case FILES_FOR_USER_FAILURE:
			case UPLOAD_FILE_FAILURE:
			case DELETE_FILE_FAILURE:
			case RENAME_FILE_FAILURE:
			case FETCH_EMAIL_HISTORY_FAILURE:
			case FETCH_EMAIL_FAILURE:
			case SAVE_ON_UPLOAD_FAILURE:
			case FETCH_MEMBERS_FAILURE:
			case EXPORT_MEMBERS_FAILURE:
			case SEND_EMAIL_TO_MEMBERS_FAILURE:
			case UPLOAD_MEMBERS_HELP_FAILURE:
			case SAVE_XMASCALENDAR_FAILURE:
			case ALTER_XMAS_ARTWORK_FAILURE:
			case DELETE_XMAS_FAILURE:
			case UPDATE_CUSTOMERS_FAILURE:
			case CREATE_INVOICE_FAILURE:
			case INVOICES_FAILURE:
			case INVOICE_FAILURE:
			case INVOICE_PREVIEW_FAILURE:
			case FILE_FAILURE:
			case CONTACTLIST_FETCH_FAILURE:
			case CONTACTLISTS_FAILURE:
			case CLOSE_ENQUIRY_FAILURE:
			case PRO_INCREASE_FAILURE:
			case SELECTION_FETCH_FAILURE:
			case ARTWORK_CREATE_INVOICE_FAILURE:
			case MANUAL_ORDER_FAILURE:
			case ARTIST_INVOICES_FAILURE:
			case REMOVE_INVOICE_FAILURE:
			case SEND_CONTACT_EMAIL_FAILURE:
			case PRINT_GET_FAILURE:
			case PRINT_SAVE_FAILURE:
			case PRINT_REMOVE_FAILURE:
			case INFLUENCER_SAVE_FAILURE:
			case INFLUENCER_REMOVE_FAILURE:
				dispatch(hideLoadingSpinner());
				break;
			default:
		}
		return next(action);
	};

export default loadingMiddleware;
