import { initialize, stopSubmit, startSubmit, change } from "redux-form";
import diff from "object-diff";
import { saveContactList } from "@artworkslab/sharedmodules/src/actions/ContactActions";

export function submitContactList(data, initial, contactListId) {
	return (dispatch, getState) => {
		const changedValues = initial ? diff(initial, data) : data;
		changedValues._id = data._id ? data._id : undefined;

		const body = { contactListId: contactListId, data: data };
		dispatch(startSubmit("contactlist"));
		return dispatch(saveContactList(body));
	};
}
