import {
	GALLERIES_REQUEST, GALLERIES_SUCCESS, GALLERIES_FAILURE,
	GALLERIES_ALL_REQUEST, GALLERIES_ALL_FAILURE, GALLERIES_ALL_SUCCESS,
	GALLERIES_ALL_POPULATE_REQUEST, GALLERIES_ALL_POPULATE_SUCCESS, GALLERIES_ALL_POPULATE_FAILURE,
	GALLERY_REQUEST, GALLERY_FAILURE, GALLERY_SUCCESS, GALLERY_NO_RESET_REQUEST,
	GALLERY_SAVE_REQUEST, GALLERY_SAVE_FAILED, GALLERY_SAVE_SUCCESS,
	UPDATE_VALUE_REQUEST, UPDATE_VALUE_SUCCESS, UPDATE_VALUE_FAILURE,
	GALLERY_REMOVE_REQUEST, GALLERY_REMOVE_FAILURE, GALLERY_REMOVE_SUCCESS,
	MENTIONS_REQUEST, MENTIONS_SUCCESS, MENTIONS_FAILURE,
	GALLERY_BY_QUERY_REQUEST, GALLERY_BY_QUERY_SUCCESS, GALLERY_BY_QUERY_FAILURE,
	COPY_GALLERY_REQUEST, COPY_GALLERY_SUCCESS, COPY_GALLERY_FAILURE,
	GALLERY_TIER_REQUEST, GALLERY_TIER_SUCCESS, GALLERY_TIER_FAILURE,
} from '../actions/GalleryActions'

export function galleryReducer(state = {
		isFetching: false,
		authenticated: false,
		gallery: {},
		galleries: {},
		allGalleries: {},
		galleriesByQuery: {},
		mentions: {},
	}, action) {
	switch (action.type) {
		case GALLERIES_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				deletedGallery: false,
				galleries: []
			})
		}
		case GALLERIES_SUCCESS: {
			let galleries = {}
			action.response.map((gallery) => {
				galleries[gallery._id] = gallery
			})
			return Object.assign({}, state, {
				isFetching: false,
				galleries: galleries
			})
		}
		case GALLERIES_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case GALLERIES_ALL_POPULATE_REQUEST:
		case GALLERIES_ALL_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case GALLERIES_ALL_SUCCESS: {
			let galleries = {}
			action.response.map((gallery) => {
				galleries[gallery._id] = gallery
			})
			return Object.assign({}, state, {
				isFetching: false,
				allGalleries: galleries,
			})
		}
		case GALLERIES_ALL_POPULATE_SUCCESS: {
			let galleries = Object.assign({}, state.allGalleries)
			action.response.map((gallery) => {
				galleries[gallery._id] = gallery
			})
			return Object.assign({}, state, {
				isFetching: false,
				allGalleries: galleries,
			})
		}
		case GALLERIES_ALL_POPULATE_FAILURE:
		case GALLERIES_ALL_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error,
			})
		}
		case GALLERY_NO_RESET_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case GALLERY_TIER_REQUEST:
		case GALLERY_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				gallery: {}
			})
		}
		case GALLERY_TIER_SUCCESS:
		case GALLERY_SUCCESS: {
			let gallery = action.response.length > 0 ? action.response[0] : action.response
			if (gallery.registry) {
				// Some galleries had gotten an array instead of an object. Need to remove it or it can't be saved.
				if ((Array.isArray && Array.isArray(gallery.registry)) || gallery.registry.length === 0) {
					delete gallery.registry
				}
			}
			return Object.assign({}, state, {
				isFetching: false,
				gallery: gallery,
			})
		}
		case GALLERY_TIER_FAILURE:
		case GALLERY_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case GALLERY_REMOVE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				deletedGallery: true
			})
		}
		case GALLERY_REMOVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case GALLERY_REMOVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case GALLERY_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				updateColumns: false,
			})
		}
		case GALLERY_SAVE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				gallery: action.response,
				updateColumns: action.updatedColumns,
			})
		}
		case GALLERY_SAVE_FAILED: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			})
		}
		case UPDATE_VALUE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case UPDATE_VALUE_SUCCESS: {
			const gallery = action.response
			const galleries = Object.assign({}, state.galleries)
			galleries[gallery._id] = gallery
			return Object.assign({}, state, {
				isFetching: false,
				gallery,
				galleries,
			})
		}
		case UPDATE_VALUE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			})
		}
		case GALLERY_BY_QUERY_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				galleriesByQuery: {},
			})
		}
		case GALLERY_BY_QUERY_SUCCESS: {
			let galleries = {}
			action.response.map((gallery) => {
				galleries[gallery._id] = gallery
			})
			return Object.assign({}, state, {
				isFetching: false,
				galleriesByQuery: galleries
			})
		}
		case GALLERY_BY_QUERY_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case MENTIONS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case MENTIONS_SUCCESS: {
			// console.log(MENTIONS_SUCCESS, action);
			const { galleryId } = action
			let mentions = Object.assign({}, state.mentions)
			mentions[galleryId] = action.response
			return Object.assign({}, state, {
				isFetching: false,
				mentions,
				error: null,
			});
		}
		case MENTIONS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message,
			})
    }
		case COPY_GALLERY_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true
			})
		}
		case COPY_GALLERY_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false
			})
		}
		case COPY_GALLERY_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false
			})
		}
		default:
			return state
	}
}
