import {
	EVENTS_REQUEST, EVENTS_FAILURE, EVENTS_SUCCESS,
	EVENTS_ALL_REQUEST, EVENTS_ALL_FAILURE, EVENTS_ALL_SUCCESS,
	EVENTS_ALL_POPULATE_REQUEST, EVENTS_ALL_POPULATE_SUCCESS, EVENTS_ALL_POPULATE_FAILURE,
	EVENT_REQUEST, EVENT_FAILURE, EVENT_SUCCESS, RESET_EVENT,
	EVENT_SAVE_REQUEST, EVENT_SAVE_FAILURE, EVENT_SAVE_SUCCESS,
	EVENT_UPDATE_REQUEST, EVENT_UPDATE_FAILURE, EVENT_UPDATE_SUCCESS,
	EVENT_DELETE_REQUEST, EVENT_DELETE_FAILURE, EVENT_DELETE_SUCCESS,
	INFORM_MEMBERS_EVENT_REQUEST, INFORM_MEMBERS_EVENT_SUCCESS, INFORM_MEMBERS_EVENT_FAILURE,
	EVENT_MAIL_PREVIEW_REQUEST, EVENT_MAIL_PREVIEW_SUCCESS, EVENT_MAIL_PREVIEW_FAILURE, EVENT_MAIL_PREVIEW_RESET,
	RSVP_MAIL_REQUEST, RSVP_MAIL_SUCCESS, RSVP_MAIL_FAILURE,
	RSVP_MAIL_FEE_MISSING_REQUEST, RSVP_MAIL_FEE_MISSING_SUCCESS, RSVP_MAIL_FEE_MISSING_FAILURE,
} from '../actions/EventActions'

export function eventReducer(state = {
		isFetching: false,
		events: {},
		event: {},
		allEvents: {},
		preview: '',
		rsvpMailSent: false,
		rsvpMailFeeMissingSent: false,
		authenticated: true
	}, action) {
	switch (action.type) {
		case EVENTS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				events: {},
				deletedEvent: false
			})
		}
		case EVENTS_SUCCESS: {
			let events = {}
			action.response.map((event) => {
				events[event._id] = event
			})
			return Object.assign({}, state, {
				isFetching: false,
				events: events,
				error: null
			})
		}
		case EVENTS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case EVENTS_ALL_POPULATE_REQUEST:
		case EVENTS_ALL_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
		}
		case EVENTS_ALL_SUCCESS: {
			let events = {}
			action.response.map((event) => {
				events[event._id] = event
			})
			return Object.assign({}, state, {
				isFetching: false,
				allEvents: events,
			})
		}
		case EVENTS_ALL_POPULATE_SUCCESS: {
			let events = Object.assign({}, state.allEvents)
			action.response.map((event) => {
				events[event._id] = event
			})
			return Object.assign({}, state, {
				isFetching: false,
				allEvents: events,
			})
		}
		case EVENTS_ALL_POPULATE_FAILURE:
		case EVENTS_ALL_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message || action.error,
			})
		}
		case EVENT_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				deletedEvent: false
			})
		}
		case EVENT_SUCCESS: {
			const event = action.response
			let events = Object.assign({}, state.events)
			events[event._id] = event
			return Object.assign({}, state, {
				isFetching: false,
				events: events,
				event,
			})
		}
		case EVENT_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.message
			})
		}
		case EVENT_SAVE_SUCCESS: {
			const event = action.response
			let events = Object.assign({}, state.events)
			let allEvents = Object.assign({}, state.allEvents)
			events[event._id] = event
			allEvents[event._id] = event
			return Object.assign({}, state, {
				isFetching: false,
				events,
				allEvents,
				event,
			})
		}
		case EVENT_SAVE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				deletedEvent: false
			})
		}
		case EVENT_SAVE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case EVENT_UPDATE_SUCCESS: {
			const event = action.response
			let events = Object.assign({}, state.events)
			let allEvents = Object.assign({}, state.allEvents)
			events[event._id] = event
			allEvents[event._id] = event
			return Object.assign({}, state, {
				isFetching: false,
				events,
				allEvents,
				event,
			})
		}
		case EVENT_UPDATE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null
			})
		}
		case EVENT_UPDATE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case EVENT_DELETE_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				deletedEvent: true
			})
		}
		case EVENT_DELETE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
				deletedEvent: false
			})
		}
		case EVENT_DELETE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			})
		}
		case INFORM_MEMBERS_EVENT_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
			})
		}
		case INFORM_MEMBERS_EVENT_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				event: action.response,
			})
		}
		case INFORM_MEMBERS_EVENT_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informMembersError',
			})
		}
		case EVENT_MAIL_PREVIEW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				preview: '',
				error: null,
			})
		}
		case EVENT_MAIL_PREVIEW_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				preview: action.response,
			})
		}
		case EVENT_MAIL_PREVIEW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: 'informMembersError',
			})
		}
		case EVENT_MAIL_PREVIEW_RESET: {
			return Object.assign({}, state, {
				preview: '',
			})
		}
		case RSVP_MAIL_REQUEST: {
			return Object.assign({}, state, {
				rsvpMailSent: false,
				isFetching: true,
				error: null,
			})
		}
		case RSVP_MAIL_SUCCESS: {
			return Object.assign({}, state, {
				rsvpMailSent: true,
				isFetching: false,
			})
		}
		case RSVP_MAIL_FAILURE: {
			return Object.assign({}, state, {
				rsvpMailSent: false,
				isFetching: false,
				error: action.message,
			})
		}
		case RSVP_MAIL_FEE_MISSING_REQUEST: {
			return Object.assign({}, state, {
				rsvpMailFeeMissingSent: false,
				isFetching: true,
				error: null,
			})
		}
		case RSVP_MAIL_FEE_MISSING_SUCCESS: {
			return Object.assign({}, state, {
				rsvpMailFeeMissingSent: true,
				isFetching: false,
			})
		}
		case RSVP_MAIL_FEE_MISSING_FAILURE: {
			return Object.assign({}, state, {
				rsvpMailFeeMissingSent: false,
				isFetching: false,
				error: action.message,
			})
		}
		case RESET_EVENT: {
			return Object.assign({}, state, {
				event: {},
			})
		}
		default:
			return state
	}
}
