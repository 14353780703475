import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	formValueSelector,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import LoginForm from "./LoginForm";
import Input from "../../components/Input/Input";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import { logoutUser } from "@artworkslab/sharedmodules/src/actions/AuthActions";
import {
	initLoginUser,
	submitLoginUser,
	PREMIUM_POPUP_CLOSED,
} from "../../actions/FormActions/LoginFormActions";
import { withRouter, Link } from "react-router";
import { withNamespaces } from "react-i18next";

class Login extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
	}

	componentWillReceiveProps(newProps) {
		if (newProps.authenticated) {
			this.redirectToDashboard(newProps.user);
		}
	}

	componentWillMount() {
		if (this.props.authenticated) {
			this.redirectToDashboard(this.props.user);
		} else {
			this.props.dispatch(initLoginUser());
		}
	}

	redirectToDashboard = (user) => {
		const isSociety = !!(user.role === "society");
		const isArtist = !!(user.role === "artist");
		const isAdmin = !!(user.role === "admin");
		const isUser = !!(user.role === "user");
		const isResponsive = appGlobals.useResponsive;

		if (isResponsive && isAdmin) {
			this.props.router.push("/settings");
		} else if (isResponsive && isArtist) {
			this.props.router.push("/artists/me");
		} else if (isUser) {
			this.props.router.push("/register");
			this.props.dispatch(logoutUser());
		} else {
			// Open popup after logging in, at least for the first few weeks of premium.
			// Turned off now. Need to remake popup for new tiers if we want to use it again.
			// localStorage.setItem(PREMIUM_POPUP_CLOSED, 'not-closed')
			this.props.router.push("/dashboard");
		}
	};

	handleSubmit = () => {
		const {
			form: { login },
		} = this.props;
		this.props.dispatch(submitLoginUser(login));
	};

	render() {
		const { t } = this.props;
		return (
			<div>
				<MainResp contentClassName="slim-content">
					<div className="login-new__content__login-section">
						<LoginForm
							onSubmit={this.handleSubmit}
							t={t}
							updateOnLangChange={this.props.update_DO_NOT_REMOVE}
							className="responsive-form"
						/>
						<p style={{ marginTop: 20 }}>
							<Link to="/forgot">{t("login.forgotPassword")}</Link>
						</p>
						<p style={{ marginTop: 20 }}>
							<Link to="/register">{t("login.toRegister")}</Link>
						</p>
					</div>
				</MainResp>
			</div>
		);
		// return (
		// 	<div>
		// 		<Main>
		// 			<div style={{display: 'flex', justifyContent: 'center'}}>
		// 				<div style={{width: '800px'}}>
		// 					<LoginForm
		// 					 	onSubmit={ this.handleSubmit }
		// 						t={ t }
		// 						updateOnLangChange={ this.props.update_DO_NOT_REMOVE }
		// 						className="Form"
		// 						/>
		// 					<p style={{fontSize: 18, marginTop: 20}}><Link to="/forgot">{t('login.forgotPassword')}</Link></p>
		// 					<p style={{fontSize: 18, marginTop: 20}} ><Link to="/register">{t('login.toRegister')}</Link></p>
		// 				</div>
		// 			</div>
		// 		</Main>
		// 	</div>
		// );
	}
}

function mapStateToProps(state) {
	const {
		auth: { authenticated, user },
	} = state;

	const form = {
		submitting: isSubmitting("login")(state),
		submitSucceeded: hasSubmitSucceeded("login")(state),
		submitFailed: hasSubmitFailed("login")(state),
		login: getFormValues("login")(state),
		initial: getFormInitialValues("login")(state),
	};
	return { form, authenticated, user };
}

const translated = withNamespaces("common")(Login);
export default connect(mapStateToProps)(withRouter(translated));
