import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Spinner from "react-spinkit";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import { fetchArtworksWithQuery } from "../../actions/ArtworkActions";
import { fetchArtistShows } from "../../actions/ShowActions";
import { fetchEvents } from "../../actions/EventActions";
import SelectContacts from "./SelectContacts/SelectContacts";
import EmailMaker from "./EmailMaker/EmailMaker";
import SendEmailButton from "../Buttons/SendEmailButton/SendEmailButton";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const SEARCH_GLASS = require("../../../assets/images/search_glass_black.png");

const EMAIL_TYPES = [
	{ label: "Konstverk", value: "artwork" },
	{ label: "Aktivitet", value: "event" },
	{ label: "Utställning", value: "show" },
];

const EMAIL_TYPES_ENG = [
	{ label: "Artworks", value: "artwork" },
	{ label: "Event", value: "event" },
	{ label: "Show", value: "show" },
];

class EmailConstructor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: null,
			forEmail: [],
			emailLists: [],
			hasRecipient: false,
			filterOn: false,
			message: {
				subject: "",
				text: "",
			},
		};
	}

	//Change the email type
	changeType = (option) => {
		this.setState({
			type: option,
			forEmail: [],
		});
		this.fetchData(option.value);
	};

	//Fetch data for email depending on selected type
	fetchData = (type) => {
		const { artist } = this.props.user;

		const artistId = artist._id || artist;
		let fetchFunction = null;
		switch (type) {
			case "artwork":
				const query = {
					artistMod: artistId,
					publish_status: "published",
				};
				fetchFunction = fetchArtworksWithQuery(query);
				break;
			case "show":
				fetchFunction = fetchArtistShows(artistId);
				break;
			case "event":
				fetchFunction = fetchEvents();
				break;
			default:
				break;
		}
		if (fetchFunction) {
			this.props.dispatch(fetchFunction);
		}
	};

	//Sets state emailLists to given value
	//Utilized by child component
	setEmailLists = (list) => {
		const hasRecipient = list.length > 0;
		this.setState({
			emailLists: list,
			hasRecipient: hasRecipient,
		});
	};

	//Sets state message to given value
	//Utilized by child component
	updateMessage = (message) => {
		this.setState({
			message: message,
		});
	};

	//Toggles filterOn value which determines table filterable
	toggleFilter = () => {
		const { filterOn } = this.state;
		this.setState({
			filterOn: !filterOn,
		});
	};

	//Toggles item to be included in email
	toggleForSend = (item) => {
		const { t } = this.props;
		const { type, hasRecipient } = this.state;

		if (!hasRecipient) {
			window.alert(t("warning.recipient"));
			return;
		}

		let checkedItems = this.state.forEmail.slice();
		const checkedData = this.isCheckedSend(item._id);

		let totalSize = 0;
		checkedItems.forEach((art) => {
			totalSize += art.size;
		});

		if (!checkedData.isChecked) {
			if (type.value === "artwork") {
				const localArt = this.createLocalArt(item);
				checkedItems.push(localArt);
			} else {
				checkedItems = [item];
			}
		} else {
			checkedItems.splice(checkedData.index, 1);
		}

		this.setState({
			forEmail: checkedItems,
		});
	};

	//Return boolean regarding items checked state and its index if it is checked
	isCheckedSend = (itemId) => {
		const { forEmail } = this.state;
		let isChecked = false;
		let index = null;

		forEmail.forEach((item, i) => {
			if (item._id === itemId) {
				isChecked = true;
				index = i;
			}
		});
		return { isChecked, index };
	};

	//Creates a local artwork which is formatted for sending email
	createLocalArt = (artwork) => {
		const art = {
			_id: artwork._id,
			URL: artwork.images[0].src,
			title: artwork.title ? artwork.title : "",
			contentType: "image/jpg",
			price: artwork.price ? artwork.price : "",
			technique:
				artwork.mediumMod[0] && artwork.mediumMod[0].text
					? artwork.mediumMod[0].text
					: "",
			dimensions:
				artwork.width && artwork.height
					? `${artwork.height} x ${artwork.width}`
					: "",
			created: artwork.created_at,
			slug: artwork.slug ? artwork.slug : "untitled",
			status: artwork.publish_status,
			checked: false,
			size: artwork.images[0].src.length,
			VATcode: artwork.VATcode,
		};
		return art;
	};

	//Returns the main image for a given item
	getItemImage = (item) => {
		const { type } = this.state;
		let image = "";
		if (type.value === "artwork") {
			image = item.images[0] || "";
		} else {
			image = item.featuredImage || "";
		}
		// image.thumbnails ? image.thumbnails['100x100'].src :
		if (image) {
			return image.src;
		} else {
			return "";
		}
	};

	//Default thumbnailError "handler"
	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	//Returns true if list is entirely empty or only contains items that are set as draft
	isEmpty = (list) => {
		if (list.length <= 0) {
			return true;
		}
		let hasPublished = false;
		Object.keys(list).forEach((key) => {
			const item = list[key];
			const status = item.publish_status ? item.publish_status : item.status;
			if (status === "published") {
				hasPublished = true;
			}
		});
		return !hasPublished;
	};

	//Renders table for fetched items
	renderTable(rows, maxItems, t) {
		const { filterOn } = this.state;

		// <Th column='created_at'>{ t('table.created_at') }</Th>
		// <Th column='status'>{ t('table.status') }</Th>
		return (
			<Table
				className="reactable"
				sortable={["title", "created_at"]}
				filterable={filterOn ? ["title"] : null}
				filterPlaceholder={t("table.search")}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="sendCheck">{t("table.checkbox")}</Th>
					<Th column="image">{""}</Th>
					<Th column="title">{t("table.title")}</Th>
				</Thead>
				{rows}
			</Table>
		);
	}

	//Render rows for fetched items
	renderRows(items, t) {
		let rows = [];
		const that = this;
		// <Td column="created_at" value={ item.created_at }>
		//   { item.created_at ? DateUtil.dateMonthShort(item.created_at, i18next.language, true) : 'N/A' }
		// </Td>
		// <Td column="status" value={ status }>{ t(`common:status.${status}`) }</Td>
		Object.keys(items)
			.sort((a, b) => {
				let aObject = items[a];
				let bObject = items[b];
				return new Date(bObject.created_at) - new Date(aObject.created_at);
			})
			.forEach((key) => {
				const item = items[key];
				const imgSrc = this.getItemImage(item);
				const isChecked = this.isCheckedSend(item._id).isChecked;
				const status = item.publish_status ? item.publish_status : item.status;

				//Filter shows and events that aren't published
				if (status === "published") {
					rows.push(
						<Tr key={item._id}>
							<Td column="sendCheck">
								<input
									type="checkbox"
									className="table-checkbox"
									onChange={() => this.toggleForSend(item)}
									checked={isChecked}
									disabled={false}
								/>
							</Td>
							<Td column="image">
								{imgSrc ? (
									<img
										src={imgSrc}
										className="table-image"
										onError={(e) =>
											this.thumbnailError(e, e.target.src, thumbnailSrc)
										}
										alt="Item image"
									/>
								) : null}
							</Td>
							<Td column="title" value={item.title}>
								{item.title}
							</Td>
						</Tr>
					);
				}
			});
		return rows;
	}

	render() {
		const { artworks, events, shows, user, isFetching, t } = this.props;
		const { type, forEmail, emailLists, hasRecipient, message } = this.state;

		const isSwedish = i18next.language === "sv";
		let table = null;
		let items = [];
		if (type && type.value === "artwork" && Object.keys(artworks).length > 0) {
			items = artworks;
		} else if (
			type &&
			type.value === "event" &&
			Object.keys(events).length > 0
		) {
			items = events;
		} else if (type && type.value === "show" && Object.keys(shows).length > 0) {
			items = shows;
		}
		const empty = this.isEmpty(items);
		if (!empty) {
			table = this.renderTable(this.renderRows(items, t), false, t);
		}

		return (
			<div className="EmailConstructor">
				<legend className="legend-nomargin" style={{ fontSize: "15px" }}>
					{t("artistEmail.title")}
				</legend>
				<div className="responsive-form">
					<Select
						value={type}
						options={isSwedish ? EMAIL_TYPES : EMAIL_TYPES_ENG}
						onChange={this.changeType}
						placeholder={t("artistEmail.type")}
						multi={false}
						autosize={false}
						className="no-clear-all"
					/>
				</div>
				{type && (
					<div className="EmailConstructor__content">
						<SelectContacts
							userId={user.id}
							emailLists={emailLists}
							setEmailLists={this.setEmailLists}
						/>
						<EmailMaker
							message={message}
							attachments={forEmail}
							setMessage={this.updateMessage}
							hasRecipient={hasRecipient}
						/>
						{empty ? (
							<div className="ChatList__empty">
								<p>{t(`empty.${type.value}`)}</p>
							</div>
						) : (
							<div className="EmailConstructor__content__content-list">
								<div className="EmailConstructor__content__content-list__header">
									<legend className="legend-nomargin">{`${t(
										"table.checkbox"
									)} ${type.label.toLowerCase()}`}</legend>
									<img src={SEARCH_GLASS} onClick={() => this.toggleFilter()} />
								</div>
								{table}
							</div>
						)}
						<SendEmailButton
							type={type.value}
							items={forEmail}
							emailLists={emailLists}
							message={message}
							user={user}
						/>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artwork: { artworks, isFetching: fetchingArtworks },
		event: { events, isFetching: fetchingEvents },
		show: { shows, isFetching: fetchingShows },
		auth: { user },
	} = state;
	const isFetching = fetchingArtworks || fetchingEvents || fetchingShows;
	return { user, artworks, events, shows, isFetching };
}

const translated = withNamespaces("emails")(EmailConstructor);
export default connect(mapStateToProps)(translated);
