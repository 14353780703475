import {
  FILES_FOR_USER_REQUEST, FILES_FOR_USER_SUCCESS, FILES_FOR_USER_FAILURE,
  UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE,
  RENAME_FILE_REQUEST, RENAME_FILE_SUCCESS, RENAME_FILE_FAILURE,
  DELETE_FILE_REQUEST, DELETE_FILE_SUCCESS, DELETE_FILE_FAILURE,
  FETCH_EMAIL_HISTORY_REQUEST, FETCH_EMAIL_HISTORY_SUCCESS, FETCH_EMAIL_HISTORY_FAILURE,
  FETCH_EMAIL_REQUEST, FETCH_EMAIL_SUCCESS, FETCH_EMAIL_FAILURE,
  FETCH_FAILED_EMAILS_REQUEST, FETCH_FAILED_EMAILS_SUCCESS, FETCH_FAILED_EMAILS_FAILURE,
  FETCH_FAILED_GALLERIES_REQUEST, FETCH_FAILED_GALLERIES_SUCCESS, FETCH_FAILED_GALLERIES_FAILURE,
} from '../actions/ArchiveActions'

export const archiveReducer = (state = {
  archive: [],
  uploaded: null,
  emailHistory: [],
  emailsWithHTML: {},
  failedEmails: {},
  failedGalleries: [],
  isFetching: false,
  error: null,
}, action) => {
  switch(action.type) {
    case FILES_FOR_USER_REQUEST: {
      return Object.assign({}, state, {
        // Don't empty, fetching after deleting causes blip.
        // archive: [],
        isFetching: true,
      })
    }
    case FILES_FOR_USER_SUCCESS: {
      return Object.assign({}, state, {
        archive: action.response,
        isFetching: false,
      })
    }
    case FILES_FOR_USER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case UPLOAD_FILE_REQUEST: {
      return Object.assign({}, state, {
        uploaded: null,
        isFetching: true,
      })
    }
    case UPLOAD_FILE_SUCCESS: {
      let archive = state.archive.slice()
      action.response.forEach(obj => {
        archive.unshift(obj)
      })
      return Object.assign({}, state, {
        uploaded: action.response,
        archive,
        isFetching: false,
      })
    }
    case UPLOAD_FILE_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case RENAME_FILE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case RENAME_FILE_SUCCESS: {
      return Object.assign({}, state, {
        archive: action.response,
        isFetching: false,
      })
    }
    case RENAME_FILE_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case DELETE_FILE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case DELETE_FILE_SUCCESS: {
      // After delete success, archive is fetched again in FileArchive.js
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case DELETE_FILE_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case FETCH_EMAIL_HISTORY_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case FETCH_EMAIL_HISTORY_SUCCESS: {
      return Object.assign({}, state, {
        emailHistory: action.response,
        isFetching: false,
      })
    }
    case FETCH_EMAIL_HISTORY_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case FETCH_EMAIL_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case FETCH_EMAIL_SUCCESS: {
      const emailsWithHTML = Object.assign({}, state.emailsWithHTML)
      emailsWithHTML[action.response.key] = action.response.body
      return Object.assign({}, state, {
        emailsWithHTML,
        isFetching: false,
      })
    }
    case FETCH_EMAIL_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case FETCH_FAILED_EMAILS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case FETCH_FAILED_EMAILS_SUCCESS: {
      const failedEmails = action.response
      return Object.assign({}, state, {
        failedEmails,
        isFetching: false,
      })
    }
    case FETCH_FAILED_EMAILS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    case FETCH_FAILED_GALLERIES_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case FETCH_FAILED_GALLERIES_SUCCESS: {
      const failedGalleries = action.response
      return Object.assign({}, state, {
        failedGalleries,
        isFetching: false,
      })
    }
    case FETCH_FAILED_GALLERIES_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
      })
    }
    default: {
      return state
    }
  }
}
