import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

class MetaButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showData: false,
		};
	}

	showMetaData = () => {
		this.setState({
			showData: !this.state.showData,
		});
	};

	formatDate = (date) => {
		return moment(date).format("MMMM Do YYYY");
	};

	render() {
		const { item, isAdmin } = this.props;

		if (!isAdmin || !item) {
			return null;
		}

		return (
			<div className="meta-button">
				<button
					type="button"
					className="purple-button--wide purple-button--wide--mod_meta"
					onClick={this.showMetaData}
				>
					Meta
				</button>
				{this.state.showData && (
					<div className="meta-button__foldout">
						<ul>
							{item.created_by && (
								<li>
									<h4>Created by</h4>
									<p>{item.created_by.email}</p>
								</li>
							)}
							{item.created_at && (
								<li>
									<h4>Created at</h4>
									<p>{this.formatDate(item.created_at)}</p>
								</li>
							)}
							{item.edited_by && (
								<li>
									<h4>Updated by</h4>
									<p>{item.edited_by.email}</p>
								</li>
							)}
							{item.updated_at && (
								<li>
									<h4>Updated at</h4>
									<p>{this.formatDate(item.updated_at)}</p>
								</li>
							)}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { isAdmin },
	} = state;
	return { isAdmin };
}

export default connect(mapStateToProps)(MetaButton);
