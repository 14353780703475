import Api from "@artworkslab/sharedmodules/src/Api";

export const STRIPE_FETCH_SUBSCRIBER_REQUEST =
	"stripe/stripeSubActions/STRIPE_FETCH_SUBSCRIBER_REQUEST";
export const STRIPE_FETCH_SUBSCRIBER_SUCCESS =
	"stripe/stripeSubActions/STRIPE_FETCH_SUBSCRIBER_SUCCESS";
export const STRIPE_FETCH_SUBSCRIBER_FAILURE =
	"stripe/stripeSubActions/STRIPE_FETCH_SUBSCRIBER_FAILURE";

export function fetchStripeSubscribers(type) {
	return (dispatch) => {
		dispatch(fetchStripeSubscribersRequest());
		return Api(`stripe-subscription/subscribers/${type}`, "GET", true)
			.then((json) => {
				dispatch(fetchStripeSubscribersSuccess(json));
			})
			.catch((err) => {
				dispatch(fetchStripeSubscribersFailure(err));
			});
	};
}

function fetchStripeSubscribersRequest() {
	return {
		type: STRIPE_FETCH_SUBSCRIBER_REQUEST,
	};
}

function fetchStripeSubscribersSuccess(response) {
	return {
		type: STRIPE_FETCH_SUBSCRIBER_SUCCESS,
		response,
	};
}

function fetchStripeSubscribersFailure(message) {
	return {
		type: STRIPE_FETCH_SUBSCRIBER_FAILURE,
		error: message,
	};
}

export const STRIPE_FETCH_SUBSCRIPTION_REQUEST =
	"stripe/stripeSubActions/STRIPE_FETCH_SUBSCRIPTION_REQUEST";
export const STRIPE_FETCH_SUBSCRIPTION_SUCCESS =
	"stripe/stripeSubActions/STRIPE_FETCH_SUBSCRIPTION_SUCCESS";
export const STRIPE_FETCH_SUBSCRIPTION_FAILURE =
	"stripe/stripeSubActions/STRIPE_FETCH_SUBSCRIPTION_FAILURE";

export function fetchStripeSubscription(id) {
	return (dispatch) => {
		dispatch(fetchStripeSubscriptionRequest());
		return Api(`stripe-subscription/subscriptions/${id}`, "GET", true)
			.then((json) => {
				console.log("success", json);
				dispatch(fetchStripeSubscriptionSuccess(json));
			})
			.catch((err) => {
				console.log("err", err);
				dispatch(fetchStripeSubscriptionFailure(err));
			});
	};
}

function fetchStripeSubscriptionRequest() {
	return {
		type: STRIPE_FETCH_SUBSCRIPTION_REQUEST,
	};
}

function fetchStripeSubscriptionSuccess(response) {
	return {
		type: STRIPE_FETCH_SUBSCRIPTION_SUCCESS,
		response,
	};
}

function fetchStripeSubscriptionFailure(message) {
	return {
		type: STRIPE_FETCH_SUBSCRIPTION_FAILURE,
		error: message,
	};
}

export function addOrRemoveFromArtist(artistId, subscriptionId, type) {
	console.log("ACTION addOrRemoveFromArtist", artistId, subscriptionId, type);
	const endpoint = `stripe-subscription/set-on-artist/${type}`;
	const body = { artistId, subscriptionId };
	console.log("endpoint", endpoint);
	return (dispatch) => {
		dispatch(fetchStripeSubscriptionRequest());
		return Api(endpoint, "PUT", true, body)
			.then((json) => {
				console.log("success 1", json);
				// Updated artist returned from set-on-artist used by tests. For Portal we want to return subscription.
				return Api(
					`stripe-subscription/subscriptions/${subscriptionId}`,
					"GET",
					true
				);
			})
			.then((json) => {
				console.log("success 2", json);
				dispatch(fetchStripeSubscriptionSuccess(json));
			})
			.catch((err) => {
				console.log("err", err);
				dispatch(fetchStripeSubscriptionFailure(err));
			});
	};
}

export function syncStripeUser(userId, userEmail) {
	return new Promise((resolve, reject) => {
		const body = { userId, userEmail };
		return Api("stripe-billing/sync-user", "PUT", true, body)
			.then((json) => {
				resolve(json);
			})
			.catch((err) => {
				console.log("syncStripeUser error:", err);
				reject(err);
			});
	});
}
