import {
  TOGGLE_SOCIETY_PACKAGE_MODAL,
} from '../actions/ModalActions'

export function modalReducer(state = {
  societyPackageModalOpen: false,
  societyPackageForceChoice: false,
  societyPackageHeader: '',
  societyPackageFooter: '',
}, action) {
  switch(action.type) {
    case TOGGLE_SOCIETY_PACKAGE_MODAL: {
      return Object.assign({}, state, {
        societyPackageModalOpen: action.doOpen,
        societyPackageForceChoice: action.forceChoice,
        societyPackageHeader: action.header,
        societyPackageFooter: action.footer,
      })
    }
    default: {
      return state
    }
  }
}
