import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

export const SAVE_ON_UPLOAD_REQUEST = "members/SAVE_ON_UPLOAD_REQUEST";
export const SAVE_ON_UPLOAD_SUCCESS = "members/SAVE_ON_UPLOAD_SUCCESS";
export const SAVE_ON_UPLOAD_FAILURE = "members/SAVE_ON_UPLOAD_FAILURE";

export function saveOnUpload(organisationId, members, prevMemberCount) {
	return async (dispatch) => {
		const endpoint = `registry/${organisationId}/upload`;
		dispatch(saveOnUploadRequest());
		return Api(endpoint, "POST", true, members)
			.then((json) => {
				dispatch(saveOnUploadSuccess(json, prevMemberCount));
			})
			.catch((error) => {
				dispatch(saveOnUploadFailure(error));
			});
	};
}

function saveOnUploadRequest() {
	return {
		type: SAVE_ON_UPLOAD_REQUEST,
	};
}

function saveOnUploadSuccess(members, prevMemberCount) {
	// members = all members, including previous ones.
	const newMembers = members.length - prevMemberCount;
	const message =
		i18next.language === "sv"
			? `Kul. ${newMembers} medlemmar uppladdade!`
			: `Great. ${newMembers} members uploaded!`;
	return {
		type: SAVE_ON_UPLOAD_SUCCESS,
		response: members,
		message,
	};
}

function saveOnUploadFailure(error) {
	return {
		type: SAVE_ON_UPLOAD_FAILURE,
		message: error,
	};
}

export const FETCH_MEMBERS_REQUEST = "members/FETCH_MEMBERS_REQUEST";
export const FETCH_MEMBERS_SUCCESS = "members/FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_FAILURE = "members/FETCH_MEMBERS_FAILURE";

export function fetchMembers(organisationId) {
	return async (dispatch) => {
		const endpoint = `registry/${organisationId}`;
		dispatch(fetchMembersRequest());
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchMembersSuccess(json));
			})
			.catch((error) => {
				dispatch(fetchMembersFailure(error));
			});
	};
}

function fetchMembersRequest() {
	return {
		type: FETCH_MEMBERS_REQUEST,
	};
}

function fetchMembersSuccess(members) {
	return {
		type: FETCH_MEMBERS_SUCCESS,
		response: members,
	};
}

function fetchMembersFailure(error) {
	return {
		type: FETCH_MEMBERS_FAILURE,
		message: error,
	};
}

export function importMembersFromOldRegistry(organisationId) {
	return async (dispatch) => {
		const endpoint = `registry/importoldregistry/${organisationId}`;
		dispatch(fetchMembersRequest());
		return Api(endpoint, "GET", true)
			.then((json) => {
				dispatch(fetchMembersSuccess(json));
			})
			.catch((error) => {
				dispatch(fetchMembersFailure(error));
			});
	};
}

export const UPDATE_MEMBER_REQUEST = "members/UPDATE_MEMBER_REQUEST";
export const UPDATE_MEMBER_SUCCESS = "members/UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "members/UPDATE_MEMBER_FAILURE";

export function updateMember(memberId, params, isNew) {
	return async (dispatch) => {
		const endpoint = memberId
			? `registry/member/${memberId}`
			: "registry/member";
		const METHOD = memberId ? "PUT" : "POST";
		dispatch(updateMemberRequest());
		return Api(endpoint, METHOD, true, params)
			.then((json) => {
				// Add isNew to new members, to sort them so they always appear on top of the list regardless of sort.
				// Members keep isNew when updated, only disappears when sorting manually.
				if (!memberId || isNew) json.isNew = true;
				dispatch(updateMemberSuccess(json, METHOD));
			})
			.catch((error) => {
				dispatch(updateMemberFailure(error));
			});
	};
}

function updateMemberRequest() {
	return {
		type: UPDATE_MEMBER_REQUEST,
	};
}

function updateMemberSuccess(member, METHOD) {
	return {
		type: UPDATE_MEMBER_SUCCESS,
		response: member,
		method: METHOD,
	};
}

function updateMemberFailure(error) {
	return {
		type: UPDATE_MEMBER_FAILURE,
		message: error,
	};
}

export const UPDATE_MEMBERS_REQUEST = "members/UPDATE_MEMBERS_REQUEST";
export const UPDATE_MEMBERS_SUCCESS = "members/UPDATE_MEMBERS_SUCCESS";
export const UPDATE_MEMBERS_FAILURE = "members/UPDATE_MEMBERS_FAILURE";

export function updateMembersMailingLists(listId, memberIds, value) {
	return async (dispatch) => {
		const endpoint = "registry/member/mailinglists";
		const body = {
			memberIds,
			listId,
			value,
		};
		dispatch(updateMembersRequest());
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(updateMembersSuccess(json));
			})
			.catch((error) => {
				dispatch(updateMembersFailure(error));
			});
	};
}

function updateMembersRequest() {
	return {
		type: UPDATE_MEMBERS_REQUEST,
	};
}

function updateMembersSuccess(members) {
	return {
		type: UPDATE_MEMBERS_SUCCESS,
		response: members,
	};
}

function updateMembersFailure(error) {
	return {
		type: UPDATE_MEMBERS_FAILURE,
		message: error,
	};
}

export function memberRemoveNew(member) {
	return (dispatch) => {
		delete member.isNew;
		dispatch(updateMemberSuccess(member, "PUT"));
	};
}

export const DELETE_MEMBER_REQUEST = "members/DELETE_MEMBER_REQUEST";
export const DELETE_MEMBER_SUCCESS = "members/DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILURE = "members/DELETE_MEMBER_FAILURE";

export function deleteMember(memberId) {
	return async (dispatch) => {
		const endpoint = `registry/member/${memberId}`;
		dispatch(deleteMemberRequest());
		return Api(endpoint, "DELETE", true)
			.then((json) => {
				dispatch(deleteMemberSuccess(json));
			})
			.catch((error) => {
				dispatch(deleteMemberFailure(error));
			});
	};
}

function deleteMemberRequest() {
	return {
		type: DELETE_MEMBER_REQUEST,
	};
}

function deleteMemberSuccess(memberId) {
	return {
		type: DELETE_MEMBER_SUCCESS,
		response: memberId,
	};
}

function deleteMemberFailure(error) {
	return {
		type: DELETE_MEMBER_FAILURE,
		message: error,
	};
}

export const DELETE_ALL_MEMBERS_REQUEST = "members/DELETE_ALL_MEMBERS_REQUEST";
export const DELETE_ALL_MEMBERS_SUCCESS = "members/DELETE_ALL_MEMBERS_SUCCESS";
export const DELETE_ALL_MEMBERS_FAILURE = "members/DELETE_ALL_MEMBERS_FAILURE";

export function deleteAllMembers(organisation) {
	return async (dispatch) => {
		const endpoint = `registry/${organisation}`;
		dispatch(deleteAllMembersRequest());
		return Api(endpoint, "DELETE", true)
			.then((json) => {
				dispatch(deleteAllMembersSuccess(json));
			})
			.catch((error) => {
				dispatch(deleteAllMembersFailure(error));
			});
	};
}

function deleteAllMembersRequest() {
	return {
		type: DELETE_ALL_MEMBERS_REQUEST,
	};
}

function deleteAllMembersSuccess(members) {
	return {
		type: DELETE_ALL_MEMBERS_SUCCESS,
		response: members,
	};
}

function deleteAllMembersFailure(error) {
	return {
		type: DELETE_ALL_MEMBERS_FAILURE,
		message: error,
	};
}

export const EXPORT_MEMBERS_REQUEST = "members/EXPORT_MEMBERS_REQUEST";
export const EXPORT_MEMBERS_SUCCESS = "members/EXPORT_MEMBERS_SUCCESS";
export const EXPORT_MEMBERS_FAILURE = "members/EXPORT_MEMBERS_FAILURE";

// import { CALL_API } 		from '../utils/api'
// export function exportData(headers, data) {
// 	return {
// 		[CALL_API]: {
// 			endpoint: 'registry/export/',
// 			authenticated: true,
// 			types: [EXPORT_MEMBERS_REQUEST, EXPORT_MEMBERS_SUCCESS, EXPORT_MEMBERS_FAILURE],
//       body: { headers, data },
// 		}
// 	}
// }

// Also used in Newsletter.js
export function exportData(headers, data) {
	return async (dispatch) => {
		const endpoint = `registry/export/`;
		dispatch(exportMemberRequest());
		const body = { headers, data };
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(exportMemberSuccess(json));
			})
			.catch((error) => {
				dispatch(exportMemberFailure(error));
			});
	};
}

function exportMemberRequest() {
	return {
		type: EXPORT_MEMBERS_REQUEST,
	};
}

function exportMemberSuccess(json) {
	return {
		type: EXPORT_MEMBERS_SUCCESS,
		response: json,
	};
}

function exportMemberFailure(error) {
	return {
		type: EXPORT_MEMBERS_FAILURE,
		message: error,
	};
}

export const SEND_EMAIL_TO_MEMBERS_REQUEST =
	"members/SEND_EMAIL_TO_MEMBERS_REQUEST";
export const SEND_EMAIL_TO_MEMBERS_SUCCESS =
	"members/SEND_EMAIL_TO_MEMBERS_SUCCESS";
export const SEND_EMAIL_TO_MEMBERS_FAILURE =
	"members/SEND_EMAIL_TO_MEMBERS_FAILURE";
export const SEND_EMAIL_TO_MEMBERS_RESET =
	"members/SEND_EMAIL_TO_MEMBERS_RESET";

export function sendEmailToMembers(
	galleryId,
	mailingListId,
	senderEmail,
	emailHeader,
	emailBody,
	attachments,
	metadata,
	senderName
) {
	return async (dispatch) => {
		const endpoint = "registry/sendtomailinglist/";
		dispatch(sendEmailToMembersRequest());
		const body = {
			galleryId,
			mailingListId,
			sender: senderEmail,
			senderName,
			subject: emailHeader,
			body: emailBody,
			attachments,
			mdata: metadata,
		};

		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(sendEmailToMembersSuccess(json));
			})
			.catch((error) => {
				dispatch(sendEmailToMembersFailure(error));
			});
	};
}

function sendEmailToMembersRequest() {
	return {
		type: SEND_EMAIL_TO_MEMBERS_REQUEST,
	};
}

function sendEmailToMembersSuccess(json) {
	return {
		type: SEND_EMAIL_TO_MEMBERS_SUCCESS,
		response: json,
	};
}

function sendEmailToMembersFailure(error) {
	return {
		type: SEND_EMAIL_TO_MEMBERS_FAILURE,
		message: error,
	};
}

export function sendEmailToMembersReset() {
	return {
		type: SEND_EMAIL_TO_MEMBERS_RESET,
	};
}

export const UPLOAD_MEMBERS_HELP_REQUEST =
	"registryActions/UPLOAD_MEMBERS_HELP_REQUEST";
export const UPLOAD_MEMBERS_HELP_SUCCESS =
	"registryActions/UPLOAD_MEMBERS_HELP_SUCCESS";
export const UPLOAD_MEMBERS_HELP_FAILURE =
	"registryActions/UPLOAD_MEMBERS_HELP_FAILURE";

// After uploading excel file to file archive, use this function to inform us they want our help to upload member list.
export function uploadMembersHelp(galleryId, attachment) {
	return async (dispatch) => {
		const endpoint = `registry/${galleryId}/member-upload-help`;
		dispatch(uploadMembersHelpRequest());
		const body = { attachment };
		return Api(endpoint, "POST", true, body)
			.then((json) => {
				dispatch(uploadMembersHelpSuccess(json));
				return true;
			})
			.catch((error) => {
				dispatch(uploadMembersHelpFailure(error));
				return false;
			});
	};
}

function uploadMembersHelpRequest() {
	return {
		type: UPLOAD_MEMBERS_HELP_REQUEST,
	};
}

function uploadMembersHelpSuccess(json) {
	return {
		type: UPLOAD_MEMBERS_HELP_SUCCESS,
		response: json,
	};
}

function uploadMembersHelpFailure(error) {
	return {
		type: UPLOAD_MEMBERS_HELP_FAILURE,
		message: error,
	};
}

export function countMembers() {
	return async (dispatch) => {
		return Api("registry/countmembers/true", "GET", true)
			.then((json) => {
				return json;
			})
			.catch((error) => {
				console.log("action error", error);
				return { error };
			});
	};
}

export function findMembers(startDate, endDate, appliedFromWebb) {
	const body = { startDate, endDate, appliedFromWebb };
	return async (dispatch) => {
		return Api("registry/find-members/", "POST", true, body)
			.then((json) => {
				return json;
			})
			.catch((error) => {
				console.log("action error", error);
				return { error };
			});
	};
}

export function memberCountDifference(firstDate, secondDate) {
	const body = { firstDate, secondDate };
	return async (dispatch) => {
		return Api("registry/member-count-difference/", "POST", true, body)
			.then((json) => {
				return json;
			})
			.catch((error) => {
				console.log("action error", error);
				return { error };
			});
	};
}
