import { CALL_API } from '../../utils/api';

export const TOGGLE_EXPANDED = 'artworksapp/dashboard/summary/TOGGLE_EXPANDED';
export const DATA_REQUEST = 'artworksapp/dashboard/summary/DATA_REQUEST';
export const DATA_SUCCESS = 'artworksapp/dashboard/summary/DATA_SUCCESS';
export const DATA_FAILURE = 'artworksapp/dashboard/summary/DATA_FAILURE';

export function toggleSummary() {
  return {
    type: TOGGLE_EXPANDED
  }
}

export function fetchSummaryData(artistId = false) {
  const endpoint = `stats/dash/summary/${artistId}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: true,
      types: [DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE]
    }
  }
}

export const GALLERY_STATS_COMPARE_REQUEST = 'artworksapp/dashboard/stats/GALLERY_STATS_COMPARE_REQUEST'
export const GALLERY_STATS_COMPARE_SUCCESS = 'artworksapp/dashboard/stats/GALLERY_STATS_COMPARE_SUCCESS'
export const GALLERY_STATS_COMPARE_FAILURE = 'artworksapp/dashboard/stats/GALLERY_STATS_COMPARE_FAILURE'

export function fetchGalleryStatsAndComparison(galleryId) {
  console.log('fetchGalleryStats galleryId', galleryId);
  // const endpoint = `stats/gallerystats/${galleryId}`;
  const endpoint = `stats/gallery/compare/${galleryId}`
  console.log('endpoint', endpoint);
  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      authenticated: true,
      types: [GALLERY_STATS_COMPARE_REQUEST, GALLERY_STATS_COMPARE_SUCCESS, GALLERY_STATS_COMPARE_FAILURE]
    }
  }
}
