import {
  ARTIST_TRANSACTIONS_REQUEST, ARTIST_TRANSACTIONS_SUCCESS, ARTIST_TRANSACTIONS_FAILURE,
  TRANSACTION_REQUEST, TRANSACTION_SUCCESS, TRANSACTION_FAILURE,
  REMOVE_TRANSACTION_REQUEST, REMOVE_TRANSACTION_SUCCESS, REMOVE_TRANSACTION_FAILURE,
} from '../actions/ArtworkTransactionActions'

export function artworkTransactionReducer(state = {
  isFetching: false,
  transactions: [],
  transaction: {},
}, action) {
  switch (action.type) {
    case REMOVE_TRANSACTION_REQUEST:
    case TRANSACTION_REQUEST:
    case ARTIST_TRANSACTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ARTIST_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        transactions: action.response
      });
    case TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        transaction: action.response
      });
    case REMOVE_TRANSACTION_SUCCESS:
      //Find item index and remove from list
      let transactionList = state.transactions.slice()
      const index = transactionList.findIndex(x => x._id === action.response)
      transactionList.splice(index, 1)

      return Object.assign({}, state, {
        isFetching: false,
        transactions: transactionList
      });
    case REMOVE_TRANSACTION_FAILURE:
    case TRANSACTION_FAILURE:
    case ARTIST_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state
  }
}
