import React from "react";

const RadioButtons = (props) => {
	const { input, options, disabled } = props;

	const setValue = (value) => {
		input.onChange(value);
		input.onBlur(value);
	};

	return (
		<div className="radio-buttons">
			{options.map((option, index) => {
				return (
					<div className="radio-buttons__button" key={option.value}>
						<input
							type="radio"
							id={index}
							value={index}
							checked={input.value === option.value}
							onClick={() => (!disabled ? setValue(option.value) : null)}
							className="radio-buttons__button__input"
						/>
						<label htmlFor={index} className="radio-buttons__button__label">
							{option.label}
						</label>
					</div>
				);
			})}
		</div>
	);
};

export default RadioButtons;
