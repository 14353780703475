import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import CountBadge from "./CountBadge";

const BURGER = require("../../../../assets/images/svg/Menu_v1_align.svg");

class BurgerButton extends Component {
	constructor(props) {
		super(props);
	}

	openMenu = () => {
		this.props.open();
	};

	closeMenu = () => {
		this.props.close();
	};

	render() {
		const { isOpen, notifications } = this.props;

		return (
			<div
				className={
					"BurgerButton" + (this.props.isResponsive ? " is-responsive" : "")
				}
				onClick={() => (isOpen ? this.closeMenu() : this.openMenu())}
			>
				<BURGER />
				{notifications > 0 && (
					<CountBadge type="for-burger-button-notify" count={notifications} />
				)}
			</div>
		);
	}
}

export default BurgerButton;
