import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { saveAs } from "file-saver";
import Spinner from "react-spinkit";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import { collectAndExport } from "../../actions/DataActions";

class DataPage extends Component {
	componentWillReceiveProps(nextProps) {
		if (!this.props.xlsFile && nextProps.xlsFile) {
			const type =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
			const blob = new Blob([this.s2ab(nextProps.xlsFile)], { type: type });
			saveAs(blob, "samladData.xlsx");
		}
	}

	s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	export = (type) => {
		this.props.dispatch(collectAndExport(type));
	};

	render() {
		const { fetchingData, t } = this.props;

		const isResponsive = appGlobals.useResponsive;
		const MainClass = isResponsive ? MainResp : Main;

		return (
			<div className="DataPage">
				{(__DEVELOPMENT__ || __STAGING__) && (
					<MainClass>
						<div className="DataPage__button-section">
							<div className="DataPage__button-section__button">
								<legend className="legend-nomargin">{t("admin.buyer")}</legend>
								<button
									className="purple-button--wide"
									onClick={() => this.export("buyer")}
									disabled={fetchingData}
								>
									{t("admin.export")}
								</button>
							</div>
							<div className="DataPage__button-section__button">
								<legend className="legend-nomargin">{t("admin.users")}</legend>
								<button
									className="purple-button--wide"
									onClick={() => this.export("users")}
									disabled={fetchingData}
								>
									{t("admin.export")}
								</button>
							</div>
							<div className="DataPage__button-section__button">
								<legend className="legend-nomargin">
									{t("admin.baseArtists")}
								</legend>
								<button
									className="purple-button--wide"
									onClick={() => this.export("baseArtists")}
									disabled={fetchingData}
								>
									{t("admin.export")}
								</button>
							</div>
							<div className="DataPage__button-section__button">
								<legend className="legend-nomargin">
									{t("admin.allArtists")}
								</legend>
								<button
									className="purple-button--wide"
									onClick={() => this.export("allArtists")}
									disabled={fetchingData}
								>
									{t("admin.export")}
								</button>
							</div>
						</div>
						{fetchingData && (
							<div className="DataPage__spinner">
								<Spinner spinnerName="cube-grid" noFadeIn />
							</div>
						)}
					</MainClass>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user },
		data: { isFetching: fetchingData, xlsFile },
	} = state;

	return { user, fetchingData, xlsFile };
}

const translated = withNamespaces("data")(DataPage);
export default connect(mapStateToProps)(translated);
