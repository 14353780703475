import { CALL_API } from '../../utils/api';

export const TOGGLE_EXPANDED = 'artworksapp/dashboard/pastShows/TOGGLE_EXPANDED';
export const SHOW_MORE = 'artworksapp/dashboard/pastShows/SHOW_MORE';
export const DATA_REQUEST = 'artworksapp/dashboard/pastShows/DATA_REQUEST';
export const DATA_SUCCESS = 'artworksapp/dashboard/pastShows/DATA_SUCCESS';
export const DATA_FAILURE = 'artworksapp/dashboard/pastShows/DATA_FAILURE';

export function togglePastShows() {
  return {
    type: TOGGLE_EXPANDED
  }
}

export function showMore() {
  return {
    type: SHOW_MORE
  }
}

export function fetchData() {
  const endpoint = 'stats/dash/pastshows';
  return {
    [CALL_API]: {
      endpoint,
      authenticated: true,
      types: [DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE]
    }
  }
}
