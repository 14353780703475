import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	fetchScrapedGalleries,
	rejectScrapedGallery,
} from "../../actions/ScrapedActions";
import { getPendingGalleries } from "../../selectors/scrapedSelectors";
import Main from "../../components/Main/Main";

const ScrapedGallery = ({ gallery, rejectGallery }) => {
	return (
		<div style={{ paddingBottom: "20px" }}>
			<h4>{"Gallery data"}</h4>
			<p>{`${gallery.artist ? gallery.artist : "No artist"} - ${
				gallery.title ? gallery.title : "No title"
			}`}</p>
			<p>{`${gallery.startDate} - ${gallery.endDate}`}</p>
			<p>
				<b>Gallery data</b>
			</p>
			{Object.keys(gallery.galleryData).map((key) => {
				const data = gallery.galleryData[key];
				return <p key={key}>{data}</p>;
			})}
			<Link className="button" to={"/scraped/galleries/" + gallery._id}>
				Add
			</Link>
			<button
				className="button"
				onClick={() => {
					rejectGallery(gallery);
				}}
			>
				Reject
			</button>
			<a
				className="button"
				href={`http://www.google.com/search?q=${gallery.artist}+${gallery.title}`}
				target="_blank"
				rel="noopener"
			>
				Search
			</a>
		</div>
	);
};

class ScrapedGalleries extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		this.props.dispatch(fetchScrapedGalleries());
	}

	rejectGallery = (gallery) => {
		this.props.dispatch(rejectScrapedGallery(gallery._id));
	};

	render() {
		let galleriesCount = Object.keys(this.props.scrapedGalleries).length;
		const { scrapedGalleries } = this.props;
		const admin = this.props.user.role === "admin";
		if (!admin) return null;
		return (
			<div className="Galleries">
				<Main>
					<div className="MainTop">
						<h2>Galleries ({galleriesCount})</h2>
						<Link to="/galleries/new" className="button--mod_create">
							Create new gallery
						</Link>
					</div>
					{Object.keys(scrapedGalleries).map((key) => {
						const gallery = scrapedGalleries[key];
						return (
							<ScrapedShow
								key={key}
								gallery={gallery}
								rejectGallery={this.rejectGallery}
							/>
						);
					})}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
	} = state;
	const scrapedGalleries = getPendingGalleries(state);
	return { scrapedGalleries, user, isAdmin };
}

export default connect(mapStateToProps)(ScrapedGalleries);
