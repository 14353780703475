import {
	INVOICES_REQUEST, INVOICES_SUCCESS, INVOICES_FAILURE,
	INVOICE_REQUEST, INVOICE_SUCCESS, INVOICE_FAILURE,
	CREATE_INVOICE_REQUEST, CREATE_INVOICE_SUCCESS, CREATE_INVOICES_SUCCESS, CREATE_INVOICE_FAILURE,
	INVOICE_PREVIEW_REQUEST, INVOICE_PREVIEW_SUCCESS, INVOICE_PREVIEW_FAILURE,
	FILE_REQUEST, FILE_SUCCESS, FILE_FAILURE,
	UPDATE_CUSTOMERS_REQUEST, UPDATE_CUSTOMERS_SUCCESS, UPDATE_CUSTOMERS_FAILURE,
} from '../actions/BillectaActions'

export function billectaReducer(state = {
  invoices: {},
	invoice: {},
	preview: '',
	file: '',
	results: null,
  isFetching: false,
  error: null,
	}, action) {
	switch (action.type) {
		case INVOICES_REQUEST: {
			return Object.assign({}, state, {
				invoices: {},
				isFetching: true,
				error: null,
			})
    }
		case INVOICES_SUCCESS: {
			const invoices = {}
			action.response.forEach(invoice => {
				invoices[invoice._id] = invoice
			})
			return Object.assign({}, state, {
        invoices,
				isFetching: false,
			})
    }
		case INVOICES_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.response,
			})
    }
		case INVOICE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
    }
		case INVOICE_SUCCESS: {
			return Object.assign({}, state, {
        invoice: action.response,
				isFetching: false,
			})
    }
		case INVOICE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.response,
			})
    }
		case CREATE_INVOICE_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				results: null,
				error: null,
			})
    }
		case CREATE_INVOICE_SUCCESS: {
			const invoices = Object.assign({}, state.invoices)
			invoices[action.response._id] = action.response
			return Object.assign({}, state, {
        invoices,
				isFetching: false,
			})
    }
		case CREATE_INVOICES_SUCCESS: {
			const invoicesCopy = Object.assign({}, state.invoices)
			const { invoices, results } = action.response
			invoices.forEach(invoice => {
				// Could return empty object after creating invoices for all art societies.
				if (invoice._id) {
					invoicesCopy[invoice._id] = invoice
				}
			})
			return Object.assign({}, state, {
        invoices: invoicesCopy,
				results,
				isFetching: false,
			})
    }
		case CREATE_INVOICE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.response,
			})
    }
		case INVOICE_PREVIEW_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				error: null,
			})
    }
		case INVOICE_PREVIEW_SUCCESS: {
			return Object.assign({}, state, {
        preview: action.response,
				isFetching: false,
			})
    }
		case INVOICE_PREVIEW_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.response,
			})
    }
		case FILE_REQUEST: {
			return Object.assign({}, state, {
				file: '',
				isFetching: true,
				error: null,
			})
    }
		case FILE_SUCCESS: {
			return Object.assign({}, state, {
        file: action.response,
				isFetching: false,
			})
    }
		case FILE_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.response,
			})
    }
		case UPDATE_CUSTOMERS_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true,
				results: null,
				error: null,
			})
    }
		case UPDATE_CUSTOMERS_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				results: action.response,
			})
    }
		case UPDATE_CUSTOMERS_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.response,
			})
    }
		default:
			return state
	}
}
