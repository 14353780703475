import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormValues,
	getFormInitialValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import {
	submitArtwork,
	loadArtwork,
	showSuccess,
	hideSuccess,
	removeArtwork,
	hideError,
} from "../../actions/FormActions/ArtworkFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import {
	submitAccess,
	hideAccessModal,
} from "../../actions/FormActions/AccessFormActions";
import {
	fetchArtwork,
	createCopyForArtist,
} from "../../actions/ArtworkActions";
import { saveArtist } from "../../actions/ArtistActions";
import { fetchGalleries } from "../../actions/GalleryActions";
import ArtworkForm from "./ArtworkForm";
import ArtworkFormResponsive from "./ArtworkFormResponsive";
import AccessForm from "../Access/AccessForm";
import ArtistForm from "../Artist/ArtistForm";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import Main from "../../components/Main/Main";
import MainResp from "../../components/Main/MainResp";
import Modal from "../../components/Modal/Modal";
import MetaButton from "../../components/MetaButton/MetaButton";
import InformMemberButton from "../../components/Buttons/InformMemberButton/InformMemberButton";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import ConfirmPurchaseModal from "../../components/Modal/Custom/ConfirmPurchaseModal";
import { hasAccess } from "../../utils/Helpers";

const ARROW = require("../../../assets/images/svg/arrow_right.svg");

class EditArtworkNew extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {
			confirmModalOpen: false,
		};
	}
	componentDidMount() {
		const { artworkId } = this.props.params;
		this.props.dispatch(fetchArtwork(artworkId));
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.artwork.deletedArtwork) {
			return this.props.router.push("/artworks");
		}
		if (this.props.artwork.artwork !== nextProps.artwork.artwork) {
			this.props.dispatch(loadArtwork(nextProps.artwork.artwork));
		}

		const { artwork: prevArtwork } = this.props.artwork;
		const { artwork: nextArtwork } = nextProps.artwork;
		if (
			prevArtwork._id &&
			nextArtwork._id &&
			prevArtwork.slug !== nextArtwork.slug
		) {
			this.props.router.push(`/artworks/${nextArtwork.slug}`);
		}
	}

	makeArtistCreator = (artworkId, creatorId) => {
		const { t } = this.props;
		const confirm = window.confirm(t("common:areYouSure"));
		if (confirm) {
			this.props.dispatch(createCopyForArtist(artworkId, creatorId));
		}
	};

	handleSubmit = () => {
		// save artwork
		const {
			form: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleRemove = () => {
		const { artwork } = this.props.form;
		this.props.dispatch(removeArtwork(artwork));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	handleAccessSubmit = (access) => {
		const artworkId =
			this.props.artwork.artwork._id || this.props.params.artworkId;
		this.props.dispatch(submitAccess(artworkId, access, "artworks"));
	};

	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};
	toggleAccessModal = () => {
		this.props.dispatch(hideAccessModal());
	};

	//Opens the modal used to confirm purchase details
	openConfirmModal = () => {
		this.setState({
			confirmModalOpen: true,
		});
	};
	//Closes confirm modal
	closeConfirmModal = () => {
		this.setState({
			confirmModalOpen: false,
		});
	};

	stepBack = () => {
		this.props.router.goBack();
	};

	render() {
		const {
			showArtistModal,
			isAdmin,
			isArtist,
			provider,
			showAccessModal,
			user,
			artwork: { artwork, isFetching },
			t,
			location: { query },
			infoUpdate = false,
		} = this.props;
		const { confirmModalOpen } = this.state;
		const isResponsive = appGlobals.useResponsive;
		//Moved variables so that they can be used in non-responsive page
		const artworkArtistId = artwork.artistMod ? artwork.artistMod._id : "";
		const creatorArtistId =
			artwork.created_by && artwork.created_by.artist
				? artwork.created_by.artist || artwork.created_by
				: "";
		const canCreateCopyForArtist =
			artworkArtistId && artworkArtistId !== creatorArtistId ? true : false;

		if (!hasAccess(user, artwork, "artwork")) return null;

		if ((isArtist || isAdmin) && isResponsive) {
			return (
				<MainResp
					contentClassName="slim-content"
					styling={{ overflow: "visible" }}
				>
					{/* overflow:visible to allow for sticky footer. */}
					<div className="step-back-container" style={{ marginBottom: "25px" }}>
						<div className="step-back-button" onClick={this.stepBack}>
							<ARROW />
							<span>{t("list.stepBack")}</span>
						</div>
					</div>
					<ArtworkFormResponsive
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isArtist={isArtist}
					/>
					{isAdmin && artwork.artistMod && canCreateCopyForArtist && (
						<button
							onClick={() =>
								this.makeArtistCreator(artwork._id, artwork.artistMod._id)
							}
							className="responsive-button"
							style={{ margin: "50px 0 0 0" }}
							type="button"
						>
							Copy for artist
						</button>
					)}
				</MainResp>
			);
		}

		// If coming here from ArtworksNew.js, use that list to update titel/artist and add new artist.
		// const infoUpdate = query.edit && query.edit === 'info'
		return (
			<div>
				<Main>
					<ArtworkForm
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isArtist={isArtist}
						provider={provider}
						infoUpdate={infoUpdate}
					/>
					{isAdmin && confirmModalOpen && (
						<ConfirmPurchaseModal
							close={this.closeConfirmModal}
							artworkId={artwork._id}
							manualOrder
						/>
					)}
					{isAdmin && (
						<InformMemberButton
							item={artwork}
							type={"artwork"}
							isFetching={isFetching}
							previewOnly={true}
						/>
					)}
					{isAdmin && (
						<div className="fieldset">
							<legend style={{ paddingTop: 40 }}>
								{t("invoice.manualConfirm")}
							</legend>
							<button
								type="button"
								className="purple-button--wide button-somemargin"
								onClick={() => this.openConfirmModal()}
							>
								{t("invoice.openForm")}
							</button>
						</div>
					)}
					{isAdmin && (
						<SelectionPicker
							type="artworks"
							item={this.props.artwork.artwork}
						/>
					)}
					<div
						className="fieldset"
						style={{ paddingTop: 40, justifyContent: "space-between" }}
					>
						<legend style={{ paddingTop: 40 }}>{t("common:dangerZone")}</legend>
						<button
							type="button"
							className="purple-button--wide--mod_danger"
							onClick={() => {
								let confirm = window.confirm(t("common:areYouSure"));
								if (confirm) this.handleRemove();
							}}
						>
							{t("single.deleteArtwork")}
						</button>
						<MetaButton item={this.props.artwork.artwork} />
					</div>
					{isAdmin && artwork.artistMod && canCreateCopyForArtist && (
						<button
							onClick={() =>
								this.makeArtistCreator(artwork._id, artwork.artistMod._id)
							}
							className="responsive-button"
							style={{ margin: "50px 0 0 40px" }}
							type="button"
						>
							Copy for artist
						</button>
					)}
				</Main>
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
						/>
					</Modal>
				) : null}
				{showAccessModal ? (
					<Modal onCloseClick={this.toggleAccessModal}>
						<AccessForm onSubmit={this.handleAccessSubmit} isAdmin={isAdmin} />
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artwork,
		artistForm: { showArtistModal },
		auth: { user, isAdmin },
		accessForm: { showAccessModal },
	} = state;
	const provider = artwork.artwork.provider || false;

	const isArtist = user.role === "artist";

	const form = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return {
		artwork,
		isAdmin,
		isArtist,
		showArtistModal,
		form,
		artistForm,
		provider,
		showAccessModal,
		user,
	};
}

const translated = withNamespaces("artworks")(EditArtworkNew);
export default connect(mapStateToProps)(withRouter(translated));
