import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitArtist,
	initArtist,
} from "../../actions/FormActions/ArtistFormActions";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import ArtistForm from "./ArtistForm";
import ArtistFormResp from "./ArtistFormResp";
import Main from "../../components/Main/Main";
import { withRouter } from "react-router";
import Modal from "../../components/Modal/Modal";

class CreateArtist extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
	}
	componentWillReceiveProps(newProps) {
		if (this.props.user !== newProps.user) {
			console.log("init artist");
			this.props.dispatch(initArtist());
		}
	}
	componentWillMount() {
		this.props.dispatch(initArtist());
	}

	handleSubmit = () => {
		const {
			form: { artist, initial },
			isAuthor,
			user,
		} = this.props;

		if (isAuthor && artist.representedByYou) {
			const galleryId = user.gallery._id ? user.gallery._id : user.gallery;
			const reppedArtist = Object.assign({}, artist, {
				represented_by: {
					location: "Sweden",
					gallery: galleryId,
				},
			});
			this.props.dispatch(submitArtist(reppedArtist, initial));
		} else {
			this.props.dispatch(submitArtist(artist, initial));
		}
	};

	render() {
		const {
			isAdmin,
			user,
			form,
			isAuthor,
			location: { query },
		} = this.props;
		const showVat =
			user.role && (user.role === "artist" || user.role === "admin");
		// If adding artist for artwork in ArtworksNew.js
		const forArtworkId = query.addforaw ? query.addforaw : false;
		const artistVideo =
			form.artist && form.artist.videoLink ? form.artist.videoLink : null;

		return (
			<div>
				<Main>
					{appGlobals.useResponsive ? (
						<ArtistFormResp
							onSubmit={this.handleSubmit}
							isAdmin={isAdmin}
							isAuthor={isAuthor}
							showVat={showVat}
							forArtworkId={forArtworkId}
							isPremium={isPremium(form.artist)}
							artistVideo={artistVideo}
							dispatch={this.props.dispatch}
						/>
					) : (
						<ArtistForm
							onSubmit={this.handleSubmit}
							isAdmin={isAdmin}
							isAuthor={isAuthor}
							showVat={showVat}
							forArtworkId={forArtworkId}
							isPremium={isPremium(form.artist)}
							artistVideo={artistVideo}
							dispatch={this.props.dispatch}
						/>
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, isAdmin },
	} = state;
	const isAuthor = user.role === "author";
	const form = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};
	return { user, isAdmin, form, isAuthor };
}

export default connect(mapStateToProps)(withRouter(CreateArtist));
