import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { withNamespaces } from "react-i18next";
import Modal from "../../components/Modal/Modal";
import GalleryMembershipModal from "../../components/Modal/Custom/GalleryMembershipModal";
import { toggleSocietyPackageSelector } from "../../actions/ModalActions";
import { contactArtworksNotLoggedIn } from "@artworkslab/sharedmodules/src/actions/MessageActions";
import { logoutUser } from "@artworkslab/sharedmodules/src/actions/AuthActions";

const FOOTER_LOGO = require("../../../assets/images/footer-logo.png");
const FACEBOOK = require("../../../assets/images/icons/facebook.png");
const TWITTER = require("../../../assets/images/icons/twitter.png");
const INSTAGRAM = require("../../../assets/images/icons/instagram.png");

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEmailModal: false,
			name: "",
			sender: "",
			message: "",
			emailValid: false,
		};
	}

	//Toggle contact-us modal
	toggleEmailModal = () => {
		this.setState({
			showEmailModal: !this.state.showEmailModal,
		});
	};

	//Validates email address
	validateEmail = (value) => {
		const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return value && re.test(value);
	};

	//Update given value in state
	updateData = (key, value) => {
		let validEmail = false;
		if (key === "sender") {
			validEmail = this.validateEmail(value);
			console.log("validEmail", validEmail);
			this.setState({
				[key]: value,
				emailValid: validEmail,
			});
		}
		this.setState({
			[key]: value,
		});
	};

	//Resets local data
	resetData = () => {
		this.setState({
			receivers: "",
			emailValid: false,
		});
	};

	//Send contact-us email
	sendEmail = () => {
		const { name, sender, message } = this.state;
		this.props.dispatch(contactArtworksNotLoggedIn(name, sender, message));
		this.toggleEmailModal();
	};

	//Logs out user
	logout = () => {
		const { t } = this.props;
		const confirm = window.confirm(t("common:account.logoutConfirm"));
		if (confirm) {
			this.props.dispatch(logoutUser());
			this.props.router.push("/login");
		}
	};

	closeSocietyPackageModal = () => {
		this.props.dispatch(toggleSocietyPackageSelector(false));
	};

	render() {
		const {
			className = "",
			isAuthenticated,
			isAdmin,
			isAuthor,
			isArtist,
			isSociety,
			user,
			gallery,
			language,
			changeLanguage,
			t,
		} = this.props;
		const { showEmailModal, name, sender, message, emailValid } = this.state;

		const webbLink = (
			<div className="footer__website-link">
				<a href="https://artworks.se/" target="_blank">
					{t("website")}
				</a>
			</div>
		);

		const cantSend =
			name.length <= 0 ||
			sender.length <= 0 ||
			!emailValid ||
			message.length <= 0;

		const isSwedish = language === "sv";

		let userType = { se: "konstnären", en: "artist" };
		if (isAuthor) userType = { se: "galleriet", en: "gallery" };
		if (isSociety) userType = { se: "konstföreningen", en: "art society" };

		let information = isSwedish
			? [
					<p key="1">
						<b>Artworks</b> - {`teknik som stärker ${userType.se}.`}
						<br />
						<br />
						<a href="mailto:js@artworks.se">js@artworks.se</a>{ " - 070 332 68 87"}
					</p>,
			  ]
			: [
					<p key="1">
						<b>Artworks</b> -{" "}
						{`technology that empowers the contemporary ${userType.en}.`}
						<br />
						<br />
					</p>,
			  ];

		const faq = isArtist
			? "https://artworks.se/pro#faq"
			: "https://artworks.se/faq";
		const getStarted = "https://artworks.se/get-started-pro";

		return (
			<div className="footer">
				<div className="footer__wrapper">
					<div className="footer__content">
						<div className="footer__column is-double">{information}</div>
						<div className="footer__column is-double is-links">
							<button
								className="footer__footer-link"
								onClick={() => this.toggleEmailModal()}
							>
								{t("contactUs")}
							</button>
							<a className="footer__footer-link" href={faq} target="_blank">
								{t("questions")}
							</a>
							{isArtist && (
								<a
									className="footer__footer-link"
									href={getStarted}
									target="_blank"
								>
									{t("getStarted")}
								</a>
							)}
							{language === "sv" ? (
								<button
									className="footer__footer-link"
									onClick={() => changeLanguage("en")}
								>
									In English
								</button>
							) : (
								<button
									className="footer__footer-link"
									onClick={() => changeLanguage("sv")}
								>
									På svenska
								</button>
							)}
							{isAuthenticated && (
								<button className="footer__footer-link" onClick={this.logout}>
									{t("common:logOut")}
								</button>
							)}
						</div>
					</div>
				</div>
				{showEmailModal ? (
					<Modal type="small" onCloseClick={this.toggleEmailModal}>
						<input
							className="footer__input-field"
							onChange={(e) => this.updateData("name", e.target.value)}
							value={name}
							placeholder={isSwedish ? "Ditt namn" : "Your name"}
						/>
						<input
							className="footer__input-field"
							onChange={(e) => this.updateData("sender", e.target.value)}
							value={sender}
							placeholder={
								isSwedish ? "Din epost adress" : "Your email address"
							}
						/>
						<textarea
							className="footer__input-field"
							onChange={(e) => this.updateData("message", e.target.value)}
							value={message}
							placeholder={isSwedish ? "Ditt meddelande" : "Your message"}
							rows={10}
						/>
						<button
							className="purple-button--wide button-nomargin"
							onClick={this.sendEmail}
							disabled={cantSend}
						>
							{t("smooch.send")}
						</button>
					</Modal>
				) : null}
				{/* Update modal for art society membership. */}
				{this.props.societyPackageModalOpen && (
					<GalleryMembershipModal
						header={this.props.societyPackageHeader}
						footer={this.props.societyPackageFooter}
						gallery={gallery}
						user={user}
						close={() => this.closeSocietyPackageModal()}
						canTerminate={false}
						forceChoice={this.props.societyPackageForceChoice}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		societyPackageModalOpen,
		societyPackageForceChoice,
		societyPackageHeader,
		societyPackageFooter,
	} = state.modal;

	return {
		societyPackageModalOpen,
		societyPackageForceChoice,
		societyPackageHeader,
		societyPackageFooter,
	};
};

const translated = withNamespaces("common")(Footer);
export default connect(mapStateToProps)(withRouter(translated));
