import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Main from "../../components/Main/Main";
import PostForm from "./PostForm";
import { fetchPost } from "../../actions/PostActions";
import { withNamespaces } from "react-i18next";
import {
	getFormInitialValues,
	getFormValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import {
	loadPost,
	submitPost,
	deletePost,
} from "../../actions/FormActions/PostFormActions";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import InformMemberButton from "../../components/Buttons/InformMemberButton/InformMemberButton";
import { hasAccess } from "../../utils/Helpers";
import { isVenueArtSociety } from "@artworkslab/sharedmodules/src/utils/Helpers";
import {
	isPremium,
	isStandardOrAbove,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class EditPost extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		const postId = this.props.params.postId;
		this.props.dispatch(fetchPost(postId));
	}
	componentWillReceiveProps(newProps) {
		if (newProps.post.deletedPost) {
			return this.props.router.push("/posts");
		}
		if (this.props.post.post !== newProps.post.post && newProps.post.post._id) {
			this.props.dispatch(loadPost(newProps.post.post));
		}
	}
	handleSubmit = () => {
		const {
			form: { post, initial },
		} = this.props;
		this.props.dispatch(submitPost(post, initial));
	};
	handleRemove = () => {
		const post = this.props.form.post;
		this.props.dispatch(deletePost(post));
	};

	render() {
		const {
			post: { post, isFetching },
			isAdmin,
			isSociety,
			user,
			gallery,
			form,
			t,
		} = this.props;
		const isArtSociety = isVenueArtSociety(post.belongsTo);
		const createdByAdmin = post.created_by && post.created_by.role === "admin";
		// Only admin should be able to edit posts created by an admin.
		const blockEditing = createdByAdmin && !isAdmin;

		const postVideo =
			form.post && form.post.videoLink ? form.post.videoLink : null;

		if (!hasAccess(user, post)) return null;

		return (
			<Main>
				<div
					style={blockEditing ? { pointerEvents: "none", opacity: 0.7 } : {}}
				>
					<PostForm
						onSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isSociety={isSociety}
						imageGallery={post.isImageGallery}
						blockEditing={blockEditing}
						isPremium={
							__USE_TIERS__ ? isStandardOrAbove(gallery) : isPremium(gallery)
						}
						postVideo={postVideo}
					/>
				</div>
				{isArtSociety && (
					<InformMemberButton
						item={post}
						type={"post"}
						isFetching={isFetching}
					/>
				)}
				{isAdmin && <SelectionPicker type="articles" item={post} />}
				{(isAdmin || (isSociety && !blockEditing)) && (
					<div className="fieldset">
						<legend style={{ paddingTop: 40 }}>{t("common:dangerZone")}</legend>
						<button
							type="button"
							className="purple-button--wide--mod_danger"
							onClick={() => {
								let confirm = window.confirm(t("common:areYouSure"));
								if (confirm) this.handleRemove();
							}}
						>
							{t("single.deletePost")}
						</button>
					</div>
				)}
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		post,
		auth: { isAdmin, user },
		gallery: { gallery },
	} = state;
	const isSociety = user.role === "society";
	const form = {
		submitting: isSubmitting("post")(state),
		submitSucceeded: hasSubmitSucceeded("post")(state),
		submitFailed: hasSubmitFailed("post")(state),
		post: getFormValues("post")(state),
		initial: getFormInitialValues("post")(state),
	};
	return { post, isAdmin, form, isSociety, user, gallery };
}

// Need withNamespaces here or children (InformMemberButton) won't update on language change.
const translated = withNamespaces("post")(EditPost);
export default connect(mapStateToProps)(withRouter(translated));
