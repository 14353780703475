import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
	initialize,
	submit,
	startSubmit,
	getFormValues,
	getFormInitialValues,
	hasSubmitSucceeded,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import Main from "../../components/Main/Main";
import DiscountCodeForm from "./DiscountCodeForm";
import { submitDiscountCodeForm } from "../../actions/FormActions/DiscountCodeFormActions";
import {
	fetchDiscountCode,
	deleteDiscountCode,
} from "../../actions/DiscountCodeActions";

class CreateDiscountCodes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		const { id } = this.props.params;
		if (id !== "new") {
			this.props.dispatch(fetchDiscountCode(id));
		} else {
			this.props.dispatch(initialize("discountCode", {}));
		}
	}

	componentWillReceiveProps(nextProps) {
		const { id } = nextProps.params;
		const singleCodeFetched = !this.props.singleCode && nextProps.singleCode;
		const finishedSubmitting =
			this.props.form.submitting && !nextProps.form.submitting;

		if (id !== "new" && (singleCodeFetched || finishedSubmitting)) {
			// Initialize form with single discountCode.
			// After updating single discountCode, initialize form to set pristine to true.
			this.props.dispatch(initialize("discountCode", nextProps.singleCode));
		} else if (id === "new" && finishedSubmitting) {
			this.props.router.push("/discounts");
		} else if (
			this.props.deletingCode &&
			!nextProps.deletingCode &&
			!nextProps.singleCode
		) {
			this.props.router.push("/discounts");
		}
	}

	handleSubmit = () => {
		const {
			form: { discountCode, initial },
		} = this.props;

		this.props.dispatch(submitDiscountCodeForm(discountCode, initial));
	};

	deleteDiscount = (id) => {
		this.props.dispatch(deleteDiscountCode(id));
	};

	render() {
		const {
			isAdmin,
			params: { id },
		} = this.props;
		const isNew = id === "new";

		if (!isAdmin) return null;

		return (
			<div>
				<Main>
					<DiscountCodeForm onSubmit={this.handleSubmit} isNew={isNew} />
					{!isNew && (
						<div className="fieldset">
							<legend style={{ paddingTop: 40 }}>Danger</legend>
							<button
								type="button"
								className="purple-button--wide--mod_danger"
								onClick={() => {
									let confirm = window.confirm("Are you sure?");
									if (confirm) this.deleteDiscount(id);
								}}
							>
								Delete
							</button>
						</div>
					)}
				</Main>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		discountCode: { singleCode, deletingCode },
		auth: { user },
	} = state;
	const isAdmin = user.role === "admin";

	const form = {
		submitting: isSubmitting("discountCode")(state),
		submitSucceeded: hasSubmitSucceeded("discountCode")(state),
		submitFailed: hasSubmitFailed("discountCode")(state),
		discountCode: getFormValues("discountCode")(state),
		initial: getFormInitialValues("discountCode")(state),
	};

	return { singleCode, deletingCode, form, isAdmin };
};

export default connect(mapStateToProps)(withRouter(CreateDiscountCodes));
