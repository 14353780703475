import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import TextInput from "../../../components/FormInput/TextInput";
import TextDisplay from "../../../components/FormInput/TextDisplay";
import LocationPicker from "../../../components/FormComponents/LocationPicker/LocationPicker";
import AdditionalSettings from "../../Account/AdditionalSettings";
import {
	validateVAT,
	validateOrgNo,
	validateEmail,
} from "../../../utils/Validators";

class GallerySettingsForm extends Component {
	normalizeDriveLink = (value, previousValue) => {
		if (value.indexOf("docs.google.com/document/") > -1) {
			// Change URL so not viewed in edit mode.
			return value.replace("/document/", "/file/");
		}
		return value;
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			isAdmin,
			gallery,
			invoiceLink,
			isFetching,
			t,
		} = this.props;

		return (
			<form onSubmit={handleSubmit} className="GalleryForm__form Form">
				<div className="input-holder">
					<legend className="legend-nomargin">
						{t("single.invoiceDetails")}
					</legend>
					<Field
						name="invoiceEmail"
						component={TextInput}
						placeholder={t("single.invoiceEmail")}
						validate={validateEmail}
					/>
				</div>

				{/*Invoice details*/}
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="invoiceAddress.street"
							component={TextInput}
							placeholder={t("single.invoiceAddress")}
							required={false}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="invoiceAddress.city"
							component={TextInput}
							placeholder={t("common:city")}
							required={false}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="invoiceAddress.zip"
							component={TextInput}
							placeholder={t("common:zip")}
							required={false}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="invoiceAddress.country"
							component={TextInput}
							placeholder={t("common:country")}
							required={false}
						/>
					</div>
				</div>

				{/*Banking details*/}
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="VATnumber"
							component={TextInput}
							placeholder={t("single.VATnumber")}
							validate={validateVAT}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="orgNumber"
							component={TextInput}
							placeholder={t("single.organisationNumber")}
							validate={validateOrgNo}
						/>
					</div>
				</div>

				{/*Gallery location*/}
				<div className="fieldset">
					<legend>{t("single.location")}</legend>
					<div className="input-holder">
						<Field
							name="address.street"
							component={TextInput}
							placeholder={t("common:street")}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="address.city"
							component={TextInput}
							placeholder={t("common:city")}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="address.zip"
							component={TextInput}
							placeholder={t("common:zip")}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="address.country"
							component={TextInput}
							placeholder={t("common:country")}
						/>
					</div>
				</div>

				{/*Gallery Geo coordinates*/}
				<div className="fieldset">
					<legend>{t("single.geoCoordinates")}</legend>
					<Fields
						names={[
							"locationName",
							"locationSearch",
							"location.type",
							"location.coordinates",
						]}
						component={LocationPicker}
						isGallery={true}
					/>
				</div>

				{/*Add/remove gallery admins*/}
				{gallery.venueType && gallery.venueType === "ArtSociety" && (
					<div className="fieldset-block" style={{ marginTop: "30px" }}>
						<Fields
							names={[
								"applicationEmailAddress",
								"applicationEmailAddressCopy",
								"layout",
							]}
							component={AdditionalSettings}
							gallery={gallery}
						/>
					</div>
				)}

				<div className="fieldset-block">
					{/* View-online link now in NavBar, except for admin. */}
					{isAdmin && (
						<div className="input-holder--full" style={{ marginTop: "50px" }}>
							<legend>Admin</legend>
							<Field
								name="slug"
								itemType="gallery"
								component={TextDisplay}
								isAdmin={isAdmin}
							/>
						</div>
					)}
					{isAdmin && (
						<div className="input-holder--full">
							<Field
								name="billing.invoiceLink"
								component={TextInput}
								placeholder="Link to invoice"
								normalize={this.normalizeDriveLink}
								style={{ width: "80%", display: "flex", alignItems: "center" }}
								children={
									invoiceLink ? (
										<a
											href={invoiceLink}
											target="_blank"
											className="purple-button--wide"
											style={{ marginLeft: "20px" }}
										>
											Preview
										</a>
									) : null
								}
							/>
						</div>
					)}
				</div>

				{/*Save button*/}
				<div className="fieldset">
					<div className="fixed-save-button">
						<button
							className="purple-button--wide"
							type="submit"
							disabled={pristine || submitting}
						>
							{t("common:save")}
						</button>
					</div>
				</div>
			</form>
		);
	}
}

GallerySettingsForm = reduxForm({
	form: "gallery",
})(GallerySettingsForm);

const selector = formValueSelector("gallery");
const mapStateToProps = (state) => {
	const invoiceLink = selector(state, "billing.invoiceLink");

	return { invoiceLink };
};

const translated = withNamespaces("gallery")(GallerySettingsForm);
export default connect(mapStateToProps)(translated);
