import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
// import DeliveryAddress      from '../../components/OrderComponents/DeliveryAddress'
import OrderItems from "../../components/OrderComponents/OrderItems";
import { updateInvoiceOrder } from "../../actions/OrderActions";

const OrderItem = ({ item, quantity }) => {
	if (!item) {
		return (
			<div className="order-items">
				<p>{"Artwork could not be found."}</p>
			</div>
		);
	}
	return (
		<div className="order-items">
			<p>
				<Link to={`/artworks/${item.slug}`}>{`${item.title} by ${
					item.artistMod ? item.artistMod.name : "Unknown"
				}`}</Link>
			</p>
			<p>{`Qty: ${quantity}`}</p>
			<p>{`Price: ${item.price} ${item.priceCurrency}`}</p>
		</div>
	);
};

class InvoiceOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	updateOrder = (orderId, update) => {
		updateInvoiceOrder(orderId, update);
	};

	render() {
		const { order, activeUserId, key } = this.props;

		let attachments = <p>none</p>;
		if (order.attachmentNames && order.attachmentNames.length > 0) {
			attachments = order.attachmentNames.map((name, index) => (
				<p key={index}>{name}</p>
			));
		}

		return (
			<div className="order" key={key}>
				<h4>ORDER</h4>
				<div className="order__content">
					<div>
						<div className="order__info">
							{order.user ? (
								<p>
									Logged in user:{" "}
									<Link
										to={`/accounts/${order.user._id}`}
									>{`User: ${order.user.name} ${order.user.email}`}</Link>
								</p>
							) : (
								<p>Not logged in</p>
							)}
							<p>Name on form: {order.userName}</p>
							<p>Email on form: {order.userEmail}</p>
							<p>Attachments:</p>
							{attachments}
							<p>Discount code: {order.appliedDiscount || "none"}</p>
							{order.convertedDate && (
								<p>{`Converted: ${moment(order.convertedDate).format(
									"MMM Do YYYY, HH:mm"
								)}`}</p>
							)}
							{order.deliveredDate && (
								<p>{`Delivered: ${moment(order.deliveredDate).format(
									"MMM Do YYYY, HH:mm"
								)}`}</p>
							)}
							{order.deliveredBy && (
								<p>{`Delivered by: ${order.deliveredBy.name}`}</p>
							)}
							<p>{`Order id: ${order._id}`}</p>
							{/*<p>{`Updated: ${moment(order.updatedAt).format('MMM Do YYYY, HH:mm')}`}</p>
              <p>{`Created: ${moment(order.created_at).format('MMM Do YYYY, HH:mm')}`}</p>*/}
						</div>
						{/* order.address && <DeliveryAddress {...order.address} /> */}
					</div>
					<div className="order-items">
						{order.items.map((item, index) => {
							return (
								<OrderItem
									item={item.item}
									quantity={item.quantity}
									key={index}
								/>
							);
						})}
					</div>
				</div>
				<div>
					{order.status === "converted" && (
						<button
							className="button--large-button button-nomargin"
							onClick={() => this.props.setDelivered(order, activeUserId)}
						>
							Set as delivered
						</button>
					)}
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		setDelivered: (order, activeUserId) => {
			const confirm = window.confirm(
				"Are you sure you want to set order to delivered?"
			);
			if (confirm) {
				dispatch(
					updateInvoiceOrder(order._id, {
						status: "delivered",
						deliveredDate: new Date(),
						deliveredBy: activeUserId,
					})
				);
			}
		},
	};
}

export default connect(null, mapDispatchToProps)(InvoiceOrder);
