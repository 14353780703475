import React, {Component, PropTypes} 	from 'react'
import { Fields }                     from 'redux-form';
import Select                         from '../../FormInput/Select'
import CheckBox                       from '../../FormInput/CheckBox'
import TextInput                      from '../../FormInput/TextInput'

const PRICE_CURRENCIES = [
	{value:"SEK", name: "SEK"},
	{value:"USD", name: "USD"},
	{value:"EUR", name: "EUR"},
	{value:"NOK", name: "NOK"},
	{value:"ISK", name: "ISK"},
	{value:"DKK", name: "DKK"}
]

let PriceRange = (props) => {

	// TODO: Show errors.!

	const manual = props.manual.input.value ? true : false

	return (
		<div className="fieldset">
			<div className="input-holder">
				<CheckBox
					{ ...props.manual }
					checkboxLabel="Manual pricerange"
				/>
			</div>
			<div className="input-holder">
				<Select
					{ ...props.currency}
					options={ PRICE_CURRENCIES }
					disabled={ !manual }
				/>
			</div>
			<div className="input-holder">
				<TextInput
					{ ...props.min }
					placeholder="Min"
					disabled={ !manual }
				/>
			</div>
			<div className="input-holder">
				<TextInput
					{ ...props.max }
					placeholder="Max"
					disabled={ !manual }
					/>
			</div>
		</div>
	)
}


let PriceRangePicker = (props) => {
  return(
		<Fields
			names={[
				"manual",
				"currency",
				"max",
				"min"
			]}
			component={PriceRange}
		/>
  )
}

export default PriceRangePicker
