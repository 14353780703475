import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	RadialBarChart,
	RadialBar,
	Legend,
	PieChart,
	Pie,
	ResponsiveContainer,
	Text,
} from "recharts";

class CustomizedAxisTick extends Component {
	render() {
		const { x, y, stroke, payload } = this.props;
		let date = new Date(payload.value);
		let label = date.getDate() + " / " + date.getMonth();
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy={16}
					textAnchor="end"
					fill="#666"
					transform="rotate(-45)"
				>
					{label}
				</text>
			</g>
		);
	}
}

class CustomTooltip extends Component {
	static propTypes = {
		type: PropTypes.string,
		payload: PropTypes.array,
		label: PropTypes.string,
	};

	render() {
		const { active } = this.props;

		if (active) {
			const { payload, label } = this.props;
			let date = new Date(label);
			let datalabel = date.getDate() + " / " + date.getMonth();
			return (
				<div className="custom-tooltip">
					<p className="label">{datalabel}</p>
				</div>
			);
		}
		return null;
	}
}

class ShowStats extends Component {
	/**
	 * defaultProps
	 */
	static defaultProps = {
		isAdmin: false,
	};

	static propTypes = {
		isAdmin: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			metrics: {},
			isAdmin: false,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.metrics) {
			this.setState({
				metrics: newProps.metrics,
			});
		}
	}

	renderAdmin = () => {
		return <h1>Admin lacks dashboard...</h1>;
	};

	renderShowRating = (show, title = "") => {
		let radialdata = [{ fill: "#8884d8", name: "", rating: show.rating.score }];
		let startAngle = 230;
		let endAngle = -50;
		let maxAngle = (Math.abs(endAngle - startAngle) * radialdata[0].rating) / 5;
		return (
			<div className="show_rating__chart" key={"rating_" + title}>
				<RadialBarChart
					width={200}
					height={250}
					cx={100}
					cy={100}
					innerRadius={70}
					outerRadius={90}
					data={radialdata}
				>
					<RadialBar
						minAngle={15}
						maxAngle={maxAngle}
						startAngle={startAngle}
						endAngle={endAngle}
						background
						dataKey="rating"
					/>
				</RadialBarChart>
				<span>{Math.floor(radialdata[0].rating * 10) / 10}/5</span>
				<p>{title}</p>
			</div>
		);
	};
	renderShowVisits = (metrics) => {
		let visitData = [];
		let lines = [];
		let datakeys = [];
		for (var i = 0; i < metrics.length; i++) {
			let metric = metrics[i];
			metric.visits.map((visitPeriod) => {
				let milliTime = new Date(visitPeriod.time);
				let dayTime = new Date(
					milliTime.getFullYear(),
					milliTime.getMonth(),
					milliTime.getDate()
				).toISOString();
				let data = { time: dayTime };
				data[metric.name] = visitPeriod.total;
				if (!visitData[dayTime]) {
					visitData[dayTime] = { time: dayTime };
					visitData[dayTime][metric.name] = visitPeriod.total;
				} else {
					visitData[dayTime][metric.name] = visitPeriod.total;
				}
			});
			lines.push(
				<Line type="monotone" dataKey={metric.name} stroke="#8884d8" key={i} />
			);
		}
		let data = [];
		for (var key in visitData) {
			if (visitData.hasOwnProperty(key)) {
				data.push(visitData[key]);
			}
		}
		return (
			<div className="show_visits__chart" key={"visits"}>
				<LineChart
					width={800}
					height={500}
					data={data}
					margin={{ top: 30, right: 30, left: 30, bottom: 30 }}
				>
					<XAxis dataKey="time" tick={<CustomizedAxisTick />} />
					<YAxis type="number" domain={[0, "dataMax"]} />
					<Tooltip content={<CustomTooltip />} />
					<Legend verticalAlign="top" height={36} />
					{lines}
				</LineChart>
			</div>
		);
	};

	renderGallerist = (metrics) => {
		let showRatings = [];
		let showVisits = [];

		for (var i = 0; i < metrics.length; i++) {
			let metric = metrics[i];
			showRatings.push(
				this.renderShowRating(
					metric.rating[metric.rating.length - 1],
					metric.title
				)
			);
		}
		showVisits.push(this.renderShowVisits(metrics));

		return (
			<div className="ShowStats">
				<div className="show_rating">
					<h3>Current show ratings in app</h3>
					{showRatings}
				</div>
				<div className="show_visits">
					<h3>Current show visits in app</h3>
					{showVisits}
				</div>
			</div>
		);
	};
	render() {
		const { metrics } = this.props;
		if (this.props.isAdmin) {
			return this.renderAdmin();
		} else {
			return this.renderGallerist(metrics);
		}
	}
}

export default ShowStats;
