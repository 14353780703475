import { CALL_API } from '../../utils/api';

export const TOGGLE_EXPANDED = 'artworksapp/dashboard/latestSales/TOGGLE_EXPANDED';

export function toggleLatestSales() {
  return {
    type: TOGGLE_EXPANDED
  }
}

export const SALES_REQUEST = 'artworksapp/dashboard/latestSales/SALES_REQUEST';
export const SALES_SUCCESS = 'artworksapp/dashboard/latestSales/SALES_SUCCESS';
export const SALES_FAILURE = 'artworksapp/dashboard/latestSales/SALES_FAILURE';

export function fetchSales() {
  const endpoint = 'stats/dash/latestSales';
  return {
    [CALL_API]: {
      endpoint,
      authenticated: true,
      types: [SALES_REQUEST, SALES_SUCCESS, SALES_FAILURE]
    }
  }
}
