import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import i18next from "i18next";
import { createWebbLink } from "../../../utils/Helpers";
import { isPremium } from "@artworkslab/sharedmodules/src/utils/BillingUtil";

const FIVE_MIB = 5242880;
const ACCEPT = "image/jpeg,image/jpg,image/png";

const HEADER_TEXT = {
	author: [
		<p className="muted" key={"1"}>
			{
				"Artworks gives you data to keep your eyes on! Take a few minutes every day to conquer Artworks. Numbers don’t lie about how your exhibitions are performing! Metrics are updated daily at 11.30pm."
			}
		</p>,
		<p className="muted" key={"2"}>
			{"Use the chat below if you have any questions at all."}
		</p>,
	],
	artist: [
		<p className="muted" key={"1"}>
			{
				"Artworks gives you data to keep your eyes on! Take a few minutes every day to conquer Artworks. Numbers don’t lie, and measuring your progress will help you stay on track to reach your goals."
			}
		</p>,
		<p className="muted" key={"2"}>
			{"Metrics are updated daily at 11.30pm."}
		</p>,
	],
};

class Header extends Component {
	constructor(props) {
		super(props);
	}

	// When returning to dashboard from other tab
	componentDidMount() {
		const { role, artist, t } = this.props;
		if (artist._id && role === "artist") {
			const firstName =
				artist.name.indexOf(" ") > 0
					? artist.name.slice(0, artist.name.indexOf(" "))
					: artist.name;
			const target = document.getElementById("welcome-message");
			target.innerHTML = `${t("typewriter.hello")} ${firstName}, <br /> ${t(
				"typewriter.welcome"
			)}`;
		}
	}

	// Used for initial login on dashboard
	componentWillReceiveProps(nextProps) {
		const { t } = this.props;
		if (
			this.props.artist._id !== nextProps.artist._id &&
			nextProps.artist._id &&
			nextProps.role === "artist"
		) {
			const firstName =
				nextProps.artist.name.indexOf(" ") > 0
					? nextProps.artist.name.slice(0, nextProps.artist.name.indexOf(" "))
					: nextProps.artist.name;
			const message = this.getRandomMessage(firstName, t); //Strings to be typed
			let iIndex = 0; //Starting row
			let arrLength = message[0].length; //Starting length
			let textPos = 0; //Starting letter
			this.typeWriter(message, iIndex, arrLength, textPos);
		}
	}

	//Returns a random list of strings
	getRandomMessage = (firstName, t) => {
		let message = [];
		const randint = Math.floor(Math.random() * Math.floor(11));
		switch (randint) {
			case 0:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.welcome"));
				break;
			case 1:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.detail"));
				break;
			case 2:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.show"));
				break;
			case 3:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.weather"));
				break;
			case 4:
				message.push(`${t("typewriter.hello2")} ${firstName}!`);
				message.push(t("typewriter.contacts"));
				break;
			case 5:
				message.push(`${t("typewriter.hello3")} ${firstName},`);
				message.push(t("typewriter.quote"));
				break;
			case 6:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.fyi"));
				message.push(t("typewriter.artworks"));
				break;
			case 7:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.video"));
				break;
			case 8:
				message.push(`${t("typewriter.hello4")} ${firstName}!`);
				message.push(t("typewriter.invite"));
				break;
			case 9:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.easter"));
				break;
			case 10:
				message.push(`${t("typewriter.hello")} ${firstName},`);
				message.push(t("typewriter.scotland"));
				break;
			default:
		}
		return message;
	};

	//Renders the users profile image
	renderProfileImg = () => {
		const { artist } = this.props;
		const src = artist.featuredImage ? artist.featuredImage.src : null;
		if (src) {
			return (
				<div
					className="dashboardHeader__content__profileImage"
					style={{ backgroundImage: `url(${src})` }}
				></div>
			);
		} else {
			return null;
		}
	};

	//Types out a list of strings to target div
	typeWriter = (message, iIndex, arrLength, textPos) => {
		const iSpeed = 100;
		const scrollAt = 20;
		let mContents = "";

		let row = Math.max(0, iIndex - scrollAt);
		const target = document.getElementById("welcome-message");
		if (!target) {
			return;
		}

		//Add break inbetween rows
		while (row < iIndex) {
			mContents += message[row++] + "<br />";
		}
		//Update target value
		target.innerHTML = mContents + message[iIndex].substring(0, textPos) + "|";
		if (textPos++ === arrLength) {
			textPos = 0;
			iIndex++;
			if (iIndex !== message.length) {
				arrLength = message[iIndex].length;
				//Start next string
				setTimeout(
					() => this.typeWriter(message, iIndex, arrLength, textPos),
					500
				);
			} else {
				target.innerHTML = target.innerHTML.slice(0, -1);
			}
		} else {
			//Start next letter
			setTimeout(
				() => this.typeWriter(message, iIndex, arrLength, textPos),
				iSpeed
			);
		}
	};

	render() {
		const { role, artist, fetchingArtist, t } = this.props;

		const isArtist = role === "artist" ? true : false;
		let webbLink = "";
		if (artist && artist.slug) {
			webbLink = createWebbLink(artist.slug, "artists");
		}

		return (
			<div className="dashboardHeader">
				<div className="dashboardHeader__title">
					{/* isArtist &&
            <div className={ b('title')('url') }>
              { `${t('artist.url')} ${webbLink}` }
            </div>
          */}
				</div>
				{isArtist && !isPremium(artist) && !fetchingArtist && (
					<div className="dashboardHeader__sample">
						{t("artist.samplePage")}
					</div>
				)}
				{!isArtist && !fetchingArtist && (
					<div className="dashboardHeader__content">{HEADER_TEXT[role]}</div>
				)}
				{isArtist && (
					<div className="dashboardHeader__content">
						{this.renderProfileImg()}
						<div
							className={
								"dashboardHeader__content__text" +
								(artist.featuredImage ? " is-image" : "")
							}
						>
							<h2 id="welcome-message"></h2>
						</div>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artist: { artist, isFetching: fetchingArtist },
	} = state;
	return { artist, fetchingArtist };
}

export default connect(mapStateToProps)(Header);
