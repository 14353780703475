import React, { Component } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import { fetchAccess } from "../../actions/FormActions/AccessFormActions";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextInput from "../../components/FormInput/TextInput";
import TextArea from "../../components/FormInput/TextArea";
import Select from "../../components/FormInput/Select";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import CheckBox from "../../components/FormInput/CheckBox";
import GalleryPicker from "../../components/FormComponents/GalleryPicker/GalleryPicker";
import ArtistPicker from "../../components/FormComponents/ArtistPicker/ArtistPicker";
import DatePicker from "../../components/FormComponents/DateRange/DatePicker";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LocationPicker from "../../components/FormComponents/LocationPicker/LocationPicker";
import CategoryPicker from "../../components/FormComponents/CategoryPicker/CategoryPicker";
import ArtworkPicker from "../../components/FormComponents/ArtworkPicker/ArtworkPicker";
import ShowPicker from "../../components/FormComponents/ShowPicker/ShowPicker";
import PriceRangePicker from "../../components/FormComponents/PriceRangePicker/PriceRangePicker";
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import PublishStatus from "../../components/FormComponents/PublishStatus/PublishStatus";
import OpeningHours from "../../components/FormComponents/OpeningHours/OpeningHours";
import FormEditor from "../../components/FormComponents/FormEditor/FormEditor";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import Tooltip from "../../components/Tooltip/Tooltip";
import { validateEmbed } from "../../utils/Validators";
import { CENTER_IMAGE_OPTIONS, videoTooltip } from "../../utils/values";
import { imageRotationActivated } from "../../utils/Helpers";
import { showPremiumUpgradeModal } from "../../utils/Dispatcher";
import { noEmailCheckbox } from "../../utils/languageUtil";
import { showCustomModal } from "../../actions/AppActions";
import moment from "moment";

const CLOSE = require("../../../assets/images/icons/close@2x.png");

const VISIBILITY_OPTIONS = [
	{ value: "restricted", name: "Restricted" },
	{ value: "web", name: "Webb only" },
	{ value: "app", name: "App only" },
	{ value: "all", name: "Everywhere" },
	{ value: "notlisted", name: "Not listed" },
];

/**
 * propTypes
 */
// const propTypes = {
// 	onSubmit: PropTypes.func.isRequired,
// 	isAdmin: PropTypes.bool,
// };

class ShowForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			vernissageStart: "",
			vernissageEnd: "",
			organizerFormOpen: false,
			proModalOpen: false,
			editorChanged: false,
		};
	}

	componentWillMount() {
		if (this.props.hasOrganizerData) {
			this.setState({ organizerFormOpen: true });
		}
		if (this.props.userIsSociety) {
			this.toggleExpanded();
		}
	}

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	updateChild = () => {
		if (this.editor) this.editor.updateContentFromParent();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	changeDates = (vernissageStart, vernissageEnd) => {
		this.setState({
			vernissageStart: new Date(moment(vernissageStart)),
			vernissageEnd: new Date(moment(vernissageEnd)),
		});
	};

	formSubmit = (e) => {
		e.preventDefault();
		const { vernissageStart, vernissageEnd } = this.state;
		this.props.handleSubmit(vernissageStart, vernissageEnd);
	};

	toggleOrganizerForm = (open) => {
		this.setState({ organizerFormOpen: open });
	};

	showProModal = () => {
		if (this.props.isSociety) {
			return showPremiumUpgradeModal(this.props.dispatch, "Premium");
		}

		const content = (
			<div>
				<p>
					Här kan du som <i>Premium</i> ladda upp en videofil.
				</p>
				<p>
					Läs mer och testa <i>Premium</i> gratis{" "}
					<a href="https://artworks.se/konstforening" target="_blank">
						här
					</a>
					! 🎨
				</p>
			</div>
		);
		this.props.dispatch(showCustomModal(content, ""));
	};

	premiumOnly = (evt) => {
		const { t } = this.props;
		evt.preventDefault();

		showPremiumUpgradeModal(this.props.dispatch, "Premium");
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			isAdmin,
			isArtist,
			artist,
			canAddAccess,
			showId,
			userIsSociety,
			createVernissage,
			forArtSociety,
			hasOrganizerData,
			isPremium,
			showVideo,
			hasOldAboutText,
			hasNewAboutText,
			automaticRemindersAvailable,
			t,
		} = this.props;
		const { expanded, organizerFormOpen, editorChanged } = this.state;
		const expandButtonStyle = {
			position: "absolute",
			right: "40px",
			top: 0,
		};

		const useUploadWithRotate = imageRotationActivated();

		return (
			<form onSubmit={(e) => this.formSubmit(e)} className="Form">
				{isAdmin ? (
					<div>
						<legend>{t("common:venue")}</legend>
						<Field name="gallery" component={GalleryPicker} />
					</div>
				) : null}

				<legend>{t("single.generalInformation")}</legend>
				{isArtist && (
					<div className="fieldset">
						<Fields
							names={[
								"locationName",
								"locationSearch",
								"location.type",
								"location.coordinates",
							]}
							component={LocationPicker}
							green
						/>
						<div className="fieldset-block">
							<Field
								name="onlineShow"
								component={CheckBox}
								checkboxLabel={t("single.online")}
							/>
						</div>
					</div>
				)}
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="title"
							component={TextInput}
							placeholder={t("single.name")}
							required
							marked
						/>
					</div>
					<div className="input-holder">
						<Field
							name="email"
							component={TextInput}
							placeholder={t("single.email")}
							green
						/>
					</div>
					<div className="input-holder input-holder--full input-holder--nopadding">
						<p style={{ marginLeft: "40px" }}>{t("single.pickArtist")}</p>
						<Field
							name="artists"
							component={ArtistPicker}
							multi={true}
							placeholder={t("single.artists")}
							green
						/>
					</div>
				</div>

				{/* Organizer */}
				{isAdmin && (
					<div className="fieldset">
						<legend>{t("single.organizer")} (Admin)</legend>
						<div className="input-holder input-holder--full input-holder--nopadding">
							{/* Width 361px or width changes when typing. */}
							<Field
								name="organizer"
								component={GalleryPicker}
								noFieldSet={true}
								forOrganizer={true}
							/>
							{!organizerFormOpen && (
								<div className="input-holder">
									<button
										type="button"
										className="purple-button--wide purple-button--wide--mod_picker"
										onClick={() => this.toggleOrganizerForm(true)}
									>
										{t("single.createOrganizer")}
									</button>
								</div>
							)}
							{organizerFormOpen && (
								<div className="input-holder">
									{!hasOrganizerData && (
										<img
											src={CLOSE}
											className="ShowForm__organizer-close"
											onClick={() => this.toggleOrganizerForm(false)}
										/>
									)}
									<Field
										name="organizerData.name"
										component={TextInput}
										placeholder={t("single.organizerName")}
									/>
									<Field
										name="organizerData.email"
										component={TextInput}
										placeholder={t("common:email")}
									/>
								</div>
							)}
						</div>
					</div>
				)}
				{/* End Organizer */}

				<legend>{t("common:startAndEndDate")}</legend>
				<Fields names={["startDate", "endDate"]} component={DatePicker} green />

				<legend>{t("single.aboutHeader")}</legend>
				{hasOldAboutText && !hasNewAboutText ? (
					<div className="input-holder input-holder--full">
						{/* Old about-field */}
						<Field
							name="textArea"
							component={TextArea}
							placeholder={t("single.aboutPlaceholder")}
							green
						/>
					</div>
				) : (
					<div className="input-holder input-holder--full">
						{/* New about field. */}
						<Fields
							names={["draftContentState", "htmlContent"]}
							component={FormEditor}
							onRef={(ref) => (this.editor = ref)}
							onEditorChange={this.editorChange}
							placeholder={t("single.aboutPlaceholder")}
							green
						/>
					</div>
				)}

				{/*<legend>About the artist</legend>
  			<div className="input-holder input-holder--full">
  				<Field
            name="aboutTheArtist"
            component={ TextArea }
            placeholder="About the artist"
          />
  			</div>*/}
				<div className="fieldset">
					<legend>{t("single.featuredImage")}</legend>
					<Fields
						names={[
							"featuredImage.src",
							"uploadedImage.URI",
							"uploadedImage.fileName",
							"uploadedImage.contentType",
						]}
						component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
						profileImage={true}
						canRemoveImage={true}
						marked
					/>
					{isAdmin ? (
						<Field name="featuredImage.color" component={ColorPicker} />
					) : null}
				</div>
				{isAdmin && (
					<div className="input-holder">
						<legend className="legend-nomargin">Image center position</legend>
						<Field
							name="featuredImage.center"
							options={CENTER_IMAGE_OPTIONS}
							component={Select}
						/>
					</div>
				)}

				{(isAdmin || isArtist || forArtSociety) && (
					<div className="fieldset">
						<div className="input-holder input-holder--flex">
							<legend className="legend-nomargin">
								{t("common:uploadVideo")}
							</legend>
							<Tooltip
								data={videoTooltip()}
								style={{ left: "190px", right: "auto" }}
							/>
							<Field
								name="videoLink"
								component={TextInput}
								placeholder={t("common:youtubeExamle")}
								validate={validateEmbed}
								disabled={!isPremium && !isAdmin}
								green
								video
							/>
							{!isPremium && !isAdmin && (
								<div
									className="block-div"
									onClick={() => this.showProModal()}
								></div>
							)}
						</div>
						{showVideo && (isPremium || isAdmin) && (
							<div className="videoContainer">
								<iframe
									width="440px"
									height="247px"
									src={showVideo}
									frameBorder="0"
								/>
							</div>
						)}
					</div>
				)}

				{!isArtist && (
					<div className="fieldset">
						<Fields
							names={[
								"locationName",
								"locationSearch",
								"location.type",
								"location.coordinates",
							]}
							component={LocationPicker}
							isShow={true}
							green
						/>
					</div>
				)}
				<div
					className="fieldset-block"
					style={{ position: "relative", marginBottom: "20px" }}
				>
					<legend className="legend-nomargin">
						{t("gallery:single.openingHoursForShows")}
					</legend>
					<ExpandButton
						expanded={expanded}
						click={this.toggleExpanded}
						style={expandButtonStyle}
					/>
					{expanded && (
						<Field
							name="openingHoursHidden"
							component={CheckBox}
							checkboxLabel={t("gallery:single.hideOpeningHours")}
						/>
					)}
					{expanded && (
						<FormSection name="openingHours">
							<OpeningHours />
						</FormSection>
					)}
				</div>
				{/*
          ArtworkPicker replaced with ArtworksListAndUpload in EditShow.jsx.
          Keeping ArtworkPicker here for now, just in case.
        */}
				{/*<Field
          name="artworks"
          component={ ArtworkPicker }
          defaultValue={[]}
          addOnUpload={ true }
        />*/}
				{/*<Field
          name="category"
          type="show"
          component={ CategoryPicker }
        />*/}
				{isAdmin ? (
					<div className="fieldset">
						<legend>Extra (admin only)</legend>
						<div className="input-holder">
							<Field
								name="editorRating"
								component={TextInput}
								placeholder="Editor rating(1-10)"
							/>
						</div>
						{showId && (
							<div className="input-holder">
								<Field name="name" component={TextInput} placeholder="Name" />
								<p style={{ color: "#A8A8A8" }}>
									Name. Automatically set to artists name or Group Show when
									creating show.
								</p>
							</div>
						)}
						{/*<Field
              name="relatedShows"
              component={ ShowPicker }
            />*/}

						<legend>Pricerange (including VAT)</legend>
						<FormSection name="priceRange">
							<PriceRangePicker />
						</FormSection>

						<div className="fieldset">
							<legend>Visibility</legend>
							<div className="input-holder">
								<Field
									name="visibility"
									component={Select}
									options={VISIBILITY_OPTIONS}
								/>
							</div>
							{canAddAccess ? (
								<div className="input-holder">
									<button
										type="button"
										onClick={(e) => {
											e.preventDefault();
											this.props.openAccess(showId);
										}}
									>
										Handle access
									</button>
								</div>
							) : null}
						</div>
					</div>
				) : null}

				<div className="input-holder input-holder--full">
					<Field name="status" component={PublishStatus} />
				</div>

				{(userIsSociety || isAdmin) && automaticRemindersAvailable && (
					<div className="fieldset-block">
						<Field
							name="noAutomaticEmails"
							component={CheckBox}
							checkboxLabel={noEmailCheckbox(
								i18next.language === "sv" ? "utställningen" : "show"
							)}
						/>
					</div>
				)}
				{(userIsSociety || isAdmin) && !automaticRemindersAvailable && (
					<div
						className="fieldset-block"
						style={{ marginBottom: "20px", opacity: 0.5 }}
						onClick={this.premiumOnly}
					>
						{/* pointerEvents: none, or onClick on parent element won't be triggered. */}
						<input
							id="placeholder-checkbox"
							type="checkbox"
							checked
							disabled
							style={{ pointerEvents: "none" }}
						/>
						<label htmlFor="placeholder-checkbox">
							{noEmailCheckbox(
								i18next.language === "sv" ? "utlottningen" : "raffle"
							)}
						</label>
					</div>
				)}

				<div className="fieldset-block">
					<Field
						name="createVernissage"
						component={CheckBox}
						checkboxLabel={t("common:vernissage.createVernissage")}
					/>
				</div>
				{createVernissage && (
					<DateRangePicker
						withTime={true}
						updateCallbackMs={this.changeDates}
					/>
				)}
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="slug"
							itemType="show"
							component={TextDisplay}
							isAdmin={isAdmin}
						/>
					</div>
					{!this.props.formModalOpen && (
						<div className="fixed-save-button">
							<button
								className="purple-button--wide"
								type="submit"
								onClick={this.updateChild}
								disabled={(pristine || submitting) && !editorChanged}
							>
								{t("common:save")}
							</button>
						</div>
					)}
				</div>
				{isAdmin && (
					<div className="fieldset" style={{ marginTop: "30px" }}>
						<legend>Last email sent (admin only)</legend>
						<div className="input-holder">
							<Field name="emailsSentDate" component={TextInput} />
						</div>
					</div>
				)}
				{/*Clarify that it is only possible to add artworks after the show has been created*/}
				{userIsSociety && !showId && (
					<div className="fieldset">
						<legend>{t("single.checkInfo")}</legend>
					</div>
				)}
			</form>
		);
	}
}

ShowForm = reduxForm({
	form: "show",
})(ShowForm);

const selector = formValueSelector("show");
ShowForm = connect(
	(state) => {
		const showId = selector(state, "_id");
		const restrictedAccess = selector(state, "visibility") === "restricted";
		const canAddAccess = showId && restrictedAccess;
		const userIsSociety = state.auth.user.role === "society";
		const createVernissage = selector(state, "createVernissage");
		const gallery = selector(state, "gallery");
		const forArtSociety =
			userIsSociety || (gallery && gallery.venueType === "ArtSociety");
		const organizerData = selector(state, "organizerData");

		const textArea = selector(state, "textArea");
		const htmlContent = selector(state, "htmlContent");
		const hasOldAboutText = !!textArea;
		const hasNewAboutText = !!htmlContent;

		// Return true if any value in organizerData is not empty.
		let hasOrganizerData = false;
		if (organizerData) {
			Object.keys(organizerData).forEach((key) => {
				if (organizerData[key]) hasOrganizerData = true;
			});
		}

		const {
			artistForm: { showArtistModal },
			artworkForm: { showArtworkModal },
		} = state;
		const formModalOpen = showArtistModal || showArtworkModal;

		return {
			canAddAccess,
			showId,
			gallery,
			userIsSociety,
			createVernissage,
			forArtSociety,
			hasOrganizerData,
			formModalOpen,
			hasOldAboutText,
			hasNewAboutText,
		};
	},
	(dispatch) => {
		return {
			openAccess: (showId) => {
				dispatch(fetchAccess(showId, "shows"));
			},
		};
	}
)(ShowForm);

export default withNamespaces("show")(ShowForm);
