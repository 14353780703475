import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import BurgerButton from "./BurgerMenu/BurgerButton";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import LatestSalesBanner from "./SalesBanner/LatestSalesBanner";
import { createPageWebbLink } from "../../utils/Helpers";
import { artistProSubscribeWindowOpened } from "@artworkslab/sharedmodules/src/actions/MessageActions";
import { is } from "immutable";

const LOGO = require("../../../assets/images/portal-logo.png");
const LOGO_MOBILE = require("../../../assets/images/logo.png");
const LOGO_DESKTOP = require("../../../assets/images/icons/Artworks_wordmark_black_rgb_cropped.svg");
const TRIANGLE = require("../../../assets/images/svg/triangle_up_black.svg");
const VIEW_ONLINE = require("../../../assets/images/svg/share_22px.svg");
const BURGER_MENU = "burger-menu";

const userActive = (user, updateUser) => {
	// If user not logged in:
	if (!user.id) return;
	if (!user.activeInPortal) {
		updateUser(user);
		return;
	}
	// Update user.activeInPortal, but no need to do it more than once per hour.
	// Use to send email to user after being away for some amount of time.
	const activeAgain = moment().diff(moment(user.activeInPortal), "hours") > 1;
	if (activeAgain) updateUser(user);
};

const openToSubscriptionPage = (user, dispatch) => {
	window.open("https://artworks.se/pro#erbjudanden");
	dispatch(artistProSubscribeWindowOpened(user, true));
};

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menusOpen: false,
			burgerMenuOpen: false,
		};
	}

	openMenu = (menu) => {
		this.setState({
			menusOpen: true,
			burgerMenuOpen: menu === BURGER_MENU,
		});
	};

	closeMenus = () => {
		this.setState({
			menusOpen: false,
			burgerMenuOpen: false,
		});
	};

	setResponsive = (responsive) => {
		appGlobals.setResponsiveDisplay(responsive);
	};

	render() {
		const {
			handleLogout,
			authenticated,
			isAdmin,
			isAuthor,
			isSociety,
			isArtist,
			toggleGuide,
			webbLink: generalWebbLink,
			gallery,
			user,
			pathName,
			premium,
			updateUserActive,
			xmasCalendar,
			notifications,
			dispatch,
			t,
		} = this.props;
		const { menusOpen, burgerMenuOpen } = this.state;
		const isResponsive = appGlobals.useResponsive;

		let logoLink = authenticated ? "/dashboard" : "/";
		// Before an art society has created its gallery.
		if (isSociety && !gallery._id) logoLink = "/gallery";
		// const premium = isPremium(gallery)
		if (isSociety && !premium) logoLink = "/gallery";
		// Check for gallery._id, or Bas logo will show before Premium logo.
		// New society user will have gallery false until they create a gallery.
		// const showPremiumSymbol = isSociety && premium && (gallery._id || !user.gallery)
		const showPremiumSymbol = isSociety && premium;

		const premiumPage =
			pathName === "/newsletter" ||
			pathName === "/contacts" ||
			pathName === "/filearchive";

		let navText = "";
		let showPremiumButton = true;
		switch (pathName) {
			case "/":
			case "/register":
			case "/register/gallery":
			case "/register/artsociety":
			case "/register/konstnarshuset":
				navText = t("login.registerTitle");
				break;
			case "/login":
				navText = t("login.loginTitle");
				break;
			case "/forgot":
				navText = t("login.forgotTitle");
				break;
			case "/for-artists":
				navText = t("login.forArtistTitle");
				break;
			case "/settings":
				showPremiumButton = false;
				break;
			default:
				break;
		}
		if (pathName.indexOf("/reset/") >= 0) {
			navText = t("login.updateTitle");
		}

		const showActive =
			pathName.indexOf("/shows/") > -1 || pathName.indexOf("/events/") > -1
				? true
				: false;
		let subscribeButtonStyle = "navbar-subscribe-button";
		if (!showPremiumButton) subscribeButtonStyle += " is-disabled";

		// Create a webblink for this specific page.
		// generalWebbLink is a link to the users (artists or gallerys) page.
		const pageWebbLink = createPageWebbLink(pathName);
		const webbLink = pageWebbLink || generalWebbLink;

		return (
			<div className="navbar-resp">
				<div className="navbar-resp__fixed-container">
					<div
						className="navbar-resp__inner"
						onClick={() => userActive(user, updateUserActive)}
					>
						<Link
							className="mobile-logo"
							to={authenticated ? "/dashboard" : "/login"}
						>
							<img src={LOGO_MOBILE} />
						</Link>
						<Link
							className="desktop-logo"
							to={authenticated ? "/dashboard" : "/login"}
						>
							<LOGO_DESKTOP />
						</Link>
						{authenticated && isArtist && !premium && (
							<button
								className="navbar-resp-subscribe-button is-enabled"
								onClick={() => openToSubscriptionPage(user, dispatch)}
							>
								<span>{t("navbar.upgrade")}</span>
							</button>
						)}
						{authenticated && isArtist && premium && (
							<button className="navbar-resp-subscribe-button">
								<span>
									<i>PRO</i>
								</span>
							</button>
						)}
						{authenticated && isSociety && !premium && (
							<Link
								to="/settings"
								className="navbar-resp-subscribe-button is-enabled"
								style={{ paddingTop: "7px" }}
							>
								<span>Uppgradera</span>
							</Link>
						)}
						<div className="central-spacer" />
						{authenticated && (
							<a
								href={webbLink}
								target="_blank"
								className="navbar-webblink"
								title={t("navbar.viewOnline")}
							>
								<div
									className={
										"icon-container" +
										(!webbLink ? " is-disabled" : "") +
										(webbLink ? " is-webbLink" : "")
									}
								>
									<VIEW_ONLINE />
								</div>
							</a>
						)}
						{authenticated && (
							<BurgerButton
								isOpen={burgerMenuOpen}
								notifications={
									notifications.total -
										notifications.shows -
										notifications.artworks || 0
								}
								open={() => this.openMenu(BURGER_MENU)}
								close={this.closeMenus}
								isResponsive={isResponsive}
							/>
						)}
						<TRIANGLE
							className={
								"burgermenu-tail-resp" + (burgerMenuOpen ? " is-visible" : "")
							}
						/>
						{/* !authenticated &&
              <div className={ block('inner-unauthorized').state({ notauthenticated: !authenticated })}>
                <Link to='/'><h1 className={'title'}>{navText}</h1></Link>
              </div>
            */}
						{!isAdmin && !isAuthor && !isSociety && !isArtist && (
							<div className="NavBar__nav__links fail-safe">
								<div className="fail-safe__link" onClick={handleLogout}>
									{t("logIn")}
								</div>
							</div>
						)}
					</div>
					{/*(isArtist || isSociety) &&
            <div className={ block('fixed-container')('Phone-bar') }>
              { isArtist &&
                <a href="https://www.artworks.se/pro" target="_blank"><p>{t('navbar.artistUpdateShort')}</p></a>
              }
              { isSociety &&
                <a href="https://www.artworks.se/pro" target="_blank"><p>{t('navbar.societyUpdate')}</p></a>
              }
            </div>
          */}
					<div className="menus-resp burger-menu-container">
						<div
							className={
								"menus-resp__background" + (menusOpen ? " is-open" : "")
							}
							onClick={this.closeMenus}
						/>
						<div
							className={
								"menus-resp__container" +
								(menusOpen ? " is-open" : "") +
								(!isArtist ? " is-notArtist" : "")
							}
							onClick={this.closeMenus}
						>
							<BurgerMenu
								isOpen={burgerMenuOpen}
								pathName={pathName}
								premium={premium}
								gallery={gallery}
								xmasCalendar={xmasCalendar}
								notifications={notifications}
								webbLink={webbLink}
								close={this.closeMenus}
								isResponsive={isResponsive}
								t={t}
							/>
						</div>
					</div>
				</div>
				{/*Must be placed outside as main-resp has padding*/}
				{/* {(isArtist || isSociety || isAdmin) && !__PRODUCTION__ &&
          <LatestSalesBanner
            mobile={true}
          />
        } */}
			</div>
		);
	}
}

NavBar.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	authenticated: PropTypes.bool.isRequired,
};

export default connect(null)(NavBar);
