import { createSelector } from 'reselect'

const getOrders = state => state.order.orders

export const getPendingOrders = createSelector(
  [ getOrders ],
  ( allOrders ) => {
    let orders = {}
    Object.keys(allOrders).sort((a, b) => {
      const aObj = allOrders[a]
      const bObj = allOrders[b]
      return new Date(bObj.updated_at) - new Date(aObj.updated_at);
    }).map((orderId) => {
      const order = allOrders[orderId]
      if (order.status === 'pending') {
        orders[order._id] = order
      }
    })
    return orders
  }
)

export const getPaidOrders = createSelector(
  [ getOrders ],
  ( allOrders ) => {
    let orders = {}
    Object.keys(allOrders).sort((a, b) => {
      const aObj = allOrders[a]
      const bObj = allOrders[b]
      return new Date(bObj.updated_at) - new Date(aObj.updated_at);
    }).map((orderId) => {
      const order = allOrders[orderId]
      if (order.status === 'paid') {
        orders[order._id] = order
      }
    })
    return orders
  }
)

export const getToDeliverOrders = createSelector(
  [ getOrders ],
  ( allOrders ) => {
    let orders = {}
    Object.keys(allOrders).sort((a,b) => {
      const aObj = allOrders[a]
      const bObj = allOrders[b]
      return new Date(bObj.updated_at) - new Date(aObj.updated_at);
    }).map((orderId) => {
      const order = allOrders[orderId]
      if (order.status === 'todeliver') {
        orders[order._id] = order
      }
    })
    return orders
  }
)

export const getDeliveredOrders = createSelector(
  [ getOrders ],
  ( allOrders ) => {
    let orders = {}
    Object.keys(allOrders).sort((a, b) => {
      const aObj = allOrders[a]
      const bObj = allOrders[b]
      return new Date(bObj.updated_at) - new Date(aObj.updated_at);
    }).map((orderId) => {
      const order = allOrders[orderId]
      if (order.status === 'delivered') {
        orders[order._id] = order
      }
    })
    return orders
  }
)
