import React from "react";
import Modal from "../Modal";

const VideoGuide = ({ close }) => {
	// rel=0, only show related videos from the same channel after video is finished.
	// modestbranding=1, hide YouTube logo.
	return (
		<Modal type="large" onCloseClick={close}>
			<h1>Tips och tricks</h1>
			<div className="videoguides">
				<div className="videoguides__guide">
					<h2>Kom igång</h2>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/caoGNePKQaI?rel=0&modestbranding=1&hl=sv"
						frameBorder="0"
						allowFullScreen
					></iframe>
				</div>
				<div className="videoguides__guide">
					<h2>Skapa en utställning</h2>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/lBdZgmFM-Fs?rel=0&modestbranding=1&hl=sv"
						frameBorder="0"
						allowFullScreen
					></iframe>
				</div>
				<div className="videoguides__guide">
					<h2>Skapa en aktivitet</h2>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/YOJ2IBnItI8?rel=0&modestbranding=1&hl=sv"
						frameBorder="0"
						allowFullScreen
					></iframe>
				</div>
				<div className="videoguides__guide">
					<h2>Medlemsregister</h2>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/2q-rkTMKbMA?rel=0&modestbranding=1&hl=sv"
						frameBorder="0"
						allowFullScreen
					></iframe>
				</div>
				<div className="videoguides__guide">
					<h2>Skapa en utlottning</h2>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/9KKEQij9CqM?rel=0&modestbranding=1&hl=sv"
						frameBorder="0"
						allowFullScreen
					></iframe>
				</div>
			</div>
		</Modal>
	);
};

export default VideoGuide;
