import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Table, Tr, Td, Thead, Th } from "../../../components/Table/Table";

class ArtworkPickerList extends Component {
	constructor(props) {
		super(props);
	}

	createTableHead = () => {
		const { toggleForItem, toggleHeader, t } = this.props;
		const item = {};
		const tableHead = (
			<Thead>
				{toggleForItem && <Th column="forItem">{toggleHeader}</Th>}
				<Th column="image">{` `}</Th>
				<Th column="title">{t("list.title")}</Th>
				<Th column="artist">{t("list.artist")}</Th>
				<Th column="viewHere">{` `}</Th>
			</Thead>
		);

		return tableHead;
	};

	toggleCheckbox = (artworkId, isChecked) => {
		const { addedArtworkIds, toggleForItem, t } = this.props;

		let updatedList = addedArtworkIds.slice();
		if (isChecked) {
			const index = updatedList.indexOf(artworkId);
			if (index >= 0) {
				const removed = updatedList.splice(index, 1);
			}
		} else {
			updatedList.push(artworkId);
		}

		toggleForItem(updatedList);
	};

	createTableBody = (artworks) => {
		const { addedArtworkIds, toggleForItem, t } = this.props;
		// console.log('addedArtworkIds.length', addedArtworkIds.length);
		const rows = [];
		Object.keys(artworks).forEach((key) => {
			const artwork = artworks[key];
			if (artwork.publish_status === "published") {
				const image = artwork.images[0];
				const smallThumbnail = image.thumbnails
					? image.thumbnails["100x100"].src
					: image.src;
				const isChecked = addedArtworkIds.indexOf(key) >= 0;
				// console.log('isChecked', isChecked);
				// console.log('artwork', artwork);
				const artistName = artwork.artistMod
					? artwork.artistMod.name
					: "Unknown";
				rows.push(
					<Tr key={artwork._id}>
						<Td column="forItem">
							<input
								type="checkbox"
								className={"list-and-upload__raffle-checkbox"}
								onChange={() => this.toggleCheckbox(artwork._id, isChecked)}
								checked={isChecked}
								disabled={false}
							/>
						</Td>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							<img
								src={smallThumbnail}
								onError={(elem) => (elem.target.src = image.src)}
								style={{ width: "60px" }}
								alt="image of artwork"
							/>
						</Td>
						<Td column="title" value={artwork.title}>
							{artwork.title}
						</Td>
						<Td column="artist" value={artistName}>
							{artistName}
						</Td>
						<Td column="viewHere" value="ViewHere">
							<a
								href={`${process.env.BASE_URL}konstverk/${artwork.slug}`}
								target="_blank"
								style={{ textDecoration: "underline" }}
							>
								{t("list.viewHere")}
							</a>
						</Td>
					</Tr>
				);
			}
		});

		return rows;
	};

	render() {
		const { artworks } = this.props;
		// console.log('artworks', artworks);
		const tableHead = this.createTableHead();
		const tableBody = this.createTableBody(artworks);

		return (
			<Table
				className="reactable"
				sortable={["forItem", "title", "artist"]}
				defaultSort={{ column: "artist", direction: "desc" }}
				filterable={false}
				filterPlaceholder={"Search artwork"}
				itemsPerPage={20}
				pageButtonLimit={0}
			>
				{tableHead}
				{tableBody}
			</Table>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		artwork: { artworks },
	} = state;
	return { artworks };
};

const translated = withNamespaces("artworks")(ArtworkPickerList);
export default connect(mapStateToProps)(translated);
