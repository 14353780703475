import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Table, Tr, Td, Thead, Th } from "../../components/Table/Table";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import Main from "../../components/Main/Main";
import { fetchArtSocieties } from "../../actions/GalleryActions";
import { fetchRaffles } from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";
import DateUtil from "@artworkslab/sharedmodules/src/DateUtil";

const ARROW = require("../../../assets/images/icons/chevron_right_white_22px.png");
const ALL = "All";

class Raffles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedSociety: false,
		};
	}

	componentWillMount() {
		this.fetchData(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (
			(!this.props.isAdmin && nextProps.isAdmin) ||
			(!this.props.user.gallery && nextProps.user.gallery)
		) {
			this.fetchData(nextProps);
		}
	}

	fetchData = (props) => {
		const { isAdmin, user } = props;
		const { selectedSociety } = this.state;

		if (isAdmin) {
			this.props.dispatch(fetchArtSocieties());
		} else if (isAdmin && selectedSociety) {
			this.props.dispatch(fetchRaffles(selectedSociety, false, true));
		} else if (isAdmin && !selectedSociety) {
		} else if (user.gallery) {
			this.props.dispatch(
				fetchRaffles(user.gallery._id ? user.gallery._id : user.gallery)
			);
		}
	};

	getImageSrc = (image) => {
		return image.thumbnails ? image.thumbnails["100x100"].src : image.src;
	};

	thumbnailError = (e, failedSource, fullSrc) => {
		// Must get failedSource from e.target.src inside img element.
		// If full source has failed, don't set as src, it will loop.
		if (failedSource !== fullSrc) {
			e.target.src = fullSrc;
		}
	};

	selectSociety = (galleryId) => {
		this.setState({
			selectedSociety: galleryId,
		});
		// fetchRaffles: galleryId, query, reset
		galleryId !== ALL
			? this.props.dispatch(fetchRaffles(galleryId, false, true))
			: this.props.dispatch(fetchRaffles(false, {}));
	};

	createSocietyButtons = () => {
		const { selectedSociety } = this.state;
		const { artSocieties } = this.props;

		const societyButtons = Object.keys(artSocieties).map((key) => {
			const society = artSocieties[key];
			return (
				<button
					type="button"
					className={
						"raffles__society-filters__filter" +
						(selectedSociety === key ? " is-selected" : "")
					}
					onClick={() => this.selectSociety(key)}
					key={key}
				>
					{society.name}
					{selectedSociety === key && (
						<Link to={`/galleries/${society.slug}`}>
							<img
								src={ARROW}
								className="raffles__society-filters__filter__arrow"
							/>
						</Link>
					)}
				</button>
			);
		});

		const allButtons = [
			<button
				type="button"
				className={
					"raffles__society-filters__filter" +
					(selectedSociety === ALL ? " is-selected" : "")
				}
				onClick={() => this.selectSociety("All")}
				key={ALL}
			>
				{ALL}
			</button>,
		].concat(societyButtons);

		return allButtons;
	};

	createRaffleHeader = (isAdmin, rows, maxItems, t) => {
		return (
			<Table
				className="reactable"
				sortable={["title", "drawDate", "gallery", "status", "createdAt"]}
				filterable={
					isAdmin
						? ["title", "drawDate", "gallery", "status", "createdAt"]
						: false
				}
				itemsPerPage={maxItems}
				pageButtonLimit={10}
			>
				<Thead>
					<Th column="image"> </Th>
					<Th column="title">{t("list.title")}</Th>
					<Th column="drawDate">{t("list.drawDate")}</Th>
					{isAdmin && <Th column="gallery">{t("list.gallery")}</Th>}
					<Th column="createdAt">{t("list.createdAt")}</Th>
					<Th column="status">{t("list.status")}</Th>
				</Thead>
				{rows}
			</Table>
		);
	};

	createRaffleRows = (raffles, isAdmin, t) => {
		const { selectedSociety } = this.state;

		// When coming back from another page.
		if (isAdmin && !selectedSociety) return [];

		return Object.keys(raffles)
			.sort((a, b) => {
				let aObject = raffles[a];
				let bObject = raffles[b];
				return new Date(bObject.drawDate) - new Date(aObject.drawDate);
			})
			.map((key) => {
				const raffle = raffles[key];
				if (!raffle.gallery) {
					raffle.gallery = {
						featuredImage: false,
						name: "No Gallery",
						slug: "nogallery",
					};
				}

				const image =
					raffle.featuredImage || raffle.gallery.featuredImage || "";
				const thumbnailSrc = image ? this.getImageSrc(image) : "";
				const published = raffle.publish_status === "published" ? true : false;

				return (
					<Tr key={key}>
						<Td column="image" style={{ padding: 0, width: 70 }}>
							<Link to={`/raffles/${raffle.slug || raffle._id}`}>
								{/*<div className="table-image" style={{backgroundImage: 'url(' + imageSrc + ')', backgroundColor: '#b8b8b8'}} ref={ref => this[key] = ref} />*/}
								<img
									src={thumbnailSrc}
									className="table-image"
									onError={(e) =>
										this.thumbnailError(e, e.target.src, image.src)
									}
									alt="Event image"
								/>
							</Link>
						</Td>
						<Td column="title" value={raffle.title || "Untitled"}>
							<Link to={`/raffles/${raffle.slug || raffle._id}`}>
								{raffle.title || "Untitled"}
							</Link>
						</Td>
						<Td column="drawDate" value={raffle.drawDate ? raffle.drawDate : 0}>
							<Link to={`/raffles/${raffle.slug || raffle._id}`}>
								{raffle.drawDate
									? DateUtil.dateMonthShort(
											raffle.drawDate,
											i18next.language,
											true,
											true
									  )
									: t("list.noDateSet")}
							</Link>
						</Td>
						{isAdmin && (
							<Td column="gallery" value={raffle.gallery.name}>
								<Link to={`/galleries/${raffle.gallery.slug}`}>
									{raffle.gallery.name}
								</Link>
							</Td>
						)}
						<Td column="createdAt" value={raffle.created_at}>
							<Link to={`/raffles/${raffle.slug || raffle._id}`}>
								{DateUtil.dateMonthShort(
									raffle.created_at,
									i18next.language,
									true,
									true
								)}
							</Link>
						</Td>
						<Td column="status" value={raffle.publish_status}>
							<Link
								to={`/raffles/${raffle.slug || raffle._id}`}
								className={
									"reactable__state" + (published ? " is-published" : "")
								}
							>
								{t(`common:status.${raffle.publish_status}`)}
							</Link>
						</Td>
					</Tr>
				);
			});
	};
	// DateUtil.dayMonthYear(raffle.drawDate, i18next.language)
	// moment(raffle.drawDate).format('MMM Do YYYY, HH:mm')
	// moment(raffle.created_at).format('MMM Do YYYY, HH:mm')

	render() {
		const { isAdmin, raffles, t } = this.props;
		const upcomingRaffles = [];
		const pastRaffles = [];
		const today = moment();
		if (!isAdmin) {
			Object.keys(raffles).forEach((key) => {
				const raffle = raffles[key];
				if (moment(raffle.drawDate).isAfter(today)) {
					upcomingRaffles.push(raffle);
				} else {
					pastRaffles.push(raffle);
				}
			});
		}

		return (
			<Main>
				<div className="raffles">
					<div style={{ marginBottom: "40px" }}>
						<Link
							to="/raffles/new"
							className="purple-button--wide purple-button--wide--mod_create"
						>
							{t("list.create-raffle")}
						</Link>
					</div>
					{isAdmin && (
						<div className="raffles__society-filters">
							{this.createSocietyButtons()}
						</div>
					)}
					{isAdmin &&
						this.createRaffleHeader(
							isAdmin,
							this.createRaffleRows(raffles, isAdmin, t),
							false,
							t
						)}
					{!isAdmin && (
						<div>
							<legend className="legend-nomargin">
								{t("list.upcomingRaffles")}
							</legend>
							{this.createRaffleHeader(
								isAdmin,
								this.createRaffleRows(upcomingRaffles, isAdmin, t),
								false,
								t
							)}
							<legend className="legend-nomargin" style={{ marginTop: "20px" }}>
								{t("list.pastRaffles")}
							</legend>
							{this.createRaffleHeader(
								isAdmin,
								this.createRaffleRows(pastRaffles, isAdmin, t),
								pastRaffles.lenght > 100 ? 100 : false,
								t
							)}
						</div>
					)}
				</div>
			</Main>
		);
	}
}

function mapStateToProps(state) {
	const {
		artSociety: { raffles },
		auth: { isAdmin, user },
		gallery: { galleries: artSocieties },
	} = state;
	return { raffles, isAdmin, user, artSocieties };
}

const translated = withNamespaces("raffle")(Raffles);
export default connect(mapStateToProps)(translated);
