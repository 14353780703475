import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import {
	initialize,
	submit,
	startSubmit,
	hasSubmitSucceeded,
	getFormInitialValues,
	getFormValues,
	hasSubmitFailed,
	isSubmitting,
} from "redux-form";
import SelectionPicker from "../../components/FormComponents/SelectionPicker/SelectionPicker";
import Main from "../../components/Main/Main";
import AnnouncementForm from "./AnnouncementForm";
import {
	fetchAnnouncement,
	resetAnnouncement,
} from "../../actions/AnnouncementActions";
import { submitAnnouncement } from "../../actions/FormActions/AnnouncementFormActions";

class Announcement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	componentDidMount() {
		const slug = this.props.params.announcementSlug;
		if (slug !== "new") {
			this.props.dispatch(fetchAnnouncement(slug));
		} else {
			this.props.dispatch(resetAnnouncement());
			this.props.dispatch(initialize("announcement", {}));
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			(!this.state.initialized && nextProps.announcement._id) ||
			(this.props.announcement._id !== nextProps.announcement._id &&
				nextProps.announcement._id)
		) {
			this.setState({
				initialized: true,
			});
			this.props.dispatch(initialize("announcement", nextProps.announcement));
		}
	}

	handleSubmit = () => {
		const {
			form: { announcement, initial },
		} = this.props;
		this.props.dispatch(submitAnnouncement(announcement, initial));
	};

	render() {
		const { announcement, t } = this.props;

		return (
			<div className="announcement">
				<Main>
					<AnnouncementForm onSubmit={this.handleSubmit} t={t} />
					{announcement._id && (
						<SelectionPicker type="announcements" item={announcement} />
					)}
				</Main>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		announcement: { announcement, isFetching },
	} = state;
	const form = {
		submitting: isSubmitting("announcement")(state),
		submitSucceeded: hasSubmitSucceeded("announcement")(state),
		submitFailed: hasSubmitFailed("announcement")(state),
		announcement: getFormValues("announcement")(state),
		initial: getFormInitialValues("announcement")(state),
	};

	return {
		announcement,
		form,
		isFetching,
	};
}

const translated = withNamespaces("announcement")(Announcement);
export default connect(mapStateToProps)(translated);
