import React from "react";
import moment from "moment";

class GalleryUpgrades extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			requestsExpanded: false,
			expandedRequest: -1,
		};
	}

	sortUpgradeRequests = (a, b) => {
		if (a.requestDate && !b.requestDate) return -1;
		if (!a.requestDate && b.requestDate) return 1;
		if (!a.requestDate && !b.requestDate) return 0;
		// console.log('is before', moment(a.requestDate).isBefore(moment(b.requestDate)));
		if (moment(a.requestDate).isAfter(moment(b.requestDate))) {
			return -1;
		} else {
			return 1;
		}
		return 0;
	};

	toggleRequestsExpanded = () => {
		this.setState({
			requestsExpanded: !this.state.requestsExpanded,
		});
	};

	toggleRequestOpen = (num) => {
		this.setState({
			expandedRequest: this.state.expandedRequest === num ? -1 : num,
		});
	};

	render() {
		const { gallery, isAdmin, t } = this.props;
		const { requestsExpanded, expandedRequest } = this.state;
		const requestedUpgrades = gallery.previousUpdateMembership || [];

		if (requestsExpanded) {
			requestedUpgrades.sort(this.sortUpgradeRequests);
		}

		return (
			<div className="gallery-upgrades">
				{requestedUpgrades.length > 0 && (
					<p
						className="gallery-upgrades__header"
						onClick={this.toggleRequestsExpanded}
					>
						<u>{t("components:galleryMembership.requestedUpgrades")}</u>
					</p>
				)}
				{requestsExpanded && requestedUpgrades.length > 0 && (
					<div className="gallery-upgrades__list">
						{requestedUpgrades.map((upgrade, index) => {
							let requestDate = "";
							if (upgrade.requestDate) {
								requestDate = upgrade.requestDate;
							} else if (upgrade.created_at) {
								requestDate = upgrade.created_at;
							}

							return isAdmin ? (
								<div
									key={upgrade._id}
									className="gallery-upgrade"
									onClick={() => this.toggleRequestOpen(index)}
								>
									<p>
										<span>
											{t("components:galleryMembership.requestSent")}:
										</span>
										{` ${
											requestDate
												? moment(requestDate).format("YY-MM-DD h:mm a")
												: "No date"
										}`}
									</p>
									{expandedRequest === index && (
										<div>
											<p>{`${t("components:galleryMembership.requestedBy")}: ${
												upgrade.requesterEmail
											}`}</p>
											<p>{`${t(
												"components:galleryMembership.requestedLevel"
											)}: ${t(
												`components:galleryMembership.tiers.${upgrade.newMembershipLevel}`
											)}`}</p>
											<p>{`${t(
												"components:galleryMembership.levelWhenRequestSent"
											)}: ${t(
												`components:galleryMembership.tiers.${upgrade.currentMembershipLevel}`
											)}`}</p>
											{/*<p>{ `Requested from: ${upgrade.source || 'N/A'}` }</p>*/}
										</div>
									)}
								</div>
							) : (
								<div
									key={upgrade._id}
									className="gallery-upgrade"
									onClick={() => this.toggleRequestOpen(index)}
								>
									<p>
										<span>
											{t("components:galleryMembership.requestSent")}:
										</span>
										{` ${
											requestDate
												? moment(requestDate).format("YY-MM-DD")
												: "No date"
										}`}
									</p>
									{expandedRequest === index && (
										<div>
											<p>{`${t("components:galleryMembership.requestedBy")}: ${
												upgrade.requesterEmail
											}`}</p>
											<p>{`${t(
												"components:galleryMembership.requestedLevel"
											)}: ${t(
												`components:galleryMembership.tiers.${upgrade.newMembershipLevel}`
											)}`}</p>
										</div>
									)}
								</div>
							);
						})}
					</div>
				)}
				{requestedUpgrades.length < 1 && (
					<div>
						<p className="gallery-upgrades__header">
							{t("components:galleryMembership.requestedUpgrades")}
						</p>
						<p>{t("components:galleryMembership.noRequests")}</p>
					</div>
				)}
			</div>
		);
	}
}

export default GalleryUpgrades;
