import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import {
	showErrorModal,
	showSuccessModal,
	showCustomModal,
	hideCustomModal,
} from "../../../actions/AppActions";
import { fetchPreview, sendContactEmail } from "../../../actions/EmailActions";

class SendEmailModalClass extends Component {
	render() {
		const { message, markup, sendEmail, t } = this.props;

		return (
			<div className="send-email-modal">
				<div className="send-email-modal__markup">
					{message && message.subject && (
						<div className="send-email-modal__markup__subject">
							{`${t("emails:artistEmail.subject")}: ${message.subject}`}
						</div>
					)}
					<div className="send-email-modal__markup__content">
						<div dangerouslySetInnerHTML={markup}></div>
					</div>
				</div>
				<div className="send-email-modal__send-button">
					<button className="purple-button--circle" onClick={() => sendEmail()}>
						{t("informMemberButton.send")}
					</button>
				</div>
			</div>
		);
	}
}

const SendEmailModal = connect(null)(SendEmailModalClass);

class SendEmailButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previewOpen: false,
		};
	}

	//Creates email markup
	createMarkup = (html) => {
		return { __html: html };
	};

	//Returns the preview message
	getPreviewMessage = () => {
		const { message } = this.props;
		const isSwedish = i18next.language === "sv";
		let previewSubject = isSwedish ? "Exempel rubrik" : "Sample subject";
		let previewText = isSwedish ? "Exempeltext" : "Sample message";

		const subject = message.subject ? message.subject : previewSubject;
		const text = message.text ? message.text : previewText;

		const previewMessage = { subject: subject, text: text };
		const styledMessage = this.styleMessageText(previewMessage);
		return styledMessage;
	};

	//Formats the message text for the preview
	styleMessageText = (message) => {
		let messageText = message.text;
		messageText = messageText.replace(/(\n|\r)/gi, "<br />");
		let content = `<p>${messageText}</p>`;
		const returnMessage = { subject: message.subject, text: content };
		return returnMessage;
	};

	//Fetches appropriate preview and opens the email modal
	openSendEmailModal = () => {
		const { items, type, user, t } = this.props;
		const previewMessage = this.getPreviewMessage();

		this.props
			.dispatch(fetchPreview(items, previewMessage, user.email, [], type))
			.then((result) => {
				this.openSendModal(result);
			})
			.catch((err) => {
				console.log("openSendConfirmModal error", err);
			});
	};

	//Opens send modal
	openSendModal = (preview) => {
		const { items, type, message, user, t } = this.props;
		const markup = this.createMarkup(preview);
		this.props.dispatch(
			showCustomModal(
				<SendEmailModal
					markup={markup}
					sendEmail={this.sendEmail}
					userId={user.id}
					message={message}
					t={t}
				/>,
				"no-class"
			)
		);
	};

	//Sends email to selected recipients
	sendEmail = () => {
		const { items, user, emailLists, message, type, t } = this.props;
		const styledMessage = this.styleMessageText(message);

		//Display warning and close modal if subject is missing
		if (!message.subject) {
			window.alert(t("emails:warning.subject"));
			this.props.dispatch(hideCustomModal());
			return;
		}

		this.props
			.dispatch(
				sendContactEmail(items, styledMessage, user.email, emailLists, type)
			)
			.then((result) => {
				this.props.dispatch(hideCustomModal());
				if (result) {
					this.props.dispatch(
						showSuccessModal(t("informMemberButton.sendSuccess"))
					);
				} else {
					this.props.dispatch(showErrorModal(t("informMemberButton.error")));
				}
			})
			.catch((err) => {
				console.log("sendArtworksEmail error", err);
				this.props.dispatch(hideCustomModal());
				this.props.dispatch(showErrorModal("Generic error"));
			});
	};

	render() {
		const { items, type, message, dispatch, preview, isFetching, error, t } =
			this.props;
		const { previewOpen } = this.state;
		const buttonDisabled = isFetching || error || !items.length > 0;

		return (
			<div className="send-email-button">
				<button
					className="purple-button--wide"
					onClick={() => this.openSendEmailModal()}
					disabled={buttonDisabled}
				>
					{t("informMemberButton.preview2")}
				</button>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		email: { emailPreview: preview, isFetching, error },
	} = state;

	return { preview, isFetching, error };
}

const translated = withNamespaces("components")(SendEmailButton);
export default connect(mapStateToProps)(translated);
