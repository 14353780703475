import {
  initialize,
  stopSubmit,
  startSubmit,
  change,
}                       from 'redux-form'
import diff             from 'object-diff'
import {
  saveAnnouncement
}                       from '../AnnouncementActions'

export function submitAnnouncement(data, initial) {
  return (dispatch, getState) => {
    const changedValues = initial ? diff(initial, data) : data
    changedValues._id = data._id ? data._id : undefined

    dispatch( startSubmit('announcement') )
    return dispatch( saveAnnouncement(changedValues) )
  }
}
