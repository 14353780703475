import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	syncUser,
	populateUser,
	updateUserIsActive,
} from "../../actions/UserActions";
import {
	hideNetworkError,
	hideSuccesModal,
	retryModalAction,
	hideErrorModal,
	showCustomModal,
	hideCustomModal,
} from "../../actions/AppActions";
import { clearArtworks } from "../../actions/ArtworkActions";
import { toggleSocietyPackageSelector } from "../../actions/ModalActions";
import {
	logoutUser,
	syncAuth,
} from "@artworkslab/sharedmodules/src/actions/AuthActions";
import { resetUserEnquiries } from "@artworkslab/sharedmodules/src/actions/EnquiryActions";
import { createWebbLink } from "../../utils/Helpers";
import NavBar from "../../components/NavBar/NavBar";
import NavBarResponsive from "../../components/NavBar/NavBarResponsive";
import Modal from "../../components/Modal/Modal";
import VideoGuide from "../../components/Modal/VideoGuide/VideoGuide";
import Footer from "../../components/Footer/Footer";
import Progressbar from "../../components/Progressbar/Progressbar";
import ArtistProLink from "../../components/ArtworksLite/ArtistProLink";
import Spinner from "react-spinkit";
import i18next from "i18next";
import { withNamespaces } from "react-i18next";
import {
	isPremium,
	isPremiumOrAbove,
	isFree,
	expiresSoon,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";
import {
	fetchArtistNotifications,
	fetchGalleryNotifications,
	resetNotifications,
} from "@artworkslab/sharedmodules/src/actions/NotificationActions";

const MODAL_IMAGE = require("../../../assets/images/icons/modal-icon.png");

class App extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {
			language: i18next.language,
			guideOpen: false,
			premium: false,
		};
	}

	componentWillMount() {
		// Trying to avoid problem with seeing other peoples artworks.
		this.props.dispatch(clearArtworks());

		const {
			authenticated,
			user,
			isAdmin,
			isArtist,
			location: { pathname },
		} = this.props;
		if (
			!authenticated &&
			pathname !== "/" &&
			pathname !== "/register" &&
			pathname !== "/register/artsociety" &&
			pathname !== "/register/gallery" &&
			pathname !== "/register/konstnarshuset" &&
			pathname !== "/for-artists" &&
			pathname !== "/login" &&
			pathname !== "/forgot" &&
			pathname.indexOf("/reset/") < 0
		) {
			this.redirectHome();
		}

		// sync user
		if (authenticated) {
			this.props.dispatch(syncAuth());
		}
		if (authenticated && user && user.id) {
			this.props.dispatch(syncUser(user.id || user._id));
			this.props.dispatch(populateUser(user));
		}
		if (authenticated && user && user.artist && user.role === "artist") {
			//Fetch items for artist notifications
			const artistId = user.artist._id || user.artist;
			this.props.dispatch(fetchArtistNotifications(artistId));
		}
		if (authenticated && user && user.gallery && user.role === "society") {
			//Fetch items for gallery notifications
			const galleryId = user.gallery._id || user.gallery;
			this.props.dispatch(fetchGalleryNotifications(galleryId));
		}

		// const canUseMobile = authenticated && user && (user.role === 'artist' || user.role === 'admin')
		// if ((__DEVELOPMENT__ || __STAGING__) && canUseMobile) {
		//   appGlobals.setMobile()
		// }
	}

	componentWillReceiveProps(nextProps) {
		if (
			this.props.authenticated !== nextProps.authenticated &&
			nextProps.authenticated
		) {
			const { user } = nextProps;
			this.props.dispatch(syncUser(user.id));
			this.props.dispatch(populateUser(user));
		} else if (this.props.authenticated && !nextProps.authenticated) {
			// If user logged in a long time, token stops working, sync fails, then log out user and redirect to login.
			// user exists and is authenticated at first, but after sync user is empty object and authenticated is false.
			this.logout();
		}

		if (
			this.props.authenticated !== nextProps.authenticated &&
			!nextProps.authenticated
		) {
			this.setState({
				premium: false,
			});
		} else if (
			nextProps.isSociety &&
			nextProps.gallery &&
			nextProps.gallery._id
		) {
			// Set premium for society. Can not set to false unless gallery has an id. Prevent flickering in navbar.
			const premium = __USE_TIERS__
				? isPremiumOrAbove(nextProps.gallery)
				: isPremium(nextProps.gallery);
			if (premium !== this.state.premium) {
				this.setState({
					premium,
				});
			}

			// if (isFree(nextProps.gallery)
			//   || (expiresSoon(nextProps.gallery))
			//   &&  !nextProps.gallery.selectedMembership) {
			//   this.openSocietyPackageModal()
			// }
			if (isFree(nextProps.gallery)) {
				this.openSocietyPackageModal();
			}
		} else if (
			nextProps.isArtist &&
			nextProps.artist &&
			this.props.artist._id !== nextProps.artist._id &&
			nextProps.artist._id
		) {
			//Fetch items for artist notifications
			this.props.dispatch(fetchArtistNotifications(nextProps.artist._id));

			//Display welcome modal if user is not PRO
			const premium = isPremium(nextProps.artist);
			if (premium !== this.state.premium) {
				this.setState({
					premium,
				});
			}
			const useResponsive = localStorage.getItem("useResponsive");
			const welcomedBack = localStorage.getItem("welcomedBack");
			if (useResponsive === null) {
				this.showResponsiveModal();
			} else if (!welcomedBack && !premium) {
				localStorage.setItem("welcomedBack", true);
				const content = (
					<div>
						<p>Välkommen tillbaka!</p>
						<p>
							Uppgradera till vårt <i>PRO</i> paket.
						</p>
						<p>
							Läs mer och prova <ArtistProLink children={"här"} />!
						</p>
					</div>
				);
				this.props.dispatch(showCustomModal(content, ""));
			}
		} else if (
			nextProps.gallery &&
			nextProps.gallery._id &&
			this.props.gallery._id !== nextProps.gallery._id
		) {
			//Fetch items for gallery notifications
			this.props.dispatch(fetchGalleryNotifications(nextProps.gallery._id));
		}

		if (this.props.user.id !== nextProps.user.id && !nextProps.user.id) {
			this.props.dispatch(resetUserEnquiries());
			this.props.dispatch(resetNotifications());
		}
	}

	showResponsiveModal = () => {
		const { t } = this.props;
		const content = (
			<div>
				<p>{t("responsive.modal")}</p>
				<div className="buttons">
					<button onClick={() => this.setUseResponsive(true)}>
						{t("responsive.mobile")}
					</button>
					<button onClick={() => this.setUseResponsive(false)}>
						{t("responsive.desktop")}
					</button>
				</div>
			</div>
		);
		this.props.dispatch(showCustomModal(content, "responsive"));
	};

	openSocietyPackageModal = () => {
		const modalHeader = (
			<h3>Vänligen välj ett av nedan två paket för att komma vidare.</h3>
		);
		const modalFooter = (
			<p
				style={{
					fontSize: "16px",
				}}
			>
				Frågor? Maila David på{" "}
				<u>
					<a href="mailto:hey@artworks.io" target="_blank">
						hey@artworks.io
					</a>
				</u>
			</p>
		);
		this.props.dispatch(
			toggleSocietyPackageSelector(true, true, modalHeader, modalFooter)
		);
	};

	setUseResponsive = (value) => {
		appGlobals.setResponsiveDisplay(value);
		this.props.dispatch(hideCustomModal());
	};

	getAppWebblink = () => {
		const { isAuthor, isSociety, isArtist, isAdmin, gallery, artist } =
			this.props;

		// Set webbLink in state, clicking on some links in NavBar makes gallery/artist temporarily an empty object, causes view online link to disappear/reappear. Don't create Link in render.
		// gallery/artist can be both undefined and an empty object
		let webbLink = "";
		if (isAuthor || isSociety) {
			if (gallery && gallery.slug) {
				webbLink = createWebbLink(gallery.slug, "venues");
			}
		} else if (isArtist) {
			if (artist && artist.slug) {
				webbLink = createWebbLink(artist.slug, "artists");
			}
		} else {
			webbLink = createWebbLink();
		}

		return webbLink;
	};

	redirectHome = () => {
		this.props.router.push("/login");
	};

	logout = () => {
		this.props.dispatch(logoutUser());
		this.redirectHome();
	};
	toggleErrorModal = () => {
		this.props.dispatch(hideNetworkError());
	};
	closeSuccessModal = () => {
		this.props.dispatch(hideSuccesModal());
	};
	closeErrorModal = () => {
		this.props.dispatch(hideErrorModal());
	};
	closeRetryModal = (shouldSave = false) => {
		this.props.dispatch(retryModalAction(shouldSave));
	};
	closeCustomModal = () => {
		this.props.dispatch(hideCustomModal());
	};
	toggleGuide = () => {
		this.setState({
			guideOpen: !this.state.guideOpen,
		});
	};

	changeLanguage = (lang) => {
		if (lang === this.state.language) return;
		i18next.changeLanguage(lang);
		// Updating state for subcomponent to rerender.
		this.setState({
			language: lang,
		});
	};

	updateUserActive = (user) => {
		this.props.dispatch(updateUserIsActive(new Date())).then((res) => {
			// Sync auth to put user in localstorage.
			if (res.response) this.props.dispatch(syncAuth());
		});
	};

	render() {
		const {
			authenticated,
			isAdmin,
			isArtist,
			isAuthor,
			isSociety,
			isSwedish,
			user,
			onboarding,
			retryModal,
			gallery,
			notifications,
			routes,
			t,
		} = this.props;
		const {
			networkError,
			error,
			message,
			showSuccessModal,
			showErrorModal,
			modalClass,
			showCustomModal,
			customModalClass,
			showLoadingSpinner,
		} = this.props.app;
		const { guideOpen, premium } = this.state;
		const pathName = this.props.location.pathname;
		const onStartPage = pathName === "/" || pathName === "/for-artists";
		const webbLink = this.getAppWebblink();
		const artistDashboard = pathName === "/dashboard" && isArtist;
		const adminDashboard = pathName === "/dashboard" && isAdmin;

		// update_DO_NOT_REMOVE on children to make them update on language change. Children passes it on to their forms to make those update as well.
		const childrenWithExtraProp = React.Children.map(
			this.props.children,
			(child) => {
				return React.cloneElement(child, {
					update_DO_NOT_REMOVE: i18next.language,
				});
			}
		);

		const NavbarComponent = appGlobals.useResponsive
			? NavBarResponsive
			: NavBar;

		return (
			<div className="App">
				{/* <div class="maintance-message">
					Vi har nyligen åtgärdat en driftstörning. Om du fortfarande upplever problem, vänligen kontakta oss på <a href="mailto:js@artworks.se">js@artworks.se</a>.
				</div> */}
				<NavbarComponent
					handleLogout={this.logout}
					authenticated={authenticated}
					isAdmin={isAdmin}
					isAuthor={isAuthor}
					isSociety={isSociety}
					isArtist={isArtist}
					toggleGuide={this.toggleGuide}
					webbLink={webbLink}
					gallery={gallery}
					user={user}
					pathName={pathName}
					premium={premium}
					updateUserActive={this.updateUserActive}
					xmasCalendar={user.xmasCalendar}
					notifications={notifications}
					route={routes[routes.length - 1]}
					t={t}
				/>
				{authenticated &&
				isAuthor &&
				onboarding &&
				onboarding.showProgressbar &&
				!onStartPage ? (
					<Progressbar {...onboarding} pathname={pathName} />
				) : null}
				<div
					className={
						"App__content " +
						(onStartPage ? "is-on-start " : "") +
						(authenticated ? "is-authenticated" : "")
					}
				>
					{/* this.props.children */}
					{childrenWithExtraProp}
				</div>
				<Footer
					isAuthenticated={authenticated}
					isAdmin={isAdmin}
					isAuthor={isAuthor || isSociety}
					isArtist={isArtist}
					isSociety={isSociety}
					gallery={gallery}
					user={user}
					language={this.state.language}
					changeLanguage={this.changeLanguage}
				/>
				{showSuccessModal ? (
					<Modal
						type="small"
						onCloseClick={this.closeSuccessModal}
						className={modalClass}
					>
						<img src={MODAL_IMAGE} style={{ marginBottom: "1em" }} />
						<p style={{ marginBottom: "1em" }}>{message}</p>
						<div className="buttons">
							<button onClick={this.closeSuccessModal}>
								{t("feedback.continue")}
							</button>
						</div>
					</Modal>
				) : null}
				{showErrorModal ? (
					<Modal
						type="small"
						onCloseClick={this.closeErrorModal}
						className={modalClass}
					>
						{typeof message === "object" ? (
							<div>{message}</div>
						) : (
							<p style={{ marginBottom: "1em" }}>{message}</p>
						)}
						<div className="buttons">
							<button onClick={this.closeErrorModal}>
								{t("feedback.continue")}
							</button>
						</div>
					</Modal>
				) : null}
				{showCustomModal && (
					<Modal
						onCloseClick={this.closeCustomModal}
						className={customModalClass}
					>
						{message}
					</Modal>
				)}
				{networkError ? (
					<Modal type="small" onCloseClick={this.toggleErrorModal}>
						<img src={SMILE_IMAGE} style={{ marginBottom: "1em" }} />
						<p style={{ marginBottom: "1em" }}>{error}</p>
						<div className="buttons">
							<button onClick={this.toggleErrorModal}>
								{t("feedback.continue")}
							</button>
						</div>
					</Modal>
				) : null}
				{retryModal.show && (
					<Modal
						type="small"
						onCloseClick={() => this.closeRetryModal(false)}
						className={modalClass}
					>
						<p style={{ marginBottom: "1em" }}>{message}</p>
						<div className="buttons">
							<button onClick={() => this.closeRetryModal(true)}>
								{isSwedish ? "Ja" : "Yes"}
							</button>
							<button
								onClick={() => this.closeRetryModal(false)}
								style={{ marginLeft: "20px" }}
							>
								{isSwedish ? "Nej" : "No"}
							</button>
						</div>
						{/*<p style={{marginBottom: "3em"}}>{ retryModal.text }</p>*/}
						{retryModal.text}
					</Modal>
				)}
				{guideOpen && <VideoGuide close={this.toggleGuide} />}
				{showLoadingSpinner && !artistDashboard && !adminDashboard && (
					<div className="App__loader">
						<Spinner spinnerName="cube-grid" noFadeIn />
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		auth: { user, authenticated },
		user: { onboarding, valid },
		app: { retryModal },
		notifications: { notifications, isFetching: fetchingNotifications },
		gallery: { gallery },
		show,
		artwork,
		post,
		artist: { artist },
		dashboard,
		app,
	} = state;
	const isAdmin = !!(user.role === "admin");
	const isArtist = !!(user.role === "artist");
	const isAuthor = !!(user.role === "author");
	const isSociety = !!(user.role === "society");
	const isSwedish = i18next.language === "sv";
	return {
		user,
		authenticated,
		isAdmin,
		isArtist,
		isAuthor,
		isSociety,
		isSwedish,
		onboarding,
		valid,
		retryModal,
		gallery,
		show,
		artwork,
		post,
		artist,
		dashboard,
		notifications,
		app,
	};
}

const translated = withNamespaces("common")(App);
export default connect(mapStateToProps)(withRouter(translated));

// This is master.
