import {
	TOGGLE_EXPANDED, DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE,
	GALLERY_STATS_COMPARE_REQUEST, GALLERY_STATS_COMPARE_SUCCESS, GALLERY_STATS_COMPARE_FAILURE,
} from '../../actions/DashboardActions/SummaryActions'

export function summaryReducer(state = {
		isFetching: false,
		authenticated: false,
		data: [],
    expanded: true,
		galleryStats: [],
		galleryStatsCompare: [],
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED: {
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		}
		case DATA_REQUEST: {
			return Object.assign({}, state, {
				isFetching: true
			});
		}
		case DATA_SUCCESS: {
			return Object.assign({}, state, {
				isFetching: false,
				data: action.response.data
			});
		}
		// fall through
		case GALLERY_STATS_COMPARE_FAILURE:
		case DATA_FAILURE: {
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			});
		}
		case GALLERY_STATS_COMPARE_REQUEST: {
			return Object.assign({}, state, {
				galleryStats: [],
				galleryStatsCompare: [],
				isFetching: true
			});
		}
		case GALLERY_STATS_COMPARE_SUCCESS: {
			return Object.assign({}, state, {
				galleryStats: action.response.stats,
				galleryStatsCompare: action.response.compareStats,
				isFetching: false,
			});
		}
		default:
			return state
	}
}
