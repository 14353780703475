import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Field,
	Fields,
	FormSection,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { submit } from "../../actions/FormActions/GalleryFormActions";
import i18n from "i18next";
import { withNamespaces } from "react-i18next";
import TextInput from "../../components/FormInput/TextInput";
import TextDisplay from "../../components/FormInput/TextDisplay";
import TextArea from "../../components/FormInput/TextArea";
import CheckBox from "../../components/FormInput/CheckBox";
import Select from "../../components/FormInput/Select";
import ImageUpload from "../../components/FormInput/ImageUpload";
import ImageUploadRotate from "../../components/FormInput/ImageUploadRotate";
import LogoUpload from "../../components/FormInput/LogoUpload";
import OpeningHours from "../../components/FormComponents/OpeningHours/OpeningHours";
import TicketPicker from "../../components/FormComponents/TicketPicker/TicketPicker";
import ColorPicker from "../../components/FormComponents/ColorPicker/ColorPicker";
import RelatedPicker from "../../components/FormComponents/RelatedPicker/RelatedPicker";
import CreateRelated from "../../components/FormComponents/RelatedPicker/CreateRelated";
import ArtworkPicker from "../../components/FormComponents/ArtworkPicker/ArtworkPicker";
import SingleDateTimePicker from "../../components/FormComponents/DateRange/SingleDateTimePicker";
import FormEditor from "../../components/FormComponents/FormEditor/FormEditor";
import Tooltip from "../../components/Tooltip/Tooltip";
import ExpandButton from "../../components/DashboardComponents/ExpandButton/ExpandButton";
import RadioButtons from "../../components/FormInput/RadioButtons";
// import FormMeta                         from '../../components/Meta/FormMeta'
import {
	validatePhone,
	validateEmail,
	validateWebAddress,
	validateOrgNo,
	validateVAT,
} from "../../utils/Validators";
import { CENTER_IMAGE_OPTIONS } from "../../utils/values";
import {
	showPremiumUpgradeModal,
	showPremiumFunctionalityModal,
} from "../../utils/Dispatcher";
import { imageRotationActivated, createWebbLink } from "../../utils/Helpers";

const venueTypes = (lang) => {
	if (lang === "sv") {
		return [
			{ name: "Galleri", value: "Gallery" },
			{ name: "Museum", value: "Museum" },
			{ name: "Konsthall", value: "Art space" },
			{ name: "Offentlig konst", value: "Public art" },
			{ name: "Pop-up", value: "Pop-up" },
			{ name: "Konstmässa", value: "Art fair" },
			{ name: "Företag", value: "Business" },
			{ name: "Biennal", value: "Biennal" },
			{ name: "Restaurang", value: "Restaurant" },
			{ name: "Skulpturpark", value: "Sculpture park" },
			{ name: "Konstförening", value: "ArtSociety" },
			{ name: "Konstnärsdrivet galleri/förening", value: "ArtistSociety" },
			{ name: "Konstrunda", value: "Art walk" },
			{ name: "Konstskola", value: "Art institution" },
			// { name: "Sponsored", value: "Sponsored" },
			// { name: "Corporate", value: "Corporate" },
			// { name: "Institution", value: "Institution" },
			// { name: "Association", value: "Association" },
		];
	} else {
		return [
			{ name: "Gallery", value: "Gallery" },
			{ name: "Museum", value: "Museum" },
			{ name: "Art space", value: "Art space" },
			{ name: "Public art", value: "Public art" },
			{ name: "Pop-up", value: "Pop-up" },
			{ name: "Art fair", value: "Art fair" },
			{ name: "Business", value: "Business" },
			{ name: "Biennal", value: "Biennal" },
			{ name: "Restaurant", value: "Restaurant" },
			{ name: "Sculpture park", value: "Sculpture park" },
			{ name: "Art society", value: "ArtSociety" },
			{ name: "Artist-run gallery/society", value: "ArtistSociety" },
			{ name: "Art walk", value: "Art walk" },
			{ name: "Art institution", value: "Art institution" },
			// { name: "Sponsored", value: "Sponsored" },
			// { name: "Corporate", value: "Corporate" },
			// { name: "Institution", value: "Institution" },
			// { name: "Association", value: "Association" },
		];
	}
};

const MEMBERSHIPS = [
	{ name: "Free", value: "free" },
	{ name: "Bas", value: "basic" },
	{ name: "Standard", value: "standard" },
	{ name: "Premium", value: "premium" },
];

const UPDATE_MEMBERSHIPS = [
	{ name: "Bas", value: "basic" },
	{ name: "Standard", value: "standard" },
	{ name: "Premium", value: "premium" },
	{ name: "Terminate", value: "terminate" },
];

const TOOL_TIPS = {
	colorPicker: {
		text: "Choose a color you want the image to be associated with",
		popupPos: "left",
	},
	vatNumber: {
		text: "Your VAT number. If you have any problems, please contact artworks: hey@artworks.io",
		popupPos: "left",
	},
};

const VISIBILITY_OPTIONS = [
	{ value: "restricted", name: "Restricted" },
	{ value: "web", name: "Webb only" },
	{ value: "app", name: "App only" },
	{ value: "all", name: "Everywhere" },
	{ value: "notlisted", name: "Not listed" },
	{ value: "hidden", name: "Hidden" },
];

class GalleryForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hoursExpanded: false,
			unlocksExpanded: false,
			editorChanged: false,
		};
	}

	updateChild = () => {
		if (this.editor) this.editor.updateContentFromParent();
		this.setState({
			editorChanged: false,
		});
	};

	editorChange = () => {
		if (!this.state.editorChanged) {
			this.setState({
				editorChanged: true,
			});
		}
	};

	toggleExpanded = (name) => {
		this.setState({
			[name]: !this.state[name],
		});
	};

	normalizeInstagram = (value) => {
		if (!value) return value;
		if (value.charAt(0) === "@") return value.slice(1);
		return value;
	};

	openUpgradePopup = (e) => {
		e.preventDefault();
		if (__USE_TIERS__) {
			showPremiumUpgradeModal(this.props.dispatch, "Premium");
		} else {
			showPremiumFunctionalityModal(this.props.dispatch, false);
		}
	};

	render() {
		const {
			handleSubmit,
			pristine,
			submitting,
			isAdmin,
			isAssociation,
			slug,
			standardOrAbove,
			isNewForm,
			name,
			selections,
			reqUpdateId,
			useNewEditor,
			t,
		} = this.props;
		const { editorChanged, hoursExpanded, unlocksExpanded } = this.state;
		const vatToolTip = {
			text: t("single.toolTips.vatNumberTip"),
			popupPos: "left",
		};
		const colorToolTip = {
			text: t("single.toolTips.colorPickerTip"),
			popupPos: "left",
		};

		const typesOfVenue = venueTypes(i18n.language);

		const expandButtonStyle = {
			position: "absolute",
			right: "40px",
			top: 0,
		};

		const useUploadWithRotate = imageRotationActivated();

		const selectionOptions = Object.keys(selections).map((key) => {
			const selection = selections[key];
			return {
				name: selection.title,
				value: selection._id,
			};
		});

		// Conditional "required" on certain fields, to make sure hidden fields aren't required. A just-in-case thing.
		return (
			<form onSubmit={handleSubmit} className="Gallery__form Form">
				{/* !isAdmin ?
          <FormMeta type="gallery"/>
        : null */}
				<legend>{t("single.generalInformation")}</legend>
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="name"
							component={TextInput}
							placeholder={
								isAssociation
									? t("single.artSocietyName")
									: t("single.venueName")
							}
							required
						/>
					</div>
					<div className="input-holder">
						<Field
							name="contactPerson"
							component={TextInput}
							placeholder={t("single.contactPerson")}
						/>
					</div>
					{isAssociation && (
						<div className="input-holder">
							<Field
								name="displayName"
								component={TextInput}
								placeholder={t("single.displayName")}
								required={false}
							/>
						</div>
					)}
					<div className="input-holder">
						<Field
							name="email"
							component={TextInput}
							placeholder={t("common:email")}
							validate={validateEmail}
							required
						/>
					</div>
					<div className="input-holder">
						<Field
							name="phone"
							component={TextInput}
							placeholder={t("common:phoneNumber")}
							validate={validatePhone}
							required
						/>
					</div>
					<div className="input-holder">
						<Field
							name="webSite"
							component={TextInput}
							placeholder={t("single.yourWebsite")}
							validate={validateWebAddress}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="social.facebook"
							component={TextInput}
							placeholder={t("single.facebook")}
						/>
					</div>
					<div className="input-holder">
						<Field
							name="social.instagram"
							component={TextInput}
							placeholder={t("single.instagram")}
							normalize={this.normalizeInstagram}
						/>
					</div>

					{isAssociation && <legend>{t("single.aboutArtSociety")}</legend>}
					{/*Replaced by FormEditor*/}
					{!useNewEditor && (
						<div className="input-holder input-holder--full">
							<Field
								name="about"
								component={TextArea}
								placeholder={
									isAssociation
										? t("single.aboutArtSocietyPlaceholder")
										: t("single.aboutVenue")
								}
								required
							/>
						</div>
					)}
					{!useNewEditor && isAdmin && <legend>New editor (Admin only)</legend>}
					{(useNewEditor || (!useNewEditor && isAdmin)) && (
						<div className="input-holder input-holder--full">
							<Fields
								names={["draftContentStateAbout", "htmlContentAbout"]}
								component={FormEditor}
								onRef={(ref) => (this.editor = ref)}
								onEditorChange={this.editorChange}
							/>
						</div>
					)}
				</div>

				{/*Profile image*/}
				<div className="fieldset">
					<legend>{t("single.profileImage")}</legend>
					<Fields
						names={[
							"featuredImage.src",
							"uploadedImage.URI",
							"uploadedImage.fileName",
							"uploadedImage.contentType",
						]}
						component={useUploadWithRotate ? ImageUploadRotate : ImageUpload}
						profileImage={true}
						canRemoveImage={true}
					/>
					{isAdmin && (
						<Field name="featuredImage.color" component={ColorPicker}>
							<Tooltip data={colorToolTip} />
						</Field>
					)}
				</div>

				{isAdmin && (
					<div className="fieldset">
						<div className="input-holder">
							<legend className="legend-nomargin">
								{t("single.imageCenterPosition")}
							</legend>
							<Field
								name="featuredImage.center"
								options={CENTER_IMAGE_OPTIONS}
								component={Select}
							/>
						</div>
					</div>
				)}

				{isAssociation && (
					<div className="fieldset">
						<legend>{t("single.logo")}</legend>
						<Fields
							names={[
								"logo.src",
								"uploadedLogo.URI",
								"uploadedLogo.fileName",
								"uploadedLogo.contentType",
							]}
							component={LogoUpload}
							update_DO_NOT_REMOVE={this.props.updateOnLangChange}
						/>
					</div>
				)}

				{isAdmin && (
					<div className="fieldset-block" style={{ position: "relative" }}>
						<legend className="legend-nomargin">
							{isAssociation
								? t("single.openingHoursForShows")
								: t("single.openingHours")}
						</legend>
						<ExpandButton
							expanded={hoursExpanded}
							click={() => this.toggleExpanded("hoursExpanded")}
							style={expandButtonStyle}
						/>
						{hoursExpanded && (
							<Field
								name="openingHoursHidden"
								component={CheckBox}
								checkboxLabel={t("single.hideOpeningHours")}
							/>
						)}
						{hoursExpanded && (
							<FormSection name="openingHours">
								<OpeningHours />
							</FormSection>
						)}
					</div>
				)}

				{(!isAssociation || isAdmin) && (
					<div className="fieldset">
						<legend>{t("single.typeOfVenue")}</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="venueType"
								options={typesOfVenue}
								component={Select}
							/>
						</div>
					</div>
				)}
				{isAdmin && (
					<div className="fieldset">
						<legend>
							Artists adding this gallery to their education/membership will be
							added to this selection
						</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="artistSelection"
								options={selectionOptions}
								component={Select}
							/>
						</div>
					</div>
				)}
				{!isAssociation && (
					<Fields
						names={[
							"isMuseum",
							"museumPage",
							"entranceFee",
							"entranceFeeCurrency",
							"entranceFeeType",
						]}
						component={TicketPicker}
					/>
				)}
				{!isAssociation && (
					<div
						className="fieldset-block fieldset-block--nopadding"
						style={{ marginBottom: "30px" }}
					>
						<legend>{t("common:mentions.linksToMentions")}</legend>
						<div className="fieldset">
							<div className="input-holder input-holder--full">
								<Field name="related" component={RelatedPicker} />
							</div>
						</div>
						<legend>{t("common:mentions.addNewMention")}</legend>
						<CreateRelated submitting={submitting} />
					</div>
				)}

				{!isNewForm && !isAssociation && (
					<div className="fieldset-block fieldset-block--nopadding">
						<Field
							name="selectedArtworks"
							component={ArtworkPicker}
							defaultValue={[]}
							header={t("single.selectAvailableWorks")}
							upload={false}
							onlyForSale={true}
						/>
					</div>
				)}

				{/*Select theme*/}
				{isAssociation && (
					<div
						className="input-holder"
						onClick={standardOrAbove ? null : this.openUpgradePopup}
						style={{ marginBottom: "20px" }}
					>
						<legend className="legend-nomargin">
							{t("common:account.selectLayout")}
						</legend>
						<div style={{ opacity: standardOrAbove ? 1 : 0.5 }}>
							<Field
								name="layout"
								component={RadioButtons}
								options={[
									{ value: 0, label: t("common:account.themeA") },
									{ value: 1, label: t("common:account.themeB") },
									{ value: 2, label: t("common:account.themeC") },
								]}
								disabled={!standardOrAbove}
								dispatch={this.props.dispatch}
							/>
						</div>
						{/* slug &&
              <button className={ block('purple-button--wide')({mod: 'webb'}) } type="button" onClick={() => window.open(createWebbLink(slug, 'venues'), '_blank')}>{t('common:preview')}</button>
            */}
					</div>
				)}

				{isAssociation && (
					<div
						className="input-holder input-holder--full"
						style={{ margin: "40px 0 20px 0" }}
					>
						<Field
							name="inviteFriendActivated"
							component={CheckBox}
							checkboxLabel={t("single.activateInviteFriend")}
						/>
					</div>
				)}

				{/*Editor rating and gallery visibility*/}
				{isAdmin && (
					<div className="fieldset">
						<legend>Extra (admin only)</legend>
						<div className="input-holder">
							<Field
								name="editorRating"
								component={TextInput}
								placeholder="Editor rating(1-10)"
							/>
						</div>
						<legend>Visibility</legend>
						<div className="input-holder">
							<Field
								name="visibility"
								component={Select}
								options={VISIBILITY_OPTIONS}
							/>
						</div>
					</div>
				)}

				{/*Display and edit gallery membership level*/}
				{isAdmin && isAssociation && (
					<div className="fieldset">
						<legend>{t("single.currentMembership")} (Admin)</legend>
						<div className="input-holder">
							<Field
								name="billing.membershipLevel"
								options={MEMBERSHIPS}
								component={Select}
							/>
						</div>
						<div className="input-holder">
							<Field
								name="collectEmails"
								component={CheckBox}
								checkboxLabel={t("single.collectEmails")}
							/>
						</div>
					</div>
				)}

				{/*Indication for when the gallery membership expires*/}
				{isAdmin && isAssociation && (
					<div className="fieldset-block">
						<div className="fieldset">
							<legend className="legend-nomargin">
								{t("single.nextInvoiceDate")} (Admin)
							</legend>
							<Field
								name="billing.membershipEnds"
								component={SingleDateTimePicker}
								disabled={false}
							/>
						</div>
					</div>
				)}

				{/*Handle gallery update requests. Only displayed if a request has been made*/}
				{isAdmin && isAssociation && reqUpdateId && (
					<div className="fieldset-block fieldset-block--nopadding">
						<div className="fieldset">
							<legend>{t("single.updateMembership.update")} (Admin)</legend>
							<div className="input-holder">
								<legend className="legend-nomargin">
									{t("single.updateMembership.currentLevel")}
								</legend>
								<Field
									name="updateMembership.currentMembershipLevel"
									options={MEMBERSHIPS}
									component={Select}
									disabled
								/>
							</div>
							<div className="input-holder">
								<legend className="legend-nomargin">
									{t("single.updateMembership.requestedLevel")}
								</legend>
								<Field
									name="updateMembership.newMembershipLevel"
									options={UPDATE_MEMBERSHIPS}
									component={Select}
								/>
							</div>
						</div>
						<legend>{t("single.updateMembership.slackReminder")}</legend>
						<div className="fieldset">
							<div className="input-holder">
								<Field
									name="updateMembership.sendReminders"
									component={SingleDateTimePicker}
								/>
							</div>
							<div className="input-holder">
								<Field
									name="updateMembership.scheduleUpdate"
									component={CheckBox}
									checkboxLabel={t("single.updateMembership.schedule")}
								/>
							</div>
						</div>
					</div>
				)}

				{isAdmin && (
					<div className="fieldset-block" style={{ position: "relative" }}>
						<legend className="legend-nomargin">
							{t("single.features.unlockFeatures")}
						</legend>
						<ExpandButton
							expanded={unlocksExpanded}
							click={() => this.toggleExpanded("unlocksExpanded")}
							style={expandButtonStyle}
						/>
						{unlocksExpanded && (
							<FormSection name="features">
								<legend className="legend-nomargin">
									{t("single.features.description")}
								</legend>
								<Field
									name="rsvpUnlocked"
									component={CheckBox}
									checkboxLabel={t("single.features.unlockRsvp")}
								/>
								<Field
									name="voteUnlocked"
									component={CheckBox}
									checkboxLabel={t("single.features.unlockVote")}
								/>
								<Field
									name="memberListsImpExpUnlocked"
									component={CheckBox}
									checkboxLabel={t("single.features.unlockMemberListsImpExp")}
								/>
								<Field
									name="mailReportUnlocked"
									component={CheckBox}
									checkboxLabel={t("single.features.unlockMailReport")}
								/>
								<Field
									name="automaticRemindersUnlocked"
									component={CheckBox}
									checkboxLabel={t("single.features.unlockAutomaticReminders")}
								/>
							</FormSection>
						)}
					</div>
				)}
				{/* isAdmin && isAssociation &&
          <div className="fieldset">
            <div className="input-holder input-holder--full">
              <Field
                name="billing.premiumUnlocked"
                component={ CheckBox }
                checkboxLabel={t('single.unlockPremium')}
                />
            </div>
          </div>
        */}

				{isAdmin && isAssociation && (__DEVELOPMENT__ || __STAGING__) && (
					<div className="fieldset">
						<legend>{t("single.demoEnvironment")}</legend>
						<div className="input-holder input-holder--full">
							<Field
								name="demoEnvironment"
								component={CheckBox}
								checkboxLabel={t("single.sendStagingEmails")}
							/>
						</div>
					</div>
				)}

				{/*Save button*/}
				{/*<div className="fieldset">
          <div className="input-holder">
            <Field
              name="slug"
              itemType="gallery"
              component={ TextDisplay }
              isAdmin={ isAdmin }
            />
          </div>
          <div className="fixed-save-button mobile-only">
            <button className="purple-button--wide" type="submit" onClick={ this.updateChild } disabled={ (pristine || submitting) && !editorChanged}>{t('common:save')}</button>
          </div>
        </div>*/}
				<div className="fieldset">
					<div className="input-holder">
						<Field
							name="slug"
							itemType="gallery"
							component={TextDisplay}
							isAdmin={isAdmin}
						/>
					</div>
				</div>
				<div className="sticky-save-button">
					<button
						className="purple-button--wide"
						type="submit"
						onClick={this.updateChild}
						disabled={(pristine || submitting) && !editorChanged}
					>
						{t("common:save")}
					</button>
				</div>
			</form>
		);
	}
}

GalleryForm = reduxForm({
	form: "gallery",
})(GalleryForm);

const selector = formValueSelector("gallery");
const mapStateToProps = (state) => {
	const {
		selection: { selections },
	} = state;

	const about = selector(state, "about");
	const slug = selector(state, "slug");
	const htmlContentAbout = selector(state, "htmlContentAbout");
	const useNewEditor = htmlContentAbout || !about ? true : false;

	return { selections, useNewEditor, slug };
};

const translated = withNamespaces("gallery")(GalleryForm);
export default connect(mapStateToProps)(translated);
