import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import { fetchContactLists } from "@artworkslab/sharedmodules/src/actions/ContactActions";

class SelectContacts extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { userId } = this.props;
		if (userId) {
			this.props.dispatch(fetchContactLists(false, userId));
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.userId !== nextProps.userId && nextProps.userId) {
			this.props.dispatch(fetchContactLists(false, nextProps.userId));
		}
	}

	//Creates Select-options from fetched contactLists
	createOptions = (contactLists) => {
		let options = [];
		contactLists.forEach((list) => {
			options.push({ label: list.name, value: list._id });
		});
		return options;
	};

	//Sets the selected contactlists in parent state
	setContactlists = (selections) => {
		const { emailLists } = this.props;
		let listIds = [];
		selections.forEach((selection) => {
			const listId = selection.value;
			listIds.push(listId);
		});
		this.props.setEmailLists(listIds);
	};

	render() {
		const { contactLists, emailLists, isFetching, t } = this.props;
		const isResponsive = appGlobals.useResponsive;

		let options = [];
		if (contactLists.length > 0) {
			options = this.createOptions(contactLists);
		}

		return (
			<div
				className={
					"SelectContacts responsive-form" +
					(isResponsive ? " is-responsive" : "")
				}
			>
				{options.length > 0 && (
					<Select
						value={emailLists}
						options={options}
						onChange={this.setContactlists}
						isLoading={isFetching}
						placeholder={t("artistEmail.recipients")}
						multi={true}
						className="no-clear-all"
					/>
				)}
				<Link
					className="purple-button--wide purple-button--wide--mod_link"
					to="/contactlists"
				>
					{t("contactlist:createNewList")}
				</Link>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		contactList: { contactLists, isFetching },
	} = state;
	return { contactLists, isFetching };
}

const translated = withNamespaces("emails")(SelectContacts);
export default connect(mapStateToProps)(translated);
