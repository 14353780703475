import {
  LOAD,
  SHOW_SHOW_MODAL,
  HIDE_SHOW_MODAL,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  SHOW_ERROR,
  HIDE_ERROR
}                         from '../../actions/FormActions/ShowFormActions'
import {
  SHOWS_REQUEST,
  SHOW_REQUEST,
  SHOW_SAVE_SUCCESS,
  SHOW_SAVE_FAILED,
}                         from '../../actions/ShowActions'

export function showFormReducer(state = {
  showSuccess: false,
  showError: false,
  showShowModal: false,
  error: null
}, action) {
  switch (action.type) {
    case LOAD:
      return Object.assign({}, state, {
        data: action.data
      })
    case SHOW_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false
      })
    case SHOWS_REQUEST:
      return Object.assign({}, state, {
        showSuccess: false,
        showError: false
      })
    case SHOW_SAVE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true,
        showShowModal: false
      })
    case SHOW_SAVE_FAILED:
      return Object.assign({}, state, {
        showError: true,
        error: action.message
      })
    case SHOW_SHOW_MODAL:
      return Object.assign({}, state, {
        showShowModal: true
      })
    case HIDE_SHOW_MODAL:
      return Object.assign({}, state, {
        showShowModal: false
      })
    case HIDE_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        showError: false,
        error: null
      })
    default:
      return state
  }
}
