import React, { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
	togglePopularOnAw,
	fetchData,
} from "../../../actions/DashboardActions/PopularOnAwActions";
import ExpandButton from "../ExpandButton/ExpandButton";
import ItemStats from "../ItemStats/ItemStats";

class PopularOnAw extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.dispatch(fetchData());
	}

	render() {
		const { expanded, artwork } = this.props;
		const artist =
			artwork.artist && artwork.artist.name ? artwork.artist.name : "Unknown";
		const stats = [
			{ title: "Artwork", value: artwork.title, name: artist },
			{ title: "Likes", value: artwork.likes },
			//{ title: 'Visits', value: artwork.visits }, // Don't use this since visits don't increment as it should.
		];

		return (
			<div className="PopularOnAw">
				<ExpandButton
					expanded={expanded}
					click={() => this.props.dispatch(togglePopularOnAw())}
				/>
				<h4 className="underlined">The most popular artwork on Artworks</h4>
				<TransitionGroup>
					<CSSTransition
						classNames="expand"
						timeout={{ enter: 500, exit: 300 }}
					>
						{expanded && artwork.images ? (
							<div className="PopularOnAw__Content">
								<ItemStats
									image={{ backgroundImage: `url(${artwork.images[0].src})` }}
									stats={stats}
								/>
							</div>
						) : null}
					</CSSTransition>
				</TransitionGroup>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { popularOnAw } = state;
	const { expanded, artwork } = popularOnAw;
	return { expanded, artwork };
}

export default connect(mapStateToProps)(PopularOnAw);
