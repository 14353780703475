import { saveArtworkData }  from './FormActions/ArtworkFormActions'
import { saveArtistData }   from './FormActions/ArtistFormActions'
import { saveGalleryData }  from './FormActions/GalleryFormActions'

export const NETWORK_ERROR = 'NETWORK_ERROR'
export const HIDE_NETWORK_ERROR = 'HIDE_NETWORK_ERROR'

export const SHOW_LOADING_SPINNER = 'artworksapp/app/SHOW_LOADING_SPINNER'
export const HIDE_LOADING_SPINNER = 'artworksapp/app/HIDE_LOADING_SPINNER'

export const SHOW_SUCCESS_MODAL = 'artworksapp/app/SHOW_SUCCESS_MODAL'
export const HIDE_SUCCESS_MODAL = 'artworksapp/app/HIDE_SUCCESS_MODAL'

export const SHOW_RETRY_MODAL = 'artworksapp/app/SHOW_RETRY_MODAL'
export const HIDE_RETRY_MODAL = 'artworksapp/app/HIDE_RETRY_MODAL'

export const SHOW_ERROR_MODAL = 'artworksapp/app/SHOW_ERROR_MODAL'
export const HIDE_ERROR_MODAL = 'artworksapp/app/HIDE_ERROR_MODAL'

export const SHOW_CUSTOM_MODAL = 'artworksapp/app/SHOW_CUSTOM_MODAL'
export const HIDE_CUSTOM_MODAL = 'artworksapp/app/HIDE_CUSTOM_MODAL'

export function hideNetworkError(){
  return {
    type: HIDE_NETWORK_ERROR
  }
}

export function hideLoadingSpinner(){
  return {
    type: HIDE_LOADING_SPINNER
  }
}

export function showLoadingSpinner(){
  return {
    type: SHOW_LOADING_SPINNER
  }
}

export function hideErrorModal(){
  return {
    type: HIDE_ERROR_MODAL
  }
}

export function showErrorModal(message){
  return {
    type: SHOW_ERROR_MODAL,
    message: message,
    className: 'responsive-modal',
  }
}

export function hideSuccesModal(){
  return {
    type: HIDE_SUCCESS_MODAL
  }
}

export function showSuccessModal(message){
  return {
    type: SHOW_SUCCESS_MODAL,
    message: message,
    className: 'responsive-modal',
  }
}

export function showRetryModal(data, type, message, text){
  return {
    type: SHOW_RETRY_MODAL,
    itemData: data,
    itemType: type,
    message: message,
    text: text,
    className: 'responsive-modal',
  }
}

export function retryModalAction(shouldSave){
  return (dispatch, getState) => {
    if (!shouldSave) return dispatch( hideRetryModal() )

    const { app: { retryModal: { itemData, itemType } } } = getState()

    switch(itemType) {
      case 'artwork':
        dispatch( saveArtworkData(itemData) )
        break
      case 'artist':
        dispatch( saveArtistData(itemData) )
        break
      case 'gallery':
        dispatch( saveGalleryData(itemData) )
        break
      default:
        //
    }

    return dispatch( hideRetryModal() )
  }
}

export function hideRetryModal(){
  return {
    type: HIDE_RETRY_MODAL,
    className: '',
  }
}

export function showCustomModal(message, className = '') {
  return {
    type: SHOW_CUSTOM_MODAL,
    message,
    className: className ? className : 'responsive-modal',
  }
}

export function hideCustomModal(){
  return {
    type: HIDE_CUSTOM_MODAL,
  }
}
