import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withNamespaces } from "react-i18next";
import {
	getArtistOptions,
	resetArtistOptions,
} from "../../../actions/PickersActions";
import {
	showArtistModal,
	hideArtistModal,
} from "../../../actions/FormActions/ArtistFormActions";

class ArtistPicker extends Component {
	static propTypes = {};
	static defaultProps = {
		multi: true,
		placeholder: "Select artists...",
		canAdd: true,
		disabled: false,
	};
	constructor(props) {
		super(props);
	}

	componentWillReceiveProps(nextProps) {
		const { multi, input } = this.props;
		if (
			nextProps.artist.artist &&
			this.props.artist.artist._id != nextProps.artist.artist._id
		) {
			let selectedArtists = [];
			if (multi && Array.isArray(input.value)) {
				// Check so we don't add empty object to list.
				if (input.value[0] && input.value[0]._id) {
					selectedArtists.push.apply(selectedArtists, input.value);
				}
				selectedArtists.push(nextProps.artist.artist);
			} else {
				selectedArtists.push(nextProps.artist.artist);
			}
			// Staging wants to add your own artist every time you reload the page.
			selectedArtists = this.removeDuplicates(selectedArtists);

			const selection = multi ? selectedArtists : selectedArtists[0];
			this.props.dispatch(hideArtistModal());
			this.props.input.onChange(selection);
			this.props.input.onBlur(selection);
			this.props.dispatch(resetArtistOptions(selection));
		}
	}

	removeDuplicates = (artists) => {
		const filtered = [];
		const added = [];
		artists.forEach((artist) => {
			if (artist._id && added.indexOf(artist._id) < 0) {
				filtered.push(artist);
				added.push(artist._id);
			}
		});

		return filtered;
	};

	selectArtists = (selections) => {
		const { multi } = this.props;
		const artists = [];
		if (multi) {
			selections.forEach((selection) => {
				if (!selection.name) {
					const artist = this.props.artist.artists[selection];
					artists.push(artist);
				} else {
					artists.push(selection);
				}
			});
		} else {
			artists.push(selections);
		}
		//Update parent, state
		const selectedArtists = multi ? [].concat(artists) : artists[0];
		this.props.input.onChange(selectedArtists);
		this.props.input.onBlur(selectedArtists);
		this.props.dispatch(resetArtistOptions(selectedArtists));
	};

	toggleArtistModal = (e) => {
		e.preventDefault();
		this.props.dispatch(showArtistModal());
	};

	searchArtist = (searchString) => {
		this.props.dispatch(getArtistOptions(searchString));
	};

	onInputChange = (text) => {
		// console.log('input change', text);
		this.searchArtist(text);
	};

	render() {
		// TODO: Show error.!
		const {
			multi,
			placeholder,
			input: { value },
			meta: { error },
			canAdd,
			disabled,
			isFetching,
			options,
			noPadding,
			fullWidth,
			isAdmin,
			searchForTagged = false,
			isResponsive = false,
			t,
		} = this.props;

		const green = this.props.green ? true : false;
		const required = this.props.required ? true : false;
		const empty = this.props.input.value.length === 0;
		const valid =
			this.props.meta.valid && this.props.meta.touched && !empty ? true : false;
		let selectHolderClass = "input-holder";
		if (noPadding) selectHolderClass += " input-holder--nopadding";
		if (fullWidth) selectHolderClass += " input-holder--full";
		let selectClass = "";
		if (green && !empty) selectClass = "select-green";
		let inputHolderClass = "input-holder";
		let buttonClass = "purple-button--wide purple-button--wide--mod_picker";

		if (isResponsive) {
			selectHolderClass = "input-holder-r";
			selectClass = "input-holder-r input-holder-r--full";
			inputHolderClass = "input-holder-r";
			buttonClass += " responsive-button";
		}

		if (error) {
			console.log("Artist error:", error);
		}

		return (
			<div
				className={
					"ArtistPicker " +
					(isResponsive ? "fieldset-r" : "fieldset") +
					(required ? " is-required" : "") +
					(valid ? " is-valid" : "")
				}
			>
				<div className={selectHolderClass}>
					<Select
						value={value}
						options={options}
						onChange={this.selectArtists}
						onInputChange={this.onInputChange}
						disabled={disabled}
						valueKey="_id"
						labelKey="name"
						backspaceToRemoveMessage=""
						backspaceRemoves={false}
						isLoading={isFetching}
						placeholder={
							searchForTagged
								? t("artistPicker.placeholderTagged")
								: t("artistPicker.placeholder")
						}
						multi={multi}
						className={selectClass}
					/>
					{error ? <span className="ArtistPicker__error">{error}</span> : null}
				</div>
				{canAdd && (
					<div className={inputHolderClass}>
						<button
							className={buttonClass}
							type="button"
							onClick={(e) => {
								this.toggleArtistModal(e);
							}}
						>
							{t("artistPicker.addNewArtist")}
						</button>
					</div>
				)}
				{canAdd && value._id && isAdmin && (
					<div
						className={inputHolderClass + " mobile-padding-top"}
						style={{
							marginBottom: "20px",
						}}
					>
						<Link to={`/artists/${value._id}`} activeClassName="active">
							{t("artistPicker.viewArtist")}
						</Link>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		artist,
		pickers: {
			artistPicker: { isFetching, options },
		},
		auth: { user },
	} = state;
	const isAdmin = state.auth.isAdmin || user.role === "admin";
	return { artist, isFetching, options, isAdmin };
}

const translated = withNamespaces("components")(ArtistPicker);
export default connect(mapStateToProps)(translated);
