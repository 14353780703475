import Api from "@artworkslab/sharedmodules/src/Api";
import { CALL_API } from "../utils/api";
import i18n from "i18next";
import {
	RAFFLE_SAVE_REQUEST,
	RAFFLE_SAVE_SUCCESS,
	RAFFLE_SAVE_FAILURE,
	RAFFLE_REMOVE_REQUEST,
	RAFFLE_REMOVE_SUCCESS,
	RAFFLE_REMOVE_FAILURE,
	INFORM_MEMBERS_RAFFLE_REQUEST,
	INFORM_MEMBERS_RAFFLE_SUCCESS,
	INFORM_MEMBERS_RAFFLE_FAILURE,
} from "@artworkslab/sharedmodules/src/actions/ArtSocietyActions";

export function saveRaffle(body, displayMessage = true) {
	// resolve and reject for submitRaffle() in RaffleFormAction.jsx in Portal.
	return (dispatch) =>
		new Promise((resolve, reject) => {
			dispatch(saveRaffleRequest());
			const ENDPOINT = body._id ? `raffles/${body._id}` : "raffles/";
			const METHOD = body._id ? "PUT" : "POST";
			return Api(ENDPOINT, METHOD, true, body)
				.then((json) => {
					dispatch(saveRaffleSuccess(json, displayMessage));
					resolve(json);
				})
				.catch((err) => {
					console.log("saveRaffleFailure", err);
					dispatch(saveRaffleFailure(err));
					reject(err);
				});
		});
}

function saveRaffleRequest(reset = false) {
	return {
		type: RAFFLE_SAVE_REQUEST,
		reset,
	};
}

function saveRaffleSuccess(json, displayMessage) {
	const isSwedish = i18n.language === "sv";
	return {
		type: RAFFLE_SAVE_SUCCESS,
		response: json,
		message: displayMessage ? (isSwedish ? "Sparat!" : "Saved!") : "NO MESSAGE",
	};
}

function saveRaffleFailure(error) {
	return {
		type: RAFFLE_SAVE_FAILURE,
		message: error,
	};
}

export function removeRaffle(raffle) {
	return {
		[CALL_API]: {
			endpoint: `raffles/${raffle._id}`,
			authenticated: true,
			method: "DELETE",
			types: [
				RAFFLE_REMOVE_REQUEST,
				RAFFLE_REMOVE_SUCCESS,
				RAFFLE_REMOVE_FAILURE,
			],
		},
	};
}

export function informMembersAboutRaffle(
	raffleId,
	sendToMembers,
	sendToContacts
) {
	return {
		[CALL_API]: {
			endpoint: `raffles/inform/${raffleId}?members=${sendToMembers}&contacts=${sendToContacts}`,
			authenticated: true,
			types: [
				INFORM_MEMBERS_RAFFLE_REQUEST,
				INFORM_MEMBERS_RAFFLE_SUCCESS,
				INFORM_MEMBERS_RAFFLE_FAILURE,
			],
		},
	};
}

export const RAFFLE_MAIL_PREVIEW_REQUEST =
	"raffleActions/RAFFLE_MAIL_PREVIEW_REQUEST";
export const RAFFLE_MAIL_PREVIEW_SUCCESS =
	"raffleActions/RAFFLE_MAIL_PREVIEW_SUCCESS";
export const RAFFLE_MAIL_PREVIEW_FAILURE =
	"raffleActions/RAFFLE_MAIL_PREVIEW_FAILURE";

export function fetchRaffleEmailPreview(raffleId) {
	return {
		[CALL_API]: {
			endpoint: `raffles/preview/${raffleId}`,
			authenticated: true,
			types: [
				RAFFLE_MAIL_PREVIEW_REQUEST,
				RAFFLE_MAIL_PREVIEW_SUCCESS,
				RAFFLE_MAIL_PREVIEW_FAILURE,
			],
		},
	};
}

export const RAFFLE_MAIL_PREVIEW_RESET =
	"raffleActions/RAFFLE_MAIL_PREVIEW_RESET";

export function resetRaffleEmailPreview() {
	return {
		type: RAFFLE_MAIL_PREVIEW_RESET,
	};
}
