import { CALL_API } 		from '../utils/api'
import i18next          from 'i18next'

const translate = (name) => {
  return i18next.t(`common:feedback.${name}`)
}

// fetch posts

export const POSTS_REQUEST = 'POSTS_REQUEST'
export const POSTS_SUCCESS = 'POSTS_SUCCESS'
export const POSTS_FAILURE = 'POSTS_FAILURE'

// Uses the API to get a post
export function fetchPosts() {
	return {
		[CALL_API]: {
			endpoint: 'posts/',
			authenticated: true,
			types: [POSTS_REQUEST, POSTS_SUCCESS, POSTS_FAILURE]
		}
	}
}

export const POSTS_ALL_REQUEST = 'POSTS_ALL_REQUEST'
export const POSTS_ALL_SUCCESS = 'POSTS_ALL_SUCCESS'
export const POSTS_ALL_FAILURE = 'POSTS_ALL_FAILURE'

export function fetchAllPostsFast() {
  return {
    [CALL_API]: {
      endpoint: 'posts/fetch-all-fast',
      authenticated: true,
      types: [POSTS_ALL_REQUEST, POSTS_ALL_SUCCESS, POSTS_ALL_FAILURE]
    }
  }
}

export const POSTS_FOR_PICKER_REQUEST = 'POSTS_FOR_PICKER_REQUEST'
export const POSTS_FOR_PICKER_SUCCESS = 'POSTS_FOR_PICKER_SUCCESS'
export const POSTS_FOR_PICKER_FAILURE = 'POSTS_FOR_PICKER_FAILURE'

export function fetchPostsForPicker() {
  return {
    [CALL_API]: {
      endpoint: 'posts/fetch-for-picker',
      authenticated: true,
      types: [POSTS_FOR_PICKER_REQUEST, POSTS_FOR_PICKER_SUCCESS, POSTS_FOR_PICKER_FAILURE]
    }
  }
}

export const POSTS_ALL_POPULATE_REQUEST = 'POSTS_ALL_POPULATE_REQUEST'
export const POSTS_ALL_POPULATE_SUCCESS = 'POSTS_ALL_POPULATE_SUCCESS'
export const POSTS_ALL_POPULATE_FAILURE = 'POSTS_ALL_POPULATE_FAILURE'

export function fetchAllPostsPopulated() {
  // Request was too slow, timed out.
  return { type: 'NOT-DOING-ANYTHING' }
  return {
    [CALL_API]: {
      endpoint: 'posts/fetch-all-populated',
      authenticated: true,
      types: [POSTS_ALL_POPULATE_REQUEST, POSTS_ALL_POPULATE_SUCCESS, POSTS_ALL_POPULATE_FAILURE]
    }
  }
}

export const POST_IMAGE_REQUEST = 'POST_IMAGE_REQUEST'
export const POST_IMAGE_SUCCESS = 'POST_IMAGE_SUCCESS'
export const POST_IMAGE_FAILURE = 'POST_IMAGE_FAILURE'

// Uses the API to get a post
export function uploadPostImage(imageData) {
	const body = {
		imageData
	}
	return {
		[CALL_API]: {
			endpoint: 'posts/image',
			authenticated: true,
			method: 'POST',
			body,
			types: [POST_IMAGE_REQUEST, POST_IMAGE_SUCCESS, POST_IMAGE_FAILURE]
		}
	}
}

// fetch post

export const POST_REQUEST = 'POST_REQUEST'
export const POST_SUCCESS = 'POST_SUCCESS'
export const POST_FAILURE = 'POST_FAILURE'

// Uses the API to get a post
export function fetchPost(id) {
	return {
		[CALL_API]: {
			endpoint: 'posts/' + id,
			authenticated: true,
			types: [POST_REQUEST, POST_SUCCESS, POST_FAILURE]
		}
	}
}

// save post

export const POST_SAVE_REQUEST = 'POST_SAVE_REQUEST'
export const POST_SAVE_SUCCESS = 'POST_SAVE_SUCCESS'
export const POST_SAVE_FAILURE = 'POST_SAVE_FAILURE'

// Uses the API to get a post
export function savePost(post) {
	const method = post._id ? "PUT" : "POST" // update if id is present, otherwise create
	const endpoint = post._id ? 'posts/'+post._id : 'posts'
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [POST_SAVE_REQUEST, POST_SAVE_SUCCESS, POST_SAVE_FAILURE],
			method: method,
			message: translate('postSaved'),
			body: post
		}
	}
}

export const POST_REMOVE_REQUEST = 'POST_REMOVE_REQUEST'
export const POST_REMOVE_SUCCESS = 'POST_REMOVE_SUCCESS'
export const POST_REMOVE_FAILURE = 'POST_REMOVE_FAILURE'

// Uses the API to get a post
export function removePost(post) {
	return {
		[CALL_API]: {
			endpoint: `posts/${post._id}`,
			authenticated: true,
			method: 'DELETE',
			types: [POST_REMOVE_REQUEST, POST_REMOVE_SUCCESS, POST_REMOVE_FAILURE]
		}
	}
}

export const INFORM_MEMBERS_POST_REQUEST = 'postActions/INFORM_MEMBERS_POST_REQUEST'
export const INFORM_MEMBERS_POST_SUCCESS = 'postActions/INFORM_MEMBERS_POST_SUCCESS'
export const INFORM_MEMBERS_POST_FAILURE = 'postActions/INFORM_MEMBERS_POST_FAILURE'

export function informMembersAboutPost(postId, sendToMembers, sendToContacts) {
	return {
		[CALL_API]: {
			endpoint: `posts/inform/${postId}?members=${sendToMembers}&contacts=${sendToContacts}`,
			authenticated: true,
			types: [INFORM_MEMBERS_POST_REQUEST, INFORM_MEMBERS_POST_SUCCESS, INFORM_MEMBERS_POST_FAILURE]
		}
	}
}

export const POST_MAIL_PREVIEW_REQUEST = 'postActions/POST_MAIL_PREVIEW_REQUEST'
export const POST_MAIL_PREVIEW_SUCCESS = 'postActions/POST_MAIL_PREVIEW_SUCCESS'
export const POST_MAIL_PREVIEW_FAILURE = 'postActions/POST_MAIL_PREVIEW_FAILURE'

export function fetchPostEmailPreview(postId) {
	return {
		[CALL_API]: {
			endpoint: `posts/preview/${postId}`,
			authenticated: true,
			types: [POST_MAIL_PREVIEW_REQUEST, POST_MAIL_PREVIEW_SUCCESS, POST_MAIL_PREVIEW_FAILURE]
		}
	}
}

export const POST_MAIL_PREVIEW_RESET = 'postActions/POST_MAIL_PREVIEW_RESET'

export function resetPostEmailPreview() {
  return {
    type: POST_MAIL_PREVIEW_RESET,
  }
}

// It's own success action, don't want to open Saved-modal after updating.
export const UPDATE_CAPTION_REQUEST = 'postActions/UPDATE_CAPTION_REQUEST'
export const UPDATE_CAPTION_SUCCESS = 'postActions/UPDATE_CAPTION_SUCCESS'
export const UPDATE_CAPTION_FAILURE = 'postActions/UPDATE_CAPTION_FAILURE'

export function updatePostImageCaption(postId, imageId, caption) {
	const method = "PUT"
	const endpoint = `posts/${postId}/caption`
  const body = {
    postId,
    imageId,
    caption,
  }
	return {
		[CALL_API]: {
			endpoint: endpoint,
			authenticated: true,
			types: [UPDATE_CAPTION_REQUEST, UPDATE_CAPTION_SUCCESS, UPDATE_CAPTION_FAILURE],
			method: method,
			message: translate('postSaved'),
			body,
		}
	}
}

export const UPDATE_POSTS_REQUEST = 'postActions/UPDATE_POSTS_REQUEST'
export const UPDATE_POSTS_SUCCESS = 'postActions/UPDATE_POSTS_SUCCESS'
export const UPDATE_POSTS_FAILURE = 'postActions/UPDATE_POSTS_FAILURE'

export function reorderPost(postId, newIndex, galleryId) {
  const body = { newIndex, galleryId }
	return {
		[CALL_API]: {
			endpoint: `posts/${postId}/reorder`,
			authenticated: true,
			types: [UPDATE_POSTS_REQUEST, UPDATE_POSTS_SUCCESS, UPDATE_POSTS_FAILURE],
      method: 'PUT',
      message: 'NO MESSAGE',
      body,
		}
	}
}
