import {
  updateUser,
  saveUser
}  from '../UserActions'
import {
  saveGallery,
} from '../GalleryActions'

import {
  initialize,
  getFormValues,
  stopSubmit,
  startSubmit,
}  from 'redux-form'

import Validator              from 'validatorjs'

export const LOAD = 'artworksapp/users/LOAD'
export const SUBMIT = 'artworksapp/users/SUBMIT'

export const SHOW_SUCCESS = 'artworksapp/users/SHOW_SUCCESS'
export const HIDE_SUCCESS = 'artworksapp/users/HIDE_SUCCESS'

export const SHOW_ERROR = 'artworksapp/users/SHOW_ERROR'
export const HIDE_ERROR = 'artworksapp/users/HIDE_ERROR'

const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const userModel = {
  name: '',
  email: '',
  role: 'user',
  country: '',
  phone: '',
  gallery: '',
  artist: ''
}

export function initUser() {
  return dispatch => {
    const usr = Object.assign({}, userModel)
    console.log('initializing: ', usr);
    dispatch( initialize('user', usr, false) )
  }
}

export function loadUser(user) {
  return dispatch => {
    const usr = Object.assign({}, userModel, user)
    dispatch( initialize('user', usr) )
  }
}

export function submitUser(data) {
  return (dispatch, getState) => {
    let customErrors = {}
    let rules = {
      email: 'required|email',
      role: 'required'
    }
    const validation = new Validator(data, rules)
    validation.passes()
    const validatorErrors = validation.errors.all()
    const errors = Object.assign({},customErrors, validatorErrors)
    console.log('Submitting: ', data);
    if (Object.keys(errors) < 1) {
      // TODO: Handle new users
      dispatch( startSubmit('user') )
      if (data._id) {
        // Save gallery here before updating user if data includes memberApplicationEmail or memberApplicationEmailCopy.
        dispatch( updateUser(data) )
      }else{
        dispatch( saveUser(data) )
      }
    }else{
      console.log('Errors, stopping submit:', errors);
      dispatch( stopSubmit('user', errors) )
    }
  }
}

export function removeUser(user){
  return dispatch => {
    // dispatch( deleteUser(user) )
  }
}

export function showSuccess(){
  return {
    type: SHOW_SUCCESS,
    showSuccess: true
  }
}

export function hideSuccess(){
  return {
    type: HIDE_SUCCESS,
    showSuccess: false
  }
}


export function showError(){
  return {
    type: SHOW_ERROR,
    showError: true
  }
}

export function hideError(){
  return {
    type: HIDE_ERROR,
    showError: false
  }
}
