import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import DeliveryAddress from "../../components/OrderComponents/DeliveryAddress";
import OrderItems from "../../components/OrderComponents/OrderItems";
import { updateOrder } from "../../actions/OrderActions";

class Order extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orderNumber: "",
		};
	}

	onOrderNumberChange = (e) => {
		// console.log('e', e);
		this.setState({ orderNumber: e.currentTarget.value });
	};

	render() {
		const { order, setDelivered, setToDeliver } = this.props;
		const { orderNumber } = this.state;
		const shipmentId =
			order.shipping && order.shipping.shipmentId
				? order.shipping.shipmentId
				: false;
		return (
			<div className="order">
				<h4>ORDER</h4>
				<div className="order__content">
					<div>
						<div className="order__info">
							{order.user ? (
								<p>
									<Link to={`/accounts/${order.user._id}`}>{`User: ${
										order.user ? order.user.email : "N/A"
									}`}</Link>
								</p>
							) : (
								<p>User missing</p>
							)}
							<p>{`Updated: ${moment(order.updated_at).format(
								"MMM Do YYYY, HH:mm"
							)}`}</p>
							<p>{`Order id: ${order._id}`}</p>
							{shipmentId && <p>{`Shipment Id: ${shipmentId}`}</p>}
						</div>
						{order.address && <DeliveryAddress {...order.address} />}
					</div>
					<OrderItems {...order} />
				</div>
				{order.needInstall && (
					<p className="order__install-help">
						This user has requested help with installing the artwork.
					</p>
				)}
				<div>
					{order.status === "paid" && (
						<div>
							<h4 className="order__input-header">Shipment id:</h4>
							<div className="Input">
								<input
									value={orderNumber}
									onChange={this.onOrderNumberChange}
								/>
							</div>
							<button
								className="purple-button--wide button-nomargin"
								onClick={() => this.props.setToDeliver(order, orderNumber)}
							>
								Delivery Ordered
							</button>
						</div>
					)}
					{order.status === "todeliver" && (
						<button
							className="purple-button--wide button-nomargin"
							onClick={() => this.props.setDelivered(order)}
						>
							Delivered
						</button>
					)}
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		setDelivered: (order) => {
			console.log("SET delivered", order);
			dispatch(updateOrder(order._id, { status: "delivered" }));
		},
		setToDeliver: (order, shipmentId = false) => {
			console.log("SET todeliver", order, shipmentId);
			shipmentId = shipmentId.trim();
			const update = Object.assign(
				{},
				{ status: "todeliver" },
				shipmentId ? { "shipping.shipmentId": shipmentId } : {}
			);
			console.log("update", update);
			dispatch(updateOrder(order._id, update));
		},
	};
}

export default connect(null, mapDispatchToProps)(Order);
