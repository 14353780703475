import React        from 'react'
import { Link }     from 'react-router'
import { connect }  from 'react-redux'
import Main         from '../../components/Main/Main'

const OrdersSelect = (props) => {
  return (
    <Main>
      <div style={{
        display: 'flex',
        flexFlow: 'column nowrap',
      }}>
        <Link to="orders/person"><u>Person</u></Link>
        <Link to="orders/organization"><u>Organization</u></Link>
      </div>
    </Main>
  )
}

export default OrdersSelect
