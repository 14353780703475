import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
	submit,
	startSubmit,
	hasSubmitSucceeded,
	hasSubmitFailed,
	getFormInitialValues,
	getFormValues,
	isSubmitting,
} from "redux-form";
import {
	submitShow,
	initShow,
} from "../../actions/FormActions/ShowFormActions";
import {
	hideArtworkModal,
	submitArtwork,
	fetchArtworks,
} from "../../actions/FormActions/ArtworkFormActions";
import {
	hideArtistModal,
	submitArtist,
} from "../../actions/FormActions/ArtistFormActions";
import { fetchShow } from "../../actions/ShowActions";
import ShowForm from "./ShowForm";
import ArtistForm from "../Artist/ArtistForm";
import ArtworkForm from "../Artworks/ArtworkForm";
import Main from "../../components/Main/Main";
import { withRouter } from "react-router";
import { saveArtwork } from "../../actions/ArtworkActions";
import { getObjectProp } from "../../utils/Helpers";
import Modal from "../../components/Modal/Modal";
import { withNamespaces } from "react-i18next";
import {
	isPremium,
	isStandardOrAbove,
	hasAccessToFeature,
	AUTOMATIC_REMINDERS_FEATURE,
} from "@artworkslab/sharedmodules/src/utils/BillingUtil";

class CreateShow extends Component {
	static propTypes = {
		router: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
	};
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(initShow());
	}
	componentWillReceiveProps(newProps) {
		if (this.props.user !== newProps.user) {
			const gallery = newProps.user.gallery;
			if (gallery) {
				const galleryId = gallery._id ? gallery._id : gallery;
				this.props.dispatch(fetchArtworks(galleryId));
			}
			this.props.dispatch(initShow());
		}

		// Redirect to shows/slug after saving. Not getting selected artists works for ArtworkPicker on create page.
		if (this.props.form.submitting && !newProps.form.submitting) {
			if (newProps.form.show && newProps.form.show.slug) {
				this.props.router.push(`/shows/${newProps.form.show.slug}`);
			}
		}
	}

	handleSubmit = (vernissageStart, vernissageEnd) => {
		const {
			form: { show, initial },
			t,
		} = this.props;
		show.vernissageStart = vernissageStart;
		show.vernissageEnd = vernissageEnd;
		if (show.artists.length === 0) {
			const confirm = window.confirm(t("single.noArtists"));
			if (confirm) {
				this.props.dispatch(submitShow(show, initial));
			}
		} else {
			this.props.dispatch(submitShow(show, initial));
		}
	};
	handleArtworkSubmit = () => {
		const {
			artworkForm: { artwork, initial },
		} = this.props;
		this.props.dispatch(submitArtwork(artwork, initial));
	};
	handleArtistSubmit = () => {
		const {
			artistForm: { artist, initial },
		} = this.props;
		this.props.dispatch(submitArtist(artist, initial));
	};
	toggleArtworksModal = () => {
		this.props.dispatch(hideArtworkModal());
	};
	toggleArtistModal = () => {
		this.props.dispatch(hideArtistModal());
	};

	render() {
		const {
			isAdmin,
			isArtist,
			isSociety,
			artist,
			gallery,
			showArtworkModal,
			showArtistModal,
			form,
			t,
		} = this.props;

		const showVideo =
			form.show && form.show.videoLink ? form.show.videoLink : null;
		let premium = false;
		let automaticRemindersAvailable = false;
		if (isArtist) {
			premium = isPremium(artist);
		} else if (isSociety) {
			premium = isStandardOrAbove(gallery);
			automaticRemindersAvailable =
				premium || hasAccessToFeature(gallery, AUTOMATIC_REMINDERS_FEATURE);
		}
		const backLink = isArtist ? "/activities" : "/shows";

		return (
			<div>
				<Main>
					<div style={{ marginBottom: "30px" }}>
						<Link
							to={backLink}
							className="purple-button--wide purple-button--wide--mod_create"
							style={{ minWidth: "180px" }}
						>
							{t("list.yourShows")}
						</Link>
					</div>
					<ShowForm
						handleSubmit={this.handleSubmit}
						isAdmin={isAdmin}
						isArtist={isArtist}
						isSociety={isSociety}
						artist={artist}
						isPremium={premium}
						showVideo={showVideo}
						automaticRemindersAvailable={automaticRemindersAvailable}
					/>
					<legend style={{ marginTop: "50px" }}>
						{t("single.saveToSelectArtworks")}
					</legend>
				</Main>
				{showArtworkModal ? (
					<Modal onCloseClick={this.toggleArtworksModal}>
						<ArtworkForm
							onSubmit={this.handleArtworkSubmit}
							isAdmin={isAdmin}
						/>
					</Modal>
				) : null}
				{showArtistModal ? (
					<Modal onCloseClick={this.toggleArtistModal}>
						<ArtistForm
							onSubmit={this.handleArtistSubmit}
							isAdmin={isAdmin}
							isModal={true}
							forArtSociety={gallery && gallery.venueType === "ArtSociety"}
						/>
					</Modal>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		show,
		artworkForm: { showArtworkModal },
		artistForm: { showArtistModal },
		auth: { user, isAdmin },
		artist: { artist },
		gallery: { gallery },
	} = state;
	const isArtist = user.role === "artist";
	const isSociety = user.role === "society";
	const form = {
		submitting: isSubmitting("show")(state),
		submitSucceeded: hasSubmitSucceeded("show")(state),
		submitFailed: hasSubmitFailed("show")(state),
		show: getFormValues("show")(state),
		initial: getFormInitialValues("show")(state),
	};
	const artistForm = {
		submitting: isSubmitting("artist")(state),
		submitSucceeded: hasSubmitSucceeded("artist")(state),
		submitFailed: hasSubmitFailed("artist")(state),
		artist: getFormValues("artist")(state),
		initial: getFormInitialValues("artist")(state),
	};

	const artworkForm = {
		submitting: isSubmitting("artwork")(state),
		submitSucceeded: hasSubmitSucceeded("artwork")(state),
		submitFailed: hasSubmitFailed("artwork")(state),
		artwork: getFormValues("artwork")(state),
		initial: getFormInitialValues("artwork")(state),
	};
	return {
		show,
		user,
		artist,
		gallery,
		isAdmin,
		isArtist,
		isSociety,
		form,
		artworkForm,
		artistForm,
		showArtworkModal,
		showArtistModal,
	};
}

const translated = withNamespaces("show")(CreateShow);
export default connect(mapStateToProps)(withRouter(translated));
