import { CALL_API } from '../../utils/api';

export const TOGGLE_EXPANDED = 'artworksapp/dashboard/admin/TOGGLE_EXPANDED';
export const WEEKLY_REQUEST = 'artworksapp/dashboard/admin/WEEKLY_REQUEST';
export const WEEKLY_SUCCESS = 'artworksapp/dashboard/admin/WEEKLY_SUCCESS';
export const WEEKLY_FAILURE = 'artworksapp/dashboard/admin/WEEKLY_FAILURE';

export function toggleSummary() {
  return {
    type: TOGGLE_EXPANDED
  }
}

export function fetchWeeklyData(week) {
  const endpoint = `stats/admin/weekly/${week}`
  return {
    [CALL_API]: {
      endpoint,
      authenticated: true,
      types: [WEEKLY_REQUEST, WEEKLY_SUCCESS, WEEKLY_FAILURE]
    }
  }
}
