import { CALL_API } from "../utils/api";
import LocationUtil from "../utils/LocationUtil";
import Api from "@artworkslab/sharedmodules/src/Api";
import i18next from "i18next";

// Fetch announcements
export const ANNOUNCEMENTS_REQUEST = "ANNOUNCEMENTS_REQUEST";
export const ANNOUNCEMENTS_SUCCESS = "ANNOUNCEMENTS_SUCCESS";
export const ANNOUNCEMENTS_FAILURE = "ANNOUNCEMENTS_FAILURE";

// Utilizes the api to fetch all announcements
export function fetchAnnouncements() {
	return {
		[CALL_API]: {
			endpoint: "announcements/all",
			authenticated: true,
			types: [
				ANNOUNCEMENTS_REQUEST,
				ANNOUNCEMENTS_SUCCESS,
				ANNOUNCEMENTS_FAILURE,
			],
		},
	};
}

// Fetch announcement
export const ANNOUNCEMENT_REQUEST = "ANNOUNCEMENT_REQUEST";
export const ANNOUNCEMENT_SUCCESS = "ANNOUNCEMENT_SUCCESS";
export const ANNOUNCEMENT_FAILURE = "ANNOUNCEMENT_FAILURE";

// Utilizes the apit to get an announcement
export function fetchAnnouncement(slug) {
	return {
		[CALL_API]: {
			endpoint: "announcements/" + slug,
			authenticated: true,
			types: [ANNOUNCEMENT_REQUEST, ANNOUNCEMENT_SUCCESS, ANNOUNCEMENT_FAILURE],
		},
	};
}

// Save announcement
export const SAVE_ANNOUNCEMENT_REQUEST = "SAVE_ANNOUNCEMENT_REQUEST";
export const SAVE_ANNOUNCEMENT_SUCCESS = "SAVE_ANNOUNCEMENT_SUCCESS";
export const SAVE_ANNOUNCEMENT_FAILURE = "SAVE_ANNOUNCEMENT_FAILURE";

// Utilizes the api to save an announcement
export function saveAnnouncement(changedValues) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			const endpoint = changedValues._id
				? `announcements/${changedValues._id}`
				: "announcements/save";
			const method = changedValues._id ? "PUT" : "POST";
			const track = changedValues._id
				? "Updated announcement"
				: "Saved announcement";
			const payload = changedValues._id
				? { announcement: changedValues._id }
				: {};
			const analytics = {
				track,
				payload,
				incrementScore: 1,
			};

			dispatch(saveAnnouncementRequest(analytics));
			Api(endpoint, method, true, changedValues)
				.then((json) => {
					dispatch(saveAnnouncementSuccess(json));
					resolve(json);
				})
				.catch((error) => {
					dispatch(saveAnnouncementFailure(error));
					reject(error);
				});
		});
}

export function saveAnnouncementRequest(analytics) {
	return {
		type: SAVE_ANNOUNCEMENT_REQUEST,
		analytics,
	};
}

export function saveAnnouncementSuccess(json) {
	return {
		type: SAVE_ANNOUNCEMENT_SUCCESS,
		response: json,
	};
}

export function saveAnnouncementFailure(error) {
	return {
		type: SAVE_ANNOUNCEMENT_FAILURE,
		error,
	};
}

//Delete announcement
export const DELETE_ANNOUNCEMENT_REQUEST = "DELETE_ANNOUNCEMENT_REQUEST";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_FAILURE = "DELETE_ANNOUNCEMENT_FAILURE";

//Utilizes the api to delete an announcement
export function deleteAnnouncement(announcementId) {
	return {
		[CALL_API]: {
			endpoint: "announcements/" + announcementId,
			authenticated: true,
			types: [
				DELETE_ANNOUNCEMENT_REQUEST,
				DELETE_ANNOUNCEMENT_SUCCESS,
				DELETE_ANNOUNCEMENT_FAILURE,
			],
			method: "delete",
		},
	};
}

//Reset announcement
export const RESET_ANNOUNCEMENT = "RESET_ANNOUNCEMENT";

//Resets the reducer announcement object
export function resetAnnouncement() {
	return {
		type: RESET_ANNOUNCEMENT,
	};
}
