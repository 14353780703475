import {
	TOGGLE_EXPANDED, DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE
} from '../../actions/DashboardActions/PopularArtworkActions'

export function popularArtworkReducer(state = {
		isFetching: false,
		authenticated: false,
		artworks: [],
    expanded: true
	}, action) {
	switch (action.type) {
		case TOGGLE_EXPANDED:
      return Object.assign({}, state, {
        expanded: !state.expanded
      });
		case DATA_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			});
		case DATA_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				artworks: action.response
			});
		case DATA_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				error: action.error
			});
		default:
			return state
	}
}
