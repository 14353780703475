import React, { Component } from "react";
import { connect }          from 'react-redux'
import {  Field,
          Fields,
          FormSection,
          reduxForm }       from 'redux-form';
import Select               from '../../components/FormInput/Select'
import TextInput            from '../../components/FormInput/TextInput'
import TextDisplay          from '../../components/FormInput/TextDisplay'
import TextArea             from '../../components/FormInput/TextArea'
import CheckBox             from '../../components/FormInput/CheckBox'
import ImageUpload          from "../../components/FormInput/ImageUpload"
import ImageUploadRotate    from "../../components/FormInput/ImageUploadRotate"
import PDFUpload            from "../../components/FormInput/PDFUpload"
import RepresentedByPicker  from "../../components/FormComponents/RepresentedByPicker/RepresentedByPicker"
import ColorPicker          from '../../components/FormComponents/ColorPicker/ColorPicker'
import RelatedPicker        from '../../components/FormComponents/RelatedPicker/RelatedPicker'
import CreateRelated        from '../../components/FormComponents/RelatedPicker/CreateRelated'
import LocationPicker       from '../../components/FormComponents/LocationPicker/LocationPicker'
import GalleryPicker        from '../../components/FormComponents/GalleryPicker/GalleryPicker'
import SingleDateTimePicker from '../../components/FormComponents/DateRange/SingleDateTimePicker'
import Tooltip              from '../../components/Tooltip/Tooltip'
import ArtistProLink        from '../../components/ArtworksLite/ArtistProLink'
import ExpandButton         from '../../components/DashboardComponents/ExpandButton/ExpandButton'
import {
  validateEmail,
  validatePhone,
  validateWebAddress,
  validateEmbed,
  validateVAT,
  validateClearNr,
  validateAccNr,
  isNumber
}                           from '../../utils/Validators'
import {
  isUserArtSociety,
  capitalizeFirstLetter,
  imageRotationActivated,
}                           from '../../utils/Helpers'
import {
  CENTER_IMAGE_OPTIONS,
  videoTooltip,
  getYearList,
}                           from '../../utils/values'
import {
  COUNTRIES_SELECTED,
  COUNTRIES_ALL_ENG,
  COUNTRIES_ALL_SWE,
  GENDER_OPTIONS,
  GENDER_OPTIONS_SWE
}                           from '../Account/FormValues'
import { withNamespaces }   from 'react-i18next'
import i18next              from 'i18next'
import {
  showCustomModal,
}	 													from '../../actions/AppActions'

const TOOL_TIPS = (type, t) => {
  if (type === 'colorPicker') {
    return ({
        text: 'Choose a color you want the image to be associated with',
        popupPos: 'left'
      })
  } else if (type === 'orgNumber') {
    return ({
      text: t('single.toolTips.orgNumberTip'),
      popupPos: 'left'
    })
  } else if (type === 'vatReg') {
    return ({
      text: 'Check this box if your company is registered for VAT in Sweden. If you have any problems, please contact artworks: hey@artworks.io',
      popupPos: 'left'
    })
  } else if (type === 'artistEmail') {
    return ({
      text: t('single.toolTips.artistEmailTip'),
      popupPos: 'left'
    })
  }
}

const showProModal = (dispatch) => {
  // const content = (
  //   <div className="responsive-modal-content">
  //     <p>Här kan du som <i>PRO</i> ladda upp en videofil.</p>
  //     {/*<p>Under Juli fortsätter vi fira 100! Därmed erbjuder vi <b>alla nya</b> <i>PRO</i> <b>medlemmar halva priset</b> vid årlig fakturering.</p>*/}
  //     <p><i>PRO</i> konstnärer har flest besök i sina profiler, står för ¼  av all försäljning 2021* och har lägre kommission.</p>
  //     <p>Läs mer och testa <i>PRO</i> gratis <ArtistProLink children={ 'här' } />! 🎨</p>
  //     <div className="modal-footnote">*Enligt data från 18/8 2021</div>
  //   </div>
  // )
  const content = (
    <div>
      <p>Här kan du som <i>PRO</i> ladda upp en videofil.</p>
      <p>Läs mer och prova <ArtistProLink children={ 'här' } />!</p>
    </div>
  )
  dispatch( showCustomModal(content, '') )
}

const VISIBILITY_OPTIONS = [
	{value:'restricted', name: "Restricted"},
	{value:'web', name: "Webb only"},
  {value:'app', name: "App only"},
  {value:'all', name: "Everywhere"},
  {value:'notlisted', name: "Not listed"},
]

const MEMBERSHIPS = [
  { name: 'BAS', value: 'free' },
  { name: 'PRO', value: 'premium' },
]

const PREMIUMS = [
  { name: 'Månadsvis', value: 'monthly' },
  { name: 'Årligen', value: 'yearly' },
]

const expandButtonStyle = {
  top: 0,
}

class ArtistForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: {
        general: true,
        social: false,
        payments: false,
        admin: false,
      },
      videoInputEnabled: true,
    }
  }

  toggleExpanded = (section) => {
    const { sections } = this.state
    sections[section] = !this.state.sections[section]
    this.setState({
      sections,
    })
  }

  // Video input could be clicked when trying to click on gallery in gallery picker dropdown.
  // Disable video popup if dropdown component has focus.
  swapVideoInput = (enable) => {
    this.setState({
      videoInputEnabled: enable,
    })
  }

  render() {
  	const {
      handleSubmit, pristine, submitting, isAdmin, isAuthor, isAssociation, isArtist,
      showVat, header, editArtist = false, isPremium, artistVideo, dispatch, t } = this.props
    // editArtist: Tooltip about email only when creating new artist. Email not sent when updating an artists email.
    const {
      sections: { general, social, payments, admin: adminSectionOpen },
      videoInputEnabled,
    } = this.state
    const useUploadWithRotate = imageRotationActivated()
    const isSwedish = i18next.language === 'sv'

    return (
      <form onSubmit={ handleSubmit } className="responsive-form" >
        <div className="fieldset-block-r">
    			<legend className="expand-legend">{t('single.generalInformation')}</legend>
          <ExpandButton expanded={ general } click={() => this.toggleExpanded('general')} style={ expandButtonStyle } />
          { general &&
      			<div className="fieldset-r">
            { isAdmin &&
              <div className="input-holder-r input-holder-r--full">
                <legend>{t('single.representedBy')}</legend>
                <Field
                  name="represented_by"
                  component={ RepresentedByPicker }
                  responsive={ true } />
              </div>
            }
            { isAuthor &&
              <div className="input-holder-r input-holder-r--full">
                <Field
                  name="representedByYou"
                  component={ CheckBox }
                  checkboxLabel={"This artist is represented by you."}
                  secondLabel={"Tick the box if this artist is represented by you."}
                >
                </Field>
              </div>
            }
    				<div className="input-holder-r input-holder-r--full">
    					<Field
                name="name"
                component={ TextInput }
                placeholder={t('common:name')}
                required
                marked
              />
    				</div>
            <div className="input-holder-r input-holder-r--full">
              <Field
                name="about"
                component={ TextArea }
                placeholder={ isArtist ? t('single.aboutMePlaceholder') : t('single.aboutTheArtistPlaceholder') }
                marked
              />
            </div>
            <div className="input-holder-r input-holder-r--full">
              {/*<legend>{ isArtist ? t('single.locationArtist') : t('single.location') }</legend>*/}
              <Fields
                names={[
                  "locationName",
                  "locationSearch",
                  "location.type",
                  "location.coordinates"
                ]}
                mapSize={{ width: 300, height: 300 }}
                isArtist={ isArtist }
                component={ LocationPicker }
                responsive={ true }
              />
            </div>
            <div className="input-holder-r input-holder-r--full"
              onFocus={() => this.swapVideoInput(false)}
              onBlur={() => this.swapVideoInput(true)}>
              <Field
                name="education"
                component={ GalleryPicker }
                type="education"
                multi={ true }
                placeholder={capitalizeFirstLetter(t('single.education'))}
                forEducation={ true }
                responsive={ true }
                marked
              />
            </div>
            { (isArtist || isAdmin) &&
              <div className="fieldset-r">
                {/*Left checkbox section*/}
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="professional"
                    component={ CheckBox }
                    checkboxLabel={t('single.additionalData.professional')}
                  />
                  <Field
                    name="autodidact"
                    component={ CheckBox }
                    checkboxLabel={t('single.additionalData.autodidact')}
                  />
                  <Field
                    name="student"
                    component={ CheckBox }
                    checkboxLabel={t('single.additionalData.student')}
                  />
                </div>
                {/*More visibility checkbox*/}
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="moreVisibility"
                    component={ CheckBox }
                    checkboxLabel={t('single.additionalData.moreVisibilityLabel')}
                  />
                </div>
                {/*Dropdowns*/}
                <div className="input-holder-r input-holder-r--full">
                  <legend className="grey-legend">{t('single.additionalData.activeSince')}</legend>
                  <Field
                    name="activeSince"
                    component={ Select }
                    options={ getYearList() }
                    />
                  <legend className="grey-legend">{t('single.additionalData.nationality')}</legend>
                  <Field
                    name="nationality"
                    component={ Select }
                    options={ isSwedish ? COUNTRIES_ALL_SWE : COUNTRIES_ALL_ENG }
                    />
                  <legend className="grey-legend">{t('single.additionalData.birthYear')}</legend>
                  <Field
                    name="birthYear"
                    component={ Select }
                    options={ getYearList() }
                    />
                  <legend className="grey-legend">{t('single.additionalData.gender')}</legend>
                  <Field
                    name="gender"
                    component={ Select }
                    options={ isSwedish ? GENDER_OPTIONS_SWE : GENDER_OPTIONS }
                    />
                </div>
              </div>
            }
            <div className="input-holder-r input-holder-r--full"
              onFocus={() => this.swapVideoInput(false)}
              onBlur={() => this.swapVideoInput(true)}>
              <Field
                name="member"
                component={ GalleryPicker }
                multi={ true }
                allowAdd={ false }
                placeholder={t('single.memberOfSociety')}
                responsive={ true }
                />
            </div>
            { !isAssociation &&
              <div className="fieldset-r">
                <div className="input-holder-r input-holder-r--full">
                  {/*<legend className="legend-nomargin">{t('common:uploadVideo')}</legend>*/}
                  { isPremium && <Tooltip data={ videoTooltip() } extraClass="marker-move-left" /> }
                  <Field
                    name="videoLink"
                    component={ TextInput }
                    placeholder={t('common:uploadVideo')}
                    validate={ validateEmbed }
                    disabled={ (!isPremium && !isAdmin) }
                    video
                  />
                  { (!isPremium && !isAdmin && videoInputEnabled) &&
                    <div className="block-div" onClick={() => showProModal(dispatch)}></div>
                  }
                </div>
                { (artistVideo && (isPremium || isAdmin)) &&
                  <div className="input-holder-r input-holder-r--full">
                    <div className="videoContainer artist-video">
                      <iframe
                        src={ artistVideo }
                        frameBorder='0'
                      />
                    </div>
                  </div>
                }
              </div>
            }
            <div className="input-holder-r input-holder-r--full">
              <Fields
                names={[
                  "featuredImage.src",
                  "uploadedImage.URI",
                  "uploadedImage.fileName",
                  "uploadedImage.contentType"
                ]}
                component={ useUploadWithRotate ? ImageUploadRotate : ImageUpload }
                centerRotateButtons={ true }
                canRemoveImage={ true }
                profileImage={ true }
                responsive={ true }
              />
              { isAdmin &&
                <Field name="featuredImage.color" component={ ColorPicker }>
                  <Tooltip data={ TOOL_TIPS('colorPicker', t)} />
                </Field>
              }
            </div>
            <div className="input-holder-r">
              { isAdmin &&
                <div className="input-holder-r">
                  <legend className="legend-nomargin">Image center position</legend>
                  <Field name="featuredImage.center" options={ CENTER_IMAGE_OPTIONS } component={ Select } />
                </div>
              }
            </div>
            { !isAssociation &&
              <div className="fieldset-block-r">
                <Fields
                  names={[
                    "pdfCV",
                    "uploadedPDF.URI",
                    "uploadedPDF.fileName",
                    "uploadedPDF.contentType"
                  ]}
                  component={ PDFUpload }
                  responsive={ true }
                />
              </div>
            }
          {/* fieldset-r */}
          </div>
        }
        {/* fieldset-block-r */}
        </div>
        {/* general */}

        <div className="fieldset-block-r">
          <legend className="expand-legend">{ t('single.communication') }</legend>
          <ExpandButton expanded={ social } click={() => this.toggleExpanded('social')} style={ expandButtonStyle } />
          { social &&
            <div className="fieldset-r">
              <div className="input-holder-r input-holder-r--full">
                <Field
                  name="email"
                  component={ TextInput }
                  placeholder={t('common:email')}
                  validate={ validateEmail }
                  marked
                />
                { !editArtist &&
                  <Tooltip data={ TOOL_TIPS('artistEmail', t)} extraClass="resp-left" />
                }
                </div>
              { !isAssociation &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="phone"
                    component={ TextInput }
                    placeholder={t('common:phoneNumber')}
                    validate={ validatePhone }
                    required
                  />
                </div>
              }
              { !isAssociation &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="webSite"
                    component={ TextInput }
                    placeholder={t('common:websiteExample')}
                    validate={ validateWebAddress }
                  />
                </div>
              }
              { !isAssociation &&
        				<div className="input-holder-r input-holder-r--full">
        					<Field
                    name="social.instagram"
                    component={ TextInput }
                    placeholder="@ Instagram"
                    normalize={ normalizeInstagram }
                  />
                </div>
              }
              { !isAssociation &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="social.facebook"
                    component={ TextInput }
                    placeholder="Facebook"
                  />
                </div>
              }
            {/* fieldset-r */}
            </div>
          }
        {/* fieldset-block-r */}
        </div>
        {/* social */}

        {/*<div className="fieldset-block-r">
          <legend className="expand-legend" style={{ marginTop: '10px' }}>{ t('single.payment') }</legend>
          <ExpandButton expanded={ payments } click={() => this.toggleExpanded('payments')} style={ expandButtonStyle } />
          { payments &&
            <div className="fieldset-r">
              { showVat &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="orgNumber"
                    component={ TextInput }
                    placeholder={t('single.organisationNumber')}
                    validate={ validateVAT }
                    >
                  </Field>
                </div>
              }
              { showVat &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="bankName"
                    component={ TextInput }
                    placeholder={t('single.bankName')}
                    />
                </div>
              }
              { showVat &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="clearingNr"
                    component={ TextInput }
                    placeholder={t('single.clearingNr')}
                    validate={ validateClearNr }
                    />
                </div>
              }
              { showVat &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="accountNr"
                    component={ TextInput }
                    placeholder={t('single.accountNr')}
                    />
                </div>
              }
              { showVat &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="bankgiro"
                    component={ TextInput }
                    placeholder={t('single.bankgiro')}
                    />
                </div>
              }
              { !isAssociation &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="country"
                    component={ Select }
                    options={ COUNTRIES_SELECTED }
                    />
                </div>
              }
              { false && showVat &&
                <div className="input-holder-r input-holder-r--full">
                  <Field
                    name="vatReg"
                    component={ CheckBox }
                    checkboxLabel={"Registered for VAT."}
                  >
                  </Field>
                </div>
              }
            </div>
          }
        </div>*/}
        {/* payments */}

        { isAdmin &&
          <div className="fieldset-block-r">
            <legend className="expand-legend">Admin</legend>
            <ExpandButton expanded={ adminSectionOpen } click={() => this.toggleExpanded('admin')} style={ expandButtonStyle } />
            { adminSectionOpen &&
              <div className="fieldset-r">
                <legend>{t('common:mentions.linksToMentions')}</legend>
                <div className="fieldset-r">
                  <div className="input-holder-r input-holder-r--full">
                    <Field
                    name="related"
                    component={ RelatedPicker }
                    />
                  </div>
                </div>
                <legend>{t('common:mentions.addNewMention')}</legend>
                <CreateRelated
                  handleSubmit={ handleSubmit }
                  submitting={ submitting }
                  responsive={ true } />
                <div className="input-holder-r">
                  <legend className="legend-nomargin">Editor rating</legend>
                  <Field
                    name="editorRating"
                    component={ TextInput }
                    placeholder="Editor rating(1-10)"
                  />
                </div>
                <div className="input-holder-r">
                  <legend className="legend-nomargin">Visibility</legend>
                  <Field
                    name="visibility"
                    component={ Select }
                    options={ VISIBILITY_OPTIONS }
                    />
                </div>
                <legend style={{ width: '50%' }}>{t('single.premiumMembership')}</legend>
                <div className="input-holder">
                  <Field
                    name="billing.membershipLevel"
                    options={MEMBERSHIPS}
                    component={ Select }
                    />
                </div>
                { isPremium &&
                  <div className="input-holder">
                    <Field
                      name="billing.premiumType"
                      options={PREMIUMS}
                      component={ Select }
                      />
                  </div>
                }
                { isPremium &&
                  <div className="input-holder">
                    <p style={{ color: '#A8A8A8' }}>Became Pro</p>
                    <Field
                      name="billing.premiumDate"
                      component={ SingleDateTimePicker }
                      />
                  </div>
                }
                { isPremium &&
                  <div className="input-holder">
                    <Field
                      name="ambassador"
                      component={ CheckBox }
                      checkboxLabel={"This artist is an ambassador."}
                      secondLabel={"Tick the box if this artist is an ambassador."}
                    />
                  </div>
                }
                <div className="input-holder">
                  <Field
                    name="noCommission"
                    component={ CheckBox }
                    checkboxLabel={"This artist pays no commission"}
                    secondLabel={"Tick the box if this artist is absolved of commission costs"}
                  />
                </div>
                <legend style={{ width: '50%' }}>{t('single.quarterData.title')} (Admin)</legend>
                <div className="input-holder">
                  <legend className="legend-nomargin">{t('single.quarterData.newsletters')}</legend>
                  <Field
                    name="newsletters"
                    component={ TextInput }
                    placeholder={t('single.quarterData.newsletters')}
                    validate={ isNumber }
                  />
                </div>
                <div className="input-holder">
                  <legend className="legend-nomargin">{t('single.quarterData.tips')}</legend>
                  <Field
                    name="tips"
                    component={ TextInput }
                    placeholder={t('single.quarterData.tips')}
                    validate={ isNumber }
                  />
                </div>
                <div className="input-holder">
                  <legend className="legend-nomargin">{t('single.quarterData.suggestions')}</legend>
                  <Field
                    name="suggestions"
                    component={ TextInput }
                    placeholder={t('single.quarterData.suggestions')}
                    validate={ isNumber }
                  />
                </div>
                <div className="input-holder">
                  <legend className="legend-nomargin">{t('single.quarterData.events')}</legend>
                  <Field
                    name="events"
                    component={ TextInput }
                    placeholder={t('single.quarterData.events')}
                    validate={ isNumber }
                  />
                </div>
                <div className="input-holder">
                  <legend className="legend-nomargin">{t('single.quarterData.exposure')}</legend>
                  <Field
                    name="exposure"
                    component={ TextInput }
                    placeholder={t('single.quarterData.exposure')}
                    validate={ isNumber }
                  />
                </div>
                <div className="input-holder">
                  <legend className="legend-nomargin">{t('single.quarterData.talks')}</legend>
                  <Field
                    name="talks"
                    component={ TextInput }
                    placeholder={t('single.quarterData.talks')}
                    validate={ isNumber }
                  />
                </div>
              </div>
            }
          {/* fieldset-block-r */}
          </div>
        }

        {/* Mobile only. */}
        <div className="fixed-save-button mobile-only">
          <button className="purple-button--wide" type="submit" disabled={ pristine || submitting }>{t('common:save')}</button>
        </div>
        {/* Desktop only. */}
        <div className="buttons-row" style={{ marginTop: '50px' }}>
          <button className="purple-button--wide desktop-only" type="submit" disabled={ pristine || submitting }>{t('common:save')}</button>
          <Field
            name="slug"
            itemType="artist"
            component={ TextDisplay }
            isAdmin={ isAdmin }
            isArtist={ isArtist }
            isResponsive={ true }
          />
        </div>
      </form>
    )
  }
}

const normalizeInstagram = (value) => {

  if (!value) return value;

  if (value.charAt(0) === '@') return value.slice(1);

  return value;
}

ArtistForm.defaultProps = {
  showVat: false
}

ArtistForm = reduxForm({
  form: 'artist'
})(ArtistForm)

ArtistForm = connect(
  state => {
    const isAssociation = isUserArtSociety(state.auth.user)
    const isArtist = state.auth.user.role === 'artist'
    return { isAssociation, isArtist }
  },
  dispatch => {
    return {}
  }
)(ArtistForm)

export default withNamespaces('artist')(ArtistForm)
