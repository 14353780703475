import React              from 'react'
import { Link }           from 'react-router'
import { connect }        from 'react-redux'
import Select             from 'react-select'
import moment             from 'moment'
import {
  Table, Tr, Td, Thead, Th,
} from 'reactable'
import Main               from '../../components/Main/Main'
import {
  fetchDiscountCodes,
} from '../../actions/DiscountCodeActions'

class DiscountCodes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      batchOptions: [{ value: 'all', label: 'All' }],
      selectedOption: { value: 'all', label: 'All' },
      userCodes: '',
    }
  }

  componentWillMount() {
    this.props.dispatch( fetchDiscountCodes() )
  }

  componentWillReceiveProps(nextProps) {
    // console.log('Object.keys(this.props.allCodes).length', Object.keys(this.props.allCodes).length);
    // console.log('Object.keys(nextProps.allCodes).length', Object.keys(nextProps.allCodes).length);
    // if (Object.keys(this.props.allCodes).length !== Object.keys(nextProps.allCodes).length) {
    if (this.props.allCodesArray.length !== nextProps.allCodesArray.length) {
      const options = this.state.batchOptions.slice()
      const alreadyAdded = []
      nextProps.allCodesArray.forEach(code => {
        // const batchName = nextProps.allCodes[key].batchName
        const batchName = code.batchName
        if (alreadyAdded.indexOf(batchName) < 0){
          alreadyAdded.push(batchName)
          options.push({ value: batchName, label: batchName })
        }
      })

      this.setState({
        batchOptions: options,
      })
    }
  }

  viewUserCodesForBatch = (codes) => {
    let userCodes = ''
    codes.forEach(code => {
      userCodes += code.code + ', '
    })
    console.log('userCodes', userCodes);
    this.setState({
      userCodes,
    })
  }

  selectBatch = (option) => {
    this.setState({
      selectedOption: option,
    })
  }

  createRows = (codes) => {
    return codes.map(code => {
      let discount = ''
      if (code.discountPercent) discount += code.discountPercent + '%'
      if (code.discountSEK) discount += code.discountSEK + 'SEK'

      return (
        <Tr key={ code._id }>
          <Td column="batchName" value={ code.batchName }>{ code.batchName }</Td>
          <Td column="code" value={ code.code }>
            <Link to={ `/discounts/${code._id}` }>{ code.code }</Link>
          </Td>
          <Td column="discount" value={ discount }>{ discount }</Td>
          <Td column="valid" value={ code.valid }>{ code.valid ? 'Valid' : 'Invalid' }</Td>
          <Td column="expirationDate" value={ code.expirationDate }>{ moment(code.expirationDate).format('MMM Do, YYYY') }</Td>
          <Td column="redeemedDate" value={ code.redeemedDate }>{ code.redeemedDate ? moment(code.redeemedDate).format('MMM Do, YYYY') : '' }</Td>
        </Tr>
      )
    })
  }

  render() {
    const { isAdmin, allCodes, allCodesArray } = this.props
    const { batchOptions, selectedOption, userCodes } = this.state
    if (!isAdmin) return null

    const selectedBatch = selectedOption.value
    let selectedCodes = selectedBatch === 'all' ? allCodesArray : allCodesArray.filter(code => code.batchName === selectedBatch)

    const rows = this.createRows(selectedCodes)

    return (
      <div>
        <Main>
          <h1>Discount Codes</h1>
          <div style={{ margin: '30px 0' }}>
            <Link to="/discounts/new" className="purple-button--wide">{ 'New discount codes' }</Link>
          </div>
          <div style={{ padding: '20px 0', maxWidth: '350px' }}>
            <Select
  						value={ selectedOption }
  						options={ batchOptions }
  						onChange={ this.selectBatch }
  						multi={ false }
              clearable={ false }
  						/>
          </div>
          <Table
            className="reactable"
            sortable={[
              'batchName', 'code', 'discount', 'valid',
              'expirationDate', 'redeemedDate',
            ]}
            filterable={ false }>
            <Thead>
              <Th column="batchName">Batch name</Th>
              <Th column="code">Code</Th>
              <Th column="discount">Discount</Th>
              <Th column="valid">Valid</Th>
              <Th column="expirationDate">Expires</Th>
              <Th column="redeemedDate">Redeemed</Th>
            </Thead>
            { rows }
          </Table>
          <div style={{ padding: '20px 0', maxWidth: '500px' }}>
            <button onClick={() => this.viewUserCodesForBatch(selectedCodes)} style={{ margin: 0, margin: '20px 0 20px 0' }}>Show codes for batch</button>
            <textarea rows={10} value={ userCodes } />
          </div>
        </Main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { user },
    discountCode: { allCodes },
  } = state
  const isAdmin = user.role === 'admin'
  const allCodesArray = Object.values(allCodes)

  return { isAdmin, allCodes, allCodesArray }
}

export default connect(mapStateToProps)(DiscountCodes)
