import React, { Component } from "react";

const CheckBox = (props) => {
	const checked = props.input.value ? true : false;

	let label = false;
	if (props.checkboxLabel && !props.secondLabel) {
		label = props.checkboxLabel;
	} else if (props.checkboxLabel && props.secondLabel) {
		label = checked ? props.checkboxLabel : props.secondLabel;
	}

	return (
		<div
			className={
				"Input Input--text" +
				(props.meta.invalid ? " is-error" : "") +
				(props.meta.valid ? " is-valid" : "") +
				(props.meta.required ? " is-required" : "")
			}
		>
			<input
				{...props.input}
				type="checkbox"
				checked={checked}
				disabled={props.disabled}
			/>
			{label && <label htmlFor="">{label}</label>}
			{props.meta.invalid && !props.hideInvalid && (
				<span className="Input__error">
					{props.invalidText || props.meta.error}
				</span>
			)}
			{props.children || null}
		</div>
	);
};

export default CheckBox;
